import styled from "styled-components";

export const Error = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: #ff0000;
  padding: 0px 20px;
  width: 100%;
  // top: -22px;
  position: relative;
`;
