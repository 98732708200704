import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  #footer-sticky {
    position: sticky;
    bottom: 0;
    z-index: 100;
  }

  #root {
    position: relative;
  }

  #header-sticky {
    position: sticky;
    top: 0;
    z-index: 100;
  }

  #sidebars {
    position: relative;
    z-index: 150;
  }

  #modal {
    position: relative;
    z-index: 160;
  }

  #snackbar {
    position: relative;
    z-index: 170;
  }
`;

export const Page = styled.div<{ minHeight: number; currentPage }>`
  position: relative;
  z-index: 10;
  min-height: 100vh;
`;

export const PageContent = styled.div`
  background-color: #fafafa;
  min-height: 100vh;
  padding: 0 16px;
`;
