import { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Formik } from 'formik';

import Sidebar from "../../../../../components/Sidebar";
import Button from "../../../../../components/Button";
import Input from "../../../../../components/FormikForm/Input";
import TextArea from '../../../../../components/FormikForm/TextArea';
import Submit from "../../../../../components/FormikForm/Submit";
import Snackbar from "../../../../../components/Snackbar";

import { initialValues, schema } from '../../../../../constants/forms/category/edit'

import { resetUpdateCategory, updateCategory } from '../../../../../store/actions/category'
import { CategoryDto } from '../../../../../types/category';
import { Group } from '../../../../../types';

interface ComponentProps {
  group: Group
  groupStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  updatedCategory: CategoryDto;
  updatedCategoryStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  category?: CategoryDto
  onClose: any
  isVisible: boolean
}

const Component = ({
  group,
  groupStates,
  updatedCategory,
  updatedCategoryStates,
  category,
  onClose,
  isVisible
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState(false);

  const onSubmit = ({ values, actions }) => {
    dispatch(updateCategory(values));
  };

  useEffect(() => {
    if (updatedCategoryStates.success) {
      !!onClose && onClose();
      setTimeout(() => {
        dispatch(resetUpdateCategory());
      }, 3500);
    }
  }, [updatedCategoryStates]);

  return <>
    <Sidebar
      position="right"
      visible={isVisible}
      closeType="back"
      onClose={() => {
        !!onClose && onClose();
      }}
      width="463px"
      title="Editar categoría"
    >
      <Formik
        initialValues={{ ...initialValues, ...category }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}

        validateOnChange={false}
        validateOnBlur={formSubmmited}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="update-category-form"
            >
              <Input
                name="name"
                error={errors["name"]}
                touched={touched["name"]}
                step="any"
                placeholder="Sin espacios"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values["name"]}
                options={{
                  label: "Nombre de la categoría",
                  helperText:
                    "No debe contener espacios o caracteres especiales.",
                  marginBottom: 40,
                }}
              />

              <TextArea
                name="description"
                error={errors["description"]}
                touched={touched["description"]}
                value={values['description']}
                step="any"
                placeholder="Ej: 10"
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={200}
                options={{
                  label: "Descripción de la cateogoría",
                  marginBottom: 40,
                }}
              />
            </form>
          );
        }}
      </Formik>

      <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
        <Button
          disabled={updatedCategoryStates.loading}
          options={{
            type: "outline",
            size: "lg",
            skin: "purple",
          }}
          onClick={() => onClose()}
        >
          Cancelar
        </Button>

        <Submit
          isSubmmiting={updatedCategoryStates.loading}
          options={{
            type: "filled",
            size: "lg",
            skin: "purple",
          }}
          form="update-category-form"
        >
          Actualizar
        </Submit>
      </div>


    </Sidebar>

    <Snackbar
      visible={updatedCategoryStates.success || !!updatedCategoryStates.error}
      options={{
        time: 2000,
        type: updatedCategoryStates.success ? "success" : "error",
      }}
    >
      {updatedCategoryStates.success
        ? "Categoría actualizada."
        : "Error al actualizar la categoría."}
    </Snackbar>
  </>
}

const state = ({ groupStore, categoryStore }) => {
  const { data: categories, states: categoriesState } =
    categoryStore?.allCategories;
  const { data: group, states: groupStates } =
    groupStore.group;
  const { data: updatedCategory, states: updatedCategoryStates } = categoryStore.updateCategory;

  return {
    group,
    groupStates,
    categories,
    categoriesState,
    updatedCategory,
    updatedCategoryStates,
  };
};

export default connect(state)(Component);