import styled from "styled-components";
import { Link as UiLink } from 'react-router-dom'

export const Header = styled.div`
  margin-top: 33px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin-top: 43px;
  }
`;

export const Breadcrumb = styled.div`
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0px 0px 24px 0px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    margin: 0px 0px 36px;
  }
`;

export const TitleTwo = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 767px) {
    min-height: 90px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const TextImg = styled("p")`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

export const Link = styled("a")`
  &:first-child {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-right: 24px;
    text-decoration: none;
    color: ${(props) => props.theme.colors.Primary};

    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      font-size: 16px;
      line-height: 19px;
    }
  }
`;

export const Center = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    flex-direction: row;
  }
`;

export const EmptyStateCard = styled("div")`
  text-decoration: none;
  color: inherit;
  display: block;
  background-color: #e0e4ea;
  overflow: hidden;
  border-radius: 16px;
  min-height: 250px;
  position: relative;
  margin-bottom: 16px;
`;


export const TabsContainer = styled("div")`
  border-bottom: 1px solid #C4C4C4;
  position: sticky;
  top: 0px;
  background-color: white; 
  z-index: 150;
`

export const Tabs = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Tab = styled("div")`
  width: 100%;
  text-align: center;
`;

export const TabButton = styled("button") <{ active?: boolean }>`
  display: block;
  position: relative;
  text-decoration: none;
  color: ${(props) => props.active ? "#000" : "#979797"};
  font-weight: ${(props) => (props.active ? "600" : "500")};
  font-size: 18px;
  line-height: auto;
  padding: 0px 0px 11px;
  background-color: white;
  border: 0;
  width: 100%;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 18px;
    line-height: 22px;
  }

  &:after {
    content: "";
    display: ${(props) => (props.active ? "block" : "none")};
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 5px;
    margin-bottom: -1px;
    border-radius: 2px 2px 0px 0px;
    background-color: ${(props) => props.theme.colors.Primary};
  }
`;

