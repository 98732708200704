import styled from "styled-components";
import { AnimationFixed } from "../../../components/Animation";
import UiSubmit from "../../../components/FormikForm/Submit";
import UiInput from "../../../components/Form/Input";

export const Title = styled("h4")`
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
  line-height: 22px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 22px;
  }
`;

export const SubTitle = styled("p")`
  font-size: 14px;
  margin: 0px;
  line-height: 20px;
  margin-bottom: 24px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin-bottom: 22px;
  }
`;

export const ImageWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 767px) {
    width: 100%;
    height: 180px;
    overflow: hidden;
  }

  @media (min-width: 768px) {
    width: 554px;
  }

  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 13.31%,
      #ffffff 95.2%
    );

    @media (min-width: 768px) {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
`;

export const FormWrapper = styled("div")`
  padding: 32px 24px 24px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 32px 40px 4px 30px;
  }
`;
export const InlineForm = styled("div")`
  display: block;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Input = styled(UiInput)``;

export const Submit = styled(UiSubmit)`
  margin-bottom: 24px;
`;

export const Image = styled("img")`
  display: block;
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
`;

export const Modal = styled("div")`
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  pointer-events: auto;
`;

export const ModalDialog = styled("div") <{ width?}>`
  width: 100%;
  max-width: ${(props) => props.width || 288}px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    max-width: 1074px;
  }
`;

export const ModalContent = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  outline: 0;
  background-color: #fff;
  width: 100%;
  min-height: 48vh;
  overflow: hidden;
  border-radius: 16px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    border-radius: 8px;
    width: auto;
    min-height: 0;
  }
`;

export const ModalBody = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-align: center;
  justify-content: flex-start;
  overflow: hidden;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    flex-direction: row;
    flex: 1 1 auto;
  }
`;

export const ModalAnimation = styled(AnimationFixed)`
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
`;

export const Close = styled("button")`
  background: transparent;
  position: absolute;
  cursor: pointer;
  top: 18px;
  right: 18px;
  z-index: 10;
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 1;

  &:focus {
    outline: transparent;
  }
`;
