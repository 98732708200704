import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Input from "../../../../../../../components/FormikForm/Input";
import { Group } from "../../../../../../../types";

interface ComponentProps {
  groupInformation: Group,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  isLoading: boolean,
}

const Component = ({
  groupInformation,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  isLoading,
}: ComponentProps) => {

  return (
    <>
      {/* <Input
        name="name"
        error={errors["name"]}
        touched={touched["name"]}
        value={values["name"]}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        placeholder={`Nombre de fantasia. EJ: "MercadoPago info@klouser.com"`}
        options={{
          label: "Nombre",
          helperText: "Elije un nombre propio para identificarla.",
          marginBottom: 20,
        }}
        disabled={isLoading}
      /> */}

      <Input
        name="public_key"
        error={errors["public_key"]}
        touched={touched["public_key"]}
        value={values["public_key"]}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        placeholder={`Public Key`}
        options={{
          label: "Clave Pública (Public Key)",
          helperText: "Copia la Clave Pública de tu Integración de MercadoPago.",
          marginBottom: 20,
        }}
        disabled={isLoading}
      />

      <Input
        name="access_token"
        error={errors["access_token"]}
        touched={touched["access_token"]}
        value={values["access_token"]}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        placeholder={`Access Token`}
        options={{
          label: "Token de Acceso (Access Token)",
          helperText: "Copia el Token de Acceso de tu Integración de MercadoPago.",
          marginBottom: 20,
        }}
        disabled={isLoading}
      />

      <Input
        name="client_id"
        error={errors["client_id"]}
        touched={touched["client_id"]}
        value={values["client_id"]}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        placeholder={`Client ID`}
        options={{
          label: "ID del Cliente",
          helperText: "Copia el ID del Cliente de tu Integracion de MercadoPago. Es un codigo que aparece en tu panel de Mercado Pago",
          marginBottom: 20,
        }}
        disabled={isLoading}
      />

      <Input
        name="client_secret"
        error={errors["client_secret"]}
        touched={touched["client_secret"]}
        value={values["client_secret"]}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        placeholder={`Secret Key`}
        options={{
          label: "Clave Secreta",
          helperText: "Copia la Clave Secreta de tu Integración de MercadoPago. ",
          marginBottom: 20,
        }}
        disabled={isLoading}
      />

    </>
  );
};

const state = ({ groupStore }) => {
  const { data: groupInformation } = groupStore.group;

  return {
    groupInformation,
  };
};

export default connect(state)(Component);
