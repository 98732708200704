import Modal from "../../../../../../../components/Modal";
import Button from "../../../../../../../components/Button";
import { Title, Text } from "./styles";
import { connect, useDispatch } from "react-redux";
import Snackbar from '../../../../../../../components/Snackbar'
import { initialValues, schema } from '../../../../../../../constants/forms/welcome-section/new'
import Submit from "../../../../../../../components/FormikForm/Submit";
import { hideModal } from "../../../../../../../store/actions/modal";
import Form from '../Form'
import { CategoryDto } from "../../../../../../../types/category";
import { Center } from "../../../../../../../components/StyledComponents";
import { useEffect, useState } from "react";
import { newWelcomeContentSection, resetNewWelcomeContentSection } from "../../../../../../../store/actions/welcome-content";
import { Formik } from "formik";
import { WelcomeContentSectionDto } from "../../../../../../../types/welcome-section";
import { Group } from "../../../../../../../types";
import remove from "../../../../../../../assets/remove.svg";
import Image from "../../../../../../../components/Image";

interface ComponentProps {
  group: Group
  groupStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  newWelcomeContentSectionCreated: WelcomeContentSectionDto;
  newWelcomeContentSectionCreatedStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

const Component = ({
  group,
  groupStates,
  newWelcomeContentSectionCreated,
  newWelcomeContentSectionCreatedStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [actions, setActions] = useState<{ resetForm: () => void }>()
  const [formSubmmited, setFormSubmmited] = useState(false);

  const onSubmit = ({ values, actions }) => {
    setActions(actions)
    dispatch(newWelcomeContentSection({ ...values, group_id: group?.id }));
  };

  const handleClose = () => {
    dispatch(hideModal())
  }

  useEffect(() => {
    if (newWelcomeContentSectionCreatedStates.success) {
      setTimeout(() => {     
        actions?.resetForm()
        dispatch(resetNewWelcomeContentSection()); 
        handleClose()
      }, 2000);
    }
  }, [newWelcomeContentSectionCreatedStates]);

  return (
    <>
      <Modal name="modal-new-welcome-section" width="650" onClose={() => { }}>

        {newWelcomeContentSectionCreatedStates.success ? (
          <>
            <div style={{ textAlign: "center" }}>
              <Image src={remove} width="102px" height="117px" alt={remove} />
            </div>
            <Text>
              <strong style={{ textAlign: "center", display: "block" }}>
                La Sección fue creada con éxito. <br /> <br /> ¡Ahora puedes agregar contenidos de bienvenida!
              </strong>
            </Text>
          </>
        ) : (
          <>
            <Title>Bienvenida para nuevos suscriptores</Title>

            <div style={{ height: 22 }} />

            <Formik
              initialValues={{ ...initialValues }}
              onSubmit={(values, actions) => {
                if (onSubmit) onSubmit({ values, actions });
              }}
              validateOnChange={false}
              validateOnBlur={formSubmmited}
              validationSchema={schema}
              enableReinitialize
            >
              {({
                touched,
                errors,
                values,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => {
                return (
                  <form
                    className="theme-form"
                    onSubmit={(event) => {
                      setFormSubmmited(true);
                      handleSubmit(event);
                    }}
                    id="new-welcome-section"
                  >
                    <Form
                      touched={touched}
                      errors={errors}
                      values={values}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </form>
                )
              }}
            </Formik>


            <Center horizontal="right" vertical="center" gap={10}>
              <Button
                disabled={newWelcomeContentSectionCreatedStates.loading}
                options={{
                  type: "outline",
                  size: "lg",
                  skin: "purple",
                }}
                onClick={handleClose}
              >
                Cancelar
              </Button>

              <Submit
                isSubmmiting={newWelcomeContentSectionCreatedStates.loading}
                options={{
                  type: "filled",
                  size: "lg",
                  skin: "purple",
                }}
                form="new-welcome-section"
              >
                Crear sección
              </Submit>
            </Center>
          </>
        )}
      </Modal>
    </>
  );
};

const state = ({ groupStore, welcomeContentStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: newWelcomeContentSectionCreated, states: newWelcomeContentSectionCreatedStates } = welcomeContentStore.newWelcomeContentSection;

  return {
    group,
    groupStates,
    newWelcomeContentSectionCreated,
    newWelcomeContentSectionCreatedStates,
  };
};

export default connect(state)(Component);
