import styled from "styled-components";
import { Dot, ButtonBack, ButtonNext } from "pure-react-carousel";

export const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Dots = styled(Dot)`
  margin: 16px 5px 0px 0px;
  background-color: ${(props) => props.theme.colors.Primary};
  border: 1px solid ${(props) => props.theme.colors.Primary};
  height: 16px;
  width: 16px;
  border-radius: 100%;
  display: block;

  &:disabled,
  &:hover,
  &:focus,
  &:active {
    background-color: white;
    border: 1px solid ${(props) => props.theme.colors.Gray9};
  }
`;

export const ButtonContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  margin-right: 12px;
`;

export const ButtonsNext = styled(ButtonNext)`
  right: 14px;
  z-index: 2;
  background: none;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const ButtonsBack = styled(ButtonBack)`
  position: absolute;
  top: 50%;
  left: 14px;
  z-index: 2;
  background: none;
  border: none;
  width: 25px;
  height: 25px;
  transform: translate(0, -50%);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const SlideContent = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 21px;

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 0px;
  }
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-right: 30px;
  margin-left: 24px;
  text-align: center;
  margin-bottom: 26px;

  @media (min-width: 1024px) {
    display: block;
    min-height: 0;
    text-align: left;
    margin-bottom: 0px;
  }

  @media (min-width: 1200px) {
    margin-right: 80px;
  }

  br {
    @media (max-width: 1439px) {
      display: none;
    }
  }
`;

export const Actions = styled("div")`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  flex-shrink: 0;
  width: 100%;

  & > * {
    display: flex;
    justify-content: center;
    width: 100%;

    @media (min-width: 1024px) {
      width: auto;
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    width: auto;
  }
`;
