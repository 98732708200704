import Link from '../../../../../../../components/Link'
import Button from '../../../../../../../components/Button'
import Icon from '../../../../../../../components/Icon'
import Grid from "../../../../../../../components/Grid";
import {
  Card,
  Title,
  Text,
  HorizontalScrollWrapper,
  HorizontalScroll,
  ButtonEdit
} from './styles'
import EditIcon from '../../../../../../../assets/icons/edit-light.svg'
import { connect, useDispatch } from 'react-redux'
import { Action, Dispatch } from 'redux'
import CardAticle from "../../../../../WelcomeContentPost/components/Card";
import ModalConfirmDelete from "../../../../../Posts/components/ModalConfirmDelete";
import NoPostFound from "../../../../../Posts/List/components/NoPostFound";
import EmptyCard from "../../../../../components/EmptyCard";
import { showModal } from '../../../../../../../store/actions/modal'
import { Center } from '../../../../../../../components/StyledComponents'
import MenuIcon from '../../../../../../../assets/icons/menu.svg'
import AddIcon from '../../../../../../../assets/icons/add.svg'
import { useTheme } from 'styled-components'
import slugify from 'slugify';
import { useMemo, useState, useEffect } from 'react';
import { useWindowSize } from '../../../../../../../hooks/useWindowSize';
import LogoLoader from "../../../../../../../components/LogoLoader";

interface Component {
  setShowReorderSidebar,
  group,
  groupStates,
  welcomeContentSection,
  welcomeContentSectionStates,
}

const groupTypes = {
  membership: "membresía",
  experience: "taller",
  free_experience: "evento",
};

const Component = ({
  setShowReorderSidebar,
  group,
  groupStates,
  welcomeContentSection,
  welcomeContentSectionStates
}) => {
  const { isMobile } = useWindowSize()
  const theme = useTheme() as { [key: string]: { [key: string]: string } }
  const dispatch = useDispatch<Dispatch<Action>>()
  const [idArticleToDelete, setIdArticleToDelete] = useState()

  const handleClickEdit = () => {
    dispatch(showModal('modal-edit-welcome-section'))
  }

  const thereArePosts = useMemo(() => {
    return !!welcomeContentSection?.welcome_contents && welcomeContentSection?.welcome_contents?.length > 0
  }, [welcomeContentSection])

  return (
    <>
      <Card>
        <Center horizontal="left" vertical="center" gap={10}>
          <Title>{welcomeContentSection?.title}</Title>
          {!!welcomeContentSection && (
            <ButtonEdit
              onClick={handleClickEdit}
            >
              <Icon icon={EditIcon} size="15px" color={theme.colors?.Primary} />
            </ButtonEdit>
          )}
        </Center>


        <div style={{ height: 8 }} />

        <Text>{welcomeContentSection?.description}</Text>

        <div style={{ height: 24 }} />

        <Center vertical='center' horizontal='left' gap={13} column={isMobile}>
          <Link
            href={`/dashboard/${group?.group_type}/${group?.id}/welcome-content/new`}
            options={{
              skin: 'purple',
              type: 'filled',
              size: 'lg',
              block: isMobile
            }}
            style={{ gap: 10 }}
          >
            <Icon icon={AddIcon} size="15px" color="white" />
            Agregar contenido
          </Link>

          <Button
            onClick={() => setShowReorderSidebar(true)}
            options={{
              skin: 'purple',
              type: 'outline',
              size: 'lg',
              block: isMobile
            }}
            style={{ gap: 10 }}
          >
            <Icon icon={MenuIcon} size="15px" color={theme.colors.Primary} />
            Reordenar contenidos
          </Button>
        </Center>

        <div style={{ height: 24 }} />

        {(!thereArePosts && !welcomeContentSectionStates?.loading) ||
          (((!thereArePosts) ||
            welcomeContentSectionStates?.error) &&
            !welcomeContentSectionStates?.loading) ? (
          <EmptyCard
            title="No tienes artículos en tu sección de bienvenida"
            text={`Crea artículos para ayudar a tus seguidores en sus primeros pasos por tu ${groupTypes[group?.group_type]
              }.`}
          />
        ) : (
          <HorizontalScrollWrapper>
            <HorizontalScroll>
              {welcomeContentSection?.welcome_contents && welcomeContentSection?.welcome_contents.length > 0 ? (
                <>
                  {welcomeContentSection?.welcome_contents?.sort((a, b) => {
                    return a.content_order - b.content_order;
                  }).map((post, index) => {
                    return (
                      <CardAticle
                        key={slugify(post.title + index, { lower: true })}
                        welcomeContentPost={post}
                        onDelete={(idPost) => setIdArticleToDelete(idPost)}
                        groupInformation={group}
                      />
                    )
                  })}
                </>
              ) : (
                <NoPostFound groupType={group?.group_type} />
              )}
            </HorizontalScroll>
          </HorizontalScrollWrapper>
        )}
      </Card>

      <ModalConfirmDelete idArticle={idArticleToDelete} />
    </>
  )
}

const state = ({ groupStore, welcomeContentStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: welcomeContentSection, states: welcomeContentSectionStates } = welcomeContentStore.welcomeContentSection
  return {
    group,
    groupStates,
    welcomeContentSection,
    welcomeContentSectionStates
  };
};

export default connect(state)(Component);