import styled from "styled-components";
import { Link as UiLink } from "react-router-dom";

export const Breadcrumb = styled.div`
  font-size: 12px;
  line-height: 10px;
  background: none;
  font-weight: 300;
  margin: 4px 0px 8px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 16px;
    line-height: 19px;
    margin: 0px 0px 7px;
  }
`;
export const Link = styled(UiLink)`
  font-weight: 300;
  font-size: 12px;
  line-height: 10px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.Primary};

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 16px;
    line-height: 19px;
  }
`;
