import { Post } from "../../../types/post";
export const GET_POST = "GET_POST";
export const GET_POST_SUCCESS = "GET_POST_SUCCESS";
export const GET_POST_ERROR = "GET_POST_ERROR";
export const RESET_GET_POST = "RESET_GET_POST";
export const UPDATE_CURRENT_POST = "UPDATE_CURRENT_POST";

export interface GetPost {
  type: typeof GET_POST;
  payload: { idPost: number };
}

export interface GetPostSuccess {
  type: typeof GET_POST_SUCCESS;
  payload: Post;
}

export interface GetPostError {
  type: typeof GET_POST_ERROR;
  payload: string | boolean;
}

export interface UpdateCurrentPost {
  type: typeof UPDATE_CURRENT_POST;
  payload: Post;
}

export interface ResetGetPost {
  type: typeof RESET_GET_POST;
  payload: null;
}

export interface State {
  data: Post | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions = GetPost | GetPostSuccess | GetPostError | ResetGetPost | UpdateCurrentPost;
