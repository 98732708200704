import { connect, useDispatch } from "react-redux";
import SectionEmptyState from './components/SectionEmptyState'
import SectionContent from './components/SectionContent'
import ModalNewSection from './components/ModalNewSection'
import ModalEditSection from './components/ModalEditSection'
import SidebarReorderContent from './components/SidebarReorderContent'
import SidebarDeleteContent from './components/SidebarDeleteContent'
import Collapse from "../../../../../components/Collpase";
import { useEffect, useState } from "react";
import { Action, Dispatch } from "redux";
import { Center } from "../../../../../components/StyledComponents";
import { Title, Paragraph } from './styles'
import { getWelcomeContentSection } from "../../../../../store/actions/welcome-content";
import { Group } from "../../../../../types";
import { DeleteWelcomeContentPostOutDto } from "../../../../../types/welcome-section";

interface ComponentProps {
  group: Group
  welcomeContentSection
}

const Component = ({ group, welcomeContentSection }: ComponentProps) => {
  const dispatch = useDispatch<Dispatch<Action>>()
  const [showReorderSidebar, setShowReorderSidebar] = useState<boolean>(false)
  const [showDeleteSidebar, setshowDeleteSidebar] = useState<boolean>(false)
  const [selectedContentToBeDeleted, setSelectedContentToBeDeleted] = useState<DeleteWelcomeContentPostOutDto>()

  useEffect(() => {
    console.log('welcome section', group?.welcome_section, welcomeContentSection?.id)
    if (group?.welcome_section && group?.welcome_section !== welcomeContentSection?.id) {
      dispatch(getWelcomeContentSection({ idWelcomeContentSection: group?.welcome_section, filters: { with_contents: true } }))
    }
  }, [group])

  return (
    <>
      <Collapse
        style={{ backgroundColor: 'white', padding: '30px' }}
        toggleIcon=""
        toggleLabel={
          <Center column horizontal="left" vertical="top">
            <Title style={{ margin: 0 }}>Bienvenida para nuevos suscriptores</Title>
            <div style={{ height: 6 }} />
            <Paragraph>Crea una sección de bienvenida clara y atractiva para tus nuevos suscriptores. </Paragraph>
          </Center>
        }
      >
        <div style={{ height: 28 }} />

        {!!group && (
          <>
            {!!welcomeContentSection && (
              <SectionContent setShowReorderSidebar={setShowReorderSidebar} />
            )}

            {!group?.welcome_section && (
              <SectionEmptyState />
            )}
          </>
        )}
        
        <div style={{ height: 26 }} />
      </Collapse>

      <ModalNewSection />

      <ModalEditSection />

      <SidebarReorderContent
        onClose={() => {
          setShowReorderSidebar(!showReorderSidebar)
        }}
        isVisible={showReorderSidebar}
        setSelectedContentToBeDeleted={setSelectedContentToBeDeleted}
        setshowDeleteSidebar={setshowDeleteSidebar}
      />

      <SidebarDeleteContent
        onClose={() => {
          setshowDeleteSidebar(!showDeleteSidebar)
        }}
        isVisible={showDeleteSidebar}
        selectedContentToBeDeleted={selectedContentToBeDeleted}
      />
    </>
  )
}

const state = ({ groupStore, welcomeContentStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: welcomeContentSection, states: welcomeContentSectionStates } = welcomeContentStore.welcomeContentSection;

  return {
    group,
    welcomeContentSection,
  };
};

export default connect(state)(Component);