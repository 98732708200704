import { PaginationOutDto, PaginationInDto } from "../../../types/pagination";
interface Subscriber {
  subscriber_id: number;
  country: string;
  name: string | null;
  last_name: string | null;
  email: string;
  total_investment: number;
  free_experiences_count: number;
  experiences_count: number;
  total_subscriptions_count: number;
  recurring_subscription: {
    id: number;
    status: string;
    started_at: number | null;
    next_billing_at: number | null;
    cancelled_at: number | null;
  };
}

export const GET_COMMUNITY_SUBSCRIBERS_EMAILS =
  "GET_COMMUNITY_SUBSCRIBERS_EMAILS";
export const GET_COMMUNITY_SUBSCRIBERS_EMAILS_SUCCESS =
  "GET_COMMUNITY_SUBSCRIBERS_EMAILS_SUCCESS";
export const GET_COMMUNITY_SUBSCRIBERS_EMAILS_ERROR =
  "GET_COMMUNITY_SUBSCRIBERS_EMAILS_ERROR";

export interface GetCommunitySubscribersEmails {
  type: typeof GET_COMMUNITY_SUBSCRIBERS_EMAILS;
  payload: PaginationOutDto;
}

export interface GetCommunitySubscribersEmailsSuccess {
  type: typeof GET_COMMUNITY_SUBSCRIBERS_EMAILS_SUCCESS;
  payload: {
    data: Subscriber[];
    pagination: PaginationInDto;
  };
}

export interface GetCommunitySubscribersEmailsError {
  type: typeof GET_COMMUNITY_SUBSCRIBERS_EMAILS_ERROR;
  payload: boolean | string;
}

export interface State {
  data: {
    data: Subscriber[];
    pagination: PaginationInDto;
  } | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetCommunitySubscribersEmails
  | GetCommunitySubscribersEmailsError
  | GetCommunitySubscribersEmailsSuccess;
