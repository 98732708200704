import { connect, useDispatch } from "react-redux";
import { Action, Dispatch } from "redux";
import Collapse from "../../../../../components/Collpase";
import Loader from '../../../../../components/Loader'
import Icon from "../../../../../components/Icon"
import { Center } from "../../../../../components/StyledComponents";
import { Group } from "../../../../../types";
import {
  Check, Title, Paragraph, LoaderWrapper,
  LoaderBackground
} from './styles'
import { updateGroup } from "../../../../../store/actions/group";
import LibraryGridLayoutIcon from '../../../../../assets/library-grid-layout.svg'
import LibraryCategoryLayoutIcon from '../../../../../assets/library-category-layout.svg'
import Snackbar from "../../../../../components/Snackbar";
import SpinnerButton from "../../../../../components/SpinnerButton";
import { useEffect, useState } from "react";
import Button from "../../../../../components/Button";
import { useWindowSize } from "../../../../../hooks/useWindowSize";

interface ComponentProps {
  group: Group
  updateGroupStates: {
    loading: boolean
    success: boolean
    error: boolean
  }
}

const Loading = ({ children, isLoading }) => {
  return (
    <LoaderWrapper>
      {children}
      {isLoading && (
        <LoaderBackground >
          <Loader />
        </LoaderBackground>
      )}
    </LoaderWrapper>
  )
}

const Component = ({ group, updateGroupStates }: ComponentProps) => {
  const [layout, setLayout] = useState<string | null>(null)
  const { isMobile } = useWindowSize()
  const dispatch = useDispatch<Dispatch<Action>>()

  useEffect(() => {
    if(!!group && !updateGroupStates.loading){
      setLayout(group?.library_template_view)
    }
  }, [group])

  return (
    <Collapse
      toggleIcon=""
      style={{ backgroundColor: 'white', padding: '30px' }}
      toggleLabel={
        <Center column horizontal="left" vertical="top">
          <Title style={{ margin: 0 }}>Tipo de vista de tu Bibloteca</Title>
          <div style={{ height: 6 }} />
          <Paragraph>Selecciona como quieres que se vea tu bibloteca</Paragraph>
        </Center>
      }
    >
      <div style={{ height: 28 }} />

      <Center column={isMobile} horizontal="left" vertical="top" gap={25} >
        {/* <Loading isLoading={updateGroupStates.loading}> */}
          <Check onClick={() => setLayout('by_category')} active={layout === 'by_category'}>
            <Icon icon={LibraryCategoryLayoutIcon} size={"58px"} color={layout === 'by_category' ? '#E7E5FB' : '#E7E5FB'} />
            <span>Por Categorias</span>
          </Check>
        {/* </Loading> */}

        {/* <Loading isLoading={updateGroupStates.loading}> */}
          <Check onClick={() => setLayout('all_posts')} active={layout === 'all_posts'}>
            <Icon icon={LibraryGridLayoutIcon} size={"58px"} color={layout === 'all_posts' ? '#E7E5FB' : '#E7E5FB'} />
            <span>Orden cronologico</span>
          </Check>
        {/* </Loading> */}
      </Center>

      <div style={{ height: 26 }} />

      {layout && (
        <Paragraph>
          {layout === 'all_posts'
          ? "Los artículos se listan cronológicamente (del más reciente al más antiguo) y los suscriptores pueden aplicar filtros para buscar los artículos."
          : "Los artículos se listan por categorias y tú podrás definir el orden de las categorías y los artículos. Los suscriptores pueden navegar la biblioteca de acuerdo al orden que estableciste."}
        </Paragraph>
      )}

      <div style={{ height: 26 }} />

      <Button
        onClick={() => dispatch(updateGroup({ id: group?.id, library_template_view: layout }))}
        options={{
          type: "filled",
          size: "lg",
          skin: "purple",
        }}
      >
          <SpinnerButton 
            isLoading={!!updateGroupStates.loading }
          >
            Guardar Vista
          </SpinnerButton>

      </Button>

      <div style={{ height: 26 }} />

      <Snackbar
      visible={updateGroupStates?.success || !!updateGroupStates?.error}
      options={{
        time: 2000,
        type: updateGroupStates?.success ? "success" : "error",
      }}
    >
      {updateGroupStates?.success
        ? "Tipo de vista actualizado correctamente"
        : "Error al actualizar el tipo de vista."}
    </Snackbar>
    </Collapse>
  )
}

const state = ({ groupStore, welcomeContentStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: welcomeContentSection, states: welcomeContentSectionStates } = welcomeContentStore.welcomeContentSection;
  const { states: updateGroupStates } = groupStore.updateGroup;

  return {
    group,
    groupStates,
    welcomeContentSection,
    welcomeContentSectionStates,
    updateGroupStates
  };
};

export default connect(state)(Component);