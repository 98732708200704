import { Expert, GetSubscriptionsOutDto, Group } from "../../../types";

export const CREATE_NEW_EXPERT = "CREATE_NEW_EXPERT";
export const CREATE_NEW_EXPERT_SUCCESS = "CREATE_NEW_EXPERT_SUCCESS";
export const CREATE_NEW_EXPERT_ERROR = "CREATE_NEW_EXPERT_ERROR";

export interface CreateNewExpert {
  type: typeof CREATE_NEW_EXPERT;
  payload?: GetSubscriptionsOutDto;
}

export interface CreateNewExpertSuccess {
  type: typeof CREATE_NEW_EXPERT_SUCCESS;
  payload: Group[];
}

export interface CreateNewExpertError {
  type: typeof CREATE_NEW_EXPERT_ERROR;
  payload: boolean | string;
}

export interface State {
  data: Expert | null,
  states: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    error: false,
    success: false,
  },
};

export type Actions =
  | CreateNewExpert
  | CreateNewExpertSuccess
  | CreateNewExpertError;
