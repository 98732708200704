import { createRef, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Grid from "../../Grid";
import constants from "../../../constants";
import Header from "./components/HeaderRainbow";
import Footer from "../../Footer";
import WhatsApp from "../../Whatsapp";
import { GlobalStyles } from "./styles";
import Toolbar from "./components/Toolbar";
import { setCurrentPage } from "../../../store/actions/page";
import PhoneNumberModal from "../components/ModalPhoneNumber";
import ShareLink from "./components/ShareLink";
import { useLayout } from "../../../providers/LayoutProvider";
import { hideModal, showModal } from "../../../store/actions/modal";
import { isSubscribed } from "../../../helpers/isSubscribed";

const Component = ({
  children,
  useLocation,
  userInformation,
  subscriptions,
  groupData
}) => {
  const { layout } = useLayout();
  const location = useLocation();
  const dispatch = useDispatch();
  const footerRef = createRef<HTMLElement>();
  const headerRef = createRef<HTMLElement>();
  const [minHeight, setMinHeight] = useState(0);
  const [currentPageLocally, setCurrentPageLocally] = useState<any>();

  useEffect(() => {
    if (location) {
      const page = constants.pages.filter((page) => {
        return location.pathname.match(page.regex);
      });

      setCurrentPageLocally(page[0]);
      dispatch(setCurrentPage(page[0]));

      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    }
  }, [location]);

  useEffect(() => {
    headerRef.current &&
      footerRef.current &&
      setMinHeight(
        headerRef.current.scrollHeight + footerRef.current.scrollHeight
      );
  }, [headerRef, footerRef]);

  useEffect(() => {
    const isUserSubscribed = isSubscribed(subscriptions, groupData?.id)

    if (
      !!userInformation &&
      !userInformation?.phone_number &&
      isUserSubscribed &&
      currentPageLocally &&
      groupData.group_type !== "free_experience" &&
      currentPageLocally?.name !== "GROUP_DETAILS"
    ) {
      dispatch(showModal("modal-phone-number"));
    } else if (!!userInformation && !!userInformation?.phone_number) {
      //dispatch(hideModal());
    }
  }, [userInformation, subscriptions, currentPageLocally, groupData]);

  return (
    <>
      <GlobalStyles background={layout?.body?.background} />

      {layout.toolbar.visible && <Toolbar />}
      {layout.header.visible && (
        <Grid.Container>
          <Grid.Row>
            <Grid.Col lg={10} offset={{ lg: 1 }}>
              {layout.header.beforeHeader}
              <Header forwardedRef={headerRef} />
              {layout.header.afterHeader}
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      )}

      {children}


      {layout.footer.visible && <Footer forwardedRef={footerRef} />}
      {layout.footer.visible && <WhatsApp floating />}
      <PhoneNumberModal />
    </>
  );
};

const state = ({ userStore, groupStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: subscriptions } = userStore.subscriptions;
  const { data: groupData } = groupStore.group


  return {
    userInformation,
    subscriptions,
    groupData
  };
};

export default connect(state)(Component);
