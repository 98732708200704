import { useEffect, useMemo, useState } from "react";
import { Formik, FormikProps } from "formik";
import { Center } from "../../../../../../components/StyledComponents";
import ArticleForm from "../../modules/Post";
import NotesForm from "../../modules/Notes";
import SurveyForm from "../../modules/Survey";
import TimerForm from "../../modules/Timer";
import Input from "../../../../../../components/FormikForm/Input";
import Submit from "../../../../../../components/FormikForm/Submit";
import Button from "../../../../../../components/Button";
import Select from "../../../../../../components/FormikForm/ReactSelect";
import Autosave from "../AutoSave";
import { Card } from "../../styles";
import {
  schema as postSchema,
  initialValues as postInitialValues,
} from "../../../../../../constants/forms/modules/post";
import {
  schema as messageSchema,
  initialValues as messageInitialValues,
} from "../../../../../../constants/forms/modules/message";
import {
  schema as newSchema,
  initialValues as newInitialValues,
} from "../../../../../../constants/forms/modules/new";
import {
  schema as notesSchema,
  initialValues as notesInitialValues,
} from "../../../../../../constants/forms/modules/notes";
import {
  schema as surveySchema,
  initialValues as surveyInitialValues,
} from "../../../../../../constants/forms/modules/survey";
import {
  schema as timerSchema,
  initialValues as timeInitialValues,
} from "../../../../../../constants/forms/modules/timer";
import { useModules } from "../../../../../../providers/ModulesProvider";
import Loader from "../../../../../../components/Loader";
import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import { getFilesSize } from "../../../../../../helpers/filesSizes";
import { hideModal, showModal } from "../../../../../../store/actions/modal";
import { connect, useDispatch } from "react-redux";

interface ComponentProps { }

const mergeSchemas = (schemas) => {
  const [first, ...rest] = schemas;

  const merged = rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first
  );

  return merged;
};

const Component = ({ }: ComponentProps) => {
  const { isMobile } = useWindowSize()
  const dispatch = useDispatch();
  const { selectedModule, actions } = useModules();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [formName, setFormName] = useState<string>("");

  useEffect(() => {
    if (actions.update.states.success) {
      dispatch(hideModal())
    }

  }, [actions.update.states]);

  // useEffect(() => {
  //   if (actions.update.states.success) {
  //     dispatch(hideModal())
  //   }
  // }, [actions.update.states]);

  const submitForm = ({ values, actions: formActions }) => {
    const formattedValues = {
      title: values.title,
      state: values.state,
      is_valid: Boolean(values.is_valid),
      content_data: values.content_data,
      content_type: values.content_type,
    }

    if ((formattedValues && formattedValues?.content_data?.attached_files && formattedValues?.content_data?.attached_files.length > 0)) {
        dispatch(showModal("modal-wait-a-minute"));
    }


    if (selectedModule) {
      actions?.update?.exec({ ...selectedModule, ...formattedValues });
    }
  };

  const modulesFormsSchemas = {
    post: { schema: postSchema, initialValues: postInitialValues },
    note: { schema: notesSchema, initialValues: notesInitialValues },
    survey: { schema: surveySchema, initialValues: surveyInitialValues },
    message: { schema: messageSchema, initialValues: messageInitialValues },
    timer: { schema: timerSchema, initialValues: timeInitialValues },
  };

  useEffect(() => {
    setFormName("");
    if (selectedModule) {
      setTimeout(() => {
        setFormName(selectedModule?.content_type);
      }, 100);
    }
  }, [selectedModule]);

  return !selectedModule ? (
    <>Selecciona o crea tu primer modulo</>
  ) : (
    <Formik
      initialValues={{
        ...newInitialValues,
        ...(!!selectedModule && selectedModule),
      }}
      onSubmit={(values, actions) => {
        if (submitForm) submitForm({ values, actions });
      }}
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={mergeSchemas([
        newSchema,
        modulesFormsSchemas[selectedModule?.content_type].schema,
      ])}
      enableReinitialize
    >

      {(props) => {
        const {
          values,
          errors,
          touched,
          handleSubmit,
          handleReset,
          handleBlur,
          handleChange,
          setFieldValue,
          isValid,
        } = props;

        const handleFieldBlur = (e) => {
          handleBlur(e);

          if (props.values !== props.initialValues) {
            const values = { ...(props.values as { [key: string]: any }), is_valid: props.isValid }
            submitForm({ values, actions: {} });
          }
        };

        const modulesForms = {
          post: <ArticleForm {...props} handleBlur={handleFieldBlur} />,
          note: <NotesForm {...props} handleBlur={handleFieldBlur} />,
          survey: <SurveyForm {...props} handleBlur={handleFieldBlur} />,
          timer: <TimerForm {...props} handleBlur={handleFieldBlur} />,
        };

        return (
          <form
            name="module-form"
            id="module-form"
            onSubmit={(event) => {
              setFormSubmmited(true);
              handleSubmit(event);
            }}
            style={{
              width: "100%",
            }}
          >
            <Center horizontal="center" vertical="top" column gap={16}>
              <Card style={{ padding: "18px 24px 24px" }}>
                <Center horizontal="space" vertical="top" gap={8} column={isMobile}>
                  <Input
                    name="title"
                    type="text"
                    placeholder="Nombre"
                    value={values["title"]}
                    touched={touched["title"]}
                    error={errors["title"]}
                    disabled={false}
                    onBlur={handleFieldBlur}
                    onChange={handleChange}
                    options={{
                      label: "Nombre del módulo",
                      marginBottom: 0,
                    }}
                  />

                  <Select
                    name="content_type"
                    touched={touched["content_type"]}
                    error={errors["content_type"]}
                    disabled={Object.keys(values.content_data).length > 0}
                    onBlur={handleFieldBlur}
                    onChange={handleChange}
                    items={[
                      {
                        value: "post",
                        label: "Artículo",
                      },
                      {
                        value: "note",
                        label: "Notas (próximamente)",
                        disabled: true
                      },
                      {
                        value: "survey",
                        label: "Encuesta (próximamente)",
                        disabled: true
                      },
                    ]}
                    isOptionDisabled={(option) => option.disabled}
                    options={{
                      label: "Tipo de módulo",
                      marginBottom: 0,
                    }}
                  />
                </Center>
              </Card>

              {/* <Card style={{ padding: "18px 24px 24px" }}>
                <TimerForm {...props} />
              </Card> */}

              <Card style={{ padding: "18px 24px 24px" }}>
                {formName === "" && (
                  <Center
                    horizontal="center"
                    vertical="center"
                    style={{ minHeight: "712px" }}
                    column
                  >
                    <Loader color="#5c8fe7" />
                  </Center>
                )}

                {modulesForms[formName]}
              </Card>
            </Center>

            <div style={{ height: 24 }} />

            <Center horizontal="right" vertical="center" gap={8}>
              {/* <Button
                options={{
                  type: "outline",
                  skin: "lightBlue",
                  size: "lg",
                }}
                onClick={() => handleReset()}
                style={{ minWidth: 200 }}
              >
                Cancelar
              </Button> */}

              <Submit
                isSubmmiting={actions.update.states.loading}
                form="module-form"
                options={{
                  type: "filled",
                  skin: "lightBlue",
                  size: "lg",
                }}
                style={{ minWidth: 200 }}
              >
                Guardar
              </Submit>
            </Center>

            {/* <Autosave callback={submitForm} /> */}
          </form>
        );
      }}
    </Formik>
  );
};

export default Component;
