import {
  GetAllNotificationsOutDto,
  GetAllNotificationsInDto,
} from "../../../types/notification";

export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const GET_ALL_NOTIFICATIONS_SUCCESS = "GET_ALL_NOTIFICATIONS_SUCCESS";
export const GET_ALL_NOTIFICATIONS_ERROR = "GET_ALL_NOTIFICATIONS_ERROR";
export const RESET_GET_ALL_NOTIFICATIONS = "RESET_GET_ALL_NOTIFICATIONS";

export interface GetAllNotifications {
  type: typeof GET_ALL_NOTIFICATIONS;
  payload: GetAllNotificationsOutDto;
}

export interface GetAllNotificationsSuccess {
  type: typeof GET_ALL_NOTIFICATIONS_SUCCESS;
  payload: GetAllNotificationsInDto | null;
}

export interface GetAllNotificationsError {
  type: typeof GET_ALL_NOTIFICATIONS_ERROR;
  payload: boolean | string;
}

export interface ResetGetAllNotifications {
  type: typeof RESET_GET_ALL_NOTIFICATIONS;
  payload: null;
}

export interface State {
  data: GetAllNotificationsInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetAllNotifications
  | GetAllNotificationsSuccess
  | GetAllNotificationsError
  | ResetGetAllNotifications;
