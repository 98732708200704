import Grid from "../../../../../components/Grid";
import {
  Experts,
  Title,
  ExpertsList,
  Expert,
  Picture,
  Name,
  Experience,
} from "./styles";

const Component = () => {
  return (
    <Experts id="expertos">
      <Grid.Container>
        <Grid.Row>
          <Grid.Col lg={10} offset={{ lg: 1 }}>
            <Title>
              Conoce a los Expertos <br />
              que participaran
            </Title>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col lg={8} offset={{ lg: 2 }}>
            <ExpertsList>
              <Expert>
                <Picture />
                <Name>Leandro Rosenthal</Name>
                <Experience>CEO</Experience>
              </Expert>

              <Expert>
                <Picture />
                <Name>Alex Vaisberg</Name>
                <Experience>CTO</Experience>
              </Expert>

              <Expert>
                <Picture />
                <Name>Valu</Name>
                <Experience>Grosa en todo</Experience>
              </Expert>

              <Expert>
                <Picture />
                <Name>Pip</Name>
                <Experience>Otro groso en todo</Experience>
              </Expert>
            </ExpertsList>

            <ExpertsList>
              <Expert>
                <Picture />
                <Name>Leandro Rosenthal</Name>
                <Experience>CEO</Experience>
              </Expert>

              <Expert>
                <Picture />
                <Name>Alex Vaisberg</Name>
                <Experience>CTO</Experience>
              </Expert>

              <Expert>
                <Picture />
                <Name>Valu</Name>
                <Experience>Grosa en todo</Experience>
              </Expert>

              <Expert>
                <Picture />
                <Name>Pip</Name>
                <Experience>Otro groso en todo</Experience>
              </Expert>
            </ExpertsList>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Experts>
  );
};

export default Component;
