import {
  Card,
  CardHeader,
  Title,
  CardBody,
  List,
  ListItem,
  Name,
  Email,
  Copy,
  EmptyState,
} from "./styles";
import Icon from "../../../../../../components/Icon";
import CopyTextToClipboad from "../../../../../../components/CopyToClipboad";
import Pagination from "../../../../../../components/Pagination";
import Button from "../../../../../../components/Button";
import Loader from "../../../../../../components/Loader";
import services from "../../../../../../services";
import copyIcon from "../../../../../../assets/icons/copy.svg";

interface ComponentProps {
  groupId?: number;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  data: {
    country: string;
    email: string;
    last_name: string;
    name: string;
    purchased_groups_count: string;
    status;
    transactions_count;
    transactions_sum;
    started_at;
    currency;
  }[];
  pagination: {
    currentPage: string;
    from: number;
    lastPage: number;
    perPage: string;
    to: number;
    total: number;
  };
  goToPage: (page: number) => void;
}

const Component = ({
  groupId,
  states,
  data,
  pagination,
  goToPage,
}: ComponentProps) => {
  const paginate = (page) => {
    !!goToPage && goToPage(page);
  };

  return (
    <Card>
      <CardHeader>
        <Title>Miembros del evento ({pagination?.total})</Title>

        <Button
          type="button"
          options={{ type: "link", skin: "purple", size: "md" }}
          onClick={() => {
            groupId
              ? services.group.exportSubscribers(groupId, null)
              : services.community.exportSubscribers();
          }}
          style={{ marginLeft: "10px" }}
        >
          Exportar
        </Button>
      </CardHeader>

      <CardBody>
        {states.loading && (!data || data.length <= 0) && <Loader />}

        {!states.loading && (!data || data.length <= 0) && (
          <EmptyState key="empty-state-member-list">
            Aún no tienes suscriptores.
          </EmptyState>
        )}

        {!states.loading && data?.length > 0 && (
          <List>
            {data?.map((item, index) => (
              <ListItem>
                <Name>
                  {item.name} {item.last_name}
                </Name>
                <Email>
                  {item.email}

                  <Copy>
                    <CopyTextToClipboad
                      copy={item.email}
                      showChildren
                      successStyle={{
                        position: "absolute",
                        left: "1%",
                        top: "50%",
                        transform: "translate(0%, -50%)",
                      }}
                    >
                      <Icon
                        icon={copyIcon}
                        size="20px"
                        color="rgba(45, 52, 54, 0.75)"
                      />
                    </CopyTextToClipboad>
                  </Copy>
                </Email>
              </ListItem>
            ))}
          </List>
        )}

        {!states.loading && data?.length > 0 && (
          <Pagination
            style={{ width: "100%" }}
            {...pagination}
            states={states}
            onClick={(page) => paginate(page)}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default Component;
