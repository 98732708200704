import styled from "styled-components";

export const Progress = styled('span')`
  font-weight: 600;
  font-size: 14px;
  color: #12B47A;

  @media(min-width: 1024px){
    font-size: 18px;
  }
`

export const ProgressBarContainer = styled('div')`
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: rgba(45, 52, 54, .5);
`

export const ProgressBar = styled('div')`
  height: 8px;
  border-radius: 4px;
  background-color: rgb(20 180 121);
`