import flagAF from "./flags/flag-AF.png";
import flagAX from "./flags/flag-AX.png";
import flagAL from "./flags/flag-AL.png";
import flagDZ from "./flags/flag-DZ.png";
import flagAS from "./flags/flag-AS.png";
import flagAD from "./flags/flag-AD.png";
import flagAO from "./flags/flag-AO.png";
import flagAI from "./flags/flag-AI.png";
import flagAG from "./flags/flag-AG.png";
import flagAR from "./flags/flag-AR.png";
import flagAM from "./flags/flag-AM.png";
import flagAW from "./flags/flag-AW.png";
import flagAU from "./flags/flag-AU.png";
import flagAT from "./flags/flag-AT.png";
import flagAZ from "./flags/flag-AZ.png";
import flagBS from "./flags/flag-BS.png";
import flagBH from "./flags/flag-BH.png";
import flagBD from "./flags/flag-BD.png";
import flagBB from "./flags/flag-BB.png";
import flagBY from "./flags/flag-BY.png";
import flagBE from "./flags/flag-BE.png";
import flagBJ from "./flags/flag-BJ.png";
import flagBZ from "./flags/flag-BZ.png";
import flagBM from "./flags/flag-BM.png";
import flagBT from "./flags/flag-BT.png";
import flagBO from "./flags/flag-BO.png";
import flagBA from "./flags/flag-BA.png";
import flagBW from "./flags/flag-BW.png";
import flagBV from "./flags/flag-BV.png";
import flagBR from "./flags/flag-BR.png";
import flagIO from "./flags/flag-IO.png";
import flagBN from "./flags/flag-BN.png";
import flagBG from "./flags/flag-BG.png";
import flagBF from "./flags/flag-BF.png";
import flagBI from "./flags/flag-BI.png";
import flagKH from "./flags/flag-KH.png";
import flagCM from "./flags/flag-CM.png";
import flagCA from "./flags/flag-CA.png";
import flagCV from "./flags/flag-CV.png";
import flagKY from "./flags/flag-KY.png";
import flagCF from "./flags/flag-CF.png";
import flagTD from "./flags/flag-TD.png";
import flagCL from "./flags/flag-CL.png";
import flagCN from "./flags/flag-CN.png";
import flagCX from "./flags/flag-CX.png";
import flagCC from "./flags/flag-CC.png";
import flagCO from "./flags/flag-CO.png";
import flagKM from "./flags/flag-KM.png";
import flagCG from "./flags/flag-CG.png";
import flagCD from "./flags/flag-CD.png";
import flagCK from "./flags/flag-CK.png";
import flagCR from "./flags/flag-CR.png";
import flagCI from "./flags/flag-CI.png";
import flagHR from "./flags/flag-HR.png";
import flagCU from "./flags/flag-CU.png";
import flagCY from "./flags/flag-CY.png";
import flagCZ from "./flags/flag-CZ.png";
import flagDK from "./flags/flag-DK.png";
import flagDJ from "./flags/flag-DJ.png";
import flagDM from "./flags/flag-DM.png";
import flagEG from "./flags/flag-EG.png";
import flagEC from "./flags/flag-EC.png";
import flagSV from "./flags/flag-SV.png";
import flagGQ from "./flags/flag-GQ.png";
import flagER from "./flags/flag-ER.png";
import flagEE from "./flags/flag-EE.png";
import flagET from "./flags/flag-ET.png";
import flagFK from "./flags/flag-FK.png";
import flagFO from "./flags/flag-FO.png";
import flagFJ from "./flags/flag-FJ.png";
import flagFI from "./flags/flag-FI.png";
import flagFR from "./flags/flag-FR.png";
import flagGF from "./flags/flag-GF.png";
import flagPF from "./flags/flag-PF.png";
import flagGA from "./flags/flag-GA.png";
import flagGM from "./flags/flag-GM.png";
import flagGE from "./flags/flag-GE.png";
import flagDE from "./flags/flag-DE.png";
import flagGH from "./flags/flag-GH.png";
import flagGI from "./flags/flag-GI.png";
import flagGR from "./flags/flag-GR.png";
import flagGL from "./flags/flag-GL.png";
import flagGD from "./flags/flag-GD.png";
import flagGP from "./flags/flag-GP.png";
import flagGU from "./flags/flag-GU.png";
import flagGT from "./flags/flag-GT.png";
import flagGG from "./flags/flag-GG.png";
import flagGN from "./flags/flag-GN.png";
import flagGW from "./flags/flag-GW.png";
import flagGY from "./flags/flag-GY.png";
import flagHT from "./flags/flag-HT.png";
import flagHN from "./flags/flag-HN.png";
import flagHK from "./flags/flag-HK.png";
import flagHU from "./flags/flag-HU.png";
import flagIS from "./flags/flag-IS.png";
import flagIN from "./flags/flag-IN.png";
import flagID from "./flags/flag-ID.png";
import flagIR from "./flags/flag-IR.png";
import flagIQ from "./flags/flag-IQ.png";
import flagIE from "./flags/flag-IE.png";
import flagIM from "./flags/flag-IM.png";
import flagIL from "./flags/flag-IL.png";
import flagIT from "./flags/flag-IT.png";
import flagJM from "./flags/flag-JM.png";
import flagJP from "./flags/flag-JP.png";
import flagJE from "./flags/flag-JE.png";
import flagJO from "./flags/flag-JO.png";
import flagKZ from "./flags/flag-KZ.png";
import flagKE from "./flags/flag-KE.png";
import flagKI from "./flags/flag-KI.png";
import flagKP from "./flags/flag-KP.png";
import flagKR from "./flags/flag-KR.png";
import flagXK from "./flags/flag-XK.png";
import flagKW from "./flags/flag-KW.png";
import flagKG from "./flags/flag-KG.png";
import flagLA from "./flags/flag-LA.png";
import flagLV from "./flags/flag-LV.png";
import flagLB from "./flags/flag-LB.png";
import flagLS from "./flags/flag-LS.png";
import flagLR from "./flags/flag-LR.png";
import flagLY from "./flags/flag-LY.png";
import flagLI from "./flags/flag-LI.png";
import flagLT from "./flags/flag-LT.png";
import flagLU from "./flags/flag-LU.png";
import flagMO from "./flags/flag-MO.png";
import flagMK from "./flags/flag-MK.png";
import flagMG from "./flags/flag-MG.png";
import flagMW from "./flags/flag-MW.png";
import flagMY from "./flags/flag-MY.png";
import flagMV from "./flags/flag-MV.png";
import flagML from "./flags/flag-ML.png";
import flagMT from "./flags/flag-MT.png";
import flagMH from "./flags/flag-MH.png";
import flagMQ from "./flags/flag-MQ.png";
import flagMR from "./flags/flag-MR.png";
import flagMU from "./flags/flag-MU.png";
import flagYT from "./flags/flag-YT.png";
import flagMX from "./flags/flag-MX.png";
import flagFM from "./flags/flag-FM.png";
import flagMD from "./flags/flag-MD.png";
import flagMC from "./flags/flag-MC.png";
import flagMN from "./flags/flag-MN.png";
import flagME from "./flags/flag-ME.png";
import flagMS from "./flags/flag-MS.png";
import flagMA from "./flags/flag-MA.png";
import flagMZ from "./flags/flag-MZ.png";
import flagMM from "./flags/flag-MM.png";
import flagNA from "./flags/flag-NA.png";
import flagNR from "./flags/flag-NR.png";
import flagNP from "./flags/flag-NP.png";
import flagNL from "./flags/flag-NL.png";
import flagNC from "./flags/flag-NC.png";
import flagNZ from "./flags/flag-NZ.png";
import flagNI from "./flags/flag-NI.png";
import flagNE from "./flags/flag-NE.png";
import flagNG from "./flags/flag-NG.png";
import flagNU from "./flags/flag-NU.png";
import flagNF from "./flags/flag-NF.png";
import flagMP from "./flags/flag-MP.png";
import flagNO from "./flags/flag-NO.png";
import flagOM from "./flags/flag-OM.png";
import flagPK from "./flags/flag-PK.png";
import flagPW from "./flags/flag-PW.png";
import flagPS from "./flags/flag-PS.png";
import flagPA from "./flags/flag-PA.png";
import flagPG from "./flags/flag-PG.png";
import flagPY from "./flags/flag-PY.png";
import flagPE from "./flags/flag-PE.png";
import flagPH from "./flags/flag-PH.png";
import flagPL from "./flags/flag-PL.png";
import flagPT from "./flags/flag-PT.png";
import flagPR from "./flags/flag-PR.png";
import flagQA from "./flags/flag-QA.png";
import flagRO from "./flags/flag-RO.png";
import flagRU from "./flags/flag-RU.png";
import flagRW from "./flags/flag-RW.png";
import flagBL from "./flags/flag-BL.png";
import flagSH from "./flags/flag-SH.png";
import flagKN from "./flags/flag-KN.png";
import flagLC from "./flags/flag-LC.png";
import flagMF from "./flags/flag-MF.png";
import flagPM from "./flags/flag-PM.png";
import flagVC from "./flags/flag-VC.png";
import flagWS from "./flags/flag-WS.png";
import flagSM from "./flags/flag-SM.png";
import flagST from "./flags/flag-ST.png";
import flagSA from "./flags/flag-SA.png";
import flagSN from "./flags/flag-SN.png";
import flagRS from "./flags/flag-RS.png";
import flagSC from "./flags/flag-SC.png";
import flagSL from "./flags/flag-SL.png";
import flagSG from "./flags/flag-SG.png";
import flagSK from "./flags/flag-SK.png";
import flagSI from "./flags/flag-SI.png";
import flagSB from "./flags/flag-SB.png";
import flagSO from "./flags/flag-SO.png";
import flagZA from "./flags/flag-ZA.png";
import flagSS from "./flags/flag-SS.png";
import flagES from "./flags/flag-ES.png";
import flagLK from "./flags/flag-LK.png";
import flagSD from "./flags/flag-SD.png";
import flagSR from "./flags/flag-SR.png";
import flagSJ from "./flags/flag-SJ.png";
import flagSZ from "./flags/flag-SZ.png";
import flagSE from "./flags/flag-SE.png";
import flagCH from "./flags/flag-CH.png";
import flagSY from "./flags/flag-SY.png";
import flagTW from "./flags/flag-TW.png";
import flagTJ from "./flags/flag-TJ.png";
import flagTZ from "./flags/flag-TZ.png";
import flagTH from "./flags/flag-TH.png";
import flagTL from "./flags/flag-TL.png";
import flagTG from "./flags/flag-TG.png";
import flagTK from "./flags/flag-TK.png";
import flagTO from "./flags/flag-TO.png";
import flagTT from "./flags/flag-TT.png";
import flagTN from "./flags/flag-TN.png";
import flagTR from "./flags/flag-TR.png";
import flagTM from "./flags/flag-TM.png";
import flagTC from "./flags/flag-TC.png";
import flagTV from "./flags/flag-TV.png";
import flagUG from "./flags/flag-UG.png";
import flagUA from "./flags/flag-UA.png";
import flagAE from "./flags/flag-AE.png";
import flagGB from "./flags/flag-GB.png";
import flagUS from "./flags/flag-US.png";
import flagUY from "./flags/flag-UY.png";
import flagUZ from "./flags/flag-UZ.png";
import flagVU from "./flags/flag-VU.png";
import flagVE from "./flags/flag-VE.png";
import flagVN from "./flags/flag-VN.png";
import flagWF from "./flags/flag-WF.png";
import flagYE from "./flags/flag-YE.png";
import flagZM from "./flags/flag-ZM.png";
import flagZW from "./flags/flag-ZW.png";
import flagDO from "./flags/flag-DO.png";

const countries = [
  {
    enabled: true,
    ru: "Афганистан",
    lt: "Afganistanas",
    tr: "Afganistan",
    en: "Afghanistan",
    es: "Afghanistan",
    flagIcon: "🇦🇫",
    currency: {
      iso: "",
      label: "",
    },
    code: "AF",
    flag: flagAF,
    dialCode: "93",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "Аландские острова",
    lt: "Alandų salos",
    tr: "Aland adaları",
    en: "Åland Islands",
    es: "Åland Islands",
    flagIcon: "🇦🇽",
    currency: {
      iso: "",
      label: "",
    },
    code: "AX",
    flag: flagAX,
    dialCode: "358",
    mask: "999 9999999",
  },
  {
    enabled: true,
    ru: "Албания",
    lt: "Albanija",
    tr: "Arnavutluk",
    en: "Albania",
    es: "Albania",
    flagIcon: "🇦🇱",
    currency: {
      iso: "",
      label: "",
    },
    code: "AL",
    flag: flagAL,
    dialCode: "355",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "Алжир",
    lt: "Alžyras",
    tr: "Cezayir",
    en: "Algeria",
    es: "Algeria",
    flagIcon: "🇩🇿",
    currency: {
      iso: "",
      label: "",
    },
    code: "DZ",
    flag: flagDZ,
    dialCode: "213",
    mask: "9999 99 99 99",
  },
  {
    enabled: true,
    ru: "американское Самоа",
    lt: "Amerikos Samoa",
    tr: "Amerikan Samoası",
    en: "American Samoa",
    es: "American Samoa",
    flagIcon: "🇦🇸",
    currency: {
      iso: "",
      label: "",
    },
    code: "AS",
    flag: flagAS,
    dialCode: "1684",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "андорра",
    lt: "Andora",
    tr: "Andorra",
    en: "Andorra",
    es: "Andorra",
    flagIcon: "🇦🇩",
    currency: {
      iso: "",
      label: "",
    },
    code: "AD",
    flag: flagAD,
    dialCode: "376",
    mask: "999 999",
  },
  {
    enabled: true,
    ru: "Ангола",
    lt: "Angoloje",
    tr: "Angora",
    en: "Angola",
    es: "Angola",
    flagIcon: "🇦🇴",
    currency: {
      iso: "",
      label: "",
    },
    code: "AO",
    flag: flagAO,
    dialCode: "244",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Ангилья",
    lt: "Angilija",
    tr: "Anguilla",
    en: "Anguilla",
    es: "Anguilla",
    flagIcon: "🇦🇮",
    currency: {
      iso: "",
      label: "",
    },
    code: "AI",
    flag: flagAI,
    dialCode: "1264",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Антигуа и Барбуда",
    lt: "Antigva ir Barbuda",
    tr: "Antigua ve Barbuda",
    en: "Antigua and Barbuda",
    es: "Antigua and Barbuda",
    flagIcon: "🇦🇬",
    currency: {
      iso: "",
      label: "",
    },
    code: "AG",
    flag: flagAG,
    dialCode: "1268",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Аргентина",
    lt: "Argentina",
    tr: "Arjantin",
    en: "Argentina",
    es: "Argentina",
    mainTimezone: "America/Argentina/Buenos_Aires",
    flagIcon: "🇦🇷",
    currency: {
      iso: "ARS",
      label: "ar$",
      description: "Pesos argentinos",
    },
    code: "AR",
    flag: flagAR,
    dialCode: "54",
    featured: true,
    mask: "99 9999-9999",
  },
  {
    enabled: true,
    ru: "Армения",
    lt: "Armėnija",
    tr: "Ermenistan",
    en: "Armenia",
    es: "Armenia",
    flagIcon: "🇦🇲",
    currency: {
      iso: "",
      label: "",
    },
    code: "AM",
    flag: flagAM,
    dialCode: "374",
    mask: "999 999999",
  },
  {
    enabled: true,
    ru: "Аруба",
    lt: "Aruba",
    tr: "Aruba",
    en: "Aruba",
    es: "Aruba",
    flagIcon: "🇦🇼",
    currency: {
      iso: "",
      label: "",
    },
    code: "AW",
    flag: flagAW,
    dialCode: "297",
    mask: "999 9999",
  },
  {
    enabled: true,
    ru: "Австралия",
    lt: "Australija",
    tr: "Avustralya",
    en: "Australia",
    es: "Australia",
    flagIcon: "🇦🇺",
    currency: {
      iso: "",
      label: "",
    },
    code: "AU",
    flag: flagAU,
    dialCode: "61",
    mask: "9999 999 999",
  },
  {
    enabled: true,
    ru: "Австрия",
    lt: "Austrijoje",
    tr: "Avusturya",
    en: "a",
    es: "Austria",
    flagIcon: "🇦🇹",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "AT",
    flag: flagAT,
    dialCode: "43",
    mask: "9999 999999",
  },
  {
    enabled: true,
    ru: "Азербайджан",
    lt: "Azerbaidžanas",
    tr: "Azerbeycan",
    en: "Azerbaijan",
    es: "Azerbaijan",
    flagIcon: "🇦🇿",
    currency: {
      iso: "",
      label: "",
    },
    code: "AZ",
    flag: flagAZ,
    dialCode: "994",
    mask: "999 999 99 99",
  },
  {
    enabled: true,
    ru: "Багамские о-ва",
    lt: "Bahamos",
    tr: "Bahamalar",
    en: "Bahamas",
    es: "Bahamas",
    flagIcon: "🇧🇸",
    currency: {
      iso: "",
      label: "",
    },
    code: "BS",
    flag: flagBS,
    dialCode: "1242",
    mask: "(999) 999-999",
  },
  {
    enabled: true,
    ru: "Бахрейн",
    lt: "Bahreinas",
    tr: "Bahreyn",
    en: "Bahrain",
    es: "Bahrain",
    flagIcon: "🇧🇭",
    currency: {
      iso: "",
      label: "",
    },
    code: "BH",
    flag: flagBH,
    dialCode: "973",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Бангладеш",
    lt: "Bangladešas",
    tr: "Bangladeş",
    en: "Bangladesh",
    es: "Bangladesh",
    flagIcon: "🇧🇩",
    currency: {
      iso: "",
      label: "",
    },
    code: "BD",
    flag: flagBD,
    dialCode: "880",
    mask: "99999-999999",
  },
  {
    enabled: true,
    ru: "Барбадос",
    lt: "Barbadosas",
    tr: "Barbados",
    en: "Barbados",
    es: "Barbados",
    flagIcon: "🇧🇧",
    currency: {
      iso: "",
      label: "",
    },
    code: "BB",
    flag: flagBB,
    dialCode: "1246",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Беларусь",
    lt: "Baltarusija",
    tr: "Belarus",
    en: "Belarus",
    es: "Belarus",
    flagIcon: "🇧🇾",
    currency: {
      iso: "",
      label: "",
    },
    code: "BY",
    flag: flagBY,
    dialCode: "375",
    mask: "9 999 999-99-99",
  },
  {
    enabled: true,
    ru: "Бельгия",
    lt: "Belgija",
    tr: "Belçika",
    en: "Belgium",
    es: "Belgium",
    flagIcon: "🇧🇪",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "BE",
    flag: flagBE,
    dialCode: "32",
    mask: "9999 99 99 99",
  },
  {
    enabled: true,
    ru: "Белиз",
    lt: "Belizas",
    tr: "Belize",
    en: "Belize",
    es: "Belize",
    flagIcon: "🇧🇿",
    currency: {
      iso: "",
      label: "",
    },
    code: "BZ",
    flag: flagBZ,
    dialCode: "501",
    mask: "999-9999",
  },
  {
    enabled: true,
    ru: "Бенин",
    lt: "Beninas",
    tr: "Benin",
    en: "Benin",
    es: "Benin",
    flagIcon: "🇧🇯",
    currency: {
      iso: "",
      label: "",
    },
    code: "BJ",
    flag: flagBJ,
    dialCode: "229",
    mask: "99 99 99 99",
  },
  {
    enabled: true,
    ru: "Бермудские острова",
    lt: "Bermudai",
    tr: "Bermuda",
    en: "Bermuda",
    es: "Bermuda",
    flagIcon: "🇧🇲",
    currency: {
      iso: "",
      label: "",
    },
    code: "BM",
    flag: flagBM,
    dialCode: "1441",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Бутан",
    lt: "Butanas",
    tr: "Butan",
    en: "Bhutan",
    es: "Bhutan",
    flagIcon: "🇧🇹",
    currency: {
      iso: "",
      label: "",
    },
    code: "BT",
    flag: flagBT,
    dialCode: "975",
    mask: "99 99 99 99",
  },
  {
    enabled: true,
    ru: "Боливия, Многонациональное Государство Боливия",
    lt: "Bolivija, Bolivijos daugiakalbė valstybė",
    tr: "Bolivya, Bolivya Çokuluslu Devleti",
    en: "Bolivia, Plurinational State of bolivia",
    es: "Bolivia, Plurinational State of bolivia",
    featured: true,
    flagIcon: "🇧🇴",
    currency: {
      iso: "",
      label: "",
    },
    code: "BO",
    flag: flagBO,
    dialCode: "591",
    mask: "99999999",
  },
  {
    enabled: true,
    ru: "Босния и Герцеговина",
    lt: "Bosnija ir Hercegovina",
    tr: "Bosna Hersek",
    en: "Bosnia and Herzegovina",
    es: "Bosnia and Herzegovina",
    flagIcon: "🇧🇦",
    currency: {
      iso: "",
      label: "",
    },
    code: "BA",
    flag: flagBA,
    dialCode: "387",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Ботсвана",
    lt: "Botsvana",
    tr: "Botsvana",
    en: "Botswana",
    es: "Botswana",
    flagIcon: "🇧🇼",
    currency: {
      iso: "",
      label: "",
    },
    code: "BW",
    flag: flagBW,
    dialCode: "267",
    mask: "99 999 999",
  },
  {
    enabled: true,
    ru: "Остров Буве",
    lt: "Bouvet sala",
    tr: "Bouvet Adası",
    en: "Bouvet Island",
    es: "Bouvet Island",
    flagIcon: "🇧🇻",
    currency: {
      iso: "",
      label: "",
    },
    code: "BV",
    flag: flagBV,
    dialCode: "47",
    mask: "(99) 99999-9999",
  },
  {
    enabled: true,
    ru: "Бразилия",
    lt: "Brazilija",
    tr: "Brezilya",
    mainTimezone: "America/Sao_Paulo",
    en: "Brazil",
    es: "Brazil",
    featured: true,
    flagIcon: "🇧🇷",
    currency: {
      iso: "",
      label: "",
    },
    code: "BR",
    flag: flagBR,
    dialCode: "55",
    mask: "(99) 99999 9999",
  },
  {
    enabled: true,
    ru: "Британская территория Индийского океана",
    lt: "Britanijos Indijos vandenyno teritorija",
    tr: "Britanya Hint Okyanusu Toprakları",
    en: "British Indian Ocean Territory",
    es: "British Indian Ocean Territory",
    flagIcon: "🇮🇴",
    currency: {
      iso: "",
      label: "",
    },
    code: "IO",
    flag: flagIO,
    dialCode: "246",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Бруней-Даруссалам",
    lt: "Brunėjaus Darusalamas",
    tr: "Brunei Sultanlığı",
    en: "Brunei Darussalam",
    es: "Brunei Darussalam",
    flagIcon: "🇧🇳",
    currency: {
      iso: "",
      label: "",
    },
    code: "BN",
    flag: flagBN,
    dialCode: "673",
    mask: "999 9999",
  },
  {
    enabled: true,
    ru: "Болгария",
    lt: "Bulgarija",
    tr: "Bulgaristan",
    en: "Bulgaria",
    es: "Bulgaria",
    flagIcon: "🇧🇬",
    currency: {
      iso: "",
      label: "",
    },
    code: "BG",
    flag: flagBG,
    dialCode: "359",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Буркина-Фасо",
    lt: "Burkina Fasas",
    tr: "Burkina Faso",
    en: "Burkina Faso",
    es: "Burkina Faso",
    flagIcon: "🇧🇫",
    currency: {
      iso: "",
      label: "",
    },
    code: "BF",
    flag: flagBF,
    dialCode: "226",
    mask: "99 99 99 99",
  },
  {
    enabled: true,
    ru: "Бурунди",
    lt: "Burundis",
    tr: "Burundi",
    en: "Burundi",
    es: "Burundi",
    flagIcon: "🇧🇮",
    currency: {
      iso: "",
      label: "",
    },
    code: "BI",
    flag: flagBI,
    dialCode: "257",
    mask: "99 99 99 99",
  },
  {
    enabled: true,
    ru: "Камбоджа",
    lt: "Kambodža",
    tr: "Kamboçya",
    en: "Cambodia",
    es: "Cambodia",
    flagIcon: "🇰🇭",
    currency: {
      iso: "",
      label: "",
    },
    code: "KH",
    flag: flagKH,
    dialCode: "855",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Камерун",
    lt: "Kamerūnas",
    tr: "Kamerun",
    en: "Cameroon",
    es: "Cameroon",
    flagIcon: "🇨🇲",
    currency: {
      iso: "",
      label: "",
    },
    code: "CM",
    flag: flagCM,
    dialCode: "237",
    mask: "9 99 99 99 99",
  },
  {
    enabled: true,
    ru: "Канада",
    lt: "Kanada",
    tr: "Kanada",
    en: "Canada",
    es: "Canada",
    flagIcon: "🇨🇦",
    currency: {
      iso: "",
      label: "",
    },
    code: "CA",
    flag: flagCA,
    dialCode: "1",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Кабо-Верде",
    lt: "Žaliasis Kyšulys",
    tr: "Yeşil Burun Adaları",
    en: "Cape Verde",
    es: "Cape Verde",
    flagIcon: "🇨🇻",
    currency: {
      iso: "",
      label: "",
    },
    code: "CV",
    flag: flagCV,
    dialCode: "238",
    mask: "999 99 99",
  },
  {
    enabled: true,
    ru: "Каймановы острова",
    lt: "Kaimanų salos",
    tr: "Cayman Adaları",
    en: "Cayman Islands",
    es: "Cayman Islands",
    flagIcon: "🇰🇾",
    currency: {
      iso: "",
      label: "",
    },
    code: "KY",
    flag: flagKY,
    dialCode: "345",
    mask: "999 9999",
  },
  {
    enabled: true,
    ru: "Центрально-Африканская Республика",
    lt: "Centrine Afrikos Respublika",
    tr: "Orta Afrika Cumhuriyeti",
    en: "Central African Republic",
    es: "Central African Republic",
    flagIcon: "🇨🇫",
    currency: {
      iso: "",
      label: "",
    },
    code: "CF",
    flag: flagCF,
    dialCode: "236",
    mask: "99 99 99 99",
  },
  {
    enabled: true,
    ru: "Чад",
    lt: "Čadas",
    tr: "Çad",
    en: "Chad",
    es: "Chad",
    flagIcon: "🇹🇩",
    currency: {
      iso: "",
      label: "",
    },
    code: "TD",
    flag: flagTD,
    dialCode: "235",
    mask: "99 99 99 99",
  },
  {
    enabled: true,
    ru: "Чили",
    lt: "Čilė",
    tr: "Şili",
    en: "Chile",
    es: "Chile",
    mainTimezone: "Chile/Continental",
    flagIcon: "🇨🇱",
    currency: {
      iso: "",
      label: "",
    },
    code: "CL",
    flag: flagCL,
    dialCode: "56",
    featured: true,
    mask: "(9) 9999 9999",
  },
  {
    enabled: true,
    ru: "Китай",
    lt: "Kinija",
    tr: "Çin",
    en: "China",
    es: "China",
    flagIcon: "🇨🇳",
    currency: {
      iso: "",
      label: "",
    },
    code: "CN",
    flag: flagCN,
    dialCode: "86",
    mask: "999 9999 9999",
  },
  {
    enabled: true,
    ru: "Остров Рождества",
    lt: "Kalėdų sala",
    tr: "Noel Adası",
    en: "Christmas Island",
    es: "Christmas Island",
    flagIcon: "🇨🇽",
    currency: {
      iso: "",
      label: "",
    },
    code: "CX",
    flag: flagCX,
    dialCode: "61",
    mask: "9999 999 999",
  },
  {
    enabled: true,
    ru: "Кокосовые (Килинг) острова",
    lt: "Kokosų (Kylingo) salos",
    tr: "Cocos (Keeling) Adaları",
    en: "Cocos (Keeling) Islands",
    es: "Cocos (Keeling) Islands",
    flagIcon: "🇨🇨",
    currency: {
      iso: "",
      label: "",
    },
    code: "CC",
    flag: flagCC,
    dialCode: "61",
    mask: "9999 999 999",
  },
  {
    enabled: true,
    ru: "Колумбия",
    lt: "Kolumbija",
    tr: "Kolombiya",
    en: "Colombia",
    es: "Colombia",
    featured: true,
    flagIcon: "🇨🇴",
    currency: {
      iso: "",
      label: "",
    },
    code: "CO",
    flag: flagCO,
    dialCode: "57",
    mask: "999 9999999",
  },
  {
    enabled: true,
    ru: "Коморские острова",
    lt: "Komorai",
    tr: "Komorlar",
    en: "Comoros",
    es: "Comoros",
    flagIcon: "🇰🇲",
    currency: {
      iso: "",
      label: "",
    },
    code: "KM",
    flag: flagKM,
    dialCode: "269",
    mask: "999 99 99",
  },
  {
    enabled: true,
    ru: "Конго",
    lt: "Kongas",
    tr: "Kongo",
    en: "Congo",
    es: "Congo",
    flagIcon: "🇨🇬",
    currency: {
      iso: "",
      label: "",
    },
    code: "CG",
    flag: flagCG,
    dialCode: "242",
    mask: "9999 999 999",
  },
  {
    enabled: true,
    ru: "Конго, Демократическая Республика Конго",
    lt: "Kongo Demokratinė Kongo Respublika",
    tr: "Kongo, Demokratik Kongo Cumhuriyeti",
    en: "Congo, The Democratic Republic of the Congo",
    es: "Congo, The Democratic Republic of the Congo",
    flagIcon: "🇨🇩",
    currency: {
      iso: "",
      label: "",
    },
    code: "CD",
    flag: flagCD,
    dialCode: "243",
    mask: "99 999 9999",
  },
  {
    enabled: true,
    ru: "Острова Кука",
    lt: "Kuko salos",
    tr: "Cook Adaları",
    en: "Cook Islands",
    es: "Cook Islands",
    flagIcon: "🇨🇰",
    currency: {
      iso: "",
      label: "",
    },
    code: "CK",
    flag: flagCK,
    dialCode: "682",
    mask: "99 999",
  },
  {
    enabled: true,
    ru: "Коста Рика",
    lt: "Kosta Rika",
    tr: "Kosta Rika",
    en: "Costa Rica",
    es: "Costa Rica",
    flagIcon: "🇨🇷",
    currency: {
      iso: "",
      label: "",
    },
    code: "CR",
    flag: flagCR,
    dialCode: "506",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Берег Слоновой Кости",
    lt: "Dramblio Kaulo Krantas",
    tr: "Fildişi Sahili",
    en: "Cote d'Ivoire",
    es: "Cote d'Ivoire",
    flagIcon: "🇨🇮",
    currency: {
      iso: "",
      label: "",
    },
    code: "CI",
    flag: flagCI,
    dialCode: "225",
    mask: "99 99 99 99",
  },
  {
    enabled: true,
    ru: "Хорватия",
    lt: "Kroatija",
    tr: "Hırvatistan",
    en: "Croatia",
    es: "Croatia",
    flagIcon: "🇭🇷",
    currency: {
      iso: "",
      label: "",
    },
    code: "HR",
    flag: flagHR,
    dialCode: "385",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "Куба",
    lt: "Kuba",
    tr: "Küba",
    en: "Cuba",
    es: "Cuba",
    flagIcon: "🇨🇺",
    currency: {
      iso: "",
      label: "",
    },
    code: "CU",
    flag: flagCU,
    dialCode: "53",
    mask: "99 9999999",
  },
  {
    enabled: true,
    ru: "Кипр",
    lt: "Kipras",
    tr: "Kıbrıs",
    en: "Cyprus",
    es: "Cyprus",
    flagIcon: "🇨🇾",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "CY",
    flag: flagCY,
    dialCode: "357",
    mask: "99 999999",
  },
  {
    enabled: true,
    ru: "Республика Чехия",
    lt: "Čekijos Respublika",
    tr: "Çek Cumhuriyeti",
    en: "Czech Republic",
    es: "Czech Republic",
    flagIcon: "🇨🇿",
    currency: {
      iso: "",
      label: "",
    },
    code: "CZ",
    flag: flagCZ,
    dialCode: "420",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Дания",
    lt: "Danija",
    tr: "Danimarka",
    en: "Denmark",
    es: "Denmark",
    flagIcon: "🇩🇰",
    currency: {
      iso: "",
      label: "",
    },
    code: "DK",
    flag: flagDK,
    dialCode: "45",
    mask: "99 99 99 99",
  },
  {
    enabled: true,
    ru: "Джибути",
    lt: "Džibutis",
    tr: "Cibuti",
    en: "Djibouti",
    es: "Djibouti",
    flagIcon: "🇩🇯",
    currency: {
      iso: "",
      label: "",
    },
    code: "DJ",
    flag: flagDJ,
    dialCode: "253",
    mask: "99 99 99 99",
  },
  {
    enabled: true,
    ru: "Доминика",
    lt: "Dominika",
    tr: "Dominika",
    en: "Dominica",
    es: "Dominica",
    flagIcon: "🇩🇲",
    currency: {
      iso: "",
      label: "",
    },
    code: "DM",
    flag: flagDM,
    dialCode: "1767",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Эквадор",
    lt: "Ekvadoras",
    tr: "Ekvador",
    en: "Ecuador",
    es: "Ecuador",
    featured: true,
    flagIcon: "🇪🇨",
    currency: {
      iso: "",
      label: "",
    },
    code: "EC",
    flag: flagEC,
    dialCode: "593",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "Египет",
    lt: "Egiptas",
    tr: "Mısır",
    en: "Egypt",
    es: "Egypt",
    flagIcon: "🇪🇬",
    currency: {
      iso: "",
      label: "",
    },
    code: "EG",
    flag: flagEG,
    dialCode: "20",
    mask: "9999 999 9999",
  },

  {
    enabled: true,
    ru: "Сальвадор",
    lt: "Salvadoras",
    tr: "El Salvador",
    en: "El Salvador",
    es: "El Salvador",
    flagIcon: "🇸🇻",
    currency: {
      iso: "",
      label: "",
    },
    code: "SV",
    flag: flagSV,
    dialCode: "503",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Экваториальная Гвинея",
    lt: "Pusiaujo Gvinėja",
    tr: "Ekvator Ginesi",
    en: "Equatorial Guinea",
    es: "Equatorial Guinea",
    flagIcon: "🇬🇶",
    currency: {
      iso: "",
      label: "",
    },
    code: "GQ",
    flag: flagGQ,
    dialCode: "240",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Эритрея",
    lt: "Eritrėja",
    tr: "Eritre",
    en: "Eritrea",
    es: "Eritrea",
    flagIcon: "🇪🇷",
    currency: {
      iso: "",
      label: "",
    },
    code: "ER",
    flag: flagER,
    dialCode: "291",
    mask: "99 999 999",
  },
  {
    enabled: true,
    ru: "Эстония",
    lt: "Estija",
    tr: "Estonya",
    en: "Estonia",
    es: "Estonia",
    flagIcon: "🇪🇪",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "EE",
    flag: flagEE,
    dialCode: "372",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Эфиопия",
    lt: "Etiopija",
    tr: "Etiyopya",
    en: "Ethiopia",
    es: "Ethiopia",
    flagIcon: "🇪🇹",
    currency: {
      iso: "",
      label: "",
    },
    code: "ET",
    flag: flagET,
    dialCode: "251",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "Фолклендские (Мальвинские) острова",
    lt: "Folklando salos (Malvinai)",
    tr: "Falkland Adaları (Malvinas)",
    en: "Falkland Islands (Malvinas)",
    es: "Falkland Islands (Malvinas)",
    flagIcon: "🇫🇰",
    currency: {
      iso: "",
      label: "",
    },
    code: "FK",
    flag: flagFK,
    dialCode: "500",
    mask: "99999",
  },
  {
    enabled: true,
    ru: "Фарерские острова",
    lt: "Farerų salos",
    tr: "Faroe Adaları",
    en: "Faroe Islands",
    es: "Faroe Islands",
    flagIcon: "🇫🇴",
    currency: {
      iso: "",
      label: "",
    },
    code: "FO",
    flag: flagFO,
    dialCode: "298",
    mask: "999999",
  },
  {
    enabled: true,
    ru: "Фиджи",
    lt: "Fidžis",
    tr: "Fiji",
    en: "Fiji",
    es: "Fiji",
    flagIcon: "🇫🇯",
    currency: {
      iso: "",
      label: "",
    },
    code: "FJ",
    flag: flagFJ,
    dialCode: "679",
    mask: "999 9999",
  },
  {
    enabled: true,
    ru: "Финляндия",
    lt: "Suomija",
    tr: "Finlandiya",
    en: "Finland",
    es: "Finland",
    flagIcon: "🇫🇮",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "FI",
    flag: flagFI,
    dialCode: "358",
    mask: "999 9999999",
  },
  {
    enabled: true,
    ru: "Франция",
    lt: "Prancūzijoje",
    tr: "Fransa",
    en: "France",
    es: "France",
    flagIcon: "🇫🇷",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "FR",
    flag: flagFR,
    dialCode: "33",
    mask: "99 99 99 99 99",
  },
  {
    enabled: true,
    ru: "Французская Гвиана",
    lt: "Prancūzijos Gviana",
    tr: "Fransız Guyanası",
    en: "French Guiana",
    es: "French Guiana",
    flagIcon: "🇬🇫",
    currency: {
      iso: "",
      label: "",
    },
    code: "GF",
    flag: flagGF,
    dialCode: "594",
    mask: "9999 99 99 99",
  },
  {
    enabled: true,
    ru: "Французская Полинезия",
    lt: "Prancūzų Polinezija",
    tr: "Fransız Polinezyası",
    en: "French Polynesia",
    es: "French Polynesia",
    flagIcon: "🇵🇫",
    currency: {
      iso: "",
      label: "",
    },
    code: "PF",
    flag: flagPF,
    dialCode: "689",
    mask: "99 99 99 99",
  },
  {
    enabled: true,
    ru: "Габон",
    lt: "Gabonas",
    tr: "Gabon",
    en: "Gabon",
    es: "Gabon",
    flagIcon: "🇬🇦",
    currency: {
      iso: "",
      label: "",
    },
    code: "GA",
    flag: flagGA,
    dialCode: "241",
    mask: "99 99 99 99",
  },
  {
    enabled: true,
    ru: "Гамбия",
    lt: "Gambija",
    tr: "Gambiya",
    en: "Gambia",
    es: "Gambia",
    flagIcon: "🇬🇲",
    currency: {
      iso: "",
      label: "",
    },
    code: "GM",
    flag: flagGM,
    dialCode: "220",
    mask: "999 9999",
  },
  {
    enabled: true,
    ru: "Грузия",
    lt: "Gruzijoje",
    tr: "Gürcistan",
    en: "Georgia",
    es: "Georgia",
    flagIcon: "🇬🇪",
    currency: {
      iso: "",
      label: "",
    },
    code: "GE",
    flag: flagGE,
    dialCode: "995",
    mask: "999 99 99 99",
  },
  {
    enabled: true,
    ru: "Германия",
    lt: "Vokietija",
    tr: "Almanya",
    en: "Germany",
    es: "Germany",
    flagIcon: "🇩🇪",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "DE",
    flag: flagDE,
    dialCode: "49",
    mask: "99999 9999999",
  },
  {
    enabled: true,
    ru: "Гана",
    lt: "Gana",
    tr: "Gana",
    en: "Ghana",
    es: "Ghana",
    flagIcon: "🇬🇭",
    currency: {
      iso: "",
      label: "",
    },
    code: "GH",
    flag: flagGH,
    dialCode: "233",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "Гибралтар",
    lt: "Gibraltaras",
    tr: "Cebelitarık",
    en: "Gibraltar",
    es: "Gibraltar",
    flagIcon: "🇬🇮",
    currency: {
      iso: "",
      label: "",
    },
    code: "GI",
    flag: flagGI,
    dialCode: "350",
    mask: "99999999",
  },
  {
    enabled: true,
    ru: "Греция",
    lt: "Graikija",
    tr: "Yunanistan",
    en: "Greece",
    es: "Greece",
    flagIcon: "🇬🇷",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "GR",
    flag: flagGR,
    dialCode: "30",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "Гренландия",
    lt: "Grenlandija",
    tr: "Grönland",
    en: "Greenland",
    es: "Greenland",
    flagIcon: "🇬🇱",
    currency: {
      iso: "",
      label: "",
    },
    code: "GL",
    flag: flagGL,
    dialCode: "299",
    mask: "99 99 99",
  },
  {
    enabled: true,
    ru: "Гренада",
    lt: "Grenada",
    tr: "Grenada",
    en: "Grenada",
    es: "Grenada",
    flagIcon: "🇬🇩",
    currency: {
      iso: "",
      label: "",
    },
    code: "GD",
    flag: flagGD,
    dialCode: "1473",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Гваделупа",
    lt: "Gvadelupa",
    tr: "Guadeloupe",
    en: "Guadeloupe",
    es: "Guadeloupe",
    flagIcon: "🇬🇵",
    currency: {
      iso: "",
      label: "",
    },
    code: "GP",
    flag: flagGP,
    dialCode: "590",
    mask: "9999 99 99 99",
  },
  {
    enabled: true,
    ru: "Гуам",
    lt: "Guamas",
    tr: "Guam",
    en: "Guam",
    es: "Guam",
    flagIcon: "🇬🇺",
    currency: {
      iso: "",
      label: "",
    },
    code: "GU",
    flag: flagGU,
    dialCode: "1671",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Гватемала",
    lt: "Gvatemala",
    tr: "Guatemala",
    en: "Guatemala",
    es: "Guatemala",
    flagIcon: "🇬🇹",
    currency: {
      iso: "",
      label: "",
    },
    code: "GT",
    flag: flagGT,
    dialCode: "502",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "шерстяная фуфайка",
    lt: "Gernsis",
    tr: "bir tür inek",
    en: "Guernsey",
    es: "Guernsey",
    flagIcon: "🇬🇬",
    currency: {
      iso: "",
      label: "",
    },
    code: "GG",
    flag: flagGG,
    dialCode: "44",
    mask: "99999 999999",
  },
  {
    enabled: true,
    ru: "Гвинея",
    lt: "Gvinėjos",
    tr: "Gine",
    en: "Guinea",
    es: "Guinea",
    flagIcon: "🇬🇳",
    currency: {
      iso: "",
      label: "",
    },
    code: "GN",
    flag: flagGN,
    dialCode: "224",
    mask: "999 99 99 99",
  },
  {
    enabled: true,
    ru: "Гвинея-Бисау",
    lt: "Bisau Gvinėja",
    tr: "Gine-Bissau",
    en: "Guinea-Bissau",
    es: "Guinea-Bissau",
    flagIcon: "🇬🇼",
    currency: {
      iso: "",
      label: "",
    },
    code: "GW",
    flag: flagGW,
    dialCode: "245",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Гайана",
    lt: "Gajana",
    tr: "Guyana",
    en: "Guyana",
    es: "Guyana",
    flagIcon: "🇬🇾",
    currency: {
      iso: "",
      label: "",
    },
    code: "GY",
    flag: flagGY,
    dialCode: "592",
    mask: "999 9999",
  },
  {
    enabled: true,
    ru: "Гаити",
    lt: "Haitis",
    tr: "Haiti",
    en: "Haiti",
    es: "Haiti",
    flagIcon: "🇭🇹",
    currency: {
      iso: "",
      label: "",
    },
    code: "HT",
    flag: flagHT,
    dialCode: "509",
    mask: "99 99 9999",
  },
  {
    enabled: true,
    ru: "Гондурас",
    lt: "Hondūras",
    tr: "Honduras",
    en: "Honduras",
    es: "Honduras",
    flagIcon: "🇭🇳",
    currency: {
      iso: "",
      label: "",
    },
    code: "HN",
    flag: flagHN,
    dialCode: "504",
    mask: "9999-9999",
  },
  {
    enabled: true,
    ru: "Гонконг",
    lt: "Honkongas",
    tr: "Hong Kong",
    en: "Hong Kong",
    es: "Hong Kong",
    flagIcon: "🇭🇰",
    currency: {
      iso: "",
      label: "",
    },
    code: "HK",
    flag: flagHK,
    dialCode: "852",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Венгрия",
    lt: "Vengrija",
    tr: "Macaristan",
    en: "Hungary",
    es: "Hungary",
    flagIcon: "🇭🇺",
    currency: {
      iso: "",
      label: "",
    },
    code: "HU",
    flag: flagHU,
    dialCode: "36",
    mask: "(99) 999 9999",
  },
  {
    enabled: true,
    ru: "Исландия",
    lt: "Islandija",
    tr: "İzlanda",
    en: "Iceland",
    es: "Iceland",
    flagIcon: "🇮🇸",
    currency: {
      iso: "",
      label: "",
    },
    code: "IS",
    flag: flagIS,
    dialCode: "354",
    mask: "999 9999",
  },
  {
    enabled: true,
    ru: "Индия",
    lt: "Indija",
    tr: "Hindistan",
    en: "India",
    es: "India",
    flagIcon: "🇮🇳",
    currency: {
      iso: "",
      label: "",
    },
    code: "IN",
    flag: flagIN,
    dialCode: "91",
    mask: "99999 99999",
  },
  {
    enabled: true,
    ru: "Индонезия",
    lt: "Indonezija",
    tr: "Endonezya",
    en: "Indonesia",
    es: "Indonesia",
    flagIcon: "🇮🇩",
    currency: {
      iso: "",
      label: "",
    },
    code: "ID",
    flag: flagID,
    dialCode: "62",
    mask: "9999-999-999",
  },
  {
    enabled: true,
    ru: "Иран, Исламская Республика Персидского залива",
    lt: "Iranas, Persijos įlankos Islamo Respublika",
    tr: "İran, Basra Körfezi İslam Cumhuriyeti",
    en: "Iran, Islamic Republic of Persian Gulf",
    es: "Iran, Islamic Republic of Persian Gulf",
    flagIcon: "🇮🇷",
    currency: {
      iso: "",
      label: "",
    },
    code: "IR",
    flag: flagIR,
    dialCode: "98",
    mask: "9999 999 9999",
  },
  {
    enabled: true,
    ru: "Ирак",
    lt: "Irakas",
    tr: "Irak",
    en: "Iraq",
    es: "Iraq",
    flagIcon: "🇮🇶",
    currency: {
      iso: "",
      label: "",
    },
    code: "IQ",
    flag: flagIQ,
    dialCode: "964",
    mask: "9999 999 9999",
  },
  {
    enabled: true,
    ru: "Ирландия",
    lt: "Airija",
    tr: "İrlanda",
    en: "Ireland",
    es: "Ireland",
    flagIcon: "🇮🇪",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "IE",
    flag: flagIE,
    dialCode: "353",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "Остров Мэн",
    lt: "Meno sala",
    tr: "Man Adası",
    en: "Isle of Man",
    es: "Isle of Man",
    flagIcon: "🇮🇲",
    currency: {
      iso: "",
      label: "",
    },
    code: "IM",
    flag: flagIM,
    dialCode: "44",
    mask: "99999 999999",
  },
  {
    enabled: true,
    ru: "Израиль",
    lt: "Izraelis",
    tr: "İsrail",
    en: "Israel",
    es: "Israel",
    flagIcon: "🇮🇱",
    currency: {
      iso: "",
      label: "",
    },
    code: "IL",
    flag: flagIL,
    dialCode: "972",
    mask: "999-999-9999",
  },
  {
    enabled: true,
    ru: "Италия",
    lt: "Italijoje",
    tr: "İtalya",
    en: "Italy",
    es: "Italy",
    featured: true,
    flagIcon: "🇮🇹",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "IT",
    flag: flagIT,
    dialCode: "39",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "Ямайка",
    lt: "Jamaika",
    tr: "Jamaika",
    en: "Jamaica",
    es: "Jamaica",
    flagIcon: "🇯🇲",
    currency: {
      iso: "",
      label: "",
    },
    code: "JM",
    flag: flagJM,
    dialCode: "1876",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Япония",
    lt: "Japonija",
    tr: "Japonya",
    en: "Japan",
    es: "Japan",
    flagIcon: "🇯🇵",
    currency: {
      iso: "",
      label: "",
    },
    code: "JP",
    flag: flagJP,
    dialCode: "81",
    mask: "999-9999-9999",
  },
  {
    enabled: true,
    ru: "Джерси",
    lt: "Džersis",
    tr: "Jersey",
    en: "Jersey",
    es: "Jersey",
    flagIcon: "🇯🇪",
    currency: {
      iso: "",
      label: "",
    },
    code: "JE",
    flag: flagJE,
    dialCode: "44",
    mask: "99999 999999",
  },
  {
    enabled: true,
    ru: "Иордания",
    lt: "Jordanija",
    tr: "Ürdün",
    en: "Jordan",
    es: "Jordan",
    flagIcon: "🇯🇴",
    currency: {
      iso: "",
      label: "",
    },
    code: "JO",
    flag: flagJO,
    dialCode: "962",
    mask: "99 9999 9999",
  },
  {
    enabled: true,
    ru: "Казахстан",
    lt: "Kazachstanas",
    tr: "Kazakistan",
    en: "Kazakhstan",
    es: "Kazakhstan",
    flagIcon: "🇰🇿",
    currency: {
      iso: "",
      label: "",
    },
    code: "KZ",
    flag: flagKZ,
    dialCode: "7",
    mask: "9 (999) 999 9999",
  },
  {
    enabled: true,
    ru: "Кения",
    lt: "Kenija",
    tr: "Kenya",
    en: "Kenya",
    es: "Kenya",
    flagIcon: "🇰🇪",
    currency: {
      iso: "",
      label: "",
    },
    code: "KE",
    flag: flagKE,
    dialCode: "254",
    mask: "9999 999999",
  },
  {
    enabled: true,
    ru: "Кирибати",
    lt: "Kiribatis",
    tr: "Kiribati",
    en: "Kiribati",
    es: "Kiribati",
    flagIcon: "🇰🇮",
    currency: {
      iso: "",
      label: "",
    },
    code: "KI",
    flag: flagKI,
    dialCode: "686",
    mask: "99999999",
  },
  {
    enabled: true,
    ru: "Корея, Корейская Народно-Демократическая Республика",
    lt: "Korėja, Korėjos Liaudies Demokratinė Respublika",
    tr: "Kore, Kore Demokratik Halk Cumhuriyeti",
    en: "Korea, Democratic People's Republic of Korea",
    es: "Korea, Democratic People's Republic of Korea",
    flagIcon: "🇰🇵",
    currency: {
      iso: "",
      label: "",
    },
    code: "KP",
    flag: flagKP,
    dialCode: "850",
    mask: "(9) 99999",
  },
  {
    enabled: true,
    ru: "Корея, Республика Южная Корея",
    lt: "Korėja, Pietų Korėjos Respublika",
    tr: "Güney Kore Cumhuriyeti",
    en: "Korea, Republic of South Korea",
    es: "Korea, Republic of South Korea",
    flagIcon: "🇰🇷",
    currency: {
      iso: "",
      label: "",
    },
    code: "KR",
    flag: flagKR,
    dialCode: "82",
    mask: "999-9999-9999",
  },
  {
    enabled: true,
    ru: "Косово",
    lt: "Kosovas",
    tr: "Kosova",
    en: "Kosovo",
    es: "Kosovo",
    flagIcon: "🇽🇰",
    currency: {
      iso: "",
      label: "",
    },
    code: "XK",
    flag: flagXK,
    dialCode: "383",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Кувейт",
    lt: "Kuveitas",
    tr: "Kuveyt",
    en: "Kuwait",
    es: "Kuwait",
    flagIcon: "🇰🇼",
    currency: {
      iso: "",
      label: "",
    },
    code: "KW",
    flag: flagKW,
    dialCode: "965",
    mask: "999 99999",
  },
  {
    enabled: true,
    ru: "Киргизия",
    lt: "Kirgizija",
    tr: "Kırgızistan",
    en: "Kyrgyzstan",
    es: "Kyrgyzstan",
    flagIcon: "🇰🇬",
    currency: {
      iso: "",
      label: "",
    },
    code: "KG",
    flag: flagKG,
    dialCode: "996",
    mask: "9999 999 999",
  },
  {
    enabled: true,
    ru: "Лаос",
    lt: "Laosas",
    tr: "Laos",
    en: "Laos",
    es: "Laos",
    flagIcon: "🇱🇦",
    currency: {
      iso: "",
      label: "",
    },
    code: "LA",
    flag: flagLA,
    dialCode: "856",
    mask: "999 99 999 999",
  },
  {
    enabled: true,
    ru: "Латвия",
    lt: "Latvijoje",
    tr: "Letonya",
    en: "Latvia",
    es: "Latvia",
    flagIcon: "🇱🇻",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "LV",
    flag: flagLV,
    dialCode: "371",
    mask: "99 999 999",
  },
  {
    enabled: true,
    ru: "Ливан",
    lt: "Libanas",
    tr: "Lübnan",
    en: "Lebanon",
    es: "Lebanon",
    flagIcon: "🇱🇧",
    currency: {
      iso: "",
      label: "",
    },
    code: "LB",
    flag: flagLB,
    dialCode: "961",
    mask: "99 999 999",
  },
  {
    enabled: true,
    ru: "Лесото",
    lt: "Lesotas",
    tr: "Lesotho",
    en: "Lesotho",
    es: "Lesotho",
    flagIcon: "🇱🇸",
    currency: {
      iso: "",
      label: "",
    },
    code: "LS",
    flag: flagLS,
    dialCode: "266",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Либерия",
    lt: "Liberija",
    tr: "Liberya",
    en: "Liberia",
    es: "Liberia",
    flagIcon: "🇱🇷",
    currency: {
      iso: "",
      label: "",
    },
    code: "LR",
    flag: flagLR,
    dialCode: "231",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "Ливийская арабская джамахирия",
    lt: "Libijos arabų Jamahiriya",
    tr: "Libya Arap Jamahiriya",
    en: "Libyan Arab Jamahiriya",
    es: "Libyan Arab Jamahiriya",
    flagIcon: "🇱🇾",
    currency: {
      iso: "",
      label: "",
    },
    code: "LY",
    flag: flagLY,
    dialCode: "218",
    mask: "999-9999999",
  },
  {
    enabled: true,
    ru: "Лихтенштейн",
    lt: "Lichtenšteinas",
    tr: "Lihtenştayn",
    en: "Liechtenstein",
    es: "Liechtenstein",
    flagIcon: "🇱🇮",
    currency: {
      iso: "",
      label: "",
    },
    code: "LI",
    flag: flagLI,
    dialCode: "423",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Литва",
    lt: "Lietuva",
    tr: "Litvanya",
    en: "Lithuania",
    es: "Lithuania",
    flagIcon: "🇱🇹",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "LT",
    flag: flagLT,
    dialCode: "370",
    mask: "(9-999) 9999",
  },
  {
    enabled: true,
    ru: "Люксембург",
    lt: "Liuksemburgas",
    tr: "Lüksemburg",
    en: "Luxembourg",
    es: "Luxembourg",
    flagIcon: "🇱🇺",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "LU",
    flag: flagLU,
    dialCode: "352",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Macao",
    lt: "Makao",
    tr: "Macao",
    en: "Macao",
    es: "Macao",
    flagIcon: "🇲🇴",
    currency: {
      iso: "",
      label: "",
    },
    code: "MO",
    flag: flagMO,
    dialCode: "853",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Македония",
    lt: "Makedonija",
    tr: "Makedonya",
    en: "Macedonia",
    es: "Macedonia",
    flagIcon: "🇲🇰",
    currency: {
      iso: "",
      label: "",
    },
    code: "MK",
    flag: flagMK,
    dialCode: "389",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Мадагаскар",
    lt: "Madagaskaras",
    tr: "Madagaskar",
    en: "Madagascar",
    es: "Madagascar",
    flagIcon: "🇲🇬",
    currency: {
      iso: "",
      label: "",
    },
    code: "MG",
    flag: flagMG,
    dialCode: "261",
    mask: "999 99 999 99",
  },
  {
    enabled: true,
    ru: "Малави",
    lt: "Malavis",
    tr: "Malawi",
    en: "Malawi",
    es: "Malawi",
    flagIcon: "🇲🇼",
    currency: {
      iso: "",
      label: "",
    },
    code: "MW",
    flag: flagMW,
    dialCode: "265",
    mask: "9999 99 99 99",
  },
  {
    enabled: true,
    ru: "Малайзия",
    lt: "Malaizija",
    tr: "Malezya",
    en: "Malaysia",
    es: "Malaysia",
    flagIcon: "🇲🇾",
    currency: {
      iso: "",
      label: "",
    },
    code: "MY",
    flag: flagMY,
    dialCode: "60",
    mask: "999-999 9999",
  },
  {
    enabled: true,
    ru: "Мальдивы",
    lt: "Maldyvai",
    tr: "Maldivler",
    en: "Maldives",
    es: "Maldives",
    flagIcon: "🇲🇻",
    currency: {
      iso: "",
      label: "",
    },
    code: "MV",
    flag: flagMV,
    dialCode: "960",
    mask: "999-9999",
  },
  {
    enabled: true,
    ru: "Мали",
    lt: "Malis",
    tr: "Mali",
    en: "Mali",
    es: "Mali",
    flagIcon: "🇲🇱",
    currency: {
      iso: "",
      label: "",
    },
    code: "ML",
    flag: flagML,
    dialCode: "223",
    mask: "99 99 99 99",
  },
  {
    enabled: true,
    ru: "Мальта",
    lt: "Malta",
    tr: "Malta",
    en: "Malta",
    es: "Malta",
    flagIcon: "🇲🇹",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "MT",
    flag: flagMT,
    dialCode: "356",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Маршалловы острова",
    lt: "Maršalo salos",
    tr: "Marşal Adaları",
    en: "Marshall Islands",
    es: "Marshall Islands",
    flagIcon: "🇲🇭",
    currency: {
      iso: "",
      label: "",
    },
    code: "MH",
    flag: flagMH,
    dialCode: "692",
    mask: "999-9999",
  },
  {
    enabled: true,
    ru: "Мартиника",
    lt: "Martinika",
    tr: "Martinik",
    en: "Martinique",
    es: "Martinique",
    flagIcon: "🇲🇶",
    currency: {
      iso: "",
      label: "",
    },
    code: "MQ",
    flag: flagMQ,
    dialCode: "596",
    mask: "9999 99 99 99",
  },
  {
    enabled: true,
    ru: "Мавритания",
    lt: "Mauritanija",
    tr: "Moritanya",
    en: "Mauritania",
    es: "Mauritania",
    flagIcon: "🇲🇷",
    currency: {
      iso: "",
      label: "",
    },
    code: "MR",
    flag: flagMR,
    dialCode: "222",
    mask: "99 99 99 99",
  },
  {
    enabled: true,
    ru: "Маврикий",
    lt: "Mauricijus",
    tr: "Mauritius",
    en: "Mauritius",
    es: "Mauritius",
    flagIcon: "🇲🇺",
    currency: {
      iso: "",
      label: "",
    },
    code: "MU",
    flag: flagMU,
    dialCode: "230",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Майотта",
    lt: "Majotas",
    tr: "Mayotte",
    en: "Mayotte",
    es: "Mayotte",
    flagIcon: "🇾🇹",
    currency: {
      iso: "",
      label: "",
    },
    code: "YT",
    flag: flagYT,
    dialCode: "262",
    mask: "9999 99 99 99",
  },
  {
    enabled: true,
    ru: "Мексика",
    lt: "Meksika",
    tr: "Meksika",
    en: "Mexico",
    es: "Mexico",
    mainTimezone: "America/Mexico_City",
    featured: true,
    flagIcon: "🇲🇽",
    currency: {
      iso: "",
      label: "",
    },
    code: "MX",
    flag: flagMX,
    dialCode: "52",
    mask: "999 999 999 9999",
  },
  {
    enabled: true,
    ru: "Микронезия, Федеративные Штаты Микронезии",
    lt: "Mikronezijos Federacinės Mikronezijos valstybės",
    tr: "Mikronezya, Mikronezya Federal Devletleri",
    en: "Micronesia, Federated States of Micronesia",
    es: "Micronesia, Federated States of Micronesia",
    flagIcon: "🇫🇲",
    currency: {
      iso: "",
      label: "",
    },
    code: "FM",
    flag: flagFM,
    dialCode: "691",
    mask: "999 9999",
  },
  {
    enabled: true,
    ru: "Молдова",
    lt: "Moldovoje",
    tr: "Moldova",
    en: "Moldova",
    es: "Moldova",
    flagIcon: "🇲🇩",
    currency: {
      iso: "",
      label: "",
    },
    code: "MD",
    flag: flagMD,
    dialCode: "373",
    mask: "9999 99 999",
  },
  {
    enabled: true,
    ru: "Монако",
    lt: "Monakas",
    tr: "Monako",
    en: "Monaco",
    es: "Monaco",
    flagIcon: "🇲🇨",
    currency: {
      iso: "",
      label: "",
    },
    code: "MC",
    flag: flagMC,
    dialCode: "377",
    mask: "99 99 99 99 99",
  },
  {
    enabled: true,
    ru: "Монголия",
    lt: "Mongolija",
    tr: "Moğolistan",
    en: "Mongolia",
    es: "Mongolia",
    flagIcon: "🇲🇳",
    currency: {
      iso: "",
      label: "",
    },
    code: "MN",
    flag: flagMN,
    dialCode: "976",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Черногория",
    lt: "Juodkalnija",
    tr: "Karadağ",
    en: "Montenegro",
    es: "Montenegro",
    flagIcon: "🇲🇪",
    currency: {
      iso: "",
      label: "",
    },
    code: "ME",
    flag: flagME,
    dialCode: "382",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Монсеррат",
    lt: "Montserratas",
    tr: "Montserrat",
    en: "Montserrat",
    es: "Montserrat",
    flagIcon: "🇲🇸",
    currency: {
      iso: "",
      label: "",
    },
    code: "MS",
    flag: flagMS,
    dialCode: "1664",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Марокко",
    lt: "Marokas",
    tr: "Fas",
    en: "Morocco",
    es: "Morocco",
    flagIcon: "🇲🇦",
    currency: {
      iso: "",
      label: "",
    },
    code: "MA",
    flag: flagMA,
    dialCode: "212",
    mask: "9999-999999",
  },
  {
    enabled: true,
    ru: "Мозамбик",
    lt: "Mozambikas",
    tr: "Mozambik",
    en: "Mozambique",
    es: "Mozambique",
    flagIcon: "🇲🇿",
    currency: {
      iso: "",
      label: "",
    },
    code: "MZ",
    flag: flagMZ,
    dialCode: "258",
    mask: "99 999 9999",
  },
  {
    enabled: true,
    ru: "Мьянма",
    lt: "Mianmaras",
    tr: "Myanmar",
    en: "Myanmar",
    es: "Myanmar",
    flagIcon: "🇲🇲",
    currency: {
      iso: "",
      label: "",
    },
    code: "MM",
    flag: flagMM,
    dialCode: "95",
    mask: "99 999 9999",
  },
  {
    enabled: true,
    ru: "Намибия",
    lt: "Namibija",
    tr: "Namibya",
    en: "Namibia",
    es: "Namibia",
    flagIcon: "🇳🇦",
    currency: {
      iso: "",
      label: "",
    },
    code: "NA",
    flag: flagNA,
    dialCode: "264",
    mask: "999 999 99999",
  },
  {
    enabled: true,
    ru: "Науру",
    lt: "Nauru",
    tr: "Nauru",
    en: "Nauru",
    es: "Nauru",
    flagIcon: "🇳🇷",
    currency: {
      iso: "",
      label: "",
    },
    code: "NR",
    flag: flagNR,
    dialCode: "674",
    mask: "999 9999",
  },
  {
    enabled: true,
    ru: "Непал",
    lt: "Nepalas",
    tr: "Nepal",
    en: "Nepal",
    es: "Nepal",
    flagIcon: "🇳🇵",
    currency: {
      iso: "",
      label: "",
    },
    code: "NP",
    flag: flagNP,
    dialCode: "977",
    mask: "999-9999999",
  },
  {
    enabled: true,
    ru: "Нидерланды",
    lt: "Nyderlandai",
    tr: "Hollanda",
    en: "Netherlands",
    es: "Netherlands",
    flagIcon: "🇳🇱",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "NL",
    flag: flagNL,
    dialCode: "31",
    mask: "99",
  },
  {
    enabled: true,
    ru: "Новая Каледония",
    lt: "Naujoji Kaledonija",
    tr: "Yeni Kaledonya",
    en: "New Caledonia",
    es: "New Caledonia",
    flagIcon: "🇳🇨",
    currency: {
      iso: "",
      label: "",
    },
    code: "NC",
    flag: flagNC,
    dialCode: "687",
    mask: "99.99.99",
  },
  {
    enabled: true,
    ru: "Новая Зеландия",
    lt: "Naujoji Zelandija",
    tr: "Yeni Zelanda",
    en: "New Zealand",
    es: "New Zealand",
    flagIcon: "🇳🇿",
    currency: {
      iso: "",
      label: "",
    },
    code: "NZ",
    flag: flagNZ,
    dialCode: "64",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "Никарагуа",
    lt: "Nikaragva",
    tr: "Nikaragua",
    en: "Nicaragua",
    es: "Nicaragua",
    flagIcon: "🇳🇮",
    currency: {
      iso: "",
      label: "",
    },
    code: "NI",
    flag: flagNI,
    dialCode: "505",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Нигер",
    lt: "Nigeris",
    tr: "Nijer",
    en: "Niger",
    es: "Niger",
    flagIcon: "🇳🇪",
    currency: {
      iso: "",
      label: "",
    },
    code: "NE",
    flag: flagNE,
    dialCode: "227",
    mask: "99 99 99 99",
  },
  {
    enabled: true,
    ru: "Нигерия",
    lt: "Nigerija",
    tr: "Nijerya",
    en: "Nigeria",
    es: "Nigeria",
    flagIcon: "🇳🇬",
    currency: {
      iso: "",
      label: "",
    },
    code: "NG",
    flag: flagNG,
    dialCode: "234",
    mask: "9999 999 9999",
  },
  {
    enabled: true,
    ru: "Niue",
    lt: "Niue",
    tr: "Niue",
    en: "Niue",
    es: "Niue",
    flagIcon: "🇳🇺",
    currency: {
      iso: "",
      label: "",
    },
    code: "NU",
    flag: flagNU,
    dialCode: "683",
    mask: "999 9999",
  },
  {
    enabled: true,
    ru: "Остров Норфолк",
    lt: "Norfolko sala",
    tr: "Norfolk Adası",
    en: "Norfolk Island",
    es: "Norfolk Island",
    flagIcon: "🇳🇫",
    currency: {
      iso: "",
      label: "",
    },
    code: "NF",
    flag: flagNF,
    dialCode: "672",
    mask: "9 99999",
  },
  {
    enabled: true,
    ru: "Северные Марианские острова",
    lt: "Šiaurinės Marianos salos",
    tr: "Kuzey Mariana Adaları",
    en: "Northern Mariana Islands",
    es: "Northern Mariana Islands",
    flagIcon: "🇲🇵",
    currency: {
      iso: "",
      label: "",
    },
    code: "MP",
    flag: flagMP,
    dialCode: "1670",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Норвегия",
    lt: "Norvegija",
    tr: "Norveç",
    en: "Norway",
    es: "Norway",
    flagIcon: "🇳🇴",
    currency: {
      iso: "",
      label: "",
    },
    code: "NO",
    flag: flagNO,
    dialCode: "47",
    mask: "999 99 999",
  },
  {
    enabled: true,
    ru: "Оман",
    lt: "Omanas",
    tr: "Umman",
    en: "Oman",
    es: "Oman",
    flagIcon: "🇴🇲",
    currency: {
      iso: "",
      label: "",
    },
    code: "OM",
    flag: flagOM,
    dialCode: "968",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Пакистан",
    lt: "Pakistanas",
    tr: "Pakistan",
    en: "Pakistan",
    es: "Pakistan",
    flagIcon: "🇵🇰",
    currency: {
      iso: "",
      label: "",
    },
    code: "PK",
    flag: flagPK,
    dialCode: "92",
    mask: "9999 9999999",
  },
  {
    enabled: true,
    ru: "Palau",
    lt: "Palau",
    tr: "Palau",
    en: "Palau",
    es: "Palau",
    flagIcon: "🇵🇼",
    currency: {
      iso: "",
      label: "",
    },
    code: "PW",
    flag: flagPW,
    dialCode: "680",
    mask: "999 9999",
  },
  {
    enabled: true,
    ru: "Палестинская территория, оккупированная",
    lt: "Palestinos teritorija, okupuota",
    tr: "Filistin Arazisi, İşgal altında",
    en: "Palestinian Territory, Occupied",
    es: "Palestinian Territory, Occupied",
    flagIcon: "🇵🇸",
    currency: {
      iso: "",
      label: "",
    },
    code: "PS",
    flag: flagPS,
    dialCode: "970",
    mask: "9999 999 999",
  },
  {
    enabled: true,
    ru: "Панама",
    lt: "Panama",
    tr: "Panama",
    en: "Panama",
    es: "Panama",
    flagIcon: "🇵🇦",
    currency: {
      iso: "",
      label: "",
    },
    code: "PA",
    flag: flagPA,
    dialCode: "507",
    mask: "9999-9999",
  },
  {
    enabled: true,
    ru: "Папуа - Новая Гвинея",
    lt: "Papua Naujoji Gvinėja",
    tr: "Papua Yeni Gine",
    en: "Papua New Guinea",
    es: "Papua New Guinea",
    flagIcon: "🇵🇬",
    currency: {
      iso: "",
      label: "",
    },
    code: "PG",
    flag: flagPG,
    dialCode: "675",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Парагвай",
    lt: "Paragvajus",
    tr: "Paraguay",
    en: "Paraguay",
    es: "Paraguay",
    flagIcon: "🇵🇾",
    currency: {
      iso: "",
      label: "",
    },
    code: "PY",
    flag: flagPY,
    dialCode: "595",
    mask: "9999 999999",
  },
  {
    enabled: true,
    ru: "Перу",
    lt: "Peru",
    tr: "Peru",
    en: "Peru",
    es: "Peru",
    featured: true,
    flagIcon: "🇵🇪",
    currency: {
      iso: "",
      label: "",
    },
    code: "PE",
    flag: flagPE,
    dialCode: "51",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Филиппины",
    lt: "Filipinai",
    tr: "Filipinler",
    en: "Philippines",
    es: "Philippines",
    flagIcon: "🇵🇭",
    currency: {
      iso: "",
      label: "",
    },
    code: "PH",
    flag: flagPH,
    dialCode: "63",
    mask: "9999 999 9999",
  },
  {
    enabled: true,
    ru: "Польша",
    lt: "Lenkija",
    tr: "Polonya",
    en: "Poland",
    es: "Poland",
    flagIcon: "🇵🇱",
    currency: {
      iso: "",
      label: "",
    },
    code: "PL",
    flag: flagPL,
    dialCode: "48",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Португалия",
    lt: "Portugalija",
    tr: "Portekiz",
    en: "Portugal",
    es: "Portugal",
    flagIcon: "🇵🇹",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "PT",
    flag: flagPT,
    dialCode: "351",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Пуэрто-Рико",
    lt: "Puerto Rikas",
    tr: "Porto Riko",
    en: "Puerto Rico",
    es: "Puerto Rico",
    flagIcon: "🇵🇷",
    currency: {
      iso: "",
      label: "",
    },
    code: "PR",
    flag: flagPR,
    dialCode: "1939",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Катар",
    lt: "Kataras",
    tr: "Katar",
    en: "Qatar",
    es: "Qatar",
    flagIcon: "🇶🇦",
    currency: {
      iso: "",
      label: "",
    },
    code: "QA",
    flag: flagQA,
    dialCode: "974",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Доминиканская Республика",
    lt: "Dominikanska republika",
    tr: "Dominik Cumhuriyeti",
    en: "Dominican Republic",
    es: "República Dominicana",
    flagIcon: "🇩🇴",
    currency: {
      iso: "",
      label: "",
    },
    code: "DO",
    flag: flagDO,
    dialCode: "1",
    mask: "9999 999 999",
  },
  {
    enabled: true,
    ru: "Румыния",
    lt: "Rumunija",
    tr: "Romanya",
    en: "Romania",
    es: "Romania",
    flagIcon: "🇷🇴",
    currency: {
      iso: "",
      label: "",
    },
    code: "RO",
    flag: flagRO,
    dialCode: "40",
    mask: "9999 999 999",
  },
  {
    enabled: true,
    ru: "Россия",
    lt: "Rusija",
    tr: "Rusya",
    en: "Russia",
    es: "Russia",
    flagIcon: "🇷🇺",
    currency: {
      iso: "",
      label: "",
    },
    code: "RU",
    flag: flagRU,
    dialCode: "7",
    mask: "9 (999) 999-99-99",
  },
  {
    enabled: true,
    ru: "Руанда",
    lt: "Ruanda",
    tr: "Ruanda",
    en: "Rwanda",
    es: "Rwanda",
    flagIcon: "🇷🇼",
    currency: {
      iso: "",
      label: "",
    },
    code: "RW",
    flag: flagRW,
    dialCode: "250",
    mask: "9999 999 999",
  },
  {
    enabled: true,
    ru: "Сен-Бартельми",
    lt: "Šventasis Barthelemy",
    tr: "Saint Barthelemy",
    en: "Saint Barthelemy",
    es: "Saint Barthelemy",
    flagIcon: "🇧🇱",
    currency: {
      iso: "",
      label: "",
    },
    code: "BL",
    flag: flagBL,
    dialCode: "590",
    mask: "9999 99 99 99",
  },
  {
    enabled: true,
    ru: "Святой Елены, Вознесения и Тристан-да-Кунья",
    lt: "Šventoji Helena, Ascension ir Tristan Da Cunha",
    tr: "Saint Helena, Yükseliş ve Tristan Da Cunha",
    en: "Saint Helena, Ascension and Tristan Da Cunha",
    es: "Saint Helena, Ascension and Tristan Da Cunha",
    flagIcon: "🇸🇭",
    currency: {
      iso: "",
      label: "",
    },
    code: "SH",
    flag: flagSH,
    dialCode: "290",
    mask: "99999",
  },
  {
    enabled: true,
    ru: "Сент-Китс и Невис",
    lt: "Sent Kitsas ir Nevis",
    tr: "Saint Kitts ve Nevis",
    en: "Saint Kitts and Nevis",
    es: "Saint Kitts and Nevis",
    flagIcon: "🇰🇳",
    currency: {
      iso: "",
      label: "",
    },
    code: "KN",
    flag: flagKN,
    dialCode: "1869",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Сент-Люсия",
    lt: "Sent Lusija",
    tr: "Saint Lucia",
    en: "Saint Lucia",
    es: "Saint Lucia",
    flagIcon: "🇱🇨",
    currency: {
      iso: "",
      label: "",
    },
    code: "LC",
    flag: flagLC,
    dialCode: "1758",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Святой мартин",
    lt: "Sent Martinas",
    tr: "Aziz Martin",
    en: "Saint Martin",
    es: "Saint Martin",
    flagIcon: "🇲🇫",
    currency: {
      iso: "",
      label: "",
    },
    code: "MF",
    flag: flagMF,
    dialCode: "590",
    mask: "9999 99 99 99",
  },
  {
    enabled: true,
    ru: "Сен-Пьер и Микелон",
    lt: "Sent Pjeras ir Mikelonas",
    tr: "Saint Pierre ve Miquelon",
    en: "Saint Pierre and Miquelon",
    es: "Saint Pierre and Miquelon",
    flagIcon: "🇵🇲",
    currency: {
      iso: "",
      label: "",
    },
    code: "PM",
    flag: flagPM,
    dialCode: "508",
    mask: "999 99 99",
  },
  {
    enabled: true,
    ru: "Святой Винсент и Гренадины",
    lt: "Sent Vinsentas ir Grenadinai",
    tr: "Saint Vincent ve Grenadinler",
    en: "Saint Vincent and the Grenadines",
    es: "Saint Vincent and the Grenadines",
    flagIcon: "🇻🇨",
    currency: {
      iso: "",
      label: "",
    },
    code: "VC",
    flag: flagVC,
    dialCode: "1784",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Самоа",
    lt: "Samoa",
    tr: "Samoa",
    en: "Samoa",
    es: "Samoa",
    flagIcon: "🇼🇸",
    currency: {
      iso: "",
      label: "",
    },
    code: "WS",
    flag: flagWS,
    dialCode: "685",
    mask: "99 99999",
  },
  {
    enabled: true,
    ru: "Сан-Марино",
    lt: "San Marinas",
    tr: "San Marino",
    en: "San Marino",
    es: "San Marino",
    flagIcon: "🇸🇲",
    currency: {
      iso: "",
      label: "",
    },
    code: "SM",
    flag: flagSM,
    dialCode: "378",
    mask: "99 99 99 99",
  },
  {
    enabled: true,
    ru: "Сан-Томе и Принсипи",
    lt: "San Tomė ir Principe",
    tr: "Sao Tome ve Principe",
    en: "Sao Tome and Principe",
    es: "Sao Tome and Principe",
    flagIcon: "🇸🇹",
    currency: {
      iso: "",
      label: "",
    },
    code: "ST",
    flag: flagST,
    dialCode: "239",
    mask: "999 9999",
  },
  {
    enabled: true,
    ru: "Саудовская Аравия",
    lt: "Saudo Arabija",
    tr: "Suudi Arabistan",
    en: "Saudi Arabia",
    es: "Saudi Arabia",
    flagIcon: "🇸🇦",
    currency: {
      iso: "",
      label: "",
    },
    code: "SA",
    flag: flagSA,
    dialCode: "966",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "Сенегал",
    lt: "Senegalas",
    tr: "Senegal",
    en: "Senegal",
    es: "Senegal",
    flagIcon: "🇸🇳",
    currency: {
      iso: "",
      label: "",
    },
    code: "SN",
    flag: flagSN,
    dialCode: "221",
    mask: "99 999 99 99",
  },
  {
    enabled: true,
    ru: "Сербия",
    lt: "Serbija",
    tr: "Sırbistan",
    en: "Serbia",
    es: "Serbia",
    flagIcon: "🇷🇸",
    currency: {
      iso: "",
      label: "",
    },
    code: "RS",
    flag: flagRS,
    dialCode: "381",
    mask: "999 9999999",
  },
  {
    enabled: true,
    ru: "Сейшельские острова",
    lt: "Seišeliai",
    tr: "Seyşeller",
    en: "Seychelles",
    es: "Seychelles",
    flagIcon: "🇸🇨",
    currency: {
      iso: "",
      label: "",
    },
    code: "SC",
    flag: flagSC,
    dialCode: "248",
    mask: "9 999 999",
  },
  {
    enabled: true,
    ru: "Сьерра-Леоне",
    lt: "Siera Leonė",
    tr: "Sierra Leone",
    en: "Sierra Leone",
    es: "Sierra Leone",
    flagIcon: "🇸🇱",
    currency: {
      iso: "",
      label: "",
    },
    code: "SL",
    flag: flagSL,
    dialCode: "232",
    mask: "(999) 999999",
  },
  {
    enabled: true,
    ru: "Сингапур",
    lt: "Singapūras",
    tr: "Singapur",
    en: "Singapore",
    es: "Singapore",
    flagIcon: "🇸🇬",
    currency: {
      iso: "",
      label: "",
    },
    code: "SG",
    flag: flagSG,
    dialCode: "65",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Словакия",
    lt: "Slovakija",
    tr: "Slovakya",
    en: "Slovakia",
    es: "Slovakia",
    flagIcon: "🇸🇰",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "SK",
    flag: flagSK,
    dialCode: "421",
    mask: "9999 999 999",
  },
  {
    enabled: true,
    ru: "Словения",
    lt: "Slovėnija",
    tr: "Slovenya",
    en: "Slovenia",
    es: "Slovenia",
    flagIcon: "🇸🇮",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "SI",
    flag: flagSI,
    dialCode: "386",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Соломоновы острова",
    lt: "Saliamono salos",
    tr: "Solomon Adaları",
    en: "Solomon Islands",
    es: "Solomon Islands",
    flagIcon: "🇸🇧",
    currency: {
      iso: "",
      label: "",
    },
    code: "SB",
    flag: flagSB,
    dialCode: "677",
    mask: "99 99999",
  },
  {
    enabled: true,
    ru: "Сомали",
    lt: "Somalis",
    tr: "Somali",
    en: "Somalia",
    es: "Somalia",
    flagIcon: "🇸🇴",
    currency: {
      iso: "",
      label: "",
    },
    code: "SO",
    flag: flagSO,
    dialCode: "252",
    mask: "9 9999999",
  },
  {
    enabled: true,
    ru: "Южная Африка",
    lt: "pietų Afrika",
    tr: "Güney Afrika",
    en: "South Africa",
    es: "South Africa",
    flagIcon: "🇿🇦",
    currency: {
      iso: "",
      label: "",
    },
    code: "ZA",
    flag: flagZA,
    dialCode: "27",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "южный Судан",
    lt: "Pietų Sudanas",
    tr: "Güney Sudan",
    en: "South Sudan",
    es: "South Sudan",
    flagIcon: "🇸🇸",
    currency: {
      iso: "",
      label: "",
    },
    code: "SS",
    flag: flagSS,
    dialCode: "211",
    mask: "9999 999 999",
  },
  {
    enabled: true,
    ru: "Испания",
    lt: "Ispanija",
    tr: "ispanya",
    en: "Spain",
    es: "España",
    mainTimezone: "Europe/Madrid",
    flagIcon: "🇪🇸",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "ES",
    featured: true,
    flag: flagES,
    dialCode: "34",
    mask: "999 99 99 99",
  },
  {
    enabled: true,
    ru: "Испания",
    lt: "Ispanija",
    tr: "ispanya",
    en: "Canary Islands",
    es: "Islas Canarias",
    flagIcon: "🇪🇸",
    currency: {
      iso: "EUR",
      label: "€",
      description: "Euros",
    },
    code: "IC",
    featured: true,
    flag: flagES,
    dialCode: "34",
    mask: "999 99 99 99",
  },
  {
    enabled: true,
    ru: "Шри-Ланка",
    lt: "Šri Lanka",
    tr: "Sri Lanka",
    en: "Sri Lanka",
    es: "Sri Lanka",
    flagIcon: "🇱🇰",
    currency: {
      iso: "",
      label: "",
    },
    code: "LK",
    flag: flagLK,
    dialCode: "94",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "Судан",
    lt: "Sudanas",
    tr: "Sudan",
    en: "Sudan",
    es: "Sudan",
    flagIcon: "🇸🇩",
    currency: {
      iso: "",
      label: "",
    },
    code: "SD",
    flag: flagSD,
    dialCode: "249",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "Суринам",
    lt: "Surinamas",
    tr: "Surinam",
    en: "Suriname",
    es: "Suriname",
    flagIcon: "🇸🇷",
    currency: {
      iso: "",
      label: "",
    },
    code: "SR",
    flag: flagSR,
    dialCode: "597",
    mask: "999-9999",
  },
  {
    enabled: true,
    ru: "Шпицберген и Ян Майен",
    lt: "Svalbardas ir Janas Mayenas",
    tr: "Svalbard ve Jan Mayen",
    en: "Svalbard and Jan Mayen",
    es: "Svalbard and Jan Mayen",
    flagIcon: "🇸🇯",
    currency: {
      iso: "",
      label: "",
    },
    code: "SJ",
    flag: flagSJ,
    dialCode: "47",
    mask: "999 99 999",
  },
  {
    enabled: true,
    ru: "Свазиленд",
    lt: "Svazilandas",
    tr: "Svaziland",
    en: "Swaziland",
    es: "Swaziland",
    flagIcon: "🇸🇿",
    currency: {
      iso: "",
      label: "",
    },
    code: "SZ",
    flag: flagSZ,
    dialCode: "268",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Швеция",
    lt: "Švedija",
    tr: "İsveç",
    en: "Sweden",
    es: "Sweden",
    flagIcon: "🇸🇪",
    currency: {
      iso: "",
      label: "",
    },
    code: "SE",
    flag: flagSE,
    dialCode: "46",
    mask: "999-999 99 99",
  },
  {
    enabled: true,
    ru: "Швейцария",
    lt: "Šveicarija",
    tr: "İsviçre",
    en: "Switzerland",
    es: "Switzerland",
    flagIcon: "🇨🇭",
    currency: {
      iso: "",
      label: "",
    },
    code: "CH",
    flag: flagCH,
    dialCode: "41",
    mask: "999 999 99 99",
  },
  {
    enabled: true,
    ru: "Сирийская Арабская Республика",
    lt: "Sirijos Arabų Respublika",
    tr: "Suriye Arap Cumhuriyeti",
    en: "Syrian Arab Republic",
    es: "Syrian Arab Republic",
    flagIcon: "🇸🇾",
    currency: {
      iso: "",
      label: "",
    },
    code: "SY",
    flag: flagSY,
    dialCode: "963",
    mask: "9999 999 999",
  },
  {
    enabled: true,
    ru: "Тайвань",
    lt: "Taivanas",
    tr: "Tayvan",
    en: "Taiwan",
    es: "Taiwan",
    flagIcon: "🇹🇼",
    currency: {
      iso: "",
      label: "",
    },
    code: "TW",
    flag: flagTW,
    dialCode: "886",
    mask: "9999 999 999",
  },
  {
    enabled: true,
    ru: "Таджикистан",
    lt: "Tadžikistanas",
    tr: "Tacikistan",
    en: "Tajikistan",
    es: "Tajikistan",
    flagIcon: "🇹🇯",
    currency: {
      iso: "",
      label: "",
    },
    code: "TJ",
    flag: flagTJ,
    dialCode: "992",
    mask: "999 99 9999",
  },
  {
    enabled: true,
    ru: "Танзания, Объединенная Республика Танзания",
    lt: "Tanzanija, Jungtinė Tanzanijos Respublika",
    tr: "Tanzanya, Tanzanya Birleşik Cumhuriyeti",
    en: "Tanzania, United Republic of Tanzania",
    es: "Tanzania, United Republic of Tanzania",
    flagIcon: "🇹🇿",
    currency: {
      iso: "",
      label: "",
    },
    code: "TZ",
    flag: flagTZ,
    dialCode: "255",
    mask: "9999 999 999",
  },
  {
    enabled: true,
    ru: "Таиланд",
    lt: "Tailandas",
    tr: "Tayland",
    en: "Thailand",
    es: "Thailand",
    flagIcon: "🇹🇭",
    currency: {
      iso: "",
      label: "",
    },
    code: "TH",
    flag: flagTH,
    dialCode: "66",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "Восточный Тимор",
    lt: "Timoras-Leste",
    tr: "Timor-Leste",
    en: "Timor-Leste",
    es: "Timor-Leste",
    flagIcon: "🇹🇱",
    currency: {
      iso: "",
      label: "",
    },
    code: "TL",
    flag: flagTL,
    dialCode: "670",
    mask: "9999 9999",
  },
  {
    enabled: true,
    ru: "Идти",
    lt: "Eiti",
    tr: "Gitmek",
    en: "Togo",
    es: "Togo",
    flagIcon: "🇹🇬",
    currency: {
      iso: "",
      label: "",
    },
    code: "TG",
    flag: flagTG,
    dialCode: "228",
    mask: "99 99 99 99",
  },
  {
    enabled: true,
    ru: "Токелау",
    lt: "Tokelau",
    tr: "Tokelau",
    en: "Tokelau",
    es: "Tokelau",
    flagIcon: "🇹🇰",
    currency: {
      iso: "",
      label: "",
    },
    code: "TK",
    flag: flagTK,
    dialCode: "690",
    mask: "9999",
  },
  {
    enabled: true,
    ru: "Тонга",
    lt: "Tonga",
    tr: "Tonga",
    en: "Tonga",
    es: "Tonga",
    flagIcon: "🇹🇴",
    currency: {
      iso: "",
      label: "",
    },
    code: "TO",
    flag: flagTO,
    dialCode: "676",
    mask: "999 9999",
  },
  {
    enabled: true,
    ru: "Тринидад и Тобаго",
    lt: "Trinidadas ir Tobagas",
    tr: "Trinidad ve Tobago",
    en: "Trinidad and Tobago",
    es: "Trinidad and Tobago",
    flagIcon: "🇹🇹",
    currency: {
      iso: "",
      label: "",
    },
    code: "TT",
    flag: flagTT,
    dialCode: "1868",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Тунис",
    lt: "Tunisas",
    tr: "Tunus",
    en: "Tunisia",
    es: "Tunisia",
    flagIcon: "🇹🇳",
    currency: {
      iso: "",
      label: "",
    },
    code: "TN",
    flag: flagTN,
    dialCode: "216",
    mask: "99 999 999",
  },
  {
    enabled: true,
    ru: "индейка",
    lt: "Turkija",
    tr: "Türkiye",
    en: "Turkey",
    es: "Turkey",
    flagIcon: "🇹🇷",
    currency: {
      iso: "",
      label: "",
    },
    code: "TR",
    flag: flagTR,
    dialCode: "90",
    mask: "(999) 999 9999",
  },
  {
    enabled: true,
    ru: "Туркменистан",
    lt: "Turkmėnistanas",
    tr: "Türkmenistan",
    en: "Turkmenistan",
    es: "Turkmenistan",
    flagIcon: "🇹🇲",
    currency: {
      iso: "",
      label: "",
    },
    code: "TM",
    flag: flagTM,
    dialCode: "993",
    mask: "9 99 999999",
  },
  {
    enabled: true,
    ru: "Острова Теркс и Кайкос",
    lt: "Terkso ir Kaikoso salos",
    tr: "Turks ve Caicos Adaları",
    en: "Turks and Caicos Islands",
    es: "Turks and Caicos Islands",
    flagIcon: "🇹🇨",
    currency: {
      iso: "",
      label: "",
    },
    code: "TC",
    flag: flagTC,
    dialCode: "1649",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Тувалу",
    lt: "Tuvalu",
    tr: "Tuvalu",
    en: "Tuvalu",
    es: "Tuvalu",
    flagIcon: "🇹🇻",
    currency: {
      iso: "",
      label: "",
    },
    code: "TV",
    flag: flagTV,
    dialCode: "688",
    mask: "999999",
  },
  {
    enabled: true,
    ru: "Уганда",
    lt: "Uganda",
    tr: "Uganda",
    en: "Uganda",
    es: "Uganda",
    flagIcon: "🇺🇬",
    currency: {
      iso: "",
      label: "",
    },
    code: "UG",
    flag: flagUG,
    dialCode: "256",
    mask: "9999 999999",
  },
  {
    enabled: true,
    ru: "Украина",
    lt: "Ukraina",
    tr: "Ukrayna",
    en: "Ukraine",
    es: "Ukraine",
    flagIcon: "🇺🇦",
    currency: {
      iso: "",
      label: "",
    },
    code: "UA",
    flag: flagUA,
    dialCode: "380",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "Объединенные Арабские Эмираты",
    lt: "Jungtiniai Arabų Emyratai",
    tr: "Birleşik Arap Emirlikleri",
    en: "United Arab Emirates",
    es: "United Arab Emirates",
    flagIcon: "🇦🇪",
    currency: {
      iso: "",
      label: "",
    },
    code: "AE",
    flag: flagAE,
    dialCode: "971",
    mask: "999 999 9999",
  },
  {
    enabled: true,
    ru: "объединенное Королевство",
    lt: "Jungtinė Karalystė",
    tr: "Birleşik Krallık",
    en: "United Kingdom",
    es: "United Kingdom",
    flagIcon: "🇬🇧",
    currency: {
      iso: "",
      label: "",
    },
    code: "GB",
    flag: flagGB,
    dialCode: "44",
    mask: "99999 999999",
  },
  {
    enabled: true,
    ru: "Соединенные Штаты",
    lt: "Jungtinės Valstijos",
    tr: "Amerika Birleşik Devletleri",
    en: "United States",
    es: "United States",
    featured: true,
    flagIcon: "🇺🇸",
    currency: {
      iso: "",
      label: "",
    },
    code: "US",
    flag: flagUS,
    dialCode: "1",
    mask: "(999) 999-9999",
  },
  {
    enabled: true,
    ru: "Уругвай",
    lt: "Urugvajus",
    tr: "Uruguay",
    en: "Uruguay",
    es: "Uruguay",
    mainTimezone: "America/Montevideo",
    featured: true,
    flagIcon: "🇺🇾",
    currency: {
      iso: "",
      label: "",
    },
    code: "UY",
    flag: flagUY,
    dialCode: "598",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Узбекистан",
    lt: "Uzbekistanas",
    tr: "Özbekistan",
    en: "Uzbekistan",
    es: "Uzbekistan",
    flagIcon: "🇺🇿",
    currency: {
      iso: "",
      label: "",
    },
    code: "UZ",
    flag: flagUZ,
    dialCode: "998",
    mask: "9 99 999 99 99",
  },
  {
    enabled: true,
    ru: "Вануату",
    lt: "Vanuatu",
    tr: "Vanuatu",
    en: "Vanuatu",
    es: "Vanuatu",
    flagIcon: "🇻🇺",
    currency: {
      iso: "",
      label: "",
    },
    code: "VU",
    flag: flagVU,
    dialCode: "678",
    mask: "999 9999",
  },
  {
    enabled: true,
    ru: "Венесуэла, Боливарианская Республика Венесуэла",
    lt: "Venesuela, Venesuelos Bolivaro Respublika",
    tr: "Venezuela, Bolivarcı Venezuela Cumhuriyeti",
    en: "Venezuela, Bolivarian Republic of Venezuela",
    es: "Venezuela, Bolivarian Republic of Venezuela",
    flagIcon: "🇻🇪",
    currency: {
      iso: "",
      label: "",
    },
    code: "VE",
    flag: flagVE,
    dialCode: "58",
    mask: "9999-9999999",
  },
  {
    enabled: true,
    ru: "Вьетнам",
    lt: "Vietnamas",
    tr: "Vietnam",
    en: "Vietnam",
    es: "Vietnam",
    flagIcon: "🇻🇳",
    currency: {
      iso: "",
      label: "",
    },
    code: "VN",
    flag: flagVN,
    dialCode: "84",
    mask: "999 999 99 99",
  },
  {
    enabled: true,
    ru: "Уоллис и Футуна",
    lt: "Volis ir Futuna",
    tr: "Wallis ve Futuna",
    en: "Wallis and Futuna",
    es: "Wallis and Futuna",
    flagIcon: "🇼🇫",
    currency: {
      iso: "",
      label: "",
    },
    code: "WF",
    flag: flagWF,
    dialCode: "681",
    mask: "99 99 99",
  },
  {
    enabled: true,
    ru: "Йемен",
    lt: "Jemenas",
    tr: "Yemen",
    en: "Yemen",
    es: "Yemen",
    flagIcon: "🇾🇪",
    currency: {
      iso: "",
      label: "",
    },
    code: "YE",
    flag: flagYE,
    dialCode: "967",
    mask: "999 999 999",
  },
  {
    enabled: true,
    ru: "Замбия",
    lt: "Zambija",
    tr: "Zambiya",
    en: "Zambia",
    es: "Zambia",
    flagIcon: "🇿🇲",
    currency: {
      iso: "",
      label: "",
    },
    code: "ZM",
    flag: flagZM,
    dialCode: "260",
    mask: "999 9999999",
  },
  {
    enabled: true,
    ru: "Zimbabve",
    lt: "Zimbabvė",
    tr: "Zimbabve",
    en: "Zimbabwe",
    es: "Zimbabwe",
    flagIcon: "🇿🇼",
    currency: {
      iso: "",
      label: "",
    },
    code: "ZW",
    flag: flagZW,
    dialCode: "263",
    mask: "999 999 9999",
  },
];

export default countries;
