import styled from "styled-components";

export const CheckoutContainer = styled('div')`
  gap: 0;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;

  @media(min-width:  ${(props) => props.theme.mediaBreakpoints.smMin}) {
    flex-direction: row;
  }
`

export const Column = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: 30px 20px;
  height: auto;
  
  @media(min-width: 768px){
    width: 50%;
    min-height: 100vh;
    padding: 40px 80px;
  }

  form[class^='svelte-']{
    padding: 0px!important;
    background: none!important;
  }

   &.col-one { 
    background-color: transparent;
    
    @media(max-width: 767px){
      padding-bottom: 0
    }

    @media(min-width: 768px){
      background-color: #FAFAFA;
    }
  }

   &.col-two { 
    @media(max-width: 767px){
      padding-bottom: 0
    }
  }
`

export const ShowInMobile = styled('div')`
  display: none;
  @media(max-width: 767px){
    display: block;
  }
`
export const ShowInDesktop = styled('div')`
  display: none;
  @media(min-width: 768px){
    display: block;
  }
`

export const Title = styled("h1")`
  margin: 0px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  width: 100%;
`;