import { PlanDto } from "../../../types/plan.dto";
export const SELECTED_PLAN = "SELECTED_PLAN";
export const SELECTED_PLAN_SUCCESS = "SELECTED_PLAN_SUCCESS";
export const SELECTED_PLAN_ERROR = "SELECTED_PLAN_ERROR";
export const RESET_SELECTED_PLAN = "RESET_SELECTED_PLAN";

export interface SelectedPlan {
  type: typeof SELECTED_PLAN;
  payload: PlanDto | null;
}

export interface SelectedPlanSuccess {
  type: typeof SELECTED_PLAN_SUCCESS;
  payload: PlanDto;
}

export interface SelectedPlanError {
  type: typeof SELECTED_PLAN_ERROR;
  payload: string | boolean;
}

export interface ResetSelectedPlan {
  type: typeof RESET_SELECTED_PLAN;
  payload: null;
}

export interface State {
  data: PlanDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions = SelectedPlan | SelectedPlanSuccess | SelectedPlanError | ResetSelectedPlan;
