import styled from "styled-components";
import UiImage from "../../../../components/Image";

export const Community = styled.div`
  margin-bottom: 80px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    margin-bottom: 150px;
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  line-height: 50px;
  font-weight: 700;
  text-align: center;
  margin: 0px 0px 24px;

  & > span {
    color: ${(props) => props.theme.colors.PurpleVeryLight};
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 48px;
    line-height: 60px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    font-size: 50px;
    line-height: 72px;
  }
`;

export const StepperBar = styled.div`
  margin: 0px auto 20px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: 0px auto 65px;
  }
`;

export const Steps = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Step = styled("div")<{ visible: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  z-index: -1;

  &.active {
    opacity: 1;
    position: relative;
    z-index: 10;
  }
`;

export const Information = styled.div`
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    margin-right: 30px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.lgMin}) {
    margin-right: 100px;
  }
`;

export const H1 = styled.div`
  font-size: 32px;
  line-height: 41px;
  font-weight: 600;
  margin-bottom: 30px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 32px;
  }
`;

export const Text = styled.div`
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 16px;
  font-weight: 400;
  text-align: left;
  color: #000000;
`;

export const CommunityImg = styled.div`
  position: relative;
  margin-bottom: 60px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin-bottom: 40px;
  }
`;

export const Img = styled(UiImage)`
  width: 100%;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.lgMin}) {
    width: 552px;
    height: 368px;
  }
`;

export const DivOne = styled.div`
  width: 90px;
  height: 100px;
  position: absolute;
  top: 94%;
  left: 35%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 16px;
  background: red;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    width: 243px;
    height: 167px;
  }
`;

export const DivTwo = styled.div`
  width: 90px;
  height: 100px;
  position: absolute;
  top: 94%;
  left: 85%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 16px;
  background: red;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    width: 243px;
    height: 167px;
  }
`;
