import { createRef, useEffect, useState } from "react";
import Dropdown from "./partials";

const Components = ({ children, label, listMinWidth = 230, ...rest }: any) => {
  const dropdownRef = createRef<HTMLElement>();
  const [isOpen, setIsOpen] = useState(false);

  const closeDropdown = (event) => {
    if (
      dropdownRef &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);
    return () => document.removeEventListener("mousedown", closeDropdown);
  }, [dropdownRef]);

  return (
    <Dropdown.Wrapper {...rest} forwardRef={dropdownRef}>
      <Dropdown.Toggle onClick={() => setIsOpen(!isOpen)}>
        {label}
      </Dropdown.Toggle>

      {isOpen && (
        <Dropdown.List style={{minWidth: listMinWidth}}>
          {children &&
            children.map((child: any, index: number) => {
              if (!child) {
                return null;
              }
              const { marginBottom, isSeparator, alignSelf } = child.props;
              const key = new Date().getUTCMilliseconds() + index;

              return (
                <Dropdown.Item
                  alignSelf={alignSelf}
                  isSeparator={isSeparator}
                  marginBottom={marginBottom}
                  key={key}
                  onClick={() => setIsOpen(false)}
                >
                  <span>{child}</span>
                </Dropdown.Item>
              );
            })}
        </Dropdown.List>
      )}
    </Dropdown.Wrapper>
  );
};

export default Components;
