import Modal from "../../../Modal";
import Button from "../../../Button";
import { Title, Text } from "./styles";
import { connect, useDispatch } from "react-redux";
import Submit from "../../../FormikForm/Submit";
import { hideModal } from "../../../../store/actions/modal";
import PhoneNumberForm from "../../../../pages/components/PhoneNumberForm";
import { Center } from "../../../StyledComponents";

const Component = ({ userInformation, changeUserPhoneStates }) => {
  const dispatch = useDispatch();

  return (
    <Modal
      name="modal-phone-number"
      width="650"
      showClose={!!userInformation?.phone_number}
      onClose={() => { }}
    >
      <Title>Registra tu teléfono</Title>

      <Text>
        Ingresá tu teléfono para que podamos brindarte una experiencia más
        personalizada.
      </Text>

      <PhoneNumberForm />

      <Center horizontal="right" vertical="center" gap={8}>
        {!!userInformation?.phone_number && (
          <Button
            type="button"
            options={{
              skin: "purple",
              size: "lg",
              type: "outline",
            }}
            onClick={() => dispatch(hideModal())}
          >
            Cancelar
          </Button>
        )}

        <Submit
          isSubmmiting={changeUserPhoneStates.loading}
          options={{
            type: "filled",
            size: "lg",
            skin: "purple",
          }}
          form="phone-number-form"
        >
          {userInformation?.phone_number ? "Cambiar mi número" : "Registrar mi número"}
        </Submit>
      </Center>
    </Modal>
  );
};

const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;
  const { states: changeUserPhoneStates } = userStore.changeUserPhone;

  return {
    changeUserPhoneStates,
    userInformation,
  };
};

export default connect(state)(Component);
