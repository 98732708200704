import ImageUi from '../../../../../../../../components/Image'
import { Link } from 'react-router-dom';
import UiAvatar from "../../../../../../../../components/Avatar";
import { Center } from "../../../../styles";
import linesSteps from '../../../../../../../../assets/lines-steps.svg'
import styled, { css, keyframes } from "styled-components";

export const AvatarImage = styled("div")`
  display: flex;
  align-items: center;
`;

export const Avatar = styled(UiAvatar)`
  margin-right: 16px;
`;

const resetButtonStyles = css`
  margin: 0px;
  padding: 0px;
  border: 0px;
  background: none;
  cursor: pointer;
  text-decoration: none;
`;

const bigText = css`
  font-weight: 600;
  font-size: 18px;
`

export const Line = styled('div')`
  width: 100%;
  height: 1px;
  background-color: #D9DDE6;
`


export const Cover = styled('div') <{ src, isExpiredSession }>`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 146px;
  border-radius: 12px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  
  &:before {
    content: "";
    display: ${({ isExpiredSession }) => isExpiredSession ? 'block' : 'none'};
    position: absolute;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,.87) 0%, rgba(204,199,241,1) 100%);
  }

  span { 
    position: relative;
    z-index: 10;
  }
`

export const Image = styled('div') <{ src }>`
  width: 100%;
  height: 146px;
  border-radius: 12px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`

export const Row = styled('div')`
  display: flex;
  gap: 0px;
  padding: 0px;

  @media(min-width: 768px){
    gap: 20px;
    padding: 25px;
  }

  @media(min-width: 1200px){
    gap: 45px;
    padding: 58px 35px;
  }


  @media(min-width: 1440px){
    gap: 95px;
    padding: 58px 35px;
  }
`

const sonarWave = keyframes`
from {
  opacity: 0.4;
}
to {
  transform: scale(3);
  opacity: 0;
}`;

export const Sonar = styled("div")`
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.Primary};
  margin-right: 4px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.Primary};
    opacity: 0;
    pointer-events: none;
    animation: ${sonarWave} 1.5s linear infinite;
  }
`;

export const OwnerActions = styled('div') <{
  isExpiredSession?,
  sessionWillBeRecorded?
}>`
  background-color: ${({ isExpiredSession, sessionWillBeRecorded }) => isExpiredSession && sessionWillBeRecorded ? "rgba(246, 230, 229, 1)" : "rgba(231,233,239,.45)"};
  color: ${({ isExpiredSession, sessionWillBeRecorded, theme }) => isExpiredSession && sessionWillBeRecorded ? "#C0382C" : theme.colors.Black};
  font-weight: ${({ isExpiredSession, sessionWillBeRecorded }) => isExpiredSession && sessionWillBeRecorded ? "500" : "400"};
  padding: 10px 20px; 
  border-radius: 6px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  gap: 16px;
`

export const Actions = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const EditCta = styled("a")`
  ${resetButtonStyles}
  background-color: ${({ theme }) => theme.colors.Primary};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 25px;
  width: 75px;
  border-radius: 13px;
`;

export const ShareCta = styled("button")`
  ${resetButtonStyles}
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 12px;

  @media(min-width: 768px){
    gap: 8px;
  }
`;

export const DeleteCta = styled("button")`
  ${resetButtonStyles}
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 12px;

  @media(min-width: 768px){
    gap: 8px;
  }
`;

export const Reminder = styled('div')`
  background-color: rgba(76,187,23, .15);
  border-radius: 15px;
  height: 30px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  width: fit-content;
`

export const BigText = styled('span')`
  font-weight: 600;
  font-size: 18px;
`

export const SmallText = styled('span')`
  font-weight: regular;
  font-size: 14px;
`

export const Description = styled.div`
  font-size: 14px;
  line-height: 25px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 14px;
  }

  p {
    &:first-child {
      margin-top: 0px
    }
    &:last-child {
      margin-bottom: 0px
    }
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  padding: 0px 0px 0px 22px;
  margin: 0;
  line-height: normal;
`

export const SessionDetail = styled(Center) <{ highlighted?, withSteps?, isExpiredSession?}>`
  padding: ${({ highlighted }) => highlighted ? "23px 46px 23px 23px" : '0px 46px 0px 23px'};
  background-color: ${({ theme, highlighted, isExpiredSession }) => isExpiredSession ? "rgb(235 235 235)" : highlighted ? theme.colors.Primary15 : 'transparent'};
  border-radius: 6px;
  position: relative;
  
  &:after { 
    content: '';
    display: ${({ withSteps }) => withSteps ? 'block' : 'none'};
    position: absolute;
    top: 100%;
    left: 34px;
    width: 1px;
    height: 100%;
    background-image: url(${linesSteps});
    background-size: contain;
    background-repeat: repeat-y;
  }
  
`

export const CleanLink = styled.a`
  ${resetButtonStyles}
  color: inherit;

  i {
    display: inline-block;
    vertical-align: top;
  }
`

