const bestPractices = [
  {
    href: "https://www.klouser.com/post/detr%C3%A1s-de-cada-comunidad-exitosa",
    label: "Detrás de cada comunidad exitosa",
    target: "_blank",
  },
  {
    href: "https://academy.klouser.com/es/klouser-telegram",
    label: "Klouser y Telegram",
    target: "_blank",
  },
  {
    href: "https://academy.klouser.com/es/article/preguntas-frecuentes-administrativas",
    label: "Preguntas frecuentes administrativas",
    target: "_blank",
  },
];

export default bestPractices;
