import { put, call, takeLatest, select } from "redux-saga/effects";
import services from "../../services";

import {
  GET_COMMUNITY,
  GET_COMMUNITY_SUCCESS,
  GET_COMMUNITY_ERROR,
} from "../types/community/community";

import {
  GET_COMMUNITY_SUBSCRIBERS,
  GET_COMMUNITY_SUBSCRIBERS_SUCCESS,
  GET_COMMUNITY_SUBSCRIBERS_ERROR,
} from "../types/community/subscribers";

import {
  GET_COMMUNITY_SUBSCRIBERS_EMAILS,
  GET_COMMUNITY_SUBSCRIBERS_EMAILS_SUCCESS,
  GET_COMMUNITY_SUBSCRIBERS_EMAILS_ERROR,
} from "../types/community/subscribers-emails";

import {
  EXPORT_COMMUNITY_SUBSCRIBERS,
  EXPORT_COMMUNITY_SUBSCRIBERS_SUCCESS,
  EXPORT_COMMUNITY_SUBSCRIBERS_ERROR,
} from "../types/community/export-subscribers";

import {
  GET_COMMUNITY_METRICS,
  GET_COMMUNITY_METRICS_SUCCESS,
  GET_COMMUNITY_METRICS_ERROR,
} from "../types/community/metrics";

import {
  SUBSCRIBE_TO_THE_COMMUNITY,
  SUBSCRIBE_TO_THE_COMMUNITY_SUCCESS,
  SUBSCRIBE_TO_THE_COMMUNITY_ERROR,
} from "../types/community/subscription";

const getUserInformation = ({ userStore }) => userStore.information.data;

function* getCommunity({ payload }) {
  try {
    const community = yield call(services.group.getGroupById, payload);
    const totalSubscribers = yield call(
      services.group.getTotalSubscribers,
      payload
    );
    yield put({
      type: GET_COMMUNITY_SUCCESS,
      payload: { ...community, ...totalSubscribers },
    });
  } catch (error) {
    yield put({ type: GET_COMMUNITY_ERROR, payload: error });
  }
}

function* getGroupSubscribers({ payload }) {
  try {
    const { response, error } = yield call(
      services.community.getSubscribers,
      payload
    );

    if (error) {
      yield put({ type: GET_COMMUNITY_SUBSCRIBERS_ERROR, payload: error });
    } else {
      yield put({
        type: GET_COMMUNITY_SUBSCRIBERS_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({ type: GET_COMMUNITY_SUBSCRIBERS_ERROR, payload: error });
  }
}

function* getGroupSubscribersEmails({ payload }) {
  try {
    const { response, error } = yield call(
      services.community.getSubscribers,
      payload
    );

    if (error) {
      yield put({
        type: GET_COMMUNITY_SUBSCRIBERS_EMAILS_ERROR,
        payload: error,
      });
    } else {
      yield put({
        type: GET_COMMUNITY_SUBSCRIBERS_EMAILS_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({ type: GET_COMMUNITY_SUBSCRIBERS_EMAILS_ERROR, payload: error });
  }
}

function* exportGroupSubscribers({ payload }) {
  try {
    const subscribers = yield call(
      services.community.exportSubscribers,
      payload
    );
    yield put({
      type: EXPORT_COMMUNITY_SUBSCRIBERS_SUCCESS,
      payload: { ...subscribers },
    });
  } catch (error) {
    yield put({ type: EXPORT_COMMUNITY_SUBSCRIBERS_ERROR, payload: error });
  }
}

function* getCommunityMetrics() {
  try {
    const metrics = yield call(services.community.getCommunityMetrics);
    yield put({
      type: GET_COMMUNITY_METRICS_SUCCESS,
      payload: metrics,
    });
  } catch (error) {
    yield put({ type: GET_COMMUNITY_METRICS_ERROR, payload: error });
  }
}

function* subscribeToTheCommunity({ payload }) {
  try {
    const response = yield call(services.community.subscribe, payload);
    yield put({
      type: SUBSCRIBE_TO_THE_COMMUNITY_SUCCESS,
      payload: null,
    });
  } catch (error) {
    yield put({ type: SUBSCRIBE_TO_THE_COMMUNITY_ERROR, payload: error });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_COMMUNITY, getCommunity);
  yield takeLatest<any>(GET_COMMUNITY_SUBSCRIBERS, getGroupSubscribers);
  yield takeLatest<any>(EXPORT_COMMUNITY_SUBSCRIBERS, exportGroupSubscribers);
  yield takeLatest<any>(
    GET_COMMUNITY_SUBSCRIBERS_EMAILS,
    getGroupSubscribersEmails
  );
  yield takeLatest<any>(GET_COMMUNITY_METRICS, getCommunityMetrics);
  yield takeLatest<any>(SUBSCRIBE_TO_THE_COMMUNITY, subscribeToTheCommunity);
}
