import { CreateBy, Name, ChangeName } from "./styles";
import Image from "./../../../../components/Image";
import change from "./../../../../assets/change.svg";
import Input from "../../../../components/Form/Input";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
interface ComponentProps {
  onFocus;
  onBlur?;
  onChange?;
  fieldNumber;
  register;
  errors;
  labelForwardRef;
  userData;
  userStates;
  dispatch;
}

const Component = ({
  onFocus,
  onBlur,
  onChange,
  fieldNumber,
  register,
  errors,
  labelForwardRef,
  userData,
  userStates,
  dispatch,
  ...rest
}: ComponentProps) => {
  const rules = {
    required: true,
  };

  const name = "creator_name";

  const handleChange = () => {
    onChange(name);
  };

  const handleFocus = () => {
    onFocus(fieldNumber, name);
  };

  return (
    <CreateBy ref={labelForwardRef}>
      <Name withError={!!errors.creator_name}>
        Creado por{" "}
        <input
          name={name}
          id={name}
          type="text"
          placeholder="Ingresa tu nombre"
          value={userData?.expert?.name}
          onFocus={handleFocus}
          {...(register && {
            ...register(name, rules),
            onChange: (event) => {
              return register(name, rules).onChange(event);
            },
            onBlur: onBlur,
          })}
          {...rest}
        />
      </Name>

      <ChangeName htmlFor={name}>
        <Image src={change} width="16px" height="16px" alt={change} />
      </ChangeName>
    </CreateBy>
  );
};

const state = ({ userStore }) => {
  const { information } = userStore;

  const { data: userData, states: userStates } = information;

  return {
    userData,
    userStates,
  };
};

export default connect(state)(Component);
