import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  PayWall,
  PayWallContent,
  GroupInformation,
  Title,
  ImageWrapper,
  Image,
  Price,
  Frecuency,
  DesktopOwner,
  MobileOwner,
  SuscriptionEnded,
} from "./styles";
import dateHandler from "../../../../helpers/dates-hanlder";
import constants from "../../../../constants";
import { DiscountDto, Group, PlanDto } from "../../../../types";
import Loader from "../../../../components/Loader";
import Clock from "../../../../components/Clock";
import { getGroupBySlug } from "../../../../store/actions/group";
import Link from "../../../../components/Link";

import background1 from "../../../../assets/backgrounds/membership/background-1.svg";
import background2 from "../../../../assets/backgrounds/membership/background-4.svg";
import background3 from "../../../../assets/backgrounds/membership/background-5.svg";

const backgrounds = [background1, background2, background3];
const randomBg = Math.floor(Math.random() * backgrounds.length);

export interface ComponentProps {
  postSlug: string;
  group: Group;
}

export const Component = ({ postSlug, group }: ComponentProps) => {
  const [inscriptionEnded, setInscriptionEnded] = useState<boolean>();
  const [monthlyPlan, setMonthyPlan] = useState<PlanDto | undefined>(undefined);
  const [monthlyPlanDiscount, setMonthlyPlanDiscount] = useState<
    DiscountDto | undefined
  >(undefined);
  const dispatch = useDispatch();

  const getCurrency = () => {
    if (monthlyPlan?.currency) {
      return constants.currencies.filter(
        (currency) => currency?.iso === monthlyPlan?.currency
      )[0].label;
    }
  };

  useEffect(() => {
    !!postSlug && dispatch(getGroupBySlug(postSlug));
  }, [postSlug]);

  useEffect(() => {
    const isMembership = group?.group_type === "membership";
    const isExperience = group?.group_type === "experience";
    setMonthyPlan(
      group?.plans?.filter(
        (plan) =>
          (isMembership && plan.period_unit === "month") ||
          (isExperience && !plan.period_unit)
      )[0]
    );
  }, [group]);

  useEffect(() => {
    if (monthlyPlan?.discounts)
      setMonthlyPlanDiscount(monthlyPlan?.discounts[0]);
  }, [monthlyPlan]);

  if (!group) {
    return <Loader />;
  }

  return (
    <PayWall>
      <PayWallContent>
        <ImageWrapper>
          <Image
            src={group?.cover_pic?.url || backgrounds[randomBg]}
            onError={(event) =>
              (event.currentTarget.src = backgrounds[randomBg])
            }
          />
        </ImageWrapper>

        <GroupInformation>
          <DesktopOwner>
            Este contenido pertenece{" "}
            {group?.group_type === "membership"
              ? "a la membresía de"
              : "al taller de"}{" "}
            <strong>{group?.creator_name}</strong>
          </DesktopOwner>

          <MobileOwner>
            {group?.group_type === "membership" ? "Membresía" : group?.group_type === "experience" ? "Taller" : "Evento"} de{" "}
            <strong>{group?.creator_name}</strong>
          </MobileOwner>

          <Price withDiscount={!!monthlyPlanDiscount}>
            {getCurrency()}{" "}
            {monthlyPlan?.discounts && monthlyPlan?.discounts.length > 0
              ? (monthlyPlan?.price || 0) -
              (monthlyPlan?.discounts[0].amount || 0)
              : monthlyPlan?.price}
          </Price>

          <Frecuency>
            {group?.group_type === "membership" ? "por mes" : group?.group_type === "experience" ? "un pago" : "¡Es gratis!"}
          </Frecuency>

          <Link
            href={
              !dateHandler.isExpired(group.registration_closing_date)
                ? `/group/${group?.slug}?scrollTo=price`
                : `/${group.expert.username}`
            }
            options={{
              skin: "purple",
              size: "lg",
              type: "filled",
            }}
            style={{ width: "100%", maxWidth: "312px" }}
          >
            {!dateHandler.isExpired(group.registration_closing_date)
              ? "Inscribirme"
              : "Ver otras experiencias"}
          </Link>

          {!!group.registration_closing_date && (
            <>
              {!dateHandler.isExpired(group.registration_closing_date) ? (
                <Clock
                  style={{ marginTop: "6px" }}
                  date={group.registration_closing_date}
                  onEnd={() => window.location.reload()}
                  type="string"
                  prefix="Las inscripciones cierran en"
                />
              ) : (
                <SuscriptionEnded>Inscripciones finalizada</SuscriptionEnded>
              )}
            </>
          )}
        </GroupInformation>
      </PayWallContent>
    </PayWall>
  );
};

const state = ({ groupStore }) => {
  const { data: group } = groupStore.group;
  return { group };
};

export default connect(state)(Component);
