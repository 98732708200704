import styled from "styled-components";

export const Title = styled("h6")<{ marginBottom? }>`
  color: ${(props) => props.theme.colors.Black};
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  margin: 0px;
  letter-spacing: 0.25px;
  ${(props) => `margin-bottom: ${props?.marginBottom || 0}px`};

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 24px;
  }
`;
