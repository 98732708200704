import { combineReducers } from "redux";
import changeTracking from './change-tracking'
import changeUserEmail from './change-user-email'
import changeUserPassword from './change-user-password'
import changeUserPhone from './change-user-phone'
import changeUsername from './change-username'
import createNewExpert from './create-new-expert'
import subscriptionsManagerUrl from './get-subscriptions-manager-url'
import groups from './get-user-groups'
import information from './get-user-information'
import subscriptions from './get-user-subscriptions'
import updateExpertInformation from './update-expert'
import isLoggedIn from './user-is-logged-in'
import changeWhiteLabel from './change-white-label'
import changeUserAvatar from './change-user-avatar'

const reducer = combineReducers({
  changeTracking,
  changeUserEmail,
  changeUserPassword,
  changeUserPhone,
  changeUsername,
  changeWhiteLabel,
  changeUserAvatar,
  createNewExpert,
  subscriptionsManagerUrl,
  groups,
  information,
  subscriptions,
  updateExpertInformation,
  isLoggedIn,
});

export default reducer;
