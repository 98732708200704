import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
} from "react-router-dom";

import Tabs from "./components/Tabs";
import DashboardLayout from "../../../components/Layout/DashboardLayout";

import Preview from "./Preview";
import Members from "./Members";
import PostsList from "../Posts/List";
import PostsNew from "../Posts/New";
import PostsEdit from "../Posts/Edit";

import Events from "./Event";

import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Group as GroupDto } from "../../../types";
import { getCommunity } from "../../../store/actions/community";

interface ComponentProps {
  groups: GroupDto[];
  community: GroupDto;
  communityStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ groups, community, communityStates }: ComponentProps) => {
  const { path } = useRouteMatch();
  const [communityGroup, setCommunityGroup] = useState<GroupDto | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!groups && groups.length > 0) {
      setCommunityGroup(
        groups.filter((group) => group?.group_type === "community")[0]
      );
    }
  }, [groups]);

  useEffect(() => {
    if (!!communityGroup) {
      dispatch(getCommunity({ group_id: communityGroup?.id }));
    }
  }, [communityGroup]);

  return (
    <Switch>
      <Route
        exact
        path={`${path}/`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={1}>
            <Preview {...props} />
          </DashboardLayout>
        )}
      />

      <Route
        path={`${path}/members`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <Members {...props} />
          </DashboardLayout>
        )}
      />

      <Route path={`${path}/events`} component={Events} />

      <Route
        exact
        path={`${path}/:idGroup/posts/`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <PostsList {...props}>
              <Tabs active="library" />
            </PostsList>
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/posts/new`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <PostsNew {...props} groupType="community" />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/posts/:idPost`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <PostsEdit {...props} />
          </DashboardLayout>
        )}
      />

      <Route exact path={`${path}/*`} render={() => <Redirect to={"/404"} />} />
    </Switch>
  );
};

const state = ({ userStore, communityStore }) => {
  const { data: groups } = userStore.groups;
  const { data: community, states: communityStates } = communityStore.community;

  return { groups, community, communityStates };
};

export default connect(state)(Component);
