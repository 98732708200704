import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import constants from "../../../../constants";
import { getFreeSubscriptions } from "../../../../helpers/getFreeSubscriptions";
import { isSubscribed } from "../../../../helpers/isSubscribed";
import { Group, SessionInDto, User } from "../../../../types";
import Icon from "../../../../components/Icon";
import dateHandlder from "../../../../helpers/dates-hanlder";
import {
  Backdrop, Content, Avatar, H3, H2, Link, Hello, ChangeAccount, SignInLink, ChangeAccountLink, AccountSignIn
} from './styles'

import warningIcon from "../../../../assets/icons/warning.svg";
import { Center } from "../../../../components/StyledComponents";
import { CSSProperties } from "styled-components";
import { signOut } from "../../../../store/actions/auth";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { Post } from "../../../../types/post";

interface ComponentProps {
  userInformation: User
  userSubscriptions
  isLoggedIn: Boolean;
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  post: Post;
  postStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  session: SessionInDto;
  sessionStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  currentPage: string;
  style?: CSSProperties
  chat?: boolean
}

const Component = ({
  group,
  groupStates,
  post,
  postStates,
  session,
  sessionStates,
  userInformation,
  userSubscriptions,
  isLoggedIn,
  currentPage,
  style,
  chat = false
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [suscriptionEnded, setSuscriptionEnded] = useState<boolean>();
  const { isMobile }  = useWindowSize();

  const isOwnGroup = useMemo(() => {
    const expertId = group?.expert?.id || session?.group?.expert?.id  || post?.group?.expert?.id 
    return !!expertId && !!userInformation?.expert?.id && expertId === userInformation?.expert?.id;
  }, [group, post, session, userInformation]);

  const pageText = {
    "CHAT": "Chat Exclusivo",
    "GROUP_DETAILS_ACTIVITY_SUMMARY": "Contenido Exclusivo",
    "GROUP_DETAILS_LIBRARY": "Biblioteca Exclusiva",
    "GROUP_DETAILS_SESSIONS": "Encuentros Exclusivos"
  }

  const isUserAuthenticated = useMemo(() => {
    return isLoggedIn || group?.group_type === 'free_experience'
  }, [isLoggedIn, group])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(userSubscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [userSubscriptions, group, isLoggedIn])

  useEffect(() => {
    if (!!group?.registration_closing_date) {
      setSuscriptionEnded(
        dateHandlder.isExpired(group?.registration_closing_date)
      );
    }
  }, [group]);

  if (isUserSubscribed || isOwnGroup) {
    return <></>
  }

  return (
    <Backdrop style={style}>
      <Content>
        {isUserAuthenticated ? (<div style={{ height: isMobile ? 20 : 40 }} />) : (<div style={{ height: isMobile ? 35 : 70 }} />)}

        {isUserAuthenticated && (
          <>
            <Hello>
              Hola! 👋​ {userInformation?.email}
            </Hello>

            <div style={{ height: isMobile ? 20 : 35 }} />
          </>
        )}

        <Avatar>
          <img src={group?.expert?.profile_pic?.url} alt="profile pic" />
        </Avatar>

        <div style={{ height: 15 }} />

        <H3>
          {group?.group_name}
        </H3>

        <div style={{ height: isMobile ? 20 : 35 }} />

        <H2>
          {chat ? pageText['CHAT'] : pageText[currentPage]} para suscriptores pagos
        </H2>

        <div style={{ height: isMobile ? 25 : 35 }} />

        <Link
          href={`/group/${group?.slug}?scrollTo=price`}
          options={{
            type: "filled",
            size: "lg",
            skin: "purple",
            block: true,
          }}
          target="_self"
        >
          {group?.group_type === "membership" && (
            <>{suscriptionEnded ? "Suscripción finalizada" : `Suscribirme`}</>
          )}

          {group?.group_type !== "membership" && (
            <>{suscriptionEnded ? "Inscripción finalizada" : `Inscribirme`}</>
          )}
        </Link>

        <div style={{ height: isMobile ? 25 : 35 }} />

        {isUserAuthenticated ? (
          <>
            <ChangeAccount>
              <Center horizontal="left" vertical="center" style={{ maxWidth: 270 }} gap={8}>
                <Icon icon={warningIcon} color="rgba(45,52,54,.75)" size="24px" />
                <div style={{ textAlign: 'left', fontSize: 12 }}>
                  Si ya estas suscripto, es posible que
                  hayas efectuado el pago con otro email 
                  o que tu suscripción se haya cancelado
                </div>
              </Center>

              <div style={{ height: 2 }} />

              <ChangeAccountLink
                onClick={() => dispatch(signOut())}
                type="button"
                options={{
                  type: "outline",
                  size: "lg",
                  skin: "purple",
                }}
              >
                Cambiar de cuenta
              </ChangeAccountLink>
            </ChangeAccount>
          </>
        ) : (
          <AccountSignIn>
            ¿Ya te suscribiste?

            <SignInLink
              href={constants.urls.subscriber.signin.url}
              options={{
                type: "outline",
                size: "lg",
                skin: "purple",
              }}
              target="_self"
            >
              Iniciar Sesión
            </SignInLink>
          </AccountSignIn>
        )}

        {isUserAuthenticated ? (<div style={{ height: isMobile ? 25 : 35}} />) : (<div style={{ height: isMobile ? 35 : 70}} />)}
      </Content>
    </Backdrop>
  );
};

const state = ({ userStore, postStore, sessionStore, groupStore, pageStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: session, states: sessionStates } = sessionStore.one;
  const { data: post, states: postStates } = postStore.post;
  const { data: userInformation } = userStore.information;
  const { data: userSubscriptions } = userStore.subscriptions;
  const { isLoggedIn } = userStore.isLoggedIn;


  return {
    group,
    post,
    session,
    groupStates,
    sessionStates,
    postStates,
    userInformation,
    userSubscriptions,
    isLoggedIn,
    currentPage: pageStore?.name,
  };
};

export default connect(state)(Component);