import { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  DragDropContext,
  Droppable,
  DroppableProps,
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult
} from "react-beautiful-dnd";

import PostOrderCard from '../../../../components/PostOrderCard'

import Sidebar from "../../../../../../components/Sidebar";
import Button from "../../../../../../components/Button";
import Input from "../../../../../../components/FormikForm/Input";
import TextArea from '../../../../../../components/FormikForm/TextArea';
import Submit from "../../../../../../components/FormikForm/Submit";
import Snackbar from "../../../../../../components/Snackbar";

import { initialValues, schema } from '../../../../../../constants/forms/category/edit'

import { getCategory, reorderCategoryPosts, resetUpdateCategory, updateCategory } from '../../../../../../store/actions/category'
import { CategoryDto } from '../../../../../../types/category';
import { Group } from '../../../../../../types';
import { GetAllPostInDto, GetPostsOutDto, Post } from '../../../../../../types/post';

import {
  Description,
  List,
  ListItem,
  Preview
} from './styles'
import { getPosts, reorderFeaturedPosts, resetSetFeaturedPost } from '../../../../../../store/actions/post';


export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

interface ComponentProps {
  group: Group
  groupStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  featuredPosts: GetAllPostInDto
  featuredPostsStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  reorderedPosts: Post[]
  reorderPostsStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  onClose: any
  isVisible: boolean
}

const Component = ({
  group,
  groupStates,
  featuredPosts,
  featuredPostsStates,
  reorderedPosts,
  reorderPostsStates,
  onClose,
  isVisible,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [posts, setPosts] = useState<Post[]>([])

  const reorder = () => {
    dispatch(reorderFeaturedPosts({ idGroup: group?.id, posts }));
  };

  const onDragEnd = (payload: DropResult) => {
    const { source, destination } = payload;

    if (!destination) {
      return;
    }

    const result: Post[] = Array.from(posts);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);

    const newOrder = result.map((item: Post, index) => {
      return {
        ...item,
        pinned_order: index,
      };
    });

    setPosts(newOrder);
  };

  useEffect(() => {
    if (featuredPosts?.data?.length > 0) {
      const orderedPosts = featuredPosts?.data
        ?.sort(function (a, b) {
          return a.pinned_order - b.pinned_order;
        })

      setPosts(orderedPosts || []);
    }
  }, [featuredPosts]);

  useEffect(() => {
    if (reorderPostsStates.success) {
      !!onClose && onClose();
      setTimeout(() => {
        dispatch(resetUpdateCategory());
      }, 3500);
    }
  }, [reorderPostsStates]);

  return <>
    <Sidebar
      position="right"
      visible={isVisible}
      closeType="back"
      onClose={() => {
        !!onClose && onClose();
      }}
      width="463px"
      title="Ordenar contenido destacado"
    >

      <Preview>
        <Description>Mueve y arrastra los bloques para ordenar los contenidos destacados, luego toca en "Guardar Orden"</Description>

        <div style={{ height: 30 }} />


        <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
          {/* <Button
            disabled={allCategoriesState.loading}
            options={{
              type: "outline",
              skin: "purple",
              size: "lg",
              block: true,
            }}
            onClick={() => onClose()}
          >
            Cancelar
          </Button> */}

          <Button
            disabled={reorderPostsStates.loading}
            loading={reorderPostsStates.loading}
            options={{
              type: "filled",
              skin: "purple",
              size: "lg",
              block: true,
            }}
            onClick={() => reorder()}
          >
            Guardar Orden
          </Button>
        </div>

        <div style={{ height: 30 }} />

        <DragDropContext onDragEnd={onDragEnd}>
          <StrictModeDroppable droppableId={'category-drag-and-drop'}>
            {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
              <List key={'category-drag-and-drop'} {...provided.droppableProps} ref={provided.innerRef}>

                {posts.map((post, index) => (
                  <>
                    <Draggable
                      key={"post-" + post.id}
                      draggableId={"post-" + post.id}
                      index={index}
                      isDragDisabled={String(post?.id) === "without_post"}
                    >
                      {(
                        providedDraggable: DraggableProvided,
                        snapshotDraggable: DraggableStateSnapshot
                      ) => (
                        <ListItem
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                          ref={providedDraggable.innerRef}
                          style={{
                            ...providedDraggable?.draggableProps?.style,
                            left: 50
                            // Add any other styles as needed
                          }}
                        >
                          <PostOrderCard post={post} showActions={false} />
                        </ListItem>

                      )}
                    </Draggable>
                  </>
                ))}

                {provided.placeholder}
              </List>
            )}
          </StrictModeDroppable>
        </DragDropContext>

        <div style={{ height: 30 }} />
      </Preview>

    </Sidebar>

    <Snackbar
        visible={(reorderPostsStates.success || !!reorderPostsStates.error || reorderPostsStates.loading)}
        options={{
          time: 1000,
          type: reorderPostsStates.success ? "success" : (reorderPostsStates.loading ? "info" : "error"),
        }}
        // onHide={() => dispatch(resetReorder())}
      >
        {reorderPostsStates.success && "Artículos Reordenados exitosamente!"}
        {reorderPostsStates.error && "Error al Reordenar artículos"}
    </Snackbar>
  </>
}

const state = ({ groupStore, postStore }) => {
  const { data: group, states: groupStates } =
    groupStore.group;
  const { data: reorderedPosts, states: reorderPostsStates } = postStore.reorderFeaturedPosts;
  const { data: featuredPosts, states: featuredPostsStates } =
    postStore.featuredPosts;

  return {
    group,
    groupStates,
    featuredPosts,
    featuredPostsStates,
    reorderedPosts,
    reorderPostsStates
  };
};

export default connect(state)(Component);