import {
  initialState,
  Actions,
  State,
  NEW_TELEGRAM_GROUP,
  NEW_TELEGRAM_GROUP_SUCCESS,
  NEW_TELEGRAM_GROUP_ERROR,
} from "../../types/group/new-telegram-group";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case NEW_TELEGRAM_GROUP:
      return {
        ...state,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case NEW_TELEGRAM_GROUP_SUCCESS:
      return {
        ...state,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case NEW_TELEGRAM_GROUP_ERROR:
      return {
        ...state,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default reducers;
