import { SessionInDto } from "../../../types/session.dto";

export const GET_ALL_SESSIONS = "GET_ALL_SESSIONS";
export const GET_ALL_SESSIONS_SUCCESS = "GET_ALL_SESSIONS_SUCCESS";
export const GET_ALL_SESSIONS_ERROR = "GET_ALL_SESSIONS_ERROR";
export const RESET_GET_ALL_SESSIONS = "RESET_GET_ALL_SESSIONS";

export interface GetAllSessions {
  type: typeof GET_ALL_SESSIONS;
  payload: { idGroup: string | number };
}

export interface GetAllSessionsSuccess {
  type: typeof GET_ALL_SESSIONS_SUCCESS;
  payload: SessionInDto[];
}

export interface GetAllSessionsError {
  type: typeof GET_ALL_SESSIONS_ERROR;
  payload: string | boolean;
}

export interface ResetGetAllSessions {
  type: typeof RESET_GET_ALL_SESSIONS;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetAllSessions
  | GetAllSessionsSuccess
  | GetAllSessionsError
  | ResetGetAllSessions;
