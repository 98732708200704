import { LineItem } from "chargebee-typescript/lib/resources/invoice";
import { Link as NativeLink } from "react-router-dom";
import styled from "styled-components";

export const SectionTitle = styled("h2")`
  // margin: 0px 0px 0px 0px;
  // font-size: 18px;
  // font-weight: 600;

  margin: 0px;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
`;

export const SectionDescription = styled("p")`
  margin: 0px 0px 0px 0px;
  font-size: 14px;
  font-weight: normal;
`;

export const Line = styled('div')`
  height: 2px;
  background-color: rgb(214 214 214 / 50%);
`

export const Statistics = styled("div")`
  background-color: #fff;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  min-height: 175px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 20px 0;
    flex-direction: row;
  }
`;



export const Statistic = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    padding: 0px 32px;
  }

  &:not(:last-child) {
    &:after {
      content: "";
      display: block;
      background-color: rgba(196, 196, 196, 1);
      width: 100%;
      height: 1px;
      position: absolute;
      right: 0px;
      bottom: 0px;

      @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
        top: 0px;
        width: 1px;
        height: 100%;
      }
    }
  }
`;

export const StatisticHeader = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: ${(props) => props.theme.mediaBreakpoints.smMax}) {
    align-items: center;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-direction: column;

    // @media (max-width: ${(props) => props.theme.mediaBreakpoints.lgMax}) {
    //   flex-direction: row;
    // }
  }
`;

export const Title = styled("h3")`
  margin: 0px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 19px;
  color: rgba(45, 52, 54, 0.75);
  max-width: 245px;
`;

export const Image = styled("img")`
  display: block;
  margin-right: 15px;
`;

export const Value = styled("div")`
  font-weight: 500;
  font-size: 53px;
  line-height: 70px;
  //color: rgba(108, 92, 231, 1);
  color: #596171;
  text-align: center;
  width: 100%;
`;

export const SubValue = styled("div")`
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  width: 100%;
`;

export const SubMetric = styled("div")`
  text-align: center;
`;

export const ComparisonBox = styled("div") <{ isGreatherThanPreviousPeriod?: boolean }>`
  margin-bottom: 2px;
  background-color: ${(props) => (props.isGreatherThanPreviousPeriod ? "#d1fab3" : "#fde9ee")};
  box-sizing: border-box;
  color: ${(props) => (props.isGreatherThanPreviousPeriod ? "#217005" : "#c0123c")};
  padding: 1px 6px 1px 6px;
  border: ${(props) => (props.isGreatherThanPreviousPeriod ? "1px solid #a8f170" : "1px solid #fbd3dc")};
  border-radius: 5px;
  font-size: 14px;
  line-height: 14px;
  display: inline-block; /* Para ajustar el ancho automáticamente al contenido */
  width: auto; /* Ajusta el ancho automáticamente */
  text-align: center; /* Para centrar el contenido horizontalmente */
  vertical-align: middle; /* Para centrar el contenido verticalmente */
`;

export const PreviousPeriod = styled("span")`
  font-size: 14px;
  font-weight: 350;
  color: #596171;
  text-align: center; /* Para centrar el contenido horizontalmente */
`;

export const CurrencyText = styled("span")`
  font-size: 10px;
  color: #596171;
  font-weight: 400;
  text-align: center; /* Para centrar el contenido horizontalmente */

`;

export const EmptyState = styled("div")`
  padding: 12px 16px;
  background: #fafafa;
  border-radius: 6px;
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;

  p {
    font-size: 12px;
    line-height: 14px;
    margin: 0px 0px 4px;
  }
`;

export const Link = styled(NativeLink)`
  font-size: 14px;
  line-height: 17px;
  color: #6c5ce7;
  text-decoration: underline;
`;

export const Button = styled("button")`
  font-size: 14px;
  line-height: 17px;
  color: #6c5ce7;
  text-decoration: underline;
  padding: 0px;
  margin: 0px;
  border: 0px;
  background: none;
  cursor: pointer;
`;
