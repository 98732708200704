import {
  ReferentsCard,
  CardTitle,
  CardsWrapper,
  Cards,
  Card,
  Image,
  Title,
} from "./styles";
import Food from "../../../../assets/home-page/alimento-saludable.webp";
import Astrology from "../../../../assets/home-page/astrologia.webp";
import Coaching from "../../../../assets/home-page/coaching.webp";
import Fitness from "../../../../assets/home-page/fitness.webp";
import Mindfulness from "../../../../assets/home-page/mindfulness.webp";
import Yoga from "../../../../assets/home-page/yoga.webp";
import Grid from "../../../../components/Grid";
import Row from "../../../../components/Grid/Row";
import Col from "../../../../components/Grid/Col";
import { useWindowSize } from "../../../../hooks/useWindowSize";

const Component = () => {
  const { isMobile } = useWindowSize();
  return (
    <ReferentsCard>
      <Grid.Container>
        <Row>
          <Col offset={{ md: 1 }} md={10}>
            <Row>
              <Col>
                <Title>
                  Nuestra plataforma es utilizada por referentes del bienestar
                  como tú
                </Title>
              </Col>
            </Row>

            <CardsWrapper>
              {isMobile && (
                <Col>
                  <Cards>
                    <Card>
                      <CardTitle className="text">Salud</CardTitle>
                      <Image src={Food} alt="alimento" width="" height="" lazy={true}/>
                    </Card>
                    <Card>
                      <CardTitle className="text">Astrologia</CardTitle>
                      <Image
                        src={Astrology}
                        alt="astrologia"
                        width=""
                        height=""
                        lazy={true}
                      />
                    </Card>
                  </Cards>
                  <Cards>
                    <Card>
                      <CardTitle className="text">Coaching</CardTitle>
                      <Image
                        src={Coaching}
                        alt="entrenamiento"
                        width=""
                        height=""
                        lazy={true}
                      />
                    </Card>
                    <Card>
                      <CardTitle className="text">Fitness</CardTitle>
                      <Image src={Fitness} alt="aptitud" width="" height="" lazy={true}/>
                    </Card>
                  </Cards>
                  <Cards>
                    <Card>
                      <CardTitle className="text">Consciencia</CardTitle>
                      <Image
                        src={Mindfulness}
                        alt="consciencia"
                        width=""
                        height=""
                        lazy={true}
                      />
                    </Card>
                    <Card>
                      <CardTitle className="text">Yoga</CardTitle>
                      <Image src={Yoga} alt="yoga" width="" height="" lazy={true}/>
                    </Card>
                  </Cards>
                </Col>
              )}
              {!isMobile && (
                <Col>
                  <Cards>
                    <Card>
                      <CardTitle className="text">Salud</CardTitle>
                      <Image src={Food} alt="alimento" width="" height="" lazy={true} />
                    </Card>
                    <Card>
                      <CardTitle className="text">Astrologia</CardTitle>
                      <Image
                        src={Astrology}
                        alt="astrologia"
                        width=""
                        height=""
                        lazy={true}
                      />
                    </Card>
                    <Card>
                      <CardTitle className="text">Coaching</CardTitle>
                      <Image
                        src={Coaching}
                        alt="entrenamiento"
                        width=""
                        height=""
                        lazy={true}
                      />
                    </Card>
                  </Cards>
                  <Cards>
                    <Card>
                      <CardTitle className="text">Fitness</CardTitle>
                      <Image src={Fitness} alt="aptitud" width="" height="" lazy={true} />
                    </Card>
                    <Card>
                      <CardTitle className="text">Consciencia</CardTitle>
                      <Image
                        src={Mindfulness}
                        alt="consciencia"
                        width=""
                        height=""
                        lazy={true}
                      />
                    </Card>
                    <Card>
                      <CardTitle className="text">Yoga</CardTitle>
                      <Image src={Yoga} alt="yoga" width="" height="" lazy={true}/>
                    </Card>
                  </Cards>
                </Col>
              )}
            </CardsWrapper>
          </Col>
        </Row>
      </Grid.Container>
    </ReferentsCard>
  );
};

export default Component;
