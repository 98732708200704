import Grid from "../../../../../components/Grid";
import {
  Schedule,
  Meetings,
  Meeting,
  Time,
  Title,
  Detail,
  By,
  Description,
} from "./styles";

const Component = () => {
  return (
    <Schedule id="cronograma">
      <Grid.Container>
        <Grid.Row>
          <Grid.Col lg={10} offset={{ lg: 1 }}>
            <Meetings>
              <Meeting>
                <Time>8:00</Time>
                <Detail>
                  <Title>¡Bienvenidos al congreso!</Title>
                  <By>Leandro Rosenthal</By>
                  <Description>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam et purus a odio finibus bibendum in sit amet leo.
                    Mauris feugiat erat tellus.
                  </Description>
                </Detail>
              </Meeting>

              <Meeting>
                <Time>9:00</Time>
                <Detail>
                  <Title>¡Bienvenidos al congreso!</Title>
                  <By>Leandro Rosenthal</By>
                  <Description>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam et purus a odio finibus bibendum in sit amet leo.
                    Mauris feugiat erat tellus.
                  </Description>
                </Detail>
              </Meeting>

              <Meeting>
                <Time>10:00</Time>
                <Detail>
                  <Title>¡Bienvenidos al congreso!</Title>
                  <By>Leandro Rosenthal</By>
                  <Description>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam et purus a odio finibus bibendum in sit amet leo.
                    Mauris feugiat erat tellus.
                  </Description>
                </Detail>
              </Meeting>

              <Meeting>
                <Time>11:00</Time>
                <Detail>
                  <Title>¡Bienvenidos al congreso!</Title>
                  <By>Leandro Rosenthal</By>
                  <Description>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam et purus a odio finibus bibendum in sit amet leo.
                    Mauris feugiat erat tellus.
                  </Description>
                </Detail>
              </Meeting>

              <Meeting>
                <Time>12:00</Time>
                <Detail>
                  <Title>¡Bienvenidos al congreso!</Title>
                  <By>Leandro Rosenthal</By>
                  <Description>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam et purus a odio finibus bibendum in sit amet leo.
                    Mauris feugiat erat tellus.
                  </Description>
                </Detail>
              </Meeting>

              <Meeting>
                <Time last>13:00</Time>
                <Detail>
                  <Title>¡Bienvenidos al congreso!</Title>
                  <By>Leandro Rosenthal</By>
                  <Description>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam et purus a odio finibus bibendum in sit amet leo.
                    Mauris feugiat erat tellus.
                  </Description>
                </Detail>
              </Meeting>
            </Meetings>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Schedule>
  );
};

export default Component;
