import styled from 'styled-components'

export const Card = styled('div')`
  background-color: #EBFFEE;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const Title = styled('div')`
display: flex;
aling-items: center;
justify-content: flex-start;
font-size: 22px;
font-weight: 500;
text-align: center;

p {
    margin: 3px 0px;
}
`
export const Text = styled('div')`
text-align: center;
`