import { Formik } from "formik";
import Input from "../../../../../../components/FormikForm/Input";
import Submit from "../../../../../../components/FormikForm/Submit";
import Modal from "../../../../../../components/Modal";
import { Error, Warning } from "../../../../../../components/FormikForm/File/components/Dropzone/styles";
import {
  schema,
  initialValues,
} from "../../../../../../constants/forms/change-user-email";
import congrats from "./../../../../../../assets/icons/congrats.svg";
import { connect, useDispatch } from "react-redux";
import {
  changeUserEmail,
  resetChangeUserEmail,
} from "../../../../../../store/actions/user";
import { Congrats } from "./styles";
import { signOut } from "../../../../../../store/actions/auth";
import { Link } from "react-router-dom";
import user from "../../../../../../services/user";

interface ComponentProps {
  userInformation;
  userInformationStates: {
    loading: boolean;
    error: boolean | string;
  };
  changeUserEmailStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

const Component = ({
  userInformation,
  userInformationStates,
  changeUserEmailStates,
}: ComponentProps) => {
  const dispatch = useDispatch();

  const onFormSubmit = ({ values, actions }) => {
    dispatch(changeUserEmail({ ...values, userId: userInformation?.id }));
  };

  return (
    <Modal
      width="500"
      name="change-email"
      title={changeUserEmailStates.success ? "" : "Ingresa tu nuevo email"}
      onClose={() => dispatch(resetChangeUserEmail())}
    >
      {changeUserEmailStates.success ? (
        <Congrats>
          <img
            src={congrats}
            alt="Congratulations"
            width="114px"
            height="114px"
          />
          <p>E-mail Actualizado!</p>
          <span>El nuevo correo de tu cuenta es:</span>
          <span><strong>{userInformation?.email}</strong></span>
        </Congrats>
      ) : (
        <Formik
          initialValues={{
            ...initialValues,
            email: userInformation?.email,
          }}
          onSubmit={(values, actions) => {
            if (onFormSubmit) onFormSubmit({ values, actions });
          }}
          validateOnChange={true}
          validateOnBlur={false}
          validationSchema={schema}
          enableReinitialize
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            isSubmitting,
            handleBlur,
            handleReset,
            handleSubmit,
          }) => {
            return (
              <form
                className="theme-form"
                onSubmit={handleSubmit}
                id="change-email-form"
              >
                <Input
                  name="email"
                  touched={touched["email"]}
                  error={errors["email"]}
                  value={values["email"]}
                  type="text"
                  disabled={changeUserEmailStates.loading}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={`${userInformation?.email}`}
                  options={{
                    marginBottom: changeUserEmailStates.error ? 15 : 42 ,
                  }}
                />

                {changeUserEmailStates.error ===
                  "auth/email-already-in-use" && (
                  <Error>Ya existe una cuenta con este e-mail. Para acceder, puedes cerrar sesion de esta cuenta e intentar recuperar la contraseña!</Error>
                )}
                {changeUserEmailStates.error === "auth/too-many-requests" && (
                  <Error>
                    Espera unos minutos antes de volver a intentarlo.
                  </Error>
                )}
                {(changeUserEmailStates.error ===
                  "auth/requires-recent-login" || changeUserEmailStates.error === "CREDENTIAL_TOO_OLD_LOGIN_AGAIN") && (
                  <Warning style={{"marginBottom": "10px"}}>
                    Para poder realizar esta operación necesitamos que primero cierres sesión de tu cuenta y vuelvas ingresar (con tu email {userInformation.email}). Para cerrar sesión puedes hacer click aquí: {" "}
                    <Link
                      to="#"
                      onClick={() => dispatch(signOut())}
                    >
                      Cerrar sesión
                    </Link>
                  </Warning>
                )}

                <Submit
                  isSubmmiting={changeUserEmailStates.loading}
                  form="change-email-form"
                  options={{
                    skin: "purple",
                    size: "lg",
                    type: "filled",
                    block: true,
                  }}
                >
                  Cambiar E-mail
                </Submit>
              </form>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
};

const state = ({ userStore }) => {
  const { data: userInformation, states: userInformationStates } =
    userStore.information;
  const { states: changeUserEmailStates } = userStore.changeUserEmail;

  return {
    userInformation,
    userInformationStates,
    changeUserEmailStates,
  };
};

export default connect(state)(Component);
