import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import { useDispatch, connect } from "react-redux";
import { getExpertGroups } from "../../../store/actions/expert";

import { Title } from "./styles";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Col from "../../../components/Grid/Col";

import GroupCard from "../../../components/ExpertGroupCard";
import Loader from "../../../components/LogoLoader";
import { useWindowSize } from "../../../hooks/useWindowSize";
import constants from "../../../constants";

const removeCongresoGroups = (groups) => {
  const congreso = constants.congreso.map((items) => items.id);
  return groups.filter((group) => !congreso.flat().includes(group?.id));
};

const Groups = ({ groups, expert }) => {
  const filteredGroups = removeCongresoGroups(groups);

  const itemsPerRow = {
    isMobile: 1,
    isTabletLandscape: 3,
    isTabletPortrait: 2,
    isDesktop: 4,
  };

  const { isTabletPortrait, isTabletLandscape, isDesktop } = useWindowSize();
  let windowSize = "isMobile";
  windowSize = isTabletPortrait ? "isTabletPortrait" : windowSize;
  windowSize = isTabletLandscape ? "isTabletLandscape" : windowSize;
  windowSize = isDesktop ? "isDesktop" : windowSize;

  const responsiveItemsPerRow = itemsPerRow[windowSize];
  const totalRows = Math.ceil(filteredGroups.length / responsiveItemsPerRow);
  const rows: any[] = [];

  for (let i = 0; i < totalRows; i++) {
    rows.push(
      filteredGroups.slice(
        i * responsiveItemsPerRow,
        i * responsiveItemsPerRow + responsiveItemsPerRow
      )
    );
  }

  return (
    <>
      {rows.map((row, index1) => {
        return (
          <Row key={`row-groups-${index1}`}>
            {row.map((group, index2) => (
              <Col sm={6} md={4} lg={3} key={`col-groups-${index2}`}>
                <GroupCard
                  group={group}
                  expert={expert}
                  link={`/group/${group.slug}`}
                />
              </Col>
            ))}
          </Row>
        );
      })}
    </>
  );
};

const Component = ({ match, groups, expert, loading, error }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    match?.params?.username && dispatch(getExpertGroups(match.params.username));
  }, [match]);

  /**
   * If there is only one group, the user is redirected to the detail page of that group
   */
  if (groups?.length === 1) {
    // const groupSlug = slugify(groups[0].slug, { lower: true });
    return <Redirect to={`/group/${groups[0].slug}`} />;
  }

  if (loading) {
    return <Loader />;
  }

  if (!!error) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <Helmet>
        ‍<title>{`Klouser - Grupos de ${expert.showable_name}`}</title>‍
        <meta
          name="description"
          content={`Grupos de ${expert.showable_name}`}
        />
      </Helmet>
      <Container>
        <Row>
          <Col offset={{ sm: 1 }} sm={10}>
            <Row>
              <Col>
                <Title>Grupos de {expert.showable_name}</Title>
              </Col>
            </Row>

            {groups?.length > 0 ? (
              <Groups groups={groups} expert={expert} />
            ) : (
              <Row>
                <Col>
                  <h3>No se encontraron Grupos</h3>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

const state = ({ expertStore }) => {
  const { groups } = expertStore;
  const { data, states } = groups;
  const { experts_groups, ...rest } = data || {};
  return {
    ...states,
    expert: { ...rest },
    groups: experts_groups,
  };
};

export default connect(state)(Component);
