import { Tabs, Tab, Link, HorizontalScroll } from "../../../components/styles";
import Grid from "../../../../../components/Grid";

interface ComponentProps {
  active: string;
}

const Component = ({ active }: ComponentProps) => {
  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <HorizontalScroll>
            <Tabs>
              <Tab>
                <Link
                  to="/dashboard/coupon/list/active"
                  active={active === "active"}
                >
                  Activos
                </Link>
              </Tab>
              <Tab>
                <Link
                  to={`/dashboard/coupon/list/expired`}
                  active={active === "expired"}
                >
                  Expirados
                </Link>
              </Tab>
            </Tabs>
          </HorizontalScroll>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default Component;
