import styled from "styled-components";

export const SectionTitle = styled("h2")`
  margin: 32px 0px 16px 0px;
  font-size: 18px;
  font-weight: normal;
`;

export const Beginning = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

export const Breadcrumb = styled.div`
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

// export const Title = styled.h1`
//   font-weight: 600;
//   font-size: 20px;
//   line-height: 24px;
//   margin: 0px 0px 24px 0px;

//   @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
//     font-weight: 600;
//     font-size: 36px;
//     line-height: 44px;
//     margin: 0px 0px 48px;
//   }
// `;
export const InLine = styled("div")`
  flex-shrink: 0;
  display: flex;
`;

export const TitleCard = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0px 0px 20px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: 0px 0px 36px;
    text-align: left;
  }
`;
