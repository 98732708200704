import {
  Start,
  Image,
  Title,
  StepOne,
  StepTwo,
  StepThree,
  Img,
  TitleStep,
  LinkItem,
  Link,
  Information,
} from "./styles";
import Woman from "../../../../assets/backgrounds/pages/girl-chatting-with-friends.svg";
import Talking from "../../../../assets/dashboard-home/small-team-discussing.svg";
import Congratulation from "../../../../assets/congratulation.svg";
import IsoLogo from "../../../../assets/home-page/iso-logo.svg";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import NewMeet from "../../components/NewMeet";
import Grid from "../../../../components/Grid";
import Row from "../../../../components/Grid/Row";
import Col from "../../../../components/Grid/Col";
import { scrollTo } from "../../../../helpers/scroll-to";

const Component = () => {
  const { isMobile } = useWindowSize();
  return (
    <Start>
      <Image src={IsoLogo} alt="" width="" height="" lazy={true} />
      <Grid.Container>
        <Row>
          <Col offset={{ lg: 2 }} lg={8}>
            <Title>¡Comienza hoy!</Title>
          </Col>
        </Row>

        <Row>
          <Col offset={{ sm: 3, md: 1 }} sm={6} md={10} lg={10}>
            <Row>
              <Col>
                <StepOne>
                  <Img src={Woman} alt="" width="" height="" lazy={true} />
                  <TitleStep>1. ¡Regístrate Gratis!</TitleStep>
                </StepOne>
              </Col>
              <Col>
                <StepTwo>
                  <Img src={Talking} alt="" width="" height="" lazy={true} />
                  <TitleStep>
                    2. Elige lanzar un Evento de pago unico o un Grupo de
                    suscripción
                  </TitleStep>
                </StepTwo>
              </Col>
              <Col>
                <StepThree>
                  <Img
                    src={Congratulation}
                    alt=""
                    width=""
                    height=""
                    lazy={true}
                  />
                  <TitleStep>
                    3. ¡Listo! Sólo cobraremos una pequeña comisión cuando
                    empieces a generar ingresos.
                  </TitleStep>
                </StepThree>
              </Col>
            </Row>
          </Col>
        </Row>

        <LinkItem>
          <Link to="/expert/signup">¡Crear mi Klouser gratis!</Link>
          <NewMeet skin="ligthPurple" />
        </LinkItem>

        <Information onClick={() => scrollTo({ id: "faqs-section" })}>
          Más información sobre comisiones{" "}
        </Information>
      </Grid.Container>
    </Start>
  );
};

export default Component;
