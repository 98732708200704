import { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Separator } from "../../../../../components/StyledComponents";
import { Group } from "../../../../../types";
import { GetPostsOutDto, Post } from "../../../../../types/post";
import CardAticle from "../../../../Dashboard/Posts/components/CardArticle";
import {
  CardsGrid,
} from "./styles";
import EmptyState from "../../../components/EmptyState";
import InfinityScroll from '../../../../../components/InfinityScroll'
import { getCategory } from "../../../../../store/actions/category";
import { CategoryDto } from "../../../../../types/category";
import { getPosts, resetGetPosts } from "../../../../../store/actions/post";
import slugify from "slugify";

interface ComponentProps {
  posts: { pagination: { total: number }; data: Post[] };
  postsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  category: CategoryDto;
}

const Component = ({
  posts,
  postsStates,
  group,
  category,
}: ComponentProps) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [thereAreItems, setThereAreItems] = useState(true)
  const [mergedItems, setMergedItems] = useState<Post[]>([])
  // const [totalElements, setTotalElements] = useState<number>(0)
  const [startPagination, setStartPagination] = useState<boolean>(false)


  useEffect(() => {
    setMergedItems([])
    setPage(1)
    dispatch(resetGetPosts())


    if (category?.id?.toString() === "without_category") {
      fetchData({ page: 1, without_category: true });
    } else {
      fetchData({ page: 1, content_categories: category?.id?.toString() });
    }


    return () => {
      dispatch(resetGetPosts())
      setMergedItems([])
    }
  }, [])

  const fetchData = ({ page, content_categories, without_category }: { page: number, content_categories?: string, without_category?: boolean }) => {
    if (!postsStates.loading) {
      setStartPagination(true)
      let payload: GetPostsOutDto = {
        filters: { group_id: group?.id, show_scheduled_post: true, ...(content_categories ? { content_categories } : {}), ...(without_category ? { without_category: true } : {}) }, pagination: {
          page, per_parge: 12,
          _sort: "desc",
          _sort_by: category?.id?.toString() === "without_category" ? 'published_at' : 'post_order'
        }
      };
      dispatch(getPosts(payload));
    }
  }

  /** Paginate with the already selected filter */
  const paginate = (page) => {
    if (category?.id?.toString() === "without_category") {
      fetchData({ page: page, without_category: true });
    } else {
      fetchData({ page: page, content_categories: category?.id?.toString() });
    }
    // fetchData({ page: page, content_categories: category?.id.toString() });
  }

  useEffect(() => {
    if (!!posts?.data && startPagination) {
      const newItemsObj = [...mergedItems, ...posts.data]
      setMergedItems(newItemsObj);
      setThereAreItems(!!posts && posts?.data?.length > 0 && newItemsObj.length < posts?.pagination?.total);

      /** Setting the total of elements without filters */
      // if (!category?.id) {
      //   setTotalElements(posts.pagination.total)
      // }
    }
  }, [posts])



  return (
    <>
      {!postsStates.loading && !posts && mergedItems && mergedItems?.length <= 0 ? (
        <EmptyState text={
          <>
            <strong>Aún no se escribio ningún artículo.</strong>
            <br /> Aguarda a que se comience a crearlos.
          </>
        } />
      ) : (
        <>
          <InfinityScroll
            setPage={setPage}
            data={mergedItems}
            isLoading={postsStates.loading}
            page={page}
            action={paginate}
            active={thereAreItems}
          >
            {!!mergedItems && mergedItems?.length > 0 && (
              <>
                <CardsGrid>
                  {mergedItems?.map((post, index) => (
                    <CardAticle
                      style={{ marginBottom: 16 }}
                      post={post}
                      groupInformation={group}
                      showPinAction={false}
                      key={slugify(`${post.title}-${post.id}`)}
                      coverPicHeight={100}
                    />
                  ))}
                </CardsGrid>
                <div style={{ height: 16 }} />
              </>
            )}
          </InfinityScroll>

          <Separator height={{ desktop: "32px", mobile: "16px" }} />
        </>
      )}
    </>
  );
};

const state = ({ userStore, postStore, groupStore }) => {
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;
  const { data: userInformation, states: userInformationStates } =
    userStore.information;
  const { data: posts, states: postsStates } = postStore.listPosts;
  const { data: group, states: groupStates } = groupStore.group;

  return {
    subscriptions,
    subscriptionsStates,
    userInformation,
    userInformationStates,
    posts,
    postsStates,
    group,
    groupStates,
  };
};

export default connect(state)(Component);
