import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Icon from "../../../Icon";
import Sidebar from "../../../Sidebar";
import UserRoundedIcon from "../../../../assets/icons/user-rounded.svg";
import triangle from "../../../../assets/dashboard-menu/triangle.svg";
import constants from "../../../../constants";
import {
  SidebarContent,
  Navbars,
  Navbar,
  NavbarItem,
  NavbarItemWrapper,
  NavbarItemLabel,
  Profile,
  AvatarImage,
  Name,
  Username,
  Mark,
} from "./styles";
import slugify from "slugify";
import { useHistory } from "react-router-dom";
import { showPreference } from "../../../../helpers/preferenceVisible";
import { ghostLink } from "../../../../helpers/ghost-link";
import { User } from "../../../../types";
import { Preference } from "../../../../types/preferences";
import { Triangle } from "../DesktopMenu/styles";

interface ComponentProps {
  userInformation: User;
  userInformationStates;
  useLocation;
  preferences: Preference[];
  preferencesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  subscriptions;
  subscriptionsStates;
  menuIsOpened: boolean;
  setMenuIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const Component = ({
  userInformation,
  userInformationStates,
  useLocation,
  preferences,
  preferencesStates,
  subscriptions,
  subscriptionsStates,
  menuIsOpened,
  setMenuIsOpened,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeItem, setActiveItem] = useState("");
  const [availableMarks, setAvailableMarks] = useState<string[]>([]);

  const isExpert = useMemo((): boolean => {
    return !!userInformation?.expert && userInformationStates.success;
  }, [userInformation, userInformationStates]);

  const onClickHanlder = (menuItem) => {
    const redirect = menuItem.native;

    if (menuItem.url) {
      if (redirect) {
        history.push(menuItem.url);
      } else {
        ghostLink(menuItem.url);
      }

      setActiveItem(menuItem.url);
    }

    if (menuItem.action) {
      dispatch(menuItem.action());
    }

    setMenuIsOpened(false);
  };

  useEffect(() => {
    const marks = [
      ...constants?.dashboardMenu?.secondaryMenu,
      ...constants?.dashboardMenu?.mainMenu,
    ]
      .filter(
        (item) => !!item?.newFeatureMark && item?.newFeatureMark?.length > 0
      )
      .map((item) => item.newFeatureMark || [])
      .flat();

    if (marks.length > 0) {
      const availablesMarks = marks?.filter((mark) => {
        const condition = showPreference({
          preferences,
          preferencesStates,
          id: mark,
        });

        return condition;
      });

      setAvailableMarks(availablesMarks);
    }
  }, [preferences]);

  return (
    <Sidebar
      width="375px"
      position="right"
      closeType="close"
      visible={menuIsOpened}
      onClose={() => setMenuIsOpened(false)}
      title=""
      options={{ style: { padding: "13px 28px" } }}
    >
      <SidebarContent>
        <Profile>
          <AvatarImage
            src={userInformation?.expert?.profile_pic?.url || UserRoundedIcon}
            alt=""
            isOpened={menuIsOpened}
          />
          <div style={{ height: 11 }} />
          <Username isOpened={menuIsOpened}>
            {userInformation?.expert?.username || userInformation?.email?.split("@")[0]}
          </Username>
          <Name isOpened={menuIsOpened}>{userInformation?.name}</Name>
        </Profile>

        <div style={{ height: 40 }} />

        <Navbars>
          <Navbar style={{ marginBottom: "80px" }}>
            {constants.dashboardMenu.mainMenu
              .filter(
                (menuItem) =>
                  menuItem.active &&

                  //!(
                  //menuItem.label === "Cupones" &&
                  //userInformation.expert?.currency === "ARS"
                  //) &&

                  (!menuItem.expert ||
                    (menuItem.expert && !!userInformation?.expert)) &&
                  (menuItem.label !== "Mis suscripciones" ||
                    (menuItem.label === "Mis suscripciones" &&
                      !!subscriptions &&
                      subscriptions.length > 0))
              )
              .map((menuItem) => {
                const showMark = availableMarks.some(
                  (r) =>
                    menuItem?.newFeatureMark &&
                    menuItem?.newFeatureMark?.indexOf(r) >= 0
                );

                return (
                  <NavbarItem key={slugify(menuItem.label, { lower: true })}>
                    <NavbarItemWrapper
                      isOpened={menuIsOpened}
                      isActive={menuItem.url === activeItem}
                      onClick={() => onClickHanlder(menuItem)}
                      width={""}
                    >
                      {showMark && (
                        <Mark isActive={menuItem.url === activeItem} />
                      )}

                      <Icon
                        icon={menuItem.icon}
                        size="24px"
                        color={
                          menuItem.url === activeItem
                            ? "white"
                            : "rgba(45, 52, 54, 0.75)"
                        }
                      />

                      <NavbarItemLabel
                        className="main-menu-label"
                        isOpened={menuIsOpened}
                        isActive={menuItem.url === activeItem}
                      >
                        {menuItem.url === "/dashboard/community" && !isExpert
                          ? "Crear mi comunidad"
                          : menuItem.label}
                      </NavbarItemLabel>
                    </NavbarItemWrapper>
                  </NavbarItem>
                );
              })}
          </Navbar>

          <Navbar>
            {constants.dashboardMenu.secondaryMenu
              .filter((menuItem) => menuItem.active)
              .map((menuItem) => {
                const showMark = availableMarks.some(
                  (r) =>
                    menuItem?.newFeatureMark &&
                    menuItem?.newFeatureMark?.indexOf(r) >= 0
                );

                return (
                  <NavbarItem key={slugify(menuItem.label, { lower: true })}>
                    <NavbarItemWrapper
                      isOpened={menuIsOpened}
                      isActive={menuItem.url === activeItem}
                      onClick={() => onClickHanlder(menuItem)}
                      width={""}
                    >
                      {showMark && (
                        <Mark isActive={menuItem.url === activeItem} />
                      )}

                      <Icon
                        icon={menuItem.icon}
                        size="26px"
                        color={
                          menuItem.url === activeItem
                            ? "white"
                            : "rgba(45, 52, 54, 0.75)"
                        }
                      />
                      <NavbarItemLabel
                        className="main-menu-label"
                        isOpened={menuIsOpened}
                        isActive={menuItem.url === activeItem}
                      >

                        <Triangle
                          icon={triangle}
                          size="8px"
                          color="#6C5CE7"
                          isOpened={menuIsOpened}
                        />

                        {menuItem.label}
                      </NavbarItemLabel>
                    </NavbarItemWrapper>
                  </NavbarItem>
                );
              })}
          </Navbar>
        </Navbars>
      </SidebarContent>
    </Sidebar>
  );
};

const state = ({ userStore, preferencesStore }) => {
  const { data: userInformation, states: userInformationStates } =
    userStore.information;
  const { data: preferences, states: preferencesStates } =
    preferencesStore.preferences;
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;

  return {
    userInformation,
    userInformationStates,
    preferences,
    preferencesStates,
    subscriptions,
    subscriptionsStates,
  };
};

export default connect(state)(Component);
