import { connect, useDispatch } from "react-redux";
import { initialValues, schema } from "../../../../../../../constants/forms/coupon";
import Radio from "../../../../../../../components/FormikForm/Radio";
import Input from "../../../../../../../components/FormikForm/Input";
import ReactSelect from "../../../../../../../components/FormikForm/ReactSelect";
import { Group, PlanDto, User } from "../../../../../../../types";
import {
  newDiscount,
  resetNewDiscount,
} from "../../../../../../../store/actions/discount";
import {
  inputDate,
  inputDateTimeLocal,
  setToZeroTimeZone,
} from "../../../../../../../helpers/dates-hanlder";
import { useMemo, useState } from "react";
import { formatPrice } from "../../../../../../../helpers/price-formatter";
import moment from "moment";

interface Option {
  label: string;
  value: string | number;
}

const groupTypes = [
  { label: "Experiencia", value: "experience" },
  { label: "Membresía", value: "membership" },
];

interface ComponentProps {
  group: Group
  selectedPlan: PlanDto
  selectedPlanStates
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  isLoading: boolean
}

const Component = ({
  group,
  selectedPlan,
  selectedPlanStates,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  isLoading,
}: ComponentProps) => {
  const currency = useMemo(() => (selectedPlan?.currency), [selectedPlan]);
  const planPrice = useMemo(() => (`${selectedPlan?.currency} ${selectedPlan?.price}`), [selectedPlan]);


  return (
    <>
      <Input
        name="amount"
        error={errors["amount"]}
        touched={touched["amount"]}
        value={values['amount']}
        type="number"
        step="any"
        placeholder="0"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isLoading}
        options={{
          label: "Precio con descuento",
          before: currency,
          marginBottom: 24,
          helperText: `El valor con descuento debe ser menor a ${planPrice}`
        }}
      />

      {selectedPlan?.type === 'subscription' && (
        <>
          <Radio
            name="period_type"
            error={errors["period_type"]}
            touched={touched["period_type"]}
            value={values["period_type"]}
            items={[
              { value: "indefinido", label: "Aplicar descuento indefinidamente" },
              { value: "por-periodo", label: "Aplicar descuento para cierta cantidad de cobros" }
            ]}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isLoading}
            options={{
              label: "Duración del descuento",
              marginBottom: 40,
              vertical: true,
              helperText: "Indica cuantas veces se cobrara a tus subscriptores con precio promocional."
            }}
          />

          <Input
            name="period"
            error={errors["period"]}
            touched={touched["period"]}
            value={values["period"]}
            placeholder={values["period_type"] === "indefinido" || isLoading ? "-" : "0"}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={values["period_type"] === "indefinido" || isLoading}
            options={{
              label: "Aplicar durante los primeros...",
              after: "Cobros",
              marginBottom: 40,
            }}
          />
        </>
      )}

      <Radio
        name="valid_till_type"
        error={errors["valid_till_type"]}
        touched={touched["valid_till_type"]}
        value={values["valid_till_type"]}
        items={[
          { value: "indefinido", label: "Descuento sin fecha de expiración" },
          { value: "por-periodo", label: "Descuento con expiración en una fecha especifica" }
        ]}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isLoading}
        options={{
          label: "Expiración del descuento",
          marginBottom: 40,
          vertical: true,
          helperText: "Indica si quieres que el descuento este activo y visible de forma indefinida (hasta que decidas desactivarlo) o hasta una fecha y hora especificas."
        }}
      />

      <Input
        name="valid_till"
        error={errors["valid_till"]}
        touched={touched["valid_till"]}
        min={moment().format("YYYY-MM-DDThh:mm")}
        value={inputDateTimeLocal(values['valid_till'])}
        type="datetime-local"
        placeholder="dd/mm/aa"
        disabled={values["valid_till_type"] === "indefinido" || isLoading}
        onChange={handleChange}
        onBlur={handleBlur}
        options={{
          label: "Descuento activo hasta",
          explainText: "Indica con fecha y hora hasta cuando este descuento se encontrará activo en tu página de ventas.",
          marginBottom: 40,
        }}
      />

      <ReactSelect
        name="status"
        error={errors["status"]}
        touched={touched["status"]}
        items={[{ value: "active", label: "Activo" }, { value: "archived", label: "Inactivo" }]}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isLoading}
        options={{
          label: "Estado",
          marginBottom: 40,
          explainText: "Define el estado del descuento. Solo puedes tener un descuento activo a la vez",
          helperText: "Cambiando el estado puedes mostrar u ocultar el descuento para los suscriptores que visitan tu página de ventas. "
        }}
      />
    </>
  );
};

const state = ({ groupStore, planStore }) => {
  const { data: group } = groupStore.group;
  const { data: selectedPlan, states: selectedPlanStates } = planStore.selectedPlan;

  return {
    group,
    selectedPlan,
    selectedPlanStates,
  };
};

export default connect(state)(Component);
