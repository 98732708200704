import { connect, useDispatch } from "react-redux";
import { CustomDropdown as Dropdown, HorizontalSeparator } from "../styles";
import { Welcome, LinkItem, ButtonItem } from "./styles";
import { Toggle } from "../styles";
import { User } from "../../../../../../types/user";
import { signOut } from "../../../../../../store/actions/auth";
import hamburger from "../hamburger.svg";
import { useWindowSize } from "../../../../../../hooks/useFormHook";
import { useLayout } from "../../../../../../providers/LayoutProvider";
import Actions from "./components/Actions";

interface ComponentProps {
  userInformation: User;
}

const Component = ({ userInformation }: ComponentProps) => {
  const dispatch = useDispatch();
  const { layout } = useLayout();
  const { isDesktop } = useWindowSize();

  return (
    <>
      {/* <LoginMenu> */}
      {/* {layout?.header?.actions ? (
        <Actions>{layout?.header?.actions}</Actions>
      ) : (
        <>
          {isDesktop && (
            <Welcome>
              Hola{userInformation?.name && ` ${userInformation?.name}`}! 👋
            </Welcome>
          )}
        </>
      )} */}

      <Dropdown
        label={
          <Toggle skin={layout.skin}>
            <img src={hamburger} alt="hamburger" width="24px" height="24px" />
          </Toggle>
        }
      >
        <LinkItem marginBottom="16px" to="/profile/subscriptions">
          Mis suscripciones
        </LinkItem>

        {!!userInformation?.expert && (
          <LinkItem marginBottom="16px" to="/dashboard/community">
            Mi Comunidad
          </LinkItem>
        )}

        {!userInformation?.expert && (
          <LinkItem marginBottom="16px" to="/dashboard/community">
            Crear mi comunidad
          </LinkItem>
        )}

        <LinkItem marginBottom="0px" to="/profile/my-account">
          Configuración
        </LinkItem>

        <HorizontalSeparator isSeparator="16px" visible={true} />

        <ButtonItem
          alignSelf="center"
          marginBottom="0px"
          type="button"
          onClick={() => dispatch(signOut())}
        >
          Cerrar sesión
        </ButtonItem>
      </Dropdown>
      {/* </LoginMenu> */}
    </>
  );
};

const state = ({ userStore }) => ({
  userInformation: userStore.information.data,
});

export default connect(state)(Component);
