import { put, call, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  SAVE_MEET,
  SAVE_MEET_SUCCESS,
  SAVE_MEET_ERROR,
  GET_MEET_SUCCESS,
  GET_MEET_ERROR,
  GET_MEET,
} from "../types/meet";

function* saveMeet({ payload }) {
  try {
    const result = yield call(services.meet.saveMeet, payload);
    yield put({ type: SAVE_MEET_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: SAVE_MEET_ERROR, payload: error });
  }
}

function* getMeet({ payload }) {
  try {
    const result = yield call(services.meet.getMeet, payload);
    yield put({ type: GET_MEET_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_MEET_ERROR, payload: error });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(SAVE_MEET, saveMeet);
  yield takeLatest<any>(GET_MEET, getMeet);
}
