import { combineReducers } from "redux";
import one from "./get-one";
import all from "./get-all";
import edit from "./edit";
import newOne from "./new-one";
import deleteOne from "./delete-one";
import shareSession from "./share";

const reducer = combineReducers({
  one,
  all,
  edit,
  newOne,
  deleteOne,
  shareSession,
});

export default reducer;
