import ReactDOM from "react-dom";
import { connect, useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import Icon from "../../../Icon";
import constants from "../../../../constants";
import { Group, SessionInDto, User } from "../../../../types";
import { Preference } from "../../../../types/preferences";
import { Navbar, NavbarItem, NavbarItemWrapper, Mark } from "./styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import slugify from "slugify";
import { ghostLink } from "../../../../helpers/ghost-link";
import { useHistory } from "react-router-dom";
import { Post } from "../../../../types/post";
import { isSubscribed } from "../../../../helpers/isSubscribed";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { getFreeSubscriptions } from "../../../../helpers/getFreeSubscriptions";
import { userInfo } from "os";
import { WelcomeContentPostDto } from "../../../../types/welcome-section";

interface ComponentProps { }

const footerSticky = document.getElementById("footer-sticky") as HTMLElement;

interface ComponentProps {
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  post: Post;
  postStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  welcomeContent: WelcomeContentPostDto;
  welcomeContentStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  session: SessionInDto;
  sessionStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  userInformation: User;
  userInformationStates;
  useLocation;
  preferences: Preference[];
  preferencesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  subscriptions;
  subscriptionsStates;
  currentPage;
  isLoggedIn
}

const Component = ({
  group,
  groupStates,
  post,
  postStates,
  welcomeContent,
  welcomeContentStates,
  session,
  sessionStates,
  userInformation,
  userInformationStates,
  useLocation,
  preferences,
  preferencesStates,
  subscriptions,
  subscriptionsStates,
  currentPage,
  isLoggedIn
}: ComponentProps) => {
  const theme = useTheme() as { colors: { Primary: string } }
  const dispatch = useDispatch();
  const history = useHistory();
  const [availableMarks, setAvailableMarks] = useState<string[]>([]);

  const addGroupSlug = (path) => {
    const slug = groupInformation?.slug
    return path.replace(":slug", slug)
  }

  const onClick = (page) => {
    const redirect = page.native;

    if (page.url) {
      if (redirect) {
        history.push(addGroupSlug(page.url));
      } else {
        ghostLink(addGroupSlug(page.url));
      }
    }

    if (page.action) {
      dispatch(page.action());
    }
  };

  const groupInformation = useMemo(() => {
    if(!!group)
      return group

    if(!!session)
      return session?.group

    if(!!post)
      return post?.group

    if(!!welcomeContent)
      return welcomeContent?.welcome_section?.group

  }, [group, post, session, welcomeContent]);

  const isOwnGroup = useMemo(() => {
    const expertId = groupInformation?.expert?.id;
    return expertId === userInformation?.expert?.id && !!expertId && !!userInformation?.expert?.id;
  }, [groupInformation, userInformation]);

  const isUserAuthenticated = useMemo(() => {
    return isLoggedIn || groupInformation?.group_type === 'free_experience'
  }, [isLoggedIn, groupInformation])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(subscriptions, groupInformation?.id)
    } else if (!isLoggedIn || groupInformation?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), groupInformation?.id)
    } else {
      return false
    }
  }, [subscriptions, groupInformation, isLoggedIn])

  const isChatDisabled = useCallback((label) => {
    const isChatItem = label === "Chat"
    return isChatItem && (!isUserSubscribed || !isUserAuthenticated) && !isOwnGroup
  }, [isUserSubscribed, isUserAuthenticated, isOwnGroup])

  const isExpert = useMemo((): boolean => {
    return !!userInformation?.expert && userInformationStates.success;
  }, [userInformation, userInformationStates]);


  return ReactDOM.createPortal(
    <Navbar>
      {constants?.groupMenu?.filter((item) => {
        const showChat = groupInformation && groupInformation?.telegram_groups?.length > 0
        const isChatItem = item?.label === "Chat"
        return (isChatItem && showChat && item.active) || (!isChatItem && item.active)
      }).filter((item) => {
        const isRecordedGroup = groupInformation && groupInformation?.recorded
        const isRecorded = item?.label === "Aula Virtual"
        return (isRecorded && isRecordedGroup && item.active) || (!isRecorded && item.active)
      }).filter((item) => {
        const isRecordedGroup = groupInformation && groupInformation?.recorded
        const isBiblioteca = item?.label === "Biblioteca"

        return (isBiblioteca && !isRecordedGroup && item.active) || (!isBiblioteca && item.active)
      }).filter((item) => {
        const isFreeGroup = groupInformation && groupInformation?.group_type === 'free_experience'
        const isEncuentros = item?.label === "Encuentros"

        return (isEncuentros && !isFreeGroup && item.active) || (!isEncuentros && item.active)
      })
        .map((item) => {
          const showMark = availableMarks.some(
            (r) => item?.newFeatureMark && item?.newFeatureMark?.indexOf(r) >= 0
          );

          if (item.label === "Inicio" && ((isUserAuthenticated && isUserSubscribed) || isOwnGroup)) {
            item.url = "/group/:slug/activity-summary"
          }

          return (
            <NavbarItem key={slugify(item.label, { lower: true })}>
              <NavbarItemWrapper
                disabled={isChatDisabled(item.label)}
                isActive={item?.key?.includes(currentPage)}
                onClick={() => isChatDisabled(item.label) ? () => { } : onClick(item)}
                width={""}
              >
                {showMark && (
                  <Mark isActive={item?.key?.includes(currentPage)} />
                )}

                <Icon
                  icon={item.icon}
                  size="24px"
                  color={
                    isChatDisabled(item.label) ? '#ccc' :
                      item?.key?.includes(currentPage)
                        ? theme.colors.Primary
                        : "rgba(45, 52, 54, 0.75)"
                  }
                />

                <span>
                  {item.url === "/dashboard/community" && !isExpert
                    ? "Crear mi comunidad"
                    : item.label}
                </span>
              </NavbarItemWrapper>
            </NavbarItem>
          );
        })}
    </Navbar>,
    footerSticky
  );
};

const state = ({ userStore, preferencesStore, pageStore, groupStore, postStore, sessionStore, welcomeContentStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: session, states: sessionStates } = sessionStore.one;
  const { data: userInformation, states: userInformationStates } = userStore.information;
  const { data: preferences, states: preferencesStates } =
    preferencesStore.preferences;
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;
  const { data: post, states: postStates } = postStore.post;
  const { data: welcomeContent, states: welcomeContentStates } = welcomeContentStore.welcomeContentPost;
  const { isLoggedIn } = userStore;

  return {
    group,
    groupStates,
    userInformation,
    userInformationStates,
    preferences,
    preferencesStates,
    subscriptions,
    subscriptionsStates,
    currentPage: pageStore?.name,
    post,
    postStates,
    welcomeContent,
    welcomeContentStates,
    isLoggedIn,
    sessionStates,
    session,
  };
};

export default connect(state)(Component);
