import Grid from "../../../components/Grid";
import Page from "./components/Page";
import PageContent from "./components/PageContent";
import Intro from "./components/Intro";
import { FormTitle, P1, P2, P3, P4, TelegramDownload, Link } from "./styles";
import { useWindowSize } from "../../../hooks/useWindowSize";

const Component = () => {
  const { isTabletPortrait, isMobile } = useWindowSize();

  return (
    <Page>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col
            offset={isTabletPortrait ? { sm: 3 } : { md: 1 }}
            sm={6}
            md={5}
            lg={5}
          >
            <Intro />
          </Grid.Col>

          <Grid.Col
            offset={isTabletPortrait ? { sm: 3 } : { md: 1 }}
            sm={6}
            md={4}
            lg={4}
          >
            <PageContent>
              <FormTitle marginBottom={isMobile ? 16 : 36}>
                Ya casi estamos listos
              </FormTitle>

              <P1>
                Para que accedas a todo el material de manera ordenada
                utilizaremos <b>Telegram</b>
              </P1>

              <P2>
                <i>
                  (Es una aplicación similar a WhatsApp especializada en
                  comunidades)
                </i>
              </P2>

              <Link
                href="https://t.me/+Z6ibvKCxfeIwOGNh"
                options={{
                  skin: "purple",
                  size: "lg",
                  type: "filled",
                  block: true,
                  native: false,
                }}
              >
                Continuar
              </Link>

              <P3>¿No tenés Telegram?</P3>

              <P4>
                Descargala y regístrate con tu número de teléfono. Una vez
                instalada, podrás continuar el proceso con el email que te
                enviaremos.
              </P4>

              <TelegramDownload
                target="_blank"
                href="http://onelink.to/84gpfk"
                options={{
                  size: "md",
                  type: "outline",
                  skin: "purple",
                  block: false,
                  native: false,
                }}
              >
                Descargar Telegram
              </TelegramDownload>
            </PageContent>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Page>
  );
};

export default Component;
