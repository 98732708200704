import styled from "styled-components";

export const Card = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px;
  min-height: 80px;
  outline: 1px solid #cfcfcf;
  border-radius: 16px;
  background-color: #ffffff;
`

export const Title = styled('h3')`
font-size: 14px;
font-weight: 500;
margin: 0;
`
export const SubTitle = styled('h4')`
font-size: 14px;
margin: 0;
`