import styled from "styled-components";
import Image from "../Image";

export const Img = styled(Image)`
  margin-top: 12px;

  &.logo-desktop {
    display: none;
    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      display: block;
    }
  }
`;

export const Footer = styled.footer`
  padding: 25px 0px 0px 0px;
  margin-top: 15px;
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 25px 0px 0px 0px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 15px;
    left: 0px;
    display: block;
    height: 1px;
    width: 100%;
    background: #e4e4e5;
  }
`;

export const HorizontalSeparator = styled("div")`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.Gray5};
  margin-bottom: 30px;
`;
