import styled from "styled-components";

const animationTime = ".35s";

export const Acordeon = styled("div")<{ align?; colorTheme? }>`
  width: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: ${({ align }) =>
    align === "right" ? "flex-end" : "flex-start"};

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 0px;
  }
`;

export const Item = styled("div")<{ isCollapsed: boolean; colorTheme }>`
  width: 100%;
  max-width: 625px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid
    ${({ colorTheme }) =>
      colorTheme === "white"
        ? "rgba(238, 231,235, .3)"
        : "rgba(238, 231,235, 1)"};
  cursor: pointer;
  padding: 30px 0px;

  &:first-child {
    border-top: 1px solid
      ${({ colorTheme }) =>
        colorTheme === "white"
          ? "rgba(238, 231,235, .3)"
          : "rgba(238, 231,235, 1)"};
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    padding: 15px 0px;
  }
`;

export const Toggle = styled("button")<{ colorTheme? }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  border: 0px;
  text-align: left;
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  color: ${({ theme, colorTheme }) =>
    colorTheme === "black" ? theme.colors.Black : "white"};
`;

export const Chevron = styled.img<{ isCollapsed: boolean }>`
  margin-left: 25px;
  transition: opacity 0.25s ease-in-out;
  opacity: ${({ isCollapsed }) => (isCollapsed ? 1 : 0)};
`;

export const Text = styled("p")<{
  isCollapsed: boolean;
  height?: number;
  colorTheme;
}>`
  color: ${({ theme, colorTheme }) =>
    colorTheme === "black" ? theme.colors.Black : "white"};
  max-width: 715px;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  transition: 
  height ${animationTime} ease-in-out,
  opacity ${animationTime} ease-in-out;
  opacity: ${(props) => `${props.isCollapsed ? "0" : "1"};`}
  height: ${(props) => `${props.isCollapsed ? 0 : props.height}px;`}
  font-size: 18px;
  line-height: 32px;
  
  @media( min-width: ${(props) => props.theme.mediaBreakpoints.smMin} ){
   
  }
`;

export const TextContent = styled.span`
  display: block;
  padding-top: 30px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding-top: 15px;
  }
`;
