const urls = {
  subscriber: {
    signin: {
      name: "Subscriber Sign In",
      url: "/subscriber/signin",
    },
    signup: {
      name: "Subscriber Sign Up",
      url: "/subscriber/signup",
    },
  },

  expert: {
    signin: {
      name: "Expert Sign In",
      url: "/expert/signin",
    },
    signup: {
      name: "Expert Sign Up",
      url: "/expert/signup",
    },
  },
  dashboard: {
    home: {
      name: "Dashboard",
      url: "/dashboard",
    },
    newMembership: {
      name: "New Membership",
      url: "/dashboard/membership/new",
    },
    newExperience: {
      name: "New Experience",
      url: "/dashboard/experience/new",
    },
    newCommunityEvent: {
      name: "New Community Event",
      url: "/dashboard/community/events/new",
    },
    groups: {
      name: "Groups",
      url: "/dashboard/groups",
    },
    experience: {
      name: "Experiences",
      url: "/dashboard/experience",
    },
    membership: {
      name: "Membersia",
      url: "/dashboard/membership",
    },
    community: {
      name: "Comunidad",
      url: "/dashboard/community",
    },
  },

  profile: {
    myAccount: {
      name: "User Account",
      url: "/profile/my-account",
    },
    subscriptions: {
      name: "User Subscriptions",
      url: "/profile/subscriptions",
    },
    subscription: {
      name: "Subscription Telegram",
      url: "/group/:slug/library",
    },
  },

  public: {
    homePage: {
      name: "Página principal",
      url: "/",
    },
  },
};

export default urls;
