import axios, { AxiosRequestConfig } from "axios";
import firebase from "./../firebase";
const BASE_URL = process.env.APP_BASE_URL || "/";

interface VerbParamsInterface {
  url: string;
  body?: any;
  config?: AxiosRequestConfig | undefined;
}

const forceRefreshToken = () => {
  const refreshToken = sessionStorage.getItem("FbRefreshToken");
  return !!refreshToken;
};

const getConfig = async (config) => {
  const { headers, ...rest } = config;
  const token = await firebase
    .auth()
    .currentUser?.getIdToken(forceRefreshToken());

  return {
    ...rest,
    baseURL: BASE_URL,
    headers: {
      ...headers,
      ...(token && { authorization: `Bearer ${token}` }),
    },
  };
};

const Get = async ({ url, config = {} }: VerbParamsInterface) => {
  const defaultConfig = await getConfig(config);
  const { data } = await axios.get(url, { ...defaultConfig });
  return data;
};

const Post = async ({ url, body = {}, config = {} }: VerbParamsInterface) => {
  const defaultConfig = await getConfig(config);
  const { data } = await axios.post(url, body, { ...defaultConfig });
  return data;
};

const Put = async ({ url, body = {}, config = {} }: VerbParamsInterface) => {
  const defaultConfig = await getConfig(config);
  const { data } = await axios.put(url, body, { ...defaultConfig });
  return data;
};

const Del = async ({ url, config = {} }: VerbParamsInterface) => {
  const defaultConfig = await getConfig(config);
  const { data } = await axios.delete(url, { ...defaultConfig });
  return data;
};

const Patch = async ({ url, body = {}, config = {} }: VerbParamsInterface) => {
  const defaultConfig = await getConfig(config);
  const { data } = await axios.patch(url, body, {
    ...defaultConfig,
    ...config,
  });
  return data;
};

const actions = {
  Get,
  Post,
  Put,
  Del,
  Patch,
};

export default actions;
