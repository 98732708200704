export const CHANGE_USER_EMAIL = "CHANGE_USER_EMAIL";
export const CHANGE_USER_EMAIL_SUCCESS = "CHANGE_USER_EMAIL_SUCCESS";
export const CHANGE_USER_EMAIL_ERROR = "CHANGE_USER_EMAIL_ERROR";
export const RESET_CHANGE_USER_EMAIL = "RESET_CHANGE_USER_EMAIL";

export interface ChangeUserEmail {
  type: typeof CHANGE_USER_EMAIL;
  payload: { email: string; userId: number };
}

export interface ChangeUserEmailSuccess {
  type: typeof CHANGE_USER_EMAIL_SUCCESS;
  payload: string;
}

export interface ChangeUserEmailError {
  type: typeof CHANGE_USER_EMAIL_ERROR;
  payload: boolean | string;
}

export interface ResetChangeUserEmail {
  type: typeof RESET_CHANGE_USER_EMAIL;
  payload: null
}

export interface State {
  states: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
}

export const initialState: State = {
  states: {
    loading: false,
    error: false,
    success: false,
  },
};

export type Actions =
  | ChangeUserEmail
  | ChangeUserEmailSuccess
  | ChangeUserEmailError
  | ResetChangeUserEmail;
