import {
  initialState,
  Actions,
  State,
  SET_FEATURED_POST,
  SET_FEATURED_POST_SUCCESS,
  SET_FEATURED_POST_ERROR,
  RESET_SET_FEATURED_POST,
} from "../../types/post/set-featured-post";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_FEATURED_POST:
      return {
        ...state,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case SET_FEATURED_POST_SUCCESS:
      return {
        ...state,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case SET_FEATURED_POST_ERROR:
      return {
        ...state,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_SET_FEATURED_POST:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
