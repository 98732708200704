import styled from "styled-components";

export const Title = styled("div")`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

export const Text = styled("p")`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 16px 0px 32px;
`;

export const AlignEnd = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
`;

export const CopyToClipboardButton = styled("span")`
  font-family: "Inter", sans-serif;
  cursor: pointer;
  border: none;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #6c5ce7;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.25);
  font-size: 14px;
  color: #6c5ce7;
  border-radius: 6px;
  padding: 0px 17px;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-letter-spacing: 0.2px;
  -moz-letter-spacing: 0.2px;
  -ms-letter-spacing: 0.2px;
  letter-spacing: 0.2px;
  font-weight: 600;
  min-width: 150px;
  text-align: center;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 1;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  }

  button {
    span {
      color: #6c5ce7 !important;
      font-size: 14px;
      color: #ffffff;
      font-weight: 600;
    }
  }
`;
