import { createRef, useEffect, useMemo, useState } from "react";
import PriceList from '../Price'
import { connect } from "react-redux";
import dateHandlder from "../../../../../helpers/dates-hanlder";
import { Group, User } from "../../../../../types";
import Image from "../../../../../components/Image";
import { PlanDto } from "../../../../../types/plan.dto";
import { DiscountDto } from "../../../../../types/discount";
import constants from "../../../../../constants";
import GoToSubscribeSection from "../GoToSubscribeButton"
import SubscribeButton from "../SubscibeButton";
import Clock from "../../../../../components/Clock";
import RegistrationClosingDate from '../RegistrationClosingDate'
import cancelation from "../../../../../assets/cancelation.svg";
import secure from "../../../../../assets/secure.svg";
import paymentreturn from "../../../../../assets/paymentreturn.svg";

import {
  Card,
  Owner,
  OldPriceContainer,
  OldPrice,
  Start,
  Duration,
  List,
  ListItem,
  Price,
  Currency,
  Amount,
  Period,
  LocalPrice,
  CardCol,
  Title,
  ClockCard,
  Button
} from "./styles";
import { useWindowSize } from "../../../../../hooks/useFormHook";
import { isSubscribed } from "../../../../../helpers/isSubscribed";
//import { isOwnGroup } from "../../../../../helpers/isOwnGroup";
import { getFreeSubscriptions } from "../../../../../helpers/getFreeSubscriptions";

const types = {
  community: {
    label: "Comunidad",
    period_unit: null,
  },
  experience: {
    label: "Taller",
    period_unit: null,
  },
  free_experience: {
    label: "Taller",
    period_unit: null,
  },
  membership: {
    label: "Membresía",
    period_unit: "month",
  },
};

// const periods = {
//   hour: { plural: "horas", singular: "hora" },
//   day: { plural: "días", singular: "día" },
//   week: { plural: "semanas", singular: "semana" },
//   month: { plural: "meses", singular: "mes" },
//   year: { plural: "años", singular: "año" },
// };

// const Benefits = () => {
//   return (
//     <List>
//       <ListItem>
//         <Image src={secure} width="18px" height="18px" alt="Secure" />
//         <span>
//           SSL
//           <br />
//           Pago Seguro
//         </span>
//       </ListItem>

//       <ListItem>
//         <Image src={cancelation} width="18px" height="18px" alt="Cancelation" />
//         <span>
//           Cancelas
//           <br />
//           cuando quieras
//         </span>
//       </ListItem>

//       <ListItem>
//         <Image
//           src={paymentreturn}
//           width="18px"
//           height="18px"
//           alt="Payment Return"
//         />
//         <span>
//           Devolución
//           <br />
//           garantizada
//         </span>
//       </ListItem>
//     </List>
//   );
// };
interface ComponentProps {
  group: Group;
  sticky?: boolean;
  callback?: ({
    aboveFold,
    belowFold,
  }: {
    aboveFold: boolean;
    belowFold: boolean;
  }) => void;
  aboveFold?: boolean;
  belowFold?: boolean;
  subscriptions;
  user: User;
  isLoggedIn: boolean
  scrollToRef?
}

const Component = ({
  group,
  user,
  sticky,
  callback,
  aboveFold,
  belowFold,
  subscriptions,
  isLoggedIn,
  scrollToRef
}: ComponentProps) => {
  // const { isMobile, isTabletPortrait, isDesktop, isTabletLandscape } = useWindowSize();
  // const [plan, setPlan] = useState<PlanDto>();
  // const [discount, setDiscount] = useState<DiscountDto>();
  // const [currency, setCurrency] = useState<string>();
  // const [secondaryCurrency, setSecondaryCurrency] = useState<string>();
  const cardRef = createRef<HTMLDivElement>();
  // const [pinned, setPinned] = useState(false);
  // const [suscriptionEnded, setSuscriptionEnded] = useState<boolean>();

  // const getFechaComienzo = (date) => {
  //   const { day, month, year } = dateHandlder.dateSpliter(date);
  //   return `${day.number}/${month.number}/${year}`;
  // };

  // const scrollToSection = (ref) => {
  //   if (ref.current) {
  //     ref.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  // const getFechaInscripcion = (date) => {
  //   const { day, month, year, hours, minutes } = dateHandlder.dateSpliter(date);
  //   return `Las inscripciones cierran el: ${day.number}/${month.number}/${year} a las ${hours}:${minutes} horas.`
  // }

  // const getPeriod = (duration, unit) => {
  //   if (unit) {
  //     return `${duration} ${duration > 1 ? periods[unit].plural : periods[unit].singular
  //       }`;
  //   }
  //   return "";
  // };

  // const isUserAuthenticated = useMemo(() => {
  //   return isLoggedIn || group?.group_type === 'free_experience'
  // }, [isLoggedIn, group])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(subscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [subscriptions, group, isLoggedIn])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (!!callback) {
          callback({
            aboveFold: entry.boundingClientRect.top < 0,
            belowFold: entry.boundingClientRect.top > 0,
          });
        }
      },
      { threshold: [1] }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [cardRef]);


  // useEffect(() => {
  // if (!!group) {
  //   setPlan(
  //     group?.plans?.filter(
  //       (plan) => plan.period_unit === types[group?.group_type].period_unit
  //     )[0]
  //   );
  // }

  // if (!!group?.registration_closing_date) {
  //   setSuscriptionEnded(
  //     dateHandlder.isExpired(group?.registration_closing_date)
  //   );
  // }
  // }, [group]);

  // useEffect(() => {
  // if (!!plan?.discounts) {
  //   setDiscount(plan?.discounts[0]);
  // }

  // if (!!plan?.currency) {
  //   setCurrency(
  //     constants.currencies.filter((item) => item.iso === plan?.currency)[0]
  //       .label
  //   );
  // }

  if (isUserSubscribed) {
    return <></>;
  }

  return !sticky ? (
    <div ref={cardRef}>
      <PriceList />
    </div>
    // <Card ref={cardRef}>
    //   <CardCol>
    //     <Owner>
    //       {`${types[group?.group_type]?.label} de ${group?.creator_name}`}
    //     </Owner>

    //     <div style={{ height: "4px" }} />

    //     {!group?.recorded &&
    //       (group?.group_type === "experience" ||
    //         group?.group_type === "free_experience") && (
    //         <>
    //           <Start>
    //             Comienzo:{" "}
    //             <strong>
    //               {getFechaComienzo(group?.registration_closing_date)}
    //             </strong>
    //           </Start>

    //           <div style={{ height: "4px" }} />

    //           <Duration>
    //             Duración:{" "}
    //             <strong>
    //               {getPeriod(group?.duration, group?.duration_unit)}
    //             </strong>
    //           </Duration>

    //           <div style={{ height: "12px" }} />
    //         </>
    //       )}
    //   </CardCol>

    //   <CardCol>
    //     <Price>
    //       {group?.group_type !== "free_experience" && (
    //         <Currency>{currency}</Currency>
    //       )}

    //       <Amount>
    //         {group?.group_type === "free_experience" ? (
    //           <>Gratuito</>
    //         ) : (
    //           <>
    //             {!!discount && (
    //               <OldPriceContainer>
    //                 Antes <OldPrice>${plan?.price}</OldPrice>
    //               </OldPriceContainer>
    //             )}

    //             {!!discount
    //               ? (plan?.price || 0) - (plan?.discounts[0]?.amount || 0)
    //               : plan?.price}
    //           </>
    //         )}
    //       </Amount>

    //       {group?.group_type !== "free_experience" && (
    //         <div>
    //           {group?.group_type !== "experience" ? (
    //             <Period>/ mes</Period>
    //           ) : (
    //             <Period style={{ textIndent: "-9px", marginLeft: "9px" }}>
    //               / única <br /> vez
    //             </Period>
    //           )}
    //         </div>
    //       )}
    //     </Price>

    //     {group?.group_type === "free_experience" ? (
    //       <LocalPrice>Gratuito</LocalPrice>
    //     ) : (
    //       <>
    //         {!!plan?.secondary_currency_rate && (
    //           <LocalPrice>
    //             <p style={{ margin: "0" }}>
    //               $
    //               {Math.round(
    //                 (plan?.secondary_currency_rate || 0) *
    //                 ((plan?.price || 0) - (plan?.discounts[0]?.amount || 0))
    //               ).toLocaleString(
    //                 secondaryCurrency === "ARS" ? "es-AR" : "en-US"
    //               )}
    //               <span style={{ marginLeft: "2px" }}>{secondaryCurrency}
    //               </span>
    //             </p>
    //             {secondaryCurrency === "ARS" && (
    //               <p style={{ fontSize: "12px", margin: "0", top: "-10px", position: "relative" }}>(+ impuestos en Argentina)</p>
    //             )}
    //           </LocalPrice>
    //         )}

    //       </>
    //     )}

    //     {(isMobile || isTabletPortrait) && <Benefits />}

    //     {(!discount ||
    //       (!!discount && new Date(discount?.valid_till) <= new Date())) &&
    //       !!group?.registration_closing_date &&
    //       new Date(group?.registration_closing_date) >= new Date() && (
    //         <ClockCard>
    //           <span>Tiempo para inscribirse:</span>
    //           <div style={{ height: "7px" }} />
    //           <Clock
    //             date={group?.registration_closing_date}
    //             onEnd={() => window.location.reload()}
    //           />
    //         </ClockCard>
    //       )}

    //     {!!discount &&
    //       !suscriptionEnded &&
    //       new Date(discount?.valid_till) >= new Date() && (
    //         <ClockCard rainbow>
    //           <span>Promoción válida hasta:</span>
    //           <div style={{ height: "7px" }} />
    //           <Clock
    //             date={discount?.valid_till}
    //             onEnd={() => window.location.reload()}
    //           />
    //         </ClockCard>
    //       )}
    //   </CardCol>

    //   <CardCol style={{ maxWidth: "344px" }}>
    //     <SubscribeButton options={{ block: true }} showDiscount />
    //     {(isDesktop || isTabletLandscape) && <Benefits />}
    //   </CardCol>
    // </Card>

  ) : (
    <Card className={`sticky ${belowFold ? "hide-card" : ""}`} ref={cardRef}>
      <CardCol>
        <Owner style={{ fontSize: "14px", lineHeight: "18px" }}>
          {`${types[group?.group_type]?.label} de ${group?.creator_name}`}
        </Owner>

        <Title>{group?.group_name}</Title>
      </CardCol>

      <CardCol style={{ maxWidth: 264, padding: 0 }}>
        <GoToSubscribeSection scrollToRef={scrollToRef} />
        {/* <Button
          onClick={() => scrollToSection(scrollToRef)}
          disabled={
            suscriptionEnded ||
            isOwnGroup({ group, expertId: user?.expert?.id })
          }
          options={{
            size: "lg",
            skin: "purple",
            type: "filled",
          }}
        >
          {group?.group_type === "membership" && (
            <>{suscriptionEnded ? "Suscripción finalizada" : `Suscribirme`}</>
          )}

          {group?.group_type !== "membership" && (
            <>{suscriptionEnded ? "Inscripción finalizada" : `Inscribirme`}</>
          )}
        </Button> */}
        {/* <SubscribeButton options={{ block: true }} showDiscount /> */}
        {/* <Price>
          {group?.group_type !== "free_experience" && (
            <Currency>{currency}</Currency>
          )}

          <Amount>
            {group?.group_type === "free_experience" ? (
              <>Gratuito</>
            ) : (
              <>
                {!!discount && (
                  <OldPriceContainer>
                    Antes <OldPrice>${plan?.price}</OldPrice>
                  </OldPriceContainer>
                )}

                {!!discount
                  ? (plan?.price || 0) - (plan?.discounts[0]?.amount || 0)
                  : plan?.price}
              </>
            )}
          </Amount>

          {group?.group_type !== "free_experience" && (
            <div>
              {group?.group_type !== "experience" ? (
                <Period>/ mes</Period>
              ) : (
                <Period style={{ textIndent: "-9px", marginLeft: "9px" }}>
                  / única <br /> vez
                </Period>
              )}
            </div>
          )}
        </Price> */}

        {/* {group?.group_type === "free_experience" ? (
          <LocalPrice>Sin cargo</LocalPrice>
        ) : (
          <>
            {!!plan?.secondary_currency_rate && (
              <LocalPrice>
                <p style={{ margin: "0" }}>
                  $
                  {
                    Math.round(
                      (plan?.secondary_currency_rate || 0) *
                      ((plan?.price || 0) - (plan?.discounts[0]?.amount || 0))
                    ).toLocaleString(
                      secondaryCurrency === "ARS" ? "es-AR" : "en-US"
                    )}
                  <span style={{ marginLeft: "2px" }}>{secondaryCurrency}</span>
                </p>
                {secondaryCurrency === "ARS" && (
                  <p style={{ fontSize: "12px", margin: "0", top: "-10px", position: "relative" }}>(+ impuestos en Argentina)</p>
                )}
              </LocalPrice>
            )}
          </>
        )} */}
      </CardCol>

      <CardCol>
        <RegistrationClosingDate />
        {/* <div style={{ fontSize: 14 }}><i>{suscriptionEnded ? 'Inscripción finalizada.' : getFechaInscripcion(group?.registration_closing_date)}  </i></div>
        <div style={{ fontSize: 14 }}><i>Duración del taller: {getPeriod(group?.duration, group?.duration_unit)}.</i></div> */}
        {/* {(isDesktop || isTabletLandscape) && <Benefits />} */}
      </CardCol>
    </Card>
  );
};

const state = ({ groupStore, userStore }) => {
  const { isLoggedIn } = userStore;
  const { data: group } = groupStore.group;
  const { data: subscriptions } = userStore.subscriptions;
  const { data: user } = userStore.information;

  return {
    isLoggedIn,
    user,
    group,
    subscriptions,
  };
};

export default connect(state)(Component);
