import styled from "styled-components";

export const RecordSettingContainer = styled("div")`
  overflow: hidden;
  position: relative;
`;

export const Close = styled("button")`
  background: transparent;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  top: 18px;
  right: 18px;
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 1;

  &:focus {
    outline: transparent;
  }
`;
