import styled, { css } from "styled-components";

const commonItem = css`
  padding: 0px 16px;

  span {
    display: block;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    padding: 0px 12px;

    &:hover {
      background-color: ${(props) => props.theme.colors.Primary};

      & > * {
        color: white;
      }
    }

    & > * {
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 36px;
      text-align: right;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.8);
      border: none;
      border-color: transparent;
      background-color: transparent;
      padding: 0;
      text-align: center;
      min-width: auto;
    }
  }
`;
const separatorItem = `
  height: 1px; 
  width: 100%;
  background-color: #ADADAD;
  margin: 16px 0px;

`;

export const Item = styled("li") <{ isSeparator?; marginBottom?; alignSelf?}>`
  list-style-type: none;

  margin-bottom: ${(props) => props.marginBottom || `33px`};
  ${(props) => (props.isSeparator ? separatorItem : commonItem)}

  align-self: ${(props) => props.alignSelf || `flex-end`}
`;
