export const NEW_GROUP = "NEW_GROUP";
export const NEW_GROUP_SUCCESS = "NEW_GROUP_SUCCESS";
export const NEW_GROUP_ERROR = "NEW_GROUP_ERROR";
export const RESET_NEW_GROUP = "RESET_NEW_GROUP";

export interface NewGroup {
  type: typeof NEW_GROUP;
  payload: string;
}

export interface NewGroupSuccess {
  type: typeof NEW_GROUP_SUCCESS;
  payload: any;
}

export interface NewGroupError {
  type: typeof NEW_GROUP_ERROR;
  payload: boolean | string;
}

export interface ResetNewGroup {
  type: typeof RESET_NEW_GROUP;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions = NewGroup | NewGroupSuccess | NewGroupError | ResetNewGroup
