import styled from "styled-components";
import Image from "../../../Image";
import { Link } from "react-router-dom";
import Icon from "../../../Icon";

const sidebarMenu = {
  opened: {
    width: 300,
    backgroundColor: "rgba(108, 92, 231, 0.16)",
  },
  closed: {
    width: 108,
    backgroundColor: "transparent",
  },
};

export const Menu = styled("div") <{ isOpened }>`
  position: relative;
  display: none;
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  transition: max-width 0.2s ease-in-out;
  max-width: ${(props) =>
    sidebarMenu[props.isOpened ? "opened" : "closed"].width}px;
  z-index: 4;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    display: flex;
  }
`;

export const ToggleMenu = styled("button") <{ isOpened }>`
  position: absolute;
  right: 0px;
  cursor: pointer;
  top: ${(props) => (props.isOpened ? "26px" : "34px")};
  height: 31px;
  width: 24px;
  border-radius: 10px 0px 0px 10px;
  border: 0px;
  margin: 0px;
  background-color: ${(props) => props.theme.colors.Primary};
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    !props.isOpened
      ? "transform: rotate3d(0, 1, 0, 180deg);  right: auto; left: 100%;"
      : "transform: rotate3d(0, 1, 0, 0deg);"}

  svg {
    width: 5px;
  }
`;

export const Navbar = styled("ul")`
  display: flex;
  flex-direction: column;
  flex-align: center;
  justify-content: center;
  padding: 0px 16px;
  margin: 0px;
  width: 100%;
`;

export const Navbars = styled("div")`
  height: 100%;
  width: 100%;
  overflow: auto;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  flex-align: center;
  justify-content: space-between;
`;

export const NavbarItem = styled("li")`
  list-style: none;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
  align-items: center;
  width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const NavbarItemWrapper = styled("button") <{
  isOpened;
  isActive;
  width;
}>`
  position: relative;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.Primary : "transparent"};
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    !props.isOpened && props.isActive ? "center" : "flex-start"};
  border: 0px;
  width: ${(props) => (props.width ? "36px" : "100%")};
  height: 44px;
  border-radius: 6px;
  padding: 0px 24px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  outline: 1px solid
    ${(props) => (props.isActive ? props.theme.colors.Primary : "transparent")};

  ${(props) =>
    !props.isActive
      ? `&:hover {
    background-color: rgba(249, 249, 249, 1);

    //outline: 1px solid ${(props) => props.theme.colors.Primary};

    & > span {
      z-index: 10;
      visibility: visible;
      opacity: 1;

      ${(props) => {
        return !props.isOpened
          ? `
          background-color: #6b5ce7;
          color: white;
          margin-left: 16px;
        `
          : "";
      }}
    }
  }`
      : ""}
`;

export const NavbarItemLabel = styled("span") <{ isOpened; isActive }>`
  display: block;
  width: ${(props) => (props.isOpened ? "100%" : "auto")};
  margin-left: ${(props) => (props.isOpened ? "16px" : "8px")};
  white-space: nowrap;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  background-color: ${(props) =>
    props.isOpened ? "transparent" : props.theme.colors.Primary};
  color: ${(props) =>
    props.isActive || !props.isOpened ? "white" : props.theme.colors.Black75};
  position: ${(props) => (props.isOpened ? "initial" : "absolute")};
  left: ${(props) => (props.isOpened ? "auto" : "100%")};
  z-index: ${(props) =>
    props.isOpened || (props.isOpened && props.isActive) ? "1" : "-1"};
  padding: ${(props) => (props.isOpened ? "0px" : "4px 25px")};
  line-height: ${(props) => (props.isOpened ? "0px" : "27px")};
  opacity: ${(props) =>
    props.isOpened || (props.isOpened && props.isActive) ? "1" : "0"};
  visibility: ${(props) =>
    props.isOpened || (props.isOpened && props.isActive)
      ? "visible"
      : "hidden"};
  border-radius: ${(props) => (props.isOpened ? "0px" : "5px")};
  transition: margin-left 0.2s ease-in-out, opacity 0.2s ease-in-out,
    z-index 0.2s ease-in-out;
`;

export const Triangle = styled(Icon) <{ isOpened }>`
  display: ${(props) => (props.isOpened ? "none" : "block")};
  position: absolute;
  right: calc(100% + 7px);
  top: 50%;
  transform: translate(8px, -50%);
`;

export const LinkToHomePage = styled("a")`
  color: inherit;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
`;

export const Brand = styled("div") <{ isOpened }>`
  padding: 29px 24px;
  width: 100%;
  text-align: ${(props) => (props.isOpened ? "left" : "center")};
`;

export const Logo = styled(Image)`
  &.logo-desktop {
    display: none;
    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      display: block;
    }
  }

  &.logo-mobile {
    display: block;
    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      display: none;
    }
  }
`;

export const Profile = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  margin-bottom: 43px;
  padding: 0px 16px;
  width: 100%;
  gap: 8px;
`;

export const AvatarImage = styled("img") <{ isOpened }>`
  width: ${(props) => (props.isOpened ? "64px" : "72px")};
  height: ${(props) => (props.isOpened ? "64px" : "72px")};
  border-radius: 100%;
  margin-bottom: 0px;
  outline: 2px solid white;
  transition: all 0.2s ease-in-out;
  background-color: #f5f5f5;
`;

export const Name = styled("p") <{ isOpened }>`
  margin: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  transition: position 0.2s ease-in-out;
  z-index: ${(props) => (props.isOpened ? "1" : "-1")};
  opacity: ${(props) => (props.isOpened ? "1" : "0")};
  position: ${(props) => (props.isOpened ? "static" : "absolute")};
`;

export const Username = styled("p") <{ isOpened }>`
  margin: -3px 0px 0px;
  font-size: 12px;
  line-height: 26px;
  transition: all 0.2s ease-in-out;
  color: rgba(45, 52, 54, 0.75);
  z-index: ${(props) => (props.isOpened ? "1" : "-1")};
  opacity: ${(props) => (props.isOpened ? "1" : "0")};
  position: ${(props) => (props.isOpened ? "static" : "absolute")};
`;

export const Mark = styled("div") <{ isActive }>`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${({ isActive }) => (isActive ? "white" : "#6c5ce7")};
  flex-shrink: 0;
  position: absolute;
  top: 9px;
`;
