import React, { ReactElement, useContext, useEffect, useState } from "react";
import rainbow from "../../assets/rainbow-background.svg";

export interface Layout {
  skin: "rainbow" | "plain";
  footer: {
    visible: boolean;
    afterFooter?: ReactElement
    beforeFooter?: ReactElement
  };
  header: {
    visible: boolean;
    actions?: ReactElement[];
    afterHeader?: ReactElement
    beforeHeader?: ReactElement
  };
  toolbar: {
    visible: boolean;
  };
  body: {
    visible: boolean;
    background?: {
      image?: string;
      color?: string;
    };
  };
  dashboard?: {
    tabs: {
      visible: boolean;
      activeTab?: string;
      component?: ReactElement[];
    };
  };
  page?: {
    title?: ReactElement | string;
    description?: string;
    showBack?: boolean
    back?: string;
  };
}

const initialValues: Layout = {
  skin: "rainbow",
  footer: {
    visible: true,
  },
  header: {
    visible: true,
  },
  toolbar: {
    visible: false,
  },
  dashboard: {
    tabs: {
      visible: false,
      activeTab: "",
    },
  },
  body: {
    visible: true,
    background: {
      image: rainbow,
    },
  },
};

interface ContexInitialValuesDto {
  layout: Layout;
  setLayout: React.Dispatch<React.SetStateAction<Layout>>;
  resetLayout: () => void;
}

const contexInitialValues: ContexInitialValuesDto = {
  layout: initialValues,
  setLayout: () => { },
  resetLayout: () => { },
};

export const LayoutProvidersContext = React.createContext(contexInitialValues);

export const useLayout = () => useContext(LayoutProvidersContext);

const LayoutProvider = ({ children, useLocation }) => {
  const [layout, setLayout] = useState<Layout>(initialValues);
  const { pathname } = useLocation();

  const resetLayout = () => {
    setLayout(initialValues);
  };

  return (
    <LayoutProvidersContext.Provider
      value={{
        layout,
        setLayout,
        resetLayout,
      }}
    >
      {children}
    </LayoutProvidersContext.Provider>
  );
};

export default LayoutProvider;
