import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import Submit from "../../../../../components/FormikForm/Submit";
import { Title, Text, AlignEnd } from "./style";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import { deletePost, resetDeletePost } from "../../../../../store/actions/post";
import { Post } from "../../../../../types/post";
import remove from "../../../../../assets/remove.svg";
import Image from "../../../../../components/Image";

interface ComponentProps {
  postInformation: Post;
  deletePostStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ postInformation, deletePostStates }: ComponentProps) => {
  const dispatch = useDispatch();

  const handleDeletePost = () => {
    dispatch(deletePost(postInformation?.id));
  };

  const onModalClose = () => {
    if (deletePostStates.success) {
      dispatch(resetDeletePost());

      setTimeout(() => {
        window.history.back();
      }, 500);
    }
  };

  return (
    <Modal
      name="modal-wait-a-minute"
      width="650"
      onClose={() => onModalClose()}
    >
      <Title>⏳ Aguarda un instante... </Title>

      <Text>
        Estamos subiendo los archivos de tu artículo<br /><br />
        Dependiendo el tamaño del archivo y tu conexión a internet, este proceso podría tardar algunos minutos, por lo que te pedimos que no
        cierres el navegador ni abandones esta pantalla.
      </Text>
    </Modal>
  );
};

const state = ({ postStore }) => {
  const { data: postInformation } = postStore.post;
  const { states: deletePostStates } = postStore.deletePost;
  return {
    postInformation,
    deletePostStates,
  };
};

export default connect(state)(Component);
