export const GET_GROUP_METRICS = "GET_GROUP_METRICS";
export const GET_GROUP_METRICS_SUCCESS = "GET_GROUP_METRICS_SUCCESS";
export const GET_GROUP_METRICS_ERROR = "GET_GROUP_METRICS_ERROR";
export const RESET_GET_GROUP_METRICS = "RESET_GET_GROUP_METRICS";

export interface GetGroupMetrics {
  type: typeof GET_GROUP_METRICS;
  payload: {
    id: string;
  };
}

export interface GetGroupMetricsSuccess {
  type: typeof GET_GROUP_METRICS_SUCCESS;
  payload: any;
}

export interface GetGroupMetricsError {
  type: typeof GET_GROUP_METRICS_ERROR;
  payload: boolean | string;
}

export interface ResetGetGroupMetrics {
  type: typeof RESET_GET_GROUP_METRICS;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetGroupMetrics
  | GetGroupMetricsSuccess
  | GetGroupMetricsError
  | ResetGetGroupMetrics;
