import {
  initialState,
  Actions,
  State,
  GET_ONE_SURVEY,
  GET_ONE_SURVEY_SUCCESS,
  GET_ONE_SURVEY_ERROR,
  RESET_GET_ONE_SURVEY,
  UPDATE_CURRENT_SURVEY,
  UPDATE_CURRENT_SURVEY_SUCCESS,
  UPDATE_CURRENT_SURVEY_ERROR,
} from "../../types/survey/one";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ONE_SURVEY:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case GET_ONE_SURVEY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case GET_ONE_SURVEY_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };

    case UPDATE_CURRENT_SURVEY:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case UPDATE_CURRENT_SURVEY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case UPDATE_CURRENT_SURVEY_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_GET_ONE_SURVEY:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
