import styled, { createGlobalStyle } from "styled-components";
import {
  ButtonBack,
  ButtonNext,
  Slide as SlidePrc,
} from "pure-react-carousel";

export const GlobalStyles = createGlobalStyle`
  .carousel {
    &.content-carousel{
      width: 100%;
  
      .carousel__slider {
        width: 100%;
        padding: 10px calc(15%);
  
        @media (min-width: 768px) {
          padding: 10px calc(30% - 10px);
        }
  
        @media (min-width: 1024px) {
          padding: 10px calc(100% / 3 - 10px);
        }
      }
    }
  }
`;

export const ButtonsNext = styled(ButtonNext)`
  z-index: 2;
  background: none;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const ButtonsBack = styled(ButtonBack)`
  z-index: 2;
  background: none;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const CarouselContent = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const Slide = styled(SlidePrc)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: transform 0.3s ease-in-out;

  transform: scale(0.8);

  &.carousel__slide--visible {
    transform: scale(1);
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
  }
`;
