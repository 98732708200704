import styled from 'styled-components'

export const Toggle = styled.button<{ isOpen: boolean }>`
  background-color: white;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  border: 0px;
  background: 0px;

  & > * {
    transition: tranform .3s ease-in-out;
    transform: rotate(${({ isOpen }) => isOpen ? '180deg' : '0deg'});
  }
`
