import React, {
  useContext,
  useEffect,
  useState,
} from "react";
import { connect, useDispatch } from "react-redux";
import {
  ModuleInDto,
  GetAllModulesOutDto,
  GetOneModuleOutDto,
} from "../../types/module";
import inboxIcon from "../../assets/icons/inbox.svg";
import documentIcon from "../../assets/icons/document.svg";
import bookIcon from "../../assets/icons/book.svg";
import {
  getAllModules,
} from "../../store/actions/module";
import { isOwnGroup } from "../../helpers/isOwnGroup";
import { getUrlParamByName } from "../../helpers/get-url-param";
import { getUserPreferences, savePreference } from "../../store/actions/preferences";

const modulesIcons = {
  post: documentIcon,
  note: bookIcon,
  survey: inboxIcon,
};

interface States {
  success: boolean;
  error: string | boolean;
  loading: boolean;
}

interface ContexInitialValuesDto {
  selectedModule?: ModuleInDto;
  setSelectedModule: React.Dispatch<
    React.SetStateAction<ModuleInDto | undefined>
  >;
  modules?: ModuleInDto[];
  pagination: { current: number, next: number | null, prev: number | null };
  actions: {
    getOne: {
      exec: (payload: GetOneModuleOutDto) => void;
      states: States;
    };
    getAll: {
      exec: (payload: GetAllModulesOutDto) => void;
      states: States;
    };
    nextModule: {
      exec: () => void;
      states: States;
    };
    prevModule: {
      exec: () => void;
      states: States;
    };
  };
}

const contexInitialValues: ContexInitialValuesDto = {
  setSelectedModule: () => { },
  pagination: {
    current: 0,
    next: 1,
    prev: null,
  },
  actions: {
    getOne: {
      exec: () => { },
      states: { success: false, error: false, loading: false },
    },
    getAll: {
      exec: () => { },
      states: { success: false, error: false, loading: false },
    },
    nextModule: {
      exec: () => { },
      states: { success: false, error: false, loading: false },
    },
    prevModule: {
      exec: () => { },
      states: { success: false, error: false, loading: false },
    },
  },
};

export const ModulesPreviewProviderContext = React.createContext(contexInitialValues);

export const useModulesPreview = () => useContext(ModulesPreviewProviderContext);

const ModulesPreviewProvider = ({
  group,
  allModules,
  allModulesStates,
  children,
  useLocation,
  user,
  preferences
}) => {
  const dispatch = useDispatch();
  const [isOwner, setIsOwner] = useState<boolean>(false)
  const [modules, setModules] = useState<ModuleInDto[]>([]);
  const [pagination, setPagination] = useState<{ current: number, next: number | null, prev: number | null }>({
    current: 0,
    next: 1,
    prev: null,
  })
  const [selectedModule, setSelectedModule] = useState<ModuleInDto>();
  const location = useLocation()
  const oneModuleStates = { success: false, error: false, loading: false }
  const nextModuleStates = { success: false, error: false, loading: false }
  const prevModuleStates = { success: false, error: false, loading: false }

  const getNextPrev = () => {
    let current: number = modules?.findIndex(module => {
      return module.id === selectedModule?.id
    })
    const next = current + 1 >= modules?.length ? null : current + 1
    const prev = current - 1 < 0 ? null : current - 1

    setPagination({
      current,
      next,
      prev
    })
  }

  const getOne = (payload: GetOneModuleOutDto) => { };

  const getAll = (payload: GetAllModulesOutDto) => {
    dispatch(getAllModules(payload));
  };

  const readedModule = () => {
    if (!selectedModule?.readed) {
      dispatch(
        savePreference({
          preferenceSlug: `module-${selectedModule?.id}`,
          preferenceValue: 'readed',
        })
      );
    }
  };

  const nextModule = () => {
    const next = pagination.next
    if (next !== null) {
      setSelectedModule(modules[next])
    }
  };

  const prevModule = () => {
    const prev = pagination.prev
    if (prev !== null) {
      setSelectedModule(modules[prev])
    }
  };

  useEffect(() => {
    const orderedModules = allModules
      ?.sort(function (a, b) {
        return a.order - b.order;
      })
      .filter((item) => item.state !== 'inactive')
      .map((item) => ({
        ...item,
        icon: modulesIcons[item?.content_type],
        readed: preferences?.find(preference => preference?.preferenceSlug === `module-${item?.id}`) ? true : false
      }));

    setModules(orderedModules || []);
  }, [allModules, preferences]);

  useEffect(() => {
    if (!!group && !!location) {
      const isOwner = isOwnGroup({ group, expertId: user?.expert?.id })
      setIsOwner(isOwner)
      setModules([])
      const version = getUrlParamByName("v")
      const versionSelected = isOwner && !!version ? version : 'live'

      getAll({ idGroup: group?.id, version: versionSelected as 'live' | 'draft' });
      //window.history.pushState(null, document.title, location.pathname);
    }
  }, [group, location, user]);

  useEffect(() => {
    if (!!modules && modules?.length > 0 && !selectedModule) {
      setSelectedModule(modules[0]);
    }
  }, [modules]);

  useEffect(() => {
    if (!!selectedModule) {
      getNextPrev()
      readedModule()
    }
  }, [selectedModule])

  return (
    <ModulesPreviewProviderContext.Provider
      value={{
        selectedModule,
        setSelectedModule,
        modules,
        pagination,
        actions: {
          getOne: {
            exec: getOne,
            states: oneModuleStates,
          },
          getAll: {
            exec: getAll,
            states: allModulesStates,
          },
          nextModule: {
            exec: nextModule,
            states: nextModuleStates,
          },
          prevModule: {
            exec: prevModule,
            states: prevModuleStates,
          },

        },
      }}
    >
      {children}
    </ModulesPreviewProviderContext.Provider>
  );
};

const state = ({ groupStore, modulesStore, userStore, preferencesStore }) => {
  const { data: group } = groupStore.group;
  const { data: allModules, states: allModulesStates } =
    modulesStore.allModules;
  const { data: user } = userStore.information
  const { data: preferences } = preferencesStore.preferences

  return {
    group,
    user,
    allModules,
    allModulesStates,
    preferences,
  };
};

export default connect(state)(ModulesPreviewProvider);
