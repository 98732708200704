import styled from "styled-components";

export const Congrats = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  `

export const Boton= styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 205px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    max-width: 238px;
  }
`