export const DELETE_ONE_SURVEY = "DELETE_ONE_SURVEY";
export const DELETE_ONE_SURVEY_SUCCESS = "DELETE_ONE_SURVEY_SUCCESS";
export const DELETE_ONE_SURVEY_ERROR = "DELETE_ONE_SURVEY_ERROR";
export const RESET_DELETE_ONE_SURVEY = "RESET_DELETE_ONE_SURVEY";

export interface DeleteOneSurvey {
  type: typeof DELETE_ONE_SURVEY;
  payload: { idSurvey: string | number };
}

export interface DeleteOneSurveySuccess {
  type: typeof DELETE_ONE_SURVEY_SUCCESS;
  payload: null;
}

export interface DeleteOneSurveyError {
  type: typeof DELETE_ONE_SURVEY_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteOneSurvey {
  type: typeof RESET_DELETE_ONE_SURVEY;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | DeleteOneSurvey
  | DeleteOneSurveySuccess
  | DeleteOneSurveyError
  | ResetDeleteOneSurvey;
