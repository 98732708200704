import { put, call, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  GET_EXPERT_GROUPS,
  GET_EXPERT_GROUPS_SUCCESS,
  GET_EXPERT_GROUPS_ERROR,
} from "../types/expert/get-expert-groups";

import {
  GET_EXPERT_INFORMATION,
  GET_EXPERT_INFORMATION_SUCCESS,
  GET_EXPERT_INFORMATION_ERROR,
} from "../types/expert/get-expert-information";

import {
  VALIDATE_EXPERT_USERNAME,
  VALIDATE_EXPERT_USERNAME_SUCCESS,
  VALIDATE_EXPERT_USERNAME_ERROR,
} from "../types/expert/validate-expert-username";

import {
  LINK_GATEWAY,
  LINK_GATEWAY_SUCCESS,
  LINK_GATEWAY_ERROR,
} from "../types/expert/link-gateway";

function* getExpertInformation({ payload }): any {
  try {
    const result = yield call(services.expert.getExpertInformation, payload);
    yield put({ type: GET_EXPERT_INFORMATION_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_EXPERT_INFORMATION_ERROR, payload: error });
  }
}

function* getExpertGroups({ payload }): any {
  try {
    const result = yield call(services.expert.getExpertGroups, payload);
    yield put({ type: GET_EXPERT_GROUPS_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_EXPERT_GROUPS_ERROR, payload: error });
  }
}

function* validateExpertUsername({ payload }): any {
  try {
    const { response, error } = yield call(
      services.expert.validateExpert,
      payload
    );
    if (error) {
      yield put({ type: VALIDATE_EXPERT_USERNAME_ERROR, payload: error });
    } else {
      yield put({ type: VALIDATE_EXPERT_USERNAME_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: VALIDATE_EXPERT_USERNAME_ERROR,
      payload: "Error al comprobar el nombre de usuario.",
    });
  }
}

function* linkGateway({ payload }): any {
  try {
    const { response, error } = yield call(
      services.expert.linkGateway,
      payload
    );
    if (error) {
      yield put({ type: LINK_GATEWAY_ERROR, payload: error });
    } else {
      yield put({ type: LINK_GATEWAY_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: LINK_GATEWAY_ERROR,
      payload: "Error al linkear gateway",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_EXPERT_INFORMATION, getExpertInformation);
  yield takeLatest<any>(GET_EXPERT_GROUPS, getExpertGroups);
  yield takeLatest<any>(VALIDATE_EXPERT_USERNAME, validateExpertUsername);
  yield takeLatest<any>(LINK_GATEWAY, linkGateway);
}
