import {
  initialState,
  Actions,
  State,
  DELETE_PLAN,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_ERROR,
  RESET_DELETE_PLAN,
} from "../../types/plan/delete";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case DELETE_PLAN:
      return {
        ...state,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case DELETE_PLAN_SUCCESS:
      return {
        ...state,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case DELETE_PLAN_ERROR:
      return {
        ...state,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_DELETE_PLAN:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
