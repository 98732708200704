import {
  initialState,
  Actions,
  State,
  GET_GROUP_BY_ID,
  GET_GROUP_BY_ID_SUCCESS,
  GET_GROUP_BY_ID_ERROR,
  GET_GROUP_BY_SLUG,
  GET_GROUP_BY_SLUG_SUCCESS,
  GET_GROUP_BY_SLUG_ERROR,
  UPDATE_CURRENT_GROUP_SUCCESS,
  DELETE_COVER_PIC,
  RESET_GET_GROUP
} from "../../types/group/get-group";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_GROUP_BY_ID:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case GET_GROUP_BY_ID_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case GET_GROUP_BY_ID_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case GET_GROUP_BY_SLUG:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case GET_GROUP_BY_SLUG_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case GET_GROUP_BY_SLUG_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case DELETE_COVER_PIC:
      return {
        ...state,
        data: state.data ? { ...state.data, cover_pic: undefined } : state.data,
      };
    case UPDATE_CURRENT_GROUP_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
      case RESET_GET_GROUP:
        return initialState
    default:
      return state;
  }
};

export default reducers;
