export enum StorageActions {
  READ = "read",
  WRITE = "write",
  DELETE = "delete",
  RESUMABLE = "resumable",
}

// export interface StorageFile {
//   fileName: string;
// }

export interface NewFileOutDto {
  file: File,
  fileName: string;
  onUploadProgress: (progress: number) => void
  onAbort?: (xhr: XMLHttpRequest) => void
}
export interface NewFileInDto {

}

export interface DeleteFileOutDto {
  fileName: string;
}
export interface DeleteFileInDto {
}

export interface GetFileOutDto {
  fileName: string;
}
export interface GetFileInDto {
  url: string
}