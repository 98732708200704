import * as Yup from "yup";


const today = new Date();


const futureMaxDate = new Date(today);
// Add 3 months to the current date
futureMaxDate.setMonth(futureMaxDate.getMonth() + 3);

export const schema = Yup.object().shape({
  title: Yup.string().required("Debes indicar el nombre de la artículo."),
  body: Yup.string()
    .nullable()
    .required("Agrega una descripción a tu artículo."),
  attached_files: Yup.array().nullable(),
  attached_url: Yup.string().nullable(),
  content_categories: Yup.array().of(Yup.number()).nullable(),
  notificate_by_email: Yup.array().of(Yup.string()).nullable(),
  notificate_by_telegram: Yup.array().of(Yup.string()).nullable(),
  pinned: Yup.array().of(Yup.string()).nullable(),
  show_scheduled_post: Yup.array().of(Yup.string()).nullable(),
  is_free: Yup.array().of(Yup.string()).nullable(),
  group: Yup.number().required(),
  block_files_download: Yup.array().of(Yup.string()).nullable(),
  schedule_publish_at: Yup.date().when('action_publish_at', {
    is: 'scheduled_publication',
    then: Yup.date()
      .min(today, "Debes indicar una fecha futura")
      .max(futureMaxDate, "Puedes programar la publicacion hasta un máximo de 3 meses en el futuro")
      .required("Debes completar la fecha y hora en que quieres que se publique el artículo"),
    otherwise: Yup.date().nullable(), // Make schedule_publish_at nullable for other cases
  }),
});

interface FormValues {
  title: string;
  body: string;
  attached_files?: object[];
  attached_url?: string;
  content_categories?: number[];
  notificate_by_email: string[];
  notificate_by_telegram: string[];
  is_free: string[];
  group?: number;
  schedule_publish_at: string;
  action_publish_at: string;
  show_scheduled_post: string[],
  pinned: string[],
  block_files_download: string[]
}

export const initialValues: FormValues = {
  title: "",
  body: "",
  notificate_by_email: ["true"],
  notificate_by_telegram: ["true"],
  is_free: [],
  schedule_publish_at: "",
  action_publish_at: "now_publication",
  show_scheduled_post: [],
  pinned: [],
  block_files_download: ["true"]
};
