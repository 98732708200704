import {
  initialState,
  Actions,
  State,
  UPDATE_DISCOUNT,
  UPDATE_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_ERROR,
  RESET_UPDATE_DISCOUNT,
} from "../../types/discount/update-discount";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case UPDATE_DISCOUNT:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case UPDATE_DISCOUNT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case UPDATE_DISCOUNT_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_UPDATE_DISCOUNT:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducers;
