import Modal from "../../../components/Modal";
import Form from "../../../components/Form";
import Button from "../../../components/Button";
import Input from "../../../components/Form/Input";
import { Title, Text } from "./styles";
import { connect, useDispatch } from "react-redux";
import Snackbar from "../../../components/Snackbar";
import services from "../../../services";
import {
  passwordRecovery,
  resetPasswordRecovery,
} from "../../../store/actions/auth";
import emailIcon from "../../../assets/icons/mail.svg";

interface ComponentProps {
  passwordRecoveryData;
  passwordRecoveryStates;
}

const Component = ({
  passwordRecoveryData,
  passwordRecoveryStates,
}: ComponentProps) => {
  const dispatch = useDispatch();

  const onSubmit = ({ data }) => {
    dispatch(passwordRecovery(data));
  };

  const onModalHide = () => {
    dispatch(resetPasswordRecovery());
  };

  const existingEmail = async (email: string) => {
    const emailExist = await services.auth.validateEmail(email);
    return emailExist.id || "No existe una cuenta asociada a ese email";
  };

  return (
    <>
      <Modal
        name="password-recovery-modal"
        width="360"
        showClose={true}
        onClose={onModalHide}
      >
        <Title>¿Olvidaste tu contraseña?</Title>
        <Text>
          Introduce tu email para que podamos enviarte un enlace para reactivar
          tu contraseña
        </Text>

        <Form onSubmit={onSubmit} id="password-recovery-form">
          <Input
            name="email"
            placeholder={"Ingresa tu e-mail"}
            rules={{
              required: "Ingresa un email",
              validate: existingEmail,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "El email ingresado es invalido.",
              },
            }}
            onChange={(event) => {
              event.currentTarget.value = event.currentTarget.value.trim();
            }}
            options={{
              after: <img src={emailIcon} alt="Email" />,
            }}
          />

          <Button
            form="password-recovery-form"
            type="submit"
            disabled={passwordRecoveryStates.loading}
            loading={passwordRecoveryStates.loading}
            options={{
              skin: "purple",
              size: "lg",
              type: "filled",
            }}
          >
            Enviar e-mail
          </Button>
        </Form>
      </Modal>

      <Snackbar
        visible={passwordRecoveryStates.success || passwordRecoveryStates.error}
        options={{
          type: passwordRecoveryStates.success ? "success" : "error",
          position: "left",
        }}
      >
        {passwordRecoveryStates.success
          ? "Continúa desde tu correo."
          : "Error al enviar e-mail."}
      </Snackbar>
    </>
  );
};

const state = ({ authStore }) => {
  const { data: passwordRecoveryData, states: passwordRecoveryStates } =
    authStore.passwordRecovery;

  return {
    passwordRecoveryData,
    passwordRecoveryStates,
  };
};

export default connect(state)(Component);
