import { put, call, takeLatest, select } from "redux-saga/effects";
import services from "./../../services";
import {
  USER_IS_LOGGEDIN,
  USER_IS_ALREADY_SUBSCRIBED,
  SAVE_CURRENT_GROUP,
  SAVE_USER_SUBSCRIPTION,
  SAVE_USER_SUBSCRIPTION_SUCCESS,
  SAVE_USER_SUBSCRIPTION_ERROR,
} from "../types/user/subscription";


import {
  GET_USER_INFORMATION_SUCCESS,
  RESET_USER_INFORMATION,
} from "../types/user/get-user-information";

import { GET_USER_GROUPS_SUCCESS } from "../types/user/get-user-groups";

import { GET_GROUP_BY_SLUG_SUCCESS } from "../types/group/get-group";

const getUserSubscriptions = ({ userStore }) => userStore.subscriptions.data;
const getUserInformation = ({ userStore }) => userStore.information.data;
const getCurrentGroup = ({ groupStore }) => groupStore.data;

function* saveCurrentGroup() {
  const currentGroup = yield select(getCurrentGroup);
  yield put({ type: SAVE_CURRENT_GROUP, payload: currentGroup });
}

function* isLoggedIn() {
  const userInformation = yield select(getUserInformation);
  yield put({ type: USER_IS_LOGGEDIN, payload: !!userInformation });
}

function* isAlreadySubscribed({ payload }) {
  const userSubscriptions = yield select(getUserSubscriptions);
  const filter =
    userSubscriptions?.filter(
      (subscription) => payload.id === subscription.group.id
    ) || [];
  yield put({ type: USER_IS_ALREADY_SUBSCRIBED, payload: filter.length >= 1 });
}

function* saveUserSubscription({ payload }) {
  try {
    const { response, error } = yield call(
      services.subscription.saveUserSubscription,
      payload
    );

    if (error) {
      yield put({ type: SAVE_USER_SUBSCRIPTION_ERROR, payload: error });
    } else {
      yield put({ type: SAVE_USER_SUBSCRIPTION_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: SAVE_USER_SUBSCRIPTION_ERROR,
      payload:
        "Algo salio mal al intentar guarda la suscripción, ponte en contacto con nuestro equipo de soporte.",
    });
  }
}


/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(SAVE_USER_SUBSCRIPTION, saveUserSubscription);
  yield takeLatest<any>(GET_GROUP_BY_SLUG_SUCCESS, saveCurrentGroup);

  yield takeLatest<any>(GET_USER_INFORMATION_SUCCESS, isLoggedIn);
  yield takeLatest<any>(RESET_USER_INFORMATION, isLoggedIn);

  yield takeLatest<any>(GET_USER_GROUPS_SUCCESS, isAlreadySubscribed);
  yield takeLatest<any>(GET_GROUP_BY_SLUG_SUCCESS, isAlreadySubscribed);

}
