import { useEffect, useState } from 'react'
import {
  DragDropContext,
  Droppable,
  DroppableProps,
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult
} from "react-beautiful-dnd";

import Card from './components/Card'
import Sidebar from "../../../../../../../components/Sidebar";
import Button from "../../../../../../../components/Button";
import Snackbar from "../../../../../../../components/Snackbar";

import { connect, useDispatch } from 'react-redux'
import { getAllWelcomeContentPosts, reorderWelcomeContentPost } from '../../../../../../../store/actions/welcome-content'

import {
  Description,
  List,
  ListItem,
  Preview
} from './styles';

import { ReorderWelcomeContentPostsInDto, GetAllWelcomeContentPostsInDto, WelcomeContentPostDto, GetAllWelcomeContentPostsOutDto, GetWelcomeContentSectionInDto } from '../../../../../../../types/welcome-section';
import { Group } from '../../../../../../../types';



const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

interface ComponentProps {
  group: Group
  groupStates: {
    loading: boolean
    success: boolean
    error: string | boolean
  }
  welcomeContentSection: GetWelcomeContentSectionInDto
  welcomeContentSectionStates: {
    loading: boolean
    success: boolean
    error: string | boolean
  }
  reorderedWelcomeContentPosts: ReorderWelcomeContentPostsInDto
  reorderedWelcomeContentPostsState: {
    loading: boolean
    success: boolean
    error: string | boolean
  }
  onClose: () => void
  isVisible: boolean
  setSelectedContentToBeDeleted
  setshowDeleteSidebar
}

const Component = ({
  group,
  groupStates,
  welcomeContentSection,
  welcomeContentSectionStates,
  reorderedWelcomeContentPosts,
  reorderedWelcomeContentPostsState,
  onClose,
  isVisible,
  setSelectedContentToBeDeleted,
  setshowDeleteSidebar
}: ComponentProps) => {
  const dispatch = useDispatch()
  const [newWelcomeContentPostOrder, setNewWelcomeContentPostOrder] = useState<WelcomeContentPostDto[]>([]);

  const reorder = () => {
    dispatch(reorderWelcomeContentPost(newWelcomeContentPostOrder));
  };

  const onDragEnd = (payload: DropResult) => {
    const { source, destination } = payload;

    if (!destination) {
      return;
    }

    const result: WelcomeContentPostDto[] = Array.from(newWelcomeContentPostOrder);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);

    const newOrder = result.map((item: WelcomeContentPostDto, index) => {
      return {
        ...item,
        content_order: index,
      };
    });

    setNewWelcomeContentPostOrder(newOrder);
  };

  useEffect(() => {
    if (welcomeContentSection?.welcome_contents) {
      const orderedWelcomeContentPost = welcomeContentSection?.welcome_contents
        ?.sort((a, b) => {
          return a.content_order - b.content_order;
        })

      setNewWelcomeContentPostOrder(orderedWelcomeContentPost || []);
    }
  }, [welcomeContentSection]);

  // useEffect(() => {
  //   if (!!group) {
  //     dispatch(getAllWelcomeContentPosts({}))
  //   }
  // }, [group])

  return <>
    <Sidebar
      position="right"
      visible={isVisible}
      closeType="back"
      onClose={() => {
        !!onClose && onClose();
      }}
      width="463px"
      title="Reordenar contenido"
    >
      <Preview>
        <Description>Mueve y arrastra los bloques para ordenar el contenido de tu sección de bienvenida, luego toca en "Guardar Orden"</Description>


        <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
          {/* <Button
            disabled={reorderedWelcomeContentPostsState.loading}
            options={{
              type: "outline",
              skin: "purple",
              size: "lg",
              block: true,
            }}
            onClick={() => onClose()}
          >
            Cancelar
          </Button> */}

          <Button
            disabled={reorderedWelcomeContentPostsState.loading}
            loading={reorderedWelcomeContentPostsState.loading}
            options={{
              type: "filled",
              skin: "purple",
              size: "lg",
              block: true,
            }}
            onClick={() => reorder()}
          >
            Guardar Orden
          </Button>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <StrictModeDroppable droppableId={'welcome-content-drag-and-drop'}>
            {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
              <List key={'welcome-content-drag-and-drop'} {...provided.droppableProps} ref={provided.innerRef}>

                {newWelcomeContentPostOrder?.map((welcomeContent, index) => (
                  <>
                    <Draggable
                      key={"welcome-content-" + welcomeContent.id}
                      draggableId={"welcome-content-" + welcomeContent.id}
                      index={index}
                      isDragDisabled={false}
                    >
                      {(
                        providedDraggable: DraggableProvided,
                        snapshotDraggable: DraggableStateSnapshot
                      ) => (
                        <ListItem
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                          ref={providedDraggable.innerRef}
                          style={{
                            ...providedDraggable?.draggableProps?.style,
                            left: 50
                            // Add any other styles as needed
                          }}
                        >
                          <Card welcomeContent={welcomeContent} setSelectedContentToBeDeleted={setSelectedContentToBeDeleted} setShowSidebar={setshowDeleteSidebar} />
                        </ListItem>

                      )}
                    </Draggable>
                  </>
                ))}

                {provided.placeholder}
              </List>
            )}
          </StrictModeDroppable>
        </DragDropContext>

      </Preview>
    </Sidebar>

    <Snackbar
      visible={reorderedWelcomeContentPostsState.success || !!reorderedWelcomeContentPostsState.error}
      options={{
        time: 2000,
        type: reorderedWelcomeContentPostsState.success ? "success" : "error",
      }}
    >
      {reorderedWelcomeContentPostsState.success
        ? "Contenido de bienvenida reorganizado."
        : "Error al reorganizar el contenido de bienvenida."}
    </Snackbar>
  </>
}

const state = ({ groupStore, welcomeContentStore }) => {
  const { data: allWelcomeContentPost, states: allWelcomeContentPostState } =
    welcomeContentStore?.allWelcomeContentPosts;
  const { data: reorderedWelcomeContentPosts, states: reorderedWelcomeContentPostsState } =
    welcomeContentStore.reorderWelcomeContentPosts;
  const { data: group, states: groupStates } =
    groupStore.group;
  const { data: welcomeContentSection, states: welcomeContentSectionStates } = welcomeContentStore.welcomeContentSection;

  return {
    group,
    groupStates,
    // allWelcomeContentPost,
    // allWelcomeContentPostState,
    reorderedWelcomeContentPosts,
    reorderedWelcomeContentPostsState,
    welcomeContentSection,
    welcomeContentSectionStates
  };
};

export default connect(state)(Component);