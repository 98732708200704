import styled from "styled-components";

export const InLine = styled("div")`
  display: flex;
  flex-shrink: 0;
`;

export const CardsGrid = styled("div")`
  display: grid;
  gap: 16px;
  grid-auto-rows: 96px;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
`;

export const CardsGridLarge = styled("div")`
  display: grid;
  gap: 16px;
  grid-auto-rows: 237px;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
`;

export const SectionTitle = styled("h2")`
  margin: 0px;
  font-size: 18px;
  font-weight: normal;
`;

export const SessionCardSkeleton = styled("div")`
  background-color: #e0e4ea;
  border-radius: 14px;
`;

export const Center = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const TextImg = styled("p")`
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  & > span {
    font-style: oblique;
    font-weight: 100;
  }
`;

export const Card = styled("div")`
  background-color: white;
  border-radius: 16px;
  padding: 24px 32px;
  min-width: 100%;
`;

export const GridTableHeaders = styled("span")`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding: 0px 16px 16px 0px;
  width: 268px;
  flex-shrink: 0;
`;

export const GridTableDatum = styled("span")`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 16px 0px;
  width: 268px;
  flex-shrink: 0;
`;

export const HorizontalScroll = styled("div")`
  overflow-x: scroll;
  max-width: 100%;

  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
`;
