import styled from "styled-components";

export const Title = styled.div`
  display: flex;
`;

export const Text = styled.div`
  display: flex;
  align-items: flex-start;
`;
export const DateInput = styled.div`
  width: 100%;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    width: 50%;
  }
`;
