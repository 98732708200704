import action from "../helpers/rest-client";

export class ErrorDiscount extends Error { }

class Discount {
  public async exist(coupon_id): Promise<{ response?; error?}> {
    return action
      .Get({
        url: `/api/discount/exist/${coupon_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async new(payload) {
    return action
      .Post({
        url: `/api/discount`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async update(payload) {
    const { id, ...rest } = payload
    return action
      .Put({
        url: `/api/discount/${id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async delete(id) {
    return action
      .Del({
        url: `/api/discount/${id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getOne(payload) {
    return action
      .Get({
        url: `/api/discount/${payload}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async all(payload) {
    const query = new URLSearchParams(payload);

    return action
      .Get({
        url: `/api/discount?${query.toString()}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const discount = new Discount();
export default discount;
