export const grid = {
  columns: 12,
  gutter: 20,
  margin: 0,
  maxWidth: {
    md: "100%",
    lg: "100%",
    xl: "1440px",
  },
};
