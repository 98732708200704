import { all } from "redux-saga/effects";
import group from "./group";
import user from "./user";
import expert from "./expert";
import auth from "./auth";
import subscription from "./subscription";
import meet from "./meet";
import carousel from "./carousel";
import currency from "./currency";
import preference from "./preferences";
import discount from "./discount";
import post from "./post";
import category from "./category";
import community from "./community";
import session from "./session";
import survey from "./survey";
import notification from "./notification";
import modules from "./modules";
import plan from "./plan";
import storage from "./storage";
import reaction from "./reaction";
import invitation from "./invitation";
import subscriptionAdmin from "./subscription-admin";
import welcomeContent from './welcome-content'

export default function* rootSaga() {
  yield all([
    group(),
    user(),
    expert(),
    auth(),
    subscription(),
    meet(),
    carousel(),
    currency(),
    preference(),
    discount(),
    post(),
    category(),
    community(),
    session(),
    survey(),
    notification(),
    modules(),
    plan(),
    reaction(),
    invitation(),
    storage(),
    subscriptionAdmin(),
    welcomeContent()
  ]);
}
