import { combineReducers } from "redux";
import welcomeContentPost from "./get-welcome-content-post";
import newWelcomeContentPost from "./new-welcome-content-post";
import editWelcomeContentPost from "./edit-welcome-content-post";
import deleteWelcomeContentPost from "./delete-welcome-content-post";
import allWelcomeContentPosts from "./get-all-welcome-content-posts";
import reorderWelcomeContentPosts from "./reorder-welcome-content-posts"

import welcomeContentSection from "./get-welcome-content-section";
import newWelcomeContentSection from "./new-welcome-content-section";
import editWelcomeContentSection from "./edit-welcome-content-section";

import deleteWelcomeContentPostFile from "./delete-welcome-content-post-file";

import finishWelcomeContentPost from "./finish-welcome-content-post";
import finishWelcomeContentSection from "./finish-welcome-content-section";


const reducer = combineReducers({
  welcomeContentPost,
  newWelcomeContentPost,
  editWelcomeContentPost,
  deleteWelcomeContentPost,
  finishWelcomeContentPost,
  allWelcomeContentPosts,
  reorderWelcomeContentPosts,
  deleteWelcomeContentPostFile,
  welcomeContentSection,
  newWelcomeContentSection,
  editWelcomeContentSection,
  finishWelcomeContentSection
});

export default reducer;
