import { GetPostsOutDto, Post } from "../../../types/post";

export const GET_LAST_POSTS = "GET_LAST_POSTS";
export const GET_LAST_POSTS_SUCCESS = "GET_LAST_POSTS_SUCCESS";
export const GET_LAST_POSTS_ERROR = "GET_LAST_POSTS_ERROR";
export const UPDATE_GET_LAST_POSTS = "UPDATE_GET_LAST_POSTS";
export const RESET_GET_LAST_POSTS = "RESET_GET_LAST_POSTS";

export interface GetLastPosts {
  type: typeof GET_LAST_POSTS;
  payload: GetPostsOutDto;
}

export interface UpdateGetLastPosts {
  type: typeof UPDATE_GET_LAST_POSTS;
  payload: Post[];
}

export interface GetLastPostsError {
  type: typeof GET_LAST_POSTS_ERROR;
  payload: boolean | string;
}

export interface GetLastPostsSuccess {
  type: typeof GET_LAST_POSTS_SUCCESS;
  payload: Post[];
}

export interface ResetGetLastPosts {
  type: typeof RESET_GET_LAST_POSTS;
  payload: null;
}

export interface State {
  data: Post[] | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetLastPosts
  | GetLastPostsError
  | GetLastPostsSuccess
  | UpdateGetLastPosts
  | ResetGetLastPosts;
