import {
  DeleteOneModuleInDto,
  DeleteOneModuleOutDto,
} from "../../../types/module";

export const DELETE_MODULE = "DELETE_MODULE";
export const DELETE_MODULE_SUCCESS = "DELETE_MODULE_SUCCESS";
export const DELETE_MODULE_ERROR = "DELETE_MODULE_ERROR";
export const RESET_DELETE_MODULE = "RESET_DELETE_MODULE";

export interface DeleteModule {
  type: typeof DELETE_MODULE;
  payload: DeleteOneModuleOutDto;
}

export interface DeleteModuleSuccess {
  type: typeof DELETE_MODULE_SUCCESS;
  payload: DeleteOneModuleInDto;
}

export interface DeleteModuleError {
  type: typeof DELETE_MODULE_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteModule {
  type: typeof RESET_DELETE_MODULE;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | DeleteModule
  | DeleteModuleSuccess
  | DeleteModuleError
  | ResetDeleteModule;
