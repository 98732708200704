import { connect, useDispatch } from "react-redux";
import { Slide } from "pure-react-carousel";
import Button from "../../../../../../components/Button";
import Link from "../../../../../../components/Link";

import { Text, Actions, SlideContent } from "./styles";
import { Group } from "../../../../../../types";
import { showModal } from "../../../../../../store/actions/modal";
import { savePreference } from "../../../../../../store/actions/preferences";
import { useEffect } from "react";
import { getExpertTelegramBotUrlForNotifications, getExpertTelegramGroup } from "../../../../../../store/actions/group";

interface ComponentProps {
  id: string;
  groupInformation: Group;
  groupStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  expertTelegramGroupUrl: string;
  expertTelegramGroupUrlStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  expertTelegramBotUrlNotificationsUrl: string;
  expertTelegramBotUrlNotificationsUrlStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  id,
  groupInformation,
  expertTelegramGroupUrl,
  expertTelegramGroupUrlStates,
  expertTelegramBotUrlNotificationsUrl,
  expertTelegramBotUrlNotificationsUrlStates
}: ComponentProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExpertTelegramBotUrlForNotifications())
  }, [])

  return (
    <>
      <Slide index={0}>
        <SlideContent>
          <Text>
            ¡Hey! 👋 Comienza por aquí. <b>Configura tu grupo de chat</b>. Es un
            canal <br /> privado de Telegram para interactuar con tus
            suscriptores.
          </Text>

          {(!groupInformation?.telegram_groups ||
            !groupInformation?.telegram_groups[0]) && (
              <Actions>
                <Button
                  onClick={() => dispatch(showModal("modal-telegram"))}
                  options={{
                    type: "filled",
                    size: "lg",
                    skin: "purple",
                  }}
                  style={{ flexShrink: "0" }}
                >
                  Configurar mi canal
                </Button>
              </Actions>
            )}
        </SlideContent>
      </Slide>
      <Slide index={1}>
        <SlideContent>
          <Text>
            ✨ <b>Activa las notificaciones</b> para enterarte cuando se
            <br /> unan nuevos suscriptores
          </Text>

          <Actions>
            <Link
              href={expertTelegramGroupUrl}
              loading={expertTelegramGroupUrlStates.loading}
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
              }}
              style={{ flexShrink: "0" }}
            >
              Activar notificaciones
            </Link>
          </Actions>
        </SlideContent>
      </Slide>
      <Slide index={2}>
        <SlideContent>
          <Text>
            Lanza un <b>descuento promocional</b> para sumar más
            <br /> personas al taller
          </Text>

          <Actions>
            <Link
              href={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/price`}
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
              }}
              style={{ flexShrink: "0" }}
            >
              Lanzar descuento
            </Link>
          </Actions>
        </SlideContent>
      </Slide>
      <Slide index={3}>
        <SlideContent>
          <Text>
            Sube un <b>contenido liberado.</b> Funciona como muestra gratuita
            para sumar más personas
          </Text>

          <Actions>
            <Link
              href={`/dashboard/community/${groupInformation?.id}/posts/new?t=f`}
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
              }}
              style={{ flexShrink: "0" }}
            >
              Subir contenido
            </Link>
          </Actions>
        </SlideContent>
      </Slide>
      <Slide index={4}>
        <SlideContent>
          <Text>
            ¡Perfecto! 🤗 Ahora <b>Comparte</b> tu página de venta.
          </Text>

          <Actions>
            <Button
              onClick={() => {
                dispatch(
                  savePreference({
                    preferenceSlug: id,
                    preferenceValue: false,
                  })
                );
              }}
              options={{
                type: "outline",
                size: "lg",
                skin: "purple",
              }}
              style={{ flexShrink: "0" }}
            >
              Finalizar
            </Button>

            <Button
              onClick={() => {
                const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${groupInformation?.slug}`;
                navigator.clipboard.writeText(link);
                dispatch(showModal("modal-share-group-link"));
              }}
              disabled={
                !groupInformation?.telegram_groups ||
                !groupInformation?.telegram_groups[0]
              }
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
              }}
            >
              Compartir
            </Button>
          </Actions>
        </SlideContent>
      </Slide>
    </>
  );
};

const state = ({ groupStore, userStore, preferencesStore }) => {
  const { data: userInformation } = userStore.information;
  const { group, expertTelegramGroup, expertTelegramBotUrlForNotifications } = groupStore;
  const { data: groupInformation, states: groupStates } = group;
  const { data: expertTelegramGroupUrl, states: expertTelegramGroupUrlStates } =
    expertTelegramGroup;
  const { data: expertTelegramBotUrlNotificationsUrl, states: expertTelegramBotUrlNotificationsUrlStates } = expertTelegramBotUrlForNotifications;
  const { data: preferences, states: preferencesStates } =
    preferencesStore.preferences;

  return {
    groupInformation,
    groupStates,
    userInformation,
    preferences,
    preferencesStates,
    expertTelegramGroupUrl,
    expertTelegramGroupUrlStates,
    expertTelegramBotUrlNotificationsUrl,
    expertTelegramBotUrlNotificationsUrlStates
  };
};

export default connect(state)(Component);
