import styled from "styled-components";

export const Title = styled("div")`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

export const Text = styled("p")`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

export const AlignEnd = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;
