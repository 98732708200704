import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
} from "react-router-dom";

import DashboardLayout from "../../../../components/Layout/DashboardLayout";
import Tabs from "./components/Tabs";

import Edit from "./Edit";
import New from "./New";
import List from "./List";
import Preview from "./Preview";
import Group from "../../Group";
import PostsList from "../../Posts/List";
import PostsNew from "../../Posts/New";
import PostsEdit from "../../Posts/Edit";
import SurveyList from "../../Survey/List";

const Component = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}/`}
        render={() => <Redirect to={`${path}/list`} />}
      />
      <Route
        path={`${path}/list`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <List {...props} />
          </DashboardLayout>
        )}
      />
      <Route
        path={`${path}/new`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <New {...props} />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <Preview {...props} />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/edit/:idGroup`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <Group {...props}>
              <Edit {...props} />
            </Group>
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/posts/`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <PostsList {...props}>
                <Tabs
                    active="library"
                    hiddenTabs={["modules", "members", "sessions"]}
                />
            </PostsList>
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/posts/new`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <PostsNew {...props} groupType="free_experience" />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/posts/:idPost`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <PostsEdit {...props} />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/surveys`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <SurveyList {...props}>
              <Tabs
                active="surveys"
                hiddenTabs={["modules", "sessions", "members"]}
              />
            </SurveyList>
          </DashboardLayout>
        )}
      />
      <Route exact path={`${path}/*`} render={() => <Redirect to={"/404"} />} />
    </Switch>
  );
};

export default Component;
