import {
  initialState,
  Actions,
  State,
  UPDATE_ONE_SESSION,
  UPDATE_ONE_SESSION_SUCCESS,
  UPDATE_ONE_SESSION_ERROR,
  RESET_UPDATE_ONE_SESSION,
} from "../../types/session/update";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case UPDATE_ONE_SESSION:
      return {
        ...state,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case UPDATE_ONE_SESSION_SUCCESS:
      return {
        ...state,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case UPDATE_ONE_SESSION_ERROR:
      return {
        ...state,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_UPDATE_ONE_SESSION:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
