import { createRef, useEffect, useState } from "react";
import { Acordeon, Item, Toggle, Chevron, Text, TextContent } from "./styles";
import plus from "./plus.svg";
import minus from "./minus.svg";
import Icon from "../../../../components/Icon";
import Markdown from "markdown-to-jsx";

interface ComponentProps {
  items: { title: string; text: string }[];
  align?: "right" | "left";
  theme?: "white" | "black";
  onChange?: (currentItem: number) => void;
}

const Component = ({
  items,
  align = "left",
  theme = "black",
  onChange,
  ...rest
}: ComponentProps) => {
  const [refs, setRefts] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState<number | undefined>(undefined);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (items) {
      setRefts((Refs) => items.map((_, i) => Refs[i] || createRef()));
    }
  }, [items]);

  const toggle = (index: number, ref) => {
    if (isCollapsed !== index) {
      setIsCollapsed(index);
      setHeight(ref.current.scrollHeight);
    } else {
      setIsCollapsed(undefined);
      setHeight(0);
    }
  };
  useEffect(() => {
    if (refs.length > 0) {
      toggle(0, refs[0]);
    }
  }, [refs]);

  return (
    <Acordeon align={align} colorTheme={theme} {...rest}>
      {items?.map((item: any, index: number) => {
        const elementCollapsed = isCollapsed !== index;
        !elementCollapsed && !!onChange && onChange(index);
        return (
          <Item
            key={`faq-${index}`}
            isCollapsed={elementCollapsed}
            onClick={() => toggle(index, refs[index])}
            colorTheme={theme}
          >
            <Toggle colorTheme={theme}>
              {item.title}

              <Icon
                icon={elementCollapsed ? plus : minus}
                size="24px"
                {...(theme === "white"
                  ? { color: "white" }
                  : { color: "black" })}
              />
            </Toggle>

            <Text
              colorTheme={theme}
              ref={refs[index]}
              isCollapsed={elementCollapsed}
              height={height}
            >
              <TextContent>
                <Markdown>{item.text}</Markdown>
              </TextContent>
            </Text>
          </Item>
        );
      })}
    </Acordeon>
  );
};

export default Component;
