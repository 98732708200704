const durations = [
  { value: "minute", label: "Minutos" },
  { value: "hour", label: "Horas" },
  { value: "day", label: "Días" },
  { value: "week", label: "Semanas" },
  { value: "month", label: "Meses" },
  { value: "year", label: "Años" },
];

export default durations;
