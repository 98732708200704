import services from "../../services";
import * as Yup from "yup";

const today = new Date();
today.setHours(0, 0, 0, 0);

export const schema = Yup.object().shape({
  group_type: Yup.string().required("Debes indicar el tipo de grupo."),
  group_id: Yup.number()
    .nullable()
    .required("Debes indicar a que grupo quieres aplicarle el descuento."),
  plan_id: Yup.number()
    .nullable()
    .required("Debes indicar a que precio quieres aplicarle el descuento."),
  coupon_id: Yup.string()
    .matches(
      /^[a-zA-Z0-9._-]+$/,
      "Elimina los espacios o caracteres especiales."
    )
    .test(
      "Código cupón único",
      "El código cupón que ingresaste ya fue utilizado",
      async (value) => {
        const { response, error } = await services.discount.exist(value);

        return error?.response?.status === 404;
      }
    ),
  amount: Yup.number()
    .test(
      "is-decimal",
      "El descuento ingresado no es valido.",
      (value) => !!value && value % 1 === 0
    )
    .positive("El descuento ingresado no es valido.")
    .integer("El descuento ingresado no es valido.")
    .min(1, "Debes ingresas un descuento.")
    .max(100, "El descuento no puede ser superior del 100%.")
    .required("Debes ingresas un descuento."),
  valid_till: Yup.date()
    .min(today, "Debes indicar una fecha futura")
    .required("Debes indicar hasta cuando sera válido el descuento."),
  period: Yup.number()
    .nullable()
    .when("group_type", {
      is: "membership",
      then: Yup.number()
        .min(1, "Debes indicar un periodo mínimo de 1 mes.")
        .required("Debes ingresar un número entero."),
    }),
});

interface FormValues {
  group_type: string;
  group_id?: number;
  plan_id?: number;
  amount?: number;
  valid_till: string;
  period?: number;
}

export const initialValues: FormValues = {
  group_type: "experience",
  group_id: undefined,
  plan_id: undefined,
  amount: 0,
  valid_till: "",
  period: undefined,
};
