import styled from "styled-components";
import Dropdown from "../../../../Dropdown";
import Image from "../../../../Image";
import { rainbowBackgroundGradient } from "../../../../StyledComponents";

export const Header = styled.header`
  position: relative;
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    // margin-top: 20px; // esto lo dejo comentado ya que hay un margen agregado al header que no se entiende por que estaba
  }
`;

export const Navbar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const VerticalSeparator = styled("span") <{ margin?; visible?}>`
  width: 1px;
  height: 34px;
  margin: 0px ${(props) => (props.margin ? props.margin / 2 : `0`)}px;
  background-color: ${(props) => (props.visible ? `#ADADAD` : `transparent`)};
`;

export const HorizontalSeparator = styled("span") <{
  margin?;
  visible?;
  isSeparator?;
}>`
  height: 1px;
  width: 100%;
  background-color: ${(props) => (props.visible ? `#ADADAD` : `transparent`)};
`;

export const Img = styled(Image)`
  &.logo-desktop {
    display: none;
    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      display: block;
    }
  }

  &.logo-mobile {
    display: block;
    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      display: none;
    }
  }
`;
export const CustomDropdown = styled(Dropdown)``;

export const Toggle = styled("div") <{ skin }>`
  ${({ skin }) =>
    skin === "rainbow" ? rainbowBackgroundGradient : "background: #6C5CE7;"}
  width: 41px;
  height: 41px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
