import { getFreeSubscriptions } from "./getFreeSubscriptions";

export const newFreeSubscription = (subscription, idGroup) => {
  const key = "KLOUSER_SUBSCRIBED_GROUPS"
  const otherFreeSubscriptions = getFreeSubscriptions()

  const subscriptionAlreadyExist = otherFreeSubscriptions.some(item => item.id === subscription.id)

  if (!subscriptionAlreadyExist) {
    localStorage.setItem(key, JSON.stringify([...otherFreeSubscriptions, subscription].flat()))
  }
};