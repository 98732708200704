import {
  initialState,
  Actions,
  State,
  DELETE_ONE_SURVEY,
  DELETE_ONE_SURVEY_SUCCESS,
  DELETE_ONE_SURVEY_ERROR,
  RESET_DELETE_ONE_SURVEY,
} from "../../types/survey/delete";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case DELETE_ONE_SURVEY:
      return {
        ...state,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case DELETE_ONE_SURVEY_SUCCESS:
      return {
        ...state,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case DELETE_ONE_SURVEY_ERROR:
      return {
        ...state,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_DELETE_ONE_SURVEY:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
