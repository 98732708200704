import { Helmet } from "react-helmet";
import FrontPage from "./sections/FrontPage";
import Partners from "./sections/Partners";
import Community from "./sections/Community";
import ReferentCard from "./sections/ReferentsCard";
import StartsToday from "./sections/StartsToday";
import Message from "./sections/Message";
import Creates from "./sections/Creates";
import Interact from "./sections/Interact";
import Monetize from "./sections/Monetize";
import Testimonial from "./sections/Testimonial";
import Ready from "./sections/Ready";
import Faqs from "./sections/Faqs";

import logo from "./../../assets/logo.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Klouser</title>
          <meta
            name="description"
            content="Klouser es una plataforma específica para referentes del mundo del bienestar. Trabajamos con personas como tú expertas en diferentes temáticas que generan cambios positivos en la vida de las personas."
          />
          <meta property="og:title" content="Klouser" />
          <meta
            name="description"
            property="og:description"
            content="Klouser es una plataforma específica para referentes del mundo del bienestar. Trabajamos con personas como tú expertas en diferentes temáticas que generan cambios positivos en la vida de las personas."
          />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={logo} />
          <meta property="og:site_name" content="Klouser" />
          <meta
            property="og:url"
            content={`${
              (window as any).SEO_PROTOCOL || (window as any).location.protocol
            }//${(window as any).SEO_HOST || (window as any).location.host}`}
          />
          <meta property="og:locale" content="es_LA" />
          <link
            rel="canonical"
            href={`${
              (window as any).SEO_PROTOCOL || (window as any).location.protocol
            }//${(window as any).SEO_HOST || (window as any).location.host}`}
          />
        </Helmet>
        <FrontPage />
        <Partners />
        <Community />
        <StartsToday />
        <ReferentCard />
        <Message />
        <Creates />
        <Interact />
        <Monetize />
        <Testimonial />
        <Ready />
        <Faqs />
      </header>
    </div>
  );
}

export default App;
