import { SurveyOutDto, SurveyInDto } from "../../../types/survey.dto";

export const NEW_SURVEY = "NEW_SURVEY";
export const NEW_SURVEY_SUCCESS = "NEW_SURVEY_SUCCESS";
export const NEW_SURVEY_ERROR = "NEW_SURVEY_ERROR";
export const RESET_NEW_SURVEY = "RESET_NEW_SURVEY";

export interface NewSurvey {
  type: typeof NEW_SURVEY;
  payload: SurveyOutDto;
}

export interface NewSurveySuccess {
  type: typeof NEW_SURVEY_SUCCESS;
  payload: SurveyInDto;
}

export interface NewSurveyError {
  type: typeof NEW_SURVEY_ERROR;
  payload: string | boolean;
}

export interface ResetNewSurvey {
  type: typeof RESET_NEW_SURVEY;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | NewSurvey
  | NewSurveySuccess
  | NewSurveyError
  | ResetNewSurvey;
