import { Group } from "../../../types";

export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_ERROR = "UPDATE_GROUP_ERROR";
export const RESET_UPDATE_GROUP = "RESET_UPDATE_GROUP";
export interface UpdateGroup {
  type: typeof UPDATE_GROUP;
  payload: Group;
}

export interface UpdateGroupSuccess {
  type: typeof UPDATE_GROUP_SUCCESS;
  payload: Group;
}

export interface UpdateGroupError {
  type: typeof UPDATE_GROUP_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateGroup {
  type: typeof RESET_UPDATE_GROUP;
  payload: null;
}

export interface State {
  data: Group | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | UpdateGroup
  | UpdateGroupSuccess
  | UpdateGroupError
  | ResetUpdateGroup;
