import styled from "styled-components";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";


export const Title = styled("div")`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

export const StyledSlide = styled(Slide)`
  // display: flex;
  // width: 110px;
  // min-width: 110px;
  // min-height: 72px;
  .focusRing___1airF.carousel__slide-focus-ring {
    outline: none !important;
  }
`;

export const Text = styled("p")`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 16px 0px 32px;
`;

export const LossBenefit = styled("li")`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 3px;
  
`;


export const AlignEnd = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    & > * {
      margin-bottom: 10px;
    }
  }
`


export const AlignCenter = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`


export const StyledUl = styled('ul')`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 0;
  margin-bottom: 45px;
  // margin: 16px 0px 32px;
`

export const Error = styled("span")`
  margin-top: 20px;
  font-size:12px;
  display: flex;
  align-items: end;
  justify-content: flex-end;
  width: 100%;
  color: #ff0000;
`

