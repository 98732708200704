import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

interface GoogleAnalyticsProviderProps {
  trackingId: string;
}

export const GoogleAnalyticsProvider: React.FC<GoogleAnalyticsProviderProps> = ({ trackingId, children }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(trackingId);
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.search });
  }, [trackingId, location]);

  return <>{children}</>;
};



export const GTMInitializer = ({ gtmId }) => {
  useEffect(() => {
    TagManager.initialize({ gtmId });
  }, [gtmId]);

  return null;
};
