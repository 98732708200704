import styled from "styled-components";

export const DisabledDropzone = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: not-allowed;
  background-image: #F5F5F5;
  border: 1px solid #C2C2C2;

  border-radius: 16px;
  color: #bdbdbd;
  outline: none;

  width: 217px;
  height: 129px;
  margin-bottom: 16px;
  cursor pointer;

  span {
    display: block;
    line-height: 16px;
    min-height: 16px;
  }
`;

export const Message = styled("p")`
  margin: 0px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0px;
  text-align: center;
`;
