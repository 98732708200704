import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Action, Dispatch } from 'redux';
import services from '../../services';
import { getFilePath } from '../../services/storage';
import { hideModal, showModal } from '../../store/actions/modal';
import { NewFileInDto } from '../../types/storage.dto';

interface FileUploadContextProps {
  fileUploadProgress: { fileName: string, fileType: string, originalName: string, progress: number, remainingTime: string, preview?: string }[];
  updateFileUploadProgress: ({ fileName, fileType, originalName, progress, fileSize, preview }: { fileName: string, fileType: string, originalName: string, progress: number, fileSize: number, preview?: string }) => void;
  resetFileUploadProgress: () => void;
  uploadFiles
}

const FileUploadProgressContext = createContext<FileUploadContextProps | undefined>(undefined);

interface FileUploadProgressProviderProps {
  children: ReactNode;
}

export const FileUploadProgressProvider: React.FC<FileUploadProgressProviderProps> = ({ children }) => {
  const dispatch = useDispatch<Dispatch<Action>>()
  const [fileUploadProgress, setFileUploadProgress] = useState<{ fileName: string; fileType: string; originalName: string; progress: number; remainingTime: string, preview?: string }[]>([]);
  const [uploadSpeed, setUploadSpeed] = useState<number | null>(null);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [startBytesLoaded, setStartBytesLoaded] = useState<number | null>(null); // Add this line

  const uploadFiles = async (files: { [field: string]: (File & { preview?: string, url?: string })[] }, idUser: number, path: string) => {
    const timestamp = new Date().getTime();

    // Create an object to hold the uploaded files for each type
    const uploadedFiles: { [field: string]: NewFileInDto[] } = {};

    for (const [fileType, fileList] of Object.entries(files)) {
      // Initialize the array for this fileType in uploadedFiles
      uploadedFiles[fileType] = uploadedFiles[fileType] || [];

      // Filter out files that are instances of File
      const fileListFiltered = fileList.filter(file => file instanceof File);

      // Add non-File instances directly to uploadedFiles[fileType]
      const nonFileList = fileList.filter(file => !(file instanceof File));
      nonFileList.forEach(file => {
        uploadedFiles[fileType].push(file);
      });

      // Create an array to hold all upload promises
      const uploadPromises: Promise<any>[] = [];

      for (let i = 0; i < fileListFiltered.length; i++) {
        const file = fileListFiltered[i];
        const fileExt = file?.name?.slice((file?.name?.lastIndexOf(".") - 1 >>> 0) + 2);
        const fileName = `${idUser}_${path}_${fileType}_${timestamp + i}`;

        const uploadPromise = new Promise((resolve, reject) => {
          services?.storage.saveFile({
            file,
            fileName: getFilePath({ fileName, fileExt, entity: path }),
            onUploadProgress: (uploadProgress) => {
              updateFileUploadProgress({ preview: file?.url || file?.preview, fileName: fileName, fileSize: file.size, fileType: file.type, originalName: file.name, progress: uploadProgress });
            },
            onAbort: () => {
              dispatch(hideModal())
            },
          })
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });

        uploadPromises.push(uploadPromise);
      }

      // Wait for all upload promises for this fileType to resolve
      try {
        const uploadResponses = await Promise.all(uploadPromises);

        // Check if all uploads for this fileType were successful
        const allUploadsSuccessful = uploadResponses.every(
          (response) => 'response' in response
        );

        if (allUploadsSuccessful) {
          // Create an array to hold attached files data for this fileType
          const uploadedFilesForType = fileListFiltered.map((file, i) => {
            const ext = file?.name?.slice((file?.name?.lastIndexOf(".") - 1 >>> 0) + 2);
            const fileName = `${idUser}_${path}_${fileType}_${timestamp + i}`;

            return {
              file_name: fileName,
              original_name: file.name,
              mime: file.type,
              extension: ext,
              size: file.size,
              path: getFilePath({ fileName, fileExt: ext, entity: path }),
            };
          });

          // Append the successfully uploaded files to the uploadedFiles object
          uploadedFiles[fileType].push(...uploadedFilesForType);
        } else {
          // Handle the case where at least one upload failed
          console.error(`One or more file uploads for ${fileType} failed.`);
        }
      } catch (error) {
        // Handle errors that occurred during file uploads
        console.error(`Error during file uploads for ${fileType}:`, error);
      }
    }

    return uploadedFiles;
  };


  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const pad = (num: number) => (num < 10 ? "0" + num : num);

    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  };

  const calculateUploadSpeed = (bytesLoaded: number) => {
    const currentTime = new Date().getTime();
    const elapsedTimeInSeconds = (currentTime - startTime!) / 1000; // convert to seconds

    const bytesUploaded = bytesLoaded - (startBytesLoaded || 0); // fallback to 0 if startBytesLoaded is not available
    const uploadSpeed = bytesUploaded / elapsedTimeInSeconds; // bytes per second

    return uploadSpeed;
  };

  const onUploadStart = () => {
    setStartTime(new Date().getTime());
    setStartBytesLoaded(0);
    setUploadSpeed(null);
  };

  const onUploadProgress = (event) => {
    const bytesLoaded = event.loaded;

    if (!startTime) {
      // Set initial values on the first progress event
      setStartTime(new Date().getTime());
      setStartBytesLoaded(bytesLoaded);
      setUploadSpeed(null);
      return;
    }

    const currentUploadSpeed = calculateUploadSpeed(bytesLoaded);
    setUploadSpeed(currentUploadSpeed);
  };

  const updateFileUploadProgress = ({ fileName, fileType, originalName, progress, fileSize, preview, }: { fileName: string, fileType: string, originalName: string, progress: number, fileSize: number, preview?: string }) => {
    setFileUploadProgress((prevProgress) => {
      let updatedProgress = [...prevProgress];
      const fileIndex = updatedProgress.findIndex((file) => file.fileName === fileName);

      if (fileIndex !== -1) {
        // Calculate remaining time
        const remainingBytes = fileSize - progress;
        const remainingTimeInSeconds = remainingBytes / (uploadSpeed || 1); // fallback to 1 if uploadSpeed is not available
        const remainingTimeFormatted = formatTime(remainingTimeInSeconds);

        // Update progress and remaining time
        updatedProgress[fileIndex].progress = progress;
        updatedProgress[fileIndex].remainingTime = remainingTimeFormatted;
      } else {
        // Add new file entry
        updatedProgress = [...prevProgress, { preview, fileName, fileType, originalName, progress, remainingTime: '0' }];
      }

      return updatedProgress;
    });
  };

  const resetFileUploadProgress = () => {
    setFileUploadProgress([]);
  };

  /*useEffect(() => {
    console.log('fileUploadProgress provider', fileUploadProgress)
  }, [fileUploadProgress])*/

  return (
    <FileUploadProgressContext.Provider
      value={{
        fileUploadProgress,
        updateFileUploadProgress,
        resetFileUploadProgress,
        uploadFiles,
      }}
    >
      {children}
    </FileUploadProgressContext.Provider>
  );
};

export const useFileUploadProgress = (): FileUploadContextProps => {
  const context = useContext(FileUploadProgressContext);
  if (!context) {
    throw new Error('useFileUploadProgress must be used within a FileUploadProgressProvider');
  }
  return context;
};
