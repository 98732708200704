import { combineReducers } from "redux";
import community from "./community";
import posts from "./posts";
import subscribers from "./subscribers";
import subscribersEmails from "./subscribers-emails";
import metrics from "./metrics";
import subscription from "./subscription";

const reducer = combineReducers({
  community,
  posts,
  subscribers,
  subscribersEmails,
  metrics,
  subscription,
});

export default reducer;
