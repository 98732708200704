import { connect } from "react-redux";
import image from "../../../../assets/man-hands-into-pocket.svg";
import Image from "../../../../components/Image";
import Link from "../../../../components/Link";
import { Group } from "../../../../types";
import { Center } from "./styles";

interface ComponentProps {
  link?: string;
  label?: string;
  group: Group;
  text?: any
}

const groupTypes = {
  membership: "la membresia",
  experience: "el taller",
  free_experience: "el evento",
};

const Component = ({ link, label, group, text }: ComponentProps) => {
  return (
    <Center>
      <Image src={image} width="322px" height="345px" alt="Not Found" />
      <p style={{ margin: "29px 0px 8px" }}>
        {text}
      </p>
      {link && (
        <Link
          style={{ maxWidth: "323px" }}
          href={link}
          options={{ size: "lg", skin: "purple", type: "outline", block: true }}
        >
          {label}
        </Link>
      )}
    </Center>
  );
};

const state = ({ groupStore }) => {
  const { data: group, states: groupStates } = groupStore.group;

  return {
    group,
    groupStates,
  };
};

export default connect(state)(Component);
