import styled from "styled-components";

export const Title = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0px 0px 24px 0px;

  @media (min-width: 1024px) {
    grid-template-columns: auto 200px 200px 200px;
    margin-bottom: 20px;
  }
`;

export const Filters = styled("div")`
  display: grid;
  // grid-template-rows: auto 200px 200px 200px;
  grid-gap: 10px;
  row-gap: 20px;
  margin-bottom: 50px;

  @media (min-width: 1024px) {
    grid-template-columns: auto 200px 200px 200px;
    margin-bottom: 20px;
  }
`;

export const FilterLabel = styled("span")`
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  display: flex;
`;
