import styled from "styled-components";

export const Alert = styled("div") <{ marginBottom?}>`
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.Gray16};
  border-radius: 16px;
  padding: 24px 35px;
  position: relative;
  ${(props) => `margin-bottom: ${props?.marginBottom || 0}px`};

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 16px;
    background: ${(props) => props.theme.colors.Primary};
  }
`;

export const Close = styled("button")`
  background: transparent;
  position: absolute;
  cursor: pointer;
  top: 18px;
  right: 18px;
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 1;

  &:focus {
    outline: transparent;
  }
`;

export const Title = styled("h3")`
  margin: 0px 0px 8px 0px;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.Black};
`;

export const Text = styled("p")`
  margin: 0px;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.Black};

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 16px;
  }
`;

export const InLine = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
