import { useEffect, useMemo } from "react";
import ReactPixel from "react-facebook-pixel";
import { connect } from "react-redux";

const Component = ({ group, groupStates, currentPage }) => {
  const pixelId: string = useMemo(() => {
    return group?.expert?.account_fb_pixel_id || "";
  }, [group]);

  const advancedMatching = undefined;
  const options = {
    autoConfig: true,
    debug: false,
  };

  const init = () => {
    ReactPixel.init(pixelId, advancedMatching, options);
    ReactPixel.pageView();
  };

  useEffect(() => {
    if (!!group?.expert?.account_fb_pixel_id) {
      init();
    }
  }, [group]);

  return <></>;
};

const state = ({ groupStore, pageStore }) => {
  const { name: currentPage } = pageStore;
  const { data: group, states: groupStates } = groupStore.group;

  return {
    group,
    groupStates,
    currentPage,
  };
};

export default connect(state)(Component);
