import {
  initialState,
  Actions,
  State,
  GET_USER_GROUPS,
  GET_USER_GROUPS_SUCCESS,
  GET_USER_GROUPS_ERROR,
  UPDATE_CURRENT_USER_GROUPS,
  UPDATE_CURRENT_USER_GROUPS_SUCCESS,
  UPDATE_CURRENT_USER_GROUPS_ERROR,
} from "../../types/user/get-user-groups";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_USER_GROUPS:
      return {
        ...state,
        data: null,
        states: {
          success: false,
          loading: true,
          error: false,
        },
      };
    case GET_USER_GROUPS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          success: true,
          loading: false,
          error: false,
        },
      };
    case GET_USER_GROUPS_ERROR:
      return {
        ...state,
        data: null,
        states: {
          success: false,
          loading: false,
          error: action.payload,
        },
      };

    case UPDATE_CURRENT_USER_GROUPS:
      return {
        ...state,
        data: state?.data,
        states: {
          success: false,
          loading: true,
          error: false,
        },
      };
    case UPDATE_CURRENT_USER_GROUPS_SUCCESS:
      return {
        ...state,
        data: action?.payload,
        states: {
          success: true,
          loading: false,
          error: false,
        },
      };
    case UPDATE_CURRENT_USER_GROUPS_ERROR:
      return {
        ...state,
        data: state?.data,
        states: {
          success: false,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducers;
