import constants from "../constants";

export const newAppDemo = (name = "", email = "") => {
  const {
    answers: calendlyAnswers,
    user: calendlyUser,
    meet: calendlyMeet,
  } = constants.calendly.calendlyAppDemo;

  window.Calendly.initPopupWidget({
    url: `https://calendly.com/${calendlyUser}/${calendlyMeet}`,
    prefill: {
      name,
      email,
      customAnswers: { ...calendlyAnswers },
    },
  });
};
