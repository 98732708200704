export const USER_IS_LOGGEDIN = "USER_IS_LOGGED_IN";

export interface UserIsLoggedId {
  type: typeof USER_IS_LOGGEDIN;
  payload: boolean;
}

export interface State {
  isLoggedIn: boolean,
};

export const initialState: State = {
  isLoggedIn: false
};

export type Actions =
  | UserIsLoggedId;
