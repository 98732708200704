import { useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Action, Dispatch } from "redux";
import { useTheme } from "styled-components";
import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import { showModal } from "../../../../../../store/actions/modal";
import CardAticle from "../Card";
import Grid from '../../../../../../components/Grid'
import Button from '../../../../../../components/Button'
import Link from '../../../../../../components/Link'
import Icon from '../../../../../../components/Icon'
import closeCircleIcon from '../../../../../../assets/icons/close_circled.svg'
import { Center } from '../../../../../../components/StyledComponents'
import checkCircledIcon from "../../../../../../assets/icons/check_circled.svg";
import arrowRightTopIcon from "../../../../../../assets/icons/arrow_forward_up_right.svg";
import ProgressBar from '../ProgressBar'
import {
  CloseButton,
  Card,
  SectionTitle,
  Title,
  Completed,
  NotCompleted,
  Text,
} from './styles'
import slugify from "slugify";
import { GetWelcomeContentSectionInDto } from "../../../../../../types/welcome-section";

interface ComponentProps {
  welcomeContentSection: GetWelcomeContentSectionInDto
  showCloseButton: boolean
}

const Component = ({
  welcomeContentSection,
  showCloseButton = false
}) => {
  const dispatch = useDispatch<Dispatch<Action>>()
  const { isMobile } = useWindowSize()

  // Ordenar los welcome_contents por content_order
  const sortedWelcomeContents = useMemo(() => {
    return welcomeContentSection?.welcome_contents?.slice().sort((a, b) => a.content_order - b.content_order);
  }, [welcomeContentSection]);

  // Obtener el primer item
  const firstItem = useMemo(() => {
    return sortedWelcomeContents?.length > 0 ? sortedWelcomeContents[0] : null;
  }, [sortedWelcomeContents]);

  // Obtener el primer item con completed en false
  const firstIncompleteItem = useMemo(() => {
    return sortedWelcomeContents?.find(item => !item.completed) || null;
  }, [sortedWelcomeContents]);

  return (
    <Card>
      {showCloseButton && (
        <CloseButton onClick={() => { dispatch(showModal("modal-minimize-welcome-section")) }}><Icon icon={closeCircleIcon} size="20px" /></CloseButton>
      )}

      <Center horizontal="space" vertical="center" style={{ width: '100%' }}>
        <div style={{ width: '100%' }}>
          {(isMobile || welcomeContentSection?.user_finished_welcome_section) && (
            <>
              <SectionTitle>Comienza por aquí</SectionTitle>
              <div style={{ height: 6 }} />
            </>
          )}

          <Title>{welcomeContentSection?.title}</Title>
        </div>

        {!isMobile && welcomeContentSection?.user_finished_welcome_section && (
          <div style={{ flexShrink: 0 }}>
            <Center horizontal="left" vertical="center" gap={isMobile ? 16 : 32}>
              <Completed>¡Finalizado con exito! 🎉</Completed>
              <NotCompleted to={`/group/${welcomeContentSection?.group?.id}/wc/${firstItem?.id}`}>
                <span>Volver a ver</span>
                <Icon icon={arrowRightTopIcon} size="14px" color={"white"} />
              </NotCompleted>
            </Center>
          </div>
        )}
      </Center>

      {!isMobile && !welcomeContentSection?.user_finished_welcome_section && (
        <>
          <div style={{ height: isMobile ? 22 : 18 }} />
          <Text>{welcomeContentSection?.description}</Text>
        </>
      )}

      {welcomeContentSection?.user_finished_welcome_section ? (
        <>
          {isMobile && (
            <>
              <div style={{ height: isMobile ? 19 : 34 }} />
              <Center horizontal="space" vertical="center" gap={isMobile ? 0 : 32}>
                <Completed>¡Finalizado con exito! 🎉</Completed>
                <NotCompleted to={`/group/${welcomeContentSection?.group?.id}/wc/${firstItem?.id}`}>
                  <span>Volver a ver</span>
                  <Icon icon={arrowRightTopIcon} size="14px" color={"white"} />
                </NotCompleted>
              </Center>
            </>
          )}
        </>
      ) : (
        <>
          <div style={{ height: isMobile ? 19 : 34 }} />
          <Center horizontal="right" vertical={isMobile ? "bottom" : "top"} column gap={isMobile ? 10 : 35} >
            <ProgressBar />
            {firstIncompleteItem && (
              <NotCompleted to={`/group/${welcomeContentSection?.group?.id}/wc/${firstIncompleteItem?.id}`}>
                <span>{isMobile ? "Ver próximo contenido" : `Próximo: ${firstIncompleteItem?.title || ""}`}</span>
                <Icon icon={arrowRightTopIcon} size="14px" color={"white"} />
              </NotCompleted>
            )}
          </Center>
        </>
      )}
    </Card>
  )
}


const state = ({ userStore, groupStore, welcomeContentStore }) => {
  const { isLoggedIn } = userStore;
  const { data: user, states: userStates } =
    userStore.information;
  const { data: welcomeContentSection, states: welcomeContentSectionStates } = welcomeContentStore.welcomeContentSection;
  const { data: group, states: groupStates } = groupStore.group;

  return {
    isLoggedIn,
    user,
    userStates,
    welcomeContentSection,
    welcomeContentSectionStates,
    group,
    groupStates,
  };
};

export default connect(state)(Component);
