import action from "./../helpers/rest-client";

export class ErrorInvitation extends Error { }
class Invitation {
  public async createInvitation(payload) {
    return action
      .Post({
        url: "/api/group/create-invitation",
        body: payload,
      })
      .then((response) => {
        return { response, error: undefined };
      })
      .catch((error) => {
        return { error, response: undefined };
      });
  }

  public async editInvitation(payload) {
    const { subscription_id } = payload

    return action
      .Put({
        url: `/api/subscription/guests/${subscription_id}`,
        body: payload,
      })
      .then((response) => {
        return { response, error: undefined };
      })
      .catch((error) => {
        return { error, response: undefined };
      });
  }

  public async validateInvitation(payload) {
    return action
      .Post({
        url: `/api/group/validate-invitation`,
        body: payload,
      })
      .then((response) => {
        return { response, error: undefined };
      })
      .catch((error) => {
        return { error, response: undefined };
      });
  }

  public async acceptInvitation(payload: { reference_id: number }) {
    const response = await action
      .Post({
        url: `/api/group/accept-invitation`,
        body: payload
      })
      .then((response) => {
        return { response, error: undefined };
      })
      .catch((error) => {
        return { error, response: undefined };
      });

    return response;
  }
}

const invitation = new Invitation();

export default invitation;
