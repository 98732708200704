import styled from "styled-components";

export const Title = styled.h1`
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin: 24px 0px 37px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    text-align: left;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    margin: 59px 0px 32px;
  }
`;

export const CardsGrid = styled("div")`
  display: grid;
  gap: 16px;
  grid-auto-rows: 113px;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
`;
