import Grid from "../../../../../components/Grid";
import Clock from "../../../../../components/Clock";
import { Banner, Title, SubTitle } from "./styles";

const Component = () => {
  return (
    <Banner>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col lg={10} offset={{ lg: 1 }}>
            <Title>
              Congreso
              <br /> <small>del</small> Bienestar
            </Title>
            <SubTitle>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus
              orci a risus mollis imperdiet. Ut sit amet velit elementum,
              hendrerit leo a, auctor metus. Proin finibus dolor eget suscipit
              eleifend.
            </SubTitle>
            <Clock
              date="2022-12-10T11:00:00.000Z"
              onEnd={() => window.location.reload()}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Banner>
  );
};

export default Component;
