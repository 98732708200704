import { SurveyOutDto, SurveyAnswerOutDto } from "../../types/survey.dto";

import {
  NEW_SURVEY,
  NewSurvey,
  ResetNewSurvey,
  RESET_NEW_SURVEY,
} from "../types/survey/new";

import {
  NEW_SURVEY_ANSWER,
  NewSurveyAnswer,
  ResetNewSurveyAnswer,
  RESET_NEW_SURVEY_ANSWER,
} from "../types/survey/new-answer";

import {
  UPDATE_ONE_SURVEY,
  UpdateOneSurvey,
  ResetUpdateOneSurvey,
  RESET_UPDATE_ONE_SURVEY,
} from "../types/survey/update";

import {
  DELETE_ONE_SURVEY,
  DeleteOneSurvey,
  RESET_DELETE_ONE_SURVEY,
  ResetDeleteOneSurvey,
} from "../types/survey/delete";

import {
  GET_ONE_SURVEY,
  GetOneSurvey,
  RESET_GET_ONE_SURVEY,
  ResetGetOneSurvey,
} from "../types/survey/one";

import {
  GetAllSurveys,
  GET_ALL_SURVEYS,
  ResetGetAllSurveys,
  RESET_GET_ALL_SURVEYS,
} from "../types/survey/all";

import {
  GetAllSurveyAnswers,
  GET_ALL_SURVEY_ANSWERS,
  ResetGetAllSurveyAnswers,
  RESET_GET_ALL_SURVEY_ANSWERS,
} from "../types/survey/all-answers";

import {
  ExportSurveyAnswers,
  EXPORT_SURVEY_ANSWERS,
} from "../types/survey/export-survey-answers";

export const newSurvey = (payload: SurveyOutDto): NewSurvey => {
  return { type: NEW_SURVEY, payload };
};

export const resetNewSurvey = (): ResetNewSurvey => {
  return { type: RESET_NEW_SURVEY, payload: null };
};

export const updateSurvey = (payload: SurveyOutDto): UpdateOneSurvey => {
  return { type: UPDATE_ONE_SURVEY, payload };
};

export const resetUpdateSurvey = (): ResetUpdateOneSurvey => {
  return { type: RESET_UPDATE_ONE_SURVEY, payload: null };
};

export const deleteSurvey = (payload: {
  idSurvey: number;
}): DeleteOneSurvey => {
  return { type: DELETE_ONE_SURVEY, payload };
};

export const resetDeleteSurvey = (): ResetDeleteOneSurvey => {
  return { type: RESET_DELETE_ONE_SURVEY, payload: null };
};

export const getSurvey = (payload: { idSurvey: number }): GetOneSurvey => {
  return { type: GET_ONE_SURVEY, payload };
};

export const resetGetSurvey = (): ResetGetOneSurvey => {
  return { type: RESET_GET_ONE_SURVEY, payload: null };
};

export const getAllSurveys = ({
  idGroup,
}: {
  idGroup: number;
}): GetAllSurveys => {
  return { type: GET_ALL_SURVEYS, payload: { idGroup } };
};

export const resetGetAllSurveys = (): ResetGetAllSurveys => {
  return { type: RESET_GET_ALL_SURVEYS, payload: null };
};

export const newSurveyAnswer = (
  payload: SurveyAnswerOutDto
): NewSurveyAnswer => {
  return { type: NEW_SURVEY_ANSWER, payload };
};

export const resetNewSurveyAnswer = (): ResetNewSurveyAnswer => {
  return { type: RESET_NEW_SURVEY_ANSWER, payload: null };
};

export const getAllSurveyAnswers = (payload: {
  idSurvey: number;
}): GetAllSurveyAnswers => {
  return { type: GET_ALL_SURVEY_ANSWERS, payload };
};

export const resetGetAllSurveyAnswers = (): ResetGetAllSurveyAnswers => {
  return { type: RESET_GET_ALL_SURVEY_ANSWERS, payload: null };
};

export const exportSurveyAnswers = (payload): ExportSurveyAnswers => {
  return { type: EXPORT_SURVEY_ANSWERS, payload };
};
