import { SessionInDto } from "../../../types/session.dto";

export const GET_ONE_SESSION = "GET_ONE_SESSION";
export const GET_ONE_SESSION_SUCCESS = "GET_ONE_SESSION_SUCCESS";
export const GET_ONE_SESSION_ERROR = "GET_ONE_SESSION_ERROR";
export const RESET_GET_ONE_SESSION = "RESET_GET_ONE_SESSION";

export interface GetOneSession {
  type: typeof GET_ONE_SESSION;
  payload: { idSession: number };
}

export interface GetOneSessionSuccess {
  type: typeof GET_ONE_SESSION_SUCCESS;
  payload: SessionInDto;
}

export interface GetOneSessionError {
  type: typeof GET_ONE_SESSION_ERROR;
  payload: string | boolean;
}

export interface ResetGetOneSession {
  type: typeof RESET_GET_ONE_SESSION;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetOneSession
  | GetOneSessionSuccess
  | GetOneSessionError
  | ResetGetOneSession;
