import styled from "styled-components";
import ImageUi from "../../../../components/Image";

export const Card = styled.div`
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  box-shadow: 0px 1px 12px rgba(202, 202, 202, 0.15);
  border-radius: 16px;
  padding: 30px 25px;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 30px;
`;

export const User = styled.div`
  display: flex;
  align-items: center;
`;

export const Data = styled.div``;

export const Avatar = styled(ImageUi)`
  width: 50px;
  height: 50px;
  border: 1px solid #e2e2e2;
  margin-right: 15px;
  border-radius: 50%;
`;

export const UserName = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
`;

export const GroupName = styled.div`
  font-size: 12px;
  line-height: 15px;
`;
