import { Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { getGroupById } from "../../../store/actions/group";
import { PageBody } from "./styles";
import Grid from "../../../components/Grid";
import Loader from "../../../components/Loader";

interface ComponentProps {
  children;
  match;
  pageContentRef?;
  group;
  groupStates;
}

const Component = ({
  children,
  match,
  pageContentRef,
  group,
  groupStates,
}: ComponentProps) => {
  const { loading, success, error } = groupStates;
  const dispatch = useDispatch();

  useEffect(() => {
    const otherGroup =
      group &&
      group?.id !== Number(match?.params?.idGroup);

    if (
      !!match.params &&
      (otherGroup || !group) &&
      !groupStates.loading
    ) {
      dispatch(getGroupById({ group_id: match.params.idGroup }));
    }
  }, [match]);

  if (loading || (!group && !error)) {
    return <Loader />;
  }

  if (error === "Not Found" || error === "Forbidden") {
    return <Redirect to="/404" />;
  }

  return (
    <PageBody>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col sm={6}>
            {React.createElement(children.type, {
              ...{
                ...children.props,
                pageContentRef: pageContentRef,
              },
            })}
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </PageBody>
  );
};

const state = ({ groupStore }) => {
  const { data: group, states: groupStates } = groupStore.group;

  return {
    group,
    groupStates,
  };
};

export default connect(state)(Component);
