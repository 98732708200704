import styled from "styled-components";

const button = `
  position: relative;
  border: 0px;
  padding: 0px;
  margin: 0px;
  background-color: #eeeeee;
  color: rgba(0, 0, 24, 0.48);
  font-size: 14px;
  width: 24px;
  line-height: 24px;
  border-radius: 50%;
  outline: 0;
  display: flex;
  align-items: center;
	justify-content: center;
  cursor: pointer;
	
	&:hover {
		background: rgba(0, 0, 24, 0.08);
		border-radius: 50px;
	}
`;

const activePage = `
  content: '';
  display: block;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  background-color: #6C5CE7;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  top: 0;
	left: 0;
`;

const disabled = `
	&:disabled,
	&[disabled] {
		background: #fbfbfb;

		i:before {
			background-color: #e3e3e3;
		}
	}
`;

export const PaginationComponent = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 92px;
`;

export const PagesList = styled("div")`
  margin: 0px;
  padding: 0px 4px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  background-color: #eeeeee;
  height: 24px;
`;

export const PageLink = styled("button")<{ isActive? }>`
  ${button}

  ${({ isActive }) =>
    isActive &&
    ` width: 30px;
      height: 30px;	
      position: relative;
      
      span {
        position: relative;
        z-index: 2;
        font-weight: 600;
        font-size: 16px;
        color: white;
      }

      &:after {
        ${activePage}
      }`}
`;

export const PrevPage = styled("button")`
  ${button}
  height: 24px;
  margin-right: 16px;
  border-radius: 50%;
  ${disabled}
`;

export const NextPage = styled("button")`
  ${button}
  height: 24px;
  margin-left: 16px;
  border-radius: 50%;
  ${disabled}
`;

export const Etc = styled("div")`
  font-size: 14px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  text-align: center;
`;
