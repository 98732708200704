
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_ERROR = "CHANGE_USER_PASSWORD_ERROR";
export const RESET_CHANGE_USER_PASSWORD = "RESET_CHANGE_USER_PASSWORD";

export interface ChangeUserPassword {
  type: typeof CHANGE_USER_PASSWORD;
  payload: string;
}

export interface ChangeUserPasswordSuccess {
  type: typeof CHANGE_USER_PASSWORD_SUCCESS;
  payload: string;
}

export interface ChangeUserPasswordError {
  type: typeof CHANGE_USER_PASSWORD_ERROR;
  payload: boolean | string;
}

export interface ResetChangeUserPassword {
  type: typeof RESET_CHANGE_USER_PASSWORD;
  payload: null
}

export interface State {
  states: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
}

export const initialState: State = {
  states: {
    loading: false,
    error: false,
    success: false,
  },
};

export type Actions =
  | ChangeUserPassword
  | ChangeUserPasswordSuccess
  | ChangeUserPasswordError
  | ResetChangeUserPassword;
