import * as Yup from "yup";

const today = new Date();


export const schema = Yup.object().shape({
  title: Yup.string().required("Debes indicar el nombre del encuentro."),
  description: Yup.string()
    .nullable()
    .required("Agrega una descripción a tu encuentro."),
  join_url: Yup.string().matches(
    /^(http|https):\/\/[^\s/$.?#].[^\s]*$/,
    'La url no posee http:// o https://'
  ),
  speaker_name: Yup.array().of(Yup.string()).nullable(),
  speaker_profile_pic: Yup.array().nullable(),
  use_profile_info: Yup.array().of(Yup.string()).nullable(),
  cover_pic: Yup.array().nullable(),
  session_datetime: Yup.date()
  //.min(today, "Debes indicar una fecha futura")
  .required("Indica la fecha del encuentro."),
  content_categories: Yup.array().of(Yup.number()).nullable(),
  recorded_session_url: Yup.string(),
  group: Yup.number().required(),
});

interface FormNotificationRemindersValues {
  exec_time_before: number | null;
  exec_time_before_unit: string | null;
  enabled: string[];
  custom_message: string;
  execution_type: string;
  type: string;
  exec_datetime?: string;
  schedule_type?: string;
}

interface FormValues {
  title: string;
  description: string;
  join_url: string;
  speaker_name: string;
  speaker_profile_pic: object[];
  use_profile_info: string[];
  cover_pic: object[];
  session_datetime: string;
  content_categories: number[];
  recorded_session_url: string;
  group: number | null;
  will_be_recorded: string[];
  timezones_to_show: string[];
  duration: number;
  duration_type: string;
  access_code: string | null;
  recorded_access_code: string | null;
  notify_upload_recording: string[];
  notificate_by_chat: string[];
  notificate_by_email: string[];
  session_reminders_settings: FormNotificationRemindersValues[];
}

export const initialValues: FormValues = {
  title: "",
  description: "",
  join_url: "",
  speaker_name: "",
  speaker_profile_pic: [],
  use_profile_info: ["true"],
  cover_pic: [],
  session_datetime: "",
  content_categories: [],
  recorded_session_url: "",
  group: null,
  will_be_recorded: ["true"],
  timezones_to_show: [],
  duration: 0,
  duration_type: "hour",
  access_code: "",
  recorded_access_code: "",
  notificate_by_chat: ["true"],
  notificate_by_email: ["true"],
  notify_upload_recording: ["true"],
  session_reminders_settings: []
};
