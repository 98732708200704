import {
    EditInvitationDto
  } from "../../../types/invitation.dto";


export const EDIT_INVITATION = "EDIT_INVITATION";
export const EDIT_INVITATION_SUCCESS = "EDIT_INVITATION_SUCCESS";
export const EDIT_INVITATION_ERROR = "EDIT_INVITATION_ERROR";
export const RESET_EDIT_INVITATION = "RESET_EDIT_INVITATION";

export interface EditInvitation {
    type: typeof EDIT_INVITATION;
    payload: EditInvitationDto;
  }
  
  export interface EditInvitationSuccess {
    type: typeof EDIT_INVITATION_SUCCESS;
    payload: EditInvitationDto | null;
  }
  
  export interface EditInvitationError {
    type: typeof EDIT_INVITATION_ERROR;
    payload: boolean | string;
  }
  
  export interface ResetEditInvitation {
    type: typeof RESET_EDIT_INVITATION;
    payload: null;
  }
  
  export interface State {
    data: EditInvitationDto | null;
    states: {
      success: boolean;
      loading: boolean;
      error: boolean | string;
    };
  }
  
  export const initialState: State = {
    data: null,
    states: {
      success: false,
      loading: false,
      error: false,
    },
  };
  
  export type Actions =
    | EditInvitation
    | EditInvitationSuccess
    | EditInvitationError
    | ResetEditInvitation;
  