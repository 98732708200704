import { useEffect, useRef } from 'react';
import { Pdf } from './styles';

interface ComponentProps {
  canvas: HTMLCanvasElement,
  className?: string
}

const Component = ({
  canvas,
  ...rest
}: ComponentProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const handleCanvasContextMenu = (e) => {
    e.preventDefault(); // Prevent the right-click context menu
  };

  useEffect(() => {
    if (canvasRef.current) {
      const canvasElement = canvasRef.current;
      const context = canvasElement.getContext('2d');

      // Get the dimensions of the original image (from the canvas)
      const imageWidth = canvas.width;
      const imageHeight = canvas.height;

      // Set the canvas dimensions to match the image dimensions
      canvasElement.width = imageWidth;
      canvasElement.height = imageHeight;

      // Clear the canvas before drawing the image
      context?.clearRect(0, 0, imageWidth, imageHeight);

      // Draw the image on the canvas
      context?.drawImage(canvas, 0, 0, imageWidth, imageHeight);
    }
  }, [canvas]);

  return (
    <Pdf ref={canvasRef} {...rest} onContextMenu={handleCanvasContextMenu} />
  );
};

export default Component