import { Button } from "./styles";
import Loader from "../Loader";
interface ButtonProps {
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
  children: any;
  disabled?: boolean;
  forwardref?;
  form?: any;
  loading?: boolean;
  options: {
    type: "filled" | "outline" | "link" | "common";
    size: "xs" | "sm" | "md" | "lg" | "xl";
    skin:
    | "purple"
    | "ligthPurple"
    | "lightGray"
    | "lightBlue"
    | "transparent"
    | "black"
    | "white"
    | "rainbow";
    block?: boolean;
  };
  style?;
}

const Component = ({
  children,
  loading,
  forwardref,
  type = "button",
  ...rest
}: ButtonProps) => {

  return (
    <Button {...(forwardref && { ref: forwardref })} {...rest} disabled={rest?.disabled || loading} type={type}>
      {loading ? (
        <Loader
          color={rest?.options?.type === "outline" || rest?.options?.type === "link" ? "#6C5CE7" : "white"}
        />
      ) : (
        children
      )}
    </Button>
  );
};

export default Component;
