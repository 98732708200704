import styled from "styled-components";
import Button from '../../../../../../components/Button'


export const Tabs = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px
`

export const Tab = styled(Button)`
  
`