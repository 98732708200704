export const GET_GROUP_SESSIONS = "GET_GROUP_SESSIONS";
export const GET_GROUP_SESSIONS_SUCCESS = "GET_GROUP_SESSIONS_SUCCESS";
export const GET_GROUP_SESSIONS_ERROR = "GET_GROUP_SESSIONS_ERROR";
export const RESET_GET_GROUP_SESSIONS = "RESET_GET_GROUP_SESSIONS";

export interface GetGroupSessions {
  type: typeof GET_GROUP_SESSIONS;
  payload: string;
}

export interface GetGroupSessionsSuccess {
  type: typeof GET_GROUP_SESSIONS_SUCCESS;
  payload: any;
}

export interface GetGroupSessionsError {
  type: typeof GET_GROUP_SESSIONS_ERROR;
  payload: boolean | string;
}

export interface ResetGetGroupSessions {
  type: typeof RESET_GET_GROUP_SESSIONS;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetGroupSessions
  | GetGroupSessionsSuccess
  | GetGroupSessionsError
  | ResetGetGroupSessions;
