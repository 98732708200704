import { useState, useEffect } from "react";
import { connect } from "react-redux";
import dateHandlder from "../../../../../helpers/dates-hanlder";
import { Group } from "../../../../../types";

const periods = {
  minute: { plural: "minutos", singular: "minuto" },
  hour: { plural: "horas", singular: "hora" },
  day: { plural: "días", singular: "día" },
  week: { plural: "semanas", singular: "semana" },
  month: { plural: "meses", singular: "mes" },
  year: { plural: "años", singular: "año" },
};

interface ComponentProps {
  group: Group
}

const Component = ({ group }: ComponentProps) => {
  const [suscriptionEnded, setSuscriptionEnded] = useState<boolean>();

  const getFechaInscripcion = (date) => {
    const { day, month, year, hours, minutes } = dateHandlder.dateSpliter(date);
    return <span>Cierre de inscripciones: <strong>{day.number}/{month.number}/{year} a las {hours}:{minutes} hs.</strong></span>
  }

  const getPeriod = (duration, unit) => {
    if (unit) {
      return `${duration} ${duration > 1 ? periods[unit].plural : periods[unit].singular
        }`;
    }
    return "";
  };

  useEffect(() => {
    if (!!group?.registration_closing_date) {
      setSuscriptionEnded(
        dateHandlder.isExpired(group?.registration_closing_date)
      );
    }
  }, [group]);

  return group?.group_type === "membership" ? (
    <div style={{ fontSize: 14, textAlign: 'center'  }}>Sin tiempo mínimo de permanencia, cancelas cuando quieras!</div>
  ) : group?.recorded ? (
    <div style={{ fontSize: 14, textAlign: 'center'  }}>Tendrás acceso a todos los contenidos del Curso de forma inmediata!</div>
  ) : (
    <>
      <div style={{ fontSize: 14, textAlign: 'center' }}><i>{suscriptionEnded ? 'Inscripción finalizada.' : getFechaInscripcion(group?.registration_closing_date)}  </i></div>
      <div style={{ fontSize: 14, textAlign: 'center' }}><i>Duración del taller: <strong>{getPeriod(group?.duration, group?.duration_unit)}.</strong></i></div>
    </>
  )
}

const state = ({ groupStore }) => {
  const { data: group } = groupStore.group;

  return {
    group,
  };
};

export default connect(state)(Component);
