import styled from 'styled-components'

export const Card = styled('div')`
  background-color: #F7F8FA;
  border-radius: 8px;
  padding: 20px;
  width: 100%;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    max-width: 410px;
  }
`

export const Row = styled('div')`
  display: flex;
  aling-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`

export const Title = styled('div')`
display: flex;
aling-items: center;
justify-content: flex-start;
font-size: 14px;
font-weight: 500;

p {
    margin: 3px 0px;
}
`
export const Text = styled('div')`
font-size: 14px;
`