import * as Yup from "yup";

export const schema = Yup.object().shape({
  show: Yup.number().min(1).nullable(),
  duration: Yup.number().min(1).nullable(),
  duration_unit: Yup.string().nullable(),
  from: Yup.string().nullable(),
});

interface FormValues {
  show: string;
  duration: number;
  duration_unit: string;
  from: string;
}

export const initialValues: FormValues = {
  show: "show_always",
  duration: 1,
  duration_unit: "hours",
  from: "previous_module_finished",
};
