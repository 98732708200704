import { Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { schema, initialValues } from '../../../../../../constants/forms/plan/new'
import Sidebar from "../../../../../../components/Sidebar";
import {
  Preview,
  Option,
} from "../../styles";
import Form from "../components/NewGatewayForm"
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/FormikForm/Submit";
import { Center } from "../../../../../../components/StyledComponents";
import { useRef } from 'react';
import { Gateway, NewGatewayDto } from "../../../../../../types/gateway";
import { linkGateway } from "../../../../../../store/actions/expert";
import { getUserInformation } from "../../../../../../store/actions/user";

interface ComponentProps {
  isVisible: boolean
  onClose
  expertInformation,
  linkGatewayStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

const Component = ({
  isVisible,
  onClose,
  expertInformation,
  linkGatewayStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [actions, setActions] = useState<FormikHelpers<any>>();

  const onSubmit = ({ values, actions }) => {
    const { client_id, client_secret, access_token, public_key, name} = values;
    
    const payload: NewGatewayDto = {
        name,
        credentials: {
          client_id,
          client_secret,
          access_token,
          public_key
        }
    }

    dispatch(linkGateway(payload))
    setActions(actions)
  }

  useEffect(() => {
    if (linkGatewayStates.success && actions) {
      actions.resetForm();
      window.location.href="/profile/gateways"
    }
  }, [linkGatewayStates, actions])

  return (
    <>
      <Sidebar
        position="right"
        visible={isVisible}
        closeType="back"
        onClose={() => {
          //the sidebar is outside of formik context so reseting the form is not working. I implement this solution to move foward.
          if (cancelButtonRef.current) {
            cancelButtonRef.current.click();
          }
        }}
        width="520px"
        title="Nueva Pasarela de Pago"
      >
        <Preview>
          <Formik
            initialValues={{
            }}
            onSubmit={(values, actions) => {
              if (onSubmit) onSubmit({ values, actions });
            }}
            validateOnChange={formSubmmited}
            validateOnBlur={false}
            // validationSchema={schema}
            enableReinitialize
          >
            {(props) => {
              return (
                <>
                  <div>

                    <Option>

                      <form
                        className="theme-form"
                        onSubmit={(event) => {
                          setFormSubmmited(true);
                          props.handleSubmit(event);
                        }}
                        id="new-gateway-form"
                      >
                        <Form {...props} isLoading={linkGatewayStates.loading} />
                      </form>

                    </Option>
                  </div>

                  <div style={{ height: 40 }} />

                  <Center horizontal="center" vertical="center" gap={10}>
                    <Button
                      forwardref={cancelButtonRef}
                      options={{
                        type: "outline",
                        skin: "purple",
                        size: "lg",
                        block: true
                      }}
                      onClick={() => {
                        props?.resetForm()
                        !!onClose && onClose()
                      }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={linkGatewayStates.loading}
                      disabled={linkGatewayStates.loading}
                      options={{
                        type: "filled",
                        size: "lg",
                        skin: "purple",
                        block: true
                      }}
                      form="new-gateway-form"
                    >
                      Configurar Pasarela
                    </Submit>
                  </Center>
                </>
              );
            }}
          </Formik>
        </Preview>
      </Sidebar>
    </>
  )
}

const state = ({ groupStore, expertStore }) => {
  const { data: group } = groupStore.group;
  const { data: expertInformation, states: linkGatewayStates } = expertStore.linkGateway;

  return {
    group,
    expertInformation,
    linkGatewayStates,
  };
};

export default connect(state)(Component);
