import styled from "styled-components";

export const Title = styled("h3")`
  margin: 0px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #2d3436;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 40px;
`;

export const Sidebar = styled("div")<{ styles? }>`
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  background-color: white;
  overflow-y: scroll;
  padding: 93px 28px 40px;

  @media (min-width: 768px) {
    padding: 93px 48px 40px;
  }
`;

export const CloseButton = styled.button`
  border: 0px;
  margin: 0px;
  padding: 0px;
  background: none;
  box-shadow: none;
  outline: none;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 100;
  cursor: pointer;
`;

export const BackArrow = styled.button`
  border: 0px;
  margin: 0px 10px 0px 0px;
  padding: 0px;
  background: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
`;

export const Backdrop = styled("div")`
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: calc(100vh * 2);
  background-color: #000;
`;
