import {
  initialState,
  Actions,
  State,
  CREATE_NEW_EXPERT,
  CREATE_NEW_EXPERT_SUCCESS,
  CREATE_NEW_EXPERT_ERROR,
} from "../../types/user/create-new-expert";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case CREATE_NEW_EXPERT:
      return {
        ...state,
        states: {
          success: false,
          loading: true,
          error: false,
        },
      };
    case CREATE_NEW_EXPERT_SUCCESS:
      return {
        ...state,
        states: {
          success: true,
          loading: false,
          error: false,
        },
      };
    case CREATE_NEW_EXPERT_ERROR:
      return {
        ...state,
        states: {
          success: false,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducers;
