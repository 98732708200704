import { signOut } from "../../store/actions/auth";

import academy from "../../assets/dashboard-menu/academy.svg";
import members from "../../assets/dashboard-menu/members-list.svg";
import logOut from "../../assets/dashboard-menu/log-out.svg";
import groups from "../../assets/dashboard-menu/groups.svg";
import home from "../../assets/dashboard-menu/home.svg";
import profile from "../../assets/dashboard-menu/profile.svg";
import settings from "../../assets/dashboard-menu/settings.svg";
import community from "../../assets/dashboard-menu/community.svg";
import membership from "../../assets/dashboard-menu/membership.svg";
import experience from "../../assets/dashboard-menu/one-time-payment.svg";

interface MenuItem {
  active: boolean;
  icon: string;
  native?: boolean;
  url?: string;
  action?: () => void;
  label: string;
}

const mainMenu: MenuItem[] = [
  {
    active: true,
    icon: home,
    native: true,
    url: "/profile/suscriber",
    label: "Home",
  },
];

const secondaryMenu: MenuItem[] = [
  {
    active: true,
    icon: settings,
    native: true,
    url: "/profile/my-account",
    label: "Mi cuenta",
  },
  {
    active: true,
    icon: logOut,
    action: signOut,
    label: "Cerrar sesión",
  },
];

export default { mainMenu, secondaryMenu };
