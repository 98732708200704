import styled from "styled-components";

export const List = styled("div")`
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ListItem = styled("div")``;
