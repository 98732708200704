import Script from "../types/script";

const scripts: Script[] = [
  {
    attributes: [
      { name: "async", value: "true" },
      { name: "type", value: "text/javascript" },
    ],
    name: "chargebee-script-head",
    parent: "head",
    location: "end",
    type: "script",
    code: "",
    src: "https://js.chargebee.com/v2/chargebee.js",
    lazyLoad: true,
  },
  {
    attributes: [
      { name: "async", value: "true" },
      { name: "type", value: "text/javascript" },
    ],
    name: "calendly-script",
    parent: "head",
    location: "start",
    type: "script",
    code: "",
    src: "https://assets.calendly.com/assets/external/widget.js",
  },
  {
    attributes: [
      { name: "async", value: "true" },
      { name: "rel", value: "stylesheet" },
    ],
    name: "calendly-styles",
    parent: "head",
    location: "start",
    type: "link",
    code: "",
    href: "https://assets.calendly.com/assets/external/widget.css",
  },
];

export default scripts;
