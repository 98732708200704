import { GetWelcomeContentPostOutDto, GetWelcomeContentPostInDto } from "../../../types/welcome-section";
export const GET_WELCOME_CONTENT_POST = "GET_WELCOME_CONTENT_POST";
export const GET_WELCOME_CONTENT_POST_SUCCESS = "GET_WELCOME_CONTENT_POST_SUCCESS";
export const GET_WELCOME_CONTENT_POST_ERROR = "GET_WELCOME_CONTENT_POST_ERROR";
export const RESET_GET_WELCOME_CONTENT_POST = "RESET_GET_WELCOME_CONTENT_POST";
export const UPDATE_CURRENT_WELCOME_CONTENT = "UPDATE_CURRENT_WELCOME_CONTENT";

export interface GetWelcomeContentPost {
  type: typeof GET_WELCOME_CONTENT_POST;
  payload: GetWelcomeContentPostOutDto;
}

export interface GetWelcomeContentPostSuccess {
  type: typeof GET_WELCOME_CONTENT_POST_SUCCESS;
  payload: GetWelcomeContentPostInDto;
}

export interface GetWelcomeContentPostError {
  type: typeof GET_WELCOME_CONTENT_POST_ERROR;
  payload: string | boolean;
}

export interface UpdateCurrentWelcomeContentPost {
  type: typeof UPDATE_CURRENT_WELCOME_CONTENT;
  payload: GetWelcomeContentPostInDto;
}

export interface ResetGetWelcomeContentPost {
  type: typeof RESET_GET_WELCOME_CONTENT_POST;
  payload: null;
}

export interface State {
  data: GetWelcomeContentPostInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetWelcomeContentPost
  | GetWelcomeContentPostSuccess
  | GetWelcomeContentPostError
  | UpdateCurrentWelcomeContentPost
  | ResetGetWelcomeContentPost;
