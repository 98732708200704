import {
  Title,
  HorizontalScrollWrapper,
  HorizontalScroll,
} from "./styles";
import CardAticle from "../../../../Dashboard/Posts/components/CardArticle";
import SessionCard from "../../../../Dashboard/Sessions/components/SessionCard";
import { Separator } from "../../../../../components/StyledComponents";
import { Post } from "../../../../../types/post";
import { connect } from "react-redux";
import { Group, SessionInDto } from "../../../../../types";

interface ComponentProps {
  group: Group;
  lastActivities: {
    type: string;
    data: Post | SessionInDto;
  }[];
  lastActivitiesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  group,
  lastActivities,
  lastActivitiesStates,
}: ComponentProps) => {
  if (
    !lastActivities ||
    lastActivities.filter(la => la.type === "post").length <= 0 ||
    !!lastActivitiesStates.loading
  ) {
    return null;
  }

  return (
    <>
      <Title>Últimos artículos</Title>
      <Separator height={{ desktop: "16px", mobile: "16px" }} />


      <HorizontalScrollWrapper>
        <HorizontalScroll >
          {lastActivities.map((lastActivity, index) => {
            if (lastActivity.type === "post") {
              return (
                <CardAticle
                  post={lastActivity?.data as Post}
                  key={`last-activity-${index}`}
                  groupInformation={group}
                  showPinAction={false}
                />
              );
            }
          })}
        </HorizontalScroll >
      </HorizontalScrollWrapper>
    </>
  );
};

const state = ({ groupStore }) => {
  const { data: lastActivities, states: lastActivitiesStates } =
    groupStore.lastActivity;

  const { data: group } = groupStore.group;
  return {
    group,
    lastActivities,
    lastActivitiesStates,
  };
};

export default connect(state)(Component);
