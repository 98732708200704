import { connect, useDispatch } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import { resetUpdateGroup, updateGroup } from "../../../../../store/actions/group";
import { showModal, hideModal } from "../../../../../store/actions/modal";
import Link from "../../../../../components/Link";
import Snackbar from "../../../../../components/Snackbar";
import Submit from "../../../../../components/FormikForm/Submit";
import Grid from "../../../../../components/Grid";
import Name from "../../../components/Name";
import Description from "../../../components/Description";
import Benefits from "../../../components/Benefits";
import Duration from "../../../components/Duration";
import RegistrationClosingDate from "../../../components/RegistrationClosingDate";
import CreatedBy from "../../../components/CreatedBy";
import Price from "../../../components/Price";
import { Button } from "./styles";
import CoverPic from "../../../components/CoverPic";
import {
  inputDateTimeLocal,
  setToZeroTimeZone,
} from "../../../../../helpers/dates-hanlder";
import constants from "../../../../../constants";
import Loader from "../../../../../components/LogoLoader";
import { useLayout } from "../../../../../providers/LayoutProvider";
import { useHistory } from "react-router-dom";

interface ComponentProps {
  match;
  pageContentRef?;
  currentSlide?: number;
  userInformation;
  groupInformation;
  groupUpdateStates;
}

interface FormFieldsProps {
  brief: string;
  // cover_pic?: string;
  group_name: string;
  creator_name: string;
  group_type: string;
  registration_closing_date: string;
  "duration[duration_value]": string;
  "duration[duration_unit]": { value: string; label: string };
  group_perks: string[];
}

const Component = ({
  match,
  pageContentRef,
  currentSlide,
  userInformation,
  groupInformation,
  groupUpdateStates,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [defaultValues, setDefaultValues] = useState<FormFieldsProps>();
  const { isMobile } = useWindowSize();
  const [activeSlide, setActiveSlide] = useState<number>();
  const labelRefName = useRef<HTMLLabelElement>();
  const labelRefDescription = useRef<HTMLLabelElement>();
  const labelRefBenefits = useRef<HTMLLabelElement>();
  const labelRefDuration = useRef<HTMLLabelElement>();
  const labelRefClosingDate = useRef<HTMLLabelElement>();
  const labelRefCreatedBy = useRef<HTMLLabelElement>();
  const labelRefFiles = useRef<HTMLLabelElement>();

  const labelsRefs = [
    labelRefName,
    labelRefCreatedBy,
    labelRefFiles,
    labelRefDescription,
    labelRefBenefits,
    labelRefDuration,
    labelRefClosingDate,
  ];

  const {
    control,
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    setError,
    unregister,
    getValues,
    formState,
    setFocus,
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const setFieldFocus = (fieldNumber, name) => {
    const label = labelsRefs[fieldNumber]?.current;
    if (!!label && !!pageContentRef.current) {
      const target = isMobile ? window : pageContentRef?.current;
      const offset = 200;
      const top = label.offsetTop;
      target.scrollTo({
        top: top + offset,
        behavior: "smooth",
      });
    }

    if (name && !isMobile) {
      //setFocus(name);
    }
  };

  const onFormSubmit = (data) => {
    const { duration_unit, duration_value } = data.duration;

    const payload = {
      ...data,
      registration_closing_date: setToZeroTimeZone(
        data.registration_closing_date
      ),
      id: groupInformation.id,
      group_perks: data.group_perks.filter(
        (group_perk) => !!group_perk && group_perk !== ""
      ),
      duration_unit: duration_unit.value,
      duration: duration_value,
    };

    if (!descriptionError) {
      dispatch(updateGroup(payload));
    }
  };

  const onFormError = (data) => console.log("Form Error:", data);

  const onFocus = (fieldNumber, name) => {
    setActiveSlide(fieldNumber - 1);
    setFieldFocus(fieldNumber - 1, name);
  };

  const onBlur = () => { };

  useEffect(() => {
    if (currentSlide !== undefined) {
      setFieldFocus(currentSlide, null);
    }
  }, [currentSlide]);

  useEffect(() => {
    dispatch(showModal("tips"));
    setActiveSlide(0);
  }, []);

  useEffect(() => {
    reset(defaultValues);
    setTimeout(() => {
      setShowForm(true);
    }, 100);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!groupInformation) {
      const duration_unit = constants.durations.filter(
        (item) => item.value === groupInformation.duration_unit
      )[0];

      setDefaultValues({
        brief: groupInformation.brief,
        // cover_pic: groupInformation.cover_pic?.url,
        group_name: groupInformation.group_name,
        registration_closing_date: inputDateTimeLocal(
          groupInformation.registration_closing_date
        ),
        "duration[duration_value]": groupInformation.duration,
        "duration[duration_unit]": duration_unit,
        creator_name: groupInformation.creator_name,
        group_type: "free_experience",
        group_perks: groupInformation.group_perks,
      });
    }
  }, [groupInformation]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: groupInformation?.group_name,
        description: "Editar evento gratuito",
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: true,
        actions: [
          <Link
            style={{ marginRight: "8px" }}
            options={{
              type: "outline",
              size: "lg",
              skin: "purple",
              native: true,
            }}
            href={`/dashboard/community/events/${groupInformation.id}`}
          >
            Cancelar
          </Link>,

          <Submit
            isSubmmiting={groupUpdateStates.loading}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            form="edit-free-experience-form"
          >
            Guardar y publicar
          </Submit>,
        ],
      },
    });
  }, [groupInformation, groupUpdateStates]);

  useEffect(() => {
    if (groupUpdateStates.success) {
      setTimeout(() => {
        dispatch(resetUpdateGroup());
        setTimeout(() => {
          history.push(`/dashboard/community/events/${groupInformation.id}`);
        }, 500);
      }, 4500);
    }
  }, [groupUpdateStates]);

  if (!showForm) {
    return <Loader />;
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onFormSubmit, onFormError)}
        id="edit-free-experience-form"
      >
        <Name
          fildName="Nombre del evento"
          holderName="Elige un nombre para tu evento"
          fieldText="⚠️ Si cambias el nombre de tu evento gratuíto cambiará el link de tu página de ventas"
          labelForwardRef={labelRefName}
          fieldNumber={1}
          onFocus={onFocus}
          onBlur={onBlur}
          register={register}
          errors={{ ...formState.errors }}
        />
        <CreatedBy
          labelForwardRef={labelRefCreatedBy}
          fieldNumber={2}
          onFocus={onFocus}
          onBlur={onBlur}
          register={register}
          errors={{ ...formState.errors }}
        />
        {/* <CoverPic
          name="cover_pic"
          aceptedExtensions={[
            "image/jpeg",
            "image/png",
            "audio/mpeg",
            "audio/ogg",
            "audio/wav",
            "video/3gpp",
            "video/x-msvideo",
            "video/x-ms-wmv",
            "video/mp4",
          ]}
          labelForwardRef={labelRefFiles}
          fieldNumber={3}
          onDelete={setValue}
          onFocus={onFocus}
          onBlur={onBlur}
          control={control}
          errors={{ ...formState.errors }}
          fieldTitle="Personalizá tu evento"
          fieldText="Podés agregar un video a la página de tu evento. El vidéo debera ser MP4 y pesar hasta 500MB."
        /> */}
        <Description
          getValues={getValues}
          control={control}
          label="Describe tu evento"
          labelForwardRef={labelRefDescription}
          fieldNumber={3}
          onFocus={onFocus}
          onBlur={onBlur}
          register={register}
          setError={setError}
          setDescriptionError={setDescriptionError}
          errors={{ ...formState.errors }}
        />
        <Benefits
          fildName="¿Qué beneficios incluye tu evento?"
          labelForwardRef={labelRefBenefits}
          fieldNumber={4}
          onFocus={onFocus}
          onBlur={onBlur}
          register={register}
          unregister={unregister}
          errors={{ ...formState.errors }}
          benefits={groupInformation?.group_perks}
        />
        <Duration
          getValues={getValues}
          labelForwardRef={labelRefDuration}
          fieldNumber={5}
          onFocus={onFocus}
          onBlur={onBlur}
          register={register}
          control={control}
          errors={{ ...formState.errors }}
        />
        <RegistrationClosingDate
          labelForwardRef={labelRefClosingDate}
          fieldNumber={6}
          onFocus={onFocus}
          onBlur={onBlur}
          register={register}
          errors={{ ...formState.errors }}
        />
        <input
          type="hidden"
          value="free_experience"
          {...register("group_type", { required: true })}
        />
      </form>

      <Grid.Row>
        <Grid.Col>
          <Link
            style={{ marginRight: "8px" }}
            options={{
              type: "outline",
              size: "lg",
              skin: "purple",
              native: true,
            }}
            href={`/dashboard/community/events/${groupInformation.id}`}
          >
            Cancelar
          </Link>

          <Submit
            isSubmmiting={groupUpdateStates.loading}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            form="edit-free-experience-form"
          >
            Guardar y publicar
          </Submit>
        </Grid.Col>
      </Grid.Row>

      <Snackbar
        visible={groupUpdateStates.success || groupUpdateStates.error}
        options={{
          time: 3500,
          type: groupUpdateStates.success ? "success" : "error",
        }}
      >
        {/* {"auth/too-many-requests"} */}
        {groupUpdateStates.success
          ? "Evento gratuíto editado."
          : "Error al editar el evento gratuíto."}
      </Snackbar>
    </>
  );
};
const state = ({ carouselStore, groupStore, userStore }) => {
  const { data: userInformation } = userStore.information;
  const { currentSlide } = carouselStore;
  const { data: groupInformation, states: groupStates } = groupStore.group;
  const { states: groupUpdateStates } = groupStore.updateGroup;
  return {
    currentSlide,
    groupInformation,
    groupStates,
    userInformation,
    groupUpdateStates,
  };
};
export default connect(state)(Component);
