
export const CHANGE_TRACKING = "CHANGE_TRACKING";
export const CHANGE_TRACKING_SUCCESS = "CHANGE_TRACKING_SUCCESS";
export const CHANGE_TRACKING_ERROR = "CHANGE_USER_EMAIL_ERROR";
export const RESET_CHANGE_TRACKING = "RESET_CHANGE_TRACKING";

export interface ChangeTracking {
  type: typeof CHANGE_TRACKING;
  payload: string;
}

export interface ChangeTrackingSuccess {
  type: typeof CHANGE_TRACKING_SUCCESS;
  payload: string;
}

export interface ChangeTrackingError {
  type: typeof CHANGE_TRACKING_ERROR;
  payload: boolean | string;
}

export interface ResetChangeTracking {
  type: typeof RESET_CHANGE_TRACKING;
  payload: null
}

export interface State {
  states: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
}

export const initialState: State = {
  states: {
    loading: false,
    error: false,
    success: false,
  },
};

export type Actions =
  | ChangeTracking
  | ChangeTrackingSuccess
  | ChangeTrackingError
  | ResetChangeTracking;
