import styled from "styled-components";

export const Title = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0px 0px 24px 0px;

  @media (min-width: 1024px) {
    grid-template-columns: auto 200px 200px 200px;
    margin-bottom: 20px;
  }
`;

export const Filters = styled("div")`
  display: grid;
  //grid-template-rows: 100px 250px 250px 50px;
  grid-gap: 10px;
  row-gap: 20px;
  margin-bottom: 50px;

  @media (min-width: 1024px) {
    grid-template-columns: 300px 300px 200px;
    margin-bottom: 20px;
  }
`;

export const FilterLabel = styled("span")`
  text-align: left;
  align-items: center;
  font-weight: 600;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-left: 2px;
  display: flex;
`;
