interface Option {
  label: string;
  value: string | number | "all";
}

const groupStatus: Option[] = [
  { value: "all", label: "Mostrar todo" },
  { value: "active", label: "Activos" },
  { value: "inactive", label: "Inactivos" },
  { value: "draft", label: "No Visibles" },
];

export default groupStatus;
