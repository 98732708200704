import styled from "styled-components";

export const List = styled("ul") <{ length: number }>`
  margin: 0px;
  padding: 0px;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    columns: ${({ length }) => length <= 1 ? '1' : '2'};
  }
`;

export const ListItem = styled("li")`
  margin-bottom: 22px;
  list-style: none;
  display: flex;
  font-size: 16px;
  line-height: 32px;
  max-width: 590px;

  &:last-child {
    margin-bottom: 0px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 0px 37px;
    margin-bottom: 40px;
  }
`;

export const Bullet = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 16px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.bullet.color};
  display: flex;
  align-items: center;
  justify-content: center;
`;
