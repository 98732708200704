import styled from "styled-components";
import Dropdown from "../Dropdown";
import Image from "../Image";

export const Section = styled.div`
  z-index: 100;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(38, 50, 56, 0.08);
`;

export const Header = styled.header`
  position: relative;
  z-index: 100;
  background-color: white;
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    height: 56px;
  }
`;
export const Navbar = styled.div`
  width: 100%;
`;

export const VerticalSeparator = styled("span")<{ margin?; visible? }>`
  width: 1px;
  height: 34px;
  margin: 0px ${(props) => (props.margin ? props.margin / 2 : `0`)}px;
  background-color: ${(props) => (props.visible ? `#ADADAD` : `transparent`)};
`;

export const HorizontalSeparator = styled("span")<{
  margin?;
  visible?;
  isSeparator?;
}>`
  height: 1px;
  width: 100%;
  background-color: ${(props) => (props.visible ? `#ADADAD` : `transparent`)};
`;

export const Img = styled(Image)`
  &.logo-desktop {
    display: none;
    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      display: block;
    }
  }

  &.logo-mobile {
    display: block;
    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      display: none;
    }
  }
`;
export const CustomDropdown = styled(Dropdown)``;
