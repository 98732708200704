import {
  initialState,
  Actions,
  State,
  NEW_GROUP,
  NEW_GROUP_SUCCESS,
  NEW_GROUP_ERROR,
  RESET_NEW_GROUP,
} from "../../types/group/new-group";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case NEW_GROUP:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case NEW_GROUP_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case NEW_GROUP_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };

      case RESET_NEW_GROUP:
        return {
          ...state,
          ...initialState,     
        };
    default:
      return state;
  }
};

export default reducers;
