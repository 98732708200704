import styled from "styled-components";

export const Card = styled("div")`
  font-size: 18px;
  font-weight: 500;
  color: #c0382c;
  background-color: #f6e6e5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: box-shadow 0.3s ease-in-out 0s;
  border-radius: 12px;
  height: 205px;
  width: 315px;

  @media(max-width: 767px){
    width: 290px;
    height: 230px;
  }
`;
