const tips = {
  membership: [
    {
      title: "Nombre",
      text: "Indica un nombre breve que represente a tu servicio y a tu comunidad. Ej “Espacio Mindfulness para sanar”, “Tribu: conectando con el ser ”, “ Comunidad mujeres conscientes” etc.",
    },
    {
      title: "Creado por",
      text: "Quien lo creo",
    },
    {
      title: "Personalizá tu grupo",
      text: "Agrega una imagen de fondo a tus grupos",
    },
    {
      title: "Descripción",
      text: "¡Ánimo! “La verdadera motivación procede de trabajar en cosas que nos importan” Cuéntale a tu audiencia por qué deberían elegir tu membresía. Por ejemplo: -En esta comunidad aprenderemos... -Voy a acompañarte paso a paso para lograr... - Este espacio es para ti si... -Suscríbete y comencemos un nuevo camino...",
    },
    {
      title: "Beneficios",
      text: "En cada ítem describe lo que recibirán mensualmente. Puedes quitar o agregar los que necesites. Recuerda, ¡menos es más! Ofrece beneficios que puedas sostener en el tiempo y que tu comunidad valore, por ejemplo encuentros en vivo semanales o cada 15 días, clases grabadas, audios y material descargable en pdf.",
    },
    {
      title: "Precio",
      text: "¡Último paso! Para decidir el precio mensual puedes tomar como referencia: -El valor económico de tus propuestas actuales -Tener en cuenta un precio alineado a los beneficios que tú otorgarás -Cuánto podría pagar tu audiencia en su moneda local -Piensa en un valor que tu audiencia pueda pagar todos los meses",
    },
    {
      title: "Testimonio",
      text: "Comparte testimonios que te hayan dejado para generar confianza en nuevos suscriptores",
    },
    {
      title: "¿Esto es para mi?",
      text: "Aprovecha este espacio para quienes duden de tu propuesta. Ej: Esto es para tí si alguna vez te pasó…",
    },
  ],
  experience: [
    {
      title: "Nombre",
      text: "Utiliza este espacio para informar el tipo de experiencia. Ej: “Masterclass de manejo de la ansiedad” “Taller reprogramación emocional” “Workshop el portal del León” Si es tu primera vez, te sugerimos comenzar con experiencias de corta duración: Masterclass (1 día),Taller (2 días), Challenge (7 días)",
    },
    {
      title: "Creado por",
      text: "Quien lo creo",
    },
    {
      title: "Personalizá tu grupo",
      text: "Agrega una imagen de fondo a tus grupos",
    },
    {
      title: "Descripción",
      text: `¡Invita a tu audiencia a vivir una experiencia memorable! Describe de qué se trata tu temática, ¿Qué podrán aprender, descubrir o lograr con tu ayuda?

      - En este taller tú podrás…  
      - Te transformarás a través de...  
      - Te compartiré herramientas para...  
      - Aprenderás a…`,
    },
    {
      title: "Beneficios",
      text: "Ofrece a tu audiencia información más detallada. Te sugerimos colocar el día y la hora de tus encuentros en vivo. Además, puedes incluir actividades a realizar y beneficios que se lleve tu audiencia como descuentos exclusivos y material descargable. Puedes quitar y agregar tantos ítems como quieras. ¡Adelante!",
    },
    {
      title: "Duración",
      text: "Indica cuánto tiempo durará tu Experiencia para que puedan reservar ese tiempo junto a tí. Para elegir cantidad de días o meses ten en cuenta si se trata de un Challenge, una Formación, un Taller, un Curso Virtual etc.",
    },
    {
      title: "Fin de inscripción",
      text: "Indica hasta cuándo tienen tiempo de inscribirse. Con este dato, aparecerá un contador y tu audiencia podrá saber el tiempo que resta para anotarse.¡Eso lucirá genial! Cuando el contador llegue a cero comenzará la Experiencia, mientras tanto, aprovecha este tiempo para promocionarlo.",
    },
    {
      title: "Precio",
      text: "¡Último paso! Indica el precio de tu Experiencia. Para calcular este valor puedes tener en cuenta el valor económico de tus propuestas actuales, y cuánto podría pagar tu audiencia en su moneda local. Algunos referentes utilizan costos bajos para captar nueva audiencia y otros tienen un costo más elevado por ser únicas o exclusivas.",
    },
    {
      title: "Testimonio",
      text: "Comparte testimonios que te hayan dejado para generar confianza en nuevos suscriptores",
    },
    {
      title: "¿Esto es para mi?",
      text: "Aprovecha este espacio para quienes duden de tu propuesta. Ej: Esto es para tí si alguna vez te pasó…",
    },
  ],
};
export default tips;
