import styled from "styled-components";

export const Card = styled('div')`
  background: white;
  padding: 44px 32px;
  border-radius: 12px;
  background: rgb(222,218,255);
  background: linear-gradient(155deg, rgba(222,218,255,1) 0%, rgba(255,255,255,1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const skins = {
  purple: ['#6C5CE7', '#6C5CE7'],
  red: ['#EC221F', '#900B09'],
}
export const Title = styled('h2') <{ skin: string }>`
  line-height: 28px;
  margin: 0px;
  text-align: center;
  font-size: 22px;
  font-weight: 600;

  background: linear-gradient(155deg, ${({ skin }) => skins[skin][0]} 0%, ${({ skin }) => skins[skin][1]} 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`

export const Text = styled('p')`  
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
  color: #2D3436;
  text-align: center;
`