import styled from 'styled-components'

export const Row = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`

export const Platform = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Card = styled('div')`
  border: 1px solid ${({ theme }) => theme.colors.Primary};
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const GatewayComp = styled('div')`
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 12px 12px 25px 25px;
  gap: 12px;
`
export const Status = styled('div') <{ status }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-weight: 500;
  font-size: 12px;
  color: ${({ status }) => {
    switch (status) {
      case 'active':
        return '#12B47A';
      case 'inactive':
        return '#B41212';
      default:
        return '#B46912'
    }
  }};

  &:before {
    content: "";
    display: block;
    height: 7px;
    width: 7px;
    border-radius: 100%;
    background-color: ${({ status }) => {
    switch (status) {
      case 'active':
        return '#12B47A';
      case 'inactive':
        return '#B41212';
      default:
        return '#B46912'
    }
  }}
  }
`

export const Description = styled('div')`
width: 100%;
 `;

export const VerticalSeparator = styled("span")`
  width: 1px;
  height: 28px;
  margin: 0px 10px;
  background-color: #170F49;
  opacity: 0.5
`;
