import { Title } from "./styles";

interface ComponentProps {
  marginBottom?: number;
  children: any;
}

const Component = ({ children, marginBottom, ...rest }: ComponentProps) => {
  return (
    <Title marginBottom={marginBottom} {...rest}>
      {children}
    </Title>
  );
};

export default Component;
