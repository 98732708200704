import { ThemeProvider } from 'styled-components'
import { connect } from 'react-redux'
import klouserSkin from '../../skins/main'
import GlobalStyles from '../../GlobalStyles'

interface ComponentProps {
  children,
}

const Component = ({ children }: ComponentProps) => {

  return (
    <ThemeProvider theme={klouserSkin}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}

const state = ({ }) => {
  return {};
};

export default connect(state)(Component);
