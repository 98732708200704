import { Group } from "../../../../types";

import {
  GetGroupById,
  GetGroupByIdError,
  GetGroupByIdSuccess,
  ResetGetGroup
} from "./get-group-by-id";

import {
  GetGroupBySlug,
  GetGroupBySlugError,
  GetGroupBySlugSuccess,
} from "./get-group-by-slug";

import {
  UpdateCurrentGroup,
  UpdateCurrentGroupError,
  UpdateCurrentGroupSuccess,
} from "./update-current-group";

import {
  DeleteCoverPic,
  DeleteCoverPicSuccess,
  DeleteCoverPicError,
} from "./delete-cover-pic";

export * from "./get-group-by-id";
export * from "./delete-cover-pic";
export * from "./get-group-by-slug";
export * from "./update-current-group";

export interface State {
  data: Group | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetGroupById
  | GetGroupByIdSuccess
  | GetGroupByIdError
  | GetGroupBySlug
  | GetGroupBySlugSuccess
  | GetGroupBySlugError
  | UpdateCurrentGroup
  | UpdateCurrentGroupError
  | UpdateCurrentGroupSuccess
  | DeleteCoverPic
  | DeleteCoverPicSuccess
  | DeleteCoverPicError
  | ResetGetGroup;
