import { combineReducers } from "redux";
import post from "./get-post";
import listPosts from "./get-posts";
import newPost from "./new-post";
import editPost from "./edit-post";
import deletePost from "./delete-post";
import deleteFile from "./delete-file";
import sharePost from "./share-post";
import lastPosts from "./get-last-posts";
import setFeaturedPost from "./set-featured-post";
import featuredPosts from "./get-featured-posts";
import scheduledPosts from "./get-scheduled-posts";
import reorderFeaturedPosts from './reorder-featured-posts'

const reducer = combineReducers({
  post,
  listPosts,
  featuredPosts,
  scheduledPosts,
  lastPosts,
  newPost,
  editPost,
  deletePost,
  deleteFile,
  sharePost,
  setFeaturedPost,
  reorderFeaturedPosts
});

export default reducer;
