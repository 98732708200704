import styled from "styled-components";

export const Field = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    width: 335px;
    margin-top: 0px;
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Label = styled("div")<{ ref?; for? }>`
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  margin-bottom: 0px;
  min-width: 80px;
`;

export const StepNumber = styled.div`
  margin-right: 5px;
`;

export const Word = styled.div`
  font-size: 12px;
  line-height: 14px;
`;

export const Explanation = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 24px;
`;
