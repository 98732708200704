import styled from "styled-components";

export const TermsContent = styled("div")`
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0px 1px 25px rgb(0 0 0 / 15%);

  @media (min-width: 1024px) {
    padding: 48px 97px;
  }
`;
