import { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Icon from "../../../../Icon";
import fileInbox from "../../../../../assets/icons/file-inbox.svg";
import trash from "../../../../../assets/icons/delete.svg";
import constants from "../../../../../constants";
import { AttachedFile } from "../../../../../types/attached-file";
import {
  Dropzone,
  DropzoneLabel,
  Aside,
  Thumb,
  ThumbInner,
  Delete,
  Error,
  Card,
  FileName,
  Message,
} from "./styles";
import slugify from "slugify";

interface DropzoneFile extends AttachedFile {
  slug?: string;
  preview?: any;
  loading?: string;
}

interface DropzoneComponent {
  hideDroppedFiles?: boolean; //Se agrego para formularios en formik ya que se duplicaba la imagen cargada
  showLoadFileCta?: boolean;
  defaultFiles?: File[] | any;
  onChange?: (files) => void;
  onRemoveItem?: (event, file, newFiles) => void;
  labelButton?: string;
  maxFileSize?: number;
  maxFilesNumber?: number;
  aceptedExtensions?: typeof constants.fileUploader.aceptedMimes;
  disabled?: boolean;
}

const Basic = ({
  showLoadFileCta = true,
  defaultFiles = [],
  onRemoveItem,
  onChange,
  labelButton,
  maxFileSize = 6500000000,
  maxFilesNumber = 3,
  aceptedExtensions = constants.fileUploader.aceptedMimes,
  hideDroppedFiles,
  disabled,
}: DropzoneComponent) => {
  const [preloadedFiles, setPreloadedFiles] = useState<DropzoneFile[]>([]);
  const [invalidTypeError, setInvalidTypeError] = useState<boolean>(false);
  const [tooManyFilesError, setTooManyFilesError] = useState<boolean>(false);
  const [tooLargeFileError, setTooLargeFileError] = useState<boolean>(false);

  const isValid = (acceptedFiles) => {
    setTooManyFilesError(false);
    setInvalidTypeError(false);

    const mergedFiles = acceptedFiles.concat(preloadedFiles);
    const tooManyFiles = mergedFiles.length > maxFilesNumber;

    const tooLargeFile =
      mergedFiles.filter((file) => {
        return file.size > maxFileSize;
      }).length > 0;

    const invalidType =
      mergedFiles.filter((file) => {
        return !aceptedExtensions.includes(file.type || file.mime);
      }).length > 0;

    setTooManyFilesError(tooManyFiles);
    setInvalidTypeError(invalidType);
    setTooLargeFileError(tooLargeFile);

    return !(invalidType || tooManyFiles || tooLargeFile);
  };

  const onDrop = (acceptedFiles) => {
    if (isValid(acceptedFiles)) {
      const mergedFiles = acceptedFiles.concat(preloadedFiles);
      const newFiles = mergedFiles.map((file) => {
        if (file.url || file.signed_url) {
          return file;
        } else {
          return Object.assign(file, {
            preview:
              constants.fileUploader.icons[file.type || file.mime] ||
              URL.createObjectURL(file),
          });
        }
      });

      setPreloadedFiles([...newFiles]);

      if (!!onChange) {
        onChange(newFiles);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  useEffect(() => {
    if (defaultFiles?.length > 0) {
      setPreloadedFiles(defaultFiles);
    }
  }, [defaultFiles]);

  return (
    <section className="container">
      {showLoadFileCta && (
        <DropzoneLabel htmlFor="dropzone-input">{labelButton}</DropzoneLabel>
      )}
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} id="dropzone-input" />
        <Dropzone>
          <Icon icon={fileInbox} color="#DBDBDB" size="48px" />
          {invalidTypeError && <Error>{`El Formato del Archivo no es válido.`}</Error>}
          <Message>
            Arrastra tu archivo o haz
            <br /> click aquí{" "}
          </Message>
          {tooManyFilesError && (
            <Error>
              Máximo {maxFilesNumber}{" "}
              {`archivo${maxFilesNumber > 1 ? "s" : ""}`}.
            </Error>
          )}
          {tooLargeFileError && (
            <Error>
              Archivo muy pesado.
              <br />
              Máximo peso {(maxFileSize / 1048576) >= 1024 ? `${maxFileSize / 1073741824}GB` : `${maxFileSize / 1048576}MB`}
            </Error>
          )}
        </Dropzone>
      </div>

      <Aside>
        {/* {!hideDroppedFiles &&
          files.length > 0 &&
          files?.map((file, index) => (
            <Card key={slugify(`${file.name}-${index}`, { lower: true })}>
              <Thumb background={file.preview}>
                <ThumbInner>
                  <Delete
                    type="button"
                    onClick={(event) => {
                      const newFiles = [...files];
                      newFiles.splice(newFiles.indexOf(file), 1);
                      setFiles(newFiles);

                      if (onRemoveItem) {
                        onRemoveItem(event, file);
                      }
                    }}
                  >
                    <Icon icon={trash} color="black" size="16px" />
                  </Delete>
                </ThumbInner>
              </Thumb>
            </Card>
          ))} */}

        {preloadedFiles.length > 0 &&
          <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: 'column', gap: 15 }}>
            {preloadedFiles?.map((file, index) => {
              const fileName = file?.name || file?.original_name;
              const ext = fileName?.slice((fileName?.lastIndexOf(".") - 1 >>> 0) + 2);
              let lastIndex = fileName?.lastIndexOf('.');
              let name = lastIndex !== -1 ? fileName?.substring(0, lastIndex) : fileName;


              return (
                <div key={slugify(`${file?.name}-${index}`, { lower: true })} style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: 15 }}>
                  <Card >
                    <Thumb
                      background={
                        constants.fileUploader.videoMimes.includes(file?.mime) || constants.fileUploader.otherMimes.includes(file?.mime) || constants.fileUploader.audioMimes.includes(file?.mime)
                          ? constants.fileUploader.icons[file?.mime]
                          : file?.preview ||
                          file.url ||
                          file?.signed_url ||
                          constants.fileUploader.icons[file?.mime]
                      }
                    >
                      <ThumbInner>
                        <Delete
                          type="button"
                          onClick={(event) => {
                            const newFiles = preloadedFiles.filter(
                              (file, fileIndex) => fileIndex !== index
                            );

                            setPreloadedFiles(newFiles);

                            if (onRemoveItem) {
                              onRemoveItem(event, file, newFiles);
                            }
                          }}
                        >
                          <Icon icon={trash} color="black" size="16px" />
                        </Delete>
                      </ThumbInner>
                    </Thumb>
                  </Card>
                  <FileName><span>{name}</span>.{ext}</FileName>
                </div>)
            }
            )}
          </div>
        }
      </Aside>
    </section >
  );
};

export default Basic;
