import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  #footer-sticky {
    position: sticky;
    bottom: 0;
    z-index: 100;
  }

  #root {
    position: relative;
    padding-top: 0px;
  }

  #header-sticky {
    position: sticky;
    top: 0;
    z-index: 105;
  }

  #sidebars {
    position: relative;
    z-index: 150;
  }

  #modal {
    position: relative;
    z-index: 160;
  }

  #snackbar {
    position: relative;
    z-index: 170;
  }

  #swipe {
    position: relative;
    z-index: 180;
  }
`;

export const Page = styled("div")`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fafafa;
  min-height: 100vh;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    flex-direction: row;
  }
`;

export const PageContent = styled("div") <{ ref }>`
  padding: 0px;
  width: 100%;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    overflow-y: auto;
    padding: 42px 68px 80px;
    height: 100vh;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  min-height: 24px;
  margin: 0px;
  max-width: 600px;

  @media (min-width: 768px) {
    line-height: 40px;
    min-height: 40px;
    margin: 0;
  }

  @media (min-width: 1024px) {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    min-height: 44px;
    margin: 0px;

    br {
      display: none;
    }
  }
`;

export const Actions = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 8px;

  @media (max-width: ${(props) => props.theme.mediaBreakpoints.smMax}) {
    flex-direction: column;
    button,
    a {
      display: block;
      width: 100%;
    }
  }
`;
