import image from "./../assets/backgrounds/pages/girl-checking-phone-during-free-time.svg";
import background from "./../assets/backgrounds/pages/colored-circles-and-squares.svg";

const signUp = {
  title: `Estar para poder *disfrutar*`,
  subtitle: "Ser parte de Klouser es pensar en uno mismo",
  image: image,
  background: background,
};

export default signUp;
