import { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

import Sidebar from "../../../../../../../components/Sidebar";
import Button from "../../../../../../../components/Button";
import Snackbar from "../../../../../../../components/Snackbar";

import {
  Description
} from './styles';

import { deleteWelcomeContentPost, resetDeleteWelcomeContentPost } from '../../../../../../../store/actions/welcome-content';
import { Group } from '../../../../../../../types';
import { DeleteWelcomeContentPostOutDto } from '../../../../../../../types/welcome-section';

interface ComponentProps {
  deleteWelcomeContentPostState: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  onClose
  selectedContentToBeDeleted?: DeleteWelcomeContentPostOutDto
  isVisible: boolean
}

const Component = ({
  deleteWelcomeContentPostState,
  onClose,
  selectedContentToBeDeleted,
  isVisible
}: ComponentProps) => {
  const dispatch = useDispatch()

  const deleteSelectedCategory = () => {
    if(selectedContentToBeDeleted)
      dispatch(deleteWelcomeContentPost(selectedContentToBeDeleted));
  };

  useEffect(() => {
    if (deleteWelcomeContentPostState.success) {
      !!onClose && onClose();

      setTimeout(() => {
        dispatch(resetDeleteWelcomeContentPost());
      }, 3500);
    }
  }, [deleteWelcomeContentPostState]);

  return <>
    <Sidebar
      position="right"
      visible={isVisible}
      closeType="back"
      onClose={() => {
        !!onClose && onClose();
      }}
      width="463px"
      title="Eliminar contenido"
    >

      <div style={{ height: 30 }} />

      <Description>
        Estás por eliminar el contenido seleccionado. Ten en cuenta que si lo eliminas ya no podras recuperarlo.
      </Description>

      <div style={{ height: 30 }} />

      <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
        <Button
          disabled={deleteWelcomeContentPostState.loading}
          options={{
            type: "outline",
            size: "lg",
            skin: "purple",
          }}
          onClick={() => onClose()}
        >
          Cancelar
        </Button>

        <Button
          disabled={deleteWelcomeContentPostState.loading}
          loading={deleteWelcomeContentPostState.loading}
          onClick={() => deleteSelectedCategory()}
          options={{
            type: "filled",
            size: "lg",
            skin: "purple",
          }}
        >
          Eliminar
        </Button>
      </div>
    </Sidebar>

    <Snackbar
      visible={deleteWelcomeContentPostState.success || !!deleteWelcomeContentPostState.error}
      options={{
        time: 2000,
        type: deleteWelcomeContentPostState.success ? "success" : "error",
      }}
    >
      {deleteWelcomeContentPostState.success
        ? "Contenido eliminado!"
        : "Error al eliminar el contenido"}
    </Snackbar>
  </>
}

const state = ({ groupStore, welcomeContentStore }) => {
  const { data: allWelcomeContentPosts, states: allWelcomeContentPostsState } =
    welcomeContentStore?.allWelcomeContentPosts;
  const { data: welcomeContentPost, states: deleteWelcomeContentPostState } =
    welcomeContentStore?.deleteWelcomeContentPost;
  const { data: group, states: groupStates } =
    groupStore.group;

  return {
    group,
    groupStates,
    allWelcomeContentPosts,
    allWelcomeContentPostsState,
    welcomeContentPost,
    deleteWelcomeContentPostState
  };
};

export default connect(state)(Component);