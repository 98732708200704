import {
    initialState,
    Actions,
    State,
    GET_SUBSCRIPTION,
    GET_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTION_ERROR,
    RESET_GET_SUBSCRIPTION,
  } from "../../types/subscription-admin/get-subscription";
  
  const reducers = (state = initialState, action: Actions): State => {
    switch (action.type) {
      case GET_SUBSCRIPTION:
        return {
          ...state,
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        };
      case GET_SUBSCRIPTION_SUCCESS:
        return {
          ...state,
          data: action.payload, 
          states: {
            loading: false,
            error: false,
            success: true,
          },
        };
      case GET_SUBSCRIPTION_ERROR:
        return {
          ...state,
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        };
      case RESET_GET_SUBSCRIPTION:
        return initialState;
      default:
        return state;
    }
  };
  
  export default reducers;
  