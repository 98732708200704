import Icon from "../../../../../../../../components/Icon";
import { Filter } from "../../../../";
import { FilterTag, AppliedFilters, DeleteFilter } from "./styles";
import DeleteFilterIcon from "../../../../../../../../assets/icons/delete-circle-cross.svg";

interface Option {
  value: string | number;
  label: string;
}

interface ComponentProps {
  appliedFilters?: Filter[];
  setAppliedFilters?: React.Dispatch<
    React.SetStateAction<Filter[] | undefined>
  >;
  conditions: Option[];
  options: Option[];
}

const Component = ({
  appliedFilters,
  setAppliedFilters,
  options,
  conditions,
}: ComponentProps) => {
  if (!appliedFilters) {
    return <></>;
  }

  return (
    <AppliedFilters>
      {appliedFilters &&
        appliedFilters?.map((appliedFilter, index) => {
          const condition = conditions.filter(
            (condition) => condition.value === appliedFilter.condition
          );

          const value = options.filter(
            (option) => option.value === appliedFilter.value
          );

          return (
            <FilterTag>
              <span>
                {condition[0].label} - {value[0].label}
              </span>

              <DeleteFilter
                type="button"
                onClick={() => {
                  if (!!setAppliedFilters) {
                    const filters = [...appliedFilters];
                    filters.splice(index, 1);
                    setAppliedFilters(filters);
                  }
                }}
              >
                <Icon icon={DeleteFilterIcon} size="20px" color="black" />
              </DeleteFilter>
            </FilterTag>
          );
        })}
    </AppliedFilters>
  );
};

export default Component;
