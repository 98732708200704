import { combineReducers } from "redux";
import newNotification from "./new-one";
import updateNotification from "./edit";
import removeNotification from "./delete-one";
import allNotifications from "./get-all";
import singleNotification from "./get-one";

const reducer = combineReducers({
  newNotification,
  updateNotification,
  removeNotification,
  allNotifications,
  singleNotification,
});

export default reducer;
