import styled from "styled-components";

export const Title = styled.div`
  display: flex;
`;

export const Text = styled.div`
  display: flex;
  align-items: flex-start;
`;
