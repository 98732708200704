import styled from "styled-components";


export const VideoContainer = styled("div")<{isMobile?: boolean; isVertical?: boolean, isPreview?: boolean}>`
  width: ${(props) => (props.isPreview ? (props.isVertical && !props.isMobile ? '30%' : '100%') : (props.isVertical && !props.isMobile) ? '30%' : '50%')};
  max-width: ${(props) => (props.isPreview ? (props.isVertical && !props.isMobile ? '30%' : '100%') : (props.isVertical && !props.isMobile) ? '30%' : '50%')};
  height: auto;
  max-height: ${(props) => (props.isVertical ? '100%' : 'none')}; // Ajusta según necesidades
`;

