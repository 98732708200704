import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getGroupBySlug } from '../../../store/actions/group';
import { Group, PlanDto, Subscription, User } from '../../../types';
import Success from '../components/CheckoutRetryPaymentSuccess';
import ErrorPayment from '../components/CheckoutPaymentError';
import ErrorSubscription from '../components/CheckoutSubscriptionError';
import CheckoutSubscriberError from '../components/CheckoutSubscriberError';
import {
  CheckoutContainer, Column, ShowInDesktop,
  ShowInMobile
} from './styles'
import { hideModal, showModal } from '../../../store/actions/modal';
import ProcessSubscriptionModal from './components/ProcessSubscriptionModal';
import ErrorReason from './components/ErrorReason';
import NeedHelp from './components/NeedHelp';
import action from "../../../helpers/rest-client";
import MercadoPagoForm from '../components/MercadoPagoForm';
import SecureTechnology from '../components/SecureTechnology';
import Image from '../../../components/Image'
import mercadoPagoImg from '../../../assets/mercado-pago.png'
import { useTheme } from 'styled-components';
import { getUrlParamByName } from '../../../helpers/get-url-param';
import { getPlan } from '../../../store/actions/plan';

interface ComponentProps {
  isLoggedIn: boolean
  userSubscriptions: Subscription[]
  userSubscriptionStates: {
    loading: boolean
    success: boolean
    error: string | boolean
  }
  user: User
  userStates: {
    loading: boolean
    success: boolean
    error: string | boolean
  }
  group: Group,
  plan: PlanDto
}

const Component = ({
  isLoggedIn,
  userSubscriptions,
  userSubscriptionStates,
  user,
  userStates,
  group,
  plan
}: ComponentProps) => {
  const dispatch = useDispatch();
  const theme = useTheme() as { logo: { small: string, big: string } };

  const [gatewayError, setGatewayError] = useState(false)
  const [appError, setAppError] = useState(false)
  const [success, setSuccess] = useState(false)

  const groupSlug = useMemo(() => getUrlParamByName('groupSlug'), []);
  const planID = useMemo(() => getUrlParamByName('planId'), []);
  const subscriptionId = useMemo(() => getUrlParamByName('subscriptionId'), []);

  const onSuccess = (subscription) => {
    setSuccess(true)
    setGatewayError(false)
    setAppError(false)
  }

  const onError = (error) => {
    if (error?.response?.data?.source === 'gateway') {
      setGatewayError(true)
    } else if (error?.response?.data?.source === 'application') {
      setAppError(true)
    } else {
      setAppError(true)
    }
    setSuccess(false)
  }

  const subscription = useMemo(() =>
    userSubscriptions?.find((suscription) => suscription?.id === Number(subscriptionId)),
    [userSubscriptions, subscriptionId]
  )

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn && !!subscription) {
      const commonsSubscriptionsStates = ["active", "paid", "non_renewing"]
      const mpSubscriptionsStates = ["waiting_for_gateway", "retrying"]
      return commonsSubscriptionsStates.includes(subscription?.status) || mpSubscriptionsStates.includes(subscription?.status)
    } else {
      return false
    }
  }, [subscription])

  const price = useMemo(() => {
    if (!!plan) {
      const selectedPlanDiscount = plan?.discounts[0]
      return (plan?.price || 0) - (selectedPlanDiscount?.amount || 0);
    }

    return 0
  }, [plan])

  const publicKey = useMemo(() => {
    if (plan) {
      return plan?.gateway?.credentials?.public?.public_key;
    }

    return 0
  }, [plan])

  const discount = useMemo(() => {
    if (plan) {
      const selectedPlanDiscount = plan?.discounts?.length > 0 ? plan?.discounts[0] : null
      return selectedPlanDiscount && [selectedPlanDiscount]
    }

    return []
  }, [plan])

  const isWrongUser = useMemo(() => {
    return !subscription
  }, [subscription])

  const onSubmit = useCallback(async (cardData) => {
    dispatch(showModal('modal-process-new-subscription'))

    const payload = {
      payment_details: cardData,
      old_subscription_id: Number(subscriptionId)
    }

    action.Post({
      url: "/api/subscription/retry-payment",
      body: payload,
    }).then((response: Subscription) => {
      onSuccess(response)
    }).catch((error) => {
      onError(error)
    }).finally(() => {
      dispatch(hideModal())
    });
  }, [discount, price, planID]);

  useEffect(() => {
    if (!!groupSlug && (!group || (!!group && group?.slug !== groupSlug))) {
      dispatch(getGroupBySlug(groupSlug));
    }
  }, [groupSlug]);

  useEffect(() => {
    if (!!planID && (!plan || (!!plan && plan?.id !== Number(planID)))) {
      dispatch(getPlan({ idPlan: Number(planID) }));
    }
  }, [planID]);

  useEffect(() => {
    if (!userSubscriptionStates.loading && isUserSubscribed) {
      window.location.href = `/group/${groupSlug}/library`
    }
  }, [isUserSubscribed, groupSlug])

  useEffect(() => {
    if (!planID || !groupSlug) {
      const referrer = document.referrer;
      if (referrer) {
        window.location.href = referrer;
      } else if (groupSlug) {
        window.location.href = `/group/${groupSlug}`;
      } else {
        window.location.href = `/404`;
      }
    }
  }, [planID, groupSlug])


  if (!isLoggedIn || !group || !plan || plan?.group?.id !== group?.id || !userSubscriptions || userSubscriptionStates.loading || !user || userStates.loading) {
    return <></>
  }

  return (
    <>
      <CheckoutContainer>
        {!isWrongUser && !gatewayError && !appError && !success && (
          <>
            <Column className='col-one'>
              <div style={{ gap: 24, display: 'flex', justifyContent: 'center', width: '100%', maxWidth: 410 }}>
                <Image
                  src={theme?.logo?.big}
                  style={{ display: 'block' }}
                  width="107px"
                  height="auto"
                  className="logo-desktop"
                  alt="Klouser"
                  lazy
                />

                <div style={{ height: 30, width: 1, backgroundColor: '#B1C6E4' }} />

                <Image
                  src={mercadoPagoImg}
                  style={{ display: 'block' }}
                  width="101px"
                  height="auto"
                  className="logo-desktop"
                  alt="Mercado pago"
                  lazy
                />
              </div>

              <div style={{ height: 30 }} />

              <ErrorReason />

              <div style={{ height: 30 }} />

              <ShowInDesktop>
                <NeedHelp />
                <div style={{ height: 30 }} />
                <SecureTechnology style={{ margin: "0px -10px", maxWidth: 410 }} />
              </ShowInDesktop>
            </Column>

            <Column className='col-two'>
              <MercadoPagoForm
                onError={onError}
                onSuccess={onSuccess}
                onSubmit={onSubmit}
                publicKey={publicKey || ""}
                title={"Completa tu método de pago"}
                subtitle={"No te preocupes, no se te cobrará dos veces"}
                price={price}
                submitText="Actualizar metodo de pago"
                changeEmail={
                  subscription?.current_rejected_reason === 'high_risk' ||
                  subscription?.current_rejected_reason === 'pay_att_cant_be_reprocessed' ||
                  subscription?.current_rejected_reason === 'pay_att_cant_be_processed'
                }                
                user={user}
              />

              <ShowInMobile>
                <NeedHelp />
                <div style={{ height: 30 }} />
                <SecureTechnology style={{ margin: "0px -10px" }} />
              </ShowInMobile>
            </Column>
          </>
        )}
      </CheckoutContainer>

      <ProcessSubscriptionModal />

      {(isWrongUser) && <CheckoutSubscriberError />}
      {(!gatewayError && appError && !success) && <ErrorSubscription />}
      {(gatewayError && !appError && !success) && <ErrorPayment title={<>Suscripción rechazada</>} text={<>Hola {user?.name}. No te preocupes, esto sucede frecuentemente. Alguno de los datos puede estar incorrectos, tu tarjeta es invalida o no es soportada para este tipo de pagos automáticos. Prueba reintentando con el mismo u otro método de pago.</>} />}
      {(!gatewayError && !appError && success) && <Success />}
    </>
  );
};

const state = ({ userStore, groupStore, planStore }) => {
  const { isLoggedIn } = userStore;
  const { data: userSubscriptions, states: userSubscriptionStates } = userStore.subscriptions;
  const { data: user, states: userStates } = userStore.information;
  const { data: group } = groupStore?.group;
  const { data: plan } = planStore?.onePlan;

  return {
    isLoggedIn,
    userSubscriptions,
    userSubscriptionStates,
    user,
    userStates,
    group,
    plan
  };
};

export default connect(state)(Component);