import { MutableRefObject } from "react";

export const SET_SETTING = "SET_SETTING";
export const SET_CAROUSEL = "SET_CAROUSEL";
export const SET_NEXT_ARROW = "SET_NEXT_ARROW";
export const SET_PREV_ARROW = "SET_PREV_ARROW";
export const CURRENT_SLIDE = "CURRENT_SLIDE";
export const NEXT_SLIDE = "NEXT_SLIDE";
export const PREV_SLIDE = "PREV_SLIDE";
export const PAUSE_SLIDE = "PAUSE_SLIDE";
export const PLAY_SLIDE = "PLAY_SLIDE";
export const GO_TO_SLIDE = "GO_TO_SLIDE";

export interface SetSettings {
  type: typeof SET_SETTING;
  payload: CarouselSettings;
}

export interface SetCarousel {
  type: typeof SET_CAROUSEL;
  payload: MutableRefObject<HTMLElement | undefined>;
}

export interface SetNextArrow {
  type: typeof SET_NEXT_ARROW;
  payload: MutableRefObject<HTMLButtonElement | undefined>;
}

export interface SetPrevArrow {
  type: typeof SET_PREV_ARROW;
  payload: MutableRefObject<HTMLButtonElement | undefined>;
}

export interface CurrentSlide {
  type: typeof CURRENT_SLIDE;
  payload: number;
}

export interface CarouselSettings {
  appendDots?: (dots: any) => HTMLElement;
  customPaging?: (index: number) => HTMLElement;
  accessibility?: boolean;
  adaptiveHeight?: true | false;
  arrows?: boolean;
  asNavFor?: any;
  autoplay?: boolean;
  autoplaySpeed?: number;
  centerMode?: boolean;
  centerPadding?: string;
  className?: string;
  cssEase?: string;
  dots?: boolean;
  dotsClass?: string;
  draggable?: boolean;
  easing?: string;
  edgeFriction?: number;
  fade?: boolean;
  focusOnSelect?: boolean;
  infinite?: boolean;
  initialSlide?: number;
  lazyLoad?: "ondemand" | "progressive";
  mobileFirst?: boolean;
  nextArrow?: any;
  pauseOnDotsHover?: boolean;
  pauseOnFocus?: boolean;
  pauseOnHover?: boolean;
  prevArrow?: any;
  respondTo?: string;
  responsive?: null;
  rows?: number;
  rtl?: boolean;
  slide?: string;
  slidesPerRow?: number;
  slidesToScroll?: number;
  slidesToShow?: number;
  speed?: number;
  swipe?: boolean;
  swipeToSlide?: boolean;
  touchMove?: boolean;
  touchThreshold?: number;
  useCSS?: boolean;
  useTransform?: boolean;
  variableWidth?: boolean;
  vertical?: boolean;
  verticalSwiping?: boolean;
  waitForAnimate?: boolean;
  zIndex?: number;
}

export interface State {
  settings: CarouselSettings;
  carouselRef: MutableRefObject<HTMLElement | undefined> | undefined;
  nextArrowRef: MutableRefObject<HTMLButtonElement | undefined> | undefined;
  prevArrowRef: MutableRefObject<HTMLButtonElement | undefined> | undefined;
  currentSlide: number;
}

export const initialState: State = {
  settings: {},
  carouselRef: undefined,
  nextArrowRef: undefined,
  prevArrowRef: undefined,
  currentSlide: 0,
};

export type Actions =
  | SetSettings
  | SetCarousel
  | SetNextArrow
  | SetPrevArrow
  | CurrentSlide;
