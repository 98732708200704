import styled from "styled-components";
import ButtonUi from "../../../../../components/Button";
import {
  rainbowBackgroundGradient,
  rainbowTextGradient,
} from "../../../../../components/StyledComponents";

export const Button = styled(ButtonUi)`
  ${rainbowBackgroundGradient}
  border: 0px;
  height: 45px;
`;
