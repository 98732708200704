import {
  initialState,
  Actions,
  State,
  EXPORT_SURVEY_ANSWERS,
  EXPORT_SURVEY_ANSWERS_SUCCESS,
  EXPORT_SURVEY_ANSWERS_ERROR,
  RESET_EXPORT_SURVEY_ANSWERS,
} from "../../types/survey/export-survey-answers";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case EXPORT_SURVEY_ANSWERS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case EXPORT_SURVEY_ANSWERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case EXPORT_SURVEY_ANSWERS_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };

    case RESET_EXPORT_SURVEY_ANSWERS:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
