import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";

import {
  CREATE_INVITATION,
  CREATE_INVITATION_SUCCESS,
  CREATE_INVITATION_ERROR,
} from "../types/invitation/create";

import {
  ACCEPT_INVITATION,
  ACCEPT_INVITATION_SUCCESS,
  ACCEPT_INVITATION_ERROR,
} from "../types/invitation/accept";

import { 
  EDIT_INVITATION,
  EDIT_INVITATION_ERROR, 
  EDIT_INVITATION_SUCCESS 
} from "../types/invitation/edit";

function* createInvitation({ payload }): any {
  try {
    const { response, error } = yield call(services.invitations.createInvitation, payload);

    if (error) {
      yield put({ type: CREATE_INVITATION_ERROR, payload: error });
    } else {
      yield put({ type: CREATE_INVITATION_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: CREATE_INVITATION_ERROR,
      payload: "Error al crear las invitaciones.",
    });
  }
}


function* editInvitation({ payload }): any {
  try {
    const { response, error } = yield call(services.invitations.editInvitation, payload);

    if (error) {
      yield put({ type: EDIT_INVITATION_ERROR, payload: error });
    } else {
      yield put({ type: EDIT_INVITATION_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: CREATE_INVITATION_ERROR,
      payload: "Error al editar las invitaciones.",
    });
  }
}

function* acceptInvitation({ payload }): any {
  try {
    const { response, error } = yield call(
      services.invitations.acceptInvitation,
      payload
    );

    if (error) {
      yield put({ type: ACCEPT_INVITATION_ERROR, payload: error });
    } else {
      yield all([
        put({ type: ACCEPT_INVITATION_SUCCESS, payload: response }),
      ]);
    }
  } catch (error) {
    yield put({
      type: ACCEPT_INVITATION_ERROR,
      payload: "Error al aceptar la invitación",
    });
  }
}
/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(CREATE_INVITATION, createInvitation);
  yield takeLatest<any>(EDIT_INVITATION, editInvitation);
  yield takeLatest<any>(ACCEPT_INVITATION, acceptInvitation);
}
