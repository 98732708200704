import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import constants from "../../constants";
import {
  clean,
  save,
  getByKey,
  getAll,
  getChargebeeData,
  init,
  getMercadoPagoData,
  getRebillData,
  SubscriptionDto,
} from "./helpers";
import { Group, PlanDto, Subscription, User } from "../../types";
import { chargebeeCheckoutUrl } from "../../chargebee";
import { rebillCheckoutUrl } from "../../rebill";
import { mercadoPagoCheckoutUrl, mercadoPagoPreApprovalCheckoutUrl } from "../../mercado-pago";
import {
  subscriptionSteps,
  subscriptionStates,
} from "../../store/types/user/subscription";
import firebase from "firebase/app";
import { isSubscribed } from "../../helpers/isSubscribed";
import { Gateway } from "../../types/gateway";

export const SubscriptionContext = React.createContext({
  startSubscription: (_plan?: PlanDto) => { },
  finishSubscription: (_plan?: PlanDto) => { },
  cancelSubscription: (_plan?: PlanDto) => { },
  retrySubscription: (_plan?: PlanDto, _subscription?: Subscription) => { },
  ...getAll(),
});

// const onLoad = (data) => {};
// const onClose = (data) => {
//   save({ subscriptionState: subscriptionStates.CANCELLED });
//   if (window.location.pathname !== getByKey("groupUrl")) {
//     window.location.href = getByKey("groupUrl");
//   } else {
//     window.location.reload();
//   }
// };
// const onSuccess = (data) => {
//   save({ subscriptionState: subscriptionStates.DONE });
//   window.location.href = `/profile/subscription/${getByKey("groupId")}`;
// };
// const onError = (data) => {};
// const onStep = (data) => {};

interface ComponentProps {
  group: Group;
  userInformation: User;
  userSubscriptions: Subscription[];
  children: any;
  userInformationStates: {
    loading: boolean;
    error: boolean | string;
  };
  userSubscriptionsStates: {
    loading: boolean;
    error: boolean | string;
  };
}

const Component = ({
  group,
  userInformation,
  userSubscriptions,
  children,
  userInformationStates,
  userSubscriptionsStates,
}: ComponentProps) => {
  const user = firebase.auth().currentUser;
  const [plan, setPlan] = useState<PlanDto>()
  const [subscription, setSubscription] = useState<Subscription | null>()
  const [someDataChanged, setSomeDataChanged] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const startSubscription = (plan) => {
    setPlan(plan)
    setSubscription(null)
    save({ subscriptionState: subscriptionStates.INPROGRESS });
    nextStep();
  };

  const retrySubscription = (plan, subscription) => {
    setPlan(plan)
    setSubscription(subscription)
    save({ subscriptionState: subscriptionStates.INPROGRESS });
    nextStep();
  };

  const cancelSubscription = () => {
    save({ subscriptionState: subscriptionStates.CANCELLED });
    nextStep();
  };

  const finishSubscription = () => {
    save({ subscriptionState: subscriptionStates.DONE });
    nextStep();
  };

  const paySubscription = async () => {
    const groupId = getByKey("groupId");
    const groupSlug = getByKey("groupSlug");
    const platform = getByKey("platform");
    const planType = getByKey("planType");
    const subscriptionId = getByKey("subscriptionId")

    /**
     * If is an event, it is FREE and doesn't has
     * a platform
     */
    if (planType === "free_pass") {
      clean();
      window.location.href = `/checkout/free-subscription/${groupId}`;
    }

    /**
     * Mercado Pago Payment Method
     */
    if (platform === "mercadopago") {
      const mercadoPagoData = getMercadoPagoData();
      const { planID, couponId } = mercadoPagoData;
      let url = ''

      if (!!planID) {
        if (planType !== "subscription") {
          url = await mercadoPagoCheckoutUrl({
            platform,
            planID,
            couponId,
          });
        } else {
          if (!!groupSlug) {
            if (!!subscriptionId) {
              url = `/checkout/retry-payment?planId=${planID}&groupSlug=${groupSlug}&subscriptionId=${subscriptionId}`;
            } else {
              url = `/checkout/payment?planId=${planID}&groupSlug=${groupSlug}`;
            }
          } else {
            console.error('Subscription Error! - No se encontro el slug del grupo.')
          }
        }

        clean();
        window.location.href = url;
      } else {
        console.error('Subscription Error! - No se encontro el id del plan.')
      }

    }

    /**
     * Chargebee Payment Method
     */
    if (platform === "chargebee") {
      const chargebeeData = getChargebeeData();
      const { planId } = chargebeeData;

      if (planId && planId !== "") {
        const chargebeeInformation = {
          ...chargebeeData,
          site: process.env.REACT_APP_CHARGEBEE_SITE || "klouser-pc2-test",
        };

        const url = chargebeeCheckoutUrl(chargebeeInformation);

        clean();
        window.location.href = url;
      } else {
        console.error('Subscription Error! - No se encontro el id del plan.')
      }
    }

    /**
     * Rebill Payment Method
     */

    if (platform === "rebill") {
      const rebillData = getRebillData();
      const { planId } = rebillData;

      if (planId && planId !== "") {
        const rebillInformation = {
          ...rebillData,
          site: process.env.REACT_APP_CHARGEBEE_SITE || "klouser-pc2-test",
        };

        const url = rebillCheckoutUrl(rebillInformation);

        clean();
        window.location.href = url;
      } else {
        console.error('Subscription Error! - No se encontro el id del plan.')
      }
    }

    /**
     * Other Payment Method
     */
    if (platform !== "rebill" && platform !== "chargebee" && platform !== "mercadopago" && planType !== "free_pass") {
      console.error('Subscription Error! - No se encontro el metodo de pago seleccionado.')
    }
  };

  const loginUser = () => {
    if (window.location.pathname !== constants.urls.subscriber.signup.url) {
      window.location.href = constants.urls.subscriber.signup.url;
    }
  };

  const subscribedGroupPage = () => {
    const data = getAll();
    if (window.location.pathname !== `${data.groupUrl}/library`) {
      window.location.assign(`${data.groupUrl}/library`);
    }
  };

  const saveData = (newData, key: keyof SubscriptionDto) => {
    const withOutEffect = ["isLoggedIn"];
    const forceEffect = ["isAlreadySubscribed"];
    const isSame = newData === getByKey(key);

    if (!isSame || forceEffect.includes(key)) {
      const obj = {};
      obj[key] = newData;
      save(obj);

      if (!withOutEffect.includes(key)) {
        setSomeDataChanged(true);
      }
    }
  };

  const nextStep = () => {
    const data = getAll();
    // if (!userInformationStates.loading && !userSubscriptionsStates.loading) {
    if (data.subscriptionState === subscriptionStates.INPROGRESS) {
      if (data.isLoggedIn) {
        if (data.isAlreadySubscribed) {
          /**
           * Go to Subscribed Group Page
           */
          save({ subscriptionStep: subscriptionSteps.AUTHORIZED });
          finishSubscription();
          subscribedGroupPage();
        } else {
          /**
           * Pay Subscription
           */
          save({ subscriptionStep: subscriptionSteps.PAYMENT });
          paySubscription();
        }
      } else {
        /**
         * Go to Register Page
         */
        save({ subscriptionStep: subscriptionSteps.AUTHENTICATION });
        loginUser();
      }
    }
    // }
  };

  useEffect(() => {
    saveData(!!user, "isLoggedIn");

    if (userInformation) {
      saveData(userInformation?.email, "userEmail");
      saveData(userInformation?.subscriber?.name, "subscriberName");
      saveData(userInformation?.subscriber?.last_name, "subscriberLastName");
      saveData(
        userInformation?.subscriber?.phone_area_code,
        "subscriberPhoneAreaCode"
      );
      saveData(userInformation?.subscriber?.cellphone, "subscriberCellphone");
      saveData(
        userInformation?.subscriber?.billing_info?.address,
        "subscriberAddress"
      );
      saveData(
        userInformation?.subscriber?.billing_info?.state,
        "subscriberState"
      );
      saveData(
        userInformation?.subscriber?.billing_info?.city,
        "subscriberCity"
      );
      saveData(
        userInformation?.subscriber?.billing_info?.postal_code,
        "subscriberPostalCode"
      );
      saveData(
        userInformation?.subscriber?.chargebee_customer_id,
        "chargebeeCustomerId"
      );
    }

    if (!!group) {
      saveData(group?.id, "groupId");
      saveData(group?.slug, "groupSlug");
      saveData(window.location.pathname, "groupUrl");
      saveData(group?.group_type, "groupType");
      saveData(group?.group_name, "groupName")
      // saveData(group?.expert?.organization_id, "organizationId")
      // saveData(group?.expert?.organization_alias, "organizationAlias")
    }

    if (!!plan) {
      saveData(plan?.id, "planID");
      saveData(plan?.plan_id, "planId");
      saveData(plan?.price, "planPrice");
      saveData(plan?.type, "planType");
      saveData(plan?.platform, "platform");

      if (plan?.discounts?.length > 0) {
        saveData(plan?.discounts[0]?.coupon_id, "couponId");
      }

      const gateway = plan?.gateway
      saveData(gateway?.credentials?.public?.organization_id, "organizationId")
      saveData(gateway?.credentials?.public?.organization_alias, "organizationAlias")
    }

    if (!!subscription) {
      saveData(subscription?.id, "subscriptionId")
      saveData(subscription?.current_rejected_reason, "currentRejectedReason")
    }

    if (!!userSubscriptions && userSubscriptions?.length > 0) {
      const isSubscribed =
        userSubscriptions?.filter(
          (subscription) => {
            const mpSubscriptionStatus = ['waiting_for_gateway', 'retrying'];
            const commonSubscriptionStatus = ['active', 'paid', 'non_renewing', 'completed'];
            return group?.id === subscription?.group.id && (commonSubscriptionStatus.includes(subscription?.status) || mpSubscriptionStatus.includes(subscription?.status))
          }
        ).length > 0;

      saveData(isSubscribed, "isAlreadySubscribed");
      nextStep();
    }

    if (someDataChanged) {
      setSomeDataChanged(false);
      nextStep();
    }
  }, [group, plan, subscription, userSubscriptions, userInformation]);



  // useEffect(() => {
  //   if (!!userSubscriptions) {
  //     const isSubscribed =
  //       userSubscriptions?.filter(
  //         (subscription) => group?.id === subscription?.group.id
  //       ).length > 0;

  //     saveData(isSubscribed, "isAlreadySubscribed");
  //     nextStep();
  //   }
  // }, [userSubscriptions]);


  return (
    <SubscriptionContext.Provider
      value={{
        finishSubscription,
        cancelSubscription,
        startSubscription,
        retrySubscription,
        ...getAll(),
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

const state = ({ groupStore, userStore }) => {
  const { data: group } = groupStore.group
  const { data: userSubscriptions, states: userSubscriptionsStates } = userStore.subscriptions
  const { data: userInformation, states: userInformationStates } = userStore.information

  return {
    group,
    userInformation,
    userInformationStates,
    userSubscriptions,
    userSubscriptionsStates,
  };
};

export default connect(state)(Component);
