import { PlanDto } from "../../../types/plan.dto";
export const GET_PLANS = "GET_PLANS";
export const UPDATE_GET_PLANS = "UPDATE_GET_PLANS";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLANS_ERROR = "GET_PLANS_ERROR";
export const RESET_GET_PLANS = "RESET_GET_PLANS";

export interface GetPlans {
  type: typeof GET_PLANS;
  payload: { idGroup: number };
}

export interface UpdateGetPlans {
  type: typeof UPDATE_GET_PLANS;
  payload: PlanDto[];
}

export interface GetPlansSuccess {
  type: typeof GET_PLANS_SUCCESS;
  payload: PlanDto[];
}

export interface GetPlansError {
  type: typeof GET_PLANS_ERROR;
  payload: string | boolean;
}

export interface ResetGetPlans {
  type: typeof RESET_GET_PLANS;
  payload: null;
}

export interface State {
  data: PlanDto[] | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetPlans
  | GetPlansSuccess
  | GetPlansError
  | UpdateGetPlans
  | ResetGetPlans;
