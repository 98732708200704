import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Card = styled('div')`
  position: relative;
  overflow: hidden;
  padding: 26px 20px;
  border-radius: 12px;

  &:before {
    opacity: .12;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(207,171,242);
    background: linear-gradient(90deg, rgba(207,171,242,1) 0%, rgba(108,92,231,1) 31%, rgba(66,178,252,1) 99%);
    z-index: -1;
  }

  @media(min-width: 1024px){
    padding: 35px 45px;
  }
`

export const SectionTitle = styled('h4')`
margin: 0px;
  font-size: 14px;
  font-weight: 500;
`

export const Title = styled('h3')`
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  line-height: 34px;
`

export const Text = styled('div')`
  font-size: 16px;
  font-weight: regular;
  line-height: normal;
  padding: 0px 24px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  @media(min-width: 1024px){
    padding: 0px;
  }
`

export const HorizontalScrollWrapper = styled('div')`
  margin: 0px -10px;
`

export const HorizontalScroll = styled('div')`
  overflow-x: scroll;
  display: flex;
  scroll-snap-type: x mandatory;
  padding: 24px 34px;
  scroll-padding: 0 34px;
  
  @media(min-width: 1024px){
    padding: 24px 10px;
    scroll-padding: 0 10px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  & > * {
    scroll-snap-align: start;
    max-width: 300px!important;
    
    &:not(:last-child){
      margin-right: 30px;
    }
  }
`


export const Completed = styled('div')`
  border-radius: 15px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background-color: white;
  color: #12B47A;
  width: fit-content;
  font-weight: 500;
  gap: 8px;
  padding: 0px 16px;

  @media(min-width: 1024px){
    height: 44px;
    border-radius: 22px;
    font-size: 16px;
    font-weight: 600;
  }
`


export const NotCompleted = styled(Link)`
text-decoration: none;
  border: 1px solid rgba(45,52,54);
  background-color: rgba(45,52,54);
  border-radius: 15px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  font-weight: 500;
  gap: 8px;
  padding: 0px 16px;

  @media(min-width: 1024px){
    height: 44px;
    border-radius: 22px;
    font-size: 16px;
    font-weight: 600;
  }
`

export const CloseButton = styled('button')`
  margin: 0px;
  padding: 0px;
  border: 0px;
  background: none;
  position: absolute;
  top: 10px; 
  right: 15px;
  cursor: pointer;
`