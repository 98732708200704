import { GetAllWelcomeContentPostsOutDto, GetAllWelcomeContentPostsInDto } from "../../../types/welcome-section";
export const GET_ALL_WELCOME_CONTENT_POSTS = "GET_ALL_WELCOME_CONTENT_POSTS";
export const GET_ALL_WELCOME_CONTENT_POSTS_SUCCESS = "GET_ALL_WELCOME_CONTENT_POSTS_SUCCESS";
export const GET_ALL_WELCOME_CONTENT_POSTS_ERROR = "GET_ALL_WELCOME_CONTENT_POSTS_ERROR";
export const UPDATE_CURRENT_ALL_WELCOME_CONTENT = "UPDATE_CURRENT_ALL_WELCOME_CONTENT";
export const RESET_GET_ALL_WELCOME_CONTENT_POSTS = "RESET_GET_ALL_WELCOME_CONTENT_POSTS";

export interface GetAllWelcomeContentPosts {
  type: typeof GET_ALL_WELCOME_CONTENT_POSTS;
  payload: GetAllWelcomeContentPostsOutDto;
}

export interface GetAllWelcomeContentPostsSuccess {
  type: typeof GET_ALL_WELCOME_CONTENT_POSTS_SUCCESS;
  payload: GetAllWelcomeContentPostsInDto;
}

export interface GetAllWelcomeContentPostsError {
  type: typeof GET_ALL_WELCOME_CONTENT_POSTS_ERROR;
  payload: string | boolean;
}

export interface UpdateCurrentAllWelcomeContentPost {
  type: typeof UPDATE_CURRENT_ALL_WELCOME_CONTENT;
  payload: GetAllWelcomeContentPostsInDto;
}

export interface ResetGetAllWelcomeContentPosts {
  type: typeof RESET_GET_ALL_WELCOME_CONTENT_POSTS;
  payload: null;
}

export interface State {
  data: GetAllWelcomeContentPostsInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetAllWelcomeContentPosts
  | GetAllWelcomeContentPostsSuccess
  | GetAllWelcomeContentPostsError
  | UpdateCurrentAllWelcomeContentPost
  | ResetGetAllWelcomeContentPosts
