export const GET_SUBSCRIBER_TELEGRAM_GROUP = "GET_SUBSCRIBER_TELEGRAM_GROUP";
export const GET_SUBSCRIBER_TELEGRAM_GROUP_SUCCESS =
  "GET_SUBSCRIBER_TELEGRAM_GROUP_SUCCESS";
export const GET_SUBSCRIBER_TELEGRAM_GROUP_ERROR =
  "GET_SUBSCRIBER_TELEGRAM_GROUP_ERROR";

export interface GetSubscriberTelegramGroup {
  type: typeof GET_SUBSCRIBER_TELEGRAM_GROUP;
  payload: string | number;
}

export interface GetSubscriberTelegramGroupSuccess {
  type: typeof GET_SUBSCRIBER_TELEGRAM_GROUP_SUCCESS;
  payload: string;
}

export interface GetSubscriberTelegramGroupError {
  type: typeof GET_SUBSCRIBER_TELEGRAM_GROUP_ERROR;
  payload: boolean | string;
}

export interface State {
  data: string | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetSubscriberTelegramGroup
  | GetSubscriberTelegramGroupSuccess
  | GetSubscriberTelegramGroupError;
