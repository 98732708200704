import { Tabs, Tab, Link, HorizontalScroll } from "../../../components/styles";

import Grid from "../../../../../components/Grid";
import { connect } from "react-redux";
import { Group } from "../../../../../types";
import { useEffect, useState } from "react";

interface ComponentProps {
  community: Group;
  active: string;
  userGroups: Group[];
  userGroupsStates;
}

const Component = ({ community, active, userGroups, userGroupsStates }: ComponentProps) => {

  const [groups, setGroups] = useState<Group[]>();

  useEffect(() => {
    if (!!userGroups) {
      setGroups(userGroups?.filter((group) => group?.group_type === "free_experience"));
    }
  }, [userGroups]);

  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <HorizontalScroll>
            <Tabs>
              <Tab>
                <Link to="/dashboard/community" active={active === "home"}>
                  Panel general
                </Link>
              </Tab>
              <Tab>
                <Link
                  to={`/dashboard/community/${community?.id}/posts/`}
                  active={active === "library"}
                >
                  Lead Magnets
                </Link>
              </Tab>
              {groups && groups?.length > 0 && (
                <Tab>
                  <Link
                    to="/dashboard/community/events"
                    active={active === "events"}
                  >
                    Eventos Gratuitos
                  </Link>
                </Tab>
              )}
              <Tab>
                <Link
                  to="/dashboard/community/members"
                  active={active === "members"}
                >
                  Base de Datos
                </Link>
              </Tab>
            </Tabs>
          </HorizontalScroll>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

const state = ({ communityStore, userStore }) => {
  const { data: community } = communityStore.community;
  const { data: userGroups, states: userGroupsStates } = userStore.groups;

  return { community, userGroups, userGroupsStates, };
};

export default connect(state)(Component);
