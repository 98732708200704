import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Helmet from "react-helmet";
import { useHistory } from "react-router";
import { Formik } from "formik";
import { Title, Text, ShareArticle } from "./styles";
import PostForm from "../components/PostForm";
import { Page, PageHeader } from "../../components/styles";
import services from "../../../../services";
import { initialValues, schema } from "../../../../constants/forms/post/edit";
import Submit from "../../../../components/FormikForm/Submit";
import Link from "../../../../components/Link";
import Grid from "../../../../components/Grid";
import GoBack from "../../../../components/GoBack";
import Snackbar from "../../../../components/Snackbar";
import CopyToClipboad from "../../../../components/CopyToClipboad";
import Loader from "../../../../components/LogoLoader";
import {
  editPost,
  resetEditPost,
  getPost,
} from "../../../../store/actions/post";
import { CategoryDto } from "../../../../types/category";
import { Post } from "../../../../types/post";
import { Group } from "../../../../types";
import { useLayout } from "../../../../providers/LayoutProvider";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { getGroupById } from "../../../../store/actions/group";
import { getFilesSize } from "../../../../helpers/filesSizes";
import { hideModal, showModal } from "../../../../store/actions/modal";
import { setToZeroTimeZone } from "../../../../helpers/dates-hanlder";
import { ConnectedFocusError } from "../../../../components/ConnectedFocusError";
import { NewFileInDto } from "../../../../types/storage.dto";
import { useFileUploadProgress } from "../../../../providers/FileUploader";
import ModalWaitAMinuteBis from "../New/components/ModalWaitAMinute";
import { getPostsFilePath } from '../../../../services/storage'

interface ComponentProps {
  match;
  userInformation;
  allCategoriesInformation;
  allCategoriesInformationStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  groupInformation: Group;
  groupInformationStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  postInformation: Post;
  postInformationStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  editPostStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

const Component = ({
  match,
  userInformation,
  allCategoriesInformation,
  allCategoriesInformationStates,
  groupInformation,
  groupInformationStates,
  postInformation,
  postInformationStates,
  editPostStates,
}: ComponentProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();
  const { layout, setLayout } = useLayout();

  const { uploadFiles, resetFileUploadProgress } = useFileUploadProgress();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const timestamp = new Date().getTime();

  const onSubmit = async ({ values, actions }) => {
    //Cuando migremos cover_pic podremos descomentar la linea de abajo
    //Debemos sumar la siguiente validacion "values.cover_pic?.some(file => file instanceof File"
    const thereAreFilesToUpload = values && values.attached_files?.some(file => file instanceof File);

    if (thereAreFilesToUpload) {
      dispatch(showModal("modal-wait-a-minute-bis"));
    }

    try {
      if (thereAreFilesToUpload) {
        const uploadedFiles = await uploadFiles(
          {
            //Cuando migremos cover_pic podremos descomentar la linea de abajo
            //...(values?.cover_pic && values?.cover_pic.length > 0 && { cover_pic: values?.cover_pic }),
            ...(values?.attached_files && values?.attached_files.length > 0 && { attached_files: values?.attached_files }),
          },
          userInformation.id,
          "post"
        );

        const updatedValues = {
          ...values,
          schedule_publish_at: values.schedule_publish_at ? setToZeroTimeZone(values.schedule_publish_at) : null,
          notificate_by_email: values.notificate_by_email.includes("true"),
          notificate_by_telegram: values.notificate_by_telegram.includes("true"),
          show_scheduled_post: values.show_scheduled_post.includes("true"),
          block_files_download: values.block_files_download.includes("true"),
          pinned: values.pinned.includes("true"),
          is_free: values.is_free.includes("true"),
          attached_files: uploadedFiles?.attached_files ? uploadedFiles?.attached_files : []
        };

        dispatch(editPost(updatedValues));
      } else {
        // If there are no files to upload, dispatch newWelcomeContentPost directly
        const updatedValues = {
          ...values,
          schedule_publish_at: values.schedule_publish_at ? setToZeroTimeZone(values.schedule_publish_at) : null,
          notificate_by_email: values.notificate_by_email.includes("true"),
          notificate_by_telegram: values.notificate_by_telegram.includes("true"),
          show_scheduled_post: values.show_scheduled_post.includes("true"),
          block_files_download: values.block_files_download.includes("true"),
          pinned: values.pinned.includes("true"),
          is_free: values.is_free.includes("true"),
          attached_files: values?.attached_files
        };

        dispatch(editPost(updatedValues));
      }
    } catch (error) {
      console.error('Error during file uploads:', error);
    } finally {
      dispatch(hideModal());
    }
  };

  // const onSubmit = async ({ values, actions }) => {
  //   const thereAreNewFilesToUpload = values && values.attached_files && values?.attached_files.filter(f => !f.hasOwnProperty("id")).length > 0
  //   //if (values.attached_files?.length > 0 || values?.cover_pic?.length > 0) {
  //   if (thereAreNewFilesToUpload) {
  //     dispatch(showModal("modal-wait-a-minute-bis"));
  //   }

  //   if (
  //     values &&
  //     values?.attached_files &&
  //     values?.attached_files?.length > 0
  //   ) {
  //     // Create an array to hold all upload promises
  //     const uploadPromises: NewFileInDto[] = [];

  //     const oldFiles = values?.attached_files.filter(f => f.hasOwnProperty("id"))
  //     const newFilesToUpload = values?.attached_files.filter(f => !f.hasOwnProperty("id"))

  //     if (newFilesToUpload && newFilesToUpload.length > 0) {
  //       dispatch(showModal("modal-wait-a-minute-bis"));
  //     }

  //     for (let i = 0; i < newFilesToUpload.length; i++) {
  //       const file = values?.attached_files[i];
  //       const fileExt = file?.name?.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
  //       const fileName = `${userInformation.id}_post_${timestamp + i}`;

  //       const uploadPromise = new Promise((resolve, reject) => {
  //         services.storage.saveFile({
  //           file,
  //           fileName: getPostsFilePath({ fileName, fileExt }),
  //           onUploadProgress: (uploadProgress) => {
  //             updateFileUploadProgress({ preview: file?.url || file?.preview, fileName: fileName, fileSize: file.size, fileType: file.type, originalName: file.name, progress: uploadProgress });
  //           },
  //           onAbort: () => {
  //             dispatch(hideModal())
  //           },
  //         })
  //           .then((response) => resolve(response))
  //           .catch((error) => reject(error));
  //       });

  //       uploadPromises.push(uploadPromise);
  //     }

  //     // Wait for all upload promises to resolve
  //     try {
  //       const uploadResponses = await Promise.all(uploadPromises);

  //       // Check if all uploads were successful
  //       const allUploadsSuccessful = uploadResponses.every(
  //         (response) => 'response' in response
  //       );

  //       if (allUploadsSuccessful) {
  //         // Create an array to hold attached files data
  //         const attachedFilesData = newFilesToUpload.map((file, i) => {
  //           const ext = file?.name?.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2);
  //           const fileName = `${userInformation.id}_post_${timestamp + i}`;

  //           return {
  //             file_name: fileName,
  //             original_name: file.name,
  //             mime: file.type,
  //             extension: ext,
  //             size: file.size,
  //             path: getPostsFilePath({ fileName, fileExt: ext }),
  //           };
  //         });

  //         // All images uploaded successfully, now you can dispatch newPost
  //         dispatch(
  //           editPost({
  //             ...values,
  //             schedule_publish_at: values.schedule_publish_at ? setToZeroTimeZone(values.schedule_publish_at) : null,
  //             notificate_by_email: values.notificate_by_email.includes("true"),
  //             notificate_by_telegram: values.notificate_by_telegram.includes("true"),
  //             show_scheduled_post: values.show_scheduled_post.includes("true"),
  //             block_files_download: values.block_files_download.includes("true"),
  //             pinned: values.pinned.includes("true"),
  //             is_free: values.is_free.includes("true"),
  //             attached_files: attachedFilesData.concat(oldFiles)
  //           })
  //         );
  //       } else {
  //         // Handle the case where at least one upload failed
  //         console.error('One or more file uploads failed.');
  //       }
  //     } catch (error) {
  //       // Handle errors that occurred during file uploads
  //       console.error('Error during file uploads:', error);
  //     }
  //   } else {
  //     // All images uploaded successfully, now you can dispatch newPost
  //     dispatch(
  //       editPost({
  //         ...values,
  //         schedule_publish_at: values.schedule_publish_at ? setToZeroTimeZone(values.schedule_publish_at) : null,
  //         notificate_by_email: values.notificate_by_email.includes("true"),
  //         notificate_by_telegram: values.notificate_by_telegram.includes("true"),
  //         show_scheduled_post: values.show_scheduled_post.includes("true"),
  //         block_files_download: values.block_files_download.includes("true"),
  //         pinned: values.pinned.includes("true"),
  //         is_free: values.is_free.includes("true"),
  //         attached_files: values?.attached_files
  //       })
  //     );
  //   }
  // };

  useEffect(() => {
    if (!!postInformation && editPostStates.success) {
      setTimeout(() => {
        const groupId = groupInformation?.id
        const postId = postInformation?.id
        dispatch(resetEditPost());
        resetFileUploadProgress();
        dispatch(hideModal());
        history.push(`/group/${groupId}/p/${postId}`);
      }, 3500);
    }
  }, [postInformation, editPostStates]);

  useEffect(() => {
    if (
      !!match.params &&
      (!groupInformation ||
        (!!groupInformation &&
          groupInformation.id !== Number(match.params.idGroup)))
    ) {
      dispatch(getGroupById({ group_id: match.params.idGroup }));
    }

    if (
      !!match.params &&
      (!postInformation ||
        (!!postInformation &&
          postInformation.id !== Number(match.params.idPost)))
    ) {
      dispatch(getPost({ idPost: match.params.idPost }));
    }
  }, [match]);

  /*useEffect(() => {
    if (editPostStates.success) {
      setTimeout(() => {
        dispatch(resetEditPost());
        dispatch(hideModal());
        history.goBack();
      }, 3500);
    }
  }, [editPostStates]);*/

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: (<strong>Editar artículo</strong>),
        description: "Editar artículo",
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: true,
        actions: [
          <Link
            options={{
              type: "outline",
              size: "lg",
              skin: "purple",
              native: true,
            }}
            disabled={!groupInformation}
            href={
              groupInformation?.group_type === "free_experience"
                ? `/dashboard/community/events/${groupInformation?.id}/posts`
                : `/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/posts`
            }
          >
            Cancelar
          </Link>,
          <Submit
            isSubmmiting={editPostStates.loading}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            disabled={!groupInformation}
            form="edit-post-form"
          >
            Guardar artículo
          </Submit>,
        ],
      },
    });
  }, [groupInformation, postInformation, editPostStates]);

  if (!postInformation || postInformationStates.loading) {
    return <Loader />;
  }

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <Text>
              📝 Redacta tus artículos y sube documentos en pdf, imágenes, clases
              grabadas, videos y ¡hasta tus Reels de Instagram!
            </Text>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col md={7}>
            <Formik
              initialValues={{
                ...initialValues,
                ...postInformation,
                notificate_by_email: postInformation.notificate_by_email ? ["true"] : [],
                notificate_by_telegram: postInformation.notificate_by_telegram ? ["true"] : [],
                block_files_download: postInformation.block_files_download ? ["true"] : [],
                pinned: postInformation.pinned ? ["true"] : [],
                show_scheduled_post: postInformation.show_scheduled_post ? ["true"] : [],
                is_free: postInformation.is_free ? ["true"] : [],
                content_categories: postInformation.content_categories?.map(
                  (item: CategoryDto) => item.id
                ),
                group: postInformation?.group?.id,
                cover_pic: postInformation?.cover_pic ? [postInformation?.cover_pic] : [],
              }}
              onSubmit={(values, actions) => {
                if (onSubmit) onSubmit({ values, actions });
              }}
              validateOnChange={false}
              validateOnBlur={formSubmmited}
              validationSchema={schema}
              enableReinitialize
            >
              {({ handleSubmit, ...rest }) => {
                return (
                  <form
                    className="theme-form"
                    onSubmit={(event) => {
                      setFormSubmmited(true);
                      handleSubmit(event);
                    }}
                    id="edit-post-form"
                  >
                    <ConnectedFocusError />
                    <PostForm {...rest} />
                  </form>
                );
              }}
            </Formik>
          </Grid.Col>
        </Grid.Row>

        {!isMobile && (
          <Grid.Row>
            <Grid.Col>
              <Link
                style={{ marginRight: "8px" }}
                options={{
                  type: "outline",
                  size: "lg",
                  skin: "purple",
                  native: true,
                }}
                href={
                  groupInformation?.group_type === "free_experience"
                    ? `/dashboard/community/events/${groupInformation?.id}/posts`
                    : `/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/posts`
                }
              >
                Cancelar
              </Link>

              <Submit
                isSubmmiting={editPostStates.loading}
                options={{
                  type: "filled",
                  size: "lg",
                  skin: "purple",
                }}
                form="edit-post-form"
              >
                Guardar artículo
              </Submit>
            </Grid.Col>
          </Grid.Row>
        )}
      </Grid.Container>

      <ModalWaitAMinuteBis />

      <Snackbar
        visible={editPostStates.success || !!editPostStates.error}
        options={{
          time: 2000,
          type: editPostStates.success ? "success" : "error",
        }}
      >
        {editPostStates.success
          ? "Artículo editado."
          : "Error al editar el artículo."}
      </Snackbar>
    </>
  );
};

const state = ({ postStore, groupStore, categoryStore, userStore }) => {
  const { data: userInformation } = userStore.information;
  const { allCategories } = categoryStore;
  const {
    data: allCategoriesInformation,
    states: allCategoriesInformationStates,
  } = allCategories;
  const { data: groupInformation, states: groupInformationStates } =
    groupStore.group;
  const { data: postInformation, states: postInformationStates } =
    postStore.post;
  const { states: editPostStates } = postStore.editPost;

  return {
    userInformation,
    allCategoriesInformation,
    allCategoriesInformationStates,
    groupInformation,
    groupInformationStates,
    postInformation,
    postInformationStates,
    editPostStates,
  };
};

export default connect(state)(Component);
