import ReactDOM from "react-dom";
import { connect, useDispatch } from "react-redux";
import Icon from "../../../Icon";
import constants from "../../../../constants";
import { User } from "../../../../types";
import { Preference } from "../../../../types/preferences";
import { Navbar, NavbarItem, NavbarItemWrapper, Mark } from "./styles";
import { useEffect, useMemo, useState } from "react";
import slugify from "slugify";
import { ghostLink } from "../../../../helpers/ghost-link";
import { useHistory } from "react-router-dom";

interface ComponentProps { }

const footerSticky = document.getElementById("footer-sticky") as HTMLElement;

interface ComponentProps {
  userInformation: User;
  userInformationStates;
  useLocation;
  preferences: Preference[];
  preferencesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  subscriptions;
  subscriptionsStates;
  currentPage;
}

const Component = ({
  userInformation,
  userInformationStates,
  useLocation,
  preferences,
  preferencesStates,
  subscriptions,
  subscriptionsStates,
  currentPage,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [availableMarks, setAvailableMarks] = useState<string[]>([]);

  const onClick = (page) => {
    const redirect = page.native;

    if (page.url) {
      if (redirect) {
        history.push(page.url);
      } else {
        ghostLink(page.url);
      }
    }

    if (page.action) {
      dispatch(page.action());
    }
  };

  const isExpert = useMemo((): boolean => {
    return !!userInformation?.expert && userInformationStates.success;
  }, [userInformation, userInformationStates]);

  return ReactDOM.createPortal(
    <Navbar>
      {constants?.dashboardMenu?.mainMenu
        .filter(
          (item) =>
            item.active &&
            !(
              item.label === "Cupones" &&
              userInformation?.expert?.currency === "ARS"
            ) &&
            (!item.expert || (item.expert && !!userInformation?.expert)) &&
            (item.label !== "Mis suscripciones" ||
              (item.label === "Mis suscripciones" &&
                !!subscriptions &&
                subscriptions.length > 0))
        )
        .map((item) => {
          const showMark = availableMarks.some(
            (r) => item?.newFeatureMark && item?.newFeatureMark?.indexOf(r) >= 0
          );

          return (
            <NavbarItem key={slugify(item.label, { lower: true })}>
              <NavbarItemWrapper
                isActive={item?.key?.includes(currentPage)}
                onClick={() => onClick(item)}
                width={""}
              >
                {showMark && (
                  <Mark isActive={item?.key?.includes(currentPage)} />
                )}

                <Icon
                  icon={item.icon}
                  size="24px"
                  color={
                    item?.key?.includes(currentPage)
                      ? "#6C5CE7"
                      : "rgba(45, 52, 54, 0.75)"
                  }
                />

                <span>
                  {item.url === "/dashboard/community" && !isExpert
                    ? "Crear mi comunidad"
                    : item.label}
                </span>
              </NavbarItemWrapper>
            </NavbarItem>
          );
        })}
    </Navbar>,
    footerSticky
  );
};

const state = ({ userStore, preferencesStore, pageStore }) => {
  const { data: userInformation, states: userInformationStates } =
    userStore.information;
  const { data: preferences, states: preferencesStates } =
    preferencesStore.preferences;
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;

  return {
    userInformation,
    userInformationStates,
    preferences,
    preferencesStates,
    subscriptions,
    subscriptionsStates,
    currentPage: pageStore?.name,
  };
};

export default connect(state)(Component);
