import styled from "styled-components";
import ImgUi from "../../../../components/Image";

export const ReferentsCard = styled.div`
  margin-bottom: 80px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin-bottom: 150px;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 46px;
  text-align: center;
  margin-bottom: 50px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 48px;
    line-height: 60px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    font-size: 50px;
    line-height: 72px;
  }
`;

export const CardsWrapper = styled.div``;

export const Cards = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px -10px 5px;

  &:last-child {
    margin: 0px -10px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    flex-direction: row;
    margin: 0px -10px 20px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
  }
`;

export const Card = styled.div`
  position: relative;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 0px 5px;
  width: 100%;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 0px 10px;
    margin-bottom: 0px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    padding: 0px 10px;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  &:before {
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0;
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  &:before,
  h3 {
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    &:before,
    h3 {
      opacity: 1;
    }
  }
`;

export const Image = styled(ImgUi)`
  border-radius: 16px;
  display: block;
  width: 100%;
  height: auto;
`;

export const CardTitle = styled.h3`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  opacity: 0;
  color: white;
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  margin: 0px;
  color: ${(props) => props.theme.colors.PurpleVeryLight};

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 50px;
    font-size: 30px;
    line-height: 32px;
  }
`;
