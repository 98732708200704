import { put, call, all, select, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NEW_REACTION,
  NEW_REACTION_SUCCESS,
  NEW_REACTION_ERROR,
} from "../types/reaction/new-reaction";

import {
  DELETE_REACTION,
  DELETE_REACTION_SUCCESS,
  DELETE_REACTION_ERROR,
} from "../types/reaction/delete-reaction";

import {
  UPDATE_CURRENT_POST
} from "../types/post/get-post"

import { DeleteReactionOutDto } from "../../types/reaction.dto";

const getCurrentPost = ({ postStore }) => postStore.post.data;

function* newReaction({ payload }): any {
  try {
    const { response, error } = yield call(services.reaction.addReaction, payload);

    if (error) {
      yield put({ type: NEW_REACTION_ERROR, payload: error });
    } else {
      const post = yield select(getCurrentPost);

      yield all([
        put({ type: NEW_REACTION_SUCCESS, payload: response }),
        put({ type: UPDATE_CURRENT_POST, payload: { ...post, post_reactions: [...(post.post_reactions ?? []), response] } }),
      ])
    }
  } catch (error) {
    yield put({
      type: NEW_REACTION_ERROR,
      payload: "Error inexperado al agregar reacción.",
    });
  }
}

function* deleteReaction({ payload }: { payload: DeleteReactionOutDto }): any {
  try {
    const { response, error } = yield call(services.reaction.removeReaction, payload);
    if (error) {
      yield put({ type: DELETE_REACTION_ERROR, payload: error });
    } else {
      const post = yield select(getCurrentPost);

      const newListReactions = !!post
        ? post.post_reactions.filter((reaction) => reaction.id !== payload.idReaction)
        : [];

      yield all([
        put({ type: DELETE_REACTION_SUCCESS, payload: response }),
        put({ type: UPDATE_CURRENT_POST, payload: { ...post, post_reactions: newListReactions } }),
      ]);
    }
  } catch (error) {
    yield put({ type: DELETE_REACTION_ERROR, payload: "Error inexperado al eliminar reacción.", });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(NEW_REACTION, newReaction);
  yield takeLatest<any>(DELETE_REACTION, deleteReaction);
}
