import { useState } from "react";
import { Header, Title, AvatarImage, ToggleMenu, Toggle } from "./styles";
import StickyActions from "../StickyActions";
import StickyMenu from "../StickyMenu";
import SidebarMenu from "../SidebarMenu";
import { connect } from "react-redux";
import { User } from "../../../../types";
import Grid from "../../../../components/Grid";
import UserRoundedIcon from "../../../../assets/icons/user-rounded.svg";
import { Preference } from "../../../../types/preferences";
import { useLayout } from "../../../../providers/LayoutProvider";
import arrowBackIcon from "../../../../assets/icons/arrow-backward.svg";
import Icon from "../../../Icon";
import GoBack from "../../../GoBack";
import hamburger from "../../../../assets/hamburger.svg";


interface ComponentProps {
  userInformation: User;
  userInformationStates;
  useLocation;
  preferences: Preference[];
  preferencesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  subscriptions;
  subscriptionsStates;
  navigationLevel?: number;
}

const Component = ({
  userInformation,
  userInformationStates,
  useLocation,
  preferences,
  preferencesStates,
  subscriptions,
  subscriptionsStates,
  navigationLevel,
}: ComponentProps) => {
  const { layout } = useLayout();
  const [menuIsOpened, setMenuIsOpened] = useState(false);

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <Header>
              {layout?.page?.back && (
                <GoBack backTo={layout?.page?.back} showAlt></GoBack>
              )}
              <Title>{layout?.page?.title}</Title>
              <ToggleMenu onClick={() => setMenuIsOpened(true)}>
                <Toggle skin={layout.skin}>
                <img src={hamburger} alt="hamburger" width="24px" height="24px" />
                </Toggle>
                {/* <AvatarImage
                  src={
                    userInformation?.expert?.profile_pic?.url || hamburger
                  }
                  alt=""
                /> */}
              </ToggleMenu>
            </Header>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <SidebarMenu
        useLocation={useLocation}
        menuIsOpened={menuIsOpened}
        setMenuIsOpened={setMenuIsOpened}
      />

      {navigationLevel === 1 && <StickyMenu useLocation={useLocation} />}
      {navigationLevel === 2 && <StickyActions useLocation={useLocation} />}
    </>
  );
};

const state = ({ userStore, preferencesStore }) => {
  const { data: userInformation, states: userInformationStates } =
    userStore.information;
  const { data: preferences, states: preferencesStates } =
    preferencesStore.preferences;
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;

  return {
    userInformation,
    userInformationStates,
    preferences,
    preferencesStates,
    subscriptions,
    subscriptionsStates,
  };
};

export default connect(state)(Component);
