import interactArticle from "./../assets/home-page/interact-article.webp";
import interactTelegram from "./../assets/home-page/interact-telegram.webp";
import createMembersDirectory from "./../assets/home-page/create-members-directory.webp";
import createGroupLanding from "./../assets/home-page/create-group-landing.webp";
import avatarOne from "./../assets/home-page/avatar-one.webp";
import avatarTwo from "./../assets/home-page/avatar-two.webp";
import avatarThree from "./../assets/home-page/avatar-three.webp";
import monetizeFlexibility from "./../assets/home-page/monetize-flexibility.webp";
import monetizeInternationalPayments from "./../assets/home-page/monetize-international-payments.webp";
import monetizeDiscounts from "./../assets/home-page/monetize-discounts.webp";

const home = {
  faqs: [
    {
      title: "¿Tengo que pagar algo?",
      text: `Registrarse y explorar Klouser es totalmente gratuito. No requiere inversión previa ni pagos mensuales. Sólo cobramos una pequeña comisión del 10% cuando generes ingresos.`,
    },
    {
      title: "¿Puede suscribirse a mis propuestas cualquier persona del mundo?",
      text: `Si eliges lanzar tus Experiencias o Membresías en la moneda “dólares” (US$) cualquier persona del mundo puede suscribirse a tus propuestas. Si eliges la moneda “pesos argentinos” ($ARG) sólo podrán suscribirse las personas de Argentina.`,
    },
    {
      title: "¿Qué significa lanzar una experiencia?",
      text: `Le llamamos “Experiencias” a las diferentes dinámicas grupales de duración limitada en donde recibes un pago único por ese evento. 
      Los eventos de duración limitada que más utilizan los referentes en klouser son: 

- Master Class (1 día) 

- Taller (2 días) 

- Challenge (7 días o 21 días) 

- Curso (1 mes) 

- Puedes re-lanzar tus experiencias cuantas veces quieras y también combinarlas con una membresía mensual.`,
    },
    {
      title: "¿Qué significa lanzar una membresía?",
      text: `Si ya tienes una audiencia que sigue tu contenido o quizás luego de lanzar una Experiencia las personas se queden con ganas de más, entonces es momento de lanzar una membresía.
      Las personas abonarán una suscripción mensual (como Netflix) y tú podrás compartirles contenido mensualmente de forma ordenada, audios, videos, imágenes y realizar encuentros en vivo. 
      Recibirás el acompañamiento de un asesor estratégico para que puedas transformar a tu audiencia en una comunidad y recibir ingresos recurrentes. `,
    },
    {
      title: "¿Quiénes usan Klouser?",
      text: `Klouser es un espacio diseñado sólo para comunidades del bienestar. 
      Utilizan nuestra plataforma referentes y profesionales de Coaching, Terapias Holísticas, Astrología, Mindfulness y Yoga entre otras.
      Si ofreces algún servicio de salud, bienestar o desarrollo personal que no hayamos nombrado puedes consultar en el botón de “Ayuda”.`,
    },
    {
      title: "¿Qué hace Klouser por mi?",
      text: `Para que puedas ocupar tu tiempo en contenido de valor para tu audiencia, nosotros nos ocupamos de las otras tareas que te quitan tiempo:

- Gestionamos los pagos de tus suscriptores y respondemos sus consultas técnicas. 

- Nuestro asistente virtual les da la bienvenida a tu Experiencia o Membresía cuando se suscriben.
      
Te ayudamos a dar un salto de calidad para profesionalizar tus propuestas y para que puedas lograr tus objetivos de venta:

- Cuando lanzas una Experiencia o Membresía te creamos un sitio de ventas personalizado con una plataforma de pago para que tus suscriptores abonen de forma fácil y segura. 

- Un asesor estratégico ofrece acompañamiento personalizado y en vivo para que puedas generar ingresos sostenibles todos los meses.`,
    },
    {
      title: "¿Qué beneficios concretos obtengo usando Klouser?",
      text: `En klouser nos dedicamos única y exclusivamente a negocios en el desarrollo del bienestar, por lo que nuestros beneficios están pensados para que los referentes de este ámbito puedan generar ingresos  y seguir creciendo de manera saludable y sostenible.

- Podrás lanzar las dinámicas grupales que necesites como talleres, master classes, cursos,challenges o una membresía mensual en un espacio privado por fuera de las redes sociales.

- Tendrás un sitio de ventas personalizado.

- Plataforma de pagos integrada.

- Link a tu sitio de venta para que puedas compartirlo y llegues a más personas

- Podrás interactuar con tus suscriptores y compartir tu contenido de forma ordenada a través de un súper canal de Telegram con integraciones nuevas para que sea más amigable.

- Una biblioteca en tu plataforma para que puedas organizar y compartir contenido en diferentes formatos. (artículos, links, videos y audios)

- Nuestro asistente virtual les dará la bienvenida a tus suscriptores.

- Un asesor estratégico te acompañará en todo el camino para guiarte de forma personalizada a lograr tus objetivos.

- Soporte personalizado y en vivo para tí y tu audiencia cuando lo necesiten.

- Lograrás transformar a tu audiencia en una comunidad que vibre en tu misma frecuencia, acompañarlos y guiarlos en el camino a la vez que generas ingresos sostenibles.`,
    },
    {
      title: "¿Cómo recibo el dinero y cuánto demora?",
      text: `Tanto para las Experiencias como para las Membresías entregamos todo el dinero recaudado al referente en una fecha pactada. 
      Para las experiencias (taller, curso, challenge, master class) transferimos el dinero recaudado aproximadamente a los 15 días del comienzo del evento. 
      Para las membresías transferimos el dinero recaudado aproximadamente entre el día 15 y 17 del mes siguiente. (Ej: recibes en diciembre lo recaudado en noviembre) 
      `,
    },
  ],
  create: [
    {
      title: "Personaliza tu espacio",
      text: "Crea tantos grupos como necesites y dales un estilo único. Elige una imagen que represente lo que quieras transmitir a tu comunidad, edita el nombre, define un precio y listo! ",
      image: createGroupLanding,
    },
    {
      title: "Directorio de miembros",
      text: "Explora el listado de participantes en todos tus grupos. Recibe información sobre ellos para que puedas conocer a tu comunidad como siempre quisiste! ",
      image: createMembersDirectory,
    },
  ],
  interact: [
    {
      title: "Biblioteca de contenidos",
      text: "Tu lugar para crear artículos y subir contenido multimedia como audios, videos, podcast, imágenes y archivos. Comparte todo lo que necesites con tus suscriptores y organízalo en carpetas.",
      image: interactArticle,
    },
    {
      title: "Súper canal en Telegram",
      text: "Creamos un canal de Telegram sincronizado con tu Klouser para que interactúes con tu comunidad. Nosotros nos ocupamos de las altas y bajas de suscriptores para que puedas poner toda tu atención y tu energía en tu comunidad.",
      image: interactTelegram,
    },
  ],
  monetize: [
    {
      title: "Flexibilidad en tu modelo de negocio",
      text: "Ofrece diferentes propuestas según tus necesidades y el tipo de audiencia. Recibe ingresos recurrentes a través de grupos de suscripción, o a través de eventos de pago único como un curso, taller o masterclass.",
      image: monetizeFlexibility,
    },
    {
      title: "Recibe pagos internacionales",
      text: "A través de tu pagina de Klouser las personas pueden suscribirse y abonar de forma segura en su moneda local, dólares o euros.",
      image: monetizeInternationalPayments,
    },
    {
      title: "Descuentos exclusivos",
      text: "Ofrece a tu comunidad promociones y descuentos exclusivos. Ideal para hacer sorteos o premiar a tus seguidores más fieles.",
      image: monetizeDiscounts,
    },
  ],
  testimonials: [
    {
      text: `Klouser me permitió generar un ingreso mensual haciendo lo que amo y
    seguridad en mi autoestima! Me aportó mucho entusiasmo en mi trabajo,
    saber que cuento con esta herramienta que fluye con toda mi manera de
    actuar. Las charlas con el equipo fueron súper importantes para poder
    dar a entender lo que quería transmitir con mi Klouser. Aprendí a
    saber "vender" lo que amo hacer. Recomiendo Klouser sobre todo a
    aquellas personas que son emprendedoras y quieren llevar su
    creatividad a un nuevo nivel`,
      name: "Relatos Mágicos",
      category: "Escritura Curativa",
      picture: avatarOne,
    },
    {
      text: `Gracias a Klouser pude lograr ordenarme, organizarme y “tener mi
      lugar” desde mi casa. He recomendando Klouser a muchos colegas porque
      es una gran oportunidad tanto para el profesional, para poder
      brindarse, como para la gente de poder “pertenecer” a un grupo que más
      allá de la temática, siempre implica contención. Me parece una forma
      de reinventarnos y estar más cerca`,
      name: "Anna Fedullo",
      category: "Experta en desarrollo personal | Meditación & Mindfulness",
      picture: avatarTwo,
    },
    {
      text: `Klouser fue para mi una propuesta de vanguardia, me sacó un poco del
      “closet”, para mostrarme una nueva dinámica con una proyección
      expansiva. En Klouser encontré un espacio de creatividad que motiva y
      estimula el crecimiento. Un espacio que me genera disponibilidad de
      tiempo y me asegura sustentabilidad. Pude crear una comunidad con
      "todas las letras", de respeto, responsabilidad y cuidado mutuo`,
      name: "Claudia Luchetti",
      category: "Experta en desarrollo personal | Meditación & Mindfulness",
      picture: avatarThree,
    },
  ],
};

export default home;
