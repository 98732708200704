export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

export interface CurrentPage {
  name: string;
  regex: RegExp | null;
}

export interface CurrentPageData {
  name: string;
  regex: RegExp | null;
}

export interface SetCurrentPage {
  type: typeof SET_CURRENT_PAGE;
  payload: CurrentPageData;
}

export interface State {
  name: string;
  regex: RegExp | null;
}

export const initialState: State = {
  name: "",
  regex: null,
};

export type Actions = SetCurrentPage;
