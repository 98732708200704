import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Transition } from "react-transition-group";
import ReactDOM from "react-dom";
import { hideModal } from "../../../store/actions/modal";
import Checkbox from "../../../components/FormikForm/Checkbox";
import Input from "../../../components/FormikForm/Input";
import { Formik } from "formik";
import {
  schema,
  initialValues,
} from "../../../constants/forms/ask-for-user-information";

import {
  Modal,
  ModalDialog,
  ModalContent,
  ModalBody,
  Title,
  SubTitle,
  ImageWrapper,
  Image,
  FormWrapper,
  InlineForm,
  ModalAnimation,
  Submit,
  Close
} from "./styles";
import {
  subscribeToTheCommunity,
  resetSubscription,
} from "../../../store/actions/community";
import { Center, Separator } from "../../../components/StyledComponents";
import { getFreeSubscriptions } from "../../../helpers/getFreeSubscriptions";
import { isSubscribed } from "../../../helpers/isSubscribed";
import closeIcon from "../../../assets/icons/close.svg";
import { User } from "../../../types";

export interface ModalProps {
  showClose?: boolean
  animation?: string;
  width?: number;
  title?: string;
  onClose?: () => void;
  visibleModal?: string | null;
  ctaText?: string | "Suscribirme";
  avatar;
  group: string;
  username: string;
  idGroup: number;
  subscriptionStates?: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  handleSubmit?: ({ email, name, idGroup }: {
    email: string,
    name: string,
    idGroup: number
  }) => void
  isLoggedIn?: boolean;
  user?: User;
}

export const Component = ({
  showClose = false,
  animation = "fadeIn",
  width,
  title,
  onClose,
  visibleModal,
  ctaText,
  avatar,
  group,
  username,
  idGroup,
  subscriptionStates,
  isLoggedIn,
  handleSubmit,
  user
}: ModalProps) => {
  const dispatch = useDispatch();
  const modalDOM = document.getElementById("modal") as HTMLElement;
  const [formSubmmited, setFormSubmmited] = useState<boolean>(false);
  const [subscriberEmail, setSubscriberEmail] = useState<string>("");
  const [subscriberName, setSubscriberName] = useState<string>("");
  const [isVisible, setIsVisible] = useState(false);

  const onFormSubmit = ({ values, actions }) => {
    localStorage.setItem("KLOUSER_SUBSCRIBER_EMAIL", values.email);
    localStorage.setItem("KLOUSER_SUBSCRIBER_NAME", values.name);

    handleSubmit && handleSubmit({ ...values, idGroup })
  };

  const handleClose = () => {
    dispatch(hideModal());
    setIsVisible(false);

    if (onClose) {
      onClose();
    }
  };

  const setBodyStyles = () => {
    if (isVisible && !isSubscribed([], idGroup)) {
      document.body.style.height = "100%";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    if (subscriptionStates?.success) {
      setIsVisible(false);
      dispatch(resetSubscription());
    }
  }, [subscriptionStates]);

  useEffect(() => {
    setIsVisible("ask-for-user-information" === visibleModal);
  }, [visibleModal]);

  useEffect(() => {
    setBodyStyles();
  }, [isVisible]);

  useEffect(() => {
    if (!isLoggedIn) {
      setSubscriberEmail(localStorage.getItem("KLOUSER_SUBSCRIBER_EMAIL") || "");
      setSubscriberName(localStorage.getItem("KLOUSER_SUBSCRIBER_NAME") || "");
    } else {
      setSubscriberEmail(user?.email || "");
      setSubscriberName(user?.name && user?.last_name ? `${user?.name} ${user?.last_name}` : "");
    }
  }, [isLoggedIn, user]);

  return ReactDOM.createPortal(
    <Transition
      in={isVisible && !isSubscribed([], idGroup)}
      timeout={350}
      mountOnEnter
    >
      {(state) => {
        return (
          <>
            <ModalAnimation name={animation} className={`animation-${state}`}>
              <Modal>
                <ModalDialog width={width}>
                  <ModalContent>
                    {showClose && (
                      <Close onClick={() => handleClose()}>
                        <img
                          src={closeIcon}
                          alt="X"
                          width="16px"
                          height="16px"
                        />
                      </Close>
                    )}
                    <ModalBody>
                      <ImageWrapper>
                        <Image src={avatar} />
                      </ImageWrapper>

                      <FormWrapper>
                        <Title>{title}</Title>

                        <Separator height={{ desktop: "10px", mobile: "10px" }} />

                        <SubTitle>
                          Completa los datos para continuar
                        </SubTitle>

                        <Formik
                          initialValues={{
                            ...initialValues,
                            email: subscriberEmail,
                            name: subscriberName
                          }}
                          onSubmit={(values, actions) => {
                            if (onFormSubmit) onFormSubmit({ values, actions });
                          }}
                          validateOnChange={formSubmmited}
                          validateOnBlur={formSubmmited}
                          validationSchema={schema}
                          enableReinitialize
                        >
                          {({
                            touched,
                            errors,
                            values,
                            handleChange,
                            isSubmitting,
                            handleBlur,
                            handleReset,
                            handleSubmit,
                            setFieldValue
                          }) => {
                            return (
                              <form
                                className="theme-form"
                                onSubmit={(event) => {
                                  setFormSubmmited(true);
                                  handleSubmit(event);
                                }}
                                id="subscribe-to-free-content"
                              >
                                <InlineForm>
                                  <div>
                                    <Input
                                      touched={touched["name"]}
                                      error={errors["name"]}
                                      type="text"
                                      name="name"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      placeholder="Ingresa tu primer nombre"
                                      options={{
                                        marginBottom: 20,
                                      }}
                                      value={values["name"]}
                                    />


                                    <Input
                                      touched={touched["email"]}
                                      error={errors["email"]}
                                      type="text"
                                      name="email"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      placeholder="Ingresa tu email"
                                      options={{
                                        marginBottom: 20,
                                      }}
                                      value={values["email"]}
                                    />

                                    <Checkbox
                                      touched={touched["terminosCondiciones"]}
                                      error={errors["terminosCondiciones"]}
                                      name="terminosCondiciones"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values["terminosCondiciones"]}
                                      items={[
                                        {
                                          label:
                                            `Acepto recibir via correo electrónico contenido exclusivo, recursos útiles y consejos prácticos`,
                                          value: "true",
                                        },
                                      ]}
                                      options={{
                                        marginBottom: 24,
                                      }}
                                    />
                                  </div>

                                  <Submit
                                    isSubmmiting={!!subscriptionStates?.loading}
                                    form="subscribe-to-free-content"
                                  >
                                    {ctaText || "Suscribirme"}
                                  </Submit>

                                </InlineForm>
                              </form>
                            );
                          }}
                        </Formik>
                      </FormWrapper>
                    </ModalBody>
                  </ModalContent>
                </ModalDialog>
              </Modal>
            </ModalAnimation>
          </>
        );
      }}
    </Transition>,
    modalDOM
  );
};

const state = ({ modalStore, communityStore, userStore }) => {
  const { isLoggedIn } = userStore
  const { data: user } = userStore.information;
  const { states: subscriptionStates } = communityStore.subscription;

  return { ...modalStore, subscriptionStates, isLoggedIn, user };
};

export default connect(state)(Component);
