import {Formik, FormikHelpers} from "formik";
import {useEffect, useMemo, useState} from "react";
import { connect, useDispatch } from "react-redux";
import dateHandler from '../../../../../../helpers/dates-hanlder'
import { schema, initialValues } from '../../../../../../constants/forms/promotion/edit'
import Sidebar from "../../../../../../components/Sidebar";
import {
  Preview,
  Title,
  Text,
  Option,
  Label,
  StrongLabel,
  StrongValue,
} from "../../styles";
import Form from "../components/EditPromotionForm"
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/FormikForm/Submit";
import Grid from "../../../../../../components/Grid";
import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import { DiscountDto, Group, PlanDto } from "../../../../../../types";
import { updateDiscount } from '../../../../../../store/actions/discount'
import { Center } from "../../../../../../components/StyledComponents";
import { setToZeroTimeZone } from "../../../../../../helpers/dates-hanlder";


interface ComponentProps {
  isVisible: boolean
  onClose
  group: Group
  // updatedDiscount,
  updatedDiscountStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  selectedDiscount: DiscountDto
  selectedDiscountStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  selectedPlan: PlanDto
  selectedPlanStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

const Component = ({
  isVisible,
  onClose,
  group,
  // updatedDiscount,
  updatedDiscountStates,
  selectedPlan,
  selectedPlanStates,
  selectedDiscount,
  selectedDiscountStates
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [actions, setActions] = useState<FormikHelpers<any>>();


  const onSubmit = ({ values, actions }) => {
    dispatch(
      updateDiscount({
        id: selectedDiscount.id,
        group_id: group?.id,
        amount: selectedDiscount.amount,
        valid_till: setToZeroTimeZone(values.valid_till),
        status: values['status'],
      })
    );

    setActions(actions)
  };

/*  useEffect(() => {
    if (updatedDiscountStates.success && actions) {
      actions.resetForm();
    }
  }, [updatedDiscountStates, actions])
*/
  const paymentText = useMemo(() => {
    if (selectedPlan?.type === "subscription") {
      const numeroCobros = selectedPlan?.period && selectedDiscount?.duration_type !== "forever" && selectedDiscount?.period / selectedPlan?.period;
      const cobroText = numeroCobros && numeroCobros > 1 ? `en los primeros ${numeroCobros} cobros` : `en el primer cobro`
      const durationType = selectedDiscount?.duration_type === "forever" ? "de forma indefinida" : cobroText
      return `Se aplicará ${durationType}.`
    } else {
      return ""
    }
  }, [selectedPlan, selectedDiscount])

  return (
    <>
      <Sidebar
        position="right"
        visible={isVisible}
        closeType="back"
        onClose={() => {
          !!onClose && onClose();
        }}
        width="520px"
        title="Editar descuento"
      >
        <Preview>
          <div>
            <Option>
              <StrongLabel>Precio del plan</StrongLabel>
              <StrongValue>{`${selectedPlan?.currency} ${selectedPlan?.price}`}</StrongValue>
            </Option>

            <Option>
              <StrongLabel>Precio promocional</StrongLabel>
              <StrongValue>{`${selectedPlan?.currency} ${selectedPlan?.price - selectedDiscount?.amount}`}</StrongValue>
            </Option>

            {selectedPlan?.type === "subscription" && (
              <Option>
                <StrongLabel>Duración del descuento</StrongLabel>
                <StrongValue>{paymentText}</StrongValue>
              </Option>
            )}

            {!selectedDiscount?.valid_till && (
              <Option>
                <StrongLabel>Descuento válido hasta</StrongLabel>
                <StrongValue>De forma indefinida</StrongValue>
              </Option>
            )}

            <Option>
              <Formik
                initialValues={{
                  status: selectedDiscount?.status,
                  valid_till: selectedDiscount?.valid_till ? dateHandler.inputDateTimeLocal(selectedDiscount?.valid_till) : null
                }}
                onSubmit={(values, actions) => {
                  if (onSubmit) onSubmit({ values, actions });
                }}
                validateOnChange={formSubmmited}
                validateOnBlur={false}
                validationSchema={schema}
                enableReinitialize
              >
                {(props) => {
                  return (
                    <form
                      className="theme-form"
                      onSubmit={(event) => {
                        setFormSubmmited(true);
                        props.handleSubmit(event);
                      }}
                      id="edit-discount-form"
                    >
                      <Form {...props} isLoading={updatedDiscountStates.loading} />
                    </form>
                  );
                }}
              </Formik>
            </Option>
          </div>

          <Center horizontal="center" vertical="center" gap={10}>
            <Button
              options={{
                type: "outline",
                skin: "purple",
                size: "lg",
                block: true
              }}
              onClick={() => !!onClose && onClose()}
            >
              Cancelar
            </Button>

            <Submit
              isSubmmiting={updatedDiscountStates.loading}
              disabled={updatedDiscountStates.loading}
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
                block: true
              }}
              form="edit-discount-form"
            >
              Editar descuento
            </Submit>
          </Center>
        </Preview>
      </Sidebar>
    </>
  )
}


const state = ({ groupStore, discountStore, planStore }) => {
  const { data: group } = groupStore.group;
  const { data: updatedDiscount, states: updatedDiscountStates } = discountStore.updateDiscount;
  const { data: selectedDiscount, states: selectedDiscountStates } = discountStore.selectedDiscount;
  const { data: selectedPlan, states: selectedPlanStates } = planStore.selectedPlan;

  return {
    group,
    //updatedDiscount,
    updatedDiscountStates,
    selectedPlan,
    selectedPlanStates,
    selectedDiscount,
    selectedDiscountStates,
  };
};

export default connect(state)(Component);
