import { DiscountDto, UpdateDiscountOutDto } from "../../../types";

export const UPDATE_DISCOUNT = "UPDATE_DISCOUNT";
export const UPDATE_DISCOUNT_SUCCESS = "UPDATE_DISCOUNT_SUCCESS";
export const UPDATE_DISCOUNT_ERROR = "UPDATE_DISCOUNT_ERROR";
export const RESET_UPDATE_DISCOUNT = "RESET_UPDATE_DISCOUNT";

export interface UpdateDiscount {
  type: typeof UPDATE_DISCOUNT;
  payload: UpdateDiscountOutDto;
}

export interface UpdateDiscountSuccess {
  type: typeof UPDATE_DISCOUNT_SUCCESS;
  payload: DiscountDto;
}

export interface UpdateDiscountError {
  type: typeof UPDATE_DISCOUNT_ERROR;
  payload: string | boolean;
}

export interface ResetUpdateDiscount {
  type: typeof RESET_UPDATE_DISCOUNT;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | UpdateDiscount
  | UpdateDiscountSuccess
  | UpdateDiscountError
  | ResetUpdateDiscount;
