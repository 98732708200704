import styled from "styled-components";
import ImageUi from "../../../../components/Image";

export const Partners = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  max-width: 100%;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin-bottom: 150px;
    flex-direction: row;
  }
`;

export const Image = styled(ImageUi)`
  max-width: 60%;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin-right: 30px;
    margin-bottom: 0px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;
