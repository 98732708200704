import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { connect, useDispatch } from "react-redux";
import {
  ModuleInDto,
  ModuleDto,
  UpdateOneModuleOutDto,
  GetAllModulesOutDto,
  GetOneModuleOutDto,
  NewModuleOutDto,
  DeleteOneModuleOutDto,
  ReorderModulesOutDto,
  RestoreModulesOutDto,
} from "../../types/module";
import { DropResult } from "react-beautiful-dnd";
import sendIcon from "../../assets/icons/send.svg";
import inboxIcon from "../../assets/icons/inbox.svg";
import documentIcon from "../../assets/icons/document.svg";
import bookIcon from "../../assets/icons/book.svg";
import {
  deleteModule,
  getAllModules,
  newModule,
  reorderModules,
  resetDeleteModule,
  resetNewModule,
  updateModule,
  resetUpdateModule,
  publishModules,
  resetRestoreModules,
  restoreModules
} from "../../store/actions/module";
import Snackbar from "../../components/Snackbar";

const modulesIcons = {
  post: documentIcon,
  note: bookIcon,
  survey: inboxIcon,
};

interface States {
  success: boolean;
  error: string | boolean;
  loading: boolean;
}

interface ContexInitialValuesDto {
  moduleToBeDeleted?: number;
  setModuleToBeDeleted: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  selectedModule?: ModuleInDto | undefined;
  setSelectedModule: React.Dispatch<
    React.SetStateAction<ModuleInDto | undefined>
  >;
  reorderOn: boolean;
  setReorderOn: React.Dispatch<React.SetStateAction<boolean>>;
  modules?: ModuleInDto[];
  actions: {
    create: {
      exec: () => void;
      states: States;
    };
    update: {
      exec: (payload: UpdateOneModuleOutDto) => void;
      states: States;
    };
    getOne: {
      exec: (payload: GetOneModuleOutDto) => void;
      states: States;
    };
    getAll: {
      exec: (payload: GetAllModulesOutDto) => void;
      states: States;
    };
    deleteOne: {
      exec: (payload: DeleteOneModuleOutDto) => void;
      states: States;
    };
    reorder: {
      exec: (result: DropResult) => void;
      states: States;
    };
    restore: {
      exec: (result: RestoreModulesOutDto) => void;
      states: States;
    };
    publish: {
      exec: (payload: { group: number }) => void;
      states: States;
    }
  };
}

const contexInitialValues: ContexInitialValuesDto = {
  moduleToBeDeleted: undefined,
  setModuleToBeDeleted: () => { },
  reorderOn: false,
  setReorderOn: () => { },
  setSelectedModule: () => { },
  actions: {
    create: {
      exec: () => { },
      states: { success: false, error: false, loading: false },
    },
    update: {
      exec: () => { },
      states: { success: false, error: false, loading: false },
    },
    getOne: {
      exec: () => { },
      states: { success: false, error: false, loading: false },
    },
    getAll: {
      exec: () => { },
      states: { success: false, error: false, loading: false },
    },
    deleteOne: {
      exec: () => { },
      states: { success: false, error: false, loading: false },
    },
    reorder: {
      exec: () => { },
      states: { success: false, error: false, loading: false },
    },
    restore: {
      exec: () => { },
      states: { success: false, error: false, loading: false },
    },
    publish: {
      exec: () => { },
      states: { success: false, error: false, loading: false },
    }
  },
};

export const ModulesProviderContext = React.createContext(contexInitialValues);

export const useModules = () => useContext(ModulesProviderContext);

const ModulesProvider = ({
  children,
  useLocation,
  allModules,
  allModulesStates,
  newModuleStates,
  updateModuleStates,
  deleteModuleStates,
  oneModuleStates,
  reorderModulesStates,
  restoreModulesStates,
  publishChangesStates,
  group,
}) => {
  const dispatch = useDispatch();
  const [moduleToBeDeleted, setModuleToBeDeleted] = useState<number>();
  const [modules, setModules] = useState<ModuleInDto[]>([]);
  const [reorderOn, setReorderOn] = useState<boolean>(false);
  const [selectedModule, setSelectedModule] = useState<ModuleInDto>();

  const createOne = () => {
    dispatch(
      newModule({
        title: "",
        content_type: "post",
        state: "active",
        is_valid: false,
        order: modules?.length + 1,
        group: group.id,
        timer: {},
        content_data: {},
      })
    );
  };

  const updateOne = (payload: UpdateOneModuleOutDto) => {
    dispatch(updateModule(payload));
  };

  const getOne = (payload: GetOneModuleOutDto) => { };

  const getAll = (payload: GetAllModulesOutDto) => {
    dispatch(getAllModules(payload));
  };

  const deleteOne = (payload: DeleteOneModuleOutDto) => {
    dispatch(deleteModule(payload));
  };

  const reorder = (payload: ReorderModulesOutDto) => {
    dispatch(reorderModules(payload));
  };

  const restore = (payload: RestoreModulesOutDto) => {
    dispatch(restoreModules(payload))
  };

  const publishChanges = (payload: { group: number }) => {
    dispatch(publishModules(payload))
  }

  const onDragEnd = (payload: DropResult) => {
    const { source, destination } = payload;

    if (!destination) {
      return;
    }

    const result: ModuleInDto[] = Array.from(allModules);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);

    const newOrder = result.map((item: ModuleInDto, index) => {
      return {
        ...item,
        order: index,
      };
    });

    reorder(newOrder);
    setModules(newOrder);
  };

  useEffect(() => {
    if (newModuleStates.success && !!modules) {
      setSelectedModule(modules[modules.length - 1]);
    }
  }, [modules, newModuleStates])

  useEffect(() => {
    if (updateModuleStates.success && !!modules) {
      let current: number = modules?.findIndex(module => {
        return module.id === selectedModule?.id
      })
      setSelectedModule(modules[current]);
    }
  }, [modules, updateModuleStates])

  useEffect(() => {
    const orderedModules = allModules
      ?.sort(function (a, b) {
        return a.order - b.order;
      })
      .map((item) => ({
        ...item,
        icon: modulesIcons[item?.content_type],
      }));
    setModules(orderedModules || []);
  }, [allModules]);

  useEffect(() => {
    if (group) {
      if (!!selectedModule && selectedModule?.group?.id !== group?.id) {
        setSelectedModule(undefined)
      }

      getAll({ idGroup: group?.id, version: 'draft' });
    }
  }, [group]);

  useEffect(() => {
    if (restoreModulesStates.success && !!modules) {
      setSelectedModule(modules[0]);
    }
  }, [modules, restoreModulesStates])

  useEffect(() => {
    if (deleteModuleStates.success && !!modules) {
      setSelectedModule(modules[0]);
    }
  }, [modules, deleteModuleStates])

  useEffect(() => {
    if (modules && !selectedModule) {
      setSelectedModule(modules[0]);
    }
  }, [modules]);

  return (
    <ModulesProviderContext.Provider
      value={{
        setModuleToBeDeleted,
        moduleToBeDeleted,
        selectedModule,
        setSelectedModule,
        reorderOn,
        setReorderOn,
        modules,
        actions: {
          create: {
            exec: createOne,
            states: newModuleStates,
          },
          update: {
            exec: updateOne,
            states: updateModuleStates,
          },
          getOne: {
            exec: getOne,
            states: oneModuleStates,
          },
          getAll: {
            exec: getAll,
            states: allModulesStates,
          },
          deleteOne: {
            exec: deleteOne,
            states: deleteModuleStates,
          },
          reorder: {
            exec: onDragEnd,
            states: reorderModulesStates,
          },
          restore: {
            exec: restore,
            states: restoreModulesStates,
          },
          publish: {
            exec: publishChanges,
            states: publishChangesStates
          }
        },
      }}
    >
      <>
        {children}

        <Snackbar
          visible={publishChangesStates.success || !!publishChangesStates.error}
          options={{
            time: 2000,
            type: publishChangesStates.success ? "success" : "error",
          }}
        >
          {publishChangesStates.success
            ? "Los cambios se publicaron con exito."
            : "Error al publicar los cambios."}
        </Snackbar>

        <Snackbar
          visible={restoreModulesStates.success || !!restoreModulesStates.error}
          options={{
            type: restoreModulesStates.success ? "success" : "error",
            position: "center",
          }}
          onHide={() => {
            dispatch(resetRestoreModules());
          }}
        >
          {restoreModulesStates.success
            ? "Modulos restaurados correctamente."
            : `Error al restaurar los modulos.`}
        </Snackbar>

        <Snackbar
          visible={updateModuleStates.success}
          options={{
            type: "success",
            position: "center",
            time: 1500
          }}
          onHide={() => {
            dispatch(resetUpdateModule());
          }}
        >
          Los cambios se guardaron correctamente.
        </Snackbar>

        <Snackbar
          visible={!!updateModuleStates.error}
          options={{
            type: "error",
            position: "center",
            time: 1500
          }}
          onHide={() => {
            dispatch(resetUpdateModule());
          }}
        >
          Error al guardar los cambios.
        </Snackbar>

        <Snackbar
          visible={newModuleStates.success || !!newModuleStates.error}
          options={{
            type: newModuleStates.success ? "success" : "error",
            position: "center",
          }}
          onHide={() => {
            dispatch(resetNewModule());
          }}
        >
          {newModuleStates.success
            ? "Módulo agregado correctamente."
            : `Error al agregar nuevo módulo.`}
        </Snackbar>

      </>
    </ModulesProviderContext.Provider>
  );
};

const state = ({ groupStore, modulesStore }) => {
  const { data: group } = groupStore.group;
  const { data: allModules, states: allModulesStates } =
    modulesStore.allModules;
  const { data: oneModule, states: oneModuleStates } =
    modulesStore.selectedModule;
  const { data: updateModule, states: updateModuleStates } =
    modulesStore.updateModule;
  const { data: deleteModule, states: deleteModuleStates } =
    modulesStore.deleteModule;
  const { data: newModule, states: newModuleStates } = modulesStore.newModule;
  const { data: reorderModules, states: reorderModulesStates } =
    modulesStore.reorderModules;
  const { data: publishChanges, states: publishChangesStates } =
    modulesStore.publishModules;
  const { states: restoreModulesStates } = modulesStore.restoreModules


  return {
    group,
    allModules,
    allModulesStates,
    //oneModule,
    oneModuleStates,
    //updateModule,
    updateModuleStates,
    //deleteModule,
    deleteModuleStates,
    //newModule,
    newModuleStates,
    //reorderModules,
    reorderModulesStates,
    //publishChanges,
    publishChangesStates,
    restoreModulesStates
  };
};

export default connect(state)(ModulesProvider);
