import styled from "styled-components";
import UiForm from "../../../components/Form";
import { Link as UiLink } from "react-router-dom";

export const Form = styled(UiForm)`
  align-items: flex-start;
  max-width: 451px;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  min-height: 24px;
  margin: 0px;
  max-width: 740px;

  @media (min-width: 768px) {
    line-height: 40px;
    min-height: 40px;
    margin: 0;
  }

  @media (min-width: 1024px) {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    min-height: 44px;
    margin: 0px;

    br {
      display: none;
    }
  }
`;

export const StepNumber = styled.div`
  margin-right: 5px;
`;

export const Text = styled.div`
  display: flex;
`;

export const Label = styled("div") <{ ref?; for?}>`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`;

export const Page = styled("div")`
  position: relative;
  min-height: 100vh;
`;

export const PageHeader = styled("div")`
  position: relative;
  display: flex;
  justify-content: space-between;

  margin: 0px;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 768px) {
    align-items: center;
    flex-direction: row;
  }
`;

export const Tabs = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

export const Tab = styled("div")`
  flex-shrink: 0;
`;

export const Link = styled(UiLink) <{ active?: boolean }>`
  display: block;
  position: relative;
  text-decoration: none;
  color: ${(props) =>
    props.active ? props.theme.colors.Primary : props.theme.colors.Black75};
  font-weight: ${(props) => (props.active ? "600" : "500")};
  font-size: 14px;
  line-height: 17px;
  padding: 0px 0px 4px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 18px;
    line-height: 22px;
  }

  &:after {
    content: "";
    display: ${(props) => (props.active ? "block" : "none")};
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 2px;
    background-color: ${(props) => props.theme.colors.Primary};
  }
`;

const verticalPosition = {
  top: "flex-start",
  bottom: "flex-end",
  center: "center",
};

const horizontalPosition = {
  left: "flex-start",
  right: "flex-end",
  center: "center",
  space: "space-between",
};

export const Center = styled("div") <{
  vertical: "top" | "center" | "bottom";
  horizontal: "left" | "center" | "right" | "space";
  gap?: number;
  column?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  justify-content: ${({ horizontal }) => horizontalPosition[horizontal]};
  align-items: ${({ vertical }) => verticalPosition[vertical]};
  gap: ${({ gap }) => (gap ? gap : 0)}px;
`;

export const HorizontalScroll = styled("div")`
  overflow-x: scroll;
  max-width: 100%;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Actions = styled("div")`
  display: grid;
  gap: 16px;
  grid-auto-rows: 132px;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
`;

export const NewFeatureLabel = styled.span`
background-color: rgb(108, 92, 231);
color: white;
font-size: 0.5em;
margin-top: 2px;
padding: 2px 4px;
margin-left: 5px;
border-radius: 3px;
text-align: center;
`;