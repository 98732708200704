import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import Submit from "../../../../../components/FormikForm/Submit";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import remove from "../../../../../assets/remove.svg";
import Image from "../../../../../components/Image";
import { SessionInDto } from "../../../../../types";
import {
  deleteSession,
  resetDeleteOneSession,
} from "../../../../../store/actions/session";
import { useState } from "react";
import { Formik } from "formik";
import Checkbox from "../../../../../components/FormikForm/Checkbox";
import TextArea from "../../../../../components/FormikForm/TextArea";
import Icon from "../../../../../components/Icon";
import trashIcon from "../../../../../assets/icons/trash.svg";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useTheme } from "styled-components";
import RichTextEditor from "../../../../../components/FormikForm/RichTextEditor";
import { isFutureDate } from "../../../../../helpers/dates-hanlder";
// import { useHistory } from "@remirror/react";

export const schema = Yup.object().shape({
  notify_action: Yup.array().of(Yup.string()).nullable(),
  action_notification_message: Yup.string().nullable(),
});

interface FormValues {
  notify_action: string[];
  action_notification_message: string
}

export const initialValues: FormValues = {
  notify_action: ["false"],
  action_notification_message: ""
};

interface ComponentProps {
  session: SessionInDto;
  deleteSessionState: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ session, deleteSessionState }: ComponentProps) => {
  const theme = useTheme() as { colors: { [key: string]: string } }
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const history = useHistory();

  const onSubmit = ({ values, actions }) => {
    dispatch(deleteSession({ idSession: session?.id, ...values, notify_action: values.notify_action.includes("true") }));
  }

  const onModalClose = () => {
    if (deleteSessionState.success) {
      dispatch(resetDeleteOneSession());

      setTimeout(() => {
        hideModal();
        history.push(`/dashboard/${session?.group?.group_type}/${session?.group?.id}/sessions/`)        
        // window.history.back();
      }, 500);
    }
  };

  return (
    <Modal
      name="modal-delete-session"
      width="780"
      onClose={() => onModalClose()}
    >
      {deleteSessionState.success ? (
        <>
          <div style={{ textAlign: "center" }}>
            <Image src={remove} width="102px" height="117px" alt={remove} />
          </div>
          <Text>
            <strong style={{ textAlign: "center", display: "block" }}>
              Encuentro eliminado satisfactoriamente
            </strong>
          </Text>
        </>
      ) : (
        <>
          <Title>Vas a eliminar un encuentro</Title>

          <Text>
            Estás por eliminar el encuentro “{session?.title}”. Una vez eliminado
            no podrás recuperarlo y quienes tenían acceso al mismo ya no
            podrán verlo.
          </Text>

          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              if (onSubmit) onSubmit({ values, actions });
            }}
            validateOnChange={false}
            validateOnBlur={formSubmmited}
            validationSchema={schema}
            enableReinitialize
          >
            {({ handleSubmit, errors, touched, values, setFieldValue, setFieldError, setFieldTouched, handleChange, handleBlur, }) => {
              return (
                <form
                  className="theme-form"
                  onSubmit={(event) => {
                    setFormSubmmited(true);
                    handleSubmit(event);
                  }}
                  id="delete-session-form"
                >
                  {isFutureDate(session?.session_datetime) && (
                    <>
                      <Checkbox
                        name="notify_action"
                        error={errors["notify_action"]}
                        touched={touched["notify_action"]}
                        value={values["notify_action"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        items={[
                          { label: "Enviar una notificación a tus suscriptores.", value: "true" },
                        ]}
                        options={{
                          marginBottom: 0,
                        }}
                      />

                      <div style={{ height: 20 }} />

                      <span>Agrega un mensaje personalizado explicando el motivo de la cancelación (opcional)</span>

                      <div style={{ height: 12 }} />

                      <RichTextEditor
                        name={"action_notification_message"}
                        error={errors[`action_notification_message`]}
                        touched={touched[`action_notification_message`]}
                        value={values[`action_notification_message`]}
                        placeholder="Ingresa tu mensaje aquí"
                        showToolbar={false}
                        max={500}
                        onBlur={handleBlur}
                        onChange={({ isCountValid }) => {
                          if (!isCountValid) {
                            setFieldError(
                              "action_notification_message",
                              "Superaste la cantidad de caracteres máximos permitidos"
                            );
                          } else {
                            setFieldTouched("action_notification_message", false, false);
                          }
                        }}
                        options={{
                          label: "",
                          marginBottom: 20,
                        }}
                      />
                    </>

                  )}

                </form>
              );
            }}
          </Formik>
          <div style={{ height: 32 }} />

          <AlignEnd>
            <Button
              options={{
                type: "outline",
                skin: "purple",
                size: "lg",
              }}
              style={{ marginRight: "16px" }}
              onClick={() => dispatch(hideModal())}
            >
              Cancelar
            </Button>

            <Submit
              form="delete-session-form"
              isSubmmiting={deleteSessionState.loading}
              options={{
                type: "filled",
                skin: "purple",
                size: "lg",
              }}
              style={{ gap: 6 }}
            >
              <Icon icon={trashIcon} size="18px" color="#FFFFFF" />
              Eliminar encuentro
            </Submit>
          </AlignEnd>
        </>
      )}
    </Modal>
  );
};

const state = ({ sessionStore }) => {
  const { data: session, states: sessionStates } = sessionStore.one;
  const { states: deleteSessionState } = sessionStore.deleteOne;

  return {
    session,
    sessionStates,
    deleteSessionState,
  };
};

export default connect(state)(Component);
