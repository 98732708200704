import { useReducer, useEffect, useState } from "react";

const getStateClasses = (fieldStates, fieldName) => {
  const loadingClass = fieldStates.isLoading ? `field-loading` : ``;
  const activeClass = fieldStates.isActive ? `field-active` : `field-inactive`;
  const errorClass = fieldStates.withError
    ? `with-error ${fieldName}-with-error`
    : `without-error ${fieldName}-without-error`;
  const contentClass = fieldStates.withContent
    ? "with-content"
    : "without-content";
  const disabledClass = fieldStates.isDisabled
    ? "field-disabled"
    : "field-enabled";

  return `${contentClass} ${activeClass} ${loadingClass} ${disabledClass} ${errorClass}`;
};

const changeState = (states, name, errors, typeValidation, dataValidation) => {
  switch (typeValidation) {
    case "value":
      states.withContent = dataValidation && dataValidation.value;
      break;
    case "active":
      states.isActive = dataValidation;
      break;
    case "loading":
      states.isLoading = dataValidation;
      break;
    case "disabled":
      states.isDisabled = dataValidation;
      break;
    default:
      break;
  }

  states.withError = Boolean(errors && errors[name]);

  return states;
};

interface States {
  withError: boolean;
  withContent: boolean;
  isActive: boolean;
  isDisabled: boolean;
  isLoading: boolean;
}

interface FieldValidationState {
  classes: string;
  fieldStates: States;
  errors: any;
  value: string | number;
  name: string;
}

const initialFieldValidationSate: FieldValidationState = {
  classes: "",
  fieldStates: {
    withError: false,
    withContent: false,
    isActive: false,
    isDisabled: false,
    isLoading: false,
  },
  errors: {},
  value: "",
  name: "",
};

interface SetFieldErrorStateAction {
  type: "state-error";
}
interface SetFieldActiveAction {
  type: "state-active";
  state: boolean;
}

interface SetFieldDisabledAction {
  type: "state-disabled";
  state: boolean;
}

interface SetValidationsErrorsAction {
  type: "errors";
  validationErrors: {};
}

interface SetFieldValueAction {
  type: "value";
  fieldValue: string | number;
}

interface SetFieldNameAction {
  type: "name";
  fieldName: string;
}

interface SetClassAction {
  type: "get-classes";
  states: States;
  name: string;
}

type FieldValidationAction =
  | SetFieldErrorStateAction
  | SetFieldActiveAction
  | SetFieldDisabledAction
  | SetValidationsErrorsAction
  | SetFieldValueAction
  | SetFieldNameAction
  | SetClassAction;

interface Error {
  type: string;
  message: string;
  ref: any;
}

const FieldValidationReducer = (
  state: FieldValidationState,
  action: FieldValidationAction
): FieldValidationState => {
  switch (action.type) {
    case "state-error":
      return {
        ...state,
        fieldStates: {
          ...changeState(
            state.fieldStates,
            state.name,
            state.errors,
            null,
            null
          ),
        },
      };
    case "errors":
      return {
        ...state,
        errors: action.validationErrors,
        fieldStates: {
          ...changeState(
            state.fieldStates,
            state.name,
            state.errors,
            null,
            null
          ),
        },
      };
    case "name":
      return {
        ...state,
        name: action.fieldName,
      };
    case "value":
      return {
        ...state,
        value: action.fieldValue,
        fieldStates: {
          ...changeState(
            state.fieldStates,
            state.name,
            state.errors,
            "value",
            action.fieldValue
          ),
        },
      };
    case "state-active":
      return {
        ...state,
        fieldStates: {
          ...changeState(
            state.fieldStates,
            state.name,
            state.errors,
            "active",
            action.state
          ),
        },
      };
    case "state-disabled":
      return {
        ...state,
        fieldStates: {
          ...changeState(
            state.fieldStates,
            state.name,
            state.errors,
            "disabled",
            action.state
          ),
        },
      };
    case "get-classes":
      return {
        ...state,
        classes: getStateClasses(action.states, action.name),
      };
    default:
      return state;
  }
};

export const useFieldValidation = (
  errors,
  name,
  disabled
): FieldValidationState & { setValue: Function; setActive: Function } => {
  const [state, dispatch] = useReducer(
    FieldValidationReducer,
    initialFieldValidationSate
  );

  const size = function (obj) {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  useEffect(() => {
    dispatch({ type: "name", fieldName: name });
    dispatch({ type: "state-disabled", state: disabled });
  }, [name, disabled]);

  useEffect(() => {
    const newErrors = {};

    if (!!errors) {
      Object.keys(errors).forEach((error) => {
        const keys = ["ref", "type", "message"];

        Object.keys(errors[error]).forEach((key, index) => {
          if (!keys.includes(key)) {
            const keyName = `${error}[${key}]`;
            newErrors[keyName] = errors[error][key];
          } else {
            newErrors[error] = errors[error];
          }
        });
      });
    }

    dispatch({ type: "errors", validationErrors: newErrors });
  }, [errors]);

  useEffect(() => {
    dispatch({ type: "get-classes", states: state.fieldStates, name });
  }, [state.fieldStates]);

  useEffect(() => {
    dispatch({ type: "state-error" });
  }, [state.errors]);

  const setValue = (value) => {
    dispatch({ type: "value", fieldValue: value });
  };

  const setActive = (a: boolean) => {
    dispatch({ type: "state-active", state: a });
  };

  return { ...state, setValue, setActive };
};
