import styled from "styled-components";

export const Card = styled('div')`
  background: #040404;
  background: linear-gradient(155deg, #2A4B28 0%, #060606 100%);
  padding: 28px 22px;
  border-radius: 12px;
  border: 3px solid #74E98A;
  position: relative;
`

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled('h2')`
font-size: 14px;
font-weight: 500;
margin: 0px;
color: white;
`

export const Text = styled('p')`
  font-size: 22px;
  line-height: 28px;
  margin: 0px;
  font-weight: 600;
  color: white;

  background: linear-gradient(155deg, #B2E6AC 0%, #6EE987 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`


export const Close = styled("button")`
  align-self: flex-start;
  background: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 1;

  &:focus {
    outline: transparent;
  }

 @media(min-width: 768px){
  position: absolute;
  top: 10px;
  right: 12px;
 }
`;