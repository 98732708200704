import {
  Step,
  Information,
  H1,
  Text,
  CommunityImg,
  DivOne,
  DivTwo,
  Img,
} from "../styles";
import monetize from "../../../../../assets/home-page/community-monetize.webp";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import Grid from "../../../../../components/Grid";
import Row from "../../../../../components/Grid/Row";
import Col from "../../../../../components/Grid/Col";

const Component = ({ visible }) => {
  const { isTabletLandscape, isDesktop } = useWindowSize();
  return (
    <Step {...(visible && { className: "active" })}>
      <Grid.Container>
        <Row>
          <Col offset={{ md: 1 }} md={10}>
            <Row>
              {!isDesktop && !isTabletLandscape && (
                <Col>
                  <CommunityImg>
                    <Img src={monetize} alt="" width="" height="" lazy={true}/>
                  </CommunityImg>
                </Col>
              )}

              <Col md={6}>
                <Information>
                  <H1>Monetiza tu comunidad</H1>
                  <Text>
                    Recibe <b>ingresos recurrentes</b> planificando múltiples
                    eventos de pago único o lanzando grupos de suscripcion.
                    Ofrece a tu comunidad una plataforma de pago segura en su
                    moneda local, dólares o euros. Además cuenta con un{" "}
                    <b>asesor estratégico</b> que te acompañará en todo el
                    camino para que logres el éxito en todas tus propuestas.
                  </Text>
                </Information>
              </Col>

              <Col md={6}>
                {(isDesktop || isTabletLandscape) && (
                  <CommunityImg>
                    <Img src={monetize} alt="" width="" height="" lazy={true}/>
                  </CommunityImg>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid.Container>
    </Step>
  );
};

export default Component;
