import { Group } from "../../../types";

export const GET_COMMUNITY = "GET_COMMUNITY";
export const GET_COMMUNITY_SUCCESS = "GET_COMMUNITY_SUCCESS";
export const GET_COMMUNITY_ERROR = "GET_COMMUNITY_ERROR";

export interface GetCommunity {
  type: typeof GET_COMMUNITY;
  payload: null;
}

export interface GetCommunitySuccess {
  type: typeof GET_COMMUNITY_SUCCESS;
  payload: Group;
}

export interface GetCommunityError {
  type: typeof GET_COMMUNITY_ERROR;
  payload: boolean | string;
}

export interface State {
  data: Group | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions = GetCommunity | GetCommunityError | GetCommunitySuccess;
