import addUser from "../assets/icons/add-user.svg";
import handHeart from "../assets/icons/hand-heart.svg";
import paperPlane from "../assets/icons/paper-plane.svg";
import bubbleChat from "../assets/icons/chat-bubble.svg";

import addUserRainbow from "../assets/icons/add-user-rainbow.svg";
import handHeartRainbow from "../assets/icons/hand-heart-rainbow.svg";
import paperPlaneRainbow from "../assets/icons/paper-plane-rainbow.svg";
import bubbleChatRainbow from "../assets/icons/chat-bubble-rainbow.svg";

const subscription = [
  {
    type: "free_experience",
    title: "Inscripciones abiertas",
    benefitsTitle: "Duración del evento: *time*",
    colors: {
      enabled: {
        primary: "#12B47A",
        secondary: "#DEF5E0",
      },
      disabled: {
        primary: "#E02F2F",
        secondary: "#F2F2F2",
      },
    },
    faqs: [
      {
        question: "¿En qué día y horario son los encuentros en vivo?",
        answer:
            "Recibirás un calendario de actividades con la información para que puedas organizarte. Además, todos los encuentros quedarán grabados para que lo puedas ver luego.",
      },
      {
        question: "¿La experiencia tiene un tiempo de duración?",
        answer: "Si, la duración está predeterminada con anticipación.",
      },
      {
        question: "¿Por donde me llega el contenido?",
        answer:
            "Para que accedas a todo el material de manera ordenada utilizaremos Telegram. Una aplicación similar a Whatsapp especializada en comunidades.",
      },
    ],
    howItWorks: [
      {
        icon: addUserRainbow,
        text: "Inscríbete al grupo",
      },
      {
        icon: paperPlaneRainbow,
        text: "Descárgate Telegram",
      },
      {
        icon: bubbleChatRainbow,
        text: "Recibe el acceso a la experiencia",
      },
      {
        icon: handHeartRainbow,
        text: "Empieza a transformar tu vida",
      },
    ],
  },
  {
    type: "experience",
    title: "Inscripciones abiertas",
    benefitsTitle: "Duración de la experiencia: *time*",
    colors: {
      enabled: {
        primary: "#12B47A",
        secondary: "#DEF5E0",
      },
      disabled: {
        primary: "#E02F2F",
        secondary: "#F2F2F2",
      },
    },
    faqs: [
      {
        question: "¿En qué día y horario son los encuentros en vivo?",
        answer:
            "Recibirás un calendario de actividades con la información para que puedas organizarte. Además, todos los encuentros quedarán grabados para que lo puedas ver luego.",
      },
      {
        question: "¿Puedo realizar el pago mediante transferencia bancaria?",
        answer:
            "El método de pago es únicamente mediante tarjeta de débito/crédito.",
      },
      {
        question: "¿Cuántos pagos debo hacer en total?",
        answer: "Para acceder a la experiencia deberás realizar un solo pago.",
      },
      {
        question: "¿La experiencia tiene un tiempo de duración?",
        answer: "Si, la duración está predeterminada con anticipación.",
      },
      {
        question: "¿Por donde me llega el contenido?",
        answer:
            "Para que accedas a todo el material de manera ordenada utilizaremos Telegram. Una aplicación similar a Whatsapp especializada en comunidades.",
      },
    ],
    howItWorks: [
      {
        icon: addUserRainbow,
        text: "Suscríbete",
      },
      {
        icon: paperPlaneRainbow,
        text: "Descarga Telegram",
      },
      {
        icon: bubbleChatRainbow,
        text: "Recibe el acceso",
      },
      {
        icon: handHeartRainbow,
        text: "Comienza a transformar tu vida",
      },
    ],
  },
  {
    type: "experience",
    title: "Inscripciones abiertas",
    benefitsTitle: "Duración de la experiencia: *time*",
    group_id: 4079,
    colors: {
      enabled: {
        primary: "#12B47A",
        secondary: "#DEF5E0",
      },
      disabled: {
        primary: "#E02F2F",
        secondary: "#F2F2F2",
      },
    },
    faqs: [
      {
        question: "¿En qué día y horario son los encuentros en vivo?",
        answer:
          "En la solapa de 'Encuentros', encontrás el enlace de zoom para las reuniones en vivo que se dictarán durante los 15 días del programa. ¡Te recomendamos agendarlos para que no te pierdas ninguno!",
      },
      {
        question: "¿La experiencia tiene un tiempo de duración?",
        answer: "Si, la duración está predeterminada con anticipación.",
      },
      {
        question: "¿Por donde me llega el contenido?",
        answer:
          "Te enviaremos al email con el que te registraste, el acceso a tu grupo de WhatsApp, donde vas a poder hacer consultas a tu facilitadora. Y recibirás en el mismo email el enlace para acceder a la plataforma, donde vas a encontrar todo el contenido de manera ordenada y los encuentros en vivo del programa",
      },
    ],
    howItWorks: [
      {
        icon: addUserRainbow,
        text: "Registrate",
      },
      {
        icon: bubbleChatRainbow,
        text: "Recibe el acceso",
      },
      {
        icon: handHeartRainbow,
        text: "Comienza a transformar tu vida",
      },
    ],
  },
  {
    type: "membership",
    title: "Taller",
    group_id: 2863,
    benefitsTitle: "Grandes beneficios",
    colors: {
      enabled: {
        primary: "#FFFFFF",
        secondary: "#FAF3FF",
      },
      disabled: {
        primary: "#FFFFFF",
        secondary: "#FAF3FF",
      },
    },
    faqs: [
      {
        question: "¿En qué día y horario son las consultorias en vivo?",
        answer:
            "Recibirás un calendario de actividades con la información para que puedas organizarte.",
      },
      {
        question: "¿Puedo realizar el pago mediante transferencia bancaria?",
        answer:
            "El método de pago es únicamente mediante tarjeta de débito/crédito. Al igual que otros servicios de suscripción como Netflix o Spotify.",
      },
      {
        question: "¿Cuantos pagos son?",
        answer:
            "El valor del taller es de 3 pagos obligatorios, realizando 1 pago por mes. Una vez realizado el primer pago los dos restantes se hacen de forma automática.",
      },
      {
        question: "¿Cómo sé cuando es la próxima fecha de pago?",
        answer:
            "La fecha del pago será 30 días después de tu primer pago, y se respetará esa fecha en los próximos 2 meses subsiguientes.",
      },
      {
        question: "¿Por donde me llega el contenido?",
        answer:
            "Una vez realices el pago tendras acceso al Aula Virtual con todo el material del curso para que realices a tu tiempo. También podrás unirte al grupo de Whatsapp exclusivo para estar en contacto con Cris y su equipo.",
      },
    ],
    howItWorks: [
      {
        icon: addUserRainbow,
        text: "Suscríbete al taller",
      },
      {
        icon: paperPlaneRainbow,
        text: "Únete al grupo de Whatsapp",
      },
      {
        icon: bubbleChatRainbow,
        text: "Accede a todo el contenido",
      },
      {
        icon: handHeartRainbow,
        text: "Empieza a  transformar tu vida",
      },
    ],
  },
  {
    type: "membership",
    title: "Membresía",
    group_id: 3012,
    benefitsTitle: "Grandes beneficios",
    colors: {
      enabled: {
        primary: "#FFFFFF",
        secondary: "#FAF3FF",
      },
      disabled: {
        primary: "#FFFFFF",
        secondary: "#FAF3FF",
      },
    },
    faqs: [
      {
        question: "¿En qué día y horario son los encuentros en vivo?",
        answer:
            "Recibirás un calendario de actividades con la información para que puedas organizarte. Además, todos los encuentros quedarán grabados para que lo puedas ver luego.",
      },
      {
        question: "¿Qué pasa si me pierdo alguna charla en vivo?",
        answer: "Todo quedará grabado en nuestra biblioteca y espero que igual veas todo en vivo.",
      },
      {
        question:
            "¿Puedo cancelar la membresía cuando yo quiera? ¿Qué debo hacer?",
        answer:
            "Puedes cancelar tu suscripción en cualquier momento y regresar cuando así lo sientas. Para hacerlo tienes que iniciar sesión, en el Menú seleccionar 'Mi cuenta', y en 'Administrar Suscripciones' podrás cancelarla.",
      },
      {
        question: "¿Me puedo sumar desde cualquier parte del mundo?",
        answer:
            "Obvio. Podés suamrte y pagar tu membresía en dólares desde acá: https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-1C0332315A432951EMSXN42A",
      },
      {
        question: "¿Puedo realizar el pago mediante transferencia bancaria?",
        answer:
            "El método de pago es únicamente mediante tarjeta de débito/crédito. Al igual que otros servicios de suscripción como Netflix o Spotify. ",
      },
      {
        question: "¿La membresía tiene un tiempo de duración?",
        answer:
            "La membresía está pensada como una herramienta de transformación que te acompañará en tu día a día. No tiene una fecha de finalización estipulada, podrás permanecer el tiempo que lo desees.",
      },
      {
        question: "¿Qué pasa si el Klouser no cumple con mis expectativas?",
        answer:
            "Tienes 7 días de prueba para estar dentro de la plataforma, si no estás conforme o no era lo que esperabas, puedes solicitar el reembolso del dinero.",
      },
      {
        question: "¿Cómo sé cuando es la próxima fecha de pago?",
        answer:
            "La fecha del pago será 30 días después de tu primer pago, y se respetará esa fecha en todos los meses subsiguientes.",
      },
      {
        question: "¿Por donde me llega el contenido?",
        answer:
            "Para que accedas a todo el material de manera ordenada utilizaremos la aplicación Telegram. Una aplicación similar a Whatsapp especializada en comunidades. Una vez te suscribas, te enviaremos una invitación a nuestro canal de Telegram!",
      },
    ],
    howItWorks: [
      {
        icon: addUserRainbow,
        text: "Suscríbete al  grupo",
      },
      {
        icon: paperPlaneRainbow,
        text: "Descárgate  Telegram",
      },
      {
        icon: bubbleChatRainbow,
        text: "Recibe el acceso  a tu membresía",
      },
      {
        icon: handHeartRainbow,
        text: "Empieza a  transformar tu vida",
      },
    ],
  },
  {
    type: "membership",
    title: "Membresía",
    benefitsTitle: "Grandes beneficios",
    colors: {
      enabled: {
        primary: "#FFFFFF",
        secondary: "#FAF3FF",
      },
      disabled: {
        primary: "#FFFFFF",
        secondary: "#FAF3FF",
      },
    },
    faqs: [
      {
        question: "¿En qué día y horario son los encuentros en vivo?",
        answer:
            "Recibirás un calendario de actividades con la información para que puedas organizarte. Además, todos los encuentros quedarán grabados para que lo puedas ver luego.",
      },
      {
        question:
            "¿Puedo cancelar la membresía cuando yo quiera? ¿Qué debo hacer?",
        answer:
            "Puedes cancelar tu suscripción en cualquier momento y regresar cuando así lo sientas. Para hacerlo tienes que iniciar sesión, en el Menú seleccionar 'Mi cuenta', y en 'Administrar Suscripciones' podrás cancelarla.",
      },
      {
        question: "¿Puedo realizar el pago mediante transferencia bancaria?",
        answer:
            "El método de pago es únicamente mediante tarjeta de débito/crédito. Al igual que otros servicios de suscripción como Netflix o Spotify. ",
      },
      {
        question: "¿La membresía tiene un tiempo de duración?",
        answer:
            "La membresía está pensada como una herramienta de transformación que te acompañará en tu día a día. No tiene una fecha de finalización estipulada, podrás permanecer el tiempo que lo desees.",
      },
      {
        question: "¿Qué pasa si el Klouser no cumple con mis expectativas?",
        answer:
            "Tienes 7 días de prueba para estar dentro de la plataforma, si no estás conforme o no era lo que esperabas, puedes solicitar el reembolso del dinero.",
      },
      {
        question: "¿Cómo sé cuando es la próxima fecha de pago?",
        answer:
            "La fecha del pago será 30 días después de tu primer pago, y se respetará esa fecha en todos los meses subsiguientes.",
      },
      {
        question: "¿Por donde me llega el contenido?",
        answer:
            "Para que accedas a todo el material de manera ordenada utilizaremos la aplicación Telegram. Una aplicación similar a Whatsapp especializada en comunidades. Una vez te suscribas, te enviaremos una invitación a nuestro canal de Telegram!",
      },
    ],
    howItWorks: [
      {
        icon: addUserRainbow,
        text: "Suscríbete al  grupo",
      },
      {
        icon: paperPlaneRainbow,
        text: "Descárgate  Telegram",
      },
      {
        icon: bubbleChatRainbow,
        text: "Recibe el acceso  a tu membresía",
      },
      {
        icon: handHeartRainbow,
        text: "Empieza a  transformar tu vida",
      },
    ],
  },
];

export default subscription;
