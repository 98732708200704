import styled from "styled-components";
import { Link } from "react-router-dom";

export const ExpertName = styled("p")`
  margin: 0px;
  font-size: 12px;
  line-height: 15px;
  min-height: 15px;
  color: ${(props) => props.theme.colors.BlueLight};
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const LinkCard = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 10px rgba(108, 92, 231, 0.15);
  border-radius: 14px;
  overflow: hidden;
  cursor: pointer;
`;

export const ButtonCard = styled('button')`
  margin: 0px;
  padding: 0px;
  border: 0px;
  background: none;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 10px rgba(108, 92, 231, 0.15);
  border-radius: 14px;
  overflow: hidden;
`;

export const Avatar = styled("div")`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  width: 68px;
  overflow: hidden;
`;

export const Information = styled("div")`
  padding: 12px 16px;
  max-width: calc(100% - 68px);
  width: 100%;
  height: 113px;
  text-align: left;
`;

export const Type = styled("p")`
  margin: 0px;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.Primary};
  text-transform: capitalize;
`;

export const Title = styled("h2")`
  margin: 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  min-height: 30px;
  color: ${({ theme }) => theme.colors.Black};
`;

export const Tag = styled("div") <{ inverted?, invited?}>`
  flex-shrink: ${({ inverted }) => (inverted ? "0" : "1")};
  height: 17px;
  line-height: ${({ inverted }) => (inverted ? "15px" : "17px")};
  border: ${({ inverted, theme }) =>
    inverted ? `1px solid ${theme.colors.Primary}` : "0px"};
  font-size: 10px;
  border-radius: 10px;
  background-color: ${({ inverted, invited, theme }) =>
    inverted ? "transparent" : invited ? theme.colors.SoftOrange : theme.colors.Primary};
  color: ${({ inverted, theme }) => (inverted ? theme.colors.Primary : "white")};
  padding: 0px 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Status = styled("div")``;

export const InLine = styled("div")`
  display: flex;
  align-items: center;
`;

// export const GroupCard = styled.div`
//   background: #ffffff;
//   box-shadow: 16px 0px 16px rgba(0, 0, 0, 0.08);
//   border-radius: 16px;
//   overflow: hidden;
//   margin-bottom: 16px;
//   width: 100%;
// `;

// export const GroupCardHeader = styled.div`
//   padding: 16px;
//   margin-bottom: 20px;
//   display: flex;
//   align-items: center;
//   height: 56px;
//   background-color: rgba(108, 92, 231, 0.08);
// `;
// export const GroupCardAvatar = styled(Avatar)`
//   margin-right: 16px;
// `;

// export const SubscriptionName = styled.div`
//   display: flex;
//   width: 100%;
//   justify-content: space-between;
// `;

// export const GroupCardBody = styled.div`
//   padding: 12px 16px 16px;
//   text-align: center;
//   border-bottom: 1px solid rgba(105, 119, 155, 0.25);
// `;

// export const GroupTitle = styled.p`
//   margin: 0px 0px 16px 0px;
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 19px;
//   text-align: center;
//   color: ${(props) => props.theme.colors.Black};
// `;

// export const GroupSubtitle = styled.p`
//   font-size: 14px;
//   line-height: 17px;
//   min-height: 34px;
//   margin: 0px 0px 4px 0px;
// `;

// export const GroupCardFooter = styled.div`
//   padding: 16px 16px 0px;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;

//   @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
//     flex-direction: row;
//     justify-content: space-around;
//   }
// `;

// export const Link = styled(LinkUi)`
//   margin-bottom: 20px;
//   min-width: 154px;
// `;

// export const State = styled.h3`
//   margin: 0px;
//   font-weight: 600;
//   font-size: 12px;
//   line-height: 15px;
//   text-align: left;
//   color: ${(props) => props.theme.colors.Primary};

//   text-overflow: ellipsis;
//   overflow: hidden;
//   white-space: nowrap;
// `;
