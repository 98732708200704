import styled from "styled-components";
import ImgUi from "../../../../components/Image";

export const Menssage = styled.div`
  padding: 45px 10px 30px;
  background: ${(props) => props.theme.colors.PurpleVeryLight};
  border-radius: 16px;
  max-width: 1200px;
  position: relative;
  margin: 0 auto 80px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 90px 50px 75px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    padding: 90px 100px 75px;
    margin: 0 auto 150px;
  }
`;

export const Image = styled(ImgUi)`
  margin: 0px auto 24px;
  display: flex;
`;

export const Title = styled.h1`
  font-size: 32px;
  line-height: 46px;
  font-weight: 700;
  color: white;
  margin-top: 50px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 48px;
    line-height: 60px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    font-size: 50px;
    line-height: 72px;
  }
`;

export const Text = styled.div`
  font-size: 20px;
  line-height: 30px;
  color: white;
  text-align: center;

  &:span {
    margin-bottom: 10px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 24px;
    line-height: 36px;
  }
`;
