import moment from "moment";
import tzMoment from "moment-timezone";
import countriesDB from 'countries-db';

// Function to convert Etc/GMT+X to UTC+X
const convertEtcToUTC = (etcTimezone) => {
  // The Etc/GMT offsets are reversed, so GMT+3 is UTC-3
  let offset = parseInt(etcTimezone.replace('Etc/GMT', ''), 10);
  return `UTC${offset > 0 ? '-' : '+'}${Math.abs(offset)}`;
}

// Function to find IANA time zone name for a given UTC offset
function findIANATimeZone(offset) {
  // Get the current time in the given offset
  let currentTime = moment().utcOffset(offset);

  // Find all IANA time zones that match the current UTC offset
  let matchingTimeZones = moment.tz.names().filter(tz => {
    return currentTime.isSame(moment.tz(tz), 'minute');
  });

  console.log('matchingTimeZones', matchingTimeZones)

  return matchingTimeZones[0];
}

interface Country {
  id: string;
  name: string;
  officialName: string;
  emoji: string;
  areaSqKm: number;
  continentId: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  currencyCode: string;
  currencyName: string;
  domain: string;
  elevation: number;
  emojiUnicode: string;
  geonameId: number;
  iso2: string;
  iso3: string;
  isoNumeric: string;
  languages: string[];
  locales: string[];
  phoneCode: string;
  population: number;
  postalCodeFormat: string;
  postalCodeRegex: string;
  timezones: string[];
  neighborCountryIds: string[];
}

export const months = {
  1: {
    large: "Enero",
    short: "Ene",
  },
  2: {
    large: "Febrero",
    short: "Feb",
  },
  3: {
    large: "Marzo",
    short: "Mar",
  },
  4: {
    large: "Abril",
    short: "Abr",
  },
  5: {
    large: "Mayo",
    short: "May",
  },
  6: {
    large: "Junio",
    short: "Jun",
  },
  7: {
    large: "Julio",
    short: "Jul",
  },
  8: {
    large: "Agosto",
    short: "Ago",
  },
  9: {
    large: "Septiembre",
    short: "Sep",
  },
  10: {
    large: "Octubre",
    short: "Oct",
  },
  11: {
    large: "Noviembre",
    short: "Nov",
  },
  12: {
    large: "Diciembre",
    short: "Dic",
  },
};

const days = {
  Mon: {
    large: "lunes",
    short: "lun",
  },
  Tue: {
    large: "martes",
    short: "mar",
  },
  Wed: {
    large: "miercoles",
    short: "mie",
  },
  Thu: {
    large: "jueves",
    short: "jue",
  },
  Fri: {
    large: "viernes",
    short: "vie",
  },
  Sat: {
    large: "sabado",
    short: "sab",
  },
  Sun: {
    large: "domingo",
    short: "dom",
  },
};

export interface DateSpliteDto {
  input: string;
  inputMonth: string;
  inputHour: string;
  date: string;
  month: {
    number: string;
    name: { large: string; short: string };
  };
  day: {
    number: string;
    name: { large: string; short: string };
  };
  year: string;
  hours: string;
  minutes: string;
  seconds: string;
  timeZone: {
    raw: string,
    country: string
  };
}

export const dateSpliter = (date): DateSpliteDto => {
  const utcDate = setToLocalTimeZone(date);
  const dateObj = moment(utcDate);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return {
    input: `${dateObj.format("YYYY")}-${dateObj.format("M")}-${dateObj.format(
      "D"
    )}`,
    inputMonth: `${dateObj.format("YYYY")}-${dateObj.format("M")}`,
    inputHour: `${dateObj.format("HH")}:${dateObj.format("mm")}`,
    date: `${dateObj.format("D")}/${dateObj.format("M")}/${dateObj.format(
      "YYYY"
    )}`,
    month: {
      number: dateObj.format("M"),
      name: months[dateObj.format("M")],
    },
    day: {
      number: dateObj.format("D"),
      name: days[dateObj.format("ddd")],
    },
    year: dateObj.format("YYYY"),
    hours: dateObj.format("HH"),
    minutes: dateObj.format("mm"),
    seconds: dateObj.format("ss"),
    timeZone: {
      raw: timeZone,
      country: getCountryFromTimeZone(timeZone)
    }
  };
};

const fromDateToUtcSeconds = (dateStr) => {
  return new Date(dateStr).getTime() / 1000
}

const getCountryFromTimeZone = (timeZone: string): string => {
  let ianaTimeZone = timeZone;

  // Check if the timezone starts with 'Etc/GMT' and conver into IANA time zone
  // if (ianaTimeZone.startsWith('Etc/GMT')) {
  //   const utcTz = convertEtcToUTC(ianaTimeZone);
  //   ianaTimeZone = findIANATimeZone(utcTz);
  // }

  const countries = countriesDB.getAllCountries();

  const country: any = Object.keys(countries)
    .filter(country => {
      return countries[country].timezones.includes(ianaTimeZone)
    })

  return countries[country]?.name || '';
};

export const fromUtcSecondsToDateStr = (utcSeconds, format = "YYYY-MM-DD") => {
  const utcDate = moment.unix(utcSeconds); // Convert UTC seconds to UTC date
  const localDate = setToLocalTimeZone(utcDate);
  const dateObj = moment(localDate);
  return dateObj.format(format);
}

export const isExpired = (date) => {
  const utcDate = setToLocalTimeZone(date);
  const timeDate = new Date(utcDate).getTime();
  const today = new Date().getTime();
  const distance = timeDate - today;
  return distance <= 0;
};

export const sessionInProgress = (date, duration = 1) => {
  const sessionTime = setToLocalTimeZone(date);
  const sessionTimePlusOne = moment(date).add(duration, "minute").format();
  const today = moment().format();

  return sessionTime <= today && today <= sessionTimePlusOne;
};

export const timeToSession = (date) => {
  const sessionTime = moment(date).local();
  const today = moment().local();

  // Calculate the difference in days
  const daysDifference = sessionTime.diff(today, 'days', true);

  if (daysDifference >= 1) {
    const roundedDaysDifference = Math.round(daysDifference);
    return `${roundedDaysDifference} ${roundedDaysDifference === 1 ? 'día' : 'días'}`;
  } else {
    // Calculate the difference in hours
    const hoursDifference = sessionTime.diff(today, 'hours', true);
    if (hoursDifference >= 1) {
      const roundedHoursDifference = Math.round(hoursDifference);
      return `${roundedHoursDifference} ${roundedHoursDifference === 1 ? 'hora' : 'horas'}`;
    } else {
      // Calculate the difference in minutes
      const minutesDifference = sessionTime.diff(today, 'minutes', true);
      const roundedMinutesDifference = Math.round(minutesDifference);
      return `${roundedMinutesDifference} ${roundedMinutesDifference === 1 ? 'minuto' : 'minutos'}`;
    }
  }
};

export const convertFloatHourToMinutes = (numero) => {
  // Extraer la parte entera y decimal del número
  let parteEntera = Math.floor(numero);
  let parteDecimal = numero - parteEntera;

  // Convertir la parte entera a horas y la parte decimal a minutos
  let horas = parteEntera;
  let minutos = Math.round(parteDecimal * 60);

  // Calcular el total de minutos
  let totalMinutos = horas * 60 + minutos;

  return totalMinutos;
}

export const convertTimeToMinutes = (duration, durationUnit) => {
  // Extraer la parte entera y decimal del número
  switch (durationUnit) {
    case "hour":
      return duration * 60
    case "day":
      return duration * 60 * 24
    case "minute":
      return duration;
  }
}

export const sessionExpired = (date, duration = 1, margin = 0) => {
  const sessionTimePlusOne = moment(date).add(duration + margin, "minute").format();
  const today = moment().format();
  return sessionTimePlusOne < today;
};

export const sessionExpiredWithMargin = (date, duration = 1, margin = 60) => {
  return sessionExpired(date, duration, margin)
};

export const getUtcDate = (date) => {
  const utcDate = setToLocalTimeZone(date);
  const timeDate = new Date(utcDate).getTime();
  const today = new Date().getTime();
  const distance = timeDate - today;
  return distance <= 0;
};

export const isFutureDate = (date) => {
  const selectedDate = moment(date);
  const today = moment();

  return selectedDate.diff(today) >= 0;
};

// Function to check if the datetime string is today
export const isToday = (datetimeString) => {
  // Parse the datetime string using 'moment'
  const datetime = moment(datetimeString, moment.ISO_8601);

  // Get today's date
  const todayDate = moment().startOf('day');

  // Compare the datetime with today's date
  return datetime.isSame(todayDate, 'day');
}

// Function to check if the datetime string is tomorrow
export const isTomorrow = (datetimeString) => {
  // Parse the datetime string using 'moment'
  const datetime = moment(datetimeString, moment.ISO_8601);

  // Get tomorrow's date
  const tomorrowDate = moment().add(1, 'day').startOf('day');

  // Compare the datetime with tomorrow's date
  return datetime.isSame(tomorrowDate, 'day');
}

export const isBeforeXDays = (dateString, days = 1) => {
  const futureDate = moment().add(days, 'days');
  const inputDate = moment(dateString); // Adjust format as needed

  return inputDate.isBefore(futureDate)
}

export const setToZeroTimeZone = (dateTime) => {
  return tzMoment(dateTime).tz("UTC").format();
};

export const timestampToDate = (seconds, format = "YYYY-MM-DD") => {
  return tzMoment.unix(seconds).utc().format(format)
};

export const setToLocalTimeZone = (dateTime) => {
  const LocalTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return tzMoment(dateTime).tz(LocalTimeZone).format();
};

export const dateTimeLocal = (dateTime) => {
  return tzMoment(dateTime).local();
};


export const inputUtcSeconds = (utcSeconds) => {
  const utcDate = moment.unix(utcSeconds); // Convert UTC seconds to UTC date
  const localDate = setToLocalTimeZone(utcDate);
  const dateObj = moment(localDate);
  return dateObj.format("YYYY-MM-DD");
};

export const inputUtcSecondsWithNoTimeZone = (utcSeconds, format = "DD/MM/YYYY") => {
  const utcDate = moment.unix(utcSeconds); // Convert UTC seconds to UTC date
  return utcDate.format(format);
};


export const inputDate = (date, format = "YYYY-MM-DD") => {
  const utcDate = setToLocalTimeZone(date);
  const dateObj = moment(utcDate);
  return dateObj.format(format);
};


export const isBetweenNextDays = (dateStr, nextDays, daysBeforeToday = 0) => {
  const date = moment(dateStr).subtract(daysBeforeToday, "days");
  const today = moment();

  // Calculate the date 30 days from now
  const nextDaysFromNow = moment().add(nextDays, 'days');

  return date.isBetween(today, nextDaysFromNow);
};

const getDateInLocalTimeZone = (date, format = "DD-MM-YYYY") => {
  const utcDate = setToLocalTimeZone(date);
  const dateObj = moment(utcDate);
  return dateObj.format(format);
};

export const inputDateTimeLocal = (date) => {
  const localTime = setToLocalTimeZone(date);
  return moment(localTime).format(moment.HTML5_FMT.DATETIME_LOCAL);
};

const date = {
  dateSpliter,
  isExpired,
  inputDate,
  inputDateTimeLocal,
  isFutureDate,
  setToZeroTimeZone,
  setToLocalTimeZone,
  getDateInLocalTimeZone,
  fromUtcSecondsToDateStr,
  timestampToDate,
  inputUtcSecondsWithNoTimeZone
};

export default date;
