import { createRef, useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import UiKitLink from '../../Link'

import { setCurrentPage } from "../../../store/actions/page";
import { useLayout } from "../../../providers/LayoutProvider";
import { hideModal, showModal } from "../../../store/actions/modal";
import { isSubscribed } from "../../../helpers/isSubscribed";
import { useWindowSize } from "../../../hooks/useWindowSize";
import Swipe from "../../../components/Swipe";
import CoverPic from "./components/CoverPic"
import Grid from "../../Grid";
import constants from "../../../constants";
import WhatsApp from "../../Whatsapp";
import Footer from "../../Footer";

import PhoneNumberModal from "../components/ModalPhoneNumber";
import userAvatar from "../../../assets/avatar.svg";
import Toolbar from "./components/Toolbar";
import MobileHeader from './components/MobileHeader'
import DesktopHeader from './components/DesktopHeader'
import GroupDesktopMenu from '../components/GroupDesktopMenu'
import GroupMobileMenu from '../components/GroupMobileMenu'

import { GlobalStyles, TabsContainer, Tabs, Tab, Link, SwipeContent, Title, SectionName } from "./styles";
import ModalSelectChat from "../components/ModalSelectChat";
import { Group, Subscription, User } from "../../../types";
import { getFreeSubscriptions } from "../../../helpers/getFreeSubscriptions";
import { getRandomBg } from "../../../helpers/randomGroupCover";
import CreatedByKlouser from "./components/CreatedByKlouser";

interface ComponentProps {
  children,
  useLocation,
  user: User
  userStates,
  subscriptions: Subscription[]
  subscriptionsStates,
  group: Group
  groupStates,
  postsStates,
  currentPage,
  isLoggedIn
}

const groupTypes = {
  community: "comunidad",
  free_experience: "taller",
  experience: "taller",
  membership: "membresía",
};

const Component = ({
  children,
  useLocation,
  user,
  userStates,
  subscriptions,
  subscriptionsStates,
  group,
  groupStates,
  postsStates,
  currentPage,
  isLoggedIn
}: ComponentProps) => {
  const { isMobile } = useWindowSize()
  const { layout } = useLayout();
  const location = useLocation();
  const dispatch = useDispatch();
  const footerRef = createRef<HTMLElement>();
  const headerRef = createRef<HTMLElement>();
  const [minHeight, setMinHeight] = useState(0);
  const [currentPageLocally, setCurrentPageLocally] = useState<any>();
  const [groupCoverPic, setGroupCoverPic] = useState<string>();

  const isLoading = useMemo(() => {
    return groupStates.loading ||
      postsStates.loading ||
      subscriptionsStates.loading ||
      userStates.loading
  }, [groupStates,
    postsStates,
    subscriptionsStates,
    userStates])

  const isUserAuthenticated = useMemo(() => {
    return isLoggedIn || group?.group_type === 'free_experience'
  }, [isLoggedIn, group])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(subscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [subscriptions, group, isLoggedIn])

  const showChat = useMemo(() => {
    return group?.telegram_groups?.length > 0 && isUserAuthenticated && isUserSubscribed
  }, [group, isUserAuthenticated, isUserSubscribed])

  const isOwnGroup = useCallback(() => {
    const expertId = group?.expert?.id
    return expertId === user?.expert?.id;
  }, [group, user]);

  useEffect(() => {
    if (location) {
      const page = constants.pages.filter((page) => {
        return location.pathname.match(page.regex);
      });

      setCurrentPageLocally(page[0]);
      dispatch(setCurrentPage(page[0]));

      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    }
  }, [location]);

  useEffect(() => {
    headerRef.current &&
      footerRef.current &&
      setMinHeight(
        headerRef.current.scrollHeight + footerRef.current.scrollHeight
      );
  }, [headerRef, footerRef]);

  useEffect(() => {
    if (!!group)
      setGroupCoverPic(group?.cover_pic?.signed_url || getRandomBg())
  }, [group]);

  useEffect(() => {
    const isUserSubscribed = isSubscribed(subscriptions, group?.id)

    if (
      !!user &&
      !user?.phone_number &&
      isUserSubscribed &&
      currentPageLocally &&
      group.group_type !== "free_experience" &&
      currentPageLocally?.name !== "GROUP_DETAILS"
    ) {
      dispatch(showModal("modal-phone-number"));
    } else if (!!user && !!user?.phone_number) {
      //dispatch(hideModal());
    }
  }, [user, subscriptions, currentPageLocally, group]);



  return (
    <>
      <GlobalStyles background={layout?.body?.background} />

      {!isLoading && (
        <>

          {layout.toolbar.visible && <Toolbar />}

          {/* {layout.toolbar.visible && <CreatedByKlouser />} */}

          {layout.header.beforeHeader}

          {!!group && isMobile ? (
            <>
              <MobileHeader />
              <div style={{ height: 8 }} />
              <CoverPic
                coverPicUrl={groupCoverPic}
                profilePicUrl={group?.expert?.profile_pic?.url || userAvatar}
              />
              <div style={{ height: 32 }} />
              <SectionName>
                {!!group.creator_name &&
                  `${groupTypes[group?.group_type]} de ${group?.creator_name}`
                }
              </SectionName>
              <Title>{group?.group_name}</Title>

              {/* {showChat && (
                <>
                  <BannerSelectChat />
                  <div style={{ height: 10 }} />
                </>
              )} */}
            </>
          ) : (
            <>
              <DesktopHeader useLocation={useLocation} />
              <div style={{ height: 24 }} />
              <CoverPic
                coverPicUrl={groupCoverPic}
                profilePicUrl={group?.expert?.profile_pic?.url || userAvatar}
              />
            </>
          )}

          {!!group && !isMobile && <GroupDesktopMenu />}

          {!!subscriptions && !!group && isMobile && !group?.recorded && (isOwnGroup() || isSubscribed(subscriptions, group?.id)) && (currentPage === "GROUP_DETAILS_ACTIVITY_SUMMARY" || currentPage === "GROUP_DETAILS") && (
            <>
              <TabsContainer toolbarVisible={layout.toolbar.visible && isOwnGroup()}>
                <div style={{ height: 36 }} />
                {/* <Grid.Container>
                  <Grid.Row>
                    <Grid.Col lg={10} offset={{ lg: 1 }}> */}
                <Tabs>
                  <Tab>
                    <Link
                      to={`/group/${group?.slug}/activity-summary`}
                      active={currentPage === "GROUP_DETAILS_ACTIVITY_SUMMARY"}
                    >
                      Destacados
                    </Link>
                  </Tab>
                  <Tab>
                    <Link
                      to={`/group/${group?.slug}`}
                      active={currentPage === "GROUP_DETAILS"}
                    >
                      Acerca de
                    </Link>
                  </Tab>
                </Tabs>
                {/* </Grid.Col>
                  </Grid.Row>
                </Grid.Container> */}
              </TabsContainer>
            </>
          )}

        </>
      )}

      {children}

      {!isLoading && (
        <>
          <Grid.Container>
            <Grid.Row>
              <Grid.Col lg={10} offset={{ lg: 1 }}>
                {layout.footer.visible && <Footer forwardedRef={footerRef} />}
                {layout.footer.visible && <WhatsApp floating options={{ marginBottom: 50 }} />}
              </Grid.Col>
            </Grid.Row>
          </Grid.Container>

          <PhoneNumberModal />

          {isMobile && (
            <GroupMobileMenu useLocation={useLocation} />
          )}

          <ModalSelectChat />

          <Swipe name="swipe-group-owner-menu">
            <SwipeContent>
              <UiKitLink
                href={
                  group?.group_type === "free_experience"
                    ? `/dashboard/community/events/edit/${group?.id}`
                    : `/dashboard/${group?.group_type}/edit/${group?.id}`
                }
                options={{
                  type: "outline",
                  size: "lg",
                  skin: "purple",
                  block: isMobile,
                  native: false,
                }}
              >
                Editar página de venta
              </UiKitLink>

              {group?.group_type !== "free_experience" && (
                <UiKitLink
                  href={`/dashboard/${group?.group_type}/${group?.id}/price`}
                  options={{
                    type: "outline",
                    size: "lg",
                    skin: "purple",
                    block: isMobile,
                    native: false,
                  }}
                >
                  Administrar precios
                </UiKitLink>
              )}

              <UiKitLink
                href={
                  group?.group_type === "free_experience"
                    ? `/dashboard/community/events/${group?.id}/posts/new`
                    : `/dashboard/${group?.group_type}/${group?.id}/posts/new`
                }
                options={{
                  type: "outline",
                  size: "lg",
                  skin: "purple",
                  block: isMobile,
                  native: false,
                }}
              >
                Agregar contenido
              </UiKitLink>

              {group?.group_type !== "free_experience" && (
                <UiKitLink
                  href={
                    group?.group_type === "free_experience"
                      ? `/dashboard/community/events/${group?.id}/sessions/new`
                      : `/dashboard/${group?.group_type}/${group?.id}/sessions/new`
                  }
                  options={{
                    type: "outline",
                    size: "lg",
                    skin: "purple",
                    block: isMobile,
                    native: false,
                  }}
                >
                  Agregar encuentro
                </UiKitLink>
              )}
            </SwipeContent>
          </Swipe>
        </>
      )}
    </>
  );
};

const state = ({ userStore, groupStore, pageStore, postStore }) => {
  const { data: user, states: userStates } = userStore.information;
  const { data: subscriptions, states: subscriptionsStates } = userStore.subscriptions;
  const { data: group, states: groupStates } = groupStore.group
  const { data: posts, states: postsStates } = postStore.listPosts;
  const { isLoggedIn } = userStore;

  return {
    user,
    userStates,
    subscriptions,
    subscriptionsStates,
    group,
    groupStates,
    postsStates,
    currentPage: pageStore?.name,
    isLoggedIn
  };
};

export default connect(state)(Component);
