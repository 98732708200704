import action from "./../helpers/rest-client";
import { Group as GroupType, Expert as ExpertType } from "./../types";

export class ErrorExpert extends Error { }
class Expert {
  public async createNewExpert(formFields) {
    const formData = new FormData();
    const data = {};

    for (let key in formFields) {
      const currentElement = formFields[key];
      if (key === "profile_pic") {
        if (currentElement !== "/static/media/avatar.d0af69d3.svg") {
          const file = await fetch(currentElement)
            .then((res) => res.blob())
            .then(
              (blob) =>
                new File([blob], "profile_photo.png", { type: "image/png" })
            );
          formData.append(key, file, file.name);
        }
      } else {
        data[key] = currentElement;
      }
    }

    formData.append("data", JSON.stringify(data));

    return action
      .Post({
        url: "/api/expert/new-expert",
        body: formData,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorExpert(error.response.statusText);
      });
  }

  public async updateExpertInformation(formFields) {
    const formData = new FormData();
    const data = {};

    for (let key in formFields) {
      const currentElement = formFields[key];
      console.log(key)

      if (key === "big_logo" || key === "small_logo") {
        currentElement?.forEach(async (element) => {
          if (!element?.url) {
            formData.append(key, element, element?.name);
          }
        });
      } else {
        data[key] = currentElement;
      }
    }

    formData.append("data", JSON.stringify(data));

    return action
      .Put({
        url: `/api/expert/update-information`,
        body: formData,
      })
      .then((response) => {
        return { response, error: null };
      })
      .catch((error) => {
        return { error, response: null }
      });
  }


  //TODO: create a gateway service for this method
  public async linkGateway(payload) {
    return action
      .Post({
        url: `/api/gateway/create`,
        body: payload,
      })
      .then((apiResponse) => {
        return apiResponse;
      })
      .catch((error) => {
        throw new ErrorExpert(error.response.statusText);
      });
  }

  public async updatePicture(formFields) {
    const formData = new FormData();
    const data = {};

    for (let key in formFields) {
      const currentElement = formFields[key];
      if (key === "profile_pic") {
        const file = await fetch(formFields.profile_pic)
          .then((res) => res.blob())
          .then((blob) => {
            return new File([blob], "profile_photo.png", { type: "image/png" });
          });
        formData.append(key, file, file.name);
      } else {
        data[key] = currentElement;
      }
    }

    formData.append("data", JSON.stringify(data));

    return action
      .Put({
        url: `/api/expert/update-picture`,
        body: formData,
      })
      .then((apiResponse) => {
        return apiResponse;
      })
      .catch((error) => {
        throw new ErrorExpert("file / upload-error");
      });
  }

  public async getExpertInformation(username: string) {
    const response: ExpertType = await action
      .Get({
        url: `/api/expert/${username}/information`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorExpert(error.response.statusText);
      });

    return response;
  }

  public async validateExpert(
    username: string
  ): Promise<{ response?; error?}> {
    return action
      .Get({
        url: `/api/expert/${username}/username`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getExpertGroups(username: string) {
    const response: GroupType[] = await action
      .Get({
        url: `/api/expert/${username}/groups`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorExpert(error.response.statusText);
      });

    return response;
  }

  public async getExpertGroup(username: string, group: string) {
    const response: GroupType[] = await action
      .Get({
        url: `/api/expert/${username}/group/${group}`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorExpert(error.response.statusText);
      });

    return response;
  }

  public async updateWhiteLabel(formFields) {
    const formData = new FormData();
    const data = {};

    for (let key in formFields) {
      const currentElement = formFields[key];

      if (key === "big_logo" || key === "small_logo") {
        currentElement?.forEach(async (element) => {
          if (!element?.url) {
            formData.append(key, element, element?.name);
          }
        });
      } else {
        data[key] = currentElement;
      }
    }

    formData.append("data", JSON.stringify(data));

    return action
      .Put({
        url: `/api/expert/update-information`,
        body: formData,
      })
      .then((response) => {
        return { response, error: null };
      })
      .catch((error) => {
        return { error, response: null }
      });
  }

  public async deleteFile(payload) {
    const { idFile } = payload;

    return action
      .Del({
        url: `/api/expert/file/${idFile}`,
      })
      .then((response) => {
        return { response, error: null };
      })
      .catch((error) => {
        return { error, response: null }
      });
  }
}

const expert = new Expert();

export default expert;
