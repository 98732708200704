export const DELETE_ONE_SESSION = "DELETE_ONE_SESSION";
export const DELETE_ONE_SESSION_SUCCESS = "DELETE_ONE_SESSION_SUCCESS";
export const DELETE_ONE_SESSION_ERROR = "DELETE_ONE_SESSION_ERROR";
export const RESET_DELETE_ONE_SESSION = "RESET_DELETE_ONE_SESSION";

export interface DeleteOneSession {
  type: typeof DELETE_ONE_SESSION;
  payload: { idSession: number };
}

export interface DeleteOneSessionSuccess {
  type: typeof DELETE_ONE_SESSION_SUCCESS;
  payload: null;
}

export interface DeleteOneSessionError {
  type: typeof DELETE_ONE_SESSION_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteOneSession {
  type: typeof RESET_DELETE_ONE_SESSION;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | DeleteOneSession
  | DeleteOneSessionSuccess
  | DeleteOneSessionError
  | ResetDeleteOneSession;
