import {
  FrontPage,
  Background,
  BannerWrapper,
  HeroBanner,
  Image,
} from "./styles";
import { Title, SubTitle, Link } from "../../components/styles";
import HomeImg from "../../../../assets/home-page/featured-image.webp";
import Grid from "../../../../components/Grid";
import Row from "../../../../components/Grid/Row";
import Col from "../../../../components/Grid/Col";

const Component = () => {
  return (
    <FrontPage>
      <Background>
        <Grid.Container>
          <Row>
            <Col offset={{ md: 1 }} md={10}>
              <Title>
                Las comunidades quedan,
                <br />
                las redes sociales pasan.
              </Title>
              <SubTitle>
                Activa a tu audiencia con eventos de pago único o grupos de
                suscripción <br />
                en la única plataforma para expertos del bienestar
              </SubTitle>
              <Link to="/expert/signup">¡Crear mi Klouser gratis!</Link>
            </Col>
          </Row>
        </Grid.Container>
      </Background>

      <BannerWrapper>
        <HeroBanner>
          <Image
            src={HomeImg}
            alt="administrar"
            width="auto"
            height="auto"
            lazy={false}
            preload={true}
          />
        </HeroBanner>
      </BannerWrapper>
    </FrontPage>
  );
};

export default Component;
