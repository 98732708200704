import {
  GET_PREFERENCE,
  SAVE_PREFERENCE,
  SavePreferencePayload,
} from "../types/user/preferences";

export const savePreference = (value: SavePreferencePayload) => {
  return { type: SAVE_PREFERENCE, payload: value };
};

export const getUserPreferences = () => {
  return { type: GET_PREFERENCE };
};
