import { useMemo } from "react";
import { connect } from "react-redux";
import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import {
  Progress,
  ProgressBar,
  ProgressBarContainer,
} from './styles'
interface ComponentProps {
  welcomeContentSection,
  showNumber?
}

const Component = ({
  welcomeContentSection,
  showNumber = true
}: ComponentProps) => {
  const { isMobile } = useWindowSize()

  const total = useMemo(() => {
    return welcomeContentSection?.welcome_contents?.length
  }, [welcomeContentSection])

  const completed = useMemo(() => {
    return welcomeContentSection?.welcome_contents?.filter(item => item.completed).length;
  }, [welcomeContentSection])

  const progress = useMemo(() => {
    return total ? Math.floor((completed / total) * 100) : 0
  }, [total, completed])

  return (
    <div style={{ width: '100%', display: "flex", alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', gap: isMobile ? 8 : 14 }}>
      <ProgressBarContainer style={{}}>
        <ProgressBar style={{ width: `${progress}%`, }} />
      </ProgressBarContainer>
      {showNumber && <Progress style={{}}>{progress}% completado</Progress>}
    </div>
  )
}

const state = ({ userStore, groupStore, welcomeContentStore }) => {
  const { isLoggedIn } = userStore;
  const { data: user, states: userStates } =
    userStore.information;
  const { data: welcomeContentSection, states: welcomeContentSectionStates } = welcomeContentStore.welcomeContentSection;
  const { data: group, states: groupStates } = groupStore.group;

  return {
    isLoggedIn,
    user,
    userStates,
    welcomeContentSection,
    welcomeContentSectionStates,
    group,
    groupStates,
  };
};

export default connect(state)(Component);