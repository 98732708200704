import { combineReducers } from "redux";
import cancelSubscription from "./cancel-subscription";
import editSubscription from "./edit-subscription";
import subscriptionAdmin from "./get-subscription";


const reducer = combineReducers({
    cancelSubscription,
    editSubscription,
    subscriptionAdmin
});

export default reducer;
