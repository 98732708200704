import Input from "../../../../components/Form/Input";
import Select from "../../../../components/Form/Select";
import { Label, StepNumber, Text } from "../styles";
import { DurationNumber, DurationUnit, Duration } from "./styles";
import TipCta from "../TipCta";
import { useState } from "react";
import constants from "../../../../constants";

interface ComponentProps {
  onFocus;
  onBlur?;
  onChange?;
  fieldNumber;
  register;
  errors;
  control;
  labelForwardRef;
  getValues?;
}

const Component = ({
  onFocus,
  onBlur,
  onChange,
  fieldNumber,
  register,
  control,
  errors,
  labelForwardRef,
  getValues,
  ...rest
}: ComponentProps) => {
  const [duration, setDuration] = useState(
    getValues("duration[duration_unit]")
  );

  const getDurationUnit = (data) => {
    if (!data) {
      return constants.durations[2];
    }

    if (typeof data === "string") {
      return constants.durations.filter(
        (durationUnit) => durationUnit.value === data
      )[0];
    }

    return constants.durations.filter(
      (durationUnit) => durationUnit.value === data.value
    )[0];
  };

  return (
    <>
      <Label>
        <Text>
          <StepNumber>5.</StepNumber>
          <span>Duración</span>
        </Text>

        {/*<TipCta
          htmlFor={""}
          forwardRef={labelForwardRef}
          onClick={() => onFocus(fieldNumber, "duration[duration_value]")}
        />*/}
      </Label>

      <Duration>
        <DurationNumber>
          <Input
            name="duration[duration_value]"
            type="number"
            placeholder="20"
            onFocus={() => onFocus(fieldNumber, "duration[duration_value]")}
            onBlur={onBlur}
            register={register}
            rules={{
              required: true,
              min: 1,
            }}
            errors={errors}
            {...rest}
          />
        </DurationNumber>

        <DurationUnit>
          <Select
            name="duration[duration_unit]"
            options={{
              data: constants.durations,
              valueField: "value",
              textField: "label",
            }}
            placeholder="Unidad"
            defaultValue={getValues("duration[duration_unit]")}
            // onChange={(e) => {
            //   setDuration(
            //     durationUnits.filter((durationUnit) => {
            //       return durationUnit.label === e.label;
            //     })[0]
            //   );
            // }}
            //onFocus={() => onFocus(fieldNumber, "duration[duration_unit]")}
            onBlur={onBlur}
            rules={{
              required: true,
            }}
            errors={errors}
            control={control}
            {...rest}
          />
        </DurationUnit>
      </Duration>
    </>
  );
};

export default Component;
