import {
  GetAllModulesInDto,
  GetAllModulesOutDto,
  ModuleInDto,
} from "../../../types/module";

export const GET_ALL_MODULES = "GET_ALL_MODULES";
export const GET_ALL_MODULES_SUCCESS = "GET_ALL_MODULES_SUCCESS";
export const GET_ALL_MODULES_ERROR = "GET_ALL_MODULES_ERROR";
export const UPDATE_CURRENT_MODULES = "UPDATE_CURRENT_MODULES";

export interface GetAllModules {
  type: typeof GET_ALL_MODULES;
  payload: GetAllModulesOutDto;
}

export interface GetAllModulesSuccess {
  type: typeof GET_ALL_MODULES_SUCCESS;
  payload: GetAllModulesInDto[];
}

export interface GetAllModulesError {
  type: typeof GET_ALL_MODULES_ERROR;
  payload: string | boolean;
}

export interface UpdateCurrentModules {
  type: typeof UPDATE_CURRENT_MODULES;
  payload: ModuleInDto[];
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetAllModules
  | GetAllModulesSuccess
  | GetAllModulesError
  | UpdateCurrentModules;
