import * as Yup from "yup";

export const schema = Yup.object().shape({
  email: Yup.string(),
  status: Yup.array().nullable(),
});

interface FormValues {
  email: string;
  status: string[];
}

export const initialValues: FormValues = {
  email: "",
  status: [],
};
