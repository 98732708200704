import * as Yup from "yup";
import services from "../../services";

let typingTimeout;

export const schema = Yup.object().shape({
  email: Yup.string()
    .email("Ingresa un e-mail válido.")
    .test(
      "checkUserEmailExist",
      "Ya existe una cuenta con este e-mail. Para acceder, puedes cerrar sesion de esta cuenta e intentar recuperar la contraseña!",
      function async(email) {
        //clear timeout if user is typing and timeout is set
        if (typingTimeout) clearTimeout(typingTimeout);

        return new Promise((resolve, reject) => {
          //delay until user stops typing

          typingTimeout = setTimeout(async () => {
            //check if email is valid
            if (!email) return resolve(true);

            const { response } = await services.auth.validateEmail(email);

            if (response?.status > 500) {
              return resolve(
                this.createError({
                  message: "Error inesperado al validar el e-mail",
                })
              );
            }

            if (response?.status > 400) {
              return resolve(true);
            }

            if (!response || response?.status < 400) {
              return resolve(false);
            }
          }, 1000);
        });
      }
    )
    .required("Ingresa un e-mail válido."),
});

interface FormValues {
  email: string;
}

export const initialValues: FormValues = {
  email: "",
};
