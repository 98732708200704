import styled from "styled-components";
import BreadcrumbUi from "../../../components/breadcrumb";
import { PageHeader as PageHeaderUi } from "../components/styles";
import logo from "../../../assets/release-4.svg";

export const PageHeader = styled(PageHeaderUi)`
  padding: 25px 0px;
  margin-bottom: 25px;
  // background-color: white;
  // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);

  // @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
  //   background-image: url(${logo});
  //   background-repeat-y: no-repeat;
  //   background-repeat-x: no-repeat;
  //   background-position: initial;
  //   background-position-y: -45px;
  //   background-position-x: 8px;
  //   background-size: auto 151px;
  // }
`;

export const PageBody = styled("div")`
  padding: 20px 0px 50px;
`;

export const Title = styled("h2")`
  margin: 0px 0px 18px;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: ${(props) => props.theme.colors.Black};
`;

export const Breadcrumb = styled(BreadcrumbUi)`
  margin-bottom: 20px;
`;
