import { DiscountDto } from "../../../types";

export const GET_DISCOUNT = "GET_DISCOUNT";
export const GET_DISCOUNT_SUCCESS = "GET_DISCOUNT_SUCCESS";
export const GET_DISCOUNT_ERROR = "GET_DISCOUNT_ERROR";
export const UPDATE_CURRENT_DISCOUNT = "UPDATE_CURRENT_DISCOUNT";

export interface GetDiscount {
  type: typeof GET_DISCOUNT;
  payload: { plan_id: number };
}

export interface GetDiscountSuccess {
  type: typeof GET_DISCOUNT_SUCCESS;
  payload: DiscountDto;
}

export interface GetDiscountError {
  type: typeof GET_DISCOUNT_ERROR;
  payload: string | boolean;
}

export interface UpdateCurrentDiscount {
  type: typeof UPDATE_CURRENT_DISCOUNT;
  payload: DiscountDto;
}

export interface State {
  data: DiscountDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetDiscount
  | GetDiscountSuccess
  | GetDiscountError
  | UpdateCurrentDiscount;
