import styled, { css } from "styled-components";

/**
 * Table component
 */
export const Table = styled("table")`
  width: 100%;
  border-spacing: 0;

  // thead tr th:nth-child(2),
  // tbody tr td:nth-child(2) {
  //   left: 90px !important;
  // }

  // .rc-table-cell-fix-left-last {
  //   &:after {
  //     content: "";
  //     z-index: 0;
  //     width: 1px;
  //     height: 100%;
  //     position: absolute;
  //     right: 0px;
  //     top: 0px;
  //     background: #f0f0f0;
  //   }
  // }
`;
export const Head = styled("thead")``;
export const Body = styled("tbody")``;
export const Footer = styled("tfoot")``;
export const Row = styled("tr")``;
export const Td = styled("td")`
  border-top: 1px solid #f0f0f0;
  height: 56px;
  background: white;

  &.rc-table-cell-row-hover {
    background: #f0f0f0;
  }

  &-fix-left-first::after,
  &-fix-left-last::after {
    position: absolute;
    top: 0;
    right: -1px;
    bottom: -1px;
    width: 20px;
    transform: translateX(100%);
    transition: box-shadow 0.3s;
    content: "";
    pointer-events: none;
  }
`;
export const Th = styled("th")`
  vertical-align: top;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #2d3436;
  background: white;
  border-bottom: 24px solid transparent;
`;
