import styled, { css, keyframes } from 'styled-components';

const hidden = css`
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`

const show = css`
  clip: auto;
  overflow: visible;
  opacity: 1;
`

const reaction_size = 48;
const reaction_margin = 0;
const toggle_button_height = 44;

const getWidth = (reactions) => {
  const paddings = 10;
  const contentWidth = reactions.length * reaction_size;
  const contentMargin = reactions.length * reaction_margin - 10;
  return `${paddings + contentWidth + contentMargin}px`;
}


export const LabelReaction = styled('button') <{ isActive }>`
  height: ${toggle_button_height}px;
  width: 100px;
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.colors.Primary};
  border-radius: ${toggle_button_height / 2}px;
  padding: 0px;
  background-color: ${({ theme, isActive }) => isActive ? theme.colors.Primary : 'white'};
  color: ${({ theme, isActive }) => isActive ? 'white' : theme.colors.Primary};
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  min-width: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
`

export const Emoji = styled('span') <{ size?}>`
  font-size: 34px;
  width: ${({ size }) => size ? size : reaction_size}px;
  height: ${({ size }) => size ? size : reaction_size}px;
  border-radius: 100%;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Reaction = styled('label') <{ index }>`
  ${hidden}
  border: none;
  display: block;
  cursor: pointer;
  position: absolute;
  height: ${reaction_size}px;
  width: ${reaction_size}px;
  z-index: 11;
  top: -15px;
  transform-origin: 50% 100%;
  transform: scale(.1);
  transition: all .3s;
  outline: none;
  will-change: transform;
  opacity: 0;
  left: ${({ index }) => (reaction_size + reaction_margin) * index}px;
`

export const Label = styled('span')`
  background: rgba(0, 0, 0, .75);
  border-radius: 10px;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  text-overflow: ellipsis;
  font-weight: bold;
  line-height: 20px;
  width: max-content;
  opacity: 0;
  overflow: hidden;
  padding: 0 6px;
  transition: opacity 50ms ease;
  left: 50%;
  position: absolute;
  text-align: center;
  top: -28px;
  transform: translateX(-50%);
`

const toolboxMarginBottom = 6;

export const Toolbox = styled('div') <{ reactions }>`
  background: #fff;
  height: ${reaction_size * .8 + 6}px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .08), 0 2px 2px rgba(0, 0, 0, .15);
  width: ${({ reactions }) => getWidth(reactions)};
  border-radius: 40px;
  top: -${reaction_size * .8 + 6 + toolboxMarginBottom}px;
  left: 0;
  margin-bottom: ${toolboxMarginBottom}px;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s;

  &:after {
    position: absolute;
    top: 100%;
    content: "";
    display: block;
    background: transparent;
    width: 100%;
    height: ${toolboxMarginBottom}px;
  }
`

export const Reactions = styled('div') <{ reactions }>`
  display: flex;
`

export const Box = styled('div') <{ reactions }>`
  width: max-content;
  position: relative;

  &:hover {
    ${Reaction} {
      transform: scale(.8) translateY(-${reaction_size * .8 + 6 + toolboxMarginBottom + toolboxMarginBottom / 3}px);
      ${show}

      &.active {
        transform: scale(.95) translateY(-${reaction_size * .95}px);

        ${Emoji}{
          background-color: ${({ theme }) => theme.colors.Primary50};
        }
      }
      
      &:hover,
      &:focus {
        transition: all .2s ease-in;
        transform: scale(.95) translateY(-${reaction_size * .95}px);
        
        ${Label} {
          opacity: 1
        }
      }
    }
    
    ${Toolbox} {
      opacity: 1;
      visibility: visible;
    }
    
    ${Reactions} {
      ${({ reactions }) => reactions.map((_, index) => `& > :nth-child(${index + 1}) { transition-delay: .${3 * index < 10 ? '0' : ''}${3 * index}s; }`)}
    }
  }
`

export const Overlay = styled('label')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  visibility: hidden;
`

export const Checkbox = styled('input')`
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`

const expand = keyframes`
  0% {
    padding: 0px;
    width: 0%;
  }
  100% {
    padding: 0px 5px;
    width: 100%;
  }
`

export const EmojiWithCounter = styled('div')`
  height: 34px;
  padding: 0px 5px;
  border-radius: 17px;
  background-color: rgb(241, 241, 241);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -7px;
  font-size: 24px;
  box-shadow: rgba(0, 0, 0, .4) -1px 0px 1px -1px;
  position: relative;
  cursor: pointer;

  div {
    &.reaction-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    
    &.emoji {
      
    }

    &.counter {
      width: 0px;
      overflow: hidden;
      font-size: 14px;
    }
  }

  &:hover {
    z-index: 10;

    div {
      &.counter {
        animation: ${expand} 0.2s ease-in-out forwards;
      }
    }
  }
  
`

export const Views = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  color: #2D3436;
`