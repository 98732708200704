import Modal from "../../../../../../../components/Modal";
import Button from "../../../../../../../components/Button";
import Submit from "../../../../../../../components/FormikForm/Submit";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../../../store/actions/modal";
import { deletePost, resetDeletePost } from "../../../../../../../store/actions/post";
import { Post } from "../../../../../../../types/post";
import remove from "../../../../../../../assets/remove.svg";
import Image from "../../../../../../../components/Image";
import { Group, PlanDto } from "../../../../../../../types";
import flagArgentina from '../../../../../../../constants/countries/flags/flag-AR.png'
import flagUnitedStates from '../../../../../../../constants/countries/flags/flag-US.png'
import flagEuropeanUnion from '../../../../../../../constants/countries/flags/flag-AR.png'
import { deletePlan, editPlan, resetDeletePlan } from "../../../../../../../store/actions/plan";
import { useMemo } from "react";

const currencies = [
  {
    flag: flagArgentina,
    iso: "ARS",
    label: "AR$",
    description: "Pesos Argentinos (ARS)",
  },
  {
    flag: flagEuropeanUnion,
    iso: "EUR",
    label: "€",
    description: "Euros (EUR)",
  },
  {
    flag: flagUnitedStates,
    iso: "USD",
    label: "US$",
    description: "Dolares Americanos (USD)",
  },
];
interface ComponentProps {
  plan: PlanDto;
  onClose: React.Dispatch<React.SetStateAction<PlanDto | undefined>>
  deletePlanState: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ plan, deletePlanState, onClose }: ComponentProps) => {
  const dispatch = useDispatch();

  const planCurrency = useMemo(() => (currencies.find(currency => currency?.iso === (plan?.currency || 'ARS'))), [plan])

  const handleDeletePost = () => {
    dispatch(deletePlan({ idPlan: plan.id }))
  };

  const onModalClose = () => {
    if (deletePlanState.success) {
      dispatch(resetDeletePlan());
      onClose && onClose(undefined);
    }
  };

  return (
    <Modal name="modal-confirm-plan-deletion" width="650" onClose={() => onModalClose()}>
      {deletePlanState.success ? (
        <>
          <div style={{ textAlign: "center" }}>
            <Image src={remove} width="102px" height="117px" alt={remove} />
          </div>
          <Text>
            <strong style={{ textAlign: "center", display: "block" }}>
              Precio eliminado satisfactoriamente.
            </strong>
          </Text>
        </>
      ) : (
        <>
          <Title>Vas a eliminar un precio</Title>

          <Text>
            Estás por eliminar un precio de la moneda "{planCurrency?.description}". Una vez
            eliminado no podrás recuperarlo y dejara de verse en la página de venta de tu grupo.
          </Text>

          <AlignEnd>
            <Button
              options={{
                type: "outline",
                skin: "purple",
                size: "lg",
              }}
              style={{ marginRight: "16px" }}
              onClick={() => {
                dispatch(hideModal());
                onClose && onClose(undefined);
              }}
            >
              Cancelar
            </Button>
            <Submit
              form=""
              isSubmmiting={deletePlanState.loading}
              options={{
                type: "filled",
                skin: "purple",
                size: "lg",
              }}
              onClick={() => handleDeletePost()}
            >
              Eliminar precio
            </Submit>
          </AlignEnd>
        </>
      )}
    </Modal>
  );
};

const state = ({ planStore }) => {
  const { states: deletePlanState } = planStore.deletePlan;
  return {
    deletePlanState,
  };
};

export default connect(state)(Component);
