import Modal from "../../../../../../../components/Modal";
import NewExperienceCta from "../../../../../components/NewExperienceCard";
import NewMembershipCta from "../../../../../components/NewMembershipCard";
import Row from "../../../../../../../components/Grid/Row";
import Col from "../../../../../../../components/Grid/Col";
import { Title } from "./styles";

const Component = () => {
  return (
    <Modal name="modal-new-group" width="1225">
      <Title>¿Qué tipo de grupo te gustaría comenzar?</Title>
      <Row>
        <Col sm={6}>
          <NewExperienceCta />
        </Col>
        <Col sm={6}>
          <NewMembershipCta />
        </Col>
      </Row>
    </Modal>
  );
};

export default Component;
