import { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import Link from "../../../../../../components/Link";
import {
  Modal,
  ModalDialog,
  Title,
  Text,
  RedirectMsg,
  Button,
  CurrentStep,
  Body,
  Card,
  SubTitle
} from "./styles";
import { Center } from "../../../../../../components/StyledComponents";
import { newTelegramGroup } from "../../../../../../store/actions/group";


interface ComponentProps {
  groupInformation;
  newTelegramGroupInformation;
  newTelegramGroupStates;
}

const availablePlatforms: { [type: string]: ("whatsapp" | "telegram")[] } = {
  broadcast: ["whatsapp", "telegram"],
  broadcast_with_comments: ["telegram"],
  broadcast_with_comments_and_chat: ["telegram"],
  group_chat: ["whatsapp", "telegram"],
};

const typesLabels = {
  broadcast: "Canal de difusión",
  broadcast_with_comments: "Canal de difusión con Comentarios",
  broadcast_with_comments_and_chat: "Canal de difusion y Grupo de Chat",
  group_chat: "Grupo de chat",
};

const Component = ({
  groupInformation,
  newTelegramGroupInformation,
  newTelegramGroupStates,
}: ComponentProps) => {
  const [type, setType] = useState<
    "broadcast" | "broadcast_with_comments" | "broadcast_with_comments_and_chat" | "group_chat"
  >("broadcast_with_comments_and_chat");
  const [platform, setPlatform] = useState<"whatsapp" | "telegram">("telegram");
  const { isMobile } = useWindowSize();
  const [index, setIndex] = useState(1);
  const totalSlides = 3;
  const dispatch = useDispatch();

  const handleNextButtonPressed = () => {
    if (index === totalSlides - 1) {
      return;
    } else {
      setIndex(index + 1);
    }
  };

  const handlePrevButtonPressed = () => {
    setIndex(index - 1);
  };

  const createTelegramGroup = () => {
    if (platform && type) {
      dispatch(
        newTelegramGroup({ group_id: groupInformation.id, type, platform })
      );
    }
  };

  useEffect(() => {
    if (newTelegramGroupStates.success) {
      handleNextButtonPressed();
    }
  }, [newTelegramGroupStates]);

  return (
    <Modal name="modal-telegram" width="650" >
      <ModalDialog>
        <CarouselProvider
          currentSlide={index}
          naturalSlideWidth={305}
          naturalSlideHeight={146}
          totalSlides={totalSlides}
          isIntrinsicHeight={true}
          dragEnabled={false}
          touchEnabled={false}
          infinite={false}
        >
          <Body>
            <Slider>
              <Slide index={0}>
                <Title>
                  <CurrentStep>
                    Crea tu grupo exclusivo en telegram ❤️
                  </CurrentStep>
                </Title>

                <div style={{ height: 50 }} />

                <Text style={{ maxWidth: 420, textAlign: "center" }}>
                  Aqui es donde ocurrira la magia de tu membresia ✨
                  <br />
                  <br />
                  <b>Este es el espacio que compartirás con tus suscriptores,</b> clave para generar comunidad y asegurarte que ningun miembro se pierda de todo tu maravilloso contenido.
                </Text>

                <div style={{ height: 50 }} />

                <Center
                  horizontal="center"
                  vertical="center"
                  gap={20}
                >

                    <Button
                      onClick={createTelegramGroup}
                      disabled={newTelegramGroupStates.loading}
                      options={{
                        type: "filled",
                        skin: "lightBlue",
                        size: "lg",
                      }}
                    >
                      Comenzar
                    </Button>
                  </Center>
              </Slide>

              <Slide index={1}>
                <Title>
                  <CurrentStep>
                    Define la dinamica de tu membresia
                  </CurrentStep>
                </Title>

                <div style={{ height: 50 }} />

                <Center
                  horizontal="center"
                  vertical="top"
                  gap={20}
                  column={false}
                >
                  <div>
                    <Card
                      onClick={() => setType("broadcast_with_comments_and_chat")}
                      className={`${type === "broadcast_with_comments_and_chat" ? "active" : ""
                        }`}
                    >
                      <SubTitle>Canal de difusión 📣 <br /> y Grupo de chat 💬 </SubTitle>
                    </Card>

                    <div style={{ fontSize: 12, lineHeight: "20px", color: "#229ED9", textAlign: "center", marginTop: 7 }}><i>¡La opción más elegída!</i></div>
                  </div>

                  <Card
                    onClick={() => setType("broadcast")}
                    className={`${type === "broadcast" ? "active" : ""}`}
                  >
                    <SubTitle>Canal de difusión 📣</SubTitle>
                  </Card>
                </Center>

                <div style={{ height: 50 }} />

                <Text style={{ textAlign: "left" }}>
                  {type === "broadcast_with_comments_and_chat" && (
                    <>
                      <b>Canal de difusión</b> 📣​ <u>Solo tú puedes publicar contenido</u>. Tus suscriptores reciben  tus novedades de manera ordenada sin perderse en un chat infinito. Además todo lo que subas a tu biblioteca, se replicará automáticamente en este canal.
                      <br />
                      <br />
                      <b>y... Grupo de chat</b> 💬️​ <u>Tus suscriptores pueden chatear libremente</u>. Es fundamental para generar comunidad y que tus suscriptores se acompañen mutuamente.
                    </>
                  )}

                  {type === "broadcast" && (
                    <>
                      <b>Canal de difusión</b> 💬 <u>Solo tú puedes publicar contenido</u>. Tus suscriptores reciben  tus novedades de manera ordenada sin perderse en un chat infinito. Además todo lo que subas a tu biblioteca, se replicará automáticamente en este canal.
                      <br />
                      <br />
                      <b>Importante</b> 💔: <u>Tus suscriptores NO tendran un grupo de chat</u> para conversar y acompañarse mutuamente.
                    </>
                  )}
                </Text>

                <div style={{ height: 50 }} />

                <Center
                  horizontal="center"
                  vertical="center"
                  gap={20}
                >
                  <Button
                    onClick={createTelegramGroup}
                    disabled={newTelegramGroupStates.loading}
                    options={{
                      type: "filled",
                      skin: "lightBlue",
                      size: "lg",
                    }}
                  >
                    Crear y configurar espacios
                  </Button>
                </Center>
              </Slide>

              <Slide index={2}>
                <Title>
                  <CurrentStep>
                    Ingresar a tu {typesLabels[type]} en{" "}
                    {platform === "whatsapp" ? "Whatsapp" : "Telegram"}
                  </CurrentStep>
                </Title>

                <div style={{ height: 50 }} />

                <Text>
                  ¡Felicidades! Tus espacios fueron creados de manera exitosa 🎉
                  <br />
                  <br />

                  Si aún no tienes la aplicacion de{" "}
                  {platform === "whatsapp"
                    ? "Whatsapp"
                    : "Telegram"} instalada,{" "}<br />
                  <Link
                    href={
                      platform === "whatsapp"
                        ? "https://www.whatsapp.com/download"
                        : "https://onelink.to/84gpfk"
                    }
                    target="_blank"
                    options={{
                      type: "link",
                      size: "sm",
                      skin: "lightBlue",
                      native: false,
                    }}
                  >
                    <u><b>descargala</b></u>
                  </Link>{" "}
                  y crea tu cuenta antes de continuar.
                </Text>

                <div style={{ height: 50 }} />

                <Link
                  href={
                    !!groupInformation?.telegram_groups
                      ? groupInformation?.telegram_groups[0]
                        ?.main_invitation_link
                      : newTelegramGroupInformation?.tg_bot_url
                  }
                  options={{
                    skin: "lightBlue",
                    size: "lg",
                    type: "filled",
                    native: false,
                  }}
                  style={{ minWidth: "260px" }}
                  target="_blank"
                >
                  Ir a {platform === "whatsapp" ? "Whatsapp" : "Telegram"}
                </Link>

                <div style={{ height: 15 }} />

                <RedirectMsg>
                  <i>Solo serás re-dirigido si tienes la app de {platform === "whatsapp" ? "Whatsapp" : "Telegram"} instalada</i>
                </RedirectMsg>
              </Slide>
            </Slider>
          </Body>
        </CarouselProvider>
      </ModalDialog>
    </Modal>
  );
};

const state = ({ groupStore }) => {
  const { group, newTelegramGroup } = groupStore;
  const { data: groupInformation } = group;
  const { data: newTelegramGroupInformation, states: newTelegramGroupStates } =
    newTelegramGroup;

  return {
    groupInformation,
    newTelegramGroupInformation,
    newTelegramGroupStates,
  };
};

export default connect(state)(Component);
