interface Subscriber {
  subscriber: {
    cellphone: string | null;
    chargebee_customer_id: string | null;
    email: string;
    id: number;
    last_name: string | null;
    name: string | null;
    phone_area_code: string | null;
    telegram_bot_sync: boolean | null;
    user: number;
  };
}

interface Pagination {
  currentPage: number;
  results: Subscriber[];
  totalCount: number;
  totalPages: number;
}

export const GET_GROUP_SUBSCRIBERS = "GET_GROUP_SUBSCRIBERS";
export const GET_GROUP_SUBSCRIBERS_SUCCESS = "GET_GROUP_SUBSCRIBERS_SUCCESS";
export const GET_GROUP_SUBSCRIBERS_ERROR = "GET_GROUP_SUBSCRIBERS_ERROR";
export const RESET_GROUP_SUBSCRIBERS = "RESET_GROUP_SUBSCRIBERS";

export interface GetGroupSubscribers {
  type: typeof GET_GROUP_SUBSCRIBERS;
  payload: {
    page: number;
    id: number;
  };
}

export interface GetGroupSubscribersSuccess {
  type: typeof GET_GROUP_SUBSCRIBERS_SUCCESS;
  payload: Pagination;
}

export interface GetGroupSubscribersError {
  type: typeof GET_GROUP_SUBSCRIBERS_ERROR;
  payload: boolean | string;
}

export interface ResetGroupSubscribers {
  type: typeof RESET_GROUP_SUBSCRIBERS;
  payload: null;
}

export interface State {
  data: Pagination | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetGroupSubscribers
  | GetGroupSubscribersError
  | GetGroupSubscribersSuccess
  | ResetGroupSubscribers;
