import { ReactElement, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Text } from "./styles";
import Button from '../../../components/Button'
import PricesListSection from './components/PlansListSection'
import Grid from "../../../components/Grid";
import NewPlanSidebar from './components/PlanSidebar/New'
import EditPlanSidebar from './components/PlanSidebar/Edit'
import NewPromotionSidebar from './components/PromotionSidebar/New'
import EditPromotionSidebar from './components/PromotionSidebar/Edit'
import { DiscountDto, Group, PlanDto } from "../../../types";
import { getGroupById } from "../../../store/actions/group";
import { useLayout } from "../../../providers/LayoutProvider";
import { resetCreateNewPlan, resetEditPlan, selectedPlan } from "../../../store/actions/plan";
import Snackbar from "../../../components/Snackbar";
import { ErrorPlan } from "../../../services/plan";
import { resetNewDiscount, resetUpdateDiscount } from "../../../store/actions/discount";
import {Link as RrdLink} from "react-router-dom";
import {showModal} from "../../../store/actions/modal";
import Dropdown from "../../../components/DropdownBis";
import ModalShareLink from "../components/ModalShareLink";

interface ComponentProps {
  children: ReactElement
  group: Group
  groupStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  updateMainCurrencyStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  newPlan: PlanDto
  newPlanStates: {
    success: boolean;
    loading: boolean;
    error: ErrorPlan
  };
  editPlan: PlanDto
  editPlanStates: {
    success: boolean;
    loading: boolean;
    error: ErrorPlan
  };
  newDiscount: DiscountDto
  newDiscountStates: {
    success: boolean;
    loading: boolean;
    error: ErrorPlan
  };
  updateDiscount: DiscountDto
  updateDiscountStates: {
    success: boolean;
    loading: boolean;
    error: ErrorPlan
  };
  match
}
const Component = ({
  children,
  group,
  groupStates,
  updateMainCurrencyStates,
  newPlan,
  newPlanStates,
  editPlan,
  editPlanStates,
  newDiscount,
  newDiscountStates,
  updateDiscount,
  updateDiscountStates,
  match,
}: ComponentProps) => {
  const dispatch = useDispatch()
  const { layout, setLayout } = useLayout();
  const [showNewPlanSidebar, setShowNewPlanSidebar] = useState<boolean>(false);
  const [showEditPlanSidebar, setShowEditPlanSidebar] = useState<boolean>(false);
  const [showNewPromotionSidebar, setShowNewPromotionSidebar] = useState<boolean>(false);
  const [showEditPromotionSidebar, setShowEditPromotionSidebar] = useState<boolean>(false);

  useEffect(() => {
    const otherGroup =
      group &&
      group?.id !== Number(match?.params?.idGroup);

    if (!!match.params) {
      if (
        (otherGroup || !group) &&
        !groupStates.loading
      ) {
        dispatch(getGroupById({ group_id: match.params.idGroup }));
      }
    }
  }, [match]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: group?.group_name,
        description: "Precios",
        back: `/dashboard/${group?.group_type}/${group?.group_type === "community" ? "" : group?.id}`,
      },
      dashboard: {
        tabs: {
          visible: true,
        },
      },
      header: {
        visible: !!group,
        actions: [
          <Dropdown>
            <RrdLink
              to={`/group/${group?.slug}`}
              key={'go-to-preview-btn'}
              target="_blank"
            >
              Vista previa
            </RrdLink>

            <button
              disabled={group?.status === 'draft'}
              onClick={() => {
                const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.slug}`;
                navigator.clipboard.writeText(link);
                dispatch(showModal("modal-share-group-link"));
              }}
              key={'share-experience-btn'}
            >
              Compartir taller
            </button>
          </Dropdown>,
          <Button
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            onClick={() => setShowNewPlanSidebar(true)}
            key="agregar-precio-button"
          >
            Agregar precio
          </Button>
        ],
      },
    });
  }, [group]);


  useEffect(() => {
    if (newPlanStates.success) {
      setShowNewPlanSidebar(false);

      setTimeout(() => {
        dispatch(resetCreateNewPlan());
      }, 5000);
    }
  }, [newPlanStates]);

  useEffect(() => {
    if (editPlanStates.success) {
      setShowEditPlanSidebar(false);

      setTimeout(() => {
        dispatch(resetEditPlan());
      }, 5000);
    }
  }, [editPlanStates]);

  useEffect(() => {
    if (newDiscountStates.success) {
      setShowNewPromotionSidebar(false);

      setTimeout(() => {
        dispatch(resetNewDiscount());
      }, 5000);
    }
  }, [newDiscountStates]);

  useEffect(() => {
    if (newDiscountStates.success) {
      setShowEditPromotionSidebar(false);

      setTimeout(() => {
        dispatch(resetUpdateDiscount());
      }, 5000);
    }
  }, [updateDiscountStates]);

  return (
    <>
      {children}

      <div style={{ height: 32 }} />

      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <Text>
              💸 Configura precios en distintas monedas y con distintas opciones de cobro: mensual, trimestral, anual, pago único, y más! Además elige la moneda principal de tu {group?.group_type === "membership" ? "Membresia" : "Taller"} que será la moneda predeterminada en tu página de ventas.
            </Text>
          </Grid.Col>
        </Grid.Row>

        <div style={{ height: 35 }} />

        <Grid.Row>
          <Grid.Col md={7}>
            <PricesListSection setShowEditPromotionSidebar={setShowEditPromotionSidebar} setShowNewPromotionSidebar={setShowNewPromotionSidebar} setShowNewPlanSidebar={setShowNewPlanSidebar} setShowEditPlanSidebar={setShowEditPlanSidebar} />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <NewPlanSidebar
        onClose={setShowNewPlanSidebar}
        isVisible={showNewPlanSidebar}
        setShowNewPromotionSidebar={setShowNewPromotionSidebar}
      />

      <EditPlanSidebar
        onClose={setShowEditPlanSidebar}
        isVisible={showEditPlanSidebar}
        setShowEditPromotionSidebar={setShowEditPromotionSidebar}
        setShowNewPromotionSidebar={setShowNewPromotionSidebar}
      />

      <NewPromotionSidebar
        onClose={setShowNewPromotionSidebar}
        isVisible={showNewPromotionSidebar}
      />

      <EditPromotionSidebar
        onClose={setShowEditPromotionSidebar}
        isVisible={showEditPromotionSidebar}
      />

      <Snackbar
        visible={newPlanStates.success}
        options={{
          position: "center",
          time: 2000,
          type: "success"
        }}
      >
        Precio creado satisfactoriamente.
      </Snackbar>

      <Snackbar
        visible={!!newPlanStates.error}
        options={{
          position: "center",
          time: 2000,
          type: "error",
        }}
      >
        {newPlanStates?.error?.response?.status === 409 ? "Ya cuentas con un precio con similares caracteristicas." : "Error al crear precio."}
      </Snackbar>

      <Snackbar
        visible={editPlanStates.success}
        options={{
          position: "center",
          time: 2000,
          type: "success"
        }}
      >
        Precio editado satisfactoriamente.
      </Snackbar>

      <Snackbar
        visible={!!editPlanStates.error}
        options={{
          position: "center",
          time: 2000,
          type: "error",
        }}
      >
        Error al editar precio.
      </Snackbar>

      <Snackbar
        visible={newDiscountStates.success}
        options={{
          position: "center",
          time: 2000,
          type: "success"
        }}
      >
        Descuento creado satisfactoriamente.
      </Snackbar>

      <Snackbar
        visible={!!newDiscountStates.error}
        options={{
          position: "center",
          time: 2000,
          type: "error",
        }}
      >
        Error al crear descuento.
      </Snackbar>

      <Snackbar
        visible={updateDiscountStates.success}
        options={{
          position: "center",
          time: 2000,
          type: "success"
        }}
      >
        Descuento editado satisfactoriamente.
      </Snackbar>

      <Snackbar
        visible={!!updateDiscountStates.error}
        options={{
          position: "center",
          time: 2000,
          type: "error",
        }}
      >
        Error al editar el descuento.
      </Snackbar>

      <Snackbar
        visible={updateMainCurrencyStates.success}
        options={{
          position: "center",
          time: 2000,
          type: "success"
        }}
      >
        Se cambió la moneda principal.
      </Snackbar>

      <Snackbar
        visible={!!updateMainCurrencyStates.error}
        options={{
          position: "center",
          time: 2000,
          type: "error",
        }}
      >
        Error al cambiar la moneda principal.
      </Snackbar>

      <ModalShareLink />
    </>
  )
}

const state = ({ groupStore, planStore, discountStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: newPlan, states: newPlanStates } = planStore.newPlan
  const { data: editPlan, states: editPlanStates } = planStore.editPlan
  const { data: newDiscount, states: newDiscountStates } = discountStore.newDiscount
  const { data: updateDiscount, states: updateDiscountStates } = discountStore.updateDiscount
  const { states: updateMainCurrencyStates } = groupStore.updateGroupMainCurrency

  return {
    group,
    groupStates,
    updateMainCurrencyStates,
    newPlan,
    newPlanStates,
    editPlan,
    editPlanStates,
    newDiscount,
    newDiscountStates,
    updateDiscount,
    updateDiscountStates
  };
};

export default connect(state)(Component);
