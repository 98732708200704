import file from "../assets/icons/file-text.svg";
import music from "../assets/icons/headphones.svg";
import video from "../assets/icons/video.svg";

const videoMimes = [
  //"video/quicktime",
  "video/3gpp",
  "video/x-msvideo",
  "video/x-ms-wmv",
  "video/mp4",
  "video/mp4v",
  "video/mpg4",
  "video/x-flv",
  "video/webm",
  "video/x-matroska",
];

const otherMimes = [
  "application/pdf"
]

const audioMimes = [
  "audio/mpeg",
  "audio/mp3",
  "audio/mpeg3",
  "audio/x-mpeg-3",
  "audio/ogg",
  "audio/wav",
  "audio/x-m4a"
];

const imagesMimes = [
  "image/jpeg",
  "image/png",
];


const aceptedMimes = [
  ...otherMimes,
  ...imagesMimes,
  ...audioMimes,
  ...videoMimes,
];

const icons = {
  "application/pdf": file,
  "image/jpeg": null,
  "image/png": null,

  "audio/mpeg": music,
  "audio/ogg": music,
  "audio/wav": music,
  "audio/mp3": music,
  "audio/mpeg3": music,
  "audio/x-mpeg-3": music,

  "video/x-matroska": video,
  "video/quicktime": video,
  "video/3gpp": video,
  "video/x-msvideo": video,
  "video/x-ms-wmv": video,
  "video/mp4": video,
  "video/mp4v": video,
  "video/mpg4": video,
};

const files = {
  aceptedMimes,
  imagesMimes,
  audioMimes,
  videoMimes,
  otherMimes,
  icons,
};

export default files;
