import { GetPostsOutDto, Post } from "../../../types/post";

export const GET_SCHEDULED_POSTS = "GET_SCHEDULED_POSTS";
export const GET_SCHEDULED_POSTS_SUCCESS = "GET_SCHEDULED_POSTS_SUCCESS";
export const GET_SCHEDULED_POSTS_ERROR = "GET_SCHEDULED_POSTS_ERROR";
export const UPDATE_GET_SCHEDULED_POSTS = "UPDATE_GET_SCHEDULED_POSTS";
export const RESET_GET_SCHEDULED_POSTS = "RESET_GET_SCHEDULED_POSTS";

export interface GetScheduledPosts {
  type: typeof GET_SCHEDULED_POSTS;
  payload: GetPostsOutDto;
}

export interface UpdateGetScheduledPosts {
  type: typeof UPDATE_GET_SCHEDULED_POSTS;
  payload: Post[];
}

export interface GetScheduledPostsError {
  type: typeof GET_SCHEDULED_POSTS_ERROR;
  payload: boolean | string;
}

export interface GetScheduledPostsSuccess {
  type: typeof GET_SCHEDULED_POSTS_SUCCESS;
  payload: Post[];
}

export interface ResetGetScheduledPosts {
  type: typeof RESET_GET_SCHEDULED_POSTS;
  payload: null;
}

export interface State {
  data: Post[] | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetScheduledPosts
  | GetScheduledPostsError
  | GetScheduledPostsSuccess
  | UpdateGetScheduledPosts
  | ResetGetScheduledPosts;
