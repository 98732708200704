import {
  initialState,
  Actions,
  State,
  USER_IS_LOGGEDIN,
} from "../../types/user/user-is-logged-in";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case USER_IS_LOGGEDIN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    default:
      return state;
  }
};

export default reducers;
