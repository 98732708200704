import action from "../helpers/rest-client";

export class ErrorSurvey extends Error {}

class Survey {
  public async new(payload) {
    return action
      .Post({
        url: `/api/survey`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async update({ id: idSurvey, ...rest }) {
    return action
      .Put({
        url: `/api/survey/${idSurvey}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async delete({ idSurvey }) {
    return action
      .Del({
        url: `/api/survey/${idSurvey}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getOne({ idSurvey }) {
    return action
      .Get({
        url: `/api/survey/${idSurvey}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  }

  public async getAll({ idGroup }) {
    return action
      .Get({
        url: `/api/survey?group_id=${idGroup}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async newAnswer(payload) {
    return action
      .Post({
        url: `/api/survey/answers`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getAllSurveyAnswers({ idSurvey }) {
    return action
      .Get({
        url: `/api/survey/${idSurvey}/answers`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async exportSurveyAnswers(payload) {
    action
      .Get({
        url: `/api/survey/export/survey-answers/${payload}`,
      })
      .then((response) => {
        const binary = new Uint8Array(response.data);

        const buftype =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";

        const url = window.URL.createObjectURL(
          new Blob([binary], {
            type: buftype,
          })
        );

        // Create blob link to download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `respuestas-encuesta-${payload}` + ".xlsx"
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      })
      .catch((error) => {
        return error;
      });
  }
}

const survey = new Survey();
export default survey;
