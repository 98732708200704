import styled, { css } from "styled-components";
import UiAvatar from "../../../../../components/Avatar";

import LinkUi from "../../../../../components/Link";
import ButtonUi from "../../../../../components/Button";

import {
  rainbowTextGradient,
  rainbowBackgroundGradient,
} from "../../../../../components/StyledComponents";

export const Button = styled(ButtonUi)`
  ${rainbowBackgroundGradient}
  border: 0px;
`;

export const Link = styled(LinkUi) <{ options }>`
  ${rainbowBackgroundGradient}
  border: 0px;
`;

export const OutlineLink = styled(LinkUi) <{ options }>`
  ${rainbowBackgroundGradient}
  padding: 0px;
  border: 0px;
  height: ${({ options }) => (options.size === "lg" ? "40px" : "32px")};
  position: relative;

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 5px;
  }

  span {
    ${rainbowTextGradient}
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 5px;
  }
`;

export const Section = styled.div`
  background-color: white;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(38, 50, 56, 0.08);
  //padding: 24px 0px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin-bottom: -56px;
    padding: 0px;
  }
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    flex-direction: row;
  }
`;

export const Expert = styled.div`
  display: none;
  flex-align: center;
  align-items: center;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    display: flex;
  }
`;

export const Avatar = styled(UiAvatar)`
  width: 40px;
  height: 40px;
  background-color: #c4c4c4;
  border: 2px solid white;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
`;

export const Name = styled.span`
  color: #2d3436;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  margin-left: 15px;
`;
