import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  Redirect,
} from "react-router-dom";
import Layout from "../../components/Layout/MainLayout";

import Telegram from "./Telegram";
import Congreso from "./Landing";

const Component = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`} component={Congreso} />
      <Route
        path={`${path}/telegram`}
        component={() => (
          <Layout useLocation={useLocation}>
            <Telegram />
          </Layout>
        )}
      />
      <Route path={`${path}/*`} render={() => <Redirect to={"/404"} />} />
    </Switch>
  );
};

export default Component;
