import Grid from '../../../../Grid'
import {
  BackgroundImage,
  ProfileImage
} from './styles'

const CoverPic = ({ coverPicUrl, profilePicUrl }) => {
  return (
    <Grid.Container>
      <Grid.Row style={{ alignItems: 'center' }}>
        <Grid.Col xs={12} lg={10} offset={{ lg: 1 }} >
          <BackgroundImage style={{ backgroundImage: `url(${coverPicUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }} />
          <ProfileImage loading="lazy" src={profilePicUrl} alt={"Profile Image"} />
          <div style={{ height: 46 }} />
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default CoverPic;