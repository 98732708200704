import styled from "styled-components";

export const Subscribe = styled("section")`
  padding: 100px 0px;
  background-color: #452f61;
`;

export const Title = styled("h1")`
  margin: 0px 0px 30px;
  font-size: 48px;
  line-height: 1;
  text-align: center;
  color: white;
`;

export const SubTitle = styled("p")`
  margin: 0px auto 30px;
  font-size: 16px;
  max-width: 500px;
  text-align: center;
  color: white;
`;

export const Img = styled("img")`
  width: 100%;
  height: 100%;
  background-color: blue;
`;

export const Button = styled("button")`
  padding: 10px 20px;
  margin: 0px;
  border: 0px;
  border-radius: 8px;
  background-color: #bb85ff;
  color: white;
  font-weight: 600;
  font-size: 32px;
  cursor: pointer;
`;

export const Center = styled("div")`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
