import {
  GET_EXPERT_TELEGRAM_GROUP,
  GetExpertTelegramGroup,
  RESET_GET_EXPERT_TELEGRAM_GROUP,
  ResetGetExpertTelegramGroup,
} from "../types/group/get-expert-telegram-group";

import {
  GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS,
  GetExpertTelegramBotForNotifications,
  RESET_GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_GROUP,
  ResetGetExpertTelegramBotForNotifications,
} from "../types/group/get-expert-telegram-bot-url-for-notifications";

import {
  GET_GROUP_BY_ID,
  GetGroupById,
  GET_GROUP_BY_SLUG,
  GetGroupBySlug,
  DELETE_COVER_PIC,
  DeleteCoverPic,
  ResetGetGroup,
  RESET_GET_GROUP
} from "../types/group/get-group";

import {
  GET_GROUP_SUBSCRIBERS,
  RESET_GROUP_SUBSCRIBERS,
  ResetGroupSubscribers,
  GetGroupSubscribers,
} from "../types/group/get-group-subscribers";
import { GetGroupMetrics, GET_GROUP_METRICS } from "../types/group/get-metrics";

import {
  GET_SUBSCRIBER_TELEGRAM_GROUP,
  GetSubscriberTelegramGroup,
} from "../types/group/get-subscriber-telegram-group";

import {
  NEW_GROUP,
  NewGroup,
  RESET_NEW_GROUP,
  ResetNewGroup,
} from "../types/group/new-group";

import {
  NEW_TELEGRAM_GROUP,
  NewTelegramGroup,
} from "../types/group/new-telegram-group";

import {
  UPDATE_GROUP,
  UpdateGroup,
  RESET_UPDATE_GROUP,
  ResetUpdateGroup,
} from "../types/group/update-group";

import { DeleteReview, DELETE_REVIEW } from "../types/group/delete-review";

import {
  GetGroupLastActivity,
  GET_GROUP_LAST_ACTIVITY,
} from "../types/group/get-group-last-activity";
import { UpdateGroupMainCurrency, UPDATE_GROUP_MAIN_CURRENCY } from "../types/group/update-group-main-currency";

export const getGroupBySlug = (slug: string): GetGroupBySlug => {
  return { type: GET_GROUP_BY_SLUG, payload: slug };
};

export const getGroupById = (payload): GetGroupById => {
  return { type: GET_GROUP_BY_ID, payload };
};

export const resetGetGroup = (): ResetGetGroup => {
  return { type: RESET_GET_GROUP, payload:null };
};

export const getSubscriberTelegramGroup = (
  id: string | number
): GetSubscriberTelegramGroup => {
  return { type: GET_SUBSCRIBER_TELEGRAM_GROUP, payload: id };
};

export const getExpertTelegramGroup = (
  id: number | string
): GetExpertTelegramGroup => {
  return { type: GET_EXPERT_TELEGRAM_GROUP, payload: id };
};

export const getExpertTelegramBotUrlForNotifications = (): GetExpertTelegramBotForNotifications => {
  return { type: GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS, payload: null };
};

export const resetExpertTelegramBotUrlForNotifications = (): ResetGetExpertTelegramBotForNotifications => {
  return { type: RESET_GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_GROUP, payload: null };
};

export const resetGetExpertTelegramGroup = (): ResetGetExpertTelegramGroup => {
  return { type: RESET_GET_EXPERT_TELEGRAM_GROUP, payload: null };
};

export const getMetrics = (payload: {
  id;
  filters;
}): GetGroupMetrics => {
  return { type: GET_GROUP_METRICS, payload: payload };
};

export const newTelegramGroup = (payload: {
  group_id: number;
  platform: "whatsapp" | "telegram";
  type: "broadcast" | "broadcast_with_comments" | "group_chat" | "broadcast_with_comments_and_chat";
}): NewTelegramGroup => {
  return { type: NEW_TELEGRAM_GROUP, payload };
};

export const newGroup = (payload: any): NewGroup => {
  return { type: NEW_GROUP, payload };
};

export const resetNewGroup = (): ResetNewGroup => {
  return { type: RESET_NEW_GROUP, payload: null };
};

export const updateGroup = (payload: any): UpdateGroup => {
  return { type: UPDATE_GROUP, payload };
};

export const updateGroupMainCurrency = (payload: { main_currency: 'ARS' | 'USD' | 'EUR', group_id: number }): UpdateGroupMainCurrency => {
  return { type: UPDATE_GROUP_MAIN_CURRENCY, payload };
};

export const resetUpdateGroup = (): ResetUpdateGroup => {
  return { type: RESET_UPDATE_GROUP, payload: null };
};

export const deleteCoverPic = (): DeleteCoverPic => {
  return { type: DELETE_COVER_PIC, payload: null };
};

export const deleteReview = (reviewId): DeleteReview => {
  return { type: DELETE_REVIEW, payload: reviewId };
};

export const resetGroupSubscribers = (): ResetGroupSubscribers => {
  return { type: RESET_GROUP_SUBSCRIBERS, payload: null };
};

export const getGroupSubscribers = (payload: {
  id;
  page;
  filters;
}): GetGroupSubscribers => {
  return { type: GET_GROUP_SUBSCRIBERS, payload };
};

export const getGroupLastActivity = (payload: {
  group_id: number;
  size?: number;
}): GetGroupLastActivity => {
  return { type: GET_GROUP_LAST_ACTIVITY, payload };
};
