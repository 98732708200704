import { Group } from "../../../../types";

export const GET_GROUP_BY_SLUG = "GET_GROUP_BY_SLUG";
export const GET_GROUP_BY_SLUG_SUCCESS = "GET_GROUP_BY_SLUG_SUCCESS";
export const GET_GROUP_BY_SLUG_ERROR = "GET_GROUP_BY_SLUG_ERROR";

export interface GetGroupBySlug {
  type: typeof GET_GROUP_BY_SLUG;
  payload: string;
}

export interface GetGroupBySlugSuccess {
  type: typeof GET_GROUP_BY_SLUG_SUCCESS;
  payload: Group;
}

export interface GetGroupBySlugError {
  type: typeof GET_GROUP_BY_SLUG_ERROR;
  payload: boolean | string;
}
