import { Monetize, Guide, Title } from "./styles";
import { ImageWrapper, Image, Denomination } from "../../components/styles";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import Grid from "../../../../components/Grid";
import Row from "../../../../components/Grid/Row";
import Col from "../../../../components/Grid/Col";
import constants from "../../../../constants";
import { useState } from "react";
import Acordeon from "../../components/Acordeon";

const Component = () => {
  const [currentItem, setCurrentItem] = useState(0);
  const { isMobile } = useWindowSize();

  return (
    <Monetize>
      <Grid.Container {...(!isMobile && { gutter: 0 })}>
        <Row {...(!isMobile && { gutter: 0 })}>
          <Col {...(!isMobile && { gutter: 0 })}>
            <Row {...(!isMobile && { gutter: 0 })}>
              <Col offset={{ sm: 1 }} sm={5} {...(!isMobile && { gutter: 0 })}>
                <Denomination>
                  <Guide>MONETIZA</Guide>
                  <Title>Monetiza tu comunidad</Title>
                </Denomination>

                {isMobile && (
                  <ImageWrapper>
                    <Image
                      src={constants.homePage.monetize[currentItem].image}
                      alt=""
                      width=""
                      height=""
                      lazy={true}
                    />
                  </ImageWrapper>
                )}

                <Acordeon
                  onChange={setCurrentItem}
                  items={constants.homePage.monetize}
                  align="left"
                  theme="white"
                />
              </Col>

              {!isMobile && (
                <Col
                  offset={{ sm: 1 }}
                  sm={5}
                  {...(!isMobile && { gutter: 0 })}
                >
                  <ImageWrapper>
                    <Image
                      src={constants.homePage.monetize[currentItem].image}
                      alt=""
                      width=""
                      height=""
                      lazy={true}
                    />
                  </ImageWrapper>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Grid.Container>
    </Monetize>
  );
};

export default Component;
