import React, { useEffect, useState } from "react";
import firebase from "firebase/app";
import app from "../../firebase";
import Loader from "../../components/LogoLoader";
import { useDispatch } from "react-redux";
import { getUserInformation, resetUserStore } from "../../store/actions/user";

import { TagManager } from "../../helpers/ga-manager";
import DataLayers from "../../constants/gtm";
import { getUrlParamByName } from "../../helpers/get-url-param";

export const AuthContext = React.createContext({
  user: null as firebase.User | null | undefined,
});

export const AuthProvider = ({ children }: any) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState<firebase.User | null | undefined>(undefined);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    /**
     * Magic Link
     * Recuperar password
     */
    if (app.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email =
        window.localStorage.getItem("emailForSignIn") ||
        getUrlParamByName("email");

      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email =
          window.prompt(
            "Para iniciar sesión por favor escribe nuevamente el email asociado a tu cuenta"
          ) || "";
      }

      // The client SDK will parse the code from the link for you.
      app
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          console.log("ERROR:", error);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }

    /**
     * Listener Login/Registro
     */
    app.auth().onAuthStateChanged((user) => {
      if (!!user) {
        dispatch(getUserInformation());
      } else {
        dispatch(resetUserStore());
      }

      setUser(user);
      setPending(false);
    });
  }, []);

  if (pending && user === undefined) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={{ user }}>
      {!pending && children}
    </AuthContext.Provider>
  );
};
