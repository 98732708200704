import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import { useEffect } from "react";

const Component = () => {
  const dispatch = useDispatch();

  return (
    <Modal name="modal-share-session-link" width="650" onClose={() => { }}>
      <Title>Se copió el link de tu encuentro</Title>

      <Text>
        Ahora puedes compartirlo en redes sociales y a todos tus contactos para
        promocionar tu encuentro. 🙌
      </Text>

      <AlignEnd>
        <Button
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
          }}
          onClick={() => dispatch(hideModal())}
        >
          Entendido
        </Button>
      </AlignEnd>
    </Modal>
  );
};

const state = ({ discountStore }) => {
  const { data: allDiscounts, states: allDiscountsStates } =
    discountStore.allDiscounts;
  const { data: newDiscount, states: newDiscountStates } =
    discountStore.newDiscount;

  return { allDiscounts, allDiscountsStates, newDiscount, newDiscountStates };
};

export default connect(state)(Component);
