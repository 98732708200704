import { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../../../../../components/Modal";
import congratulation from "../../../../../../../../../../assets/congratulation.svg";
import { Image, Title, Text } from "./styles";
import { useTheme } from "styled-components";
import { Formik } from "formik";
import Form from '../../../Form'
import Submit from "../../../../../../../../../../components/FormikForm/Submit";
import Link from "../../../../../../../../../../components/Link";
import {
  initialValues,
  schema,
} from "../../../../../../../../../../constants/forms/invitations";
import dateHelpers from "../../../../../../../../../../helpers/dates-hanlder"
import Spinner from '../../../../../../../../../../components/Loader'
import Icon from "../../../../../../../../../../components/Icon";
import Button from "../../../../../../../../../../components/Button";
import Textarea from "../../../../../../../../../../components/FormikForm/TextArea";
import { Center } from "../../../../../../../../../../components/StyledComponents";

import CalendarIcon from "../../../../../../../../assets/icons/calendar.svg";
import CloseCircledIcon from "../../../../../../../../assets/icons/close_circled.svg";
import SendIcon from "../../../../../../../../../../assets/icons/send.svg";
import AddCircledIcon from "../../../../../../../../assets/icons/add_circled.svg";

import { Group } from "../../../../../../../../../../types";
import { createInvitation, resetCreateInvitation } from "../../../../../../../../../../store/actions/invitation";
import { CreateInvitationOutDto } from "../../../../../../../../../../types/invitation.dto";
import { CreateInvitation } from "../../../../../../../../../../store/types/invitation/create";
import { hideModal } from "../../../../../../../../../../store/actions/modal";

const Component = ({
  onSubmit,
  emails,
  group,
  createdInvitation,
  createInvitationStates
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (createInvitationStates.success) {
      setTimeout(() => {
        dispatch(hideModal())
      }, 500)
    }
  }, [createInvitationStates])

  return (
    <Modal name="confirm-send-modal" width="500" showClose={true}>
      <Title>Estás a punto de enviar <strong>{emails.length > 1 ? `${emails.length} invitaciones` : '1 invitación'}.</strong></Title>

      {group?.group_type === "membership" && (
                <Text>⚠️ Por cada suscriptor que acepte la invitación y haga uso del acceso, se incurrirá en una pequeña tarifa{" "}
                <Link
                  href="https://www.notion.so/klouser/C-mo-funcionan-los-costos-de-los-Invitados-con-Acceso-Libre-c135cf7c7ca34d15a8a133074da5aae9"
                  target={"_blank"}
                  options={{
                    type: "link",
                    size: "sm",
                    skin: "purple",
                    native: true
                  }}
                >
                  Lee más para enterarte cómo funciona
                </Link>
              </Text>
      )}

      {group?.group_type === "experience" && (
        <Text>⚠️ Por cada suscriptor que acepte la invitación y haga uso del acceso, se incurrirá en una pequeña tarifa{" "}
          <Link
            href="https://www.notion.so/klouser/C-mo-funcionan-los-costos-de-los-Invitados-con-Acceso-Libre-c135cf7c7ca34d15a8a133074da5aae9"
            target={"_blank"}
            options={{
              type: "link",
              size: "sm",
              skin: "purple",
              native: true,
            }}
          >
            Lee más para enterarte cómo funciona
          </Link>

        </Text>
      )}

      <div style={{ height: 20 }} />

      <Text> Para generar los accesos toca en <strong>"Confirmar"</strong></Text>

      <div style={{ height: 40 }} />

      <Center horizontal="center" vertical="center" gap={12}>


        <Button
          options={{
            type: "outline",
            skin: "purple",
            size: "lg",
            block: true,
          }}
          onClick={() => {
            dispatch(hideModal())
          }}
        >
          Cancelar
        </Button>

        <Button
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
            block: true,
          }}
          onClick={onSubmit}
        >
          <Center vertical="center" horizontal="center" gap={5}>
            {createInvitationStates?.loading ? (
              <Spinner color="white" />
            ) : (
              <>
                <Icon icon={SendIcon} color="#FFFFFF" size="20px" />
                Confirmar
              </>
            )}
          </Center>
        </Button>
      </Center>
    </Modal>
  )
}


const state = ({ groupStore, invitationStore }) => {
  const { data: group } = groupStore.group;
  const { data: createdInvitation, states: createInvitationStates } = invitationStore.createInvitation;

  return {
    group,
    createdInvitation,
    createInvitationStates
  };
};

export default connect(state)(Component);