import {
  NewExperience,
  Experience,
  ToCreate,
  FormTitle,
  Explanation,
  Img,
  HorizontalSeparator,
  Boton,
} from "./styles";
import logo from "./../../../../assets/dashboard-home/skateboard-woman.svg";
import Link from "../../../../components/Link";
import constants from "../../../../constants";

const Component = () => {
  return (
    <NewExperience>
      <Experience>
        <ToCreate>
          <FormTitle>Crea una Experiencia</FormTitle>
          <Explanation>
            Tus seguidores abonarán un pago único para acceder a una experiencia
            con fecha de finalización. Por ejemplo: Challenge, Curso, Taller,
            Masterclass o un Evento especial, entre otros.
          </Explanation>
        </ToCreate>
        <Img src={logo} alt="newExperience" width="200px" height="188px" />
      </Experience>
      <HorizontalSeparator />

      <Boton>
        <Link
          href={constants.urls.dashboard.newExperience.url}
          options={{
            size: "sm",
            type: "outline",
            skin: "purple",
          }}
        >
          Quiero crear una experiencia
        </Link>
      </Boton>
    </NewExperience>
  );
};

export default Component;
