import Sidebar from "../../../../../../components/Sidebar";
import Button from "../../../../../../components/Button";
import {
  Preview,
  Title,
  Text,
  Option,
  StrongLabel,
  StrongValue,
} from "../styles";
import { DiscountDto } from "../../../../../../types/discount";
import { useEffect, useState } from "react";
import { Formik } from "formik";
import { connect, useDispatch } from "react-redux";
import {
  initialValues,
  schema,
} from "../../../../../../constants/forms/coupon";
import Submit from "../../../../../../components/FormikForm/Submit";
import Input from "../../../../../../components/FormikForm/Input";
import { updateDiscount } from "../../../../../../store/actions/discount";
import {
  inputDate,
  inputDateTimeLocal,
  setToZeroTimeZone,
} from "../../../../../../helpers/dates-hanlder";
import moment from "moment";

const groupType = {
  membership: "Membresia",
  experience: "Experiencia",
};

interface ComponentProps {
  coupon?: DiscountDto;
  onClose?: (data: any) => void;
  isVisible: boolean;
  updateDiscountStates;
}

const Component = ({
  coupon,
  onClose,
  isVisible,
  updateDiscountStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState(false);

  const onSubmit = ({ values, actions }) => {
    dispatch(
      updateDiscount({
        ...coupon,
        ...values,
        valid_till: setToZeroTimeZone(values.valid_till),
      })
    );
  };

  useEffect(() => {
    if (updateDiscountStates.success) {
      !!onClose && onClose(false);
    }
  }, [updateDiscountStates]);

  return (
    <>
      <Sidebar
        position="right"
        visible={isVisible}
        closeType="back"
        onClose={() => {
          !!onClose && onClose(false);
        }}
        width="463px"
        title="Cupón de descuento"
      >
        <Preview>
          <div>
            <Text>
              Al editar un cupón que ya fue utilizado, sólo podrás editar el
              campo “Cupón válido hasta”.
            </Text>

            <Option>
              <StrongLabel>Tipo de actividad</StrongLabel>
              <StrongValue>
                {" "}
                {coupon && groupType[coupon?.group_type]}
              </StrongValue>
            </Option>

            <Option>
              <StrongLabel>Código de cupón</StrongLabel>
              <StrongValue> {coupon?.coupon_id}</StrongValue>
            </Option>

            <Option>
              <StrongLabel>Nombre del evento</StrongLabel>
              <StrongValue> {coupon?.group_name}</StrongValue>
            </Option>

            {!!coupon?.redemptions && coupon?.redemptions > 0 && (
              <Option>
                <StrongLabel>Porcentaje de descuento</StrongLabel>
                <StrongValue> {coupon?.amount}%</StrongValue>
              </Option>
            )}

            <Option>
              <Formik
                initialValues={{
                  ...initialValues,
                  ...(!!coupon
                    ? {
                      group_type: coupon?.group_type,
                      group_id: coupon?.group_id,
                      amount: coupon?.amount,
                      valid_till: coupon?.valid_till,
                      period: coupon?.period,
                      period_unit: coupon?.period_unit,
                    }
                    : {}),
                }}
                onSubmit={(values, actions) => {
                  if (onSubmit) onSubmit({ values, actions });
                }}
                validateOnChange={formSubmmited}
                validateOnBlur={false}
                validationSchema={schema}
                enableReinitialize
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <form
                      className="theme-form"
                      onSubmit={(event) => {
                        setFormSubmmited(true);
                        handleSubmit(event);
                      }}
                      id="edit-coupon-form"
                    >
                      {!!coupon?.redemptions && coupon?.redemptions <= 0 && (
                        <Input
                          name="amount"
                          error={errors["amount"]}
                          touched={touched["amount"]}
                          value={values["amount"]}
                          type="number"
                          step="any"
                          placeholder="Ej: 10"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          options={{
                            label: "Porcentaje de descuento",
                            after: "%",
                            marginBottom: 16,
                          }}
                        />
                      )}

                      <Input
                        name="valid_till"
                        error={errors["valid_till"]}
                        touched={touched["valid_till"]}
                        type="datetime-local"
                        placeholder="dd/mm/aa"
                        value={inputDateTimeLocal(values?.valid_till)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={{
                          label: "Cupón válido hasta",
                          marginBottom: 16,
                        }}
                      />

                      {values.group_type === "membership" &&
                        !!coupon?.redemptions &&
                        coupon?.redemptions <= 0 && (
                          <Input
                            name="period"
                            error={errors["period"]}
                            touched={touched["period"]}
                            value={values["period"]}
                            type="number"
                            placeholder="Número de meses"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={{
                              label: "Descuento válido por",
                              after: "meses",
                              explainText:
                                "Elige cuánto tiempo se aplicará el descuento. Por ejemplo, si eliges 3 meses, las personas pagarán tu membresía con descuento durante los próximos 3 meses.",
                              marginBottom: 0,
                            }}
                          />
                        )}
                    </form>
                  );
                }}
              </Formik>
            </Option>
          </div>

          <div>
            <Submit
              isSubmmiting={updateDiscountStates.loading}
              form="edit-coupon-form"
              options={{
                type: "filled",
                skin: "purple",
                size: "lg",
                block: true,
              }}
              style={{ marginBottom: "8px" }}
            >
              Guardar Cambios
            </Submit>

            <Button
              options={{
                type: "outline",
                skin: "purple",
                size: "lg",
                block: true,
              }}
              onClick={() => !!onClose && onClose(false)}
            >
              Cancelar
            </Button>
          </div>
        </Preview>
      </Sidebar>
    </>
  );
};

const state = ({ discountStore }) => {
  const { states: updateDiscountStates } = discountStore.updateDiscount;

  return {
    updateDiscountStates,
  };
};

export default connect(state)(Component);
