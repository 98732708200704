import Grid from "../../../../../components/Grid";
import { About, Title, SubTitle, Img } from "./styles";

const Component = () => {
  return (
    <About id="mas-info">
      <Grid.Container>
        <Grid.Row>
          <Grid.Col lg={10} offset={{ lg: 1 }}>
            <Title>
              Eres bienvenido al primer
              <br /> Congreso de Bienestar
            </Title>

            <SubTitle>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus
              orci a risus mollis imperdiet. Ut sit amet velit elementum,
              hendrerit leo a, auctor metus. Proin finibus dolor eget suscipit
              eleifend.
            </SubTitle>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col lg={4} offset={{ lg: 2 }}>
            <Img />
          </Grid.Col>
          <Grid.Col lg={4}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus
              orci a risus mollis imperdiet. Ut sit amet velit elementum,
              hendrerit leo a, auctor metus. Proin finibus dolor eget suscipit
              eleifend.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus
              orci a risus mollis imperdiet. Ut sit amet velit elementum,
              hendrerit leo a, auctor metus. Proin finibus dolor eget suscipit
              eleifend. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Ut luctus orci a risus mollis imperdiet. Ut sit amet velit
              elementum, hendrerit leo a, auctor metus. Proin finibus dolor eget
              suscipit eleifend.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus
              orci a risus mollis imperdiet. Ut sit amet velit elementum,
              hendrerit leo a, auctor metus. Proin finibus dolor eget suscipit
              eleifend.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus
              orci a risus mollis imperdiet. Ut sit amet velit elementum,
              hendrerit leo a, auctor metus. Proin finibus dolor eget suscipit
              eleifend. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Ut luctus orci a risus mollis imperdiet. Ut sit amet velit
              elementum, hendrerit leo a, auctor metus. Proin finibus dolor eget
              suscipit eleifend.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus
              orci a risus mollis imperdiet. Ut sit amet velit elementum,
              hendrerit leo a, auctor metus. Proin finibus dolor eget suscipit
              eleifend.
            </p>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </About>
  );
};

export default Component;
