import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { showModal } from "../../../../../../store/actions/modal";
import { useTheme } from 'styled-components'
import ModalConfirmDelete from '../../../components/ModalConfirmDelete'
import Grid from "../../../../../../components/Grid";
import Image from "../../../../../../components/Image";
import Swipe from "../../../../../../components/Swipe";
import { Post } from "../../../../../../types/post";
import GoBack from "../../../../../../components/GoBack";
import Link from "../../../../../../components/Link";
import Icon from "../../../../../../components/Icon";
import Button from "../../../../../../components/Button";
import { isOwnArticle } from "../../../../../../helpers/isOwnArticle";
import {
  ShareCta,
  SwipeContent,
  Actions,
  MenuCta,
  Name,
  Avatar,
  AvatarImage,
  CreatedAt,
  Dropdown,
  ButtonItem,
  LinkItem,
} from "./styles";
import { Center } from '../../../../../../components/StyledComponents'

import dotsVerticalIcon from "../../../../../../assets/icons/more-vertical.svg";
import shareIcon from "../../../../../../assets/icons/share.svg";
import pencilIcon from "../../../../../../assets/icons/post-edit-icon.svg";
import { getUrlParamByName } from "../../../../../../helpers/get-url-param";
import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import { dateSpliter } from "../../../../../../helpers/dates-hanlder";
import userAvatar from "../../../../../../assets/avatar.svg";
import schedulerIcon from "../../../../../../assets/scheduler-icon.svg";
import checkIcon from "../../../../../../assets/icons/check-light.svg";
import { WelcomeContentPostDto } from "../../../../../../types/welcome-section";
import { getUserInformation } from "../../../../../../store/actions/user";
import CopyToClipboad from "../../../../../../components/CopyToClipboad";

interface ComponentProps {
  userInformation;
  welcomeContentPost: WelcomeContentPostDto;
  viewAsSubscriber: boolean
}

const Component = ({ userInformation, welcomeContentPost, viewAsSubscriber }: ComponentProps) => {
  const theme = useTheme() as { colors: { Primary: string } }
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  const createdAt = useMemo(() => dateSpliter(welcomeContentPost?.published_at), [welcomeContentPost])

  useEffect(() => {
    if (!userInformation) {
      dispatch(getUserInformation())
    }
  }, [])

  return (
    <>
      <Center vertical="center" horizontal="space">
        <AvatarImage>
          <Avatar
            src={
              welcomeContentPost?.welcome_section?.group?.profile_pic?.url ||
              welcomeContentPost?.welcome_section?.group?.expert?.profile_pic?.url ||
              userAvatar
            }
            alt={welcomeContentPost?.welcome_section?.group?.expert?.username}
            width="55px"
            height="55px"
          />

          <Center horizontal="center" vertical="top" column gap={2}>
            <Name>
              {welcomeContentPost?.welcome_section?.group?.creator_name ||
                welcomeContentPost?.welcome_section?.group?.expert?.username}
            </Name>
            <CreatedAt>
              <>
                {createdAt?.date}
                <span>|</span>
                <span style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  <Icon icon={checkIcon} color="#2D3436" size="14px" />
                  Publicado
                </span>
              </>
            </CreatedAt>
          </Center>
        </AvatarImage>

        <Actions>
          {/* 
          <Link
            target={"_blank"}
            options={{
              type: "link",
              size: "lg",
              skin: "white",
            }}
            href={`/group/${welcomeContentPost?.welcome_section?.group?.id}/p/${welcomeContentPost?.id}?viewAsSubscriber=true`}
          >
            Ver como suscriptor
          </Link>
          */}

          {isOwnArticle(welcomeContentPost?.welcome_section, userInformation?.expert?.id) && !viewAsSubscriber && (
            <>
              {!isMobile &&
                <Link
                  options={{
                    type: "link",
                    size: "lg",
                    skin: "purple",
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 6,
                    textDecoration: 'none'
                  }}
                  href={`/dashboard/${welcomeContentPost?.welcome_section?.group?.group_type}/${welcomeContentPost?.welcome_section?.group?.id}/welcome-content/${welcomeContentPost?.id}`}
                >
                  <Icon icon={pencilIcon} size="24px" color={theme.colors.Primary} />
                  Editar
                </Link>
              }

              <CopyToClipboad
                copy={`${process.env.REACT_APP_SITE_BASE_URL}/group/${welcomeContentPost?.welcome_section?.group?.id}/wc/${welcomeContentPost?.id}`}
                showChildren
                iconColor="white"
                style={{
                  width: "auto",
                }}
                successStyle={{
                  position: "absolute",
                  left: "100%",
                  top: "50%",
                  transform: "translate(0%, -50%)",
                }}
              >
                <ShareCta
                  type="button"
                  onClick={() => {
                    const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${welcomeContentPost?.welcome_section?.group?.id}/wc/${welcomeContentPost?.id}`;
                    navigator.clipboard.writeText(link);
                  }}
                >
                  <Icon icon={shareIcon} size="24px" color={theme.colors.Primary} />
                  {!isMobile && "Compartir"}
                </ShareCta>
              </CopyToClipboad>

              {isMobile ? (
                <MenuCta
                  type="button"
                  style={{ display: "flex" }}
                  onClick={() =>
                    dispatch(showModal("swipe-article-preview-owner-menu"))
                  }
                >
                  <Icon icon={dotsVerticalIcon} size="24px" color={theme.colors.Primary} />
                </MenuCta>
              ) : (
                <Dropdown
                  listMinWidth="250px"
                  label={
                    <Icon icon={dotsVerticalIcon} size="24px" color={theme.colors.Primary} />
                  }>

                  <LinkItem
                    marginBottom="8px"
                    alignSelf="flex-end"
                    options={{
                      type: "outline",
                      size: "lg",
                      skin: "purple",
                    }}
                    href={`/dashboard/${welcomeContentPost?.welcome_section?.group?.group_type}/${welcomeContentPost?.welcome_section?.group?.id}/welcome-content/${welcomeContentPost?.id}`}
                  >
                    Editar contenido
                  </LinkItem>

                  <ButtonItem
                    marginBottom="0px"
                    alignSelf="flex-end"
                    options={{
                      type: "link",
                      size: "lg",
                      skin: "purple",
                    }}
                    onClick={() => {
                      dispatch(showModal("modal-delete-welcome-content-post"));
                    }}
                  >
                    Eliminar artículo
                  </ButtonItem>
                </Dropdown>
              )}
            </>
          )}
        </Actions>
      </Center>

      {
        isOwnArticle(welcomeContentPost?.welcome_section, userInformation?.expert?.id) && !viewAsSubscriber && (
          <Swipe name="swipe-article-preview-owner-menu">
            <SwipeContent>
              <Link
                options={{
                  type: "outline",
                  size: "lg",
                  skin: "purple",
                  block: true,
                }}
                style={{ marginBottom: "8px", height: "40px" }}
                href={`/dashboard/${welcomeContentPost?.welcome_section?.group?.group_type}/${welcomeContentPost?.welcome_section?.group?.id}/welcome-content/${welcomeContentPost?.id}`}
              >
                Editar contenido
              </Link>

              <Button
                options={{
                  type: "link",
                  size: "lg",
                  skin: "purple",
                  block: true,
                }}
                style={{ height: "40px" }}
                onClick={() => {
                  dispatch(showModal("modal-delete-welcome-content-post"));
                }}
              >
                Eliminar contenido
              </Button>
            </SwipeContent>
          </Swipe>
        )
      }
      {/* <ModalConfirmDelete /> */}
    </>
  );
};

const state = ({ userStore, welcomeContentStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: welcomeContentPost } = welcomeContentStore.welcomeContentPost;

  return {
    userInformation,
    welcomeContentPost,
  };
};

export default connect(state)(Component);
