import styled from "styled-components";
import { Link as ReactRouterLink } from "react-router-dom";

export const Link = styled("a") <{ type; size; skin; block?: boolean }>`
  ${(props: any) => {
    const { block, size, type, skin, theme } = props;

    return `
    cursor: pointer;
      ${block
        ? `display: inline-flex; align-items: center; justify-content: center; width: 100%;`
        : `display: inline-flex; align-items: center; justify-content: center;`
      }
      ${skin !== "transparent"
        ? `border: 1px solid ${theme.colors[theme.link.colors[type][skin].borderColor] || "transparent"
        };`
        : ``
      }
      background-color: ${theme.colors[theme.link.colors[type][skin].backgroundColor] || "transparent"
      };
      color: ${theme.colors[theme.link.colors[type][skin].color]};
      font-size: ${theme.link.size[size].fontSize};
      border-radius: ${theme.link.size[size].borderRadius};
      ${type !== "link" ? `padding: 0px 12px;` : ``};
      ${type !== "link" ? `min-width: 120px;` : ``};
      ${type !== "link" ? `height: ${theme.link.size[size].lineHeight}` : ``};
      text-decoration: ${type === "link" ? "underline" : "none"};
      letter-spacing: 0.25px;
      font-weight: ${type === "link" ? "normal" : "600"};
      text-align: center;
      transition: all .2s ease-in-out;

      &:hover {
        ${type !== "link"
        ? "box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);"
        : "text-decoration: underline;"
      }
        
      }

      &:disabled {
        background-color: ${theme.colors[theme.link.colors[type][skin].backgroundColor] || "transparent"
      };
      }
    `;
  }}
`;

export const NativeLink = styled(ReactRouterLink) <{
  type;
  size;
  skin;
  block?: boolean;
}>`
  ${(props: any) => {
    const { block, size, type, skin, theme } = props;

    return `
    cursor: pointer;
      ${block
        ? `display: inline-flex; align-items: center; justify-content: center; width: 100%;`
        : `display: inline-flex; align-items: center; justify-content: center;`
      }
      ${skin !== "transparent"
        ? `border: 1px solid ${theme.colors[theme.link.colors[type][skin].borderColor]
        };`
        : ``
      }
      background-color: ${theme.colors[theme.link.colors[type][skin].backgroundColor]};
      font-size: ${theme.link.size[size].fontSize};
      color: ${theme.colors[theme.link.colors[type][skin].color]};
      border-radius: ${theme.link.size[size].borderRadius};
      ${type !== "link" ? `padding: 0px 12px;` : ``}
      ${type !== "link" ? `min-width: 120px;` : ``}
      ${type !== "link" ? `height: ${theme.link.size[size].lineHeight}` : ``};
      text-decoration: ${type === "link" ? "underline" : "none"};
      letter-spacing: 0.25px;
      font-weight: ${type === "link" ? "normal" : "600"};
      text-align: center;
      transition: all .2s ease-in-out;

      &:hover {
        ${type !== "link"
        ? "box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);"
        : "text-decoration: underline;"
      }
        
      }

      &:disabled {
        background-color: ${theme.colors[theme.link.colors[type][skin].backgroundColor]
      };
      }
    `;
  }}
`;

export const DisabledLink = styled("span") <{
  type;
  size;
  skin;
  block?: boolean;
}>`
  ${(props: any) => {
    const { block, size, type, skin, theme } = props;

    return `
      ${block
        ? `display: inline-flex; align-items: center; justify-content: center; width: 100%;`
        : `display: inline-flex; align-items: center; justify-content: center;`
      }
      ${skin !== "transparent"
        ? `border: 1px solid ${theme.colors[theme.link.colors[type][skin].borderColor]
        };`
        : ``
      }
      background-color: ${theme.colors[theme.link.colors[type][skin].backgroundColor] || "transparent"
      };
      font-size: ${theme.link.size[size].fontSize};
      color: ${theme.colors[theme.link.colors[type][skin].color]};
      border-color: ${theme.colors[theme.link.colors[type][skin].borderColor] || "transparent"
      };
      border-radius: ${theme.link.size[size].borderRadius};
      ${type !== "link" ? `padding: 0px 12px;` : ``};
      ${type !== "link" ? `min-width: 120px;` : ``};
      ${type !== "link" ? `height: ${theme.link.size[size].lineHeight}` : ``};
      text-decoration: ${type === "link" ? "underline" : "none"};
      letter-spacing: 0.25px;
      font-weight: ${type === "link" ? "normal" : "600"};
      text-align: center;
      opacity: .5;

      &:disabled {
        background-color: ${theme.colors.Black40};
      }
    `;
  }}

  & > * {
    display: inline-block;
  }
`;
