import { Group } from "../types";

const groupTypes = {
  membership: "membresía",
  experience: "taller",
  free_experience: "evento gratuíto",
  community: "comunidad",
};

const articles = {
  membership: "la",
  experience: "el",
  free_experience: "el",
  community: "la",
};

interface FunctionProps {
  group: Group;
  options?: {
    showArticle?: boolean;
  };
}

export const getGroupType = ({ group, options }: FunctionProps) => {
  const { showArticle } = { showArticle: false, ...options };

  return `${showArticle ? articles[group?.group_type] + " " : ""}${
    groupTypes[group?.group_type] || "Otro"
  }`.trim();
};
