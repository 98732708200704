import { createRef, useEffect, useRef, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { connect, useDispatch } from "react-redux";
import slugify from "slugify";
import { Title, Text } from '../../../styles'

import { RemirrorRenderer } from "@remirror/react-renderer";

import constants from "../../../../../../../constants";
import Button from "../../../../../../../components/Button";
import Link from "../../../../../../../components/Link";
import {
  getYouTubeUrl,
  isYoutubeUrl,
  isInstagramUrl,
  isVimeoUrl,
  getVimeoVideoIdFromUrl,
} from "../../../../../../../helpers/you-tube";
import AspectRatio from "../../../../../../../components/AspectRatio";
import file from "../../../../../../../assets/icons/file-text-2.svg";
import linkIcon from "../../../../../../../assets/icons/open-link.svg";
import Icon from "../../../../../../../components/Icon";
import Grid from "../../../../../../../components/Grid";
import Row from "../../../../../../../components/Grid/Row";
import Col from "../../../../../../../components/Grid/Col";
import {
  getPost,
  resetSharePostInTelegram,
} from "../../../../../../../store/actions/post";
import { AttachedFile } from "../../../../../../../types/attached-file";
import { Post } from "../../../../../../../types/post";
import forward from "../../../../../../../helpers/fowardUrl";
import Loader from "../../../../../../../components/LogoLoader";
import { useMarkdown } from "../../../../../../../hooks/useMarkdown";
import { hideModal, showModal } from "../../../../../../../store/actions/modal";
import Snackbar from "../../../../../../../components/Snackbar";
import { getUrlParamByName } from "../../../../../../../helpers/get-url-param";
import { useWindowSize } from "../../../../../../../hooks/useFormHook";
import { isOwnArticle } from "../../../../../../../helpers/isOwnArticle";
import { DateSpliteDto, dateSpliter } from "../../../../../../../helpers/dates-hanlder";
import { useLayout } from "../../../../../../../providers/LayoutProvider";
import VideoPlay from "../../../../../../../components/VideoPlayer";


import {
  Image,
  Audio,
  PdfLink,
  NoSubscribedMessage,
  GoToAccount,
  NoSubscribedMessageTitle,
  PostContent,
  //Image,
  CreatedAt,
  InLine,
  Center
} from "./styles";

import PayWall from "../../../../../../Dashboard/components/PayWall";

import InstagramReel from "./components/InstagramReel";
import { useModulesPreview } from "../../../../../../../providers/ModulesPreviewProvider";
import { ArticleModuleDto } from "../../../../../../../types/module";
import VimeoVideo from "../../../../../../Dashboard/Posts/components/VimeoVideo";

interface ComponentProps {
  userInformation;
  userSubscriptions;
  //match;
  //avatar?: any;
  // sharePostInTelegramStates: {
  //   success: boolean;
  //   loading: boolean;
  //   error: boolean | string;
  // };
}

const Component = ({ userInformation, userSubscriptions }: ComponentProps) => {
  const { modules, actions, pagination, selectedModule } = useModulesPreview();
  const { typeMap, markMap, jsonStr, textStr } = useMarkdown({
    brief: (selectedModule?.content_data as ArticleModuleDto)?.description,
  });
  const [moduleNumber, setModuleNumber] = useState(1)
  const dispatch = useDispatch();
  const history = useHistory();
  const [contentData, setContentData] = useState<ArticleModuleDto>()
  const { layout, setLayout } = useLayout();
  const { isMobile } = useWindowSize();
  const [audioFiles, setAudioFiles] = useState<AttachedFile[]>([]);
  const [imageFiles, setImageFiles] = useState<AttachedFile[]>([]);
  const [pdfFiles, setPdfFiles] = useState<AttachedFile[]>([]);
  const [videoFiles, setVideoFiles] = useState<AttachedFile[]>([]);
  const [showSubscriptionModal, setShopSubscriptionModal] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  const [videoSizes, setVideoSizes] = useState<{
    aspectRatio: number;
    width: number;
    height: number;
  }>();

  const videoRef = createRef<HTMLVideoElement>();

  const handleLoad = () => {
    const videoElement = videoRef?.current;

    if (videoElement) {
      if (videoElement.videoWidth && videoElement.videoHeight) {
        const aspectRatio =
          videoElement?.videoWidth / videoElement?.videoHeight;
        setVideoSizes({
          aspectRatio: aspectRatio,
          width: videoElement.videoWidth,
          height: videoElement?.videoHeight,
        });
      }
    }
  };

  useEffect(() => {
    setAudioFiles(
      contentData?.attached_files?.filter((attached_file) =>
        attached_file?.mime?.includes("audio")
      ) || []
    );

    setImageFiles(
      contentData?.attached_files?.filter((attached_file) =>
        attached_file?.mime?.includes("image")
      ) || []
    );

    setPdfFiles(
      contentData?.attached_files?.filter((attached_file) =>
        attached_file?.mime?.includes("pdf")
      ) || []
    );

    setVideoFiles(
      contentData?.attached_files?.filter((attached_file) =>
        attached_file?.mime?.includes("video")
      ) || []
    );
  }, [contentData]);

  useEffect(() => {
    if (selectedModule) {
      setContentData({ ...(selectedModule?.content_data as ArticleModuleDto) })
    }
  }, [selectedModule])

  useEffect(() => {
    const isLoggedIn = !!userInformation;
    const isSubscribed =
      userSubscriptions?.filter(
        (subscription) => subscription.id === selectedModule?.group?.id
      ).length > 0;
    const isPostOwner =
      !!selectedModule &&
      !!userInformation &&
      selectedModule?.group?.expert?.id === userInformation?.expert?.id;

    setIsOwner(isPostOwner);
    setShopSubscriptionModal(
      (!isLoggedIn || (isLoggedIn && !isSubscribed && !isPostOwner))
    );
  }, [selectedModule, userInformation]);

  useEffect(() => {
    if (getUrlParamByName("new") === "t" && isOwner) {
      dispatch(showModal("modal-new-post-created"));
      window.history.pushState(null, document.title, history.location.pathname);
    }
  }, [isOwner]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: `${selectedModule?.group?.group_name} - ${selectedModule?.title}`,
      },
    });
  }, [selectedModule]);

  useEffect(() => {
    if (!!selectedModule && !!modules) {
      for (let i; i < modules.length; i++) {
        if (module[i].id === selectedModule.id) {
          setModuleNumber(i + 1)
        }
      }
      modules?.filter((module) => module.id === selectedModule.id)
    }
  }, [selectedModule, modules])

  return <>
    <Title>{isMobile && `Modulo ${pagination.current + 1}: `} {selectedModule?.title}</Title>

    <div style={{ height: isMobile ? 16 : 24 }} />

    {audioFiles.length > 0 &&
      audioFiles.map((attached_file) => (
        <>
          <Audio key={`attached-file-${slugify(attached_file.name)}`}>
            <span>
              Escucha el audio:{" "}
              {attached_file.name.replace(".mp3", "")}
            </span>
            <audio
              controls
              style={{
                margin: "10px 0px 0px 0px",
                height: "40px",
                width: "100%",
              }}
            >
              <source
                src={attached_file.url}
                type={attached_file.mime}
              />
            </audio>
          </Audio>
        </>
      ))}

    {imageFiles.length > 0 &&
      imageFiles.map((attached_file) => (
        <Image
          src={attached_file.url}
          key={`attached-file-${slugify(attached_file.name)}`}
        />
      ))}

    {pdfFiles.length > 0 &&
      pdfFiles.map((attached_file) => (
        <PdfLink
          target="_blank"
          href={attached_file.url}
          key={`attached-file-${slugify(attached_file.name)}`}
        >
          <Icon icon={file} color="white" />
          Clickea aqui para descargar: {attached_file.name}
        </PdfLink>
      ))}

    {videoFiles.length > 0 &&
      videoFiles.map((attached_file) => (
        <video
          onContextMenu={e => e.preventDefault()}
          controlsList="nodownload"
          ref={videoRef}
          controls
          width="100%"
          key={`attached-file-${slugify(attached_file.name)}`}
          style={{
            display: "block",
            ...(videoSizes && {
              maxHeight:
                videoSizes?.height > 640
                  ? videoSizes?.height / 2
                  : videoSizes?.height,
              maxWidth:
                videoSizes?.width > 1280
                  ? videoSizes?.width / 2
                  : videoSizes?.width,
            }),
          }}
          onLoad={handleLoad}
          onLoadedMetadata={handleLoad}
        >
          <source src={attached_file.url} type={attached_file.mime} />
          Tu navegador no soporta Video.
        </video>
      ))}

    {!!contentData?.attached_url && (
      <>
        {isYoutubeUrl(contentData?.attached_url) && (
          <AspectRatio
            ratio="16/9"
            maxWidth={940}
            key={`attached-file-${slugify(
              contentData?.attached_url
            )}`}
          >
            <iframe
              style={{ maxWidth: "100%" }}
              src={getYouTubeUrl(contentData?.attached_url)}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </AspectRatio>
        )}

        {isInstagramUrl(contentData?.attached_url) && (
          <InstagramReel
            src={contentData?.attached_url}
            key={`attached-file-${slugify(
              contentData?.attached_url
            )}`}
          ></InstagramReel>
        )}

        {isVimeoUrl(contentData?.attached_url) && (
          <VimeoVideo videoId={getVimeoVideoIdFromUrl(contentData?.attached_url)} isPreview={true} />
        )}

        {!isVimeoUrl(contentData?.attached_url) && !isYoutubeUrl(contentData?.attached_url) &&
          !isInstagramUrl(contentData?.attached_url) && (
            <Link
              target="_blank"
              href={contentData?.attached_url}
              options={{
                type: "filled",
                skin: "purple",
                size: "lg",
                native: false,
              }}
            >
              <InLine style={{ gap: "10px" }}>
                Click aquí para acceder al contenido
                <Icon icon={linkIcon} color="white" />
              </InLine>
            </Link>
          )}

      </>
    )}

    <div style={{ height: isMobile ? 16 : 24 }} />

    <Text>
      {!!jsonStr && (
        <RemirrorRenderer
          json={jsonStr}
          typeMap={typeMap}
          markMap={markMap}
        />
      )}</Text>
  </>
}

const state = ({ userStore, sessionStore, groupStore }) => {
  const { isLoggedIn } = userStore;
  const { data: userInformation } = userStore.information;
  const { data: sessions, states: sessionsStates } = sessionStore.all;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: userSubscriptions } = userStore.subscriptions;

  return {
    userInformation,
    userSubscriptions,
    isLoggedIn,
    sessions,
    sessionsStates,
    group,
    groupStates,
  };
};

export default connect(state)(Component);
