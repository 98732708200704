import { Formik, FormikHelpers } from "formik";
import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { schema, initialValues } from '../../../../../../constants/forms/plan/new'
import Sidebar from "../../../../../../components/Sidebar";
import {
  Preview,
  Title,
  Text,
  Option,
  StrongLabel,
  StrongValue,
} from "../../styles";
import PromotionSection from '../components/PromotionSection'
import Form from "../components/NewPlanForm"
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/FormikForm/Submit";
import { Group, NewPlanOutDto, User } from "../../../../../../types";
import { createNewPlan } from "../../../../../../store/actions/plan";
import { Center } from "../../../../../../components/StyledComponents";
import { useRef } from 'react';
import { Gateway } from "../../../../../../types/gateway";

interface ComponentProps {
  isVisible: boolean
  userData: User
  onClose
  group: Group
  newPlan,
  newPlanStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  setShowNewPromotionSidebar
}

const Component = ({
  isVisible,
  userData,
  onClose,
  group,
  newPlan,
  newPlanStates,
  setShowNewPromotionSidebar
}: ComponentProps) => {
  const dispatch = useDispatch();
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [actions, setActions] = useState<FormikHelpers<any>>();

  const getPeriodTranslation = ({ period_unit }) => {
    const translation = {
      "month": "month",
      "quarter": "month",
      "half-year": "month",
      "year": "year",
    }

    return translation[period_unit]
  }

  const getCalculatedPeriod = ({ period, period_unit }) => {
    const multiplier = {
      "month": 1,
      "quarter": 3,
      "half-year": 6,
      "year": 1,
    }

    return period * multiplier[period_unit];
  }

  const primaryGateway = useMemo((): Gateway | undefined => userData?.expert?.linked_gateways?.find(lg => lg.priority === "primary" && lg.currency === "ARS"), [userData])

  const onSubmit = ({ values, actions }) => {
    const type = values["type"];
    const currency = values["currency"];
    const period_unit = values["type"] === "subscription" ? getPeriodTranslation({ period_unit: values["period_unit"] }) : null;
    const period = values["type"] === "subscription" ? getCalculatedPeriod({ period: Number(values["period"]), period_unit: values["period_unit"] }) : null;
    const billing_cycles = group?.group_type === "experience" && values["type"] === "subscription" ? Number(values["billing_cycles"]) : null

    //      values["type"] === "subscription" && values["billing_cycle_type"] === "c values["billing_cycle_type"] === "cobro-tiempo-limitado"obro-tiempo-limitado" ? Number(values["billing_cycles"]) : null;
    const price = Number(values["price"]);
    const external_name = values["external_name"];

    const payload: NewPlanOutDto = {
      external_name,
      "group": group?.id,
      type,
      currency,
      period_unit, //only for plans with type = "subscription", otherwhise need to be null
      period, //only for plans with type "subscription, otherwhise need to be null"
      billing_cycles, //only for plans with type = "subscription" and billing_cycle_type = "cobro-tiempo-limitado" otherwhise need to be null",
      status: "active",
      price,
    }

    dispatch(createNewPlan(payload))
    setActions(actions)
  }

  useEffect(() => {
    if (newPlanStates.success && actions) {
      actions.resetForm();
    }
  }, [newPlanStates, actions])

  return (
    <>
      <Sidebar
        position="right"
        visible={isVisible}
        closeType="back"
        onClose={() => {
          //the sidebar is outside of formik context so reseting the form is not working. I implement this solution to move foward.
          if (cancelButtonRef.current) {
            cancelButtonRef.current.click();
          }
        }}
        width="520px"
        title="Nuevo precio"
      >
        <Preview>
          <Formik
            initialValues={{
              ...initialValues,
            }}
            onSubmit={(values, actions) => {
              if (onSubmit) onSubmit({ values, actions });
            }}
            validateOnChange={formSubmmited}
            validateOnBlur={false}
            validationSchema={schema}
            enableReinitialize
          >
            {(props) => {
              return (
                <>
                  <div>
                    <Option>
                      <form
                        className="theme-form"
                        onSubmit={(event) => {
                          setFormSubmmited(true);
                          props.handleSubmit(event);
                        }}
                        id="new-plan-form"
                      >
                        <Form {...props} isLoading={newPlanStates.loading} isNew />
                      </form>
                    </Option>
                  </div>

                  <div style={{ height: 40 }} />

                  <Center horizontal="center" vertical="center" gap={10}>
                    <Button
                      forwardref={cancelButtonRef}
                      options={{
                        type: "outline",
                        skin: "purple",
                        size: "lg",
                        block: true
                      }}
                      onClick={() => {
                        props?.resetForm()
                        !!onClose && onClose()
                      }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={newPlanStates.loading}
                      disabled={newPlanStates.loading || (!primaryGateway && props?.values?.currency === "ARS")}
                      options={{
                        type: "filled",
                        size: "lg",
                        skin: "purple",
                        block: true
                      }}
                      form="new-plan-form"
                    >
                      Crear precio
                    </Submit>
                  </Center>
                </>
              );
            }}
          </Formik>
        </Preview>
      </Sidebar>
    </>
  )
}

const state = ({ groupStore, userStore, planStore }) => {
  const { data: userData } = userStore.information;
  const { data: group } = groupStore.group;
  const { data: newPlan, states: newPlanStates } = planStore.newPlan;

  return {
    userData,
    group,
    newPlan,
    newPlanStates,
  };
};

export default connect(state)(Component);
