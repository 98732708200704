import { EditSubscriptionOutDto, Subscription } from "../../../types";

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_ERROR = "CANCEL_SUBSCRIPTION_ERROR";
export const RESET_CANCEL_SUBSCRIPTION = "RESET_CANCEL_SUBSCRIPTION";

export interface CancelSubscription {
  type: typeof CANCEL_SUBSCRIPTION;
  payload: EditSubscriptionOutDto;
}

export interface CancelSubscriptionSuccess {
  type: typeof CANCEL_SUBSCRIPTION_SUCCESS;
  payload: null;
}

export interface CancelSubscriptionError {
  type: typeof CANCEL_SUBSCRIPTION_ERROR;
  payload: string | boolean;
}

export interface ResetCancelSubscription {
  type: typeof RESET_CANCEL_SUBSCRIPTION;
  payload: null;
}

export interface State {
  data: Subscription | null
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | CancelSubscription
  | CancelSubscriptionSuccess
  | CancelSubscriptionError
  | ResetCancelSubscription;
