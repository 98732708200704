import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import Submit from "../../../../../components/FormikForm/Submit";
import { Title, Text, AlignEnd, Error } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import {editPost, resetDeletePost, resetEditPost} from "../../../../../store/actions/post";
import { Post } from "../../../../../types/post";
import remove from "../../../../../assets/remove.svg";
import Image from "../../../../../components/Image";
import { partialSchema } from "../../../../../constants/forms/post/edit";
import { Formik } from "formik";
import { useState } from "react";
import {inputDateTimeLocal, setToZeroTimeZone} from "../../../../../helpers/dates-hanlder";
import SchedulePublicationDateFormFields from "../SchedulePublicationDateFormFields";

interface ComponentProps {
    postInformation: Post;
    editPostStates: {
        loading: boolean;
        success: boolean;
        error: string | boolean;
    };
}

const Component = ({ postInformation, editPostStates }: ComponentProps) => {
    const dispatch = useDispatch();
    const [formSubmmited, setFormSubmmited] = useState(false);

    const onSubmit = (values, actions) => {
        dispatch(editPost({...values, schedule_publish_at: setToZeroTimeZone(values.schedule_publish_at), show_scheduled_post: values.show_scheduled_post.includes("true"),}));
    };

    const onModalClose = () => {
        if (editPostStates.success) {
            dispatch(resetEditPost());

            setTimeout(() => {
                hideModal();
            }, 500);
        }
    };


    return (
        <Modal name="modal-change-post-published-date" width="650" onClose={() => onModalClose()}>
            {editPostStates.success ? (
                <>
                    <div style={{ textAlign: "center" }}>
                        <Image src={remove} width="102px" height="117px" alt={remove} />
                    </div>
                    <Text>
                        <strong style={{ textAlign: "center", display: "block" }}>
                            Cambio realizado exitosamente
                        </strong>
                    </Text>
                </>
            ) : (
                <>
                    <Title>Cambia la fecha de publicación del articulo</Title>
                    <Text>
                        Puedes cambiar la fecha programada de publicación para el artículo “{postInformation?.title}”.
                    </Text>

                    <Formik
                        initialValues={{
                            schedule_publish_at: postInformation?.schedule_publish_at ? inputDateTimeLocal(postInformation?.schedule_publish_at) : "",
                            show_scheduled_post: postInformation?.show_scheduled_post ? ["true"] : [],
                            action_publish_at: postInformation?.published_at ? "now_publication" : "scheduled_publication",
                            published_at: postInformation?.published_at,
                            group: postInformation?.group?.id,
                            id: postInformation?.id,
                        }}
                        validationSchema={partialSchema}
                        onSubmit={(values, actions) => {
                            if (onSubmit) onSubmit(values, actions);
                        }}
                        validateOnChange={false}
                        validateOnBlur={formSubmmited}
                        enableReinitialize
                    >
                        {({
                              dirty,
                              touched,
                              errors,
                              values,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              setFieldValue,
                          }) => {
                            return (
                                <form
                                    className="theme-form"
                                    onSubmit={(event) => {
                                        setFormSubmmited(true);
                                        handleSubmit(event);
                                    }}
                                    id="edit-schedule-post-form"
                                >


                                    <SchedulePublicationDateFormFields
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />

                                    <AlignEnd>
                                        <Button
                                            options={{
                                                type: "outline",
                                                skin: "purple",
                                                size: "lg",
                                            }}
                                            style={{ marginRight: "16px" }}
                                            onClick={() => dispatch(hideModal())}
                                        >
                                            Cancelar
                                        </Button>
                                        <Submit
                                            form="edit-schedule-post-form"
                                            disabled={!dirty}
                                            isSubmmiting={editPostStates.loading}
                                            options={{
                                                type: "filled",
                                                skin: "purple",
                                                size: "lg",
                                            }}
                                        >
                                            Confirmar cambios
                                        </Submit>
                                    </AlignEnd>
                                    {editPostStates.error && (
                                        <Error> Error al guardar los cambios!</Error>
                                    )}
                                </form>
                            )
                        }}
                    </Formik>
                </>
            )}
        </Modal>
    );
};

const state = ({ postStore }) => {
    const { data: postInformation } = postStore.post;
    const { states: editPostStates } = postStore.editPost;
    return {
        postInformation,
        editPostStates,
    };
};

export default connect(state)(Component);
