import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Page, PageHeader, Title } from "../../components/styles";
import GoBack from "../../../../components/GoBack";
import Link from "../../../../components/Link";
import Submit from "../../../../components/FormikForm/Submit";
import Helmet from "react-helmet";

import Grid from "../../../../components/Grid";
import { initialValues, schema } from "../../../../constants/forms/coupon";
import Radio from "../../../../components/FormikForm/Radio";
import Input from "../../../../components/FormikForm/Input";
import ReactSelect from "../../../../components/FormikForm/ReactSelect";
import { Group, PlanDto, User } from "../../../../types";
import {
  newDiscount,
  resetNewDiscount,
} from "../../../../store/actions/discount";
import {
  inputDate,
  setToZeroTimeZone,
} from "../../../../helpers/dates-hanlder";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useLayout } from "../../../../providers/LayoutProvider";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { getPlans } from "../../../../store/actions/plan";

interface ComponentProps {
  userInformation: User;
  userGroups: Group[];
  userGroupsStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  newDiscountStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  allPlans: PlanDto[]
  allPlansStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

interface Option {
  label: string;
  value: string | number;
}

const groupTypes = [
  { label: "Experiencia", value: "experience" },
  { label: "Membresía", value: "membership" },
];

const periodsUnitsTranslations = {
  "month": { singular: "mes", plural: "meses" },
  "quarter": { singular: "trimestre", plural: "trimestres" },
  "half-year": { singular: "semestre", plural: "semestres" },
  "year": { singular: "año", plural: "años" },
}

const Component = ({
  userGroups,
  userGroupsStates,
  newDiscountStates,
  allPlans,
  allPlansStates,
}: ComponentProps) => {
  const { isMobile } = useWindowSize();
  const { layout, setLayout } = useLayout();
  const dispatch = useDispatch();
  const history = useHistory();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [groups, setGroups] = useState<Option[] | Option>();
  const [selectedGroup, setSelectedGroup] = useState<{ name: string, value: number }>();
  const [groupTypeSelected, setGroupTypeSelected] = useState(
    groupTypes[0].value
  );


  const onSubmit = ({ values, actions }) => {
    const group_id = values["group_id"]
    const plan_ids = [values["plan_id"]];

    let period_unit;
    let period;
    let duration_type;

    const selectedPlan = allPlans?.find(p => p.id === values["plan_id"])

    if(selectedPlan && selectedPlan?.type === "subscription" && selectedPlan.period && values["period"]){
       period = Number(values["period"]) * selectedPlan.period;
       period_unit = selectedPlan.period_unit
      duration_type = "limited_period"
    } else {
      period = undefined;
      period_unit = undefined;
      duration_type = "one_time"
    }

    const amount = Number(values["amount"]);
    //const period = values["period"] ? Number(values["period"]) : null;
    const valid_till = setToZeroTimeZone(values["valid_till"]);
    const type = "percentage";
    const show_in_page = false;
    const status = 'active';
    const coupon_id = values["coupon_id"];

    dispatch(
      newDiscount({
        plan_ids,
        group_id,
        coupon_id,
        amount,
        period,
        valid_till,
        type,
        show_in_page,
        status,
        period_unit,
        duration_type,
      })
    );
  };

  const paymentPeriodTranslated = (plan) => {
    if (plan["period_unit"] && plan["period"]) {
      const periodSelected = plan["period"]; //Cantidad de cobros
      const selectedPeriodUnit = periodsUnitsTranslations[plan["period_unit"]] //Meses / Trimestres / Años / Etc...
      //const selectedPeriodUnit =  [plan["period_unit"]] //Meses / Trimestres / Años / Etc...
      return `$${plan.price} ${plan["period"] <= 1 ? "por" : `cada ${plan["period"]}`} ${selectedPeriodUnit[periodSelected > 1 ? "plural" : "singular"]}.`
    } else {
      return ""
    }
  }

  const plansOptions = useMemo(() => {
    if (!!allPlans && allPlans.length > 0) {
      return allPlans.filter(item => item.currency !== 'ARS').map((item) => {
        const paymentCicles = item['billing_cycles'] ? `${item['billing_cycles']} cobros en total.` : "";
        const paymentPeriod = item["type"] === "charge" ? `$${item.price} por única vez.` : paymentPeriodTranslated(item);

        return { label: `${item.currency} - ${paymentPeriod} ${paymentCicles}`, value: item.id }
      })
    } else {
      return []
    }
  }, [allPlans])


  useEffect(() => {
    if (userGroups && userGroups.length > 0) {
      setGroups(
        userGroups
          .filter((userGroup) => userGroup?.group_type === groupTypeSelected)
          .map((userGroup) => ({
            value: userGroup.id,
            label: userGroup.title,
          }))
      );
    }
  }, [userGroups, groupTypeSelected]);

  useEffect(() => {
    if (newDiscountStates.success) {
      history.push("/dashboard/coupon/list/active");
    }
  }, [newDiscountStates]);

  useEffect(() => {
    if (selectedGroup?.value) {
      dispatch(getPlans({ idGroup: selectedGroup?.value }))
    }
  }, [selectedGroup])

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: "Crear Cupón",
        description: "Crear Cupón",
        back: "/dashboard/coupon/list/active",
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: true,
        actions: [
          <Link
            style={{ marginRight: "8px" }}
            options={{
              type: "outline",
              size: "lg",
              skin: "purple",
              native: true,
            }}
            href="/dashboard/coupon/list/active"
          >
            Cancelar
          </Link>,

          <Submit
            isSubmmiting={newDiscountStates.loading}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            form="new-coupon-form"
          >
            Crear Cupón
          </Submit>,
        ],
      },
    });
  }, []);

  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <p>
            ¡Crea un <strong>cupón de descuento</strong> para un taller o
            membresía!
            <br />✨ <strong>Tip mágico:</strong> Este beneficio sirve para
            atraer nueva audiencia y puede aumentar tus ventas.
          </p>
        </Grid.Col>
      </Grid.Row>

      <div style={{ height: "32px" }}></div>

      <Grid.Row>
        <Grid.Col lg={6}>
          <Formik
            initialValues={{ ...initialValues }}
            onSubmit={(values, actions) => {
              if (onSubmit) onSubmit({ values, actions });
            }}
            validateOnChange={false}
            validateOnBlur={formSubmmited}
            validationSchema={schema}
            enableReinitialize
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <form
                  className="theme-form"
                  onSubmit={(event) => {
                    setFormSubmmited(true);
                    handleSubmit(event);
                  }}
                  id="new-coupon-form"
                >
                  <Radio
                    name="group_type"
                    error={errors["group_type"]}
                    touched={touched["group_type"]}
                    items={groupTypes}
                    value={values["group_type"]}
                    onChange={(event) => {
                      handleChange(event);

                      /*if (
                        event.currentTarget.value === "experience" &&
                        !!values.period
                      ) {
                        setFieldValue("period", undefined);
                        setFieldValue("period_unit", undefined);
                      } else {
                        setFieldValue("period_unit", "month");
                      }

                      if (!!values.group_id) {
                        setFieldValue("group_id", null);
                      }*/

                      setGroupTypeSelected(event.currentTarget.value);
                    }}
                    onBlur={handleBlur}
                    options={{
                      label:
                        "Elige la actividad que quieras promocionar con un descuento",
                      marginBottom: 40,
                    }}
                  />

                  <ReactSelect
                    name="group_id"
                    error={errors["group_id"]}
                    touched={touched["group_id"]}
                    placeholder={`${groupTypeSelected === "membership"
                      ? "Elige una membresía"
                      : "Elige un taller"
                      }`}
                    items={groups}
                    onChange={(event) => { handleChange(event); setSelectedGroup(event.target) }}
                    onBlur={handleBlur}
                    options={{
                      label: `¿En cuál ${groupTypeSelected === "membership"
                        ? "membresia"
                        : "taller"
                        } aplicará el descuento?`,
                      marginBottom: 40,
                      loading: userGroupsStates.loading,
                    }}
                    disabled={!values["group_type"]}
                  />

                  <ReactSelect
                    name="plan_id"
                    error={errors["plan_id"]}
                    touched={touched["plan_id"]}
                    placeholder="Elige un precio"
                    items={plansOptions}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Selecciona un precio",
                      marginBottom: 40,
                      helperText: "Por el momento la funcionalidad de cupones solo está disponible para precios en las monedas EUR y USD. 🔜 Próximamente estará disponible para nuevas monedas!",
                      loading: allPlansStates.loading,

                    }}
                    disabled={!values["group_id"]}
                  />

                  <Input
                    name="coupon_id"
                    error={errors["coupon_id"]}
                    touched={touched["coupon_id"]}
                    step="any"
                    placeholder="Sin espacios"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values["coupon_id"]}
                    options={{
                      label: "Código de cupón",
                      helperText:
                        "No debe contener espacios o caracteres especiales.",
                      marginBottom: 40,
                    }}
                  />

                  <Input
                    name="amount"
                    error={errors["amount"]}
                    touched={touched["amount"]}
                    type="number"
                    step="any"
                    placeholder="Ej: 10"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Ingresa un porcentaje (%) de descuento",
                      after: "%",
                      marginBottom: 40,
                    }}
                  />

                  <Input
                    name="valid_till"
                    error={errors["valid_till"]}
                    touched={touched["valid_till"]}
                    type="datetime-local"
                    placeholder="dd/mm/aa"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: "Cupón válido hasta",
                      explainText:
                        "Selecciona con fecha y hora hasta cuándo se puede utilizar el cupón",
                      marginBottom: 40,
                    }}
                  />

                  {allPlans?.filter(p => p.id === values["plan_id"] && p?.type === "subscription")?.length > 0 && (
                    <Input
                      name="period"
                      error={errors["period"]}
                      touched={touched["period"]}
                      type="number"
                      placeholder="0"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        label: "El descuento aplica durante los primeros...",
                        after: "cobros",
                        explainText:
                          "Elige la cantidad de cobros en los que aplicara el Descuento. Por ejemplo: Si eliges Aplicar durante los primeros 3 cobros, el suscriptor pagará los primeros 3 cobros con descuento y  a partir del 4to cobro sin el descuento..",
                        marginBottom: 40,
                      }}
                    />
                  )}
                </form>
              );
            }}
          </Formik>
        </Grid.Col>
      </Grid.Row>

      {!isMobile && (
        <Grid.Row>
          <Grid.Col>
            <Link
              style={{ marginRight: "8px" }}
              options={{
                type: "outline",
                size: "lg",
                skin: "purple",
                native: true,
              }}
              href="/dashboard/coupon/list/active"
            >
              Cancelar
            </Link>

            <Submit
              isSubmmiting={newDiscountStates.loading}
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
              }}
              form="new-coupon-form"
            >
              Crear Cupón
            </Submit>
          </Grid.Col>
        </Grid.Row>
      )}
    </Grid.Container>
  );
};

const state = ({ userStore, discountStore, planStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: userGroups, states: userGroupsStates } = userStore.groups;
  const { states: newDiscountStates } = discountStore.newDiscount;
  const { data: allPlans, states: allPlansStates } = planStore.allPlans;

  return {
    userInformation,
    userGroups,
    userGroupsStates,
    newDiscountStates,
    allPlans,
    allPlansStates
  };
};

export default connect(state)(Component);
