import styled, { createGlobalStyle } from "styled-components";
import { Link as UiLink } from 'react-router-dom'
import { rainbowTextGradient } from "../../StyledComponents";


export const GlobalStyles = createGlobalStyle<{ background }>`
  body {
    min-height: 100vh;
    ${({ background }) => {
    if (!!background?.image) {
      return `background-image: url(${background.image}); background-repeat: no-repeat; background-size: cover;`;
    }

    if (!!background?.color) {
      return `background-color: ${background?.color};`;
    }
  }}

  #footer-sticky {
    position: sticky;
    bottom: 0;
    z-index: 100;
  }

  #root {
    position: relative;
    padding-top: 0px;
    min-height: 100vh;
  }

  #header-sticky {
    position: sticky;
    top: 0;
    z-index: 105;
  }

  #sidebars {
    position: relative;
    z-index: 150;
  }

  #modal {
    position: relative;
    z-index: 160;
  }

  #snackbar {
    position: relative;
    z-index: 170;
  }

  #swipe {
    position: relative;
    z-index: 180;
  }
`;

export const TabsContainer = styled("div") <{ toolbarVisible }>`
  border-bottom: 1px solid #C4C4C4;
  position: sticky;
  top: 0px;
  background-color: white; 
  z-index: ${({ toolbarVisible }) => toolbarVisible ? 110 : 30};
`

export const Tabs = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  //gap: 16px;
`;

export const Tab = styled("div")`
  width: 100%;
  text-align: center;
`;

export const Link = styled(UiLink) <{ active?: boolean }>`
  display: block;
  position: relative;
  text-decoration: none;
  color: ${(props) => props.active ? "#000" : "#979797"};
  font-weight: ${(props) => (props.active ? "600" : "500")};
  font-size: 18px;
  line-height: auto;
  padding: 0px 0px 11px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 18px;
    line-height: 22px;
  }

  &:after {
    content: "";
    display: ${(props) => (props.active ? "block" : "none")};
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 5px;
    margin-bottom: -1px;
    border-radius: 2px 2px 0px 0px;
    background-color: ${(props) => props.theme.colors.Primary};
  }
`;


export const SwipeContent = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 18px 16px;
`;


export const IconButton = styled('button')`
  margin: 0px;
  padding: 0px;
  border: 0px;
  background: none;
  cursor: pointer;
`

export const Title = styled("h1")`
  margin: 0px 20px;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 42px;
    line-height: 42px;
  }
`;

export const SectionName = styled("h4")`
  margin: 0px;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  text-align: center;
  ${rainbowTextGradient};

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    letter-spacing: 2.5px;
  }
`;