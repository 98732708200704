import styled, { keyframes } from "styled-components";
import { Dot, ButtonBack, ButtonNext } from "pure-react-carousel";
import ImageUi from "../../../../../../components/Image";
import UiButton from "../../../../../../components/Button";
import UiModal from "../../../../../../components/Modal";

export const Modal = styled(UiModal)``;

export const ModalDialog = styled("div")`
  padding: 10px  25px;
  margin: 0 auto;
`;

export const Body = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;

  .carousel__slide-focus-ring {
    display: none;
  }

  .carousel__inner-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.div`
  margin: 0px;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
`;

export const CurrentStep = styled.span`
  font-weight: 600;
  font-size: 16px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  min-height: 51px;
  text-align: center;
`;

export const RedirectMsg = styled('div')`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const Button = styled(UiButton)`
  min-width: 150px;

  @media (min-width: 768px) {
    min-width: 175px;
  }
`;

export const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Dots = styled(Dot)`
  margin: 16px 5px 0px 0px;
  background-color: ${(props) => props.theme.colors.Primary};
  border: 1px solid ${(props) => props.theme.colors.Primary};
  height: 16px;
  width: 16px;
  border-radius: 100%;
  display: block;

  &:disabled,
  &:hover,
  &:focus,
  &:active {
    background-color: white;
    border: 1px solid ${(props) => props.theme.colors.Gray9};
  }
`;

export const ButtonsNext = styled(ButtonNext) <{ ref }>`
  position: absolute;
  top: 112px;
  right: 0px;
  background: none;
  border: none;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    top: 250px;
    right: 28px;
  }
`;

export const ButtonsBack = styled(ButtonBack)`
  position: absolute;
  top: 112px;
  left: 0px;
  background: none;
  border: none;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-content: center;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    top: 250px;
    left: 28px;
  }
`;

export const Image = styled(ImageUi)`
  margin-bottom: 20px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: 0px 0px 0px 10px;
    width: 160px;
    height: 171px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    margin-bottom: 0px;
    width: 181px;
    height: 191px;
  }
`;

const AnimationZoomIn = keyframes`
0% {
  //transform: scale(0.9, 0.9);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  outline: 2px solid transparent;
  opacity: .6;
}
100% {
  //transform: scale(1, 1);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  outline: 2px solid #229ED9;
  opacity: 1;
}
`;

const AnimationZoomOut = keyframes`
0% {
  //transform: scale(1, 1);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  outline: 2px solid #229ED9;
  opacity: 1;
}
100% {
  //transform: scale(0.9, 0.9);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  outline: 2px solid transparent;
  opacity: .6;
}
`;

export const SubTitle = styled("h4")`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  text-align: center;
`;

export const AlignEnd = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const Card = styled("div")`
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: ${AnimationZoomOut};
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100%;
  // max-width: calc(100% - 40px);
  cursor: pointer;
  min-height: 120px;
  padding: 8px;
  margin-left: 10px; //new
  margin-right: 10px; //new

  @media (min-width: 768px) {
    // max-width: 190px;
    min-height: 90px;
    padding: 16px;
  }

  &.active {
    animation-name: ${AnimationZoomIn};
  }
`;

export const InLineIcon = styled("span")`
  & > * {
    display: inline-block;
  }

  & > i {
    vertical-align: middle;
  }
`;
