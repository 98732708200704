import styled, { keyframes } from "styled-components";
import UiAvatar from "../../../../components/Avatar";

export const Text = styled.div`
  font-size: 16px;
  line-height: 19px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 20px;
    line-height: 24px;
  }
`;
export const Image = styled("img")`
  border-radius: 18px;
  width: 100%;
`;

export const Audio = styled("div")`
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const PdfLink = styled("a")`
  background-color: #b30c01;
  color: white;
  display: inline-flex;
  border-radius: 8px;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  padding: 5px 10px 5px 0;
  text-decoration: none;
  margin-bottom: 15px;
  margin-top: 15px;

  &:hover {
    text-decoration: underline;
  }
  i {
    margin-right: 10px;
  }
`;

export const NoSubscribedMessage = styled("div")`
  margin: 50px auto 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NoSubscribedMessageTitle = styled("h3")`
  text-align: center;
`;

export const GoToAccount = styled("p")`
  margin: 24px 0px 20px;
  text-align: center;
  text-indent: 5px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: 36px 0px 0px;
  }
`;

export const PostContent = styled("div")`
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0px 1px 25px rgb(0 0 0 / 15%);

  @media (min-width: 1024px) {
    padding: 48px 97px;
  }
`;

export const AvatarImage = styled("div")`
  display: flex;
  align-items: center;
`;

export const Avatar = styled(UiAvatar)`
  margin-right: 8px;
`;

export const Name = styled("div")``;
// export const ShareArticle = styled("div")`
//   margin-left: 15px;
//   border: 1px solid #6c5ce7;
//   color: #6c5ce7;
//   display: inline-flex;
//   vertical-align: middle;
//   align-items: center;
//   justify-content: center;
//   border-radius: 16px;
//   margin-bottom: 3px;
//   width: 96px;
//   height: 26px;

//   @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
//     width: 117px;
//     height: 32px;
//   }
// `;

export const InLine = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled("div")`
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  margin: 24px 0px;
`;

export const Date = styled("div")`
  padding: 0px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Day = styled("div")`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.Black};
`;

export const Number = styled("div")`
  margin-top: -4px;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: ${({ theme }) => theme.colors.Primary};
`;

export const State = styled("div")`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
`;

export const When = styled("span")`
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.Primary};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const sonarWave = keyframes`
from {
  opacity: 0.4;
}
to {
  transform: scale(3);
  opacity: 0;
}`;

export const Sonar = styled("div")`
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.Primary};
  margin-right: 4px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.Primary};
    opacity: 0;
    pointer-events: none;
    animation: ${sonarWave} 1.5s linear infinite;
  }
`;

export const Hour = styled("div")`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.Primary};
  margin-right: 8px;
`;

const verticalPosition = {
  top: "flex-start",
  bottom: "flex-end",
  center: "center",
};

const horizontalPosition = {
  left: "flex-start",
  right: "flex-end",
  center: "center",
  space: "space-between",
};

export const Center = styled("div") <{
  vertical: "top" | "center" | "bottom";
  horizontal: "left" | "center" | "right" | "space";
  gap?: number;
  column?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  justify-content: ${({ horizontal }) => horizontalPosition[horizontal]};
  align-items: ${({ vertical }) => verticalPosition[vertical]};
  gap: ${({ gap }) => (gap ? gap : 0)}px;
`;

export const CreatedAt = styled("span")`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #2d3436;
`;
