import { CSSProperties, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Sidebar, CloseButton, BackArrow, Backdrop, Title } from "./styles";
import { Transition } from "react-transition-group";
import Icon from "../Icon";
import { AnimationFixed, Animation } from "../Animation";
import leftArrow from "../../assets/icons/go-back-arrow.svg";
import close from "../../assets/icons/close.svg";

interface ComponentProps {
  position: "left" | "right";
  children: any;
  visible: boolean;
  onClose?: () => void;
  closeType?: "close" | "back";
  style?: any;
  width?: string;
  title: string;
  options?: {
    style?: CSSProperties;
  };
}

const Component = ({
  position,
  children,
  visible,
  onClose,
  closeType,
  style,
  width,
  title,
  options,
}: ComponentProps) => {
  const [isVisible, setIsVisible] = useState(visible);
  const animation =
    position === "left" ? "fadeInSlideLeft" : "fadeInSlideRight";

  const setBodyStyles = () => {
    if (isVisible) {
      document.body.style.height = "100%";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    }
  };

  const handleClose = () => {
    setIsVisible(false);
    setBodyStyles();

    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    setBodyStyles();
  }, [isVisible]);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  return ReactDOM.createPortal(
    <Transition in={isVisible} timeout={300}>
      {(state) => (
        <>
          <AnimationFixed
            name={animation}
            className={`animation-${state}`}
            duration={0.5}
            width={width}
            position={position}
            {...(!!style && style)}
          >
            <Sidebar {...options}>
              {closeType && closeType === "close" && (
                <CloseButton type="button" onClick={handleClose}>
                  <Icon
                    icon={close}
                    color="rgba(45, 52, 54, 0.75)"
                    size="24px"
                  />
                </CloseButton>
              )}

              <Title>
                {closeType && closeType === "back" && (
                  <BackArrow type="button" onClick={handleClose}>
                    <Icon
                      icon={leftArrow}
                      color="rgba(45, 52, 54, 0.75)"
                      size="24px"
                    />
                  </BackArrow>
                )}
                <span>{title}</span>
              </Title>

              {children}
            </Sidebar>
          </AnimationFixed>

          <Animation name="fadeIn" className={`animation-${state}`}>
            <Backdrop onClick={handleClose} />
          </Animation>
        </>
      )}
    </Transition>,
    document.getElementById("sidebars") as HTMLElement
  );
};

export default Component;
