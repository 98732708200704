import styled from 'styled-components'

export const Card = styled('div')`
  border: 1px solid rgba(105, 119, 155, 25%);
  border-radius: 16px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
`
export const Title = styled('div')`
  font-weight: 600;
  font-size: 14px;
`
export const Text = styled('div')`
  font-size: 14px;
`