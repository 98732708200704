import styled from "styled-components";
import { Link } from "react-router-dom";

export const LoginMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Welcome = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  margin-right: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  color: #000000;
`;

export const LinkItem = styled(Link)<{ marginBottom? }>`
  text-decoration: none;
  line-height: 33px;
`;

export const ButtonItem = styled("button")<{ marginBottom?; alignSelf? }>`
  padding: 0px;
  margin: 0px;
  border: 0px;
  background: 0px;
  cursor: pointer;
`;

export const Actions = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 0px 8px;
`;
