import styled from "styled-components";
import ReactSelect from "react-select";
import InputPhone from "react-phone-number-input/input";

export const OptionContainer = styled("div")`
  display: flex;
  gap: 10px;
`;

const resetSelect = `
  margin: 0px;
  padding: 0px;
  border: 0px;
  border-radius: 0px;
  background: none;
  box-shadow: none;`;

export const Select = styled(ReactSelect)<{
  theme;
  before;
  variant;
  size;
  css;
}>`
  ${resetSelect}
  font-size: ${(props) => {
    return props.theme.form.size[props.size].fontSize;
  }};
  font-weight: 400;
  letter-spacing: 0.009375em;
  box-sizing: border-box;
  width: 100%;
  min-height: ${(props) => props.theme.form.size[props.size].height};
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  position: static !important;

  .select__indicators {
    cursor: pointer;

    .select__dropdown-indicator {
      transition: all 0.2s ease-in-out;
      transform: rotate(0deg);
    }

    span {
      display: none;
    }

    .select__indicator {
      padding: 0;
    }
  }

  & > .select__control,
  & > .select__control:hover {
    ${resetSelect}

    width: 100%;
    color: rgba(0, 0, 24, 0.6);
    padding: ${(props) => (props.before ? "0 16px 0 43px" : "0px")};
    min-height: ${(props) => props.theme.form.size[props.size].height};

    .select__value-container {
      ${resetSelect}

      &.select__value-container--is-multi {
        gap: 3px;
        margin: 4px 0;
      }

      .select__placeholder {
        ${resetSelect}
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      [class^="css-"] {
        ${resetSelect}
      }

      .select__single-value {
        margin: 0px;
      }

      .select__multi-value {
        background: #e7e5fc;
        border-radius: 10px;
        padding: 2px 10px;
        margin-right: 5px;

        .select__multi-value__label {
          margin-right: 10px;
          background: transparent;
        }

        .select__multi-value__remove {
          cursor: pointer;
        }
      }

      .select__input {
        input {
          font-family: ${(props) => props.theme.fonts.family};
        }
      }
    }

    .select__indicators {
      .select__indicator {
        img {
          width: 10px;
          height: auto;
        }
      }
    }

    &.select__control--menu-is-open {
      ${resetSelect}

      .select__indicators {
        .select__dropdown-indicator {
          transform: rotate(180deg);
        }
      }
    }

    &.select__control--menu-is-focused {
      ${resetSelect}
    }
  }

  & > .select__menu {
    min-width: 100%;
    margin-top: 4px;
    border-radius: 8px;
    background: ${(props) =>
      props.variant === "lighten"
        ? "#ffffff"
        : `${(props) => props.theme.colors.Gray}`};
    position: absolute;
    z-index: 20;
    padding: 8px;
    left: 0;
    right: 0;

    .select__placeholder,
    .select__value-container .select__single-value {
    }

    .select__menu-list {
      padding: 0px;
      max-height: 120px;

      .select__option {
        border-radius: 4px;
        font-weight: 600;
        padding: 8px 12px;
        line-height: 20px;
        font-size: ${(props) => props.theme.form.size[props.size].fontSize};
        cursor: pointer;

        &.select__option--is-selected,
        &.select__option--is-focused {
          color: ${(props) => {
            return props.theme.form.skin[props.variant].idle.color;
          }};
          background: ${(props) =>
            props.variant === "lighten"
              ? "rgba(0, 0, 24, 0.04)"
              : "rgba(0, 0, 24, 0.08)"};
        }
      }
    }
  }

  ${(props) => {
    return props.css;
  }};
`;

export const makeCustomSup = () => {
  return `color: red;`;
};

export const makeAutocomplete = styled(Select)`
  & .select__dropdown-indicator {
    display: none;
  }
`;

const resetFieldStyles = `
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  outline: none;
  border: 0px;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  width: 100%;`;

export const Input = styled(InputPhone)<{ skin; size }>`
  ${resetFieldStyles}
  height: calc(${(props) => props.theme.form.size[props.size].height} - 2px);
  line-height: ${(props) => props.theme.form.size[props.size].lineHeight};
  font-size: ${(props) => props.theme.form.size[props.size].fontSize};
  font-family: ${(props) => props.theme.fonts.family};
  color: ${(props) => props.theme.colors.Black};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px
      ${(props) => props.theme.form.skin[props.skin].idle.backgroundColor} inset !important;
    background-color: transparent !important;
    background-image: none !important;
    color: ${(props) => props.theme.colors.Black} !important;
    font-family: ${(props) => props.theme.fonts.family};
    font-size: ${(props) => props.theme.form.size[props.size].mobileFontSize};
    line-height: ${(props) =>
      props.theme.form.size[props.size].mobileLineHeight};

    @media (min-width: 768px) {
      font-size: ${(props) => props.theme.form.size[props.size].fontSize};
      line-height: ${(props) => props.theme.form.size[props.size].lineHeight};
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const CountryName = styled("span")`
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
`;

export const CountryCode = styled("span")`
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  @media (min-width: 768px) {
    width: auto;
    height: auto;
    overflow: visible;
    position: initial;
  }
`;
