import { ChargebeeSubscriptionPage } from "../../../types";

export const GET_SUBSCRIPTIONS_MANAGER_URL = "GET_SUBSCRIPTIONS_MANAGER_URL";
export const GET_SUBSCRIPTIONS_MANAGER_URL_SUCCESS = "GET_SUBSCRIPTIONS_MANAGER_URL_SUCCESS";
export const GET_SUBSCRIPTIONS_MANAGER_URL_ERROR = "GET_SUBSCRIPTIONS_MANAGER_URL_ERROR";

export interface GetSubscriptionsManagerUrl {
  type: typeof GET_SUBSCRIPTIONS_MANAGER_URL;
  payload: null;
}

export interface GetSubscriptionsManagerUrlSuccess {
  type: typeof GET_SUBSCRIPTIONS_MANAGER_URL_SUCCESS;
  payload: ChargebeeSubscriptionPage;
}

export interface GetSubscriptionsManagerUrlError {
  type: typeof GET_SUBSCRIPTIONS_MANAGER_URL_ERROR;
  payload: boolean | string;
}

export interface State {
  data: ChargebeeSubscriptionPage | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetSubscriptionsManagerUrl
  | GetSubscriptionsManagerUrlSuccess
  | GetSubscriptionsManagerUrlError;
