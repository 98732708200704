import Button from '../../../../../../../components/Button'
import {
  Preview,
  Title,
  Text,
  Option,
  StrongLabel,
  Value,
} from "../../../styles";

interface ComponentProps {
  setShowNewPromotionSidebar
  children?
}

const Component = ({ setShowNewPromotionSidebar, children }: ComponentProps) => {
  return (
    <>
      <Option>
        <StrongLabel>Descuentos</StrongLabel>
        <div style={{ height: 8 }} />
        <Value>Crea un descuento para hacer este precio más atractivo. Solo puedes tener un descuento activo por precio y aplica únicamente para nuevos suscriptores.</Value>
      </Option>

      {children}

      <div style={{ height: 16 }} />

      <Button
        options={{
          type: "outline",
          skin: "purple",
          size: "lg",
          block: true
        }}
        onClick={() => setShowNewPromotionSidebar(true)}
      >
        {!!children ? 'Reemplazar descuento' : 'Crear descuento'}
      </Button>
    </>
  )
}

export default Component
