import { EditSubscription, EDIT_SUBSCRIPTION, ResetEditSubscription, RESET_EDIT_SUBSCRIPTION } from "../types/subscription-admin/edit-subscription";
import { CancelSubscription, CANCEL_SUBSCRIPTION, RESET_CANCEL_SUBSCRIPTION, ResetCancelSubscription } from "../types/subscription-admin/cancel-subscription";
import { GetSubscription, GET_SUBSCRIPTION, RESET_GET_SUBSCRIPTION, ResetGetSubscription } from "../types/subscription-admin/get-subscription";

export const editSubscription = (payload: any): EditSubscription => {
    return { type: EDIT_SUBSCRIPTION, payload };
  };
  
  export const resetEditSubscription = (): ResetEditSubscription => {
    return { type: RESET_EDIT_SUBSCRIPTION, payload: null };
  };
  
  export const cancelSubscription = (payload: any): CancelSubscription => {
    return { type: CANCEL_SUBSCRIPTION, payload };
  };
  
  export const resetCancelSubscription = (): ResetCancelSubscription => {
    return { type: RESET_CANCEL_SUBSCRIPTION, payload: null };
  };

  export const getSubscription = (payload: number): GetSubscription => {
    return { type: GET_SUBSCRIPTION, payload };
  };

  export const resetGetSubscription = (): ResetGetSubscription => {
    return { type: RESET_GET_SUBSCRIPTION, payload: null };
  };