import {
  initialState,
  Actions,
  State,
  SAVE_MEET,
  SAVE_MEET_SUCCESS,
  SAVE_MEET_ERROR,
  GET_MEET,
  GET_MEET_SUCCESS,
  GET_MEET_ERROR,
} from "../types/meet";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SAVE_MEET:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case SAVE_MEET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: false,
        },
      };
    case SAVE_MEET_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: true,
          success: false,
        },
      };
    case GET_MEET:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case GET_MEET_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: false,
        },
      };
    case GET_MEET_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: true,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default reducers;
