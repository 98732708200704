import { Explanation, Acordeon, Guide, Title } from "./styles";
import { ImageWrapper, Image, Denomination } from "../../components/styles";
import constants from "../../../../constants";
import Grid from "../../../../components/Grid";
import Row from "../../../../components/Grid/Row";
import Col from "../../../../components/Grid/Col";
import { useState } from "react";
import { useWindowSize } from "../../../../hooks/useFormHook";

const Component = () => {
  const [currentItem, setCurrentItem] = useState(0);
  const { isMobile } = useWindowSize();

  return (
    <Explanation>
      <Grid.Container {...(!isMobile && { gutter: 0 })}>
        <Row {...(!isMobile && { gutter: 0 })}>
          <Col {...(!isMobile && { gutter: 0 })}>
            <Row {...(!isMobile && { gutter: 0 })}>
              <Col offset={{ sm: 1 }} sm={5} {...(!isMobile && { gutter: 0 })}>
                <Denomination>
                  <Guide>INTERACTUA</Guide>
                  <Title>¡Conecta de verdad!</Title>
                </Denomination>

                {isMobile && (
                  <Col sm={5} gutter={0}>
                    <ImageWrapper>
                      <Image
                        src={constants.homePage.interact[currentItem].image}
                        alt=""
                        width=""
                        height=""
                        lazy={true}
                      />
                    </ImageWrapper>
                  </Col>
                )}

                <Acordeon
                  onChange={setCurrentItem}
                  items={constants.homePage.interact}
                  align={"left"}
                />
              </Col>

              {!isMobile && (
                <Col
                  offset={{ sm: 1 }}
                  sm={5}
                  {...(!isMobile && { gutter: 0 })}
                >
                  <ImageWrapper>
                    <Image
                      src={constants.homePage.interact[currentItem].image}
                      alt=""
                      width=""
                      height=""
                      lazy={true}
                    />
                  </ImageWrapper>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Grid.Container>
    </Explanation>
  );
};

export default Component;
