const size = {
  xs: {
    fontSize: "14px",
    lineHeight: "20px",
    borderRadius: "6px",
  },
  sm: {
    fontSize: "14px",
    lineHeight: "30px",
    borderRadius: "6px",
  },
  md: {
    fontSize: "14px",
    lineHeight: "32px",
    borderRadius: "6px",
  },
  lg: {
    fontSize: "14px",
    lineHeight: "40px",
    borderRadius: "6px",
  },
  xl: {
    fontSize: "24px",
    lineHeight: "52px",
    borderRadius: "6px",
  },
};

const colors = {
  outline: {
    purple: {
      color: "Primary",
      borderColor: "Primary",
      backgroundColor: "Transparent25",
    },
    transparent: {
      color: "White",
      borderColor: "Transparent25",
      backgroundColor: "Transparent25",
    },
    white: {
      color: "White",
      borderColor: "White",
      backgroundColor: "Transparent0",
    },
    "white-black": {
      color: "Black",
      borderColor: "Black",
      backgroundColor: "White",
    },
    lightGreen: {
      color: "Black",
      borderColor: "LightGreen",
      backgroundColor: "Transparent25",
    },
    lightBlue: {
      color: "Black",
      borderColor: "LightBlue2",
      backgroundColor: "Transparent25",
    },
    rainbow: {
      color: "RainbowText",
      borderColor: "RainbowBackground",
      backgroundColor: "Transparent0",
    },
  },
  filled: {
    purple: {
      color: "White",
      borderColor: "Primary",
      backgroundColor: "Primary",
    },
    transparent: {
      color: "White",
      borderColor: "Transparent25",
      backgroundColor: "Transparent25",
    },
    lightGray: {
      color: "Primary",
      borderColor: "LightGray",
      backgroundColor: "LightGray",
    },
    lightBlue: {
      color: "White",
      borderColor: "LightBlue2",
      backgroundColor: "LightBlue2",
    },
    lightGreen: {
      color: "White",
      borderColor: "LightGreen",
      backgroundColor: "LightGreen",
    },
    white: {
      color: "Primary",
      borderColor: "Primary",
      backgroundColor: "White",
    },
    "white-black": {
      color: "Black",
      borderColor: "Black",
      backgroundColor: "White",
    },
    rainbow: {
      color: "White",
      borderColor: "RainbowBackground",
      backgroundColor: "RainbowBackground",
    },
  },
  link: {
    purple: {
      color: "Primary",
      borderColor: null,
      backgroundColor: null,
    },
    transparent: {
      color: "Primary",
      borderColor: "Transparent25",
      backgroundColor: "transparent",
    },
    white: {
      color: "White",
      borderColor: "transparent",
      backgroundColor: "Transparent0",
    },
    "white-black": {
      color: "Black",
      borderColor: "Black",
      backgroundColor: "White",
    },
    lightBlue: {
      color: "LightBlue2",
      borderColor: "transparent",
      backgroundColor: "transparent",
    },
  },
};

export const link = {
  size,
  colors
};
