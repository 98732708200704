import styled from "styled-components";
import ImageUi from "../../../../components/Image";

export const FrontPage = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin-bottom: 80px;
  }
`;

export const Background = styled.div`
  position: static;
  display: flex;
  overflow: visible;
  width: 100vw;
  padding-top: 100px;
  padding-bottom: 333px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  background-color: ${(props) => props.theme.colors.PurpleVeryLight};

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding-bottom: 310px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    padding-bottom: 500px;
  }
`;

export const BannerWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 90%;
  margin-top: -250px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px none #000;
  max-width: 1280px;
  padding-bottom: 60px;
  padding-right: 0px;
  padding-left: 40px;
  overflow: scroll;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    border-radius: 18px;
    padding-bottom: 0px;
    box-shadow: 0 0 20px 20px rgb(0 0 0 / 11%);
    padding-right: 0px;
    padding-left: 0px;
    margin-top: -260px;
    width: 90%;
    overflow: hidden;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    margin-top: -400px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.lgMin}) {
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const HeroBanner = styled.div`
  position: relative;
  z-index: 10;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  border-radius: 18px;
  box-shadow: 0 0 20px 20px rgb(0 0 0 / 8%);
  width: 970px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    box-shadow: none;
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
  }
`;

export const Image = styled(ImageUi)`
  width: 100%;
  height: auto;
`;
