import {
  initialState,
  Actions,
  State,
  GET_SUBSCRIPTIONS_MANAGER_URL,
  GET_SUBSCRIPTIONS_MANAGER_URL_SUCCESS,
  GET_SUBSCRIPTIONS_MANAGER_URL_ERROR,
} from "../../types/user/get-subscription-manager-url";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_MANAGER_URL:
      return {
        ...state,
        data: null,
        states: {
          success: false,
          loading: true,
          error: false,
        },
      };
    case GET_SUBSCRIPTIONS_MANAGER_URL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          success: true,
          loading: false,
          error: false,
        },
      };
    case GET_SUBSCRIPTIONS_MANAGER_URL_ERROR:
      return {
        ...state,
        data: null,
        states: {
          success: false,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducers;
