import { Formik, FormikHelpers } from "formik";
import { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { schema, initialValues } from '../../../../../../constants/forms/promotion/new'
import Sidebar from "../../../../../../components/Sidebar";
import * as Yup from "yup";
import {
  Preview,
  Title,
  Text,
  Option,
  Label,
  StrongLabel,
  StrongValue,
} from "../../styles";
import Form from "../components/NewPromotionForm"
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/FormikForm/Submit";
import Grid from "../../../../../../components/Grid";
import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import { DiscountOutDto, Group, NewDiscountOutDto, PlanDto } from "../../../../../../types";
import { newDiscount } from '../../../../../../store/actions/discount'
import { selectedPlan } from "../../../../../../store/actions/plan";
import { Center } from "../../../../../../components/StyledComponents";
import { setToZeroTimeZone } from "../../../../../../helpers/dates-hanlder";
import { ConnectedFocusError } from "../../../../../../components/ConnectedFocusError";


interface ComponentProps {
  isVisible: boolean
  onClose
  group: Group
  createdDiscount,
  createdDiscountStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  selectedPlan: PlanDto
  selectedPlanStates
}

const Component = ({
  isVisible,
  onClose,
  group,
  createdDiscount,
  createdDiscountStates,
  selectedPlan,
  selectedPlanStates
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [actions, setActions] = useState<FormikHelpers<any>>();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);


  const getCalculatedPeriod = ({ period_promotion, period_plan }) => {
    return period_promotion * period_plan;
  }

  const onSubmit = ({ values, actions }) => {
    const payload: NewDiscountOutDto = {
      plan_ids: [selectedPlan.id],
      group_id: group?.id,
      // coupon_id: null,
      // name: null,
      type: 'fixed_amount',
      amount: Number(selectedPlan?.price) - Number(values.amount),
      valid_till: values.valid_till_type === "indefinido" ? null : setToZeroTimeZone(values.valid_till),
      show_in_page: true,
      status: values.status,
      ...(selectedPlan?.type === "subscription" ? {
        period_unit: values.period_type === "indefinido" ? null : selectedPlan.period_unit,
        period: values.period_type !== "indefinido" && values.period ? getCalculatedPeriod({ period_promotion: Number(values.period), period_plan: selectedPlan.period }) : null, //only for discount for subscription plan types.
        duration_type: values.period_type === "indefinido" ? "forever" : "limited_period"
      } : {
        duration_type: "one_time"
      }) //only for discount for subscription plan types.
    }

    dispatch(
      newDiscount(payload)
    );
    setActions(actions)
  };

  useEffect(() => {
    if (createdDiscountStates.success && actions) {
      actions.resetForm();
    }
  }, [createdDiscountStates, actions])

  return (
    <>
      <Sidebar
        position="right"
        visible={isVisible}
        closeType="back"
        onClose={() => {
          //the sidebar is outside of formik context so reseting the form is not working. I implement this solution to move foward.
          if (cancelButtonRef.current) {
            cancelButtonRef.current.click();
          }
          //!!onClose && onClose();
        }}
        width="520px"
        title="Nuevo descuento"
      >
        <Preview>
          <Formik
            initialValues={{
              ...initialValues,
              amount: "",
              period: ""
            }}
            onSubmit={(values, actions) => {
              if (onSubmit) onSubmit({ values, actions });
            }}
            onReset={() => console.log("reseteado")}
            validateOnChange={formSubmmited}
            validateOnBlur={false}
            validationSchema={schema(selectedPlan?.price)}
            enableReinitialize
          >
            {(props) => {

              return (
                <>
                  <ConnectedFocusError />
                  <div>
                    <Option>
                      <StrongLabel>Precio del plan</StrongLabel>
                      <StrongValue>{selectedPlan?.currency} {selectedPlan?.price}</StrongValue>
                    </Option>

                    <Option>

                      <form
                        className="theme-form"
                        onSubmit={(event) => {
                          setFormSubmmited(true);
                          props.handleSubmit(event);
                        }}
                        id="new-discount-form"
                      >
                        <Form {...props} isLoading={createdDiscountStates.loading} />
                      </form>

                    </Option>
                  </div>

                  <Center horizontal="center" vertical="center" gap={10}>
                    <Button
                      forwardref={cancelButtonRef}
                      options={{
                        type: "outline",
                        skin: "purple",
                        size: "lg",
                        block: true
                      }}
                      onClick={() => {
                        props.resetForm()
                        !!onClose && onClose()
                      }}
                    >
                      Cancelar
                    </Button>

                    <Submit
                      isSubmmiting={createdDiscountStates.loading}
                      disabled={createdDiscountStates.loading}
                      options={{
                        type: "filled",
                        size: "lg",
                        skin: "purple",
                        block: true
                      }}
                      form="new-discount-form"
                    >
                      Crear descuento
                    </Submit>
                  </Center>
                </>
              );
            }}
          </Formik>
        </Preview>
      </Sidebar>
    </>
  )
}


const state = ({ groupStore, discountStore, planStore }) => {
  const { data: group } = groupStore.group;
  const { data: createdDiscount, states: createdDiscountStates } = discountStore.newDiscount;
  const { data: selectedPlan, states: selectedPlanStates } = planStore.selectedPlan;

  return {
    group,
    createdDiscount,
    createdDiscountStates,
    selectedPlan,
    selectedPlanStates,
  };
};

export default connect(state)(Component);
