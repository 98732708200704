import { useEffect, useState } from "react";
import scripts from "../constants/scripts";

const ScriptInjecter = () => {
  const [pageLoaded, setPageLoaded] = useState(false);

  const injectScripts = () => {
    scripts.forEach((script) => {
      const { parent, type, code, src, href, attributes, location, lazyLoad } =
        script;
      const parentElement = document.querySelector(parent);
      const element = document.createElement(type);

      if (((lazyLoad && pageLoaded) || !lazyLoad) && parentElement) {
        if (code) {
          element.innerHTML = code;
        }
        if (src) {
          element.setAttribute("src", src);
        }
        if (href) {
          element.setAttribute("href", href);
        }
        if (attributes) {
          attributes.forEach((attribute) => {
            const { name, value } = attribute;
            element.setAttribute(name, value);
          });
        }
        if (location === "start") {
          parentElement?.prepend(element);
        } else {
          parentElement?.appendChild(element);
        }
      }
    });
  };

  useEffect(() => {
    if (pageLoaded) {
      injectScripts();
    }
  }, [pageLoaded]);

  useEffect(() => {
    setPageLoaded(true);
    // window.addEventListener("load", () => {
    //   console.log("pageLoaded");
    // });

    // return () => {
    //   window.removeEventListener("load", () => {
    //     setPageLoaded(false);
    //   });
    // };
  }, []);

  return null;
};

export default ScriptInjecter;
