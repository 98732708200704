import {
  LinkCard,
  NativeLinkCard,
  ButtonCard,
  Image,
  Text,
  Title,
  Description,
  SuffixContainer,
  HideInMobile,
} from "./styles";
import chevronNext from "../../../../assets/icons/chevron-next.svg";
import openLink from "../../../../assets/icons/open-link.svg";

interface ComponentProps {
  title: string;
  description?: string;
  link?: string;
  action?: () => void;
  style?;
  active?: boolean;
  target?: "_self" | "_blank";
  options: {
    preffix?: string;
    showSuffix?: boolean;
    suffix?: string;
    color?: string;
    size?: "xsmall" | "small" | "normal";
    type?: "link" | "cta";
    nativeLink?: boolean;
    icon?: boolean;
  };
}

const Component = ({
  title,
  description,
  link,
  action,
  options,
  active,
  ...rest
}: ComponentProps) => {
  const {
    preffix,
    suffix = chevronNext,
    showSuffix = false,
    color,
    size = "normal",
    type = "cta",
    nativeLink = true,
    icon = true,
  } = options;

  const Content = () => (
    <>
      <Image
        size={size}
        src={preffix}
        width={size === "small" || size === "xsmall" ? "46px" : "76px"}
        height={size === "small" || size === "xsmall" ? "46px" : "76px"}
      />

      <Text size={size}>
        <Title size={size} hasDescription={!!description}>
          {title}
        </Title>
        <Description size={size}>{description}</Description>
      </Text>

      {showSuffix && (
        <HideInMobile>
          {type === "cta" && !!color && (
            <SuffixContainer background={color}>
              <img src={suffix} width="14px" height="14px" />
            </SuffixContainer>
          )}

          {type === "link" && (
            <img
              src={openLink}
              width="24px"
              height="24px"
              style={{ marginRight: "10px" }}
            />
          )}
        </HideInMobile>
      )}
    </>
  );

  if (!!link) {
    return nativeLink ? (
      <NativeLinkCard to={link} size={size} {...rest} active={active}>
        <Content />
      </NativeLinkCard>
    ) : (
      <LinkCard
        href={link}
        size={size}
        target="_blank"
        {...rest}
        active={active}
      >
        <Content />
      </LinkCard>
    );
  }

  if (!!action) {
    return (
      <ButtonCard onClick={action} size={size} {...rest} active={active}>
        <Content />
      </ButtonCard>
    );
  }

  return <></>;
};

export default Component;
