import { NewReactionInDto, NewReactionOutDto } from "../../../types/reaction.dto";
export const NEW_REACTION = "NEW_REACTION";
export const NEW_REACTION_SUCCESS = "NEW_REACTION_SUCCESS";
export const NEW_REACTION_ERROR = "NEW_REACTION_ERROR";
export const RESET_NEW_REACTION = "RESET_NEW_REACTION";

export interface NewReaction {
  type: typeof NEW_REACTION;
  payload: NewReactionOutDto;
}

export interface NewReactionSuccess {
  type: typeof NEW_REACTION_SUCCESS;
  payload: NewReactionInDto;
}

export interface NewReactionError {
  type: typeof NEW_REACTION_ERROR;
  payload: string | boolean;
}

export interface ResetNewReaction {
  type: typeof RESET_NEW_REACTION;
  payload: null;
}

export interface State {
  data: NewReactionInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions = NewReaction | NewReactionSuccess | NewReactionError | ResetNewReaction;
