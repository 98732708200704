import styled from "styled-components";

export const Step = styled("button")<{ state }>`
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  margin: 0px;
  padding: 10px 0px;
  border: 0px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  outline: 0px;
  color: #717680;

  &:not(:last-child) {
    margin-right: 20px;
  }

  ${({ state }) => {
    if (state === "CURRENT") {
      return `
        color: #000000;
        font-weight: 700;

        &:after {
          content: '';
          height: 3px;
          background-color: #000000;
          display: block;
          width: 100%;
          position: absolute;
          bottom: 0px;
          left: 0px;
        }
      `;
    }

    return "";
  }}

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 24px;
  }
`;
