import {
  Card,
  Title,
  Text,
  Link
} from './styles'
import Image from '../../../../../components/Image'
import mercadoPagoImg from '../../../../../assets/mercado-pago.png'
import { useTheme } from 'styled-components';
import Icon from '../../../../../components/Icon';
import arrowLinkIcon from '../../../../../assets/icons/arrow_forward_up_right-semibold.svg'
import { useMemo } from 'react';
import { connect } from 'react-redux';
import { Gateway } from '../../../../../types/gateway';

interface ComponentProps {
  userData;
}

const Component = ({ userData }: ComponentProps) => {
  const theme = useTheme() as { logo: { small: string, big: string } };

  const primaryGateway = useMemo((): Gateway => userData?.expert?.linked_gateways?.find(lg => lg.priority === "primary" && lg.currency === "ARS"), [userData])

  if (!!primaryGateway) {
    return <></>
  }

  return (
    <Card style={{ maxWidth: 350 }}>

      <div style={{ gap: 24, display: 'flex', justifyContent: 'center', width: '100%', maxWidth: 410 }}>
        <Image
          src={theme?.logo?.big}
          style={{ display: 'block' }}
          width="107px"
          height="auto"
          className="logo-desktop"
          alt="Klouser"
          lazy
        />

        <div style={{ height: 30, width: 1, backgroundColor: '#B1C6E4' }} />

        <Image
          src={mercadoPagoImg}
          style={{ display: 'block' }}
          width="101px"
          height="auto"
          className="logo-desktop"
          alt="Mercado pago"
          lazy
        />
      </div>
      <div style={{ height: 18 }} />
      <Title>Paso a paso para conectar tu cuenta de Mercado Pago</Title>
      <div style={{ height: 18 }} />
      <Text>Te mostramos cómo hacerlo de forma rápida y sencilla para empezar a recibir pagos en pesos argentinos sin complicaciones.</Text>
      <div style={{ height: 18 }} />
      <Link href='https://www.notion.so/klouser/C-mo-configuro-mi-cuenta-de-Mercado-Pago-en-Klouser-1146ace4971c807581dbf142df54bcc8' target='_blank' options={{ size: 'sm', skin: 'purple', type: 'link', native: false }}>
        Ver tutorial
        <Icon icon={arrowLinkIcon} size="14px" color='rgb(108, 92, 231)' />
      </Link>
    </Card>
  )
}

const state = ({ userStore }) => {
  const { data: userData } = userStore.information;

  return {
    userData,
  };
};

export default connect(state)(Component);