import { RestoreModulesInDto, RestoreModulesOutDto } from "../../../types/module";

export const RESTORE_MODULES = "RESTORE_MODULES";
export const RESTORE_MODULES_SUCCESS = "RESTORE_MODULES_SUCCESS";
export const RESTORE_MODULES_ERROR = "RESTORE_MODULES_ERROR";
export const RESET_RESTORE_MODULES = "RESET_RESTORE_MODULES";

export interface RestoreModules {
  type: typeof RESTORE_MODULES;
  payload: RestoreModulesOutDto;
}

export interface RestoreModulesSuccess {
  type: typeof RESTORE_MODULES_SUCCESS;
  payload: RestoreModulesInDto;
}

export interface RestoreModulesError {
  type: typeof RESTORE_MODULES_ERROR;
  payload: string | boolean;
}

export interface ResetRestoreModules {
  type: typeof RESET_RESTORE_MODULES;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | RestoreModules
  | RestoreModulesSuccess
  | RestoreModulesError
  | ResetRestoreModules;
