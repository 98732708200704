import styled, { css } from "styled-components";
import UiAvatar from "../../../../../../components/Avatar";
import Button from "../../../../../../components/Button";
import Link from "../../../../../../components/Link";
import DropdownUi from "../../../../../../components/Dropdown";

const resetButtonStyles = css`
  margin: 0px;
  padding: 0px;
  border: 0px;
  background: none;
  cursor: pointer;
`;

export const MobileHeader = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PostType = styled("div")`
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 10px;
`;

export const Actions = styled("div")`
  display: flex;
  align-items: center;
  gap: 10px;
  @media(min-width: 768px){
    gap: 40px;
  }
`;

export const ShareCta = styled("button")`
  ${resetButtonStyles}
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${({theme}) => theme.colors.Primary};
  font-size: 14px;

  @media(min-width: 768px){
    gap: 10px;
  }
`;

export const MenuCta = styled("button")`
  ${resetButtonStyles}
`;

export const Title = styled("div")`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 8px;
`;

export const Name = styled("div")`
  font-size: 16px;
`;

export const Avatar = styled(UiAvatar)``;


export const AvatarImage = styled("div")`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const SwipeContent = styled("div")`
  display: flex;
  width: 100%;
  padding: 0px 18px 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InLine = styled("div")`
  display: flex;
  //align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const PublicationStateData = styled("span")`
  color: #fff;
  display: flex;
  gap: 10px;
  //padding: 5px 5px 5px 10px;

  @media (max-width: 768px) {
    display: inline-flex; /* Change to block on smaller screens for full width */
    align-items: center;
  }
`;


export const PublicationState = styled("div")`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  display: inline-block;
  justify-content: flex-start;
  padding: 10px 10px 10px 10px;
  margin-bottom: 40px;
  border-radius: 6px;
  max-width: 100%;

  @media (max-width: 768px) {
    display: block; /* Change to block on smaller screens for full width */
  }
`;

export const CreatedAt = styled("span")`
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;

  i {
    margin-top: 3px;
  }
`;

export const LinkItem = styled(Link) <{ marginBottom?; alignSelf?; native?}>`
  text-decoration: none;
  color: ${(props) => props.theme.colors.Primary};
  font-size: 14px;
  line-height: 33px;
  font-weight: 700;
  letter-spacing: 0.25px;
  text-decoration: none;
  &:hover{
    box-shadow: none;
    text-decoration: none;
  }
`;

export const ButtonItem = styled(Button) <{ marginBottom?; alignSelf?}>`
  text-decoration: none;

  &:hover{
    box-shadow: none;
    text-decoration: none;
  }
`;

export const Dropdown = styled(DropdownUi) <{ alignSelf?}>`

`;