import styled from "styled-components";
import { Link as LinkRrd } from "react-router-dom";
import LinkUi from "./../Link";

export const GroupCard = styled.div`
  background: ${(props) => props.theme.colors.White};
  box-shadow: 2px 1px 4px rgba(0,0,0,0.08);
  border-radius: 16px;
  position: relative;
`;

export const GroupLink = styled("a")`
  display: block;
  text-decoration: none;
  border-radius: 16px;
  overflow: hidden;
`;

export const GroupCardHeader = styled("div") <{ status }>`
  height: 81px;
  padding-bottom: 23px;
  position: relative;
`;

export const GroupCardBody = styled.div`
  padding: 8px 20px 20px;
`;

export const GroupCardFooter = styled.div``;

export const ExpertName = styled.h5`
  margin: 0px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.theme.colors.Gray9};
  color: rgba(45, 52, 54, 0.8);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const GroupName = styled.h3`
  margin: 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.Black};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const GroupDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  min-height: 34px;
  margin: 0px;
  color: ${(props) => props.theme.colors.Black};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const Actions = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  position: absolute;
  z-index: 2;
  right: 20px;
  height: 58px;

  & > * {
    flex-shrink: 0;
    width: 27px;
    height: 27px;
    background-color: white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Link = styled(LinkUi)`
  line-height: normal;
  padding: 5px 7px;
`;

export const Copy = styled.div``;

export const Cover = styled("div") <{ background }>`
  width: 100%;
  height: 100%;
  background-color: white;
  background-size: cover;
  background-position: center;
  background-image: url(${({ background }) => {
    return !!background ? background : "";
  }});
`;

export const Avatar = styled("div") <{ background }>`
  position: absolute;
  z-index: 1;
  width: 47px;
  height: 47px;
  background-color: black;
  background-size: cover;
  background-position: center;
  background-image: url(${({ background }) => {
    return !!background ? background : "";
  }});

  border-radius: 100%;
  position: absolute;
  bottom: 0;
  left: 20px;
  outline: 2px solid white;
`;

const states = {
  active: '#12B47A',
  inactive: '#CA1931',
  draft: '#B0B0B0'
}

export const State = styled('div') <{ state }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 400;
  color: ${({ state }) => states[state]};

  &:before {
    content: '';
    display: flex;
    background-color: ${({ state }) => states[state]};
    width: 9px; 
    height: 9px; 
    border-radius: 100%;
  }
`

export const GroupTypeIcon = styled('div')`
  width: 24px;
  height: 24px;
  background-color: #6DA5F3;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  position: absolute;
  z-index: 10;
    bottom: -4px;
  left: 50px;
`