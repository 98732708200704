import {
  initialState,
  Actions,
  State,
  UPDATE_EXPERT_INFORMATION,
  UPDATE_EXPERT_INFORMATION_SUCCESS,
  UPDATE_EXPERT_INFORMATION_ERROR,
} from "../../types/user/update-expert-information";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case UPDATE_EXPERT_INFORMATION:
      return {
        ...state,
        states: {
          success: false,
          loading: true,
          error: false,
        },
      };
    case UPDATE_EXPERT_INFORMATION_SUCCESS:
      return {
        ...state,
        states: {
          success: true,
          loading: false,
          error: false,
        },
      };
    case UPDATE_EXPERT_INFORMATION_ERROR:
      return {
        ...state,
        states: {
          success: false,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducers;
