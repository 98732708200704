import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import constants from "../../../constants";
import { showModal, hideModal } from "../../../store/actions/modal";
import Image from "../../Image";
import Modal from "../../Modal";
import pencil from "../../../assets/pencil.svg";
import avatar from "../../../assets/avatar.svg";
import {
  Avatar,
  AvatarEditor,
  UserAvatar,
  AvatarCta,
  UpdatePicture,
  Cancel,
  SavePicture,
  Editor,
  Actions,
  ValidationError,
  AvatarErrorMessage,
  Zoom,
} from "./styles";

interface ComponentsProps {
  picture?;
  onChange?;
  onBlur?;
  onFocus?;
  onSave?;
  register?;
  errors?;
  name;
  rules?;
  setValue;
}

const errorMessages = {
  extensionError: "Formatos permitidos .JPEG y .PNG",
  sizeError: "El peso de la imagen debe ser menor a 5Mb",
};

const isValidExtension = (event) => {
  const selectedImageExtension =
    event.target.files[0] &&
    event.target.files[0].name &&
    event.target.files[0].name.split(".").pop();

  return constants.userAvatar.validExtensions.includes(selectedImageExtension);
};

const isValidSize = (event) =>
  event.target.files[0].size / 1024 / 1024 < constants.userAvatar.maxSize;

const Component = ({
  picture,
  onChange,
  onBlur,
  onFocus,
  onSave,
  register,
  errors,
  name,
  rules,
  setValue,
}: ComponentsProps) => {
  const dispatch = useDispatch();
  const zoomInputRef = useRef<HTMLInputElement>(null);
  const avatarEditorRef = useRef<any>();
  const [userAvatar, setUserAvatar] = useState(avatar);
  const [editing, setEditing] = useState(false);
  const [editingImage, setEditingImage] = useState(userAvatar);
  const [zoom, setZoom] = useState<number>(1);
  const [withError, setWithError] = useState<string | false>();

  const resetEdition = () => {
    setZoom(1);
    setEditing(false);
    setWithError(false);
    setEditingImage(avatar);
  };

  const savePicture = async () => {
    if (!!avatarEditorRef && !!avatarEditorRef.current) {
      const picture = avatarEditorRef.current.getImage().toDataURL();

      setUserAvatar(picture);
      setValue(name, picture);
      resetEdition();

      if (onSave) {
        onSave();
      }
    }
  };

  const onPictureChange = (event) => {
    const image = event.target.files[0];
    setWithError(false);

    if (!isValidExtension(event)) {
      setWithError("extensionError");
    }

    if (!isValidSize(event)) {
      setWithError("sizeError");
    }

    if (isValidSize(event) && isValidExtension(event)) {
      setEditing(true);
      setEditingImage(image);
    }
  };

  useEffect(() => {
    if (picture) {
      setUserAvatar(picture);
      setEditingImage(picture);
    }
  }, [picture]);

  useEffect(() => {
    setZoom(1);
  }, []);

  if (!userAvatar) {
    return null;
  }

  return (
    <Avatar>
      <input
        type="file"
        id="file"
        onChange={(event) => {
          onPictureChange(event);
          return register(name).onChange(event);
        }}
        onBlur={(event) => {
          return register(name).onBlur(event);
        }}
        hidden
        accept="image/jpeg;capture=camera"
      />

      <input
        type="text"
        hidden
        id={name}
        {...register(name)}
        value={userAvatar}
      />

      <UserAvatar
        src={userAvatar}
        width="auto"
        height="auto"
        alt="Avatar"
        hasError={!!errors[name]}
      />

      <AvatarCta
        type="button"
        onClick={() => dispatch(showModal("avatar-editor"))}
      >
        <Image src={pencil} width="10px" height="10px" alt="Edit" />
      </AvatarCta>

      {/* {errors[name] && (
        <AvatarErrorMessage>Selecciona una foto de perfil.</AvatarErrorMessage>
      )} */}

      <Modal
        name="avatar-editor"
        onClose={() => resetEdition()}
        title={editing ? "Edita tu foto" : "Selecciona tu foto"}
      >
        <Editor>
          <AvatarEditor
            ref={avatarEditorRef}
            image={editingImage}
            width={160}
            height={160}
            border={16}
            color={[0, 0, 24, 0.6]}
            scale={zoom}
            rotate={0}
            borderRadius={360}
          />

          {editing ? (
            <>
              <Zoom>
                Zoom:
                <input
                  ref={zoomInputRef}
                  type="range"
                  disabled={false}
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.002}
                  onChange={(event) => setZoom(Number(event.target.value))}
                />
              </Zoom>

              <Actions>
                <Cancel
                  options={{
                    type: "link",
                    size: "md",
                    skin: "purple",
                  }}
                  onClick={() => {
                    dispatch(hideModal());
                    resetEdition();
                  }}
                >
                  Cancelar
                </Cancel>

                <SavePicture
                  options={{
                    type: "filled",
                    size: "sm",
                    skin: "purple",
                  }}
                  onClick={() => savePicture()}
                >
                  Guardar
                </SavePicture>
              </Actions>
            </>
          ) : (
            <UpdatePicture htmlFor={"file"}>Elegir otra foto</UpdatePicture>
          )}

          {!!withError && (
            <ValidationError>{errorMessages[withError]}</ValidationError>
          )}
        </Editor>
      </Modal>
    </Avatar>
  );
};

export default Component;
