import {
  NewReaction,
  NEW_REACTION,
  ResetNewReaction,
  RESET_NEW_REACTION,
} from '../types/reaction/new-reaction'
import {
  DeleteReaction,
  DELETE_REACTION,
  ResetDeleteReaction,
  RESET_DELETE_REACTION
} from '../types/reaction/delete-reaction'
import { DeleteReactionOutDto, NewReactionOutDto } from '../../types/reaction.dto';

export const newReaction = (payload: NewReactionOutDto): NewReaction => {
  return { type: NEW_REACTION, payload };
};

export const resetNewReaction = (): ResetNewReaction => {
  return { type: RESET_NEW_REACTION, payload: null };
};

export const deleteReaction = (payload: DeleteReactionOutDto): DeleteReaction => {
  return { type: DELETE_REACTION, payload };
};

export const resetDeleteReaction = (): ResetDeleteReaction => {
  return { type: RESET_DELETE_REACTION, payload: null };
};