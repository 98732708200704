import { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import dateHandlder, {
  dateSpliter,
} from "../../../../../helpers/dates-hanlder";
import { SubscriptionContext } from "../../../../../providers/Subscription";
import constants from "../../../../../constants";
import { DiscountDto, Group, PlanDto, User } from "../../../../../types";
import { Button, Link, ValidTill } from "./styles";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import { isSubscribed } from "../../../../../helpers/isSubscribed";
import { isOwnGroup } from "../../../../../helpers/isOwnGroup";
import { showModal } from "../../../../../store/actions/modal";

const types = {
  community: {
    label: "Comunidad",
    period_unit: null,
  },
  experience: {
    label: "Taller",
    period_unit: null,
  },
  free_experience: {
    label: "Taller",
    period_unit: null,
  },
  membership: {
    label: "Membresía",
    period_unit: "month",
  },
};
interface ComponentProps {
  subscriptions;
  user: User;
  group: Group;
  options?: {
    block: boolean;
  };
  style?;
  showDiscount?: boolean;
  plan: PlanDto
}

const Component = ({
  subscriptions,
  user,
  group,
  options,
  style,
  showDiscount = false,
  plan,
}: ComponentProps) => {
  const dispatch = useDispatch()
  const { isMobile, isTabletPortrait } = useWindowSize();
  const [discount, setDiscount] = useState<DiscountDto>();
  const [currency, setCurrency] = useState<string>();
  const [suscriptionEnded, setSuscriptionEnded] = useState<boolean>();

  const { startSubscription } = useContext(SubscriptionContext);

  useEffect(() => {
    // if (!!group) {
    //   setPlan(
    //     group?.plans?.filter(
    //       (plan) => plan.period_unit === types[group?.group_type].period_unit
    //     )[0]
    //   );
    // }

    if (!!group?.registration_closing_date) {
      setSuscriptionEnded(
        dateHandlder.isExpired(group?.registration_closing_date)
      );
    }
  }, [group]);

  useEffect(() => {
    if (!!plan?.discounts) {
      setDiscount(plan?.discounts[0]);
    }

    if (!!plan?.currency) {
      setCurrency(
        constants.currencies.filter((item) => item.iso === plan?.currency)[0]
          .label
      );
    }
  }, [plan]);

  if (isSubscribed(subscriptions, group?.id)) {
    return <></>;
  }

  return (
    <>
      {isSubscribed(subscriptions, group?.id) ? (
        <Link
          href={`/group/${group?.slug}/library`}
          options={{
            size: "lg",
            skin: "purple",
            type: "filled",
            ...options,
          }}
          style={style}
        >
          Acceder
        </Link>
      ) : (
        <>
          <Button
            onClick={() => {
              if (group?.group_type === 'free_experience') {
                if (!user) {
                  dispatch(showModal("ask-for-user-information"))
                } else {
                  window.location.href = `/checkout/free-subscription/${group?.id}`
                }
              } else if (!suscriptionEnded) {
                startSubscription(plan)
              }
            }}
            disabled={
              suscriptionEnded ||
              isOwnGroup({ group, expertId: user?.expert?.id })
            }
            options={{
              size: "lg",
              skin: "purple",
              type: "filled",
              ...options,
            }}
            style={style}
          >
            {group?.group_type === "membership" && (
              <>{suscriptionEnded ? "Suscripción finalizada" : `Suscribirme`}</>
            )}

            {group?.group_type !== "membership" && (
              <>{suscriptionEnded ? "Inscripción finalizada" : `Inscribirme`}</>
            )}

            {!suscriptionEnded &&
              showDiscount &&
              !!discount &&
              ` por ${currency} ${(plan?.price || 0) - (plan?.discounts[0]?.amount || 0)
              }`}
          </Button>

          {/* {!isMobile &&
            !isTabletPortrait &&
            showDiscount &&
            !!discount &&
            !suscriptionEnded && (
              <ValidTill>
                Promoción válida hasta el{" "}
                {dateSpliter(discount?.valid_till).date}
              </ValidTill>
            )} */}
        </>
      )}
    </>
  );
};

const state = ({ userStore, groupStore }) => {
  const { data: group } = groupStore.group;
  const { data: subscriptions } = userStore.subscriptions;
  const { data: user } = userStore.information;

  return {
    user,
    subscriptions,
    group,
  };
};

export default connect(state)(Component);
