import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import { restoreModules } from "../../../../../store/actions/module";
import { group } from "console";
import { Group } from "../../../../../types";
import { Center } from "../../../../../components/StyledComponents";
import { useEffect } from "react";
import { useModules } from "../../../../../providers/ModulesProvider";

interface ComponentProps {
  group: Group
  modulesRestorationStates: {
    loading: boolean
    success: boolean
    error: string | boolean
  }
}

const Component = ({ group, modulesRestorationStates }: ComponentProps) => {
  const { modules, selectedModule, actions, setSelectedModule } = useModules();
  const dispatch = useDispatch()

  useEffect(() => {
    if (modulesRestorationStates.success) {
      dispatch(hideModal())
    }

  }, [modulesRestorationStates])

  return (
    <Modal name="modal-undo-modules-changes" width="650" showClose={false}>
      <Title>Cuidado, esta acción es irreversible</Title>

      <Text>
        ¡Cuidado! Restaurarás tu taller a la última versión publicada. <br />
        Todos los cambios que realizaste en los modulos no publicados se perderán. <br />
        Esta acción es irreversible.
      </Text>

      <Center horizontal="right" vertical="center" gap={8}>
        <Button
          options={{
            type: "outline",
            skin: "purple",
            size: "lg",
          }}
          disabled={modulesRestorationStates.loading}
          loading={modulesRestorationStates.loading}
          onClick={() => {
            if (!!group) {
              actions.restore.exec({ group: group?.id })
            }
          }}
        >
          Restaurar
        </Button>

        <Button
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
          }}
          disabled={modulesRestorationStates.loading}
          onClick={() =>
            dispatch(hideModal())
          }
        >
          Canacelar
        </Button>
      </Center>
    </Modal>
  );
};

const state = ({ groupStore, modulesStore }) => {
  const { data: group } =
    groupStore.group;
  const { states: modulesRestorationStates } = modulesStore.restoreModules

  return { group, modulesRestorationStates };
};

export default connect(state)(Component);
