import styled from "styled-components";


export const NoSubscribedMessage = styled("div")`
  margin: 50px auto 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 420px
`;

export const NoSubscribedMessageTitle = styled("h3")`
  text-align: center;
`;

export const GoToAccount = styled("p")`
  margin: 24px 0px 20px;
  text-align: center;
  text-indent: 5px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: 36px 0px 0px;
  }
`;