import styled from "styled-components";

export const CreateBy = styled.div`
  margin-bottom: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Name = styled("div") <{ withError }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > input {
    flex-shrink: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.colors.Primary};
    width: 160px;
    margin: 0px 3px;
    padding: 5px;
    box-sizing: border-box;
    background: transparent;
    border: 1px solid
      ${({ withError }) => (withError ? "#FF0000" : "transparent")};
    border-radius: 8px;
    outline: none;

    &:focus,
    &:focus-visible {
      border: 1px solid ${(props) => props.theme.colors.Primary};
    }
  }
`;

export const ChangeName = styled("label")`
  padding: 0px;
  border: none;
  background: none;
  cursor: pointer;
  height: 31px;
  line-height: 31px;
  display: block;

  & > img {
  }
`;
