import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import { CopyToClipboad } from "../../../Coupon/Preview/components/styles";

interface ComponentProps {
  session;
  sessionStates;
}

const Component = ({ session, sessionStates }: ComponentProps) => {
  const dispatch = useDispatch();

  return (
    <Modal name="modal-new-session-created" width="650" onClose={() => { }}>
      <Title>¡Creaste un nuevo encuentro!</Title>

      <Text>
        Podrás visualizar tu nuevo encuentro en el panel de “Encuentros” 🙌. Recordá
        que una vez pasada la fecha del encuentro, deberás subir la grabación del encuentro para que tus suscriptores puedan verlo cuando deseen.
      </Text>

      <AlignEnd>
        <CopyToClipboad
          copy={`${process.env.REACT_APP_SITE_BASE_URL}/group/${session?.group?.id}/s/${session?.id}`}
          showChildren
          iconColor="white"
          style={{
            width: "auto",
          }}
          successStyle={{
            position: "absolute",
            left: "100%",
            top: "50%",
            transform: "translate(0%, -50%)",
          }}
        >
          <span style={{ color: "#ffff", fontWeight: "600" }}>
            Compartir link
          </span>
        </CopyToClipboad>
      </AlignEnd>
    </Modal>
  );
};

const state = ({ sessionStore }) => {
  const { data: session, states: sessionStates } = sessionStore.one;

  return {
    session,
    sessionStates,
  };
};

export default connect(state)(Component);
