import { Center } from "../../../../../../components/StyledComponents";
import Button from "../../../../../../components/Button";
import File from "../../../../../../components/FormikForm/File";
import Input from "../../../../../../components/FormikForm/Input";
import TextArea from "../../../../../../components/FormikForm/RichTextEditor";
import { Text, Title } from "../styles";
import { Tabs, Tab } from "./styles";
import { useDispatch } from "react-redux";
import { deleteFile } from "../../../../../../store/actions/module";
import { useEffect, useState } from "react";
import {
  getYouTubeUrl,
  isYoutubeUrl,
  isVimeoUrl,
  getVimeoVideoIdFromUrl
} from "../../../../../../helpers/you-tube";
import VimeoVideo from "../../../../Posts/components/VimeoVideo";

interface ComponentProps {
  setFieldValue;
  setFieldError;
  setFieldTouched;
  values;
  touched;
  handleBlur;
  handleChange;
  errors;
}

const Component = ({
  setFieldValue,
  setFieldError,
  setFieldTouched,
  values,
  touched,
  handleBlur,
  handleChange,
  errors,
}: ComponentProps) => {
  const [activeTab, setActiveTab] = useState<"file" | "url">("file");
  const [embedURL, setEmbedURL] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!values?.content_data["attached_url"] && isYoutubeUrl(values?.content_data["attached_url"])) {
      setEmbedURL(getYouTubeUrl(values?.content_data["attached_url"]));
    } else if (!!values?.content_data["attached_url"] && isVimeoUrl(values?.content_data["attached_url"])) {
      setEmbedURL(values?.content_data["attached_url"])
    } else {
      setEmbedURL(null);
    }

    if (!!values?.content_data["attached_url"]) {
      setActiveTab('url')
    }

    if (!!values?.content_data["attached_files"]) {
      setActiveTab('file')
    }
  }, [values]);

  return (
    <>
      <Title>Artículo</Title>
      <div style={{ height: 16 }} />
      <Text>
        Publica un archivo (video/audio/pdf/imagen) sumado a un texto o mensaje
        con el que lo quieras acompañar.
      </Text>
      <div style={{ height: 24 }} />

      <Center
        horizontal="center"
        vertical="top"
        column
        style={{ maxWidth: 483 }}
        gap={16}
      >

        <label htmlFor="" style={{
          fontWeight: 500,
          fontSize: 16,
          lineHeight: 1,
          marginBottom: 8,
        }}>Embeber URL</label>

        <Tabs>
          <Tab
            disabled={values.content_data["attached_url"]}
            options={{
              type: activeTab === 'file' ? "filled" : "outline",
              size: "sm",
              skin: "purple",
            }}
            onClick={() => setActiveTab("file")}
          >
            Adjuntar archivo
          </Tab>

          <Tab
            disabled={values.content_data["attached_files"]}
            options={{
              type: activeTab === 'url' ? "filled" : "outline",
              size: "sm",
              skin: "purple",
            }}
            onClick={() => setActiveTab("url")}
          >
            Desde una URL
          </Tab>
        </Tabs>

        {activeTab === "file" && (
          <File
            name="content_data.attached_files"
            value={values.content_data["attached_files"]}
            touched={touched["content_data.attached_files"]}
            error={errors["content_data.attached_files"]}
            rules={{
              maxSize: 1073741824,
              maxFiles: 1,
              typeFiles: ["image", "audio", "video", "other"],
            }}
            onRemoveItem={(event, file) => {
              if (file?.id) {
                dispatch(deleteFile({ idFile: file?.id }));
              }
            }}
            options={{
              explainText:
                "Puedes subir imágenes, audios, PDF y videos en tu artículo de hasta 1GB.",
              marginBottom: 40,
              showLoadFileCta: false,
            }}
          />
        )}

        {activeTab === "url" && (
          <>
            <Input
              name="content_data.attached_url"
              error={errors["content_data.attached_url"]}
              touched={touched["content_data.attached_url"]}
              value={values.content_data["attached_url"]}
              onChange={(event) => {
                handleChange(event);
                if (isYoutubeUrl(event.target.value)) {
                    setEmbedURL(getYouTubeUrl(event.target.value));
                } else if (isVimeoUrl(event.target.value)){
                  setEmbedURL(getVimeoVideoIdFromUrl(event.target.value));
                } else {
                  setEmbedURL(null);
                }
              }}
              onBlur={handleBlur}
              placeholder="http://youtube.com/XiEJNFpe3"
              options={{
                explainText:
                  "Embebe directamente videos de Youtube, Vimeo, Instagram o simplemente comparte cualquier link que quieras que tus suscriptores abran!.",
                marginBottom: !!embedURL ? 16 : 40,
              }}
            />

            {!!embedURL && isYoutubeUrl(embedURL) && (
              <>
                <iframe
                  src={embedURL}
                  id="myIframe"
                  width="320"
                  height="200"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
                <div style={{ height: "40px" }} />
              </>
            )}
            
            {isVimeoUrl(embedURL) && (
              <VimeoVideo videoId={getVimeoVideoIdFromUrl(embedURL)} showLink={false} isPreview={false} />
            )}
          </>
        )}


        <TextArea
          name="content_data.description"
          placeholder="Descripción..."
          value={values.content_data["description"]}
          touched={touched["content_data.description"]}
          error={errors["content_data.description"]}
          disabled={false}
          onBlur={handleBlur}
          max={3000}
          onChange={({ isCountValid }) => {
            if (!isCountValid) {
              setFieldError(
                "content_data.description",
                "Superaste la cantidad de caracteres máximos permitidos"
              );
            } else {
              setFieldTouched("content_data.description", false, false);
            }
          }}
          options={{
            label: 'Descripción',
            marginBottom: 0,
          }}
        />
      </Center>
    </>
  );
};

export default Component;
