import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "./styles";
import dateHandlder from "../../../../../helpers/dates-hanlder";
import { isOwnGroup } from "../../../../../helpers/isOwnGroup";

const Component = ({ group, scrollToRef, user, ...rest }) => {
  const [suscriptionEnded, setSuscriptionEnded] = useState<boolean>();

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (!!group?.registration_closing_date) {
      setSuscriptionEnded(
        dateHandlder.isExpired(group?.registration_closing_date)
      );
    }
  }, [group]);
  return (
    <Button
      onClick={() => scrollToSection(scrollToRef)}
      disabled={
        suscriptionEnded ||
        isOwnGroup({ group, expertId: user?.expert?.id })
      }
      options={{
        size: "lg",
        skin: "purple",
        type: "filled",
      }}
      {...rest}
    >
      {group?.group_type === "membership" && (
        <>{suscriptionEnded ? "Suscripción finalizada" : `Suscribirme`}</>
      )}

      {group?.group_type !== "membership" && (
        <>{suscriptionEnded ? "Inscripción finalizada" : `Inscribirme`}</>
      )}
    </Button>
  )
}

const state = ({ groupStore, userStore }) => {
  const { isLoggedIn } = userStore;
  const { data: group } = groupStore.group;
  const { data: subscriptions } = userStore.subscriptions;
  const { data: user } = userStore.information;

  return {
    isLoggedIn,
    user,
    group,
    subscriptions,
  };
};

export default connect(state)(Component);