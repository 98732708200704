import {
  Card,
  Title,
  Text,
  Link
} from './styles'
import Image from '../../../../../../../../../components/Image'
import mercadoPagoImg from '../../../../../assets/mercado-pago.png'
import { useTheme } from 'styled-components';
import Icon from '../../../../../../../../../components/Icon';
import arrowLinkIcon from '../../../../../../../../../assets/icons/arrow_forward_up_right-semibold.svg'
import { useMemo } from 'react';
import { connect } from 'react-redux';
import { Gateway } from '../../../../../../../../../types/gateway';
import paymentIcon from '../../../../../../../../../assets/icons/payment.svg';
import securePaymentImage from '../../../../../../../../../assets/secure-payment.png'

interface ComponentProps {
  userData;
}

const Component = ({ userData }: ComponentProps) => {
  const theme = useTheme() as { logo: { small: string, big: string } };

  const primaryGateway = useMemo((): Gateway => userData?.expert?.linked_gateways?.find(lg => lg.priority === "primary" && lg.currency === "ARS"), [userData])

  if (!!primaryGateway) {
    return <></>
  }

  return (
    <Card>
      <Image src={securePaymentImage} width="65px" />
      <div style={{ height: 18 }} />
      <Title>Todavía nos has vinculado<br />tu cuenta de Mercado pago</Title>
      <div style={{ height: 12 }} />
      <Text>Para recibir pagos en pesos argentinos, debes<br />conectar tu cuenta de Mercado Pago con Klouser.</Text>
      <div style={{ height: 18 }} />
      <Link href='/profile/gateways' options={{ size: 'lg', skin: 'purple', type: 'filled', native: false }}>
        <Icon icon={paymentIcon} size="24px" />
        Agregar nuevo gateway
      </Link>
    </Card>
  )
}

const state = ({ userStore }) => {
  const { data: userData } = userStore.information;

  return {
    userData,
  };
};

export default connect(state)(Component);