import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Transition } from "react-transition-group";
import { AnimationOnTop } from "../Animation";
import { ErrorIcon, SuccessIcon, Snackbar } from "./styles";

interface SnackbarProps {
  visible: boolean;
  options: {
    type: "success" | "error" | "info";
    position?: "left" | "right" | "center";
    time?: any;
    animation?: string;
  };
  children?: any;
  onHide?: () => void;
}

const Component = ({ visible, options, children, onHide }: SnackbarProps) => {
  const [isVisible, setIsVisible] = useState(visible);
  const snackbarDOM = document.getElementById("snackbar") as HTMLElement;
  const {
    type,
    time = 5000,
    animation = "bounceInUp",
    position = "right",
  } = options;

  const timer = () => {
    setTimeout(() => {
      setIsVisible(false);

      !!onHide && setTimeout(() => onHide(), 1000);
    }, time);
  };

  useEffect(() => {
    if (visible) {
      setIsVisible(visible);
      timer();
    }
  }, [visible]);

  if(type === "info")
    return <></>

  return ReactDOM.createPortal(
    <Transition in={isVisible} timeout={300} mountOnEnter>
      {(state) => {
        return (
          <AnimationOnTop name={animation} className={`animation-${state}`}>
            <Snackbar type={type} position={position}>
              {type === "error" ? <ErrorIcon /> : <SuccessIcon />}
              {children}
            </Snackbar>
          </AnimationOnTop>
        );
      }}
    </Transition>,
    snackbarDOM
  );
};

export default Component;
