import TextArea from "../../../../../../components/FormikForm/TextArea";
import { Text, Title } from "../styles";

interface ComponentProps {
  setFieldValue;
  values;
  touched;
  handleBlur;
  handleChange;
  errors;
}

const Component = ({
  setFieldValue,
  values,
  touched,
  handleBlur,
  handleChange,
  errors,
}: ComponentProps) => {
  return (
    <>
      <Title>Nota/Bitacora</Title>
      <div style={{ height: 16 }} />
      <Text>
        En este módulo se le pedirá al suscriptor un espacio para que tome
        notas. Indícale una consigna a modo de guía para que la tarea sea
        enfocada a un objetivo.
      </Text>
      <div style={{ height: 24 }} />

      <TextArea
        name="content_data.notes"
        placeholder="Notas..."
        value={values.content_data["notes"]}
        touched={touched["content_data.notes"]}
        error={errors["content_data.notes"]}
        disabled={false}
        onBlur={handleBlur}
        onChange={handleChange}
        maxLength={1000}
        options={{
          marginBottom: 0,
        }}
      />
    </>
  );
};

export default Component;
