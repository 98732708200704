import { DiscountDto } from "../../../types";

export const SELECTED_DISCOUNT = "SELECTED_DISCOUNT";
export const SELECTED_DISCOUNT_SUCCESS = "SELECTED_DISCOUNT_SUCCESS";
export const SELECTED_DISCOUNT_ERROR = "SELECTED_DISCOUNT_ERROR";

export interface SelectedDiscount {
  type: typeof SELECTED_DISCOUNT;
  payload: DiscountDto;
}

export interface SelectedDiscountSuccess {
  type: typeof SELECTED_DISCOUNT_SUCCESS;
  payload: any;
}

export interface SelectedDiscountError {
  type: typeof SELECTED_DISCOUNT_ERROR;
  payload: string | boolean;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | SelectedDiscount
  | SelectedDiscountSuccess
  | SelectedDiscountError;
