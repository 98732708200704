import Grid from "../Grid";
import SocialNetworks from "./components/SocialNetworks";
import Email from "./components/Email";
import SubTitle from "./../../components/SubTitle";
import { Footer, Img, HorizontalSeparator } from "./styles";
import { connect } from "react-redux";
import logo from "./../../assets/klouser-footer.svg";
import { useWindowSize } from "../../hooks/useWindowSize";

const Component = ({ forwardedRef, currentPage, ...rest }: any) => {
  const { isMobile } = useWindowSize();

  const pages = ["EXPERT_SIGN_IN", "EXPERT_SIGN_UP"];
  const showShortFooter = pages.includes(currentPage.name);

  return (
    <Footer ref={forwardedRef} {...rest}>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col lg={10} offset={{ lg: 1 }}>
            <Grid.Row>
              {!showShortFooter && !isMobile && (
                <Grid.Col sm={4} md={4} lg={3}>
                  <Img
                    src={logo}
                    width="215px"
                    height="42px"
                    className="logo-desktop"
                    alt="Klouser"
                    lazy
                  />
                </Grid.Col>
              )}

              {!showShortFooter ? (
                <Grid.Col sm={3} md={3} lg={2}>
                  <SubTitle marginBottom={4}>info</SubTitle>
                  <Email>info@klouser.com</Email>
                </Grid.Col>
              ) : (
                <Grid.Col sm={3} md={3} lg={2}>
                  <SubTitle marginBottom={4}>info</SubTitle>
                  <Email>info@klouser.com</Email>
                </Grid.Col>
              )}

              <Grid.Col sm={2} md={4} lg={3}>
                <SubTitle marginBottom={8}>siguenos</SubTitle>
                <SocialNetworks />
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Footer>
  );
};

const state = ({ pageStore }) => {
  return {
    currentPage: pageStore,
  };
};
export default connect(state)(Component);
