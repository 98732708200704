import styled from "styled-components";

export const Email = styled.a`
  display: inline-block;
  font-weight: 700;
  font-size: 24px;
  line-height: 48px;
  margin: 0px 0px 20px;
  color: ${(props) => props.theme.footer.socialNetworks.color};

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 16px;
  }
`;
