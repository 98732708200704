import React, { ReactElement, useState } from "react";
import { ButtonsNext, ButtonsBack, ButtonContainer } from "../Slides/styles";
import { CarouselProvider, Slider } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Step, Big, Small, CarouselContent } from "./styles";
import Slides from "../Slides";

interface ComponentProps {
  id: string;
  slides?: ReactElement;
}

export const Component = ({ id, slides }: ComponentProps) => {
  const [index, setIndex] = useState(0);
  const totalSteps = 5;

  const handleBackButtonPressed = () => {
    if (index === 0) {
      return;
    } else {
      setIndex(index - 1);
    }
  };

  const handleNextButtonPressed = () => {
    if (index === totalSteps - 1) {
      return;
    } else {
      setIndex(index + 1);
    }
  };

  return (
    <CarouselProvider
      naturalSlideWidth={650}
      naturalSlideHeight={550}
      totalSlides={totalSteps}
      isIntrinsicHeight={true}
    >
      <CarouselContent>
        <ButtonsBack
          onClick={handleBackButtonPressed}
          disabled={!(index + 1 > 1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="19"
            viewBox="0 0 12 19"
            fill="none"
          >
            <path
              opacity="0.5"
              d="M9.42 0.219999L11.28 2.06L3.66 9.6L11.28 17.14L9.42 18.98L0.0199999 9.6L9.42 0.219999Z"
              fill="white"
            />
          </svg>
        </ButtonsBack>

        <Step>
          <Big>{index + 1}</Big>
          <Small>/{totalSteps}</Small>
        </Step>

        <Slider>
          {slides ? (
            React.createElement(slides?.type, {
              ...{
                ...slides?.props,
                id,
              },
            })
          ) : (
            <Slides id={id} />
          )}
        </Slider>

        <ButtonContainer>
          <ButtonsNext
            onClick={handleNextButtonPressed}
            disabled={!(index + 1 !== totalSteps)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="19"
              viewBox="0 0 12 19"
              fill="none"
            >
              <path
                d="M2.58 18.78L0.72 16.94L8.34 9.4L0.72 1.86L2.58 0.0199981L11.98 9.4L2.58 18.78Z"
                fill="#6C5CE7"
              />
            </svg>
          </ButtonsNext>
        </ButtonContainer>
      </CarouselContent>
    </CarouselProvider>
  );
};

export default Component;
