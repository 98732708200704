import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import Snackbar from "../../../../../components/Snackbar";
import {
  deleteSurvey,
  resetDeleteSurvey,
  resetGetAllSurveys,
  resetGetSurvey,
  resetNewSurvey,
  resetGetAllSurveyAnswers,
} from "../../../../../store/actions/survey";
import { Group, SurveyInDto } from "../../../../../types";
import { useEffect } from "react";

const groupTypes = {
  membership: "membresía",
  experience: "taller",
};

interface ComponentProps {
  survey: SurveyInDto;
  group: Group;
  deleteSurveyStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({ survey, group, deleteSurveyStates }: ComponentProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (deleteSurveyStates.success) {
      dispatch(hideModal());
    }
  }, [deleteSurveyStates]);

  return (
    <Modal name="modal-delete-survey" width="650">
      <Title>¿Deseas eliminar esta encuesta?</Title>

      <Text>
        Una vez eliminado no podrás volver a acceder al mismo. Las respuestas
        serán eliminadas y tendrás que realizar una nueva encuesta en caso de
        querer recolectar información acerca de tu{" "}
        {groupTypes[group?.group_type]} nuevamente.
      </Text>

      <AlignEnd>
        <Button
          options={{
            type: "outline",
            skin: "purple",
            size: "lg",
          }}
          disabled={deleteSurveyStates.loading}
          onClick={() => dispatch(hideModal())}
        >
          Cancelar
        </Button>

        <Button
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
          }}
          disabled={deleteSurveyStates.loading}
          loading={deleteSurveyStates.loading}
          onClick={() => dispatch(deleteSurvey({ idSurvey: survey?.id }))}
        >
          Eliminar
        </Button>
      </AlignEnd>
    </Modal>
  );
};

const state = ({ surveyStore, surveyGroup, groupStore }) => {
  const { data: group } = groupStore.group;
  const { data: survey } = surveyStore.selected;
  const { data: newSurvey } = surveyStore.newOne;
  const { states: deleteSurveyStates } = surveyStore.deleteOne;
  return {
    newSurvey,
    survey,
    group,
    deleteSurveyStates,
  };
};

export default connect(state)(Component);
