import { NewModuleInDto, NewModuleOutDto } from "../../../types/module";

export const NEW_MODULE = "NEW_MODULE";
export const NEW_MODULE_SUCCESS = "NEW_MODULE_SUCCESS";
export const NEW_MODULE_ERROR = "NEW_MODULE_ERROR";
export const RESET_NEW_MODULE = "RESET_NEW_MODULE";

export interface NewModule {
  type: typeof NEW_MODULE;
  payload: NewModuleOutDto;
}

export interface NewModuleSuccess {
  type: typeof NEW_MODULE_SUCCESS;
  payload: NewModuleInDto;
}

export interface NewModuleError {
  type: typeof NEW_MODULE_ERROR;
  payload: string | boolean;
}

export interface ResetNewModule {
  type: typeof RESET_NEW_MODULE;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | NewModule
  | NewModuleSuccess
  | NewModuleError
  | ResetNewModule;
