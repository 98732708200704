import { Redirect } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getGroupById } from "../../../store/actions/group";
import Grid from "../../../components/Grid";
import Loader from "../../../components/Loader";
import { useLayout } from "../../../providers/LayoutProvider";
import WelcomeContentSection from './components/WelcomeContentSection'
import Dropdown from '../../../components/DropdownBis'
import { Link as RrdLink } from "react-router-dom";
import { showModal } from "../../../store/actions/modal";
import ModalShareLink from "../components/ModalShareLink";
import LibraryLayout from "./components/LibraryLayout";
import LogoLoader from "../../../components/LogoLoader";


interface ComponentProps {
  match;
  group;
  groupStates;
  children
}

const Component = ({
  match,
  group,
  groupStates,
  children
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const { loading, success, error } = groupStates;
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !!match.params &&
      (!group || (!!group && group?.id !== Number(match?.params?.idGroup)))
    ) {
      dispatch(getGroupById({ group_id: match.params.idGroup }));
    }
  }, [match]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: group?.group_name,
        description: group?.brief,
        back: `/dashboard/${group?.group_type}/${group?.id}`,
      },
      dashboard: {
        tabs: {
          visible: !!group,
        },
      },
      header: {
        visible: !!group,
        actions: [
          <Dropdown>
            <RrdLink
              to={`/group/${group?.slug}/activity-summary`}
              key={'go-to-preview-btn'}
              target="_blank"
            >
              Vista previa
            </RrdLink>

            <button
              disabled={group?.status === 'draft'}
              onClick={() => {
                const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.slug}`;
                navigator.clipboard.writeText(link);
                dispatch(showModal("modal-share-group-link"));
              }}
              key={'share-experience-btn'}
            >
              Compartir taller
            </button>
          </Dropdown>
        ]
      },
    });
  }, [group]);

  if (!!loading || (!group && !error)) {
    return <LogoLoader />;
  }

  if (error === "Not Found" || error === "Forbidden") {
    return <Redirect to="/404" />;
  }

  return (
    <>
      {children}

      <div style={{ height: 36 }} />

      <Grid.Container>
        <Grid.Row>
          <Grid.Col sm={12}>
            <WelcomeContentSection />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col sm={12}>
            <LibraryLayout />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <ModalShareLink />
    </>
  );
};

const state = ({ groupStore }) => {
  const { data: group, states: groupStates } = groupStore.group;

  return {
    group,
    groupStates,
  };
};

export default connect(state)(Component);