import { SessionInDto, SessionOutDto } from "../../../types/session.dto";

export const UPDATE_ONE_SESSION = "UPDATE_ONE_SESSION";
export const UPDATE_ONE_SESSION_SUCCESS = "UPDATE_ONE_SESSION_SUCCESS";
export const UPDATE_ONE_SESSION_ERROR = "UPDATE_ONE_SESSION_ERROR";
export const RESET_UPDATE_ONE_SESSION = "RESET_UPDATE_ONE_SESSION";

export interface UpdateOneSession {
  type: typeof UPDATE_ONE_SESSION;
  payload: SessionOutDto;
}

export interface UpdateOneSessionSuccess {
  type: typeof UPDATE_ONE_SESSION_SUCCESS;
  payload: SessionInDto;
}

export interface UpdateOneSessionError {
  type: typeof UPDATE_ONE_SESSION_ERROR;
  payload: string | boolean;
}

export interface ResetUpdateOneSession {
  type: typeof RESET_UPDATE_ONE_SESSION;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | UpdateOneSession
  | UpdateOneSessionSuccess
  | UpdateOneSessionError
  | ResetUpdateOneSession;
