import { CategoryDto } from "../../../types/category";

export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_ERROR = "UPDATE_CATEGORY_ERROR";
export const RESET_UPDATE_CATEGORY = "RESET_UPDATE_CATEGORY";
export interface UpdateCategory {
  type: typeof UPDATE_CATEGORY;
  payload: CategoryDto;
}

export interface UpdateCategorySuccess {
  type: typeof UPDATE_CATEGORY_SUCCESS;
  payload: CategoryDto | null;
}

export interface UpdateCategoryError {
  type: typeof UPDATE_CATEGORY_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateCategory {
  type: typeof RESET_UPDATE_CATEGORY;
  payload: null;
}

export interface State {
  data: CategoryDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | UpdateCategory
  | UpdateCategorySuccess
  | UpdateCategoryError
  | ResetUpdateCategory;
