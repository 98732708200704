import styled from "styled-components";

export const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

export const ErrorMessage = styled.div`
  padding: 100px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  color: white;
  background-color: #f23d4e;
`;

export const Image = styled.div`
  position: relative;
  border-radius: 100%;
  width: 150px;
  height: 150px;
  padding: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 80px;
    height: auto;
  }

  #errorIcon {
    background-color: #f23d4e;
    padding: 5px;
    border-radius: 100%;
    position: absolute;
    max-width: 25px;
    max-height: 25px;
    bottom: 30px;
    right: 30px;
    color: white;
  }
`;
