import { connect, useDispatch } from "react-redux";
import { showModal } from "./../../../../../store/actions/modal";
import { Data, Button, UserEmail, Section } from "../../styles";
import ModalChangeEmail from "./ModalChangeEmail";

const Component = ({ userData }) => {
  const dispatch = useDispatch();
  return (
    <Section>
      <Data>Correo electrónico asociado</Data>
      <UserEmail>{userData?.email}</UserEmail>
      <Button
        type="button"
        onClick={() => dispatch(showModal("change-email"))}
        loading={false}
        options={{
          skin: "purple",
          size: "lg",
          type: "link",
        }}
      >
        Modificar
      </Button>
      <ModalChangeEmail />
    </Section>
  );
};

const state = ({ userStore }) => {
  const { information } = userStore;

  const { data: userData, states: userStates } = information;

  return {
    userData,
    userStates,
  };
};

export default connect(state)(Component);
