import styled from "styled-components";
import Title from "../../../components/Title";
import SubTitle from "../../../components/SubTitle";
import LinkUi from "../../../components/Link";

export const FormTitle = styled(Title)`
  text-align: center;
  color: ${(props) => props.theme.colors.Black};
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 32px;
    line-height: 44px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    font-size: 36px;
    line-height: 44px;
  }
`;

export const FormSubtitle = styled(SubTitle)`
  text-align: center;
`;
export const P1 = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: ${(props) => props.theme.colors.Black};
  font-weight: 600;
  text-align: center;
  margin-bottom: 8px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-weight: 400;
    margin-bottom: 25px;
  }
`;
export const P2 = styled.div`
  font-size: 12px;
  line-height: 13px;
  color: ${(props) => props.theme.colors.BlueLight};
  text-align: center;
  margin-bottom: 24px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 14px;
    line-height: 19px;
  }
`;
export const Link = styled(LinkUi)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    max-width: 320px;
  }
`;
export const P3 = styled.div`
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  font-weight: 600;
  margin: 32px 0px 8px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: 36px 0px 16px;
  }
`;

export const P4 = styled.div`
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  font-weight: 400;
  margin-bottom: 24px;
`;

export const TelegramDownload = styled(LinkUi)`
  margin-top: 24px;
`;
