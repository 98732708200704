import { put, call, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  SAVE_PREFERENCE,
  SAVE_PREFERENCE_SUCCESS,
  SAVE_PREFERENCE_ERROR,
  GET_PREFERENCE_SUCCESS,
  GET_PREFERENCE_ERROR,
  GET_PREFERENCE,
} from "../types/user/preferences";

import { GET_USER_INFORMATION_SUCCESS } from "../types/user/get-user-information";

const getUserInformation = ({ userStore }) => userStore.information.data;

function* savePreference({ payload }) {
  const userInformation = yield select(getUserInformation);
  const localEmail = localStorage.getItem(
    "KLOUSER_SUBSCRIBER_EMAIL"
  );

  try {
    const result = yield call(services.preferences.savePreference, {
      payload: {
        ...payload,
        ...(!!userInformation && { userId: userInformation?.id }),
        userEmail: !!userInformation ? userInformation?.email : localEmail
      },
    });
    yield put({ type: SAVE_PREFERENCE_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: SAVE_PREFERENCE_ERROR, payload: error });
  }
}

function* getUserPreferences() {
  try {
    const userInformation = yield select(getUserInformation);
    const localEmail = localStorage.getItem(
      "KLOUSER_SUBSCRIBER_EMAIL"
    ) || '';

    if (!!userInformation || !!localEmail) {
      const { response, error } = yield call(services.preferences.getUserPreferences, {
        ...(!!userInformation && { userId: userInformation?.id }),
        userEmail: !!userInformation ? userInformation?.email : localEmail
      });

      if (error) {
        yield put({ type: GET_PREFERENCE_ERROR, payload: error });
      } else {

        yield put({ type: GET_PREFERENCE_SUCCESS, payload: response });
      }
    } else {
      yield put({ type: GET_PREFERENCE_ERROR, payload: 'Preferencias: No se indico id de usuario o e-mail.' });
    }
  } catch (error) {
    yield put({ type: GET_PREFERENCE_ERROR, payload: 'Preferencias: Error al obtener las preferencias del usuario.' });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(SAVE_PREFERENCE, savePreference);
  yield takeLatest<any>(GET_PREFERENCE, getUserPreferences);
  yield takeLatest<any>(GET_USER_INFORMATION_SUCCESS, getUserPreferences);
  yield takeLatest<any>(SAVE_PREFERENCE_SUCCESS, getUserPreferences);
}
