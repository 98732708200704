import CoverPic from '../CoverPic';

import {
  Title, Column, Text, Line, Discount, Total, MemberUntil
  , Plan, Currency, FooterMessage, Price, PaymentDetails
} from "./styles";
import dateHandlder, { dateSpliter } from "../../../../../helpers/dates-hanlder";
import { connect } from "react-redux";
import { formatPrice } from '../../../../../helpers/price-formatter';
import { useEffect, useMemo, useState } from 'react';
import { getUrlParamByName } from '../../../../../helpers/get-url-param';
import { DiscountDto, PlanDto } from '../../../../../types';
import constants from '../../../../../constants';
import { CSSProperties } from 'styled-components';
import SecureTechnology from '../../../components/SecureTechnology';
import SecureProcess from '../SecureProcess';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import Faqs from '../../../../Group/components/MPVerifyYourPaymentInformationSection/components/Faq';

const groupTypes = {
  community: "comunidad",
  free_experience: "taller",
  experience: "taller",
  membership: "membresía",
};


const periodsUnitsTranslations = {
  "month": { singular: "mes", plural: "meses" },
  "quarter": { singular: "trimestre", plural: "trimestres" },
  "half-year": { singular: "semestre", plural: "semestres" },
  "year": { singular: "año", plural: "años" },
}

interface ComponentProps {
  style: CSSProperties
  group,
  plan,
  subscriber,
  subscription
}

const Component = ({ style, group, plan, subscriber, subscription }: ComponentProps) => {
  const { isMobile } = useWindowSize()
  const [discount, setDiscount] = useState<DiscountDto>();
  const [secondaryCurrency, setSecondaryCurrency] = useState<string | null>('');
  const [suscriptionEnded, setSuscriptionEnded] = useState<boolean>();

  const paymentDiscountTranslated = (plan?: PlanDto) => {
    const discount = plan?.["discounts"] ? plan?.discounts[0] : null;

    if (discount) {
      const periodSelected = discount["period"] / (plan?.["period"] || 1); //Cantidad de cobros
      return `${periodSelected > 1 ? `Los primeros ${periodSelected} pagos` : 'El primer pago'} con el descuento referenciado arriba.`
    } else {
      return ""
    }
  }

  const discountTermText = useMemo(() => {
    const discount = plan?.["discounts"][0];
    if (discount) {
      const periodSelected = discount["period"] / (plan?.["period"] || 1); //Cantidad de cobros
      return `${periodSelected > 1 ? `para los primeros ${periodSelected} cobros` : 'para el primer cobro'}`
    } else {
      return ""
    }
  }, [plan])

  const paymentPeriodTranslated = (plan?: PlanDto) => {
    if (plan?.["period_unit"] && plan?.["period"]) {
      const periodSelected = plan?.["period"]; //Cantidad de cobros
      const selectedPeriodUnit = periodsUnitsTranslations[plan?.["period_unit"]] //Meses / Trimestres / Años / Etc...
      return `Pagas ${plan?.["period"] <= 1 ? "por" : `cada ${plan?.["period"]}`} ${selectedPeriodUnit[periodSelected > 1 ? "plural" : "singular"]}.`
    } else {
      return ""
    }
  }

  const explainText = (plan?: PlanDto,) => {
    const paymentCicles = plan?.['billing_cycles'] ? `${plan?.['billing_cycles']} cobros en total.` : "";
    const paymentPeriod = plan?.["type"] === "charge" ? "Pagá 1 sola vez." : paymentPeriodTranslated(plan);
    const paymentDiscount = paymentDiscountTranslated(plan);

    return `${paymentCicles} ${paymentPeriod} ${paymentDiscount}`;
  }

  const planPeriod = (plan?: PlanDto) => {
    if (plan) {
      if (plan.type === 'subscription') {
        const isPlural = plan?.period && plan?.period > 1;
        const period = plan?.period_unit && periodsUnitsTranslations[plan?.period_unit]
        return `Cada ${plan?.period} ${period[isPlural ? "plural" : "singular"]}`
      } else {
        return "Pago único"
      }
    }
  }

  useEffect(() => {
    if (!!plan) {
      const planDiscount = plan?.discounts ? plan?.discounts[0] : null;
      const planSecondaryCurrency = !!plan?.secondary_currency_rate ? constants.currencies.filter(c => c.iso === plan?.showable_secondary_currency)[0]?.iso : null
      setDiscount(planDiscount)
      setSecondaryCurrency(planSecondaryCurrency)
    }

    if (!!group?.registration_closing_date) {
      setSuscriptionEnded(
        dateHandlder.isExpired(group?.registration_closing_date)
      );
    }
  }, [plan]);

  return (
    <>
      <div style={style}>
        <Title>Hola {subscriber?.name} 👋</Title>
        <div style={{ height: 20 }} />
        <Text>Para mantener tu tarifa actual en {group?.group_name}, por favor verifica tus datos de pago</Text>

        <div style={{ height: 35 }} />

        <SecureProcess
          group={group}
          subscription={subscription}
        />

        {!isMobile && (
          <>
            <div style={{ height: 12 }} />
            <Faqs showDropdown={false} />
          </>
        )}
      </div>
    </>
  )
}

export default Component