import styled from "styled-components";

export const Explanation = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 150px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

export const ImageWrapper = styled.div`
  padding: 20px 25px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.Gray10};
  margin: 0px 0px 40px;
  border-radius: 16px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 50px 53px;
    margin: 0px;
    border-radius: 0px 25px 25px 0px;
  }
`;

export const Denomination = styled.div`
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding-top: 15px;
  }
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    padding-top: 20px;
  }
`;

export const Guide = styled.p`
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.PurpleVeryLight};
  text-align: start;
  margin: 0px 0px 10px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    text-align: end;
    margin: 0px 0px 20px 0px;
  }
`;

export const Title = styled.h2`
  font-size: 36px;
  line-height: 46px;
  margin: 0px 0px 60px;
  font-weight: 700;
  text-align: start;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 30px;
    text-align: end;
    line-height: 40px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    margin: 0px 0px 60px;
    font-size: 50px;
    line-height: 72px;
  }
`;
