import styled from "styled-components";

const positions = {
  'bottom-centered': {
    top: 'calc(100% + 5px)',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  'bottom-left': {
    top: 'calc(100% + 5px)',
    left: '0'
  },
  'bottom-right': {
    top: 'calc(100% + 5px)',
    right: '0'
  },
  'top-centered': {
    bottom: 'calc(100% + 5px)',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  'top-left': {
    bottom: 'calc(100% + 5px)',
    left: '0'
  },
  'top-right': {
    bottom: 'calc(100% + 5px)',
    right: '0'
  },
  'left-centered': {
    right: 'calc(100% + 5px)',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  'left-top': {
    right: 'calc(100% + 5px)',
    top: '0'
  },
  'left-bottom': {
    right: 'calc(100% + 5px)',
    bottom: '0'
  },
  'right-centered': {
    left: 'calc(100% + 5px)',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  'right-top': {
    left: 'calc(100% + 5px)',
    top: '0'
  },
  'right-bottom': {
    left: 'calc(100% + 5px)',
    bottom: '0'
  }
};


export const List = styled('ul') <{ position: string, isOpen: boolean }>`
  ${({ isOpen }) => isOpen ? `
    z-index: 1;
    opacity: 1;
    pointer-events: all;
  ` : `
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  `}
  background-color: white;
  padding: 10px 0px;
  margin: 0px;
  position: absolute;
  z-index: 10000;
  
  /* Aplicar la posición del dropdown */
  ${({ position }) => positions[position]}

  /* Ajustes generales */
  min-width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.10);
  border-radius: 6px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    min-width: calc(100% + 10px);
  }

  li {
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;
