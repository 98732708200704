import { useEffect, useState } from "react";
import { Text } from "../../components/styles";
import GoBack from "../../../../components/GoBack";
import Card from "../components/Card";
import Link from "../../../../components/Link";
import { List, CardEmpty } from "./styles";
import Helmet from "react-helmet";
import Tabs from "../components/Tabs";
import Grid from "../../../../components/Grid";
import Snackbar from "../../../../components/Snackbar";
import Preview from "../Preview";
import { DiscountDto } from "../../../../types/discount";
import { connect, useDispatch } from "react-redux";
import { getAllDiscounts } from "../../../../store/actions/discount";
import NewCouponCreatedModal from "../components/NewCouponCreatedModal";
import { getUrlParamByName } from "../../../../helpers/get-url-param";
import { showModal } from "../../../../store/actions/modal";
import circlePlus from "../../../../assets/icons/circle-plus.svg";
import { useLayout } from "../../../../providers/LayoutProvider";

interface ComponentProps {
  match;
  allDiscounts: DiscountDto[];
  deleteDiscountStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  updateDiscountStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  allDiscountsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  newDiscount;
  newDiscountStates;
}

const Component = ({
  match,
  allDiscounts,
  allDiscountsStates,
  newDiscount,
  newDiscountStates,
  updateDiscountStates,
  deleteDiscountStates,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const [selectedCoupon, setSelectedCoupon] = useState<
    DiscountDto | undefined
  >();
  const [filteredCoupons, setFilteredCoupons] = useState<DiscountDto[]>();
  const [couponStatus, setCouponStatus] = useState("active");
  const dispatch = useDispatch();

  useEffect(() => {
    if (match.params) {
      setCouponStatus(match.params.status);
    }
  }, [match]);

  useEffect(() => {
    if (!!allDiscounts && !!couponStatus) {
      const discounts = allDiscounts.filter((discount) => {
        const states = {
          active: ["active", "archived"],
          expired: ["expired"],
        };
        return states[couponStatus].includes(discount.status);
      });
      setFilteredCoupons(discounts);
    }
  }, [couponStatus, allDiscounts]);

  useEffect(() => {
    if (!!newDiscount) {
      dispatch(showModal("modal-new-coupon-created"));
    }
  }, [newDiscount]);

  useEffect(() => {
    dispatch(getAllDiscounts({ show_in_page: false }));
  }, []);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: "Cupones y descuentos",
        description: "Cupones y descuentos",
      },
      dashboard: {
        tabs: {
          visible: true,
          activeTab: couponStatus,
          component: [<Tabs active={couponStatus} />],
        },
      },
      header: {
        visible: true,
        actions: [
          <Link
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            href="/dashboard/coupon/new"
          >
            Crear Cupón
          </Link>,
        ],
      },
    });
  }, [filteredCoupons, couponStatus]);

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <Text>
              {couponStatus === "expired"
                ? "Encuentra aquí tus cupones expirados."
                : "Encuentra aquí tus cupones activos, listos para copiar y compartir con tu audiencia. 🙌"}
            </Text>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col>
            <List>
              {filteredCoupons &&
                filteredCoupons?.length <= 0 &&
                !allDiscountsStates.loading && (
                  <CardEmpty>
                    <img src={circlePlus} alt="+" />

                    <div>
                      <h3>No tienes cupones creados</h3>
                      <p>
                        Crea un cupón de descuento y comparte este beneficio con
                        tu comunidad
                      </p>
                    </div>

                    <Link
                      options={{
                        type: "filled",
                        size: "lg",
                        skin: "lightGray",
                      }}
                      href="/dashboard/coupon/new"
                    >
                      Nuevo Cupón
                    </Link>
                  </CardEmpty>
                )}

              {filteredCoupons &&
                !allDiscountsStates.loading &&
                filteredCoupons
                  .filter((counpon) => counpon.show_in_page === false)
                  .map((coupon) => (
                    <Card
                      coupon={coupon}
                      onClick={setSelectedCoupon}
                      key={coupon.coupon_id}
                    />
                  ))}
            </List>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <Preview coupon={selectedCoupon} onClose={setSelectedCoupon} />

      <NewCouponCreatedModal />

      <Snackbar
        visible={deleteDiscountStates.success || !!deleteDiscountStates.error}
        options={{
          type: deleteDiscountStates.success ? "success" : "error",
          position: "center",
        }}
      >
        {deleteDiscountStates.success
          ? `Cupón ${!!selectedCoupon && selectedCoupon?.redemptions > 0
            ? "pausado"
            : "eliminado"
          }.`
          : `Error al ${!!selectedCoupon && selectedCoupon?.redemptions > 0
            ? "pausar"
            : "eliminar"
          } cupón.`}
      </Snackbar>

      <Snackbar
        visible={updateDiscountStates.success || !!updateDiscountStates.error}
        options={{
          type: updateDiscountStates.success ? "success" : "error",
          position: "center",
        }}
      >
        {updateDiscountStates.success
          ? "Cupón actualizado correctamente."
          : `Error al actualizar cupón.`}
      </Snackbar>
    </>
  );
};

const state = ({ discountStore }) => {
  const { data: allDiscounts, states: allDiscountsStates } =
    discountStore.allDiscounts;
  const { data: newDiscount, states: newDiscountStates } =
    discountStore.newDiscount;
  const { states: deleteDiscountStates } = discountStore.deleteDiscount;
  const { states: updateDiscountStates } = discountStore.updateDiscount;

  return {
    allDiscounts,
    allDiscountsStates,
    newDiscount,
    newDiscountStates,
    deleteDiscountStates,
    updateDiscountStates,
  };
};

export default connect(state)(Component);
