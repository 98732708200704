import { Label, StepNumber, Text } from "../styles";
import TipCta from "../TipCta";
import RichTextEditor from "../../../../components/Form/RichTextEditor";
import TextArea from "../../../../components/Form/TextArea";
import { useState } from "react";

interface ComponentProps {
  onFocus;
  onBlur?;
  onChange?;
  fieldNumber: number;
  register;
  errors;
  labelForwardRef;
  label: any;
  control;
  getValues?;
  setDescriptionError;
  setError;
}

const Component = ({
  onFocus,
  onBlur,
  onChange,
  fieldNumber,
  register,
  errors,
  labelForwardRef,
  label,
  control,
  getValues,
  setError,
  setDescriptionError,
  ...rest
}: ComponentProps) => {
  const [fieldError, setFieldError] = useState<
    | {
        brief: { message: string };
      }
    | {}
  >({});
  const name = "brief";

  const handleFocus = () => {
    onFocus(fieldNumber, name);
  };

  return (
    <>
      <Label>
        <Text>
          <StepNumber>{fieldNumber - 1}.</StepNumber>
          <span>{label}</span>
        </Text>

        {/*<TipCta
          htmlFor={name}
          forwardRef={labelForwardRef}
          onClick={handleFocus}
        />*/}
      </Label>

      <RichTextEditor
        name={name}
        {...(!!getValues &&
          !!getValues(name) && {
            defaultValue: getValues(name),
          })}
        placeholder="Se trata de..."
        maxLength={5000}
        onFocus={handleFocus}
        onBlur={onBlur}
        onChange={({ isCountValid }) => {
          setDescriptionError(!isCountValid);

          if (!isCountValid) {
            setError(
              name,
              "Superaste la cantidad de caracteres máximos permitidos"
            );
          }

          setFieldError(
            !isCountValid
              ? {
                  [name]: {
                    message:
                      "Superaste la cantidad de caracteres máximos permitidos",
                  },
                }
              : {}
          );
        }}
        options={{ marginBottom: 42 }}
        rules={{
          required: "Ingresa la descripción de tu grupo",
        }}
        errors={{ ...errors, ...fieldError }}
        register={register}
        control={control}
        {...rest}
      />

      {/* <TextArea
        name={name}
        placeholder="Se trata de..."
        maxLength={1000}
        onFocus={handleFocus}
        onBlur={onBlur}
        options={{ marginBottom: 42 }}
        rules={{
          required: true,
        }}
        errors={{...errors}}
        register={register}
        {...rest}
      /> */}
    </>
  );
};

export default Component;
