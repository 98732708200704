import { useMemo } from "react";
import { connect } from "react-redux";
import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import { GetWelcomeContentPostInDto } from "../../../../../../types/welcome-section";
import {
  Progress,
  ProgressBar,
  ProgressBarContainer,
} from './styles'

interface ComponentProps {
  welcomeContentPost: GetWelcomeContentPostInDto
  welcomeContentPostStates
}

const Component = ({
  welcomeContentPost,
  welcomeContentPostStates
}: ComponentProps) => {
  const { isMobile } = useWindowSize()

  const total = useMemo(() => {
    return welcomeContentPost?.welcome_section?.total_contents
  }, [welcomeContentPost])

  const completed = useMemo(() => {
    return welcomeContentPost?.welcome_section?.completed_contents_by_user
  }, [welcomeContentPost])

  const progress = useMemo(() => {
    return total ? Math.floor((completed / total) * 100) : 0
  }, [total, completed])

  return (
    <div style={{ width: '100%', display: "flex", alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', gap: isMobile ? 8 : 14 }}>
      <Progress style={{}}>{progress}%</Progress>
      <ProgressBarContainer style={{}}>
        <ProgressBar style={{ width: `${progress}%`, }} />
      </ProgressBarContainer>
    </div>
  )
}

const state = ({ welcomeContentStore }) => {
  const { data: welcomeContentPost, states: welcomeContentPostStates } = welcomeContentStore.welcomeContentPost;

  return {
    welcomeContentPost,
    welcomeContentPostStates,
  };
};

export default connect(state)(Component);