import services from "../../../services";
import * as Yup from "yup";

const today = new Date();
today.setHours(0, 0, 0, 0);

export const schema = Yup.object().shape({
  name: Yup.string(),
  description: Yup.string(),
  fields: Yup.array().of(
    Yup.object().shape({
      caption: Yup.string().required(),
      field_type: Yup.string().required(),
      option: Yup.array().of(Yup.string()),
    })
  ),
  group: Yup.number().required(),
});

interface FormValues {
  name: string;
  description: string;
  fields: {
    caption: string;
    field_type: "short_text" | "drowdownlist";
    option?: string[];
  }[];
  group?: number;
}

export const initialValues: FormValues = {
  name: "Encuesta post venta",
  description: "",
  fields: [
    {
      caption: "",
      field_type: "short_text",
    },
    {
      caption: "",
      field_type: "short_text",
    },
  ],
};
