import { connect, useDispatch } from "react-redux";
import {
  ButtonsNext,
  ButtonsBack,
  CarouselContent,
  Slide,
  GlobalStyles,
  ReviewCard,
  Message,
  Name,
  Occupation,
} from "./styles";

import { CarouselProvider, Slider } from "pure-react-carousel";
import { Group } from "../../../../../types";
import { Section, SectionName, Title } from "../StyledComponents";
import { useEffect, useState } from "react";
import { getPosts } from "../../../../../store/actions/post";
import { useWindowSize } from "../../../../../hooks/useWindowSize";

interface ComponentProps {
  group: Group;
  groupStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ group }: ComponentProps) => {
  const dispatch = useDispatch();
  const { isDesktop } = useWindowSize();
  const [totalSteps, setTotalSteps] = useState(0);
  const [slideNo, setSlideNo] = useState(1);

  useEffect(() => {
    const indexOf = (slides, slide) => {
      for (const [currentSlide, index] of slides) {
        if (currentSlide === slide) {
          return index;
        }
      }
    };

    let slides: NodeListOf<Element> =
      document.querySelectorAll(".carousel-item");

    const change = (slide: Element) => {
      setSlideNo(indexOf(slides, slide) + 1);
    };

    slides.forEach((slide) =>
      slide.addEventListener("transitionstart", () => change(slide))
    );

    return () => {
      slides.forEach((slide) =>
        slide.removeEventListener("transitionstart", () => change(slide))
      );
    };
  }, []);

  useEffect(() => {
    if (!!group) {
      setTotalSteps(group?.reviews?.length);
    }
  }, [group]);

  return (
    <Section>
      <GlobalStyles />
      <SectionName>¿qué opinan sobre mi?</SectionName>
      <Title>Testimonios de suscriptores</Title>

      <div style={{ height: "34px" }} />

      <CarouselProvider
        naturalSlideWidth={650}
        naturalSlideHeight={550}
        totalSlides={totalSteps}
        isIntrinsicHeight={true}
        visibleSlides={1}
        currentSlide={
          isDesktop
            ? !!group && !!group?.reviews && group?.reviews?.length <= 3
              ? 0
              : 1
            : 0
        }
        className="review-carousel"
      >
        <CarouselContent>
          {isDesktop && !!group?.reviews && group?.reviews?.length > 1 && (
            <ButtonsBack>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="19"
                viewBox="0 0 12 19"
                fill="none"
              >
                <path
                  opacity="0.5"
                  d="M9.42 0.219999L11.28 2.06L3.66 9.6L11.28 17.14L9.42 18.98L0.0199999 9.6L9.42 0.219999Z"
                  fill="#6C5CE7"
                />
              </svg>
            </ButtonsBack>
          )}

          <Slider>
            {!!group?.reviews &&
              group?.reviews?.length > 0 &&
              group?.reviews?.map((review, index) => (
                <Slide
                  index={index}
                  className={index === slideNo ? "current-slide" : ""}
                >
                  <ReviewCard className="review-card">
                    <Message className="review-message">
                      {review.message}
                    </Message>
                    <Name className="review-author">{review.author_name}</Name>
                    <Occupation className="review-occupation">
                      {review.occupation}
                    </Occupation>
                  </ReviewCard>
                </Slide>
              ))}
          </Slider>

          {isDesktop && !!group?.reviews && group?.reviews?.length > 1 && (
            <ButtonsNext>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="19"
                viewBox="0 0 12 19"
                fill="none"
              >
                <path
                  d="M2.58 18.78L0.72 16.94L8.34 9.4L0.72 1.86L2.58 0.0199981L11.98 9.4L2.58 18.78Z"
                  fill="#6C5CE7"
                />
              </svg>
            </ButtonsNext>
          )}
        </CarouselContent>
      </CarouselProvider>
    </Section>
  );
};

const state = ({ groupStore }) => {
  const { data: group, states: groupStates } = groupStore.group;

  return {
    group,
    groupStates,
  };
};

export default connect(state)(Component);
