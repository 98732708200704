import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";
import {
  GET_EXPERT_TELEGRAM_GROUP,
  GET_EXPERT_TELEGRAM_GROUP_SUCCESS,
  GET_EXPERT_TELEGRAM_GROUP_ERROR,
} from "../types/group/get-expert-telegram-group";

import {
  GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS,
  GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_SUCCESS,
  GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_ERROR,
} from "../types/group/get-expert-telegram-bot-url-for-notifications";

import {
  GET_GROUP_BY_ID,
  GET_GROUP_BY_ID_SUCCESS,
  GET_GROUP_BY_ID_ERROR,
  GET_GROUP_BY_SLUG,
  GET_GROUP_BY_SLUG_SUCCESS,
  GET_GROUP_BY_SLUG_ERROR,
  UPDATE_CURRENT_GROUP,
  UPDATE_CURRENT_GROUP_SUCCESS,
  UPDATE_CURRENT_GROUP_ERROR,
} from "../types/group/get-group";

import {
  GET_GROUP_SUBSCRIBERS,
  GET_GROUP_SUBSCRIBERS_SUCCESS,
  GET_GROUP_SUBSCRIBERS_ERROR,
} from "../types/group/get-group-subscribers";

import {
  GET_GROUP_METRICS,
  GET_GROUP_METRICS_SUCCESS,
  GET_GROUP_METRICS_ERROR,
} from "../types/group/get-metrics";

import {
  GET_SUBSCRIBER_TELEGRAM_GROUP,
  GET_SUBSCRIBER_TELEGRAM_GROUP_SUCCESS,
  GET_SUBSCRIBER_TELEGRAM_GROUP_ERROR,
} from "../types/group/get-subscriber-telegram-group";

import {
  NEW_GROUP,
  NEW_GROUP_SUCCESS,
  NEW_GROUP_ERROR,
} from "../types/group/new-group";

import {
  NEW_TELEGRAM_GROUP,
  NEW_TELEGRAM_GROUP_SUCCESS,
  NEW_TELEGRAM_GROUP_ERROR,
} from "../types/group/new-telegram-group";

import {
  UPDATE_GROUP,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_ERROR,
} from "../types/group/update-group";

import {
  UPDATE_GROUP_MAIN_CURRENCY,
  UPDATE_GROUP_MAIN_CURRENCY_SUCCESS,
  UPDATE_GROUP_MAIN_CURRENCY_ERROR
} from "../types/group/update-group-main-currency";

import {
  NEW_DISCOUNT_SUCCESS,
  NEW_DISCOUNT_ERROR,
} from "../types/discount/new-discount";

import {
  UPDATE_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_ERROR,
} from "../types/discount/update-discount";

import {
  DELETE_REVIEW,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_ERROR,
} from "../types/group/delete-review";

import {
  GET_GROUP_LAST_ACTIVITY,
  GET_GROUP_LAST_ACTIVITY_SUCCESS,
  GET_GROUP_LAST_ACTIVITY_ERROR,
} from "../types/group/get-group-last-activity";
import { UPDATE_CURRENT_USER_GROUPS } from "../types/user/get-user-groups";

const getGroupInformation = ({ groupStore }) => groupStore.group.data;

function* getGroupBySlug({ payload }) {
  try {
    const groupInformation = yield call(services.group.getGroupBySlug, payload);

    yield put({
      type: GET_GROUP_BY_SLUG_SUCCESS,
      payload: { ...groupInformation },
    });
  } catch (error) {
    yield put({ type: GET_GROUP_BY_SLUG_ERROR, payload: error });
  }
}

function* getGroupById({ payload }) {
  try {
    const groupInformation = yield call(services.group.getGroupById, payload);
    const totalSubscribers = yield call(
      services.group.getTotalSubscribers,
      payload
    );
    yield put({
      type: GET_GROUP_BY_ID_SUCCESS,
      payload: { ...groupInformation, ...totalSubscribers },
    });
  } catch (error) {
    yield put({ type: GET_GROUP_BY_ID_ERROR, payload: error });
  }
}

function* getGroupMetrics({ payload }) {
  try {
    const metrics = yield call(services.group.getGroupMetrics, payload);
    yield put({
      type: GET_GROUP_METRICS_SUCCESS,
      payload: metrics,
    });
  } catch (error) {
    yield put({ type: GET_GROUP_METRICS_ERROR, payload: error });
  }
}

function* getGroupSubscribers({ payload }) {
  const groupInformation = yield select(getGroupInformation);

  try {
    const subscribers = yield call(services.group.getSubscribers, {
      id: groupInformation.id,
      ...payload,
    });
    yield put({
      type: GET_GROUP_SUBSCRIBERS_SUCCESS,
      payload: { ...subscribers },
    });
  } catch (error) {
    yield put({ type: GET_GROUP_SUBSCRIBERS_ERROR, payload: error });
  }
}

function* getGroupLastActivity({ payload }) {
  try {
    const { error, response } = yield call(
      services.group.getGroupLastActivity,
      payload
    );

    if (error) {
      yield put({ type: GET_GROUP_LAST_ACTIVITY_ERROR, payload: error });
    } else {
      yield put({
        type: GET_GROUP_LAST_ACTIVITY_SUCCESS,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: GET_GROUP_LAST_ACTIVITY_ERROR,
      payload: "Error al obtener la actividad del grupo",
    });
  }
}

function* getSubscriberTelegramGroup({ payload }) {
  try {
    const result = yield call(
      services.group.getSubscriberTelegramGroup,
      payload
    );
    yield put({ type: GET_SUBSCRIBER_TELEGRAM_GROUP_SUCCESS, payload: result });
  } catch (error) {
    yield put({
      type: GET_SUBSCRIBER_TELEGRAM_GROUP_ERROR,
      payload: error,
    });
  }
}

function* getExpertTelegramGroup({ payload }) {
  try {
    const result = yield call(services.group.getExpertTelegramGroup, payload);
    yield put({ type: GET_EXPERT_TELEGRAM_GROUP_SUCCESS, payload: result });
  } catch (error) {
    yield put({
      type: GET_EXPERT_TELEGRAM_GROUP_ERROR,
      payload: error,
    });
  }
}

function* getExpertTelegramBotUrlForNotifications() {
  try {
    const result = yield call(services.group.getExpertTelegramBotUrlForNotifications);
    yield put({ type: GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_SUCCESS, payload: result });
  } catch (error) {
    yield put({
      type: GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_ERROR,
      payload: error,
    });
  }
}

function* newTelegramGroup({ payload }) {
  try {
    const result = yield call(services.group.newTelegramGroup, payload);
    yield all([
      put({ type: NEW_TELEGRAM_GROUP_SUCCESS, payload: result }),
      put({
        type: UPDATE_CURRENT_GROUP,
        payload,
      }),
    ]);
  } catch (error) {
    yield put({ type: NEW_TELEGRAM_GROUP_ERROR, payload: error });
  }
}

function* newGroup({ payload }) {
  try {
    const result = yield call(services.group.newGroup, payload);
    yield all([
      put({ type: NEW_GROUP_SUCCESS, payload: result }),
      put({ type: UPDATE_CURRENT_USER_GROUPS, payload: result }),
    ]);
  } catch (error) {
    yield put({ type: NEW_GROUP_ERROR, payload: error });
  }
}

function* updateGroup({ payload }) {
  try {
    const result = yield call(services.group.updateGroup, payload);
    yield all([
      put({ type: UPDATE_GROUP_SUCCESS, payload: result }),
      put({
        type: UPDATE_CURRENT_GROUP,
        payload: { ...payload, ...result, group_id: payload.id },
      }),
    ]);
  } catch (error) {
    yield put({ type: UPDATE_GROUP_ERROR, payload: error });
  }
}

function* updateGroupMainCurrency({ payload }) {
  try {
    const { error, response } = yield call(services.group.updateGroupMainCurrency, payload);

    if (error) {
      yield put({ type: UPDATE_GROUP_MAIN_CURRENCY_ERROR, payload: error });
    } else {
      const groupInformation = yield select(getGroupInformation);

      yield all([
        put({ type: UPDATE_GROUP_MAIN_CURRENCY_SUCCESS, payload: response }),
        put({
          type: UPDATE_CURRENT_GROUP_SUCCESS,
          payload: { ...groupInformation, main_currency: payload?.main_currency },
        }),
      ]);
    }
  } catch (error) {
    yield put({ type: UPDATE_GROUP_MAIN_CURRENCY_ERROR, payload: error });
  }
}

function* updateCurrentGroup({ action, payload }) {
  try {
    const result = yield call(services.group.getGroupById, payload);
    yield put({ type: UPDATE_CURRENT_GROUP_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: UPDATE_CURRENT_GROUP_ERROR, payload: error });
  }
}

function* deleteReview({ payload }) {
  try {
    const result = yield call(services.group.deleteReview, payload);
    yield put({ type: DELETE_REVIEW_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: DELETE_REVIEW_ERROR, payload: error });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_GROUP_BY_SLUG, getGroupBySlug);
  yield takeLatest<any>(GET_GROUP_BY_ID, getGroupById);
  yield takeLatest<any>(
    GET_SUBSCRIBER_TELEGRAM_GROUP,
    getSubscriberTelegramGroup
  );
  yield takeLatest<any>(GET_EXPERT_TELEGRAM_GROUP, getExpertTelegramGroup);
  yield takeLatest<any>(GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS, getExpertTelegramBotUrlForNotifications);
  yield takeLatest<any>(NEW_TELEGRAM_GROUP, newTelegramGroup);
  yield takeLatest<any>(NEW_GROUP, newGroup);
  yield takeLatest<any>(UPDATE_GROUP, updateGroup);
  yield takeLatest<any>(UPDATE_CURRENT_GROUP, updateCurrentGroup);
  yield takeLatest<any>(GET_GROUP_BY_ID_SUCCESS, getGroupSubscribers);
  yield takeLatest<any>(GET_GROUP_SUBSCRIBERS, getGroupSubscribers);

  yield takeLatest<any>(NEW_DISCOUNT_ERROR, updateCurrentGroup);
  yield takeLatest<any>(NEW_DISCOUNT_SUCCESS, updateCurrentGroup);
  yield takeLatest<any>(UPDATE_DISCOUNT_ERROR, updateCurrentGroup);
  yield takeLatest<any>(UPDATE_DISCOUNT_SUCCESS, updateCurrentGroup);

  yield takeLatest<any>(GET_GROUP_METRICS, getGroupMetrics);
  yield takeLatest<any>(GET_GROUP_LAST_ACTIVITY, getGroupLastActivity);

  yield takeLatest<any>(DELETE_REVIEW, deleteReview);
  yield takeLatest<any>(UPDATE_GROUP_MAIN_CURRENCY, updateGroupMainCurrency);

}
