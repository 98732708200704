import React, { createRef, useEffect, useMemo, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { GlobalStyles, Page, Header, Title, PageContent } from "./styles";
import Tabs from "./components/Tabs";
import Grid from "../../Grid";
import Toolbar from "./components/Toolbar";

import Loader from "../../Loader";
import DesktopMenu from "../components/DesktopMenu";
import MobileMenu from "../components/MobileHeader";
import constants from "../../../constants";
import { setCurrentPage } from "../../../store/actions/page";

const Component = ({ children, expertInformation, userStates }: any) => {
  const pageContentRef = createRef<HTMLElement>();
  const [activeTab, setActiveTab] = useState<string>("");

  const isExpert = useMemo((): boolean => {
    return !!expertInformation && userStates.success;
  }, [expertInformation, userStates]);

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <Tabs active={activeTab} isExpert={isExpert} />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <div style={{ height: "20px" }} />

      {React.createElement(children.type, {
        ...{
          ...children.props,
          setActiveTab,
          pageContentRef,
        },
      })}
    </>
  );
};

const state = ({ userStore }) => {
  const { data: userInformation, states: userStates } = userStore.information;
  return {
    expertInformation: userInformation?.expert,
    userStates,
  };
};

export default connect(state)(Component);
