import styled from "styled-components";

export const Actions = styled("div")`
  padding: 8px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: white;
  gap: 8px;
`;
