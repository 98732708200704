import styled from "styled-components";

/* Tooltip.css */
export const Tooltip = styled('div')`
  position: absolute;
  z-index: 10000;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  width: 180px;
  font-size: 12px;
  line-height: 16px;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;

  &.visible {
    opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;
    border-style: solid;
    opacity: 1;
  }

  /* Posiciones */

  &.bottom-centered {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    &:before {
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-color: transparent transparent #333 transparent;
    }
  }

  &.bottom-left {
    top: 100%;
    left: 0;
    transform: translateY(5px);
    &:before {
      top: -5px;
      left: 10px;
      border-width: 5px;
      border-color: transparent transparent #333 transparent;
    }
  }

  &.bottom-right {
    top: 100%;
    right: 0;
    transform: translateY(5px);
    &:before {
      top: -5px;
      right: 10px;
      border-width: 5px;
      border-color: transparent transparent #333 transparent;
    }
  }

  &.top-centered {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    &:before {
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-color: #333 transparent transparent transparent;
    }
  }

  &.top-left {
    bottom: 100%;
    left: 0;
    transform: translateY(-5px);
    &:before {
      bottom: -5px;
      left: 10px;
      border-width: 5px;
      border-color: #333 transparent transparent transparent;
    }
  }

  &.top-right {
    bottom: 100%;
    right: 0;
    transform: translateY(-5px);
    &:before {
      bottom: -5px;
      right: 10px;
      border-width: 5px;
      border-color: #333 transparent transparent transparent;
    }
  }

  &.left-centered {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    &:before {
      right: -5px;
      top: 50%;
      transform: translateY(-50%);
      border-width: 5px;
      border-color: transparent #333 transparent transparent;
    }
  }

  &.left-top {
    right: 100%;
    top: 0;
    transform: translateX(-5px);
    &:before {
      right: -5px;
      top: 10px;
      border-width: 5px;
      border-color: transparent #333 transparent transparent;
    }
  }

  &.left-bottom {
    right: 100%;
    bottom: 0;
    transform: translateX(-5px);
    &:before {
      right: -5px;
      bottom: 10px;
      border-width: 5px;
      border-color: transparent #333 transparent transparent;
    }
  }

  &.right-centered {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    &:before {
      left: -5px;
      top: 50%;
      transform: translateY(-50%);
      border-width: 5px;
      border-color: transparent transparent transparent #333;
    }
  }

  &.right-top {
    left: 100%;
    top: 0;
    transform: translateX(5px);
    &:before {
      left: -10px;
      top: 10px;
      transform: rotate(180deg);
      border-width: 5px;
      border-color: transparent transparent transparent #333;
    }
  }

  &.right-bottom {
    left: 100%;
    bottom: 0;
    transform: translateX(5px);
    &:before {
      left: -10px;
      bottom: 10px;
      transform: rotate(180deg);
      border-width: 5px;
      border-color: transparent transparent transparent #333;
    }
  }
`;
