import { DiscountDto } from "../../../types";

export const GET_ALL_DISCOUNTS = "GET_ALL_DISCOUNTS";
export const GET_ALL_DISCOUNTS_SUCCESS = "GET_ALL_DISCOUNTS_SUCCESS";
export const GET_ALL_DISCOUNTS_ERROR = "GET_ALL_DISCOUNTS_ERROR";
export const UPDATE_CURRENT_DISCOUNTS = "UPDATE_CURRENT_DISCOUNTS";

export interface GetAllDiscounts {
  type: typeof GET_ALL_DISCOUNTS;
  payload: { plan_id?: number, show_in_page: boolean };
}

export interface GetAllDiscountsSuccess {
  type: typeof GET_ALL_DISCOUNTS_SUCCESS;
  payload: DiscountDto[];
}

export interface GetAllDiscountsError {
  type: typeof GET_ALL_DISCOUNTS_ERROR;
  payload: string | boolean;
}

export interface UpdateCurrentDiscounts {
  type: typeof UPDATE_CURRENT_DISCOUNTS;
  payload: DiscountDto[];
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetAllDiscounts
  | GetAllDiscountsSuccess
  | GetAllDiscountsError
  | UpdateCurrentDiscounts;
