import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  LinkToHomePage,
  Menu,
  Brand,
  ToggleMenu,
  Navbars,
  Navbar,
  NavbarItem,
  NavbarItemWrapper,
  NavbarItemLabel,
  Triangle,
  Logo,
  Profile,
  AvatarImage,
  Name,
  Username,
  Mark,
} from "./styles";
import { Center } from "../../../StyledComponents";
import constants from "../../../../constants";
import triangle from "../../../../assets/dashboard-menu/triangle.svg";
import { ghostLink } from "../../../../helpers/ghost-link";
import Icon from "../../../Icon";
import slugify from "slugify";
import { connect, useDispatch } from "react-redux";
import { User } from "../../../../types";
import logo from "../../../../assets/logo.svg";
import logoIso from "../../../../assets/logo-icon.svg";
import UserRoundedIcon from "../../../../assets/icons/user-rounded.svg";
import { Preference } from "../../../../types/preferences";
import { showPreference } from "../../../../helpers/preferenceVisible";

interface ComponentProps {
  userInformation: User;
  userInformationStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  useLocation;
  preferences: Preference[];
  preferencesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  subscriptions;
  subscriptionsStates;
}

const Component = ({
  userInformation,
  userInformationStates,
  useLocation,
  preferences,
  preferencesStates,
  subscriptions,
  subscriptionsStates,
}: ComponentProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [menuIsOpened, setMenuIsOpened] = useState(true);
  const [forceHide, setForceHide] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const [availableMarks, setAvailableMarks] = useState<string[]>([]);

  useEffect(() => {
    const marks = [
      ...constants?.dashboardMenu?.secondaryMenu,
      ...constants?.dashboardMenu?.mainMenu,
    ]
      .filter(
        (item) => !!item?.newFeatureMark && item?.newFeatureMark?.length > 0
      )
      .map((item) => item.newFeatureMark || [])
      .flat();

    if (marks.length > 0) {
      const availablesMarks = marks?.filter((mark) => {
        const condition = showPreference({
          preferences,
          preferencesStates,
          id: mark,
        });

        return condition;
      });

      setAvailableMarks(availablesMarks);
    }
  }, [preferences]);

  const isExpert = useMemo((): boolean => {
    return !!userInformation?.expert && userInformationStates.success;
  }, [userInformation, userInformationStates]);

  const onClickHanlder = (menuItem) => {
    const redirect = menuItem.native;

    if (menuItem.url) {
      if (redirect) {
        history.push(menuItem.url);
      } else {
        ghostLink(menuItem.url);
      }

      setActiveItem(menuItem.url);
    }

    if (menuItem.action) {
      dispatch(menuItem.action());
    }
  };

  const setResize = () => {
    const forceHide = window.innerWidth < 1130;
    setForceHide(forceHide);
  };

  useEffect(() => {
    setActiveItem(location.pathname);
  }, []);

  useEffect(() => {
    setResize();
    window.addEventListener("resize", setResize);

    return () => {
      window.removeEventListener("resize", setResize);
    };
  }, []);

  return (
    <Menu isOpened={menuIsOpened && !forceHide}>
      {!forceHide && (
        <ToggleMenu
          onClick={() => setMenuIsOpened(!menuIsOpened || forceHide)}
          isOpened={menuIsOpened && !forceHide}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5" fill="none">
            <path
              d="M2.687 0.175L3.3575 0.8455L1.823 2.335L3.3575 3.8245L2.687 4.495L0.5225 2.335L2.687 0.175Z"
              fill="white"
            />
          </svg>
        </ToggleMenu>
      )}

      <Brand isOpened={menuIsOpened && !forceHide}>
        <LinkToHomePage href="/">
          {menuIsOpened && !forceHide && (
            <Logo
              src={logo}
              width="128px"
              height="25px"
              className="logo-desktop"
              alt="Klouser"
              lazy
            />
          )}
          {(!menuIsOpened || forceHide) && (
            <Icon icon={logoIso} size="40px" color="#bb85ff" />
          )}
        </LinkToHomePage>
      </Brand>

      <Profile>
        <AvatarImage
          src={userInformation?.expert?.profile_pic?.url || UserRoundedIcon}
          alt=""
          isOpened={menuIsOpened && !forceHide}
        />

        <Center column horizontal="left" vertical="top">
          <Username isOpened={menuIsOpened && !forceHide}>
            {userInformation?.expert?.username || userInformation?.email?.split("@")[0]}
          </Username>

          <Name isOpened={menuIsOpened && !forceHide}>
            {userInformation?.name}
          </Name>
        </Center>
      </Profile>

      <Navbars>
        <Navbar>
          {constants.dashboardMenu.mainMenu
            .filter(
              (menuItem) =>
                menuItem.active &&
                //!(
                //  menuItem.label === "Cupones" &&
                //  userInformation?.expert?.currency === "ARS"
                //) &&
                (!menuItem.expert ||
                  (menuItem.expert && !!userInformation?.expert)) &&
                (menuItem.label !== "Mis suscripciones" ||
                  (menuItem.label === "Mis suscripciones" &&
                    !!subscriptions &&
                    subscriptions.length > 0))
            )
            .map((menuItem) => {
              const showMark = availableMarks.some(
                (r) =>
                  menuItem?.newFeatureMark &&
                  menuItem?.newFeatureMark?.indexOf(r) >= 0
              );

              return (
                <NavbarItem key={slugify(menuItem.label, { lower: true })}>
                  <NavbarItemWrapper
                    isOpened={menuIsOpened && !forceHide}
                    isActive={
                      !!menuItem?.url && activeItem.includes(menuItem?.url)
                    }
                    onClick={() => onClickHanlder(menuItem)}
                    width={""}
                  >
                    {showMark && (
                      <Mark isActive={menuItem.url === activeItem} />
                    )}
                    <Icon
                      icon={menuItem.icon}
                      size="24px"
                      color={
                        !!menuItem?.url && activeItem.includes(menuItem?.url)
                          ? "white"
                          : "rgba(45, 52, 54, 0.75)"
                      }
                    />

                    <NavbarItemLabel
                      className="main-menu-label"
                      isOpened={menuIsOpened && !forceHide}
                      isActive={
                        !!menuItem?.url && activeItem.includes(menuItem?.url)
                      }
                    >
                      <Triangle
                        icon={triangle}
                        size="8px"
                        color="#6C5CE7"
                        isOpened={menuIsOpened && !forceHide}
                      />

                      {menuItem.url === "/dashboard/community" && !isExpert
                        ? "Crear mi propia Comunidad "
                        : menuItem.label}
                    </NavbarItemLabel>
                  </NavbarItemWrapper>
                </NavbarItem>
              );
            })}
        </Navbar>

        <Navbar>
          {constants.dashboardMenu.secondaryMenu
            .filter((menuItem) => menuItem.active)
            .map((menuItem) => {
              const showMark = availableMarks.some(
                (r) =>
                  menuItem?.newFeatureMark &&
                  menuItem?.newFeatureMark?.indexOf(r) >= 0
              );

              return (
                <NavbarItem key={slugify(menuItem.label, { lower: true })}>
                  <NavbarItemWrapper
                    isOpened={menuIsOpened && !forceHide}
                    isActive={menuItem.url === activeItem}
                    onClick={() => onClickHanlder(menuItem)}
                    width={""}
                  >
                    {showMark && (
                      <Mark isActive={menuItem.url === activeItem} />
                    )}

                    <Icon
                      icon={menuItem.icon}
                      size="26px"
                      color={
                        menuItem.url === activeItem
                          ? "white"
                          : "rgba(45, 52, 54, 0.75)"
                      }
                    />
                    <NavbarItemLabel
                      className="secondary-menu-label"
                      isOpened={menuIsOpened && !forceHide}
                      isActive={
                        !!menuItem.url && activeItem.includes(menuItem.url)
                      }
                    >
                      <Triangle
                        icon={triangle}
                        size="8px"
                        color="#6C5CE7"
                        isOpened={menuIsOpened && !forceHide}
                      />

                      {menuItem.label}
                    </NavbarItemLabel>
                  </NavbarItemWrapper>
                </NavbarItem>
              );
            })}
        </Navbar>
      </Navbars>
    </Menu>
  );
};

const state = ({ userStore, preferencesStore }) => {
  const { data: userInformation, states: userInformationStates } =
    userStore.information;
  const { data: preferences, states: preferencesStates } =
    preferencesStore.preferences;
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;

  return {
    userInformation,
    userInformationStates,
    preferences,
    preferencesStates,
    subscriptions,
    subscriptionsStates,
  };
};

export default connect(state)(Component);
