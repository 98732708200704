import { useState } from "react";
import check from "../../assets/check.svg";
import postCopyIcon from "../../assets/icons/post-copy-link-icon.svg"
import Icon from "../Icon";
import { CopyToClipboad, Copied, NotCopied } from "./styles";

interface ComponentProps {
  copy?: string;
  children?: any;
  successStyle?: any;
  showChildren?: boolean;
  showCopyIcon?: boolean;
  iconColor?: string;
  style?: any;
}

const Component = ({
  copy = "",
  children,
  successStyle,
  showChildren = false,
  iconColor = "green",
  showCopyIcon = false,
  ...rest
}: ComponentProps) => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    setCopied(true);

    navigator.clipboard.writeText(copy);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <CopyToClipboad type="button" onClick={handleClick} {...rest}>
      <NotCopied copied={copied && !showChildren} style={{ fontWeight: 100 }}>{children} {showCopyIcon && (<Icon icon={copied ? check : postCopyIcon} size="19px" color={iconColor || "gray"} />)}</NotCopied>
      <Copied copied={copied} {...successStyle}>
        {!showCopyIcon && (<Icon icon={check} size="19px" color={iconColor} />)}
      </Copied>
    </CopyToClipboad>
  );
};

export default Component;
