import { Center } from "../../../../../../components/StyledComponents";
import { ModuleInDto } from "../../../../../../types/module";
import Image from "../../../../../../components/Image";
import Icon from "../../../../../../components/Icon";
import { Button, ModuleIcon, ModuleName, ModuleActions, ValidationFlag } from "./styles";
import deleteIcon from "../../../../../../assets/icons/trash.svg";
import eyeOnIcon from "../../../../../../assets/icons/show.svg";
import eyeOffIcon from "../../../../../../assets/icons/hide.svg";

import { useMemo } from "react";
import { useModules } from "../../../../../../providers/ModulesProvider";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../../../store/actions/modal";

interface ComponentProps {
  module: ModuleInDto;
  icon?: string;
}

const Component = ({ module, icon }: ComponentProps) => {
  const dispatch = useDispatch();
  const {
    setModuleToBeDeleted,
    reorderOn,
    selectedModule,
    setSelectedModule,
    setReorderOn,
    actions,
  } = useModules();

  const isActiveModule = useMemo(() => {
    return module.state === "active";
  }, [module]);

  const isSelectedModule = useMemo(() => {
    return selectedModule?.id === module.id;
  }, [selectedModule]);

  return (
    <Center horizontal="center" vertical="center" gap={6}>
      <ModuleIcon
        isActiveModule={isActiveModule}
        isSelectedModule={isSelectedModule}
      >
        {!!module?.icon && (
          <Icon icon={icon || module?.icon} size="20px" color="white" />
        )}
      </ModuleIcon>

      <ModuleName
        isActiveModule={isActiveModule}
        isSelectedModule={isSelectedModule}
        onClick={() =>
          !reorderOn &&
          selectedModule?.id !== module.id &&
          setSelectedModule({ ...module })
        }
      >
        {!module.is_valid && <ValidationFlag isValid={module.is_valid} />}
        <span>{module.title || "Módulo sin título"}</span>
      </ModuleName>

      <ModuleActions>
        <Button
          onClick={() => {
            actions.update.exec({
              ...module,
              state: module.state === "inactive" ? "active" : "inactive",
            });
          }}
        >
          <Icon
            icon={isActiveModule ? eyeOnIcon : eyeOffIcon}
            size="20px"
            color="#2869C5"
          />
        </Button>

        <Button
          onClick={() => {
            setModuleToBeDeleted(module.id);
            dispatch(showModal("modal-remove-module"));
          }}
        >
          <Icon icon={deleteIcon} size="20px" color="#2869C5" />
        </Button>
      </ModuleActions>
    </Center>
  );
};

export default Component;
