import { GlobalStyles, DotWrapper, Dots } from "./styles";
import React from "react";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import { useWindowSize } from "../../../../hooks/useWindowSize";

interface ComponentProps {
  children: any;
}

const Component = ({ children }: ComponentProps) => {
  const { isMobile } = useWindowSize();
  return (
    <>
      <GlobalStyles />
      <CarouselProvider
        className="carousel-testimonials"
        naturalSlideWidth={650}
        naturalSlideHeight={550}
        totalSlides={children.length}
        isIntrinsicHeight={true}
        visibleSlides={isMobile ? 1 : 2}
        touchEnabled={true}
        dragEnabled={true}
        dragStep={isMobile ? 1 : 2}
        infinite={true}
      >
        <Slider
          classNameTray="slider-skin-one"
          classNameTrayWrap="slider-wrap-skin-one"
        >
          {React.Children.map(children, (child, index) => (
            <Slide index={index} className="slide-skin-one">
              {React.createElement(child.type, {
                ...child.props,
              })}
            </Slide>
          ))}
        </Slider>

        <DotWrapper>
          {React.Children.map(children, (child, index) => (
            <Dots slide={index} />
          ))}
        </DotWrapper>
      </CarouselProvider>
    </>
  );
};

export default Component;
