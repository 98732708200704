import Modal from "./../../../../components/Modal";
import congratulation from "../../../../assets/congratulation.svg";
import { Image, Title, Text } from "./styles";

interface ComponentProps {
  page: any;
}

const Component = ({ page }: ComponentProps) => {
  return (
    <Modal name="congratulation" width="334" showClose={false}>
      <Image src={congratulation} alt="Congrat" />
      <Title>¡Felicitaciones tu {page} se creó con éxito!</Title>
      <Text>A continuación configura los detalles para tu lanzamiento</Text>
    </Modal>
  );
};

export default Component;
