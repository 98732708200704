import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Success } from "./styles";
import { getUrlParamByName } from "../../../helpers/get-url-param";
import Loader from "../../../components/Loader";
import Grid from "../../../components/Grid";
import { saveUserSubscription } from "./../../../store/actions/subscription";
import logo from "./../../../assets/logo.svg";
import { getCookie } from "../../../helpers/cookies-handler";

interface ComponentProps {
  subscription;
  subscriptionStates;
}

const Component = ({ subscription, subscriptionStates }: ComponentProps) => {
  const dispatch = useDispatch();
  const [param, setParam] = useState<string | null>(null);
  const [organizationId, setOrganizationId] = useState<string | null>(null);
  const [gw, setGw] = useState<string | null>(null);
  const [platform, setPlatform] = useState<string | null>(null);

  useEffect(() => {
    let urlParam = getUrlParamByName("id");
    let platform = "chargebee";
    let organizationIdParam = getUrlParamByName("organization_id");
    let gwParam = getUrlParamByName("gw");

    if (!urlParam) {
      urlParam = getUrlParamByName("subscription_id");
      platform = "rebill";
    }

    const isMercadoPago = getUrlParamByName("site_id") || getUrlParamByName("preapproval_id");
    if (isMercadoPago) {
      urlParam = getUrlParamByName("payment_id") || getUrlParamByName("preapproval_id");
      platform = "mercadopago";
    }

    if (!!platform && urlParam) {
      setPlatform(platform);
      setParam(urlParam);

      if(organizationIdParam){
        setOrganizationId(organizationIdParam || null)
      } else {
        if(platform === "rebill"){
          setOrganizationId("bc07b250-8f5f-437f-8f44-2f00a01964b8") 
          //default organization_id for rebill main account. This change is to avoid chaning the URL redirect from every product at rebill
        }
      }

      if(gwParam){
        setGw(gwParam || null)
      }
      
    } else {
      setTimeout(() => {
        window.location.href = `/profile/subscriptions`;
      }, 10000)
    }
  }, []);

  useEffect(() => {
    !!param &&
      !!platform &&
      dispatch(
        saveUserSubscription({
          reference_id: param,
          platform: platform,
          referral_id: getCookie("referral_id"),
          organization_id: organizationId,
          gateway_id: gw ? Number(gw) : null
        })
      );
  }, [param, platform]);

  useEffect(() => {
    if (subscriptionStates.success && subscription?.group?.slug) {
      window.location.href = `/group/${subscription?.group?.slug}/survey`;
    }

    if (subscriptionStates.error) {
      window.location.href = "/checkout/faliure";
    }
  }, [subscriptionStates, subscription]);

  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <Success>
            <img src={logo} alt="Klouser" width="183px" height="36px" />
            <p>Estamos procesando tu subscripción.</p>
            <Loader color="#bb85ff" />
          </Success>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

const state = ({ subscriptionStore }) => {
  const { data: subscription, states: subscriptionStates } =
    subscriptionStore.userSubscription;

  return {
    subscription,
    subscriptionStates,
  };
};

export default connect(state)(Component);
