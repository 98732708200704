import {
  Step,
  Information,
  H1,
  Text,
  CommunityImg,
  DivOne,
  DivTwo,
  Img,
} from "../styles";
import create from "../../../../../assets/home-page/community-create.webp";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import Grid from "../../../../../components/Grid";
import Row from "../../../../../components/Grid/Row";
import Col from "../../../../../components/Grid/Col";

const Component = ({ visible }) => {
  const { isDesktop, isTabletLandscape } = useWindowSize();
  return (
    <Step {...(visible && { className: "active" })}>
      <Grid.Container>
        <Row>
          <Col offset={{ md: 1 }} md={10}>
            <Row>
              {!isDesktop && !isTabletLandscape && (
                <Col>
                  <CommunityImg>
                    <Img src={create} alt="" width="" height="" lazy={true}/>
                  </CommunityImg>
                </Col>
              )}

              <Col md={6}>
                <Information>
                  <H1>
                    Tú ayudas a las personas, <br />
                    Klouser te ayuda a tí
                  </H1>
                  <Text>
                    De forma rápida y sin conocimientos técnicos, crea{" "}
                    <b>
                      talleres, desafíos, masterclasses, o grupos de suscripción
                    </b>
                    . Un espacio más cercano para interactuar con tu comunidad a
                    través de eventos en vivo, contenidos en múltiples formatos
                    y chats grupales.
                  </Text>
                </Information>
              </Col>

              <Col md={6}>
                {(isDesktop || isTabletLandscape) && (
                  <CommunityImg>
                    <Img src={create} alt="" width="" height="" lazy={true}/>
                  </CommunityImg>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid.Container>
    </Step>
  );
};

export default Component;
