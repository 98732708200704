import { CategoryDto } from "../../../types/category";

export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_ERROR = "DELETE_CATEGORY_ERROR";
export const RESET_DELETE_CATEGORY = "RESET_DELETE_CATEGORY";

export interface DeleteCategory {
  type: typeof DELETE_CATEGORY;
  payload: { id: number };
}

export interface DeleteCategorySuccess {
  type: typeof DELETE_CATEGORY_SUCCESS;
  payload: CategoryDto | null;
}

export interface DeleteCategoryError {
  type: typeof DELETE_CATEGORY_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteCategory {
  type: typeof RESET_DELETE_CATEGORY;
  payload: boolean | string;
}

export interface State {
  data: CategoryDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | DeleteCategory
  | DeleteCategorySuccess
  | DeleteCategoryError
  | ResetDeleteCategory
