import {
  CreateInvitation,
  CREATE_INVITATION,
  RESET_CREATE_INVITATION,
  ResetCreateInvitation
} from "../types/invitation/create";

import {
  EditInvitation,
  EDIT_INVITATION,
  RESET_EDIT_INVITATION,
  ResetEditInvitation
} from "../types/invitation/edit";

import {
  AcceptInvitation,
  ACCEPT_INVITATION,
} from "../types/invitation/accept";

import { AcceptInvitationOutDto, CreateInvitationOutDto, EditInvitationDto } from "../../types/invitation.dto";

export const createInvitation = (payload: CreateInvitationOutDto): CreateInvitation => {
  return { type: CREATE_INVITATION, payload };
};

export const resetCreateInvitation = (): ResetCreateInvitation => {
  return { type: RESET_CREATE_INVITATION, payload: null };
};

export const editInvitation = (payload: EditInvitationDto): EditInvitation => {
  return { type: EDIT_INVITATION, payload };
};

export const resetEditInvitation = (): ResetEditInvitation => {
  return { type: RESET_EDIT_INVITATION, payload: null };
};

export const acceptInvitation = (payload: AcceptInvitationOutDto): AcceptInvitation => {
  return { type: ACCEPT_INVITATION, payload };
};
