import styled from "styled-components";

export const Title = styled("div")`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
`;

export const Text = styled("p")`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 16px 0px 32px;
`;


// Styled component para el contenedor del texto
export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
`;

// Styled component para los puntitos con la animación
export const Dots = styled.span`
  display: inline-block;
  &:after {
    content: '...';
    animation: dots 2s steps(3, end) 1;
  }

  @keyframes dots {
    0% {
      content: '';
    }
    33% {
      content: '.';
    }
    66% {
      content: '..';
    }
    100% {
      content: '...';
    }
  }
`;