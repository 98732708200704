import { connect, useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import slugify from "slugify";

import Icon from "../../../../../../components/Icon";
import Swipe from "../../../../../../components/Swipe";
import Button from "../../../../../../components/Button"
import Link from "../../../../../../components/Link"
import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import { isOwnSession } from "../../../../../../helpers/isOwnSession";
import { Group, SessionInDto, Subscription, User } from "../../../../../../types";

import shareIcon from "../../../../../../assets/icons/share-semibold.svg";
import userAvatar from "../../../../../../assets/avatar.svg";
import calendarIcon from "../../../../../../assets/icons/calendar-semibold.svg";
import clockIcon from "../../../../../../assets/icons/clock-semibold.svg";

import VimeoVideo from "../../../../Posts/components/VimeoVideo";
import AspectRatio from "../../../../../../components/AspectRatio";
import NoSubscribed from '../NoSubscribed'

import { Center } from "../../styles";
import {
  Row,
  OwnerActions,
  Actions,
  ShareCta,
  EditCta,
  DeleteCta,
  Reminder,
  Avatar,
  BigText,
  SmallText,
  Description,
  Title,
  Line,
  SessionDetail,
  CleanLink,
  MenuCta,
  SwipeContent,
  Cover,
  OverCover
} from './styles'
import { useMemo } from "react";
import { useMarkdown } from "../../../../../../hooks/useMarkdown";
import { RemirrorRenderer } from "@remirror/react";
import { dateSpliter, isExpired, sessionExpired, convertFloatHourToMinutes } from "../../../../../../helpers/dates-hanlder";
import { getVimeoVideoIdFromUrl, getYouTubeUrl, isVimeoUrl, isYoutubeUrl } from "../../../../../../helpers/you-tube";
import { isSubscribed } from "../../../../../../helpers/isSubscribed";
import { getFreeSubscriptions } from "../../../../../../helpers/getFreeSubscriptions";

import pencilIcon from "../../../../../../assets/icons/post-edit-icon.svg";
import dotsVerticalIcon from "../../../../../../assets/icons/more-vertical.svg";
import laptopIcon from "../../../../../../assets/icons/laptop-semibold.svg";
import trashIcon from "../../../../../../assets/icons/trash.svg";
import unlockIcon from "../../../../../../assets/icons/unlock-semibold.svg";
import locationIcon from "../../../../../../assets/icons/location-semibold.svg";
import arroFowardUpRight from '../../../../../../assets/icons/arrow_forward_up_right-semibold.svg'
import warningIcon from '../../../../../../assets/icons/warning-semibold.svg'
import { showModal } from "../../../../../../store/actions/modal";
import { getRandomBg } from "../../../../../../helpers/randomBackground";
import MPBlockedSection from "../../../../../Group/components/MPBlockedSection";

interface ComponentProps {
  session: SessionInDto
  user: User
  group: Group
  userSubscriptions: Subscription[]
  isLoggedIn: boolean
}

function sumarMinutosAFecha(fecha, minutos) {
  // Clonar la fecha para evitar modificar la original
  let nuevaFecha = new Date(fecha);

  // Sumar los minutos a la fecha
  nuevaFecha.setMinutes(nuevaFecha.getMinutes() + minutos);

  return nuevaFecha;
}

const Component = ({ session, user, group, userSubscriptions, isLoggedIn }: ComponentProps) => {
  const theme = useTheme() as { colors: { [key: string]: string } }
  const { isMobile, isDesktop } = useWindowSize()
  const dispatch = useDispatch()
  const { typeMap, markMap, jsonStr, textStr } = useMarkdown({
    brief: session?.description,
  });

  const expert = useMemo(() => {
    const useExpertInformation = !!session?.use_profile_info

    if (useExpertInformation) {
      return {
        name: group?.creator_name,
        avatar: session?.group?.expert?.profile_pic?.url
      }
    } else {
      return {
        name: session?.speaker_name,
        avatar: session?.speaker_profile_pic?.url || userAvatar
      }
    }
  }, [session, user, group])

  const date = useMemo(() => {
    return dateSpliter(session?.session_datetime)
  }, [session])

  const startHour = useMemo(() => {
    const date = dateSpliter(session?.session_datetime);
    return `${date.hours}:${date.minutes}hs`
  }, [session])

  const endHour = useMemo(() => {
    const durationInMinutes = session?.duration_type === "hour" ? convertFloatHourToMinutes(session?.duration) : session?.duration
    const date = dateSpliter(sumarMinutosAFecha(session?.session_datetime, durationInMinutes))
    return `${date.hours}:${date.minutes}hs`
  }, [session])

  const isExpiredSession = useMemo(() => {
    const minutes = session?.duration_type === "hour" ? convertFloatHourToMinutes(session?.duration) : session?.duration
    return sessionExpired(session?.session_datetime, minutes)
  }, [session])

  const sessionWillBeRecorded = useMemo(() => {
    return session?.will_be_recorded
  }, [session])

  const sessionRecordingUrl = useMemo(() => {
    return session?.recorded_session_url
  }, [])

  const sessionRecordingExpirationDate = useMemo(() => {
    return dateSpliter(session?.recording_expiration_datetime)
  }, [session])

  const sessionRecordingExpired = useMemo(() => {
    return !!session?.recording_expiration_datetime && isExpired(session?.recording_expiration_datetime)
  }, [session])

  const notifySession = useMemo(() => {
    return session?.notificate_by_email || session?.notificate_by_chat
  }, [session])

  const chatGroup = useMemo(() => {
    return session?.group?.telegram_groups.length > 0 ? session?.group?.telegram_groups[0] : null
  }, [session])

  const notificationText = useMemo(() => {
    return (session?.notificate_by_email || session?.notificate_by_chat) && `Recordatorios activados vía ${session?.notificate_by_email ? "e-mail" : ""}${session?.notificate_by_email ? ` y ${chatGroup?.platform}` : ""}`
  }, [session, chatGroup])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(userSubscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [userSubscriptions, group, isLoggedIn])

  const isMpInactiveSubscription = useMemo(() => {
    const groupSubscription = userSubscriptions?.find(subscription => subscription?.group?.id === session?.group?.id)
    const mpInactiveSubscriptionStates = ['retrying', 'not_paid']
    return groupSubscription?.status && mpInactiveSubscriptionStates.includes(groupSubscription?.status)
  }, [userSubscriptions, session])

  return (
    <Row>
      {!isMobile && (
        <>
          <Title>{session?.title}</Title>
          <div style={{ height: 24 }} />
        </>
      )}

      {(isOwnSession(session, user?.expert?.id) || isUserSubscribed) && !isMpInactiveSubscription ? (
        <>

          {sessionRecordingExpired ? (
            <Cover onContextMenu={e => e.preventDefault()} src={session?.cover_pic?.url || getRandomBg()} isExpiredSession={true}>
              <OverCover>
                <BigText style={{ textAlign: "center" }}>{`La grabación expiró el ${sessionRecordingExpirationDate?.date} a las ${sessionRecordingExpirationDate?.hours}:${sessionRecordingExpirationDate?.minutes}hs`}</BigText>
              </OverCover>
            </Cover>
          ) : (
            <>
              {sessionRecordingUrl ? (
                <>
                  {isYoutubeUrl(session?.recorded_session_url) && (
                    <AspectRatio
                      style={{ margin: "0px auto" }}
                      ratio="16/9"
                      maxWidth={760}
                      key={`attached-file-${slugify(
                        session?.recorded_session_url
                      )}`}
                    >
                      <iframe
                        style={{ maxWidth: "100%" }}
                        src={getYouTubeUrl(session?.recorded_session_url)}
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </AspectRatio>
                  )}

                  {isVimeoUrl(session?.recorded_session_url) && (
                    <VimeoVideo videoId={getVimeoVideoIdFromUrl(session?.recorded_session_url)} isPreview={true} />
                  )}

                  {(!isVimeoUrl(session?.recorded_session_url) && !isYoutubeUrl(session?.recorded_session_url)) && (
                    <Cover onContextMenu={e => e.preventDefault()} src={session?.cover_pic?.url || getRandomBg()} isExpiredSession={true}>
                      <OverCover>
                        <BigText style={{ textAlign: "center" }}>Ya esta disponible el encuentro del {date?.day?.name?.large}, {date?.day?.number} de {date?.month?.name?.large}</BigText>
                        <Link
                          href={session?.recorded_session_url}
                          target={"_blank"}
                          options={{
                            type: "filled",
                            size: "lg",
                            skin: "purple",
                            block: true,
                            native: false
                          }}
                          style={{ gap: 6 }}
                        >
                          <Icon icon={laptopIcon} size="24px" color="#FFFFFF" />
                          Ver encuentro
                        </Link>
                      </OverCover>
                    </Cover>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {isMpInactiveSubscription ? (
            <MPBlockedSection />
          ) : (
            <NoSubscribed />
          )}
        </>
      )}

      <div style={{ padding: isMobile ? '0px 12px' : '0px' }}>
        {isOwnSession(session, user?.expert?.id) &&
          <>
            <div style={{ height: 22 }} />

            <OwnerActions isExpiredSession={isExpiredSession && !sessionRecordingUrl} sessionWillBeRecorded={sessionWillBeRecorded} >
              <Center horizontal="left" vertical="center" gap={8}>
                {isExpiredSession && sessionWillBeRecorded && !sessionRecordingUrl ? <><Icon icon={warningIcon} size="24px" color="#C0382C" />Subir grabación pediente</> : "Eres anfitrion del encuentro"}
              </Center>

              <Actions>
                <EditCta
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 6,
                    textDecoration: 'none'
                  }}
                  href={`/dashboard/${session?.group?.group_type === "free_experience"
                    ? "community/events"
                    : session?.group?.group_type || "experience"
                    }/${session?.group?.id}/sessions/${session?.id}`}
                >
                  {isExpiredSession && !sessionRecordingUrl && sessionWillBeRecorded ? "Subir" : "Editar"}
                  <Icon icon={arroFowardUpRight} size="12px" color="white" />
                </EditCta>

                {!isMobile && (
                  <>
                    <ShareCta
                      type="button"
                      onClick={() => {
                        const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.id}/s/${session?.id}`;
                        navigator.clipboard.writeText(link);

                        dispatch(showModal('modal-share-session-link'));
                      }}
                    >
                      <Icon icon={shareIcon} size="18px" color={theme.colors.Primary} />
                      Compartir
                    </ShareCta>

                    <DeleteCta
                      type="button"
                      onClick={() => {
                        dispatch(showModal("modal-delete-session"));
                      }}
                    >
                      <Icon icon={trashIcon} size="18px" color={theme.colors.Primary} />
                      Eliminar
                    </DeleteCta>
                  </>
                )}

                {!isDesktop && (
                  <MenuCta
                    type="button"
                    style={{ display: "flex" }}
                    onClick={() =>
                      dispatch(showModal("swipe-session-preview-owner-menu"))
                    }
                  >
                    <Icon icon={dotsVerticalIcon} size="24px" color={theme.colors.Primary} />
                  </MenuCta>
                )}
              </Actions>
            </OwnerActions>

            {notifySession && !isExpiredSession && (
              <>
                <div style={{ height: 10 }} />

                <Reminder>
                  <Icon icon={pencilIcon} size="18px" color={theme.colors.Black} />
                  {notificationText}
                </Reminder>
              </>
            )}

            <div style={{ height: 14 }} />
          </>
        }
      </div>

      <div style={{ padding: isMobile ? '0px 22px' : '0px' }}>
        {isMobile ? (
          <>
            <div style={{ height: 22 }} />
            <Title>{session?.title}</Title>
            <div style={{ height: 20 }} />
          </>
        ) : (
          <div style={{ height: 46 }} />
        )}

        <Description>
          {!jsonStr && !!textStr && textStr}

          {!!jsonStr && (
            <RemirrorRenderer
              json={jsonStr}
              typeMap={typeMap}
              markMap={markMap}
            />
          )}

          {!jsonStr && !textStr && ""}
        </Description>

        {isMobile ? (
          <div style={{ height: 25 }} />
        ) : (
          <div style={{ height: 38 }} />
        )}

        <BigText>Acerca del encuentro</BigText>

        {isMobile ? (
          <div style={{ height: 14 }} />
        ) : (
          <div style={{ height: 17 }} />
        )}

        <Line />

        {isMobile ? (
          <div style={{ height: 26 }} />
        ) : (
          <div style={{ height: 36 }} />
        )}

        <Center horizontal={isMobile ? "left" : 'space'} vertical={isMobile ? "top" : 'top'} column={isMobile} gap={isMobile ? 26 : 0}>
          <Center horizontal="center" vertical="center" gap={16} style={{ flexShrink: 0, width: 'auto' }}>
            <Avatar
              src={expert.avatar ? expert.avatar : userAvatar}
              alt={expert.name}
              width="50px"
              height="50px"
            />

            <Center horizontal="center" vertical="top" column gap={2}>
              <SmallText>Organiza el encuentro</SmallText>
              <BigText>{expert.name}</BigText>
            </Center>
          </Center>

          <SessionDetail horizontal="left" vertical="center" gap={16}>
            <Icon icon={calendarIcon} size="24px" color={theme.colors.Primary} />
            <Center horizontal="left" vertical="top" column >
              <SmallText>Día del encuentro</SmallText>
              <BigText>{date?.day?.name?.large}, {date?.day?.number} de {date?.month?.name?.large}</BigText>
            </Center>
          </SessionDetail>

          <SessionDetail horizontal="left" vertical="center" gap={16}>
            <Icon icon={clockIcon} size="24px" color={theme.colors.Primary} />

            <Center horizontal="left" vertical="top" column >
              <SmallText>Horario</SmallText>
              <BigText>{startHour} - {endHour}</BigText>
            </Center>
          </SessionDetail>
        </Center>
      </div>

      {isOwnSession(session, user?.expert?.id) && (
        <Swipe name="swipe-session-preview-owner-menu">
          <SwipeContent>
            <Button
              options={{
                type: "link",
                size: "lg",
                skin: "purple",
                block: true,
              }}
              style={{ height: "40px", gap: 10, textDecoration: "none" }}
              onClick={() => {
                const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.id}/s/${session?.id}`;
                navigator.clipboard.writeText(link);
                //dispatch(showModal(modal));
              }}
            >
              <Icon icon={shareIcon} size="18px" color={theme.colors.Primary} />
              Compartir
            </Button>

            <Button
              options={{
                type: "link",
                size: "lg",
                skin: "purple",
                block: true,
              }}
              style={{ height: "40px", gap: 10, textDecoration: "none" }}
              onClick={() => {
                dispatch(showModal("modal-delete-session"));
              }}
            >
              <Icon icon={trashIcon} size="18px" color={theme.colors.Primary} />
              Eliminar
            </Button>
          </SwipeContent>
        </Swipe>
      )}
    </Row>
  )
}

const state = ({ userStore, sessionStore, groupStore }) => {
  const { isLoggedIn } = userStore;
  const { data: user, states: userStates } =
    userStore.information;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: userSubscriptions, states: userSubscriptionsStates } =
    userStore.subscriptions;
  const { data: session, states: sessionStates } = sessionStore.one;
  return {
    user,
    userStates,
    userSubscriptions,
    userSubscriptionsStates,
    session,
    sessionStates,
    group,
    groupStates,
    isLoggedIn,
  };
};

export default connect(state)(Component);