import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import constants from "../../../../../../constants";
import { Group, SessionInDto, User } from "../../../../../../types";
import dateHandlder from "../../../../../../helpers/dates-hanlder";
import {
  Backdrop, Content, Avatar, H3, H2, Link, Hello, ChangeAccountLink, ChangeAccount, AccountSignIn, SignInLink
} from './styles'
import warningIcon from "../../../../../../assets/icons/warning.svg";
import { Center } from "../../../../../../components/StyledComponents";
import Icon from "../../../../../../components/Icon";
import { signOut } from "../../../../../../store/actions/auth";

interface ComponentProps {
  userInformation: User
  userSubscriptions
  isLoggedIn: Boolean;
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  session: SessionInDto
  sessionStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  currentPage: string
}

const Component = ({
  group,
  groupStates,
  session,
  sessionStates,
  userInformation,
  userSubscriptions,
  isLoggedIn,
  currentPage
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [suscriptionEnded, setSuscriptionEnded] = useState<boolean>();

  const isOwnGroup = useMemo(() => {
    const expertId = group?.expert?.id || session?.group?.expert?.id;
    return expertId === userInformation?.expert?.id;
  }, [group, session, userInformation]);

  const isUserAuthenticated = useMemo(() => {
    return isLoggedIn || session?.group?.group_type === 'free_experience'
  }, [isLoggedIn, session])

  const isCommunitysession = useMemo(() => {
    return session?.group?.group_type === "community";
  }, [session])

  useEffect(() => {
    if (!!session?.group?.registration_closing_date) {
      setSuscriptionEnded(
        dateHandlder.isExpired(session?.group?.registration_closing_date)
      );
    }
  }, [session]);

  if (session?.can_access_to_full_session || isCommunitysession || isOwnGroup) {
    return <></>
  }

  return (
    <Backdrop>
      <Content>
        {isUserAuthenticated ? (<div style={{ height: 40 }} />) : (<div style={{ height: 75 }} />)}

        {isUserAuthenticated && (
          <>
            <Hello>
              Hola! 👋​ {userInformation?.email}
            </Hello>

            <div style={{ height: 15 }} />
          </>
        )}

        <H3>
          {session?.group?.title}
        </H3>

        <div style={{ height: 15 }} />

        <H2>
          Este evento es para<br />suscriptores pagos
        </H2>

        <div style={{ height: 25 }} />

        <Link
          href={`/group/${session?.group?.slug}?scrollTo=price`}
          options={{
            type: "filled",
            size: "lg",
            skin: "white",
            block: true,
          }}
          target="_self"
        >
          {session?.group?.group_type === "membership" && (
            <>{suscriptionEnded ? "Suscripción finalizada" : `Suscribirme`}</>
          )}

          {session?.group?.group_type !== "membership" && (
            <>{suscriptionEnded ? "Inscripción finalizada" : `Inscribirme`}</>
          )}
        </Link>

        <div style={{ height: 25 }} />


        {isUserAuthenticated ? (
          <ChangeAccount>
            <Center horizontal="left" vertical="center" style={{ maxWidth: 270 }} gap={8}>
              <Icon icon={warningIcon} color="white" size="24px" />
              <div style={{ textAlign: 'left', fontSize: 12 }}>
                Si ya estas suscripto, es posible que
                hayas efectuado el pago con otro email
                o que tu suscripción se haya cancelado
              </div>
            </Center>

            <div style={{ height: 2 }} />

            <ChangeAccountLink
              onClick={() => dispatch(signOut())}
              type="button"
              options={{
                type: "outline",
                size: "lg",
                skin: "purple",
              }}
            >
              Cambiar de cuenta
            </ChangeAccountLink>
          </ChangeAccount>
        ) : (
          <AccountSignIn>
            ¿Ya te suscribiste?
            <SignInLink
              href={constants.urls.subscriber.signin.url}
              options={{
                type: "outline",
                size: "lg",
                skin: "purple",
              }}
              target="_self"
            >
              Iniciar Sesión
            </SignInLink>
          </AccountSignIn>
        )}

        {isUserAuthenticated ? (<div style={{ height: 35 }} />) : (<div style={{ height: 75 }} />)}
      </Content>
    </Backdrop>
  );
};

const state = ({ userStore, sessionStore, groupStore, pageStore }) => {
  const { data: session, states: sessionStates } = sessionStore.one;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: userInformation } = userStore.information;
  const { data: userSubscriptions } = userStore.subscriptions;
  const { isLoggedIn } = userStore.isLoggedIn;

  return {
    group,
    groupStates,
    session,
    sessionStates,
    userInformation,
    userSubscriptions,
    isLoggedIn,
    currentPage: pageStore?.name,
  };
};

export default connect(state)(Component);