import styled from "styled-components";

export const Title = styled.div`
  font-weight: 600;
  font-size: 25px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 30px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 36px;
  }
`;

export const Information = styled.div`
  text-align: center;
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 19px;
`;
