import { useEffect, useState } from "react";
import Icon from "../Icon";
import { useWindowSize } from "../../hooks/useWindowSize";
import leftArrow from "./../../assets/icons/chevron-prev.svg";
import rightArrow from "./../../assets/icons/chevron-next.svg";

import {
  PaginationComponent,
  PagesList,
  PageLink,
  PrevPage,
  NextPage,
  Etc,
} from "./styles";

const createPageArray = (currentPage, totalPages, isTabletPortrait) => {
  const ARRAY_MAX_LENGTH = !isTabletPortrait ? 3 : 9;
  const MIDDLE_POSITION = Math.floor(ARRAY_MAX_LENGTH / 2);

  if (!currentPage) return [];

  let pages = [currentPage];

  while (pages[0] > 1 && pages.length < ARRAY_MAX_LENGTH) {
    pages.unshift(pages[0] - 1);
  }

  let nextPagesCount = 0;

  while (
    pages[pages.length - 1] < totalPages &&
    (pages.length < ARRAY_MAX_LENGTH || nextPagesCount < MIDDLE_POSITION)
  ) {
    pages.push(pages[pages.length - 1] + 1);
    nextPagesCount += 1;

    if (pages.length > ARRAY_MAX_LENGTH) {
      pages = pages.splice(1, ARRAY_MAX_LENGTH);
    }
  }

  return pages;
};

const Component = ({ currentPage, lastPage, onClick, states, ...rest }) => {
  currentPage = parseInt(currentPage);
  const { isTabletPortrait } = useWindowSize();
  const [pages, setPages] = useState<number[]>([]);

  useEffect(() => {
    setPages(createPageArray(currentPage, lastPage, isTabletPortrait));
  }, [isTabletPortrait, currentPage, lastPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <PaginationComponent {...rest}>
      <PrevPage
        disabled={currentPage <= 1 || states.loading}
        onClick={() => onClick(currentPage - 1)}
      >
        <Icon icon={leftArrow} size="10px" color="#898994" />
      </PrevPage>

      {pages.length && pages[0] > 1 && (
        <>
          <PagesList>
            <PageLink onClick={() => onClick(1)}>1</PageLink>
          </PagesList>

          <Etc>...</Etc>
        </>
      )}

      <PagesList>
        {pages.map((page) => (
          <PageLink
            key={page}
            disabled={states.loading}
            isActive={page === currentPage}
            onClick={() => page !== currentPage && onClick(page)}
          >
            <span>{page}</span>
          </PageLink>
        ))}
      </PagesList>

      {pages.length && pages[pages.length - 1] < lastPage && (
        <>
          <Etc>...</Etc>

          <PagesList>
            <PageLink onClick={() => onClick(lastPage)}>{lastPage}</PageLink>
          </PagesList>
        </>
      )}

      <NextPage
        disabled={currentPage >= lastPage || states.loading}
        onClick={() => onClick(currentPage + 1)}
      >
        <Icon icon={rightArrow} size="10px" color="#898994" />
      </NextPage>
    </PaginationComponent>
  );
};

export default Component;
