import styled from 'styled-components'

export const Button = styled('button')`
  flex-shrink: 0;
  border: 1px solid ${({ theme }) => theme.colors.Primary};
  background: white;
  cursor: pointer;
  border: none;
  // border-radius: 50%;
  color: ${({ theme }) => theme.colors.Primary};
  // padding: 0px;
  // margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in-out;

  // &:hover {        
  //   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // }
`

export const Title = styled('h3')`
  margin: 0px;
`

export const Paragraph = styled('p')`
  margin: 0px;
`
