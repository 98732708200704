import { put, call, all, takeLatest, select } from "redux-saga/effects";
import services from "../../services";

import {
  NEW_CATEGORY,
  NEW_CATEGORY_SUCCESS,
  NEW_CATEGORY_ERROR,
} from "../types/category/new-category";

import {
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_ERROR,
} from "../types/category/update-category";

import {
  GET_ALL_CATEGORIES,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_ERROR,
} from "../types/category/get-all-categories";

import {
  GET_CATEGORY,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERROR,
} from "../types/category/get-category";

import {
  DELETE_CATEGORY,
  DELETE_CATEGORY_ERROR,
  DELETE_CATEGORY_SUCCESS
} from "../types/category/delete-category";

import {
  REORDER_CATEGORIES,
  REORDER_CATEGORIES_ERROR,
  REORDER_CATEGORIES_SUCCESS
} from "../types/category/reorder-categories";

import {
  REORDER_CATEGORY_POSTS,
  REORDER_CATEGORY_POSTS_ERROR,
  REORDER_CATEGORY_POSTS_SUCCESS
} from "../types/category/reorder-category-posts";

const getGroupInformation = ({ groupStore }) => groupStore.group.data;

function* newCategory({ payload }): any {
  try {
    const { response, error } = yield call(services.category.newCategory, payload);
    if (error) {
      yield put({ type: NEW_CATEGORY_ERROR, payload: error });
    } else {
      const group = yield select(getGroupInformation);
      yield all([
        put({ type: NEW_CATEGORY_SUCCESS, payload: response }),
        put({ type: GET_ALL_CATEGORIES, payload: { group_id: group?.id, with_posts: true, only_showable_posts: false} }),
      ]);
    }
  } catch (error) {
    yield put({ type: NEW_CATEGORY_ERROR, payload: 'Error al crear la categorías' });
  }
}

function* updateCategory({ payload }): any {
  try {
    const { response, error } = yield call(services.category.updateCategory, payload);
    if (error) {
      yield put({ type: UPDATE_CATEGORY_ERROR, payload: error });
    } else {
      const group = yield select(getGroupInformation);
      yield all([
        put({ type: UPDATE_CATEGORY_SUCCESS, payload: response }),
        put({
          type: GET_ALL_CATEGORIES, payload: {
            group_id: group?.id, with_posts: true,
            only_showable_posts: false,
            posts_per_category: 6,
            sort_by: 'category_order:asc',
          }
        }),
      ]);
    }
  } catch (error) {
    yield put({ type: UPDATE_CATEGORY_ERROR, payload: 'Error al actualizar la categoría' });
  }
}

function* deleteCategory({ payload }): any {
  try {
    const { response, error } = yield call(services.category.delete, payload);
    if (error) {
      yield put({ type: DELETE_CATEGORY_ERROR, payload: error });
    } else {
      const group = yield select(getGroupInformation);
      yield all([
        put({ type: DELETE_CATEGORY_SUCCESS, payload: response }),
        put({ type: GET_ALL_CATEGORIES, payload: { group_id: group?.id, with_posts: true, only_showable_posts: false } }),
      ]);
    }
  } catch (error) {
    yield put({ type: DELETE_CATEGORY_ERROR, payload: 'Error al eliminar la categoría' });
  }
}

function* getAllCategories({ payload }): any {
  try {
    const { response, error } = yield call(services.category.getAll, payload);
    if (error) {
      yield put({ type: GET_ALL_CATEGORIES_ERROR, payload: error });
    } else {
      yield put({ type: GET_ALL_CATEGORIES_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({ type: GET_ALL_CATEGORIES_ERROR, payload: 'Error al obtener categorías' });
  }
}

function* getCategory({ payload }): any {
  try {
    const { response, error } = yield call(services.category.getOne, payload);
    if (error) {
      yield put({ type: GET_CATEGORY_ERROR, payload: error });
    } else {
      yield put({ type: GET_CATEGORY_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({ type: GET_CATEGORY_ERROR, payload: 'Error al obtener categoría' });
  }
}

function* reorderCategories({ payload }): any {
  try {
    const { response, error } = yield call(services.category.reorder, payload);
    if (error) {
      yield put({ type: REORDER_CATEGORIES_ERROR, payload: error });
    } else {
      const group = yield select(getGroupInformation);
      yield all([
        put({ type: REORDER_CATEGORIES_SUCCESS, payload: response }),
        put({
          type: GET_ALL_CATEGORIES, payload: {
            group_id: group?.id, with_posts: true,
            only_showable_posts: false,
            posts_per_category: 6,
            sort_by: 'category_order:asc',
          }
        }),
      ]);
    }

  } catch (error) {
    yield put({ type: REORDER_CATEGORIES_ERROR, payload: 'Error al reordenar las categorías' });
  }
}

function* reorderCategoryPosts({ payload }): any {
  try {
    const { response, error } = yield call(services.category.reorderPosts, payload);
    if (error) {
      yield put({ type: REORDER_CATEGORY_POSTS_ERROR, payload: error });
    } else {
      const group = yield select(getGroupInformation);
      yield all([
        put({ type: REORDER_CATEGORY_POSTS_SUCCESS, payload: response }),
        put({
          type: GET_ALL_CATEGORIES, payload: {
            group_id: group?.id,
            with_posts: true,
            only_showable_posts: false,
            posts_per_category: 6,
            sort_by: 'category_order:asc',
          }
        }),
      ]);
    }

  } catch (error) {
    yield put({ type: REORDER_CATEGORY_POSTS_ERROR, payload: 'Error al reordenar las categorías' });
  }
}
/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(NEW_CATEGORY, newCategory);
  yield takeLatest<any>(UPDATE_CATEGORY, updateCategory);
  yield takeLatest<any>(GET_ALL_CATEGORIES, getAllCategories);
  yield takeLatest<any>(GET_CATEGORY, getCategory);
  yield takeLatest<any>(REORDER_CATEGORIES, reorderCategories);
  yield takeLatest<any>(REORDER_CATEGORY_POSTS, reorderCategoryPosts);
  yield takeLatest<any>(DELETE_CATEGORY, deleteCategory);
}
