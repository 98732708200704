import styled from "styled-components";

export const CardsGrid = styled("div")`
  display: grid;
  gap: 16px;
  grid-auto-rows: 237px;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));

  @media(min-width: 768px){
    grid-auto-rows: 237px;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  }

  @media(min-width: 1200px){
    grid-auto-rows: 237px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
`;

export const EmptyStateCard = styled("div")`
  text-decoration: none;
  color: inherit;
  display: block;
  background-color: #e0e4ea;
  overflow: hidden;
  border-radius: 16px;
  min-height: 237px;
  position: relative;
  margin-bottom: 16px;
`;
