import * as Yup from "yup";

export const schema = Yup.object().shape({
  session_cancelled: Yup.array().of(Yup.string()).nullable(),
  new_post: Yup.array().of(Yup.string()).nullable(),
  membership_new_subscription: Yup.array().of(Yup.string()).nullable(),
  experience_free_subscription: Yup.array().of(Yup.string()).nullable(),
  uploaded_recording: Yup.array().of(Yup.string()).nullable(),
  live_session: Yup.array().of(Yup.string()).nullable(),
  new_session: Yup.array().of(Yup.string()).nullable(),
  update_session: Yup.array().of(Yup.string()).nullable(),
  membership_subscriptions_cancellation: Yup.array()
    .of(Yup.string())
    .nullable(),
  session_reminder: Yup.array().of(Yup.string()).nullable(),
});

interface FormValues {
  session_cancelled: string[];
  new_post: string[];
  membership_new_subscription: string[];
  experience_free_subscription: string[];
  uploaded_recording: string[];
  live_session: string[];
  new_session: string[];
  update_session: string[];
  membership_subscriptions_cancellation: string[];
  session_reminder: string[];
}

export const initialValues: FormValues = {
  session_cancelled: [],
  new_post: [],
  membership_new_subscription: [],
  experience_free_subscription: [],
  uploaded_recording: [],
  live_session: [],
  new_session: [],
  update_session: [],
  membership_subscriptions_cancellation: [],
  session_reminder: [],
};
