import styled from "styled-components";
import Title from "../../../components/Title";
import UiImage from "../../../components/Image";

export const Image = styled(UiImage)`
  max-width: 159px;
  margin-bottom: 20px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    max-width: 335px;
    margin-bottom: 50px;
  }
`;

export const FormTitle = styled(Title)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  & > span {
    color: ${(props) => props.theme.colors.Primary};
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 36px;
    line-height: 44px;
  }
`;

export const FormSubTitle = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-top: 0px;
  margin-bottom: 36px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const Explanation = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 36px;

  & > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
`;
