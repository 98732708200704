import { createRef, useMemo, useState } from "react";
import { connect } from "react-redux";
import GoToSubscribeSection from '../GoToSubscribeButton'
import Description from "../Description";
import { Page } from "../../../components/styles";
import SubscriptionCard from "../SubscriptionCard";
import Benefits from "../Benefits";
import Content from "../Content";
import Testimonials from "../Testimonials";
import ItsForMe from "../ItsForMe";
import HowItsWorks from "../HowItsWorks";
import Faqs from "../Faqs";
import AskForUserInformation from '../../../../components/AskForUserInformation'
import RegistrationClosingDate from '../RegistrationClosingDate'
import { Center } from "../../../../../components/StyledComponents";
import { Group } from "../../../../../types";
import Grid from "../../../../../components/Grid";
import SubscriptionCta from "../SubscriptionCta";
import { Separator } from "../../../../../components/StyledComponents";
import { isSubscribed } from "../../../../../helpers/isSubscribed";
import userAvatar from "../../../../../assets/avatar.svg";
import { getFreeSubscriptions } from "../../../../../helpers/getFreeSubscriptions";

interface ComponentProps {
  match;
  subscriptions;
  group: Group;
  isLoggedIn: boolean
}

const Component = ({
  subscriptions,
  group,
  isLoggedIn
}: ComponentProps) => {
  const [hideStickySubscriptionCard, setHideStickySubscriptionCard] = useState<{
    aboveFold: boolean;
    belowFold: boolean;
  }>();

  const scrollToRef = createRef<HTMLDivElement>()

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(subscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [subscriptions, group, isLoggedIn])

  return (
    <Page>
      <SubscriptionCta />

      <Grid.Container>
        <Grid.Row>
          <Grid.Col lg={10} offset={{ lg: 1 }}>
            <Description />

            <Separator height={{ desktop: "28px", mobile: "28px" }} />

            <Center horizontal="center" vertical="center" column>
              <GoToSubscribeSection scrollToRef={scrollToRef} style={{ width: "260px" }} />
              <Separator height={{ desktop: "32px", mobile: "16px" }} />
              <RegistrationClosingDate />
            </Center>

            <Separator height={{ desktop: "68px", mobile: "34px" }} />

            <Benefits />

            <Separator height={{ desktop: "90px", mobile: "90px" }} />

            {!isUserSubscribed && (
              <div ref={scrollToRef}>
                <SubscriptionCard callback={setHideStickySubscriptionCard} />
              </div>
            )}

            <Content />

            {!!group?.reviews && group?.reviews?.length > 0 && (
              <>
                <Separator height={{ desktop: "120px", mobile: "90px" }} />
                <Testimonials />
              </>
            )}

            {!!group?.this_is_for_me && (
              <>
                <Separator height={{ desktop: "120px", mobile: "90px" }} />
                <ItsForMe />
              </>
            )}

            <Separator height={{ desktop: "120px", mobile: "90px" }} />
            <HowItsWorks />
            <Separator height={{ desktop: "120px", mobile: "90px" }} />
            <Faqs />
            <Separator height={{ desktop: "20px", mobilep: "20px" }} />
            <SubscriptionCard
              sticky
              belowFold={hideStickySubscriptionCard?.belowFold}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <AskForUserInformation
        avatar={
          group?.expert?.profile_pic?.url || userAvatar
        }
        ctaText={"Inscribirme"}
        showClose={true}
        title={group?.group_name}
        group={group?.group_name}
        username={group?.expert?.username}
        idGroup={group?.id}
        handleSubmit={(data) => {
          window.location.href = `/checkout/free-subscription/${data.idGroup}`
        }}
      />
    </Page>
  );
};

const state = ({ userStore, groupStore }) => {
  const { isLoggedIn } = userStore;
  const { data: group } = groupStore.group;
  const { data: subscriptions } =
    userStore.subscriptions;

  return {
    subscriptions,
    group,
    isLoggedIn,
  };
};

export default connect(state)(Component);
