import { Testimonial, Title, Card } from "./styles";
import Grid from "../../../../components/Grid";
import Row from "../../../../components/Grid/Row";
import Col from "../../../../components/Grid/Col";
import TestimonialsCarousel from "../../components/TestimonialsCarousel";

const Component = () => {
  return (
    <Testimonial>
      <Grid.Container>
        <Row>
          <Col offset={{ lg: 1 }} lg={10}>
            <Title>
              Más de 1000 referentes del mundo del bienestar confían en nuestra
              plataforma.
            </Title>
          </Col>
        </Row>
      </Grid.Container>
      <TestimonialsCarousel />
    </Testimonial>
  );
};

export default Component;
