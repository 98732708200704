import {
    initialState,
    Actions,
    State,
    DELETE_DISCOUNT,
    DELETE_DISCOUNT_SUCCESS,
    DELETE_DISCOUNT_ERROR,
} from "../../types/discount/delete-discount";

const reducers = (state = initialState, action: Actions): State => {
    switch (action.type) {
        case DELETE_DISCOUNT:
            return {
                ...state,
                states: {
                    loading: true,
                    error: false,
                    success: false,
                },
            };
        case DELETE_DISCOUNT_SUCCESS:
            return {
                ...state,
                states: {
                    loading: false,
                    error: false,
                    success: true,
                },
            };
        case DELETE_DISCOUNT_ERROR:
            return {
                ...state,
                states: {
                    loading: false,
                    error: action.payload,
                    success: false,
                },
            };
        default:
            return state;
    }
};

export default reducers;
