import { connect, useDispatch } from "react-redux";
import { SectionTitle } from "../../components/Statistics/styles";
import { MoreContent, InLine, HorizontalScrollWrapper, HorizontalScroll } from "./styles";
import Grid from "../../../../components/Grid";
import PostCard from "../../Posts/components/CardArticle";
import PostCardSkeleton from "../../Posts/components/PostCardSkeleton";
import slugify from "slugify";
import fileIcon from "../../../../assets/icons/file.svg";
import EmptyCard from "../../components/EmptyCard";
import { Post } from "../../../../types/post";
import { useEffect, useState } from "react";
import { getLastPosts, getPosts, resetGetFeaturedPosts, resetGetPosts, resetGetScheduledPosts } from "../../../../store/actions/post";
import { Group } from "../../../../types";
import ModalConfirmDelete from "../../Posts/components/ModalConfirmDelete";

interface ComponentProps {
  groupInformation: Group;
  itemsPerRow?: number;
  lastPosts: { pagination: {}, data: Post[] };
  lastPostsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  showMoreLink?: boolean;
}

const Component = ({
  groupInformation,
  lastPosts,
  lastPostsStates,
  showMoreLink = true,
}: ComponentProps) => {
  const [idArticleToDelete, setIdArticleToDelete] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!groupInformation) {
      dispatch(getLastPosts({ pagination: { per_parge: 3 }, filters: { group_id: groupInformation.id } }));
    }
  }, [groupInformation]);

  useEffect(() => {
    return () => {
      dispatch(resetGetPosts())
      dispatch(resetGetScheduledPosts())
      dispatch(resetGetFeaturedPosts())
    }
  }, [])


  return (
    <>
      <Grid.Row>
        <Grid.Col>
          <InLine>
            <SectionTitle>Últimos contenidos</SectionTitle>
            {showMoreLink && (
              <MoreContent
                to={`/dashboard/${groupInformation?.group_type === "free_experience"
                  ? "community/events/" + groupInformation?.id + "/posts"
                  : groupInformation?.group_type +
                  "/" +
                  groupInformation?.id +
                  "/posts/"
                  }`}
              >
                Ver más
              </MoreContent>
            )}
          </InLine>
        </Grid.Col>
      </Grid.Row>

      <div style={{ height: 16 }} />

      <Grid.Row>
        <Grid.Col>
          <HorizontalScrollWrapper>
            <HorizontalScroll>
              {lastPostsStates.loading && (
                <>
                  <PostCardSkeleton />
                  <PostCardSkeleton />
                  <PostCardSkeleton />
                </>
              )}

              {(!lastPosts?.data && !lastPostsStates.loading) ||
                ((lastPosts?.data?.length <= 0 || lastPostsStates.error) &&
                  !lastPostsStates.loading) ? (
                <EmptyCard
                  title="No tienes artículos creados"
                  text="Redacta artículos, adjunta pdf, imágenes, grabaciones y videos"
                  icon={fileIcon}
                  link={{
                    label: "Crear contenido",
                    url:
                      groupInformation?.group_type === "free_experience"
                        ? `/dashboard/community/events/${groupInformation?.id}/posts/new`
                        : `/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/posts/new`,
                  }}
                />
              ) : (
                <>
                  {lastPosts?.data?.map((post, index) => (
                    <PostCard
                      key={slugify(post.title + index, { lower: true })}
                      style={{ marginBottom: "16px" }}
                      post={post}
                      onDelete={(idPost) => setIdArticleToDelete(idPost)}
                      isMine
                      groupInformation={groupInformation}
                    />
                  ))}
                </>
              )}
            </HorizontalScroll>
          </HorizontalScrollWrapper>
        </Grid.Col>
      </Grid.Row>

      <ModalConfirmDelete idArticle={idArticleToDelete} />
    </>
  );
};

const state = ({ postStore }) => {
  const { data: lastPosts, states: lastPostsStates } = postStore.lastPosts;

  return {
    lastPosts,
    lastPostsStates,
  };
};

export default connect(state)(Component);
