import moment from "moment-timezone";
import { isFutureDate } from "../../../../helpers/dates-hanlder";
import { Label, StepNumber } from "../styles";
import TipCta from "../TipCta";
import Input from "../../../../components/Form/Input";
import { Text, DateInput } from "./styles";
import { useEffect, useState } from "react";

interface ComponentProps {
  onFocus;
  onBlur?;
  onChange?;
  register;
  fieldNumber;
  errors;
  labelForwardRef;
}

const Component = ({
  onFocus,
  onBlur,
  onChange,
  register,
  fieldNumber,
  errors,
  labelForwardRef,
  ...rest
}: ComponentProps) => {
  const name = "registration_closing_date";

  const handleFocus = () => {
    onFocus(fieldNumber, name);
  };

  return (
    <>
      <Label>
        <Text>
          <StepNumber>{fieldNumber - 1}.</StepNumber>
          <span>Cierre de inscripciones</span>
        </Text>

        {/*<TipCta
          htmlFor={name}
          forwardRef={labelForwardRef}
          onClick={handleFocus}
        />*/}
      </Label>

      <DateInput>
        <Input
          name={name}
          type="datetime-local"
          onFocus={handleFocus}
          onBlur={onBlur}
          register={register}
          rules={{
            required: true,
            //validate: isFutureDate,
          }}
          errors={errors}
          {...rest}
        />
      </DateInput>
    </>
  );
};

export default Component;
