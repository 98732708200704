import styled from "styled-components";
import AcordeonUi from "../../components/Acordeon";

export const Explanation = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 150px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.lgMin}) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

export const Acordeon = styled(AcordeonUi)`
  margin-bottom: 40px;
`;

export const Guide = styled.p`
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.PurpleVeryLight};
  text-align: start;
  margin: 0px 0px 10px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.lgMin}) {
    margin: 0px 0px 20px 0px;
  }
`;

export const Title = styled.h2`
  font-size: 36px;
  line-height: 46px;
  margin: 0px 0px 60px;
  font-weight: 700;
  text-align: start;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    margin: 0px 0px 60px;
    font-size: 50px;
    line-height: 72px;
  }
`;
