import styled from "styled-components";

export const ModuleIcon = styled("div") <{ isActiveModule, isSelectedModule }>`
  background-color: ${({ isSelectedModule }) => (isSelectedModule ? "#2869C5" : "#6DA5F3")};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 60px;
  width: 40px;
  color: white;
  opacity: ${({ isSelectedModule, isActiveModule }) => (isActiveModule || isSelectedModule ? "1" : ".5")};
`;
export const ModuleName = styled("div") <{ isActiveModule, isSelectedModule }>`
  position: relative;
  background-color: ${({ isSelectedModule }) => (isSelectedModule ? "#2869C5" : "#6DA5F3")};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 60px;
  width: calc(100% - 80px);
  color: white;
  cursor: pointer;
  opacity: ${({ isSelectedModule, isActiveModule }) => (isActiveModule || isSelectedModule ? "1" : ".5")};
  display: flex;
  text-align: center;
  padding: 3px 16px;
  span {
    display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  }
`;
export const ModuleActions = styled("div")`
  display: flex;
  alig-items: center;
  justify-content: center;
  border-radius: 6px;
  flex-direction: column;
  gap: 4px;
`;
export const Button = styled("button")`
  border: 0px;
  padding: 0px;
  margin: 0px;
  width: 28px;
  height: 28px;
  border-radius: 6px;
  background-color: #dbe9fc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ValidationFlag = styled('div') <{ isValid: boolean }>`
width: 8px;
height: 8px;
border-radius: 100%;
background: red;
position: absolute;
top: 4px;
right: 4px;
`