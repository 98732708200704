export const CHANGE_USER_AVATAR = "CHANGE_USER_AVATAR";
export const CHANGE_USER_AVATAR_SUCCESS = "CHANGE_USER_AVATAR_SUCCESS";
export const CHANGE_USER_AVATAR_ERROR = "CHANGE_USER_AVATAR_ERROR";
export const RESET_CHANGE_USER_AVATAR = "RESET_CHANGE_USER_AVATAR";

export interface ChangeUserAvatar {
  type: typeof CHANGE_USER_AVATAR;
  payload: string;
}

export interface ChangeUserAvatarSuccess {
  type: typeof CHANGE_USER_AVATAR_SUCCESS;
  payload: string;
}

export interface ChangeUserAvatarError {
  type: typeof CHANGE_USER_AVATAR_ERROR;
  payload: boolean | string;
}

export interface ResetChangeUserAvatar {
  type: typeof RESET_CHANGE_USER_AVATAR;
  payload: null
}

export interface State {
  states: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
}

export const initialState: State = {
  states: {
    loading: false,
    error: false,
    success: false,
  },
};

export type Actions =
  | ChangeUserAvatar
  | ChangeUserAvatarSuccess
  | ChangeUserAvatarError
  | ResetChangeUserAvatar;
