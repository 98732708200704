import expert from "./expert";
import group from "./group";
import user from "./user";
import auth from "./auth";
import subscription from "./subscription";
import meet from "./meet";
import currency from "./currency";
import preferences from "./preferences";
import discount from "./discount";
import post from "./post";
import category from "./category";
import community from "./community";
import session from "./session";
import survey from "./survey";
import notification from "./notification";
import modules from "./modules";
import plan from "./plan";
import storage from "./storage";
import reaction from "./reaction";
import invitations from "./invitations"
import welcomeContent from "./welcome-content"

const services = {
  user,
  group,
  expert,
  auth,
  subscription,
  meet,
  currency,
  preferences,
  discount,
  post,
  category,
  community,
  session,
  survey,
  notification,
  modules,
  plan,
  reaction,
  invitations,
  storage,
  welcomeContent
};

export default services;
