import { useEffect, useState } from "react";
import Pagination from "../../../../components/Pagination";
import Helmet from "react-helmet";
import SelectYourCountryModal from "../../components/SelectCountry";
import { Page, PageHeader, Title } from "../../components/styles";
import { ColumnsType } from "rc-table/lib/interface";
import moment from "moment";
import Button from "../../../../components/Button";
import Image from "../../../../components/Image";
import constants from "../../../../constants";
import Grid from "../../../../components/Grid";
import Row from "../../../../components/Grid/Row";
import Col from "../../../../components/Grid/Col";
import Icon from "../../../../components/Icon";
import Table from "./components/Table";
import Filters from "./components/Filters";
import CopyTextToClipboad from "../../../../components/CopyToClipboad";
import Tabs from "../components/Tabs";
import { connect, useDispatch } from "react-redux";
import {
  exportCommunitySubscribers,
  getCommunitySubscribers,
} from "../../../../store/actions/community";
import { Group } from "../../../../types";
import {
  Card,
  Scroll,
  Country,
  MembershipState,
  IconButton,
  IconLink,
} from "./styles";
import { Center } from "../../../../components/StyledComponents";

import SortAscIcon from "../../../../assets/icons/sort_ascending.svg";
import SortDescIcon from "../../../../assets/icons/sort_decending.svg";
import TelegramIcon from "../../../../assets/icons/telegram.svg";
import WhatsappIcon from "../../../../assets/icons/whatsapp.svg";
import EmailIcon from "../../../../assets/icons/mail.svg";
import PreDifusion from "./components/PreDifusion";
import { PaginationInDto } from "../../../../types/pagination";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { dateSpliter } from "../../../../helpers/dates-hanlder";
import { useLayout } from "../../../../providers/LayoutProvider";
import { showModal } from "../../../../store/actions/modal";
import { formatPrice } from "../../../../helpers/price-formatter";

interface ComponentProps {
  groups: Group[];
  community: Group;
  communityStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  communitySubscribers: {
    pagination: PaginationInDto;
    data: DataType[];
  };
  communitySubscribersStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

interface FiltersDto {
  _sort: { [field_name: string]: "ASC" | "DESC" };
  group_id_in: number[];
  group_id_not_in: number[];
  experiences_gt: boolean;
  free_experiences_gt: boolean;
  all_group_types_gt: boolean;
}
interface DataType {
  subscriber_id: number;
  country: string;
  name: string;
  last_name: string;
  currency: string
  email: string;
  total_investment: number;
  free_experiences_count: number;
  experiences_count: number;
  total_subscriptions_count: string;
  recurring_subscription: {
    id: number;
    status: string;
    started_at?: string;
    next_billing_at?: string;
    cancelled_at?: string;
    expire_at?: string
  } | null;
}

export interface Option {
  value: string | number;
  label: string;
  group_type?: string;
}

export interface GroupedOptions {
  label: string;
  options: Option[];
}

export interface Filter {
  condition: string;
  value: string | number | boolean;
}

const conditions = [
  {
    label: "Participó de",
    value: "group_id_in",
  },
  {
    label: "No participó de",
    value: "group_id_not_in",
  },
];

const fixedOptions = [
  {
    label: "Al menos un taller",
    value: "experiences_gt",
  },
  {
    label: "Al menos un evento gratuito",
    value: "free_experiences_gt",
  },
  {
    label: "Al menos una actividad",
    value: "all_group_types_gt",
  },
];

const Component = ({
  groups,
  community,
  communityStates,
  communitySubscribers,
  communitySubscribersStates,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const { isMobile } = useWindowSize();
  const [preDifusionVisible, setPreDifusionVisible] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<Filter[]>([]);
  const [sortBy, setSortBy] = useState<string>();
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const dispatch = useDispatch();

  const getAllAppliedFilters = () => {
    const getGroupsIds = (groupOfIds) => {
      const fixedOptionsValues = [
        "experiences_gt",
        "free_experiences_gt",
        "all_group_types_gt",
      ];

      const isFixedOption = (value) =>
        fixedOptionsValues.includes(value as string);

      return appliedFilters
        ?.filter(
          (appliedFilter) =>
            appliedFilter.condition === groupOfIds &&
            !isFixedOption(appliedFilter.value)
        )
        .map((appliedFilter) => appliedFilter.value);
    };

    const getGtFilters = (filterName) => {
      const gtFilter = appliedFilters?.filter(
        (appliedFilter) => appliedFilter.value === filterName
      );

      if (gtFilter && gtFilter?.length > 0) {
        const gtFilterValue =
          gtFilter[0].condition === "group_id_in" ? "1" : "0";
        return gtFilterValue;
      }

      return undefined;
    };

    //_sort=<<ASC or DESC>>&sort_by=<<field_name>>

    return {
      ...(!!sortBy && { _sort: sortDirection, _sort_by: sortBy }),
      ...(getGroupsIds("group_id_in") && {
        group_id_in: getGroupsIds("group_id_in"),
      }),
      ...(!!getGroupsIds("group_id_not_in") && {
        group_id_not_in: getGroupsIds("group_id_not_in"),
      }),
      ...(getGtFilters("experiences_gt") && {
        experiences_gt: getGtFilters("experiences_gt"),
      }),
      ...(getGtFilters("free_experiences_gt") && {
        free_experiences_gt: getGtFilters("free_experiences_gt"),
      }),
      ...(getGtFilters("all_group_types_gt") && {
        all_group_types_gt: getGtFilters("all_group_types_gt"),
      }),
    };
  };

  const paginate = (page) => {
    if (community?.expert) {
      dispatch(
        getCommunitySubscribers({
          ...getAllAppliedFilters(),
          page,
          id: community?.expert?.id,
        })
      );
    }
  };

  const exportSubscribers = () => {
    dispatch(
      exportCommunitySubscribers({
        ...getAllAppliedFilters(),
      })
    );
  };

  const getLabel = (group) => {
    return (
      <span
        style={{
          display: "flex",
          gap: "5px",
        }}
      >
        {group?.group_name}{" "}
        <span
          style={{
            fontStyle: "italic",
            fontWeight: "normal",
            fontSize: "12px",
            color: "gray",
          }}
        >
          {!!group?.launch_date
            ? "(" +
            dateSpliter(group?.launch_date)?.month?.name?.short +
            " " +
            dateSpliter(group?.launch_date)?.year +
            ")"
            : ""}
        </span>
      </span>
    );
  };

  const Sort = ({ dataIndex, children }) => {
    return (
      <Center horizontal="left" vertical="top">
        <IconButton
          onClick={() => {
            setSortBy(dataIndex);
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
          }}
        >
          {sortBy === dataIndex ? (
            <>
              {sortDirection === "asc" ? (
                <Icon icon={SortAscIcon} size="20px" color="#626768" />
              ) : (
                <Icon icon={SortDescIcon} size="20px" color="#626768" />
              )}
            </>
          ) : (
            <Icon icon={SortAscIcon} size="20px" color="#D0D0D0" />
          )}
        </IconButton>
        {children}
      </Center>
    );
  };

  const Actions = ({ record }) => {
    return (
      <Center horizontal="left" vertical="top" gap={8}>
        <IconLink
          {...(record?.phone_number && {
            href: `https://t.me/${record?.phone_number}`,
            target: "_blank"
          })}
        >
          <Icon
            icon={TelegramIcon}
            size="18px"
            color={record?.phone_number ? "#808080" : "#DADADA"}
          />
        </IconLink>
        <IconLink
          {...(record?.phone_number && {
            href: `https://wa.me/${record?.phone_number}`,
            target: "_blank"
          })}
        >
          <Icon
            icon={WhatsappIcon}
            size="18px"
            color={record?.phone_number ? "#808080" : "#DADADA"}
          />
        </IconLink>
        <CopyTextToClipboad
          copy={record?.email}
          successStyle={{
            position: "absolute",
            left: "1%",
            top: "50%",
            transform: "translate(0%, -50%)",
          }}
        >
          <Icon icon={EmailIcon} size="20px" color="#808080" />
        </CopyTextToClipboad>
      </Center>
    );
  };

  const columns: ColumnsType<DataType> = [
    {
      title: <Sort dataIndex="country">País</Sort>,
      width: 90,
      fixed: false,
      ellipsis: true,
      render: (value, record, index) => {
        let country = constants.countries.filter(
          (c) => c.code === record?.country
        )[0];

        if (!country)
          return "-"

        return (
          <Country>
            <Image
              src={country?.flag}
              width="22px"
              height="16px"
              alt={country?.code}
              style={{ borderRadius: "3px" }}
            />
            {country?.code}
          </Country>
        );
      },
      dataIndex: "country",
      key: "country",
    },
    {
      title: <Sort dataIndex="name">Usuario</Sort>,
      width: 220,
      fixed: isMobile ? false : "left",
      ellipsis: true,
      render: (value, record, index) => {
        return (
          <>
            <span>{record.name} {record.last_name}</span>
            <br />
            <span>{record.email}</span>
          </>
        )
      },
      // record?.name || record?.last_name
      //   ? `${record?.name || ""} ${record?.last_name || ""}`
      //   : record?.email,
      key: "name",
    },
    {
      title: <Sort dataIndex="free_experiences_count">Eventos gratuitos asistidos</Sort>,
      width: 130,
      ellipsis: true,
      dataIndex: "free_experiences_count",
      key: "free_experiences_count",
    },
    {
      title: <Sort dataIndex="experiences_count">Talleres comprados</Sort>,
      width: 105,
      ellipsis: true,
      dataIndex: "experiences_count",
      key: "experiences_count",
    },
    {
      title: (<>Suscripto <br /> a la membresia </>),
      width: 145,
      ellipsis: true,
      render: (value, record, index) => {
        const memership = record?.recurring_subscription;
        const date = {
          active: memership?.started_at
            ? `Desde el ${moment
              .unix(Number(memership?.started_at))
              .format("DD/MM/YY")}`
            : "",
          paid: memership?.started_at
            ? `Pago Único desde el ${moment
              .unix(Number(memership?.started_at))
              .format("DD/MM/YY")}`
            : "",
          paused: memership?.started_at
            ? `Pausada desde ${moment
              .unix(Number(memership?.started_at))
              .format("DD/MM/YY")}`
            : "",
          non_renewing: `Vence el ${moment(memership?.next_billing_at).format(
            "DD/MM/YY"
          )}`,
          refunded: memership?.started_at
            ? `Reintegrado el ${moment
              .unix(Number(memership?.started_at))
              .format("DD/MM/YY")}`
            : "",
          cancelled: memership?.cancelled_at
            ? `De baja el ${moment
              .unix(Number(memership?.cancelled_at))
              .format("DD/MM/YY")}`
            : "",
          expired: memership?.expire_at
            ? `Invitado Expirado el ${moment
              .unix(Number(memership?.expire_at))
              .format("DD/MM/YY")}`
            : "",
          pending_invite: `Invitado Pendiente`,
          defaulted: memership?.cancelled_at
            ? `Baja forzada desde el ${moment
              .unix(Number(memership?.cancelled_at))
              .format("DD/MM/YY")}`
            : "",
        };

        return (
          <MembershipState
            className={!!memership ? memership?.status : "no-subscribed"}
          >
            {!!memership ? date[memership?.status] : "No suscripto"}
          </MembershipState>
        );
      },
      dataIndex: "recurring_subscription",
      key: "recurring_subscription",
    },
    {
      title: (
        <Sort dataIndex="total_investment">
          Total <br /> invertido
        </Sort>
      ),
      width: 130,
      ellipsis: true,
      render: (value, record, index) => {
        return `$ ${formatPrice(value || 0)} ${record.currency ? record.currency : ""}`;
      },
      dataIndex: "total_investment",
      key: "total_investment",
    },
    {
      title: "Contacto",
      width: 100,
      ellipsis: true,
      fixed: isMobile ? false : "right",
      render: (value, record, index) => {
        return <Actions record={record} />;
      },
      key: "contact",
    },
  ];

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: community?.group_name,
        description: "Listado de miembros de la comunidad",
        back: "/dashboard/community",
      },
      dashboard: {
        tabs: {
          visible: true,
          activeTab: "members",
          component: [<Tabs active="members" />],
        },
      },
      header: {
        visible: true,
        actions: [
          <Button
            onClick={() => dispatch(showModal("modal-importar-miembros"))}
            options={{
              size: "lg",
              type: "filled",
              skin: "purple",
            }}
            style={{ minWidth: "210px" }}
          >
            Importar miembros
          </Button>,
        ],
      },
    });
  }, [community]);

  useEffect(() => {
    paginate(1);
  }, [appliedFilters, sortBy, sortDirection]);

  useEffect(() => {
    paginate(1);
  }, [community]);

  return (
    <>
      <Grid.Container>
        <Row>
          <Col>
            <Filters
              conditions={conditions}
              data={[
                ...fixedOptions,
                ...(!!groups ?
                  groups?.map((group) => ({
                    value: group?.id,
                    label: getLabel(group),
                    group_type: group?.group_type,
                  })) : []),
              ]}
              appliedFilters={appliedFilters}
              setAppliedFilters={setAppliedFilters}
            />

            <div style={{ padding: "0px 20px", marginBottom: "16px" }}>
              <Center horizontal="space" vertical="center" column={isMobile}>
                <div style={{ flexShrink: 0 }}>
                  Listado de miembros ({communitySubscribers?.pagination?.total}
                  )
                </div>

                <Center
                  horizontal={isMobile ? "center" : "right"}
                  vertical="center"
                  gap={12}
                >
                  <Button
                    onClick={() => setPreDifusionVisible(!preDifusionVisible)}
                    options={{ size: "lg", type: "link", skin: "purple" }}
                  >
                    Copiar los e-mails (
                    {communitySubscribers?.pagination?.total})
                  </Button>

                  <Button
                    onClick={() => exportSubscribers()}
                    options={{ size: "lg", type: "link", skin: "purple" }}
                  >
                    Exportar XLS
                  </Button>
                </Center>
              </Center>
            </div>

            <div style={{ height: "6px" }} />

            <Card>
              <Scroll>
                <Table
                  columns={columns}
                  data={communitySubscribers?.data}
                  states={communitySubscribersStates}
                  pagination={{}}
                />
              </Scroll>
            </Card>

            {!communitySubscribersStates.loading &&
              communitySubscribers?.data?.length > 0 && (
                <Pagination
                  {...communitySubscribers?.pagination}
                  states={communitySubscribersStates}
                  onClick={(page) => paginate(page)}
                />
              )}
          </Col>
        </Row>
      </Grid.Container>

      <PreDifusion
        appliedFilters={{
          ...getAllAppliedFilters(),
          page: 1,
          id: community?.expert?.id,
          paginate: false,
        }}
        visible={preDifusionVisible}
        setVisible={setPreDifusionVisible}
        onClose={() => { }}
      />
    </>
  );
};

const state = ({ communityStore, userStore, groupStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: groups } = userStore.groups;
  const { data: communitySubscribers, states: communitySubscribersStates } =
    communityStore.subscribers;
  const { data: community, states: communityStates } = communityStore.community;

  return {
    userInformation,
    communitySubscribers,
    communitySubscribersStates,
    community,
    communityStates,
    groups,
  };
};

export default connect(state)(Component);
