import { Center } from "../../../../../../components/StyledComponents";
import Input from "../../../../../../components/FormikForm/Input";
import Select from "../../../../../../components/FormikForm/ReactSelect";
import { useState } from "react";

interface ComponentProps {
  setFieldValue;
  values;
  touched;
  handleBlur;
  handleChange;
  errors;
}

const Component = ({
  setFieldValue,
  values,
  touched,
  handleBlur,
  handleChange,
  errors,
}: ComponentProps) => {
  const [showAlways, setShowAlways] = useState<boolean>(false);
  return (
    <>
      <Select
        name="content_data.show"
        touched={touched["content_data.show"]}
        error={errors["content_data.show"]}
        disabled={false}
        onBlur={handleBlur}
        onChange={(value) => {
          setFieldValue("content_data.show", value);
        }}
        onOptionSelected={(selectedOption) => {
          setShowAlways(selectedOption.value === "show_always");
        }}
        items={[
          {
            value: "show_always",
            label: "Mostrar siempre activo",
          },
          {
            value: "programed",
            label: "Programar liberación",
          },
        ]}
        options={{
          label: "Liberar artículo",
          marginBottom: 16,
        }}
      />

      <Center horizontal="center" vertical="center" gap={8}>
        <Input
          name="content_data.duration"
          type="text"
          placeholder="Tiempo"
          value={values["content_data.duration"]}
          touched={touched["content_data.duration"]}
          error={errors["content_data.duration"]}
          disabled={showAlways}
          onBlur={handleBlur}
          onChange={handleChange}
          options={{
            marginBottom: 0,
          }}
          style={{ maxWidth: "98px" }}
        />
        <Select
          name="content_data.duration_unit"
          touched={touched["content_data.duration_unit"]}
          error={errors["content_data.duration_unit"]}
          disabled={showAlways}
          onBlur={handleBlur}
          onChange={(value) => {
            setFieldValue("content_data.duration_unit", value);
          }}
          items={[
            {
              value: "minutes",
              label: "Minutos",
            },
            {
              value: "hours",
              label: "Horas",
            },
            {
              value: "days",
              label: "Días",
            },
            {
              value: "weeks",
              label: "Semanas",
            },
            {
              value: "months",
              label: "Meses",
            },
          ]}
          options={{
            marginBottom: 0,
          }}
          style={{ maxWidth: "175px" }}
        />
        <Select
          name="content_data.from"
          touched={touched["content_data.from"]}
          error={errors["content_data.from"]}
          disabled={showAlways}
          onBlur={handleBlur}
          onChange={(value) => {
            setFieldValue("content_data.from", value);
          }}
          items={[
            {
              value: "previous_module_finished",
              label: "Desde finalizado el módulo previo",
            },
            {
              value: "experience_starts",
              label: "Desde inicio del taller",
            },
          ]}
          options={{
            marginBottom: 0,
          }}
          style={{ width: "100%", maxWidth: "none" }}
        />
      </Center>
    </>
  );
};

export default Component;
