export const GET_LOCAL_PRICE = "GET_LOCAL_PRICE";
export const GET_LOCAL_PRICE_SUCCESS = "GET_LOCAL_PRICE_SUCCESS";
export const GET_LOCAL_PRICE_ERROR = "GET_LOCAL_PRICE_ERROR";

export interface GetLocalPricePayload {
  source: string;
  target: string;
}

export interface CurrentPageData {
  name: string;
  regex: RegExp | null;
}

export interface GetLocalPrice {
  type: typeof GET_LOCAL_PRICE;
  payload: GetLocalPricePayload;
}

export interface GetLocalPriceSuccess {
  type: typeof GET_LOCAL_PRICE_SUCCESS;
  payload: any;
}

export interface GetLocalPriceError {
  type: typeof GET_LOCAL_PRICE_ERROR;
  payload: any;
}

export interface State {
  data: any;
  states: {
    success: boolean;
    loading: boolean;
    error: string | boolean;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions = GetLocalPrice | GetLocalPriceSuccess | GetLocalPriceError;
