import styled from "styled-components";

export const SectionTitle = styled("h2")`
  margin: 0px 0px 5px 0px;
  font-size: 18px;
  font-weight: semi-bold;
  color: rgba(45, 52, 54, 1);
  width: 100%;
  text-align: left;
`;

export const Text = styled("p")`
  margin: 0px;
  color: rgba(45, 52, 54, 0.5);
`;

export const EmptyPlanCard = styled('div')`
  min-height: 70px;
  outline: 1px solid #cfcfcf;
  font-size: 14px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 24px;
  gap: 12px;
`