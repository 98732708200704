import { createGlobalStyle } from "styled-components";

export const RichTextEditorStyles = createGlobalStyle`
  .remirror-editor-wrapper {
    width: 100%;
  }

  .remirror-theme {
    .remirror-role{
      &.remirror-toolbar { 
        width: 100%;
        background-color: transparent
      }

    }

    .remirror-editor-wrapper {
      .remirror-editor {
        box-shadow: 0px 0px 0px 1px rgb(0, 0, 0, .25)!important;

        &:focus {
          box-shadow: 0px 0px 0px 1px hsla(252, 55%, 53%, 1)!important; 
          box-shadow: 0px 0px 0px 1px rgb(0, 0, 0, .25)!important;
        }
      }
    }
  }
`;
