import { initialState, Actions, State, SET_CURRENT_PAGE } from "../types/page";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducers;
