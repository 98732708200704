import { connect, useDispatch } from "react-redux";
import { CustomDropdown as Dropdown, HorizontalSeparator } from "../styles";
import { LoginMenu, Welcome, LinkItem, ButtonItem } from "./styles";
import { User } from "../../../types/user";
import { signOut } from "../../../store/actions/auth";
import hamburger from "./../hamburger.svg";
import foward from "../../../helpers/fowardUrl";

interface ComponentProps {
  userInformation: User;
}

const Component = ({ userInformation }: ComponentProps) => {
  const dispatch = useDispatch();

  return (
    <LoginMenu>
      <Welcome>
        Hola{userInformation?.name && ` ${userInformation?.name}`}! 👋
      </Welcome>
      <Dropdown
        label={
          <img src={hamburger} alt="hamburger" width="24px" height="24px" />
        }
      >
        <LinkItem marginBottom="16px" to="/profile/subscriptions">
          Mis suscripciones
        </LinkItem>

        {!!userInformation?.expert && (
          <LinkItem marginBottom="16px" to="/dashboard/community">
            Mi Comunidad
          </LinkItem>
        )}

        {!userInformation?.expert && (
          <LinkItem marginBottom="16px" to="/dashboard/community">
            Crear mi comunidad
          </LinkItem>
        )}

        <LinkItem marginBottom="0px" to="/profile/my-account">
          Mi cuenta
        </LinkItem>

        <HorizontalSeparator isSeparator="16px" visible={true} />

        <ButtonItem
          alignSelf="center"
          marginBottom="0px"
          type="button"
          onClick={() => {
            foward.removeFoward()
            dispatch(signOut())
          }}
        >
          Cerrar sesión
        </ButtonItem>
      </Dropdown>
    </LoginMenu>
  );
};

const state = ({ userStore }) => ({
  userInformation: userStore.information.data,
});

export default connect(state)(Component);
