import styled from "styled-components";
import UiButton from "../../../../components/Button";

export const Breadcrumb = styled("div")`
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Button = styled(UiButton)`
  min-width: 288px;
  border-radius: 16px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    min-width: 318px;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    margin: 0px;
  }
`;

export const Text = styled("p")`
  margin-top: 0px;
  margin-bottom: 57px;
`;

export const ShareArticle = styled("div")`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #6c5ce7;
  color: #6c5ce7;
  border-radius: 16px;
  width: 96px;
  height: 26px;
  text-align: center;
  margin-top: 6px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    width: 117px;
    height: 32px;
  }
`;
