import { ReorderWelcomeContentPostsOutDto, ReorderWelcomeContentPostsInDto, WelcomeContentPostDto } from "../../../types/welcome-section";
export const REORDER_WELCOME_CONTENT_POSTS = "REORDER_WELCOME_CONTENT_POSTS";
export const REORDER_WELCOME_CONTENT_POSTS_SUCCESS = "REORDER_WELCOME_CONTENT_POSTS_SUCCESS";
export const REORDER_WELCOME_CONTENT_POSTS_ERROR = "REORDER_WELCOME_CONTENT_POSTS_ERROR";
export const UPDATE_REORDER_WELCOME_CONTENT_POSTS = "UPDATE_REORDER_WELCOME_CONTENT_POSTS";
export const RESET_REORDER_WELCOME_CONTENT_POSTS = "RESET_REORDER_WELCOME_CONTENT_POSTS";

export interface ReorderWelcomeContentPosts {
  type: typeof REORDER_WELCOME_CONTENT_POSTS;
  payload: ReorderWelcomeContentPostsOutDto;
}

export interface UpdateWelcomeContentPosts {
  type: typeof UPDATE_REORDER_WELCOME_CONTENT_POSTS;
  payload: ReorderWelcomeContentPostsInDto;
}

export interface ReorderWelcomeContentPostsSuccess {
  type: typeof REORDER_WELCOME_CONTENT_POSTS_SUCCESS;
  payload: WelcomeContentPostDto[];
}

export interface ReorderWelcomeContentPostsError {
  type: typeof REORDER_WELCOME_CONTENT_POSTS_ERROR;
  payload: string | boolean;
}

export interface ResetReorderWelcomeContentPosts {
  type: typeof RESET_REORDER_WELCOME_CONTENT_POSTS;
  payload: null;
}

export interface State {
  data: WelcomeContentPostDto[] | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | ReorderWelcomeContentPosts
  | UpdateWelcomeContentPosts
  | ReorderWelcomeContentPostsSuccess
  | ReorderWelcomeContentPostsError
  | ResetReorderWelcomeContentPosts
