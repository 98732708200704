import { useEffect, useState } from "react";
import { Center, Page, PageHeader, Title } from "../../components/styles";
import Button from "../../../../components/Button";
import CallToAction from "../../components/CallToAction";
import {
  InLine,
  SectionTitle,
  Card,
  GridTableHeaders,
  GridTableDatum,
  HorizontalScroll,
} from "./styles";
import Helmet from "react-helmet";
import Grid from "../../../../components/Grid";
import {
  Group,
  SurveyAnswerInDto,
  SurveyInDto,
  SurveyOutDto,
} from "../../../../types";
import { connect, useDispatch } from "react-redux";
import {
  getAllSurveys,
  resetGetAllSurveys,
  getAllSurveyAnswers,
  resetNewSurvey,
  resetUpdateSurvey,
  resetGetAllSurveyAnswers,
  resetDeleteSurvey,
  getSurvey,
  resetGetSurvey,
  exportSurveyAnswers,
} from "../../../../store/actions/survey";
import NewSurveyCreatedModal from "../components/NewSurveyCreatedModal";
import { showModal } from "../../../../store/actions/modal";
import EditSurveySidebar from "../EditSurveySidebar";
import NewSurveySidebar from "../NewSurveySidebar";
import { getGroupById } from "../../../../store/actions/group";
import newContent from "../../../../assets/group/new-content.svg";
import DeleteSurveyModal from "../components/DeleteSurveyModal";
import Snackbar from "../../../../components/Snackbar";
import { getUrlParamByName } from "../../../../helpers/get-url-param";
import { useHistory } from "react-router";
import { useLayout } from "../../../../providers/LayoutProvider";
interface ComponentProps {
  match;
  children?;
  group: Group;
  groupStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  survey: SurveyInDto;
  surveyStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  newSurvey: SurveyOutDto;
  newSurveyStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  updateSurveyStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };

  deleteSurveyStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  allSurveyAnswers: SurveyAnswerInDto;
  allSurveyAnswersStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  match,
  children,
  group,
  groupStates,
  survey,
  surveyStates,
  newSurvey,
  newSurveyStates,
  updateSurveyStates,
  allSurveyAnswers,
  deleteSurveyStates,
  allSurveyAnswersStates,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const [showNewSurveySidebar, setShowNewSurveySidebar] =
    useState<boolean>(false);
  const [showEditSurveySidebar, setShowEditSurveySidebar] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!!match.params && group?.id !== Number(match?.params?.idGroup)) {
      dispatch(getGroupById({ group_id: match.params.idGroup }));
    }

    if (getUrlParamByName("new") === "t") {
      dispatch(showModal("new-survey-created-modal"));
      window.history.pushState(null, document.title, history.location.pathname);
    }
  }, [match]);

  useEffect(() => {
    if (newSurveyStates.success) {
      dispatch(showModal("new-survey-created-modal"));
    }
  }, [newSurveyStates]);

  useEffect(() => {
    if (!!group && group?.survey && group?.survey !== survey?.id) {
      dispatch(getSurvey({ idSurvey: group?.survey }));
    }
  }, [group]);

  useEffect(() => {
    if (
      !!group &&
      !group?.survey &&
      (!survey || (!!survey && group?.id !== survey?.group?.id))
    ) {
      dispatch(resetGetSurvey());
      dispatch(resetGetAllSurveyAnswers());
    }
  }, [survey, group]);

  useEffect(() => {
    if (!!group && group?.survey && !!survey && survey?.has_answers) {
      dispatch(getAllSurveyAnswers({ idSurvey: survey?.id }));
    }
  }, [survey]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: group?.group_name,
        description: "Encuestas",
        back: `/dashboard/${group?.group_type === 'free_experience' ? 'community/events' : group?.group_type}/${group?.id}`,
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: true,
        actions: [
          <Button
            disabled={groupStates.loading}
            loading={groupStates.loading}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            onClick={() => setShowNewSurveySidebar(true)}
          >
            Crear encuesta
          </Button>,
        ],
      },
    });
  }, [group]);

  return (
    <>
      {children}

      <div style={{ height: "32px" }} />

      <Grid.Container>
        <Grid.Row>
          <Grid.Col md={6}>
            {!!survey ? (
              <CallToAction
                active={true}
                title="Editar encuesta"
                description="Agrega, elimina o edita preguntas en caso de creerlo necesario."
                action={() => setShowEditSurveySidebar(true)}
                options={{
                  showSuffix: true,
                  preffix: newContent,
                  color: "#FFFFFF",
                }}
              />
            ) : (
              <CallToAction
                title="Configurar encuesta"
                description="Es ideal para que conozcan  tus propuestas y quieran seguir en contacto."
                action={() => setShowNewSurveySidebar(true)}
                options={{
                  showSuffix: true,
                  preffix: newContent,
                  color: "rgba(108, 92, 231, .15)",
                }}
              />
            )}
          </Grid.Col>
        </Grid.Row>

        <div style={{ height: "32px" }} />

        <Grid.Row>
          <Grid.Col>
            <Center horizontal="space" vertical="center">
              <SectionTitle>Respuestas de tus suscriptores</SectionTitle>
              {survey && (
                <Button
                  disabled={groupStates.loading}
                  loading={groupStates.loading}
                  options={{
                    type: "link",
                    size: "lg",
                    skin: "purple",
                  }}
                  onClick={() => dispatch(exportSurveyAnswers(survey?.id))}
                >
                  Exportar CSV
                </Button>
              )}
            </Center>
          </Grid.Col>
        </Grid.Row>

        <div style={{ height: "16px" }} />

        <Grid.Row>
          <Grid.Col>
            <HorizontalScroll>
              <Card
                style={{
                  minWidth: "100%",
                  width: `calc(64px + ${survey?.fields?.length + 2
                    } * (268px + 10px))`,
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridAutoFlow: "column",
                    gap: "10px",
                    gridTemplateColumns: "repeat(auto-fill,minmax(268px, 1fr))",
                  }}
                >
                  <GridTableHeaders>Nombre</GridTableHeaders>
                  <GridTableHeaders>E-mail</GridTableHeaders>
                  {survey?.fields?.map((field) => (
                    <GridTableHeaders>{field.caption}</GridTableHeaders>
                  ))}
                </div>
                <>
                  {!allSurveyAnswers?.has_answers ? (
                    <div
                      style={{
                        display: "grid",
                        gridAutoFlow: "column",
                        gap: "10px",
                        gridTemplateColumns:
                          "repeat(auto-fill,minmax(268px, 1fr))",
                        borderTop: "1px solid #e0e4ea",
                      }}
                    >
                      <GridTableDatum>
                        <Center horizontal="left" vertical="center">
                          Aún no recibiste ninguna respuesta.
                        </Center>
                      </GridTableDatum>
                    </div>
                  ) : (
                    <>
                      {allSurveyAnswers?.survey_answers?.map(
                        (surveyAnswers) => {
                          const { name, last_name } = surveyAnswers?.subscription?.subscriber
                          return (
                            <div
                              style={{
                                display: "grid",
                                gridAutoFlow: "column",
                                gap: "10px",
                                gridTemplateColumns:
                                  "repeat(auto-fill,minmax(268px, 1fr))",
                                borderTop: "1px solid #F0F0F0",
                              }}
                            >
                              <GridTableDatum>
                                {`${name ? name : ''} ${last_name ? last_name : ''}`}
                              </GridTableDatum>
                              <GridTableDatum>
                                {surveyAnswers?.subscription?.subscriber?.email}
                              </GridTableDatum>
                              {surveyAnswers.answers.map((answer) => (
                                <GridTableDatum>{answer.value}</GridTableDatum>
                              ))}
                            </div>
                          );
                        }
                      )}
                    </>
                  )}
                </>
              </Card>
            </HorizontalScroll>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <EditSurveySidebar
        survey={survey}
        isVisible={showEditSurveySidebar}
        onClose={() => setShowEditSurveySidebar(false)}
      />

      <NewSurveySidebar
        isVisible={showNewSurveySidebar}
        onClose={() => setShowNewSurveySidebar(false)}
      />

      <NewSurveyCreatedModal />

      <DeleteSurveyModal />

      <Snackbar
        visible={newSurveyStates.success || !!newSurveyStates.error}
        options={{
          type: newSurveyStates.success ? "success" : "error",
          position: "center",
        }}
        onHide={() => {
          dispatch(resetNewSurvey());
        }}
      >
        {newSurveyStates.success
          ? "Encuesta creada correctamente."
          : `Error al crear encuesta.`}
      </Snackbar>

      <Snackbar
        visible={updateSurveyStates.success || !!updateSurveyStates.error}
        options={{
          type: updateSurveyStates.success ? "success" : "error",
          position: "center",
        }}
        onHide={() => {
          dispatch(resetUpdateSurvey());
        }}
      >
        {updateSurveyStates.success
          ? "Encuesta actualizada correctamente."
          : `Error al actualizar encuesta.`}
      </Snackbar>

      <Snackbar
        visible={deleteSurveyStates.success || !!deleteSurveyStates.error}
        options={{
          type: deleteSurveyStates.success ? "success" : "error",
          position: "center",
        }}
        onHide={() => {
          dispatch(resetDeleteSurvey());
        }}
      >
        {deleteSurveyStates.success
          ? "Encuesta eliminada correctamente."
          : `Error al eliminar encuesta.`}
      </Snackbar>
    </>
  );
};

const state = ({ groupStore, surveyStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: newSurvey, states: newSurveyStates } = surveyStore.newOne;
  const { states: updateSurveyStates } = surveyStore.update;
  const { states: deleteSurveyStates } = surveyStore.deleteOne;
  const { data: survey, states: surveyStates } = surveyStore.selected;
  const { data: allSurveyAnswers, states: allSurveyAnswersStates } =
    surveyStore.allAnswers;

  return {
    group,
    groupStates,
    survey,
    surveyStates,
    newSurvey,
    newSurveyStates,
    updateSurveyStates,
    deleteSurveyStates,
    allSurveyAnswers,
    allSurveyAnswersStates,
  };
};

export default connect(state)(Component);
