import services from ".";
import getPaginationString from "../helpers/getPaginationString";
import action from "./../helpers/rest-client";

export class ErrorPost extends Error { }

class SaveMeet {
  public async newPost(formFields) {
    const formData = new FormData();
    const data = {};

    for (let key in formFields) {
      const currentElement = formFields[key];
      if ((key === "cover_pic") && currentElement) {
        currentElement?.forEach(async (element) => {
          formData.append(key, element, element?.name);
        });
      } else {
        data[key] = currentElement;
      }
    }

    formData.append("data", JSON.stringify(data));

    return action
      .Post({
        url: `/api/post`,
        body: formData,
        config: {
          onUploadProgress: (event) => {
            const { loaded, total } = event;
            const completed = (loaded / total) * 100;
          },
        },
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        throw { error };
      });
  }

  public async editPost(formFields) {
    const { id: idPost, ...rest } = formFields;

    const formData = new FormData();
    const data = {};

    for (let key in rest) {
      const currentElement = rest[key];

      if ((key === "cover_pic") && currentElement) {
        currentElement?.forEach(async (element) => {
          if (element?.url) {
            const file = await fetch(element?.url)
              .then((res) => res.blob())
              .then((blob) => {
                const extension = element?.url.split(".").pop();
                return new File([blob], `${key}.${extension}"`, {
                  type: `image/${extension}`,
                });
              });

            formData.append(key, file, file.name);
          } else {
            formData.append(key, element, element?.name);
          }
        });
      } else {
        data[key] = currentElement;
      }
      // if ((key === "cover_pic") && currentElement) {
      //   currentElement?.forEach(async (element) => {
      //     formData.append(key, element, element?.name);
      //   });
      // } else {
      //   data[key] = currentElement;
      // }
    }

    formData.append("data", JSON.stringify(data));

    return action
      .Put({
        url: `/api/post/${idPost}`,
        body: formData,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        throw { error };
      });
  }

  public async deletePost(idPost) {
    return action
      .Del({
        url: `/api/post/${idPost}`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorPost("Error al procesar la subscripción");
      });
  }

  public async deleteFile(payload) {
    const { idFile } = payload;

    return action
      .Del({
        url: `/api/post/file/${idFile}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        throw new ErrorPost("Error al procesar la subscripción");
      });
  }

  public async getPost(payload) {
    const { idPost, anonymous_email } = payload;
    return action
      .Get({
        url: `/api/post/${idPost}${anonymous_email ? `?anonymous_email=${anonymous_email}` : ''}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getPosts(payload) {
    const pagination = getPaginationString(payload?.pagination);
    const queryString = new URLSearchParams(payload?.filters).toString();

    // const { group_id, content_categories, show_scheduled_post, pinned, published_at_null } = payload;
    // const filterByCategory = !!content_categories
    //   ? `&content_categories=${content_categories}`
    //   : "";

    // const filterByPinned = `&pinned=${pinned}`

    // const filterByShowScheduledPost = typeof show_scheduled_post !== "undefined" ? `&show_scheduled_post=${show_scheduled_post}` : ""

    // const filterByPublishAtNull = typeof published_at_null !== "undefined" ? `&published_at_null=${published_at_null}` : ""

    return action
      .Get({
        url: `/api/post/?${pagination}&${queryString ? queryString : ''}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getFeaturedPosts({ group_id }) {
    return action
      .Get({
        url: `/api/post/?group_id=${group_id}&pinned=true&_sort=pinned_order:ASC`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async sharePostInTelegram({ id }) {
    return action
      .Post({
        url: `/api/post/share-in-telegram`,
        body: { post_id: id },
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async setFeaturedPost({ idPost, pinned }) {
    return action
      .Put({
        url: `/api/post/pinit/${idPost}`,
        body: { pinned },
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async reorderPosts(payload) {
    return action
      .Put({
        url: `/api/post/reorder-posts`,
        body: payload
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const saveMeet = new SaveMeet();
export default saveMeet;
