import {
  initialState,
  Actions,
  State,
  GET_LOCAL_PRICE,
  GET_LOCAL_PRICE_SUCCESS,
  GET_LOCAL_PRICE_ERROR,
} from "../types/currency";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_LOCAL_PRICE:
      return {
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case GET_LOCAL_PRICE_SUCCESS:
      return {
        data: { ...action.payload[0] },
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case GET_LOCAL_PRICE_ERROR:
      return {
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default reducers;
