export const CHANGE_USER_PHONE = "CHANGE_USER_PHONE";
export const CHANGE_USER_PHONE_SUCCESS = "CHANGE_USER_PHONE_SUCCESS";
export const CHANGE_USER_PHONE_ERROR = "CHANGE_USER_PHONE_ERROR";
export const RESET_CHANGE_USER_PHONE = "RESET_CHANGE_USER_PHONE";

export interface ChangeUserPhone {
  type: typeof CHANGE_USER_PHONE;
  payload: string;
}

export interface ChangeUserPhoneSuccess {
  type: typeof CHANGE_USER_PHONE_SUCCESS;
  payload: string;
}

export interface ChangeUserPhoneError {
  type: typeof CHANGE_USER_PHONE_ERROR;
  payload: boolean | string;
}

export interface ResetChangeUserPhone {
  type: typeof RESET_CHANGE_USER_PHONE;
  payload: null
}

export interface State {
  states: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
}

export const initialState: State = {
  states: {
    loading: false,
    error: false,
    success: false,
  },
};

export type Actions =
  | ChangeUserPhone
  | ChangeUserPhoneSuccess
  | ChangeUserPhoneError
  | ResetChangeUserPhone;
