import styled, { css } from "styled-components";

const commonItem = css`
  padding: 0px 16px;

  span {
    display: block;
    color: black;
    
    &:hover {
      //color: ${(props) => props.theme.colors.Primary};
      
      & > * {
        color: ${(props) => props.theme.colors.Primary};
      }
    }
    
    & > * {
      cursor: pointer;
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 36px;
      text-align: right;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.8);
      border: none;
      border-color: transparent;
      background-color: transparent;
      padding: 0;
      text-align: left;
      min-width: auto;
      text-decoration: none;

      &:disabled {
        color: gray;
      }
    }
  }
`;

const separatorItem = `
  height: 1px; 
  width: 100%;
  background-color: #ADADAD;
  margin: 16px 0px;

`;

export const Item = styled("li") <{ isSeparator?; marginBottom?; alignSelf?}>`
  list-style-type: none;
  padding: 0px 8px;
  width: max-content;
  cursor: pointer;
  ${(props) => (props.isSeparator ? separatorItem : commonItem)}
`;
