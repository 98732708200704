import { Card, Text, User, Avatar, Data, UserName, GroupName } from "./styles";
import Carrousel from "../Carousel";
import constants from "../../../../constants";

const Component = () => {
  return (
    <Carrousel>
      {constants.homePage.testimonials.map((testimonial) => {
        return (
          <Card>
            <Text>{testimonial.text}</Text>
            <User>
              <Avatar src={testimonial.picture} alt="" width="" height="" lazy={true}/>
              <Data>
                <UserName>{testimonial.name}</UserName>
                <GroupName>{testimonial.category}</GroupName>
              </Data>
            </User>
          </Card>
        );
      })}
    </Carrousel>
  );
};

export default Component;
