import Radio from "../../../../../../../../components/FormikForm/Radio";
import Input from "../../../../../../../../components/FormikForm/Input";
import { useTheme } from "styled-components";

const options = [
  { label: "Indefinido", value: 'indefinido' },
  { label: "Fecha especifica", value: 'fecha-especifica' },
]

interface ComponentProps {
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  validateField,
  isLoading: boolean,
  isNew?: boolean,
  isEdit?: boolean,
  isValidating?: boolean
}

const Component = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  validateField,
  isLoading,
  isNew,
  isEdit,
  isValidating,
}: ComponentProps) => {
  const theme = useTheme() as { colors: { Primary: string } }

  return (
    <>
      <Input
        name="email"
        error={errors["email"]}
        touched={touched["email"]}
        step="any"
        placeholder="ejemplo@klouser.com"
        onChange={handleChange}
        //onBlur={(event) => { handleBlur(event); validateField('email') }}
        onBlur={(event) => { handleBlur(event); }}
        value={values["email"]}
        options={{
          label: "E-mail del suscriptor",
          marginBottom: 20,
          //after: isValidating ? <Loader color={theme.colors.Primary} width={35} /> : <></>
        }}
      />

      <Radio
        name="expire_at_type"
        error={errors["expire_at_type"]}
        touched={touched["expire_at_type"]}
        items={options}
        value={values["expire_at_type"]}
        onChange={(event) => {
          handleChange(event);

          if (
            event.currentTarget.value === "indefinido" && !!values.expire_at_type
          ) {
            setFieldValue("expire_at", undefined);
          }
        }}
        onBlur={handleBlur}
        options={{
          label:
            "Permanencia hasta",
          helperText: "Elige el tiempo que el suscriptor pordrá acceder de forma gratuíta.",
          marginBottom: 20,
          // vertical: true,
        }}
      />

      {values["expire_at_type"] === 'fecha-especifica' && (
        <Input
          name="expire_at"
          error={errors["expire_at"]}
          touched={touched["expire_at"]}
          type="date"
          placeholder="dd/mm/aaaa"
          value={values["expire_at"]}
          onChange={handleChange}
          onBlur={handleBlur}
          options={{
            label: "Permanencia válida hasta",
            helperText:
              "Selecciona hasta que fecha tendra acceso el suscriptor.",
            marginBottom: 20,
          }}
        />
      )}
    </>
  )
}

export default Component