import styled from "styled-components";

export const Image = styled.img`
  max-width: 224px;
  height: 151px;
  margin-bottom: 24px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    max-width: 257px;
    height: 173px;
    margin-bottom: 15px;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  text-align: center;
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 17px;
  text-align: center;
`;
