import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  #header-sticky {
    position: relative;
  }
`;

export const Toolbar = styled("div")`
  background-color: #dad6f9;
  padding: 8px 0px;
  font-size: 18px;
  line-height: 22px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
    background-color: ${({ theme }) => theme.colors.Primary};
  }
`;

export const Circle = styled("div")`
  width: 37px;
  height: 37px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-right: 12px;
  flex-shrink: 0;
`;

export const Back = styled("a")`
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 36px;

  &:hover {
    text-decoration: underline;
  }

  span {
    display: none;
    margin-left: 16px;
    @media (min-width: 768px) {
      display: block;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    color: white;
  }
`;

export const InLine = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Actions = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 14px;

  @media (min-width: 768px) {
    margin-left: 24px;
    width: auto;
    flex-direction: row;
  }
`;
