import { connect, useDispatch } from 'react-redux'
import { Group } from '../../../../types'
import { Post } from '../../../../types/post'
import Icon from '../../../../components/Icon'

import {
  Card,
  Title,
  Description,
  Button,
  Actions
} from './styles'


import DragAndDropIcon from '../../../../assets/icons/sort-icon-2.svg'
import BookIcon from '../../../../assets/icons/book.svg'
import DeleteIcon from '../../../../assets/icons/trash.svg'
import EditIcon from '../../../../assets/icons/edit-light.svg'
import { showModal } from '../../../../store/actions/modal'

interface ComponentProps {
  post?: Post;
  setSelectedPostToBeDeleted?;
  setSelectedPostToEdit?;
  showActions?: boolean;
}

const Component = ({
  post,
  setSelectedPostToBeDeleted,
  setSelectedPostToEdit,
  showActions = true,
}: ComponentProps) => {
  const dispatch = useDispatch()

  return (
    <div style={{ display: 'flex', gap: 10 }}>
      <Card>
        <Icon icon={DragAndDropIcon} size="40px" />
        <div>
          <Title>{post?.title}</Title>
        </div>
      </Card >

      {showActions && (
        <Actions>
          <Button
            onClick={() => setSelectedPostToEdit(post)}
          >
            <Icon
              icon={EditIcon}
              size="16px"
              color="black"
            />
          </Button>

          <Button
            onClick={() => {
              setSelectedPostToBeDeleted(post)
            }}
          >
            <Icon icon={DeleteIcon} size="16px" color="black" />
          </Button>
        </Actions>
      )}
    </div>
  )
}

export default Component;