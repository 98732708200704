export const SET_FEATURED_POST = "SET_FEATURED_POST_POST";
export const SET_FEATURED_POST_SUCCESS = "SET_FEATURED_POST_SUCCESS";
export const SET_FEATURED_POST_ERROR = "SET_FEATURED_POST_ERROR";
export const RESET_SET_FEATURED_POST = "RESET_SET_FEATURED_POST";

export interface SetFeaturedPost {
  type: typeof SET_FEATURED_POST;
  payload: { idPost: number; pinned: boolean };
}

export interface SetFeaturedPostError {
  type: typeof SET_FEATURED_POST_ERROR;
  payload: boolean | string;
}

export interface SetFeaturedPostSuccess {
  type: typeof SET_FEATURED_POST_SUCCESS;
  payload: null;
}

export interface ResetSetFeaturedPost {
  type: typeof RESET_SET_FEATURED_POST;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | SetFeaturedPost
  | SetFeaturedPostError
  | SetFeaturedPostSuccess
  | ResetSetFeaturedPost;
