import { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

import Sidebar from "../../../../../components/Sidebar";
import Button from "../../../../../components/Button";
import Snackbar from "../../../../../components/Snackbar";

import {
  Description
} from './styles';

import { deleteCategory, resetUpdateCategory } from '../../../../../store/actions/category';
import { Group } from '../../../../../types';
import { CategoryDto } from '../../../../../types/category';

interface ComponentProps {
  group: Group
  groupStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  deletedCategory,
  deletedCategoryState: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  onClose
  category?: CategoryDto
  isVisible: boolean
}

const Component = ({
  group,
  groupStates,
  deletedCategory,
  deletedCategoryState,
  onClose,
  category,
  isVisible
}: ComponentProps) => {
  const dispatch = useDispatch()

  const deleteSelectedCategory = () => {
    dispatch(deleteCategory({ id: category?.id }));
  };

  useEffect(() => {
    if (deletedCategoryState.success) {
      !!onClose && onClose();

      setTimeout(() => {
        dispatch(resetUpdateCategory());
      }, 3500);
    }
  }, [deletedCategoryState]);

  return <>
    <Sidebar
      position="right"
      visible={isVisible}
      closeType="back"
      onClose={() => {
        !!onClose && onClose();
      }}
      width="463px"
      title="Eliminar categoría"
    >
      <Description>
        Si elimina esta categoría, todos los artículos agrupados bajo ella perderán esa clasificación. Si no están asignados a otra categoría, se considerarán artículos sin categoría.
      </Description>

      <div style={{ height: 30 }} />

      <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
        <Button
          disabled={deletedCategoryState.loading}
          options={{
            type: "outline",
            size: "lg",
            skin: "purple",
          }}
          onClick={() => onClose()}
        >
          Cancelar
        </Button>

        <Button
          disabled={deletedCategoryState.loading}
          loading={deletedCategoryState.loading}
          onClick={() => deleteSelectedCategory()}
          options={{
            type: "filled",
            size: "lg",
            skin: "purple",
          }}
        >
          Eliminar
        </Button>
      </div>
    </Sidebar>

    <Snackbar
      visible={deletedCategoryState.success || !!deletedCategoryState.error}
      options={{
        time: 2000,
        type: deletedCategoryState.success ? "success" : "error",
      }}
    >
      {deletedCategoryState.success
        ? "Categoría eliminada."
        : "Error al eliminar la categoría."}
    </Snackbar>
  </>
}

const state = ({ groupStore, categoryStore }) => {
  const { data: categories, states: categoriesState } =
    categoryStore?.allCategories;
  const { data: deletedCategory, states: deletedCategoryState } =
    categoryStore?.deleteCategory;
  const { data: group, states: groupStates } =
    groupStore.group;


  return {
    group,
    groupStates,
    categories,
    categoriesState,
    deletedCategory,
    deletedCategoryState
  };
};

export default connect(state)(Component);