import { useState } from "react";
import {
  FieldContainer,
  Field,
  Label,
  FieldBefore,
  FieldAfter,
  HelperLine,
  HelperText,
  ErrorMessage,
  TooglePassword,
  Required,
  CharacterCounter,
} from "../Field";
import { Input } from "./styles";
import EyeOpen from "./../../../assets/icons/eye-on.svg";
import EyeClose from "./../../../assets/icons/eye-off.svg";
import { useFieldValidation } from "../../../hooks/useFieldValidation";

interface ComponentProps {
  register?: any;
  forwardRef?;
  name: string;
  value?: string | number;
  type?: string;
  disabled?: boolean;
  rules?: any;
  errors?: {};
  placeholder?: string;
  maxLength?: number;
  formState?: any;
  onFocus?: (data: any, formState: any) => void;
  onBlur?: (data: any, formState: any) => void;
  onChange?: (data: any, formState: any) => void;
  onWheel?: (data: any, formState: any) => void;
  options?: {
    skin?: "base";
    size?: "md";
    label?: string;
    helperText?: string;
    before?: any;
    after?: any;
    marginBottom?: number;
    hidden?: boolean;
  };
}

const initialValue = {
  options: {
    skin: "base",
    size: "md",
    marginBottom: 24,
  },
};

const Component = ({
  register,
  forwardRef,
  rules = {},
  options,
  formState,
  type,
  disabled,
  name,
  errors,
  maxLength,
  onFocus,
  onBlur,
  onChange,
  onWheel,
  ...rest
}: ComponentProps) => {
  const { classes, setActive } = useFieldValidation(errors, name, disabled);
  const { skin, size, marginBottom, hidden } = {
    ...initialValue.options,
    ...options,
  };
  const [fieldType, setFieldType] = useState(type);
  const [numCharacters, setNumCharacters] = useState(0);

  const togglePassword = () => {
    !disabled &&
      setFieldType(type === `password` && fieldType === type ? `text` : type);
  };

  const handleOnChange = (event, callback) => {
    const { value } = event.target;
    setNumCharacters(value.length);
    callback && callback(event, formState);
  };

  const handleOnBlur = (event, callback) => {
    setActive(false);
    callback && callback(event, formState);
  };

  const handleOnFocus = (event, callback) => {
    setActive(true);
    callback && callback(event, formState);
  };

  const handleWheel = (event, callback) => {
    callback && callback(event, formState);
  };

  return (
    <FieldContainer
      marginBottom={marginBottom}
      {...(hidden && { style: { display: "none" } })}
    >
      {options?.label && (
        <Label size={options?.size} htmlFor={`input-${name}`}>
          {options.label}
          {rules && rules.required && <Required>*</Required>}
        </Label>
      )}

      <Field skin={skin} size={size} className={classes}>
        {options?.before && (
          <FieldBefore size={options?.size} className="field-before">
            {options.before}
          </FieldBefore>
        )}

        <Input
          id={name}
          name={name}
          onChange={(event) => {
            handleOnChange(event, onChange);
          }}
          onBlur={(event) => {
            handleOnBlur(event, onBlur);
          }}
          onFocus={(event) => {
            handleOnFocus(event, onFocus);
          }}
          onWheel={(event) => {
            handleWheel(event, onWheel)
          }}
          ref={forwardRef}
          {...(register && {
            ...register(name, rules),
            onChange: (event) => {
              handleOnChange(event, onChange);
              return register(name, rules).onChange(event);
            },
            onBlur: (event) => {
              handleOnBlur(event, onBlur);
              return register(name, rules).onBlur(event);
            },
            ...(forwardRef && {
              ref: (e) => {
                const { ref } = register(name, rules);
                ref(e);
                forwardRef.current = e;
              },
            }),
          })}
          maxLength={maxLength}
          type={fieldType}
          disabled={disabled}
          {...rest}
        />

        {(options?.after || type === "password") && (
          <FieldAfter size={options?.size} className="field-after">
            {type === "password" && !options?.after ? (
              <TooglePassword type="button" onClick={togglePassword}>
                <img
                  src={fieldType === "password" ? EyeOpen : EyeClose}
                  alt="Eye"
                />
              </TooglePassword>
            ) : (
              options && options.after
            )}
          </FieldAfter>
        )}
      </Field>

      {((options && options.helperText) || maxLength || errors) && (
        <HelperLine marginBottom={marginBottom}>
          {errors && errors[name] && (
            <ErrorMessage>{errors[name].message}</ErrorMessage>
          )}
          {options && options.helperText && errors && !errors[name] && (
            <HelperText>{options.helperText}</HelperText>
          )}
          {maxLength && (
            <CharacterCounter errors={errors && errors[name]}>
              {numCharacters} / {maxLength}
            </CharacterCounter>
          )}
        </HelperLine>
      )}
    </FieldContainer>
  );
};

export default Component;
