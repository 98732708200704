export const GET_EXPERT_TELEGRAM_GROUP = "GET_EXPERT_TELEGRAM_GROUP";


export const GET_EXPERT_TELEGRAM_GROUP_SUCCESS =
  "GET_EXPERT_TELEGRAM_GROUP_SUCCESS";
export const GET_EXPERT_TELEGRAM_GROUP_ERROR =
  "GET_EXPERT_TELEGRAM_GROUP_ERROR";
export const RESET_GET_EXPERT_TELEGRAM_GROUP =
  "RESET_GET_EXPERT_TELEGRAM_GROUP";

export interface GetExpertTelegramGroup {
  type: typeof GET_EXPERT_TELEGRAM_GROUP;
  payload: number | string;
}

export interface GetExpertTelegramGroupSuccess {
  type: typeof GET_EXPERT_TELEGRAM_GROUP_SUCCESS;
  payload: string;
}

export interface GetExpertTelegramGroupError {
  type: typeof GET_EXPERT_TELEGRAM_GROUP_ERROR;
  payload: boolean | string;
}

export interface ResetGetExpertTelegramGroup {
  type: typeof RESET_GET_EXPERT_TELEGRAM_GROUP;
  payload: null;
}
export interface State {
  data: string | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetExpertTelegramGroup
  | GetExpertTelegramGroupSuccess
  | GetExpertTelegramGroupError
  | ResetGetExpertTelegramGroup;
