import { Group } from "../../../types";

export const UPDATE_GROUP_MAIN_CURRENCY = "UPDATE_GROUP_MAIN_CURRENCY";
export const UPDATE_GROUP_MAIN_CURRENCY_SUCCESS = "UPDATE_GROUP_MAIN_CURRENCY_SUCCESS";
export const UPDATE_GROUP_MAIN_CURRENCY_ERROR = "UPDATE_GROUP_MAIN_CURRENCY_ERROR";
export const RESET_UPDATE_GROUP_MAIN_CURRENCY = "RESET_UPDATE_GROUP_MAIN_CURRENCY";

export interface UpdateGroupMainCurrency {
  type: typeof UPDATE_GROUP_MAIN_CURRENCY;
  payload: { "main_currency": 'ARS' | 'USD' | 'EUR', group_id: number }
}

export interface UpdateGroupMainCurrencySuccess {
  type: typeof UPDATE_GROUP_MAIN_CURRENCY_SUCCESS;
  payload: Group;
}

export interface UpdateGroupMainCurrencyError {
  type: typeof UPDATE_GROUP_MAIN_CURRENCY_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateGroupMainCurrency {
  type: typeof RESET_UPDATE_GROUP_MAIN_CURRENCY;
  payload: null;
}

export interface State {
  data: Group | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | UpdateGroupMainCurrency
  | UpdateGroupMainCurrencySuccess
  | UpdateGroupMainCurrencyError
  | ResetUpdateGroupMainCurrency;
