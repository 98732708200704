import styled from "styled-components";

export const Title = styled.h1`
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin: 24px 0px 37px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    text-align: left;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    margin: 59px 0px 32px;
  }
`;

export const Filters = styled("div")`
  display: grid;
  grid-template-columns: 2fr 0.5fr;
  justify-content: flex-start;
  grid-gap: 10px;
  row-gap: 20px;
  margin-bottom: 50px;

  @media (min-width: 1024px) {
    grid-template-columns: 0.35fr auto;
  }
`;

export const Text = styled("p")`
  margin: 0px;
  padding: 10px;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.Black};
  text-align: left;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 18px;
    line-height: 24px;
  }

`;


// export const Text = styled("p")`
//   margin: 0px;
//   padding-left: 15px;
//   font-size: 14px;
//   line-height: 19px;
//   color: ${(props) => props.theme.colors.Black};

//   @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
//     font-size: 16px;
//   }
// `;

export const FilterLabel = styled("span")`
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  display: flex;
`;

