import Grid from "./../../components/Grid";
import {
  NotFound,
  Img,
  Section,
  Title,
  SubTitle,
  Text,
  LinkItem,
  Link,
} from "./styles";
import Helmet from "react-helmet";
import Woman from "./../../assets/woman-error.svg";

const Component = () => (
  <>
    <Helmet>
      ‍<title>Klouser - 404</title>‍
      <meta name="description" content="404" />
    </Helmet>

    <NotFound>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col offset={{ sm: 1, md: 1 }} sm={10} md={4}>
            <Img src={Woman} alt="Error" width="175px" height="156px" />
          </Grid.Col>
          <Grid.Col offset={{ sm: 1, md: 1 }} sm={10} md={5}>
            <Section>
              <Title>Oops!</Title>
              <SubTitle>
                No podemos encontrar la página que estas buscando.
              </SubTitle>
              <Text>
                Puede ser nuestro error. No te preocupes, te invitamos a que
                busques más información en nuestro inicio.
              </Text>
              <LinkItem>
                <Link
                  href="/signin"
                  options={{
                    skin: "purple",
                    size: "lg",
                    type: "filled",
                  }}
                >
                  Ir al inicio
                </Link>
              </LinkItem>
            </Section>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </NotFound>
  </>
);

export default Component;
