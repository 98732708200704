import * as Yup from "yup";

export const schema = Yup.object().shape({
  email: Yup.string()
    .required("Ingresa tu corréo electrónico")
    .email("El email no es válido.")
    .trim("El corréo electrónico no puede contener espacio")
    .max(200, "no puede superar los 200 caracteres"),
  name: Yup.string()
    .required("Ingresa tu nombre"),
  terminosCondiciones: Yup.array()
    .of(Yup.string())
    .min(1, "Debes aceptar las condiciones."),
});

interface FormValues {
  email: string;
  name: string;
  terminosCondiciones: string[];
}

export const initialValues: FormValues = {
  email: "",
  name: "",
  terminosCondiciones: ["true"],
};
