import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import {
  getGroupById,
  getGroupSubscribers,
  resetGroupSubscribers,
} from "../../../../../store/actions/group";
import Tabs from '../components/Tabs'
import newSurveyIcon from "../../../../../assets/group/new-survey.svg";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import LastResourses from "../../../components/LastResourses";
import Loader from "../../../../../components/Loader";
import Grid from "../../../../../components/Grid";
import Statistics from "../../../components/Statistics";
import GoBack from "../../../../../components/GoBack";
import TelegramSync from "../../../components/TelegramSync";
import ShareLink from "../../../components/ShareLink";
import CallToAction from "../../../components/CallToAction";
import { Page, PageHeader, Title } from "../../../components/styles";
import { SectionTitle, Breadcrumb, InLine } from "./styles";
import Discount from "./components/Discount";
import ModalTelegram from "../../../components/ModalTelegram";
import constants from "../../../../../constants";
import newPromotion from "../../../../../assets/group/new-promotion.svg";
import newContent from "../../../../../assets/group/new-content.svg";
import goToTelegram from "../../../../../assets/group/go-to-telegram.svg";
import salesPage from "../../../../../assets/group/sales-page.svg";
import { showModal, hideModal } from "../../../../../store/actions/modal";
import InitialSteps from "./components/InitialSteps";
import Alert from "../../../../../components/Alert";
import Link from "../../../../../components/Link";
import Button from "../../../../../components/Button";
import Swipe from "../../../../../components/Swipe";
import ModalShareLink from "./components/ModalShareLink";
import Members from "../components/Members";
import { useLayout } from "../../../../../providers/LayoutProvider";
import NewSurveySidebar from "../../../Survey/NewSurveySidebar";
import Snackbar from "../../../../../components/Snackbar";
import {
  getSurvey,
  resetNewSurvey,
} from "../../../../../store/actions/survey";
import { SurveyInDto } from "../../../../../types";

interface ComponentProps {
  match;
  pageContentRef?;
  currentSlide?: number;
  userInformation;
  groupInformation;
  groupStates;
  groupSubscribers;
  groupSubscribersStataes;
  survey: SurveyInDto;
  surveyState: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  newSurvey: SurveyInDto;
  newSurveyStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const ctaLabel = {
  telegram: "Ir al grupo de Telegram",
  whatsapp: "Ir al grupo de Whatsapp",
};

const Component = ({
  match,
  groupInformation,
  groupStates,
  userInformation,
  groupSubscribers,
  groupSubscribersStataes,
  survey,
  newSurveyStates,
}: ComponentProps) => {
  const [showNewSurveySidebar, setShowNewSurveySidebar] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const { layout, setLayout } = useLayout();

  const paginate = (page) => {
    dispatch(
      getGroupSubscribers({
        page,
        id: groupInformation?.id,
        filters: {},
      })
    );
  };

  useEffect(() => {
    paginate(1);
  }, []);

  useEffect(() => {
    dispatch(resetGroupSubscribers());
    const otherGroup =
      groupInformation &&
      groupInformation?.id !== Number(match?.params?.idGroup);

    if (
      !!match.params &&
      (otherGroup || !groupInformation) &&
      !groupStates.loading
    ) {
      dispatch(getGroupById({ group_id: match.params.idGroup }));
    }
  }, [match]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: groupInformation?.group_name,
        description: groupInformation?.group_name,
        back: "/dashboard/community/events/list",
      },
      dashboard: {
        tabs: {
          visible: true,
          activeTab: "home",
          component: [
            <Tabs
              key={'navigation-tabs'}
              active="home"
              hiddenTabs={
                groupInformation?.recorded ? ["surveys", "library", "configuration"] : ["modules", "sessions", "members"]
              }
            />,
          ],
        },
      },
      header: {
        visible: true,
        actions: [
          <Link
            target="_blank"
            href={
              !!groupInformation?.telegram_groups &&
              !!groupInformation?.telegram_groups[0] &&
              groupInformation?.telegram_groups[0]?.main_invitation_link
            }
            disabled={!groupInformation?.telegram_groups[0]}
            options={{
              type: "outline",
              size: "lg",
              skin: "purple",
              native: false,
            }}
            style={{ marginRight: "8px" }}
          >
            {groupInformation?.telegram_groups[0]?.platform
              ? ctaLabel[groupInformation?.telegram_groups[0]?.platform]
              : "Ir al grupo de chat"}
          </Link>,

          <Button
            onClick={() => {
              const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${groupInformation?.slug}`;
              navigator.clipboard.writeText(link);
              dispatch(showModal("modal-share-group-link"));
            }}
            disabled={!groupInformation?.telegram_groups[0]}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
          >
            Compartir taller
          </Button>,
        ],
      },
    });
  }, [groupInformation]);

  useEffect(() => {
    if (groupInformation && groupInformation?.survey && !survey) {
      dispatch(getSurvey({ idSurvey: groupInformation?.survey }));
    }
  }, [groupInformation]);

  if (groupStates.loading || (!groupInformation && !groupStates.error)) {
    return <Loader />;
  }

  if (groupStates.error === "Not Found" || groupStates.error === "Forbidden") {
    return <Redirect to="/404" />;
  }

  return (
    <>
      {!groupInformation?.telegram_groups[0] && (
        <>
          <div style={{ height: "32px" }}></div>
          <Grid.Container>
            <Grid.Row>
              <Grid.Col>
                Haz crecer tu comunidad 🙌 Lanza un evento especial de forma
                gratuita para captar audiencia interesada en tus propuestas.
              </Grid.Col>
            </Grid.Row>
          </Grid.Container>
        </>
      )}

      <div style={{ height: "32px" }}></div>

      <Grid.Container>
        <Grid.Row>
          <Grid.Col sm={8}>
            <Grid.Row>
              <Grid.Col>
                <SectionTitle>Complementos del evento</SectionTitle>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <CallToAction
                  title={
                    groupInformation?.telegram_groups[0]?.platform
                      ? ctaLabel[groupInformation?.telegram_groups[0]?.platform]
                      : "Ir al grupo de chat"
                  }
                  {...(!!groupInformation?.telegram_groups &&
                    !!groupInformation?.telegram_groups[0]
                    ? {
                      link: groupInformation?.telegram_groups[0]
                        ?.main_invitation_link,
                    }
                    : { action: () => dispatch(showModal("modal-telegram")) })}
                  options={{
                    preffix: goToTelegram,
                    type: "link",
                    size: "small",
                    nativeLink: false,
                  }}
                  style={{ marginBottom: "8px" }}
                  target="_blank"
                />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <CallToAction
                  title="Ir a página del evento"
                  link={`/group/${groupInformation?.slug}`}
                  options={{
                    preffix: salesPage,
                    type: "link",
                    size: "small",
                  }}
                />
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <SectionTitle>Acciones</SectionTitle>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col>
                <CallToAction
                  title="Subir contenido"
                  description="Crea un contenido para compartir con los suscriptores de tu evento"
                  link={`/dashboard/community/events/${groupInformation?.id}/posts/new`}
                  options={{
                    preffix: newContent,
                    color: "rgba(253, 236, 243, 1);",
                  }}
                />
              </Grid.Col>
            </Grid.Row>

            <div style={{ height: 10 }} />

            <Grid.Row>
              <Grid.Col>
                <CallToAction
                  active={!!survey}
                  title={!!survey ? "Encuesta activa" : "Configurar encuesta"}
                  description="Es ideal para que conozcan  tus propuestas y quieran seguir en contacto"
                  {...(!!survey
                    ? {
                      link: `/dashboard/community/events/${groupInformation?.id}/surveys`,
                    }
                    : { action: () => setShowNewSurveySidebar(true) })}
                  options={{
                    preffix: newSurveyIcon,
                    color: "rgba(253, 236, 243, 1);",
                  }}
                />
              </Grid.Col>
            </Grid.Row>

            <LastResourses
              itemsPerRow={2}
              showMoreLink={false}
              groupInformation={groupInformation}
            />
          </Grid.Col>

          <Grid.Col sm={4}>
            <Members
              groupId={groupInformation?.id}
              states={groupSubscribersStataes}
              data={groupSubscribers?.data}
              pagination={groupSubscribers?.pagination}
              goToPage={paginate}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <ModalTelegram />

      <ModalShareLink />

      <Swipe name="swipe-menu">
        <Button
          onClick={() => {
            navigator.clipboard.writeText(
              `${process.env.REACT_APP_SITE_BASE_URL}/group/${groupInformation?.slug}`
            );
          }}
          disabled={!groupInformation?.telegram_groups[0]}
          options={{
            type: "filled",
            size: "lg",
            skin: "purple",
          }}
        >
          Compartir taller
        </Button>
      </Swipe>

      <Snackbar
        visible={newSurveyStates.success || !!newSurveyStates.error}
        options={{
          type: newSurveyStates.success ? "success" : "error",
          position: "center",
        }}
        onHide={() => {
          window.location.href = `/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/surveys?new=t`;
          dispatch(resetNewSurvey());
        }}
      >
        {newSurveyStates.success
          ? "Encuesta creada correctamente."
          : `Error al crear encuesta.`}
      </Snackbar>

      <NewSurveySidebar
        isVisible={showNewSurveySidebar}
        onClose={() => setShowNewSurveySidebar(false)}
      />
    </>
  );
};

const state = ({ groupStore, userStore, surveyStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: groupInformation, states: groupStates } = groupStore.group;
  const { data: groupSubscribers, states: groupSubscribersStataes } =
    groupStore.groupSubscribers;
  const { data: newSurvey, states: newSurveyStates } = surveyStore.newOne;
  const { data: survey, states: surveyState } = surveyStore.selected;

  return {
    groupInformation,
    groupStates,
    userInformation,
    groupSubscribers,
    groupSubscribersStataes,
    survey,
    surveyState,
    newSurvey,
    newSurveyStates,
  };
};

export default connect(state)(Component);
