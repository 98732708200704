import { ErrorPlan } from "../../../services/plan";
import { NewPlanInDto, NewPlanOutDto, PlanDto } from "../../../types/plan.dto";
export const NEW_PLAN = "NEW_PLAN";
export const NEW_PLAN_SUCCESS = "NEW_PLAN_SUCCESS";
export const NEW_PLAN_ERROR = "NEW_PLAN_ERROR";
export const RESET_NEW_PLAN = "RESET_NEW_PLAN";

export interface NewPlan {
  type: typeof NEW_PLAN;
  payload: NewPlanOutDto;
}

export interface NewPlanSuccess {
  type: typeof NEW_PLAN_SUCCESS;
  payload: NewPlanInDto;
}

export interface NewPlanError {
  type: typeof NEW_PLAN_ERROR;
  payload: ErrorPlan | boolean;
}

export interface ResetNewPlan {
  type: typeof RESET_NEW_PLAN;
  payload: null;
}

export interface State {
  data: NewPlanInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: ErrorPlan | boolean;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions = NewPlan | NewPlanSuccess | NewPlanError | ResetNewPlan;
