import { NewWelcomeContentSectionOutDto, NewWelcomeContentSectionInDto } from "../../../types/welcome-section";
export const NEW_WELCOME_CONTENT_SECTION = "NEW_WELCOME_CONTENT_SECTION";
export const NEW_WELCOME_CONTENT_SECTION_SUCCESS = "NEW_WELCOME_CONTENT_SECTION_SUCCESS";
export const NEW_WELCOME_CONTENT_SECTION_ERROR = "NEW_WELCOME_CONTENT_SECTION_ERROR";
export const RESET_NEW_WELCOME_CONTENT_SECTION = "RESET_NEW_WELCOME_CONTENT_SECTION";

export interface NewWelcomeContentSection {
  type: typeof NEW_WELCOME_CONTENT_SECTION;
  payload: NewWelcomeContentSectionOutDto
}

export interface NewWelcomeContentSectionSuccess {
  type: typeof NEW_WELCOME_CONTENT_SECTION_SUCCESS;
  payload: NewWelcomeContentSectionInDto
}

export interface NewWelcomeContentSectionError {
  type: typeof NEW_WELCOME_CONTENT_SECTION_ERROR;
  payload: string
  | boolean;
}

export interface ResetNewWelcomeContentSection {
  type: typeof RESET_NEW_WELCOME_CONTENT_SECTION;
  payload: null;
}

export interface State {
  data: NewWelcomeContentSectionInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | NewWelcomeContentSection
  | NewWelcomeContentSectionSuccess
  | NewWelcomeContentSectionError
  | ResetNewWelcomeContentSection
