import * as Yup from "yup";

export const schema = Yup.object().shape({
  facebook_pixel_id: Yup.string(),
});

interface FormValues {
  facebook_pixel_id: string;
}

export const initialValues: FormValues = {
  facebook_pixel_id: "",
};
