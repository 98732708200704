import styled from "styled-components";

export const Experts = styled("section")`
  min-height: 100vh;
  padding: 100px 0px;
`;

export const Title = styled("h1")`
  margin: 0px 0px 30px;
  font-size: 48px;
  line-height: 1;
  text-align: center;
`;

export const SubTitle = styled("p")`
  margin: 0px auto 30px;
  font-size: 16px;
  max-width: 500px;
  text-align: center;
`;
export const ExpertsList = styled("div")`
  margin: 50px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: flext-start;
  justify-content: space-between;
`;
export const Expert = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 200px;
`;
export const Picture = styled("div")`
  width: 160px;
  height: 160px;
  border-radius: 100%;
  background-color: gray;
  margin-bottom: 15px;
`;
export const Name = styled("div")`
  font-weight: bold;
  font-size: 16px;
`;
export const Experience = styled("div")`
  font-weight: lighter;
  font-size: 14px;
`;
