import { CardsGrid, EmptyStateCard } from "./styles";

const Component = () => {
  return (
    <CardsGrid>
      <EmptyStateCard />
      <EmptyStateCard />
      <EmptyStateCard />
      <EmptyStateCard />
      <EmptyStateCard />
      <EmptyStateCard />
      <EmptyStateCard />
      <EmptyStateCard />
      <EmptyStateCard />
      <EmptyStateCard />
      <EmptyStateCard />
      <EmptyStateCard />
    </CardsGrid>
  );
};

export default Component;
