import { globals } from "../globals";

export const form = ({ primary, secondary }) => {
  const colors = globals.colors({ primary, secondary })

  return {
    skin: {
      base: {
        idle: {
          color: colors.Black,
          placeHolderColor: colors.Gray68,
          backgroundColor: "white",
          borderColor: colors.Gray16,
          iconColor: "transparent",
        },
        disabled: {
          color: colors.Black24,
          placeHolderColor: colors.Black,
          backgroundColor: "#F5F5F5",
          borderColor: colors.Black24,
          iconColor: "transparent",
        },
        active: {
          color: colors.Black,
          placeHolderColor: colors.Primary,
          backgroundColor: "white",
          borderColor: colors.Primary,
          iconColor: colors.Primary,
        },
        hover: {
          color: colors.Black,
          placeHolderColor: colors.Black,
          backgroundColor: "white",
          borderColor: colors.Primary,
          iconColor: "transparent",
        },
        focus: {
          color: colors.Black,
          placeHolderColor: colors.Primary,
          backgroundColor: "white",
          borderColor: colors.Primary,
          iconColor: colors.Primary,
        },
        error: {
          color: colors.Danger,
          placeHolderColor: colors.Danger,
          backgroundColor: "white",
          borderColor: colors.Danger,
          iconColor: colors.Danger,
        },
      },
    },
    size: {
      md: {
        fontSize: "16px",
        lineHeight: "19px",
        mobileFontSize: "14px",
        mobileLineHeight: "17px",
        height: "40px",
        padding: "16px",
      },
    },
  }
};
