import styled from "styled-components";

export const Title = styled("div")`
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
`;

export const Text = styled("p")`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 16px 0px 32px;
`;

export const SubscriptionInfoItem = styled("span")`
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 3px;
  display: block;
`;


export const AlignEnd = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const CheckWordMatchInput = styled("input")`
  border: 2px solid #ccc;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
  width: 300px;
`

export const Error = styled("p")`
  margin-top: 20px;
  font-size:12px;
  display: flex;
  align-items: end;
  justify-content: flex-end;
  width: 100%;
  color: #ff0000;
`
