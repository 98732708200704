import { Step } from "./styles";

const Component = ({ step, state, label, onClick }) => {
  return (
    <Step onClick={onClick} state={state}>
      {label}
    </Step>
  );
};

export default Component;
