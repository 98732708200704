import { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import { SlideContent } from './styles'
import Sidebar from "../../../../../../components/Sidebar";
import Button from "../../../../../../components/Button";
import StepOne from "./components/StepOne"
import StepTwo from "./components/StepTwo"
import { group } from "console";
import { Group } from "../../../../../../types/group";

interface ComponentProps {
  group: Group,
  createInvitationStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  }
  isOpened: boolean;
  onClose: () => void;
}

const Component = ({ group, createInvitationStates, isOpened, onClose }: ComponentProps) => {
  const dispatch = useDispatch();
  const totalSlides = 2;


  const [index, setIndex] = useState(0);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  const handleNextButtonPressed = () => {
    if (index === totalSlides - 1) {
      return;
    } else {
      setIndex(index + 1);
    }
  };

  const handlePrevButtonPressed = () => {
    setIndex(index - 1);
  };

  useEffect(() => {
    if (createInvitationStates.success) {
      handleNextButtonPressed();
    }
  }, [createInvitationStates]);

  return (
      <Sidebar
        position="right"
        visible={isOpened}
        closeType="back"
        onClose={() => {
          //the sidebar is outside of formik context so reseting the form is not working. I implement this solution to move foward.
          if (cancelButtonRef?.current) {
            cancelButtonRef.current.click();
          }

          setIndex(0);
          onClose();
        }}
        width="570px"
        title="Invitaciones con Acceso Libre"
      >
        <CarouselProvider
          currentSlide={index}
          naturalSlideWidth={305}
          naturalSlideHeight={800}
          disableKeyboard={true}
          totalSlides={totalSlides}
          isIntrinsicHeight={true}
          dragEnabled={false}
          touchEnabled={false}
          infinite={false}
        >
          <Slider>
            <Slide index={0}>
              <SlideContent>
                <StepOne cancelButtonRef={cancelButtonRef} onClose={onClose} />
              </SlideContent>
            </Slide>

            <Slide index={0}>
              <SlideContent>
                <StepTwo />
              </SlideContent>
            </Slide>
          </Slider>
        </CarouselProvider>
      </Sidebar>
  );
};

const state = ({ groupStore, invitationStore }) => {
  const { data: group } = groupStore.group;
  const { data: createdInvitation, states: createInvitationStates } = invitationStore.createInvitation;

  return {
    group,
    createdInvitation,
    createInvitationStates
  };
};

export default connect(state)(Component);
