import {
  initialState,
  Actions,
  State,
  NEW_WELCOME_CONTENT_POST,
  NEW_WELCOME_CONTENT_POST_SUCCESS,
  NEW_WELCOME_CONTENT_POST_ERROR,
  RESET_NEW_WELCOME_CONTENT_POST,
} from "../../types/welcome-content/new-welcome-content-post";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case NEW_WELCOME_CONTENT_POST:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case NEW_WELCOME_CONTENT_POST_SUCCESS:
      console.log('NEW_WELCOME_CONTENT_POST_SUCCESS', action.payload)
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case NEW_WELCOME_CONTENT_POST_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_NEW_WELCOME_CONTENT_POST:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
