import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
} from "react-router-dom";

import Tabs from "./components/Tabs";
import DashboardLayout from "../../../components/Layout/DashboardLayout";
import ModulesProvider from "../../../providers/ModulesProvider";

import Edit from "./Edit";
import New from "./New";
import List from "./List";
import Preview from "./Preview";
import Members from "./Members";
import Group from "../Group";
import Modules from "./Modules";
import PostsList from "../Posts/List";
import PostsNew from "../Posts/New";
import PostsEdit from "../Posts/Edit";
import SessionList from "../Sessions/List";
import SessionNew from "../Sessions/New";
import SessionEdit from "../Sessions/Edit";
import SurveyList from "../Survey/List";

import Configuration from "../Configuration";
import WelcomeContentPostNew from "../WelcomeContentPost/New";
import WelcomeContentPostEdit from "../WelcomeContentPost/Edit";

import Price from '../Price'

import { connect } from "react-redux";

const Component = ({ group }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}/`}
        render={() => <Redirect to={`${path}/list`} />}
      />
      <Route
        path={`${path}/list`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={1}>
            <List {...props} />
          </DashboardLayout>
        )}
      />
      <Route
        path={`${path}/new`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <New {...props} />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <ModulesProvider useLocation={useLocation}>
              <Preview {...props} />
            </ModulesProvider>
          </DashboardLayout>
        )}
      />

      <Route
        exact
        path={`${path}/edit/:idGroup`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            {/* <Group {...props}> */}
            <Edit {...props} />
            {/* </Group> */}
          </DashboardLayout>
        )}
      />


      <Route
        path={`${path}/:idGroup/configuration`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <Configuration {...props}>
              <Tabs
                active="configuration"
                hiddenTabs={group?.recorded ? ["surveys", "library", "configuration"] : ['modules']}
              />
            </Configuration>
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/welcome-content/new`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <WelcomeContentPostNew {...props} />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/welcome-content/:idPost`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <WelcomeContentPostEdit {...props} />
          </DashboardLayout>
        )}
      />

      {/* <Route
        path={`${path}/:idGroup/categories`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <Category {...props} />
          </DashboardLayout>
        )}
      /> */}

      <Route
        path={`${path}/:idGroup/members`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <Members {...props} />
          </DashboardLayout>
        )}
      />

      <Route
        exact
        path={`${path}/:idGroup/modules`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <ModulesProvider useLocation={useLocation}>
              <Modules {...props} />
            </ModulesProvider>
          </DashboardLayout>
        )}
      />

      <Route
        exact
        path={`${path}/:idGroup/posts/`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <PostsList {...props}>
              <Tabs
                active="library"
                hiddenTabs={group?.recorded ? ["surveys", "library", "configuration"] : ['modules']}
              />
            </PostsList>
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/posts/new`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <PostsNew {...props} groupType="experience" />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/posts/:idPost`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <PostsEdit {...props} />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/sessions/`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <SessionList {...props}>
              <Tabs
                active="sessions"
                hiddenTabs={group?.recorded ? ["surveys", "library", "configuration"] : ['modules']}
              />
            </SessionList>
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/sessions/new`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <SessionNew {...props} />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/sessions/:session`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <SessionEdit {...props} />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/surveys`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <SurveyList {...props}>
              <Tabs
                active="surveys"
                hiddenTabs={group?.recorded ? ["surveys", "library", "configuration"] : ['modules']}
              />
            </SurveyList>
          </DashboardLayout>
        )}
      />

      <Route
        exact
        path={`${path}/:idGroup/price`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <Price {...props}>
              <Tabs
                active="price"
                hiddenTabs={group?.recorded ? ["surveys", "library", "configuration"] : ['modules']}
              />
            </Price>
          </DashboardLayout>
        )}
      />

      <Route exact path={`${path}/*`} render={() => <Redirect to={"/404"} />} />
    </Switch >
  );
};

const state = ({ groupStore }) => {
  const { data: group } = groupStore.group;

  return {
    group,
  };
};

export default connect(state)(Component);
