import styled from "styled-components";
import { Link as RrdLink } from "react-router-dom";
import ImageUi from "../../../components/Image";

export const Title = styled.h1`
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  margin: 0px 0px 16px;
  color: white;
  text-align: center;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 56px;
    line-height: 64px;
    margin: 0px 0px 15px;
  }
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    line-height: 72px;
  }
`;

export const SubTitle = styled.p`
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  margin: 0px 0px 30px;
  color: white;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 20px;
    line-height: 32px;
    margin: 0px 0px 30px;
  }
`;

export const Link = styled(RrdLink)<{ to }>`
  margin: 0px;
  padding: 0px;
  line-height: 23px;
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.PurpleVeryLight};
  background: white;
  border-radius: 8px;
  height: 52px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  min-width: 230px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 18px;
    min-width: 280px;
  }
`;

export const ImageWrapper = styled.div`
  padding: 20px 25px;
  border-radius: 8px;
  background: #f0f2f5;
  margin: 0px 0px 40px;
  border-radius: 16px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 50px 53px;
    border-radius: 25px 0px 0px 25px;
    margin: 0px;
  }
`;

export const Image = styled(ImageUi)`
  max-width: 100%;
  height: auto;
  border-radius: 16px;
  display: block;
  margin: 0 auto;
`;

export const Denomination = styled.div`
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding-top: 15px;
  }
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    padding-top: 20px;
  }
`;
