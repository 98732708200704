import Modal from "./../../../../../../components/Modal";
import congratulation from "../../../../../../assets/congratulation.svg";
import { Image, Title, Text } from "./styles";


const Component = () => {
  return (
    <Modal name="section-congratulation" width="334" showClose={false}>
      <Image src={congratulation} alt="Congrat" />
      <Title>🎉 Terminaste tu bienvenida</Title>
      <Text>Redirigiendo...</Text>
    </Modal>
  );
};

export default Component;
