import { CSSProperties } from "react"
import { Text, Card } from './styles'
import Link from '../../../../../components/Link'
import constants from "../../../../../constants"
import { connect } from "react-redux"
import { Group, User } from "../../../../../types"

interface ComponentProps {
  style?: CSSProperties
  group: Group
  user: User
}

const Component = ({ style, group, user }: ComponentProps) => {
  return (
    <Card {...(!!style ? { style: { ...style } } : {})}>
      <Text>¿Necesitas ayuda?</Text>
      <div style={{ height: 14 }} />
      <Text>Estamos aquí para resolverlo juntos.</Text>
      <div style={{ height: 14 }} />
      <Text>
        <Link
          options={{ skin: 'purple', size: 'sm', type: 'link', native: false }}
          style={{ fontWeight: 500 }}
          href={`${constants.whatsapp.url}?phone=${constants.whatsapp.number
            }&text=${constants.whatsapp.retrying_payment.replace("{name}", user?.name).replace("{email}", user?.email)}`}
          target="_blank"
        >
          Hablar con soporte
        </Link>
      </Text>
    </Card>
  )
}

const state = ({ groupStore, userStore }) => {
  const { data: group } = groupStore?.group;
  const { data: user } = userStore.information;
  return { group, user };
};

export default connect(state)(Component);