import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { connect } from "react-redux";

const expertAudience = [
  "EXPERT_SIGN_IN",
  "EXPERT_SIGN_UP",
  "NEW_EXPERIENCE",
  "LIST_EXPERIENCE",
  "PREVIEW_EXPERIENCE",
  "NEW_MEMBERSHIP",
  "LIST_MEMBERSHIP",
  "PREVIEW_MEMBERSHIP",
  "PREVIEW_COMMUNITY",
  "HOME_PAGE",
  "COMPLETE_PROFILE",
  "SURVEY",
];

const Component = ({ currentPage }) => {
  const pixelId: string = process.env.REACT_APP_FACEBOOK_PIXEL_ID || "";
  const advancedMatching = undefined;
  const options = {
    autoConfig: true,
    debug: false,
  };

  const init = () => {
    ReactPixel.init(pixelId, advancedMatching, options);
    ReactPixel.pageView();
  };

  useEffect(() => {
    if (expertAudience.includes(currentPage)) {
      init();
    }
  }, [currentPage]);

  return <></>;
};

const state = ({ pageStore }) => {
  const { name: currentPage } = pageStore;

  return {
    currentPage,
  };
};

export default connect(state)(Component);
