import styled from "styled-components";

export const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px 0px 30px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: 72px 0px 50px;
  }
`;
