import styled, { css } from "styled-components";
import { rainbowTextGradient } from "../../../StyledComponents";

export const SectionName = styled("h4")`
  margin: 0px;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  text-align: center;
  ${rainbowTextGradient};

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    letter-spacing: 2.5px;
  }
`;

export const Title = styled("h1")`
  margin: 0px;
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 42px;
    line-height: 42px;
  }
`;
