import {
  Card,
  Top,
  Actions,
  Link,
  Button,
  Title,
  Author,
  Date,
  Day,
  Number,
  Information,
  GoToPreview,
  Background,
  FreeLabel,
  Circle,
  FutureDate,
  Reactions,
  EmojiWithCounter,
  Hour
} from "./styles";
import schedulerIcon from "../../../../../assets/scheduler-icon.svg";


import sessionEditIcon from "../../../../../assets/icons/session-edit-icon.svg";
import sessionPinUncheckedIcon from "../../../../../assets/icons/session-pin-unchecked-icon.svg";
import sessionPinCheckedIcon from "../../../../../assets/icons/session-pin-checked-icon.svg";
import sessionCopyLinkIcon from "../../../../../assets/icons/session-copy-link-icon.svg";

import { connect, useDispatch } from "react-redux";
import { SessionInDto } from "../../../../../types/session.dto";
import { useEffect, useMemo, useState } from "react";
import constants from "../../../../../constants";
import CopyToClipboad from "../../../../../components/CopyToClipboad";
import { useMarkdown } from "../../../../../hooks/useMarkdown";
import KeyIcon from "../../../../../assets/icons/key-2.svg";
import Icon from "../../../../../components/Icon";
import { DateSpliteDto, dateSpliter, sessionExpired, sessionInProgress } from "../../../../../helpers/dates-hanlder";
import { useLocation } from "react-router-dom";

import background1 from "../../../../../assets/backgrounds/membership/background-1.svg";
import background2 from "../../../../../assets/backgrounds/membership/background-4.svg";
import background3 from "../../../../../assets/backgrounds/membership/background-5.svg";
import { State } from "../../../../../components/GroupCard/styles";
import chevronDownIcon from "../../../../../assets/icons/chevron-down.svg";
import { Separator } from "../../../../../components/StyledComponents";

const backgrounds = [background1, background2, background3];
const randomBg = Math.floor(Math.random() * backgrounds.length);
interface ComponentProps {
  session: SessionInDto;
  isMine?: boolean;
  groupInformation;
  userInformation;
  onDelete?;
  style?;
  bigCard?: boolean;
  showActions?: boolean;
  showPinAction?: boolean;
  onUnpinIt?: React.Dispatch<React.SetStateAction<{
    idSession: number;
    pinned: boolean;
  } | undefined>>
  link: string
  expired?: boolean
}

const showFeatureSessionCta = ["experience", "membership"];
const Component = ({
  onDelete,
  session,
  isMine = false,
  groupInformation,
  userInformation,
  style,
  bigCard = false,
  showActions = true,
  showPinAction = true,
  onUnpinIt,
  link,
  expired
}: ComponentProps) => {
  const { typeMap, markMap, jsonStr, textStr } = useMarkdown({
    brief: session?.description,
  });
  // const dispatch = useDispatch();
  const [date, setDate] = useState<DateSpliteDto>();
  // const [ownGroup, setOwnGroup] = useState(false);
  // const [isInProgress, setIsInProgress] = useState<boolean>();
  // const [useExpertInfo, setUseExpertInfo] = useState<boolean>(false);
  // const [isExpired, setIsExpired] = useState<boolean>();
  // const [hastRecordedSessionUrl, setHastRecordedSessionUrl] =
  //   useState<boolean>();
  // const [image, setImage] = useState<string>();
  const location = useLocation();


  const sessionReactions = useMemo(() => {
    if (session?.session_reactions) {
      const result = session?.session_reactions.reduce((acc, item) => {
        const emoji = item.reaction_emoji;

        // Create an array for the emoji if it doesn't exist in the result
        if (!acc[emoji]) {
          acc[emoji] = [];
        }

        // Push the current item to the array for the current emoji
        acc[emoji].push(item);

        // Return the accumulator (acc)
        return acc;
      }, {});

      return result;
    }

    return [];
  }, [session]);


  useEffect(() => {
    setDate(dateSpliter(session.session_datetime));
    // setIsInProgress(sessionInProgress(session.session_datetime));
    // setUseExpertInfo(session?.use_profile_info);
    // setIsExpired(sessionExpired(session.session_datetime));
    // setHastRecordedSessionUrl(!!session.recorded_session_url);
  }, [session]);

  // useEffect(() => {
  //   if (!!groupInformation && !!userInformation) {
  //     setOwnGroup(groupInformation?.expert?.id === userInformation?.expert?.id);
  //   }
  // }, [groupInformation, userInformation]);

  // useEffect(() => {
  //   if (!!session) {

  //     if (session.published_at)
  //       setPublishedAt(dateSpliter(session?.published_at));
  //     else
  //       setPublishedAt(dateSpliter(session?.schedule_publish_at))

  //     setHasAttached(session?.attached_files?.length > 0 || !!session?.attached_url);

  //     if (!!session?.attached_files || !!session?.cover_pic) {
  //       let attachmentImage = backgrounds[randomBg];

  //       if (session?.attached_files.length > 0) {
  //         attachmentImage =
  //           (!!session?.attached_files &&
  //             session?.attached_files?.filter((file) =>
  //               file?.mime?.includes("image")
  //             )[0]?.url) ||
  //           constants.fileUploader?.icons[session?.attached_files[0].mime];
  //       }

  //       setImage(session?.cover_pic?.url || attachmentImage);
  //     }
  //   }
  // }, [session]);

  return (
    <Card style={{ ...style }} bigCard={bigCard}>
      <Background className="background" backgroundImage={session?.cover_pic?.url || backgrounds[randomBg]} bigCard={bigCard} />

      <Top>
        <Date>
          <Day>{date?.month.name.short}</Day>
          <Number>{date?.day.number}</Number>
        </Date>

        {/* {showActions && (
            <Actions className="article-actions">
              {(ownGroup || isMine) && (
                <>
                  {showPinAction && showFeatureSessionCta.includes(groupInformation?.group_type) && (
                    <Circle>
                      <Button
                        onClick={() => {
                          dispatch(
                            setFeaturedSession({
                              idSession: session?.id,
                              pinned: !session?.pinned,
                            })
                          );

                          if (!!onUnpinIt) {
                            onUnpinIt({
                              idSession: session?.id,
                              pinned: !session?.pinned,
                            })
                          }
                        }}
                        options={{
                          size: "sm",
                          type: "filled",
                          skin: "purple",
                        }}
                      >
                        <Icon
                          icon={
                            session?.pinned
                              ? sessionPinCheckedIcon
                              : sessionPinUncheckedIcon
                          }
                          size="14px"
                          color={"black"}
                        />
                      </Button>
                    </Circle>
                  )}

                  <Circle>
                    <CopyToClipboad
                      copy={`${process.env.REACT_APP_SITE_BASE_URL}/group/${groupInformation?.id}/p/${session.id}`}
                    >
                      <Icon icon={sessionCopyLinkIcon} size="14px" color={"black"} />
                    </CopyToClipboad>
                  </Circle>

                  <Circle>
                    <Link
                      href={`/dashboard/${groupInformation?.group_type === "free_experience"
                        ? "community/events"
                        : groupInformation?.group_type
                        }/${groupInformation?.id}/sessions/${session.id}`}
                      options={{
                        native: true,
                        size: "sm",
                        type: "filled",
                        skin: "purple",
                      }}
                    >
                      <Icon icon={sessionEditIcon} size="14px" color={"black"} />
                    </Link>
                  </Circle>
                </>
              )}
            </Actions>
          )} */}
      </Top>

      {/* <GoToPreview to={`/group/${groupInformation?.id}/s/${session.id}`} bigCard={bigCard}> */}
      <GoToPreview to={{ pathname: `/group/${session?.id}/s/${session.id}`, state: { from: location?.pathname } }} bigCard={bigCard}>
        <Title>{session.title}</Title>
        {!expired && <Hour>{`${date?.day.name.short} ${date?.day.number} de  ${date?.month.name.short}, ${date?.hours}:${date?.minutes}hs (${date?.timeZone?.country})`}</Hour>}
        {sessionReactions && Object.keys(sessionReactions).length > 0 && (
          <Information>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Reactions reactions={sessionReactions}>
                {Object.keys(sessionReactions)?.map((reaction) => (
                  <EmojiWithCounter>
                    <div className='reaction-container'>
                      <div className='emoji'>{reaction} </div>
                      <div className='counter'>{sessionReactions[reaction].length}</div>
                    </div>
                  </EmojiWithCounter>
                ))}
              </Reactions>
            </div>
          </Information>
        )}
      </GoToPreview>
    </Card>
  );
};

const state = ({ groupStore, userStore }) => {
  const { data: userInformation } = userStore.information;

  return {
    userInformation,
  };
};

export default connect(state)(Component);
