import styled from "styled-components";
import { Link as UiLink } from "react-router-dom";

export const Card = styled("div")`
  padding: 13px 11px 9px 16px;
  background: white;
  border-radius: 16px;
  display: grid;
  grid-template-columns: auto 15px 52px 1fr;
  grid-gap: 0px;
  row-gap: 13px;

  div {
    &:nth-child(n + 5) {
      grid-column: span 4;
    }
  }
`;

export const Percent = styled("div")`
  color: ${(props) => props.theme.colors.Primary};
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -2px;
  text-align: center;
`;

export const Divider = styled("div")`
  width: 1px;
  background: linear-gradient(
    180deg,
    rgba(45, 52, 54, 0.75),
    rgba(45, 52, 54, 0.75) 50%,
    transparent 0,
    transparent
  );
  background-size: 1px 5px;
  margin: 0px 7px;
`;

export const Code = styled("div")`
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  color: white;
  border-radius: 4px;
  padding: 1px 3px;
  background-color: ${(props) => props.theme.colors.Primary};
  text-align: center;
`;

export const IconWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 44px;
  width: 44px;
  border-radius: 100%;
  border: 2px solid ${(props) => props.theme.colors.Primary};
  box-sizing: border-box;
  padding: 4px;

  svg {
    width: 28px;
    fill: ${(props) => props.theme.colors.Primary};
  }
`;

export const Span = styled("span")`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: rgba(45, 52, 54, 0.75);
`;

export const Title = styled("div")`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #2d3436;
`;

export const Valid = styled("div")`
  margin-top: 6px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #2d3436;
`;
