import { put, all, call, select, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NEW_MODULE,
  NEW_MODULE_SUCCESS,
  NEW_MODULE_ERROR,
} from "../types/module/new-module";

import {
  UPDATE_MODULE,
  UPDATE_MODULE_SUCCESS,
  UPDATE_MODULE_ERROR,
} from "../types/module/update-module";

import {
  DELETE_MODULE,
  DELETE_MODULE_SUCCESS,
  DELETE_MODULE_ERROR,
} from "../types/module/delete-module";

import {
  GET_ALL_MODULES,
  GET_ALL_MODULES_SUCCESS,
  GET_ALL_MODULES_ERROR,
  UPDATE_CURRENT_MODULES,
} from "../types/module/all-modules";

import {
  REORDER_MODULES,
  REORDER_MODULES_SUCCESS,
  REORDER_MODULES_ERROR,
} from "../types/module/reorder-modules";

import {
  PUBLISH_MODULES,
  PUBLISH_MODULES_ERROR,
  PUBLISH_MODULES_SUCCESS,
  PublishModules,
  RESET_PUBLISH_MODULES,
  ResetPublishModules,
} from "../types/module/publish-modules";

import {
  RESTORE_MODULES,
  RESTORE_MODULES_ERROR,
  RESTORE_MODULES_SUCCESS,
  RestoreModules,
  RESET_RESTORE_MODULES,
  ResetRestoreModules,
} from "../types/module/restore-modules";


import {
  DELETE_POST_ERROR,
  DELETE_POST_SUCCESS,
} from "../types/post/delete-post";

import { GET_POSTS_SUCCESS } from "../types/post/get-posts";
import { Select } from "../../components/FormikForm/PhoneInput/styles";
import { UPDATE_CURRENT_GROUP_SUCCESS } from "../types/group/get-group";

const allModules = ({ modulesStore }) => modulesStore?.allModules.data;
const currentGroup = ({ groupStore }) => groupStore?.group.data;

function* publishModules({ payload }) {
  const { response, error } = yield call(services.modules.publishModules, payload);

  if (error) {
    yield put({ type: PUBLISH_MODULES_ERROR, payload: error });
  } else {
    const oldGroup = yield select(currentGroup);

    yield all([
      put({ type: PUBLISH_MODULES_SUCCESS, payload: response }),
      put({ type: UPDATE_CURRENT_GROUP_SUCCESS, payload: { ...oldGroup, status: "active" } })
    ]);
  }
}

function* restoreModules({ payload }) {
  const { response, error } = yield call(services.modules.restoreModules, payload);

  if (error) {
    yield put({ type: RESTORE_MODULES_ERROR, payload: error });
  } else {
    yield all([
      put({ type: RESTORE_MODULES_SUCCESS, payload: response }),
      put({ type: GET_ALL_MODULES_SUCCESS, payload: response })
    ]);
  }
}

function* getAllModules({ payload }) {
  const { response, error } = yield call(services.modules.getAll, payload);

  if (error) {
    yield put({ type: GET_ALL_MODULES_ERROR, payload: error });
  } else {
    yield put({ type: GET_ALL_MODULES_SUCCESS, payload: response });
  }
}

function* newModule({ payload }): any {
  try {
    const { response, error } = yield call(services.modules.new, payload);

    if (error) {
      yield put({ type: NEW_MODULE_ERROR, payload: error });
    } else {
      const oldModules = yield select(allModules);

      yield all([
        put({ type: NEW_MODULE_SUCCESS, payload: response }),
        put({ type: GET_ALL_MODULES_SUCCESS, payload: [...oldModules, response] }),
      ]);
    }
  } catch {
    yield put({
      type: NEW_MODULE_ERROR,
      payload: "Error al crear un nuevo módulo.",
    });
  }
}

function* deleteModule({ payload }): any {
  try {
    const { response, error } = yield call(services.modules.delete, payload);
    const oldModules = yield select(allModules);

    if (error) {

      yield all([
        put({ type: DELETE_MODULE_ERROR, payload: error }),
        put({ type: GET_ALL_MODULES_SUCCESS, payload: oldModules }),
      ]);
    } else {
      yield all([
        put({ type: DELETE_MODULE_SUCCESS, payload: response }),
        put({ type: GET_ALL_MODULES_SUCCESS, payload: oldModules.filter(oldModule => oldModule.id !== payload.id) }),
      ]);
    }
  } catch (error) {
    yield put({
      type: DELETE_MODULE_ERROR,
      payload: "Error al eliminar módulo.",
    });
  }
}

function* updateModule({ payload }): any {
  try {
    const { response, error } = yield call(services.modules.update, payload);

    if (error) {
      yield put({ type: UPDATE_MODULE_ERROR, payload: error });
    } else {
      const modules = yield select(allModules);
      const updatedModules = modules?.map((module) => {
        if (payload.id === module.id) {
          return { ...module, ...response };
        }

        return module;
      });

      yield all([
        put({ type: UPDATE_MODULE_SUCCESS, payload: response }),
        put({ type: UPDATE_CURRENT_MODULES, payload: updatedModules }),
      ]);
    }
  } catch (error) {
    yield put({
      type: REORDER_MODULES_ERROR,
      payload: "Error al actualizar módulos",
    });
  }
}

function* reorderModules({ payload }): any {
  try {
    const { response, error } = yield call(services.modules.reorder, payload);
    const oldOrder = yield select(allModules);

    if (error) {
      yield all([
        put({ type: REORDER_MODULES_ERROR, payload: error }),
        put({ type: GET_ALL_MODULES_SUCCESS, payload: oldOrder }),
      ]);
    } else {
      yield all([
        put({ type: REORDER_MODULES_SUCCESS, payload: response }),
        put({ type: GET_ALL_MODULES_SUCCESS, payload: response }),
      ]);
    }
  } catch (error) {
    yield put({
      type: REORDER_MODULES_ERROR,
      payload: "Error al reordenar módulos",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(NEW_MODULE, newModule);
  yield takeLatest<any>(UPDATE_MODULE, updateModule);
  yield takeLatest<any>(DELETE_MODULE, deleteModule);
  yield takeLatest<any>(GET_ALL_MODULES, getAllModules);
  yield takeLatest<any>(REORDER_MODULES, reorderModules);
  yield takeLatest<any>(PUBLISH_MODULES, publishModules);
  yield takeLatest<any>(RESTORE_MODULES, restoreModules);

}
