import styled from "styled-components";
import UiButton from "../../../../components/Button";

export const StepNumber = styled.div`
  margin-right: 5px;
`;

export const Text = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Explanation = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 24px;
`;

export const Button = styled(UiButton)`
  margin-bottom: 32px;
  cursor: pointer;
  border: none;
  display: inline-block;
`;

export const Label = styled("div")<{ ref?; for? }>`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`;

export const P = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: rgba(36, 40, 44, 0.76);
  margin-left: 8px;
`;
