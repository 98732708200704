import {
    initialState,
    Actions,
    State,
    EDIT_INVITATION,
    EDIT_INVITATION_SUCCESS,
    EDIT_INVITATION_ERROR,
  } from "../../types/invitation/edit";
  
  const reducers = (state = initialState, action: Actions): State => {
    switch (action.type) {
      case EDIT_INVITATION:
        return {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        };
      case EDIT_INVITATION_SUCCESS:
        return {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        };
      case EDIT_INVITATION_ERROR:
        return {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        };
      default:
        return state;
    }
  };
  
  export default reducers;
  