import { Subscription } from "react-hook-form/dist/utils/createSubject";

export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_ERROR = "GET_SUBSCRIPTION_ERROR";
export const RESET_GET_SUBSCRIPTION = "RESET_GET_SUBSCRIPTION";

export interface GetSubscription {
    type: typeof GET_SUBSCRIPTION;
    payload: number;
}

export interface GetSubscriptionSuccess {
    type: typeof GET_SUBSCRIPTION_SUCCESS;
    payload: Subscription;
}

export interface GetSubscriptionError {
    type: typeof GET_SUBSCRIPTION_ERROR;
    payload: string | boolean;
}

export interface ResetGetSubscription {
    type: typeof RESET_GET_SUBSCRIPTION;
    payload: null;
}

export interface State {
    data: Subscription | null;
    states: {
        success: boolean;
        loading: boolean;
        error: boolean | string;
    };
}

export const initialState: State = {
    data: null,
    states: {
        success: false,
        loading: false,
        error: false,
    },
};

export type Actions =
    | GetSubscription
    | GetSubscriptionSuccess
    | GetSubscriptionError
    | ResetGetSubscription;
