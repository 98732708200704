export const VALIDATE_EXPERT_USERNAME = "VALIDATE_EXPERT_USERNAME";
export const VALIDATE_EXPERT_USERNAME_SUCCESS =
  "VALIDATE_EXPERT_USERNAME_SUCCESS";
export const VALIDATE_EXPERT_USERNAME_ERROR = "VALIDATE_EXPERT_USERNAME_ERROR";

export interface ValidateExpertUsername {
  type: typeof VALIDATE_EXPERT_USERNAME;
  payload: string;
}
export interface ValidateExpertUsernameSuccess {
  type: typeof VALIDATE_EXPERT_USERNAME_SUCCESS;
  payload: any;
}
export interface ValidateExpertUsernameError {
  type: typeof VALIDATE_EXPERT_USERNAME_ERROR;
  payload: boolean | string;
}

export interface State {
  data: any;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | ValidateExpertUsername
  | ValidateExpertUsernameSuccess
  | ValidateExpertUsernameError;
