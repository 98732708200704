import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useHistory } from 'react-router-dom'
import { initialValues, schema } from "../../../../constants/forms/membership/edit";
import Link from '../../../../components/Link'
import Grid from '../../../../components/Grid'
import Submit from "../../../../components/FormikForm/Submit";
import { useLayout } from "../../../../providers/LayoutProvider";
import services from "../../../../services";
import { getGroupsFilePath } from "../../../../services/storage";
import { hideModal, showModal } from "../../../../store/actions/modal";
import { NewFileInDto } from "../../../../types/storage.dto";
import Snackbar from "../../../../components/Snackbar";

import Congratulations from "../../components/ModalCongratulations";
import Form from "../../components/GroupForm"

import { useFileUploadProgress } from "../../../../providers/FileUploader";
import Loader from "../../../../components/LogoLoader";
import { getGroupById, resetUpdateGroup, updateGroup } from "../../../../store/actions/group";


const Component = ({ match, groupInformation, groupInformationStates, updateGroupStates, updateGroupData, userInformation }) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const { layout, setLayout } = useLayout();
  const timestamp = new Date().getTime();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const { uploadFiles, resetFileUploadProgress } = useFileUploadProgress();

  useEffect(() => {
    if (match?.params?.idGroup && (!groupInformation || (!!groupInformation && groupInformation?.id !== Number(match?.params?.idGroup)))) {
      dispatch(getGroupById({ group_id: match.params.idGroup }))
    }
  }, [match])

  const onSubmit = async ({ values, actions }) => {
    const thereAreFilesToUpload = values && (values.cover_pic?.some(file => file instanceof File) || values.group_video?.some(file => file instanceof File));

    if (thereAreFilesToUpload) {
      dispatch(showModal("modal-wait-a-minute-bis"));
    }

    try {
      if (thereAreFilesToUpload) {
        const uploadedFiles = await uploadFiles(
          {
            cover_pic: values.cover_pic,
            group_video: values.group_video,
          },
          userInformation.id,
          "group"
        );

        const updatedValues = {
          ...values,
          cover_pic: uploadedFiles?.cover_pic && uploadedFiles?.cover_pic.length >= 0 ? uploadedFiles?.cover_pic[0] : null,
          group_video: uploadedFiles?.group_video ? uploadedFiles?.group_video[0] : null
        };

        dispatch(updateGroup(updatedValues));
      } else {
        // If there are no files to upload, dispatch update directly
        const updatedValues = {
          ...values,
          cover_pic: values?.cover_pic && values?.cover_pic[0] ? values?.cover_pic[0] : null,
          group_video: values?.group_video && values?.group_video[0] ? values?.group_video[0] : null
        };

        dispatch(updateGroup(updatedValues));
      }
    } catch (error) {
      console.error('Error during file uploads:', error);
    } finally {
      dispatch(hideModal());
    }
  }

  useEffect(() => {
    if (!!updateGroupStates && updateGroupStates.success) {
      setTimeout(() => {
        const groupId = updateGroupData?.id;
        dispatch(resetUpdateGroup());
        resetFileUploadProgress();
        dispatch(hideModal());
        history.push(`/dashboard/membership/${groupId}`);
      }, 3500);
    }
  }, [updateGroupStates, updateGroupData]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: "Editar membresía 🙏✨",
        description: "Editar membresía",
        back: `/dashboard/membership/list`,
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: true,
        actions: [
          <Submit
            isSubmmiting={updateGroupStates.loading}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            form="new-membership-form"
          >
            Editar Membresía
          </Submit>,
        ],
      },
    });
  }, []);

  if (!groupInformation || groupInformationStates.loading) {
    return <Loader />;
  }


  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col sm={6}>
          <Formik
            initialValues={{
              ...initialValues,
              ...groupInformation,
              group_video: groupInformation?.group_video ? [groupInformation?.group_video] : [],
              cover_pic: groupInformation?.cover_pic ? [groupInformation?.cover_pic] : [],
              group_type: "membership"
            }}
            onSubmit={(values, actions) => {
              if (onSubmit) onSubmit({ values, actions });
            }}
            validateOnChange={false}
            validateOnBlur={formSubmmited}
            validationSchema={schema}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setFieldError,
              setFieldTouched,
              handleChange,
              handleBlur,
              handleReset,
              handleSubmit,
            }) => {
              return (
                <form
                  className="theme-form"
                  onSubmit={(event) => {
                    setFormSubmmited(true);
                    handleSubmit(event);
                  }}
                  id="edit-membership-form"
                >
                  <Form
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    setFieldTouched={setFieldTouched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isEdition
                  />
                </form>
              );
            }}
          </Formik>

        </Grid.Col >
      </Grid.Row >

      <div style={{ height: 40 }} />

      <Grid.Row>
        <Grid.Col>
          <Link
            style={{ marginRight: "8px" }}
            options={{
              type: "outline",
              size: "lg",
              skin: "purple",
              native: true,
            }}
            href="/dashboard/membership/list"
          >
            Cancelar
          </Link>

          <Submit
            isSubmmiting={updateGroupStates.loading}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            form="edit-membership-form"
          >
            Editar membresía
          </Submit>
        </Grid.Col>
      </Grid.Row>

      <Congratulations page="Experiencia" />

      <Snackbar
        visible={updateGroupStates.success || !!updateGroupStates.error}
        options={{
          time: 2000,
          type: updateGroupStates.success ? "success" : "error",
        }}
      >
        {updateGroupStates.success
          ? "Membresía editada."
          : "Error al editar la membresía."}
      </Snackbar>

      <div style={{ height: "50px" }} />
    </Grid.Container >
  )
}

const state = ({ carouselStore, groupStore, userStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: userGroups, states: userGroupsStates } = userStore.groups;
  const { currentSlide } = carouselStore;
  const { data: updateGroupData, states: updateGroupStates } = groupStore.updateGroup;
  const { data: groupInformation, states: groupInformationStates } = groupStore.group;

  return {
    currentSlide,
    updateGroupStates,
    updateGroupData,
    userInformation: userInformation,
    userGroups,
    userGroupsStates,
    groupInformation,
    groupInformationStates
  };
};

export default connect(state)(Component);