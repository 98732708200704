import { Group } from "../../../../types";

export const UPDATE_CURRENT_GROUP = "UPDATE_CURRENT_GROUP";
export const UPDATE_CURRENT_GROUP_SUCCESS = "UPDATE_CURRENT_GROUP_SUCCESS";
export const UPDATE_CURRENT_GROUP_ERROR = "UPDATE_CURRENT_GROUP_ERROR";

export interface UpdateCurrentGroup {
  type: typeof UPDATE_CURRENT_GROUP;
  payload: string;
}

export interface UpdateCurrentGroupSuccess {
  type: typeof UPDATE_CURRENT_GROUP_SUCCESS;
  payload: Group | null;
}

export interface UpdateCurrentGroupError {
  type: typeof UPDATE_CURRENT_GROUP_ERROR;
  payload: boolean | string;
}
