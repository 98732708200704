import styled from "styled-components";
import UiLink from '../../../../components/Link';
import Image from "../../../../components/Image";
import conffetiImage from "../../../../assets/conffeti-bg.jpg"

export const Column = styled('div')`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 45px 20px;
  max-width: 375px;
  margin: 0 auto;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0px;
    left: 0px;
    // background-image: url(${conffetiImage});
    // background-repeat: repeat-x;
  }
`

export const Title = styled("h3")`
  margin: 0px;
  font-size: 24px;
  font-weight: 500;
`;

export const Message = styled('p')`
  text-align: center;
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
`

export const ProfileImage = styled(Image)`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 2px solid white;
  display: block;
  border: 3px solid #00E5FF;
`

export const SmallMessage = styled('p')`
  text-align: center;
  margin: 0px;
  font-size: 16px;
`

export const Link = styled('a')`
  margin: 0px;
  border: 0px;
  border-radius: ${({ theme }) => theme.button.size['lg'].borderRadius};
  font-size: ${({ theme }) => theme.button.size['lg'].fontSize};
  height: ${({ theme }) => theme.button.size['lg'].height};
  width: 100%;
  max-width: 346px;
  font-weight: 500;
  letter-spacing: 0.2px;
  background-color: #0C0C0C;
  color: white;
  transition: all .1s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  gap: 8px;

  &:hover{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
`