import getPaginationString from "../helpers/getPaginationString";
import action from "./../helpers/rest-client";

export class ErrorReaction extends Error { }

class Reaction {
  public async addReaction({ postId, subscriberId, reaction_emoji }) {
    return action
      .Post({
        url: `/api/post/add-reaction`,
        body: { postId, subscriberId, reaction_emoji }
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async removeReaction({ idReaction }) {
    return action
      .Del({
        url: `/api/post/remove-reaction/${idReaction}`
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const reaction = new Reaction();
export default reaction;
