import { useContext, useMemo } from "react";
import { connect } from "react-redux";
import dateHandlder from "../../../../helpers/dates-hanlder";
import { SubscriptionContext } from "../../../../providers/Subscription";
import { Group, SessionInDto, User } from "../../../../types";
import { Button } from "./styles";
import { isOwnGroup } from "../../../../helpers/isOwnGroup";
import Icon from '../../../../components/Icon';
import arrowLinkIcon from '../../../../assets/icons/arrow_forward_up_right-semibold.svg'
import { Post } from "../../../../types/post";
import { getPriorityGroupSubscription } from "../../../../helpers/mpStatusPriority";
interface ComponentProps {
  subscriptions;
  user: User;
  group: Group;
  post: Post;
  session: SessionInDto
}

const Component = ({
  subscriptions,
  user,
  group,
  post,
  session
}: ComponentProps) => {
  const { retrySubscription } = useContext(SubscriptionContext);

  const groupId = useMemo(() => {
    return post?.group?.id || session?.group?.id || group?.id
  }, [group, post, session])

  const subscription = useMemo(() => {
    return getPriorityGroupSubscription(subscriptions, groupId)
  }, [subscriptions, groupId])

  const plan = useMemo(() => {
    return subscription?.plan
  }, [subscription])

  const suscriptionEnded = useMemo(() => {
    if (!!group?.registration_closing_date) {
      return dateHandlder.isExpired(group?.registration_closing_date)
    }

    return false
  }, [group])

  return (
    <Button
      onClick={() => { retrySubscription(plan, subscription) }}
      disabled={
        suscriptionEnded ||
        (!!group && !!user?.expert && isOwnGroup({ group, expertId: user?.expert?.id }))
      }
      options={{
        size: "lg",
        skin: "lightGray",
        type: "filled",
      }}
    >
      Actualizar método de pago
      <Icon icon={arrowLinkIcon} color={'white'} size="20px" />
    </Button>
  );
};

const state = ({ userStore, groupStore, postStore, sessionStore }) => {
  const { data: group } = groupStore.group;
  const { data: post } = postStore.post;
  const { data: session } = sessionStore.one;
  const { data: subscriptions } = userStore.subscriptions;
  const { data: user } = userStore.information;

  return {
    user,
    subscriptions,
    group,
    post,
    session
  };
};

export default connect(state)(Component);
