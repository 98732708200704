import { Expert } from "../../../types";

export const GET_EXPERT_INFORMATION = "GET_EXPERT_INFORMATION";
export const GET_EXPERT_INFORMATION_SUCCESS = "GET_EXPERT_INFORMATION_SUCCESS";
export const GET_EXPERT_INFORMATION_ERROR = "GET_EXPERT_INFORMATION_ERROR";

export interface GetExpertInformation {
  type: typeof GET_EXPERT_INFORMATION;
  payload: string;
}
export interface GetExpertInformationSuccess {
  type: typeof GET_EXPERT_INFORMATION_SUCCESS;
  payload: Expert;
}
export interface GetExpertInformationError {
  type: typeof GET_EXPERT_INFORMATION_ERROR;
  payload: boolean | string;
}

export interface State {
  data: Expert | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetExpertInformation
  | GetExpertInformationSuccess
  | GetExpertInformationError;
