import { combineReducers } from "redux";
import onePlan from "./get-plan";
import allPlans from "./get-plans";
import newPlan from "./new-plan";
import editPlan from "./edit-plan";
import deletePlan from "./delete-plan";
import selectedPlan from './selected-plan'

const reducer = combineReducers({
  selectedPlan,
  onePlan,
  allPlans,
  newPlan,
  editPlan,
  deletePlan,
});

export default reducer;
