import {
  DeleteNotificationOutDto,
  DeleteNotificationInDto,
} from "../../../types/notification";

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_ERROR = "DELETE_NOTIFICATION_ERROR";
export const RESET_DELETE_NOTIFICATION = "RESET_DELETE_NOTIFICATION";

export interface DeleteNotification {
  type: typeof DELETE_NOTIFICATION;
  payload: DeleteNotificationOutDto;
}

export interface DeleteNotificationSuccess {
  type: typeof DELETE_NOTIFICATION_SUCCESS;
  payload: DeleteNotificationInDto | null;
}

export interface DeleteNotificationError {
  type: typeof DELETE_NOTIFICATION_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteNotification {
  type: typeof RESET_DELETE_NOTIFICATION;
  payload: null;
}

export interface State {
  data: DeleteNotificationInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | DeleteNotification
  | DeleteNotificationSuccess
  | DeleteNotificationError
  | ResetDeleteNotification;
