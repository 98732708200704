import styled from "styled-components";
import Button from "../../../../components/Button";

export const AddBenefit = styled(Button)`
  margin-bottom: 36px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin-bottom: 48px;
  }
`;
