import styled from "styled-components";
import { Link } from "react-router-dom";

export const CardsGrid = styled("div")`
  display: grid;
  gap: 16px;
  grid-auto-rows: 237px;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
`;

export const SectionTitle = styled("h2")`
  margin: 32px 0px 16px 0px;
  font-size: 18px;
  font-weight: normal;
`;

export const InLine = styled("div")`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const MoreContent = styled(Link)`
  text-decoration: none;
  border-bottom: 1px solid;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
`;

export const HorizontalScrollWrapper = styled('div')`
  margin: 0px 0px 0px -10px;

  @media(min-width: 768px){
    margin: 0px calc(-1 * ((100vw * 8.33333%) + 10px));
  }
`

export const HorizontalScroll = styled('div')`
  overflow-x: scroll;
  display: flex;
  padding: 0px 10px;
  scroll-snap-type: x mandatory;
  scroll-padding: 0px 10px;

  ::-webkit-scrollbar {
    display: none;
  }

  & > * {
    scroll-snap-align: start;
    max-width: 270px;

    &:not(:last-child){
      margin-right: 16px;
    }
  }
`