import {
  initialState,
  Actions,
  State,
  GET_COMMUNITY_SUBSCRIBERS_EMAILS,
  GET_COMMUNITY_SUBSCRIBERS_EMAILS_SUCCESS,
  GET_COMMUNITY_SUBSCRIBERS_EMAILS_ERROR,
} from "../../types/community/subscribers-emails";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_COMMUNITY_SUBSCRIBERS_EMAILS:
      return {
        ...state,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case GET_COMMUNITY_SUBSCRIBERS_EMAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case GET_COMMUNITY_SUBSCRIBERS_EMAILS_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: true,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default reducers;
