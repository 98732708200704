import { useEffect, useMemo, useState } from "react";
import Grid from '../../../../../components/Grid'
import { connect, useDispatch } from "react-redux";
import { Separator } from "../../../../../components/StyledComponents";
import { Group } from "../../../../../types";
import { GetPostsOutDto, Post } from "../../../../../types/post";
import CardAticle from "../../../../Dashboard/Posts/components/CardArticle";
import { Filters, Filter, Input, Label, CardsGrid, Total, GlobalStyle, Title } from "./styles";
import EmptyState from "../../../components/EmptyState";
import InfinityScroll from '../../../../../components/InfinityScroll'
import { getAllCategories } from "../../../../../store/actions/category";
import { CategoryDto } from "../../../../../types/category";
import { getPosts, resetGetPosts } from "../../../../../store/actions/post";
import Skeleton from '../Skeleton'

interface ComponentProps {
  posts: { pagination: { total: number }; data: Post[] };
  postsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  allCategories: CategoryDto[];
  allCategoriesState: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  posts,
  postsStates,
  group,
  allCategories,
}: ComponentProps) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [thereAreItems, setThereAreItems] = useState(true)
  const [mergedItems, setMergedItems] = useState<Post[]>([])
  const [totalElements, setTotalElements] = useState<number>(0)
  const [startPagination, setStartPagination] = useState<boolean>(false)
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);

  const categories = useMemo(() => {
    return [{
      id: "show-all",
      name: "Todos",
      count_posts: totalElements
    },
    ...(allCategories ? allCategories : [])
    ]
  }, [allCategories, totalElements])

  const fetchData = ({ page, content_categories }: { page: number, content_categories?: string }) => {
    if (!postsStates.loading) {
      setStartPagination(true)
      let payload: GetPostsOutDto = { filters: { group_id: group?.id, show_scheduled_post: true, ...(content_categories ? { content_categories } : {}) }, pagination: { page, per_parge: 12 } };
      dispatch(getPosts(payload));
    }
  }

  /** Start the new pagination with new filter */
  const filterByCategory = (category) => {
    const newSelectedCategory = category === "show-all" ? undefined : category;
    setMergedItems([])
    setPage(1)
    setSelectedCategory(newSelectedCategory);
    fetchData({ page: 1, content_categories: newSelectedCategory });
  }

  /** Paginate with the already selected filter */
  const paginate = (page) => {
    fetchData({ page, content_categories: selectedCategory });
  }

  useEffect(() => {
    dispatch(getAllCategories({ group_id: group?.id }));

    return () => {
      dispatch(resetGetPosts())
      setMergedItems([])
    }
  }, [])

  useEffect(() => {
    if (!!posts?.data && startPagination) {
      const newItemsObj = [...mergedItems, ...posts.data]
      setMergedItems(newItemsObj);
      setThereAreItems(!!posts && posts?.data?.length > 0 && newItemsObj.length < posts?.pagination?.total);

      /** Setting the total of elements without filters */
      if (!selectedCategory) {
        setTotalElements(posts.pagination.total)
      }
    }
  }, [posts])


  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col lg={10} offset={{ lg: 1 }}>

          <GlobalStyle />

          <Title>Biblioteca de Contenidos</Title>

          <Separator height={{ desktop: "16px", mobile: "16px" }} />

          <Filters>
            {categories?.filter(category => category?.count_posts && category?.count_posts > 0).map((category) => {
              return (
                <Filter key={`category-filter-${category.id}`}>
                  <Input
                    name="filter"
                    type="radio"
                    id={`filter-${category.id}`}
                    value={category.id}
                    onChange={(event) => {
                      if (event.target.checked) {
                        filterByCategory(event.target.value)
                      }
                    }}
                    checked={(selectedCategory === String(category.id)) || (!selectedCategory && category.id === 'show-all')}
                  />
                  <Label htmlFor={`filter-${category.id}`}>
                    {category.name} {category?.count_posts && <Total>{category?.count_posts}</Total>}
                  </Label>
                </Filter>
              );
            })}
          </Filters>

          <Separator height={{ desktop: "16px", mobile: "16px" }} />

          <InfinityScroll
            setPage={setPage}
            data={mergedItems}
            isLoading={postsStates.loading}
            page={page}
            action={paginate}
            active={thereAreItems}
            EmptyState={Skeleton}
          >
            {(!mergedItems || mergedItems?.length <= 0) && !postsStates.loading && postsStates.success ? (
              <div style={{ textAlign: "center" }}>
                <EmptyState text={
                  <>
                    <strong>Aún no se escribio ningún artículo.</strong>
                    <br /> Aguarda a que se comience a crearlos.
                  </>
                } />
              </div>
            ) : (
              <CardsGrid>
                {!!mergedItems && mergedItems?.length > 0 && (
                  <>
                    {mergedItems?.map((post, index) => (
                      <CardAticle
                        post={post}
                        groupInformation={group}
                        showPinAction={false}
                        coverPicHeight={100}
                      />
                    ))}
                  </>
                )}
              </CardsGrid>
            )}
          </InfinityScroll>

          <Separator height={{ desktop: "32px", mobile: "16px" }} />
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

const state = ({ userStore, postStore, groupStore, categoryStore }) => {
  const { data: allCategories, states: allCategoriesState } =
    categoryStore.allCategories;
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;
  const { data: userInformation, states: userInformationStates } =
    userStore.information;
  const { data: posts, states: postsStates } = postStore.listPosts;
  const { data: group, states: groupStates } = groupStore.group;

  return {
    subscriptions,
    subscriptionsStates,
    userInformation,
    userInformationStates,
    posts,
    postsStates,
    group,
    groupStates,
    allCategories,
    allCategoriesState,
  };
};

export default connect(state)(Component);