import { useState } from 'react'
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import constants from '../../../../constants/'
import { deletePost, resetDeletePost } from "../../../../store/actions/post";
import { Post } from "../../../../types/post";
import { useFileUploadProgress } from "../../../../providers/FileUploader";
import { abortUpload } from '../../../../services/storage'
import { Title, Text, Image, ProgressBar, ProgressBarWrapper, FileName } from "./style";

interface ComponentProps {
  postInformation: Post;
  newPostStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  editPostStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  deletePostStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ postInformation, newPostStates, editPostStates, deletePostStates }: ComponentProps) => {
  const { fileUploadProgress, resetFileUploadProgress } = useFileUploadProgress();
  const [elapsedTime, setElapsedTime] = useState('');
  const dispatch = useDispatch();

  const handleDeletePost = () => {
    dispatch(deletePost(postInformation?.id));
  };

  const onModalClose = () => {
    if (deletePostStates.success) {
      dispatch(resetDeletePost());

      setTimeout(() => {
        window.history.back();
      }, 500);
    }
  };

  // function elapsedTimeString(startDate: Date): string {
  //   const currentDate: Date = new Date();
  //   const elapsedMilliseconds: number = currentDate.getTime() - startDate.getTime();

  //   // Calculate elapsed time in different units
  //   const seconds: number = Math.floor(elapsedMilliseconds / 1000);
  //   const minutes: number = Math.floor(seconds / 60);
  //   const hours: number = Math.floor(minutes / 60);
  //   const days: number = Math.floor(hours / 24);

  //   // Build the string representation
  //   if (days > 0) {
  //     return `${days} day${days !== 1 ? 's' : ''} ago`;
  //   } else if (hours > 0) {
  //     return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  //   } else if (minutes > 0) {
  //     return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  //   } else {
  //     return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
  //   }
  // }

  // useEffect(() => {
  //   const startDate = new Date(); 

  //   const intervalId = setInterval(() => {
  //     const elapsed = elapsedTimeString(startDate);
  //     setElapsedTime(elapsed);
  //   }, 1000); 

  //   return () => clearInterval(intervalId); 
  // }, []);


  return (
    <Modal
      name="modal-wait-a-minute-bis"
      width="650"
      onClose={() => onModalClose()}
      showClose={false}
    >
      <Title>⏳ Aguarda un instante... </Title>
      <Text>Estamos subiendo los archivos de tu artículo</Text>
      <Text>
        Dependiendo del tamaño del archivo y de tu conexión a Internet, este proceso podría tardar algunos minutos. Por lo tanto, te pedimos que no cierres el navegador ni abandones esta pantalla.
      </Text>
      <div style={{ height: 30 }} />
      {!!fileUploadProgress && fileUploadProgress.map((file) => {
        const ext = file?.originalName?.slice((file?.originalName?.lastIndexOf(".") - 1 >>> 0) + 2);
        let lastIndex = file?.originalName?.lastIndexOf('.');
        let name = lastIndex !== -1 ? file?.originalName?.substring(0, lastIndex) : file?.originalName;
        const preview = file?.fileType?.includes('image') ? file?.preview : constants.fileUploader.icons[file.fileType];
        return (
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 20, width: '100%' }}>
              <Image style={{ backgroundImage: `url(${preview})` }} />

              <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', width: '100%', maxWidth: 400 }}>
                <FileName><span>{name}</span>.{ext}</FileName>

                <ProgressBarWrapper>
                  <ProgressBar style={{ width: `${file.progress}%` }} className={`${file.progress < 100 ? "active-bar" : ""} ${file.progress === 100 ? "finished" : ""}`} />
                </ProgressBarWrapper>
                {/* <span>{parseFloat(file.progress?.toFixed(0))}%</span> */}
              </div>
            </div>
            <div style={{ height: 15 }} />
          </>
        )
      })}

      <div style={{ height: 15 }} />

      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 20, width: '100%' }}>
        {/* <span>{elapsedTime}</span> */}
        <Button
          style={{ marginRight: "8px" }}
          options={{
            type: "outline",
            size: "lg",
            skin: "purple",
          }}
          disabled={newPostStates.loading || newPostStates.success || editPostStates.loading || editPostStates.success}
          onClick={() => {
            abortUpload();
            resetFileUploadProgress();
          }}
        >
          Cancelar
        </Button>
      </div>
    </Modal>
  );
};

const state = ({ postStore }) => {
  const { data: postInformation } = postStore.post;
  const { states: deletePostStates } = postStore.deletePost;
  const { states: newPostStates } = postStore.newPost;
  const { states: editPostStates } = postStore.editPost;

  return {
    postInformation,
    deletePostStates,
    newPostStates,
    editPostStates
  };
};

export default connect(state)(Component);
