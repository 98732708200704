import styled from "styled-components";

const resetFieldStyles = `
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  outline: none;
  border: 0px;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  width: 100%;`;

export const TextArea = styled("textarea") <{ size; maxLength }>`
  ${resetFieldStyles}
  resize: none;
  font-size: ${({ theme, size = "md" }) => theme.form.size[size].fontSize};
  line-height: ${({ theme, size = "md" }) => theme.form.size[size].lineHeight};
  font-family: ${({ theme }) => theme.fonts.family};
  padding: 0px;
  margin: 0px;
  ${({ maxLength }) => (maxLength ? "margin-bottom: 20px;" : "")}

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;
