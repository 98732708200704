import ReactPixel from "react-facebook-pixel";
import { useHistory } from "react-router-dom";
import Input from "../../../components/Form/Input";
import Error from "../../../components/Form/Error";
import Button from "../../../components/Button";
import Col from "../../../components/Grid/Col";
import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import AvatarEditor from "../../../components/Form/AvatarEditor";
import {
  FormTitle,
  Explanation,
  Form,
  UsernameReqsText,
  Profile,
} from "./styles";
import { createNewExpert } from "../../../store/actions/user";
import { connect, useDispatch } from "react-redux";
import Whatsapp from "./../../../components/Whatsapp";
import { useForm } from "react-hook-form";
import services from "../../../services";
import { useEffect, useMemo } from "react";
import Loader from "../../../components/LogoLoader";
import { getUrlParamByName } from "../../../helpers/get-url-param";

const Component = ({
  match,
  createExpertStates,
  userInformationStates,
  userInformation,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    success: createExpertSuccess,
    loading: createExpertLoading,
    error: createExpertError,
  } = createExpertStates;

  const { handleSubmit, register, reset, setValue, formState } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: useMemo(() => {
      return { username: userInformation?.expert?.username };
    }, [userInformation]),
  });

  const onFormSubmit = (data) => {
    dispatch(createNewExpert(data));
  };

  const onFormError = (data) => {
    console.log("Form Error:", data);
  };

  let typingTimeout;
  const existingUser = async (username: string) => {
    if (typingTimeout) clearTimeout(typingTimeout);

    return new Promise((resolve, reject) => {
      //delay until user stops typing

      typingTimeout = setTimeout(async () => {
        //check if username is valid
        if (!username) return resolve(true);

        const { response, error } = await services.expert.validateExpert(
          username
        );

        if (error) {
          resolve("Error inesperado al validar el nombre de usuario");
        }

        resolve(
          !response?.id ||
            "Este nombre de usuario no está disponible. Prueba con otro."
        );
      }, 1000);
    });

    // const { response, error } = await services.expert.validateExpert(username);

    // if (error) {
    //   return "Error al validar el nombre de usuario.";
    // }

    // if (response) {
    //   return (
    //     !response.id ||
    //     "Este nombre de usuario no está disponible. Prueba con otro."
    //   );
    // }
  };

  useEffect(() => {
    if (
      getUrlParamByName("p") &&
      getUrlParamByName("p") !== "" &&
      getUrlParamByName("p") !== "0-5.000 seguidores" &&
      !!userInformation
    ) {
      ReactPixel.trackCustom("QualifiedExpertRegister", {
        value: userInformation.id,
      });
    }
  }, [match, userInformation]);

  useEffect(() => {
    if (!!userInformation?.expert) {
      reset({
        username: userInformation?.expert.username,
      });
    }

    if (!!userInformation?.expert?.username) {
      history.push("/dashboard");
    }
  }, [userInformation]);

  if (userInformationStates.loading || !!userInformation?.expert?.username) {
    return <Loader />;
  }

  return (
    <Container>
      <Row>
        <Col sm={4} offset={{ sm: 4 }}>
          <Profile>
            <FormTitle>Completa tu perfil</FormTitle>

            <Explanation>
              Añade una <span>foto de perfil</span> y un{" "}
              <span>nombre de usuario</span> para que tu comunidad te reconozca
            </Explanation>

            <Form
              onSubmit={handleSubmit(onFormSubmit, onFormError)}
              id="complete-profile"
            >
              <AvatarEditor
                name="profile_pic"
                picture={userInformation?.expert?.profile_pic?.url}
                setValue={setValue}
                register={register}
                errors={{ ...formState.errors }}
              />

              <UsernameReqsText>
                Los nombres de usuario solo pueden contener letras, números,
                guiones bajos y puntos.
              </UsernameReqsText>

              <Input
                disabled={!!userInformation?.expert?.username}
                placeholder="Nombre de usuario"
                name="username"
                register={register}
                rules={{
                  required: "Ingresa un nombre de usuario.",
                  validate: existingUser,
                  pattern: {
                    value: /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/i,
                    message:
                      "Los nombres de usuario solo pueden contener letras, números, guiones bajos y puntos.",
                  },
                }}
                value={userInformation?.expert?.username}
                errors={{ ...formState.errors }}
                options={{
                  marginBottom: 42,
                }}
              />

              {createExpertError === "Internal Server Error" && (
                <Error>Se produjo un error, intente más tarde.</Error>
              )}

              <Button
                form="complete-profile"
                type="submit"
                disabled={createExpertLoading}
                loading={createExpertLoading}
                options={{
                  skin: "purple",
                  size: "sm",
                  type: "filled",
                }}
              >
                Continuar
              </Button>
            </Form>
          </Profile>
          <Whatsapp floating />
        </Col>
      </Row>
    </Container>
  );
};

const state = ({ userStore }) => {
  const { data: userInformation, states: userInformationStates } =
    userStore.information;
  const { states: createExpertStates } = userStore.createNewExpert;
  return {
    createExpertStates,
    userInformationStates,
    userInformation,
  };
};

export default connect(state)(Component);
