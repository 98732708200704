import { CategoryDto } from "../../../types/category";

export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_ERROR = "GET_ALL_CATEGORIES_ERROR";

export interface GetAllCategories {
  type: typeof GET_ALL_CATEGORIES;
  payload: CategoryDto;
}

export interface GetAllCategoriesSuccess {
  type: typeof GET_ALL_CATEGORIES_SUCCESS;
  payload: CategoryDto[] | null;
}

export interface GetAllCategoriesError {
  type: typeof GET_ALL_CATEGORIES_ERROR;
  payload: boolean | string;
}

export interface State {
  data: CategoryDto[] | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetAllCategories
  | GetAllCategoriesSuccess
  | GetAllCategoriesError;
