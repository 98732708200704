import styled from "styled-components";

export const Ready = styled.div`
  padding: 25px 15px;
  margin-bottom: 80px;
  border-radius: 18px;
  text-align: center;
  background: ${(props) => props.theme.colors.PurpleVeryLight};
  margin: 0px 10px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    border-radius: 0px;
    padding: 70px 50px;
    margin: 0px 0px 150px;
  }
`;

export const Text = styled.div`
  font-size: 32px;
  line-height: 46px;
  font-weight: 600;
  color: white;
  margin-bottom: 45px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 48px;
    line-height: 60px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    font-size: 50px;
    line-height: 72px;
  }
`;
