import action from "../helpers/rest-client";

export class ErrorSettings extends Error { }

class Preferences {
  public async savePreference({ payload }) {
    const result = await action
      .Post({
        url: `/api/preference/`,
        body: { ...payload },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorSettings("Error al guardar tu preferencia.");
      });

    return result;
  }

  public async getUserPreferences({ userId, userEmail }: { userId?: number, userEmail?: string }) {
    const result = await action
      .Get({
        url: `/api/preference?userId=${userId}&userEmail=${userEmail}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error }
      });

    return result;
  }
}

const currency = new Preferences();
export default currency;
