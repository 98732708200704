import { useModulesPreview } from "../../../../../providers/ModulesPreviewProvider";
import orderIcon from "../../../../../../assets/icons/order.svg";
import Button from "../../../../../components/Button";
import { Card } from "../../styles";
import ModuleCta from "../ModuleCta";
import { List, ListItem } from "./styles";
import { useEffect, useState } from "react";
import { Center } from "../../../../../components/StyledComponents";

interface ComponentProps { }

const Component = ({ }: ComponentProps) => {
  const id = "module-list";
  const { modules, actions } = useModulesPreview();

  if (!!modules && modules?.length <= 0) {
    return <></>
  }

  return (
    <Card
      style={{ width: "100%", maxWidth: "285px", position: "sticky", top: 0, padding: '24px 17px 17px' }}
    >

      <div style={{ textAlign: 'center' }}>Modulos del taller</div>

      <div style={{ height: 24 }} />

      <List key={id}>
        {modules?.map((module, index) => (
          <ListItem key={'module-' + index}>
            <ModuleCta
              module={module}
            />
          </ListItem>
        ))}
      </List>

    </Card>
  );
};

export default Component;
