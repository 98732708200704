import styled from "styled-components";

export const List = styled.ul`
  padding: 0px;
  margin: 0px 0px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ListItem = styled.li`
  padding: 0px 16px;
  margin: 0px;
  list-style: none;

  &:first-child {
    padding-left: 0px;
  }

  &:last-child {
    padding-right: 0px;
  }
`;

export const SocialNetwork = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  background-color: transparent;
  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.footer.socialNetworks.background};
  }
`;