import { connect } from "react-redux";
import Helmet from "react-helmet";
import { User } from "../../../types";
import { Wrapper } from "./styles";

interface ComponentProps {
  userData: User;
}

const Component = ({ userData }: ComponentProps) => {
  if (!userData) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Klouser - Encuesta</title>
        <script
          src="//embed.typeform.com/next/embed.js"
          type="text/javascript"
        />
      </Helmet>
      <Wrapper
        id="wrapper"
        data-tf-widget={process.env.REACT_APP_TYPEFORM}
        data-tf-inline-on-mobile
        data-tf-hidden={`user_id=${userData.id},email=${
          userData.email
        },initial_profile=${""}`}
      />
    </>
  );
};

const state = ({ userStore }) => {
  const { information } = userStore;
  const { data: userData } = information;

  return {
    userData,
  };
};

export default connect(state)(Component);
