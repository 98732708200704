import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Post from './modules/Post'
import Button from '../../../../../components/Button'
import { useLayout } from "../../../../../providers/LayoutProvider";
import { useModulesPreview } from "../../../../../providers/ModulesPreviewProvider";
import { Center, Separator } from "../../../../../components/StyledComponents";
import TelegramBanner from "../../../ActivitySummary/components/TelegramBanner";
import userAvatar from "../../../../../assets/avatar.svg";
import {
  AvatarImage,
  Avatar,
  Name,
  CreatedAt,
} from './styles'
import { Card } from '../styles'
import { dateSpliter } from "../../../../../helpers/dates-hanlder";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import { isSubscribed } from "../../../../../helpers/isSubscribed";
import { isOwnGroup } from "../../../../../helpers/isOwnGroup";
import { Subscription, User } from "../../../../../types";

interface ComponentProps {
  userInformation;
  userSubscriptions;
  group
  groupStates
  isLoggedIn: boolean
  subscriptions: Subscription[];
  user: User;
}

const Component = ({ userInformation, userSubscriptions, group, isLoggedIn, subscriptions, user,
  groupStates }: ComponentProps) => {
  const { isMobile } = useWindowSize()
  const { actions, pagination, selectedModule } = useModulesPreview();
  const { layout, setLayout } = useLayout();
  // const [createdAt, setCreatedAt] = useState<DateSpliteDto>();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [isOwner, setIsOwner] = useState(false);


  useEffect(() => {
    const isLoggedIn = !!userInformation;
    const isSubscribed =
      userSubscriptions?.filter(
        (subscription) => subscription.id === selectedModule?.group?.id
      ).length > 0;
    const isPostOwner =
      !!selectedModule &&
      !!userInformation &&
      selectedModule?.group?.expert?.id === userInformation?.expert?.id;

    setIsOwner(isPostOwner);
    setShowSubscriptionModal(
      (!isLoggedIn || (isLoggedIn && !isSubscribed && !isPostOwner))
    );
  }, [selectedModule, userInformation]);

  useEffect(() => {
    // setCreatedAt(dateSpliter(selectedModule?.published_at));
    setLayout({
      ...layout,
      page: {
        title: `${selectedModule?.group?.group_name} - ${selectedModule?.title}`,
      },
    });
  }, [selectedModule]);

  return (
    <Center horizontal="space" vertical="top" column>
      <Card
        style={{ width: "100%", padding: isMobile ? '10px 14px' : '30px 40px' }}
      >

        <Center horizontal="left" vertical="top" column style={{ backgroundColor: 'white' }}>
          {/* <Center horizontal="left" vertical="top" column>
          <AvatarImage>
            <Avatar
              src={
                group?.profile_pic?.url ||
                group?.expert?.profile_pic?.url ||
                userAvatar
              }
              alt={group?.expert?.username}
              width="36px"
              height="36px"
            />

            <Center horizontal="center" vertical="top" column gap={2}>
              <Name>
                {group?.creator_name ||
                  group?.expert?.username}
              </Name>
              <CreatedAt>{createdAt?.date}</CreatedAt>
            </Center>
          </AvatarImage>
        </Center> */}

          {selectedModule?.content_type === 'post' && <Post />}
          {/* {selectedModule?.content_type === 'post' && <Post /> } */}
        </Center >

        {isMobile &&
          <>
            <div style={{ height: isMobile ? 16 : 24 }} />
            <Center horizontal="space" vertical="top" style={{ backgroundColor: 'white' }} gap={10}>
              <Button
                options={{
                  type: "filled",
                  skin: "purple",
                  size: "lg",
                  block: true
                }}
                onClick={() => actions.prevModule.exec()}
                disabled={pagination.prev === null}
              >Anterior</Button>

              <Button
                options={{
                  type: "filled",
                  skin: "purple",
                  size: "lg",
                  block: true
                }}
                onClick={() => actions.nextModule.exec()}
                disabled={pagination.next === null}
              >Próximo</Button>
            </Center>
          </>
        }
      </Card>

      <Separator height={{ desktop: "32px", mobile: "16px" }} />

      <>
        {
          isLoggedIn &&
          (isSubscribed(subscriptions, group?.id) ||
            isOwnGroup({
              group,
              expertId: user?.expert?.id,
            })) && <TelegramBanner />
        }
      </>

    </Center>
  )

}

const state = ({ userStore, sessionStore, groupStore }) => {
  const { isLoggedIn } = userStore;
  const { data: userInformation } = userStore.information;
  const { data: userSubscriptions } = userStore.subscriptions;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: subscriptions } = userStore.subscriptions;
  const { data: user } = userStore.information;

  return {
    user,
    subscriptions,
    isLoggedIn,
    userInformation,
    userSubscriptions,
    group,
    groupStates
  };
};

export default connect(state)(Component);
