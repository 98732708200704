import ReactDOM from "react-dom";
import { Toolbar, Actions } from "./styles";
import { useWindowSize } from "../../../../../../../../hooks/useWindowSize";

interface ComponentProps {
  children;
}

const FooterSticky = document.getElementById("footer-sticky") as HTMLElement;

const Component = ({ children }: ComponentProps) => {
  const { isMobile } = useWindowSize();

  return isMobile ? (
    ReactDOM.createPortal(
      <Toolbar>
        <Actions>{children}</Actions>
      </Toolbar>,
      FooterSticky
    )
  ) : (
    <Actions>{children}</Actions>
  );
};

export default Component;
