import { EditPlanInDto, EditPlanOutDto } from "../../../types/plan.dto";
export const EDIT_PLAN = "EDIT_PLAN";
export const EDIT_PLAN_SUCCESS = "EDIT_PLAN_SUCCESS";
export const EDIT_PLAN_ERROR = "EDIT_PLAN_ERROR";
export const RESET_EDIT_PLAN = "RESET_EDIT_PLAN";

export interface EditPlan {
  type: typeof EDIT_PLAN;
  payload: EditPlanOutDto;
}

export interface EditPlanSuccess {
  type: typeof EDIT_PLAN_SUCCESS;
  payload: EditPlanInDto;
}

export interface EditPlanError {
  type: typeof EDIT_PLAN_ERROR;
  payload: string | boolean;
}

export interface ResetEditPlan {
  type: typeof RESET_EDIT_PLAN;
  payload: null;
}

export interface State {
  data: EditPlanInDto | null
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | EditPlan
  | EditPlanSuccess
  | EditPlanError
  | ResetEditPlan;
