import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import { resetNewDiscount } from "../../../../../store/actions/discount";
import { useEffect, useState } from "react";
import { DiscountDto } from "../../../../../types";

const type = {
  membership: "membresía",
  experience: "taller",
};

const Component = ({ allDiscounts, newDiscount }) => {
  const [discount, setDiscount] = useState<DiscountDto>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!allDiscounts && !!newDiscount) {
      setDiscount(
        allDiscounts.filter((discount) => discount.id === newDiscount.id)[0]
      );
    }
  }, [allDiscounts, newDiscount]);

  if (!discount) {
    return null;
  }

  return (
    <Modal
      name="modal-new-coupon-created"
      width="650"
      onClose={() => dispatch(resetNewDiscount())}
    >
      <Title>¡Cupón creado con éxito! 🎟</Title>

      <Text>
        Creaste un cupón de descuento para el {type[discount?.group_type]} “
        {discount?.group_name}”. Ahora puedes compartirlo copiando el link del
        cupón desde la sección Cupones. Tu audiencia podrá utilizarlo ingresando
        el código de descuento en la sección de pagos de tu{" "}
        {type[discount?.group_type]}.
      </Text>

      <AlignEnd>
        <Button
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
          }}
          onClick={() => dispatch(hideModal())}
        >
          Aceptar
        </Button>
      </AlignEnd>
    </Modal>
  );
};

const state = ({ discountStore }) => {
  const { data: allDiscounts, states: allDiscountsStates } =
    discountStore.allDiscounts;
  const { data: newDiscount, states: newDiscountStates } =
    discountStore.newDiscount;

  return { allDiscounts, allDiscountsStates, newDiscount, newDiscountStates };
};

export default connect(state)(Component);
