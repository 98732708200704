import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  Redirect,
} from "react-router-dom";

import SimpleLayout from "../../components/Layout/SimpleLayout";
import RainbowLayout from "../../components/Layout/RainbowLayout";

import Welcome from "./Welcome";
import CompleteProfile from "./CompleteProfile";
import Subscriptions from "./Subscriptions";
import Subscription from "./Subscription";
import SubscriptionsManager from "./SubscriptionsManager";
import MyAccount from "./MyAccount";
import Notifications from "./Notifications";
import Gateways from "./Gateways";
import Analytics from "./Analytics";
import Survey from "./Survey";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getUserGroups, getUserInformation } from "../../store/actions/user";
import DashboardLayout from "../../components/Layout/DashboardLayout";
import ProfileLayout from "../../components/Layout/ProfileLayout";
import UpdateSubscriptionPaymentMethod from "./UpdateSubscriptionPaymentMethod";
import Gateways2 from "./Gateways2";

const Component = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserGroups({}));
    dispatch(getUserInformation());
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={`${path}/`}
        render={() => <Redirect to={`${path}/my-account`} />}
      />

      <Route
        path={`${path}/welcome`}
        render={() => (
          <SimpleLayout useLocation={useLocation}>
            <Welcome />
          </SimpleLayout>
        )}
      />

      <Route
        path={`${path}/my-account`}
        render={() => (
          <DashboardLayout useLocation={useLocation}>
            <ProfileLayout>
              <MyAccount />
            </ProfileLayout>
          </DashboardLayout>
        )}
      />


      <Route
        path={`${path}/gateways`}
        render={() => (
          <DashboardLayout useLocation={useLocation}>
            <ProfileLayout>
              <Gateways2 />
            </ProfileLayout>
          </DashboardLayout>
        )}
      />

      

      <Route
        path={`${path}/notifications`}
        render={() => (
          <DashboardLayout useLocation={useLocation}>
            <ProfileLayout>
              <Notifications />
            </ProfileLayout>
          </DashboardLayout>
        )}
      />

      <Route
        path={`${path}/analytics`}
        render={() => (
          <DashboardLayout useLocation={useLocation}>
            <ProfileLayout>
              <Analytics />
            </ProfileLayout>
          </DashboardLayout>
        )}
      />

      <Route
        path={`${path}/survey`}
        render={() => (
          <SimpleLayout useLocation={useLocation}>
            <Survey />
          </SimpleLayout>
        )}
      />
      <Route
        path={`${path}/complete-profile`}
        render={(props) => (
          <SimpleLayout useLocation={useLocation}>
            <CompleteProfile {...props} />
          </SimpleLayout>
        )}
      />
      <Route
        path={`${path}/subscriptions`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation}>
            <Subscriptions {...props} />
          </DashboardLayout>
        )}
      />
      
      <Route
        path={`${path}/manage-subscriptions/update`}
        render={(props) => (
          <RainbowLayout useLocation={useLocation}><UpdateSubscriptionPaymentMethod /></RainbowLayout>
        )}
      />

      <Route
        path={`${path}/manage-subscriptions`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation}>
            <ProfileLayout>
              <SubscriptionsManager />
            </ProfileLayout>
          </DashboardLayout>
        )}
      />
      {/* <Route
        path={`${path}/subscription/:groupId`}
        render={(props) => (
          <SimpleLayout useLocation={useLocation}>
            <Subscription {...props} />
          </SimpleLayout>
        )}
      /> */}

      <Route path={`${path}/*`} render={() => <Redirect to={"/404"} />} />
    </Switch>
  );
};

export default Component;
