import * as Yup from "yup";

const today = new Date();
today.setHours(0, 0, 0, 0);

export const schema = (maxAmount = 999999999) => {
  return Yup.object().shape({
    amount: Yup.number()
      .nullable() // Allow null values
      .test(
        "is-decimal",
        "El descuento ingresado no es válido.",
        (value) => {
          if (value === null) {
            return false; // Return false to trigger the custom error message
          }
          return !!value && value % 1 === 0;
        }
      )
      .positive("El descuento ingresado no es válido.")
      .integer("El descuento ingresado no es válido.")
      .min(1, "Debes ingresar un descuento.")
      .max(
        maxAmount - 1,
        "El descuento no debe igualar o superar el precio de inscripción."
      )
      .required("Debes ingresar un descuento."),
    valid_till_type: Yup.string()
      .required("Debes seleccionar una opción."),
    valid_till: Yup.date()
      .nullable()
      .when("valid_till_type", {
        is: "por-periodo",
        then: Yup.date()
          .min(today, "Debes indicar una fecha futura.")
          .required("Debes indicar hasta cuándo será válido el descuento."),
      }),
    period: Yup.number()
      .nullable()
      .when("period_type", {
        is: "por-periodo",
        then: Yup.number()
          .min(1, "Debes indicar un periodo mínimo de 1 mes.")
          .required("Debes ingresar un número entero."),
      }),
    status: Yup.string().required("Debes indicar un estado."),
  });
};

interface FormValues {
  name: string
  amount: number | null
  valid_till_type: string
  valid_till?: string
  period_type: string
  period?: string
  status: "active" | "inactive"
}

export const initialValues: FormValues = {
  name: "",
  amount: null,
  valid_till_type: "indefinido",
  period_type: "indefinido",
  status: "active",
};
