import React, { useEffect, useRef, ReactNode } from 'react';
import styled from 'styled-components';

interface HorizontalScrollProps {
  children: ReactNode;
  scrollToIndex?: number;
}

const HorizontalScroll = ({ children, scrollToIndex }: HorizontalScrollProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current && scrollToIndex !== undefined) {
      const container = containerRef.current;
      const targetItem = container.children[scrollToIndex] as HTMLElement;
      if (targetItem) {
        const scrollLeft = targetItem.offsetLeft - container.offsetWidth / 2 + targetItem.offsetWidth / 2;
        container.scrollLeft = scrollLeft;
      }
    }
  }, [scrollToIndex]);

  return (
    <ScrollContainer ref={containerRef}>
      {children}
    </ScrollContainer>
  );
};

const ScrollContainer = styled.div`
  overflow-x: scroll;
  display: flex;
  scroll-snap-type: x mandatory;
  padding: 10px;
  scroll-padding: 0 10px;

  ::-webkit-scrollbar {
    display: none;
  }

  & > * {
    scroll-snap-align: start;
    max-width: 300px !important;
    
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

export default HorizontalScroll;
