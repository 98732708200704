import styled, { keyframes, css } from "styled-components";

export const Title = styled("div")`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

export const Text = styled("p")`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 16px 0px;
`;

export const AlignEnd = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;


export const Image = styled('div')`
  width: 70px;
  height: 70px;
  background-color: #f2f2f2;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;
  overflow: hidden;
`

const progressBarStripes = keyframes`
  from { background-position: 40px 0; }
  to { background-position: 0 0; }
`;

export const ProgressBarWrapper = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 5px;
`;

export const ProgressBar = styled.div`
  background-color: ${({ theme }) => theme.colors.Primary};
  height: 14px;
  border-radius: 4px;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
  transition: background-color .2s ease-in-out;

  &.active-bar {
    -webkit-animation: ${progressBarStripes} 2s linear infinite;
    -o-animation: ${progressBarStripes} 2s linear infinite;
    animation: ${progressBarStripes} 2s linear infinite;
  }

  &.finished {
    background-color: #0ad695;
  }
`;


export const FileName = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 12px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px; /* You can adjust the max-width as needed */
  }
`