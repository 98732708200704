import * as Yup from "yup";

export const schema = Yup.object().shape({
  email: Yup.string()
    .required("Ingresa tu corréo electrónico")
    .trim("El corréo electrónico no puede contener espacio")
    .email("Ingresa un corréo electrónico válido"),
  password: Yup.string().required("Ingresa tu contraseña"),
});

interface FormValues {
  email: string;
  password: string;
}

export const initialValues: FormValues = {
  email: "",
  password: "",
};
