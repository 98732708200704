import styled from 'styled-components'

export const Row = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
`
export const Card = styled('div') <{ featured: boolean }>`
  border: ${({ featured }) => featured ? 2 : 1}px solid ${({ featured, theme }) => featured ? theme.colors.Primary : "#cfcfcf"};
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const Plan = styled('div') <{ featured: boolean }>`
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 12px 25px;
  gap: 12px;
`

export const Discount = styled('div')`
  height: 40px;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  background-color: #F0F0F0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 25px;
`

export const Payments = styled('div')`

`

export const Status = styled('div') <{ status }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-weight: 500;
  font-size: 12px;
  color: ${({ status }) => {
    switch (status) {
      case 'active':
        return '#12B47A';
      case 'inactive':
        return '#B41212';
      default:
        return '#B46912'
    }
  }};

  &:before {
    content: "";
    display: block;
    height: 7px;
    width: 7px;
    border-radius: 100%;
    background-color: ${({ status }) => {
    switch (status) {
      case 'active':
        return '#12B47A';
      case 'inactive':
        return '#B41212';
      default:
        return '#B46912'
    }
  }}
  }
`

export const Description = styled('div')`
  width: 100%;
  font-size: 14px;
  padding: 0px 10px 0px 0px;
`

export const Price = styled('span')`
  color: #6C5CE7;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -2px;
  text-align: center;
  width: 100%;
  height: 100%;
  max-width: max-content;
`

export const ValidTill = styled('span')`
  margin-top: 6px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #2d3436;
`

export const Separator = styled('div')`
  width: 1px;
  background: linear-gradient( 180deg, rgba(45,52,54,0.75), rgba(45,52,54,0.75) 50%, transparent 0, transparent );
  background-size: 1px 5px;
  margin: 0px 7px;
`

export const Frecuency = styled('span')``

export const Button = styled('button')`
  font-weight: normal!important; 
  line-height: 26px!important; 
  color: #2d3436!important;
  width: 100%;
  text-align: right;
  display: flex!important;
  align-items: flex-end!important;
  justify-content: flex-end!important;
  padding: 0px 4px!important;

  &:hover {
    background-color: #6C5CE7;
    color: white!important;
    border-radius: 4px;
    
  }
`
