import styled, { keyframes } from "styled-components";
import { Field as UiField } from "../Field";
import { Field as FormikField } from "formik";

const RadioOn = keyframes`
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px;
  }
`;

const RadioOff = keyframes`
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  50% {
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0;
    height: 0;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px,
      -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
  }
  51% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0,
      0px 0 0 10px inset;
  }
  100% {
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0,
      0px 0 0 0 inset;
  }
`;

const shrinkBounce = keyframes`
  0%{
    transform: scale(1);
  }
  33%{    
    transform: scale(.85);
  }
  100%{
    transform: scale(1);    
  }
}
`;

const RadioCheck = keyframes`
  0%{
    width: 0;
    height: 0;
    border-color: white;
    transform: translate3d(0,0,0) rotate(45deg);
  }
  33%{
    width: 8px;
    height: 10px;
    transform: translate3d(-4px,-8px,0) rotate(45deg);
  }
  100%{    
    width: 8px;
    height: 16px;    
    border-color: white;
    transform: translate3d(-8px,-15px,0) rotate(45deg);
}`;

export const Field = styled(UiField)`
  border: none;
  background: transparent;
  padding: 0;
  align-items: center;
  justify-content: flex-start;
`;

export const Checked = styled('div') <{ checked }>`
  position: absolute;
  left: 2px;
  top: 2px;
  height: 14px;
  width: 14px;
  border: 1px solid white;
  border-radius: 100%;
  box-sizing: content-box;
`
export const Radio = styled("div") <{ size }>`
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: ${(props) => (props.size === "small" ? "16px" : "20px")};
  min-width: ${(props) => (props.size === "small" ? "16px" : "20px")};
  
  &:not(:last-child) {
    margin-right: 32px;
  }

  input {
    margin: 0px 8px 0px 0px;
    -webkit-appearance:none;
    width: 20px;
    height: 20px;
    background: transparent;
    border: 1px solid #939798;
    border-radius: 100%;
    cursor: pointer;
    transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:not(:disabled) {
      &:focus,
      &:active {
        border: 2px solid ${(props) => props.theme.colors.PurpleLight};
        background: transparent;
      }

      &:checked {
        border: 2px solid ${(props) => props.theme.colors.Primary};
        animation: ${shrinkBounce} 200ms cubic-bezier(0.4, 0, 0.23, 1);

        & + ${Checked} {  
          background-color: ${(props) => props.theme.colors.Primary};
        }
      }
    }
  
    &:disabled {
      border: 1px solid #C2C2C2;
      background: transparent;
  
      &:checked {
        border: 1px solid #C2C2C2;
        animation: ${shrinkBounce} 200ms cubic-bezier(0.4, 0, 0.23, 1);
  
        & + ${Checked} {  
          background-color: #C2C2C2;
        }
      }
    }
  }
`;

// export const Input = styled("input") <{}>`
//   display: none;

//   &:not(:disabled) {
//     &:focus ~ label > span,
//     &:active ~ label > span {
//       border: 2px solid ${(props) => props.theme.colors.PurpleLight};
//       background: transparent;
//     }

//     &:checked ~ label > span {
//       border: 2px solid ${(props) => props.theme.colors.Primary};
//       animation: ${shrinkBounce} 200ms cubic-bezier(0.4, 0, 0.23, 1);

//       &:before {
//         content: "";
//         position: absolute;
//         height: 16px;
//         width: 16px;
//         border: 1px solid white;
//         border-radius: 100%;
//         background-color: ${(props) => props.theme.colors.Primary};
//         box-sizing: content-box;
//       }
//     }
//   }

//   &:disabled {
//     & ~ span {
//       border: 1px solid #C2C2C2;
//     }

//     & ~ label > span,
//     & ~ label > span {
//       border: 1px solid #C2C2C2;
//       background: transparent;
//     }

//     &:checked ~ label > span {
//       border: 1px solid #C2C2C2;
//       animation: ${shrinkBounce} 200ms cubic-bezier(0.4, 0, 0.23, 1);

//       &:before {
//         content: "";
//         position: absolute;
//         height: 16px;
//         width: 16px;
//         border: 1px solid white;
//         border-radius: 100%;
//         background-color: #C2C2C2;
//         box-sizing: content-box;
//       }
//     }
//   }
// `;

const paddingLeft = {
  withLabel: {
    small: "24px",
    large: "32px",
  },
  withoutLabel: {
    small: "16px",
    large: "20px",
  },
};

export const OptionLabel = styled("label") <{
  disabled;
  size;
  withLabel;
  theme;
}>`
  position: relative;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) =>
    props.disabled ? "#C2C2C2" : "rgba(0, 0, 24, 0.84)"};
  min-height: ${(props) => (props.size === "small" ? "16px" : "20px")};
  z-index: 2;
  font-size: ${(props) => props.theme.form.size[props.size].mobileFontSize};
  line-height: ${(props) => props.theme.form.size[props.size].mobileLineHeight};
  width: max-content;

  @media (min-width: 768px) {
    font-size: ${(props) => props.theme.form.size[props.size].fontSize};
    line-height: ${(props) => props.theme.form.size[props.size].lineHeight};
  }
`;

// export const Checkmark = styled("span") <{ size; theme }>`
//   flex-shrink: 0;
//   display: flex;
//   position: relative;
//   justify-content: center;
//   align-items: center;
//   margin-right: 8px;
//   width: 20px;
//   height: 20px;
//   background: transparent;
//   border: 1px solid #939798;
//   border-radius: 100%;
//   cursor: pointer;
//   transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
// `;
