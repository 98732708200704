import styled from "styled-components";
import ButtonUi from "../../../../../components/Button";
import LinkUi from "../../../../../components/Link";
import { rainbowBackgroundGradient } from "../../../../../components/StyledComponents";

export const Button = styled(ButtonUi)`
  ${rainbowBackgroundGradient}
  border: 0px;
  height: 45px;
`;

export const Link = styled(LinkUi)`
  ${rainbowBackgroundGradient}
  border: 0px;
  height: 45px;
`;

export const ValidTill = styled("div")`
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  text-align: center;
  margin-top: 8px;
`;
