import { connect } from "react-redux";
import { Group } from "../../../../../types";
import Icon from '../../../../../components/Icon'
import checkIcon from "../../../../../assets/bullet.svg";
import { Section, SectionName, Title } from "../StyledComponents";
import { List, ListItem, Bullet } from "./styles";

interface ComponentProps {
  group: Group;
  groupStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ group }: ComponentProps) => {
  return (
    <Section>
      <SectionName>no te quedes afuera</SectionName>
      <Title>Beneficios de sumarte</Title>
      <div style={{ height: "64px" }} />
      <List length={group?.group_perks?.length}>
        {group?.group_perks?.map((benefit, index) => (
          <ListItem key={`benefit-${index}`}>
            <Bullet>
              <Icon icon={checkIcon} size="32px" />
            </Bullet>
            {benefit}
          </ListItem>
        ))}
      </List>
    </Section>
  );
};

const state = ({ groupStore }) => {
  const { data: group, states: groupStates } = groupStore.group;

  return {
    group,
    groupStates,
  };
};

export default connect(state)(Component);
