import {
  CreateInvitationOutDto,
  CreateInvitationInDto,
} from "../../../types/invitation.dto";

export const CREATE_INVITATION = "CREATE_INVITATION";
export const CREATE_INVITATION_SUCCESS = "CREATE_INVITATION_SUCCESS";
export const CREATE_INVITATION_ERROR = "CREATE_INVITATION_ERROR";
export const RESET_CREATE_INVITATION = "RESET_CREATE_INVITATION";

export interface CreateInvitation {
  type: typeof CREATE_INVITATION;
  payload: CreateInvitationOutDto;
}

export interface CreateInvitationSuccess {
  type: typeof CREATE_INVITATION_SUCCESS;
  payload: CreateInvitationInDto | null;
}

export interface CreateInvitationError {
  type: typeof CREATE_INVITATION_ERROR;
  payload: boolean | string;
}

export interface ResetCreateInvitation {
  type: typeof RESET_CREATE_INVITATION;
  payload: null;
}

export interface State {
  data: CreateInvitationInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | CreateInvitation
  | CreateInvitationSuccess
  | CreateInvitationError
  | ResetCreateInvitation;
