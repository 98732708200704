import {
  initialState,
  Actions,
  State,
  VALIDATE_EXPERT_USERNAME,
  VALIDATE_EXPERT_USERNAME_SUCCESS,
  VALIDATE_EXPERT_USERNAME_ERROR,
} from "../../types/expert/validate-expert-username";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case VALIDATE_EXPERT_USERNAME:
      return {
        ...state,
        data: state.data,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case VALIDATE_EXPERT_USERNAME_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case VALIDATE_EXPERT_USERNAME_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default reducers;
