import { Title } from "./styles";
import { connect, useDispatch } from "react-redux";
import ChatBannerCoreAction from "./components/ChatBannerCoreAction";
import { Separator } from "../../../../../components/StyledComponents";
import { Group } from "../../../../../types";
import { savePreference } from "../../../../../store/actions/preferences";
import { showPreference } from "../../../../../helpers/preferenceVisible";
import React, { useEffect, useState } from "react";


interface ComponentProps {
  group: Group;
  preferences;
  preferencesStates;
  
}

const Component = ({group, preferences, preferencesStates}: ComponentProps) => {

  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(true);


  const isOwnGroup = ({ group, post, user }) => {
    const expertId = group?.expert?.id || post?.group?.expert?.id
    return expertId === user?.expert?.id;
  };

  const handleClose = () => {
    if (!!group) {
      dispatch(
        savePreference({
          preferenceSlug: `core-action-join-groupchat-${group?.id}`,
          preferenceValue: false,
        })
      );

      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (!!group) {
      setIsVisible(showPreference({ preferences, preferencesStates, id: `core-action-join-groupchat-${group?.id}` }));
    }
  }, [preferences, group]);


  // dado que solo hay una recomended action si no hay que mostrarla no se muestra toda la seccion
  if(!group?.telegram_groups || group?.telegram_groups.length <=0 || !isVisible)
    return <></>

  return (
        <>
          <Title>Acciones recomendadas</Title>

          <Separator height={{ desktop: "16px", mobile: "16px" }} />

          <ChatBannerCoreAction 
            onClose={handleClose}
          />

          <div style={{ height: 26 }} />
        </>
  );
};

const state = ({ groupStore, userStore, postStore, preferencesStore }) => {
  const { data: subscriberTelegramUrl, states: subscriberTelegramUrlStates } =
    groupStore?.subscriberTelegramGroup;
  const { data: expertTelegramUrl, states: expertTelegramUrlStates } =
    groupStore?.expertTelegramGroup;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: user, states: userStates } = userStore.information;
  const { data: preferences, states: preferencesStates } = preferencesStore.preferences;

  return {
    user,
    userStates,
    group,
    groupStates,
    subscriberTelegramUrl,
    subscriberTelegramUrlStates,
    expertTelegramUrl,
    expertTelegramUrlStates,
    preferences,
    preferencesStates,
  };
};

export default connect(state)(Component);
