import { useMemo } from 'react';
import { connect } from 'react-redux';
import { getFreeSubscriptions } from '../../../../helpers/getFreeSubscriptions';
import { isSubscribed } from '../../../../helpers/isSubscribed';
import { Group, User } from '../../../../types';
import Link from '../../../../components/Link'
import {
  Card,
  Title,
  Text
} from './styles'
import constants from '../../../../constants';
import { getPriorityGroupSubscription } from '../../../../helpers/mpStatusPriority';


interface ComponentProps {
  user: User
  group: Group;
  userSubscriptions
  isLoggedIn: Boolean;
}

const Component = ({
  user,
  group,
  userSubscriptions,
  isLoggedIn,
}: ComponentProps) => {

  const isUserAuthenticated = useMemo(() => {
    return isLoggedIn || group?.group_type === 'free_experience'
  }, [isLoggedIn, group])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(userSubscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [userSubscriptions, group, isLoggedIn])

  const subscription = useMemo(() => {
    return getPriorityGroupSubscription(userSubscriptions, group?.id)
  }, [group, userSubscriptions])

  const isRetryingSubscription = useMemo(() => {
    return subscription?.status === 'retrying'
  }, [subscription])

  const isPaidSubscription = useMemo(() => {
    const freeSubscriptionTypes = ["guest", "free"]
    return !freeSubscriptionTypes?.includes(subscription?.type)
  }, [subscription])

  if (!isUserAuthenticated || !isUserSubscribed || !isRetryingSubscription || !isPaidSubscription) {
    return <></>
  }

  return (
    <Card>
      <Title>Verificación de pago en proceso</Title>
      <div style={{ height: 18 }} />
      <Text>Recibirás dos correos de Mercado Pago: <br />uno notificando la cancelación de la suscripción no cobrada y otro confirmando <br /> el inicio de una nueva suscripción con el método de pago actualizado.</Text>
      <div style={{ height: 18 }} />
      <Text style={{ display: 'flex', gap: 4, justifyContent: 'center', alignItems: 'center' }}>
        Si pasan mas de 24hs y tu pago sigue en proceso, por favor
        <Link
          options={{ skin: 'white', size: 'sm', type: 'link', native: false }}
          style={{ fontWeight: 500, fontSize: 14, color: '#81CEE3' }}
          href={`${constants.whatsapp.url}?phone=${constants.whatsapp.number
            }&text=${constants.whatsapp.retrying_payment.replace("{name}", user?.name).replace("{email}", user?.email)}`}
          target="_blank"
        >
          Contacta a Soporte
        </Link>
      </Text>
    </Card>
  )
}

const state = ({ userStore, groupStore }) => {
  const { data: user } = userStore.information;
  const { data: group } = groupStore.group;
  const { data: userSubscriptions } = userStore.subscriptions;
  const { isLoggedIn } = userStore;

  return {
    user,
    group,
    userSubscriptions,
    isLoggedIn,
  };
};

export default connect(state)(Component);
