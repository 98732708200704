import Sidebar from "../../../../components/Sidebar";
import Button from "../../../../components/Button";
import { Preview, Text, Option } from "../components/StyledComponents";
import { initialValues, schema } from "../../../../constants/forms/survey/new";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import SurveyForm from "../components/SurveyForm";
import { connect, useDispatch } from "react-redux";
import { newSurvey, resetNewSurvey } from "../../../../store/actions/survey";
import { Group } from "../../../../types";
import Submit from "../../../../components/FormikForm/Submit";
import Snackbar from "../../../../components/Snackbar";
import { showModal } from "../../../../store/actions/modal";

interface ComponentProps {
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  newSurveyStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  isVisible: boolean;
  onClose?: (data: any) => void;
}

const Component = ({
  group,
  newSurveyStates,
  isVisible,
  onClose,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState(false);

  const onSubmit = ({ values, actions }) => {
    dispatch(newSurvey(values));
  };

  useEffect(() => {
    if (newSurveyStates.success) {
      !!onClose && onClose(false);
    }
  }, [newSurveyStates]);

  return (
    <>
      <Sidebar
        position="right"
        visible={isVisible}
        closeType="back"
        onClose={() => {
          !!onClose && onClose(false);
        }}
        width="576px"
        title="Encuesta post venta"
      >
        <Preview>
          <div>
            <Text>
              Desde este panel podrás crear, editar o eliminar la encuesta.
              Recuerda que puedes elegir preguntas con respuestas abiertas,
              respuestas numéricas o de respuesta múltiple.
            </Text>

            <Option>
              <Formik
                initialValues={{
                  ...initialValues,
                  group: group?.id,
                }}
                onSubmit={(values, actions) => {
                  if (onSubmit) onSubmit({ values, actions });
                }}
                validateOnChange={false}
                validateOnBlur={formSubmmited}
                validationSchema={schema}
                enableReinitialize
              >
                {({ handleSubmit, resetForm, ...rest }) => {
                  return (
                    <form
                      className="theme-form"
                      onSubmit={(event) => {
                        setFormSubmmited(true);
                        handleSubmit(event);
                      }}
                      id="new-survey-form"
                    >
                      <SurveyForm
                        {...rest}
                        isLoading={newSurveyStates.loading}
                      />
                    </form>
                  );
                }}
              </Formik>
            </Option>
          </div>

          <div>
            <Submit
              isSubmmiting={newSurveyStates.loading}
              form="new-survey-form"
              options={{
                type: "filled",
                skin: "purple",
                size: "lg",
                block: true,
              }}
              style={{ marginBottom: "8px" }}
            >
              Crear encuesta
            </Submit>

            <Button
              options={{
                type: "link",
                skin: "purple",
                size: "lg",
                block: true,
              }}
              onClick={() => !!onClose && onClose(false)}
            >
              Cancelar
            </Button>
          </div>
        </Preview>
      </Sidebar>
    </>
  );
};

const state = ({ surveyStore, groupStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: newSurvey, states: newSurveyStates } = surveyStore.newOne;

  return { newSurvey, newSurveyStates, group, groupStates };
};

export default connect(state)(Component);
