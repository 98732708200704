import * as Yup from "yup";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

export const schema = Yup.object().shape({
  phone_number: Yup.string()
    .required("Ingresa tu teléfono celular")
    .test(
      "checkIsValidPhone",
      "El teléfono ingresado es incorrecto",
      function async(phone) {
        if (!!phone) {
          return isPossiblePhoneNumber(phone) && isValidPhoneNumber(phone);
        }

        return true;
      }
    ),
  phone_area_code: Yup.string().required("Ingresa tu teléfono celular"),
});

interface FormValues {
  phone_number: string;
  phone_area_code: string;
}

export const initialValues: FormValues = {
  phone_number: "",
  phone_area_code: "",
};
