import action from "./../helpers/rest-client";

export class ErrorCategory extends Error { }

class Category {
  public async newCategory(fields) {
    return action
      .Post({
        url: `/api/category/`,
        body: fields,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async updateCategory({ id, ...rest }) {
    return action
      .Put({
        url: `/api/category/${id}`,
        body: rest,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getAll(payload) {
    const queryString = new URLSearchParams(payload).toString();

    return action
      .Get({
        url: `/api/category${queryString ? '?' + queryString : ''}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getOne(payload) {
    console.log('payload', payload)
    return action
      .Get({
        url: `/api/category/${payload.id}?group_id=${payload.group_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async delete({ id }) {
    return action
      .Del({
        url: `/api/category/${id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async reorder(payload) {
    return action
      .Put({
        url: `/api/category/reorder`,
        body: payload
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async reorderPosts(payload) {
    console.log('reorder post services')
    return action
      .Put({
        url: `/api/category/reorder-posts`,
        body: payload
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const category = new Category();
export default category;
