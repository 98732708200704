import {
  NEW_CATEGORY,
  RESET_NEW_CATEGORY,
} from "../types/category/new-category";
import { UPDATE_CATEGORY, RESET_UPDATE_CATEGORY } from "../types/category/update-category";
import { GetAllCategoriesOutDto } from '../../types/category'
import { GET_ALL_CATEGORIES } from "../types/category/get-all-categories";
import { GET_CATEGORY } from "../types/category/get-category";
import { REORDER_CATEGORIES } from "../types/category/reorder-categories";
import { REORDER_CATEGORY_POSTS } from "../types/category/reorder-category-posts";
import { DELETE_CATEGORY, RESET_DELETE_CATEGORY } from "../types/category/delete-category";

export const createCategory = (payload: {
  name: string;
  description: string;
}) => {
  return { type: NEW_CATEGORY, payload };
};

export const updateCategory = (payload) => {
  return { type: UPDATE_CATEGORY, payload };
};

export const resetUpdateCategory = () => {
  return { type: RESET_UPDATE_CATEGORY, payload: null };
};


export const getAllCategories = (payload: GetAllCategoriesOutDto) => {
  return { type: GET_ALL_CATEGORIES, payload };
};

export const resetNewCategory = () => {
  return { type: RESET_NEW_CATEGORY, payload: null };
};

export const getCategory = (payload: { id: number, group_id: number }) => {
  return { type: GET_CATEGORY, payload };
};

export const reorderCategories = (payload) => {
  return { type: REORDER_CATEGORIES, payload };
}

export const deleteCategory = (payload) => {
  return { type: DELETE_CATEGORY, payload }
}

export const resetDeleteCategory = () => {
  return { type: RESET_DELETE_CATEGORY, payload: null };
};


export const reorderCategoryPosts = (payload) => {
  return { type: REORDER_CATEGORY_POSTS, payload };
}