import React from 'react';
import moment from 'moment-timezone';

// Assuming selectedTimezones is an array of selected timezones in the format [{ label: "Timezone Label", value: "Timezone Value" }, ...]
// and values['session_datetime'] contains the datetime for which the time needs to be displayed.

const getTimeInTimezone = (datetime, timezone) => {
  return moment(datetime).tz(timezone).format('HH:mm');
};

const GroupedTimezones = ({ selectedTimezones, datetime }) => {
  if (!selectedTimezones || selectedTimezones.length === 0) {
    return null;
  }

  // Map each timezone to its time
  const timezoneTimes = selectedTimezones.map(tz => ({
    timezone: tz.label.split(" ")[0],
    time: getTimeInTimezone(datetime, tz.value)
  }));

  // Group timezones by time
  const groupedTimezones = timezoneTimes.reduce((groups, tz) => {
    const group = groups.find(g => g.time === tz.time);
    if (group) {
      group.timezones.push(tz.timezone);
    } else {
      groups.push({ time: tz.time, timezones: [tz.timezone] });
    }
    return groups;
  }, []);

  // Render grouped timezones
  return (
    <>
      <span>Horario del Encuentro: </span>
      {groupedTimezones.map((group, index) => (
        <span style={{ fontSize: "15px" }} key={index}>
          {group.timezones.join(' ')} {group.time}hs {index + 1 === groupedTimezones.length ? "" : " | "}
        </span>
      ))}
    </>
  );
};

export default GroupedTimezones;
