import {
  Card,
  Top,
  Actions,
  Link,
  Button,
  Title,
  Author,
  Date,
  Information,
  GoToPreview,
  Background,
  FreeLabel,
  Circle,
  FutureDate,
  Reactions,
  EmojiWithCounter,
  NotCompleted,
  Completed
} from "./styles";
import schedulerIcon from "../../../../../../assets/scheduler-icon.svg";

import postEditIcon from "../../../../../../assets/icons/post-edit-icon.svg";
import checkCircledIcon from "../../../../../../assets/icons/check_circled.svg";
import arrowRightTopIcon from "../../../../../../assets/icons/arrow_forward_up_right.svg";
import postPinUncheckedIcon from "../../../../../../assets/icons/post-pin-unchecked-icon.svg";
import postPinCheckedIcon from "../../../../../../assets/icons/post-pin-checked-icon.svg";
import postCopyLinkIcon from "../../../../../../assets/icons/post-copy-link-icon.svg";

import { connect, useDispatch } from "react-redux";
import { Post } from "../../../../../../types/post";
import { useEffect, useMemo, useState } from "react";
import constants from "../../../../../../constants";
import CopyToClipboad from "../../../../../../components/CopyToClipboad";
import { useMarkdown } from "../../../../../../hooks/useMarkdown";
import KeyIcon from "../../../../../../assets/icons/key-2.svg";
import Icon from "../../../../../../components/Icon";
import { dateSpliter } from "../../../../../../helpers/dates-hanlder";
import { setFeaturedPost } from "../../../../../../store/actions/post";

import background1 from "../../../../../../assets/backgrounds/membership/background-1.svg";
import background2 from "../../../../../../assets/backgrounds/membership/background-4.svg";
import background3 from "../../../../../../assets/backgrounds/membership/background-5.svg";
import { State } from "../../../../../../components/GroupCard/styles";
import chevronDownIcon from "../../../../../../assets/icons/chevron-down.svg";
import { Separator } from "../../../../../../components/StyledComponents";
import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import { useLocation } from "react-router-dom";
import { WelcomeContentPostDto } from "../../../../../../types/welcome-section";

const backgrounds = [background1, background2, background3];
const randomBg = Math.floor(Math.random() * backgrounds.length);
interface ComponentProps {
  welcomeContentPost: WelcomeContentPostDto;
  isMine?: boolean;
  groupInformation;
  userInformation;
  onDelete?;
  style?;
  showActions?: boolean;
  showPinAction?: boolean;
  onUnpinIt?: React.Dispatch<React.SetStateAction<{
    idPost: number;
    pinned: boolean;
  } | undefined>>
  coverPicHeight?: number;
}

const showFeaturePostCta = ["experience", "membership"];
const Component = ({
  onDelete,
  welcomeContentPost,
  isMine = false,
  groupInformation,
  userInformation,
  style,
  showActions = true,
  showPinAction = true,
  onUnpinIt,
  coverPicHeight = 130,
}: ComponentProps) => {
  const { typeMap, markMap, jsonStr, textStr } = useMarkdown({
    brief: welcomeContentPost?.body,
  });
  const dispatch = useDispatch();
  const [ownGroup, setOwnGroup] = useState(false);
  const [hastAttached, setHasAttached] = useState(false);
  const [publishedAt, setPublishedAt] = useState<any>();
  const [schedulePublishAt, setSchedulePublishAt] = useState<any>();
  const { isMobile } = useWindowSize()
  const [image, setImage] = useState<string>();
  const location = useLocation();

  const postReactions = useMemo(() => {
    if (welcomeContentPost?.post_reactions) {
      const result = welcomeContentPost?.post_reactions.reduce((acc, item) => {
        const emoji = item.reaction_emoji;

        // Create an array for the emoji if it doesn't exist in the result
        if (!acc[emoji]) {
          acc[emoji] = [];
        }

        // Push the current item to the array for the current emoji
        acc[emoji].push(item);

        // Return the accumulator (acc)
        return acc;
      }, {});

      return result;
    }

    return [];
  }, [welcomeContentPost]);

  useEffect(() => {
    if (!!groupInformation && !!userInformation) {
      setOwnGroup(groupInformation?.expert?.id === userInformation?.expert?.id);
    }
  }, [groupInformation, userInformation]);

  useEffect(() => {
    if (!!welcomeContentPost) {
      setPublishedAt(dateSpliter(welcomeContentPost?.published_at));
      setHasAttached(welcomeContentPost?.attached_files && welcomeContentPost?.attached_files?.length > 0 || !!welcomeContentPost?.attached_url);

      // if (!!welcomeContentPost?.attached_files || !!welcomeContentPost?.cover_pic) {
      let attachmentImage = backgrounds[randomBg];

      if (welcomeContentPost?.attached_files && welcomeContentPost?.attached_files?.length > 0) {
        attachmentImage =
          (!!welcomeContentPost?.attached_files &&
            welcomeContentPost?.attached_files?.filter((file) =>
              file?.mime?.includes("image")
            )[0]?.signed_url) || backgrounds[randomBg]
      }

      setImage(welcomeContentPost?.cover_pic?.signed_url || attachmentImage);
      // }
    }
  }, [welcomeContentPost]);

  return (
    <Card style={{ ...style }}>
      <Background className="background" backgroundImage={image} height={coverPicHeight} />

      <Top>
        {showActions && (
          <Actions className="article-actions">
            {(ownGroup || isMine) && (
              <>
                <Circle>
                  <CopyToClipboad
                    copy={`${process.env.REACT_APP_SITE_BASE_URL}/group/${groupInformation?.id}/wc/${welcomeContentPost?.id}`}
                  >
                    <Icon icon={postCopyLinkIcon} size="14px" color={"black"} />
                  </CopyToClipboad>
                </Circle>

                <Circle>
                  <Link
                    href={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/welcome-content/${welcomeContentPost?.id}`}
                    options={{
                      native: true,
                      size: "sm",
                      type: "filled",
                      skin: "purple",
                    }}
                  >
                    <Icon icon={postEditIcon} size="14px" color={"black"} />
                  </Link>
                </Circle>
              </>
            )}
          </Actions>
        )}
      </Top>

      <GoToPreview coverPicHeight={coverPicHeight} to={{ pathname: `/group/${groupInformation?.id}/wc/${welcomeContentPost?.id}`, state: { from: location?.pathname } }}>
        <Title>{welcomeContentPost?.title}</Title>
        <div style={{ height: 12 }} />
        {welcomeContentPost?.completed ? (
          <Completed><span>Completado</span>  <Icon icon={checkCircledIcon} size="14px" color={"#12B47A"} /></Completed>
        ) : (
          <NotCompleted><span>Ir al Contenido</span> <Icon icon={arrowRightTopIcon} size="14px" color={"white"} /></NotCompleted>
        )}


        <Information>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {/* <Author>{groupInformation?.creator_name}</Author> */}
            <Reactions reactions={postReactions}>
              {Object.keys(postReactions)?.map((reaction) => (
                <EmojiWithCounter>
                  <div className='reaction-container'>
                    <div className='emoji'>{reaction} </div>
                    <div className='counter'>{postReactions[reaction].length}</div>
                  </div>
                </EmojiWithCounter>
              ))}
            </Reactions>
            <Date>{`${publishedAt?.day?.number}/${publishedAt?.month?.number}/${publishedAt?.year}`}</Date>
          </div>
        </Information>
      </GoToPreview>
    </Card>
  );
};

const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;

  return {
    userInformation,
  };
};

export default connect(state)(Component);
