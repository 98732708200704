import styled from "styled-components";

export const BackgroundImage = styled('div')`
  width: 100%;
  height: 114px;
  border-radius: 22px;

  @media (max-width: 768px) {
    height: 82px;
  }
`

