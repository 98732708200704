import styled from "styled-components";

export const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin: 0px 0px 24px;
  text-align: center;
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-bottom: 24px;
`;
