import { Benefit, RemoveBenefit } from "./styles";
import Image from "../../../../../../components/Image";
import remove from "../../../../../../assets/icons/delete.svg";
import Input from "../../../../../../components/Form/Input";

interface ComponentProps {
  canDelete?: boolean;
  onDelete?: () => void;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
  value?;
  name;
  id;
  register;
  placeholder?;
  rules;
  errors;
  maxLength?;
  options?;
}

const Component = ({
  canDelete = false,
  onDelete,
  errors,
  ...rest
}: ComponentProps) => {
  return (
    <Benefit>
      <Input {...rest} errors={errors} />

      {canDelete && (
        <RemoveBenefit onClick={onDelete}>
          <Image src={remove} width="16px" height="16px" alt="Delete" />
        </RemoveBenefit>
      )}
    </Benefit>
  );
};

export default Component;
