import { Field, Text, Label } from "./styles";
import Select from "../../../../../../../components/Form/Select";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Group } from "../../../../../../../types";
import constants from "../../../../../../../constants";

interface ComponentProps {
  groups: Group[] | undefined;
  setFilteredGroups: (groups: Group[]) => void;
  reset;
}

const Component = ({ groups, setFilteredGroups, reset }: ComponentProps) => {
  const name = "content_states";
  const defaultFilter = {
    label: "Mostrar todos",
    value: "all",
  };
  const [selectedState, setSelectedState] = useState(defaultFilter);

  const resetFilter = () => {
    setSelectedState(defaultFilter);
  };

  const getFilteredGroup = () => {
    if (!!groups) {
      setFilteredGroups(
        groups.filter((group) => {
          if (!!group.registration_closing_date) {
            let inactiveDate = new Date(group.registration_closing_date);

            if (selectedState.value === "active") {
              return inactiveDate >= new Date();
            }

            if (selectedState.value === "inactive") {
              return inactiveDate < new Date();
            }

            if (selectedState.value === "all") {
              return true;
            }

            //Esto lo dejo asi parseado hasta que organizemos el tema de los status de los grupos
            // if (selectedState.value === "all")
            //   inactiveDate > new Date()
            //     ? (group.status = "active")
            //     : (group.status = "inactive");
            // else group.status = selectedState.value;

            // return selectedState.value === "active"
            //   ? inactiveDate >= new Date()
            //   : inactiveDate < new Date();
          }

          return (
            selectedState.value === "all" ||
            selectedState.value === group.status
          );
        })
      );
    }
  };

  useEffect(() => {
    getFilteredGroup();
  }, [selectedState, groups]);

  useEffect(() => {
    resetFilter();
  }, [reset]);

  if (!groups) {
    return null;
  }

  return (
    <Field>
      <Label>
        <Text>
          <span>Filtrar por:</span>
        </Text>
      </Label>

      <Select
        name={name}
        options={{
          data: constants.groupStatus,
          textField: "label",
          valueField: "value",
          marginBottom: 0,
        }}
        defaultValue={selectedState}
        placeholder={"Estado"}
        reactSelectOptions={{
          isSearchable: true,
        }}
        onChange={setSelectedState}
      />
    </Field>
  );
};

export default Component;
