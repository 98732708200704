import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { getUrlParamByName } from "../../../../helpers/get-url-param";
import constants from "../../../../constants";
import { deleteFile } from "../../../../store/actions/post";

import flagArgentina from "../../../../constants/countries/flags/flag-AR.png";
import flagEuropeanUnion from "../../../../constants/countries/flags/flag-UE.png";
import flagUnitedStates from "../../../../constants/countries/flags/flag-US.png";

import { Center } from "../../../../components/StyledComponents";
import Image from "../../../../components/Image";
import Input from "../../../../components/FormikForm/Input";
import Switch from "../../../../components/FormikForm/Switch";
import File from "../../../../components/FormikForm/File";
import ReactSelect from "../../../../components/FormikForm/ReactSelect";
import RichTextEditor from "../../../../components/FormikForm/RichTextEditor";

import Benefits from './components/BenefitsField';
import Reviews from './components/ReviewsField';
import ModalWaitAMinute from "../ModalWaitAMinute";

import {
  StepTitle,
  StepLabel,
  CurrencyLabel,
  LocalPrice
} from './styles'
import { getLocalPrice } from "../../../../store/actions/currency";

const currencyLabel = (currency) => {
  return (
    <CurrencyLabel>
      <Image src={currency?.flag} width="28px" height="20px" alt="" />
      {currency.description}
    </CurrencyLabel>
  )
}

const currencies = [
  {
    flag: flagArgentina,
    iso: "ARS",
    label: "AR$",
    description: "Pesos Argentinos (ARS)",
  },
  {
    flag: flagEuropeanUnion,
    iso: "EUR",
    label: "€",
    description: "Euros (EUR)",
  },
  {
    flag: flagUnitedStates,
    iso: "USD",
    label: "US$",
    description: "Dolares Americanos (USD)",
  },
];

const Component = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  localCurrencyData,
  localCurrencyStates,
  isEdition = false,
  isRecordedExperience = false
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const offset = useMemo(() => {
    let offset = 0;

    if (values.group_type === 'experience') {
      offset = offset + 1;
    }

    if (values.group_type === 'experience' && !isRecordedExperience) {
      offset = offset + 1;
    }

    return offset
  }, [values, isRecordedExperience]);

  const groupType = useMemo(() => {
    return values.group_type === "membership" ? "Membresía" : "Taller"
  }, [values])

  const localCurrencyPrice = useMemo(() => {
    const exchange = localCurrencyData?.rate || 0;
    const calculatedLocalPrice = values?.plan?.price * exchange;
    const localPrice = calculatedLocalPrice.toFixed(2);

    return `= ${values.plan?.showable_secondary_currency} ${localPrice} ${values.plan?.showable_secondary_currency === "ARS" ? "+ impuestos." : ""}`
  }, [values, localCurrencyData])

  useEffect(() => {
    if (values?.plan?.show_local_price?.includes('true')) {
      dispatch(
        getLocalPrice({
          source: values.plan.currency,
          target: values.plan.showable_secondary_currency,
        })
      );
    }
  }, [values])

  return (
    <>
      <StepTitle>
        <StepLabel>1. Define el nombre de tu {groupType}</StepLabel>
      </StepTitle>

      <Input
        name={"group_name"}
        error={errors["group_name"]}
        touched={touched["group_name"]}
        value={values["group_name"]}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Nombre de tu experiencia"
        options={{
          marginBottom: 10
        }}
      />

      <Center vertical="center" horizontal="left" gap={10}>
        <span style={{ flexShrink: 0 }}>Creado por</span>
        <Input
          name="creator_name"
          error={errors["creator_name"]}
          placeholder="Tu nombre..."
          touched={touched["creator_name"]}
          value={values["creator_name"]}
          onChange={handleChange}
          onBlur={handleBlur}
          options={{
            marginBottom: 0
          }}
        />
      </Center>

      <div style={{ height: 40 }} />

      {/* 
      <StepTitle>
        <StepLabel>2. Personalizá tu taller (opcional)</StepLabel>
      </StepTitle>

      <File
        name="cover_pic"
        error={errors["cover_pic"]}
        touched={touched["cover_pic"]}
        value={values["cover_pic"]}
        rules={{
          maxFiles: 1,
          typeFiles: ["image"],
        }}
        onRemoveItem={(event, file) => {
          if (file?.id) {
            dispatch(deleteFile({ idFile: file?.id }));
          }
        }}
        options={{
          explainText:
            `Puedes agregar una imagen de portada para tu ${groupType}. JPG o PNG. El tamanño ideal es de 333px (ancho) x 174px (alto), pero si subes de otro tamaño funcionará!`,
          marginBottom: 0,
          showLoadFileCta: false,
        }}
      /> 

      <div style={{ height: 40 }} />
      */}

      <StepTitle>
        <StepLabel>2. Describe la temática</StepLabel>
      </StepTitle>

      <RichTextEditor
        name="brief"
        error={errors["brief"]}
        touched={touched["brief"]}
        value={values["brief"]}
        placeholder=""
        max={5000}
        onBlur={handleBlur}
        onChange={({ isCountValid }) => {
          if (!isCountValid) {
            setFieldError(
              "brief",
              "Superaste la cantidad de caracteres máximos permitidos"
            );
          } else {
            setFieldTouched("brief", false, false);
          }
        }}
        options={{
          label: "",
          marginBottom: 0,
        }}
      />

      <div style={{ height: 40 }} />

      <StepTitle>
        <StepLabel>3. Video presentación (opcional)</StepLabel>
      </StepTitle>

      <File
        name="group_video"
        error={errors["group_video"]}
        touched={touched["group_video"]}
        value={values["group_video"]}
        onChange={(files) => handleChange({ target: { name: 'group_video', value: files } })}
        onBlur={handleBlur}
        onRemoveItem={() => { }}
        rules={{
          maxFiles: 1,
          typeFiles: ["video"],
        }}
        options={{
          explainText:
            "Podés agregar un video a la página de venta de tu taller. El vidéo debera ser en formato .MP4",
          marginBottom: 0,
          showLoadFileCta: false,
        }}
      />

      <div style={{ height: 40 }} />


      <StepTitle>
        <StepLabel>4. Portada (opcional)</StepLabel>
      </StepTitle>

      <File
        name="cover_pic"
        error={errors["cover_pic"]}
        touched={touched["cover_pic"]}
        value={values["cover_pic"]}
        onChange={(files) => handleChange({ target: { name: 'cover_pic', value: files } })}
        onBlur={handleBlur}
        onRemoveItem={() => { }}
        rules={{
          maxFiles: 1,
          typeFiles: ["image"],
          maxSize: 10 * 1024 * 1024
        }}
        options={{
          explainText:
            `Puedes agregar una imagen de portada para tu ${groupType}. 
              Formato JPG o PNG de hasta 10Mb. El tamaño ideal es de 1140px (ancho) x 182px (alto), pero si subes de otro tamaño se acomodará automáticamente!`,
          marginBottom: 0,
          showLoadFileCta: false,
        }}
      />

      <div style={{ height: 40 }} />

      <StepTitle>
        <StepLabel>5. Enumera qué incluye tu Experiencia</StepLabel>
      </StepTitle>

      <Benefits
        values={values}
        errors={errors}
        touched={touched}
        placeholder="Beneficio"
        onChange={handleChange}
        onBlur={handleBlur}
      />

      {values.group_type === 'experience' && !isRecordedExperience && (
        <>
          <StepTitle>
            <StepLabel>6. Duración</StepLabel>
          </StepTitle>

          <Center vertical="center" horizontal="left" gap={10}>
            <Input
              name="duration"
              error={errors?.duration}
              touched={touched?.duration}
              value={values?.duration}
              type={"number"}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Ej: 7"
              options={{
                marginBottom: 0
              }}
            />

            <ReactSelect
              name="duration_unit"
              error={errors?.duration_unit}
              touched={touched?.duration_unit}
              items={constants.durations}
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                marginBottom: 0,
              }}
            />
          </Center>

          <div style={{ height: 40 }} />
        </>
      )}

      {values.group_type === 'experience' && !isRecordedExperience && (
        <>
          <StepTitle>
            <StepLabel>{isRecordedExperience ? 6 : 7}. Cierre de inscripciones</StepLabel>
          </StepTitle>

          <Input
            name="registration_closing_date"
            type="datetime-local"
            error={errors?.registration_closing_date}
            touched={touched?.registration_closing_date}
            value={values?.registration_closing_date}
            onChange={handleChange}
            onBlur={handleBlur}
            options={{
              marginBottom: 0
            }}
          />

          <div style={{ height: 40 }} />
        </>
      )}

      {/* {!isEdition && (
        <>
          <StepTitle>
            <StepLabel>{6 + offset}. Precio predeterminado</StepLabel>
          </StepTitle>

          <ReactSelect
            name="plan[currency]"
            error={errors?.plan?.currency}
            touched={touched?.plan?.currency}
            items={currencies.map((item) => ({
              label: currencyLabel(item),
              value: item.iso,
            }))}
            onChange={handleChange}
            onBlur={handleBlur}
            options={{
              label: "Moneda principal",
              helperText: "Los precios que crees en la Moneda Principal serán los que aparezcan de forma predeterminada al cargar tu página de ventas.",
              marginBottom: 14,
            }}
          />

          <Input
            name="plan[price]"
            error={errors?.plan?.price}
            touched={touched?.plan?.price}
            value={values?.plan?.price}
            onChange={handleChange}
            type={"number"}
            onBlur={handleBlur}
            placeholder="0"
            options={{
              label: "Precio",
              helperText: `Define un precio inicial para continuar la creación de tu ${groupType}. Más tarde podrás crear otros planes de pago y precios en otras monedas!`,
              marginBottom: 14,
              before: values?.plan?.currency || "$",
              after: values.group_type === 'experience' ? 'Pago único' : 'Pago mensual'
            }}
          />

          {values?.plan?.currency !== "ARS" && (
            <>
              <Switch
                name="plan[show_local_price]"
                error={errors?.plan?.show_local_price}
                touched={touched?.plan?.show_local_price}
                value={values?.plan?.show_local_price}
                onChange={handleChange}
                onBlur={handleBlur}
                items={[
                  {
                    label: "También mostrar valor en moneda local",
                    value: "true",
                  }
                ]}
                options={{
                  marginBottom: values?.plan?.show_local_price.includes('true') ? 14 : 0,
                  helperText: `Al activar esta opción, en la página de venta de tu ${groupType} también se mostrar el precio en la moneda local seleccionada.`
                }}
              />


              {values?.plan?.show_local_price.includes('true') && (
                <Center vertical="center" horizontal="left" gap={10}>
                  <ReactSelect
                    name="plan[showable_secondary_currency]"
                    error={errors?.plan?.showable_secondary_currency}
                    touched={touched?.plan?.showable_secondary_currency}
                    placeholder={``}
                    items={constants.currencies.filter((item) => item.iso !== values?.plan?.currency).map((item) => ({
                      label: item.description,
                      value: item.iso,
                    }))}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      label: ``,
                      marginBottom: 0,
                    }}
                  />

                  <LocalPrice>{localCurrencyPrice}</LocalPrice>
                </Center>
              )}
            </>
          )}

          <div style={{ height: 40 }} />
        </>
      )} */}

      <StepTitle>
        <StepLabel>{(!isEdition ? 6 : 5) + offset}. Agrega reseñas que validen tu trabajo (opcional)</StepLabel>
      </StepTitle>

      <Reviews
        values={values}
        errors={errors}
        touched={touched}
        setFieldError={setFieldError}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />

      <div style={{ height: 40 }} />

      <StepTitle>
        <StepLabel>{(!isEdition ? 7 : 6) + offset}. Cuéntale a tu audiencia por qué {values.group_type === "membership" ? "la Membresía" : " el Taller"} es para ellos (opcional)</StepLabel>
      </StepTitle>

      <RichTextEditor
        name="this_is_for_me"
        error={errors["this_is_for_me"]}
        touched={touched["this_is_for_me"]}
        value={values["this_is_for_me"]}
        placeholder=""
        max={5000}
        onBlur={handleBlur}
        onChange={({ isCountValid }) => {
          if (!isCountValid) {
            setFieldError(
              "this_is_for_me",
              "Superaste la cantidad de caracteres máximos permitidos"
            );
          } else {
            setFieldTouched("this_is_for_me", false, false);
          }
        }}
        options={{
          label: "",
          marginBottom: 0,
        }}
      />

      <ModalWaitAMinute />
    </>
  )
}

const state = ({ currencyStore }) => {
  const { data: localCurrencyData, states: localCurrencyStates } = currencyStore;

  return { localCurrencyData, localCurrencyStates };
};

export default connect(state)(Component)