import bookGroup from "../../assets/group-menu/book.svg";
import calendarGroup from "../../assets/group-menu/calendar.svg";
import homeGroup from "../../assets/group-menu/home.svg";
import messagesGroup from "../../assets/group-menu/messages.svg";
import laptopGroup from "../../assets/group-menu/laptop.svg";
import { showModal } from "../../store/actions/modal";

interface MenuItem {
  active: boolean;
  icon: string;
  native?: boolean;
  url?: string;
  action?: () => void;
  label: string;
  expert?: boolean;
  newFeatureMark?: string[];
  key?: string[];
}

const groupMenu: MenuItem[] = [
  {
    active: true,
    icon: homeGroup,
    native: true,
    url: "/group/:slug",
    label: "Inicio",
    key: ['GROUP_DETAILS', 'GROUP_DETAILS_ACTIVITY_SUMMARY']
  },
  {
    active: true,
    icon: laptopGroup,
    native: true,
    url: "/group/:slug/modules",
    label: "Aula Virtual",
    key: ['GROUP_DETAILS_MODULES']
  },
  {
    active: true,
    icon: bookGroup,
    native: true,
    url: "/group/:slug/library",
    label: "Biblioteca",
    key: ['GROUP_DETAILS_LIBRARY', 'GROUP_POST_PREVIEW']
  },
  {
    active: true,
    icon: calendarGroup,
    native: true,
    url: "/group/:slug/sessions",
    label: "Encuentros",
    key: ['GROUP_DETAILS_SESSIONS', 'GROUP_SESSION_PREVIEW']
  },
  {
    active: true,
    icon: messagesGroup,
    action: () => showModal('modal-select-chat'),
    label: "Chat",
  },
];

export default groupMenu 