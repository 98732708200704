import Link from "../../Link";
import { VerticalSeparator, HorizontalSeparator, Header } from "./../styles";
import { LogoutMenu, LinkItem, Dropdown, ButtonItem } from "./styles";
import { useWindowSize } from "../../../hooks/useWindowSize";
import hamburger from "./../hamburger.svg";
import constants from "../../../constants";
import { newAppDemo } from "../../../helpers/calendly";

const Component = ({ currentPage }) => {
  const { isMobile } = useWindowSize();

  const urls = {
    EXPERT_SIGN_UP: constants.urls.expert.signup.url,
    EXPERT_SIGN_IN: constants.urls.expert.signin.url,
    SIGN_UP: constants.urls.subscriber.signup.url,
    SIGN_IN: constants.urls.subscriber.signin.url,
    HOME_PAGE: constants.urls.public.homePage,
  };

  const isGroupDetailPageUrl = () => {
    return currentPage === "GROUP_DETAILS" || currentPage === "EXPERT_GROUPS";
  };

  const isSignInUrl = () => {
    return currentPage === "SIGN_IN" || currentPage === "EXPERT_SIGN_IN";
  };

  const isSignUpUrl = () => {
    return currentPage === "SIGN_UP" || currentPage === "EXPERT_SIGN_UP";
  };

  const isHomePage = () => {
    return currentPage === "HOME_PAGE";
  };

  const signInUrl = () => {
    const signInUrls = {
      EXPERT_SIGN_UP: constants.urls.expert.signin.url,
      SIGN_UP: constants.urls.subscriber.signin.url,
    };

    if (isHomePage()) {
      return signInUrls["EXPERT_SIGN_UP"];
    }

    if (!isSignInUrl() && !isSignUpUrl()) {
      return signInUrls["SIGN_UP"];
    }

    return signInUrls[currentPage];
  };

  const signUpUrl = () => {
    const signInUrls = {
      EXPERT_SIGN_IN: constants.urls.expert.signup.url,
      SIGN_IN: constants.urls.subscriber.signup.url,
    };

    if (isHomePage()) {
      return signInUrls["EXPERT_SIGN_IN"];
    }

    if (!isSignInUrl() && !isSignUpUrl()) {
      return signInUrls["SIGN_IN"];
    }

    return signInUrls[currentPage];
  };

  return isMobile ? (
    <LogoutMenu>
      {!isSignInUrl() && (
        <LinkItem
          href={signInUrl()}
          options={{
            size: "sm",
            type: "outline",
            skin: "purple",
          }}
        >
          Iniciar sesión
        </LinkItem>
      )}
      <VerticalSeparator margin={15} visible={false} />
      <Dropdown label={<img src={hamburger} alt="hamburger" />}>
        {!isGroupDetailPageUrl() && (
          <ButtonItem
            marginBottom={"0px"}
            onClick={newAppDemo}
            options={{
              size: "sm",
              type: "filled",
              skin: "purple",
            }}
          >
            Quiero una Demo
          </ButtonItem>
        )}
        {!isGroupDetailPageUrl() && (
          <LinkItem
            href={"https://blog.klouser.app"}
            target="_blank"
            options={{
              size: "sm",
              type: "filled",
              skin: "purple",
              native: false,
            }}
          >
            Blog
          </LinkItem>
        )}
        {!isGroupDetailPageUrl() && !isSignUpUrl() && (
          <HorizontalSeparator isSeparator="16px" visible={true} />
        )}
        {!isSignUpUrl() && (
          <LinkItem
            href={signUpUrl()}
            options={{
              size: "sm",
              type: "filled",
              skin: "purple",
              native: false,
            }}
          >
            Registrarme
          </LinkItem>
        )}
      </Dropdown>
    </LogoutMenu>
  ) : (
    <LogoutMenu>
      {!isGroupDetailPageUrl() && (
        <>
          <ButtonItem
            onClick={newAppDemo}
            style={{ fontWeight: "bold" }}
            options={{
              size: "sm",
              type: "link",
              skin: "purple",
            }}
          >
            Quiero una Demo
          </ButtonItem>
          <VerticalSeparator margin={32} visible={true} />
          <Link
            href={"https://blog.klouser.app"}
            target="_blank"
            options={{
              size: "sm",
              type: "link",
              skin: "purple",
              native: false,
            }}
          >
            Blog
          </Link>
          <VerticalSeparator margin={32} visible={true} />
        </>
      )}
      {!isSignInUrl() && (
        <Link
          href={signInUrl()}
          options={{
            size: "sm",
            type: "outline",
            skin: "purple",
            native: true,
          }}
        >
          Iniciar sesión
        </Link>
      )}
      {!isSignInUrl() && !isSignUpUrl() && (
        <VerticalSeparator margin={16} visible={false} />
      )}
      {!isSignUpUrl() && (
        <Link
          href={signUpUrl()}
          options={{
            size: "sm",
            type: "filled",
            skin: "purple",
            native: true,
          }}
        >
          Registrarme
        </Link>
      )}
    </LogoutMenu>
  );
};

export default Component;
