import {
  AcceptInvitationOutDto,
  AcceptInvitationInDto,
} from "../../../types/invitation.dto";

export const ACCEPT_INVITATION = "ACCEPT_INVITATION";
export const ACCEPT_INVITATION_SUCCESS = "ACCEPT_INVITATION_SUCCESS";
export const ACCEPT_INVITATION_ERROR = "ACCEPT_INVITATION_ERROR";
export const RESET_ACCEPT_INVITATION = "RESET_ACCEPT_INVITATION";

export interface AcceptInvitation {
  type: typeof ACCEPT_INVITATION;
  payload: AcceptInvitationOutDto;
}

export interface AcceptInvitationSuccess {
  type: typeof ACCEPT_INVITATION_SUCCESS;
  payload: AcceptInvitationInDto | null;
}

export interface AcceptInvitationError {
  type: typeof ACCEPT_INVITATION_ERROR;
  payload: boolean | string;
}

export interface ResetAcceptInvitation {
  type: typeof RESET_ACCEPT_INVITATION;
  payload: null;
}

export interface State {
  data: AcceptInvitationInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | AcceptInvitation
  | AcceptInvitationSuccess
  | AcceptInvitationError
  | ResetAcceptInvitation;
