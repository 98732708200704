import Grid from "../../../../../components/Grid";
import { Subscribe, Title, SubTitle, Img, Center, Button } from "./styles";

const Component = () => {
  return (
    <Subscribe id="subscribe">
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <Title>Unete a la conferencia</Title>
            <SubTitle>
              Reserva tu lugar en la conferencia, es totalmente gratis. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus orci
              a risus mollis imperdiet. Ut sit amet velit elementum, hendrerit
              leo a, auctor metus. Proin finibus dolor eget suscipit eleifend.
            </SubTitle>
            <Center>
              <Button>Reservar mi lugar</Button>
            </Center>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Subscribe>
  );
};

export default Component;
