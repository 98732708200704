import {RESET_DELETE_POST} from "../post/delete-post";

export const DELETE_DISCOUNT = "DELETE_DISCOUNT";
export const DELETE_DISCOUNT_SUCCESS = "DELETE_DISCOUNT_SUCCESS";
export const DELETE_DISCOUNT_ERROR = "DELETE_DISCOUNT_ERROR";
export const RESET_DELETE_DISCOUNT = "RESET_DELETE_DISCOUNT";

export interface DeleteDiscount {
    type: typeof DELETE_DISCOUNT;
    payload: { id: number };
}

export interface DeleteDiscountSuccess {
    type: typeof DELETE_DISCOUNT_SUCCESS;
    payload: null;
}

export interface DeleteDiscountError {
    type: typeof DELETE_DISCOUNT_ERROR;
    payload: string | boolean;
}

export interface ResetDeleteDiscount {
    type: typeof RESET_DELETE_DISCOUNT;
    payload: null;
}

export interface State {
    states: {
        success: boolean;
        loading: boolean;
        error: boolean | string;
    };
}

export const initialState: State = {
    states: {
        success: false,
        loading: false,
        error: false,
    },
};

export type Actions =
    | DeleteDiscount
    | DeleteDiscountSuccess
    | DeleteDiscountError
    | ResetDeleteDiscount;