import styled from "styled-components";

export const List = styled.ul`
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
  }
`;

export const ListItem = styled.li`
  padding: 0px;
  margin: 0px 0px 16px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 157px;
  color: ${(props) => props.theme.colors.Gray68};

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-right: 0px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: 0px 28px;
    height: 121px;
    align-items: space-between;
    text-align: center;
  }

  & > br {
    display: none;

    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      display: block;
    }
  }

  & > p {
    margin: 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #adadad;
  }

  & > img {
    width: 80px;
    height: 80px;
  }
`;
