import React, { ReactElement, useContext, useEffect, useState } from "react";
import { CarouselProvider, Slider, Dot } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Step, Big, Small, CarouselContent } from "./styles";
import Modal from "../../../../../../components/Modal";
import Slides from "../Slides";

interface ComponentProps {
  id: string;
  slides?: ReactElement;
}

export const Component = ({ id, slides }: ComponentProps) => {
  const [index, setIndex] = useState(0);
  const totalSteps = 5;

  const handleBackButtonPressed = () => {
    if (index === 0) {
      return;
    } else {
      setIndex(index - 1);
    }
  };

  const handleNextButtonPressed = () => {
    if (index === totalSteps - 1) {
      return;
    } else {
      setIndex(index + 1);
    }
  };

  return (
    <Modal name="modal-initial-steps" width="650">
      <CarouselProvider
        naturalSlideWidth={650}
        naturalSlideHeight={550}
        totalSlides={totalSteps}
        isIntrinsicHeight={true}
      >
        <CarouselContent>
          <Step>
            <Big>
              <Dot slide={0}>1</Dot>
              <Dot slide={1}>2</Dot>
              <Dot slide={2}>3</Dot>
              <Dot slide={3}>4</Dot>
              <Dot slide={4}>5</Dot>
            </Big>

            <Small>/{totalSteps}</Small>
          </Step>
          <Slider>
            {slides ? (
              React.createElement(slides?.type, {
                ...{
                  ...slides?.props,
                  id,
                },
              })
            ) : (
              <Slides id={id} />
            )}
          </Slider>
        </CarouselContent>
      </CarouselProvider>
    </Modal>
  );
};

export default Component;
