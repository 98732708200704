import * as Yup from "yup";

export const schema = Yup.object().shape({
  condition: Yup.string().required("Agregar condición"),
  value: Yup.string().required("Selecciona una opción"),
});

interface FormValues {
  condition: string;
  value: string;
}

export const initialValues: FormValues = {
  condition: "",
  value: "",
};
