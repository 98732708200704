import {
  initialState,
  Actions,
  State,
  NEW_SURVEY_ANSWER,
  NEW_SURVEY_ANSWER_SUCCESS,
  NEW_SURVEY_ANSWER_ERROR,
  RESET_NEW_SURVEY_ANSWER,
} from "../../types/survey/new-answer";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case NEW_SURVEY_ANSWER:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case NEW_SURVEY_ANSWER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case NEW_SURVEY_ANSWER_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_NEW_SURVEY_ANSWER:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
