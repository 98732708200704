import { Currency } from "../types/currency";

const currencies: Currency[] = [
  {
    iso: "MXN",
    label: "MX$",
    description: "Pesos Mexicanos (MXN)",
  },
  {
    iso: "CLP",
    label: "CL$",
    description: "Pesos Chilenos (CLP)",
  },
  {
    iso: "ARS",
    label: "AR$",
    description: "Pesos Argentinos (ARS)",
  },
  {
    iso: "COP",
    label: "CO$",
    description: "Pesos Colombianos (COP)",
  },
  {
    iso: "UYU",
    label: "UY$",
    description: "Pesos Uruguayos (UYU)",
  },
  {
    iso: "PEH",
    label: "PE$",
    description: "Soles Peruanos (PEH)",
  },
  {
    iso: "EUR",
    label: "€",
    description: "Euros (EUR)",
  },
  {
    iso: "USD",
    label: "US$",
    description: "Dolares Americanos (USD)",
  },
  {
    iso: "PAB",
    label: "B/.",
    description: "Peso Panameño (PAB)",
  },
];

export default currencies;
