import styled from "styled-components";
import Icon from "../Icon";

export const Separator = styled("div") <{ collapsed }>`
  height: 1px;
  background-color: #D9DDE6;
  max-height: ${({ collapsed }) => (collapsed ? 1 : 1)}px;
  margin-bottom: 0px;
`;

export const Collapse = styled("div")`
  overflow: hidden;
  transition: all 0.3s ease-out;
  margin-bottom: 20px;
  border-radius: 12px;
`;

export const CollapseToggle = styled("button")`
  margin: 0px;
  padding: 12px 0px;
  border: none;
  background: none;
  cursor: pointer;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;

export const CollapseContent = styled("div") <{
  collapsed;
  contentHeight;
}>`
  display: block;
  position: relative;
  height: ${({ contentHeight }) => contentHeight + "px"};
  max-height: ${({ collapsed, contentHeight }) =>
    collapsed ? 0 : contentHeight}px;
  transition: max-height 0.2s ease-in-out;
  overflow: hidden;
`;

export const ToggleIcon = styled(Icon) <{ collapsed }>`
  display: inline-block;
  vertical-align: -4px;
  transition: all 0.5s ease-in-out;
  transform: ${({ collapsed }) =>
    collapsed ? "rotate(0deg)" : "rotate(180deg)"};
`;

export const Arrow = styled("div") <{ isCollapsed }>`
  transition: transform 0.3s ease-in-out;
  transform: rotate(${({ isCollapsed }) => (isCollapsed ? "90deg" : "-90deg")});
`;
