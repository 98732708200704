import styled from "styled-components";
import Avatar from "../Avatar";

export const GroupCard = styled.div`
  background: #ffffff;
  box-shadow: 16px 0px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 16px;
`;

export const GroupCardHeader = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 84px;
  background-color: rgba(108, 92, 231, 0.08);
`;

export const GroupCardBody = styled.div`
  padding: 12px 16px 16px;
  text-align: center;
  border-bottom: 1px solid rgba(105, 119, 155, 0.25);
`;

export const GroupCardFooter = styled.div`
  padding: 12px 16px 20px;
  text-align: center;
`;

export const ExpertName = styled.h5`
  margin: 0px;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #69779b;

  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  white-space: nowrap;
`;

export const GroupName = styled.h3`
  margin: 0px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  color: #2d3436;

  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  white-space: nowrap;
`;

export const GroupTitle = styled.p`
  margin: 0px;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #2d3436;
`;

export const GroupCardAvatar = styled(Avatar)`
  margin-right: 16px;
`;
