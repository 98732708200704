import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, Link as RrdLink } from "react-router-dom";
import { getGroupById, getMetrics } from "../../../../store/actions/group";
import Dropdown from '../../../../components/DropdownBis'
import LastResourses from "../../components/LastResourses";
import Loader from "../../../../components/LogoLoader";
import Grid from "../../../../components/Grid";
import Statistics from "../../components/Statistics";
import CallToAction from "../../components/CallToAction";
import Tabs from "../components/Tabs";
import { SectionTitle } from "../../components/Statistics/styles";
import ModalTelegram from "../../components/ModalTelegram";
import constants from "../../../../constants";
import newContent from "../../../../assets/group/new-content.svg";
import newSession from "../../../../assets/group/new-session.svg";
import newSurveyIcon from "../../../../assets/group/new-survey.svg";
import goToTelegram from "../../../../assets/group/go-to-telegram.svg";
import salesPage from "../../../../assets/group/sales-page.svg";
import editGroup from "../../../../assets//group/edit-group.svg";
import { showModal } from "../../../../store/actions/modal";
import InitialSteps from "../../components/InitialSteps";
import Link from "../../../../components/Link";
import Button from "../../../../components/Button";
import Swipe from "../../../../components/Swipe";
import ModalShareLink from "../../components/ModalShareLink";
import { Group, SurveyInDto } from "../../../../types";
import NewSurveySidebar from "../../Survey/NewSurveySidebar";
import {
  getSurvey,
  resetNewSurvey,
} from "../../../../store/actions/survey";
import Snackbar from "../../../../components/Snackbar";
import { useLayout } from "../../../../providers/LayoutProvider";
import InitialStepsSlides from "./components/InitialStepsSlides";
import { Actions } from "../../components/styles";
import ModalGoToPreview from "../components/ModalGoToPreview";
import { useModules } from "../../../../providers/ModulesProvider";
import ModalCreatePlan from "../../components/ModalCreatePlan";

const ctaLabel = {
  telegram: "Ir al grupo de Telegram",
  whatsapp: "Ir al grupo de Whatsapp",
};

interface ComponentProps {
  match;
  pageContentRef?;
  currentSlide?: number;
  userInformation;
  group: Group;
  groupStates;
  groupMetrics;
  groupMetricsStates;
  survey: SurveyInDto;
  surveyState: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  newSurvey: SurveyInDto;
  newSurveyStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  match,
  groupMetrics,
  group,
  groupStates,
  survey,
  newSurveyStates,
}: ComponentProps) => {
  const [allModulesAreValid, setAllModulesAreValid] = useState<boolean>(false);
  const [showNewSurveySidebar, setShowNewSurveySidebar] = useState<boolean>(false);
  const { modules, actions } = useModules();
  const { layout, setLayout } = useLayout();
  const dispatch = useDispatch();
  const [editCoupon, setEditCoupon] = useState(false);

  useEffect(() => {
    if (
      !!match.params &&
      (!group || (!!group && group?.id !== Number(match?.params?.idGroup)))
    ) {
      dispatch(getGroupById({ group_id: match.params.idGroup }));
      dispatch(getMetrics({ id: match.params.idGroup, filters: [] }));
    }
  }, [match]);

  useEffect(() => {
    if (group && group?.survey && !survey) {
      dispatch(getSurvey({ idSurvey: group?.survey }));
    }
  }, [group]);

  useEffect(() => {
    if (group && group?.plans && group?.plans.length <= 0) {
      dispatch(showModal('create-first-plan-modal'));
    }
  }, [group]);

  useEffect(() => {
    const checkerAllModulesAreValild =
      modules?.every((v) =>
        v.is_valid === true
      ) ||
      false;

    setAllModulesAreValid(checkerAllModulesAreValild);
  }, [modules]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: group?.group_name,
        description: group?.brief,
        back: `/dashboard/${group?.group_type}/list`,
      },
      dashboard: {
        tabs: {
          visible: !!group,
          activeTab: "home",
          component: [
            <Tabs
              key={'navigation-tabs'}
              active="home"
              hiddenTabs={
                group?.recorded ? ["surveys", "library", "configuration"] : ["modules"]
              }
            />,
          ],
        },
      },
      header: {
        visible: !!group,
        actions: [
          <Dropdown>
            <RrdLink
              to={`/group/${group?.slug}`}
              key={'go-to-preview-btn'}
              target="_blank"
            >
              Vista previa
            </RrdLink>

            <button
              disabled={group?.status === 'draft'}
              onClick={() => {
                const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.slug}`;
                navigator.clipboard.writeText(link);
                dispatch(showModal("modal-share-group-link"));
              }}
              key={'share-experience-btn'}
            >
              Compartir taller
            </button>
          </Dropdown>,
          ...(!group?.recorded
            ? [
              <Link
                href={
                  !!group?.telegram_groups &&
                  !!group?.telegram_groups[0] &&
                  group?.telegram_groups[0]?.main_invitation_link
                }
                disabled={
                  !group?.telegram_groups || !group?.telegram_groups[0]
                }
                options={{
                  type: "filled",
                  size: "lg",
                  skin: "purple",
                  native: false,
                }}
                target="_blank"
                key={'ir-al-grupo-de-chat-link'}
              >
                {group?.telegram_groups[0]?.platform
                  ? ctaLabel[group?.telegram_groups[0]?.platform]
                  : "Ir al grupo de chat"}
              </Link>,
            ]
            : []),
          ...(group?.recorded
            ? [
              <Button
                onClick={() => {
                  actions.publish.exec({ group: group?.id });
                }}
                disabled={
                  !allModulesAreValid ||
                  !modules ||
                  (!!modules && modules.length <= 0)
                }
                options={{
                  type: "filled",
                  size: "lg",
                  skin: "purple",
                }}
                loading={actions.publish.states.loading}
                key={"publicar-taller-btn"}
              >
                {group?.status === "draft"
                  ? "Publicar taller"
                  : "Publicar cambios"}
              </Button>,
            ]
            : []),
        ],
      },
    });
  }, [group, allModulesAreValid, modules, actions.publish.states]);

  if (groupStates.loading || (!group && !groupStates.error)) {
    return <Loader />;
  }

  if (groupStates.error === "Not Found" || groupStates.error === "Forbidden") {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <InitialSteps slides={InitialStepsSlides} />

      <Statistics
        metricsObj={group?.recorded ? constants.pageRecordedExperienceHomeGroup.statistics : constants.pageExperienceHomeGroup.statistics}
        metrics={groupMetrics}
        showPreviousPeriod={false}
        actions={[
          {
            href: `/group/${group?.slug}`,
          },
          {
            href: `/dashboard/${group?.group_type}/${group?.id}/price`
            //onClick: () => setEditCoupon(true),
          },
          {
            ...(group?.recorded ? { href: `/group/${group?.slug}` } :
              {
                href: `/dashboard/${group?.group_type === "community" ||
                  group?.group_type === "free_experience"
                  ? "community"
                  : group?.group_type
                  }/${group?.id}/members`,
              })
          },
        ]}
      />

      <div style={{ height: 36 }} />

      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <SectionTitle>Complementos del Taller</SectionTitle>
          </Grid.Col>
        </Grid.Row>

        <div style={{ height: 16 }} />

        <Grid.Row>
          <Grid.Col sm={4}>
            <CallToAction
              title={
                group?.telegram_groups[0]?.platform
                  ? ctaLabel[group?.telegram_groups[0]?.platform]
                  : "Ir al grupo de chat"
              }
              {...(!!group?.telegram_groups && !!group?.telegram_groups[0]
                ? { link: group?.telegram_groups[0]?.main_invitation_link }
                : { action: () => dispatch(showModal("modal-telegram")) })}
              options={{
                preffix: goToTelegram,
                type: "link",
                size: "small",
                nativeLink: false,
              }}
              style={{ marginBottom: "8px" }}
              target="_blank"
            />
          </Grid.Col>

          <Grid.Col sm={4}>
            <CallToAction
              title="Vista previa de mi taller"
              link={`/group/${group?.slug}`}
              options={{
                preffix: salesPage,
                type: "link",
                size: "small",
                nativeLink: false,
              }}
              style={{ marginBottom: "8px" }}
            />
          </Grid.Col>

          <Grid.Col sm={4}>
            <CallToAction
              title="Editar taller"
              link={`/dashboard/experience/edit/${group?.id}`}
              options={{
                preffix: editGroup,
                type: "link",
                size: "small",
                nativeLink: true,
              }}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <div style={{ height: 36 }} />

      {!group?.recorded && (
        <Grid.Container>
          <Grid.Row>
            <Grid.Col>
              <SectionTitle>Acciones</SectionTitle>
            </Grid.Col>
          </Grid.Row>

          <div style={{ height: 16 }} />

          <Grid.Row>
            <Grid.Col>
              <Actions>
                <CallToAction
                  title="Subir contenido"
                  description="Crea un contenido liberado para que conozcan de qué se trata tu taller"
                  link={`/dashboard/${group?.group_type}/${group?.id}/posts/new`}
                  options={{
                    preffix: newContent,
                    color: "rgba(253, 236, 243, 1);",
                  }}
                  style={{ marginBottom: "8px" }}
                />

                <CallToAction
                  title="Calendarizar Encuentro"
                  description="Calendariza los próximos Encuentros para que todos se enteren y reciban notificaciones"
                  link={`/dashboard/${group?.group_type}/${group?.id}/sessions/new`}
                  options={{
                    preffix: newSession,
                    color: "rgba(253, 236, 243, 1);",
                  }}
                  style={{ marginBottom: "8px" }}
                />

                <CallToAction
                  active={!!survey}
                  title={!!survey ? "Encuesta activa" : "Configurar encuesta"}
                  description="Es ideal para que conozcan  tus propuestas y quieran seguir en contacto"
                  {...(!!survey
                    ? {
                      link: `/dashboard/${group?.group_type}/${group?.id}/surveys`,
                    }
                    : { action: () => setShowNewSurveySidebar(true) })}
                  options={{
                    preffix: newSurveyIcon,
                    color: "rgba(253, 236, 243, 1);",
                  }}
                />
              </Actions>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      )}

      {!group?.recorded && (
        <>
          <div style={{ height: 36 }} />

          <Grid.Container>
            <LastResourses groupInformation={group} />
          </Grid.Container>
        </>
      )}

      <NewSurveySidebar
        isVisible={showNewSurveySidebar}
        onClose={() => setShowNewSurveySidebar(false)}
      />
      <ModalTelegram />
      <ModalShareLink />
      <ModalCreatePlan group={group} page="taller" />

      <Snackbar
        visible={newSurveyStates.success || !!newSurveyStates.error}
        options={{
          type: newSurveyStates.success ? "success" : "error",
          position: "center",
        }}
        onHide={() => {
          window.location.href = `/dashboard/${group?.group_type}/${group?.id}/surveys?new=t`;
          dispatch(resetNewSurvey());
        }}
      >
        {newSurveyStates.success
          ? "Encuesta creada correctamente."
          : `Error al crear encuesta.`}
      </Snackbar>

      <Swipe name="swipe-menu">
        <Button
          onClick={() => {
            navigator.clipboard.writeText(
              `${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.slug}`
            );
          }}
          disabled={!group?.telegram_groups}
          options={{
            type: "filled",
            size: "lg",
            skin: "purple",
          }}
        >
          Compartir taller
        </Button>
      </Swipe>
    </>
  );
};

const state = ({ groupStore, userStore, surveyStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: survey, states: surveyState } = surveyStore.selected;
  const { data: newSurvey, states: newSurveyStates } = surveyStore.newOne;
  const { data: groupMetrics, states: groupMetricsStates } = groupStore.metrics;

  return {
    userInformation,
    group,
    groupStates,
    groupMetrics,
    groupMetricsStates,
    survey,
    surveyState,
    newSurvey,
    newSurveyStates,
  };
};

export default connect(state)(Component);
