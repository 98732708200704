import { GetSubscriptionsOutDto, Group } from "../../../types";

export const GET_USER_SUBSCRIPTIONS = "GET_USER_SUBSCRIPTIONS";
export const GET_USER_SUBSCRIPTIONS_SUCCESS = "GET_USER_SUBSCRIPTIONS_SUCCESS";
export const GET_USER_SUBSCRIPTIONS_ERROR = "GET_USER_SUBSCRIPTIONS_ERROR";

export const GET_USER_FREE_SUBSCRIPTIONS = "GET_USER_FREE_SUBSCRIPTIONS";
export const GET_USER_FREE_SUBSCRIPTIONS_SUCCESS = "GET_USER_FREE_SUBSCRIPTIONS_SUCCESS";
export const GET_USER_FREE_SUBSCRIPTIONS_ERROR = "GET_USER_FREE_SUBSCRIPTIONS_ERROR";


export interface GetUserSubscriptions {
  type: typeof GET_USER_SUBSCRIPTIONS;
  payload?: GetSubscriptionsOutDto;
}

export interface GetUserSubscriptionsSuccess {
  type: typeof GET_USER_SUBSCRIPTIONS_SUCCESS;
  payload: Group[];
}

export interface GetUserSubscriptionsError {
  type: typeof GET_USER_SUBSCRIPTIONS_ERROR;
  payload: boolean | string;
}

export interface GetUserFreeSubscriptions {
  type: typeof GET_USER_FREE_SUBSCRIPTIONS;
  payload: null;
}

export interface GetUserFreeSubscriptionsSuccess {
  type: typeof GET_USER_FREE_SUBSCRIPTIONS_SUCCESS;
  payload: Group[];
}

export interface GetUserFreeSubscriptionsError {
  type: typeof GET_USER_FREE_SUBSCRIPTIONS_ERROR;
  payload: boolean | string;
}

export interface State {
  data: Group[] | null,
  states: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    error: false,
    success: false,
  },
};

export type Actions =
  | GetUserSubscriptions
  | GetUserSubscriptionsSuccess
  | GetUserSubscriptionsError
  | GetUserFreeSubscriptions
  | GetUserFreeSubscriptionsSuccess
  | GetUserFreeSubscriptionsError;
