const size = {
  xs: {
    fontSize: "14px",
    height: "20px",
    borderRadius: "6px",
  },
  sm: {
    fontSize: "14px",
    height: "30px",
    borderRadius: "6px",
  },
  md: {
    fontSize: "14px",
    height: "32px",
    borderRadius: "6px",
  },
  lg: {
    fontSize: "14px",
    height: "40px",
    borderRadius: "6px",
  },
  xl: {
    fontSize: "24px",
    height: "52px",
    borderRadius: "6px",
  },
};

const colors = {
  outline: {
    purple: {
      color: "Primary",
      borderColor: "Primary",
      backgroundColor: "Transparent25",
    },
    ligthPurple: {
      color: "PurpleVeryLight",
      borderColor: "PurpleVeryLight",
      backgroundColor: "Transparent25",
    },
    lightGray: {
      color: "LightGray",
      borderColor: "LightGray",
      backgroundColor: "Transparent0",
    },
    white: {
      color: "White",
      borderColor: "White",
      backgroundColor: "Transparent0",
    },
    black: {
      color: "Black",
      borderColor: "Black",
      backgroundColor: "Transparent0",
    },
    lightBlue: {
      color: "Blue3",
      borderColor: "Blue3",
      backgroundColor: "Transparent0",
    },
    rainbow: {
      color: "RainbowText",
      borderColor: "RainbowBackground",
      backgroundColor: "Transparent0",
    },
  },
  filled: {
    purple: {
      color: "White",
      borderColor: "Primary",
      backgroundColor: "Primary",
    },
    ligthPurple: {
      color: "Primary",
      borderColor: "PurpleVeryLight",
      backgroundColor: "Transparent25",
    },
    lightGray: {
      color: "Primary",
      borderColor: "LightGray",
      backgroundColor: "LightGray",
    },
    lightBlue: {
      color: "White",
      borderColor: "Blue3",
      backgroundColor: "Blue3",
    },
    transparent: {
      color: "White",
      borderColor: "Transparent25",
      backgroundColor: "Transparent25",
    },
    white: {
      color: "Primary",
      borderColor: "White",
      backgroundColor: "White",
    },
    black: {
      color: "White",
      borderColor: "Black",
      backgroundColor: "Black",
    },
    rainbow: {
      color: "White",
      borderColor: "RainbowBackground",
      backgroundColor: "RainbowBackground",
    },
  },
  common: {
    purple: {
      color: "Primary",
      borderColor: "White",
      backgroundColor: "White",
    },
  },
  link: {
    purple: {
      color: "Primary",
      borderColor: null,
      backgroundColor: null,
    },
    lightGray: {
      color: "Black75",
      borderColor: null,
      backgroundColor: null,
    },
    lightBlue: {
      color: "Blue3",
      borderColor: null,
      backgroundColor: null,
    },
    black: {
      color: "Black",
      borderColor: null,
      backgroundColor: null,
    },
  },
};

export const button = {
  size,
  colors,
};
