import { SessionOutDto } from "../../types/session.dto";
import {
  NEW_SESSION,
  NewSession,
  ResetNewSession,
  RESET_NEW_SESSION,
} from "../types/session/new";

import {
  UPDATE_ONE_SESSION,
  UpdateOneSession,
  ResetUpdateOneSession,
  RESET_UPDATE_ONE_SESSION,
} from "../types/session/update";

import {
  DELETE_ONE_SESSION,
  DeleteOneSession,
  RESET_DELETE_ONE_SESSION,
  ResetDeleteOneSession,
} from "../types/session/delete";

import {
  GET_ONE_SESSION,
  GetOneSession,
  RESET_GET_ONE_SESSION,
  ResetGetOneSession,
} from "../types/session/one";

import {
  GET_ALL_SESSIONS,
  GetAllSessions,
  RESET_GET_ALL_SESSIONS,
  ResetGetAllSessions,
} from "../types/session/all";

import {
  ShareSessionInTelegram,
  SHARE_SESSION_IN_TELEGRAM,
  RESET_SHARE_SESSION_IN_TELEGRAM,
  ResetShareSessionInTelegram,
} from "../types/session/share";

import { DeleteFile, DELETE_FILE } from "../types/session/delete-file";

export const newSession = (payload: SessionOutDto): NewSession => {
  return { type: NEW_SESSION, payload };
};

export const resetNewSession = (): ResetNewSession => {
  return { type: RESET_NEW_SESSION, payload: null };
};

export const deleteSession = (payload: {
  idSession: number;
  action_notification_message: string;
  notify_action: boolean;
}): DeleteOneSession => {
  return { type: DELETE_ONE_SESSION, payload };
};

export const resetDeleteOneSession = (): ResetDeleteOneSession => {
  return { type: RESET_DELETE_ONE_SESSION, payload: null };
};

export const updateSession = (payload: SessionOutDto): UpdateOneSession => {
  return { type: UPDATE_ONE_SESSION, payload };
};

export const resetUpdateOneSession = (): ResetUpdateOneSession => {
  return { type: RESET_UPDATE_ONE_SESSION, payload: null };
};

export const getAllSessions = ({
  idGroup,
}: {
  idGroup: number;
}): GetAllSessions => {
  return { type: GET_ALL_SESSIONS, payload: { idGroup } };
};

export const resetGetAllSessions = (): ResetGetAllSessions => {
  return { type: RESET_GET_ALL_SESSIONS, payload: null };
};

export const getOneSession = (payload: {
  idSession: number;
}): GetOneSession => {
  return { type: GET_ONE_SESSION, payload };
};

export const resetGetOneSession = (): ResetGetOneSession => {
  return { type: RESET_GET_ONE_SESSION, payload: null };
};

export const deleteFile = (payload: { idFile: number }): DeleteFile => {
  return { type: DELETE_FILE, payload };
};

export const shareSessionInTelegram = (payload: {
  id: number;
}): ShareSessionInTelegram => {
  return { type: SHARE_SESSION_IN_TELEGRAM, payload };
};

export const resetShareSessionInTelegram = (): ResetShareSessionInTelegram => {
  return { type: RESET_SHARE_SESSION_IN_TELEGRAM, payload: null };
};
