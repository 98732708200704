import Header from "../../Header";
import Footer from "../../Footer";
import { Page, PageContent, GlobalStyles } from "./styles";
import { createRef, useEffect, useState } from "react";
import Grid from "../../Grid";
import WhatsApp from "../../Whatsapp";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../../store/actions/page";
import constants from "../../../constants";

const Component = ({ children, useLocation }: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const footerRef = createRef<HTMLElement>();
  const headerRef = createRef<HTMLElement>();
  const [minHeight, setMinHeight] = useState(0);
  const [currentPageLocally, setCurrentPageLocally] = useState<any>();

  useEffect(() => {
    const page =
      location &&
      constants.pages.filter((page) => {
        return location.pathname.match(page.regex);
      });
    setCurrentPageLocally(page[0]);
    dispatch(setCurrentPage(page[0]));

    document.body.style.height = "auto";
    document.body.style.overflow = "auto";
  }, [location]);

  useEffect(() => {
    headerRef.current &&
      footerRef.current &&
      setMinHeight(
        headerRef.current.scrollHeight + footerRef.current.scrollHeight
      );
  }, [headerRef, footerRef]);

  return (
    <>
      <GlobalStyles />
      <PageContent>{children}</PageContent>
      <WhatsApp floating />
    </>
  );
};

export default Component;
