import styled from "styled-components";

export const Card = styled('div')`
  background: #040404;
  background: linear-gradient(155deg, #2E2E30 0%, #040404 100%);
  padding: 28px 22px;
`

export const Text = styled('p')`
font-size: 14px;
font-weight: 500;
margin: 0px;
color: white;
text-align: center;
`

export const Title = styled('h2')`
  font-size: 22px;
  line-height: 28px;
  margin: 0px;
  font-weight: 600;
  background: linear-gradient(155deg, #ACE6D5 0%, #6EC3E9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
`