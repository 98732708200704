import Grid from "../../../../../components/Grid";
import { Group, User } from "../../../../../types";
import {
  Section,
  SubHeader,
  Expert,
  Avatar,
  Name,
  Button,
  Link,
} from "./styles";
import ReactDOM from "react-dom";
import { useContext, useEffect, useState } from "react";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import dateHanlder from "../../../../../helpers/dates-hanlder";
import { connect } from "react-redux";
import { SubscriptionContext } from "../../../../../providers/Subscription";
import { isSubscribed } from "../../../../../helpers/isSubscribed";
import { isOwnGroup } from "../../../../../helpers/isOwnGroup";
import SubscribeButton from "../SubscibeButton";

interface ComponentProps {
  group: Group | null;
  userInformation: User;
  subscriptions;
}

const FooterSticky = document.getElementById("footer-sticky") as HTMLElement;
const HeaderSticky = document.getElementById("header-sticky") as HTMLElement;

const Component = ({
  group,
  userInformation,
  subscriptions,
}: ComponentProps) => {
  const { startSubscription } = useContext(SubscriptionContext);
  const { isMobile } = useWindowSize();
  const [portal, setPortal] = useState(FooterSticky);
  const [isOwner, setIsOwner] = useState<boolean>();
  const [inscriptionEnded, setInscriptionEnded] = useState<boolean>();

  useEffect(() => {
    setIsOwner(isOwnGroup({ group, expertId: userInformation?.expert?.id }));
  }, [group, userInformation]);

  useEffect(() => {
    if (!!group) {
      setInscriptionEnded(
        dateHanlder.isExpired(
          group.launch_date || group.registration_closing_date
        )
      );
    }
  }, [group]);

  if (!isMobile || isSubscribed(subscriptions, group?.id)) {
    return null;
  }

  return ReactDOM.createPortal(
    <Section>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <SubHeader>
              <Expert>
                <Avatar
                  src={group?.expert?.profile_pic?.url}
                  alt={group?.expert?.profile_pic?.url}
                  width="40px"
                  height="40px"
                />
                <Name>{group?.creator_name}</Name>
              </Expert>

              {/* <SubscribeButton options={{ block: true }} /> */}
            </SubHeader>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Section>,
    portal
  );
};

const state = ({ groupStore, userStore }) => {
  const { data: group } = groupStore.group;
  const { data: userInformation } = userStore.information;
  const { data: subscriptions } = userStore.subscriptions;

  return {
    group,
    userInformation,
    subscriptions,
  };
};

export default connect(state)(Component);
