import styled from 'styled-components'

export const Description = styled('p')`
  font-size: 16px;
  font-weight: normal;
  margin: 0px;
`

export const List = styled("div")`
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  // max-height: calc(100vh - 260px);
  // overflow: auto;
}
`;

export const ListItem = styled("div")``;

export const Preview = styled("div")`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
