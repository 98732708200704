import * as Yup from "yup";

export const schema = Yup.object().shape({
  content_data: Yup.object({
    fields: Yup.array().of(
      Yup.object().shape({
        caption: Yup.string().required("Ingresa una pregunta"),
        field_type: Yup.string().required(""),
        option: Yup.array().of(Yup.string()),
      })
    ),
  }),
});

interface FormValues {
  content_data: {
    fields?: {
      caption: string;
      field_type: "short_text" | "drowdownlist";
      option?: string[];
    }[];
  };
}

export const initialValues: FormValues = {
  content_data: { fields: [] },
};
