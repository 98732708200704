import styled from "styled-components";

export const List = styled("div")`
  margin-top: 40px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const CardEmpty = styled("div")`
  padding: 13px 11px 9px 16px;
  background: white;
  border-radius: 16px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0px;
  row-gap: 13px;

  & > div {
    margin-left: 12px;
  }

  h3 {
    margin: 0px 0px 1px;
    font-weight: 500;
    font-size: 16px;
    lin-height: 19px;
    color: #2d3436;
  }

  p {
    margin: 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(45, 52, 54, 0.75);
  }

  * {
    &:nth-child(n + 3) {
      grid-column: span 2;
    }
  }
`;
