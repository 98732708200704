import { Tabs, Tab, Link, Center } from "./styles";
import { connect } from "react-redux";
import { HorizontalScroll } from "../../../../../StyledComponents";
import { Group, SessionInDto } from "../../../../../../types";
import { Sonar } from "../../../../../../pages/Dashboard/Sessions/components/ReducedSessionCard/styles";
import { useEffect, useRef, useState } from "react";
import {
  sessionExpired,
  sessionInProgress,
  convertFloatHourToMinutes
} from "../../../../../../helpers/dates-hanlder";

interface ComponentProps {
  currentPage: string
  groupInformation: Group;
  active: string;
  sessions: SessionInDto[];
  hiddenTabs?: ("activity-summary" | "library" | "sessions" | "about" | 'modules')[];
}

const Component = ({
  currentPage,
  groupInformation,
  active,
  sessions,
  hiddenTabs = [],
}: ComponentProps) => {
  const [thereIsSessionInProgress, setThereIsSessionInProgress] = useState(false);
  const [isScrollingOne, setIsScrollingOne] = useState(true);

  useEffect(() => {
    setThereIsSessionInProgress(
      sessions
        ?.filter((session) => {
          const minutes = session?.duration_type === "hour" ? convertFloatHourToMinutes(session?.duration) : session?.duration
          return sessionInProgress(session.session_datetime, minutes) && !sessionExpired(session.session_datetime, minutes)
        }).length > 0
    );
  }, [sessions]);

  const activeTabRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {

    if (tabsRef.current && activeTabRef.current) {
      // Calculate the offset of the selected element from the left of the menu
      const menuRect = tabsRef.current.getBoundingClientRect();
      const selectedElementRect = activeTabRef.current.getBoundingClientRect();
      const scrollOffset = selectedElementRect.left - menuRect.left;

      // Calculate the amount to scroll to center the selected element
      const containerWidth = tabsRef.current.offsetWidth;
      const selectedElementWidth = activeTabRef.current.offsetWidth;
      const scrollAmount = scrollOffset - (containerWidth - selectedElementWidth) / 2;

      // Scroll the menu to center the selected element
      tabsRef.current.scrollTo({ left: scrollAmount, behavior: 'smooth' });
    }


  }, [active]);


  if (!groupInformation) {
    return <></>;
  }
  return (

    <Tabs ref={tabsRef}>
      {!hiddenTabs?.includes("activity-summary") && (
        <Tab ref={currentPage === "GROUP_DETAILS_ACTIVITY_SUMMARY" ? activeTabRef : null}>
          <Link
            to={`/group/${groupInformation?.slug}/activity-summary`}
            active={currentPage === "GROUP_DETAILS_ACTIVITY_SUMMARY"}
          >
            Panel general
          </Link>
        </Tab>
      )}
      {!hiddenTabs?.includes("modules") && (
        <Tab ref={currentPage === "modules" ? activeTabRef : null}>
          <Link
            to={`/group/${groupInformation?.slug}/modules`}
            active={currentPage === "modules"}
          >
            Aula virtual
          </Link>
        </Tab>
      )}
      {!hiddenTabs?.includes("library") && (
        <Tab ref={currentPage === "GROUP_DETAILS_LIBRARY" ? activeTabRef : null}>
          <Link
            to={`/group/${groupInformation?.slug}/library`}
            active={currentPage === "GROUP_DETAILS_LIBRARY"}
          >
            Biblioteca
          </Link>
        </Tab>
      )}
      {!hiddenTabs?.includes("sessions") &&
        groupInformation?.group_type !== "free_experience" && (
          <Tab ref={currentPage === "GROUP_DETAILS_SESSIONS" ? activeTabRef : null}>
            <Link
              to={`/group/${groupInformation?.slug}/sessions`}
              active={currentPage === "GROUP_DETAILS_SESSIONS"}
            >
              <Center>
                {thereIsSessionInProgress && <Sonar />}
                Encuentros
              </Center>
            </Link>
          </Tab>
        )}
      {!hiddenTabs?.includes("about") && (
        <Tab ref={currentPage === "GROUP_DETAILS" ? activeTabRef : null}>
          <Link
            to={`/group/${groupInformation?.slug}`}
            active={currentPage === "GROUP_DETAILS"}
          >
            Acerca de
          </Link>
        </Tab>
      )}
    </Tabs>

  );
};

const state = ({ groupStore, sessionStore, pageStore }) => {
  const { data: groupInformation } = groupStore.group;
  const { data: sessions, states: sessionsStates } = sessionStore.all;

  return {
    groupInformation,
    sessions,
    sessionsStates,
    currentPage: pageStore?.name,
  };
};

export default connect(state)(Component);
