import { GetOneModuleInDto, GetOneModuleOutDto } from "../../../types/module";

export const SELECTED_MODULE = "SELECTED_MODULE";
export const SELECTED_MODULE_SUCCESS = "SELECTED_MODULE_SUCCESS";
export const SELECTED_MODULE_ERROR = "SELECTED_MODULE_ERROR";

export interface SelectedModule {
  type: typeof SELECTED_MODULE;
  payload: GetOneModuleOutDto;
}

export interface SelectedModuleSuccess {
  type: typeof SELECTED_MODULE_SUCCESS;
  payload: GetOneModuleInDto;
}

export interface SelectedModuleError {
  type: typeof SELECTED_MODULE_ERROR;
  payload: string | boolean;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | SelectedModule
  | SelectedModuleSuccess
  | SelectedModuleError;
