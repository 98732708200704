import {
  initialState,
  Actions,
  State,
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_OUT,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_ERROR,
  PASSWORD_RECOVERY,
  PASSWORD_RECOVERY_SUCCESS,
  PASSWORD_RECOVERY_ERROR,
  RESET_PASSWORD_RECOVERY,
} from "../types/auth";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        signin: {
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        signin: {
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case SIGN_IN_ERROR:
      return {
        ...state,
        signin: {
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case SIGN_UP:
      return {
        ...state,
        signup: {
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signup: {
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case SIGN_UP_ERROR:
      return {
        ...state,
        signup: {
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case SIGN_OUT:
      return {
        ...state,
        signout: {
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        signout: {
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case SIGN_OUT_ERROR:
      return {
        ...state,
        signout: {
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case PASSWORD_RECOVERY:
      return {
        ...state,
        passwordRecovery: {
          states: {
            loading: true,
            success: false,
            error: false,
          },
        },
      };
    case PASSWORD_RECOVERY_SUCCESS:
      return {
        ...state,
        passwordRecovery: {
          states: {
            loading: false,
            success: true,
            error: false,
          },
        },
      };
    case PASSWORD_RECOVERY_ERROR:
      return {
        ...state,
        passwordRecovery: {
          states: {
            loading: false,
            success: false,
            error: action.payload,
          },
        },
      };
    case RESET_PASSWORD_RECOVERY:
      return {
        ...state,
        passwordRecovery: initialState.passwordRecovery,
      };
    default:
      return state;
  }
};

export default reducers;
