import {
  SaveMeetPayload,
  GetMeetPayload,
  GET_MEET,
  SAVE_MEET,
} from "../types/meet";

export const saveMeet = (value: SaveMeetPayload) => {
  return { type: SAVE_MEET, payload: value };
};

export const getMeet = (value: GetMeetPayload) => {
  return { type: GET_MEET, payload: value };
};
