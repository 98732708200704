import { Faqs, Questions, Guide, Title, Img } from "./styles";
import Grid from "../../../../components/Grid";
import Row from "../../../../components/Grid/Row";
import Col from "../../../../components/Grid/Col";
import constants from "../../../../constants";
import Acordeon from "../../components/Acordeon";

const Component = () => {
  return (
    <Faqs id="faqs-section">
      <Grid.Container>
        <Row>
          <Col sm={5}>
            <Title>
              Te respondemos las preguntas realizadas por nuestros usuarios con
              mas frecuencia.
            </Title>
          </Col>

          <Col sm={6}>
            <Acordeon
              items={constants.homePage.faqs}
              align="right"
              theme="black"
            />
          </Col>
        </Row>
      </Grid.Container>
    </Faqs>
  );
};

export default Component;
