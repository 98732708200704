import Header from "../../Header";
import { Page, PageContent, GlobalStyles } from "./styles";
import { createRef, useEffect, useState } from "react";
import WhatsApp from "../../Whatsapp";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../../store/actions/page";
import constants from "../../../constants";

const Component = ({ children, useLocation, showMenu = true }: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const footerRef = createRef<HTMLElement>();
  const headerRef = createRef<HTMLElement>();
  const [minHeight, setMinHeight] = useState(56);
  const [currentPageLocally, setCurrentPageLocally] = useState<any>();

  useEffect(() => {
    const page =
      location &&
      constants.pages.filter((page) => {
        return location.pathname.match(page.regex);
      });
    setCurrentPageLocally(page[0]);
    dispatch(setCurrentPage(page[0]));
  }, [location]);

  useEffect(() => {
    if (!!headerRef.current && !!footerRef.current) {
      setMinHeight(
        headerRef.current.scrollHeight + footerRef.current.scrollHeight
      );
    }
  }, [headerRef, footerRef]);

  return (
    <>
      <GlobalStyles />
      {showMenu && <Header forwardedRef={headerRef} />}
      <Page
        showMenu={showMenu}
        minHeight={minHeight}
        currentPage={currentPageLocally}
      >
        <PageContent>{children}</PageContent>
      </Page>
      <WhatsApp floating />
    </>
  );
};

export default Component;
