import { Helmet } from "react-helmet";
import logo from "./../../../assets/logo.png";
import Grid from "../../../components/Grid";
import Row from "../../../components/Grid/Row";
import Col from "../../../components/Grid/Col";
import { TermsContent } from "../components/TermsContent/styles"

function CreatorTermsOfServices() {
    return (
        <div className="App">
            <header className="App-header">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Klouser - Terminos de servicio para creadores</title>
                    <meta
                        name="description"
                        content="Terminos de uso y servicio de la plataforma Klouser para creadores."
                    />
                    <meta property="og:title" content="Klouser" />
                    <meta
                        name="description"
                        property="og:description"
                        content="Terminos de uso y servicio de la plataforma Klouser para creadores."
                    />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content={logo} />
                    <meta property="og:site_name" content="Klouser" />
                    <meta
                        property="og:url"
                        content={`${
                            (window as any).SEO_PROTOCOL || (window as any).location.protocol
                        }//${(window as any).SEO_HOST || (window as any).location.host}`}
                    />
                    <meta property="og:locale" content="es_LA" />
                    <link
                        rel="canonical"
                        href={`${
                            (window as any).SEO_PROTOCOL || (window as any).location.protocol
                        }//${(window as any).SEO_HOST || (window as any).location.host}`}
                    />
                </Helmet>

                <Grid.Container>
                    <Row>
                        <Col style={{marginTop: "50px"}} offset={{ md: 1 }} md={10}>
                            <TermsContent>
                                <h3> CONDICIONES DE USO PARA CREADORES</h3>
                                <p>AL UTILIZAR NUESTRO SITIO WEB COMO CREADOR, USTED ACEPTA ESTAS CONDICIONES - LÉALAS ATENTAMENTE</p>

                                <ol>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Introducci&oacute;n: Estas Condiciones de uso para creadores son condiciones adicionales que se aplican si utiliza Klouser como creador (tambi&eacute;n denominadas "usted" y "su" en estas Condiciones de uso para creadores). Estas Condiciones de Uso para Creadores forman parte de su acuerdo con nosotros.</span></p>
                                    </li>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Interpretaci&oacute;n: En estas Condiciones </span><span>de</span><span> Uso para Creadores, los t&eacute;rminos definidos tienen el mismo significado que se les da en las </span><span>Condiciones de Uso para todos los Usuarios</span><span>. Adem&aacute;s:</span></p>
                                    </li>
                                    <ol>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>"Impuesto" incluir&aacute; todas las formas de impuestos y tasas, derechos, impuestos, contribuciones, grav&aacute;menes, retenciones o responsabilidades legales, gubernamentales, estatales, federales, provinciales, locales o municipales, dondequiera que sean exigibles y ya sean del Reino Unido o de cualquier otra jurisdicci&oacute;n.</span></p>
                                        </li>
                                    </ol>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Otros t&eacute;rminos que se aplicar&aacute;n a su uso de Klouser: Los siguientes t&eacute;rminos tambi&eacute;n se aplicar&aacute;n a su uso de Klouser y usted los acepta:</span></p>
                                    </li>
                                    <ol>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>El </span><span>Contrato Tipo entre Seguidor y Cre</span><span>ador - que no forma parte de su acuerdo con nosotros, pero que rige y establece los t&eacute;rminos aplicables a cada Transacci&oacute;n Seguidor/Creador que usted realice en Klouser; y</span></p>
                                        </li>
                                    </ol>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Otros t&eacute;rminos que pueden aplicarse a su uso de Klouser: Los siguientes t&eacute;rminos adicionales pueden aplicarse a su uso de Klouser:</span></p>
                                    </li>Nos reservamos el derecho a cerrar su cuenta Klouser si se nos notifica o tenemos conocimiento de cualquier incumplimiento fiscal por su parte.
                                    <li dir="ltr">
                                        <p dir="ltr"><span>&iquest;Cu&aacute;les son las tarifas que cobramos a los Creadores por el uso de Klouser? Le cobramos una comisi&oacute;n del diez por ciento (10%) de todos los pagos de los seguidores que se le efect&uacute;en (excluido cualquier elemento de IVA del pago de los seguidores) (denominada "nuestra comisi&oacute;n"). El noventa por ciento (90%) restante del Pago del Seguidor (excluido cualquier elemento de IVA del Pago del Seguidor) es pagadero a usted (denominado "Ganancias del Creador"). Nuestra Tarifa incluye los costes de prestaci&oacute;n, mantenimiento y funcionamiento de Klouser y de almacenamiento de su Contenido. Nuestros honorarios se deducen del pago de los seguidores, y los ingresos de los creadores se le abonan de la forma descrita en la secci&oacute;n </span><span>Pagos a los creadores</span><span>.</span></p>
                                    </li>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>C&oacute;mo configurar su cuenta como cuenta de Creador: Para configurar tu cuenta como cuenta Creador:</span></p>
                                    </li>
                                    <ol>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>En la p&aacute;gina de su cuenta de usuario deber&aacute; a&ntilde;adir una cuenta bancaria o los datos de pago de su cuenta bancaria o un m&eacute;todo de pago.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>En la p&aacute;gina de tu cuenta de usuario deber&aacute;s seleccionar uno de los m&eacute;todos disponibles proporcionados por Klouser para transferirte tus Ganancias de Creador. Estos m&eacute;todos se denominan Opciones de Pago.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Es posible que tambi&eacute;n tenga que presentar informaci&oacute;n adicional en funci&oacute;n del pa&iacute;s en el que resida.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Una vez que haya configurado su cuenta como una cuenta de Creador, entonces si desea cobrar a sus Seguidores una cuota de suscripci&oacute;n mensual tendr&aacute; que establecer su precio de suscripci&oacute;n para sus Seguidores.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Entonces podr&aacute;s empezar a a&ntilde;adir Contenido y los Usuarios podr&aacute;n suscribirse a tu cuenta para convertirse en tus Seguidores.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Si pierde el acceso a su cuenta, puede restablecer su contrase&ntilde;a, pero para ello necesitar&aacute; conocer la direcci&oacute;n de correo electr&oacute;nico utilizada para configurar la cuenta. Si no recuerda la direcci&oacute;n de correo electr&oacute;nico utilizada para configurar la cuenta, es posible que le pidamos que nos facilite documentos de identidad y fotos, as&iacute; como cualquier otra prueba adicional que podamos solicitar razonablemente para demostrar su identidad.</span></p>
                                        </li>
                                    </ol></ol>
                                <p dir="ltr">&nbsp;</p>
                                <ol start={8}>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Responsabilidad jur&iacute;dica personal de los Creadores: S&oacute;lo las personas f&iacute;sicas pueden ser Creadores. Cada Creador est&aacute; obligado personalmente por las Condiciones del servicio. Si tiene un agente, una agencia, una sociedad de gesti&oacute;n u otro tercero que le asista en la gesti&oacute;n de su cuenta de Creador (o la gestione en su nombre), ello no afectar&aacute; a su responsabilidad legal personal. Nuestra relaci&oacute;n es con usted, y no con ning&uacute;n tercero, y usted ser&aacute; legalmente responsable de garantizar que todo el Contenido publicado y todo el uso de su cuenta cumpla las Condiciones del servicio.</span></p>
                                    </li>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Transacciones Seguidor/Creador: Esta secci&oacute;n describe los t&eacute;rminos que se aplican a las Transacciones de Seguidor/Creador:</span></p>
                                    </li>
                                    <ol>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Todas las Transacciones entre Seguidores y Creadores son contratos entre Seguidores y Creadores en los t&eacute;rminos del Contrato Tipo </span><span>entre Seguidor y Creador</span><span>. Aunque facilitamos las Transacciones entre Seguidores y Creadores proporcionando la plataforma Klouser y almacenando Contenido, no somos parte en el Contrato Est&aacute;ndar </span><span>entre Seguidor y Creador</span><span> ni en ning&uacute;n otro contrato que pueda existir entre un Seguidor y un Creador, y no somos responsables de ninguna Transacci&oacute;n entre Seguidor y Creador.</span></p>
                                        </li>
                                    </ol></ol>
                                <p dir="ltr">&nbsp;</p>
                                <ol start={2}>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Los Pagos de Seguidor no incluyen el IVA, que se a&ntilde;adir&aacute; al tipo vigente aplicable a los Pagos de Seguidor.</span></p>
                                    </li>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Cuando usted reciba confirmaci&oacute;n de Klouser, ya sea en la p&aacute;gina "Estados de Cuenta" de su cuenta de Usuario o por correo electr&oacute;nico (o ambos), de que la Transacci&oacute;n Seguidor/Creador ha sido confirmada, usted deber&aacute; cumplir su parte de dicha Transacci&oacute;n Seguidor/Creador (por ejemplo, permitiendo que el Seguidor vea el Contenido de su cuenta de Creador y/o proporcionando el Contenido personalizado pagado por el Seguidor y/o permitiendo que el Seguidor utilice la funci&oacute;n de interacci&oacute;n con el Seguidor pagada (seg&uacute;n proceda)). Usted acepta indemnizarnos por cualquier incumplimiento de esta obligaci&oacute;n por su parte (lo que significa que usted ser&aacute; responsable de cualquier p&eacute;rdida o da&ntilde;o (incluido el lucro cesante) que suframos como consecuencia del incumplimiento de esta obligaci&oacute;n por su parte).</span></p>
                                    </li>
                                </ol>
                                <p dir="ltr">&nbsp;</p>
                                <ol start={10}>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Contenido - condiciones generales: Adem&aacute;s de los t&eacute;rminos establecidos en otras partes de las Condiciones del servicio, los siguientes t&eacute;rminos se aplican al Contenido enviado, mostrado, cargado o publicado por usted como Creador en Klouser:</span></p>
                                    </li>
                                    <ol>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Su Contenido no es confidencial, y usted autoriza a sus Seguidores a acceder y ver su Contenido en Klouser para su propio uso legal y personal, y de acuerdo con cualquier licencia que usted conceda a sus Seguidores.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Usted garantiza (lo que significa que nos hace una promesa legalmente exigible) que para cada elemento de Contenido que usted publique, muestre, cargue o publique en Klouser:</span></p>
                                        </li>
                                        <ol>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>el Contenido cumple &iacute;ntegramente las Condiciones del servicio&nbsp;</span></p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>usted posee todos los derechos necesarios para licenciar y comercializar su Contenido en Klouser, incluso en cada territorio donde tenga Seguidores;</span></p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>usted es propietario de su Contenido (y de todos los derechos de propiedad intelectual sobre el mismo) o tiene una licencia v&aacute;lida para ofrecer y suministrar su Contenido a sus Seguidores;</span></p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>si su Contenido incluye o utiliza material de terceros, usted ha obtenido todos los derechos, licencias, consentimientos escritos y liberaciones que sean necesarios para el uso de dicha propiedad de terceros en su Contenido y para el posterior uso y explotaci&oacute;n de dicho Contenido en Klouser; y</span></p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>es el Contenido:</span></p>
                                            </li>
                                            <ol>
                                                <li dir="ltr">
                                                    <p dir="ltr"><span>de calidad satisfactoria, teniendo en cuenta cualquier descripci&oacute;n del Contenido, el precio y todas las dem&aacute;s circunstancias pertinentes, incluida cualquier declaraci&oacute;n o manifestaci&oacute;n que usted haga sobre la naturaleza del Contenido en su cuenta o en cualquier publicidad;</span></p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr"><span>razonablemente adecuado para cualquier prop&oacute;sito que el Seguidor le haya hecho saber que es el prop&oacute;sito para el que el Seguidor est&aacute; utilizando el Contenido; y</span></p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr"><span>tal y como usted lo ha descrito.</span></p>
                                                </li>
                                            </ol></ol>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Usted acepta que ser&aacute; responsable ante nosotros y nos indemnizar&aacute; si alguna de las garant&iacute;as de la secci&oacute;n 9(b) es falsa. Esto significa que usted ser&aacute; responsable de cualquier p&eacute;rdida o da&ntilde;o (incluido el lucro cesante) que suframos como consecuencia de la falta de veracidad de cualquiera de las garant&iacute;as.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>No somos responsables ni respaldamos ning&uacute;n aspecto de los Contenidos publicados por usted o por cualquier otro Usuario de Klouser. No tenemos ninguna obligaci&oacute;n de supervisar ning&uacute;n Contenido y no tenemos ning&uacute;n control directo sobre lo que pueda incluir su Contenido.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Tambi&eacute;n acepta actuar como custodio de los registros del Contenido que cargue en Klouser.</span></p>
                                        </li>
                                    </ol>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Publicidad en Klouser:</span></p>
                                    </li>
                                    <ol>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Si publica o carga Contenido de v&iacute;deo en su cuenta de Creador dise&ntilde;ado para promocionar, directa o indirectamente, bienes, servicios o im&aacute;genes de terceros a cambio de un pago, otra contraprestaci&oacute;n valiosa o fines de autopromoci&oacute;n (incluidos publicidad, patrocinio y colocaci&oacute;n de productos) (en conjunto, "Contenido publicitario"), deber&aacute; cumplir los requisitos establecidos en las secciones 10(b) y (c) de las presentes Condiciones de uso para Creadores.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Requisitos - Contenido publicitario: Debe asegurarse de que cualquier Contenido Publicitario que publique en su cuenta de Creador:</span></p>
                                        </li>
                                        <ol>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>no lo hace:</span></p>
                                            </li>
                                            <ol>
                                                <li dir="ltr">
                                                    <p dir="ltr"><span>prejuzgar el respeto de la dignidad humana;</span></p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr"><span>incluyan o promuevan la discriminaci&oacute;n por motivos de sexo, origen racial o &eacute;tnico, nacionalidad, religi&oacute;n o convicciones, discapacidad, edad u orientaci&oacute;n sexual;</span></p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr"><span>fomentar comportamientos perjudiciales para la salud o la seguridad;</span></p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr"><span>fomentar comportamientos gravemente perjudiciales para la protecci&oacute;n del medio ambiente;</span></p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr"><span>causar perjuicio f&iacute;sico, mental o moral a cualquier persona;</span></p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr"><span>instar directamente a las personas a comprar o alquilar bienes o servicios de manera que se aproveche de su inexperiencia o credulidad;</span></p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr"><span>animar directamente a las personas a persuadir a otras para que compren o alquilen bienes o servicios;</span></p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr"><span>explotar la confianza de las personas en los dem&aacute;s; o</span></p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr"><span>mostrar injustificadamente a las personas en situaciones peligrosas;</span></p>
                                                </li>
                                            </ol>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>no hace publicidad de cigarrillos y otros productos del tabaco, cigarrillos electr&oacute;nicos y envases de recarga de cigarrillos electr&oacute;nicos, drogas ilegales ni medicamentos sujetos a prescripci&oacute;n m&eacute;dica;</span></p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>no anuncie, promueva o facilite el juego ilegal, y</span></p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>con respecto a cualquier Contenido publicitario de bebidas alcoh&oacute;licas, no est&eacute; dirigido a menores y no fomente el consumo inmoderado de alcohol.</span></p>
                                            </li>
                                        </ol>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Requisito de transparencia - Contenido publicitario: Usted debe declarar que cualquier Contenido Publicitario que publique o suba a Klouser contiene publicidad incluyendo el significante #ad en el pie de foto del Contenido Publicitario antes de publicarlo o subirlo.</span></p>
                                        </li>
                                    </ol>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Contenido en coautor&iacute;a:</span></p>
                                    </li>
                                    <ol>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Si carga Contenido en su cuenta de Creador que muestre a otra persona distinta de usted o adem&aacute;s de usted (incluso si esa persona no puede ser identificada a partir del Contenido) ("Contenido de coautor&iacute;a"), usted garantiza (lo que significa que nos hace una promesa legalmente exigible) que cada persona que aparezca en cualquier Contenido de coautor&iacute;a cargado en su cuenta es i) un Creador en Klouser; o ii) un adulto que da su consentimiento, y que ha verificado la identidad y la edad de cada una de esas personas y proporcionar&aacute; los documentos justificativos que podamos solicitar a nuestra discreci&oacute;n.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Adem&aacute;s, usted garantiza que ha obtenido y mantiene registrado el consentimiento por escrito de cada individuo que aparece en su Contenido de Coautor&iacute;a de que dicho individuo:</span></p>
                                        </li>
                                        <ol>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>ha dado su consentimiento expreso, previo y plenamente informado para su aparici&oacute;n en el Contenido en Coautor&iacute;a; y</span></p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>ha dado su consentimiento para que el Contenido en coautor&iacute;a en el que aparece sea publicado en Klouser.</span></p>
                                            </li>
                                        </ol>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Adem&aacute;s de las confirmaciones de las secciones 11(a) y (b) anteriores, usted se compromete a que, si sube Contenido de coautor&iacute;a en el que la otra persona o personas que aparecen en el Contenido mantienen una cuenta de Creador en Klouser, etiquetar&aacute; la cuenta o cuentas de Klouser de cualquier persona o personas que aparezcan en el Contenido de coautor&iacute;a y que puedan ser identificadas a partir del mismo.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>En caso de que cualquier Contenido de coautor&iacute;a sea una obra de autor&iacute;a conjunta, usted ser&aacute; el &uacute;nico responsable de obtener las licencias o consentimientos necesarios de cualquier otro autor conjunto del Contenido que sean suficientes para permitir que dicho Contenido se cargue en Klouser y est&eacute; disponible en Klouser.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Usted acepta que s&oacute;lo haremos arreglos para que las Ganancias del Creador se paguen a la cuenta del Creador a la que se carg&oacute; el Contenido de Coautor&iacute;a. El Creador que subi&oacute; el Contenido en Coautor&iacute;a es el &uacute;nico responsable de dividir y distribuir cualquier ganancia generada por el Contenido en Coautor&iacute;a entre los individuos que aparecen en dicho Contenido en Coautor&iacute;a. Cualquier acuerdo de reparto de ingresos ser&aacute; un acuerdo independiente y privado entre usted y dicho(s) individuo(s), y nosotros no somos responsables de proporcionar o hacer cumplir dichos acuerdos. Usted entiende y acepta que no tiene derecho a ninguna Ganancia de Creador obtenida por cualquier Contenido de Coautor&iacute;a en el que usted aparezca pero que est&eacute; publicado en la cuenta de otro Creador. Si publica Contenido de coautor&iacute;a en su cuenta, podemos pedirle que proporcione informaci&oacute;n legal v&aacute;lida y completa de todas las personas que aparecen en el Contenido de coautor&iacute;a. Si usted no proporciona cualquier informaci&oacute;n solicitada por nosotros a petici&oacute;n nuestra, podemos eliminar el Contenido de Coautor&iacute;a, restringir sus derechos y permisos para publicar como Creador, cancelar su cuenta, y / o retener la totalidad o cualquier parte de las Ganancias de Creador ganadas pero a&uacute;n no pagadas a usted.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Usted se compromete a eximirnos y a no presentar ninguna reclamaci&oacute;n contra nosotros derivada del Contenido en Coautor&iacute;a. Usted acepta que todas las reclamaciones derivadas del Contenido en Coautor&iacute;a se presentar&aacute;n contra el Creador o Creadores que publicaron el Contenido en Coautor&iacute;a o la persona o personas que aparecieron en el Contenido en Coautor&iacute;a (seg&uacute;n corresponda).</span></p>
                                        </li>
                                    </ol>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Pagos a los creadores:</span></p>
                                    </li>
                                    <ol>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Todos los pagos de los seguidores ser&aacute;n recibidos por un proveedor de pagos externo aprobado por nosotros.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Si ha elegido la opci&oacute;n de pago con Stripe, Stripe cobrar&aacute; el pago de los seguidores y abonar&aacute; las ganancias de los creadores en su cuenta bancaria.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Cuando Stripe no sea la opci&oacute;n de pago elegida por usted, nuestros honorarios se deducir&aacute;n del pago recibido de los seguidores y nosotros o una de nuestras filiales retendr&aacute; sus ganancias de creador en su nombre.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Para los pagos de suscripción, su cuenta Klouser se actualizar&aacute; una vez por mes, siendo en los primeros 3 dias habiles de cada mes y sumando a su Cuenta las Ganancias de Creador respectivas del mes pasado. Sus Ganancias de Creador de su cuenta Klouser podrán ser transferidas a sus Opciones de Pago una vez que dichas Ganancias de Creador aparezcan en su cuenta Klouser o bien las Ganancias de Creador sean notificadas por correo electronico</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Una vez que se acreditan las Ganancias de Creador en su cuenta de Klouser, estas podrán ser transferidas dentro de los primeros 3 dias habiles del mes sin confirmación previa de parte del Creador utilizando los datos de las Opciones de Pago completadas en su cuenta.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Para realizar una retirada de las Ganancias de Creador de su cuenta Klouser, debe tener al menos la cantidad m&iacute;nima de pago en su cuenta Klouser. Por favor, haga clic en la p&aacute;gina de Banca de su cuenta para ver cu&aacute;l es la cantidad m&iacute;nima de pago para su pa&iacute;s de residencia y Opci&oacute;n de Pago.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>La cantidad que ves en el "saldo actual" de tu cuenta Klouser son tus ganancias de creador en ese momento. Si ha elegido la opci&oacute;n de pago "Stripe", las cifras de los pagos a los seguidores y las ganancias de los creadores se reflejar&aacute;n en su moneda local, a un tipo de cambio controlado por Stripe. Es posible que su banco le cobre comisiones de conversi&oacute;n de divisas o de transferencia por recibir el dinero. Adem&aacute;s, su empresa de monedero electr&oacute;nico puede cobrarle una comisi&oacute;n por acceder al dinero. No tenemos control sobre los tipos de cambio de divisas o los cargos impuestos por su banco o su empresa de monedero electr&oacute;nico, y nosotros y nuestras empresas filiales no seremos responsables de pagar ning&uacute;n cargo impuesto por su banco o su empresa de monedero electr&oacute;nico.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Si un seguidor solicita con &eacute;xito un reembolso o una devoluci&oacute;n de cargo a su proveedor de tarjeta de cr&eacute;dito con respecto a un pago de seguidor realizado a usted, podemos investigar y decidir deducir de su cuenta una cantidad igual a las ganancias de creador obtenidas por usted en la cantidad devuelta o reembolsada.</span></p>
                                        </li>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>A excepci&oacute;n de las Opciones de Pago que implican el pago por transferencia bancaria directa, no almacenamos ning&uacute;n dato revelado por usted cuando registra sus Opciones de Pago con un proveedor de pagos externo.</span></p>
                                        </li>
                                    </ol>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Circunstancias en las que podemos retener los Ingresos del Creador:</span></p>
                                    </li>
                                    <ol>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>Podremos retener la totalidad o una parte de los Ingresos del Creador que se le adeuden pero que a&uacute;n no se le hayan abonado:</span></p>
                                        </li>
                                        <ol>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>si creemos que ha incumplido o puede haber incumplido de forma grave o reiterada alguna parte de las Condiciones del servicio;</span></p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>si intenta o amenaza con incumplir cualquier parte de las Condiciones del servicio de un modo que consideremos que tiene o podr&iacute;a tener consecuencias graves para nosotros o para otro Usuario (incluida la p&eacute;rdida real o posible causada a nosotros o a otro Usuario); o</span></p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>si sospechamos que todas o parte de las Ganancias del Creador son el resultado de una actividad ilegal o fraudulenta, ya sea por su parte o por parte del Seguidor que realiz&oacute; el Pago del Seguidor que dio lugar a las Ganancias del Creador,</span></p>
                                            </li>
                                        </ol></ol></ol>
                                <p dir="ltr"><span>durante el tiempo que sea necesario para investigar el incumplimiento real, la amenaza de incumplimiento o la sospecha de incumplimiento por su parte o la sospecha de actividad il&iacute;cita (seg&uacute;n proceda). Si tras nuestra investigaci&oacute;n llegamos a la conclusi&oacute;n de que (i) ha incumplido de forma grave o reiterada alguna parte de las Condiciones del servicio; (ii) ha intentado o amenazado con incumplir alguna parte de las Condiciones del servicio de forma que tenga o pueda tener consecuencias graves para nosotros o para otro Usuario (incluida la p&eacute;rdida real o posible causada a nosotros o a otro Usuario), y/o (iii) las Ganancias de creador son el resultado de una actividad ilegal o fraudulenta, podremos notificarle que ha perdido sus Ganancias de creador.</span></p>
                                <ol start={2}>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Tambi&eacute;n podremos retener la totalidad o parte de las Ganancias de los Creadores que se le adeuden pero que a&uacute;n no se hayan abonado si recibimos notificaci&oacute;n de que usted ha garantizado, gravado, pignorado, cedido o permitido de otro modo que se establezca un gravamen sobre las Ganancias de los Creadores. No asumimos ninguna obligaci&oacute;n de pagar las Ganancias de creador a terceros titulares de grav&aacute;menes y podemos retener el pago de las Ganancias de creador hasta que se haya eliminado el gravamen.</span></p>
                                    </li>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>No tendremos ninguna responsabilidad ante usted si retenemos o confiscamos cualquiera de sus Ganancias de creador cuando tengamos derecho a hacerlo en virtud de estas Condiciones de uso para creadores.</span></p>
                                    </li>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Si retenemos todas o parte de las Ganancias del Creador que se le adeudan y determinamos que parte de las Ganancias del Creador retenidas por nosotros no est&aacute;n relacionadas con incumplimientos por su parte de las Condiciones de Servicio o con sospechas de actividad il&iacute;cita o fraudulenta, podremos disponer que se le abone la parte de las Ganancias del Creador que determinemos que no est&aacute; relacionada con incumplimientos por su parte de las Condiciones de Servicio o con sospechas de actividad il&iacute;cita o fraudulenta. No obstante, usted acepta que, si consideramos que su(s) incumplimiento(s) de las Condiciones del servicio nos ha(n) causado o puede(n) causarnos p&eacute;rdidas, podremos retener todas las Ganancias de creador que se le adeuden pero que a&uacute;n no se le hayan abonado, y podremos compensar dichas cantidades con cualquier p&eacute;rdida sufrida por nosotros.</span></p>
                                    </li>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Si una vez que hayamos terminado nuestra investigaci&oacute;n determinamos que las Ganancias de Creador han sido confiscadas, haremos (a menos que lo proh&iacute;ba la ley) todo lo posible para garantizar que cualquier Pago de Seguidor que haya resultado en Ganancias de Creador confiscadas sea devuelto a los Seguidores pertinentes que pagaron dichos Pagos de Seguidor.</span></p>
                                    </li>
                                </ol>
                                <p dir="ltr">&nbsp;</p>
                                <ol start={15}>
                                    <li dir="ltr">
                                        <p dir="ltr"><span>Promover el cumplimiento de las obligaciones fiscales y el IVA:</span></p>
                                    </li>
                                    <ol>
                                        <li dir="ltr">
                                            <p dir="ltr"><span>General:</span></p>
                                        </li>
                                        <ol>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>Recomendamos a todos los creadores que busquen asesoramiento profesional para asegurarse de que cumplen las normas fiscales y de IVA locales, en funci&oacute;n de sus circunstancias individuales.</span></p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>Al utilizar Klouser como Creador, usted garantiza (lo que significa que hace una promesa jur&iacute;dicamente exigible) que ha declarado y declarar&aacute; en el futuro la recepci&oacute;n de todos los pagos que se le realicen en relaci&oacute;n con el uso de Klouser a la autoridad fiscal pertinente de su jurisdicci&oacute;n, tal como exige la ley.</span></p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>Al utilizar Klouser como Creador, usted garantiza (lo que significa que hace una promesa jur&iacute;dicamente exigible) que cumplir&aacute; en todo momento con todas las leyes y reglamentos relativos a Impuestos que le sean de aplicaci&oacute;n. Si, en cualquier momento en que disponga de una cuenta Klouser, se produce alg&uacute;n incumplimiento fiscal en relaci&oacute;n con usted (incluido el incumplimiento por su parte de la obligaci&oacute;n de declarar ingresos o la imposici&oacute;n de cualquier sanci&oacute;n o inter&eacute;s relacionado con los impuestos) o si se inicia alg&uacute;n litigio, consulta o investigaci&oacute;n contra usted en relaci&oacute;n con alg&uacute;n incumplimiento fiscal, o que pueda dar lugar a ello, usted se compromete a:</span></p>
                                            </li>
                                            <ol>
                                                <li dir="ltr">
                                                    <p dir="ltr"><span>nos lo notifique por escrito por correo electr&oacute;nico a </span><a href="mailto:billing@klouser.com"><span>billing@klouser.com</span></a><span> en un plazo de 7 d&iacute;as desde que se produzca el incumplimiento fiscal o se inicie el litigio, la indagaci&oacute;n o la investigaci&oacute;n (seg&uacute;n proceda); y</span></p>
                                                </li>
                                                <li dir="ltr">
                                                    <p dir="ltr"><span>facil&iacute;tenoslo sin demora por correo electr&oacute;nico a billing@klouser.com:</span></p>
                                                </li>
                                                <ol>
                                                    <li dir="ltr">
                                                        <p dir="ltr"><span>detalles de las medidas que est&aacute; adoptando para hacer frente al incumplimiento fiscal y evitar que vuelva a producirse, junto con cualquier factor atenuante que considere pertinente; y</span></p>
                                                    </li>
                                                    <li dir="ltr">
                                                        <p dir="ltr"><span>cualquier otra informaci&oacute;n relacionada con el incumplimiento fiscal que podamos solicitar razonablemente.</span></p>
                                                    </li>
                                                </ol></ol>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>Para evitar dudas, usted es responsable de sus propios asuntos fiscales y nosotros y nuestras empresas filiales (i) no somos responsables de asesorarle sobre sus asuntos fiscales y no seremos responsables con respecto a ninguna informaci&oacute;n general proporcionada en Klouser o por </span><a href="mailto:info@klouser.com"><span>info@klouser.com</span></a><span> con respecto a los impuestos, y (ii) no seremos responsables de ning&uacute;n impago de impuestos por parte de los Creadores.</span></p>
                                            </li>
                                            <li dir="ltr">
                                                <p dir="ltr"><span>Nos reservamos el derecho a cerrar su cuenta Klouser si se nos notifica o tenemos conocimiento de cualquier incumplimiento fiscal por su parte.</span></p>
                                            </li>
                                        </ol></ol></ol>
                            </TermsContent>
                        </Col>
                    </Row>
                </Grid.Container>

            </header>


        </div>
    );
}

export default CreatorTermsOfServices;
