import styled from "styled-components";
import { Link as LinkRrd } from "react-router-dom";

export const CardBackground = styled.div<{ primaryColor }>`
  border-radius: 12px;
  //background: linear-gradient(103deg, #08c 37%, #f2eded 100%);
  background: ${({ primaryColor }) => `linear-gradient(103deg, ${primaryColor} 37%, #f2eded 100%)`};
  display: flex;
  flex-direction: column;
  color: var(--fotitos, #fff);
  padding: 28px 22px;
  margin: 0px;
  width: 100%;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  padding: 0 1px;
`;

export const HeaderText = styled.span`
  font-size: 14px;
  font-weight: 500;
  flex-grow: 1;
  flex-basis: auto;
  display: flex;
  justify-content: flex-start;
  gap: 5px;
`;

export const Description = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font: 600 28px/32px Inter, sans-serif;
`;

export const Button = styled('a')`
  background-color: #2D3436;
  border: 1px solid #2D3436;
  color: white;
  padding: 4px 17px;
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 8px;
  text-decoration: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  font-size: 14px;
  font-weight: 500;

  span {
    display: -webkit-box;
    -webkit-line-clamp: 2; // Limit to 2 lines
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; // Allow wrapping to multiple lines
  }

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`

export const Close = styled("button")`
  align-self: flex-start;
  background: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 1;

  &:focus {
    outline: transparent;
  }
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`;
