import styled from "styled-components";

export const CardsGrid = styled("div")`
  display: grid;
  gap: 16px;
  grid-auto-rows: 203px;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
`;

export const Beginning = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0px 0px 24px 0px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    margin: 0px 0px 56px;
  }
`;

export const TitleCard = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0px 0px 20px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: 0px 0px 36px;
    text-align: left;
  }
`;

export const NewGroupButton = styled("button")`
  margin: 0px;
  padding: 0px;
  border: 0px;
  background-color: ${(props) => props.theme.colors.Gray1};
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding: 16px;
  border-radius: 16px;
  cursor: pointer;
  width: 100%;

  img {
    margin-right: 16px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    max-width: 300px;
  }
`;

export const ActionsBar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 20px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    flex-direction: row;
  }
`;

export const InLine = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
