import { getThemeVar } from "remirror";
import { AllStyledComponent } from "@remirror/styles/emotion";
import {
  BoldExtension,
  ItalicExtension,
  NodeFormattingExtension,
  MarkdownExtension,
  HeadingExtension,
  StrikeExtension,
  DocExtension,
  CodeBlockExtension,
  LinkExtension,
  PlaceholderExtension,
} from "remirror/extensions";
import {
  Remirror,
  useRemirror,
  useCommands,
  ThemeProvider,
  ComponentItem,
  Toolbar,
  ToolbarItemUnion,
  ReactExtensions,
  UseRemirrorReturn,
  useRemirrorContext,
  ReactFrameworkOutput,
} from "@remirror/react";
import { CountExtension, CountStrategy } from "@remirror/extension-count";
import "remirror/styles/all.css";
import { css } from "@emotion/css";
import md from "refractor/lang/markdown";
import typescript from "refractor/lang/typescript";
import { createContextState } from "create-context-state";
import { useState, ReactElement, forwardRef, useImperativeHandle, useRef, useEffect } from "react";
import { Controller } from "react-hook-form";
import {
  FieldContainer,
  Field,
  Label,
  HelperLine,
  HelperText,
  CharacterCounter,
  ErrorMessage,
} from "../Field";

import { RichTextEditorStyles } from "./styles";
import { useMarkdown } from "../../../hooks/useMarkdown";
import { useField } from "formik";
import { useFormikFieldValidation } from "../../../hooks/useFormikFieldValidation";

const initialValue = {
  options: {
    skin: "base",
    size: "md",
    marginBottom: 24,
  },
};

const toolbarItems: ToolbarItemUnion[] = [
  {
    type: ComponentItem.ToolbarGroup,
    label: "Simple Formatting",
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "toggleBold",
        display: "icon",
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "toggleItalic",
        display: "icon",
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "toggleUnderline",
        display: "icon",
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "toggleStrike",
        display: "icon",
      },
    ],
  },
  {
    type: ComponentItem.ToolbarGroup,
    label: "Link",
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "insertLink",
        display: "icon",
      },
    ],
  },
  {
    type: ComponentItem.ToolbarGroup,
    label: "Heading Formatting",
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "toggleHeading",
        display: "icon",
        attrs: { level: 3 },
      },
    ],
  },
  {
    type: ComponentItem.ToolbarGroup,
    label: "Clipboard",
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "copy",
        display: "icon",
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "cut",
        display: "icon",
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "paste",
        display: "icon",
      },
    ],
  },
  {
    type: ComponentItem.ToolbarGroup,
    label: "History",
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "undo",
        display: "icon",
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "redo",
        display: "icon",
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "toggleColumns",
        display: "icon",
        attrs: { count: 2 },
      },
    ],
  },
];

const extensions = () => [
  new MarkdownExtension(),
  new BoldExtension(),
  new ItalicExtension(),
  new StrikeExtension(),
  new NodeFormattingExtension(),
  new LinkExtension({autoLink: true, selectTextOnClick: true}),
  new PlaceholderExtension(),
  new CountExtension({
    maximum: 5000,
    maximumStrategy: CountStrategy.CHARACTERS,
  }),
  new HeadingExtension({ levels: [1, 2, 3, 4, 5, 6] }),
];

declare global {
  interface Window {
    rawContentState: any;
    contentState: any;
    oldcontentState: any;
  }
}

interface Context extends Props {
  setMarkdown: (markdown: string) => void;
  setVisual: (markdown: string) => void;
}

interface Props {
  visual: UseRemirrorReturn<
    ReactExtensions<ReturnType<typeof extensions>[number]>
  >;
  markdown: UseRemirrorReturn<
    ReactExtensions<DocExtension | CodeBlockExtension>
  >;
}

const [DualEditorProvider, useDualEditor] = createContextState<Context, Props>(
  ({ props }) => {
    return {
      ...props,

      setMarkdown: (text: string) => {
        return props.markdown.getContext()?.setContent({
          type: "doc",
          content: [
            {
              type: "codeBlock",
              attrs: { language: "markdown" },
              content: text ? [{ type: "text", text }] : undefined,
            },
          ],
        });
      },
      setVisual: (markdown: string) => {
        return props.visual.getContext()?.setContent(markdown);
      },
    };
  }
);



const VisualEditor = ({
  onChange,
  showToolbar = true,
  onBlur,
  onFocus,
  disabled,
}: {
  onChange?;
  onBlur?;
  showToolbar? : boolean;
  onFocus?;
  disabled;
}) => {
  const [isEditable, setIsEditable] = useState(true);
  const { visual, setMarkdown } = useDualEditor();
  return (
    <Remirror
      manager={visual.manager}
      autoRender="end"
      onChange={(data) => {
        const { helpers, state } = data;
        if (!!onChange) {
          onChange({
            markdownStr: helpers.getMarkdown(state),
            json: helpers.getJSON(),
            textStr: helpers.getText(),
            htmlStr: helpers.getHTML(state),
            characterCount: helpers.getCharacterCount(),
            wordsCount: helpers.getWordCount(),
            isCountValid: helpers.isCountValid(),
          });
        }
        setIsEditable(helpers.isCountValid());
        return setMarkdown(helpers.getMarkdown(state));
      }}
      onBlur={(params, event) => {
        if (!!onBlur) {
          onBlur(event);
        }
      }}
      onFocus={(params, event) => {
        if (!!onFocus) {
          onFocus(event);
        }
      }}
      editable={!disabled}
      initialContent={visual.state}
      classNames={[
        css`
          &.ProseMirror {
            p,
            h3,
            h4 {
              margin-top: ${getThemeVar("space", 2)};
              margin-bottom: ${getThemeVar("space", 2)};
            }
            h1,
            h2 {
              margin-bottom: ${getThemeVar("space", 3)};
              margin-top: ${getThemeVar("space", 3)};
            }
          }
        `,
      ]}

    >
      {showToolbar && (<Toolbar items={toolbarItems} refocusEditor label="Top Toolbar" />)}
    </Remirror>
  );
};

interface TextareaProps {
  name: string;
  value?: string | number;
  disabled?: boolean;
  showToolbar?: boolean;
  error?: {};
  touched?: {};
  step?: string;
  placeholder?: string;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  min?: string | number;
  max?: string | number;
  forwardRef?;
  options: {
    label?: string;
    skin?: "base";
    size?: "md";
    helperText?: string;
    explainText?: string;
    before?: any;
    after?: any;
    marginBottom?: number;
    hidden?: boolean;
  };
}

const Component = ({
  forwardRef,
  options = {},
  disabled,
  name,
  value,
  error,
  touched,
  showToolbar = true,
  max,
  onFocus,
  onBlur,
  onChange,
  ...rest
}: TextareaProps) => {
  const [field, meta, helpers] = useField({ name, value });
  

  const [numCharacters, setNumCharacters] = useState(0);
  const { classes, setActive } = useFormikFieldValidation(
    error,
    name,
    disabled
  );

  const targetDivRef = useRef<HTMLDivElement>(null);

  const { skin, size, marginBottom, hidden } = {
    ...initialValue.options,
    ...options,
  };

  const handleOnChange = (event, callback) => {
    callback && callback(event);
  };

  const handleOnBlur = (event, callback) => {
    setActive(false);
    callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    setActive(true);
    callback && callback(event);
  };

  let currentValue = field.value;

  try {
    const values = JSON.parse(field.value || "");
    currentValue = JSON.parse(values.jsonStr || "");
  } catch {
    currentValue = value;
  }

  const visual = useRemirror({
    extensions,
    stringHandler: "markdown",
    content: currentValue,
  });

  const markdown = useRemirror({
    extensions: () => [
      new DocExtension({ content: "codeBlock" }),
      new CodeBlockExtension({
        supportedLanguages: [md, typescript],
        defaultLanguage: "markdown",
        syntaxTheme: "base16_ateliersulphurpool_light",
        defaultWrap: true,
      }),
    ],
    builtin: {
      exitMarksOnArrowPress: false,
    },
    stringHandler: "html",
  });

  // Function to handle the scroll
  const scrollToDiv = () => {
    // Scroll to the target div
    targetDivRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (error) {
      scrollToDiv()
    }
  }, [error, onChange, onFocus, onBlur])

  return (
    <AllStyledComponent>
      <ThemeProvider>
        <DualEditorProvider visual={visual} markdown={markdown}>
          <FieldContainer marginBottom={marginBottom}>
            <div ref={targetDivRef} />

            {options && options.label && (
              <Label size={size} htmlFor={`textarea-${name}`}>
                {options.label}
              </Label>
            )}

            <Field
              size={size}
              skin={skin}
              className={classes}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: showToolbar ? "15px" : "0",
                height: "50%"
              }}
            >
              <RichTextEditorStyles />
              <VisualEditor
                disabled={disabled}
                showToolbar={showToolbar}
                onBlur={(event) => {
                  handleOnBlur(event, onBlur);
                }}
                onFocus={(event) => {
                  handleOnFocus(event, onFocus);
                }}
                onChange={({
                  markdownStr,
                  json,
                  textStr,
                  htmlStr,
                  characterCount,
                  wordsCount,
                  isCountValid,
                }) => {
                  setNumCharacters(characterCount);
                  handleOnChange(
                    {
                      markdownStr,
                      json,
                      textStr,
                      htmlStr,
                      characterCount,
                      wordsCount,
                      isCountValid,
                    },
                    onChange
                  );

                  field.onChange(
                    JSON.stringify({
                      jsonStr: JSON.stringify(json),
                      htmlStr,
                      textStr,
                    })
                  );

                  if (characterCount > 0) {
                    helpers.setValue(
                      JSON.stringify({
                        jsonStr: JSON.stringify(json),
                        htmlStr,
                        textStr,
                      })
                    );
                  } else {
                    helpers.setValue('')
                  }

                }}
                {...rest}
              />
            </Field>

            {((options && options.helperText) || max || error) && (
              <HelperLine marginBottom={marginBottom}>
                {!!error && !!touched && <ErrorMessage>{error}</ErrorMessage>}
                {options && options.helperText && !error && (
                  <HelperText>{options.helperText}</HelperText>
                )}
                {max && (
                  <CharacterCounter errors={!!error && !!touched}>
                    {numCharacters} / {max}
                  </CharacterCounter>
                )}
              </HelperLine>
            )}
          </FieldContainer>
        </DualEditorProvider>
      </ThemeProvider>
    </AllStyledComponent>
  );
};

export default Component;
