import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import constants from "../../../../constants";
import { getFreeSubscriptions } from "../../../../helpers/getFreeSubscriptions";
import { isSubscribed } from "../../../../helpers/isSubscribed";
import { Group, User } from "../../../../types";
import Icon from "../../../../components/Icon";
import dateHandlder from "../../../../helpers/dates-hanlder";
import { isOwnGroup } from "../../../../helpers/isOwnGroup";
import {
  Backdrop, Content, Avatar, H3, H2, Link, Hello, ChangeAccount, SignInLink, ChangeAccountLink, AccountSignIn, Back
} from './styles'
import warningIcon from "../../../../assets/icons/warning.svg";
import { Center } from "../../../../components/StyledComponents";
import { signOut } from "../../../../store/actions/auth";
import backArrow from "../../../../assets/icons/arrow-backward.svg";
import { useWindowSize } from "../../../../hooks/useWindowSize";

interface ComponentProps {
  userInformation: User
  userSubscriptions
  isLoggedIn: Boolean;
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  currentPage: string
}

const Component = ({
  group,
  groupStates,
  userInformation,
  userSubscriptions,
  isLoggedIn,
  currentPage
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [suscriptionEnded, setSuscriptionEnded] = useState<boolean>();
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    const updateUrl = () => {
      setCurrentUrl(window.location.href);
    };

    // Add event listener to listen for URL changes
    window.addEventListener('popstate', updateUrl);

    return () => {
      // Cleanup the event listener
      window.removeEventListener('popstate', updateUrl);
    };
  }, []);



  const pageText = {
    "GROUP_DETAILS_ACTIVITY_SUMMARY": "Contenido exclusivo",
    "GROUP_DETAILS_LIBRARY": "Biblioteca exclusiva",
    "GROUP_DETAILS_SESSIONS": "Encuentros exclusivos"
  }

  const isUserAuthenticated = useMemo(() => {
    return isLoggedIn || group?.group_type === 'free_experience'
  }, [isLoggedIn, group])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(userSubscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [userSubscriptions, group, isLoggedIn])

  useEffect(() => {
    if (!!group?.registration_closing_date) {
      setSuscriptionEnded(
        dateHandlder.isExpired(group?.registration_closing_date)
      );
    }
  }, [group]);


  if (isUserSubscribed || isOwnGroup({ group, expertId: userInformation?.expert?.id })) {
    return <></>
  }

  return (
    <Backdrop>
      <Content>


        <Back href={`/group/${group?.slug}`}>
          <Icon icon={backArrow} color={"black"} size="24px" />
        </Back>

        {/* <ReturnToBack>Volver</ReturnToBack> */}
        {isUserAuthenticated ? (<div style={{ height: isMobile ? 20 : 40 }} />) : (<div style={{ height: isMobile ? 35 : 70 }} />)}

        {isUserAuthenticated && (
          <>
            <Hello>
              Hola! 👋​ {userInformation?.email}
            </Hello>

            <div style={{ height: isMobile ? 20 : 35 }} />
          </>
        )}

        <Avatar>
          <img src={group?.expert?.profile_pic?.url} alt="profile pic" />
        </Avatar>

        <div style={{ height: 15 }} />

        <H3>
          {group?.group_name}
        </H3>

        <div style={{ height: isMobile ? 20 : 35 }} />

        <H2>
          {pageText[currentPage]} para suscriptores pagos
        </H2>

        <div style={{ height: isMobile ? 20 : 35 }} />

        <Link
          href={`/group/${group?.slug}?scrollTo=price`}
          options={{
            type: "filled",
            size: "lg",
            skin: "purple",
            block: true,
          }}
          target="_self"
        >
          {group?.group_type === "membership" && (
            <>{suscriptionEnded ? "Suscripción finalizada" : `Suscribirme`}</>
          )}

          {group?.group_type !== "membership" && (
            <>{suscriptionEnded ? "Inscripción finalizada" : `Inscribirme`}</>
          )}
        </Link>

        <div style={{ height: isMobile ? 20 : 35 }} />

        {isUserAuthenticated ? (
          <>
            <ChangeAccount>
              <Center horizontal="left" vertical="center" style={{ maxWidth: 270 }} gap={8}>
                <Icon icon={warningIcon} color="rgba(45,52,54,.75)" size="24px" />
                <div style={{ textAlign: 'left', fontSize: 12 }}>
                  Si ya estas suscripto, es posible que
                  hayas efectuado la compra con otro mail
                  o que tu suscripción se haya cancelado
                </div>
              </Center>

              <div style={{ height: 10 }} />

              <ChangeAccountLink
                onClick={() => {
                  dispatch(signOut())
                }}
                type="button"
                options={{
                  type: "outline",
                  size: "lg",
                  skin: "purple",
                }}
              >
                Cambiar de cuenta
              </ChangeAccountLink>
            </ChangeAccount>
          </>
        ) : (
          <AccountSignIn>
            ¿Ya te suscribiste?

            <SignInLink
              href={constants.urls.subscriber.signin.url}
              options={{
                type: "outline",
                size: "lg",
                skin: "purple",
              }}
              target="_self"
            >
              Iniciar Sesión
            </SignInLink>
          </AccountSignIn>
        )}

        {isUserAuthenticated ? (<div style={{ height: isMobile ? 20 : 35 }} />) : (<div style={{ height: isMobile ? 35 : 70 }} />)}
      </Content>
    </Backdrop>
  );
};

const state = ({ userStore, groupStore, pageStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: userInformation } = userStore.information;
  const { data: userSubscriptions } = userStore.subscriptions;
  const { isLoggedIn } = userStore.isLoggedIn;

  return {
    group,
    groupStates,
    userInformation,
    userSubscriptions,
    isLoggedIn,
    currentPage: pageStore?.name,
  };
};

export default connect(state)(Component);