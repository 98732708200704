const resetSelect = `
  margin: 0px;
  padding: 0px;
  border: 0px;
  border-radius: 0px;
  background: none;
  box-shadow: none;`;

export const makeSelect = ({
  theme,
  before,
  variant = "base",
  size = "md",
}) => {
  return `${resetSelect}
          font-size: ${theme.form.size[size].fontSize};
          font-weight: 400;
          letter-spacing: 0.009375em;
          box-sizing: border-box;
          width: 100%;
          min-height: ${theme.form.size[size].height};
          transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
          outline: none;
          position: static!important;

          .select__indicators {
            cursor: pointer;
            
            .select__dropdown-indicator{
              transition: all .2s ease-in-out;
              transform: rotate(0deg);
            }
            
            span {
              display: none;
            }

            .select__indicator {
              padding: 0;
            }
          }

          & > .select__control, 
          & > .select__control:hover {
            ${resetSelect}

            width: 100%;
            color: rgba(0, 0, 24, 0.6);
            padding: ${before ? "0 16px 0 43px" : "0px"};
            min-height: ${theme.form.size[size].height};
            
            
            .select__value-container {
              ${resetSelect}
              
              &.select__value-container--is-multi {
                gap: 3px;
                margin: 4px 0;
              }
              
              .select__placeholder {
                ${resetSelect}
                text-overflow: ellipsis;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
              }
              
              [class^="css-"]{
                ${resetSelect}
              }

              .select__single-value{
                margin: 0px;
              }

              .select__multi-value {
                background: #e7e5fc;
                border-radius: 10px;
                padding: 2px 10px;
                margin-right: 5px;

                .select__multi-value__label {
                  margin-right: 10px;
                  background: transparent;
                }

                .select__multi-value__remove{
                  cursor: pointer;
                }
              }

              .select__input {
                input {
                  font-family: ${theme.fonts.family};
                }
              }
            }

            .select__indicators {
              .select__indicator {
                margin: 0 10px;

                img {
                  width: 10px;
                  height: auto;  
                }
              }
            }

            &.select__control--menu-is-open {
              ${resetSelect}

              .select__indicators {
                .select__dropdown-indicator{
                  transform: rotate(180deg);
                }
              }
            }

            &.select__control--menu-is-focused {
              ${resetSelect}
            }
          }

          & > .select__menu {
            min-width: 100%;
            margin-top: 4px;
            border-radius: 8px;
            background:  ${
              variant === "lighten" ? "#ffffff" : `${theme.colors.Gray}`
            }
            position: relative;
            z-index: 20;
            padding: 8px;
            left: 0;
            right: 0;

            .select__placeholder, 
            .select__value-container .select__single-value {
            }

            .select__menu-list {
              padding: 0px;
              max-height: 120px;
              
              .select__option {
                border-radius: 4px;
                font-weight: 400;
                padding: 8px 12px;
                line-height: 20px;
                font-size: ${theme.form.size[size].fontSize};
                cursor: pointer;
  
                &.select__option--is-selected, 
                &.select__option--is-focused {
                  color: ${theme.form.skin[variant].idle.color};
                  background: ${
                    variant === "lighten"
                      ? "rgba(0, 0, 24, 0.04)"
                      : "rgba(0, 0, 24, 0.08)"
                  };
                }
              }
            }
          }`;
};

export const makeCustomSup = () => {
  return `color: red;`;
};

export const makeAutocomplete = (props) => {
  return `
    ${makeSelect(props)}
    &  .select__dropdown-indicator {
      display: none;
    }
  `;
};
