export const DELETE_REVIEW = "DELETE_REVIEW";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_ERROR = "DELETE_REVIEW_ERROR";
export const RESET_DELETE_REVIEW = "RESET_DELETE_REVIEW";

export interface DeleteReview {
  type: typeof DELETE_REVIEW;
  payload: string;
}

export interface DeleteReviewSuccess {
  type: typeof DELETE_REVIEW_SUCCESS;
  payload: any;
}

export interface DeleteReviewError {
  type: typeof DELETE_REVIEW_ERROR;
  payload: boolean | string;
}

export interface ResetDeleteReview {
  type: typeof RESET_DELETE_REVIEW;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | DeleteReview
  | DeleteReviewSuccess
  | DeleteReviewError
  | ResetDeleteReview;
