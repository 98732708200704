import action from "./../helpers/rest-client";
import firebase from "./../firebase";
import { Group as GroupInterface, User as UserInterface } from "./../types";

export class ErrorUser extends Error { }
class User {
  public async changeFirebasePassword(email: string) {
    const response = await firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((response) => {
        return response;
      })
      .catch(function (error) {
        throw new ErrorUser(error.code);
      });

    return response;
  }

  public async changeFirebaseEmail({
    email,
    userId,
  }: {
    email: string;
    userId: number;
  }) {
    const user = await firebase.auth().currentUser;

    if (user) {
      return user
        .updateEmail(email)
        .then(async (fbReponse) => {
          sessionStorage.setItem("FbRefreshToken", "true");

          return action
            .Put({
              url: `/api/user/change`,
              body: { userId },
            })
            .then((response) => {
              return { response };
            })
            .catch((error) => {
              return { error };
            });
        })
        .catch((error) => {
          return { error };
        });
    }

    throw new ErrorUser("Error al actualizar");
  }

  public async editUserInformation(payload) {
    return action
      .Put({
        url: `/api/user/change/`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async subscriptionsManagerUrl() {
    const result = await action
      .Get({
        url: `/api/user/subscriptions-manager-url`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorUser("chargebee / subscription-url-error");
      });

    return result;
  }

  public async getUserInformation() {
    return action
      .Get({
        url: `/api/user/information`,
      })
      .then((response) => {
        sessionStorage.removeItem("hasExpertProfile");

        if (!!response.expert) {
          sessionStorage.setItem("hasExpertProfile", "true");
        }

        return { response };
      })
      .catch((error) => {
        return { error }
      });
  }

  public async getUserSubscriptions(payload) {
    const queryString = new URLSearchParams(payload?.filters).toString();

    return action
      .Get({
        url: `/api/user/subscriptions?${queryString ? queryString : ''}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error }
      });
  }

  public async getUserGroups() {
    const response: GroupInterface[] = await action
      .Get({
        url: `/api/user/groups`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorUser(error.response.statusText);
      });

    return response;
  }
}

const user = new User();
export default user;
