import styled, { css } from "styled-components";

const p = css`
  margin: 0px;
  font-size: 14px;
  line-height: 17px;
  color: #2d3436;
`;

export const Title = styled("h4")`
  margin: 0px 0px 17px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 24px;
    line-height: 29px;
  }
`;

export const SubTitle = styled("p")`
  ${p}
  font-size: 16px;
  margin: 0px;
  line-height: 20px;
  margin-bottom: 24px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin-bottom: 22px;
  }
`;

export const ImageWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;

  @media (max-width: 767px) {
    width: 100%;
    height: 180px;
    overflow: hidden;
    display: none;
  }

  @media (min-width: 768px) {
    width: 450px;
    height: auto;
    overflow: hidden;
  }

  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 13.31%,
      #ffffff 95.2%
    );

    @media (min-width: 768px) {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
`;

export const Image = styled("img")`
  display: block;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const PayWall = styled("div")`
  position: fixed;
  z-index: 1;
  bottom: 76px;
  width: 100%;
  left: 50%;
  bottom: 90px;
  transform: translate(-50%, 0px);
  padding: 0px 10px;

  @media(min-width:768px){
    bottom: 15px;
  }
`;

export const PayWallContent = styled("div")`
  max-width: 890px;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-align: center;
  justify-content: flex-start;
  box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  overflow: hidden;
  margin: 0 auto;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    flex-direction: row;
    flex: 1 1 auto;
  }
`;

export const GroupInformation = styled("div")`
  width: 100%;
  text-align: center;
  padding: 16px 20px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 28px;
  }
`;

export const Promo = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.Primary};
  color: ${(props) => props.theme.colors.Primary};
  box-sizing: border-box;
  border-radius: 99px;
  font-weight: 800;
  font-size: 12px;
  width: 75px;
  height: 26px;
  letter-spacing: 1px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    width: 90px;
    height: 32px;
    letter-spacing: 2.5px;
  }
`;

export const OldPrice = styled("div")`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  line-height: 32px;
  text-decoration: line-through;
  //margin-top: -11px;
  color: ${(props) => props.theme.colors.Black40};
  // position: absolute;
  // bottom: 100%;

  & > * {
    margin: 0px !important;
    text-transform: none !important;
    font-weight: 400 !important;
  }
`;

export const DesktopOwner = styled("p")`
  ${p}
  display: none;
  margin: 0px 0px 8px;

  @media (min-width: 768px) {
    display: block;
  }
`;

export const MobileOwner = styled("p")`
  ${p}
  display: block;
  margin: 0px 0px 8px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Price = styled("div") <{ withDiscount }>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 19px 0px 7px;
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const Frecuency = styled("p")`
  ${p}
  margin: 0px;
  display: none;

  @media (min-width: 768px) {
    display: block;
    margin-bottom: 8px;
  }
`;

export const Month = styled("div")`
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 2.5px;
  font-weight: 800;
  text-transform: uppercase;
  margin-left: 5px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    line-height: 32px;
  }
`;

export const LocalPrice = styled("div") <{ withDiscount }>`
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
`;

export const SuscriptionEnded = styled("div")`
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
`;
