import { useMemo } from 'react';
import { connect } from 'react-redux';
import { getFreeSubscriptions } from '../../../../helpers/getFreeSubscriptions';
import { isSubscribed } from '../../../../helpers/isSubscribed';
import { getPriorityGroupSubscription } from '../../../../helpers/mpStatusPriority';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { Group, User } from '../../../../types';
import {
  Card,
  Title,
  Text
} from './styles'

interface ComponentProps {
  group: Group;
  userSubscriptions
  isLoggedIn: Boolean;
}

const Component = ({
  group,
  userSubscriptions,
  isLoggedIn,
}: ComponentProps) => {
  const { isMobile } = useWindowSize()
  const isUserAuthenticated = useMemo(() => {
    return isLoggedIn || group?.group_type === 'free_experience'
  }, [isLoggedIn, group])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(userSubscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [userSubscriptions, group, isLoggedIn])

  const subscription = useMemo(() => {
    return getPriorityGroupSubscription(userSubscriptions, group?.id)
  }, [group, userSubscriptions])

  const isSubscriptionWaitingForGateway = useMemo(() => {
    return subscription?.status === 'waiting_for_gateway'
  }, [subscription])

  const isPaidSubscription = useMemo(() => {
    const freeSubscriptionTypes = ["guest", "free"]
    return !freeSubscriptionTypes?.includes(subscription?.type)
  }, [subscription])

  if (!isUserAuthenticated || !isUserSubscribed || !isSubscriptionWaitingForGateway || !isPaidSubscription) {
    return <></>
  }

  return (
    <>
      {isMobile && <div style={{ height: 26 }} />}

      <Card>
        <Title>Ya puedes comenzar a disfurtar! 🎉</Title>
        <div style={{ height: 8 }} />
        <Text>Estamos verificando tu pago. En caso de que falle el cobro, te avisaremos para resolverlo</Text>
      </Card>

      {!isMobile && <div style={{ height: 26 }} />}
    </>
  )
}


const state = ({ userStore, groupStore }) => {
  const { data: group } = groupStore.group;
  const { data: userSubscriptions } = userStore.subscriptions;
  const { isLoggedIn } = userStore;


  return {
    group,
    userSubscriptions,
    isLoggedIn,
  };
};

export default connect(state)(Component);