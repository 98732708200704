import {
  initialState,
  Actions,
  State,
  REORDER_MODULES,
  REORDER_MODULES_SUCCESS,
  REORDER_MODULES_ERROR,
  RESET_REORDER_MODULES,
} from "../../types/module/reorder-modules";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case REORDER_MODULES:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case REORDER_MODULES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case REORDER_MODULES_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_REORDER_MODULES:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducers;
