import projectedIncomes from "../assets/statistics/projected-incomes.svg";
import totalMembers from "../assets/statistics/total-members.svg";
import lostSubscribers from "../assets/statistics/lost-subscribers.svg";

const page = {
  statistics: [
    {
      type: "membership_first_kpi",
      image: projectedIncomes,
      title: "Ingresos generados",
      emptyState: {
        text: "Revisa en tu página de venta si hay algo que podrías mejorar",
        cta: {
          label: "Ir a página de venta",
        },
      },
    },
    {
      type: "membership_second_kpi",
      image: totalMembers,
      title: "Total de Suscriptores activos",
      emptyState: {
        text: "Lanza un descuento promocional y suma más suscriptores",
        cta: {
          label: "Lanzar descuento",
        },
      },
    },
    {
      type: "membership_third_kpi",
      image: lostSubscribers,
      title: "Suscriptores cancelados",
      emptyState: {
        text: "Descubre más información de los miembros de tu comunidad",
        cta: {
          label: "Ir a miembros",
        },
      },
    },
  ],
};

export default page;
