import styled from "styled-components";
import CopyToClipboadUi from "../../../../../components/CopyToClipboad";

export const Preview = styled("div")`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled("h3")`
  margin: 0px 0px 22px;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #2d3436;
`;

export const Text = styled("p")`
  font-size: 16px;
  line-height: 19px;
  color: #2d3436;
  margin: 0px 0px 16px;
`;

export const Option = styled("div")`
  margin: 0px 0px 14px;
`;

export const Label = styled("label")`
  font-size: 14px;
  line-height: 17px;
  color: rgba(45, 52, 54, 0.75);
  margin: 0px 0px 4px;
`;

export const StrongLabel = styled("label")`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #2d3436;
  margin: 0px;
`;

export const Value = styled("p")`
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: rgba(45, 52, 54, 1);
  margin: 0px;
`;

export const StrongValue = styled("p")`
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: rgba(45, 52, 54, 1);
  margin: 0px;
`;

export const GrayBox = styled("div")`
  margin-bottom: 14px;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 6px;
  padding: 19px 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.5);

  p {
    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const CopyToClipboad = styled(CopyToClipboadUi)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border: 2px solid ${(props) => props.theme.colors.Primary};
  background-color: ${(props) => props.theme.colors.Primary};
  font-size: 14px;
  color: white;
  border-radius: 6px;
  padding: 0px 12px;
  min-width: 120px;
  line-height: calc(40px - 4px);
  text-decoration: none;
  letter-spacing: 0.25px;
  font-weight: 700;
  text-align: center;
  transition: all 0.2s ease-in-out;
  margin-bottom: 8px;
  cursor: pointer;
`;
