import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Grid from "../../../../components/Grid";
import CallToAction from "../../components/CallToAction";
import { Group } from "../../../../types";
import LinkUi from "../../../../components/Link";
import { Link as RrdLink } from "react-router-dom";
import Dropdown from "../../../../components/DropdownBis";
import videoRecorder from "../../../../assets/icons/video-recorder.svg";
import sessionImage from "../../../../assets/icons/video-camera.svg";
import SessionCard from "../components/SessionCard";
import ReducedSessionCard from "../components/ReducedSessionCard";
import { getGroupById } from "../../../../store/actions/group";
import ModalShareLink from "../../components/ModalShareLink";
import { SectionTitle } from "../../components/Statistics/styles";
import { motion } from "framer-motion";
import { isBeforeXDays, sessionExpired, sessionExpiredWithMargin, convertFloatHourToMinutes } from "../../../../helpers/dates-hanlder";

import {
  CardsGrid,
  CardsGridLarge,
  SessionCardSkeleton,
} from "./styles";
import {
  getAllSessions,
  resetGetOneSession,
} from "../../../../store/actions/session";
import { SessionInDto } from "../../../../types/session.dto";
import EmptyCard from "../../components/EmptyCard";
import { useLayout } from "../../../../providers/LayoutProvider";
import { showModal } from "../../../../store/actions/modal";

interface ComponentProps {
  group: Group;
  groupStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  sessions: SessionInDto[];
  sessionsStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  match;
  children?;
}

const Component = ({
  group,
  groupStates,
  sessions,
  sessionsStates,
  match,
  children,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const [futureSessionsCardSize, setFutureSessionsCardSize] = useState<string | null>("large")

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!match.params) {
      dispatch(resetGetOneSession());
      dispatch(getAllSessions({ idGroup: match.params.idGroup }));

      if (group?.id !== Number(match?.params?.idGroup)) {
        dispatch(getGroupById({ group_id: match.params.idGroup }));
      }
    }
  }, [match]);

  useEffect(() => {
    if (sessionsStates.success && sessions?.filter((session) => !sessionExpired(session.session_datetime))?.length > 0) {
      setFutureSessionsCardSize("small")
    }

  }, [sessionsStates]);


  const nextSessions = useMemo(() => {
    if (!sessions)
      return []

    return sessions?.filter((session) => {
      let durationInMinutes = session?.duration_type === "hour" ? convertFloatHourToMinutes(session?.duration) : session?.duration
      return !sessionExpiredWithMargin(session?.session_datetime, durationInMinutes)
    })
  }, [sessions])

  const oldSessions = useMemo(() => {
    if (!sessions)
      return []

    const expiredSessions = sessions?.filter((session) => {
      let durationInMinutes = session?.duration_type === "hour" ? convertFloatHourToMinutes(session?.duration) : session?.duration
      return sessionExpiredWithMargin(session?.session_datetime, durationInMinutes)
    })

    expiredSessions.sort((a, b) => new Date(a?.session_datetime).getTime() - new Date(b?.session_datetime).getTime())
    expiredSessions.reverse()

    return expiredSessions;
  }, [sessions])



  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: group?.group_name,
        description: group?.group_name,
        back: `/dashboard/${group?.group_type === 'free_experience' ? 'community/events' : group?.group_type}/${group?.id}`,
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: true,
        actions: [<Dropdown key='actions-dropdown'>
          <RrdLink
            to={`/group/${group?.slug}/sessions`}
            key='go-to-preview-btn'
            target="_blank"
          >
            Vista previa
          </RrdLink>
          <button
            disabled={group?.status === 'draft'}
            onClick={() => {
              const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.slug}`;
              navigator.clipboard.writeText(link);
              dispatch(showModal("modal-share-group-link"));
            }}
            key={'share-experience-btn'}
          >
            Compartir {group?.group_type === "experience" ? "taller" : "membresía"}
          </button>
        </Dropdown>,
        <LinkUi
          disabled={groupStates.loading}
          loading={groupStates.loading}
          key='create-session-btn'
          options={{
            type: "filled",
            size: "lg",
            skin: "purple",
            native: true,
          }}
          href={`/dashboard/${group?.group_type}/${group?.id}/sessions/new`}
        >
          Crear encuentro
        </LinkUi>,
        ],
      },
    });
  }, [group, groupStates]);

  return (
    <>
      {children}

      <div style={{ height: 36 }} />

      <Grid.Container>
        <Grid.Row>
          <Grid.Col md={6}>
            <CallToAction
              title="Crear nuevo encuentro"
              description={`Solo podrán acceder quienes compren tu ${group?.group_type === "experience" ? "taller" : "membresía"}.`}
              link={`/dashboard/${group?.group_type}/${group?.id}/sessions/new`}
              options={{
                preffix: sessionImage,
                color: "rgba(219, 243, 244, 1)",
              }}
            />
          </Grid.Col>
        </Grid.Row>

        <div style={{ height: 36 }} />

        <Grid.Row>
          <Grid.Col>
            <SectionTitle>Próximos encuentros</SectionTitle>
          </Grid.Col>
        </Grid.Row>

        <div style={{ height: 16 }} />

        <Grid.Row>
          <Grid.Col>
            <CardsGrid size="small">
              {sessionsStates.loading && (
                <>
                  <SessionCardSkeleton />
                  <SessionCardSkeleton />
                  <SessionCardSkeleton />
                </>
              )}

              {(!sessions && !sessionsStates.loading) ||
                ((nextSessions?.length <= 0 ||
                  sessionsStates.error) &&
                  !sessionsStates.loading) ? (
                <EmptyCard
                  title="No tienes encuentros creados"
                  text="Calendariza tus próximos encuentros en vivo para que tu comunidad se entere"
                  icon={videoRecorder}
                // link={{
                //   label: "Crear Nuevo Encuentro",
                //   url: `/dashboard/${group?.group_type}/${group?.id}/sessions/new`,
                // }}
                />
              ) : (
                <>
                  {nextSessions?.map((session) => (
                    <motion.div key={`session-${session.id}`} layout>
                      <ReducedSessionCard
                        key={`session-${session.id}`}
                        session={session}
                        link={`/group/${group?.id}/s/${session.id}`}
                      />
                    </motion.div>
                  ))}
                </>
              )}
            </CardsGrid>
          </Grid.Col>
        </Grid.Row>

        <div style={{ height: 32 }} />

        <Grid.Row>
          <Grid.Col>
            <SectionTitle>Encuentros pasados</SectionTitle>
          </Grid.Col>
        </Grid.Row>

        <div style={{ height: 16 }} />

        <Grid.Row>
          <Grid.Col>
            <CardsGrid size={"large"}>
              {(!sessions && !sessionsStates.loading) ||
                ((oldSessions?.length <= 0 ||
                  sessionsStates.error) &&
                  !sessionsStates.loading) ? (
                <EmptyCard
                  title="No tienes encuentros pasados"
                  text="Sube la grabación adjuntando un link de Zoom, Youtube, Vimeo, Drive, etc."
                  icon={videoRecorder}
                // link={{
                //   label: "Subir un encuentro grabado",
                //   url: `/dashboard/${group?.group_type}/${group?.id}/sessions/new`,
                // }}
                />
              ) : (
                <>
                  {sessionsStates.loading && (
                    <>
                      <SessionCardSkeleton />
                      <SessionCardSkeleton />
                      <SessionCardSkeleton />
                    </>
                  )}

                  {oldSessions?.map((session) => (
                    <SessionCard
                      groupInformation={group}
                      key={`session-${session.id}`}
                      session={session}
                      link={`/group/${group?.id}/s/${session.id}`}
                    />
                  ))}
                </>
              )}
            </CardsGrid>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      <ModalShareLink />
    </>
  );
};

const state = ({ groupStore, sessionStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: sessions, states: sessionsStates } = sessionStore.all;

  return {
    group,
    groupStates,
    sessions,
    sessionsStates,
  };
};

export default connect(state)(Component);
