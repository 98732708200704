const advice = [
  {
    name: "Consejo 1",
  },
  {
    name: "Consejo 2",
  },
  {
    name: "Consejo 3",
  },
  {
    name: "Consejo 4",
  },
];

export default advice;
