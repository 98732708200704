import styled from "styled-components";

export const Banner = styled("section")`
  min-height: 100vh;
  padding-top: 200px;
  background-color: whitesmoke;
`;

export const Title = styled("h1")`
  margin: 0px 0px 30px;
  font-size: 72px;
  line-height: 1;
`;

export const SubTitle = styled("p")`
  margin: 0px 0px 30px;
  font-size: 16px;
  max-width: 500px;
`;
