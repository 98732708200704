import { Community, Title, StepperBar, Steps } from "./styles";
import Stepper from "../../components/Stepper";
import { useState } from "react";
import StepCreate from "./StepCreate";
import StepInteract from "./StepInteract";
import StepMonetize from "./StepMonetize";
import Grid from "../../../../components/Grid";
import Row from "../../../../components/Grid/Row";
import Col from "../../../../components/Grid/Col";

const Component = () => {
  const [activeStep, setActiveStep] = useState(0);
  return (
    <Community>
      <Grid.Container>
        <Row>
          <Col offset={{ lg: 1 }} lg={10}>
            <Title>
              Crea tu comunidad <br /> <span>Nosotros haremos el resto</span>
            </Title>
          </Col>

          <Col>
            <StepperBar>
              <Stepper
                onClick={setActiveStep}
                activeStep={activeStep}
                steps={[
                  { label: "Crea" },
                  { label: "Interactúa", active: true },
                  { label: "Monetiza" },
                ]}
              />
            </StepperBar>
          </Col>
        </Row>
      </Grid.Container>

      <Steps>
        <StepCreate visible={activeStep === 0} />
        <StepInteract visible={activeStep === 1} />
        <StepMonetize visible={activeStep === 2} />
      </Steps>
    </Community>
  );
};

export default Component;
