import { Card, Title, Text, GroupName } from './styles'
import sadIcon from '../../../../../assets/icons/sad.svg'
import Icon from '../../../../../components/Icon'
import { CSSProperties, useMemo } from 'react'
import { Group, Subscription } from '../../../../../types'
import { getUrlParamByName } from '../../../../../helpers/get-url-param'
import { connect } from 'react-redux'
import dateHandler from '../../../../../helpers/dates-hanlder';

const reasonsCopies = (reason, group_name) => {
  const texts = {
    insufficient_amount: {
      title: "Fondos insuficientes",
      description: <>Intentamos procesar tu pago, pero no hay fondos suficientes en tu tarjeta.<br /><br />Por favor, comprueba tu saldo disponible e intentalo nuevamente o  prueba con otro metodo de pago.</>
    },
    card_limit: {
      title: "Límite de tarjeta alcanzado",
      description: <>El pago no se pudo procesar porque tu tarjeta ha alcanzado su límite de gasto.<br /><br />Te sugerimos verificar tu límite o utilizar otro método de pago.</>
    },
    high_risk: {
      title: "Tu pago ha fallado",
      description: <>Tuvimos un problema al procesar el pago de tu suscripción a {group_name}.<br /><br />La transacción con tu tarjeta no pudo completarse. Te sugerimos probar con otro metodo de pago</>
    },
    call_for_authorize: {
      title: "Tu pago ha fallado",
      description: <>Tuvimos un problema al procesar el pago de tu suscripción a {group_name}.<br /><br />La transacción con tu tarjeta no pudo completarse. Te sugerimos probar con otro metodo de pago</>
    },
    other: {
      title: "Tu pago ha fallado",
      description: <>Tuvimos un problema al procesar el pago de tu suscripción a {group_name}.<br /><br />La transacción con tu tarjeta no pudo completarse. Te sugerimos probar con otro metodo de pago</>
    }
  }

  return reason ? (texts[reason] ? texts[reason] : texts['other']) : texts['other']
}

interface ComponentProps {
  style?: CSSProperties
  userSubscriptions: Subscription[]
  group: Group
}

const Component = ({ style, userSubscriptions, group }: ComponentProps) => {
  const subscriptionId = useMemo(() => getUrlParamByName('subscriptionId'), []);

  const subscription = useMemo(() =>
    userSubscriptions?.find((suscription) => suscription?.id === Number(subscriptionId)),
    [userSubscriptions, subscriptionId]
  )

  const billingDate = dateHandler.getDateInLocalTimeZone(subscription?.next_billing_at);
  const isPastDate = dateHandler.isExpired(subscription?.next_billing_at);
  const currency = subscription?.plan?.currency;

  const texts = (amount, date, isPastDate) => {
    const time = isPastDate ? 'past' : 'present';

    const textsTime = {
      past: <>El último cobro se realizó el <strong>{date}</strong>.</>,
      present: <>Tu próximo cobro se realizará el <strong>{date}</strong>.</>
    }

    return textsTime[time]
  }

  if (!subscription?.current_rejected_reason) {
    return <>
      <div style={{ height: 10 }} />
      <GroupName>{group?.group_name}</GroupName>
      <div style={{ height: 40 }} />
      <Text>{texts(`${currency} $${subscription?.current_amount}`, billingDate, isPastDate)}<br /><br /></Text>
      <Text>Esta acción no generará ningún cobro adicional y <br/> se respetará el ciclo de renovación de tu suscripción.</Text>
    </>
  }

  return (
    <Card {...(!!style ? { style } : {})}>
      <Icon icon={sadIcon} size="24px" color="#D82E33" />
      <div style={{ height: 12 }} />
      <Title>{reasonsCopies(subscription?.current_rejected_reason || 'other', group?.group_name)?.title}</Title>
      <div style={{ height: 12 }} />
      <Text>{reasonsCopies(subscription?.current_rejected_reason || 'other', group?.group_name)?.description}</Text>
    </Card>
  )
}


const state = ({ userStore, groupStore }) => {
  const { data: userSubscriptions } = userStore.subscriptions;

  return {
    userSubscriptions
  };
};

export default connect(state)(Component);