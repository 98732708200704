import { FinishWelcomeContentSectionOutDto, FinishWelcomeContentSectionInDto } from "../../../types/welcome-section";
export const FINISH_WELCOME_CONTENT_SECTION = "FINISH_WELCOME_CONTENT_SECTION";
export const FINISH_WELCOME_CONTENT_SECTION_SUCCESS = "FINISH_WELCOME_CONTENT_SECTION_SUCCESS";
export const FINISH_WELCOME_CONTENT_SECTION_ERROR = "FINISH_WELCOME_CONTENT_SECTION_ERROR";
export const RESET_FINISH_WELCOME_CONTENT_SECTION = "RESET_FINISH_WELCOME_CONTENT_SECTION";

export interface FinishWelcomeContentSection {
  type: typeof FINISH_WELCOME_CONTENT_SECTION;
  payload: FinishWelcomeContentSectionOutDto;
}

export interface FinishWelcomeContentSectionSuccess {
  type: typeof FINISH_WELCOME_CONTENT_SECTION_SUCCESS;
  payload: FinishWelcomeContentSectionInDto;
}

export interface FinishWelcomeContentSectionError {
  type: typeof FINISH_WELCOME_CONTENT_SECTION_ERROR;
  payload: string | boolean;
}

export interface ResetfinishWelcomeContentSection {
  type: typeof RESET_FINISH_WELCOME_CONTENT_SECTION;
  payload: null;
}

export interface State {
  data: FinishWelcomeContentSectionInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | FinishWelcomeContentSection
  | FinishWelcomeContentSectionSuccess
  | FinishWelcomeContentSectionError
  | ResetfinishWelcomeContentSection
