const calendlyMeet = {
  answers: {},
  user: process.env.REACT_APP_CALENDLY_USER,
  meet: "reunion-con-klouser-comunidades",
};

const calendlyAppDemo = {
  answers: {},
  user: "alepipman",
  meet: "llamada-demostracion-klouser",
};

export default { calendlyMeet, calendlyAppDemo };
