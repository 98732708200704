import { Column, Title, Message, ProfileImage, Link } from "./styles";
import { connect } from "react-redux";
import userAvatar from "../../../../../assets/avatar.svg";
import { ReactElement, useMemo } from 'react';
import Image from '../../../../../components/Image'
import mercadoPagoImg from '../../../../../assets/mercado-pago.png'
import { useTheme } from "styled-components";
import { Group, User } from "../../../../../types";
import Icon from "../../../../../components/Icon";
import backArrowIcon from "../../../../../assets/icons/arrow-backward.svg";

interface ComponentProps {
  group: Group
  user: User
  text?: ReactElement
}

const Component = ({ group, user, text }: ComponentProps) => {
  const theme = useTheme() as { logo: { small: string, big: string } };

  const profilePicture = useMemo(() => {
    return group?.expert?.profile_pic?.url || userAvatar
  }, [group])

  return (
    <Column>
      <div style={{ gap: 24, display: 'flex', justifyContent: 'center', width: '100%', maxWidth: 410 }}>
        <Image
          src={theme?.logo?.big}
          style={{ display: 'block' }}
          width="107px"
          height="auto"
          className="logo-desktop"
          alt="Klouser"
          lazy
        />

        <div style={{ height: 30, width: 1, backgroundColor: '#B1C6E4' }} />

        <Image
          src={mercadoPagoImg}
          style={{ display: 'block' }}
          width="101px"
          height="auto"
          className="logo-desktop"
          alt="Mercado pago"
          lazy
        />
      </div>

      <div style={{ height: 52 }} />

      <ProfileImage loading="lazy" src={profilePicture} alt={"Profile Image"} />

      <div style={{ height: 42 }} />

      <Title>​No se puede actualizar el método de pago</Title>

      <div style={{ height: 42 }} />

      <Message>No se puede actualizar el método de pago de la suscripción seleccionada.<br /><br /> Es posible que haya vencido el plazo de pago o que el pago no sea por débito automático.</Message>

      <div style={{ height: 42 }} />

      <Link
        options={{ type: 'filled', size: 'lg', skin: 'purple', block: true }}
        href={`/profile/manage-subscriptions/`}
        style={{ gap: 8 }}
      >
        <Icon size='20px' icon={backArrowIcon} color="#FFFFFF" />
        Ir a administrar subscripciones
      </Link>

      {/* <div style={{ height: 15 }} />
      
      <Link
        options={{ type: 'outline', size: 'lg', skin: 'purple', block: true, native: false }}
        href={`${constants.whatsapp.url}?phone=${constants.whatsapp.number
          }&text=${constants.whatsapp.checkout_failure.replace("{name}", user?.name).replace("{email}", user?.email)}`}
        target="_blank"
      >
        Contactarme con soporte
      </Link> */}
    </Column>
  )
}

const state = ({ groupStore, userStore }) => {
  const { data: group } = groupStore?.group;
  const { data: user } = userStore.information;
  return { group, user };
};

export default connect(state)(Component);