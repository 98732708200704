import { combineReducers } from "redux";
import newDiscount from "./new-discount";
import updateDiscount from "./update-discount";
import deleteDiscount from "./delete-discount";
import selectedDiscount from "./selected-discount";
import allDiscounts from "./all-discounts";
import oneDiscount from './get-discount'

//Selected discount es posible que debamos quitarlo y reemplazar todo por oneDiscount o viceversa, 
//actualmente selectedDiscount se usa en cupones y guarda el discount seleccionado sin necesidad de 
//hacer un get del mismo 

const reducer = combineReducers({
  selectedDiscount,
  oneDiscount,
  allDiscounts,
  newDiscount,
  updateDiscount,
  deleteDiscount
});

export default reducer;
