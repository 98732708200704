import styled, { createGlobalStyle } from "styled-components";

export const Title = styled("h3")`
  margin: 0px;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
`;

export const GlobalStyle = createGlobalStyle`
  .grid {
    display: grid;
    gap: 16px;
    grid-auto-rows: 237px;
    grid-template-columns: repeat(auto-fill, minmax(169px, 1fr));
  }
`;


export const CategoryName = styled('h3')`
  margin: 0px;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  max-width: calc(100% - 80px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const HorizontalScrollWrapper = styled('div')`
  margin: 0px -10px;
`

export const HorizontalScroll = styled('div')`
  overflow-x: scroll;
  display: flex;
  scroll-snap-type: x mandatory;
  padding: 10px;
  scroll-padding: 0 10px;

  ::-webkit-scrollbar {
    display: none;
  }

  & > * {
    scroll-snap-align: start;

    &:not(:last-child){
      margin-right: 16px;
    }
  }
`

export const CardsGrid = styled("div")`
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
`;

export const Filters = styled("div")`
  max-width: 100%;
  display: inline-flex;
  overflow-x: auto;
  gap: 6px;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Filter = styled("div")`
  flex-shrink: 0;
`;

export const Total = styled('div')`
  border-radius: 12px;
  min-height: 24px;
  min-width: 26px;
  position: relative;
  right: -8px;
  padding: 0px 6px;
  background-color: rgba(108,92,231,.20);
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 10px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Input = styled("input")`
  display: none;

  &:checked {
    & + label {
      background-color: ${({ theme }) => theme.colors.Primary};
      color: white;
    }

    & + label > ${Total} {
      background-color: rgba(255,255,255,.35);
      border: 1px solid ${({ theme }) => theme.colors.Primary};
      color: white;
    }
  }
`;

export const Label = styled("label")`
  text-transform: capitalize;
  cursor: pointer;
  height: 26px;
  line-height: 24px;
  border: 0px;
  font-weight: 400;
  font-size: 14px;
  border-radius: 12px;
  text-align: center;

  display: flex;
  gap: 5px;

  background-color: transparent;
  color: ${({ theme }) => theme.colors.Primary};
  border: 1px solid ${({ theme }) => theme.colors.Primary};

  padding: 0px 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

