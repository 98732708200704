import slugify from "slugify";
import {
  FieldContainer,
  HelperLine,
  HelperText,
  Label,
  ErrorMessage,
} from "../Field";

import { Field, Radio, OptionLabel, Checked } from "./styles";
import { useFormikFieldValidation } from "../../../hooks/useFormikFieldValidation";
import { useEffect, useState } from "react";
import { CSSProperties } from "styled-components";
import { Value } from "../../../pages/Dashboard/components/Statistics/styles";

const initialValue = {
  options: {
    skin: "base",
    size: "md",
  },
};

interface ComponentProps {
  name: string;
  value?: string | number;
  disabled?: boolean;
  error?: {};
  touched?: {};
  items: {
    label: string;
    value: string | number;
  }[];
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  styles?: {
    wrapper?: CSSProperties
  }
  options: {
    label?: string;
    skin?: "base";
    size?: "md";
    helperText?: string;
    before?: any;
    after?: any;
    options?: number;
    hidden?: boolean;
    marginBottom?: number;
    vertical?: boolean
  };
}

const Component = ({
  options,
  error,
  touched,
  items,
  name,
  value,
  disabled,
  onFocus,
  onBlur,
  onChange,
  styles
}: ComponentProps) => {
  const [selectedValue, setSelectedValue] = useState<string | number | undefined>(value);
  const { classes } = useFormikFieldValidation(error, name, disabled);
  const { skin, size, marginBottom } = {
    ...initialValue.options,
    ...options,
  };

  const handleOnChange = (event, callback) => {
    setSelectedValue(event.currentTarget.value);
    !!callback && callback(event);
  };

  const handleOnBlur = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    !!callback && callback(event);
  };

  useEffect(() => { setSelectedValue(value) }, [value])

  return (
    <FieldContainer marginBottom={marginBottom} style={{ ...(styles?.wrapper ? styles?.wrapper : {}) }}>
      {options?.label && (
        <Label size={size} htmlFor={`input-${name}`} style={{ marginBottom: 8 }}>
          {options.label}
          {/* {rules && rules.required && <Required>*</Required>} */}
        </Label>
      )}

      {(options && options.helperText) && (
        <HelperLine marginBottom={0} style={{ marginBottom: 12, marginTop: 0 }}>
          <HelperText>{options.helperText}</HelperText>
        </HelperLine>
      )}

      <Field
        skin={skin}
        size={size}
        className={classes}
        style={{
          backgroundColor: "transparent", ...(options.vertical ? {
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "5px",
          } : {})
        }}
      >
        {items.map((item, index) => {
          const { label: itemLabel, value: itemValue } = item;
          const id = `${slugify(itemValue.toString() || "", { lower: true })}-${index}`;

          return (
            <Radio size={size} key={id}>
              <OptionLabel
                size={size}
                disabled={disabled}
                withLabel={!!item?.label}
                className={selectedValue === itemValue ? "checked" : ""}
              >
                <input
                  id={id}
                  value={itemValue}
                  name={name}
                  type="radio"
                  onChange={(event) => handleOnChange(event, onChange)}
                  onBlur={(event) => handleOnBlur(event, onBlur)}
                  checked={selectedValue === itemValue}
                  disabled={disabled}
                />
                <Checked checked={selectedValue === itemValue} />
                {itemLabel}
              </OptionLabel>
            </Radio>
          );
        })}
      </Field>

      {error && (
        <HelperLine marginBottom={marginBottom || 40}>
          {!!error && !!touched && <ErrorMessage>{error}</ErrorMessage>}
        </HelperLine>
      )}
    </FieldContainer>
  );
};

export default Component;
