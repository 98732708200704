import Modal from "../../../../../../../components/Modal";
import Button from "../../../../../../../components/Button";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../../../store/actions/modal";
import { Group } from "../../../../../../../types";

const groupTypes = {
  membership: "la membresía",
  experience: "el taller",
  free_experience: "el evento gratuito",
  community: "la comunidad",
};

interface ComponentProps {
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  subscriptions;
}

const Component = ({ group, groupStates, subscriptions }: ComponentProps) => {
  const dispatch = useDispatch();

  return (
    <Modal name="modal-share-group-link" width="650" onClose={() => {}}>
      <Title>Se copió el link de {groupTypes[group?.group_type]}</Title>

      <Text>
        Ahora puedes compartirlo en redes sociales y a todos tus contactos. 🙌
      </Text>

      <AlignEnd>
        <Button
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
          }}
          onClick={() => dispatch(hideModal())}
        >
          Entendido
        </Button>
      </AlignEnd>
    </Modal>
  );
};

const state = ({ groupStore, userStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: subscriptions } = userStore.subscriptions;

  return {
    group,
    groupStates,
    subscriptions,
  };
};

export default connect(state)(Component);
