import * as Yup from "yup";
import services from "../../services";

let typingTimeout;
let typingTimeoutBis;

export const schema = ({ idGroup, validationRunning, addedEmails }) =>
  Yup.object().shape({
    expire_at_type: Yup.string().required("Selecciona una opción"),
    expire_at: Yup.string().when('expire_at_type', {
      is: (currentTermType) => currentTermType !== 'indefinido',
      then: Yup.string().required('Selecciona una fecha limite de permanencia.')
        .test('is-greater-than-today', 'La fecha debe ser mayor a hoy.', function (value) {
          const today = new Date();
          const selectedDate = new Date(value as string);
          return selectedDate >= today;
        }),
      otherwise: Yup.string(),
    }),
    email: Yup.string()
      .required("Ingresa un corréo electrónico")
      .trim("El corréo electrónico no puede contener espacios")
      .email("Ingresa un corréo electrónico válido")
      // .test(
      //   "checkUserEmailAlreadyListed",
      //   "El e-mail ingresado ya se encuentra agregado a la lista.",
      //   function (email) {
      //     console.log("checkUserEmailAlreadyListed")
      //     if (typingTimeoutBis) clearTimeout(typingTimeoutBis);

      //     return new Promise((resolve, reject) => {
      //       //delay until user stops typing

      //       typingTimeoutBis = setTimeout(async () => {
      //         if (!email) return true;

      //         const foundElement = addedEmails?.find(item => item?.email === email)

      //         if (foundElement && foundElement.length >= 0) {
      //           return false;
      //         } else {
      //           return true
      //         }
      //       }, 1000);
      //     });
      //   }
      // )
      .test(
        "checkUserEmailAlreadySubscribed",
        "El e-mail ingresado ya cuenta con una suscripción activa o bien ya tiene una invitación pendiente",
        function async(email) {
          //clear timeout if user is typing and timeout is set
          if (typingTimeout) clearTimeout(typingTimeout);

          return new Promise((resolve, reject) => {
            //delay until user stops typing

            typingTimeout = setTimeout(async () => {
              //check if email is valid
              if (!email) return resolve(true);

              validationRunning(true)

              const { response, error } = await services.invitations.validateInvitation({ email, idGroup });

              validationRunning(false)

              if (error) {
                return resolve(
                  this.createError({
                    message: "Error inesperado al validar el e-mail",
                  })
                );
              }

              const isAlreadySubscribed = response.data.filter(s => ['active', 'paid', 'non_renewing', 'pending_invite'].includes(s.status)).length > 0
              if (isAlreadySubscribed) {
                // Show success message when validation passes
                return resolve(false);
              } else {
                return resolve(true);
              }
            }, 1000);
          });
        }
      ),
  });


export const partialSchema = Yup.object().shape({
    expire_at_type: Yup.string().required("Selecciona una opción"),
    expire_at: Yup.string().when('expire_at_type', {
      is: (currentTermType) => currentTermType !== 'indefinido',
      then: Yup.string().required('Selecciona una fecha limite de permanencia.')
        .test('is-greater-than-today', 'La fecha debe ser mayor a hoy.', function (value) {
          const today = new Date();
          const selectedDate = new Date(value as string);
          return selectedDate >= today;
        }),
      otherwise: Yup.string(),
    }),
});

interface FormValues {
  expire_at_type: string,
  expire_at: string,
  email: string,
}

export const initialValues: FormValues = {
  expire_at_type: "indefinido",
  expire_at: "",
  email: "",
};
