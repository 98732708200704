import styled, { css } from "styled-components";
import Button from "../../../../../Button";
import Link from "../../../../../Link";
import DropdownUi from "../../../../../Dropdown";

export const LogoutMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const LinkItem = styled(Link) <{ marginBottom?; alignSelf?; native?}>`
  text-decoration: none;
  color: ${(props) => props.theme.colors.Primary};
  font-size: 14px;
  line-height: 33px;
  font-weight: 700;
  letter-spacing: 0.25px;
`;

export const ButtonItem = styled(Button) <{ marginBottom?; alignSelf?}>``;

export const Dropdown = styled(DropdownUi) <{ alignSelf?}>`
  li {
    &:last-child {
      margin: 0px auto;
    }
  }
`;
