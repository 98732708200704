import styled from "styled-components";
import Image from "../../../components/Image";

export const Img = styled(Image)`
  &.logo-desktop {
    display: none;
    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      display: block;
    }
  }

  &.logo-mobile {
    display: block;
    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      display: none;
    }
  }
`;

export const Header = styled("header")`
  padding: 30px 0px 20px;
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0px;
  left: 0px;
`;
export const Navbar = styled("header")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Logo = styled("a")``;
export const Menu = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const MenuItem = styled("a")`
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  color: black;
  padding: 0px 10px;
`;
