import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  .grid-small {
     padding: 10px 0px;
    display: grid;
    gap: 16px;
    grid-auto-rows: 120px;
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  }
`;

export const CardsGrid = styled("div")`
  padding: 10px 0px;
  display: grid;
  gap: 16px;
  grid-auto-rows: 120px;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
`;

export const Title = styled("h3")`
  margin: 0px;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
`;

export const SessionCardSkeleton = styled("div")`
  background-color: #e0e4ea;
  border-radius: 14px;
`;
