interface CheckoutbeeParams {
  groupType?: "membership" | "experience";
  planId: string | null;
  platform?: string | null;
  planType?: "subscription" | "charge"
  couponId?: number;
  customer: {
    email: string;
    id?: string;
    phoneAreaCode?: string;
    cellphone?: string;
  };
  qty?: number;
  site: string;
  onLoad?: (data: string) => void;
  onClose?: (data: string) => void;
  onSuccess?: (data: string) => void;
  onError?: (data: string) => void;
  onStep?: (data: string) => void;
}
declare global {
  interface Window {
    Chargebee: any;
  }
}

export const chargebeeJS = () => {
  const el = document.createElement("script");
  el.setAttribute("src", "https://js.chargebee.com/v2/chargebee.js");
  document.body.appendChild(el);
};

export const chargebeeUrlOneTimePayment = ({
  planId,
  couponId,
  customer,
  qty = 1,
  site,
}: CheckoutbeeParams) => {
  const plan = planId ? `item_prices[item_price_id][0]=${planId}` : "";
  const quantity = qty ? `&item_prices[quantity][0]=${qty}` : "";
  const customerId = customer.id ? `&customer_id[is]=${customer.id}` : "";
  const customerEmail = customer.email
    ? `&customer[email]=${customer.email}`
    : "";
  const coupon = couponId ? `&coupon_ids[0]=${couponId}` : "";
  return `https://${site}.chargebee.com/hosted_pages/checkout?${plan}${quantity}${customerEmail}${coupon}`;
};

export const chargebeeUrlSubscriptionPayment = ({
  planId,
  couponId,
  customer,
  qty = 1,
  site,
}: CheckoutbeeParams) => {
  const plan = planId ? `subscription_items[item_price_id][0]=${planId}` : "";
  const quantity = qty ? `&subscription_items[quantity][0]=${qty}` : "";
  const customerId = customer.id ? `&customer_id[is]=${customer.id}` : "";
  const customerEmail = customer.email
    ? `&customer[email]=${customer.email}`
    : "";
  const coupon = couponId ? `&coupon_ids[0]=${couponId}` : "";

  return `https://${site}.chargebee.com/hosted_pages/checkout?${plan}${quantity}${customerId}${customerEmail}${coupon}`;
};

export const chargebeeCheckoutUrl = (data: CheckoutbeeParams): string => {
  const { planType, ...rest } = data;
  return planType === "subscription"
    ? chargebeeUrlSubscriptionPayment(rest)
    : chargebeeUrlOneTimePayment(rest);
};

export const chargebeeCheckout = ({
  planId,
  couponId,
  customer,
  qty = 1,
  onLoad,
  onClose,
  onSuccess,
  onError,
  onStep,
  site,
}: CheckoutbeeParams) => {
  // Create ChargeBee Instance
  const cbInstance = window.Chargebee.init({
    site: site,
    isItemsModel: true,
  });

  window.Chargebee.registerAgain();

  cbInstance.setCheckoutCallbacks(() => ({
    loaded: onLoad,
    close: onClose,
    success: onSuccess,
    error: onError,
    step: onStep,
  }));

  // Create Cart Instance
  const cart = cbInstance.getCart();

  // Add Customer Info
  cart.setCustomer(customer);

  // Create product
  const product = cbInstance.initializeProduct(planId, qty);
  cart.replaceProduct(product);

  // Adding a coupon
  product.addCoupon(couponId);

  // Opening the checkout
  cart.proceedToCheckout();
};
