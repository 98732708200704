import * as Yup from "yup";

export const schema = Yup.object({
  status_in: Yup.array().of(Yup.string()).nullable(),
});

interface FormValues {
  status_in: string[];
}

export const initialValues: FormValues = {
  status_in: [],
};
