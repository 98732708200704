import { button } from "./button";
import { dropdown } from "./dropdown";
import { form } from "./form";
import { grid } from "./grid";
import { link } from "./links";
import { bullet } from "./bullet";
import { footer } from './footer'
import { howItsWorks } from './howItsWorks'

export const components = {
  button,
  dropdown,
  form,
  grid,
  link,
  bullet,
  footer,
  howItsWorks
};
