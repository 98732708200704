import styled from "styled-components";
import LinkUi from "../../../../../components/Link";

export const GroupCard = styled.div`
  background: ${(props) => props.theme.colors.White};
  box-shadow: 16px 0px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 16px;
  min-height: 300px;
`;

export const GroupCardHeader = styled.div`
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export const GroupName = styled.h5`
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  margin: 0px 0px 7px 0px;
`;

export const ExpertName = styled.h5`
  font-weight: 400;
  font-size: 15px;
  line-height: 14px;
  margin: 0px 0px 7px 0px;
`;

export const Information = styled.div``;

export const Date = styled.div`
  font-size: 15px;
  letter-spacing: -1px;
  line-height: 12px;
  margin-top: 8px;
  margin-bottom: 30px;
`;

export const Price = styled.div`
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -1px;
  margin-top: 15px;
  span {
    font-weight: 400;
    line-height: 12px;
  }
`;

export const HorizontalSeparator = styled("div")`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.Gray5};
`;

export const GroupCardBody = styled.div`
  padding: 16px 20px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
  }
`;

export const State = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const StateText = styled.div`
  margin-bottom: 24px;
  margin-top: 8px;
  font-size: 15px;
  letter-spacing: -1px;
  line-height: 18px;
  font-style: italic;
`;

export const LinksOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Link = styled(LinkUi)`
  margin: 0px auto 20px;

  &:first-child {
    width: auto;
  }

  &:last-child {
    margin-bottom: 0px
  }

  @media (max-width: ${(props) => props.theme.mediaBreakpoints.smMax}) {
    &:first-child {
      width: 100%;
    }
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) and (max-width: ${(props) => props.theme.mediaBreakpoints.mdMax}) {
    &:first-child {
      width: 42%;
    }
  }
`;
