import { getUrlParamByName } from "./get-url-param";

export const getYouTubeUrl = (url) => {
  const path = url.split("?")[0];
  return `https://www.youtube.com/embed/${getUrlParamByName("v", url) || path?.substring(path?.lastIndexOf("/") + 1)
    }`;
};

export const isYoutubeUrl = (url) => {
  const regex =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/gim;
  return regex.test(url);
};

export const isInstagramUrl = (url) => {
  const regex =
    /^((?:https?:)?\/\/)?((?:www)\.)?((?:instagram.com|instagr.am|instagr.com))(\/reel\/(?:[\w\-]+)?)/gim;
  return regex.test(url);
};

export const isVimeoUrl = (url) => {
  const regex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)/;

  return regex.test(url) && getVimeoVideoIdFromUrl(url) !== null;
};

export const getVimeoVideoIdFromUrl = (url) => {
  const vimeoDomain = 'vimeo.com/';
  const startIndex = !!url && url?.indexOf(vimeoDomain);

  if (startIndex !== -1) {
    const videoIdStartIndex = startIndex + vimeoDomain.length;
    const videoId = url?.substring(videoIdStartIndex);
    return videoId;
  } else {
    return null; // La URL no es válida de Vimeo.
  }
}