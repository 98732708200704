import Modal from "../../../../components/Modal";
import Link from '../../../../components/Link'
import { Title, Text } from "./styles";
import { Group } from "../../../../types";
import { Center } from "../../../../components/StyledComponents";

interface ComponentProps {
  page: any;
  group: Group
}

const Component = ({ page, group }: ComponentProps) => {
  return (
    <Modal name="create-first-plan-modal" width="600" showClose={false}>
      <Title>Tu {page} se ha creado con éxito 🥳​</Title>
      <div style={{ height: 20 }} />
      <Text>A continuación podrás definir valor de tu {page}. Recuerda que puedes crear diferentes planes con multiples precios y monedas.</Text>
      <div style={{ height: 20 }} />
      <Center horizontal="center" vertical="center">
        <Link
          options={{
            size: 'lg',
            skin: 'purple',
            type: 'filled'
          }}
          href={`/dashboard/${group?.group_type}/${group?.id}/price`}
        >
          + Crear plan
        </Link>
      </Center>
    </Modal>
  );
};

export default Component;
