import ReactSelect, { components } from "react-select";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import {
  FieldContainer,
  Field,
  Label,
  FieldBefore,
  HelperLine,
  HelperText,
  ErrorMessage,
} from "../Field";
import Image from "../../Image";
import { useFieldValidation } from "../../../hooks/useFieldValidation";

import { makeSelect, makeCustomSup } from "./styles";
import chevron from "./assets/chevron.svg";
import cross from "./../../../assets/cross.svg";
import { useEffect, useState } from "react";

const SelectField = styled(ReactSelect)`
  ${(props) => makeSelect(props)}
`;

const CustomSup = styled.sup`
  ${() => makeCustomSup()}
`;

const DropdownIndicator = (props) => {
  const { hasValue, selectProps } = props;
  return (
    (!selectProps.isClearable || (selectProps.isClearable && !hasValue)) && (
      <components.DropdownIndicator {...props}>
        <Image src={chevron} alt=">" width="16px" height="16px" />
      </components.DropdownIndicator>
    )
  );
};

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <img src={cross} width={16} height={16} alt={"X"} />
    </components.ClearIndicator>
  );
};

const initialValue = {
  options: {
    skin: "base",
    size: "md",
    marginBottom: 24,
  },
};

interface Option {
  value: string | number;
  label: string | number | React.ReactNode;
}

/**
 * Estas son todas las props que espera el campo de Select
 *
 * Si el select detecta rules tratara de armar un select con validacion,
 * pero tendras errores si no le mandas control, errors y name
 *
 * options es un objeto que recibe props propias del componente
 * reactSelectOptions es un objeto que recibe props propias del plugin de react-select
 */
interface SelectProps {
  control?: any;
  register?: (name: string, rules?: object) => any;
  name: string;
  forwardRef?;
  defaultValue?: any;
  // value?: { label: string; value: string | number }[];
  //items?: Option[];
  type?: string;
  disabled?: boolean;
  rules?: any;
  formState?: any;
  errors?: {};
  placeholder?: string;
  onFocus?: (data: any, formState: any) => void;
  onBlur?: (data: any, formState: any) => void;
  onChange?: (data: any, formState: any) => void;
  noOptionsText?: string;
  options: {
    data: Option[];
    textField: string;
    valueField: string;
    skin?: "base";
    size?: "md";
    label?: string;
    helperText?: string;
    before?: any;
    after?: any;
    marginBottom?: number;
  };

  reactSelectOptions?: {
    isClearable?: boolean;
    isSearchable?: boolean;
    isMulti?: boolean;
    closeMenuOnSelect?: boolean;
  };
}

const Component = ({
  control,
  name,
  forwardRef,
  defaultValue,
  disabled,
  rules,
  formState,
  errors,
  placeholder,
  onFocus,
  onBlur,
  onChange,
  options,
  noOptionsText,
  reactSelectOptions,
  ...rest
}: SelectProps) => {
  const { classes, setActive } = useFieldValidation(errors, name, disabled);
  const { skin, size, marginBottom } = { ...initialValue.options, ...options };

  const handleOnChange = (event, callback) => {
    callback && callback(event, formState);
  };

  const handleOnBlur = (event, callback) => {
    setActive(false);
    callback && callback(event, formState);
  };

  const handleOnFocus = (event, callback) => {
    setActive(true);
    callback && callback(event, formState);
  };

  /**
   * Quita el listado de opciones en caso que el select se encuentre desabilitado o en caso
   * de contar con la opcion isClearable en true y un valor definido
   */

  const removeMenu =
    disabled ||
    (reactSelectOptions && reactSelectOptions.isClearable && !!defaultValue);

  if (
    !reactSelectOptions ||
    (reactSelectOptions && !("isSearchable" in reactSelectOptions))
  ) {
    reactSelectOptions = {
      ...reactSelectOptions,
      isSearchable: true,
    };
  }

  const getDefaultValues = () => {
    const result = options.data?.filter((item) => {
      if (typeof defaultValue === "string") {
        return defaultValue === item.value;
      }

      if (typeof defaultValue === "object") {
        if (reactSelectOptions?.isMulti) {
          return defaultValue.map((item) => item.value).includes(item.value);
        } else {
          return defaultValue[options.valueField] === item.value;
        }
      }
    });

    return reactSelectOptions?.isMulti ? result : result[0];
  };

  return (
    <FieldContainer marginBottom={marginBottom}>
      {options && options.label && (
        <Label size={options?.size} htmlFor={`input-${name}`}>
          {options.label}
          {rules && rules.required && <CustomSup>*</CustomSup>}
        </Label>
      )}

      <Field skin={skin} size={size} className={classes}>
        {options && options.before && (
          <FieldBefore size={options?.size} className="field-before">
            {options.before}
          </FieldBefore>
        )}

        {rules ? (
          <Controller
            name={name}
            control={control}
            {...(!!defaultValue && {
              defaultValue: getDefaultValues(),
            })}
            rules={rules}
            render={({ field }) => {
              return (
                <SelectField
                  id={name}
                  isDisabled={disabled}
                  placeholder={placeholder}
                  components={{ DropdownIndicator, ClearIndicator }}
                  classNamePrefix="select"
                  noOptionsMessage={({ inputValue }) =>
                    inputValue
                      ? noOptionsText || "No hay resultados"
                      : "No hay resultados"
                  }
                  options={options.data}
                  menuPlacement="auto"
                  {...reactSelectOptions}
                  {...(field && {
                    ...field,
                    ...(!!forwardRef && {
                      ref: (event) => {
                        const { ref } = field;
                        ref(event);
                        forwardRef.current = event;
                      },
                    }),
                    onChange: (event) => {
                      handleOnChange(event, onChange);
                      return field.onChange(event);
                    },
                    onBlur: (event) => {
                      handleOnBlur(event, onBlur);
                      return field.onBlur();
                    },
                    onFocus: (event) => {
                      handleOnFocus(event, onFocus);
                    },
                  })}
                  {...rest}
                />
              );
            }}
          />
        ) : (
          <SelectField
            name={name}
            id={`select-${name}`}
            placeholder={placeholder}
            options={options.data}
            isDisabled={disabled}
            {...(removeMenu && { menuIsOpen: false })}
            {...reactSelectOptions}
            menuPlacement="auto"
            {...(!!defaultValue && {
              defaultValue: getDefaultValues(),
            })}
            {...(!!defaultValue && {
              value: getDefaultValues(),
            })}
            components={{ DropdownIndicator, ClearIndicator }}
            classNamePrefix="select"
            noOptionsMessage={({ inputValue }) =>
              inputValue
                ? noOptionsText || "No hay resultados"
                : "No hay resultados"
            }
            onChange={(option) => handleOnChange(option, onChange)}
            onBlur={(field) => handleOnBlur(field, onBlur)}
            onFocus={(field) => handleOnFocus(field, onFocus)}
            {...(forwardRef && { ref: forwardRef })}
            {...rest}
          />
        )}
      </Field>

      {((options && options.helperText) || errors) && (
        <HelperLine marginBottom={marginBottom}>
          {errors && errors[name] && (
            <ErrorMessage>{errors[name].message}</ErrorMessage>
          )}
          {options && options.helperText && errors && !errors[name] && (
            <HelperText>{options.helperText}</HelperText>
          )}
        </HelperLine>
      )}
    </FieldContainer>
  );
};

export default Component;
