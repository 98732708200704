import Icon from '../../../../../../components/Icon'
import WarningIcon from '../../../../../../assets/icons/warning.svg'
import {
  Card
} from './styles'

const Component = ( {groupType} ) => {
  return (
    <Card>
      <Icon icon={WarningIcon} size="63px" color="#e69138" />
      <div style={{ height: 20 }} />
      {groupType !== "community" ? (
        <span>Esta Categoria estará oculta <br/> ya que no tienes ningun<br /> articulo asociado</span>
      ) : (
        <span>Aún no tienes ningun lead magnet creado</span>
      )}
    </Card>)
}

export default Component