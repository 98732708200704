import { useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Action, Dispatch } from "redux";
import { useTheme } from "styled-components";
import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import { showModal } from "../../../../../../store/actions/modal";
import CardAticle from "../Card";
import Grid from '../../../../../../components/Grid'
import Button from '../../../../../../components/Button'
import Link from '../../../../../../components/Link'
import Icon from '../../../../../../components/Icon'
import closeCircleIcon from '../../../../../../assets/icons/close_circled.svg'
import { Center } from '../../../../../../components/StyledComponents'
import ProgressBar from '../ProgressBar'
import arrowRightTopIcon from "../../../../../../assets/icons/arrow_forward_up_right.svg";
import HorizontalScroll from "../../components/HorizontalScroll"

import {
  CloseButton,
  Card,
  Title,
  Text,
  HorizontalScrollWrapper,
  Completed,
  NotCompleted,
  CloseSectionButton
} from './styles'
import slugify from "slugify";
import LogoLoader from "../../../../../../components/LogoLoader";

const Component = ({
  welcomeContentSection,
  welcomeContentSectionStates
}) => {
  const dispatch = useDispatch<Dispatch<Action>>()
  const { isMobile } = useWindowSize()

  // Ordenar los welcome_contents por content_order
  const sortedWelcomeContents = useMemo(() => {
    return welcomeContentSection?.welcome_contents?.slice().sort((a, b) => a.content_order - b.content_order);
  }, [welcomeContentSection]);

  const uncompletedWcList = useMemo(() => {
    return sortedWelcomeContents?.filter(wc => !wc.completed)
  }, [sortedWelcomeContents])

  const texto = useMemo(() => {
    const uncompletedWelcomeContentPosts = uncompletedWcList?.length;
    const desktopTextTemplate = uncompletedWelcomeContentPosts >= 2 ? `Ve los ${uncompletedWelcomeContentPosts} contenidos nuevos!` : 'Ve el contenido nuevo!';
    const mobileTextTemplate = uncompletedWelcomeContentPosts >= 2 ? `${uncompletedWelcomeContentPosts} contenidos nuevos!` : '1 nuevo contenido!';
    return isMobile ? mobileTextTemplate : desktopTextTemplate
  }, [uncompletedWcList, isMobile])



  if (!!welcomeContentSectionStates?.loading)
    return <LogoLoader />

  return (
    <>
      <Card>
        {/* <CloseButton onClick={() => { dispatch(showModal("modal-minimize-welcome-section")) }}><Icon icon={closeCircleIcon} size="20px" /></CloseButton> */}


        <Grid.Container {...(isMobile && { gutter: 0 })}>
          <Grid.Row {...(isMobile && { gutter: 0 })}>
            <Grid.Col lg={10} offset={{ lg: 1 }} {...(isMobile && { gutter: 0 })}>
              {!!welcomeContentSection?.user_finished_welcome_section && (
                <div style={{ display: "flex", "justifyContent": "space-between" }}>
                  <Completed>¡Finalizado con éxito! 🎉</Completed>
                  <CloseButton onClick={() => { dispatch(showModal("modal-minimize-welcome-section")) }}><Icon icon={closeCircleIcon} size="20px" /></CloseButton>
                </div>
              )}
              <div style={{ height: 10 }} />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Title>{welcomeContentSection?.title}</Title>
              </div>

              <div style={{ height: isMobile ? 22 : 18 }} />

              <Text>{welcomeContentSection?.description}</Text>

              <div style={{ height: 18 }} />

              {!welcomeContentSection?.user_finished_welcome_section && (
                <div style={{ padding: isMobile ? "0 24px" : "0px" }}>
                  <ProgressBar />
                </div>
              )}


              <div style={{ height: 8 }} />

              <HorizontalScrollWrapper>
                <HorizontalScroll scrollToIndex={uncompletedWcList?.length > 0 ? uncompletedWcList[0]?.content_order : sortedWelcomeContents?.length - 1}>
                  <>
                    {sortedWelcomeContents?.map((wContent, index) => {
                      return (
                        <CardAticle
                          style={{ boxShadow: '0 6px 12px 0px rgba(0,0,0,.12)' }}
                          key={slugify(wContent.title + index, { lower: true })}
                          welcomeContentPost={wContent}
                          groupInformation={welcomeContentSection?.group}
                        />
                      )
                    })}
                  </>
                </HorizontalScroll>
              </HorizontalScrollWrapper>

              <div style={{ height: isMobile ? 8 : 32 }} />

              {welcomeContentSection?.user_finished_welcome_section && (
                <Center style={{ "padding": isMobile ? "30px" : "0px" }} column={isMobile} horizontal="left" vertical="center" gap={isMobile ? 16 : 24}>
                  {/* {uncompletedWcList?.length > 0 && (
                    <NotCompleted to={`/group/${welcomeContentSection?.group?.id}/wc/${uncompletedWcList[0]?.id}`}>
                      <span>{texto}</span>
                      <Icon icon={arrowRightTopIcon} size="14px" color={"#2D3436"} />
                    </NotCompleted>
                  )} */}

                  {/* <CloseSectionButton onClick={() => dispatch(showModal("modal-minimize-welcome-section"))}>
                    <Icon icon={closeCircleIcon} size="22px" color={"white"} />
                    Minimizar sección
                  </CloseSectionButton> */}

                  {uncompletedWcList?.length > 0 && (
                    <Link
                      options={{
                        type: 'filled',
                        size: 'lg',
                        skin: 'white-black'
                      }}
                      style={{ borderRadius: 30, gap: 8 }}
                      href={`/group/${welcomeContentSection?.group?.id}/wc/${uncompletedWcList[0]?.id}`}>
                      <span>{texto}</span>
                      <Icon icon={arrowRightTopIcon} size="14px" color={"#2D3436"} />
                    </Link>
                  )}

                  <Button
                    options={{
                      type: 'filled',
                      size: 'lg',
                      skin: 'black'
                    }}
                    style={{ borderRadius: 30, gap: 8 }}
                    onClick={() => dispatch(showModal("modal-minimize-welcome-section"))}>
                    <Icon icon={closeCircleIcon} size="22px" color={"white"} />
                    Minimizar sección
                  </Button>
                </Center>
              )}


              <div style={{ height: 8 }} />

            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </Card>
    </>
  )
}


const state = ({ userStore, groupStore, welcomeContentStore }) => {
  const { isLoggedIn } = userStore;
  const { data: user, states: userStates } =
    userStore.information;
  const { data: welcomeContentSection, states: welcomeContentSectionStates } = welcomeContentStore.welcomeContentSection;
  const { data: group, states: groupStates } = groupStore.group;

  return {
    isLoggedIn,
    user,
    userStates,
    welcomeContentSection,
    welcomeContentSectionStates,
    group,
    groupStates,
  };
};

export default connect(state)(Component);
