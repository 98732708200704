import styled from "styled-components";

export const Card = styled("div")`
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  min-height: 91px;
  padding: 16px 33px 16px 16px;
  position: relative;
`;

export const Cards = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 40px;

  & > div {
    width: calc(100% / 2 - 5px);
  }
`

export const Edit = styled("button")`
  border: 0px;
  background-color: transparent;
  padding: 0px;
  margin: 0px;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
`;

export const Delete = styled("button")`
  border: 0px;
  background-color: transparent;
  padding: 0px;
  margin: 0px;
  position: absolute;
  z-index: 1;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const Message = styled("p")`
  margin: 0px 0px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const Name = styled("p")`
  margin: 0px;
  font-family: "Playfair Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

export const Occupation = styled("p")`
  margin: 0px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #6c5ce7;
`;
