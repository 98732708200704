import {
  UpdateNotificationOutDto,
  UpdateNotificationInDto,
} from "../../../types/notification";

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_ERROR = "UPDATE_NOTIFICATION_ERROR";
export const RESET_UPDATE_NOTIFICATION = "RESET_UPDATE_NOTIFICATION";

export interface UpdateNotification {
  type: typeof UPDATE_NOTIFICATION;
  payload: UpdateNotificationOutDto;
}

export interface UpdateNotificationSuccess {
  type: typeof UPDATE_NOTIFICATION_SUCCESS;
  payload: UpdateNotificationInDto | null;
}

export interface UpdateNotificationError {
  type: typeof UPDATE_NOTIFICATION_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateNotification {
  type: typeof RESET_UPDATE_NOTIFICATION;
  payload: null;
}

export interface State {
  data: UpdateNotificationInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | UpdateNotification
  | UpdateNotificationSuccess
  | UpdateNotificationError
  | UpdateNotification
  | ResetUpdateNotification;
