import { combineReducers } from "redux";
import deleteReaction from "./delete-reaction";
import newReaction from "./new-reaction";

const reducer = combineReducers({
  newReaction,
  deleteReaction,
});

export default reducer;
