import { Formik } from "formik";
import { initialValues, schema } from "../../../constants/forms/analytics";
import Icon from "../../../components/Icon";
import Input from "../../../components/FormikForm/Input";
import Submit from "../../../components/FormikForm/Submit";
import Snackbar from "../../../components/Snackbar";
import Grid from "../../../components/Grid";
import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useWindowSize } from "../../../hooks/useWindowSize";
import IconFacebook from "../../../assets/icons/icon-facebook.svg";
import {
  changeTracking,
  resetChangeTracking,
} from "../../../store/actions/user";
import { Layout, useLayout } from "../../../providers/LayoutProvider";
import { User } from "../../../types";

interface ComponentProps {
  setActiveTab?;
  user: User;
  changeTrackingStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  setActiveTab,
  user,
  changeTrackingStates,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState(false);

  const onSubmit = ({ values, actions }) => {
    !!user && dispatch(changeTracking(values));
  };

  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "plain",
      footer: {
        visible: false,
      },
      header: {
        visible: true,
        actions: [
          <Submit
            form="analytics-form"
            isSubmmiting={changeTrackingStates.loading}
            options={{
              size: "lg",
              skin: isMobile ? "white" : "purple",
              type: "filled",
              block: isMobile,
            }}
          >
            Guardar cambios
          </Submit>,
        ],
      },
      page: {
        title: "Configuración de cuenta",
        description: "Configuración de cuenta",
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      body: {
        visible: true,
      },
    }),
    [layout, changeTrackingStates, isMobile]
  );

  // const defaultValues = useMemo(() => {
  //   let obj = {};

  //   for (const key in analytics) {
  //     Object.assign(obj, {
  //       [analytics[key].type]: analytics[key].enabled ? ["true"] : [],
  //     });
  //   }

  //   return obj;
  // }, [analytics]);

  useEffect(() => {
    setActiveTab("analytics");
    setLayout(layoutData);
  }, []);

  useEffect(() => {
    setLayout(layoutData);
  }, [changeTrackingStates, isMobile]);

  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <Formik
            initialValues={{
              ...initialValues,
              account_fb_pixel_id: user?.expert?.account_fb_pixel_id,
            }}
            onSubmit={(values, actions) => {
              if (onSubmit) onSubmit({ values, actions });
            }}
            validateOnChange={false}
            validateOnBlur={formSubmmited}
            validationSchema={schema}
            enableReinitialize
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              return (
                <form
                  style={{ width: "100%", maxWidth: "690px" }}
                  className="theme-form"
                  onSubmit={(event) => {
                    setFormSubmmited(true);
                    handleSubmit(event);
                  }}
                  id="analytics-form"
                >
                  <Input
                    name="account_fb_pixel_id"
                    touched={touched["account_fb_pixel_id"]}
                    error={errors["account_fb_pixel_id"]}
                    value={values["account_fb_pixel_id"]}
                    disabled={changeTrackingStates.loading}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Facebook Pixel ID"
                    options={{
                      marginBottom: 42,
                      label: "Facebook pixel ID",
                      before: <Icon icon={IconFacebook} size="20px" />,
                    }}
                  />

                  <div style={{ height: "30px" }} />

                  {!isMobile && (
                    <Submit
                      form="analytics-form"
                      isSubmmiting={changeTrackingStates.loading}
                    >
                      Guardar cambios
                    </Submit>
                  )}
                </form>
              );
            }}
          </Formik>
        </Grid.Col>
      </Grid.Row>

      <Snackbar
        visible={changeTrackingStates.success || !!changeTrackingStates.error}
        options={{
          time: 1500,
          type: changeTrackingStates.success ? "success" : "error",
        }}
        onHide={() => dispatch(resetChangeTracking())}
      >
        {changeTrackingStates.success
          ? "Información actualizados."
          : "Error al actualizar la información."}
      </Snackbar>
    </Grid.Container>
  );
};

const state = ({ userStore }) => {
  const { data: user, states: userStates } = userStore.information;
  const { states: changeTrackingStates } = userStore.changeTracking;

  return {
    user,
    userStates,
    changeTrackingStates,
  };
};

export default connect(state)(Component);
