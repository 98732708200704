import { Subscription } from "../types";

export const isSubscribed = (subscriptions: Subscription[], groupId) => {
  const userSubscriptions = !!subscriptions ? subscriptions : []
  const commonsSubscriptionsStates = ["active", "paid", "non_renewing", "renewal_retrying"]
  const mpSubscriptionsStates = ["waiting_for_gateway", "not_paid", "retrying"]

  return userSubscriptions
    ?.filter(
      (suscription) => commonsSubscriptionsStates.includes(suscription.status) ||
        mpSubscriptionsStates.includes(suscription.status)
    )
    .filter((suscription) => suscription.group.id === groupId).length > 0
};
