import {
  initialState,
  Actions,
  State,
  SAVE_PREFERENCE,
  SAVE_PREFERENCE_SUCCESS,
  SAVE_PREFERENCE_ERROR,
  GET_PREFERENCE,
  GET_PREFERENCE_SUCCESS,
  GET_PREFERENCE_ERROR,
} from "../types/user/preferences";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_PREFERENCE:
      return {
        ...state,
        preferences: {
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        },
      };
    case GET_PREFERENCE_SUCCESS:
      return {
        ...state,
        preferences: {
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case GET_PREFERENCE_ERROR:
      return {
        ...state,
        preferences: {
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    case SAVE_PREFERENCE:
      return {
        ...state,
        savePreference: {
          states: {
            loading: false,
            error: false,
            success: false,
          },
        },
      };
    case SAVE_PREFERENCE_SUCCESS:
      return {
        ...state,
        savePreference: {
          states: {
            loading: false,
            error: false,
            success: true,
          },
        },
      };
    case SAVE_PREFERENCE_ERROR:
      return {
        ...state,
        savePreference: {
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        },
      };
    default:
      return state;
  }
};

export default reducers;
