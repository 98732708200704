import { EditWelcomeContentPostOutDto, EditWelcomeContentPostInDto } from "../../../types/welcome-section";
export const EDIT_WELCOME_CONTENT_POST = "EDIT_WELCOME_CONTENT_POST";
export const EDIT_WELCOME_CONTENT_POST_SUCCESS = "EDIT_WELCOME_CONTENT_POST_SUCCESS";
export const EDIT_WELCOME_CONTENT_POST_ERROR = "EDIT_WELCOME_CONTENT_POST_ERROR";
export const RESET_EDIT_WELCOME_CONTENT_POST = "RESET_EDIT_WELCOME_CONTENT_POST";

export interface EditWelcomeContentPost {
  type: typeof EDIT_WELCOME_CONTENT_POST;
  payload: EditWelcomeContentPostOutDto;
}

export interface EditWelcomeContentPostSuccess {
  type: typeof EDIT_WELCOME_CONTENT_POST_SUCCESS;
  payload: EditWelcomeContentPostInDto;
}

export interface EditWelcomeContentPostError {
  type: typeof EDIT_WELCOME_CONTENT_POST_ERROR;
  payload: string | boolean;
}

export interface ResetEditWelcomeContentPost {
  type: typeof RESET_EDIT_WELCOME_CONTENT_POST;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | EditWelcomeContentPost
  | EditWelcomeContentPostSuccess
  | EditWelcomeContentPostError
  | ResetEditWelcomeContentPost;
