import { SubTitle } from './styles'

interface ComponentProps {
	marginBottom?: number
	children: any
}

const Component = ({ children, ...rest }:ComponentProps) => {
	return <SubTitle {...rest}>{children}</SubTitle>
}

export default Component