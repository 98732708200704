import * as Yup from "yup";

export const schema = Yup.object().shape({
  cancellation_reason: Yup.string().required("Debes completar este campo").min(30, "Completa al menos 30 caracteres")
});

interface FormValues {
    cancellation_reason: string;
}

export const initialValues: FormValues = {
  cancellation_reason: ""
};
