import * as Yup from "yup";
import services from "../../services";

let typingTimeout;

export const schema = Yup.object().shape({
  username: Yup.string()
    .max(
      29,
      "El nombre de usuario ingresado supera el máximo de caracteres permitidos."
    )
    .matches(
      /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/i,
      "El nombre de usuario ingresado no es válido."
    )
    .test(
      "checkUsernmaeExist",
      "Este nombre de usuario no está disponible. Prueba con otro.",
      function async(value) {
        //clear timeout if user is typing and timeout is set
        if (typingTimeout) clearTimeout(typingTimeout);

        return new Promise((resolve, reject) => {
          //delay until user stops typing

          typingTimeout = setTimeout(async () => {
            //check if username is valid
            if (!value) return resolve(true);

            const { response, error } = await services.expert.validateExpert(
              value
            );

            if (error) {
              resolve(
                this.createError({
                  message: "Error inesperado al validar el nombre de usuario",
                })
              );
            }

            resolve(!response?.id);
          }, 1000);
        });
      }
    )
    .required("Ingresa un nombre de usuario."),
});

interface FormValues {
  username: string;
}

export const initialValues: FormValues = {
  username: "",
};
