import Icon from "../../../../../../components/Icon"
import arrowButtonIcon from '../../../../../../assets/icons/chevron-down-black.svg'
import { useState } from "react"
import {
  Text,
  Button,
  Link
} from '../../styles'

interface ComponentProps {
  showDropdown?: boolean
  isOpen?: boolean
}

const Component = ({ showDropdown = true, isOpen = false }: ComponentProps) => {
  const [open, setOpen] = useState<boolean>(isOpen || !showDropdown)

  return (
    <>{showDropdown && (
      <>
        <Text style={{ display: 'flex', gap: 4, justifyContent: 'center', alignItems: 'center' }}>
          <Button
            options={{ skin: 'black', size: 'sm', type: 'link' }}
            onClick={() => setOpen((oldState) => !oldState)}
          >
            Mas información
            <Icon icon={arrowButtonIcon} color={'black'} size="20px" style={{ transform: `rotate(${open ? -180 : 0}deg)`, transition: 'transform .2s ease-in-out' }} />
          </Button>
        </Text>
        <div style={{ height: 12 }} />
      </>
    )}
      {open && (
        <>
          <div style={{ height: 23 }} />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', fontSize: 14 }}>
            <strong>¿Es seguro actualizar mis datos?</strong>
            <div style={{ height: 8 }} />
            <span>Sí, usamos tecnología de encriptación para proteger tu información. </span>
            <div style={{ height: 24 }} />
            <strong>¿Puedo utilizar la misma tarjeta?</strong>
            <div style={{ height: 8 }} />
            <span>continuar con la tarjeta que ya tienes asociada, o si lo prefieres, puedes aprovechar para cambiar a otro método de pago</span>
            <div style={{ height: 24 }} />
            <strong>¿Cuando se cobrará mi suscripción? </strong>
            <div style={{ height: 8 }} />
            <span>Respetaremos el ciclo de cobro de tu suscripción como estaba programado. No habra cobros duplicados, ni tarifas adicionales.</span>
            <div style={{ height: 24 }} />
            <strong>¿Por qué debo verificar mis datos?</strong>
            <div style={{ height: 8 }} />
            <span>Para mantener tu tarifa actual porantigüedad, necesitamos revalidar tu método de pago. Este proceso nos permitirá seguir cobrándote sin cambios.</span>
            <div style={{ height: 24 }} />
            <strong>¿Qué beneficios tiene?</strong>
            <div style={{ height: 8 }} />
            <span>onservar tu tarifa actual, tendrás más control sobre tu suscripción y un sistema de pago más seguro.</span>
            <div style={{ height: 24 }} />
            <strong>¿Qué pasa si no verifico mis datos?</strong>
            <div style={{ height: 8 }} />
            <span>Tu tarifa podría cambiar en la próxima actualización. Te sugerimos hacerlo pronto para evitar modificaciones.</span>
          </div>
          <div style={{ height: 30 }} />
        </>
      )}
    </>
  )
}

export default Component