import Helmet from "react-helmet";
import Name from "../../../components/Name";
import { Page, PageHeader } from "../../../components/styles";
import Description from "../../../components/Description";
import SelectYourCountryModal from "../../../components/SelectCountry";
import Benefits from "../../../components/Benefits";
//import Tips from "../../../components/Tips";
import Duration from "../../../components/Duration";
import RegistrationClosingDate from "../../../components/RegistrationClosingDate";
import Price from "../../../components/Price";
import Link from "../../../../../components/Link";
import Submit from "../../../../../components/FormikForm/Submit";
import Grid from "../../../../../components/Grid";
import ContainerFluid from "../../../../../components/Grid/ContainerFluid";
// import Grid.Row from "../../../../../components/Grid/Grid.Row";
// import Grid.Col from "../../../../../components/Grid/Grid.Col";
import { Title } from "../../../components/styles";
import Scheduler from "../../../components/Scheduler";
import Breadcrumb from "../../../../../components/breadcrumb";
import CreatedBy from "../../../components/CreatedBy";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import { showModal, hideModal } from "../../../../../store/actions/modal";
import { connect, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import Congratulations from "../../../components/ModalCongratulations";
import { useForm } from "react-hook-form";
import { newGroup, resetNewGroup } from "../../../../../store/actions/group";
import constants from "../../../../../constants";
import { useHistory } from "react-router";
import CoverPic from "../../../components/CoverPic";
import { setToZeroTimeZone } from "../../../../../helpers/dates-hanlder";
import { useLayout } from "../../../../../providers/LayoutProvider";

interface ComponentProps {
  match;
  pageContentRef?;
  currentSlide?: number;
  newGroupStates;
  newGroupData;
  userInformation;
}

const Component = ({
  match,
  pageContentRef,
  currentSlide,
  newGroupStates,
  newGroupData,
  userInformation,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const { layout, setLayout } = useLayout();
  const history = useHistory();
  const { isMobile } = useWindowSize();
  const [descriptionError, setDescriptionError] = useState(false);
  const {
    control,
    handleSubmit,
    register,
    reset,
    unregister,
    formState,
    watch,
    getValues,
    setError,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const [activeSlide, setActiveSlide] = useState<number>();
  const [progressBar, setProgressBar] = useState<{
    fields;
    errors;
    silencedFields;
  }>();
  const labelRefName = useRef<HTMLLabelElement>();
  const labelRefCreatedBy = useRef<HTMLLabelElement>();
  const labelRefDescription = useRef<HTMLLabelElement>();
  const labelRefBenefits = useRef<HTMLLabelElement>();
  const labelRefDuration = useRef<HTMLLabelElement>();
  const labelRefClosingDate = useRef<HTMLLabelElement>();
  const labelRefPrice = useRef<HTMLLabelElement>();
  const labelRefFiles = useRef<HTMLLabelElement>();

  const labelsRefs = [
    labelRefName,
    labelRefCreatedBy,
    labelRefFiles,
    labelRefDescription,
    labelRefBenefits,
    labelRefDuration,
    labelRefClosingDate,
    labelRefPrice,
  ];

  const handleProgressBar = () => {
    const silencedFields = ["group_type"];
    const fields = watch();
    setProgressBar({ fields, errors: formState.errors, silencedFields });
  };

  const setFieldFocus = (fieldNumber, name) => {
    const label = labelsRefs[fieldNumber]?.current;

    if (!!label && !!pageContentRef.current) {
      const target = isMobile ? window : pageContentRef.current;
      const offset = 120;
      const top = label.offsetTop;

      target.scrollTo({
        top: top + offset,
        behavior: "smooth",
      });
    }

    if (name && !isMobile) {
      //setFocus(name);
    }
  };

  const onFormSubmit = (data) => {
    const { duration_unit, duration_value } = data.duration;

    const payload = {
      ...data,
      registration_closing_date: setToZeroTimeZone(
        data.registration_closing_date
      ),
      group_perks: data.group_perks.filter(
        (group_perk) => !!group_perk && group_perk !== ""
      ),
      duration_unit: duration_unit.value,
      duration: duration_value,
      plan: {
        type: "free_pass",
        price: 0,
        status: "active"
      }
    };

    if (!descriptionError) {
      dispatch(newGroup(payload));
    }
  };

  const onFormError = (data) => console.log("Form Error:", data);

  const onFocus = (fieldNumber, name) => {
    setActiveSlide(fieldNumber - 1);
    setFieldFocus(fieldNumber - 1, name);
    handleProgressBar();
  };

  const onBlur = () => {
    handleProgressBar();
  };

  useEffect(() => {
    if (currentSlide !== undefined) {
      setFieldFocus(currentSlide, null);
    }
  }, [currentSlide]);

  useEffect(() => {
    if (!!newGroupData) {
      dispatch(showModal("congratulation"));
      setTimeout(() => {
        dispatch(resetNewGroup());
        dispatch(hideModal());
        setTimeout(() => {
          history.push(`/dashboard/community/events/${newGroupData.id}`);
        }, 200);
      }, 4300);
    }
  }, [newGroupData]);

  useEffect(() => {
    if (
      !!userInformation &&
      !!userInformation?.expert &&
      !!userInformation?.expert.showable_name
    ) {
      reset({
        creator_name: userInformation?.expert.showable_name,
      });
    }

    if (
      !!userInformation &&
      (!userInformation?.expert || !userInformation?.expert.showable_name) &&
      !!userInformation.name
    ) {
      reset({
        creator_name: userInformation.name,
      });
    }
  }, [userInformation]);

  useEffect(() => {
    //dispatch(showModal("tips"));
    setActiveSlide(0);
  }, []);

  useEffect(() => {
    setLayout({ ...layout, page: { title: "Crear evento gratuito" } });
  }, []);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: "Crear evento gratuito",
        description: "Crear evento gratuito",
        back: "/dashboard/community/events/list",
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: true,
        actions: [
          <Link
            style={{ marginRight: "8px" }}
            options={{
              type: "outline",
              size: "lg",
              skin: "purple",
              native: true,
            }}
            href={`/dashboard/community/events/list`}
          >
            Cancelar
          </Link>,

          <Submit
            isSubmmiting={newGroupStates.loading}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            form="new-free-experience-form"
          >
            Crear evento
          </Submit>,
        ],
      },
    });
  }, []);

  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col sm={6}>
          <form
            onSubmit={handleSubmit(onFormSubmit, onFormError)}
            id="new-free-experience-form"
          >
            <Name
              fildName="Nombre del evento"
              holderName="Elige un nombre para tu evento"
              labelForwardRef={labelRefName}
              fieldNumber={1}
              onFocus={onFocus}
              onBlur={onBlur}
              register={register}
              errors={{ ...formState.errors }}
            />
            <CreatedBy
              labelForwardRef={labelRefCreatedBy}
              fieldNumber={2}
              onFocus={onFocus}
              onBlur={onBlur}
              register={register}
              errors={{ ...formState.errors }}
            />
            {/* <CoverPic
              name="cover_pic"
              aceptedExtensions={[
                "image/jpeg",
                "image/png",
                "audio/mpeg",
                "audio/ogg",
                "audio/wav",
                "video/3gpp",
                "video/x-msvideo",
                "video/x-ms-wmv",
                "video/mp4",
              ]}
              labelForwardRef={labelRefFiles}
              fieldNumber={3}
              onFocus={onFocus}
              onBlur={onBlur}
              control={control}
              errors={{ ...formState.errors }}
              fieldTitle="Personalizá tu evento"
              fieldText="Podés agregar un video a la página de tu evento. El vidéo debera ser en formatos .MP4 y pesar hasta mâximo 500Mb. "
            /> */}
            <Description
              control={control}
              label="Describe tu evento"
              labelForwardRef={labelRefDescription}
              fieldNumber={3}
              onFocus={onFocus}
              onBlur={onBlur}
              register={register}
              setError={setError}
              setDescriptionError={setDescriptionError}
              errors={{ ...formState.errors }}
            />
            <Benefits
              fildName="¿Qué beneficios incluye tu evento?"
              labelForwardRef={labelRefBenefits}
              fieldNumber={4}
              onFocus={onFocus}
              onBlur={onBlur}
              register={register}
              unregister={unregister}
              errors={{ ...formState.errors }}
            />
            <Duration
              getValues={getValues}
              labelForwardRef={labelRefDuration}
              fieldNumber={5}
              onFocus={onFocus}
              onBlur={onBlur}
              register={register}
              control={control}
              errors={{ ...formState.errors }}
            />
            <RegistrationClosingDate
              labelForwardRef={labelRefClosingDate}
              fieldNumber={6}
              onFocus={onFocus}
              onBlur={onBlur}
              register={register}
              errors={{ ...formState.errors }}
            />
            <input
              type="hidden"
              value="free_experience"
              {...register("group_type", { required: true })}
            />
          </form>
        </Grid.Col>
      </Grid.Row>
      {!isMobile && (
        <Grid.Row>
          <Grid.Col>
            <Link
              style={{ marginRight: "8px" }}
              options={{
                type: "outline",
                size: "lg",
                skin: "purple",
                native: true,
              }}
              href={`/dashboard/community/events/list`}
            >
              Cancelar
            </Link>

            <Submit
              isSubmmiting={newGroupStates.loading}
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
              }}
              form="new-free-experience-form"
            >
              Crear evento
            </Submit>
          </Grid.Col>
        </Grid.Row>
      )}

      <Congratulations page="Evento" />
    </Grid.Container>
  );
};

const state = ({ carouselStore, groupStore, userStore }) => {
  const { data: userInformation } = userStore.information;
  const { currentSlide } = carouselStore;
  const { data: newGroupData, states: newGroupStates } = groupStore.newGroup;
  return {
    currentSlide,
    newGroupStates,
    newGroupData,
    userInformation: userInformation,
  };
};

export default connect(state)(Component);
