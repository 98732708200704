import styled, { css, keyframes } from "styled-components";
import UiButton from "../../../../../components/Button";
import LinkUi from "../../../../../components/Link";
import ButtonUi from "../../../../../components/Button";
import { Link as LinkRrd } from "react-router-dom";
import Icon from "../../../../../components/Icon";
import Markdown from "markdown-to-jsx";

export const Card = styled("div")`
  padding: 16px;
  color: inherit;
  overflow: hidden;
  border-radius: 6px;

  flex-shrink: 0;
  width: 100%;
  max-width: 290px;
  height: 250px;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,.1);
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 3px 5px 3px rgba(0,0,0,.1);
  }

  @media(min-width: 768px){
    max-width: 340px;
    height: 250px;
  }
`;

export const Top = styled("div")`
  position: relative;
  z-index: 10;
  display: flex;
  align-elements: center;
  justify-content: flex-end;
  width: 100%;
`;

export const Actions = styled("div")`
  display: flex;
  width: 100%;
  align-elements: center;
  justify-content: flex-end;
  gap: 4px;
`;

export const Circle = styled("div")`
  position: relative;
  width: 21px;
  height: 21px;
  background: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const cleanCta = css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  pointer-events: auto;
  width: 21px;
  height: 21px;
  border-radius: 100%;
  min-width: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    box-shadow: none;
  }
`;

export const Link = styled(LinkUi)`
  ${cleanCta}
`;

export const Button = styled(ButtonUi)`
  ${cleanCta}
`;

export const Title = styled("p")`
  margin: 0px 0px 6px;
  font-weight: 500;
  font-size: 16px;
  text-overflow: ellipsis;
  color: black;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media(min-width: 768px){
    font-size: 15px;
    -webkit-line-clamp: 2;
  }
`;

export const Author = styled("span")`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: black;
`;

export const Date = styled("span")`
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: black;

  @media(min-width: 768px){
    font-size: 12px;
  }
`;

export const FutureDate = styled("div")`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  color:#black;
  padding-left: 5px;
`;

export const Information = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const GoToPreview = styled(LinkRrd) <{ coverPicHeight?}>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding: ${({ coverPicHeight }) => !!coverPicHeight ? `${coverPicHeight + 15}px 15px 10px;` : "170px 15px 10px;"};
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Background = styled("div") <{ backgroundImage?, height?}>`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  height: ${({ height }) => !!height ? `${height}px` : "155px"};
  transition: all 0.3s ease-in-out 0s;

  ${({ backgroundImage }) =>
    !!backgroundImage
      ? "background-image:url('" +
      backgroundImage +
      "'); background-size: cover; background-repeat: no-repeat; background-position: center;"
      : ""}

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 44.79%, rgba(0, 0, 0, 0.4) 100%);
  }
`;

export const FreeLabel = styled("div")`
  width: 245px;
  background-color: white;
  border-radius: 6px;
  padding: 2.5px 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ( max-width: 767px ) {
    width: 140px;
  }

`;


const expand = keyframes`
  0% {
    padding: 0px;
    width: 0%;
  }
  100% {
    padding: 0px 5px;
    width: 100%;
  }
`

export const Reactions = styled('div') <{ reactions }>`
  display: flex;
`

export const EmojiWithCounter = styled('div')`
  height: 15px;
  padding: 0px 5px;
  border-radius: 17px;
  background-color: rgb(241, 241, 241);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 10px;
  box-shadow: rgba(0, 0, 0, 0.4) -1px 0px 1px -1px;
  position: relative;
  cursor: pointer;

  &:not(:first-child){
    margin-left: -10px;
  }

  div {
    &.reaction-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    
    &.emoji {
      
    }

    &.counter {
      width: 0px;
      overflow: hidden;
      font-size: 10px;

      @media(min-width: 768px){
        font-size: 12px;
      }
    }
  }

  &:hover {
    z-index: 10;

    div {
      &.counter {
        animation: ${expand} 0.2s ease-in-out forwards;
      }
    }
  }

  @media(min-width: 768px){
    height: 20px;
    font-size: 12px;
  }
  
`