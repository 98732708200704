export const SHARE_POST_IN_TELEGRAM = "SHARE_POST_IN_TELEGRAM_POST";
export const SHARE_POST_IN_TELEGRAM_SUCCESS = "SHARE_POST_IN_TELEGRAM_SUCCESS";
export const SHARE_POST_IN_TELEGRAM_ERROR = "SHARE_POST_IN_TELEGRAM_ERROR";
export const RESET_SHARE_POST_IN_TELEGRAM = "RESET_SHARE_POST_IN_TELEGRAM";

export interface SharePostInTelegram {
  type: typeof SHARE_POST_IN_TELEGRAM;
  payload: { id: number };
}

export interface SharePostInTelegramError {
  type: typeof SHARE_POST_IN_TELEGRAM_ERROR;
  payload: false;
}

export interface SharePostInTelegramSuccess {
  type: typeof SHARE_POST_IN_TELEGRAM_SUCCESS;
  payload: null;
}

export interface ResetSharePostInTelegram {
  type: typeof RESET_SHARE_POST_IN_TELEGRAM;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | SharePostInTelegram
  | SharePostInTelegramError
  | SharePostInTelegramSuccess
  | ResetSharePostInTelegram;
