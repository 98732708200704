import styled from "styled-components";

export const Stepper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;
