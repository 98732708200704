import { PlanDto } from "../../../types/plan.dto";
export const GET_PLAN = "GET_PLAN";
export const GET_PLAN_SUCCESS = "GET_PLAN_SUCCESS";
export const GET_PLAN_ERROR = "GET_PLAN_ERROR";
export const RESET_GET_PLAN = "RESET_GET_PLAN";

export interface GetPlan {
  type: typeof GET_PLAN;
  payload: { idPlan: number };
}

export interface GetPlanSuccess {
  type: typeof GET_PLAN_SUCCESS;
  payload: PlanDto;
}

export interface GetPlanError {
  type: typeof GET_PLAN_ERROR;
  payload: string | boolean;
}

export interface ResetGetPlan {
  type: typeof RESET_GET_PLAN;
  payload: null;
}

export interface State {
  data: PlanDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions = GetPlan | GetPlanSuccess | GetPlanError | ResetGetPlan;
