import styled from "styled-components";

const makeVariation = ({ theme, skin, state }) => {
  return `
    border-color: ${theme.form.skin[skin][state].borderColor};
    background-color: ${theme.form.skin[skin][state].backgroundColor};
    

    textarea,
    input {
      color: ${theme.form.skin[skin][state].color};
    }

    textarea::-webkit-input-placeholder {
      color: #c1c1c1;
    }
    
    textarea:-moz-placeholder { /* Firefox 18- */
      color: #c1c1c1;  
    }
    
    textarea::-moz-placeholder {  /* Firefox 19+ */
      color: #c1c1c1;  
    }
    
    textarea:-ms-input-placeholder {
      color: #c1c1c1;  
    }
    
    textarea::placeholder {
      color: #c1c1c1;  
    }

    
    input::-webkit-input-placeholder {
      color: #c1c1c1;
    }
    
    input:-moz-placeholder { /* Firefox 18- */
      color: #c1c1c1;  
    }
    
    input::-moz-placeholder {  /* Firefox 19+ */
      color: #c1c1c1;  
    }
    
    input:-ms-input-placeholder {
      color: #c1c1c1;  
    }
    
    input::placeholder {
      color: #c1c1c1;  
    }


    .select__control {
      color: ${theme.form.skin[skin][state].color}!important;

      .select__placeholder{
        color: #c1c1c1!important;
      }

      & > i {
        &:before{
          background-color: ${theme.form.skin[skin][state].iconColor}!important;
        }
      }
    }

    .field-before,
    .field-after{
      i {
        &:before{
          background-color: ${theme.form.skin[skin][state].iconColor}!important;
        }
      }
    }

    .select__indicators{
      i {
        &:before{
          background-color: ${theme.form.skin[skin][state].iconColor}!important;
        }
      }
    }`;
};

export const FieldContainer = styled("div")<{ marginBottom? }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: ${(props) =>
    props.marginBottom >= 0 ? props.marginBottom : 24}px;
`;

export const Field = styled("div")<{ skin; size? }>`
  ${(props) => {
    const { theme, skin = "base", size = "md" } = props;
    return `
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: all 0.3s ease-in-out;
    padding-left: ${theme.form.size[size].padding};
    padding-right: ${theme.form.size[size].padding};
    transition: all 150ms ease-in-out;
    border: 1px solid;
    border-radius: 16px;

    ${makeVariation({ theme, skin, state: "idle" })}
  
    &:disabled,
    &:hover,
    &:focus,
    &:active {
      outline: 0;
    }
  
    &.with-error {
      ${makeVariation({ theme, skin, state: "error" })}
    }
  
    &.field-active {
      ${makeVariation({ theme, skin, state: "idle" })}
    }
  
    &.field-disabled{
      cursor: not-allowed;
      ${makeVariation({ theme, skin, state: "disabled" })}
    }`;
  }}
`;

export const Label = styled("label")<{ size }>`
  ${(props) => {
    const { theme, size = "md" } = props;
    return `
      font-size: ${theme.form.size[size].fontSize};
      line-height: ${theme.form.size[size].lineHeight};
      color: ${theme.colors.black84};
      margin-bottom: 4px;`;
  }}
`;

export const HelperLine = styled("div")<{ marginBottom }>`
  line-height: 16px;
  // position: absolute;
  // top: calc(
  //   100% - ${(props) => (!!props.marginBottom ? props.marginBottom : 24)}px +
  //     2px
  // );
  // left: 0px;
  // width: 100%;
  padding: 0px 20px;
  min-height: 15px;
`;

export const HelperText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: rgba(36, 40, 44, 0.76);
`;

const fieldBeforeAndAfter = `
  min-width: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;`;

export const FieldBefore = styled("div")<{ size }>`
  ${(props) => {
    const { theme, size = "md" } = props;

    return `
      ${fieldBeforeAndAfter}
      margin-right: ${theme.form.size[size].padding};`;
  }}
`;

export const FieldAfter = styled("div")<{ size }>`
  ${(props) => {
    const { theme, size = "md" } = props;

    return `
      ${fieldBeforeAndAfter}
      margin-left: ${theme.form.size[size].padding};`;
  }}
`;

export const CharacterCounter = styled("span")<{ errors }>`
  ${(props) => {
    const { theme, errors } = props;

    return `
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
      text-align: right;
      position: absolute;
      right: 0px;
      bottom: 0px;
      color: ${theme.colors.black84};`;
  }}
`;

export const ErrorMessage = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: ${(props) => props.theme.colors.Danger};
`;

export const TooglePassword = styled.button`
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  background: none;
  box-shadow: none;
  height: 24px;
`;

export const Required = styled.sup`
  color: red;
`;
