import styled from 'styled-components'

export const Title = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin: 20px 0px 24px;
  Line height: 24px;

  @media( min-width: ${props => props.theme.mediaBreakpoints.smMin} ){
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    margin: 59px 0px 32px;
    Line height: 43px;
    text-align: left;
  }
`
