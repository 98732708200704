import { connect, useDispatch } from "react-redux";
import Button from "../../../../../components/Button";
import { getGroupType } from "../../../../../helpers/getGroupType";
import { isSubscribed } from "../../../../../helpers/isSubscribed";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import { showModal } from "../../../../../store/actions/modal";
import { Group } from "../../../../../types";
import ModalShareLink from "./components/ModalShareLink";

interface ComponentProps {
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  subscriptions;
}

const Component = ({ group, groupStates, subscriptions }: ComponentProps) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  return (
    <>
      <Button
        onClick={() => {
          const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.slug}`;
          navigator.clipboard.writeText(link);
          dispatch(showModal("modal-share-group-link"));
        }}
        disabled={groupStates.loading}
        loading={groupStates.loading}
        options={{
          type: "filled",
          size: "lg",
          skin: "white",
          block: isMobile,
        }}
      >
        Compartir {getGroupType({ group })}
      </Button>

      <ModalShareLink />
    </>
  );
};

const state = ({ groupStore, userStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: subscriptions } = userStore.subscriptions;

  return {
    group,
    groupStates,
    subscriptions,
  };
};

export default connect(state)(Component);
