import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import closeIcon from "../../assets/icons/close.svg";
import { RecordSettingContainer, Close } from "./styles";
import { savePreference } from "./../../store/actions/preferences";
import { Preference } from "../../types/preferences";
import slugify from "slugify";
import { showPreference } from "../../helpers/preferenceVisible";

interface ComponentProps {
  preferences: Preference[];
  preferencesStates;
  id: string | number;
  visible: boolean;
  canHide?: boolean;
  children: any;
}

const Component = ({
  preferences,
  preferencesStates,
  id,
  visible,
  canHide = true,
  children,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(visible);

  const handleCLose = () => {
    dispatch(
      savePreference({
        preferenceSlug: id,
        preferenceValue: false,
      })
    );

    setIsVisible(false);
  };

  useEffect(() => {
    setIsVisible(showPreference({ preferences, preferencesStates, id }));
  }, [preferences]);

  if (!isVisible) {
    return null;
  }

  return React.Children.map(children, (child) => {
    if (!child) {
      return null;
    }

    return !!id ? (
      <RecordSettingContainer key={slugify(String(id), { lower: true })}>
        {canHide && (
          <Close onClick={handleCLose}>
            <img src={closeIcon} alt="X" width="16px" height="16px" />
          </Close>
        )}

        {React.createElement(child.type, {
          ...{
            ...child.props,
            saveSetting: handleCLose,
            key: id,
          },
        })}
      </RecordSettingContainer>
    ) : (
      child
    );
  });
};

const states = ({ preferencesStore }) => {
  const { data: preferences, states: preferencesStates } =
    preferencesStore.preferences;

  return {
    preferences,
    preferencesStates,
  };
};

export default connect(states)(Component);
