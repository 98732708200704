import monetizedMembers from "../assets/statistics/monetized-members.svg";
import communityMembers from "../assets/statistics/community-members.svg";
import activeMembers from "../assets/statistics/active-members.svg";

const page = {
  statistics: [
    {
      type: "community",
      image: communityMembers,
      title: "Total de personas en tu Audiencia",
      emptyState: {
        text: "Aún no tienes suscriptores. Crea contenidos de valor e invita a tu audiencia para sumar suscriptores a tu comunidad.",
        cta: {
          label: "Crear contenido gratuito",
        },
      },
    },
    {
      type: "monetized",
      image: monetizedMembers,
      title: "Personas de tu audiencia que compraron algun producto",
      emptyState: {
        text: "Aún no has creado talleres. Crea un taller (cómo una masterclass o un desafío) para comenzar.",
        cta: {
          label: "Crear taller",
          href: "/dashboard/experience/new",
        },
      },
    },
    {
      type: "active_members",
      image: activeMembers,
      title: "Personas de tu audiencia activas en tu membresía",
      emptyState: {
        text: "Aún no tienes una. Crea una membresía e invita a toda tu comunidad a ser parte de un grupo exclusivo.",
        cta: {
          label: "Crear membresía",
          href: "/dashboard/membership/new",
        },
      },
    },
  ],
};

export default page;
