import {
  initialState,
  Actions,
  State,
  GET_COMMUNITY_METRICS,
  GET_COMMUNITY_METRICS_SUCCESS,
  GET_COMMUNITY_METRICS_ERROR,
} from "../../types/community/metrics";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_COMMUNITY_METRICS:
      return {
        ...state,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case GET_COMMUNITY_METRICS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case GET_COMMUNITY_METRICS_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default reducers;
