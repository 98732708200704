import SessionCard from "../../../../Dashboard/Sessions/components/SessionCard";
import { Separator } from "../../../../../components/StyledComponents";
import {
  Title,
  SessionCardSkeleton,
  CardsGrid
} from "./styles";

import { SessionInDto } from "../../../../../types";
import { sessionExpired, sessionExpiredWithMargin, convertFloatHourToMinutes } from "../../../../../helpers/dates-hanlder";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { useMemo } from "react";
import { filter } from "lodash";


interface ComponentProps {
  sessions: SessionInDto[];
  sessionsStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

const Component = ({ sessions, sessionsStates }: ComponentProps) => {

  const filteredSessions = useMemo(() => {
    if (!sessions)
      return []

    const expiredSessions = sessions?.filter((session) => {
      let durationInMinutes = session?.duration_type === "hour" ? convertFloatHourToMinutes(session?.duration) : session?.duration
      return sessionExpiredWithMargin(session?.session_datetime, durationInMinutes) && (!!session?.will_be_recorded || !!session?.recorded_session_url)
    })

    expiredSessions.sort((a, b) => new Date(a?.session_datetime).getTime() - new Date(b?.session_datetime).getTime())
    expiredSessions.reverse()

    return expiredSessions.slice(2)
  }, [sessions])

  return (
    <>
      {(!sessions && !sessionsStates.loading) ||
        ((filteredSessions?.length <= 0 ||
          sessionsStates.error) &&
          !sessionsStates.loading) ? (
        <></>
      ) : (
        <>
          <Title>Todos los Grabados</Title>

          <div style={{ height: 6 }} />

          <CardsGrid >
            {sessionsStates.loading && (
              <>
                <SessionCardSkeleton />
                <SessionCardSkeleton />
                <SessionCardSkeleton />
              </>
            )}

            {!sessionsStates.loading &&
              filteredSessions?.map((session) => (
                <SessionCard
                  groupInformation={session.group}
                  key={`session-${session.id}`}
                  session={session}
                  link={`/group/${session?.group}/s/${session.id}`}
                  expired
                />
              ))}
          </CardsGrid>
        </>
      )}
    </>
  );
};

const state = ({ sessionStore }) => {
  const { data: sessions, states: sessionsStates } = sessionStore.all;

  return {
    sessions,
    sessionsStates,
  };
};

export default connect(state)(Component);
