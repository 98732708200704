import styled from "styled-components";

export const About = styled("section")`
  min-height: 100vh;
  padding: 100px 0px;
`;

export const Title = styled("h1")`
  margin: 0px 0px 30px;
  font-size: 48px;
  line-height: 1;
  text-align: center;
`;

export const SubTitle = styled("p")`
  margin: 0px auto 30px;
  font-size: 16px;
  max-width: 500px;
  text-align: center;
`;

export const Img = styled("img")`
  width: 100%;
  height: 100%;
  background-color: gray;
`;
