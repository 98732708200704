import styled from "styled-components";
import Title from "./../../../components/Title";

export const FormTitle = styled(Title)`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 36px;
  }
`;

export const Explanation = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 36px;

  & > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const UsernameReqsText = styled("p")`
  text-align: center;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.theme.colors.Black32};
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0px 30px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: 72px 0px 50px;
  }
`;
