import { Tabs, Tab, Link, HorizontalScroll, NewFeatureLabel } from "../../../components/styles";

import Grid from "../../../../../components/Grid";
import { connect } from "react-redux";

const Component = ({ groupInformation, active }) => {
  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <HorizontalScroll>
            <Tabs>
              <Tab>
                <Link
                  to={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}`}
                  active={active === "home"}
                >
                  Panel general
                </Link>
              </Tab>
              <Tab>
                <Link
                  to={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/posts/`}
                  active={active === "library"}
                >
                  Biblioteca
                </Link>
              </Tab>
              <Tab>
                <Link
                  to={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/sessions/`}
                  active={active === "sessions"}
                >
                  Encuentros
                </Link>
              </Tab>
              <Tab>
                <Link
                  to={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/members`}
                  active={active === "members"}
                >
                  Miembros
                </Link>
              </Tab>
              <Tab>
                <Link
                  to={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/surveys`}
                  active={active === "surveys"}
                >
                  Encuestas
                </Link>
              </Tab>
              <Tab>
                <Link
                  to={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/price`}
                  active={active === "price"}
                >
                  Precio
                </Link>
              </Tab>
 
              <Tab>
                  <Link
                    to={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/configuration`}
                    active={active === "configuration"}
                  >
                    Configuración
                    <NewFeatureLabel>¡Nuevo!</NewFeatureLabel>
                  </Link>
              </Tab>
            </Tabs>
          </HorizontalScroll>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

const state = ({ groupStore }) => {
  const { group } = groupStore;
  const { data: groupInformation } = group;

  return {
    groupInformation,
  };
};

export default connect(state)(Component);
