import { Label, StepNumber } from "../styles";
import TipCta from "../TipCta";
import Input from "../../../../components/Form/Input";
import { Text } from "./styles";
import {Explanation} from "../CoverPic/styles";

interface ComponentProps {
  onFocus;
  onBlur?;
  onChange?;
  fieldNumber: number;
  errors;
  register;
  labelForwardRef;
  fildName: any;
  fieldText?: any;
  holderName: any;
}

const Component = ({
  onFocus,
  onBlur,
  onChange,
  fieldNumber,
  errors,
  register,
  labelForwardRef,
  fildName,
  fieldText,
  holderName,
  ...rest
}: ComponentProps) => {
  const name = "group_name";

  const handleFocus = () => {
    onFocus(fieldNumber, name);
  };

  return (
    <>
      <Label>
        <Text>
          <StepNumber>1.</StepNumber>
          <span>{fildName}</span>
        </Text>

        {/*<TipCta
          htmlFor={name}
          forwardRef={labelForwardRef}
          onClick={handleFocus}
        />*/}
      </Label>

      <Explanation>{fieldText}</Explanation>

      <Input
        name={name}
        placeholder={holderName}
        options={{ marginBottom: 24 }}
        onFocus={handleFocus}
        onBlur={onBlur}
        register={register}
        rules={{
          required: true,
        }}
        errors={errors}
        {...rest}
      />
    </>
  );
};

export default Component;
