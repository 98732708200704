import { put, call, all, select, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NEW_POST,
  NEW_POST_SUCCESS,
  NEW_POST_ERROR,
} from "../types/post/new-post";

import {
  GET_POST,
  GET_POST_SUCCESS,
  GET_POST_ERROR,
  RESET_GET_POST,
} from "../types/post/get-post";

import {
  GET_POSTS,
  UPDATE_GET_POSTS,
  GET_POSTS_SUCCESS,
  GET_POSTS_ERROR,
  RESET_GET_POSTS,
  GetPosts,
} from "../types/post/get-posts";

import {
  DELETE_FILE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_ERROR,
} from "../types/post/delete-file";

import {
  DELETE_POST,
  DELETE_POST_SUCCESS,
  DELETE_POST_ERROR,
} from "../types/post/delete-post";

import {
  EDIT_POST,
  EDIT_POST_SUCCESS,
  EDIT_POST_ERROR,
} from "../types/post/edit-post";

import {
  SHARE_POST_IN_TELEGRAM,
  SHARE_POST_IN_TELEGRAM_ERROR,
  SHARE_POST_IN_TELEGRAM_SUCCESS,
} from "../types/post/share-post";

import {
  SET_FEATURED_POST,
  SET_FEATURED_POST_ERROR,
  SET_FEATURED_POST_SUCCESS,
} from "../types/post/set-featured-post";

import {
  GET_LAST_POSTS,
  GET_LAST_POSTS_ERROR,
  GET_LAST_POSTS_SUCCESS,
} from "../types/post/get-last-posts";

import {
  GET_SCHEDULED_POSTS,
  GET_SCHEDULED_POSTS_ERROR,
  GET_SCHEDULED_POSTS_SUCCESS,
  UPDATE_GET_SCHEDULED_POSTS
} from "../types/post/get-scheduled-posts";

import {
  GET_FEATURED_POSTS,
  UPDATE_GET_FEATURED_POSTS,
  GET_FEATURED_POSTS_ERROR,
  GET_FEATURED_POSTS_SUCCESS,
  GetFeaturedPosts,
} from "../types/post/get-featured-posts";

import {
  GET_ALL_CATEGORIES_SUCCESS
} from "../types/category/get-all-categories"

import {
  REORDER_FEATURED_POSTS,
  REORDER_FEATURED_POSTS_ERROR,
  REORDER_FEATURED_POSTS_SUCCESS
} from "../types/post/reorder-featured-posts"



import { GetPostsOutDto, Post } from "../../types/post";

const getUserIsLoggedIn = ({ userStore }) => userStore.isLoggedIn;
const getCurrentPosts = ({ postStore }) => postStore.listPosts.data;
const getCurrentCategories = ({ categoryStore }) => categoryStore.allCategories.data;
const getCurrentScheduledPosts = ({ postStore }) => postStore.scheduledPosts.data;
const getCurrentFeaturedPosts = ({ postStore }) => postStore.featuredPosts.data;
const getCurrentPost = ({ postStore }) => postStore.post.data;
const getGroupInformation = ({ groupStore }) => groupStore.group.data;

function* newPost({ payload }): any {
  try {
    const { response, error } = yield call(services.post.newPost, payload);

    if (error) {
      yield put({ type: NEW_POST_ERROR, payload: error });
    } else {
      // const currentPostList = yield select(getCurrentPosts);
      // let postsList: { data: Post[] } = currentPostList || { data: [] };

      // if (!!postsList?.data && postsList?.data?.length > 0) {
      //   postsList.data = [...postsList?.data, response]
      // } else {
      //   postsList["data"] = [response];
      // }

      yield all([
        put({ type: NEW_POST_SUCCESS, payload: response }),
        //put({ type: UPDATE_GET_POSTS, payload: postsList })
      ])
    }
  } catch (error) {
    yield put({
      type: NEW_POST_ERROR,
      payload: "Error inexperado al crear artículo.",
    });
  }
}

function* editPost({ payload }): any {
  const post = yield select(getCurrentPost);

  try {
    const { response, error } = yield call(services.post.editPost, payload);

    if (!!error) {
      yield put({ type: EDIT_POST_ERROR, payload: error });
    } else {
      yield all([
        put({ type: EDIT_POST_SUCCESS, payload: response }),
        put({
          type: GET_POST_SUCCESS,
          payload: { ...post, ...response, group: post.group },
        }),
      ]);
    }
  } catch (error) {
    yield put({
      type: EDIT_POST_ERROR,
      payload: "No se pudo editar el Articulo",
    });
  }
}

function* setFeaturedPost({ payload }): any {
  try {
    const { response, error } = yield call(
      services.post.setFeaturedPost,
      payload
    );

    if (error) {
      yield put({ type: SET_FEATURED_POST_ERROR, payload: error });
    } else {
      let featuredPostsList = yield select(getCurrentFeaturedPosts);
      let scheduledPostsList = yield select(getCurrentScheduledPosts);
      let postsList = yield select(getCurrentPosts);
      let currentCategories = yield select(getCurrentCategories);

      if (payload.pinned) {
        let post = postsList?.data?.filter((post) => post.id === payload.idPost)[0];
        //let scheduledPost = scheduledPostsList?.data?.filter((post) => post.id === payload.idPost)[0];

        if (!!post) {
          post["pinned"] = payload.pinned;
        }

        // if (scheduledPost) {
        //   scheduledPost["pinned"] = payload.pinned;
        // }

        if (!!featuredPostsList?.data) {
          featuredPostsList.data = [
            ...featuredPostsList?.data,
            ...(!!post ? [post] : []),
            // ...(!!scheduledPost ? [scheduledPost] : [])
          ];
        } else {
          featuredPostsList.data = [
            ...(!!post ? [post] : []),
            // ...(!!scheduledPost ? [scheduledPost] : [])
          ];
        }
      } else {
        featuredPostsList.data = featuredPostsList?.data.filter(
          (post) => post.id !== payload.idPost
        );
      }

      // if (!!postsList?.data) {
      //   postsList.data = postsList?.data?.map((post) => {
      //     if (post.id === payload.idPost) {
      //       return { ...post, pinned: payload.pinned }
      //     } else {
      //       return post
      //     }
      //   });
      // }

      if (!!scheduledPostsList?.data) {
        scheduledPostsList.data = scheduledPostsList?.data?.map((post) => {
          if (post.id === payload.idPost) {
            return { ...post, pinned: payload.pinned }
          } else {
            return post
          }
        });
      }

      if (!!currentCategories) {
        currentCategories = currentCategories?.map((category) => {
          return {
            ...category,
            last_posts: category?.last_posts?.map((post) => {
              if (post.id === payload.idPost) {
                return { ...post, pinned: payload.pinned }
              } else {
                return post
              }
            })
          }
        });
      }

      yield all([
        put({ type: SET_FEATURED_POST_SUCCESS, payload: response }),
        put({ type: UPDATE_GET_FEATURED_POSTS, payload: featuredPostsList }),
        put({ type: UPDATE_GET_SCHEDULED_POSTS, payload: scheduledPostsList }),
        put({ type: UPDATE_GET_POSTS, payload: postsList }),
        put({ type: GET_ALL_CATEGORIES_SUCCESS, payload: currentCategories })
      ]);
    }
  } catch (error) {
    yield put({
      type: SET_FEATURED_POST_ERROR,
      payload: "No se pudo pinear el Articulo",
    });
  }
}

function* deletePost({ payload }): any {
  const posts = yield select(getCurrentPosts);
  const newListPosts = !!posts
    ? posts.filter((post) => post.id !== payload)
    : [];

  try {
    const result = yield call(services.post.deletePost, payload);
    yield all([
      put({ type: DELETE_POST_SUCCESS, payload: result }),
      put({ type: GET_POSTS_SUCCESS, payload: newListPosts }),
    ]);
  } catch (error) {
    yield put({ type: DELETE_POST_ERROR, payload: error });
  }
}

function* deleteFile({ payload }): any {
  try {
    const result = yield call(services.post.deleteFile, payload);
    yield put({ type: DELETE_FILE_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: DELETE_FILE_ERROR, payload: error });
  }
}

function* getPost({ payload }): any {
  const isLoggedIn = yield select(getUserIsLoggedIn);
  const anonymous_email = isLoggedIn ? null : localStorage.getItem("KLOUSER_SUBSCRIBER_EMAIL")
  const { response, error } = yield call(services.post.getPost, { ...payload, anonymous_email });

  if (error) {
    yield put({ type: GET_POST_ERROR, payload: error?.response?.status });
  } else {
    yield put({ type: GET_POST_SUCCESS, payload: response });
  }
}

function* getPosts({ payload }): any {
  const isLoggedIn = yield select(getUserIsLoggedIn);
  const anonymous_email = isLoggedIn ? null : localStorage.getItem("KLOUSER_SUBSCRIBER_EMAIL")
  const { response, error } = yield call(services.post.getPosts, { ...payload, anonymous_email });

  if (error) {
    yield put({ type: GET_POSTS_ERROR, payload: error.response.status });
  } else {
    yield put({
      type: GET_POSTS_SUCCESS,
      payload: response,
    });
  }
}

function* getFeaturedPosts({ payload }): any {
  const { response, error } = yield call(
    services.post.getPosts,
    { pagination: payload.pagination, filters: { ...payload.filters, pinned: true } })

  if (error) {
    yield put({
      type: GET_FEATURED_POSTS_ERROR,
      payload: error?.response?.status,
    });
  } else {
    yield put({
      type: GET_FEATURED_POSTS_SUCCESS,
      payload: response,
    });
  }
}

function* getLastPosts({ payload }): any {
  const { response, error } = yield call(
    services.post.getPosts,
    payload)

  if (error) {
    yield put({
      type: GET_LAST_POSTS_ERROR,
      payload: error?.response?.status,
    });
  } else {
    yield put({
      type: GET_LAST_POSTS_SUCCESS,
      payload: response,
    });
  }
}

function* getScheduledPosts({ payload }: GetFeaturedPosts): any {
  const { response, error } = yield call(
    services.post.getPosts,
    { pagination: payload.pagination, filters: { ...payload.filters, published_at_null: true } }
  );

  if (error) {
    yield put({
      type: GET_SCHEDULED_POSTS_ERROR,
      payload: error?.response?.status,
    });
  } else {
    yield put({
      type: GET_SCHEDULED_POSTS_SUCCESS,
      payload: response,
    });
  }
}

function* sharePostInTelegram({ payload }): any {
  try {
    const { response, error } = yield call(
      services.post.sharePostInTelegram,
      payload
    );

    if (error) {
      yield put({
        type: SHARE_POST_IN_TELEGRAM_ERROR,
        payload: error?.response?.status,
      });
    } else {
      yield put({
        type: SHARE_POST_IN_TELEGRAM_SUCCESS,
        payload: response.length > 0 ? response : null,
      });
    }
  } catch {
    yield put({
      type: SHARE_POST_IN_TELEGRAM_ERROR,
      payload: "Error al compartir post en Telegram",
    });
  }
}

function* reorderFeaturedPosts({ payload }): any {
  try {
    const { response, error } = yield call(services.post.reorderPosts, payload);
    if (error) {
      yield put({ type: REORDER_FEATURED_POSTS_ERROR, payload: error });
    } else {
      const group = yield select(getGroupInformation);

      yield all([
        put({ type: REORDER_FEATURED_POSTS_SUCCESS, payload: response }),
        put({
          type: GET_FEATURED_POSTS, payload: {
            pagination: { _sort: 'asc', _sort_by: 'pinned_order' },
            filters: { group_id: group?.id }
          }
        }),
      ]);
    }

  } catch (error) {
    yield put({ type: REORDER_FEATURED_POSTS_ERROR, payload: 'Error al reordenar los artículos' });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(NEW_POST, newPost);
  yield takeLatest<any>(EDIT_POST, editPost);
  yield takeLatest<any>(GET_POST, getPost);
  yield takeLatest<GetPosts>(GET_POSTS, getPosts);
  yield takeLatest<any>(DELETE_POST, deletePost);
  yield takeLatest<any>(DELETE_FILE, deleteFile);
  yield takeLatest<any>(SHARE_POST_IN_TELEGRAM, sharePostInTelegram);
  yield takeLatest<any>(SET_FEATURED_POST, setFeaturedPost);
  yield takeLatest<any>(GET_FEATURED_POSTS, getFeaturedPosts);
  yield takeLatest<any>(GET_SCHEDULED_POSTS, getScheduledPosts);
  yield takeLatest<any>(GET_LAST_POSTS, getLastPosts);
  yield takeLatest<any>(REORDER_FEATURED_POSTS, reorderFeaturedPosts);

}
