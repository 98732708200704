import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import constants from "../../../constants";
import Link from "../../../components/Link";
import { Error, ErrorMessage, Image } from "./styles";
import { getUrlParamByName } from "../../../helpers/get-url-param";
import { saveUserSubscription } from "../../../store/actions/subscription";
import creditCards from "./../../../assets/credit-cards.png";
import cross from "./../../../assets/icons/close.svg";
import image from "./../../../assets/lady-employee-doing-video-meeting.svg";
import { Layout, useLayout } from "../../../providers/LayoutProvider";

interface ComponentProps {
  subscription;
  subscriptionStates;
  userInformation;
}

const Component = ({
  subscriptionStates,
  subscription,
  userInformation,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const { whatsapp } = constants;
  const [message, setMessage] = useState(whatsapp.message);
  const [name, setName] = useState("");

  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "plain",
      footer: {
        visible: false,
      },
      header: {
        visible: true,
      },
      page: {
        title: "Error durante el pago",
        description: "Lo sentimos. Ocurrió un error durante el pago. Pero no te preocupes, estamos para ayudarte",
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      body: {
        visible: true,
      },
    }),
    [layout]
  );

  useEffect(() => {
    setLayout(layoutData);
  }, []);

  useEffect(() => {
    if (!!userInformation?.name) {
      setName(userInformation?.name);
      setMessage(whatsapp.checkout_failure);
    }
  }, [userInformation]);

  return (
    <Error>
      <div style={{ height: 90 }} />
      <h3 style={{ textAlign: 'center' }}>
        Lo sentimos. Ocurrió un error durante el pago. <br /> no te preocupes, estamos para ayudarte
      </h3>
      <div style={{ height: 30 }} />
      <img
        src={image}
        alt="Klouser"
        width="auto"
        height="314px"
      />
      <div style={{ height: 30 }} />
      <p style={{ textAlign: 'center' }}>
        Te pedimos que vuelvas a intentar. En caso de haberse ejecutado el pago de <br /> todas formas, clickeá en el botón debajo que enseguida te ayudamos a verificar <br /> que estes suscripto.
      </p>
      <div style={{ height: 30 }} />
      <Link
        href={`${constants.whatsapp.url}?phone=${whatsapp.number
          }&text=${message.replace("{name}", name)}`}
        options={{
          native: false,
          type: "filled",
          size: "lg",
          skin: "purple",
        }}
      >
        Contactar soporte
      </Link>
      <div style={{ height: 90 }} />
    </Error>
  );
};

const state = ({ subscriptionStore, userStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: subscription, states: subscriptionStates } =
    subscriptionStore.userSubscription;

  return {
    userInformation,
    subscription,
    subscriptionStates,
  };
};
export default connect(state)(Component);
