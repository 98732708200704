import * as Yup from "yup";

export const schema = Yup.object().shape({
  title: Yup.string().required("Ingresa el nombre del módulo"),
  content_type: Yup.string().required("Ingresa que tipo de módulo será"),
  is_valid: Yup.boolean()
});

interface FormValues {
  title: string;
  content_type: "post" | "note" | "survey" | "message";
  is_valid: boolean
}

export const initialValues: FormValues = {
  title: "",
  content_type: "post",
  is_valid: false
};
