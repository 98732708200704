import { useMemo } from 'react';
import { connect } from 'react-redux';
import { getRandomBg } from '../../../../../helpers/randomGroupCover';

import {
  BackgroundImage,
} from './styles'

const Component = ({ group }) => {
  const coverPicture = useMemo(() => {
    return group?.cover_pic?.signed_url || getRandomBg()
  }, [group])

  return (
    <div style={{ width: '100%', maxWidth: 400 }}>
      <BackgroundImage style={{ backgroundImage: `url(${coverPicture})`, backgroundSize: 'cover', backgroundPosition: 'center' }} />
    </div>
  );
};

const state = ({ groupStore }) => {
  const { data: group } = groupStore?.group;

  return {
    group,
  };
};

export default connect(state)(Component);
