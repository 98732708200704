import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
} from "react-router-dom";

import Tabs from "./components/Tabs";
import DashboardLayout from "../../../components/Layout/DashboardLayout";
import LogoLoader from "../../../components/LogoLoader";

import Edit from "./Edit";
import New from "./New";
import List from "./List";
import Preview from "./Preview";
import Members from "./Members";
import Group from "../Group";
import PostsList from "../Posts/List";
import PostsNew from "../Posts/New";
import PostsEdit from "../Posts/Edit";
import SessionList from "../Sessions/List";
import SessionNew from "../Sessions/New";
import SessionEdit from "../Sessions/Edit";
import Price from '../Price'
// import Category from '../Category'

import Configuration from "../Configuration";
import WelcomeContentPostNew from "../WelcomeContentPost/New";
import WelcomeContentPostEdit from "../WelcomeContentPost/Edit";
// import Category from '../Category'

import SurveyList from "../Survey/List";

import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Group as GroupDto } from "../../../types";

const Component = ({ groups }) => {
  const [membershipsGroups, setMembershipsGroups] = useState<
    GroupDto[] | null
  >();
  const { path } = useRouteMatch();

  useEffect(() => {
    if (!!groups) {
      setMembershipsGroups(
        groups.filter((group) => group?.group_type === "membership")
      );
    }
  }, [groups]);

  if (!groups) {
    return <LogoLoader />;
  }

  return (
    <Switch>
      <Route
        exact
        path={`${path}/`}
        render={() => <Redirect to={`${path}/list`} />}
      />
      <Route
        path={`${path}/list`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={1}>
            <List {...props} />
          </DashboardLayout>
        )}
      />
      {/* <Route
        exact
        path={`${path}/`}
        render={(props) => {
          if (
            membershipsGroups &&
            membershipsGroups?.length > 1 &&
            props.location.pathname !== `${path}/list`
          ) {
            return <Redirect to={`${path}/list`} />;
          }

          if (
            membershipsGroups &&
            membershipsGroups?.length === 1 &&
            props.location.pathname !== `${path}/${membershipsGroups[0].id}`
          ) {
            return <Redirect to={`${path}/${membershipsGroups[0].id}`} />;
          }

          if (
            membershipsGroups &&
            membershipsGroups?.length <= 0 &&
            props.location.pathname !== `${path}/new`
          ) {
            return <Redirect to={`${path}/new`} />;
          }
        }}
      /> */}

      {/* <Route
        exact
        path={`${path}/list`}
        render={(props) => {
          <DashboardLayout useLocation={useLocation} navigationLevel={1}>
            <List {...props} />
          </DashboardLayout>
          if (membershipsGroups && membershipsGroups?.length === 1) {
            return <Redirect to={`${path}/${membershipsGroups[0].id}`} />;
          }

          if (membershipsGroups && membershipsGroups?.length <= 0) {
            return <Redirect to={`${path}/new`} />;
          }
          return (
            <DashboardLayout useLocation={useLocation} navigationLevel={1}>
              <List {...props} />
            </DashboardLayout>
          );
        }}
      /> */}

      <Route
        exact
        path={`${path}/new`}
        render={(props) => {
          // if (membershipsGroups && membershipsGroups?.length === 1) {
          //   return <Redirect to={`${path}/${membershipsGroups[0].id}`} />;
          // }

          // if (membershipsGroups && membershipsGroups?.length > 1) {
          //   return <Redirect to={`${path}/list`} />;
          // }

          return (
            <DashboardLayout useLocation={useLocation} navigationLevel={2}>
              <New {...props} />
            </DashboardLayout>
          );
        }}
      />
      <Route
        exact
        path={`${path}/:idGroup`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <Preview {...props} />
          </DashboardLayout>
        )}
      />

      <Route
        exact
        path={`${path}/edit/:idGroup`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            {/* <Group {...props}> */}
            <Edit {...props} />
            {/* </Group> */}
          </DashboardLayout>
        )}
      />

      <Route
        path={`${path}/:idGroup/configuration`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <Configuration {...props}>
              <Tabs active="configuration" />
            </Configuration>
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/welcome-content/new`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <WelcomeContentPostNew {...props} />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/welcome-content/:idPost`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <WelcomeContentPostEdit {...props} />
          </DashboardLayout>
        )}
      />

      {/* 
      <Route
        path={`${path}/:idGroup/categories`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <Category {...props} />
          </DashboardLayout>
        )}
      /> */}

      <Route
        path={`${path}/:idGroup/members`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <Members {...props} />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/posts/`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <PostsList {...props}>
              <Tabs active="library" />
            </PostsList>
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/posts/new`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <PostsNew {...props} groupType="membership" />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/posts/:idPost`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <PostsEdit {...props} />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/sessions/`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <SessionList {...props}>
              <Tabs active="sessions" />
            </SessionList>
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/sessions/new`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <SessionNew {...props} />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/sessions/:session`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <SessionEdit {...props} />
          </DashboardLayout>
        )}
      />

      <Route
        exact
        path={`${path}/:idGroup/surveys/`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <SurveyList {...props}>
              <Tabs active="surveys" />
            </SurveyList>
          </DashboardLayout>
        )}
      />

      <Route
        exact
        path={`${path}/:idGroup/price/`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <Price {...props}>
              <Tabs active="price" />
            </Price>
          </DashboardLayout>
        )}
      />
      {/* <Route
        exact
        path={`${path}/:idGroup/survey/new`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation}>
            <SurveyNew {...props} />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/:idGroup/survey/:idSurvey`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation}>
            <SurveyEdit {...props} />
          </DashboardLayout>
        )}
      /> */}
      <Route exact path={`${path}/*`} render={() => <Redirect to={"/404"} />} />
    </Switch>
  );
};

const state = ({ userStore }) => {
  const { data: groups } = userStore.groups;
  return { groups };
};

export default connect(state)(Component);
