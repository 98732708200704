import { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Formik } from "formik";
import { Text } from "./styles";
import PostForm from "../components/PostForm";
import { initialValues, schema } from "../../../../constants/forms/post/new";
import { getUrlParamByName } from "../../../../helpers/get-url-param";
import Link from "../../../../components/Link";
import { ConnectedFocusError } from "../../../../components/ConnectedFocusError";
import Submit from "../../../../components/FormikForm/Submit";
import Grid from "../../../../components/Grid";
import Snackbar from "../../../../components/Snackbar";
import { getGroupById } from "../../../../store/actions/group";
import { newPost, resetNewPost } from "../../../../store/actions/post";
import { useLayout } from "../../../../providers/LayoutProvider";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { hideModal, showModal } from "../../../../store/actions/modal";
import { getFilesSize } from "../../../../helpers/filesSizes";
import { setToZeroTimeZone } from "../../../../helpers/dates-hanlder";
import { User } from "../../../../types";
import { newFile } from "../../../../store/actions/storage";
import ModalWaitAMinuteBis from './components/ModalWaitAMinute'
import { useFileUploadProgress } from "../../../../providers/FileUploader";
import services from "../../../../services";
import { getPostsFilePath } from '../../../../services/storage'
import { NewFileInDto } from "../../../../types/storage.dto";

interface ComponentProps {
  match;
  userInformation: User;
  newPostInformation;
  newPostStates;
  groupInformation;
  communityInformation;
  groupType: "membership" | "experience" | "community" | "free_experience";
}

const Component = ({
  match,
  userInformation,
  newPostInformation,
  newPostStates,
  groupInformation,
  communityInformation,
  groupType,
}: ComponentProps) => {
  const { uploadFiles, resetFileUploadProgress } = useFileUploadProgress();
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const history = useHistory();
  const { layout, setLayout } = useLayout();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [isFree, setIsFree] = useState<boolean>(false);

  const onSubmit = async ({ values, actions }) => {
    //Cuando migremos cover_pic podremos descomentar la linea de abajo
    //Debemos sumar la siguiente validacion "values.cover_pic?.some(file => file instanceof File"
    const thereAreFilesToUpload = values && values.attached_files?.some(file => file instanceof File);

    if (thereAreFilesToUpload) {
      dispatch(showModal("modal-wait-a-minute-bis"));
    }

    try {
      if (thereAreFilesToUpload) {
        const uploadedFiles = await uploadFiles(
          {
            //Cuando migremos cover_pic podremos descomentar la linea de abajo
            //...(values?.cover_pic && values?.cover_pic.length > 0 && { cover_pic: values?.cover_pic }),
            ...(values?.attached_files && values?.attached_files.length > 0 && { attached_files: values?.attached_files }),
          },
          userInformation.id,
          "post"
        );

        const updatedValues = {
          ...values,
          schedule_publish_at: values.schedule_publish_at ? setToZeroTimeZone(values.schedule_publish_at) : null,
          notificate_by_email: values.notificate_by_email.includes("true"),
          notificate_by_telegram: values.notificate_by_telegram.includes("true"),
          show_scheduled_post: values.show_scheduled_post.includes("true"),
          block_files_download: values.block_files_download.includes("true"),
          pinned: values.pinned.includes("true"),
          is_free: values.is_free.includes("true"),
          attached_files: uploadedFiles?.attached_files ? uploadedFiles?.attached_files : []
        };

        dispatch(newPost(updatedValues));
      } else {
        // If there are no files to upload, dispatch newWelcomeContentPost directly
        const updatedValues = {
          ...values,
          schedule_publish_at: values.schedule_publish_at ? setToZeroTimeZone(values.schedule_publish_at) : null,
          notificate_by_email: values.notificate_by_email.includes("true"),
          notificate_by_telegram: values.notificate_by_telegram.includes("true"),
          show_scheduled_post: values.show_scheduled_post.includes("true"),
          block_files_download: values.block_files_download.includes("true"),
          pinned: values.pinned.includes("true"),
          is_free: values.is_free.includes("true")
        };

        dispatch(newPost(updatedValues));
      }
    } catch (error) {
      console.error('Error during file uploads:', error);
    } finally {
      dispatch(hideModal());
    }
  };

  useEffect(() => {
    if (
      !!match.params &&
      (!groupInformation ||
        (!!groupInformation &&
          groupInformation?.id !== Number(match?.params?.idGroup)))
    ) {
      dispatch(getGroupById({ group_id: match.params.idGroup }));
    }
  }, [match]);

  useEffect(() => {
    if (!!newPostInformation && newPostStates.success) {
      setTimeout(() => {
        const groupId = groupInformation?.id;
        const postId = newPostInformation?.id;
        dispatch(resetNewPost());
        resetFileUploadProgress();
        dispatch(hideModal());
        history.push(`/group/${groupId}/p/${postId}?new=t`);
      }, 3500);
    }
  }, [newPostInformation, newPostStates]);

  useEffect(() => {
    setIsFree(
      getUrlParamByName("t") === "f" ||
      groupInformation?.group_type === "community"
    );
  }, [groupInformation]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title:
          groupInformation?.group_type === "community"
            ? "Crear contenido gratuito"
            : "Nuevo contenido",
        description: "Nuevo contenido",
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: true,
      },
    });
  }, [groupInformation]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title:
          groupInformation?.group_type === "community"
            ? "Crear contenido gratuito"
            : "Nuevo contenido",
        description: "Nuevo contenido",
        back: groupInformation?.group_type === "free_experience"
          ? `/dashboard/community/events/${groupInformation?.id}/posts`
          : `/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/posts`,
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: true,
        actions: [
          <Link
            options={{
              type: "outline",
              size: "lg",
              skin: "purple",
              native: true,
            }}
            href={groupInformation?.group_type === "free_experience"
              ? `/dashboard/community/events/${groupInformation?.id}/posts`
              : `/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/posts`}
          >
            Cancelar
          </Link>,
          <Submit
            isSubmmiting={newPostStates.loading}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            form="new-post-form"
          >
            Guardar artículo
          </Submit>,
        ],
      },
    });
  }, [groupInformation, newPostStates]);

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <Text>
              📝 Redacta tus artículos y sube documentos en pdf, imágenes, clases
              grabadas, videos y ¡hasta tus Reels de Instagram!
            </Text>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col md={7}>
            <Formik
              initialValues={{
                ...initialValues,
                notificate_by_email: ["true"],
                notificate_by_telegram: ["true"],
                is_free: isFree ? ["true"] : [],
                group:
                  groupType === "community"
                    ? communityInformation?.id
                    : groupInformation?.id,
              }}
              onSubmit={(values, actions) => {
                if (onSubmit) onSubmit({ values, actions });
              }}
              validateOnChange={false}
              validateOnBlur={formSubmmited}
              validationSchema={schema}
              enableReinitialize
            >
              {({ handleSubmit, ...rest }) => {
                return (
                  <form
                    className="theme-form"
                    onSubmit={(event) => {
                      setFormSubmmited(true);
                      handleSubmit(event);
                    }}
                    id="new-post-form"
                  >
                    <ConnectedFocusError />
                    <PostForm {...rest} />
                  </form>
                );
              }}
            </Formik>
          </Grid.Col>
        </Grid.Row>

        {!isMobile && (
          <Grid.Row>
            <Grid.Col>
              <Link
                style={{ marginRight: "8px" }}
                options={{
                  type: "outline",
                  size: "lg",
                  skin: "purple",
                  native: true,
                }}
                href={groupInformation?.group_type === "free_experience"
                  ? `/dashboard/community/events/${groupInformation?.id}/posts`
                  : `/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/posts`}

              >
                Cancelar
              </Link>

              <Submit
                isSubmmiting={newPostStates.loading}
                options={{
                  type: "filled",
                  size: "lg",
                  skin: "purple",
                }}
                form="new-post-form"
              >
                Guardar artículo
              </Submit>
            </Grid.Col>
          </Grid.Row>
        )}
      </Grid.Container>

      <ModalWaitAMinuteBis />

      <Snackbar
        visible={newPostStates.success || newPostStates.error}
        options={{
          time: 2000,
          type: newPostStates.success ? "success" : "error",
        }}
      >
        {newPostStates.success
          ? "Artículo creado."
          : "Error al crear el artículo."}
      </Snackbar>
    </>
  );
};

const state = ({ groupStore, postStore, userStore, communityStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: newPostInformation, states: newPostStates } = postStore.newPost;
  const { group } = groupStore;
  const { data: groupInformation } = group;
  const { data: communityInformation } = communityStore.community;

  return {
    newPostInformation,
    newPostStates,
    groupInformation,
    communityInformation,
    userInformation,
  };
};

export default connect(state)(Component);
