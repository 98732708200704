import Input from "../../../../../components/FormikForm/Input";
import RichTextEditor from "../../../../../components/FormikForm/RichTextEditor";
import { connect } from "react-redux";
import { Center } from "../../../../../components/StyledComponents";
import ReactSelect from "../../../../../components/FormikForm/ReactSelect";
import Switch from "../../../../../components/FormikForm/Switch";
import ButtonLink from "../../../../../components/Button";
import { useState, useEffect } from 'react'
import { isFutureDate } from "../../../../../helpers/dates-hanlder"

import {
  StepTitle,
  StepLabel,
} from '../SessionForm/styles'

const labelDurationType = {
  "hour": {
    "single": "hora",
    "plural": "horas"
  },
  "minute": {
    "single": "minuto",
    "plural": "minutos",
  },
  "day": {
    "single": "dia",
    "plural": "dias",
  }
}

const Component = ({
  values,
  errors,
  index,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  isEdit,
  isLastIndex,
  isMobile,
  isFutureSession
}) => {

  const [showCustomMsgField, setShowCustomMsgField] = useState<Boolean>(false)

  useEffect(() => {
    if (values && (values?.session_reminders_settings[index]?.type === "first_announcement" || values?.session_reminders_settings[index]?.type === "open_room")) {
      setShowCustomMsgField(true)
    }

    if(values?.session_reminders_settings[index]?.custom_message && values?.session_reminders_settings[index]?.custom_message !== ""){
        setShowCustomMsgField(true)
    }

  }, [values])

  useEffect(() => {
    if(values?.session_datetime && values?.session_reminders_settings[index]?.type === "open_room")
      setFieldValue(`session_reminders_settings.${index}.exec_datetime`, values?.session_datetime)
  }, [values?.session_datetime])


  setFieldValue(`session_reminders_settings.${index}.exec_time_before`, values?.session_reminders_settings[index]?.exec_time_before);
  setFieldValue(`session_reminders_settings.${index}.exec_time_before_unit`, values?.session_reminders_settings[index]?.exec_time_before_unit);

 
  let labelTitle;

  switch (values?.session_reminders_settings[index]?.type) {
    case "reminder":
      labelTitle = !!values?.session_reminders_settings[index]?.exec_time_before_unit && !!values?.session_reminders_settings[index]?.exec_time_before ? `Recordatorio ${values?.session_reminders_settings[index]?.exec_time_before} ${labelDurationType[values?.session_reminders_settings[index]?.exec_time_before_unit][values?.session_reminders_settings[index]?.exec_time_before > 1 ? "plural" : "single"]} antes del evento` : "-"
      break;
    case "open_room":
      labelTitle = `Recordatorio al abrir la Sala`
      break;
    case "first_announcement":
      labelTitle = "Cuándo quieres que se notifique la primera vez?"
      break;
    default:
      labelTitle = ""
  }


  return (
    <>
      {values?.session_reminders_settings && values?.session_reminders_settings[index]?.type === "first_announcement" ? (
        <>
          <StepTitle>
            <StepLabel>{labelTitle}</StepLabel>
          </StepTitle>
          <Center vertical="top" horizontal="left" gap={10} column={isMobile}>
            <ReactSelect
              name={`session_reminders_settings.${index}.execution_type`}
              error={errors?.session_reminders_settings ? errors?.session_reminders_settings[index]?.execution_type : null}
              touched={touched?.session_reminders_settings ? touched?.session_reminders_settings[index]?.execution_type : null}
              defaultValue={{ value: "now", label: "Al publicarse el encuentro" }}
              items={[{ label: "Al publicarse el encuentro", value: "now" }, { label: "En el futuro", value: "in_future" }]}
              disabled={isEdit}
              onChange={handleChange}
              onBlur={handleBlur}
              options={{
                marginBottom: 0
              }}
            />


            <Input
              name={`session_reminders_settings.${index}.exec_datetime`}
              error={errors?.session_reminders_settings ? errors?.session_reminders_settings[index]?.exec_datetime : null}
              touched={touched?.session_reminders_settings ? touched?.session_reminders_settings[index]?.exec_datetime : null}
              value={values?.session_reminders_settings[index]?.execution_type !== "in_future" ? "" : values?.session_reminders_settings[index]?.exec_datetime}
              onChange={handleChange}
              disabled={(!isEdit && values?.session_reminders_settings[index]?.execution_type !== "in_future") || (isEdit && !isFutureDate(values?.session_reminders_settings[index]?.exec_datetime))}
              onBlur={handleBlur}
              type="datetime-local"
              options={{
                marginBottom: 0
              }}
            />
          </Center>
        </>
      ) : (
        <Switch
          name={`session_reminders_settings.${index}.enabled`}
          error={errors?.session_reminders_settings ? errors[`session_reminders_settings`][index]?.enabled : null}
          touched={touched?.session_reminders_settings ? touched[`session_reminders_settings`][index]?.enabled : null}
          value={values?.session_reminders_settings[index]?.enabled}
          onChange={handleChange}
          disabled={!isFutureSession}
          onBlur={handleBlur}
          items={[
            {
              label: labelTitle,
              value: "true",
            }
          ]}
          options={{
            marginBottom: 20,
            //helperText: ``
          }}
        />
      )}


      {(!!values.session_reminders_settings[index] && !!values.session_reminders_settings[index].enabled &&
        values.session_reminders_settings[index]?.enabled?.includes('true')) && (
          <>
            {!showCustomMsgField && (
              <ButtonLink
                key={`session_reminders_settings.${index}.button`}
                onClick={() => setShowCustomMsgField(prevState => !prevState)}
                //disabled={!isFutureDate(values[`session_reminders_settings.${index}.exec_datetime`])}
                options={{
                  type: "link",
                  size: "sm",
                  skin: "purple",
                }}
              >
                Agrega un mensaje para esta notificación
              </ButtonLink>
            )}

            <div style={{ height: 20 }} />

            {!!showCustomMsgField && (
              <RichTextEditor
                key={`session_reminders_settings.${index}.custom_message`}
                name={`session_reminders_settings.${index}.custom_message`}
                error={errors?.session_reminders_settings ? errors?.session_reminders_settings[index]?.custom_message : null}
                touched={touched?.session_reminders_settings ? touched?.session_reminders_settings[index]?.custom_message : null}
                value={values['session_reminders_settings'][index]?.custom_message}
                placeholder=""
                showToolbar={false}
                disabled={isEdit && !isFutureSession}
                // disabled={isEdit && values?.session_reminders_settings[index]?.type !== "first_announcement" && (values?.session_reminders_settings[index]?.execution_type !== "in_future" || !isFutureDate(values?.session_reminders_settings[index]?.exec_datetime))}
                max={500}
                onBlur={handleBlur}
                onChange={({ isCountValid }) => {
                  if (!isCountValid) {
                    setFieldError(
                      `session_reminders_settings.${index}.exec_datetime`,
                      "Superaste la cantidad de caracteres máximos permitidos"
                    );
                  } else {
                    setFieldTouched(`session_reminders_settings.${index}.exec_datetime`, false, false);
                  }
                }}
                options={{
                  label: "Agrega un mensaje personalizado",
                  marginBottom: 20,
                }}
              />
            )}
          </>
        )}
    
      {!isLastIndex && (
        <div style={{ height: 40 }} />
      )}

    </>
  );
};

const state = ({ groupStore }) => {
  const { data: groupInformation } = groupStore.group;

  return {
    groupInformation,
  };
};

export default connect(state)(Component);
