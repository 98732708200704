import { CSSProperties, useEffect, useState } from "react";
import { useFormikFieldValidation } from "../../../hooks/useFormikFieldValidation";
import { FormikValues, useField } from "formik";
import {
  OptionContainer,
  Select,
  Input,
  CountryCode,
  CountryName,
} from "./styles";
import constants from "../../../constants";
import {
  getCountries,
  getCountryCallingCode,
  Country,
} from "react-phone-number-input";
import Flags from "react-phone-number-input/flags";
import en from "react-phone-number-input/locale/es.json";

import {
  FieldContainer,
  Field,
  Label,
  HelperLine,
  HelperText,
  ExplainText,
  ErrorMessage,
} from "../Field";

import { Center } from "../../StyledComponents";
import { constant } from "lodash";

const initialValue = {
  options: {
    skin: "base",
    size: "md",
  },
};

interface FlagDto {
  country: string;
  style: CSSProperties;
}

const Flag = ({ country, ...rest }: FlagDto) => {
  if (!country || !Flags[country]) {
    return <></>;
  }

  return Flags[country]({ title: "country", ...rest });
};

interface OptionDto {
  country: string;
  preffix: string;
  name: string;
  style: CSSProperties;
}

const Option = ({ country, preffix, name, style }: OptionDto) => {
  return (
    <OptionContainer>
      <Flag country={country} style={style} />
      {/* <CountryName>
        {countryConfig && countryConfig.length > 0 ? countryConfig[0].es : ""}
      </CountryName> */}
      <CountryCode>{country}</CountryCode> +{preffix}
    </OptionContainer>
  );
};

interface ComponentProps {
  values: FormikValues;
  disabled?: boolean;
  setFieldValue?;
  errors: {};
  touched?: {};
  style?: CSSProperties;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  forwardRef?;
  options: {
    label?: string;
    skin?: "base";
    size?: "md";
    helperText?: string;
    explainText?: string;
    marginBottom?: number;
    // showFlag?: boolean;
    // showCountryName?: boolean;
  };
}

const Component = ({
  forwardRef,
  options = {},
  setFieldValue,
  values,
  disabled,
  errors,
  touched,
  onFocus,
  onBlur,
  onChange,
  style,
  ...rest
}: ComponentProps) => {
  const error = errors["phone_number"] || errors["phone_area_code"];
  const { classes, setActive } = useFormikFieldValidation(
    error,
    "phone",
    disabled
  );

  const { skin, size, marginBottom } = {
    ...initialValue.options,
    ...options,
  };
  const [country, setCountry] = useState<Country | undefined>(undefined);

  const countriesCode = getCountries().map((code) => {

    const country = constants.countries.filter(
      (item) => item?.code === code
    )[0];

    const value = `${country?.es} - ${country?.code} - +${country?.dialCode}`;

    return {
      value: value,
      code: code,
      preffix: country?.dialCode,
      featured: country?.featured,
      label: (
        <Option
          country={code}
          preffix={country?.dialCode}
          name={en[code]}
          style={{ width: "20px" }}
        />
      ),
    };
  });

  const handleOnChange = (value) => {
    setFieldValue("phone_number", value);
  };

  const handleOnBlur = (event, callback) => {
    setActive(false);
    callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    setActive(true);
    callback && callback(event);
  };

  useEffect(() => {
    const currentCountry = getCountries().filter((country) => {
      return country === values["phone_area_code"];
    });

    setCountry(currentCountry[0]);
  }, [values]);

  return (
    <FieldContainer marginBottom={marginBottom} style={style}>
      {options?.label && (
        <Label
          size={options?.size}
          htmlFor="input-phone_number"
          style={!!options?.explainText ? { marginBottom: "0px" } : {}}
        >
          {options.label}
        </Label>
      )}

      {!!options?.explainText && (
        <ExplainText>{options?.explainText}</ExplainText>
      )}

      <Center horizontal="left" vertical="center" gap={10}>
        <Field
          skin={skin}
          size={size}
          className={classes}
          style={{ width: "250px" }}
        >
          <Select
            name="phone_area_code"
            variant="base"
            skin={skin}
            size={size}
            classNamePrefix="select"
            menuPlacement="auto"
            isDisabled={disabled}
            placeholder="País"
            isSearchable
            defaultValue={countriesCode[0]}
            value={countriesCode.filter((countryCode) => {
              return countryCode.code === values["phone_area_code"];
            })}
            onChange={({ code }) => {
              setFieldValue("phone_number", "");
              setFieldValue("phone_area_code", code);
              setCountry(code || undefined);
            }}
            autoComplete="off"
            autoCorrect="off"
            spellCheck="off"
            options={countriesCode.sort((a, b) => {
              if (a.featured && !b.featured) {
                return -1;
              }
              if (!a.featured && b.featured) {
                return 1;
              }
              return a.code.localeCompare(b.code);
            })}
          />
        </Field>

        <Field skin={skin} size={size} className={classes}>
          <Input
            variant="base"
            skin={skin}
            size={size}
            autoComplete="off"
            autoCorrect="off"
            spellCheck="off"
            name="phone_number"
            value={values["phone_number"]}
            onChange={(value) => handleOnChange(value)}
            onBlur={(event) => handleOnBlur(event, onBlur)}
            onFocus={(event) => handleOnFocus(event, onFocus)}
            placeholder="Teléfono"
            disabled={disabled}
            ref={forwardRef}
            withCountryCallingCode={false}
            country={country}
            {...rest}
          />
        </Field>
      </Center>

      {((options && options.helperText) || error) && (
        <HelperLine marginBottom={marginBottom}>
          {!!error && !!touched && <ErrorMessage>{error}</ErrorMessage>}
          {options && options.helperText && !error && (
            <HelperText>{options.helperText}</HelperText>
          )}
        </HelperLine>
      )}
    </FieldContainer>
  );
};

export default Component;
