import { GetWelcomeContentSectionInDto, GetWelcomeContentSectionOutDto, WelcomeContentSectionDto } from "../../../types/welcome-section";
export const GET_WELCOME_CONTENT_SECTION = "GET_WELCOME_CONTENT_SECTION";
export const GET_WELCOME_CONTENT_SECTION_SUCCESS = "GET_WELCOME_CONTENT_SECTION_SUCCESS";
export const GET_WELCOME_CONTENT_SECTION_ERROR = "GET_WELCOME_CONTENT_SECTION_ERROR";
export const UPDATE_CURRENT_WELCOME_SECTION = "UPDATE_CURRENT_WELCOME_SECTION";
export const RESET_GET_WELCOME_CONTENT_SECTION = "RESET_GET_WELCOME_CONTENT_SECTION";

export interface GetWelcomeContentSection {
  type: typeof GET_WELCOME_CONTENT_SECTION;
  payload: GetWelcomeContentSectionOutDto;
}

export interface GetWelcomeContentSectionSuccess {
  type: typeof GET_WELCOME_CONTENT_SECTION_SUCCESS;
  payload: GetWelcomeContentSectionInDto;
}

export interface GetWelcomeContentSectionError {
  type: typeof GET_WELCOME_CONTENT_SECTION_ERROR;
  payload: string | boolean;
}

export interface UpdateCurrentWelcomeContentSection {
  type: typeof UPDATE_CURRENT_WELCOME_SECTION;
  payload: GetWelcomeContentSectionInDto;
}

export interface ResetGetWelcomeContentSection {
  type: typeof RESET_GET_WELCOME_CONTENT_SECTION;
  payload: null;
}

export interface State {
  data: GetWelcomeContentSectionInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetWelcomeContentSection
  | GetWelcomeContentSectionSuccess
  | GetWelcomeContentSectionError
  | ResetGetWelcomeContentSection
  | UpdateCurrentWelcomeContentSection;
