import styled from "styled-components";
import LinkUi from "./../../components/Link";
import Image from "./../../components/Image"

export const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0px 0px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 90px 0px 0px;
  }
`;

export const Img = styled(Image)`
  display: flex;
  margin: 0px auto;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    width: 295px;
    height: 263px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    width: 420px;
    height: 374px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.lgMin}) {
    width: 590px;
    height: 526px;
  }
`;

export const Section = styled.div`
  padding: 0px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 35px 0px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 35px 50px;
  }
`;

export const Title = styled.h1`
  margin-top: 10px;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  margin-bottom: 28px;
`;

export const SubTitle = styled.h5`
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 18px;
  margin-top: 0px;
`;

export const Text = styled.p`
  font-weight: 200;
  font-size: 14px;
  font-style: italic;
  line-height: 19px;
  text-align: center;
  margin-bottom: 33px;
  margin-top: 0px;
  color: ${(props) => props.theme.colors.BlueLight};
`;

export const LinkItem = styled.div`
  justify-content: center;
  background-color: ${(props) => props.theme.colors.Primary};
  display: flex;
  margin: 0px auto;
  width: 69%;
  border-radius: 24px;
`;

export const Link = styled(LinkUi)`
  margin: auto 0px;
`;

