import { connect } from "react-redux";
import {
  ButtonsNext,
  ButtonsBack,
  CarouselContent,
  Slide,
  GlobalStyles,
} from "./styles";
import { Separator } from "../../../../../components/StyledComponents";
import { CarouselProvider, Slider } from "pure-react-carousel";
import { Group } from "../../../../../types";
import { Section, SectionName, Title, Text } from "../StyledComponents";
import { useEffect, useState } from "react";
import { Post } from "../../../../../types/post";
import CardArticle from "../../../../Dashboard/Posts/components/CardArticle";
import slugify from "slugify";
import { useWindowSize } from "../../../../../hooks/useWindowSize";

interface ComponentProps {
  group: Group;
  groupStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  posts: { data: Post[] };
  postsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ group, posts, postsStates }: ComponentProps) => {
  const { isDesktop } = useWindowSize();
  const [totalPosts, setTotalPosts] = useState(0);
  const [slideNo, setSlideNo] = useState(1);

  useEffect(() => {
    const indexOf = (slides, slide) => {
      for (const [currentSlide, index] of slides) {
        if (currentSlide === slide) {
          return index;
        }
      }
    };

    let slides: NodeListOf<Element> =
      document.querySelectorAll(".carousel-item");

    const change = (slide: Element) => {
      setSlideNo(indexOf(slides, slide) + 1);
    };

    slides.forEach((slide) =>
      slide.addEventListener("transitionstart", () => change(slide))
    );

    return () => {
      slides.forEach((slide) =>
        slide.removeEventListener("transitionstart", () => change(slide))
      );
    };
  }, []);

  useEffect(() => {
    if (!!posts) {
      setTotalPosts(posts?.data?.length);
    }
  }, [posts]);

  if (!posts || (!!posts && !!posts?.data && posts?.data?.length <= 0)) {
    return <></>;
  }

  return (
    <>
      <Separator height={{ desktop: "120px", mobile: "100px" }} />
      <Section>
        <GlobalStyles />
        <SectionName>¿tienes dudas sobre lo que recibirás?</SectionName>
        <Title>Una muestra de mi contenido</Title>

        <div style={{ height: "64px" }} />

        <CarouselProvider
          className="content-carousel"
          naturalSlideWidth={650}
          naturalSlideHeight={550}
          totalSlides={totalPosts}
          isIntrinsicHeight={true}
          visibleSlides={1}
          currentSlide={isDesktop ? (posts?.data?.length <= 3 ? 0 : 1) : 0}
        >
          <CarouselContent>
            {isDesktop && !!posts && posts?.data?.length > 1 && (
              <ButtonsBack>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="19"
                  viewBox="0 0 12 19"
                  fill="none"
                >
                  <path
                    opacity="0.5"
                    d="M9.42 0.219999L11.28 2.06L3.66 9.6L11.28 17.14L9.42 18.98L0.0199999 9.6L9.42 0.219999Z"
                    fill="#6C5CE7"
                  />
                </svg>
              </ButtonsBack>
            )}

            <Slider>
              {!!posts && !!posts?.data &&
                posts?.data?.length > 0 &&
                !postsStates.loading &&
                posts?.data?.map((post, index) => (
                  <Slide
                    index={index}
                    className={index === slideNo ? "current-slide" : ""}
                  >
                    <CardArticle
                      showActions={false}
                      post={post}
                      key={slugify(post.title + index, { lower: true })}
                      groupInformation={group}
                    />
                  </Slide>
                ))}
            </Slider>

            {isDesktop && !!posts && posts?.data && posts?.data?.length > 1 && (
              <ButtonsNext>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="19"
                  viewBox="0 0 12 19"
                  fill="none"
                >
                  <path
                    d="M2.58 18.78L0.72 16.94L8.34 9.4L0.72 1.86L2.58 0.0199981L11.98 9.4L2.58 18.78Z"
                    fill="#6C5CE7"
                  />
                </svg>
              </ButtonsNext>
            )}
          </CarouselContent>
        </CarouselProvider>
      </Section>
    </>
  );
};

const state = ({ groupStore, postStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: posts, states: postsStates } = postStore.listPosts;

  return {
    group,
    groupStates,
    posts,
    postsStates,
  };
};

export default connect(state)(Component);
