import styled from "styled-components";

export const Card = styled("div")`
  background-color: white;
  padding: 24px 26px;
  border-radius: 16px;
  overflow: auto;
`;

export const Scroll = styled("div")`
  overflow: auto;
`;

/**
 * Member list
 */

export const MembershipState = styled("span")<{}>`
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  align-items: center;
  color: rgba(45, 52, 54, 0.75);

  &.no-subscribed {
  }

  &.active {
    color: #12b47a;
  }

  &.paid {
    color: #12b47a;
  }

  &.paused {
    color: #FFEEE0;
  }

  &.pending_invite {
    color: #eca80d;
  }

  &.expired {
    color: #ca1931;
  }

  &.non_renewing {
    color: #db7a2c;
  }

  &.refunded {
  }

  &.cancelled {
    color: #ca1931;
  }

  &.defaulted {
    color: #ca1931;
  }
`;

export const Country = styled("span")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  padding-left: 7px;
`;

// export const Beginning = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: baseline;
// `;

// export const Title = styled.h1`
//   font-weight: 600;
//   font-size: 20px;
//   line-height: 24px;
//   margin: 0px 0px 24px 0px;

//   @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
//     font-weight: 600;
//     font-size: 36px;
//     line-height: 44px;
//     margin: 0px 0px 48px;
//   }
// `;

// export const TitleCard = styled.h1`
//   font-weight: 600;
//   font-size: 20px;
//   line-height: 24px;
//   margin: 0px 0px 20px;
//   text-align: center;

//   @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
//     margin: 0px 0px 36px;
//     text-align: left;
//   }
// `;

export const IconButton = styled("button")`
  margin: 0px;
  padding: 0px;
  border: 0px;
  background: none;
  cursor: pointer;
`;

export const IconLink = styled("a")`
  color: inherit;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  background: none;
  cursor: pointer;
`;
