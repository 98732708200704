export const DELETE_FILE = "DELETE_FILE";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_ERROR = "DELETE_FILE_ERROR";
export const RESET_DELETE_FILE = "RESET_DELETE_FILE";

export interface DeleteFile {
  type: typeof DELETE_FILE;
  payload: { idFile: number };
}

export interface DeleteFileSuccess {
  type: typeof DELETE_FILE_SUCCESS;
  payload: null;
}

export interface DeleteFileError {
  type: typeof DELETE_FILE_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteFile {
  type: typeof RESET_DELETE_FILE;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | DeleteFile
  | DeleteFileSuccess
  | DeleteFileError
  | ResetDeleteFile;
