import {
  Step,
  Information,
  H1,
  Text,
  CommunityImg,
  DivOne,
  DivTwo,
  Img,
} from "../styles";
import ImgStep from "../../../../../assets/home-page/community-interact.webp";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import Grid from "../../../../../components/Grid";
import Row from "../../../../../components/Grid/Row";
import Col from "../../../../../components/Grid/Col";

const Component = ({ visible }) => {
  const { isTabletLandscape, isDesktop } = useWindowSize();
  return (
    <Step {...(visible && { className: "active" })}>
      <Grid.Container>
        <Row>
          <Col offset={{ md: 1 }} md={10}>
            <Row>
              {!isDesktop && !isTabletLandscape && (
                <Col>
                  <CommunityImg>
                    <Img src={ImgStep} alt="" width="" height="" lazy={true} />
                  </CommunityImg>
                </Col>
              )}

              <Col md={6}>
                <Information>
                  <H1>Conecta de una forma más cercana con tu comunidad</H1>
                  <Text>
                    Logra una transformación a largo plazo a través de tu propia{" "}
                    <b>s privada</b> y afianza el vínculo con tu comunidad
                    mediante un <b>súper canal de Telégram</b> sincronizado con
                    tu Klouser.
                  </Text>
                </Information>
              </Col>

              <Col md={6}>
                {(isDesktop || isTabletLandscape) && (
                  <CommunityImg>
                    <Img src={ImgStep} alt="" width="" height="" lazy={true} />
                  </CommunityImg>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid.Container>
    </Step>
  );
};

export default Component;
