export const DELETE_PLAN = "DELETE_PLAN";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const DELETE_PLAN_ERROR = "DELETE_PLAN_ERROR";
export const RESET_DELETE_PLAN = "RESET_DELETE_PLAN";

export interface DeletePlan {
  type: typeof DELETE_PLAN;
  payload: { idPlan: number };
}

export interface DeletePlanSuccess {
  type: typeof DELETE_PLAN_SUCCESS;
  payload: null;
}

export interface DeletePlanError {
  type: typeof DELETE_PLAN_ERROR;
  payload: string | boolean;
}

export interface ResetDeletePlan {
  type: typeof RESET_DELETE_PLAN;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | DeletePlan
  | DeletePlanSuccess
  | DeletePlanError
  | ResetDeletePlan;
