import { SessionInDto, SessionOutDto } from "../../../types/session.dto";

export const NEW_SESSION = "NEW_SESSION";
export const NEW_SESSION_SUCCESS = "NEW_SESSION_SUCCESS";
export const NEW_SESSION_ERROR = "NEW_SESSION_ERROR";
export const RESET_NEW_SESSION = "RESET_NEW_SESSION";

export interface NewSession {
  type: typeof NEW_SESSION;
  payload: SessionOutDto;
}

export interface NewSessionSuccess {
  type: typeof NEW_SESSION_SUCCESS;
  payload: SessionInDto;
}

export interface NewSessionError {
  type: typeof NEW_SESSION_ERROR;
  payload: string | boolean;
}

export interface ResetNewSession {
  type: typeof RESET_NEW_SESSION;
  payload: null;
}

export interface State {
  data: SessionInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | NewSession
  | NewSessionSuccess
  | NewSessionError
  | ResetNewSession;
