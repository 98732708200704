export const GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS = "GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS";

export const GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_SUCCESS =
  "GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_SUCCESS";
export const GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_ERROR =
  "GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_ERROR";
export const RESET_GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_GROUP =
  "RESET_GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS";



export interface GetExpertTelegramBotForNotifications {
  type: typeof GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS;
  payload: null;
}

export interface GetExpertTelegramBotForNotificationsSuccess {
  type: typeof GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_SUCCESS;
  payload: string;
}

export interface GetExpertTelegramBotForNotificationsError {
  type: typeof GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_ERROR;
  payload: boolean | string;
}

export interface ResetGetExpertTelegramBotForNotifications {
  type: typeof RESET_GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_GROUP;
  payload: null;
}

export interface State {
  data: string | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetExpertTelegramBotForNotifications
  | GetExpertTelegramBotForNotificationsSuccess
  | GetExpertTelegramBotForNotificationsError
  | ResetGetExpertTelegramBotForNotifications;
