import { combineReducers } from "redux";
import newModule from "./new-module";
import updateModule from "./update-module";
import deleteModule from "./delete-module";
import selectedModule from "./selected-module";
import allModules from "./all-modules";
import reorderModules from "./reorder-modules";
import publishModules from "./publish-modules";
import restoreModules from "./restore-modules";

const reducer = combineReducers({
  selectedModule,
  allModules,
  newModule,
  updateModule,
  deleteModule,
  reorderModules,
  publishModules,
  restoreModules
});

export default reducer;
