import {
  SectionTitle,
  Title,
  Image,
  Statistics,
  Statistic,
  Value,
  SubValue,
  EmptyState,
  Link,
  Button,
  SubMetric,
  ComparisonBox,
  CurrencyText,
  PreviousPeriod,
  StatisticHeader,
} from "./styles";
import Grid from "../../../../components/Grid";
import { connect, useDispatch } from "react-redux";
import { Metric } from "../../../../types/community-metric";
import { useEffect } from "react";
import { getCommunityMetrics } from "../../../../store/actions/community";
import numberFormater from "../../../../helpers/numberFormater";
import {formatPrice} from "../../../../helpers/price-formatter";

interface ComponentProps {
  metrics: Metric[];
  metricsObj: {
    type: string;
    image: string;
    title: string;
    emptyState: {
      text: string;
      cta: {
        label: string;
        href?: string;
        onClick?: () => void;
      };
    };
  }[];
  showPreviousPeriod: boolean | null;
  previousMonthName?: string | null;
  actions?: {
    label?: string;
    href?: string;
    onClick?: () => void;
  }[];
}

const Component = ({ metricsObj, metrics, actions, previousMonthName, showPreviousPeriod = false}: ComponentProps) => {
  const dispatch = useDispatch();

  const getMetric = (type) => {
    if (!!metrics && metrics.length > 0) {

      const metric = metrics?.filter((metric) => metric.type === type);
      if (!!metric && metric.length > 0) {
        if (metric[0].currency)
          return <span><span style={{fontSize: "20px"}}>$</span>{metric[0].members_count || formatPrice(parseInt(metric[0].total.toString()))}<span style={{fontSize: "20px"}}>{metric[0].currency}</span> </span>;
        else return metric[0].members_count || metric[0].total
      }
    }

    return 0;
  };

  const getSubMetric = (type) => {
    if (!!metrics && metrics.length > 0) {
      const metric = metrics?.filter((metric) => metric.type === type);

      if(!metric[0]?.previous_period_total)
        metric[0].previous_period_total = 0

      if (!!metric && metric.length > 0) {
        return (
          <SubMetric>
            <div>
            <ComparisonBox
              isGreatherThanPreviousPeriod={metric[0].type === "membership_third_kpi" ? Number(metric[0].total || 0) <= Number(metric[0].previous_period_total || 0) : Number(metric[0].total || 0) >= Number(metric[0].previous_period_total || 0)}
            >
              {metric[0].previous_period_total === metric[0]?.total ? "" : Number(metric[0]?.previous_period_total || 0) > Number(metric[0]?.total) ? "↓" : "↑"}
              {/*metric[0].previous_period_total === metric[0]?.total ? "" : Number(metric[0]?.previous_period_total || 0) > Number(metric[0]?.total) ? metric[0].type === "membership_third_kpi" ? "↑" : "↓" : metric[0].type === "membership_third_kpi" ? "↓" : "↑"*/}
              {metric[0].previous_period_total === metric[0]?.total ? "0" : Number(metric[0]?.previous_period_total || 0) === 0 ? "∞"  : Math.abs(Math.floor((1-(Number(metric[0]?.total)/Number(metric[0]?.previous_period_total)))*100))}
              {" "}%
            </ComparisonBox>
            </div>
            <PreviousPeriod>vs. {previousMonthName}: <CurrencyText>{metric[0]?.currency ? "$" : ""}</CurrencyText>{metric[0]?.currency ? formatPrice(Number(metric[0]?.previous_period_total)) : metric[0]?.previous_period_total?.toString()} <CurrencyText>{metric[0]?.currency ? metric[0]?.currency : ""}</CurrencyText></PreviousPeriod>
          </SubMetric>
        )
      }
    }

    return <PreviousPeriod>vs. {previousMonthName}: 0</PreviousPeriod>;
  };

  const Action = ({ index, defaultAction }) => {
    const label =
      !!actions && !!actions[index]?.label
        ? actions[index]?.label
        : defaultAction?.label;

    const href =
      !!actions && !!actions[index]?.href
        ? actions[index]?.href
        : defaultAction?.href;

    const onClick =
      !!actions && !!actions[index]?.onClick
        ? actions[index]?.onClick
        : defaultAction?.onClick;

    return !!onClick ? (
      <Button type="button" onClick={() => onClick()}>
        {label}
      </Button>
    ) : (
      <Link to={href}>{label}</Link>
    );
  };

  useEffect(() => {
    dispatch(getCommunityMetrics());
  }, []);

  const validMetricValues = metricsObj.map(x => getMetric(x.type)).filter(y => y && Number(y) > 0)

  return (
    <Grid.Container>
      <Statistics>
        {metricsObj.map((metricOnj, index) => {
          return (
            <>
              <Statistic key={`metric-${metricOnj.type}`}>
                {validMetricValues.length <= 0 ? (
                  <>
                    <StatisticHeader>
                      <div>
                        <Image
                          src={metricOnj.image}
                          width="44px"
                          height="46px"
                        />
                        <Title>{metricOnj.title}</Title>
                      </div>
                    </StatisticHeader>

                    <EmptyState>
                      <p>{metricOnj.emptyState.text}</p>

                      <Action
                        index={index}
                        defaultAction={metricOnj.emptyState.cta}
                      />
                    </EmptyState>
                  </>
                ) : (
                  <>
                    <StatisticHeader>
                      <div>
                        <Image
                          src={metricOnj.image}
                          width="44px"
                          height="46px"
                        />
                        <Title>{metricOnj.title}</Title>
                      </div>
                    </StatisticHeader>
                    <Value>{getMetric(metricOnj.type)}</Value>
                    {showPreviousPeriod && (
                      <SubValue>{getSubMetric(metricOnj.type)}</SubValue>
                    )}
                  </>
                )}
              </Statistic>
            </>
          );
        })}
      </Statistics>
    </Grid.Container>
  );
};

export default Component;
