import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Button from "../../../../../components/Button";
import ReactSelect from "../../../../../components/FormikForm/ReactSelect";
import { getAllCategories } from "../../../../../store/actions/category";
import { showModal } from "../../../../../store/actions/modal";

import { Group } from "../../../../../types";
import { CategoryDto } from "../../../../../types/category";
import Category from '../../../Category'

interface ComponentProps {
  allCategories: CategoryDto[];
  allCategoriesState: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  group: Group
  name;
  error;
  touched;
  onChange;
  onBlur;
}

const Component = ({
  allCategories,
  allCategoriesState,
  group,
  ...rest
}: ComponentProps) => {
  const [createNewCategoryVisible, setCreateNewCategoryVisible] = useState<boolean>(false)
  const dispatch = useDispatch();

  const categories = useMemo(() => {
    return !!allCategories && allCategories.length > 0
      ? allCategories?.map((category) => ({
        label: category.name,
        value: category.id,
      }))
      : [];
  }, [allCategories]);

  useEffect(() => {
    if (group) {
      dispatch(getAllCategories({ group_id: group?.id, sort_by: 'category_order:asc' }));
    }
  }, [group]);

  return (
    <>
      <ReactSelect
        placeholder="Selecciona una o mas categorías"
        items={categories}
        isMulti
        isClearable
        options={{
          label: "Categoría",
          marginBottom: 40,
          loading: allCategoriesState.loading,
          noOptionsText: "No se encuentra la catategoria",
          helperText: (
            <>
              {" "}
              Asocialo a una o mas categorias para tener tus artículos
              organizados{" "}
              <Button
                onClick={() => setCreateNewCategoryVisible(!createNewCategoryVisible)}
                type="button"
                options={{
                  skin: "purple",
                  size: "md",
                  type: "link",
                }}
                style={{
                  margin: "5px 0px 0px 0px",
                  textDecoration: "underline",
                }}
              >
                Crear nueva categoría
              </Button>
            </>
          ),
        }}
        {...rest}
      />

      < Category showCategories={false} showCreateCategory={createNewCategoryVisible} onClose={() => { }} />
    </>
  );
};

const state = ({ categoryStore, groupStore }) => {
  const { data: allCategories, states: allCategoriesState } =
    categoryStore.allCategories;
  const { data: group } = groupStore.group;

  return {
    allCategories,
    allCategoriesState,
    group
  };
};

export default connect(state)(Component);
