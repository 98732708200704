import {
  NewNotificationOutDto,
  NewNotificationInDto,
} from "../../../types/notification";

export const NEW_NOTIFICATION = "NEW_NOTIFICATION";
export const NEW_NOTIFICATION_SUCCESS = "NEW_NOTIFICATION_SUCCESS";
export const NEW_NOTIFICATION_ERROR = "NEW_NOTIFICATION_ERROR";
export const RESET_NEW_NOTIFICATION = "RESET_NEW_NOTIFICATION";

export interface NewNotification {
  type: typeof NEW_NOTIFICATION;
  payload: NewNotificationOutDto;
}

export interface NewNotificationSuccess {
  type: typeof NEW_NOTIFICATION_SUCCESS;
  payload: NewNotificationInDto | null;
}

export interface NewNotificationError {
  type: typeof NEW_NOTIFICATION_ERROR;
  payload: boolean | string;
}

export interface ResetNewNotification {
  type: typeof RESET_NEW_NOTIFICATION;
  payload: null;
}

export interface State {
  data: NewNotificationInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | NewNotification
  | NewNotificationSuccess
  | NewNotificationError
  | ResetNewNotification;
