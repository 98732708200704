import constants from "../../constants";
import { Breadcrumb, Link } from "./styles";

interface ComponentProps {
  children: string;
}

const Component = ({ children, ...rest }: ComponentProps) => {
  return (
    <Breadcrumb {...rest}>
      <Link to={constants.urls.dashboard.home.url}>Home</Link>
      <span>{` > ${children}`}</span>
    </Breadcrumb>
  );
};

export default Component;
