import { RemirrorJSON } from "@remirror/core-types";
import {
  Callout,
  CodeBlock,
  createIFrameHandler,
  createLinkHandler,
  Doc,
  Heading,
  MarkMap,
  TextHandler,
} from "@remirror/react";
import { useReducer, useEffect } from "react";

interface State {
  raw: RemirrorJSON | "";
  jsonStr: RemirrorJSON | null;
  htmlStr: string | null;
  textStr: string | null;
  typeMap: MarkMap;
  markMap: MarkMap;
}

const initialState: State = {
  raw: "",
  jsonStr: null,
  htmlStr: null,
  textStr: null,
  typeMap: {
    blockquote: "blockquote",
    bulletList: "ul",
    callout: Callout,
    codeBlock: CodeBlock,
    doc: Doc,
    hardBreak: "br",
    heading: Heading,
    horizontalRule: "hr",
    iframe: createIFrameHandler(),
    image: "img",
    listItem: "li",
    paragraph: "p",
    orderedList: "ol",
    text: TextHandler,
  },
  markMap: {
    italic: "em",
    bold: "strong",
    code: "code",
    link: createLinkHandler({ target: "_blank" }),
    underline: "u",
    strike: "del",
  },
};

interface SetJsonStr {
  type: "JSON_BRIEF";
  payload: RemirrorJSON;
}

interface SetHtmlStr {
  type: "HTML_BRIEF";
  payload: string;
}

interface SetTextStr {
  type: "TEXT_BRIEF";
  payload: string;
}

interface SetRaw {
  type: "RAW";
  payload: RemirrorJSON;
}

type Actions = SetJsonStr | SetHtmlStr | SetTextStr | SetRaw;

const Reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case "JSON_BRIEF":
      return {
        ...state,
        jsonStr: action.payload,
      };
    case "HTML_BRIEF":
      return {
        ...state,
        htmlStr: action.payload,
      };
    case "TEXT_BRIEF":
      return {
        ...state,
        textStr: action.payload,
      };
    case "RAW":
      return {
        ...state,
        raw: action.payload,
      };
    default:
      return state;
  }
};

export const useMarkdown = ({ brief }: { brief: any }): State => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  const styleAsObj = (src) => {
    const target = src.content;

    if (!!target) {
      try {
        target.map((element) => {
          if (element.attrs.hasOwnProperty("style")) {
            element.attrs.style = {};
          }

          if (!!element.content) {
            return styleAsObj(element);
          } else {
            return element;
          }
        });
      } catch {
        return target;
      }
    }

    return src;
  };

  useEffect(() => {
    dispatch({ type: "RAW", payload: brief });

    if (!!brief) {
      try {
        const objBrief = JSON.parse(brief);

        if (objBrief.jsonStr) {
          dispatch({
            type: "JSON_BRIEF",
            payload: styleAsObj(JSON.parse(objBrief.jsonStr)),
          });
        }

        if (objBrief.htmlStr) {
          dispatch({ type: "HTML_BRIEF", payload: objBrief.htmlStr });
        }

        dispatch({ type: "TEXT_BRIEF", payload: objBrief.textStr });
      } catch (e) {
        dispatch({ type: "TEXT_BRIEF", payload: brief });
      }
    }
  }, [brief]);

  return { ...state };
};
