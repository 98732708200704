import { Intro, Title, SubTitle, Image } from './styles'
import constants from '../../../../../constants'

const Component = () => {
  return (
    <Intro>
      <Title>{constants.pageTelegram.title}</Title>
      <SubTitle>{constants.pageTelegram.subtitle}</SubTitle>
      <Image src={constants.pageTelegram.image}/>
    </Intro>)
}

export default Component