import { CategoryDto } from "../../../types/category";

export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_ERROR = "GET_CATEGORY_ERROR";

export interface GetCategory {
  type: typeof GET_CATEGORY;
  payload: CategoryDto;
}

export interface GetCategorySuccess {
  type: typeof GET_CATEGORY_SUCCESS;
  payload: CategoryDto | null;
}

export interface GetCategoryError {
  type: typeof GET_CATEGORY_ERROR;
  payload: boolean | string;
}

export interface State {
  data: CategoryDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions = GetCategory | GetCategorySuccess | GetCategoryError;
