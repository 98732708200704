import { connect, useDispatch } from "react-redux";
import { changeUserPassword } from "./../../../../../store/actions/user";
import Snackbar from "../../../../../components/Snackbar";
import { Data, Button, Section, UserPassword } from "../../styles";

const Component = ({ userPasswordStates, userData }) => {
  const dispatch = useDispatch();
  return (
    <>
      <Section>
        <Data>Contraseña</Data>
        <UserPassword>*************</UserPassword>
        <Button
          type="button"
          onClick={() => dispatch(changeUserPassword(userData?.email))}
          loading={false}
          options={{
            skin: "purple",
            size: "lg",
            type: "link",
          }}
        >
          Modificar
        </Button>
      </Section>

      <Snackbar
        visible={userPasswordStates.success || userPasswordStates.error}
        options={{
          time: 8000,
          type: userPasswordStates.success ? "success" : "error",
        }}
      >
        {/* {"auth/too-many-requests"} */}
        {userPasswordStates.success
          ? "Te enviamos un e-mail. Recuerda revisar SPAM/Correo no deseado."
          : "Error al enviar e-mail."}
      </Snackbar>
    </>
  );
};

const state = ({ userStore }) => {
  const { information, changeUserPassword, subscriptionsManagerUrl } =
    userStore;
  const { data: userData, states: userStates } = information;
  return {
    userData,
    userStates,
    userPasswordStates: changeUserPassword.states,
    subscriptionsManagerUrl,
  };
};

export default connect(state)(Component);
