import styled from 'styled-components'

export const StepTitle = styled.div`
  display: flex;
 margin-bottom: 16px;
`;

export const StepLabel = styled("div") <{ ref?; for?}>`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
`;

export const CurrencyLabel = styled('span')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`

export const LocalPrice = styled('div')`
  text-wrap: nowrap;
  width: 80%
`