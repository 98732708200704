import action from "../helpers/rest-client";

export class ErrorModule extends Error { }

class Modules {
  public async new(formFields) {
    const formData = new FormData();
    const data = {};

    for (let key in formFields) {
      const currentElement = formFields[key];
      if (key === "attached_files" && currentElement) {
        currentElement?.forEach(async (element) => {
          formData.append(key, element, element?.name);
        });
      } else {
        data[key] = currentElement;
      }
    }

    formData.append("data", JSON.stringify(data));

    return action
      .Post({
        url: `/api/module`,
        body: formData,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async update(formFields) {
    const fieldsThatCanBeUpdated = ['title', 'state', 'is_valid', 'content_data']
    const { id, ...rest } = formFields;

    const formData = new FormData();
    const data = {};

    for (let key in rest) {
      const currentElement = rest[key];

      if (key === "content_data" && currentElement) {
        data['content_data'] = {}

        for (const [contentDataCurrentElementKey, contentDataCurrentElementValue] of Object.entries(currentElement)) {
          if (contentDataCurrentElementKey === 'attached_files' && contentDataCurrentElementValue) {
            (contentDataCurrentElementValue as any[])?.forEach(async (element) => {
              if (element?.url) {
                data['content_data'][contentDataCurrentElementKey] = contentDataCurrentElementValue;
              } else {
                formData.append(contentDataCurrentElementKey, element, element?.name);
              }
            })
          } else {
            data['content_data'][contentDataCurrentElementKey] = contentDataCurrentElementValue;
          }

        }
      } else {
        if (fieldsThatCanBeUpdated.includes(key)) {
          data[key] = currentElement;
        }
      }
    }


    formData.append("data", JSON.stringify(data));

    return action
      .Put({
        url: `/api/module/content/${id}`,
        body: formData,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async delete({ id }) {
    return action
      .Del({
        url: `/api/module/${id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getOne({ id }) {
    return action
      .Get({
        url: `/api/module/${id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  }

  public async getAll({ idGroup, version }) {
    return action
      .Get({
        url: `/api/module?group_id=${idGroup}&version=${version}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async reorder(payload) {
    return action
      .Put({
        url: `/api/module/reorder`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async deleteFile({ idFile }) {
    return action
      .Del({
        url: `/api/module/file/${idFile}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async publishModules(payload) {
    return action
      .Post({
        url: "/api/module/publish",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error }
      });
  }

  public async restoreModules(payload) {
    return action
      .Post({
        url: "/api/module/restore",
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error }
      });
  }
}

const modules = new Modules();
export default modules;
