import { Formik, FormikHelpers } from "formik";
import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { schema } from '../../../../../../constants/forms/plan/edit'
import Sidebar from "../../../../../../components/Sidebar";
import PromotionCard from '../../PromotionSidebar/components/PromotionCard'
import {
  Preview,
  Title,
  Text,
  Option,
  StrongLabel,
  StrongValue,
} from "../../styles";
import PromotionSection from '../components/PromotionSection'
import Form from "../components/EditPlanForm"
import Button from "../../../../../../components/Button";
import Image from "../../../../../../components/Image";
import Submit from "../../../../../../components/FormikForm/Submit";
import { DiscountDto, EditPlanOutDto, Group, PlanDto } from "../../../../../../types";
import { Center } from "../../../../../../components/StyledComponents";
import flagArgentina from "../../../../../../constants/countries/flags/flag-AR.png"
import flagUnitedStates from "../../../../../../constants/countries/flags/flag-US.png"
import flagEuropeanUnion from "../../../../../../constants/countries/flags/flag-UE.png"
import { editPlan } from "../../../../../../store/actions/plan";
import { getAllDiscounts, selectedDiscount, updateDiscount } from "../../../../../../store/actions/discount";

const currencies = [
  {
    flag: flagArgentina,
    iso: "ARS",
    label: "AR$",
    description: "Pesos Argentinos (ARS)",
  },
  {
    flag: flagEuropeanUnion,
    iso: "EUR",
    label: "€",
    description: "Euros (EUR)",
  },
  {
    flag: flagUnitedStates,
    iso: "USD",
    label: "US$",
    description: "Dolares Americanos (USD)",
  },
];

const periodsUnits = [
  { value: "month", label: "Meses" },
  { value: "quarter", label: "Trimestres" },
  { value: "half-year", label: "Semestres" },
  { value: "year", label: "Años" }
]

const periodsUnitsTranslations = {
  "month": { singular: "mes", plural: "meses" },
  "quarter": { singular: "trimestre", plural: "trimestres" },
  "half-year": { singular: "semestre", plural: "semestres" },
  "year": { singular: "año", plural: "años" },
}

interface ComponentProps {
  isVisible: boolean
  onClose
  group: Group
  editedPlan,
  editedPlanStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  selectedPlan: PlanDto
  selectedPlanStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  discounts: DiscountDto[]
  discountsStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  updatedDiscount: DiscountDto
  updatedDiscountStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  setShowEditPromotionSidebar
  setShowNewPromotionSidebar
}

const Component = ({
  isVisible,
  onClose,
  group,
  editedPlan,
  editedPlanStates,
  selectedPlan,
  selectedPlanStates,
  discounts,
  discountsStates,
  updatedDiscount,
  updatedDiscountStates,
  setShowEditPromotionSidebar,
  setShowNewPromotionSidebar,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [thereIsDiscount, setThereIsDiscount] = useState(false);
  const [minPrice, setMinPrice] = useState(1);
  const [actions, setActions] = useState<FormikHelpers<any>>();

  const onSubmit = ({ values, actions }) => {
    const price = Number(values["price"]);
    const featured = selectedPlan.featured;
    const status = values["status"];
    const external_name = values["external_name"];

    const payload: EditPlanOutDto = {
      external_name,
      idPlan: selectedPlan?.id,
      price,
      old_subscribers_price: values["old_subscribers_price"] ? Number(values["old_subscribers_price"]) : null,
      featured,
      status,
    }

    dispatch(editPlan(payload))
    setActions(actions)
  }

  const planCurrency = useMemo(() => {
    const currency = currencies.find((item) => item.iso === selectedPlan?.currency)
    return (
      <Center horizontal="left" vertical="center" gap={8}>
        <Image src={currency?.flag} width="28px" height="20px" alt="" />
        {currency?.description}
      </Center>
    )
  }, [selectedPlan])

  const selectedPeriod = useMemo(() => {
    if (selectedPlan) {
      return periodsUnits?.find(period => period?.value === selectedPlan["period_unit"])
    }
  }, [selectedPlan])

  const payment = useMemo(() => {
    if (selectedPlan && selectedPeriod) {
      const cyclesSelected = selectedPlan["billing_cycles"];
      const billingCyclesSelected = selectedPlan["billing_cycles"] ? "cobro-tiempo-limitado" : "cobro-indefinido";
      const selectedPeriodUnit = periodsUnitsTranslations[selectedPeriod?.value]
      const selectedPeriodText = selectedPlan["period"] && selectedPlan["period"] > 1 ? `cada ${selectedPlan?.period} ${selectedPeriodUnit["plural"]}` : `por ${selectedPeriodUnit["singular"]}`;

      if (billingCyclesSelected === "cobro-indefinido") {
        return `Cobro por tiempo indefinido.`
      } else {
        return `Cobro ${selectedPeriodText}. Se realizaran ${cyclesSelected} cobros.`
      }
    } else {
      return `1 solo Cobro`
    }
  }, [selectedPlan, selectedPeriod])

  const onSelectPromotion = (discount: DiscountDto) => {
    dispatch(selectedDiscount(discount))
    setShowEditPromotionSidebar(true)
  }

  const onPausePromotion = (discount: DiscountDto) => {
    dispatch(updateDiscount({ ...discount, group_id: group?.id, status: 'archived' }))
    //dispatch(getAllDiscounts({ show_in_page: true, plan_id: selectedPlan.id }))
  }

  const onActivePromotion = (discount: DiscountDto) => {
    dispatch(updateDiscount({ ...discount, group_id: group?.id, status: 'active' }))
    //dispatch(getAllDiscounts({ show_in_page: true, plan_id: selectedPlan.id }))
  }

  useEffect(() => {
    if (!!selectedPlan) {
      dispatch(getAllDiscounts({ show_in_page: true, plan_id: selectedPlan.id }))
    }
  }, [selectedPlan])

  useEffect(() => {
    if (editedPlanStates.success && actions) {
      actions.resetForm();
    }
  }, [editedPlanStates, actions])

  useEffect(() => {
    if (discounts && discounts.length > 0 && discounts[0].status === "active") {
      setMinPrice(discounts && discounts.length > 0 ? discounts[0].amount + 1 : 1)
      setThereIsDiscount(discounts && discounts.length > 0)
    } else {
      setMinPrice(1)
      setThereIsDiscount(false)
    }
  }, [discounts])


  useEffect(() => {
    if (updatedDiscount?.status === "active") {
      setMinPrice(discounts && discounts.length > 0 ? discounts[0].amount + 1 : 1)
      setThereIsDiscount(discounts && discounts.length > 0)
    } else {
      setThereIsDiscount(false)
      setMinPrice(1)
    }
  }, [updatedDiscount])



  return (
    <>
      <Sidebar
        position="right"
        visible={isVisible}
        closeType="back"
        onClose={() => {
          !!onClose && onClose();
        }}
        width="520px"
        title="Editar precio"
      >
        <Preview>
          <div>
            <Text>
              Aqui puedes editar el nombre, monto y el estado del precio. Ademas puedes agregar o editar descuentos. Si buscas editar otras opciones, te recomendamos Crear un Nuevo Precio.
            </Text>

            <Option>
              <StrongLabel>Moneda</StrongLabel>
              <StrongValue>{planCurrency}</StrongValue>
            </Option>

            <Option>
              <StrongLabel>Forma de pago</StrongLabel>
              <StrongValue>{payment}</StrongValue>
            </Option>

            {selectedPlan?.subscriptions_count > 0 && (
              <Option>
                <StrongLabel>Suscriptores asociados a este Precio</StrongLabel>
                <StrongValue>{selectedPlan?.subscriptions_count} suscriptores</StrongValue>
              </Option>
            )}


            <Option>
              <Formik
                initialValues={{
                  external_name: selectedPlan?.external_name || "",
                  currency: selectedPlan?.currency,
                  type: selectedPlan?.type,
                  period_unit: selectedPlan?.period_unit || "month",
                  period: !selectedPlan?.period ? 1 : selectedPlan?.period,
                  billing_cycle_type: selectedPlan?.type === "charge" ? "cobro-tiempo-limitado" : "cobro-indefinido",
                  billing_cycles: !selectedPlan?.billing_cycles ? 1 : selectedPlan?.billing_cycles,
                  price: selectedPlan?.price,
                  apply_new_price: [],
                  old_subscribers_price: selectedPlan?.type === "subscription" && selectedPlan?.subscriptions_count > 0 ?
                    !selectedPlan?.old_subscribers_price ? selectedPlan?.price : selectedPlan?.old_subscribers_price
                    : null,
                  status: selectedPlan?.status
                }}
                onSubmit={(values, actions) => {
                  if (onSubmit) onSubmit({ values, actions });
                }}
                validateOnChange={formSubmmited}
                validateOnBlur={false}
                validationSchema={schema(minPrice, thereIsDiscount)}
                enableReinitialize
              >
                {(props) => {
                  return (
                    <form
                      className="theme-form"
                      onSubmit={(event) => {
                        setFormSubmmited(true);
                        props.handleSubmit(event);
                      }}
                      id="edit-plan-form"
                    >
                      <Form {...props} isLoading={editedPlanStates.loading} isEdit />
                    </form>
                  );
                }}
              </Formik>
            </Option>
          </div>

          <PromotionSection setShowNewPromotionSidebar={setShowNewPromotionSidebar} >
            {discounts && discounts?.length > 0 && (
              <PromotionCard
                plan={selectedPlan}
                discount={discounts[0]}
                onSelect={onSelectPromotion}
                onPause={onPausePromotion}
                onActive={onActivePromotion}
              />
            )}
          </PromotionSection>

          <div style={{ height: 40 }} />

          <Center horizontal="center" vertical="center" gap={10}>
            <Button
              options={{
                type: "common",
                skin: "purple",
                size: "lg",
                block: true
              }}
              onClick={() => !!onClose && onClose()}
            >
              Cancelar
            </Button>

            <Submit
              isSubmmiting={editedPlanStates.loading}
              disabled={editedPlanStates.loading}
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
                block: true
              }}
              form="edit-plan-form"
            >
              Guardar cambios
            </Submit>
          </Center>
        </Preview>
      </Sidebar>
    </>
  )
}

const state = ({ groupStore, planStore, discountStore }) => {
  const { data: group } = groupStore.group;
  const { data: editedPlan, states: editedPlanStates } = planStore.editPlan;
  const { data: selectedPlan, states: selectedPlanStates } = planStore.selectedPlan;
  const { data: discounts, states: discountsStates } = discountStore.allDiscounts;
  const { data: updatedDiscount, states: updatedDiscountStates } = discountStore.updateDiscount;


  return {
    group,
    editedPlan,
    editedPlanStates,
    selectedPlan,
    selectedPlanStates,
    discounts,
    discountsStates,
    updatedDiscount,
    updatedDiscountStates
  };
};

export default connect(state)(Component);
