import {
  Explanation,
  ImageWrapper,
  Denomination,
  Guide,
  Title,
} from "./styles";
import { Image } from "../../components/styles";
import { useWindowSize } from "../../../../hooks/useFormHook";
import Grid from "../../../../components/Grid";
import Row from "../../../../components/Grid/Row";
import Col from "../../../../components/Grid/Col";
import constants from "../../../../constants";
import Acordeon from "../../components/Acordeon";
import { useState } from "react";

const Component = () => {
  const [currentItem, setCurrentItem] = useState(0);
  const { isMobile } = useWindowSize();

  return (
    <Explanation>
      <Grid.Container {...(!isMobile && { gutter: 0 })}>
        <Row {...(!isMobile && { gutter: 0 })}>
          <Col {...(!isMobile && { gutter: 0 })}>
            <Row {...(!isMobile && { gutter: 0 })}>
              {!isMobile && (
                <Col sm={5} gutter={0}>
                  <ImageWrapper>
                    <Image
                      src={constants.homePage.create[currentItem].image}
                      alt=""
                      width=""
                      height=""
                      lazy={true}
                    />
                  </ImageWrapper>
                </Col>
              )}

              <Col offset={{ sm: 1 }} sm={5} {...(!isMobile && { gutter: 0 })}>
                <Denomination>
                  <Guide>CREA</Guide>
                  <Title>
                    Tu comunidad,
                    <br />
                    tus reglas
                  </Title>
                </Denomination>

                {isMobile && (
                  <ImageWrapper>
                    <Image
                      src={constants.homePage.create[currentItem].image}
                      alt=""
                      width=""
                      height=""
                      lazy={true}
                    />
                  </ImageWrapper>
                )}

                <Acordeon
                  onChange={setCurrentItem}
                  items={constants.homePage.create}
                  align={"right"}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid.Container>
    </Explanation>
  );
};

export default Component;
