import Helmet from "react-helmet";
import { connect, useDispatch } from "react-redux";
import Tabs from "../components/Tabs";
import {
  Title,
  RainbowSectionName,
  Separator,
} from "../../../components/StyledComponents";
import NoSubscribed from "../components/NoSubscribed";
import { Page } from "../components/styles";
import Grid from "../../../components/Grid";
import { useEffect, useMemo, useState } from "react";
import { getGroupBySlug } from "../../../store/actions/group";
import { resetGetPost } from "../../../store/actions/post";
import { GetPostsOutDto, Post } from "../../../types/post";
import Loader from "../../../components/LogoLoader";
import { Group, Subscription, User } from "../../../types";
import GridContent from "./components/GridContent";
import CarouselContent from "./components/CarouselContent";
import { Layout, useLayout } from "../../../providers/LayoutProvider";
import GoToTelegram from "../components/GoToTelegram";
import { isSubscribed } from "../../../helpers/isSubscribed";
import { Redirect } from "react-router-dom";
import { isCongreso } from "../../../helpers/isCongreso";
import { isCommunity } from "../../../helpers/isCommunity";
import { isOwnGroup } from "../../../helpers/isOwnGroup";
import TelegramBanner from "../ActivitySummary/components/TelegramBanner";
import { getFreeSubscriptions } from "../../../helpers/getFreeSubscriptions";
import WelcomeSection from "../components/WelcomeSection";
import { GetWelcomeContentPostInDto } from "../../../types/welcome-section";
import { getUserPreferences } from "../../../store/actions/preferences";
import MPVerifyingPaymentSection from "../components/MPVerifyingPaymentSection";
import MPDisapprovedPaymentSection from "../components/MPNewSubscribersDisapprovedPaymentSection";
import { useWindowSize } from "../../../hooks/useWindowSize";

const groupTypes = {
  membership: "Membresía",
  experience: "Taller",
  free_experience: "Evento Gratuito",
  community: "Comunidad",
};

interface ComponentProps {
  isLoggedIn: boolean;
  welcomeContentSection: GetWelcomeContentPostInDto
  subscriptions: Subscription[];
  user: User;
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  match;
  userPreferences: {
    id: number
    userId: string
    userEmail: string
    preferenceSlug: string
    preferenceValue: string
  }[]
}

const Component = ({ isLoggedIn, subscriptions, group, match, groupStates, user, userPreferences, welcomeContentSection }: ComponentProps) => {
  const dispatch = useDispatch();
  const { setLayout, layout } = useLayout();
  const { isMobile } = useWindowSize()

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(subscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [subscriptions, group, isLoggedIn])

  const showMinimized = useMemo(() => {
    if (!!userPreferences && !!group) {
      const slug = `minimize-welcome-section-${group?.welcome_section}`
      return userPreferences?.find(preference => preference.preferenceSlug === slug)
    }
  }, [userPreferences, group])

  const redirect = useMemo(() => {
    if (!!group) {
      return groupStates?.error || isCongreso(group?.id) || isCommunity(group?.group_type) || (!isOwnGroup({ group, expertId: user?.expert?.id }) && group?.status === 'draft')
    }

    return false
  }, [group, user])


  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "plain",
      footer: {
        visible: false,
      },
      header: {
        visible: true,
        beforeHeader: <><MPVerifyingPaymentSection /><MPDisapprovedPaymentSection /></>
      },
      toolbar: {
        visible: isOwnGroup({ group, expertId: user?.expert?.id })
      },
      body: {
        visible: true,
      },
    }),
    [layout, subscriptions, group, user]
  );

  useEffect(() => {
    dispatch(getUserPreferences())
  }, [])


  useEffect(() => {
    if (
      !!match?.params && !groupStates?.loading &&
      (!group?.slug || (!!group?.slug && group?.slug !== match?.params?.group))
    ) {
      dispatch(resetGetPost());
      match?.params && dispatch(getGroupBySlug(match?.params?.group));
    }
  }, [match]);

  useEffect(() => {
    setLayout(layoutData);
  }, [subscriptions, group, user]);

  if (!group) {
    return <Loader />;
  }

  if (redirect) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <Page>
        <Helmet>
          ‍<title></title>‍
          <meta name="description" content="" />
        </Helmet>

        <Grid.Container>
          <Grid.Row>
            <Grid.Col lg={10} offset={{ lg: 1 }}>
              {(group?.group_type === "free_experience" && (isUserSubscribed ||
                isOwnGroup({
                  group,
                  expertId: user?.expert?.id,
                }))) && (
                  <TelegramBanner />
                )}
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>

        {!showMinimized && (
          <Grid.Container>
            {isMobile && (<div style={{ height: 36 }} />)}
            <Grid.Row>
              <Grid.Col lg={10} offset={{ lg: 1 }}>
                <WelcomeSection minimized={true} showCloseButton={true} />
              </Grid.Col>
            </Grid.Row>
          </Grid.Container>
        )}

        <div style={{ height: 36 }} />

        {!!group && group?.library_template_view === 'all_posts' && <GridContent />}
        {!!group && group?.library_template_view === 'by_category' && <CarouselContent />}

      </Page>

      <NoSubscribed />
    </>
  );
};

const state = ({ userStore, postStore, groupStore, preferencesStore, welcomeContentStore }) => {
  const { isLoggedIn } = userStore;
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;
  const { data: user, states: userStates } =
    userStore.information;
  const { data: posts, states: postsStates } = postStore.listPosts;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: userPreferences } = preferencesStore.preferences;
  const { data: welcomeContentSection } = welcomeContentStore.welcomeContentSection;

  return {
    subscriptions,
    subscriptionsStates,
    user,
    userStates,
    posts,
    postsStates,
    group,
    groupStates,
    isLoggedIn,
    userPreferences,
    welcomeContentSection
  };
};

export default connect(state)(Component);
