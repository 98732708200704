import { NewWelcomeContentPostOutDto, NewWelcomeContentPostInDto } from "../../../types/welcome-section";
export const NEW_WELCOME_CONTENT_POST = "NEW_WELCOME_CONTENT_POST";
export const NEW_WELCOME_CONTENT_POST_SUCCESS = "NEW_WELCOME_CONTENT_POST_SUCCESS";
export const NEW_WELCOME_CONTENT_POST_ERROR = "NEW_WELCOME_CONTENT_POST_ERROR";
export const RESET_NEW_WELCOME_CONTENT_POST = "RESET_NEW_WELCOME_CONTENT_POST";

export interface NewWelcomeContentPost {
  type: typeof NEW_WELCOME_CONTENT_POST;
  payload: NewWelcomeContentPostOutDto
}

export interface NewWelcomeContentPostSuccess {
  type: typeof NEW_WELCOME_CONTENT_POST_SUCCESS;
  payload: NewWelcomeContentPostInDto
}

export interface NewWelcomeContentPostError {
  type: typeof NEW_WELCOME_CONTENT_POST_ERROR;
  payload: string
  | boolean;
}

export interface ResetNewWelcomeContentPost {
  type: typeof RESET_NEW_WELCOME_CONTENT_POST;
  payload: null;
}

export interface State {
  data: NewWelcomeContentPostInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | NewWelcomeContentPost
  | NewWelcomeContentPostSuccess
  | NewWelcomeContentPostError
  | ResetNewWelcomeContentPost
