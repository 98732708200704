import {
  initialState,
  Actions,
  State,
  USER_IS_LOGGEDIN,
  USER_IS_ALREADY_SUBSCRIBED,
  SAVE_CURRENT_GROUP,
  SAVE_USER_SUBSCRIPTION,
  SAVE_USER_SUBSCRIPTION_ERROR,
  SAVE_USER_SUBSCRIPTION_SUCCESS,
} from "../types/user/subscription";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SAVE_CURRENT_GROUP:
      return {
        ...state,
        group: action.payload,
      };
    case USER_IS_LOGGEDIN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case USER_IS_ALREADY_SUBSCRIBED:
      return {
        ...state,
        isAlreadySubscribed: action.payload,
      };
    case SAVE_USER_SUBSCRIPTION:
      return {
        ...state,
        userSubscription: {
          data: null,
          states: {
            success: false,
            loading: true,
            error: false,
          },
        },
      };
    case SAVE_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        userSubscription: {
          data: action.payload,
          states: {
            success: true,
            loading: false,
            error: false,
          },
        },
      };
    case SAVE_USER_SUBSCRIPTION_ERROR:
      return {
        ...state,
        userSubscription: {
          data: action.payload,
          states: {
            success: false,
            loading: false,
            error: true,
          },
        },
      };
    default:
      return state;
  }
};

export default reducers;
