import { DiscountDto } from "../../../../../types/discount";
import {
  Card,
  Percent,
  Code,
  Title,
  Valid,
  Divider,
  Span,
  IconWrapper,
} from "./styles";
import dateHelpers from "../../../../../helpers/dates-hanlder";
import Icon from "../../../../../components/Icon";
import Button from "../../../../../components/Button";

import membershipIcon from "../../../../../assets/dashboard-menu/membership.svg";
import experienceIcon from "../../../../../assets/dashboard-menu/one-time-payment.svg";

const icon = {
  membership: membershipIcon,
  experience: experienceIcon,
};

interface ComponentProps {
  coupon?: DiscountDto;
  onClick?: (coupon?: DiscountDto) => void;
  hidePreviewLink?: boolean;
}

const Component = ({
  onClick,
  coupon,
  hidePreviewLink = false,
}: ComponentProps) => {
  return (
    <Card style={{ opacity: coupon?.status === "archived" ? ".5" : "1" }}>
      <div>
        <Percent>{coupon?.amount}%</Percent>
        <Code>{coupon?.coupon_id}</Code>
      </div>

      <Divider />

      <IconWrapper>
        <Icon
          icon={icon[coupon?.group_type || ""]}
          color="#6C5CE7"
          size="36px"
        />
      </IconWrapper>

      <div>
        <Span>
          Cupón para{" "}
          {coupon?.group_type === "membership" ? "membresía" : "taller"}:
        </Span>
        <Title>{coupon?.group_name}</Title>

        <Valid>
          <span>Válido hasta:</span>{" "}
          {dateHelpers.getDateInLocalTimeZone(coupon?.valid_till)}
        </Valid>
      </div>

      {!hidePreviewLink && (
        <div>
          <Button
            style={{
              fontWeight: "normal",
            }}
            options={{
              type: "filled",
              size: "lg",
              skin: "lightGray",
              block: true,
            }}
            onClick={() => !!onClick && onClick(coupon)}
          >
            Ir a compartir cupón
          </Button>
        </div>
      )}
    </Card>
  );
};

export default Component;
