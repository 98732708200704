import { useMemo } from 'react'
import {
  Row,
  Card,
  GatewayComp,
  Description,
  Status,
  Platform
} from './styles'
import Image from '../../../../../../../components/Image'
import flagArgentina from '../../../../../../../constants/countries/flags/flag-AR.png'
import flagUnitedStates from '../../../../../../../constants/countries/flags/flag-US.png'
import flagEuropeanUnion from '../../../../../../../constants/countries/flags/flag-UE.png'
import mercadopagoLogo from '../../../../../../../assets/mercadopago-logo.svg'
import { Gateway } from '../../../../../../../types/gateway'
import WebhookURLCopy from '../../../GatewaySidebar/components/WebhookURLCopy'

const currencies = [
  {
    flag: flagArgentina,
    iso: "ARS",
    label: "AR$",
    description: "Pesos Argentinos (ARS)",
  },
  {
    flag: flagEuropeanUnion,
    iso: "EUR",
    label: "€",
    description: "Euros (EUR)",
  },
  {
    flag: flagUnitedStates,
    iso: "USD",
    label: "US$",
    description: "Dolares Americanos (USD)",
  },
];

const status = {
  expired: 'Oculto',
  inactive: 'Oculto',
  active: 'Visible',
  deleted: 'Dado de baja'
}

interface ComponentProps {
  gateway: Gateway
}

const Component = ({
  gateway,
}: ComponentProps) => {

  const gatewayCurrency = useMemo(() => (currencies.find(currency => currency?.iso === gateway?.currency)), [gateway])
  
  return (
    <Card>
      <GatewayComp>
        <Row>
          
          <Image src={mercadopagoLogo} width="48px" height="48px" preload={false} alt="" />
          <Description><div style={{minWidth: 100}}>{gateway?.name}</div>
          </Description>

          
          <Image src={gatewayCurrency?.flag} width="28px" height="20px" preload={false} alt="" />
          <Status status="active" />
        </Row>
        <Row>
          <Description>
            <WebhookURLCopy gateway={gateway} />
          </Description>
        </Row>
      </GatewayComp>
    </Card>
  )
}

export default Component
