import Toggle from './Toggle'
import List from './List'
import Item from './Item'
import Wrapper from './Wrapper'

const Components = {
  Wrapper,
  Toggle,
  List,
  Item
}

export default Components
