import { DeleteWelcomeContentPostOutDto, DeleteWelcomeContentPostInDto } from "../../../types/welcome-section";

export const DELETE_WELCOME_CONTENT_POST = "DELETE_WELCOME_CONTENT_POST";
export const DELETE_WELCOME_CONTENT_POST_SUCCESS = "DELETE_WELCOME_CONTENT_POST_SUCCESS";
export const DELETE_WELCOME_CONTENT_POST_ERROR = "DELETE_WELCOME_CONTENT_POST_ERROR";
export const RESET_DELETE_WELCOME_CONTENT_POST = "RESET_DELETE_WELCOME_CONTENT_POST";

export interface DeleteWelcomeContentPost {
  type: typeof DELETE_WELCOME_CONTENT_POST;
  payload: DeleteWelcomeContentPostOutDto;
}

export interface DeleteWelcomeContentPostSuccess {
  type: typeof DELETE_WELCOME_CONTENT_POST_SUCCESS;
  payload: DeleteWelcomeContentPostInDto;
}

export interface DeleteWelcomeContentPostError {
  type: typeof DELETE_WELCOME_CONTENT_POST_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteWelcomeContentPost {
  type: typeof RESET_DELETE_WELCOME_CONTENT_POST;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | DeleteWelcomeContentPost
  | DeleteWelcomeContentPostSuccess
  | DeleteWelcomeContentPostError
  | ResetDeleteWelcomeContentPost;
