import { GetFileInDto, GetFileOutDto } from "../../../types/storage.dto";

export const GET_FILE = "GET_FILE";
export const GET_FILE_SUCCESS = "GET_FILE_SUCCESS";
export const GET_FILE_ERROR = "GET_FILE_ERROR";
export const RESET_GET_FILE = "RESET_GET_FILE";

export interface GetFile {
  type: typeof GET_FILE;
  payload: GetFileOutDto;
}

export interface GetFileSuccess {
  type: typeof GET_FILE_SUCCESS;
  payload: GetFileInDto;
}

export interface GetFileError {
  type: typeof GET_FILE_ERROR;
  payload: string | boolean;
}

export interface ResetGetFile {
  type: typeof RESET_GET_FILE;
  payload: null;
}

export interface State {
  data: GetFileOutDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetFile
  | GetFileSuccess
  | GetFileError
  | ResetGetFile;
