import {
    initialState,
    Actions,
    State,
    CANCEL_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION_SUCCESS,
    CANCEL_SUBSCRIPTION_ERROR,
    RESET_CANCEL_SUBSCRIPTION,
  } from "../../types/subscription-admin/cancel-subscription";
  
  const reducers = (state = initialState, action: Actions): State => {
    switch (action.type) {
      case CANCEL_SUBSCRIPTION:
        return {
          ...state,
          data: null,
          states: {
            loading: true,
            error: false,
            success: false,
          },
        };
      case CANCEL_SUBSCRIPTION_SUCCESS:
        return {
          ...state,
          data: action.payload,
          states: {
            loading: false,
            error: false,
            success: true,
          },
        };
      case CANCEL_SUBSCRIPTION_ERROR:
        return {
          ...state,
          data: null,
          states: {
            loading: false,
            error: action.payload,
            success: false,
          },
        };
      case RESET_CANCEL_SUBSCRIPTION:
        return initialState;
      default:
        return state;
    }
  };
  
  export default reducers;
  