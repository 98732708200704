import { put, all, call, select, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NEW_DISCOUNT,
  NEW_DISCOUNT_SUCCESS,
  NEW_DISCOUNT_ERROR,
} from "../types/discount/new-discount";

import {
  UPDATE_DISCOUNT,
  UPDATE_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_ERROR,
} from "../types/discount/update-discount";

import {
  DELETE_DISCOUNT,
  DELETE_DISCOUNT_SUCCESS,
  DELETE_DISCOUNT_ERROR,
} from "../types/discount/delete-discount";

import {
  GET_ALL_DISCOUNTS,
  GET_ALL_DISCOUNTS_SUCCESS,
  GET_ALL_DISCOUNTS_ERROR,
  UPDATE_CURRENT_DISCOUNTS,
} from "../types/discount/all-discounts";

import {
  GET_DISCOUNT,
  GET_DISCOUNT_SUCCESS,
  GET_DISCOUNT_ERROR,
  UPDATE_CURRENT_DISCOUNT,
} from "../types/discount/get-one";

const getCurrentDiscounts = ({ discountStore }) => discountStore.allDiscounts.data;
const getCurrentDiscount = ({ discountStore }) => discountStore.allDiscounts.data;

function* getDiscount({ payload }) {
  try {
    const { response, error } = yield call(services.discount.getOne, payload);

    if (error) {
      yield put({ type: GET_DISCOUNT_ERROR, payload: error });
    } else {
      yield put({ type: GET_DISCOUNT_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: NEW_DISCOUNT_ERROR,
      payload: "Error inesperado al buscar un descuento.",
    });
  }
}

function* getAllDiscounts({ payload }) {
  try {
    const { response, error } = yield call(services.discount.all, payload);

    if (error) {
      yield put({ type: GET_ALL_DISCOUNTS_ERROR, payload: error });
    } else {
      yield put({ type: GET_ALL_DISCOUNTS_SUCCESS, payload: response });
    }
  } catch {
    yield put({
      type: NEW_DISCOUNT_ERROR,
      payload: "Error inesperado al buscar todos los descuentos.",
    });
  }
}

function* newDiscount({ payload }): any {
  try {
    const { response, error } = yield call(services.discount.new, payload);

    if (error) {
      yield put({ type: NEW_DISCOUNT_ERROR, payload: error });
    } else {
      yield all([
        put({ type: NEW_DISCOUNT_SUCCESS, payload: { ...response, group_id: payload?.group_id } }),
        put({ type: GET_ALL_DISCOUNTS, payload: null }),
      ]);
    }
  } catch {
    yield put({
      type: NEW_DISCOUNT_ERROR,
      payload: "Error inesperado al crear un nuevo descuento.",
    });
  }
}

function* deleteDiscount({ payload }): any {
  try {
    const { group_id, ...rest } = payload
    const { response, error } = yield call(
      services.discount.delete,
      rest.id
    );
    if (error) {
      yield put({ type: DELETE_DISCOUNT_ERROR, payload: error });
    } else {
      yield all([
        put({ type: DELETE_DISCOUNT_SUCCESS, payload: { ...response, group_id } }),
        put({ type: GET_ALL_DISCOUNTS, payload: null }),
      ]);
    }
  } catch (error) {
    yield put({
      type: DELETE_DISCOUNT_ERROR,
      payload: "Error inesperado al eliminar un descuento.",
    });
  }
}

function* updateDiscount({ payload }): any {
  try {
    const { group_id, ...rest } = payload
    const { response, error } = yield call(services.discount.update, rest);


    if (error) {
      yield put({ type: UPDATE_DISCOUNT_ERROR, payload: error });
    } else {
      let currentDiscounts = yield select(getCurrentDiscounts);
      let currentDiscount = currentDiscounts.find(item => item.id === rest.id)

      currentDiscounts = currentDiscounts?.map((discount) => {
        if (discount.id === rest.id) {
          return { ...discount, ...payload };
        }

        return discount;
      });

      yield all([
        put({ type: UPDATE_DISCOUNT_SUCCESS, payload: { ...response, group_id } }),
        put({ type: UPDATE_CURRENT_DISCOUNTS, payload: currentDiscounts }),
        put({ type: UPDATE_CURRENT_DISCOUNT, payload: currentDiscount }),
      ]);
    }
  } catch {
    yield put({
      type: UPDATE_DISCOUNT_ERROR,
      payload: "Error inesperado al actualizar un descuento.",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(NEW_DISCOUNT, newDiscount);
  yield takeLatest<any>(UPDATE_DISCOUNT, updateDiscount);
  yield takeLatest<any>(DELETE_DISCOUNT, deleteDiscount);
  yield takeLatest<any>(GET_ALL_DISCOUNTS, getAllDiscounts);
  yield takeLatest<any>(GET_DISCOUNT, getDiscount);
}
