import { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Formik } from "formik";
import PlanCard from './components/PlanCard'
import ConfirmDeletePlanModal from './components/ConfirmDeletePlanModal'
import Collapse from '../../../../../components/Collpase'
import { SectionTitle, Text, EmptyPlanCard } from './styles'
import Loader from "../../../../../components/Loader";
import Button from '../../../../../components/Button'
import Radio from '../../../../../components/FormikForm/SingleRadio'
import Grid from '../../../../../components/Grid'
import { showModal } from '../../../../../store/actions/modal'
import { editPlan, getPlans, selectedPlan } from '../../../../../store/actions/plan'
import { PlanDto } from '../../../../../types/plan.dto'
import { DiscountDto, Group } from '../../../../../types'
import flagArgentina from '../../../../../constants/countries/flags/flag-AR.png'
import flagUnitedStates from '../../../../../constants/countries/flags/flag-US.png'
import flagEuropeanUnion from '../../../../../constants/countries/flags/flag-UE.png'
import { schema, initialValues } from '../../../../../constants/forms/plan/main-currency'
import { Center } from '../../../../../components/StyledComponents';
import { updateGroupMainCurrency } from '../../../../../store/actions/group';
import { selectedDiscount } from '../../../../../store/actions/discount';
import discount from '../../../../../services/discount';
import Autosave from './components/AutoSave'
import Snackbar from '../../../../../components/Snackbar';
import EmptyCard from './components/EmptyCard'

const currencies = [
  {
    flag: flagArgentina,
    iso: "ARS",
    label: "AR$",
    description: "Pesos Argentinos (ARS)",
  },
  {
    flag: flagEuropeanUnion,
    iso: "EUR",
    label: "€",
    description: "Euros (EUR)",
  },
  {
    flag: flagUnitedStates,
    iso: "USD",
    label: "US$",
    description: "Dolares Americanos (USD)",
  },
];

interface ComponentProps {
  setShowNewPlanSidebar,
  setShowEditPlanSidebar,
  setShowNewPromotionSidebar,
  setShowEditPromotionSidebar,
  group: Group
  allPlans: PlanDto[]
  allPlansStates
}

const Component = ({ setShowNewPlanSidebar, setShowEditPlanSidebar, setShowNewPromotionSidebar, setShowEditPromotionSidebar, allPlans, allPlansStates, group }: ComponentProps) => {
  const dispatch = useDispatch()
  const [plans, setPlans] = useState<{ [currency: string]: PlanDto[] }>()
  const [planToBeRemoved, setPlanToBeRemoved] = useState<PlanDto>()
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [showOnePlanActiveMessage, setShowOnePlanActiveMessage] = useState<boolean>(false)
  const [showSelectOtherCurrencyMessage, setShowSelectOtherCurrencyMessage] = useState<boolean>(false)
  const [showCannotDeletePrice, setShowCannotDeletePrice] = useState<boolean>(false)


  const checkTotalActivePlans = () => {
    const activePlans = allPlans?.filter(item => item.status === 'active')
    const totalActivePlans = activePlans.length

    if (totalActivePlans <= 1) {
      setShowOnePlanActiveMessage(true)
      setTimeout(() => setShowOnePlanActiveMessage(false), 3000)
      return false
    }

    return true
  }

  const checkTotalPlansInCurrency = (plan) => {
    const activePlans = allPlans?.filter(item => item.status === 'active' && item.currency === plan.currency)
    const isMainCurrencyPlans = group?.main_currency === plan.currency;
    const totalActivePlans = activePlans.length;

    if (totalActivePlans <= 1 && isMainCurrencyPlans) {
      setShowSelectOtherCurrencyMessage(true)
      setTimeout(() => setShowSelectOtherCurrencyMessage(false), 3000)
      return false
    }

    return true
  }

  const checkSubscriptionsCountOnPlan = (plan) => {
    if (plan.subscriptions_count > 0) {
      setShowCannotDeletePrice(true)
      setTimeout(() => setShowCannotDeletePrice(false), 3000)
      return false
    }

    return true
  }


  const onSubmit = (values) => {
    dispatch(updateGroupMainCurrency({ ...values, group_id: group?.id }))
  }

  const onDeletePlan = (plan: PlanDto) => {
    if (checkTotalActivePlans() && checkTotalPlansInCurrency(plan) && checkSubscriptionsCountOnPlan(plan)) {
      setPlanToBeRemoved(plan);
    }
  }

  const onSelectPlan = (plan: PlanDto) => {
    dispatch(selectedPlan(plan))
    setShowEditPlanSidebar(true)
  }

  const onPausePlan = (plan: PlanDto) => {
    if (checkTotalActivePlans() && checkTotalPlansInCurrency(plan)) {
      const { old_subscribers_price, price, featured } = plan
      dispatch(editPlan({ idPlan: plan.id, status: 'inactive' }))
    }
  }

  const onActivePlan = (plan: PlanDto) => {
    const { old_subscribers_price, price, featured } = plan
    dispatch(editPlan({ idPlan: plan.id, status: 'active' }))
  }

  const onHighlightPlan = (plan: PlanDto) => {
    dispatch(editPlan({ idPlan: plan.id, featured: true }))
  }

  const onUnhighlightPlan = (plan: PlanDto) => {
    dispatch(editPlan({ idPlan: plan.id, featured: false }))
  }

  const groupedAndSorted = objectsArray => {
    return objectsArray.reduce((acc, obj) => {
      const currency = obj.currency;

      if (!acc[currency]) {
        acc[currency] = [];
      }

      acc[currency].push(obj);

      return acc;
    }, {})
  };

  useEffect(() => {
    if (group?.plans) {
      dispatch(getPlans({ idGroup: group?.id }))
    }
  }, [group])

  useEffect(() => {
    if (allPlans) {
      setPlans(groupedAndSorted(allPlans.filter(item => item.status !== "deleted")))
    }
  }, [allPlans])

  useEffect(() => {
    if (!!planToBeRemoved) {
      dispatch(showModal('modal-confirm-plan-deletion'))
    }
  }, [planToBeRemoved])

  return (
    <>
      {!plans && !allPlansStates.loading && (
        <EmptyCard setShowNewPlanSidebar={setShowNewPlanSidebar} />
      )}
      {(!!plans && !allPlansStates.loading && Object.keys(plans).length > 0) && (
        <>
          <Grid.Row>
            <Grid.Col>
              <Button
                options={{
                  type: "outline",
                  skin: "purple",
                  size: "lg",
                }}
                onClick={() => setShowNewPlanSidebar(true)}
              >
                + Agregar precio
              </Button>
            </Grid.Col>
          </Grid.Row>

          <div style={{ height: 40 }} />
        </>
      )}

      {!plans && allPlansStates.loading &&
        <>
          <Grid.Row>
            <Grid.Col>
              <EmptyPlanCard><Loader color="#6b5ce7" /></EmptyPlanCard>
            </Grid.Col>
          </Grid.Row>
          <div style={{ height: 15 }} />
        </>
      }

      <Formik
        initialValues={{ main_currency: group?.main_currency }}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit(values);
        }}
        validateOnChange={formSubmmited}
        validateOnBlur={false}
        validationSchema={schema}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, handleBlur, errors, values, touched }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="edit-main-currency-form"
            >
              {!!plans && Object.keys(plans).map((currency: string) => {
                const currencyPlans = plans[currency];
                const currencySettings = currencies?.find((item) => item.iso === currency)

                return (
                  <Collapse
                    toggleLabel={
                      <Center horizontal='space' vertical="center" style={{ paddingRight: 20 }}>
                        <SectionTitle>Precios en {currencySettings?.description}</SectionTitle>
                        <Radio
                          id={`main_currency_${currency}`}
                          name="main_currency"
                          disabled={!currencyPlans.some(cp => cp.status === "active")}
                          error={errors["main_currency"]}
                          touched={touched["main_currency"]}
                          selectedValue={values['main_currency']}
                          value={currency}
                          label="Moneda principal"
                          onChange={(event) => {
                            event.stopPropagation();
                            handleChange(event)
                          }}
                          options={{
                            marginBottom: 0,
                            label: "",
                          }}
                          styles={{ wrapper: { flex: 1 } }}
                        />
                      </Center>
                    }
                    toggleIcon=""
                  >
                    <div style={{ height: 25 }} />
                    {currencyPlans?.map((currencyPlan) =>
                      <>
                        <PlanCard onSelect={onSelectPlan} editPromotion={setShowEditPromotionSidebar} addPromotion={setShowNewPromotionSidebar} onActive={onActivePlan} onDelete={onDeletePlan} onHighlight={onHighlightPlan} onUnhighlight={onUnhighlightPlan} onPause={onPausePlan} plan={currencyPlan} />
                        <div style={{ height: 15 }} />
                      </>
                    )}
                    <div style={{ height: 50 }} />
                  </Collapse>
                )
              })}

              <Autosave callback={onSubmit} />
            </form>
          );
        }}
      </Formik>


      {planToBeRemoved && (
        <ConfirmDeletePlanModal plan={planToBeRemoved} onClose={setPlanToBeRemoved} />
      )}

      <Snackbar
        visible={showOnePlanActiveMessage}
        options={{
          position: "center",
          time: 2000,
          type: "error"
        }}
      >
        Debes contar con al menos un precio visible.
      </Snackbar>

      <Snackbar
        visible={showSelectOtherCurrencyMessage}
        options={{
          position: "center",
          time: 2000,
          type: "error"
        }}
      >
        Selecciona otra moneda principal antes de eliminar el precio.
      </Snackbar>

      <Snackbar
        visible={showCannotDeletePrice}
        options={{
          position: "center",
          time: 2000,
          type: "error"
        }}
      >
        No puedes eliminar un precio que ya tiene suscriptores. Si deseas, puedes Pausarlo.
      </Snackbar>
    </>
  )
}

const state = ({ groupStore, planStore }) => {
  const { data: group } = groupStore.group;
  const { data: allPlans, states: allPlansStates } = planStore.allPlans

  return {
    group,
    allPlans,
    allPlansStates
  };
};

export default connect(state)(Component);
