import styled from "styled-components";

const animationTime = ".1s";

export const Acordeon = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Item = styled("div")<{ isCollapsed: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #e4e4e5;
  padding: 19px 20px 19px 8px;
  cursor: pointer;
`;

export const Question = styled.button<{ isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme, isCollapsed }) =>
    isCollapsed ? theme.colors.Black75 : "#2D3436"};
  cursor: pointer;
  background: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  text-align: left;

  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 20px;
    line-height: 20px;
  }
`;
export const Chevron = styled.img<{ isCollapsed: boolean }>`
  margin-left: 25px;
  transition: transform ${animationTime} ease-in-out;
  transform: rotate(${(props) => (props.isCollapsed ? 0 : -180)}deg);
`;

export const Answer = styled("p")<{ isCollapsed: boolean; height?: number }>`
  color: ${(props) => props.theme.colors.Black};
  max-width: 715px;
  margin: 0px;
  overflow: hidden;
  transition: all .2s ease-in-out;
  height ${animationTime} ease-in-out,
  opacity ${animationTime} ease-in-out;
  opacity: ${(props) => `${props.isCollapsed ? "0" : "1"};`}
  height: ${(props) => `${props.isCollapsed ? 0 : props.height}px;`}
  font-size: 12px;
  line-height: 15px;
  
  
  @media( min-width: ${(props) => props.theme.mediaBreakpoints.smMin} ){
    font-size: 16px;
    line-height: 24px;
  }
`;

export const AnswerContent = styled.span`
  display: block;
  margin-top: 20px;
`;
