import { Pill } from "./styles";
const states = {
  active: {
    label: "Activa",
    color: {
      text: "#12B47A",
      background: "#DCF4EB",
    },
  },
  waiting_for_gateway: {
    label: "En Proceso",
    color: {
      text: "#12B47A",
      background: "#DCF4EB",
    },
  },
  retrying: {
    label: "Reintentandose",
    color: {
      text: "#FC7F0D",
      background: "#FFEEE0",
    },
  },
  non_renewing: {
    label: "Cancelación programada",
    color: {
      text: "#FC7F0D",
      background: "#FFEEE0",
    },
  },
  defaulted: {
    label: "Cancelada",
    color: {
      text: "#EB5757",
      background: "#FCE6E6",
    },
  },
  not_paid: {
    label: "Cobro fallido",
    color: {
      text: "#EB5757",
      background: "#FCE6E6",
    },
  },
  cancelled: {
    label: "Cancelada",
    color: {
      text: "#EB5757",
      background: "#FCE6E6",
    },
  },
  paid: {
    label: "Pagada",
    color: {
      text: "#12B47A",
      background: "#DCF4EB",
    },
  },
  refunded: {
    label: "Reembolsada",
    color: {
      text: "#FC7F0D",
      background: "#FFEEE0",
    },
  },
  paused: {
    label: "Pausada",
    color: {
      text: "#FC7F0D",
      background: "#FFEEE0",
    },
  },
  pending_invite: {
    label: "Pendiente",
    color: {
      text: "#FC7F0D",
      background: "#FFEEE0",
    },
  },
  expired: {
    label: "Expirada",
    color: {
      text: "#EB5757",
      background: "#FCE6E6",
    },
  },
  in_trial: {
    label: "En Periodo de Prueba",
    color: {
      text: "#FC7F0D",
      background: "#FFEEE0",
    },
  },
};

const Component = ({ subscriptionState }) => {
  return (
    <Pill styles={states[subscriptionState]?.color}>
      {states[subscriptionState]?.label}
    </Pill>
  );
};

export default Component;
