import { useMemo } from "react";
import { connect } from "react-redux";
import { GrayBox, Title } from "./styles"
import Input from "../../../../../../../components/FormikForm/Input";
import ReactSelect from "../../../../../../../components/FormikForm/ReactSelect"
import Checkbox from "../../../../../../../components/FormikForm/Checkbox";
import { PlanDto } from "../../../../../../../types";
import {Option, StrongLabel, Text, Value} from "../../../styles";
import {formatPrice} from "../../../../../../../helpers/price-formatter";

interface ComponentProps {
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  isLoading: boolean,
  isNew?: boolean,
  isEdit?: boolean,
  selectedPlan: PlanDto
}

const Component = ({
                     values,
                     errors,
                     touched,
                     handleChange,
                     handleBlur,
                     setFieldValue,
                     setFieldError,
                     setFieldTouched,
                     isLoading,
                     isNew,
                     isEdit,
                     selectedPlan
                   }: ComponentProps) => {

  const periodsUnits = [
    // { value: "day", label: "Días" },
    // { value: "week", label: "Semanas" },
    { value: "month", label: "Meses" },
    { value: "quarter", label: "Trimestres" },
    { value: "half-year", label: "Semestres" },
    { value: "year", label: "Años" }
  ]

  const periodsUnitsTranslations = {
    "month": { singular: "mes", plural: "meses" },
    "quarter": { singular: "trimestre", plural: "trimestres" },
    "half-year": { singular: "semestre", plural: "semestres" },
    "year": { singular: "año", plural: "años" },
  }

  const selectedPeriod = useMemo(() => {
    return periodsUnits?.find(period => period?.value === (values["period_unit"]))
  }, [values])

  const periodsUnitsTranslated = useMemo(() => {
    if (values["billing_cycles"] && values["period_unit"] && selectedPeriod) {
      const cyclesSelected = values["billing_cycles"];
      const selectedPeriodUnit = periodsUnitsTranslations[selectedPeriod?.value]
      return selectedPeriodUnit[cyclesSelected > 1 ? "plural" : "singular"]
    }
  }, [values, selectedPeriod])

  const paymentText = useMemo(() => {
    if (values["billing_cycles"] && values["period_unit"] && selectedPeriod && periodsUnitsTranslated) {
      const billingCyclesSelected = values["billing_cycle_type"];
      const priceSelected = values["price"];
      const oldSubscribersPriceSelected = values["old_subscribers_price"];
      const cyclesSelected = values["billing_cycles"];
      const currencySelected = values["currency"];
      const selectedPeriodUnit = periodsUnitsTranslations[selectedPeriod?.value];
      const selectedPeriodText = values["period"] > 1 ? `cada ${values?.period} ${selectedPeriodUnit["plural"]}` : `por ${selectedPeriodUnit["singular"]}`;
      const applyToOldSubscribers = values["apply_new_price"];
      const oldSubscribersPaymentText = selectedPlan?.type === "subscription" && selectedPlan?.subscriptions_count > 0 ? `Los viejos subscriptores pagaran ${currencySelected} ${oldSubscribersPriceSelected}.` : ""


      const startText = `Se cobrara <strong><u>$${formatPrice(Number(priceSelected) || 0)} ${currencySelected}</u></strong>`;
      const whoPayText = `Este precio se aplicará ${applyToOldSubscribers.includes("true") ? "a nuevos y viejos suscriptores." : `para nuevas ventas. ${oldSubscribersPaymentText}`}`;

      if (values["type"] === "charge") {
        return `${startText} en un <u><strong>único pago.</strong></u> ${isEdit ? whoPayText : ""}`
      } else {
        if (billingCyclesSelected === "cobro-indefinido") {
          return `${startText} <u><strong>${selectedPeriodText}</strong></u> por tiempo <u><strong>indefinido</strong></u>. ${isEdit ? whoPayText : ""}`
        } else {
          return `${startText} <u><strong>${selectedPeriodText}.</strong></u> Se realizaran  <u><strong>${cyclesSelected} cobros</strong></u>. ${isEdit ? whoPayText : ""}`
        }
      }
    } else {
      return "Termina de configurar el precio."
    }
  }, [values, selectedPeriod, periodsUnitsTranslated])

  return (
    <>
      <Input
        name="external_name"
        error={errors["external_name"]}
        touched={touched["external_name"]}
        value={values["external_name"]}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Nombre"
        type="text"
        options={{
          label: "Nombre",
          helperText: "Este será el nombre visible del precio en la página de ventas. ¡Puedes usar emojis 🙌 🧘🏼!",
          marginBottom: 20,
        }}
        disabled={isLoading}
      />

      <ReactSelect
        name="status"
        error={errors["status"]}
        touched={touched["status"]}
        items={[{ value: "active", label: "Visible" }, { value: "inactive", label: "Oculto" }]}
        defaultValue={values["status"]}
        onChange={handleChange}
        onBlur={handleBlur}
        options={{
          label: "Estado",
          helperText: "Al cambiar el estado solo estarás mostrando u ocultando el precio en tu página de ventas para nuevos suscriptores. Esto no afecta a viejos suscriptores.",
          marginBottom: 24,
        }}
        disabled={isLoading}
      />

      <Input
        name="price"
        error={errors["price"]}
        touched={touched["price"]}
        value={values["price"]}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="0"
        type="number"
        options={{
          label: "Precio",
          helperText: `Al editar este valor, estas definiendo un precio solo para las nuevas personas que se suscriban con este Precio. Para editar el valor que pagan los suscriptores que ya se habian suscripto en el pasado con este Precio, debes ver la sección "Precio de viejos suscriptores"` ,
          after: `/${selectedPeriod?.value && periodsUnitsTranslations[selectedPeriod?.value]['singular']}`,
          marginBottom: 40,
          before: values["currency"]
        }}
        disabled={isLoading}
      />


      {selectedPlan?.type === "subscription" && (
        <Option>
          <StrongLabel>Precio de viejos suscriptores</StrongLabel>
          <Text style={{fontSize: "13px", marginBottom: "40px", fontStyle: "italic", marginTop: "10px"}}> 💡 Si quieres actualizar el precio de viejos suscriptores, comunicate con el equipo de Soporte de Klouser APP para que pueda ayudarte.</Text>
        </Option>
      )}

      {/* OLD SUBSCRIBERS PRICE ORIGINAL [postponed]

      selectedPlan?.type === "subscription" && (
        <>
          {selectedPlan?.subscriptions_count > 0 ? (
            <>
              <Input
                name="old_subscribers_price"
                error={errors["old_subscribers_price"]}
                touched={touched["old_subscribers_price"]}
                value={values["old_subscribers_price"]}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="0"
                type="number"
                options={{
                  //label: "Precio de los viejos suscriptores",
                  marginBottom: 14,
                  explainText: `En este momento tienes ${selectedPlan?.subscriptions_count} suscriptores que compraron con este Precio.`,
                  helperText: 'Si deseas actualizar el valor para los viejos suscriptores que compraron con este Precio, aquí puedes definir el nuevo valor.',
                  before: values["currency"]
                }}
                disabled={values["apply_new_price"].includes("true") || isLoading || (selectedPlan?.type !== "subscription" || selectedPlan?.subscriptions_count <= 0)}
              />

              <Checkbox
                name="apply_new_price"
                error={errors["apply_new_price"]}
                touched={touched["apply_new_price"]}
                value={values["apply_new_price"]}
                disabled={selectedPlan?.type !== "subscription" || selectedPlan?.subscriptions_count <= 0}
                onChange={(event) => {
                  setFieldValue('old_subscribers_price', event.target.checked ? values['price'] : selectedPlan['price'])
                  handleChange(event)
                }}
                onBlur={handleBlur}
                items={[
                  {
                    label: "Aplicar el nuevo precio también a todos los viejos suscriptores",
                    value: "true",
                  },
                ]}
                options={{
                  marginBottom: 24,
                  //label: "Editar precio de suscriptores activos",
                }}
              />
            </>
          ) : (
            <Option>
              <StrongLabel>Precio de viejos suscriptores</StrongLabel>
              <Text style={{fontSize: "13px", marginBottom: "40px", fontStyle: "italic", marginTop: "10px"}}> 💡 Aún no tienes suscripciones vinculadas a este Precio. Cuando tengas al menos 1 suscripción en este precio, se mostrarán los campos para editar esta seccion.</Text>
            </Option>
          )}
        </>
      )*/}

      {(Number(values['price']) !== selectedPlan?.price ||
        (values['old_subscribers_price'] && Number(values['old_subscribers_price']) !== selectedPlan?.old_subscribers_price))
      && Object.keys(errors).length === 0 && (
        <GrayBox>
          <Title>Así es cómo quedarán los cambios al Precio:</Title>
          <div dangerouslySetInnerHTML={{ __html: paymentText }} />
        </GrayBox>
      )}

    </>
  );
};

const state = ({ groupStore, planStore }) => {
  const { data: groupInformation } = groupStore.group;
  const { data: selectedPlan, states: selectedPlanStates } = planStore.selectedPlan;

  return {
    groupInformation,
    selectedPlan,
    selectedPlanStates,
  };
};

export default connect(state)(Component);
