import {
  initialState,
  Actions,
  State,
  GET_POSTS,
  UPDATE_GET_POSTS,
  GET_POSTS_SUCCESS,
  GET_POSTS_ERROR,
  RESET_GET_POSTS,
} from "../../types/post/get-posts";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_POSTS:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case GET_POSTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case GET_POSTS_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case UPDATE_GET_POSTS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: false,
        },
      };
    case RESET_GET_POSTS:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
