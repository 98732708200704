import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Layout, useLayout } from '../../../providers/LayoutProvider';
import { Group, Subscription, User } from '../../../types';
import UpdatePaymentInvalidSubscription from './components/UpdatePaymentInvalidSubscription';
import { getUrlParamByName } from '../../../helpers/get-url-param';
import Image from '../../../components/Image';
import MercadoPagoForm from '../../Checkout/components/MercadoPagoForm'
import {
  CheckoutContainer, Column, ShowInDesktop, ShowInMobile, BeforeMpFormText
} from './styles'
import { getSubscription } from "../../../store/actions/subscription-admin";
import action from "../../../helpers/rest-client";
import CheckoutSubscriberError from '../../Checkout/components/CheckoutSubscriberError';
import UpdatePaymentError from '../../Checkout/components/CheckoutPaymentError';
import UpdateSubscriptionError from '../../Checkout/components/CheckoutSubscriptionError';
import UpdatePaymentSuccess from './components/UpdatePaymentSuccess';
import ProcessModal from './components/ProcessModal';
import mercadoPagoImg from '../../../assets/mercado-pago.png'
import { useWindowSize } from '../../../hooks/useWindowSize';
import { useTheme } from 'styled-components';
import ErrorReason from './components/ErrorReason';
import SubscriptionDetails from './components/SubscriptionDetails';
import NeedHelp from '../../Checkout/RetryPaidSubscription/components/NeedHelp'
import SecureTechnology from '../../Checkout/components/SecureTechnology'
import { getGroupById } from '../../../store/actions/group';
import dateHandler from '../../../helpers/dates-hanlder';

interface ComponentProps {
  isLoggedIn: boolean
  userSubscriptions: Subscription[]
  subscription: Subscription | null;
  subscriptionStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  user: User
  userStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  isLoggedIn,
  userSubscriptions,
  subscription,
  subscriptionStates,
  user,
  userStates
}: ComponentProps) => {
  const dispatch = useDispatch();
  const { setLayout, layout } = useLayout();
  const theme = useTheme() as { logo: { small: string, big: string } };

  const [gatewayError, setGatewayError] = useState(false)
  const [appError, setAppError] = useState(false)
  const [success, setSuccess] = useState(false)

  const onSuccess = (response) => {
    setSuccess(true)
    setGatewayError(false)
    setAppError(false)
  }

  const onError = (error) => {
    if (error?.response?.data?.source === 'gateway') {
      setGatewayError(true)
    } else if (error?.response?.data?.source === 'application') {
      setAppError(true)
    } else {
      setAppError(true)
    }
    setSuccess(false)
  }

  const subscriptionId = useMemo(() => getUrlParamByName('subscriptionId'), [])
  const group = useMemo(() => subscription?.plan?.group, [subscription])

  const isWrongUser = useMemo(() => {
    return !userSubscriptions?.find(item => item.id === Number(subscriptionId))
  }, [userSubscriptions, subscriptionId])

  const isActiveRecurrentPaymentSubscription = useMemo(() => {
    return subscription?.type === "recurring" && (subscription?.status === "active" || subscription?.status === "renewal_retrying" || subscription?.status === "non_renewing")
  }, [subscription])

  const MpFormText = useCallback(() => {
    const isActiveSubscription = subscription?.status === "active"

    if (!isActiveSubscription) {
      return <></>
    }

    const billingDate = dateHandler.getDateInLocalTimeZone(subscription?.next_billing_at);
    const isPastDate = dateHandler.isExpired(subscription?.next_billing_at);
    const currency = subscription?.plan?.currency;

    const texts = (amount, date, isPastDate) => {
      const time = isPastDate ? 'past' : 'present';

      const textsTime = {
        past: <>El último cobro fue de <strong>{amount}</strong> y se realizó el<br /><strong>{date}</strong>.</>,
        present: <>Tu próximo cobro de <strong>{amount}</strong> se realizará el<br /><strong>{date}</strong>.</>
      }

      return textsTime[time]
    }

    return (
      <>
        {/* <div style={{ height: 20 }} /> */}
        {/* <BeforeMpFormText>
          { <p>Tienes asociada una <strong>Tarjeta {subscription?.}</strong> que termina en <strong>{subscription?.current_card_last_four_digits}</strong></p> }
          {texts(`${currency} $${subscription?.current_amount}`, billingDate, isPastDate)}<br /><br />
          Esta acción no generará ningún cobro adicional y se respetará el ciclo de renovación de tu suscripción.
        </BeforeMpFormText> */}
        {/* <div style={{ height: 12 }} /> */}
      </>
    )
  }, [subscription, isActiveRecurrentPaymentSubscription])

  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "rainbow",
      footer: { visible: false },
      header: { visible: false },
      toolbar: { visible: false },
      body: {
        visible: true,
        background: { color: 'white' },
      },
    }),
    [layout]
  );

  const onSubmit = useCallback(async (cardData) => {
    const payload = {
      payment_details: cardData
    }

    action.Put({
      url: `/api/subscription/${subscriptionId}/payment/update`,
      body: payload,
    }).then((response: Subscription) => {
      onSuccess(response)
    }).catch((error) => {
      onError(error)
    });
  }, [subscriptionId]);

  useEffect(() => {
    if (!!subscriptionId) {
      dispatch(getSubscription(Number(subscriptionId)));
    }
  }, [subscriptionId]);

  useEffect(() => {
    setLayout(layoutData);
  }, []);

  useEffect(() => {
    if (!subscriptionId) {
      window.location.href = "/profile/manage-subscriptions"
    }
  }, [subscriptionId])

  if (!isLoggedIn || !group || !subscription || !userSubscriptions) {
    return <></>
  }

  return (
    <>
      <CheckoutContainer>
        {isActiveRecurrentPaymentSubscription && !isWrongUser && !gatewayError && !appError && !success && (
          <>
            <Column className='col-one' >
              <div style={{ gap: 24, display: 'flex', justifyContent: 'center', width: '100%', maxWidth: 410 }}>
                <Image
                  src={theme?.logo?.big}
                  style={{ display: 'block' }}
                  width="107px"
                  height="auto"
                  className="logo-desktop"
                  alt="Klouser"
                  lazy
                />

                <div style={{ height: 30, width: 1, backgroundColor: '#B1C6E4' }} />

                <Image
                  src={mercadoPagoImg}
                  style={{ display: 'block' }}
                  width="101px"
                  height="auto"
                  className="logo-desktop"
                  alt="Mercado pago"
                  lazy
                />
              </div>

              <div style={{ height: 30 }} />

              <ErrorReason group={group} />
              
              <div style={{ height: 30 }} />

              <ShowInDesktop style={{ display: 'flex', flexDirection: !subscription?.current_rejected_reason ? 'column-reverse' : 'column' }}>
                <NeedHelp />
                <div style={{ height: 40 }} />
                <SecureTechnology style={{ margin: "0px -10px", maxWidth: 410, ...(!subscription?.current_rejected_reason && { backgroundColor: "#EBFFEE" }) }} />
              </ShowInDesktop>
            </Column>

            <Column className='col-two'>
              <MercadoPagoForm
                onError={onError}
                onSubmit={onSubmit}
                onSuccess={onSuccess}
                publicKey={subscription?.gateway?.credentials?.public?.public_key || ""}
                title="Actualiza tu método de pago"
                subtitle="Ingresá otro medio de pago válido"
                beforeForm={
                  <MpFormText />
                }
                submitText="Actualizar"
                price={subscription?.current_amount}
                changeEmail={subscription?.current_rejected_reason === 'high_risk'}
                user={user}
              />

              <ShowInMobile>
                <NeedHelp />
                <div style={{ height: 30 }} />
                <SecureTechnology style={{ margin: "0px -10px" }} />
              </ShowInMobile>
            </Column>
          </>
        )}
      </CheckoutContainer>

      <ProcessModal />

      {(!isActiveRecurrentPaymentSubscription) && <UpdatePaymentInvalidSubscription />}
      {(isWrongUser) && <CheckoutSubscriberError />}
      {(!gatewayError && appError && !success) && <UpdateSubscriptionError text={<>Lamentablemente, la actualización de tu<br />nuevo metodo de pago no se ha podido completar. No te<br />preocupes, frecuentemente esto puede suceder por varios motivos.<br /><br />👉 Por favor, contáctate con nuestro<br />soporte para solucionarlo rápidamente.<br />¡Gracias por tu paciencia!</>} />}

      {/*OLD MESSAGE Al intentar actualizar tu método de pago hubo un error proveniente de tu tarjeta o de tu banco. Por favor revisa que todos los datos sean correctos o vuelve a intentarlo con otra tarjeta. */}
      {(gatewayError && !appError && !success) && <UpdatePaymentError retryCtaText={"Reintentar"} title={<>Actualización rechazada</>} text={<>Hola {user?.name}. No te preocupes, esto sucede frecuentemente. Alguno de los datos puede estar incorrectos o tu tarjeta no es soportada para este tipo de pagos automáticos. Prueba reintentando con el mismo u otro método de pago. <br /><br/> Si necesitas, puedes contactarte con nuestro soporte para que te podamos ayudar!</>} />}
      {(!gatewayError && !appError && success) && <UpdatePaymentSuccess />}
    </>
  );
};

const state = ({ userStore, subscriptionAdminStore, groupStore }) => {
  const { isLoggedIn } = userStore;
  const { data: userSubscriptions, states: userSubscriptionStates } = userStore.subscriptions;
  const { data: subscription, states: subscriptionStates } = subscriptionAdminStore.subscriptionAdmin;
  const { data: user, states: userStates } = userStore.information;

  return {
    isLoggedIn,
    subscription,
    subscriptionStates,
    userSubscriptions,
    user,
    userStates
  };
};

export default connect(state)(Component);