import styled from "styled-components";
import { AnimationFixed } from "../Animation";

export const ModalBackdrop = styled("div")`
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100%;
  height: calc(100vh * 2);
  background-color: #000;
`;

export const Modal = styled("div")`
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  pointer-events: auto;
`;

export const ModalDialog = styled("div")<{ width? }>`
  width: 100%;
  padding: 12px 8px;
  max-width: ${(props) => props.width || 288}px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 0px;
    max-width: ${(props) => props.width || 334}px;
  }
`;

export const ModalContent = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  outline: 0;
  background-color: #fff;
  width: 100%;
  //overflow: hidden;
  padding: 24px 27px;
  border-radius: 16px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    border-radius: 8px;
    width: auto;
  }
`;

export const ModalHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Title = styled("h3")`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin: 24px 0px 20px;
  color: ${(props) => props.theme.colors.Black};
`;

export const Close = styled("button")`
  background: transparent;
  position: absolute;
  cursor: pointer;
  top: 18px;
  right: 18px;
  z-index: 10;
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 1;

  &:focus {
    outline: transparent;
  }
`;

export const ModalBody = styled("div")`
  position: relative;
  line-height: 1.75rem;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    flex: 1 1 auto;
  }
`;

export const ModalFooter = styled("div")`
  padding: 0 0 24px;
`;

export const ModalAnimation = styled(AnimationFixed)`
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  pointer-events: auto;
`;
