import { Post } from "../../../types/post";
export const NEW_POST = "NEW_POST";
export const NEW_POST_SUCCESS = "NEW_POST_SUCCESS";
export const NEW_POST_ERROR = "NEW_POST_ERROR";
export const RESET_NEW_POST = "RESET_NEW_POST";

export interface NewPost {
  type: typeof NEW_POST;
  payload: Post;
}

export interface NewPostSuccess {
  type: typeof NEW_POST_SUCCESS;
  payload: Post;
}

export interface NewPostError {
  type: typeof NEW_POST_ERROR;
  payload: string | boolean;
}

export interface ResetNewPost {
  type: typeof RESET_NEW_POST;
  payload: null;
}

export interface State {
  data: Post | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions = NewPost | NewPostSuccess | NewPostError | ResetNewPost;
