import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  #footer-sticky {
    position: sticky;
    bottom: 0;
    z-index: 100;
  }

  #root {
    position: relative;
    padding-top: 0px;
    min-height: 100vh;
  }

  #header-sticky {
    position: sticky;
    top: 0;
    z-index: 105;
  }

  #modal {
    position: relative;
    z-index: 150;
  }

  #sidebars {
    position: relative;
    z-index: 160;
  }

  #snackbar {
    position: relative;
    z-index: 170;
  }

  #swipe {
    position: relative;
    z-index: 180;
  }
`;

export const Text = styled.div`
  font-size: 16px;
  line-height: 19px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 18px;
    line-height: 25px;
  }

  & > * {
    overflow-wrap: break-word;
    & > * {
      overflow-wrap: break-word;
    }
  }
`;
export const Image = styled("img")`
  border-radius: 18px;
  width: 100%;
`;

export const Audio = styled("div")`
  margin-bottom: 15px;
  margin-top: 15px;
`;

export const PdfLink = styled("a")`
  background-color: #b30c01;
  color: white;
  display: inline-flex;
  border-radius: 8px;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  padding: 5px 10px 5px 0;
  text-decoration: none;
  margin-bottom: 15px;
  margin-top: 15px;

  &:hover {
    text-decoration: underline;
  }
  i {
    margin-right: 10px;
  }
`;

export const BlockContentMessage = styled("div")`
  margin: 50px auto 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BlockContentMessageTitle = styled("h3")`
  text-align: center;
`;

export const PostContent = styled("div")`
  // background-color: white;
  // padding: 16px;
  // border-radius: 16px;
  // box-shadow: 0px 1px 25px rgb(0 0 0 / 15%);

  @media (min-width: 1024px) {
    //padding: 48px 97px;
  }
`;

export const InLine = styled("div")`
  display: flex;
  //align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled("h3")`
  font-weight: 500;
  font-size: 18px;
  margin: 0px;

  @media (min-width: 1024px) {
    font-size: 32px;
    line-height: 30px;
  }

  @media (max-width: 768px) {
    line-height: 24px;
  }
`;

const verticalPosition = {
  top: "flex-start",
  bottom: "flex-end",
  center: "center",
};

const horizontalPosition = {
  left: "flex-start",
  right: "flex-end",
  center: "center",
  space: "space-between",
};

export const Center = styled("div") <{
  vertical: "top" | "center" | "bottom";
  horizontal: "left" | "center" | "right" | "space";
  gap?: number;
  column?: boolean;
}>`
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  justify-content: ${({ horizontal }) => horizontalPosition[horizontal]};
  align-items: ${({ vertical }) => verticalPosition[vertical]};
  gap: ${({ gap }) => (gap ? gap : 0)}px;
`;

export const PrevContentLink = styled('a')`
  background-color: white;
  border: 1px solid #626769;
  color: #626769;
  padding: 12px 17px;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  font-size: 16px;
  font-weight: 500;

  span {
    display: -webkit-box;
    -webkit-line-clamp: 2; // Limit to 2 lines
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; // Allow wrapping to multiple lines
  }

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`

export const NextContentLink = styled('a')`
  border: 1px solid #6FCF97;
  background-color: #6FCF97;
  color: white;
  padding: 12px 17px;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  font-size: 16px;
  font-weight: 500;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`

export const NextContentButton = styled('button')`
  margin: 0px;
  padding: 0px;
  border: 0px;
  border: 1px solid #6FCF97;
  background-color: #6FCF97;
  color: white;
  padding: 12px 17px;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  font-size: 16px;
  font-weight: 500;
  
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`

export const WelcomeContentTitle = styled('h3')`
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
`


export const LoaderWrapper = styled('div')`
  position: relative;

`

export const LoaderBackground = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.35);
  border-radius: 30px;
`