import { NewGatewayDto } from "../../../types/gateway";

export const LINK_GATEWAY = "LINK_GATEWAY";
export const LINK_GATEWAY_SUCCESS = "LINK_GATEWAY_SUCCESS";
export const LINK_GATEWAY_ERROR = "LINK_GATEWAY_ERROR";

export interface LinkGateway {
  type: typeof LINK_GATEWAY;
  payload: NewGatewayDto;
}

export interface LinkGatewaySuccess {
  type: typeof LINK_GATEWAY_SUCCESS;
  payload;
}

export interface LinkGatewayError {
  type: typeof LINK_GATEWAY_ERROR;
  payload: boolean | string;
}

export interface State {
  data;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | LinkGateway
  | LinkGatewaySuccess
  | LinkGatewayError;
