import { DiscountDto, UpdateDiscountOutDto, NewDiscountOutDto } from "../../types";
import {
  NEW_DISCOUNT,
  NewDiscount,
  ResetNewDiscount,
  RESET_NEW_DISCOUNT,
} from "../types/discount/new-discount";

import {
  UPDATE_DISCOUNT,
  UpdateDiscount,
  ResetUpdateDiscount,
  RESET_UPDATE_DISCOUNT,
} from "../types/discount/update-discount";

import {
  DELETE_DISCOUNT,
  DeleteDiscount,
  RESET_DELETE_DISCOUNT,
  ResetDeleteDiscount,
} from "../types/discount/delete-discount";

import {
  SELECTED_DISCOUNT,
  SelectedDiscount,
} from "../types/discount/selected-discount";
import {
  GetAllDiscounts,
  GET_ALL_DISCOUNTS,
} from "../types/discount/all-discounts";

import { GetDiscount, GET_DISCOUNT } from "../types/discount/get-one";

export const newDiscount = (payload: NewDiscountOutDto): NewDiscount => {
  return { type: NEW_DISCOUNT, payload };
};

export const resetDeleteDiscount = (): ResetDeleteDiscount => {
  return { type: RESET_DELETE_DISCOUNT, payload: null };
};

export const resetNewDiscount = (): ResetNewDiscount => {
  return { type: RESET_NEW_DISCOUNT, payload: null };
};

export const updateDiscount = (payload: UpdateDiscountOutDto): UpdateDiscount => {
  return { type: UPDATE_DISCOUNT, payload };
};

export const resetUpdateDiscount = (): ResetUpdateDiscount => {
  return { type: RESET_UPDATE_DISCOUNT, payload: null };
};

export const deleteDiscount = (payload: { id: number }): DeleteDiscount => {
  return { type: DELETE_DISCOUNT, payload };
};

export const getAllDiscounts = (payload: { plan_id?: number, show_in_page: boolean }): GetAllDiscounts => {
  return { type: GET_ALL_DISCOUNTS, payload };
};

export const selectedDiscount = (payload: DiscountDto): SelectedDiscount => {
  return { type: SELECTED_DISCOUNT, payload };
};

export const getDiscount = (payload: { plan_id: number }): GetDiscount => {
  return { type: GET_DISCOUNT, payload };
};
