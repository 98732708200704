import styled, { createGlobalStyle } from "styled-components";
import Image from "./../../../../components/Image";
import UiButton from "./../../../../components/Button";

export const Button = styled(UiButton)`
  margin: 0px;
  padding: 0px;
  line-height: 23px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  height: 52px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  min-width: 230px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 18px;
    min-width: 280px;
  }
`;
