import moment from "moment";
import { useEffect, useState } from "react";
import { inputDateTimeLocal } from "../../helpers/dates-hanlder";
import { Clock, ClockText, Segment, Square, Dots } from "./styles";

interface ComponentProps {
  prefix?: string;
  type?: "clock" | "string";
  style?: any;
  date: string;
  day?: number; // required
  hours?: number; // Default is 0 [0-23] integer
  minutes?: number; // Default is 0 [0-59] integer
  seconds?: number; // Default is 0 [0-59] integer
  words?: {
    //words displayed into the countdown
    days: {
      singular: string;
      plural: string;
    };
    hours: {
      singular: string;
      plural: string;
    };
    minutes: {
      singular: string;
      plural: string;
    };
    seconds: {
      singular: string;
      plural: string;
    };
  };
  onEnd?: () => void; // Callback on countdown end, put your own function here
  refresh?: number; // Default refresh every 1s
}

const initialState: ComponentProps = {
  prefix: "",
  type: "clock",
  date: "",
  onEnd: () => console.log("Clock End"),
  refresh: 1000,
  words: {
    days: {
      singular: "día",
      plural: "días",
    },
    hours: {
      singular: "hora",
      plural: "horas",
    },
    minutes: {
      singular: "min",
      plural: "min",
    },
    seconds: {
      singular: "seg",
      plural: "segs",
    },
  },
};

const Component = (props: ComponentProps) => {
  const { date, words, onEnd, refresh, type, prefix, ...rest } = {
    ...initialState,
    ...props,
  };
  const [timerDays, setTimerDays] = useState(0);
  const [timerHours, setTimerHours] = useState(0);
  const [timerMinutes, setTimerMinutes] = useState(0);
  const [timerSeconds, setTimerSeconds] = useState(0);

  let interval;

  const startTimer = () => {
    const fecha = inputDateTimeLocal(date);
    const coutdownDate = new Date(fecha).getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = coutdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        // Stop Timer
        clearInterval(interval.current);
        if (onEnd) onEnd();
      } else {
        // Update Timer
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();

    return () => {
      clearInterval(interval);
    };
  });

  return type === "clock" ? (
    <Clock {...rest}>
      {/* {timerDays !== "0" && ( */}
      <Segment>
        <Square>{timerDays < 10 ? `0${timerDays}` : timerDays}</Square>
        {timerDays <= 1 ? words?.days.singular : words?.days.plural}
      </Segment>
      <Dots />
      {/* )} */}

      {/* {timerDays !== "0" && timerHours !== "0" && ( */}
      <Segment>
        <Square>{timerHours < 10 ? `0${timerHours}` : timerHours}</Square>
        {timerHours <= 1 ? words?.hours.singular : words?.hours.plural}
      </Segment>
      <Dots />
      {/* )} */}

      {/* {timerHours !== "0" && timerMinutes !== "0" && ( */}
      <Segment>
        <Square>{timerMinutes < 10 ? `0${timerMinutes}` : timerMinutes}</Square>
        {timerMinutes <= 1 ? words?.minutes.singular : words?.minutes.plural}
      </Segment>
      <Dots />
      {/* )} */}

      <Segment>
        <Square>{timerSeconds < 10 ? `0${timerSeconds}` : timerSeconds}</Square>
        {timerSeconds <= 1 ? words?.seconds.singular : words?.seconds.plural}
      </Segment>
    </Clock>
  ) : (
    <ClockText {...rest}>
      {prefix}{" "}
      <strong>{`${timerDays} ${timerDays <= 1 ? words?.days.singular : words?.days.plural
        }, ${timerHours} ${timerHours <= 1 ? words?.hours.singular : words?.hours.plural
        }, ${timerMinutes} ${timerMinutes <= 1 ? words?.minutes.singular : words?.minutes.plural
        }`}</strong>
    </ClockText>
  );
};

export default Component;
