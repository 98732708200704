import styled from "styled-components";
import Image from "../../../../Image";

export const BackgroundImage = styled('div')`
  width: 100%;
  height: 182px;
  border-radius: 22px;

  @media (max-width: 768px) {
    height: 102px;
  }
`

export const ProfileImage = styled(Image)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 160px;
  height: 160px;
  border-radius: 100%;
  border: 2px solid white;
  display: block;

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`