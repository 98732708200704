import styled from "styled-components";
import { rainbowBackgroundGradient } from "../../../../../components/StyledComponents";

export const Expert = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Name = styled("span")`
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
`;

export const Avatar = styled("div")`
  ${rainbowBackgroundGradient};
  width: 50px;
  height: 50px;
  padding: 1px;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 8px;

  & > img {
    width: 100%;
    height: auto;
    border-radius: 100%;
  }
`;

export const CoverPic = styled("div")`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  max-width: 700px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlayVideo = styled("button")`
  margin: 0px;
  padding: 0px;
  border: 0px;
  background: none;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  width: 87px;
  height: 87px;
  cursor: pointer;


  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    width: 163px;
    height: 163px;
  }
`;

export const VideoIcon = styled.img<{ isPlaying: boolean }>`
  width: 100%;
  opacity: ${(props) => (props.isPlaying ? 0.05 : 0.7)};
  transition: opacity 0.2s ease-in-out;
  
  @media (min-width: 768px) {
    width: 60%;
  }

  &:hover {
    opacity: ${(props) => (props.isPlaying ? 0.4 : 1)}; ;
  }

`;
