import styled from 'styled-components'
import LinkUi from '../../../../../components/Link'

export const Card = styled('div')`
  border-radius: 12px;
  padding: 32px 24px;
  background-color: #EBFFEE;

`

export const Title = styled('h3')`
  margin: 0px;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
`

export const Text = styled('p')`
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
  line-height: auto;
`

export const Link = styled(LinkUi)`
  border: 0px;
  height: 33px;
  text-decoration: none;
  font-weight: 500;
  position: relative;
  gap: 8px;

  &:after {
    content: '';
    height: 1px;
    width: 100%;
    background-color: #6C5CE7;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
`;