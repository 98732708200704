import { List, ListItem, SocialNetwork } from "./styles";
import Icon from '../../../Icon'
import constants from "./../../../../constants";
import { useTheme } from "styled-components";

const Component = () => {
  const theme = useTheme() as { footer: { socialNetworks: { background: string, color: string } } }
  return (
    <List>
      {constants.socialNetworks
        .filter((socialNetwork) => socialNetwork.enabled)
        .map((socialNetwork) => (
          <ListItem key={`key-${socialNetwork.name}`}>
            <SocialNetwork href={socialNetwork.url} target="_blank">
              <Icon
                icon={socialNetwork.icon}
                size="40px"
                color={theme.footer.socialNetworks.color}
              />
            </SocialNetwork>
          </ListItem>
        ))}
    </List>
  );
};

export default Component;
