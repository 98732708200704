import styled from "styled-components";

const resetFieldStyles = `
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  outline: none;
  border: 0px;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  width: 100%;`;

export const Input = styled("input")<{ skin; size }>`
  ${(props) => {
    const { theme, skin = "base", size = "md" } = props;

    return `
      ${resetFieldStyles}
      height: ${theme.form.size[size].height};
      line-height: ${theme.form.size[size].lineHeight};
      font-size: ${theme.form.size[size].fontSize};
      font-family: ${theme.fonts.family};
      color: ${theme.colors.Black};
    
      /**
       *  Estilos de cuando seleccionamos una opcion del autocomplete del input
       **/
    
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${theme.form.skin[skin].idle.backgroundColor} inset !important;
        background-color: transparent !important;
        background-image: none !important;
        color: ${theme.colors.Black} !important;
        line-height: ${theme.form.size[size].lineHeight};
        font-size: ${theme.form.size[size].fontSize};
        font-family: ${theme.fonts.family};
      }
      
      /**
       * Arrows en input number
       **/
      
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type=number] {
        -moz-appearance: textfield;
      }`;
  }}
`;
