import * as Yup from "yup";

const today = new Date();
today.setHours(0, 0, 0, 0);

export const schema = Yup.object().shape({
  valid_till: Yup.date()
    .nullable()
    .when({
      is: (valid_till) => valid_till !== null,
      then: Yup.date()
        .required('Debes ingresar una fecha válida')
        .typeError('Debes ingresar una fecha válida')
        .min(today, 'Debes indicar una fecha futura.'),
    }),
    //.required("Debes indicar hasta cuándo será válido el descuento."),
  status: Yup.string().required("Debes indicar un estado."),
});

interface FormValues {
  valid_till: string
  amount: string,
  status: string
}

export const initialValues: FormValues = {
  valid_till: "",
  amount: "",
  status: "active",
};
