import { GetPostsOutDto, Post } from "../../../types/post";
import { PaginationOutDto } from "../../../types/pagination";
export const GET_POSTS = "GET_POSTS";
export const UPDATE_GET_POSTS = "UPDATE_GET_POSTS";
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export const GET_POSTS_ERROR = "GET_POSTS_ERROR";
export const RESET_GET_POSTS = "RESET_GET_POSTS";

export interface GetPosts {
  type: typeof GET_POSTS;
  payload: GetPostsOutDto;
}

export interface UpdateGetPosts {
  type: typeof UPDATE_GET_POSTS;
  payload: Post[];
}

export interface GetPostsSuccess {
  type: typeof GET_POSTS_SUCCESS;
  payload: Post[];
}

export interface GetPostsError {
  type: typeof GET_POSTS_ERROR;
  payload: string | boolean;
}

export interface ResetGetPosts {
  type: typeof RESET_GET_POSTS;
  payload: null;
}

export interface State {
  data: Post[] | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetPosts
  | GetPostsSuccess
  | GetPostsError
  | UpdateGetPosts
  | ResetGetPosts;
