import { PaginationOutDto, PaginationInDto } from "../../../types/pagination";
export const EXPORT_COMMUNITY_SUBSCRIBERS = "EXPORT_COMMUNITY_SUBSCRIBERS";
export const EXPORT_COMMUNITY_SUBSCRIBERS_SUCCESS =
  "EXPORT_COMMUNITY_SUBSCRIBERS_SUCCESS";
export const EXPORT_COMMUNITY_SUBSCRIBERS_ERROR =
  "EXPORT_COMMUNITY_SUBSCRIBERS_ERROR";

export interface ExportCommunitySubscribers {
  type: typeof EXPORT_COMMUNITY_SUBSCRIBERS;
  payload: PaginationOutDto;
}

export interface ExportCommunitySubscribersSuccess {
  type: typeof EXPORT_COMMUNITY_SUBSCRIBERS_SUCCESS;
  payload: any;
}

export interface ExportCommunitySubscribersError {
  type: typeof EXPORT_COMMUNITY_SUBSCRIBERS_ERROR;
  payload: boolean | string;
}

export interface State {
  data: any;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | ExportCommunitySubscribers
  | ExportCommunitySubscribersError
  | ExportCommunitySubscribersSuccess;
