import Sidebar from "../../../../../../components/Sidebar";
import Button from "../../../../../../components/Button";
import Link from "../../../../../../components/Link";
import {
  Preview,
  Title,
  Option,
  Label,
  Value,
  GrayBox,
  CopyToClipboad,
} from "../styles";
import CopyTextToClipboad from "../../../../../../components/CopyToClipboad";
import { DiscountDto } from "../../../../../../types/discount";
import { useEffect, useState } from "react";
import dateHelpers from "../../../../../../helpers/dates-hanlder";
import { connect } from "react-redux";

const groupType = {
  membership: "Membresia",
  experience: "Taller",
};

interface ComponentProps {
  coupon?: DiscountDto;
  allDiscounts: DiscountDto[];
  deleteDiscountStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  allDiscountsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  onClose?: (data: any) => void;
  setShowDesactivateCouponSidebar: (show: boolean) => void;
  setShowEditCouponSidebar: (show: boolean) => void;
}

const Component = ({
  coupon,
  allDiscounts,
  allDiscountsStates,
  deleteDiscountStates,
  onClose,
  setShowDesactivateCouponSidebar,
  setShowEditCouponSidebar,
}: ComponentProps) => {
  const [currentCoupon, setCurrentCoupon] = useState<DiscountDto>();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!!coupon && !!allDiscounts) {
      const currentCoupon = allDiscounts.filter(
        (discount) => discount.id === coupon.id
      )[0];
      setVisible(!!currentCoupon);
      setCurrentCoupon(currentCoupon);
    }
  }, [coupon, allDiscounts]);

  useEffect(() => {
    if (deleteDiscountStates.success) {
      setVisible(false);
    }
  }, [deleteDiscountStates]);

  const planCharacteristics = currentCoupon && currentCoupon.plans.length > 0 ? currentCoupon.plans[0] : null;

  return (
    <Sidebar
      position="right"
      visible={visible}
      closeType="close"
      onClose={() => {
        setVisible(false);
        onClose && onClose(undefined);
      }}
      width="463px"
      title="Cupón de descuento"
    >
      <Preview>
        <div>
          <Option>
            <Label>Código del cupón</Label>
            <Value>
              <CopyTextToClipboad
                copy={coupon?.coupon_id}
                showChildren
                successStyle={{
                  position: "absolute",
                  left: "1%",
                  top: "50%",
                  transform: "translate(0%, -50%)",
                }}
              >
                {" "}
                {currentCoupon?.coupon_id}
              </CopyTextToClipboad>
            </Value>
          </Option>

          <Option>
            <Label>Nombre del producto</Label>
            <Value> {currentCoupon?.group_name}</Value>
          </Option>

          <Option>
            <Label>Precio asociado al cupon</Label>
            <Value> {planCharacteristics?.external_name} ({planCharacteristics?.currency})</Value>
          </Option>

          <Option>
            <Label>Porcentaje de descuento</Label>
            <Value> {currentCoupon?.amount}%</Value>
          </Option>

          <Option>
            <Label>Cupón válido hasta</Label>
            <Value>
              {dateHelpers.getDateInLocalTimeZone(currentCoupon?.valid_till)}
            </Value>
          </Option>

          {planCharacteristics && currentCoupon && planCharacteristics.type === "subscription" && (
            <Option>
              <Label>Descuento aplica durante los primeros:</Label>
              <Value>
                {currentCoupon?.period / (planCharacteristics?.period || 1)} cobros
              </Value>
            </Option>
          )}
        </div>

        <div>
          <GrayBox>
            <p>
              🎁 Invitación especial a mi{" "}
              {currentCoupon?.group_type === "experience"
                ? "taller"
                : "membresia"}
              : “{currentCoupon?.group_name}”,
              {currentCoupon?.group_type === "experience" &&
                ` que comienza el día ${dateHelpers.getDateInLocalTimeZone(
                  currentCoupon?.group_registration_closing_date
                )}`}
              . Regístrate al plan "{planCharacteristics?.external_name} ({planCharacteristics?.currency})" con un {currentCoupon?.amount}% de descuento{" "}
              {currentCoupon && planCharacteristics?.type === "subscription" &&
                `durante los primeros ${currentCoupon?.period / (planCharacteristics?.period || 1)} cobros `
              }
              utilizando el siguiente código de cupón
            </p>

            <p>👉 {currentCoupon?.coupon_id}</p>

            <p>¡Me encantaría verte allí!</p>

            <p>
              Link para{" "}
              {currentCoupon?.group_type === "experience"
                ? "inscribirte"
                : "suscribirte"}{" "}
              {currentCoupon?.group_type === "membership" ? "a la" : "al"}{" "}
              {currentCoupon && groupType[currentCoupon?.group_type]}: <br />{" "}
              https://klouser.app/group/
              {currentCoupon?.group_slug}
            </p>
          </GrayBox>

          {currentCoupon?.status !== "archived" && (
            <>
              <CopyToClipboad
                copy={`🎁 Invitación especial a mi ${
                  currentCoupon?.group_type === "experience"
                    ? "taller"
                    : "membresia"
                }: “${currentCoupon?.group_name}”${
                  currentCoupon?.group_type === "experience"
                    ? ` que comienza el día ${dateHelpers.getDateInLocalTimeZone(
                        currentCoupon?.group_registration_closing_date
                      )}`
                    : ""
                }.\nRegístrate al plan "${planCharacteristics?.external_name} (${planCharacteristics?.currency})" con un ${currentCoupon?.amount}% de descuento ${
                  planCharacteristics?.type === "subscription" && currentCoupon
                    ? `durante los primeros ${currentCoupon?.period / (planCharacteristics?.period || 1)} cobros ` 
                    : ""
                } utilizando el siguiente código de cupón: \n\n👉 ${
                  currentCoupon?.coupon_id
                }\n\n¡Me encantaría verte allí!.\n\nLink para ${
                  currentCoupon?.group_type === "experience"
                    ? "inscribirte"
                    : "suscribirte"
                } ${
                  currentCoupon?.group_type === "membership" ? "a la" : "al"
                } ${
                  currentCoupon && groupType[currentCoupon?.group_type]
                }:\nhttps://klouser.app/group/${currentCoupon?.group_slug}`}
                iconColor="white"
              >
                Copiar texto de invitación
              </CopyToClipboad>

              <Button
                options={{
                  type: "outline",
                  skin: "purple",
                  size: "lg",
                  block: true,
                }}
                style={{ marginBottom: "8px" }}
                onClick={() => setShowEditCouponSidebar(true)}
              >
                Editar
              </Button>

              <Button
                options={{
                  type: "link",
                  skin: "purple",
                  size: "lg",
                  block: true,
                }}
                onClick={() => setShowDesactivateCouponSidebar(true)}
              >
                {coupon?.redemptions && coupon?.redemptions > 0
                  ? "Pausar cupón"
                  : "Eliminar cupón"}
              </Button>
            </>
          )}
        </div>
      </Preview>
    </Sidebar>
  );
};

const state = ({ discountStore }) => {
  const { data: allDiscounts, states: allDiscountsStates } =
    discountStore.allDiscounts;
  const { states: deleteDiscountStates } = discountStore.deleteDiscount;

  return { allDiscounts, allDiscountsStates, deleteDiscountStates };
};

export default connect(state)(Component);
