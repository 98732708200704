import { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { getFreeSubscriptions } from '../../../../helpers/getFreeSubscriptions';
import { isSubscribed } from '../../../../helpers/isSubscribed';
import { Group, SessionInDto, User } from '../../../../types';
import Icon from '../../../../components/Icon';
import arrowLinkIcon from '../../../../assets/icons/arrow_forward_up_right-semibold.svg'
import Faq from './components/Faq'
import {
  Card,
  Title,
  Text,
  Link,
  Button
} from './styles'
import constants from '../../../../constants';
import { getPriorityGroupSubscription } from '../../../../helpers/mpStatusPriority';
import { Post } from '../../../../types/post';


interface ComponentProps {
  user: User
  group: Group;
  post: Post;
  session: SessionInDto;
  userSubscriptions
  isLoggedIn: Boolean;
}

const Component = ({
  user,
  group,
  post,
  session,
  userSubscriptions,
  isLoggedIn,
}: ComponentProps) => {

  const isUserAuthenticated = useMemo(() => {
    return isLoggedIn || group?.group_type === 'free_experience'
  }, [isLoggedIn, group])

  const finalGroup = useMemo(() => {
    return !!group ? group : !!post ? post?.group : !!session ? session?.group : null
  }, [post, group, session])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(userSubscriptions, finalGroup?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), finalGroup?.id)
    } else {
      return false
    }
  }, [userSubscriptions, finalGroup, isLoggedIn])

  const subscription = useMemo(() => {
    return getPriorityGroupSubscription(userSubscriptions, finalGroup?.id)
  }, [finalGroup, userSubscriptions])

  const isToMigrateSubscription = useMemo(() => {
    return subscription?.status === 'active' && subscription?.migration_flag === "to_migrate"
  }, [subscription])

  const isPaidSubscription = useMemo(() => {
    const freeSubscriptionTypes = ["guest", "free"]
    return !freeSubscriptionTypes?.includes(subscription?.type)
  }, [subscription])

  if (!isUserAuthenticated || !isUserSubscribed || !isToMigrateSubscription || !isPaidSubscription) {
    return <></>
  }

  return (
    <Card>
      <Title>Verifica tus datos de pago</Title>
      <div style={{ height: 18 }} />
      <Text style={{
        maxWidth: 290,
        margin: "0 auto"
      }}>
        Hola {user?.name}, {finalGroup?.group_name} actualizará el precio de la suscripción.<br /><br />Para mantener tu tarifa actual, por favor confirma tus datos de pago.
      </Text>
      <div style={{ height: 18 }} />
      <Text style={{ display: 'flex', gap: 4, justifyContent: 'center', alignItems: 'center' }}>
        <Link
          options={{ skin: 'white', size: 'sm', type: 'filled' }}
          href={`/checkout/change-payment-method?t=${subscription?.migration_token}`}
          target="_blank"
        >
          Completar verificación
          <Icon icon={arrowLinkIcon} color={'white'} size="20px" />
        </Link>
      </Text>
      <div style={{ height: 18 }} />
      <Text style={{ display: 'flex', gap: 4, justifyContent: 'center', alignItems: 'center' }}>
        Es rápido, seguro y te llevará un minuto.
      </Text>
      <div style={{ height: 18 }} />
      <Faq />
    </Card>
  )
}

const state = ({ userStore, groupStore, postStore, sessionStore}) => {
  const { data: user } = userStore.information;
  const { data: group } = groupStore.group;
  const { data: post } = postStore.post;
  const { data: session } = sessionStore.one;
  const { data: userSubscriptions } = userStore.subscriptions;
  const { isLoggedIn } = userStore;

  return {
    user,
    group,
    post,
    session,
    userSubscriptions,
    isLoggedIn,
  };
};

export default connect(state)(Component);
