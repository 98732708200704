const getPaginationString = (payload) => {
  console.log(payload)
  const sort = `_sort=${payload?._sort_by || 'published_at'}:${payload?._sort || 'DESC'}`
  const page = !!payload.page ? `&page=${payload.page ? payload.page : 12}` : ''
  const perPage = !!payload.per_page || !!payload.page ? `&perPage=${payload?.per_parge ? payload.per_parge : 12}` : ''
  console.log('page', perPage)
  return `${sort}${page}${perPage}`
}

export default getPaginationString
