import styled from "styled-components";
import Image from "../../../Image";
import { Link } from "react-router-dom";
import Icon from "../../../Icon";

const sidebarMenu = {
  opened: {
    width: 343,
    backgroundColor: "rgba(108, 92, 231, 0.16)",
  },
  closed: {
    width: 108,
    backgroundColor: "transparent",
  },
};

export const SidebarContent = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: white;
`;

export const Navbars = styled("div")`
  display: flex;
  flex-direction: column;
  flex-align: center;
  justify-content: space-between;
  width: 100%;
`;

export const Navbar = styled("ul")`
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  flex-align: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
`;

export const NavbarItem = styled("li")`
  list-style: none;
`;

export const NavbarItemWrapper = styled("button") <{
  isOpened;
  isActive;
  width;
}>`
  cursor: pointer;
  padding: 0px;
  position: relative;
  border: 0px;
  border-radius: 6px;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.Primary : "transparent"};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: 100%;
  height: 36px;

  transition: all 0.2s ease-in-out;
  outline: 1px solid
    ${(props) => (props.isActive ? props.theme.colors.Primary : "transparent")};

  ${(props) =>
    !props.isActive
      ? `&:hover {
    background-color: rgba(249, 249, 249, 1);

    & > span {
      z-index: 10;
      visibility: visible;
      opacity: 1;
    }
  }`
      : ""}
`;

export const NavbarItemLabel = styled("div") <{ isOpened; isActive }>`
  white-space: nowrap;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => (props.isActive ? "white" : props.theme.colors.Black75)};
`;

export const Profile = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const AvatarImage = styled("img") <{ isOpened }>`
  width: 80px;
  height: 80px;
  border-radius: 100%;
  outline: 2px solid white;
`;

export const Name = styled("p") <{ isOpened }>`
  margin: 5px 0px 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #2d3436;
`;

export const Username = styled("p") <{ isOpened }>`
  margin: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(45, 52, 54, 0.75);
`;
export const Mark = styled("div") <{ isActive }>`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${({ isActive }) => (isActive ? "white" : "#6c5ce7")};
  flex-shrink: 0;
  position: absolute;
  left: 29%;
  bottom: 21px;
`;
