import styled from "styled-components";
import ButtonUi from "../../../../components/Button";
import LinkUi from "../../../../components/Link";

export const Link = styled(LinkUi)`
  margin: 0px;
  border: 0px;
  border-radius: 33px;
  font-size: ${({ theme }) => theme.button.size['sm'].fontSize};
  height: 33px;
  font-weight: 500;
  letter-spacing: 0.2px;
  background-color: #14AE5C;
  color: white;
  transition: all .1s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  &:hover{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const Button = styled(ButtonUi)`
  margin: 0px;
  border: 0px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 14px;
  lin-height: 17px;
  letter-spacing: 0.2px;
  background-color: none;
  color: black;
  transition: all .1s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-decoration: none!important;
  padding: 8px;
  position: relative;

  &:after {
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: black;
  }
`;

export const Card = styled('div')`
  background: rgb(109,165,243);
  background: linear-gradient(180deg, rgba(109,165,243,1) 0%, rgba(152,195,255,1) 100%);
  padding: 28px 25px;
  transition: background .2s ease-in-out;
`

export const Text = styled('p')`
font-size: 16px;
line-height: 20px;
font-weight: 500;
margin: 0px;
color: white;
text-align: center;
`

export const Title = styled('h2')`
  font-size: 22px;
  line-height: 28px;
  margin: 0px;
  font-weight: 600;
  background: linear-gradient(-85deg, rgba(6,6,6,1) 0%, rgba(47,47,49,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
`