import React, { CSSProperties, ReactElement, useEffect, useRef, useState } from "react";
import {
  Collapse,
  CollapseToggle,
  CollapseContent,
  ToggleIcon,
  Separator,
  Arrow,
} from "./styles";
import Icon from "../Icon";
import chevronNext from "../../assets/icons/chevron-next.svg";

interface ComponentProps {
  children;
  toggleLabel: ReactElement | string;
  toggleIcon: string;
  startCollapsed?: boolean;
  style?: CSSProperties
}

const Component = ({ children, toggleLabel, toggleIcon, startCollapsed = false, style }: ComponentProps) => {
  const [isCollapsed, setIsCollapsed] = useState(startCollapsed);
  const [contentHeight, setContentHeight] = useState<number>();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current) {
      console.log(ref.current.clientHeight)
      setContentHeight(ref?.current?.clientHeight + 10);
    }
  }, [ref.current, children]);

  return (
    <Collapse {...(!!style && { style })}>
      {toggleLabel && (
        <CollapseToggle
          type="button"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {toggleIcon && (
            <ToggleIcon
              collapsed={isCollapsed}
              icon={toggleIcon}
              size="20"
              color="black"
            />
          )}

          {toggleLabel}


          <Arrow isCollapsed={isCollapsed}>
            <Icon icon={chevronNext} color="black" size="14px" />
          </Arrow>
        </CollapseToggle>
      )}

      <Separator collapsed={isCollapsed} />

      <CollapseContent collapsed={isCollapsed} contentHeight={contentHeight}>
        <div ref={ref}>
          {children}
        </div>
      </CollapseContent>
    </Collapse>
  );
};

export default Component;
