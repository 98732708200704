import { useHistory } from "react-router";
import Icon from "../Icon";
import { GoBack } from "./styles";
import goBackImg from "../../assets/icons/arrow-backward-short.svg";
import altGoBackImg from "../../assets/icons/arrow-backward.svg";
import { useWindowSize } from "../../hooks/useFormHook";
import { ReactElement } from "react";

interface ComponentProps {
  alt?: string;
  backTo?: string;
  children?: string | ReactElement;
  style?;
  showAlt?: boolean;
}

const Component = ({
  alt,
  backTo,
  children,
  showAlt = false,
  ...rest
}: ComponentProps) => {
  const { isMobile } = useWindowSize();
  const history = useHistory();

  return (
    <GoBack
      onClick={() => {
        if (!!backTo) {
          history.push(backTo);
        } else {
          history.length <= 0 && !!alt ? history.push(alt) : history.goBack();
        }
      }}
      {...rest}
    >
      <div>
        <Icon
          icon={showAlt ? altGoBackImg : goBackImg}
          color={isMobile && showAlt ? "black" : "#6C5CE7"}
          size={isMobile && showAlt ? "24px" : "10px"}
        />{" "}
        {(!isMobile || !showAlt) && (
          <span>{!!children ? children : "Volver"}</span>
        )}
      </div>
    </GoBack>
  );
};

export default Component;
