import {
  NEW_NOTIFICATION,
  RESET_NEW_NOTIFICATION,
} from "../types/notification/new";

import {
  UPDATE_NOTIFICATION,
  RESET_UPDATE_NOTIFICATION,
} from "../types/notification/update";

import {
  GET_ALL_NOTIFICATIONS,
  RESET_GET_ALL_NOTIFICATIONS,
} from "../types/notification/all";

import {
  GET_NOTIFICATION,
  RESET_GET_NOTIFICATION,
} from "../types/notification/one";
import { UpdateNotificationOutDto } from "../../types/notification";

export const newNotification = (payload: {
  name: string;
  description: string;
}) => {
  return { type: NEW_NOTIFICATION, payload };
};

export const resetNewNotification = () => {
  return { type: RESET_NEW_NOTIFICATION, payload: null };
};

export const updateNotification = (payload) => {
  return { type: UPDATE_NOTIFICATION, payload };
};

export const resetUpdateNotification = () => {
  return { type: RESET_UPDATE_NOTIFICATION, payload: null };
};

export const getAllNotifications = () => {
  return { type: GET_ALL_NOTIFICATIONS, payload: null };
};

export const ResetGetAllNotifications = () => {
  return { type: RESET_GET_ALL_NOTIFICATIONS, payload: null };
};

export const getOneNotification = (payload: { id: number }) => {
  return { type: GET_NOTIFICATION, payload };
};

export const resetGetOneNotification = () => {
  return { type: RESET_GET_NOTIFICATION, payload: null };
};
