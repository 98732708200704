import Icon from '../../../../../components/Icon'
import WarningIcon from '../../../../../assets/icons/warning.svg'
import {
  Card
} from './styles'

const Component = () => {
  return (
    <Card>
      <Icon icon={WarningIcon} size="63px" color="rgb(192, 56, 44)" />
      <div style={{ height: 20 }} />
      <span> No tienes ningun<br /> articulo asociado</span>
    </Card>)
}

export default Component