import { useEffect } from "react";
import { withRouter } from "react-router-dom";

/**
 * Si requieren que el scroll al top sea animado
 * agregar a los global styles la propiedad
 * scroll-behavior: smooth;
 */

const ScrollToTop = ({ match }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [match]);

  return null;
};

export default withRouter(ScrollToTop);
