import styled from "styled-components";
import ButtonUi from "../../../../components/Button";
import LinkUi from "../../../../components/Link";
import { rainbowBackgroundGradient } from "../../../../components/StyledComponents";

export const Button = styled(ButtonUi)`
  margin: 0px;
  border: 0px;
  border-radius: 30px;
  font-size: ${({ theme }) => theme.button.size['sm'].fontSize};
  height: ${({ theme }) => theme.button.size['sm'].height};
  width: 100%;
  max-width: 244px;
  font-weight: 500;
  letter-spacing: 0.2px;
  background-color: #14AE5C;
  color: white;
  transition: all .1s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  &:hover{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const Link = styled(LinkUi)`
  ${rainbowBackgroundGradient}
  border: 0px;
  height: 45px;
`;

export const ValidTill = styled("div")`
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  text-align: center;
  margin-top: 8px;
`;
