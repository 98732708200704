import { Post } from "../../../types/post";
export const GET_COMMUNITY_POSTS = "GET_COMMUNITY_POSTS";
export const GET_COMMUNITY_POSTS_SUCCESS = "GET_COMMUNITY_POSTS_SUCCESS";
export const GET_COMMUNITY_POSTS_ERROR = "GET_COMMUNITY_POSTS_ERROR";

export interface GetPosts {
  type: typeof GET_COMMUNITY_POSTS;
  payload: { group_id: number };
}

export interface GetPostsSuccess {
  type: typeof GET_COMMUNITY_POSTS_SUCCESS;
  payload: Post[];
}

export interface GetPostsError {
  type: typeof GET_COMMUNITY_POSTS_ERROR;
  payload: string | boolean;
}

export interface State {
  data: Post[] | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions = GetPosts | GetPostsSuccess | GetPostsError;
