import { ChangeEvent, useState} from 'react'
import { FormikErrors, FormikTouched } from 'formik';
import Input from "../../../../../../../components/FormikForm/Input";
import TextArea from '../../../../../../../components/FormikForm/TextArea';
import { Group } from '../../../../../../../types';
import { WelcomeContentSectionDto } from '../../../../../../../types/welcome-section';

interface ComponentProps {
  touched: FormikTouched<{ title: string; body: string; }>
  errors: FormikErrors<{ title: string; body: string; }>
  values: any
  setFieldValue: any
  handleChange: (e: ChangeEvent<any>) => void
  handleBlur: (e: ChangeEvent<any>) => void
}

const Component = ({
  touched,
  errors,
  values,
  setFieldValue,
  handleChange,
  handleBlur,
}: ComponentProps) => {
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <>
      <Input
        name="title"
        error={errors["title"]}
        touched={touched["title"]}
        step="any"
        placeholder="Meditaciones guiadas"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values["title"]}
        options={{
          label: "Nombre de la sección",
          marginBottom: 36,
        }}
      />

      <TextArea
        name="description"
        error={errors["description"]}
        touched={touched["description"]}
        value={values["description"]}
        step="any"
        placeholder="Se trata de..."
        onChange={(e) => {
          const input = e.target.value;
          // Replace double newlines with a single newline
          const sanitizedInput = input.replace(/\n/g, '');
          setFieldValue("description", sanitizedInput);
          //handleChange(e)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default action
            setErrorMessage('En esta descripción no puedes agregar saltos de linea');
          } else {
            setErrorMessage(''); // Clear the message for other keys
          }
        }}
        onBlur={handleBlur}
        maxLength={300}
        options={{
          label: "Ingresa una breve descripción",
          marginBottom: 0,
          minHeight: 63
        }}
      />
      {errorMessage && <div style={{ color: 'red', marginBottom: "20px", fontSize: "14px" }}>⚠️ {errorMessage}</div>}
    </>
  )
}

export default Component