import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import Submit from "../../../../../components/FormikForm/Submit";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import { deletePost, resetDeletePost } from "../../../../../store/actions/post";
import { Post } from "../../../../../types/post";
import remove from "../../../../../assets/remove.svg";
import Image from "../../../../../components/Image";

interface ComponentProps {
  postInformation: Post;
  deletePostStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ postInformation, deletePostStates }: ComponentProps) => {
  const dispatch = useDispatch();

  const handleDeletePost = () => {
    dispatch(deletePost(postInformation?.id));
  };

  const onModalClose = () => {
    if (deletePostStates.success) {
      dispatch(resetDeletePost());

      setTimeout(() => {
        window.history.back();
      }, 500);
    }
  };

  return (
    <Modal name="modal-delete-post" width="650" onClose={() => onModalClose()}>
      {deletePostStates.success ? (
        <>
          <div style={{ textAlign: "center" }}>
            <Image src={remove} width="102px" height="117px" alt={remove} />
          </div>
          <Text>
            <strong style={{ textAlign: "center", display: "block" }}>
              Contenido eliminado satisfactoriamente
            </strong>
          </Text>
        </>
      ) : (
        <>
          <Title>Vas a eliminar un artículo</Title>

          <Text>
            Estás por eliminar el artículo “{postInformation?.title}”. Una vez
            eliminado no podrás recuperarlo y quienes tenían acceso al mismo ya
            no podrán verlo.
          </Text>

          <AlignEnd>
            <Button
              options={{
                type: "outline",
                skin: "purple",
                size: "lg",
              }}
              style={{ marginRight: "16px" }}
              onClick={() => dispatch(hideModal())}
            >
              Cancelar
            </Button>
            <Submit
              form=""
              isSubmmiting={deletePostStates.loading}
              options={{
                type: "filled",
                skin: "purple",
                size: "lg",
              }}
              onClick={() => handleDeletePost()}
            >
              Eliminar articulo
            </Submit>
          </AlignEnd>
        </>
      )}
    </Modal>
  );
};

const state = ({ postStore }) => {
  const { data: postInformation } = postStore.post;
  const { states: deletePostStates } = postStore.deletePost;
  return {
    postInformation,
    deletePostStates,
  };
};

export default connect(state)(Component);
