import action from "./../helpers/rest-client";

export class ErrorGroup extends Error { }
class Group {
  public async exportSubscribers(groupId, filters) {
    const queryString = new URLSearchParams(filters).toString();
    action
      .Get({
        url: `/api/group/export/subscribers/${groupId}${!!queryString ? `?${queryString}` : ""}`,
      })
      .then((response) => {
        const binary = new Uint8Array(response.data);

        const buftype =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";

        const url = window.URL.createObjectURL(
          new Blob([binary], {
            type: buftype,
          })
        );

        // Create blob link to download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Suscriptores" + ".xlsx");

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      })
      .catch((error) => {
        return error;
      });
  }

  public async getGroupBySlug(slug: string) {
    return action
      .Get({
        url: `/api/group/name/${slug}`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorGroup(error.response.statusText);
      });
  }

  public async getGroupLastActivity({ group_id }: { group_id: number }) {
    return action
      .Get({
        url: `/api/group/last-activity/${group_id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getGroupById({ group_id }) {
    return action
      .Get({
        url: `/api/group/id/${group_id}`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorGroup(error.response.statusText);
      });
  }

  public async getTotalSubscribers({ group_id }) {
    return action
      .Get({
        url: `/api/group/total-subscribers/${group_id}`,
      })
      .then((response) => {
        return { totalSubscribers: response };
      })
      .catch((error) => {
        throw new ErrorGroup(error.response.statusText);
      });
  }

  public async getSubscribers({ id, page, filters }) {
    const queryString = new URLSearchParams(filters).toString();

    return action
      .Get({
        url: `/api/group/subscribers/${id}?page=${!!page ? page : 1}${!!queryString ? `&${queryString}` : ""
          }`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorGroup(error.response.statusText);
      });
  }

  public async getSubscriberTelegramGroup(group_id: string) {
    const response: string = await action
      .Get({
        url: `/api/group/telegram/subscriber/${group_id}`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorGroup(error.response.statusText);
      });

    return response;
  }

  public async getGroupMetrics({ id, filters }) {
    const queryString = new URLSearchParams(filters).toString();

    return action
      .Get({
        url: `/api/group/metrics/${id}${!!queryString ? `?${queryString}` : ""}`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  public async getExpertTelegramGroup(group_id: string) {
    const response: string = await action
      .Get({
        url: `/api/group/telegram/expert/${group_id}`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorGroup(error.response.statusText);
      });

    return response;
  }

  public async getExpertTelegramBotUrlForNotifications() {
    const response: string = await action
      .Get({
        url: `/api/group/telegram/expertbot-url`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorGroup(error.response.statusText);
      });

    return response;
  }

  public async newTelegramGroup(payload) {
    const response: string = await action
      .Post({
        url: `/api/group/telegram/`,
        body: payload,
        config: { timeout: 60000 * 5 },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorGroup(error.response.statusText);
      });

    return response;
  }

  public async deleteReview(review_id: number) {
    const response: string = await action
      .Del({
        url: `/api/group/review/${review_id}`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorGroup(error.response.statusText);
      });

    return response;
  }

  public async newGroup(payload) {
    return action
      .Post({
        url: "/api/group",
        body: {
          data: JSON.stringify(payload),
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorGroup(error.response.statusText);
      });
  }

  public async updateGroup(payload) {
    const { id, ...rest } = payload;

    return action
      .Put({
        url: "/api/group",
        body: {
          data: JSON.stringify(rest),
          id
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorGroup(error.response.statusText);
      });
  }

  public async updateGroupMainCurrency(payload) {
    return action
      .Put({
        url: "/api/group/main-currency",
        body: payload,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorGroup(error.response.statusText);
      });
  }
}

const group = new Group();

export default group;
