import {
  initialState,
  Actions,
  State,
  DELETE_REVIEW,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_ERROR,
  RESET_DELETE_REVIEW,
} from "../../types/group/delete-review";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case DELETE_REVIEW:
      return {
        ...state,

        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case DELETE_REVIEW_SUCCESS:
      return {
        ...state,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case DELETE_REVIEW_ERROR:
      return {
        ...state,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_DELETE_REVIEW:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducers;
