import styled from "styled-components";
import { AnimationFixed } from "../Animation";

export const Backdrop = styled("div")`
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: #000;
`;

export const TransparentBackdrop = styled("div")`
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
`;


export const SwipeAnimation = styled(AnimationFixed)`
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  pointer-events: auto;
`;

export const Swipe = styled("div")`
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

export const SwipeContent = styled("div")`
  background-color: white;
  border-radius: 12px 12px 0px 0px;
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
