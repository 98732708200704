import styled from "styled-components";

export const Intro = styled.div`
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  display: flex;
`;

export const Title = styled.h1`
  max-width: 440px;
  margin: 0px 0px 16px;
  font-weight: 700;
  font-size: 54px;
  line-height: 65px;
  text-align: right;
`;

export const SubTitle = styled.h3`
  max-width: 440px;
  margin: 0px;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: #69779b;
  text-align: right;
`;

export const Image = styled.img`
  align-self: center;
  max-width: 150px;
  margin-top: -20px;
  margin-bottom: 16px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    max-width: 547px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    align-self: flex-start;
  }
`;
