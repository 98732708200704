import {
  initialState,
  Actions,
  State,
  CHANGE_USER_PHONE,
  CHANGE_USER_PHONE_SUCCESS,
  CHANGE_USER_PHONE_ERROR,
  RESET_CHANGE_USER_PHONE,
} from "../../types/user/change-user-phone";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case CHANGE_USER_PHONE:
      return {
        ...state,
        states: {
          success: false,
          loading: true,
          error: false,
        },
      };
    case CHANGE_USER_PHONE_SUCCESS:
      return {
        ...state,
        states: {
          success: true,
          loading: false,
          error: false,
        },
      };
    case CHANGE_USER_PHONE_ERROR:
      return {
        ...state,
        states: {
          success: false,
          loading: false,
          error: action.payload,
        },
      };
    case RESET_CHANGE_USER_PHONE:
      return {
        ...state,
        states: {
          success: false,
          loading: false,
          error: false,
        },
      };
    default:
      return state;
  }
};

export default reducers;
