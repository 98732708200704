import styled from "styled-components";
import ImageUi from "../../../../components/Image";

export const Faqs = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0px 90px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    padding: 80px 0px;
    flex-direction: row;
`;

export const Questions = styled.div`
  padding-top: 40px;
`;

export const Guide = styled.div`
  color: ${(props) => props.theme.colors.PurpleVeryLight};
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 40px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    line-height: 32px;
    margin: 50px 0px 40px;
  }
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.colors.Black};
  text-align: center;
  font-size: 40px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    font-size: 50px;
    line-height: 72px;
    margin-bottom: 96px;
  }
`;

export const Img = styled(ImageUi)`
  height: 400px;
  border-radius: 16px 0px 0px 16px;
`;
