import slugify from "slugify";
import {
  FieldContainer,
  HelperLine,
  HelperText,
  Label,
  ErrorMessage,
} from "../Field";

import { Field, Checkbox, Input, OptionLabel, Checkmark } from "./styles";
import { useFormikFieldValidation } from "../../../hooks/useFormikFieldValidation";

const initialValue = {
  options: {
    skin: "base",
    size: "md",
  },
};

interface ComponentProps {
  name: string;
  value?: (string | number)[];
  disabled?: boolean;
  error?: {};
  touched?: {};
  checked?: boolean;
  items: {
    label: string;
    value: string | number;
    id?: string;
  }[];
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  options: {
    label?: string;
    skin?: "base";
    size?: "md";
    helperText?: string;
    before?: any;
    after?: any;
    options?: number;
    hidden?: boolean;
    marginBottom?: number;
  };
}

const Component = ({
  options,
  error,
  touched,
  items,
  name,
  value,
  disabled,
  onFocus,
  onBlur,
  onChange,
}: ComponentProps) => {
  const { classes } = useFormikFieldValidation(error, name, disabled);
  const { skin, size, marginBottom } = {
    ...initialValue.options,
    ...options,
  };

  const handleOnChange = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnBlur = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    !!callback && callback(event);
  };

  return (
    <FieldContainer marginBottom={marginBottom}>
      {options?.label && (
        <Label size={size} htmlFor={`input-${name}`}>
          {options.label}
          {/* {rules && rules.required && <Required>*</Required>} */}
        </Label>
      )}

      <Field skin={skin} size={size} className={classes}>
        {items.map((item, index) => {
          const { label: itemLabel, value: itemValue } = item;
          const id = `${slugify(itemLabel || "", { lower: true })}-${index}`;

          return (
            <Checkbox size={size}>
              <Input
                type="checkbox"
                name={name}
                id={item?.id || id}
                value={itemValue}
                disabled={disabled}
                checked={value && value.includes(itemValue)}
                className={`checkbox_animated ${!!error && !!touched ? "error-field" : ""
                  }`}
                onChange={(event) => handleOnChange(event, onChange)}
                onBlur={(event) => handleOnBlur(event, onBlur)}
                onFocus={(event) => handleOnFocus(event, onFocus)}
                size={size}
              />
              <OptionLabel
                size={size}
                disabled={disabled}
                htmlFor={item?.id || id}
                withLabel={!!item.label}
              >
                <Checkmark size={size} />
                {item.label}
              </OptionLabel>
            </Checkbox>
          );
        })}
      </Field>

      {((options && options.helperText) || error) && (
        <HelperLine marginBottom={marginBottom || 40}>
          {!!error && !!touched && <ErrorMessage>{error}</ErrorMessage>}
          {options && options.helperText && !error && (
            <HelperText style={{ paddingLeft: "29px" }}>
              {options.helperText}
            </HelperText>
          )}
        </HelperLine>
      )}
    </FieldContainer>
  );
};

export default Component;
