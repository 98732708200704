import { connect, useDispatch } from 'react-redux'
import Icon from '../../../../../../../../../components/Icon'

import {
  Card,
  Title,
  Description,
  Button,
  Actions
} from './styles'


import DragAndDropIcon from '../../../../../../../../../assets/icons/sort-icon-2.svg'
import DeleteIcon from '../../../../../../../../../assets/icons/trash.svg'
import { WelcomeContentPostDto } from '../../../../../../../../../types/welcome-section'

interface ComponentProps {
  welcomeContent?: WelcomeContentPostDto
  setSelectedContentToBeDeleted
  setShowSidebar
  showActions?: boolean;
}

const Component = ({
  welcomeContent,
  setSelectedContentToBeDeleted,
  setShowSidebar,
  showActions = true,
}: ComponentProps) => {
  return (
    <div style={{ display: 'flex', gap: 10 }}>
      <Card>
        <Icon icon={DragAndDropIcon} size="40px" />
        <Title>{welcomeContent?.title}</Title>
      </Card >

      {showActions && (
        <Actions>
          <Button
            onClick={() => {
              setShowSidebar(true)
              setSelectedContentToBeDeleted(welcomeContent)
            }}
          >
            <Icon icon={DeleteIcon} size="16px" color="black" />
          </Button>
        </Actions>
      )}
    </div>
  )
}

export default Component