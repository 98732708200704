import { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { Formik } from "formik";
import Form from '../Form'
import ConfirmSend from "./components/ConfirmSend"
import Submit from "../../../../../../../../components/FormikForm/Submit";
import {  getGroupSubscribers } from "../../../../../../../../store/actions/group";
import Link from "../../../../../../../../components/Link";

import {
  Text,
  VerticalScroll,
  Box,
  Label,
  AddedEmail,
  ExpirationDate,
  RemoveEmailBtn
} from "../../styles";
import {
  initialValues,
  schema,
} from "../../../../../../../../constants/forms/invitations";
import dateHelpers from "../../../../../../../../helpers/dates-hanlder"
import Spinner from '../../../../../../../../components/Loader'
import Icon from "../../../../../../../../components/Icon";
import Button from "../../../../../../../../components/Button";
import Textarea from "../../../../../../../../components/FormikForm/TextArea";
import { Center } from "../../../../../../../../components/StyledComponents";

import CalendarIcon from "../../../../../../../../assets/icons/calendar.svg";
import CloseCircledIcon from "../../../../../../../../assets/icons/close_circled.svg";
import SendIcon from "../../../../../../../../assets/icons/send.svg";
import AddCircledIcon from "../../../../../../../../assets/icons/add_circled.svg";

import { Group } from "../../../../../../../../types";
import { createInvitation, resetCreateInvitation } from "../../../../../../../../store/actions/invitation";
import { CreateInvitationOutDto } from "../../../../../../../../types/invitation.dto";
import { CreateInvitation } from "../../../../../../../../store/types/invitation/create";
import { showModal } from "../../../../../../../../store/actions/modal";
import { useWindowSize } from "../../../../../../../../hooks/useFormHook";


interface ComponentProps {
  group: Group
  onClose
  createdInvitation: CreateInvitation
  createInvitationStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  }
  cancelButtonRef: React.RefObject<HTMLButtonElement>
}

const Component = ({ group, onClose, createdInvitation, createInvitationStates, cancelButtonRef }: ComponentProps) => {
  const dispatch = useDispatch();
  const theme = useTheme() as { colors: { Primary: string } }
  const [isValidating, setIsValidating] = useState(false)
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emails, setEmails] = useState<{ email: string, expire_at: string }[]>([])
  const { isMobile } = useWindowSize();



  const messageFieldRef = useRef<HTMLTextAreaElement>(null);

  const onSubmit = () => {
    const payload: CreateInvitationOutDto = {
      subscribers: emails.map((item) => {
        const { email, expire_at } = item;
        return {email, expire_at: expire_at ? dateHelpers.setToZeroTimeZone(expire_at) : expire_at}
        //return { email, expire_at: expire_at ? dateHelpers.setToZeroTimeZone(expire_at) : expire }
      }),
      invite_message: messageFieldRef?.current?.value,
      idGroup: group?.id
    }

    dispatch(createInvitation(payload))
  };

  const emailAlreadyInList = (email) => {
    if (!email) return true;

    const foundElement = emails?.find(item => item?.email === email)

    if (foundElement) {
      return true;
    } else {
      return false;
    }
  }

  const addEmail = ({ values, actions }) => {
    const emailAlreadyListed = emailAlreadyInList(values.email);

    if (emailAlreadyListed) {
      actions.setFieldError('email', 'El e-mail ingresado ya se encuentra agregado al listado.')
    } else {
      setEmails((oldState) => [...oldState, { email: values.email, expire_at: values.expire_at }])
      setTimeout(() => {
        actions.resetForm();
        actions.setFieldValue('expire_at_type', values.expire_at_type);
        actions.setFieldValue('expire_at', values.expire_at);
      }, 500)
    }
  };

  const removeEmail = (index) => {
    setEmails((oldState) => {
      const updatedEmails = [...oldState];
      updatedEmails.splice(index, 1);
      return updatedEmails;
    });
  }


  useEffect(() => {
    if (createInvitationStates.success) {
      setTimeout(() => {
        dispatch(resetCreateInvitation());
        dispatch(getGroupSubscribers({ page: 1, id: group?.id, filters: [] }));
        setEmails([]);
      }, 1000)
    }
  }, [createInvitationStates, group])

  useEffect(() => {
    if (messageFieldRef.current) {
      messageFieldRef.current.value = `¡Hola 👋!\n\nMe emociona enviarte un acceso a mi ${group?.group_type === "membership" ? "Membresia" : "Taller"} ${group?.group_name}, donde juntos emprenderemos un emocionante proceso de transformación 🌟.\n\nTe espero alla adentro!`;
      // messageFieldRef.current.value = !preFilledEmail 
      // ? `¡Hola 👋!\n\nMe emociona enviarte un acceso a mi ${group?.group_type === "membership" ? "Membresia" : "Taller"} ${group?.group_name}, donde juntos emprenderemos un emocionante proceso de transformación 🌟.\n\nTe espero alla adentro!`
      // : `¡Hola 👋!\n\nHe reactivado tu acceso a mi ${group?.group_type === "membership" ? "Membresia" : "Taller"} ${group?.group_name}! Te espero adentro para continuar emprendiendo un emocionante proceso de transformación 🌟`
    }
  }, [group])

  return (
    <>
      <Formik
        initialValues={{
          ...initialValues 
        }}
        onSubmit={(values, actions) => {
          if (addEmail) addEmail({ values, actions });
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={schema({ idGroup: group?.id, validationRunning: setIsValidating, addedEmails: emails })}
        enableReinitialize
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
          setFieldTouched,
          validateField,
          resetForm
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="add-invitation-form"
            >
              <Form {...{
                touched,
                errors,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldError,
                setFieldTouched,
                isLoading,
                validateField,
                isValidating
              }} />

              <button
                type="button"
                ref={cancelButtonRef}
                onClick={() => {
                  resetForm();
                  setFieldValue('expire_at_type', 'indefinido')
                  if (messageFieldRef?.current) {
                    messageFieldRef.current.value = `¡Hola 👋!\n\nMe emociona enviarte un acceso a mi ${group?.group_type === "membership" ? "Membresia" : "Taller"} ${group?.group_name}, donde juntos emprenderemos un emocionante proceso de transformación 🌟.\n\nTe espero alla adentro!`;
                  }
                  onClose();
                  setEmails([]);
                }}
                style={{ display: "none" }}
              >
                cancel
              </button>

              <Submit
                form="add-invitation-form"
                isSubmmiting={isLoading || isValidating}
                options={{
                  type: "outline",
                  skin: "purple",
                  size: "lg",
                }}
              >
                <Center vertical="center" horizontal="center" gap={5}>
                  <Icon icon={AddCircledIcon} color={theme.colors.Primary} size="20px" />
                  Agregar
                </Center>
              </Submit>
              <p style={{color: 'gray', fontSize: "12px"}}>Puedes agregar varios invitados con el mismo envio. Por cada invitado completa el formulario y toca en el botón "Agregar"</p>
            </form>
          )
        }}
      </Formik>

      <div style={{ height: 20 }} />

      <Label>Accesos por generar ({emails?.length}):</Label>
      <Box style={{ height: 10 + 50 * (emails.length || 1) + (12 * emails.length) }}>
        <VerticalScroll>
          <div>
            {emails.map((email, index) => {
              const emailParts = email.email.split("@");
              return (
                <AddedEmail>
                  {emailParts[0].length > 15 ? emailParts[0].substring(0, 15) + `...@${emailParts[1]}` : email.email}
                  <ExpirationDate>
                    {email.expire_at && (<Icon icon={CalendarIcon} color="#2D3436" size="17px" />)}
                    {!isMobile ? (<span>{email.expire_at ? dateHelpers.getDateInLocalTimeZone(email.expire_at) : "Indefinido"}</span>) : "" } 

                    <RemoveEmailBtn
                      onClick={() => removeEmail(index)}>
                      <Icon icon={CloseCircledIcon} color="#2D3436" size="20px" />
                    </RemoveEmailBtn>
                  </ExpirationDate>
                </AddedEmail>
              )
            })}
          </div>
        </VerticalScroll>
      </Box>

      <div style={{ height: 20 }} />

      <Textarea
        name="message"
        placeholder="Deja tu mensaje aquí..."
      
        forwardRef={messageFieldRef}
        options={{
          label: "Mensaje de invitación",
          helperText: "Enviaremos un email en tu nombre e incluiremos este mensaje personalizado junto las instrucciones para activar el acceso. Te recomendamos seguir un formato parecido al que viene pre-establecido.",
          marginBottom: 0,
          minHeight: 130
        }}
      />

      <div style={{ height: 30 }} />

      <Center horizontal="space" vertical="center" gap={12}>
        <Button
          options={{
            type: "outline",
            skin: "purple",
            size: "lg",
            block: true,
          }}
          onClick={() => {
            if (cancelButtonRef?.current) {
              cancelButtonRef.current.click();
            }
          }}
        >
          Cancelar
        </Button>

        <Button
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
            block: true,
          }}
          disabled={emails.length <= 0}
          onClick={() => { dispatch(showModal("confirm-send-modal")) }}
        >
          <Center vertical="center" horizontal="center" gap={5}>
            {createInvitationStates?.loading ? (
              <Spinner color="white" />
            ) : (
              <>
                <Icon icon={SendIcon} color="#FFFFFF" size="20px" />
                Enviar invitaciones
              </>
            )}
          </Center>
        </Button>
      </Center>

      <div style={{ height: 30 }} />

      
      {/* {group?.group_type === "membership" && (
        <Text>⚠️ Por cada suscriptor que acepte la invitación y haga uso del acceso, se incurrirá en una pequeña tarifa.{" "}
          <Link
            href="https://www.notion.so/klouser/C-mo-funcionan-los-costos-de-los-Invitados-con-Acceso-Libre-c135cf7c7ca34d15a8a133074da5aae9"
            target={"_blank"}
            options={{
            type: "link",
              size: "sm",
              skin: "purple",
              native: false
            }}
          >
            Leer más
          </Link>
        </Text>
      )}

      {group?.group_type === "experience" && (
        <Text>⚠️ Por cada suscriptor que acepte la invitación y haga uso del acceso, se incurrirá en una pequeña tarifa.{" "}
          <Link
            href="https://www.notion.so/klouser/C-mo-funcionan-los-costos-de-los-Invitados-con-Acceso-Libre-c135cf7c7ca34d15a8a133074da5aae9"
            target={"_blank"}
            options={{
              type: "link",
              size: "sm",
              skin: "purple",
              native: true,
            }}
          >
            Leer más
          </Link>

        </Text>
      )} */}

      <ConfirmSend onSubmit={onSubmit} emails={emails} />
    </>
  )
}

const state = ({ groupStore, invitationStore }) => {
  const { data: group } = groupStore.group;
  const { data: createdInvitation, states: createInvitationStates } = invitationStore.createInvitation;

  return {
    group,
    createdInvitation,
    createInvitationStates
  };
};

export default connect(state)(Component);