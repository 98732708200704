import styled from "styled-components";
import ImageUi from "../../../../components/Image";
import { Link as RrdLink } from "react-router-dom";

export const Start = styled.div`
  position: relative;
  margin-bottom: 80px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin-bottom: 150px;
  }
`;

export const Image = styled(ImageUi)`
  position: absolute;
  width: 85px;
  height: 115px;
  top: 70px;
  opacity: 0.15;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    min-width: 260px;
    min-height: 320px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    top: 0px;
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  line-height: 46px;
  font-weight: 700;
  margin: 0px 0px 50px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 48px;
    line-height: 60px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    font-size: 50px;
    margin-bottom: 100px;
  }
`;

export const StepOne = styled.div`
  margin-bottom: 20px;
`;

export const StepTwo = styled.div`
  margin-bottom: 20px;
`;

export const StepThree = styled.div`
  margin-bottom: 60px;
`;

export const Img = styled(ImageUi)`
  width: auto;
  height: 200px;
  display: block;
  margin: 0 auto 30px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const TitleStep = styled.div`
  font-size: 18px;
  line-height: 25px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 22px;
    line-height: 32px;
  }
`;

export const LinkItem = styled.div`
  margin: 0px auto;
  display: inline-flex;
  border-radius: 8px;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    flex-direction: row;
  }

  & > * {
    margin: 20px 0px;

    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      margin: 0px 20px;
    }
  }
`;

export const Link = styled(RrdLink)<{ to }>`
  padding: 0px;
  line-height: 23px;
  font-size: 18px;
  font-weight: 600;
  color: white;
  background: ${(props) => props.theme.colors.PurpleVeryLight};
  border-radius: 8px;
  height: 52px;
  line-height 52px;
  text-align: center;
  text-decoration: none;
  min-width: 230px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 18px;
    min-width: 280px;
  }
`;

export const Information = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  cursor: pointer;
`;
