import { PublishModulesInDto, PublishModulesOutDto } from "../../../types/module";

export const PUBLISH_MODULES = "PUBLISH_MODULES";
export const PUBLISH_MODULES_SUCCESS = "PUBLISH_MODULES_SUCCESS";
export const PUBLISH_MODULES_ERROR = "PUBLISH_MODULES_ERROR";
export const RESET_PUBLISH_MODULES = "RESET_PUBLISH_MODULES";

export interface PublishModules {
  type: typeof PUBLISH_MODULES;
  payload: PublishModulesOutDto;
}

export interface PublishModulesSuccess {
  type: typeof PUBLISH_MODULES_SUCCESS;
  payload: PublishModulesInDto;
}

export interface PublishModulesError {
  type: typeof PUBLISH_MODULES_ERROR;
  payload: string | boolean;
}

export interface ResetPublishModules {
  type: typeof RESET_PUBLISH_MODULES;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | PublishModules
  | PublishModulesSuccess
  | PublishModulesError
  | ResetPublishModules;
