import styled from "styled-components";

export const FilterTag = styled("span")`
  background: #f0f0f0;
  border-radius: 100px;
  padding: 10px 18px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #2d3436;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 29px;
  flex-shrink: 0;
  max-width: 100%;
`;

export const AppliedFilters = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  flex-wrap: wrap;
`;

export const DeleteFilter = styled("button")`
  margin: 0px;
  border: 0px;
  padding: 0px;
  background: none;
  cursor: pointer;
`;
