import { Link as NativeLink } from "react-router-dom";
import styled, { css } from "styled-components";

const card = (props) => {
  return `
  background-color: ${props.active ? "#e5e3f8" : "#fff"};
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: ${props.size === "small" ? "0px" : "132px"};
  flex-direction: row;
  text-decoration: none;
  cursor: pointer;
  text-align: left;
  padding: ${
    props.size === "small"
      ? "16px"
      : props.active
      ? "16px 16px 16px 34px"
      : "16px"
  };
  position: relative;

  
  ${
    props.active &&
    "&:after { content: ''; top: 50%; left: 14px; transform: translate(0, -50%); position: absolute; width: 6px; height: 96px; border-radius: 3px; background-color: white; }"
  }
  
  @media (min-width: ${props.theme.mediaBreakpoints.smMin}) {
    padding: ${props.size === "small" ? "8px 13px" : "20px 24px"};
    margin-bottom: 0;
  }
`;
};

export const NativeLinkCard = styled(NativeLink)<{ size; active }>`
  ${(props) => card(props)}
`;

export const LinkCard = styled("a")<{ size; active }>`
  ${(props) => card(props)}
`;

export const HideInMobile = styled("span")`
  display: none;

  @media (min-width: ${({ theme }) => theme.mediaBreakpoints.lgMin}) {
    display: block;
  }
`;

export const ButtonCard = styled("button")<{ size; active }>`
  margin: 0px;
  padding: 0px;
  border: 0px;
  background: none;
  ${(props) => card(props)}
`;

export const Image = styled("img")<{ size }>`
  display: block;
  margin-right: 8px;
  width: ${({ size }) => (size === "small" ? "46px" : "60px")};
  height: ${({ size }) => (size === "small" ? "46px" : "60px")};

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    width: ${({ size }) => (size === "small" ? "46px" : "76px")};
    height: ${({ size }) => (size === "small" ? "46px" : "76px")};
    // margin-right: 14px;
  }
`;

export const Text = styled("div")<{ size }>`
  display: block;
  width: 100%;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: ${(props) => {
      return props.size === "small" ||
        props.size === "xsmall" ||
        props.size === "normal"
        ? "0px"
        : "15px 0px";
    }};
    // margin-right: 8px;
  }
`;

export const Title = styled("h3")<{ size; hasDescription }>`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2d3436;
  margin: 0px 0px ${({ hasDescription }) => (hasDescription ? "8px" : "0px")};

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: ${(props) => (props.size === "small" ? "0px" : "0px 0px 9px")};
    font-size: 16px;
    line-height: 19px;
  }
`;

export const Description = styled("p")<{ size }>`
  font-size: 12px;
  line-height: 14px;
  min-height: ${({ size }) => (size === "xsmall" ? "68px" : "0px")};
  color: rgba(45, 52, 54, 0.75);
  margin: 0;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export const SuffixContainer = styled("span")<{ background: string }>`
  width: 68px;
  height: 68px;
  border-radius: 34px;
  flex-shrink: 0;
  background-color: ${(props) =>
    props.background ? props.background : "rgba(219, 243, 244, 1)"};
  display: flex;
  align-items: center;
  justify-content: center;
`;
