import { Group } from "../../../../types";

export const DELETE_COVER_PIC = "DELETE_COVER_PIC";
export const DELETE_COVER_PIC_SUCCESS = "DELETE_COVER_PIC_SUCCESS";
export const DELETE_COVER_PIC_ERROR = "DELETE_COVER_PIC_ERROR";

export interface DeleteCoverPic {
  type: typeof DELETE_COVER_PIC;
  payload: null;
}

export interface DeleteCoverPicSuccess {
  type: typeof DELETE_COVER_PIC_SUCCESS;
  payload: Group;
}

export interface DeleteCoverPicError {
  type: typeof DELETE_COVER_PIC_ERROR;
  payload: boolean | string;
}

export interface State {
  data: Group | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}
