import { ReactNode } from "react";
import { Link, NativeLink, DisabledLink } from "./styles";
import Loader from "../Loader";
interface LinkProps {
  href: string;
  style?: any;
  options: {
    type: "filled" | "outline" | "link";
    size: "xs" | "sm" | "md" | "lg" | "xl";
    skin: "purple" | "transparent" | "lightBlue" | "lightGray" | "white" | "white-black" | "lightGreen";
    block?: boolean;
    native?: boolean;
  };
  target?: string;
  children: string | ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  noFollow?: boolean;
}

const Component = ({
  href,
  disabled,
  loading,
  target,
  options,
  children,
  ...rest
}: LinkProps) => {
  const { native = true, block } = options;

  if (disabled) {
    return (
      <DisabledLink {...options} {...rest} block={block}>
        {loading ? <Loader /> : children}
      </DisabledLink>
    );
  }

  if (native) {
    return (
      <NativeLink
        to={href}
        {...options}
        {...rest}
        target={target}
        block={block}
      >
        {children}
      </NativeLink>
    );
  }

  return (
    <Link href={href} {...options} {...rest} target={target} block={block}>
      {children}
    </Link>
  );
};

export default Component;
