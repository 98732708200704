import { signOut } from "../../store/actions/auth";

import academy from "../../assets/dashboard-menu/academy.svg";
import members from "../../assets/dashboard-menu/members-list.svg";
import logOut from "../../assets/dashboard-menu/log-out.svg";
import groups from "../../assets/dashboard-menu/groups.svg";
import home from "../../assets/dashboard-menu/home.svg";
import profile from "../../assets/dashboard-menu/profile.svg";
import settings from "../../assets/dashboard-menu/settings.svg";
import community from "../../assets/dashboard-menu/community.svg";
import membership from "../../assets/dashboard-menu/membership.svg";
import experience from "../../assets/dashboard-menu/one-time-payment.svg";
import coupons from "../../assets/dashboard-menu/coupons.svg";
import category from "../../assets/dashboard-menu/category.svg";


interface MenuItem {
  active: boolean;
  icon: string;
  native?: boolean;
  url?: string;
  action?: () => void;
  label: string;
  expert?: boolean;
  newFeatureMark?: string[];
  key?: string[];
}

const mainMenu: MenuItem[] = [
  {
    active: false,
    icon: home,
    native: true,
    expert: false,
    url: "/dashboard/home",
    label: "Home",
  },
  {
    active: true,
    icon: community,
    native: true,
    expert: false,
    url: "/dashboard/community",
    label: "Audiencia",
    key: ["PREVIEW_COMMUNITY"],
  },
  {
    active: true,
    icon: experience,
    native: true,
    expert: true,
    url: "/dashboard/experience",
    label: "Talleres",
    key: [
      "NEW_EXPERIENCE",
      "EDIT_EXPERIENCE",
      "LIST_EXPERIENCE",
      "PREVIEW_EXPERIENCE",
    ],
  },
  {
    active: true,
    icon: membership,
    native: true,
    expert: true,
    url: "/dashboard/membership",
    label: "Membresías",
    key: [
      "NEW_MEMBERSHIP",
      "EDIT_MEMBERSHIP",
      "LIST_MEMBERSHIP",
      "PREVIEW_MEMBERSHIP",
    ],
  },
  {
    active: true,
    icon: coupons,
    native: true,
    expert: true,
    url: "/dashboard/coupon",
    label: "Cupones",
    key: ["LIST_COUPONS", "NEW_COUPON", "EDIT_COUPON"],
  },
  {
    active: true,
    icon: category,
    native: true,
    expert: false,
    url: "/profile/subscriptions",
    label: "Mis suscripciones",
    key: ["SUBSCRIPTIONS_LIST"],
  },
];

const secondaryMenu: MenuItem[] = [
  {
    active: true,
    icon: academy,
    native: false,
    url: "https://klouser.notion.site/Klouser-Academy-9d91b9fc371f4288935928ce9ccc11ae",
    label: "Klouser Academy",
  },
  {
    active: true,
    icon: settings,
    native: true,
    url: "/profile/my-account",
    label: "Mi cuenta",
    newFeatureMark: ["welcome-notification-page-modal"],
  },
  {
    active: true,
    icon: logOut,
    action: signOut,
    label: "Cerrar sesión",
  },
];


export default { mainMenu, secondaryMenu, };
