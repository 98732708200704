export const CHANGE_USERNAME = "CHANGE_USERNAME";
export const CHANGE_USERNAME_SUCCESS = "CHANGE_USERNAME_SUCCESS";
export const CHANGE_USERNAME_ERROR = "CHANGE_USER_EMAIL_ERROR";
export const RESET_CHANGE_USERNAME = "RESET_CHANGE_USERNAME";

export interface ChangeUsername {
  type: typeof CHANGE_USERNAME;
  payload: string;
}

export interface ChangeUsernameSuccess {
  type: typeof CHANGE_USERNAME_SUCCESS;
  payload: string;
}

export interface ChangeUsernameError {
  type: typeof CHANGE_USERNAME_ERROR;
  payload: boolean | string;
}

export interface ResetChangeUsername {
  type: typeof RESET_CHANGE_USERNAME;
  payload: null
}

export interface State {
  states: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
}

export const initialState: State = {
  states: {
    loading: false,
    error: false,
    success: false,
  },
};

export type Actions =
  | ChangeUsername
  | ChangeUsernameSuccess
  | ChangeUsernameError
  | ResetChangeUsername;
