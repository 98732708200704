import { Post } from "../../../types/post";

export const REORDER_FEATURED_POSTS = "REORDER_FEATURED_POSTS";
export const REORDER_FEATURED_POSTS_SUCCESS = "REORDER_FEATURED_POSTS_SUCCESS";
export const REORDER_FEATURED_POSTS_ERROR = "REORDER_FEATURED_POSTS_ERROR";

export interface reorderFeaturedPosts {
  type: typeof REORDER_FEATURED_POSTS;
  payload: Post[];
}

export interface reorderFeaturedPostsSuccess {
  type: typeof REORDER_FEATURED_POSTS_SUCCESS;
  payload: Post[] | null;
}

export interface reorderFeaturedPostsError {
  type: typeof REORDER_FEATURED_POSTS_ERROR;
  payload: boolean | string;
}

export interface State {
  data: Post[] | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions = reorderFeaturedPosts | reorderFeaturedPostsSuccess | reorderFeaturedPostsError;
