import styled, { css } from "styled-components";
import LinkUi from "../../../../components/Link";
import AlertUi from "../../../../components/Alert";
import { HorizontalScroll as HorizontalScrollUi } from "../../../../components/StyledComponents";

export const CategoryName = styled('h3')`
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
  
`

export const HorizontalScrollWrapper = styled('div')`
  margin: 0px -10px;
`

export const HorizontalScroll = styled('div')`
  overflow-x: scroll;
  display: flex;
  scroll-snap-type: x mandatory;
  padding: 10px;
  scroll-padding: 0 10px;

  ::-webkit-scrollbar {
    display: none;
  }

  & > * {
    scroll-snap-align: start;
    max-width: 300px;

    &:not(:last-child){
      margin-right: 16px;
    }
  }
`

export const CardsGrid = styled("div")`
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  padding: 0px 0px 0px 10px;
  
  @media(min-width: 768px){
    padding: 0px 0px 0px 0px;
  }
`;

export const Breadcrumb = styled("div")`
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0px 0px 24px 0px;
  margin-botton: 10px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    margin: 0px 0px 40px;
  }
`;

export const Alert = styled(AlertUi)``;

export const TitleTwo = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: 767px) {
    min-height: 90px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const TextImg = styled("p")`
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  & > span {
    font-style: oblique;
    font-weight: 100;
  }
`;

export const Text = styled("p")`
  margin-top: 0px;
`;

export const Link = styled(LinkUi)`
  &:first-child {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-right: 24px;

    text-decoration: none;
    color: ${(props) => props.theme.colors.Primary};

    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  &:last-child {
    text-decoration: none;
    color: ${(props) => props.theme.colors.Black};
    margin: 16px 0px 36px 0px;
    padding: 0px;
    border: 0px;
    background-color: ${(props) => props.theme.colors.Gray1};
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding: 16px;
    border-radius: 16px;
    cursor: pointer;
    width: 100%;

    img {
      margin-right: 16px;
    }

    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      max-width: 230px;
      margin-top: 0px;
    }
  }
`;

const verticalPosition = {
  top: "flex-start",
  bottom: "flex-end",
  center: "center",
};

const horizontalPosition = {
  left: "flex-start",
  right: "flex-end",
  center: "center",
  space: "space-between",
};

export const Center = styled("div") <{
  vertical: "top" | "center" | "bottom";
  horizontal: "left" | "center" | "right" | "space";
  gap?: number;
  column?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  justify-content: ${({ horizontal }) => horizontalPosition[horizontal]};
  align-items: ${({ vertical }) => verticalPosition[vertical]};
  gap: ${({ gap }) => (gap ? gap : 0)}px;
`;

export const EmptyStateCard = styled("div")`
  text-decoration: none;
  color: inherit;
  display: block;
  background-color: #e0e4ea;
  overflow: hidden;
  border-radius: 16px;
  min-height: 250px;
  position: relative;
  margin-bottom: 16px;
`;

const Card = css`
  width: 484px;
  height: 118px;
  left: 377px;
  top: 169px;

  background: #ffffff;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
`;

export const NewFreeContent = styled("div")`
  ${Card}
`;

export const NewPremiumContent = styled("div")`
  ${Card}
`;
