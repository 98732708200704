import {
  Title,
  HorizontalScrollWrapper,
  HorizontalScroll,
} from "./styles";
import CardAticle from "../../../../Dashboard/Posts/components/CardArticle";
import { Separator } from "../../../../../components/StyledComponents";
import { Post } from "../../../../../types/post";
import { connect } from "react-redux";
import { Group } from "../../../../../types";

interface ComponentProps {
  group: Group;
  featuredPosts: { data: Post[] };
  featuredPostsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  featuredPosts,
  featuredPostsStates,
  group,
}: ComponentProps) => {
  if (
    !featuredPosts ||
    featuredPosts?.data?.filter(p => ((!p.published_at && !!p.show_scheduled_post) || !!p.published_at))?.length <= 0 ||
    !!featuredPostsStates.loading
  ) {
    return null;
  }

  return (
    <>
      <Title>Pineados</Title>

      <Separator height={{ desktop: "16px", mobile: "16px" }} />

      <HorizontalScrollWrapper>
        <HorizontalScroll >
          {featuredPosts?.data?.filter(p => ((!p.published_at && !!p.show_scheduled_post) || !!p.published_at)).map((post, index) => (
            <CardAticle
              post={post}
              key={`featured-content-${index}`}
              groupInformation={group}
              showPinAction={false}
            />
          ))}
        </HorizontalScroll>
      </HorizontalScrollWrapper>


      <div style={{ height: 26 }} />
    </>
  );
};

const state = ({ userStore, postStore, groupStore }) => {
  const { data: featuredPosts, states: featuredPostsStates } =
    postStore.featuredPosts;
  const { data: group } = groupStore.group;
  return {
    group,
    featuredPosts,
    featuredPostsStates,
  };
};

export default connect(state)(Component);
