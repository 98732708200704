import { createRef, CSSProperties, ReactElement, useEffect, useState } from "react";
import Dropdown from "./partials";
import Icon from '../Icon';
import chevronDownIcon from '../../assets/icons/chevron-down.svg';

interface ComponentProps {
  label?: ReactElement | string
  icon?: string
  position?: 'bottom-centered' |
  'bottom-left' |
  'bottom-right' |
  'top-centered' |
  'top-left' |
  'top-right' |
  'left-centered' |
  'left-top' |
  'left-bottom' |
  'right-centered' |
  'right-top' |
  'right-bottom';
  children: ReactElement[]
  staticLabel?: boolean
  styles?: {
    wrapper?: CSSProperties
    toggle?: CSSProperties
    list?: CSSProperties
    item?: CSSProperties
    icon?: CSSProperties
  }
}

const Components = ({ children, staticLabel = true, position = 'bottom-left', label, styles, icon = chevronDownIcon, ...rest }: ComponentProps) => {
  const dropdownRef = createRef<HTMLElement>();
  const [isOpen, setIsOpen] = useState(false);

  const closeDropdown = (event) => {
    if (
      dropdownRef &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);
    return () => document.removeEventListener("mousedown", closeDropdown);
  }, [dropdownRef]);

  return (
    <Dropdown.Wrapper {...rest} style={styles?.wrapper} forwardRef={dropdownRef} className={isOpen ? 'dropdown-opened' : ''}>
      {staticLabel ? children[0] : label}

      <Dropdown.Toggle
        onClick={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
        style={styles?.toggle}
      >
        <Icon icon={icon} color={styles?.icon?.color ?? ""} size="24px" />
      </Dropdown.Toggle>

      <Dropdown.List position={position} style={styles?.list} isOpen={isOpen}>
        {children?.slice(!staticLabel ? 0 : 1)?.map((child: any, index: number) => {
          if (!child) {
            return null;
          }

          const { onClick, ...childProps } = child.props;
          const key = `${new Date().getTime()}-${index}`;

          const handleClick = (event: React.MouseEvent) => {
            if (!!onClick && child?.props?.type && child?.props?.type !== 'button') {
              onClick(event);
            }
            setIsOpen(false);
          };

          return (
            <Dropdown.Item
              key={key}
              onClick={handleClick}
              {...childProps}
              style={styles?.item}
            >
              <span>{child}</span>
            </Dropdown.Item>
          );
        })}
      </Dropdown.List>
    </Dropdown.Wrapper >
  );
};

export default Components;
