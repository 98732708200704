import { useWindowSize } from "../../../hooks/useFormHook";
import { Header, Navbar, Logo, Menu, MenuItem, Img } from "./styles";

import logo from "../../../assets/logo.svg";
import icon from "../../../assets/logo-icon.svg";
import Grid from "../../../components/Grid";

import Banner from "./components/Banner";
import About from "./components/About";
import Experts from "./components/Experts";
import Schedule from "./components/Schedule";
import IntroVideo from "./components/IntroVideo";
import Subscribe from "./components/Subscribe";
import Footer from "../../../components/Footer";

const Component = () => {
  const { isMobile } = useWindowSize();

  return (
    <>
      <Header>
        <Grid.Container>
          <Grid.Row>
            <Grid.Col lg={10} offset={{ lg: 1 }}>
              <Navbar>
                <Logo href="/">
                  {!isMobile && (
                    <Img
                      src={logo}
                      width="168px"
                      height="auto"
                      className="logo-desktop"
                      alt="Klouser"
                      lazy
                    />
                  )}
                  {isMobile && (
                    <Img
                      src={icon}
                      width="40px"
                      height="36px"
                      className="logo-mobile"
                      alt="Klouser"
                      lazy
                    />
                  )}
                </Logo>

                <Menu>
                  <MenuItem href="#mas-info" target="_self">
                    Más info
                  </MenuItem>
                  <MenuItem href="#expertos" target="_self">
                    Expertos
                  </MenuItem>
                  <MenuItem href="#cronograma" target="_self">
                    Cronograma
                  </MenuItem>
                </Menu>

                <MenuItem href="" target="_self">
                  Suscribirme
                </MenuItem>
              </Navbar>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </Header>

      <Banner />
      <About />
      <IntroVideo />
      <Experts />
      <Subscribe />
      <Schedule />

      <Footer />
    </>
  );
};

export default Component;
