import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import { useEffect } from "react";
import dateHelpers from "../../../../../helpers/dates-hanlder";
import { CopyToClipboad } from "../../../Coupon/Preview/components/styles";

const Component = ({ postInformation }) => {
  const dispatch = useDispatch();

  return (
    <Modal name="modal-new-post-created" width="650" onClose={() => {}}>
      <Title>¡Contenido creado! 💫</Title>

      <Text>
        Ahora puedes <strong>compartir el link</strong> en los canales de tu
        comunidad y también en redes sociales para llegar a nuevas personas.
      </Text>

      <AlignEnd>
        <CopyToClipboad
          copy={`${process.env.REACT_APP_SITE_BASE_URL}/group/${postInformation?.group?.id}/p/${postInformation?.id}`}
          showChildren
          iconColor="white"
          style={{
            width: "auto",
          }}
          successStyle={{
            position: "absolute",
            left: "100%",
            top: "50%",
            transform: "translate(0%, -50%)",
          }}
        >
          <span style={{ color: "#ffff", fontWeight: "600" }}>
            Compartir link
          </span>
        </CopyToClipboad>
      </AlignEnd>
    </Modal>
  );
};

const state = ({ postStore }) => {
  const { data: postInformation, states: postStates } = postStore.post;

  return {
    postInformation,
    postStates,
  };
};

export default connect(state)(Component);
