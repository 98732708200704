import { getThemeVar } from "remirror";
import { AllStyledComponent } from "@remirror/styles/emotion";
import {
  BoldExtension,
  ItalicExtension,
  NodeFormattingExtension,
  MarkdownExtension,
  HeadingExtension,
  StrikeExtension,
  DocExtension,
  CodeBlockExtension,
  LinkExtension,
  PlaceholderExtension,
} from "remirror/extensions";
import {
  Remirror,
  useRemirror,
  useCommands,
  ThemeProvider,
  ComponentItem,
  Toolbar,
  ToolbarItemUnion,
  ReactExtensions,
  UseRemirrorReturn,
  useRemirrorContext,
  ReactFrameworkOutput,
} from "@remirror/react";
import { CountExtension, CountStrategy } from "@remirror/extension-count";
import "remirror/styles/all.css";
import { css } from "@emotion/css";
import md from "refractor/lang/markdown";
import typescript from "refractor/lang/typescript";

import { createContextState } from "create-context-state";
import { useState, ReactElement, forwardRef, useImperativeHandle } from "react";
import { Controller } from "react-hook-form";
import {
  FieldContainer,
  Field,
  Label,
  HelperLine,
  HelperText,
  CharacterCounter,
  ErrorMessage,
} from "./../Field";
import { useFieldValidation } from "../../../hooks/useFieldValidation";

import { RichTextEditorStyles } from "./styles";
import { useMarkdown } from "../../../hooks/useMarkdown";

const initialValue = {
  options: {
    skin: "base",
    size: "md",
    marginBottom: 24,
  },
};

const toolbarItems: ToolbarItemUnion[] = [
  {
    type: ComponentItem.ToolbarGroup,
    label: "Simple Formatting",
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "toggleBold",
        display: "icon",
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "toggleItalic",
        display: "icon",
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "toggleUnderline",
        display: "icon",
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "toggleStrike",
        display: "icon",
      },
    ],
  },
  {
    type: ComponentItem.ToolbarGroup,
    label: "Heading Formatting",
    items: [
      /*{
        type: ComponentItem.ToolbarCommandButton,
        commandName: "toggleHeading",
        display: "icon",
        attrs: { level: 3 },
      },*/
    ],
  },
  {
    type: ComponentItem.ToolbarGroup,
    label: "Clipboard",
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "copy",
        display: "icon",
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "cut",
        display: "icon",
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "paste",
        display: "icon",
      },
    ],
  },
  {
    type: ComponentItem.ToolbarGroup,
    label: "History",
    items: [
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "undo",
        display: "icon",
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "redo",
        display: "icon",
      },
      {
        type: ComponentItem.ToolbarCommandButton,
        commandName: "toggleColumns",
        display: "icon",
        attrs: { count: 2 },
      },
    ],
  },
];

const AlignButtons = () => {
  const commands = useCommands();
  return (
    <>
      <button
        onMouseDown={(event) => event.preventDefault()}
        type="button"
        onClick={() => commands.leftAlign()}
        className="remirror-role remirror-button remirror-tabbable"
        style={{ backgroundColor: '#6C5CE7' }}
      >
        <span className="remirror-role">Left</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Outline"
          viewBox="0 0 24 24"
          width="14"
          height="14"
        >
          <path d="M1,6H23a1,1,0,0,0,0-2H1A1,1,0,0,0,1,6Z" />
          <path d="M1,11H15a1,1,0,0,0,0-2H1a1,1,0,0,0,0,2Z" />
          <path d="M15,19H1a1,1,0,0,0,0,2H15a1,1,0,0,0,0-2Z" />
          <path d="M23,14H1a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" />
        </svg>
      </button>
      <button
        onMouseDown={(event) => event.preventDefault()}
        type="button"
        onClick={() => commands.centerAlign()}
        className="remirror-role remirror-button remirror-tabbable"
        style={{ backgroundColor: '#6C5CE7' }}
      >
        <span className="remirror-role">Center</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Outline"
          viewBox="0 0 24 24"
          width="14"
          height="14"
        >
          <path d="M1,6H23a1,1,0,0,0,0-2H1A1,1,0,0,0,1,6Z" />
          <path d="M5,9a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z" />
          <path d="M19,19H5a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z" />
          <path d="M23,14H1a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" />
        </svg>
      </button>
      <button
        onMouseDown={(event) => event.preventDefault()}
        type="button"
        onClick={() => commands.rightAlign()}
        className="remirror-role remirror-button remirror-tabbable"
        style={{ backgroundColor: '#6C5CE7' }}
      >
        <span className="remirror-role">Right</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Outline"
          viewBox="0 0 24 24"
          width="14"
          height="14"
        >
          <path d="M1,6H23a1,1,0,0,0,0-2H1A1,1,0,0,0,1,6Z" />
          <path d="M1,11H15a1,1,0,0,0,0-2H1a1,1,0,0,0,0,2Z" />
          <path d="M15,19H1a1,1,0,0,0,0,2H15a1,1,0,0,0,0-2Z" />
          <path d="M23,14H1a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" />
        </svg>
      </button>
    </>
  );
};

const extensions = () => [
  new MarkdownExtension(),
  new BoldExtension(),
  new ItalicExtension(),
  new StrikeExtension(),
  new NodeFormattingExtension(),
  new LinkExtension(),
  new PlaceholderExtension(),
  new CountExtension({
    maximum: 5000,
    maximumStrategy: CountStrategy.CHARACTERS,
  }),
  new HeadingExtension({ levels: [1, 2, 3, 4, 5, 6] }),
];

declare global {
  interface Window {
    rawContentState: any;
    contentState: any;
    oldcontentState: any;
  }
}

interface Context extends Props {
  setMarkdown: (markdown: string) => void;
  setVisual: (markdown: string) => void;
}

interface Props {
  visual: UseRemirrorReturn<
    ReactExtensions<ReturnType<typeof extensions>[number]>
  >;
  markdown: UseRemirrorReturn<
    ReactExtensions<DocExtension | CodeBlockExtension>
  >;
}

const [DualEditorProvider, useDualEditor] = createContextState<Context, Props>(
  ({ props }) => {
    return {
      ...props,

      setMarkdown: (text: string) => {
        return props.markdown.getContext()?.setContent({
          type: "doc",
          content: [
            {
              type: "codeBlock",
              attrs: { language: "markdown" },
              content: text ? [{ type: "text", text }] : undefined,
            },
          ],
        });
      },
      setVisual: (markdown: string) => {
        return props.visual.getContext()?.setContent(markdown);
      },
    };
  }
);

const MarkdownTextEditor = () => {
  const { markdown, setVisual } = useDualEditor();

  return (
    <Remirror
      manager={markdown.manager}
      autoRender="end"
      onChange={({ helpers, state }) => {
        const text = helpers.getText({ state });
        return setVisual(text);
      }}
      classNames={[
        css`
          &.ProseMirror {
            padding: 0;
            pre {
              height: 100%;
              padding: ${getThemeVar("space", 3)};
              margin: 0;
            }
          }
        `,
      ]}
    >
      {/* <Toolbar items={toolbarItems} refocusEditor label='Top Toolbar' /> */}
    </Remirror>
  );
};

const VisualEditor = ({
  onChange,
  onBlur,
  onFocus,
  disabled,
}: {
  onChange?;
  onBlur?;
  onFocus?;
  disabled;
}) => {
  const [isEditable, setIsEditable] = useState(true);
  const { visual, setMarkdown } = useDualEditor();

  return (
    <Remirror
      //autoFocus
      manager={visual.manager}
      autoRender="end"
      onChange={({ helpers, state }) => {
        !!onChange &&
          onChange({
            markdownStr: helpers.getMarkdown(state),
            json: helpers.getJSON(),
            textStr: helpers.getText(),
            htmlStr: helpers.getHTML(state),
            characterCount: helpers.getCharacterCount(),
            wordsCount: helpers.getWordCount(),
            isCountValid: helpers.isCountValid(),
          });
        setIsEditable(helpers.isCountValid());
        return setMarkdown(helpers.getMarkdown(state));
      }}
      onBlur={(params, event) => {
        console.log("onFocus", params, event);
      }}
      onFocus={(params, event) => {
        if (!!onFocus) {
          onFocus(event);
        }
      }}
      editable={!disabled}
      initialContent={visual.state}
      classNames={[
        css`
          &.ProseMirror {
            p,
            h3,
            h4 {
              margin-top: ${getThemeVar("space", 2)};
              margin-bottom: ${getThemeVar("space", 2)};
            }
            h1,
            h2 {
              margin-bottom: ${getThemeVar("space", 3)};
              margin-top: ${getThemeVar("space", 3)};
            }
          }
        `,
      ]}
    >
      <Toolbar items={toolbarItems} refocusEditor label="Top Toolbar" />
    </Remirror>
  );
};

interface TextareaProps {
  control?: any;
  defaultValue?: string;
  register?: (name: string, rules?: object) => any;
  forwardRef?;
  name: string;
  value?: string;
  type?: string;
  disabled?: boolean;
  rules?: any;
  errors?: {};
  placeholder?: string;
  maxLength?: number;
  formState?: any;
  onFocus?: (data: any, formState: any) => void;
  onBlur?: (data: any, formState: any) => void;
  onChange?: (data: any, formState: any) => void;
  options?: {
    skin?: "base";
    size?: "md";
    label?: string | ReactElement;
    helperText?: string;
    marginBottom: number;
  };
}

const Component = ({
  control,
  defaultValue,
  placeholder,
  register,
  forwardRef,
  rules = {},
  options,
  type,
  disabled,
  formState,
  name,
  errors,
  maxLength,
  onFocus,
  onBlur,
  onChange,
}: TextareaProps) => {
  let value = defaultValue;

  try {
    const values = JSON.parse(defaultValue || "");
    value = JSON.parse(values.jsonStr || "");
  } catch {
    value = defaultValue;
  }

  const { classes, setActive } = useFieldValidation(errors, name, disabled);
  const { skin, size, marginBottom } = { ...initialValue.options, ...options };
  const [characters, setCharacters] = useState(0);

  const handleOnChange = (event, callback) => {
    callback && callback(event, formState);
  };

  const handleOnBlur = (event, callback) => {
    setActive(false);
    callback && callback(event, formState);
  };

  const handleOnFocus = (event, callback) => {
    setActive(true);
    callback && callback(event, formState);
  };

  const visual = useRemirror({
    extensions,
    stringHandler: "markdown",
    content: value,
  });

  const markdown = useRemirror({
    extensions: () => [
      new DocExtension({ content: "codeBlock" }),
      new CodeBlockExtension({
        supportedLanguages: [md, typescript],
        defaultLanguage: "markdown",
        syntaxTheme: "base16_ateliersulphurpool_light",
        defaultWrap: true,
      }),
    ],
    builtin: {
      exitMarksOnArrowPress: false,
    },
    stringHandler: "html",
  });

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <AllStyledComponent>
          <ThemeProvider>
            <DualEditorProvider visual={visual} markdown={markdown}>
              <FieldContainer marginBottom={marginBottom}>
                {options && options.label && (
                  <Label size={size} htmlFor={`textarea-${name}`}>
                    {options.label}
                  </Label>
                )}

                <Field
                  size={size}
                  skin={skin}
                  className={classes}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "15px",
                  }}
                >
                  <RichTextEditorStyles />
                  <VisualEditor
                    disabled={disabled}
                    // onBlur={(event) => {
                    //   handleOnBlur(event, onBlur);
                    //   return field.onBlur();
                    // }}
                    onFocus={(event) => {
                      handleOnFocus(event, onFocus);
                    }}
                    onChange={({
                      markdownStr,
                      json,
                      textStr,
                      htmlStr,
                      characterCount,
                      wordsCount,
                      isCountValid,
                    }) => {
                      setCharacters(characterCount);
                      handleOnChange(
                        {
                          markdownStr,
                          json,
                          textStr,
                          htmlStr,
                          characterCount,
                          wordsCount,
                          isCountValid,
                        },
                        onChange
                      );

                      field.onChange(
                        JSON.stringify({
                          jsonStr: JSON.stringify(json),
                          htmlStr,
                          textStr,
                        })
                      );
                    }}
                  />
                  {/* <MarkdownTextEditor /> */}
                </Field>

                {((options && options.helperText) || maxLength || errors) && (
                  <HelperLine marginBottom={marginBottom}>
                    {errors && errors[name] && (
                      <ErrorMessage>{errors[name].message}</ErrorMessage>
                    )}
                    {options &&
                      options.helperText &&
                      errors &&
                      !errors[name] && (
                        <HelperText>{options.helperText}</HelperText>
                      )}
                    {maxLength && (
                      <CharacterCounter errors={errors && errors[name]}>
                        {characters} / {maxLength}
                      </CharacterCounter>
                    )}
                  </HelperLine>
                )}
              </FieldContainer>
            </DualEditorProvider>
          </ThemeProvider>
        </AllStyledComponent>
      )}
    />
  );
};

export default Component;
