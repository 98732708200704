import SessionCard from "../../../../Dashboard/Sessions/components/ReducedSessionCard";
import { Separator } from "../../../../../components/StyledComponents";
import { Title, SessionCardSkeleton, GlobalStyle } from "./styles";
import { SessionInDto } from "../../../../../types";
import { isBeforeXDays, sessionExpiredWithMargin, convertFloatHourToMinutes } from "../../../../../helpers/dates-hanlder";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { useMemo } from "react";

interface ComponentProps {
  sessions: SessionInDto[];
  sessionsStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

const Component = ({ sessions, sessionsStates }: ComponentProps) => {


  const filteredSessions = useMemo(() => {
    return sessions?.filter((session) => {
      let durationInMinutes = session?.duration_type === "hour" ? convertFloatHourToMinutes(session?.duration) : session?.duration
      return !sessionExpiredWithMargin(session?.session_datetime, durationInMinutes)
    }).slice(0, 1)

  }, [sessions])

  return (
    <>
      {(!sessions && sessionsStates.loading) ||
        ((filteredSessions?.length <= 0 ||
          sessionsStates.error) &&
          !sessionsStates.loading) ? (
        <></>
      ) : (
        <>
          <GlobalStyle />

          <Title>Próximo encuentro en vivo</Title>

          <div style={{ height: 6 }} />

          <motion.div className="grid-small">
            {!sessionsStates.loading && sessionsStates.loading && (
              <>
                <SessionCardSkeleton />
                <SessionCardSkeleton />
                <SessionCardSkeleton />
              </>
            )}

            {filteredSessions?.map((session) => (
              <motion.div key={`session-${session.id}`} layout>
                <SessionCard
                  key={`session-${session.id}`}
                  session={session}
                  link={`/group/${session?.group}/s/${session.id}`}
                />
              </motion.div>
            ))}
          </motion.div>

          <div style={{ height: 26 }} />
        </>
      )}
    </>
  );
};

const state = ({ sessionStore }) => {
  const { data: sessions, states: sessionsStates } = sessionStore.all;

  return {
    sessions,
    sessionsStates,
  };
};

export default connect(state)(Component);
