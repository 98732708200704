import Input from "../../../../../components/FormikForm/Input";
import Checkbox from "../../../../../components/FormikForm/Checkbox";
import RichTextEditor from "../../../../../components/FormikForm/RichTextEditor";
import File from "../../../../../components/FormikForm/File";
import Categories from "../FieldCategories";
import { connect, useDispatch } from "react-redux";
import { deleteFile } from "../../../../../store/actions/session";
import { getYouTubeUrl, isYoutubeUrl, isVimeoUrl, getVimeoVideoIdFromUrl } from "../../../../../helpers/you-tube";
import { useEffect, useState, createRef, useMemo } from "react";
import { Center } from "../../../../../components/StyledComponents";
import VimeoVideo from "../../../Posts/components/VimeoVideo";
import Collapse from "../../../../../components/Collpase";
import ReactSelect from "../../../../../components/FormikForm/ReactSelect";
import constants from "../../../../../constants";
import Switch from "../../../../../components/FormikForm/Switch";
import { FieldArray } from 'formik';
import GroupedTimezones from "../GroupedTimezones";
import moment from 'moment-timezone';
import {
  StepTitle,
  StepLabel,
} from './styles'
import Icon from '../../../../../components/Icon'
import ZoomIcon from '../../../../../assets/sessions/zoom-icon.svg'
import YoutubeIcon from '../../../../../assets/sessions/youtube-icon.svg'
import GMeetIcon from '../../../../../assets/sessions/google-meet-icon.svg'
import { isFutureDate, inputDate, convertTimeToMinutes } from "../../../../../helpers/dates-hanlder";
import { getUrlParamByName } from "../../../../../helpers/get-url-param";
import warningIcon from "../../../../../assets/icons/warning.svg";
import { useWindowSize } from "../../../../../hooks/useFormHook";
import ReminderSettingFields from "../ReminderSettingFields";
import { SessionReminderSetting } from "../../../../../types";

interface TimezoneOption {
  value: string;
  label: string
}

interface ComponentProps {
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  setFieldError: any;
  setFieldTouched: any;
  groupInformation: any;
  isEdit: boolean;
}

const Component = (props: ComponentProps) => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldError, setFieldTouched, groupInformation, isEdit } = props;

  const dispatch = useDispatch();
  const [embedURL, setEmbedURL] = useState<string | null>(null);
  const [isDatetimeValid, setIsDatetimeValid] = useState(false);
  const [selectedTimezones, setSelectedTimezones] = useState<TimezoneOption[]>([])
  const { isMobile } = useWindowSize();
  const [isFutureSession, setIsFutureSession] = useState<boolean>(true)

  const scrollToRef = createRef<HTMLDivElement>()

  // Add this useEffect hook
  useEffect(() => {
    console.log("isFutureSession", isFutureSession);
  }, [isFutureSession]);


  useEffect(() => {
    const scrollTo = getUrlParamByName("scrollTo")
    if (scrollTo === 'recording_section' && scrollToRef?.current) {
      //const fixedBarHeight = 130;
      //const scrollPosition = scrollToRef.current.getBoundingClientRect().top + window.scrollY - fixedBarHeight;
      //window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      //scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [scrollToRef]);

  const detectPlatformMeet = (joinUrl) => {
    if (/zoom\.us/.test(joinUrl)) {
      return <Icon icon={ZoomIcon} />;
    } else if (/meet\.google\.com/.test(joinUrl)) {
      return <Icon icon={GMeetIcon} />;
    } else if (/youtube\.com/.test(joinUrl) || /youtu\.be/.test(joinUrl)) {
      return <Icon icon={YoutubeIcon} size="24px" />;
    } else {
      return '';
    }
  };

  const handleTimezoneChange = (selectedOptions) => {
    const selectedItems = timezoneItems.filter(tzItem => selectedOptions.includes(tzItem.value))

    if (selectedItems) {
      setSelectedTimezones(selectedItems);
    }
  };

  const timezoneItems = constants.countries
    .filter(c => !!c.mainTimezone)
    .map(country => ({
      label: `${country.flagIcon} ${country.es}`,
      value: country?.mainTimezone || '' // Use an empty string as a fallback value
    }));



  useEffect(() => {
    if (
      !!values["recorded_session_url"] &&
      isYoutubeUrl(values["recorded_session_url"])
    ) {
      setEmbedURL(getYouTubeUrl(values["recorded_session_url"]));
    } else if (!!values["recorded_session_url"] &&
      isVimeoUrl(values["recorded_session_url"])) {
      setEmbedURL((values["recorded_session_url"]));
    } else {
      setEmbedURL(null);
    }

    if (values['session_datetime']) {
      setIsDatetimeValid(moment(values['session_datetime'], 'YYYY-MM-DDTHH:mm', true).isValid());
    }
  }, [values]);

  useEffect(() => {
    if (!!isDatetimeValid) {
      setIsFutureSession(isFutureDate(values['session_datetime']))
    }
  }, [isDatetimeValid])

  return (
    <>
      <Input
        name="title"
        error={errors["title"]}
        touched={touched["title"]}
        value={values["title"]}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Ingresa un título"
        options={{
          label: "Título del Encuentro",
          marginBottom: 40,
        }}
      />

      <Collapse startCollapsed={isEdit} toggleLabel="Descripción" toggleIcon="">
        <div style={{ height: 25 }} />

        <File
          name="cover_pic"
          error={errors["cover_pic"]}
          touched={touched["cover_pic"]}
          value={values["cover_pic"]}
          onChange={(data) => { }}
          rules={{
            maxFiles: 1,
            typeFiles: ["image"],
            maxSize: 5*1024*1024
          }}
          onRemoveItem={(event, file) => {
            if (file.id) {
              dispatch(deleteFile({ idFile: file.id }));
            }
          }}
          options={{
            label: "Adjuntar portada (opcional)",
            explainText: "Puedes agregar una imagen de portada para tu artículo. Formatos válidos: JPG o PNG de hasta un maximo de 5Mb. El tamaño ideal es de 333px (ancho) x 174px (alto), pero si subes de otro tamaño funcionará!",
            marginBottom: 40,
            showLoadFileCta: false,
          }}
        />

        <RichTextEditor
          name="description"
          error={errors["description"]}
          touched={touched["description"]}
          value={values["description"]}
          placeholder="Se trata de..."
          max={3000}
          onBlur={handleBlur}
          onChange={(data) => { }}
          options={{ label: "Acerca del Encuentro", marginBottom: 40 }}
        />

        <Checkbox
          name="use_profile_info"
          error={errors["use_profile_info"]}
          touched={touched["use_profile_info"]}
          value={values["use_profile_info"]}
          onChange={(event) => {
            if (values?.use_profile_info[0] === "true") {
              // setFieldValue("speaker_name", "");
              // setFieldValue("speaker_profile_pic", "");
            } else {
              // setFieldValue(
              //   "speaker_name",
              //   groupInformation?.expert?.showable_name
              // );
              // setFieldValue(
              //   "speaker_profile_pic",
              //   groupInformation?.expert?.profile_pic?.url
              // );
            }
            handleChange(event);
          }}
          onBlur={handleBlur}
          items={[
            { label: "Usar mi Nombre y Foto de perfil", value: "true" },
          ]}
          options={{
            label: "¿Quién dará el evento?",
            marginBottom: 40,
          }}
        />

        {values?.use_profile_info[0] !== "true" && (
          <>
            <Input
              name="speaker_name"
              error={errors["speaker_name"]}
              touched={touched["speaker_name"]}
              value={values["speaker_name"]}
              disabled={values?.use_profile_info[0] === "true"}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Ingresa tu nombre o el del orador/a del evento"
              options={{
                label: "Nombre del Orador",
                marginBottom: 16,
              }}
            />

            <File
              name="speaker_profile_pic"
              error={errors["speaker_profile_pic"]}
              touched={touched["speaker_profile_pic"]}
              value={values["speaker_profile_pic"]}
              disabled={values?.use_profile_info[0] === "true"}
              onChange={(data) => { }}
              rules={{
                maxFiles: 1,
                typeFiles: ["image"],
              }}
              onRemoveItem={(event, file) => {
                if (file.id) {
                  dispatch(deleteFile({ idFile: file.id }));
                }
              }}
              options={{
                label: "Adjuntar foto de perfil de orador/a del evento (opcional)",
                explainText: "Puedes agregar una imágen JPG o PNG de hasta 50Mb",
                marginBottom: 40,
                showLoadFileCta: false,
              }}
            />
          </>
        )}

      </Collapse>

      <div style={{ height: 25 }} />

      <Collapse startCollapsed={isEdit} toggleLabel="Información de Acceso" toggleIcon="">
        <div style={{ height: 25 }} />

        <Input
          name="join_url"
          error={errors["join_url"]}
          touched={touched["join_url"]}
          value={values["join_url"]}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Pega tu link aquí"
          options={{
            label: "Completa el Link para unirse al Encuentro",
            helperText:
              "Puedes compartir el link de la plataforma que uses para tus encuentros en vivo. Las más usadas son: ZOOM, Google Meet, YouTube Live, Streaming en Telegram, etc.",
            marginBottom: 40,
            after: detectPlatformMeet(values["join_url"])
          }}
        />


        <Input
          name="access_code"
          error={errors["access_code"]}
          touched={touched["access_code"]}
          value={values["access_code"]}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Escribe (si hay) el Código para acceder"
          options={{
            label: "Código de Acceso (Opcional)",
            helperText:
              "Si al ingresar a la sala les va a solicitar un código de acceso, ponlo aqui",
            marginBottom: 40,
          }}
        />

        <Switch
          name="will_be_recorded"
          error={errors?.will_be_recorded}
          touched={touched?.will_be_recorded}
          value={values?.will_be_recorded}
          onChange={handleChange}
          onBlur={handleBlur}
          items={[
            {
              label: "El Encuentro quedará Grabado",
              value: "true",
            }
          ]}
          options={{
            marginBottom: 40,
            helperText: `Al activar esta opción, le avisaremos a todos los suscriptores que luego se subirá la grabación a la plataforma.`
          }}
        />

        <StepTitle>
          <StepLabel>Duración</StepLabel>
        </StepTitle>
        <Center vertical="top" horizontal="left" gap={10}>
          <Input
            name="duration"
            error={errors?.duration}
            touched={touched?.duration}
            value={values?.duration}
            type={"number"}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Ej: 2"
            options={{
            }}
          />

          <ReactSelect
            name="duration_type"
            error={errors?.duration_type}
            touched={touched?.duration_type}
            defaultValue={{ value: "hour", label: "Horas" }}
            items={constants.durations.filter(x => x.value === "minute" || x.value === "hour")}
            onChange={handleChange}
            onBlur={handleBlur}
            options={{
              marginBottom: 40
            }}
          />
        </Center>


        <Input
          name="session_datetime"
          error={errors["session_datetime"]}
          touched={touched["session_datetime"]}
          value={values["session_datetime"]}
          onChange={handleChange}
          onBlur={(e) => {
            handleBlur(e)
            setIsDatetimeValid(moment(values['session_datetime'], 'YYYY-MM-DDTHH:mm', true).isValid());
            setIsFutureSession(isFutureDate(values['session_datetime']))            
          }}
          placeholder="Ingresa la fecha/hora del encuentro"
          type="datetime-local"
          options={{
            label: "Fecha y Hora del Encuentro",
            helperText: `Completalo en tu Zona Horaria local: "${moment.tz.guess()}"`,
            marginBottom: 40,
          }}
        />


      </Collapse>

      <div style={{ height: 25 }} />

      <Collapse startCollapsed={isEdit && (values['session_datetime'] && !isFutureSession)} toggleLabel="Recordatorios y Notificaciones" toggleIcon="">
        {!!isFutureSession && !!isDatetimeValid ? (
          <>
            <div style={{ height: 25 }} />
            <Switch
              name="notificate_by_email"
              error={errors?.notificate_by_email}
              touched={touched?.notificate_by_email}
              value={values?.notificate_by_email}
              onChange={handleChange}
              onBlur={handleBlur}
              items={[
                {
                  label: "Enviar recordatorios via Mail",
                  value: "true",
                }
              ]}
              options={{
                marginBottom: 40,
                helperText: `Al activar esta opción, le enviaremos las notificaciones que configures por mail a tus suscriptores.`
              }}
            />

            <Switch
              name="notificate_by_chat"
              error={errors?.notificate_by_chat}
              touched={touched?.notificate_by_chat}
              value={values?.notificate_by_chat}
              onChange={handleChange}
              onBlur={handleBlur}
              items={[
                {
                  label: "Enviar recordatorios vía Grupo de Chat",
                  value: "true",
                }
              ]}
              options={{
                marginBottom: 40,
                helperText: `Al activar esta opción, enviaremos las notificaciones por el Canal de Novedades de Telegram/Whatsapp`
              }}
            />



            {(values["notificate_by_chat"]?.includes("true") || values["notificate_by_email"]?.includes("true")) && (
              <>
                <ReactSelect
                  name="timezones_to_show"
                  placeholder="Selecciona una o mas Zonas Horarias"
                  items={timezoneItems}
                  isMulti
                  isClearable
                  options={{
                    label: "Indica otras Zonas Horarias en las que quieres mostrar la hora del Encuentro ",
                    marginBottom: 20,
                    noOptionsText: "No hay resultados",
                    helperText: "Servirá para aclarar en las notificaciones y recordatorios por Mail y Grupo de Chat"
                  }}
                  onChange={(e) => {
                    handleTimezoneChange(e?.target?.value)
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  touched={errors['timezones_to_show']}
                  error={errors['timezones_to_show']}
                />

                {isDatetimeValid && (
                  <GroupedTimezones selectedTimezones={selectedTimezones} datetime={values['session_datetime']} />
                )}

                <div style={{ height: 40 }} />


                <FieldArray name="session_reminders_settings">
                  {({ }) => (
                    <div>
                      {values?.session_reminders_settings?.map((reminder, index) => (
                        <ReminderSettingFields
                          key={index}
                          index={index}
                          isLastIndex={index === values?.session_reminders_settings?.length - 1}
                          isMobile={isMobile}
                          isFutureSession={isFutureSession}
                          {...props}
                        />
                      ))}
                    </div>
                  )}
                </FieldArray>
              </>
            )}
          </>
          ) : (
          <>
            <div ref={scrollToRef} style={{ height: 5 }} />
            <div style={{ display: "flex", justifyContent: "start" }}>
              <div style={{ margin: "5px", paddingTop: "10px" }}>
                <Icon icon={warningIcon} color="gray" size="24px" />
              </div>
              <p style={{ fontSize: "14px", marginLeft: "3px", color: "gray" }}>La sección para configurar las Notificaciones se habilitará solo si estas calendarizando un Encuentro para el futuro.{isDatetimeValid ? `La fecha del encuentro que completaste es pasada: ${inputDate(values['session_datetime'], "[:] DD/MM/YYYY")}` : ". Primero completa la fecha/hora del Encuentro en la sección 'Información de Acceso'"}</p>
            </div>
          </>
        )}
      </Collapse>


      {/* <Categories
        name="content_categories"
        error={errors["content_categories"]}
        touched={touched["content_categories"]}
        onChange={handleChange}
        onBlur={handleBlur}
      /> */}

      <div style={{ height: 25 }} />


      <Collapse startCollapsed={!isEdit} toggleLabel="Grabación" toggleIcon="">

        {!!isDatetimeValid && !isFutureSession ? (
          <>
            <div ref={scrollToRef} style={{ height: 25 }} />
            <Input
              name="recorded_session_url"
              error={errors["recorded_session_url"]}
              touched={touched["recorded_session_url"]}
              disabled={isFutureSession}
              value={values["recorded_session_url"]}
              onChange={(event) => {
                handleChange(event);
                if (isYoutubeUrl(event.target.value)) {
                  setEmbedURL(getYouTubeUrl(event.target.value));
                } else if (isVimeoUrl(event.target.value)) {
                  setEmbedURL(event.target.value)
                } else {
                  setEmbedURL(null);
                }
              }}
              onBlur={handleBlur}
              placeholder="Pega tu link aquí"
              options={{
                label: "Agregar link de la grabación",
                helperText:
                  "Embebe directamente videos de Youtube, Vimeo, Instagram o simplemente comparte cualquier link que quieras que tus suscriptores abran!.",
                marginBottom: !!embedURL ? 16 : 40,
              }}
            />

            {!!embedURL && isYoutubeUrl(embedURL) && (
              <>
                <iframe
                  src={embedURL}
                  id="myIframe"
                  width="320"
                  height="200"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
                <div style={{ height: "40px" }} />
              </>
            )}

            {!!embedURL && isVimeoUrl(embedURL) && (
              <div style={{ minHeight: 170 }}>
                <VimeoVideo videoId={getVimeoVideoIdFromUrl(embedURL)} isPreview={false} showLink={false} />
                <div style={{ height: "40px" }} />
              </div>
            )}


            <Input
              name="recorded_access_code"
              error={errors["recorded_access_code"]}
              touched={touched["recorded_access_code"]}
              value={values["recorded_access_code"]}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Escribe (si tiene) el Código para Acceder a la Grabación"
              options={{
                label: "Código de Acceso  de la Grabación (Opcional)",
                marginBottom: 40,
              }}
            />


            <Input
              name="recording_expiration_datetime"
              error={errors["recording_expiration_datetime"]}
              touched={touched["recording_expiration_datetime"]}
              value={values["recording_expiration_datetime"]}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="La grabación estara disponible hasta..."
              type="datetime-local"
              options={{
                label: "Vencimiento de la grabación (opcional)",
                helperText:
                  "Ingresa hasta cuando esta disponible la grabación. Si no indicas una fecha este se podrá visualizar siempre.",
                marginBottom: 40,
              }}
            />

            {!!values['recorded_session_url'] && values['recorded_session_url'] !== "" && isEdit && (
              <Switch
                name={`notify_upload_recording`}
                error={errors['notify_upload_recording']}
                touched={touched['notify_upload_recording']}
                value={values?.notify_upload_recording}
                onChange={handleChange}
                onBlur={handleBlur}
                items={[
                  {
                    label: "Enviar una notificación de que he subido la grabación",
                    value: "true",
                  }
                ]}
                options={{
                  marginBottom: 20,
                  helperText: `Al activar esta opción, le enviaremos una notificación a tus suscriptores de que cargaste el link de la grabación.`
                }}
              />
            )}
          </>
        ) : (
          <>
            <div ref={scrollToRef} style={{ height: 5 }} />
            <div style={{ display: "flex", justifyContent: "start" }}>
              <div style={{ margin: "5px", paddingTop: "10px" }}>
                <Icon icon={warningIcon} color="gray" size="24px" />
              </div>
              <p style={{ fontSize: "14px", marginLeft: "3px", color: "gray" }}>La sección para cargar la Grabación se habilitará después que ocurra el Encuentro{isDatetimeValid ? inputDate(values['session_datetime'], "[:] DD/MM/YYYY") : ". Primero completa la fecha/hora del Encuentro en la sección 'Información de Acceso'"}</p>
            </div>
          </>
        )}


      </Collapse>
      <input type="hidden" name="group" />
      <div style={{ height: 30 }} />

    </>
  );
};

const state = ({ groupStore }) => {
  const { data: groupInformation } = groupStore.group;

  return {
    groupInformation,
  };
};

export default connect(state)(Component);
