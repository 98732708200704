import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import { Title, Text, AlignEnd, CopyToClipboardButton } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import { Group, SessionInDto } from "../../../../../types";
import {
  deleteSession,
  resetDeleteOneSession,
} from "../../../../../store/actions/session";
import CopyToClipboad from "../../../../../components/CopyToClipboad";

interface ComponentProps {
  groupInformation: Group;
  session: SessionInDto;
  deleteSessionState: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  session,
  deleteSessionState,
  groupInformation,
}: ComponentProps) => {
  const dispatch = useDispatch();

  const onModalClose = () => {
    if (deleteSessionState.success) {
      dispatch(resetDeleteOneSession());

      setTimeout(() => {
        window.history.back();
      }, 500);
    }
  };

  return (
    <Modal
      name="modal-video-uploaded-session"
      width="650"
      onClose={() => onModalClose()}
    >
      <Title>¡Subiste una grabación!</Title>

      <Text>
        Ahora que subiste una grabación, tus suscriptores podrán verla cuando y
        desde donde quieran por el tiempo que dure el taller.
      </Text>

      <AlignEnd>
        <CopyToClipboardButton>
          <CopyToClipboad
            copy={`${process.env.REACT_APP_SITE_BASE_URL}/group/${groupInformation?.id}/s/${session?.id}`}
            iconColor="#6c5ce7"
          >
            <span>Compartir link</span>
          </CopyToClipboad>
        </CopyToClipboardButton>

        <Button
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
          }}
          style={{ marginRight: "16px" }}
          onClick={() => dispatch(hideModal())}
        >
          Continuar
        </Button>
      </AlignEnd>
    </Modal>
  );
};

const state = ({ sessionStore, groupStore }) => {
  const { data: groupInformation } = groupStore.group;
  const { data: session, states: sessionStates } = sessionStore.one;
  const { states: deleteSessionState } = sessionStore.deleteOne;

  return {
    groupInformation,
    session,
    sessionStates,
    deleteSessionState,
  };
};

export default connect(state)(Component);
