export const getPriorityGroupSubscription = (subscriptions, IdGroup) => {
  const statusPriority = [
    'active',
    'paid',
    'non_renewing',
    'waiting_for_gateway',
    'renewal_retrying',
    'retrying',
    'not_paid',
    'pending_invite',
    "paused",
    "cancelled",
    "defaulted",
    'expired'
  ]

  if (!!subscriptions && subscriptions.length > 0) {

    const groupSubscriptions = subscriptions?.filter((item) => item?.group?.id === IdGroup)

    // Ordenamos las suscripciones en base a la prioridad del estado
    groupSubscriptions.sort((a, b) => {
      const statusA = statusPriority.indexOf(a.status);
      const statusB = statusPriority.indexOf(b.status);

      // Compara las posiciones de los estados en el array statusPriority
      return statusA - statusB;
    });

    return groupSubscriptions[0];
  }

  return null
}