import React, { createContext, createRef, useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { GlobalStyles, Page, PageContent, Title, Actions } from "./styles";
import Grid from "../../Grid";
import Loader from "../../Loader";
import Whatsapp from "../../Whatsapp";
import DesktopMenu from "../components/DesktopMenu";
import MobileHeader from "../components/MobileHeader";
//import Tabs from "../components/Tabs";
import ImportMembersModal from "../components/ModalImportMembers";
import SelectCountryModal from "../components/ModalSelectCountry";
import PhoneNumberModal from "../components/ModalPhoneNumber";
import constants from "../../../constants";
import { setCurrentPage } from "../../../store/actions/page";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { Center } from "../../StyledComponents";
import { useLayout } from "../../../providers/LayoutProvider";
import { User } from "../../../types";
import { showModal } from "../../../store/actions/modal";
import GoBack from "../../GoBack";
import PhoneNumberModalContext from '../components/ModalPhoneNumberContext';



interface ComponentProps {
  children;
  useLocation;
  userInformation: User;
  expertInformation;
  community;
  communityStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  expertStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  paddings?: boolean;
  navigationLevel?: number;
}

const Component = ({
  children,
  useLocation,
  userInformation,
  expertInformation,
  expertStates,
  community,
  communityStates,
  paddings = true,
  navigationLevel,
}: ComponentProps) => {
  const history = useHistory();
  const { layout } = useLayout();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMobile } = useWindowSize();

  const pageContentRef = createRef<HTMLElement>();
  const footerRef = createRef<HTMLElement>();
  const headerRef = createRef<HTMLElement>();
  const [minHeight, setMinHeight] = useState(0);
  const [currentPageLocally, setCurrentPageLocally] = useState<{
    name: string;
    regex: RegExp;
  }>();
  const [publishingPage, setPublishingPage] = useState<boolean>(false);
  const [phoneNumberModalVisible, setPhoneNumberModalVisible] = useState(false);


  useEffect(() => {
    const page =
      location &&
      constants.pages.filter((page) => {
        return !!location.pathname.match(page.regex);
      });

    setCurrentPageLocally(page[0]);
    dispatch(setCurrentPage(page[0]));

    if (!!pageContentRef && !!pageContentRef.current) {
      pageContentRef.current.scrollTo(0, 0);
    }

    document.body.style.height = "auto";
    document.body.style.overflow = "auto";
  }, [location]);

  useEffect(() => {
    if (!!headerRef.current && !!footerRef.current) {
      setMinHeight(
        headerRef.current.scrollHeight + footerRef.current.scrollHeight
      );
    }
  }, [headerRef, footerRef]);

  useEffect(() => {
    setPublishingPage(
      currentPageLocally?.name === "NEW_EXPERIENCE" ||
      currentPageLocally?.name === "NEW_MEMBERSHIP" ||
      currentPageLocally?.name === "NEW_COMMUNITY_EVENT"
    );
  }, [currentPageLocally]);

  useEffect(() => {
    if (
      !expertInformation &&
      expertStates.success &&
      !!currentPageLocally &&
      currentPageLocally?.name !== "MY_PROFILE" &&
      currentPageLocally?.name !== "SUBSCRIPTIONS_LIST" &&
      currentPageLocally?.name !== "MANAGE_SUBSCRIPTIONS" &&
      currentPageLocally?.name !== "UPDATE_PAYMENT_METHOD" &&
      currentPageLocally?.name !== "LINKED_GATEWAYS"
    ) {
      history.push("/profile/survey");
    }
  }, [expertInformation, expertStates, currentPageLocally]);

  useEffect(() => { 
    if (
      !!userInformation &&
      (!userInformation?.expert ||
        (!!userInformation?.expert &&
          !!userInformation?.expert?.billing_info?.country)) &&
      !userInformation?.phone_number
    ) {
      dispatch(showModal("modal-phone-number"));
      setPhoneNumberModalVisible(true)
    } else {
      setPhoneNumberModalVisible(false)
    }
  }, [userInformation]);

  if (expertStates.loading) {
    return <Loader />;
  }


  return (
    <>
      <PhoneNumberModalContext.Provider value={phoneNumberModalVisible}>
        <GlobalStyles />
        <Page id="dashboard-layout">
          {isMobile ? (
            <>
              <div style={{ height: "25px" }} />
              <MobileHeader
                useLocation={useLocation}
                navigationLevel={navigationLevel}
              />
              <div style={{ height: "32px" }} />
            </>
          ) : (
            <DesktopMenu useLocation={useLocation} />
          )}

          <PageContent ref={pageContentRef}>
            <>
              {!isMobile && (
                <>
                  {!!layout?.page?.back && (
                    <GoBack backTo={layout?.page?.back} showAlt />
                  )}

                  {layout?.header?.visible && (
                    <>
                      <Center horizontal="space" vertical="center">
                        {!!layout?.page?.title && (
                          <Title>{layout?.page?.title}</Title>
                        )}

                        {layout?.header?.actions && (
                          <Actions>{layout?.header?.actions}</Actions>
                        )}
                      </Center>

                      <div style={{ height: "32px" }} />
                    </>
                  )}
                </>
              )}

              {isMobile && layout?.header?.actions && navigationLevel === 1 && (
                <>
                  <Grid.Container>
                    <Grid.Row>
                      <Grid.Col>
                        <Actions>{layout?.header?.actions}</Actions>
                      </Grid.Col>
                    </Grid.Row>
                  </Grid.Container>

                  <div style={{ height: "32px" }} />
                </>
              )}

              {layout?.dashboard?.tabs?.visible &&
                !!layout?.dashboard?.tabs?.activeTab && (
                  <>
                    {layout?.dashboard?.tabs?.component &&
                      layout.dashboard.tabs.component}
                    <div style={{ height: "32px" }} />
                  </>
                )}
            </>

            {React.createElement(children.type, {
              ...{
                ...children.props,
                pageContentRef,
              },
            })}
          </PageContent>
        </Page>

        <PhoneNumberModal />
        <SelectCountryModal />
        <ImportMembersModal />
        <Whatsapp floating options={{ marginBottom: 40 }} />
      </PhoneNumberModalContext.Provider>
    </>
  );
};

const state = ({ userStore, communityStore }) => {
  const { data: user, states: expertStates } = userStore.information;
  const { data: community, states: communityStates } = communityStore.community;

  return {
    community,
    communityStates,
    userInformation: user,
    expertInformation: user?.expert,
    expertStates,
  };
};

export default connect(state)(Component);
