import Tooltip from "../../../../../components/Tooltip";
import Image from "../../../../../../components/Image";
import imageInfo from '../../../../../../assets/info.png'
import { Pill } from "./styles";

const states = {
  active: {
    label: "Activo",
    color: {
      text: "#12B47A",
      background: "#DCF4EB",
    },
  },
  non_renewing: {
    label: "Vencimiento próximo",
    color: {
      text: "#EB5757",
      background: "#FCE6E6",
    },
    tooltip:
      "El suscriptor canceló su suscripción, pero mantendrá acceso hasta que finalice el período pagado.",
  },
  defaulted: {
    label: "Cancelado",
    color: {
      text: "#303030",
      background: "#F2F2F7",
    },
  },
  cancelled: {
    label: "Cancelado",
    color: {
      text: "#303030",
      background: "#F2F2F7",
    },
  },
  paid: {
    label: "Activo",
    color: {
      text: "#12B47A",
      background: "#DCF4EB",
    },
  },
  refunded: {
    label: "Reembolsado",
    color: {
      text: "#975102",
      background: "#FAF0D2",
    },
  },
  paused: {
    label: "Pausada",
    color: {
      text: "#975102",
      background: "#FAF0D2",
    },
  },
  pending_invite: {
    label: "Invitado pendiente",
    color: {
      text: "#975102",
      background: "#FAF0D2",
    },
  },
  expired: {
    label: "Cancelado",
    color: {
      text: "#303030",
      background: "#F2F2F7",
    },
  },
  in_trial: {
    label: "Periodo de prueba",
    color: {
      text: "#975102",
      background: "#FAF0D2",
    },
  },
  not_paid: {
    label: "Problema con el pago",
    color: {
      text: "#EB5757",
      background: "#FCE6E6",
    },
    tooltip:
      "No pudimos realizar el cobro. No es necesario que hagas nada por ahora. Le solicitamos por whataspp que reintente el pago.",
  },
  renewal_retrying: {
    label: "Reintentando pago",
    color: {
      text: "#EB5757",
      background: "#FCE6E6",
    },
    tooltip:
      "No pudimos renovar la suscripción. No es necesario que hagas nada por ahora. Le solicitamos que actualice su método de pago y haremos reintentos en los proximos 10 dias",
  },
  retrying: {
    label: "Re-procesando",
    color: {
      text: "#EB5757",
      background: "#FCE6E6",
    },
    tooltip:
      "El suscriptor ya actualizó su método de pago y estamos reintentando iniciar la suscripción. No es necesario que hagas nada por ahora.",
  },
  waiting_for_gateway: {
    label: "Verificando pago",
    color: {
      text: "#009FE3",
      background: "#C7EAF9",
    },
    tooltip:
      "Por políticas de Mercado Pago este proceso puede tardar algunas horas.",
  },
};


const Component = ({ subscriptionState, toolTipPositin }) => {
  const state = states[subscriptionState];

  // Object.keys(tooltopDinamycData).forEach(k =>
  //   state?.tooltip?.replace(`{${k}}`, !!tooltopDinamycData[k] ? tooltopDinamycData[k] : "pago rechazado")
  // )

  return (
    <>
      <Tooltip enableTooltip={!!state?.tooltip} content={state?.tooltip} position={toolTipPositin}>
        <Pill styles={states[subscriptionState]?.color}>
          <div style={{ flexShrink: 0 }}>{states[subscriptionState]?.label}</div>
          {!!states[subscriptionState]?.tooltip && <Image src={imageInfo} width={16} />}
        </Pill>
      </Tooltip>
    </>
  );
};

export default Component;
