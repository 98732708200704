import { connect } from "react-redux";
import Helmet from "react-helmet";
import { Formik } from "formik";
import Input from "../../../components/FormikForm/Input";
import Submit from "../../../components/FormikForm/Submit";
import Link from "../../../components/Link";
import { ErrorMessage } from "../../../components/FormikForm/Field";
import { Center } from "../../../components/StyledComponents";
import ReactHtmlParser from 'react-html-parser';
import { schema, initialValues } from "../../../constants/forms/sign-up";

import constants from "../../../constants";
import logo from "../../../assets/logo.svg";

import { GoToSignIn, Content, Title, P } from "./styles";
import { useEffect, useState } from "react";

interface ComponentProps {
  signUpStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  onFormSubmit: ({ values, actions }) => void;
  setFormSubmmited: React.Dispatch<React.SetStateAction<boolean>>;
  formSubmmited: boolean;
  usertType: "subscriber" | "expert";
}

const Component = ({
  signUpStates,
  onFormSubmit,
  formSubmmited,
  setFormSubmmited,
  usertType,
}: ComponentProps) => {
  const [isValidating, setIsValidating] = useState(false)
  const [email, setEmail] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [disableEmailField, setDisableEmailField] = useState<boolean>(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailParam = params.get('email');
    const nameParam = params.get('name');
    const lastNameParam = params.get('lastName');

    const disableEmailFieldParam = params.get('disableEmailField')?.includes("true") || false;

    if (emailParam)
      setEmail(emailParam);

    if (nameParam)
      setName(nameParam);

    if (lastNameParam)
      setLastName(lastNameParam);

    if (disableEmailFieldParam)
      setDisableEmailField(disableEmailFieldParam)

  }, []);


  return (
    <>
      <Helmet>
        ‍<title>Klouser - Forma parte de nuestra comunidad</title>‍
        <meta name="description" content="Forma parte de nuestra comunidad" />
      </Helmet>

      <Center
        horizontal="center"
        vertical="center"
        column
        style={{ minHeight: "100vh" }}
      >
        <Content>
          <img src={logo} width="159px" height="31px" alt="Klouser" />

          <div style={{ height: 32 }} />

          <Title>Registrate</Title>

          <div style={{ height: 4 }} />

          {usertType !== "subscriber" ? (
            <P>
              Somos la plataforma para escalar y
              crecer tu negocio digital
            </P>
          ) : (
            <P>
              Registrate para acceder a todo el contenido de tus suscripciones
            </P>
          )}


          <div style={{ height: 32 }} />

          <Formik
            initialValues={{
              ...initialValues,
              initial_profile: usertType,
              email: email || initialValues.email,
              name: name || initialValues.name,
              last_name: lastName || initialValues.last_name
            }}
            onSubmit={(values, actions) => {
              if (onFormSubmit) onFormSubmit({ values, actions });
            }}
            validateOnChange={formSubmmited}
            //validateOnChange={false}
            validateOnBlur={false}
            validationSchema={schema({ validationRunning: setIsValidating, initialProfile: usertType || "subscriber" })}
            enableReinitialize
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldError
            }) => {
              return (
                <form
                  className="theme-form"
                  onSubmit={(event) => {
                    setFormSubmmited(true);
                    handleSubmit(event);
                  }}
                  id="sign-up-form"
                  style={{ width: "100%" }}
                >
                  <Center horizontal="space" vertical="center" gap={10}>
                    <Input
                      name="name"
                      touched={touched["name"]}
                      error={errors["name"]}
                      value={values["name"]}
                      type="text"
                      placeholder="Tu nombre"
                      disabled={signUpStates.loading}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        marginBottom: 16,
                      }}
                    />

                    <Input
                      name="last_name"
                      touched={touched["last_name"]}
                      error={errors["last_name"]}
                      value={values["last_name"]}
                      type="text"
                      placeholder="Tu apellido"
                      disabled={signUpStates.loading}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={{
                        marginBottom: 16,
                      }}
                    />
                  </Center>

                  <Input
                    name="email"
                    touched={touched["email"]}
                    error={errors['email'] && <div>{ReactHtmlParser(errors['email'])}</div>}
                    value={values["email"]}
                    type="text"
                    placeholder="E-mail"
                    disabled={signUpStates.loading || (disableEmailField && !!email)}
                    onChange={(event) => {
                      event.target.value = event.target.value
                        .trim()
                        .replaceAll(" ", "");
                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    options={{
                      helperText: disableEmailField && !!email && usertType === 'subscriber' ? "Este campo no se puede editar. Con este email podrás acceder a tus suscripciones." : "",
                      marginBottom: 16,
                    }}
                  />

                  <Input
                    name="password"
                    touched={touched["password"]}
                    error={errors["password"]}
                    value={values["password"]}
                    type="password"
                    placeholder="Contraseña"
                    disabled={signUpStates.loading}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      marginBottom: 16,
                    }}
                  />

                  {(signUpStates.error === "auth/weak-password" || signUpStates.error === "auth/invalid-password") && (
                    <>
                      <ErrorMessage>La contraseña es demasiado corta.</ErrorMessage>
                      <div style={{ height: 16 }} />
                    </>
                  )}

                  <Input
                    name="confirmar-password"
                    touched={touched["confirmar-password"]}
                    error={errors["confirmar-password"]}
                    value={values["confirmar-password"]}
                    type="password"
                    placeholder="Confirmar contraseña"
                    disabled={signUpStates.loading}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={{
                      marginBottom: 16,
                    }}
                  />

                  <Submit
                    isSubmmiting={signUpStates.loading}
                    disabled={isValidating}
                    form="sign-up-form"
                    options={{
                      skin: "purple",
                      size: "lg",
                      type: "filled",
                      block: true,
                    }}
                  >
                    Registrarme
                  </Submit>
                </form>
              );
            }}
          </Formik>

          <div style={{ height: 8 }} />

          {signUpStates.error === "auth/email-already-in-use" && (
            <ErrorMessage>
              Ya existe una cuenta con el e-mail ingresado. Conectate tocando
              aquí:{" "}
              <Link
                href={constants.urls[usertType].signin.url}
                options={{
                  skin: "purple",
                  size: "sm",
                  type: "link",
                }}
              >
                Iniciar Sesión
              </Link>
            </ErrorMessage>
          )}

          <div style={{ height: 8 }} />

          <GoToSignIn>
            ¿Ya tienes cuenta?{" "}
            <Link
              href={constants.urls[usertType].signin.url}
              options={{
                type: "link",
                size: "sm",
                skin: "purple",
              }}
            >
              Inicia sesión
            </Link>
          </GoToSignIn>


        </Content>
      </Center>
    </>
  );
};

const state = ({ authStore, userStore }) => {
  const { states: signUpStates } = authStore.signup;
  const { data: userInformation } = userStore.information;
  return {
    signUpStates,
    userInformation,
  };
};

export default connect(state)(Component);
