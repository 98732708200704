import Spinner from "../../Loader";
import Button from "../../Button";
interface Options {
  type: "filled" | "outline" | "link" | "common";
  size: "xs" | "sm" | "md" | "lg" | "xl";
  skin: "purple" | "ligthPurple" | "transparent" | "white" | "black" | "lightBlue";
  block?: boolean;
}

const defaultOptions: Options = {
  skin: "purple",
  size: "lg",
  type: "filled",
  block: false,
};

interface ComponentProps {
  isSubmmiting: boolean;
  disabled?: boolean;
  options?: Options;
  form: string;
  children: any;
  style?: any;
  onClick?: () => void;
}

const Component = ({
  isSubmmiting,
  disabled,
  children,
  options,
  ...rest
}: ComponentProps) => {
  return (
    <Button
      options={{
        ...defaultOptions,
        ...options,
      }}
      type="submit"
      disabled={isSubmmiting || disabled}
      {...rest}
    >
      {isSubmmiting ? (
        // <Spinner color={options?.skin === "white" || options?.skin === "purple" ? "#6C5CE7" : "white"} width={50} />
        <Spinner color={options?.skin === "white"  || options?.skin === "purple" && options?.type !== "filled" ? "#6C5CE7" : "white"} width={50} />
        //<Spinner color={options?.skin === "white" ? "#6C5CE7" : "white"} width={50} />
      ) : (
        children
      )}
    </Button>
  );
};

export default Component;
