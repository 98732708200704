import * as Yup from "yup";
import { formatPrice } from "../../../helpers/price-formatter";

export const schema = (minAmount = 1, thereIsDiscount = false) => {
  return Yup.object().shape({
    external_name: Yup.string().required("Elige un nombre para tu plan."),
    price: Yup.number().min(minAmount, `${thereIsDiscount ? `Debes indicar un precio mayor a $${formatPrice(minAmount)} ya que tienes una promoción activa` : "Debes indicar un precio mayor a 1"}`).required("Indica un precio."),
    //old_subscribers_price: Yup.number().nullable().min(1, "Indica un precio.").required("Indica un precio."),
    old_subscribers_price: Yup.number().nullable(),
    status: Yup.string().required("Selecciona un estado.")
  });
}

interface FormValues {
  external_name: string,
  price?: number,
  old_subscribers_price: number | null,
  featured: boolean,
  status: string,
  apply_new_price: string[]
}

export const initialValues: FormValues = {
  external_name: "",
  old_subscribers_price: null as null,
  featured: false,
  status: 'active',
  apply_new_price: []
};
