import { connect, useDispatch } from "react-redux";
import Grid from "../../../../../components/Grid";
import Button from "../../../../../components/Button";
import Link from "../../../../../components/Link";
import Image from "../../../../../components/Image";
import Icon from "../../../../../components/Icon";
import noteImage from "../../../../../assets/icons/note.svg";
import ReactDOM from "react-dom";
import { Toolbar, Circle, Back, InLine, Actions, GlobalStyles } from "./styles";
import { showModal } from "../../../../../store/actions/modal";
import { Group, User } from "../../../../../types";
import { useEffect, useState } from "react";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import backArrow from "../../../../../assets/icons/arrow-backward-short.svg";
import expert from "../../../../../services/expert";
import { isOwnGroup } from "../../../../../helpers/isOwnGroup";
import ShareLink from "../ShareLink";
import { getGroupType } from "../../../../../helpers/getGroupType";

interface ComponentProps {
  groupInformation: Group;
  userInformation: User;
  groupInformationStates: {
    loading: boolean
  }
  userInformationStates: {
    loading: boolean
  }
}

const FooterSticky = document.getElementById("footer-sticky") as HTMLElement;
const HeaderSticky = document.getElementById("header-sticky") as HTMLElement;

const Component = ({ groupInformation, userInformation, groupInformationStates, userInformationStates }: ComponentProps) => {
  const dispatch = useDispatch();
  const [isOwner, setIsOwner] = useState<boolean>();
  const [portal, setPortal] = useState(FooterSticky);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    setPortal(isMobile ? FooterSticky : HeaderSticky);
  }, [isMobile]);

  useEffect(() => {
    setIsOwner(
      isOwnGroup({
        group: groupInformation,
        expertId: userInformation?.expert?.id,
      })
    );
  }, [groupInformation, userInformation]);

  if (!isOwner || !groupInformationStates || groupInformationStates.loading || !userInformationStates || userInformationStates.loading) {
    return <></>
  }

  return ReactDOM.createPortal(
    <>
      {isOwner && <GlobalStyles />}

      <Toolbar>
        {groupInformation?.group_type === "free_experience" ? (
          <Back href={`/dashboard/community/events/${groupInformation?.id}`}>
            <Icon icon={backArrow} color="white" size="24px" />
            <span>Volver</span>
          </Back>
        ) : (
          <Back
            href={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}`}
          >
            <Icon icon={backArrow} color="white" size="24px" />
            <span>Volver</span>
          </Back>
        )}

        <Grid.Container>
          <Grid.Row>
            <Grid.Col offset={{ lg: 1 }} lg={10}>
              <InLine>
                {!isMobile && (
                  <InLine style={{ maxWidth: "478px", fontSize: 16 }}>
                    <Circle>
                      <Icon
                        icon={noteImage}
                        size="24px"
                        color="black"
                      />
                    </Circle>

                    {groupInformation?.status === 'draft' ? `Termina de editar el contenido de tu ${getGroupType({
                      group: groupInformation,
                    })} para poder publicarlo y comenzar a promocionarlo.` : `Así verán tus suscriptores el contenido de tu ${getGroupType({
                      group: groupInformation,
                    })}.`}
                  </InLine>
                )}

                <Actions>
                  <Link
                    href={
                      groupInformation?.group_type === "free_experience"
                        ? `/dashboard/community/events/edit/${groupInformation?.id}`
                        : `/dashboard/${groupInformation?.group_type}/edit/${groupInformation?.id}`
                    }
                    options={{
                      type: "outline",
                      size: "lg",
                      skin: "white",
                      block: isMobile,
                      native: false,
                    }}
                  >
                    Editar contenido
                  </Link>

                  {groupInformation?.status === 'active' && <ShareLink />}

                  {/* <Button
                    onClick={() => {
                      const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${groupInformation?.slug}`;
                      navigator.clipboard.writeText(link);
                      dispatch(showModal("modal-share-group-link"));
                    }}
                    options={{
                      type: "filled",
                      size: "lg",
                      skin: "white",
                      block: isMobile,
                    }}
                  >
                    Compartir
                  </Button> */}
                </Actions>
              </InLine>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </Toolbar>
    </>,
    portal
  );
};

const state = ({ groupStore, userStore }) => {
  const { data: groupInformation, states: groupInformationStates } = groupStore.group;
  const { data: userInformation, states: userInformationStates } = userStore.information;

  return {
    groupInformation,
    groupInformationStates,
    userInformation,
    userInformationStates
  };
};

export default connect(state)(Component);
