import styled, { keyframes } from "styled-components";

const loader1 = keyframes`
  0% { transform: scale(0);}
  100% { transform: scale(1);}
`;

const loader2 = keyframes`
  0% { transform: scale(1);}
  100% { transform: scale(0);}
`;

const loader3 = (x) => keyframes`
  0% { transform: translate(0, 0);}
  100% { transform: translate(${x}px, 0);}
`;

const WIDTH = 80;

export const Loader = styled('div') <{ width }>`
  display: block;
  position: relative;
  width: ${({ width }) => width || WIDTH}px;
  height: ${({ width }) => (width || WIDTH) * 13 / WIDTH}px;
  margin: 0px auto;
`;
export const Circle = styled("div") <{ color?: string, width?: number }>`
  position: absolute;
  top: 0;
  width: ${({ width }) => (width || WIDTH) * 13 / WIDTH}px;
  height: ${({ width }) => (width || WIDTH) * 13 / WIDTH}px;
  border-radius: 50%;
  background: ${(props) => (props.color ? props.color : "#FFFFFF")};
  animation-timing-function: cubic-bezier(0, 1, 1, 0);

  &:nth-child(1) {
    left: ${({ width }) => (width || WIDTH) * 8 / WIDTH}px;
    animation: ${loader1} 0.6s infinite;
  }

  &:nth-child(2) {
    left: ${({ width }) => (width || WIDTH) * 8 / WIDTH}px;
    animation: ${({ width }) => loader3((width || WIDTH) * 32 / WIDTH)} 0.6s infinite;
  }

  &:nth-child(3) {
    left: ${({ width }) => (width || WIDTH) * 32 / WIDTH}px;
    animation: ${({ width }) => loader3((width || WIDTH) * 32 / WIDTH)} 0.6s infinite;
  }

  &:nth-child(4) {
    left: ${({ width }) => (width || WIDTH) * 56 / WIDTH}px;
    animation: ${loader2} 0.6s infinite;
  }
`;
