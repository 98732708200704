interface CheckoutRebillParams {
  groupType?: "membership" | "experience";
  planId: string | null;
  platform?: string | null;
  organizationId?: string | null;
  organizationAlias?: string | null;
  couponId?: number;
  customer: {
    email: string;
    id?: string;
    phoneAreaCode?: string;
    cellphone?: string;
  };
  qty?: number;
  site: string;
  onLoad?: (data: string) => void;
  onClose?: (data: string) => void;
  onSuccess?: (data: string) => void;
  onError?: (data: string) => void;
  onStep?: (data: string) => void;
}
declare global {
  interface Window {
    Chargebee: any;
  }
}

export const rebillUrlSubscriptionPayment = ({
  planId,
  couponId,
  organizationId,
  organizationAlias,
  customer,
  qty = 1,
  site,
}: CheckoutRebillParams) => {
  const callbackUrl = `?callback_url=${process.env.REACT_APP_SITE_BASE_URL}/checkout/success`;
  const customerEmail = customer.email ? `&email=${customer.email}` : "";
  const customerPhoneAreaCode = customer.phoneAreaCode
    ? `&phoneAreaCode=${customer.phoneAreaCode}`
    : "";
  const customerCellphone = customer.cellphone
    ? `&phoneNumber=${customer.cellphone}`
    : "";

  if(organizationId){
    return `https://pay.rebill.com/${organizationAlias}/price/${couponId ? couponId : planId}${callbackUrl}${customerEmail}${customerPhoneAreaCode}${customerCellphone}`
  } else {
    return `https://billing.klouser.app/price/${couponId ? couponId : planId}${callbackUrl}${customerEmail}${customerPhoneAreaCode}${customerCellphone}`
  }

  
  
  //return `https://checkout.rebill.to/${process.env.REACT_APP_REBILL_SITE}?${plan}${callbackUrl}${customerEmail}${customerPhoneAreaCode}${customerCellphone}`;
};

export const rebillCheckoutUrl = (data: CheckoutRebillParams): string => {
  const { groupType, platform, ...rest } = data;
  return rebillUrlSubscriptionPayment(rest);
};
