import styled from "styled-components";

export const SubTitle = styled("h6")<{ marginBottom? }>`
  color: ${(props) => props.theme.colors.Black32};
  font-size: 12px;
  font-weight: 800;
  line-height: 15px;
  text-transform: uppercase;
  margin: 0px;
  letter-spacing: 2.5px;
  ${(props) => `margin-bottom: ${props?.marginBottom || 0}px`};
`;
