import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  #header-sticky {
    position: relative;
  }
`;

export const Toolbar = styled("div")`
  background-color: ${({ theme }) => theme.colors.Primary};
  padding: 8px 0px;
  font-size: 18px;
  line-height: 22px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Circle = styled("div")`
  width: 37px;
  height: 37px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-right: 12px;
  flex-shrink: 0;
`;

export const Back = styled("a")`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(0%, -50%);
  display: none;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 36px;

  &:hover {
    text-decoration: underline;
  }

  i {
    margin-right: 16px;
  }

  span {
    display: none;

    @media (min-width: 1200px) {
      display: block;
    }
  }

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const InLine = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Actions = styled("div")`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }

  @media (min-width: 768px) {
    margin-left: 24px;
    width: auto;
    flex-direction: row;

    & > *:not(:last-child) {
      margin-bottom: 0px;
    }

    & > *:not(:last-child) {
      margin-right: 8px;
    }
  }
`;
