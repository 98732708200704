interface ActivityInDto {}

export const GET_GROUP_LAST_ACTIVITY = "GET_GROUP_LAST_ACTIVITY";
export const GET_GROUP_LAST_ACTIVITY_SUCCESS =
  "GET_GROUP_LAST_ACTIVITY_SUCCESS";
export const GET_GROUP_LAST_ACTIVITY_ERROR = "GET_GROUP_LAST_ACTIVITY_ERROR";

export interface GetGroupLastActivity {
  type: typeof GET_GROUP_LAST_ACTIVITY;
  payload: {
    group_id: number;
    size?: number;
  };
}

export interface GetGroupLastActivitySuccess {
  type: typeof GET_GROUP_LAST_ACTIVITY_SUCCESS;
  payload: ActivityInDto[] | null;
}

export interface GetGroupLastActivityError {
  type: typeof GET_GROUP_LAST_ACTIVITY_ERROR;
  payload: boolean | string;
}

export interface State {
  data: ActivityInDto[] | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetGroupLastActivity
  | GetGroupLastActivityError
  | GetGroupLastActivitySuccess;
