import {
  initialState,
  Actions,
  State,
  SELECTED_MODULE,
  SELECTED_MODULE_SUCCESS,
  SELECTED_MODULE_ERROR,
} from "../../types/module/selected-module";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SELECTED_MODULE:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case SELECTED_MODULE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case SELECTED_MODULE_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default reducers;
