import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";

import DashboardLayout from "../../../components/Layout/DashboardLayout";

import Edit from "./Edit";
import New from "./New";
import List from "./List";

const Component = ({ groups }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={`${path}/`}
        render={() => <Redirect to={`${path}/list/active`} />}
      />

      <Route
        exact
        path={`${path}/list`}
        render={() => <Redirect to={`${path}/list/active`} />}
      />

      <Route
        exact
        path={`${path}/list/:status`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={1}>
            <List {...props} />
          </DashboardLayout>
        )}
      />
      <Route
        exact
        path={`${path}/new`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation} navigationLevel={2}>
            <New />
          </DashboardLayout>
        )}
      />
      {/* <Route
        exact
        path={`${path}/edit/:idCoupon`}
        render={(props) => (
          <DashboardLayout useLocation={useLocation}>
            <Edit {...props} />
          </DashboardLayout>
        )}
      /> */}
      <Route exact path={`${path}/*`} render={() => <Redirect to={"/404"} />} />
    </Switch>
  );
};

const state = ({ userStore }) => {
  const { data: groups } = userStore.groups;
  return { groups };
};

export default connect(state)(Component);
