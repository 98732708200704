import LightEot from "../../../../../assets/fonts/Inter/Light.eot";
import LightTtf from "../../../../../assets/fonts/Inter/Light.ttf";
import LightWoff from "../../../../../assets/fonts/Inter/Light.woff";
import LightWoff2 from "../../../../../assets/fonts/Inter/Light.woff2";

import MediumEot from "../../../../../assets/fonts/Inter/Medium.eot";
import MediumTtf from "../../../../../assets/fonts/Inter/Medium.ttf";
import MediumWoff from "../../../../../assets/fonts/Inter/Medium.woff";
import MediumWoff2 from "../../../../../assets/fonts/Inter/Medium.woff2";

import RegularEot from "../../../../../assets/fonts/Inter/Regular.eot";
import RegularTtf from "../../../../../assets/fonts/Inter/Regular.ttf";
import RegularWoff from "../../../../../assets/fonts/Inter/Regular.woff";
import RegularWoff2 from "../../../../../assets/fonts/Inter/Regular.woff2";

import SemiBoldEot from "../../../../../assets/fonts/Inter/SemiBold.eot";
import SemiBoldTtf from "../../../../../assets/fonts/Inter/SemiBold.ttf";
import SemiBoldWoff from "../../../../../assets/fonts/Inter/SemiBold.woff";
import SemiBoldWoff2 from "../../../../../assets/fonts/Inter/SemiBold.woff2";

import BoldEot from "../../../../../assets/fonts/Inter/Bold.eot";
import BoldTtf from "../../../../../assets/fonts/Inter/Bold.ttf";
import BoldWoff from "../../../../../assets/fonts/Inter/Bold.woff";
import BoldWoff2 from "../../../../../assets/fonts/Inter/Bold.woff2";

const fontName = "Inter";
const fontSizeBase = 16;

const fontFace = `
  @font-face {
    font-display: swap;
    font-weight: 300;
    font-style: normal;
    font-family: ${fontName};
    src: local(${fontName}),
        url('${LightEot}?#iefix') format('embedded-opentype'),
        url('${LightTtf}') format('truetype');
        url('${LightWoff}') format('woff'),
        url('${LightWoff2}') format('woff2'),
  }
  
  
  @font-face {
    font-display: swap;
    font-weight: 400;
    font-style: normal;
    font-family: ${fontName};
    src: local(${fontName}),
    url('${RegularEot}?#iefix') format('embedded-opentype'),
    url('${RegularTtf}') format('truetype');
    url('${RegularWoff}') format('woff'),
    url('${RegularWoff2}') format('woff2'),
  }
  
  @font-face {
    font-display: swap;
    font-weight: 500;
    font-style: normal;
    font-family: ${fontName};
    src: local(${fontName}),
        url('${MediumEot}?#iefix') format('embedded-opentype'),
        url('${MediumTtf}') format('truetype');
        url('${MediumWoff}') format('woff'),
        url('${MediumWoff2}') format('woff2'),
  }
  
  @font-face {
    font-display: swap;
    font-weight: 600;
    font-style: normal;
    font-family: ${fontName};
    src: local(${fontName}),
        url('${SemiBoldEot}?#iefix') format('embedded-opentype'),
        url('${SemiBoldTtf}') format('truetype');
        url('${SemiBoldWoff}') format('woff'),
        url('${SemiBoldWoff2}') format('woff2'),
  }
  
  @font-face {
    font-display: swap;
    font-weight: 700;
    font-style: normal;
    font-family: ${fontName};
    src: local(${fontName}),
        url('${BoldEot}?#iefix') format('embedded-opentype'),
        url('${BoldTtf}') format('truetype');
        url('${BoldWoff}') format('woff'),
        url('${BoldWoff2}') format('woff2'),
  }
`;

export const fonts = {
  fontFace,
  family: `'${fontName}', sans-serif`,
  sizes: {
    base: `${fontSizeBase}px`,
    small: `${fontSizeBase}px`,
    medium: `${fontSizeBase + 2}px`,
    large: `${fontSizeBase + 4}px`,
    caption: `${fontSizeBase - 2}px`,
    h1: `${fontSizeBase + 38}px`,
    h2: `${fontSizeBase + 18}px`,
    h3: `${fontSizeBase + 10}px`,
    h4: `${fontSizeBase + 2}px`,
    h5: `${fontSizeBase}px`,
    h6: `${fontSizeBase - 2}px`,
  },
};
