import {
  initialState,
  Actions,
  State,
  REORDER_WELCOME_CONTENT_POSTS,
  REORDER_WELCOME_CONTENT_POSTS_SUCCESS,
  REORDER_WELCOME_CONTENT_POSTS_ERROR,
} from "../../types/welcome-content/reorder-welcome-content-posts";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case REORDER_WELCOME_CONTENT_POSTS:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case REORDER_WELCOME_CONTENT_POSTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case REORDER_WELCOME_CONTENT_POSTS_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default reducers;
