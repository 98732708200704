import Dropzone from "./components/Dropzone";
import { DisabledDropzone, Message } from "./styles";
import Icon from "../../Icon";
import fileInbox from "../../../assets/icons/file-inbox.svg";
import ModalWaitAMinute from "./components/ModalWaitAMinute";
import constants from "../../../constants";
import {
  FieldContainer,
  Label,
  HelperLine,
  HelperText,
  ExplainText,
  ErrorMessage,
} from "../Field";
import { useField } from "formik";
import { useEffect } from "react";

const initialValue = {
  options: {
    skin: "base",
    size: "md",
  },
};

const extensions = {
  video: constants.fileUploader.videoMimes,
  audio: constants.fileUploader.audioMimes,
  image: constants.fileUploader.imagesMimes,
  other: constants.fileUploader.otherMimes,
};


interface ComponentProps {
  name: string;
  rules: {
    typeFiles: ("video" | "audio" | "image" | "other")[];
    maxFiles: number;
    maxSize?: number;
  };
  value?: string | number;
  disabled?: boolean;
  error?: {};
  touched?: {};
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (file: File[]) => void;
  onRemoveItem?: (event, file) => void;
  options: {
    label?: string;
    skin?: "base";
    size?: "md";
    helperText?: string;
    explainText?: string;
    marginBottom?: number;
    hidden?: boolean;
    showLoadFileCta?: boolean;
  };
}

const Component = ({
  options = {},
  rules,
  disabled,
  name,
  value,
  error,
  touched,
  onChange,
  onRemoveItem,
}: ComponentProps) => {
  const [field, _meta, helpers] = useField({ name, value });

  const { marginBottom, hidden } = {
    ...initialValue.options,
    ...options,
  };

  const handleOnChange = (files, callback) => {
    helpers.setValue(files);
    helpers.setValue(files);
    console.log(files)
    callback && callback(files);
  };

  useEffect(() => {
    disabled && helpers.setValue("");
  }, [disabled]);

  return (
    <>
      <FieldContainer
        marginBottom={marginBottom}
        {...(hidden && { style: { display: "none" } })}
      >
        {options?.label && (
          <Label
            size={options?.size}
            htmlFor={`input-${name}`}
            style={!!options?.explainText ? { marginBottom: "0px" } : {}}
          >
            {options.label}
          </Label>
        )}

        {!!options?.explainText && (
          <ExplainText>{options?.explainText}</ExplainText>
        )}

        {disabled ? (
          <DisabledDropzone>
            <Icon icon={fileInbox} color="#DBDBDB" size="48px" />
            <Message>
              Arrastra tu archivo o haz
              <br /> click aquí{" "}
            </Message>
          </DisabledDropzone>
        ) : (
          <Dropzone
            labelButton="Adjuntar documento"
            showLoadFileCta={options.showLoadFileCta}
            maxFilesNumber={rules.maxFiles}
            maxFileSize={rules.maxSize}
            hideDroppedFiles
            aceptedExtensions={rules.typeFiles
              .map((type) => extensions[type])
              .flat()}
            defaultFiles={field.value}
            onRemoveItem={(event, deletedFile, files) => {
              handleOnChange(files, onChange);
              onRemoveItem && onRemoveItem(event, deletedFile);
            }}
            onChange={(files) => handleOnChange(files, onChange)}
          />
        )}

        {((options && options.helperText) || error) && (
          <HelperLine marginBottom={marginBottom}>
            {!!error && !!touched && <ErrorMessage>{error}</ErrorMessage>}
            {options && options.helperText && !error && (
              <HelperText>{options.helperText}</HelperText>
            )}
          </HelperLine>
        )}
      </FieldContainer>

      <ModalWaitAMinute />
    </>
  );
};

export default Component;
