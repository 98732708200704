import * as Yup from "yup";

export const schema = Yup.object().shape({
  content_data: Yup.object({
    notes: Yup.string().nullable(),
  }),
});

interface FormValues {
  content_data: {
    notes?: string;
  };
}

export const initialValues: FormValues = {
  content_data: {},
};
