import styled from 'styled-components'
import UiLink from "../../../../../components/Link";
import UiButton from "../../../../../components/Button";
import { rainbowBackgroundGradient } from '../../../../../components/StyledComponents';

export const Backdrop = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
`

export const Content = styled('div')`
  border-radius: 6px;
  ${rainbowBackgroundGradient};
  color: ${({ theme }) => theme.colors.White};
  padding: 0px 25px;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Avatar = styled('div')`
  width: 90px;
  height: 90px;
  border-radius: 100%;
  overflow: hidden;

  img { 
    width: 100%;
    height: auto;
    display: block;
  }
`

export const H3 = styled('h3')`
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  margin: 0px;
  text-align: center;
`

export const H2 = styled('h2')`
  font-size: 26px;
  line-height: 30px;
  font-weight: 500;
  margin: 0px;
  text-align: center;
`

export const Link = styled(UiLink)`
  max-width: 250px;
`

export const ChangeAccountLink = styled(UiButton)`
  padding: 0px;
  margin: 0px;
  border: none;
  width: auto;
  min-width: 0px;
  line-height: 36px;
  background-color: transparent;
  position: relative;
  color: ${({ theme }) => theme.colors.White};
  font-size: 14px;
  text-align: center;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.White};
    position: absolute;
    bottom: 0px;
    transition: height .2s ease-in-out;
  }

  &:hover {
    background-color: none;
    box-shadow: none;
    text-decoration: none;

    &:after {
      height: 2px;
    }
  }
`

export const SignInLink = styled(UiLink)`
  padding: 0px;
  margin: 0px;
  border: none;
  width: auto;
  display: block;
  min-width: 0px;
  line-height: 28px;
  background-color: transparent;
  position: relative;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.White};;
  text-align: center;
  margin-left: 15px;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.White};;
    position: absolute;
    bottom: 0px;
    transition: height .2s ease-in-out;
  }

  &:hover {
    background-color: none;
    box-shadow: none;
    text-decoration: none;

    &:after {
      height: 3px;
    }
  }
`

export const Hello = styled('div')`
  font-size: 14px;
  font-weight: normal;
`

export const AccountSignIn = styled("p")`
  margin: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const ChangeAccount = styled('div')`
  margin: 0px;
  text-align: center;
`