import image from "./../assets/backgrounds/pages/girl-doing-yoga.svg";
import background from "./../assets/backgrounds/pages/colored-circles-and-squares.svg";

const telegram = {
  title: "",
  subtitle: "",
  image: image,
  background: background,
};

export default telegram;
