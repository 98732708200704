import styled from "styled-components";

export const Benefit = styled("div")`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
`;

export const RemoveBenefit = styled("button")`
  border: 0px;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  margin: 0px 0px 0px 10px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${(props) => props.theme.colors.Gray5};
  }

  // @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
  //   position: absolute;
  //   left: 100%;
  //   top: 0px;
  // }
`;
