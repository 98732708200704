import * as Yup from "yup";

export const schema = Yup.object().shape({
  survey: Yup.number(),
  subscription: Yup.number(),
  answers: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().nullable(),
    })
  ),
});

interface FormValues {
  survey: number | null;
  subscription: number | null;
  answers: { value: string | null }[];
}

export const initialValues: FormValues = {
  survey: null,
  subscription: null,
  answers: [],
};
