// src/components/WebhookCopyUrl.js
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #f9fafb;
  border: 1px solid #d1d5db;
  border-radius: 8px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #6b7280;
  margin-bottom: 8px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  background-color: #ffffff;
  overflow: hidden;
`;

const Input = styled.input`
  flex: 1;
  border: none;
  padding: 12px 16px;
  font-size: 14px;
  color: #374151;
  background: transparent;
  outline: none;

  ::placeholder {
    color: #9ca3af;
  }
`;

const CopyButton = styled.button`
  background-color: #4f46e5;
  border: none;
  color: white;
  padding: 10px 16px;
  cursor: pointer;
  font-size: 14px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #4338ca;
  }
`;

const Notification = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: #10b981; // Tailwind 'text-green-500' color for success
`;

const Instructions = styled.div`
  margin-top: 20px;
  font-size: 14px;
  color: #4b5563; // Tailwind 'text-gray-600'
`;

const InstructionItem = styled.p`
  margin: 4px 0;
  line-height: 1.4;
`;

// Main Component
const WebhookCopyUrl = ({gateway}) => {

  const [copySuccess, setCopySuccess] = useState('');


  // Copy URL to clipboard
  const handleCopy = () => {
    navigator.clipboard.writeText(gateway?.credentials?.public?.webhook_url).then(() => {
      setCopySuccess('URL copiada al portapapeles');
      setTimeout(() => setCopySuccess(''), 3000); // Clear notification after 3 seconds
    });
  };

  return (
    <Container>
      <Label>Webhook URL</Label>
      <InputContainer>
        <Input value={gateway?.credentials?.public?.webhook_url} readOnly />
        <CopyButton type="button" onClick={handleCopy}>
          Copiar
        </CopyButton>
      </InputContainer>
      {copySuccess && <Notification>{copySuccess}</Notification>}

      <Instructions>
        <h3>Finaliza la configuración: </h3>
        <InstructionItem>En tu <a href="https://www.mercadopago.com.ar/developers/panel/app" target="_blank" rel="noopener noreferrer">integración de MercadoPago:</a>
        </InstructionItem> 
        <InstructionItem>1. Abrir la sección de <strong>Notificaciones &gt; Webhooks</strong></InstructionItem>
        <InstructionItem>2. Haz click en "Configurar Notificaciones" y Selecciona la pestaña <strong>"Modo Productivo"</strong></InstructionItem>
        <InstructionItem>
          3. Copia esta URL en el campo <strong>"URL de producción"</strong>
        </InstructionItem>
        <InstructionItem>
          4. En la parte de <strong>"Eventos"</strong>, tilda todas las casillas.
        </InstructionItem>
        <InstructionItem>
          5. Guarda los cambios realizados
        </InstructionItem>
      </Instructions>
    </Container>
  );
};

export default WebhookCopyUrl;
