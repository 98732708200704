import action from "./../helpers/rest-client";

export class ErrorSubscription extends Error {}

class SaveMeet {
  public async saveMeet(payload) {
    return action
      .Post({
        url: `/api/meet`,
        body: payload,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorSubscription("Error al procesar la subscripción");
      });
  }

  public async getMeet(payload) {
    const { userId } = payload;
    return action
      .Get({
        url: `/api/meet/${userId}`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorSubscription("Error al procesar la subscripción");
      });
  }
}

const saveMeet = new SaveMeet();
export default saveMeet;
