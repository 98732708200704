import styled from 'styled-components'

export const Text = styled("p")`
  margin: 0px;
  font-size: 16px;
  line-height: 19px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
  }

  & > * {
    overflow-wrap: break-word;
    & > * {
      overflow-wrap: break-word;
    }
  }
`;
export const Title = styled("h1")`
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
  }
`;

export const Card = styled("div")`
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  width: 100%;
`;
