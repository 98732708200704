import styled from "styled-components";

export const Toolbar = styled("div")`
  padding: 16px 0px;
  font-size: 18px;
  line-height: 22px;
  color: white;
  background-color: ${({ theme }) => theme.colors.Primary};

  & > div {
    button,
    a {
      display: block;
      width: 100%;

      &[type="button"] {
        background-color: transparent;
        border-color: white;
        color: ${({ theme }) => theme.colors.White};
      }

      &[type="filled"] {
        background-color: white;
        color: ${({ theme }) => theme.colors.Primary};
      }
    }
  }
`;

export const Actions = styled("div")`
  padding: 0px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  gap: 8px;

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }

  @media (min-width: 768px) {
    margin-left: 24px;
    width: auto;
    flex-direction: row;

    & > *:not(:last-child) {
      margin-bottom: 0px;
    }

    & > *:not(:last-child) {
      margin-right: 8px;
    }
  }
`;
