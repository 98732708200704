import styled from "styled-components";
import { Card as CardUi } from "../StyledComponents";
import {
  rainbowBackgroundGradient,
  rainbowTextGradient,
} from "../../../../../components/StyledComponents";

export const CurrencyLabel = styled('span')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: #514F6E;
`

export const Button = styled('button')`
  span {display: flex };
`

export const Cards = styled('div') <{ totalItems, thereAreDiscount: boolean }>`
  display: grid;
  gap: 30px;
  grid-auto-rows: auto;
  justify-content: center;
  align-items: flex-start;
  
  /* For screens where there are 3 items or multiples of 3 */
  @media (min-width: 1024px) {
    grid-template-columns: repeat(${({ totalItems }) => totalItems > 3 ? 3 : totalItems}, 1fr);
    gap: 53px;
    grid-auto-rows: ${({ thereAreDiscount }) => thereAreDiscount ? "419px" : "363px"};
    justify-content: flex-start;
    align-items: flex-start;
  }

  /* For screens where there are 2 items or multiples of 2 */
  @media (min-width: 1024px) and (max-width: 1439px) {
    grid-template-columns: repeat(${({ totalItems }) => totalItems > 2 ? 2 : totalItems}, 1fr);
  }
`

export const FeaturedPrice = styled('div') <{ isFeatured: boolean, hasDiscount: boolean }>`
  background: ${({ isFeatured }) => isFeatured ? rainbowBackgroundGradient : "transparent"};
  padding: 2px;
  border-radius: 18px;
  margin: 0 auto ${({ hasDiscount }) => hasDiscount ? "55px" : "0px"};
  position: relative;
  width: 100%;
  max-width: 360px;

  @media (min-width: 1024px) {
    min-height: 364px;
  }
`

export const FeaturePriceLabel = styled('div') <{ isFeatured: boolean }>`
  display: ${({ isFeatured }) => isFeatured ? "block" : "none"};
  font-size: ${({ isFeatured }) => isFeatured ? "14px" : "0px"};
  padding: 8px 0px;
  color: white;
  text-align: center;

  @media(min-width: 768px){
    display: block;
  }
`

export const Card = styled(CardUi)`
  background-color: white;
  width: 100%;
  height: 100%;
  max-width: 360px;
  padding: 22px 34px 18px;
  text-align: center;
  position: relative;
  z-index: 2;

`;

export const CardCol = styled("div")`
  padding: 12px 0px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 30px;
  }
`;

export const DiscountAmount = styled('div')`
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
`

export const Owner = styled("p")`
  ${rainbowTextGradient}
  margin: 0px;
  font-weight: 700;
  font-size: 15px;
  line-height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Start = styled("p")`
  margin: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(45, 52, 54, 0.75);
`;

export const Duration = styled("p")`
  margin: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(45, 52, 54, 0.75);
`;

export const OldPrice = styled("div")`
  width: max-content;
  font-size: 26px;
  line-height: 1;
  min-height: 26px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
  text-align: center;
  color: rgba(45, 52, 54, 0.75);
  position: relative;

  &:before {
    content: "";
    display: block;
    background-color: rgba(235, 87, 87, 1);
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 1px;
    transform: translate(-50%, -50%);
  }
`;

export const CurrentPrice = styled('div')`
  font-size: 60px;
  line-height: 64px;
  color: #170F49;
  font-weight: bold;
  font-family: 'DM Sans', sans-serif;
`

export const Price = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const PlanCurrencyAndPeriod = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 5px;
`

export const SecondaryCurrency = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

export const PlanName = styled('div')`
  line-height: 25px;
  font-size: 26px;
  font-weight: bold;
  font-family: 'DM Sans', sans-serif;
`

export const Amount = styled("p")`
  margin: 0;
  font-weight: 600;
  font-size: 62px;
  line-height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Currency = styled("p")`
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #979797;
`;

export const Period = styled("p")`
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
`;

export const LocalPrice = styled("div")`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: rgba(45, 52, 54, 0.75);
  text-align: center;
  margin-top: -10px;
  display: block;
`;

export const ExplainText = styled('div')`
  font-family: 'DM Sans', sans-serif;
  font-style: italic;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  min-height: 38px;
`


export const List = styled("ul")`
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 32px auto 0px;
  gap: 15px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: 0px;
    margin: 12px auto 0px;
  }
`;

export const ListItem = styled.li`
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.Black45};

  span {
    margin-left: 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
  }
`;

export const DiscountWrapper = styled("div") <{ rainbow?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ rainbow }) => (rainbow ? "white" : "white")}!important;
  ${({ rainbow }) =>
    rainbow ? rainbowBackgroundGradient : "background-color: #626768"};
  border-radius: 0px 0px 18px 18px;
  padding: 22px 0px 0px;

  font-size: 11px;
  line-height: 14px;

  position: absolute;
  z-index: -1;
  height: 77px;
  top: calc(100% - 22px);
  left: 0px;
  right: 0px;
  // transform: translate(-50%, -50%);

  div,
  span:before {
    color: ${({ rainbow }) => (rainbow ? "white" : "inherit")}!important;
  }
`;

export const DiscountCol = styled('div')`
  height: 55px;  
  width: 50%;
  padding: 0px 22px;
  text-align: center;
  padding: 5px 0px;
`

export const Title = styled("span")`
  font-weight: 500;
  font-size: 22px;
`;

export const SelectCurrency = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  z-index: 10;

  .dropdown-opened {
    border-radius: 15px 15px 0px 0px!important;
  }
`

export const Center = styled('div')`
display: flex; 
align-items: center;
justify-content: center;
flex-direction: column;
`
