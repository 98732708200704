const pages = [
  {
    regex: /(\/subscriber)(\/signin)/g,
    name: "SIGN_IN",
  },
  {
    regex: /(\/subscriber)(\/signup)/g,
    name: "SIGN_UP",
  },
  {
    regex: /(\/expert)(\/signin)/g,
    name: "EXPERT_SIGN_IN",
  },
  {
    regex: /(\/expert)(\/signup)/g,
    name: "EXPERT_SIGN_UP",
  },
  {
    regex: /(\/profile)(\/subscriptions)/g,
    name: "SUBSCRIPTIONS_LIST",
  },
  {
    regex: /(\/profile)(\/subscription)/g,
    name: "SUBSCRIPTION",
  },
  {
    regex: /(\/profile)(\/complete-profile)/g,
    name: "COMPLETE_PROFILE",
  },
  {
    regex: /(\/profile\/my-account)/g,
    name: "MY_PROFILE",
  },
  {
    regex: /(\/profile\/manage-subscriptions)/g,
    name: "MANAGE_SUBSCRIPTIONS",
  },
  {
    regex: /(\/profile)(\/survey)/g,
    name: "SURVEY",
  },
  {
    regex: /(\/profile)(\/welcome)/g,
    name: "WELCOME",
  },
  {
    regex: /(\/profile)/g,
    name: "PROFILE",
  },
  {
    regex: /(\/dashboard)(\/experience)(\/new)/g,
    name: "NEW_EXPERIENCE",
  },
  {
    regex: /(\/dashboard)(\/experience)(\/edit)/g,
    name: "EDIT_EXPERIENCE",
  },
  {
    regex: /(\/dashboard)(\/experience)(\/list)/g,
    name: "LIST_EXPERIENCE",
  },
  {
    regex: /(\/dashboard)(\/experience)/g,
    name: "PREVIEW_EXPERIENCE",
  },
  {
    regex: /(\/dashboard)(\/membership)(\/new)/g,
    name: "NEW_MEMBERSHIP",
  },
  {
    regex: /(\/dashboard)(\/membership)(\/edit)/g,
    name: "EDIT_MEMBERSHIP",
  },
  {
    regex: /(\/dashboard)(\/membership)(\/list)/g,
    name: "LIST_MEMBERSHIP",
  },
  {
    regex: /(\/dashboard)(\/membership)/g,
    name: "PREVIEW_MEMBERSHIP",
  },
  {
    regex: /(\/checkout)(\/update)/g,
    name: "UPDATE_PAYMENT_METHOD",
  },
  {
    regex: /(\/gateways)/g,
    name: "LINKED_GATEWAYS",
  },
  {
    regex: /(\/dashboard)(\/community)(\/events)(\/new)/g,
    name: "NEW_COMMUNITY_EVENT",
  },
  {
    regex: /(\/dashboard)(\/community)/g,
    name: "PREVIEW_COMMUNITY",
  },
  {
    regex: /(\/dashboard)(\/community)(\/new)/g,
    name: "NEW_COMMUNITY",
  },
  {
    regex: /(\/dashboard)(\/community)(\/edit)/g,
    name: "EDIT_COMMUNITY",
  },
  {
    regex: /(\/dashboard)(\/community)(\/list)/g,
    name: "LIST_COMMUNITY",
  },
  {
    regex: /(\/dashboard)(\/members)/g,
    name: "MEMBERS",
  },
  {
    regex: /(\/dashboard)(\/coupon)(\/new)/g,
    name: "NEW_COUPON",
  },
  {
    regex: /(\/dashboard)(\/coupon)(\/edit)/g,
    name: "EDIT_COUPON",
  },
  {
    regex: /(\/dashboard)(\/coupon)(\/list)/g,
    name: "LIST_COUPONS",
  },
  {
    regex: /(\/dashboard)(\/configuration)/g,
    name: "CONFIGURATION",
  },
  // {
  //   regex: /(\/dashboard)(\/home)/g,
  //   name: "DASHBOARD_HOME",
  // },
  {
    regex: /(?=(\/dashboard)(\/group)(\/[\d])).+/g,
    name: "DASHBOARD_GROUP_DETAILS",
  },
  // {
  //   regex: /(\/dashboard)(\/groups)/g,
  //   name: "DASHBOARD_GROUPS_LIST",
  // },
  // {
  //   regex: /(?=(\/dashboard)(\/library)(\/[\d])).+/g,
  //   name: "DASHBOARD_GROUP_LIBRARY",
  // },
  {
    regex: /(\/group\/.*\/survey)/g,
    name: "GROUP_DETAILS_SURVEY",
  },
  {
    regex: /(\/group\/.*\/library)/g,
    name: "GROUP_DETAILS_LIBRARY",
  },
  {
    regex: /(\/group\/.*\/activity-summary)/g,
    name: "GROUP_DETAILS_ACTIVITY_SUMMARY",
  },
  {
    regex: /(\/group\/.*\/sessions)/g,
    name: "GROUP_DETAILS_SESSIONS",
  },
  {
    regex: /(\/group\/.*\/modules)/g,
    name: "GROUP_DETAILS_MODULES",
  },
  {
    regex: /(\/group\/.*\/p\/.*)/g,
    name: "GROUP_POST_PREVIEW",
  },
  {
    regex: /(\/group\/.*\/s\/.*)/g,
    name: "GROUP_SESSION_PREVIEW",
  },
  {
    regex: /(\/group\/)/g,
    name: "GROUP_DETAILS",
  },
  {
    regex: /(\/)(\w).*/g,
    name: "EXPERT_GROUPS",
  },
  {
    regex: /(\/)/g,
    name: "HOME_PAGE",
  },
];

export default pages;
