import Icon from '../../../../components/Icon'
import {
  Column, Title, LinkBlack, LinkTransparent, ProfileImage, Message
} from "./styles";
import { connect, useDispatch } from "react-redux";
import userAvatar from "../../../../assets/avatar.svg";
import { ReactElement, useMemo } from 'react';
import whatsappIcon from '../../../../assets/icons/whatsapp.svg'
import logOut from "../../../../assets/dashboard-menu/log-out.svg";
import settings from "../../../../assets/dashboard-menu/settings.svg";
import Image from '../../../../components/Image'
import mercadoPagoImg from '../../../../assets/mercado-pago.png'
import { useTheme } from 'styled-components';
import constants from '../../../../constants';
import { signOut } from '../../../../store/actions/auth';
import { Group, User } from '../../../../types';

interface ComponentProps {
  group: Group
  user: User
  text?: ReactElement
}

const Component = ({ group, user, text }: ComponentProps) => {
  const dispatch = useDispatch()
  const theme = useTheme() as { logo: { small: string, big: string } };

  const profilePicture = useMemo(() => {
    return group?.expert?.profile_pic?.url || userAvatar
  }, [group])

  return (
    <Column>
      <div style={{ gap: 24, display: 'flex', justifyContent: 'center', width: '100%', maxWidth: 410 }}>
        <Image
          src={theme?.logo?.big}
          style={{ display: 'block' }}
          width="107px"
          height="auto"
          className="logo-desktop"
          alt="Klouser"
          lazy
        />

      </div>

      <div style={{ height: 52 }} />

      <ProfileImage loading="lazy" src={profilePicture} alt={"Profile Image"} />
      <div style={{ height: 42 }} />
      <Message>Hola {user?.email}</Message>
      <div style={{ height: 22 }} />
      <Title>Parece que estás en la cuenta equivocada.</Title>
      <div style={{ height: 42 }} />
      <Message>
        {
          text ?
            text :
            <>
              El enlace al que intentas ingresar<br /> pertenece a otra cuenta.<br /><br />
              🔍 ¿Tienes más de una cuenta con nosotros?<br /><br />Asegúrate de haber iniciado sesión con el correo correcto.
              Si manejas varios emails, prueba con otro.
            </>
        }
      </Message>
      <div style={{ height: 42 }} />
      <LinkBlack
        options={{ type: 'outline', size: 'lg', skin: 'purple', block: true }}
        href={`/profile/my-account`}
      >
        <Icon size='20px' icon={settings} color="#FFFFFF" />
        Ir a mi perfil
      </LinkBlack>

      <LinkTransparent
        options={{ type: 'link', size: 'lg', skin: 'purple', block: true }}
        onClick={() => { dispatch(signOut()) }}
      >
        <Icon size='20px' icon={logOut} color="#000000" />
        Cerrar sesión
      </LinkTransparent>
    </Column>
  )
}

const state = ({ groupStore, userStore }) => {
  const { data: group } = groupStore?.group;
  const { data: user } = userStore.information;

  return { group, user };
};

export default connect(state)(Component);