import styled from "styled-components";

export const StepTitle = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const StepLabel = styled("div") <{ ref?; for?}>`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
`;