import { Group } from "../../../types";

interface NewTelegramGroupResponse {
  channel_id: string;
  created_at: string;
  expertsgroup: Group;
  id: number;
  main_invitation_link: string;
  published_at: string;
  title: string;
  type: string;
  updated_at: string;
}

export const NEW_TELEGRAM_GROUP = "NEW_TELEGRAM_GROUP";
export const NEW_TELEGRAM_GROUP_SUCCESS = "NEW_TELEGRAM_GROUP_SUCCESS";
export const NEW_TELEGRAM_GROUP_ERROR = "NEW_TELEGRAM_GROUP_ERROR";

export interface NewTelegramGroup {
  type: typeof NEW_TELEGRAM_GROUP;
  payload: {
    group_id: number;
    platform: "whatsapp" | "telegram";
    type: "broadcast" | "broadcast_with_comments" | "group_chat" | "broadcast_with_comments_and_chat";
  };
}

export interface NewTelegramGroupSuccess {
  type: typeof NEW_TELEGRAM_GROUP_SUCCESS;
  payload: NewTelegramGroupResponse;
}

export interface NewTelegramGroupError {
  type: typeof NEW_TELEGRAM_GROUP_ERROR;
  payload: boolean | string;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | NewTelegramGroup
  | NewTelegramGroupSuccess
  | NewTelegramGroupError;
