import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { useHistory } from 'react-router-dom'

import { initialValues, schema } from "../../../../constants/forms/membership/new";
import { useWindowSize } from "../../../../hooks/useWindowSize";

import Link from '../../../../components/Link'
import Grid from '../../../../components/Grid'
import Submit from "../../../../components/FormikForm/Submit";
import { useLayout } from "../../../../providers/LayoutProvider";
import services from "../../../../services";
import { setToZeroTimeZone } from "../../../../helpers/dates-hanlder";
import { newGroup, resetNewGroup } from "../../../../store/actions/group";
import { getGroupsFilePath } from "../../../../services/storage";
import { hideModal, showModal } from "../../../../store/actions/modal";
import { NewFileInDto } from "../../../../types/storage.dto";
import Snackbar from "../../../../components/Snackbar";

import Congratulations from "../../components/ModalCongratulations";
import Form from "../../components/GroupForm"

import { useFileUploadProgress } from "../../../../providers/FileUploader";
import { getUrlParamByName } from "../../../../helpers/get-url-param";

const Component = ({ newGroupStates, newGroupData, userInformation }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { layout, setLayout } = useLayout();
  const timestamp = new Date().getTime();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const { uploadFiles, resetFileUploadProgress } = useFileUploadProgress();

  const onSubmit = async ({ values, actions }) => {
    const thereAreFilesToUpload = values && (values.cover_pic?.some(file => file instanceof File) || values.group_video?.some(file => file instanceof File));

    if (thereAreFilesToUpload) {
      dispatch(showModal("modal-wait-a-minute-bis"));
    }

    try {
      if (thereAreFilesToUpload) {
        const uploadedFiles = await uploadFiles(
          {
            ...(values?.cover_pic && values?.cover_pic.length > 0 && { cover_pic: values?.cover_pic }),
            ...(values?.group_video && values?.group_video.length > 0 && { group_video: values?.group_video }),
          },
          userInformation.id,
          "group"
        );


        const updatedValues = {
          ...values,
          cover_pic: uploadedFiles?.cover_pic && uploadedFiles?.cover_pic.length >= 0 ? uploadedFiles?.cover_pic[0] : null,
          group_video: uploadedFiles?.group_video ? uploadedFiles?.group_video[0] : null
        };

        dispatch(newGroup(updatedValues));
      } else {
        // If there are no files to upload, dispatch newWelcomeContentPost directly
        const updatedValues = {
          ...values,
          cover_pic: values?.cover_pic && values?.cover_pic[0] ? values?.cover_pic[0] : null,
          group_video: values?.group_video && values?.group_video[0] ? values?.group_video[0] : null,
        };

        dispatch(newGroup(updatedValues));
      }
    } catch (error) {
      console.error('Error during file uploads:', error);
    } finally {
      dispatch(hideModal());
    }
  };

  useEffect(() => {
    if (!!newGroupStates && newGroupStates.success) {
      setTimeout(() => {
        const groupId = newGroupData?.id;
        dispatch(resetNewGroup());
        resetFileUploadProgress();
        dispatch(hideModal());
        history.push(`/dashboard/membership/${groupId}`);
      }, 3500);
    }
  }, [newGroupStates, newGroupData]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: "Lanza tu membresía 🙏✨",
        description: "Lanza tu membresía",
        back: `/dashboard/membership/list`,
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: true,
        actions: [
          <Submit
            isSubmmiting={newGroupStates.loading}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            form="new-membership-form"
          >
            Publicar membresía
          </Submit>,
        ],
      },
    });
  }, []);

  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col sm={6}>
          <Formik
            initialValues={{
              ...initialValues,
              // plan: {
              //   ...initialValues.plan,
              //   type: "subscription",
              //   billing_cycles: null,
              //   period_unit: 'month',
              //   period: 1,
              //   show_in_page: true,
              //   status: "active",
              // },
              group_type: "membership"
            }}
            onSubmit={(values, actions) => {
              if (onSubmit) onSubmit({ values, actions });
            }}
            validateOnChange={false}
            validateOnBlur={formSubmmited}
            validationSchema={schema}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setFieldError,
              setFieldTouched,
              handleChange,
              handleBlur,
              handleReset,
              handleSubmit,
            }) => {
              console.log(errors)
              return (
                <form
                  className="theme-form"
                  onSubmit={(event) => {
                    setFormSubmmited(true);
                    handleSubmit(event);
                  }}
                  id="new-membership-form"
                >
                  <Form
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    setFieldTouched={setFieldTouched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </form>
              );
            }}
          </Formik>

        </Grid.Col >
      </Grid.Row >

      <div style={{ height: 40 }} />

      <Grid.Row>
        <Grid.Col>
          <Link
            style={{ marginRight: "8px" }}
            options={{
              type: "outline",
              size: "lg",
              skin: "purple",
              native: true,
            }}
            href="/dashboard/membership/list"
          >
            Cancelar
          </Link>

          <Submit
            isSubmmiting={newGroupStates.loading}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            form="new-membership-form"
          >
            Publicar membresía
          </Submit>
        </Grid.Col>
      </Grid.Row>

      <Congratulations page="Experiencia" />

      <Snackbar
        visible={newGroupStates.success || !!newGroupStates.error}
        options={{
          time: 2000,
          type: newGroupStates.success ? "success" : "error",
        }}
      >
        {newGroupStates.success
          ? "Membresía creada."
          : "Error al editar la membresía."}
      </Snackbar>

      <div style={{ height: "50px" }} />
    </Grid.Container >
  )
}

const state = ({ carouselStore, groupStore, userStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: userGroups, states: userGroupsStates } = userStore.groups;
  const { currentSlide } = carouselStore;
  const { data: newGroupData, states: newGroupStates } = groupStore.newGroup;

  return {
    currentSlide,
    newGroupStates,
    newGroupData,
    userInformation: userInformation,
    userGroups,
    userGroupsStates,
  };
};

export default connect(state)(Component);