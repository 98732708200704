export const SAVE_PREFERENCE = "SAVE_PREFERENCE";
export const SAVE_PREFERENCE_SUCCESS = "SAVE_PREFERENCE_SUCCESS";
export const SAVE_PREFERENCE_ERROR = "SAVE_PREFERENCE_ERROR";

export const GET_PREFERENCE = "GET_PREFERENCE";
export const GET_PREFERENCE_SUCCESS = "GET_PREFERENCE_SUCCESS";
export const GET_PREFERENCE_ERROR = "GET_PREFERENCE_ERROR";

export interface SavePreferencePayload {
  preferenceSlug: string | number;
  preferenceValue: any;
}

export interface GetPreferencePayload {
  userId: number;
}

interface Preference {
  preferenceValue: number;
  preferenceSlug: number;
  userId: number;
  id: number;
}

export interface SavePreference {
  type: typeof SAVE_PREFERENCE;
  payload: SavePreferencePayload;
}

export interface SavePreferenceSuccess {
  type: typeof SAVE_PREFERENCE_SUCCESS;
  payload: null;
}

export interface SavePreferenceError {
  type: typeof SAVE_PREFERENCE_ERROR;
  payload: string | boolean;
}

export interface GetPreference {
  type: typeof GET_PREFERENCE;
  payload: GetPreferencePayload;
}

export interface GetPreferenceSuccess {
  type: typeof GET_PREFERENCE_SUCCESS;
  payload: Preference[] | null;
}

export interface GetPreferenceError {
  type: typeof GET_PREFERENCE_ERROR;
  payload: string | boolean;
}

export interface State {
  preferences: {
    data: Preference[] | null;
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
  savePreference: {
    states: {
      loading: boolean;
      error: boolean | string;
      success: boolean;
    };
  };
}

export const initialState: State = {
  preferences: {
    data: null,
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
  savePreference: {
    states: {
      loading: false,
      error: false,
      success: false,
    },
  },
};

export type Actions =
  | SavePreference
  | SavePreferenceSuccess
  | SavePreferenceError
  | GetPreference
  | GetPreferenceSuccess
  | GetPreferenceError;
