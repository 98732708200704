import { EditSubscriptionOutDto, Subscription } from "../../../types/subcription-group";

export const EDIT_SUBSCRIPTION = "EDIT_SUBSCRIPTION";
export const EDIT_SUBSCRIPTION_SUCCESS = "EDIT_SUBSCRIPTION_SUCCESS";
export const EDIT_SUBSCRIPTION_ERROR = "EDIT_SUBSCRIPTION_ERROR";
export const RESET_EDIT_SUBSCRIPTION = "RESET_EDIT_SUBSCRIPTION";

export interface EditSubscription {
  type: typeof EDIT_SUBSCRIPTION;
  payload: EditSubscriptionOutDto;
}

export interface EditSubscriptionSuccess {
  type: typeof EDIT_SUBSCRIPTION_SUCCESS;
  payload: null;
}

export interface EditSubscriptionError {
  type: typeof EDIT_SUBSCRIPTION_ERROR;
  payload: string | boolean;
}

export interface ResetEditSubscription {
  type: typeof RESET_EDIT_SUBSCRIPTION;
  payload: null;
}

export interface State {
  data: Subscription | null,
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | EditSubscription
  | EditSubscriptionSuccess
  | EditSubscriptionError
  | ResetEditSubscription;
