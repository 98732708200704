import { ReactElement, useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Text } from "./styles";
import Grid from "../../../components/Grid";
import NewGatewaySidebar from './components/GatewaySidebar/New'
import { Layout, useLayout } from "../../../providers/LayoutProvider";
import Snackbar from "../../../components/Snackbar";
import { Link as RrdLink } from "react-router-dom";
import { showModal } from "../../../store/actions/modal";
import Tutorial from './components/Tutorial'
import GatewaysListSection from "./components/GatewaysListSection";

interface ComponentProps {
  setActiveTab?;
  // children;
  expert;
  linkGatewayStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  setActiveTab,
  // children,
  expert,
  linkGatewayStates
}: ComponentProps) => {
  const dispatch = useDispatch()
  const { layout, setLayout } = useLayout();
  const [showNewGatewaySidebar, setShowNewGatewaySidebar] = useState<boolean>(false);


  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "plain",
      footer: {
        visible: false,
      },
      header: {
        visible: true,
        actions: [],
      },
      page: {
        title: "Configuración de cuenta",
        description: "Configurar cuenta",
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      body: {
        visible: true,
      },
    }),
    [layout]
  );

  useEffect(() => {
    setActiveTab("gateways");
    setLayout(layoutData);
  }, []);


  useEffect(() => {
    if (linkGatewayStates.success) {
      setShowNewGatewaySidebar(false);
    }
  }, [linkGatewayStates]);

  return (
    <>
      {/* {children} */}

      <div style={{ height: 20 }} />

      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <Text>
              Conecta tu propia pasarela de pago de Mercado Pago para recibir los cobros de tus suscripciones (Únicamente habilitada para AR$ por el momento!)
            </Text>
          </Grid.Col>
        </Grid.Row>

        <div style={{ height: "20px" }} />

        <Grid.Row>
          <Grid.Col md={7}>
            <GatewaysListSection setShowNewGatewaySidebar={setShowNewGatewaySidebar} />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col md={7}>
            <Tutorial />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <NewGatewaySidebar
        onClose={setShowNewGatewaySidebar}
        isVisible={showNewGatewaySidebar}
      />

      <Snackbar
        visible={linkGatewayStates.success}
        options={{
          position: "center",
          time: 2000,
          type: "success"
        }}
      >
        Gateway configurado correctamente
      </Snackbar>

      <Snackbar
        visible={!!linkGatewayStates.error}
        options={{
          position: "center",
          time: 2000,
          type: "error",
        }}
      >
        Hubo un error al configurar al gateway
      </Snackbar>

    </>
  )
}

const state = ({ expertStore }) => {
  const { data: expert, states: linkGatewayStates } = expertStore.linkGateway;

  return {
    expert,
    linkGatewayStates
  };
};

export default connect(state)(Component);
