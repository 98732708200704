import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import Thumbnail from './components/Thumbnail';
import PdfPage from './components/PdfPage';
import Icon from '../Icon';

import GoBackIconSVG from '../../assets/icons/go-back-arrow.svg';
import GoFowardIconSVG from '../../assets/icons/go-foward-arrow.svg';
import ZoomInSVG from '../../assets/icons/zoom_in.svg';
import downloadFileIcon from "../../assets/icons/download-file.svg";
import ZoomOutSVG from '../../assets/icons/zoom_out.svg';
import CollapsePdfSVG from '../../assets/icons/collapse.svg';
import SizePdfSVG from '../../assets/icons/size.svg';

import {
  MainWrapper,
  Toolbar,
  Tools,
  ThumbnailsSidebar,
  PreviewMainWrapper,
  PreviewWrapper,
  ThumbnailsWrapper,
  ThumbnailsList,
  Scroll,
  Button,
  Input,
  Page,
  ZoomControl,
  LoaderWrapper,
  Loader,
  LoaderText, DownloadLink
} from './styles';
import { useWindowSize } from '../../hooks/useWindowSize';
import { PdfLink } from "../../pages/Group/Modules/components/SelectedModule/modules/Post/styles";
import slugify from "slugify";
import file from "../../assets/icons/file-text-2.svg";

declare global {
  interface Window {
    pdfjsLib
  }
}

const DEFAULT_ZOOM_DELTA = .025;
const DEFAULT_SCALE = 1;

const PdfViewer = ({ fileUrl, fileName, blockDownloadFiles, key }) => {
  const { isMobile } = useWindowSize()
  const [isPdfLibrarySupported, setIsPdfLibrarySupported] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pdfPages, setPdfPages] = useState<any[]>([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [pdfHeight, setPdfHeight] = useState<number>(0);
  const [pdfWidth, setPdfWidth] = useState<number>(0);
  const [pagesLoaded, setPagesLoaded] = useState<number>(0);
  const [pdfIsLoading, setPdfIsLoading] = useState<boolean>(true);
  const [pdfZoom, setPdfZoom] = useState<number>(1);
  const handle = useFullScreenHandle();
  const scrollRef = useRef<HTMLDivElement>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const mainWrapRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const thumbnailsListRef = useRef<HTMLDivElement>(null);
  const DEFAULT_ZOOM = useMemo(() => {
    return isMobile ? .4 : 1
  }, [isMobile]);

  // Function to go to the next page
  const goToNextPage = () => {
    const page = currentPage + 1;
    setCurrentPage(page);
  };

  // Function to go to the previous page
  const goToPreviousPage = () => {
    const page = currentPage - 1;
    setCurrentPage(page);
  };

  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  }

  const handleInputBlur = () => {
    if (inputRef?.current?.value) {
      const pageNumber = parseInt(inputRef.current.value, 10) - 1;
      goToPage(pageNumber);
      scrollToSelectedPage(pageNumber);
    }
  };

  const enterFullScreen = async () => {
    try {
      await handle.enter();
      setIsFullScreen(true);
    } catch (error) {
      console.error("Error entering full-screen mode:", error);
    }
  };

  const exitFullScreen = async () => {
    try {
      await handle.exit();
      setIsFullScreen(false);
    } catch (error) {
      console.error("Error exiting full-screen mode:", error);
    }
  };

  const zoomIn = (ticks) => {
    let newZoom = pdfZoom;
    newZoom = Number((newZoom + DEFAULT_ZOOM_DELTA).toFixed(2));
    setPdfZoom(newZoom);
  }

  const zoomOut = (ticks) => {
    let newZoom = pdfZoom;
    newZoom = Number((newZoom - DEFAULT_ZOOM_DELTA).toFixed(2));
    setPdfZoom(newZoom);
  };

  const renderPdfPage = (page, pageNum, canvasPages, renderedPages, numPages) => {
    const viewport = page.getViewport({ scale: DEFAULT_SCALE });
    const canvas = document.createElement('canvas');

    canvas.setAttribute('id', `pdf-page-${pageNum}`);

    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    canvasPages.push(canvas);

    setPdfHeight(viewport.height)
    setPdfWidth(viewport.width)

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    page.render(renderContext).promise.then(() => {
      renderedPages++;
      setPagesLoaded(pageNum);

      if (pageNum === numPages) {
        setPdfPages(canvasPages);
        setPdfIsLoading(false);
      }
    }).catch((reason) => {
      console.error(reason);
    });
  };

  const scrollToSelectedPage = (pageNumber) => {
    if (scrollRef.current) {
      const pageElements = scrollRef.current.querySelectorAll('.pdf-page');
      if (pageElements.length > pageNumber) {
        const containerHeight = scrollRef.current.clientHeight;
        const itemHeight = scrollRef.current.scrollHeight / pageElements.length;
        const scrollTop = pageNumber * itemHeight - containerHeight + itemHeight + 45.5;
        scrollRef.current.scrollTop = scrollTop;
      }
    }
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (userAgent?.indexOf('Safari') !== -1 && userAgent?.indexOf('Chrome') === -1) {
      // It's Safari
      const safariVersion = /Version\/(\d+\.\d+)/.exec(userAgent);
      if (safariVersion) {
        const version = safariVersion[1];
        if (Number(version) > 15.4)
          setIsPdfLibrarySupported(false)

        console.log(`Safari Version: ${version}`);
      }
    } else {
      console.log('Not Safari');
    }
  }, []);

  useEffect(() => {
    setPdfZoom(DEFAULT_ZOOM);
  }, [isMobile])

  useEffect(() => {
    if (inputRef?.current && !pdfIsLoading) {
      inputRef.current.value = String(currentPage + 1);
    }
  }, [inputRef, currentPage, pdfIsLoading]);

  useEffect(() => {
    const loadPdf = async () => {
      setPdfIsLoading(true);

      const script = document.createElement('script');
      script.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.min.js';
      script.type = 'text/javascript';
      script.async = true;

      script.onload = () => {
        const canvasPages: HTMLCanvasElement[] = [];
        let renderedPages = 0;

        window.pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`;

        window.pdfjsLib.getDocument(fileUrl).promise.then(async (pdfDoc) => {
          const numPages = pdfDoc.numPages;
          setTotalPages(numPages);

          for (let pageNum = 1; pageNum <= numPages; pageNum++) {
            pdfDoc.getPage(pageNum).then((page) => {
              renderPdfPage(page, pageNum, canvasPages, renderedPages, numPages);
            });
          }
        });
      };

      document.head.appendChild(script);
    };

    loadPdf();
  }, []);

  useEffect(() => {
    if (thumbnailsListRef.current && pdfPages) {
      const itemIndex = currentPage;
      const containerHeight = thumbnailsListRef.current.clientHeight;
      const itemHeight = thumbnailsListRef.current.scrollHeight / pdfPages.length;
      const scrollTop = itemIndex * itemHeight - containerHeight + itemHeight + 45.5;
      thumbnailsListRef.current.scrollTop = scrollTop;
    }
  }, [currentPage]);

  useEffect(() => {
    // Create an IntersectionObserver and specify the callback function
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Get the page number from the data attribute
            const pageNumber = parseInt((entry.target as HTMLElement).dataset.page as string, 10);
            setCurrentPage(pageNumber);
          }
        });
      },
      {
        threshold: 0.5, // Adjust the threshold as needed
      }
    );

    // Get all the page elements and observe them
    if (scrollRef.current) {
      const pageElements = scrollRef.current.querySelectorAll('.pdf-page'); // Make sure you add this class to your page elements
      pageElements.forEach((element) => {
        observer.observe(element);
      });
    }

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, [scrollRef, pdfPages]);

  // useEffect(() => {
  //   if (!isFullScreen) {
  //     scrollToSelectedPage(currentPage);
  //   }
  // }, [currentPage, isFullScreen]);


  return (
    <>
      {isPdfLibrarySupported ? (

        <FullScreen
          handle={handle}
          onChange={(state, handle) => {
            if (state) {
              setIsFullScreen(true);
            } else {
              setIsFullScreen(false);
            }
          }}
          className={`pdf-wrapper ${isFullScreen ? 'fullscreen-mode' : ''}`}
        >
          {pdfIsLoading && pagesLoaded !== totalPages ? (
            <LoaderWrapper>
              <Loader progress={pagesLoaded * 100 / totalPages}>
                <div className='progress'></div>
              </Loader>
              <LoaderText>
                Cargando páginas {pagesLoaded} de {totalPages}
              </LoaderText>
            </LoaderWrapper>
          ) : (

            <MainWrapper className={`${isFullScreen ? 'fullscreen-mode' : ''}`}>
              <ThumbnailsWrapper style={{ position: !isFullScreen && !isMobile ? 'relative' : 'absolute', opacity: !isFullScreen && !isMobile ? 1 : 0, zIndex: !isFullScreen && !isMobile ? 1 : -1, ...((isFullScreen || isMobile) && { width: 0 }) }}>
                <Scroll ref={thumbnailsListRef} height={pdfHeight} className={`${isFullScreen ? 'fullscreen-mode' : ''}`}>
                  <ThumbnailsList className={`${isFullScreen ? 'fullscreen-mode' : ''}`}>
                    <Thumbnail pages={pdfPages} setCurrentPage={setCurrentPage} currentPage={currentPage} />
                  </ThumbnailsList>
                </Scroll>
              </ThumbnailsWrapper>

              <PreviewMainWrapper className={`${isFullScreen ? 'fullscreen-mode' : ''}`}>
                <PreviewWrapper ref={mainWrapRef} height={pdfHeight} width={pdfWidth} className={`${isFullScreen ? 'fullscreen-mode' : ''}`} >
                  <Scroll ref={scrollRef} height={pdfHeight} className={`scroll ${isFullScreen ? 'fullscreen-mode' : ''}`} style={{ position: isFullScreen ? 'relative' : 'absolute', opacity: isFullScreen ? 1 : 0, zIndex: isFullScreen ? 1 : -1, ...(!isFullScreen && { width: 0 }) }}>
                    <ZoomControl height={pdfHeight} width={pdfWidth} zoom={pdfZoom} className={`zoom-control ${isFullScreen ? 'fullscreen-mode' : ''}`} style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', gap: 15 }}>
                      {pdfPages && pdfPages.length > 0 && pdfPages.map((page, index) => {
                        return (
                          <PdfPage
                            key={index}
                            canvas={page}
                            className="pdf-page"
                            data-page={index}
                          />
                        )
                      })}
                      <div style={{ height: 80, flexShrink: 0 }} />
                    </ZoomControl>
                  </Scroll>

                  <ZoomControl height={pdfHeight} width={pdfWidth} zoom={pdfZoom} className="zoom-control" style={{ position: !isFullScreen ? 'relative' : 'absolute', opacity: !isFullScreen ? 1 : 0, zIndex: !isFullScreen ? 1 : -1, ...(isFullScreen && { width: 0 }) }}>
                    {pdfPages && pdfPages.length > 0 && (
                      <PdfPage canvas={pdfPages[currentPage]} />
                    )}
                  </ZoomControl>
                </PreviewWrapper>

                <div style={{ height: 20 }} />

                <Toolbar ref={toolbarRef} className={`${isFullScreen ? 'fullscreen-mode' : ''}`} >
                  {!isFullScreen && (
                    <Button
                      onClick={goToPreviousPage}
                      disabled={currentPage === 0}
                      skin="primary"
                    >
                      <Icon icon={GoBackIconSVG} color="#FFFFFF" />
                    </Button>
                  )}

                  <Tools>
                    <Page>
                      {!isFullScreen && !isMobile && <span>Página</span>} <Input
                        ref={inputRef}
                        onBlur={handleInputBlur}
                      /> <span>/ {totalPages}</span>
                    </Page>

                    <Tools style={{ justifyContent: 'flex-end' }}>
                      {/* {(!isMobile || (isMobile && isFullScreen)) && ( */}
                      <>
                        {!blockDownloadFiles && (
                          <DownloadLink
                            target="_blank"
                            href={fileUrl}
                            key={key}
                          >
                            <Icon icon={downloadFileIcon} color="#000000" />
                            {!isMobile && (<span style={{ marginLeft: "4px", fontWeight: 500 }}>Descargar</span>)}

                          </DownloadLink>
                        )}

                        <Button
                          onClick={() => zoomIn(pdfZoom * DEFAULT_ZOOM_DELTA)}
                        >
                          <Icon icon={ZoomInSVG} color="#000000" />
                        </Button>

                        <Button
                          onClick={() => zoomOut(pdfZoom / DEFAULT_ZOOM_DELTA)}
                        //disabled={pdfZoom === DEFAULT_ZOOM}
                        >
                          <Icon icon={ZoomOutSVG} color="#000000" />
                        </Button>
                      </>

                      {isFullScreen ? (
                        <Button
                          onClick={exitFullScreen}
                        >
                          <Icon icon={CollapsePdfSVG} color="#000000" />
                        </Button>
                      ) : (
                        <Button
                          onClick={enterFullScreen}
                        >
                          <Icon icon={SizePdfSVG} color="#000000" />
                        </Button>
                      )}
                    </Tools>
                  </Tools>


                  {!isFullScreen && (
                    <>
                      <Button
                        onClick={goToNextPage}
                        disabled={currentPage + 1 === totalPages}
                        skin="primary"
                      >
                        <Icon icon={GoFowardIconSVG} color="#FFFFFF" />
                      </Button>
                    </>
                  )}
                </Toolbar>
              </PreviewMainWrapper>
            </MainWrapper>
          )}
        </FullScreen >
      ) : (
        <PdfLink
          target="_blank"
          href={fileUrl}
          key={`attached-file-${slugify(fileName)}`}
        >
          <Icon icon={file} color="white" />
          Clickea aqui para descargar: {fileName}
        </PdfLink>
      )}
    </>
  );
};

export default PdfViewer;
