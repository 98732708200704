import styled from 'styled-components'

export const Toggle = styled.button`
  background-color: white;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  border: 0px;
  background: 0px;
  display: flex;
`
