import { useState } from 'react'
import { connect } from 'react-redux'

import PreviewSidebar from './components/PreviewSidebar'
import CreateSidebar from './components/CreateSidebar'
import DeleteSidebar from './components/DeleteSidebar'
import EditSidebar from './components/EditSidebar'
import ReorderContentSidebar from './components/ReorderContentSidebar'

import { CategoryDto } from '../../../types/category'

interface ComponentProps {
  group,
  groupStates,
  categories,
  idCategoryToOrder?: number
  categoriesState,
  showCategories,
  showCreateCategory,
  showReorderContent?,
  onClose,
}

const Component = ({
  group,
  groupStates,
  categories,
  idCategoryToOrder,
  categoriesState,
  showCategories,
  showCreateCategory,
  showReorderContent,
  onClose,
}: ComponentProps) => {
  const [showDeleteCategorySidebar, setShowDeleteCategorySidebar] = useState<boolean>(false);
  const [showEditCategorySidebar, setShowEditCategorySidebar] = useState<boolean>(false);
  const [showReorderContentSidebar, setShowReorderContentSidebar] = useState<boolean>(false)
  const [showCreateCategorySidebar, setShowCreateCategorySidebar] = useState<boolean>(false);
  const [showPreviewCategoriesSidebar, setShowPreviewCategoriesSidebar] = useState<boolean>(false);
  const [selectedCategoryToReorder, setSelectedCategoryToReorder] = useState<number>();
  const [selectedCategorToEdit, setSelectedCategorToEdit] = useState<CategoryDto>();
  const [selectedCategoryToBeDeleted, setSelectedCategoryToBeDeleted] = useState<CategoryDto>();

  return <>
    <PreviewSidebar
      onClose={() => {
        setShowPreviewCategoriesSidebar(!showPreviewCategoriesSidebar)
        onClose && onClose()
      }}
      isVisible={showPreviewCategoriesSidebar !== showCategories}
      setSelectedCategorToEdit={setSelectedCategorToEdit}
      setSelectedCategoryToBeDeleted={setSelectedCategoryToBeDeleted}
      setShowDeleteCategorySidebar={setShowDeleteCategorySidebar}
      setShowEditCategorySidebar={setShowEditCategorySidebar}
      setShowCreateCategorySidebar={setShowCreateCategorySidebar}
      showCreateCategorySidebar={showCreateCategorySidebar}
    />
    {!!idCategoryToOrder && !!group && (
      <>
        <ReorderContentSidebar
          onClose={() => {
            setShowReorderContentSidebar(!showReorderContentSidebar)
          }}
          isVisible={showReorderContentSidebar !== showReorderContent}
          idCategory={idCategoryToOrder}
          idGroup={group?.id}
        />
      </>
    )}
    <CreateSidebar
      onClose={() => {
        setShowCreateCategorySidebar(!showCreateCategorySidebar)
      }}
      isVisible={showCreateCategorySidebar !== showCreateCategory}
    />
    <DeleteSidebar
      category={selectedCategoryToBeDeleted}
      onClose={() => setSelectedCategoryToBeDeleted(undefined)}
      isVisible={!!selectedCategoryToBeDeleted}
    />
    <EditSidebar
      category={selectedCategorToEdit}
      onClose={() => setSelectedCategorToEdit(undefined)}
      isVisible={!!selectedCategorToEdit}
    />
  </>
}

const state = ({ groupStore, categoryStore }) => {
  const { data: categories, states: categoriesState } =
    categoryStore?.allCategories;
  const { data: group, states: groupStates } =
    groupStore.group;

  return {
    group,
    groupStates,
    categories,
    categoriesState,
  };
};

export default connect(state)(Component);