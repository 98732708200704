import { useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useWindowSize } from "../../../../hooks/useWindowSize"
import { getWelcomeContentSection } from "../../../../store/actions/welcome-content"
import { Group } from "../../../../types"
import WelcomeSectionMaximized from "./components/WelcomeSectionMaximized"
import WelcomeSectionMinimized from "./components/WelcomeSectionMinimized"
import { Title } from "./styles"
import LogoLoader from "../../../../components/LogoLoader"

interface ComponentProps {
  group: Group
  minimized?: boolean
  showCloseButton?: boolean,
  welcomeContentSection,
  welcomeContentSectionStates
}

const Component = ({ group, minimized = false, welcomeContentSection, welcomeContentSectionStates, showCloseButton = false }: ComponentProps) => {
  const { isMobile } = useWindowSize()
  const dispatch = useDispatch()
  const params: { [key: string]: string } = useParams()

  useEffect(() => {
    if (group?.welcome_section) {
      dispatch(getWelcomeContentSection({ idWelcomeContentSection: group?.welcome_section, filters: { with_contents: true } }))
    }
  }, [group])

  if(!!welcomeContentSectionStates?.loading)
    return <LogoLoader />

  if(!welcomeContentSection || welcomeContentSection?.welcome_contents.length <= 0)
    return <></>

  return (
    <>
      {minimized ? (
        <WelcomeSectionMinimized showCloseButton={showCloseButton} />
      ) : (
        <WelcomeSectionMaximized />
      )}

      <div style={{ height: 26 }} />
    </>
  )
}

const state = ({ groupStore, welcomeContentStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: welcomeContentSection, states: welcomeContentSectionStates } = welcomeContentStore.welcomeContentSection;


  return {
    welcomeContentSection,
    welcomeContentSectionStates,
    group,
    groupStates,
  };
};

export default connect(state)(Component);
