import {
  initialState,
  Actions,
  State,
  CHANGE_USER_AVATAR,
  CHANGE_USER_AVATAR_SUCCESS,
  CHANGE_USER_AVATAR_ERROR,
} from "../../types/user/change-user-avatar";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case CHANGE_USER_AVATAR:
      return {
        ...state,
        states: {
          success: false,
          loading: true,
          error: false,
        },
      };
    case CHANGE_USER_AVATAR_SUCCESS:
      return {
        ...state,
        states: {
          success: true,
          loading: false,
          error: false,
        },
      };
    case CHANGE_USER_AVATAR_ERROR:
      return {
        ...state,
        states: {
          success: false,
          loading: false,
          error: true,
        },
      };
    default:
      return state;
  }
};

export default reducers;
