import styled from "styled-components";
import { Card as CardUi } from "../StyledComponents";
import ButtonUi from "../../../../../components/Button";
import {
  rainbowBackgroundGradient,
  rainbowTextGradient,
} from "../../../../../components/StyledComponents";

export const Button = styled(ButtonUi)`
  ${rainbowBackgroundGradient}
  border: 0px;
  height: 45px;
`;

export const Owner = styled("p")`
  ${rainbowTextGradient}
  margin: 0px;
  font-weight: 700;
  font-size: 15px;
  line-height: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Start = styled("p")`
  margin: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(45, 52, 54, 0.75);
`;
export const Duration = styled("p")`
  margin: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: rgba(45, 52, 54, 0.75);
`;
export const OldPriceContainer = styled("div")`
  font-size: 12px;
  line-height: 15px;
  color: rgba(45, 52, 54, 0.75);
  text-align: center;
  font-weight: 400;
`;

export const OldPrice = styled("span")`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(45, 52, 54, 0.75);
  position: relative;

  &:before {
    content: "";
    display: block;
    background-color: rgba(235, 87, 87, 1);
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 1px;
    transform: translate(-50%, -50%) rotate(-10deg);
  }
`;

export const Price = styled("div")`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
`;

export const Currency = styled("p")`
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 14px;
`;
export const Amount = styled("p")`
  margin: 0;
  font-weight: 600;
  font-size: 62px;
  line-height: 72px;
`;
export const Period = styled("p")`
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 14px;
`;

export const LocalPrice = styled("div")`
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: rgba(45, 52, 54, 0.75);
  text-align: center;
  margin-top: -10px;
  display: block;
`;

export const Card = styled(CardUi)`
  position: relative;
  background-color: white;
  z-index: 10;
  width: 100%;
  max-width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 18px;
  text-align: center;

  & > * {
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 0px 30px;
    flex-direction: row;
    text-align: left;

    & > * {
      width: calc((100% - 264px) / 2);
    }
  }

  &.sticky {
    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      position: sticky;
      opacity: 1;
      bottom: 10px;
      padding: 10px 18px;
      transition: all 0.4s ease-in-out;
      padding: 10px 30px;
    }

    & > div {
      @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
        padding: 10px 30px;
      }
    }

    &.hide-card {
      @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
        opacity: 0;
        z-index: -1;
        bottom: -300px;
      }
    }
  }

  &.is-pinned {
  }
`;

export const CardCol = styled("div")`
  padding: 12px 0px;
  font-family: 'DM Sans', sans-serif;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 30px;
  }
`;

export const List = styled("ul")`
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 235px;
  margin: 32px auto 0px;
  gap: 5px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: 0px;
    max-width: none;
    margin: 12px auto 0px;
  }
`;

export const ListItem = styled.li`
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.Black45};

  span {
    margin-left: 6px;
    font-weight: 600;
    font-size: 6.5px;
    line-height: 8px;
    text-align: left;
  }
`;

export const ClockCard = styled("div") <{ rainbow?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 250px;
  color: ${({ rainbow }) => (rainbow ? "white" : "inherit")}!important;
  ${({ rainbow }) =>
    rainbow ? rainbowBackgroundGradient : "background-color: #f0f0f0"};
  border-radius: 11px;

  margin-top: 12px;
  padding: 10px 0px;

  font-size: 11px;
  line-height: 14px;

  position: absolute;
  top: calc(100% + 14px);
  left: 50%;
  transform: translate(-50%, -50%);

  div,
  span:before {
    color: ${({ rainbow }) => (rainbow ? "white" : "inherit")}!important;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    top: 100%;
  }
`;

export const Title = styled("span")`
  font-weight: 500;
  font-size: 22px;
`;
