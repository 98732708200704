import styled, { css } from "styled-components";

export const rainbowTextGradient = css`${({ theme }) => theme.colors.RainbowText}`;

export const rainbowBackgroundGradient = css`${({ theme }) => theme.colors.RainbowBackground}`;

export const Separator = styled("div") <{ height }>`
  ${({ height }) => (!!height ? `height: ${height.mobile}` : "")};

  @media (min-width: 768px) {
    ${({ height }) => (!!height ? `height: ${height.desktop}` : "")};
  }
`;

export const RainbowSectionName = styled("h4")`
  margin: 0px;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  text-align: center;
  ${rainbowTextGradient};

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    letter-spacing: 2.5px;
  }
`;

export const Title = styled("h1")`
  margin: 0px;
  font-weight: 500;
  text-align: center;
  font-size: 28px;
  line-height: 28px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 42px;
    line-height: 42px;
  }
`;

const verticalPosition = {
  top: "flex-start",
  bottom: "flex-end",
  center: "center",
};

const horizontalPosition = {
  left: "flex-start",
  right: "flex-end",
  center: "center",
  space: "space-between",
};

export const Center = styled("div") <{
  vertical: "top" | "center" | "bottom";
  horizontal: "left" | "center" | "right" | "space";
  gap?: number;
  column?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  justify-content: ${({ horizontal }) => horizontalPosition[horizontal]};
  align-items: ${({ vertical }) => verticalPosition[vertical]};
  gap: ${({ gap }) => (gap ? gap : 0)}px;
`;


export const HorizontalScroll = styled("div")`
  overflow-x: scroll;
  max-width: 100%;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    display: none;
  }
`;