import { Formik } from "formik";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Link from "../../../../components/Link";
import Submit from "../../../../components/FormikForm/Submit";
import { Group } from "../../../../types";
import Grid from "../../../../components/Grid";
import {
  initialValues,
  schema,
} from "../../../../constants/forms/sessions/new";
import { newSession, resetNewSession } from "../../../../store/actions/session";
import { setToZeroTimeZone } from "../../../../helpers/dates-hanlder";
import SessionForm from "../components/SessionForm";
import { getGroupById } from "../../../../store/actions/group";
import { SessionInDto } from "../../../../types/session.dto";
import Snackbar from "../../../../components/Snackbar";
import { useLayout } from "../../../../providers/LayoutProvider";

interface ComponentProps {
  groupInformation: Group;
  groupStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  newSessionInformation: SessionInDto;
  newSessionStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  match;
}

const Component = ({
  groupInformation,
  groupStates,
  newSessionInformation,
  newSessionStates,
  match,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const dispatch = useDispatch();
  const history = useHistory();
  const [groupId, setGroupId] = useState<number>();
  const [formSubmmited, setFormSubmmited] = useState(false);

  const onSubmit = ({ values, actions }) => {
    const { speaker_name, speaker_profile_pic, notify_upload_recording, session_datetime, will_be_recorded, notificate_by_chat, recording_expiration_datetime, notificate_by_email, ...rest } =
      values;
    const useProfileInfo = values.use_profile_info.length > 0;

    const remindersSettings = values['session_reminders_settings'].map(remSett => {
        return {
          ...remSett, 
          enabled: remSett?.enabled?.includes("true"), 
          exec_datetime: remSett?.exec_datetime && remSett?.exec_datetime !== "" 
            ? setToZeroTimeZone(remSett.exec_datetime) 
            : remSett.exec_datetime}
    })

    const recordingExpirationDate = (!!recording_expiration_datetime && recording_expiration_datetime !== "")
    ? setToZeroTimeZone(recording_expiration_datetime)
    : null

    const obj = {
      ...rest,
      ...(!useProfileInfo && { speaker_name, speaker_profile_pic }),
      use_profile_info: useProfileInfo,
      session_dt_in_local_timezone: session_datetime,
      session_datetime: setToZeroTimeZone(session_datetime),
      recording_expiration_datetime: recordingExpirationDate,
      will_be_recorded: will_be_recorded?.includes("true") || false,
      notificate_by_chat: notificate_by_chat?.includes("true") || false,
      notificate_by_email: notificate_by_email?.includes("true") || false,
      notify_upload_recording: notify_upload_recording?.includes("true")  || false, 
      session_reminders_settings: remindersSettings
    }

    dispatch(
      newSession(obj)
    );
  };

  useEffect(() => {
    if (!!match?.params) {
      setGroupId(Number(match?.params?.idGroup));
    }
  }, [match]);

  useEffect(() => {
    if (!!groupId) {
      groupInformation?.id !== groupId &&
        dispatch(getGroupById({ group_id: groupId }));
    }
  }, [groupId, groupInformation]);

  useEffect(() => {
    if (!!newSessionInformation && newSessionStates.success) {
      setTimeout(() => {
        const groupId = groupInformation?.id;
        const sessionId = newSessionInformation?.id;
        dispatch(resetNewSession());
        history.push(`/group/${groupId}/s/${sessionId}?new=t`);
      }, 3500);
    }
  }, [newSessionInformation, newSessionStates]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: "Crear nuevo encuentro",
        description: "Crear nuevo encuentro",
        back: `/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/sessions`,
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: true,
        actions: [
          <Link
            style={{ marginRight: "8px" }}
            options={{
              type: "outline",
              size: "lg",
              skin: "purple",
              native: true,
            }}
            disabled={groupStates.loading}
            loading={groupStates.loading}
            href={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/sessions`}
          >
            Cancelar
          </Link>,

          <Submit
            isSubmmiting={newSessionStates.loading}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            form="new-session-form"
          >
            Crear encuentro
          </Submit>,
        ],
      },
    });
  }, [groupInformation]);

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <p>
              🎥 Calendariza tus encuentros en vivo asi todos tus suscriptores puede organizarse y saber exactamente cuando conectarse! <br />Luego podrás cargar la grabación y quedarán guardados en tu biblioteca!
            </p>
          </Grid.Col>
        </Grid.Row>

        <div style={{ height: "32px" }}></div>

        <Grid.Row>
          <Grid.Col lg={6}>
            <Formik
              initialValues={{
                ...initialValues,
                group: groupId,
              }}
              onSubmit={(values, actions) => {
                if (onSubmit) onSubmit({ values, actions });
              }}
              validateOnChange={false}
              validateOnBlur={formSubmmited}
              validationSchema={schema}
              enableReinitialize
            >
              {({ handleSubmit, ...rest }) => {
                return (
                  <form
                    className="theme-form"
                    onSubmit={(event) => {
                      setFormSubmmited(true);
                      handleSubmit(event);
                    }}
                    id="new-session-form"
                  >
                    <SessionForm {...rest} isEdit={false}/>
                  </form>
                );
              }}
            </Formik>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col>
            <Link
              style={{ marginRight: "8px" }}
              options={{
                type: "outline",
                size: "lg",
                skin: "purple",
                native: true,
              }}
              disabled={groupStates.loading}
              loading={groupStates.loading}
              href={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/sessions`}
            >
              Cancelar
            </Link>

            <Submit
              isSubmmiting={newSessionStates.loading}
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
              }}
              form="new-session-form"
            >
              Crear encuentro
            </Submit>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <Snackbar
        visible={newSessionStates.success || !!newSessionStates.error}
        options={{
          time: 2000,
          type: newSessionStates.success ? "success" : "error",
        }}
      >
        {newSessionStates.success
          ? "Encuentro creado."
          : "Error al crear el encuentro."}
      </Snackbar>
    </>
  );
};

const state = ({ groupStore, sessionStore }) => {
  const { data: newSessionInformation, states: newSessionStates } =
    sessionStore.newOne;
  const { data: groupInformation, states: groupStates } = groupStore.group;

  return {
    groupInformation,
    groupStates,
    newSessionInformation,
    newSessionStates,
  };
};

export default connect(state)(Component);
