import styled from "styled-components";

export const NoSubscribedMessage = styled("div")`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NoSubscribedMessageTitle = styled("h3")`
  text-align: center;
`;

export const GoToAccount = styled("p")`
  margin: 24px 0px 20px;
  text-align: center;
  text-indent: 5px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: 36px 0px 0px;
  }
`;

export const HorizontalScroll = styled("div")`
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Page = styled("div")`
  min-height: 100vh;
`;
