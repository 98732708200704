import styled from 'styled-components'

export const Title = styled('h3')`
  margin: 0px;
`

export const Paragraph = styled('p')`
  margin: 0px;
`

export const Button = styled('button')`
  flex-shrink: 0;
  border: 1px solid ${({ theme }) => theme.colors.Primary};
  background: white;
  cursor: pointer;
  border: none;
  // border-radius: 50%;
  color: ${({ theme }) => theme.colors.Primary};
  // padding: 0px;
  // margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in-out;

  // &:hover {        
  //   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // }
`

export const Check = styled('div') <{ active: boolean }>`
  cursor: pointer;
  width: 252px;
  height: 84px;
  border: 2px solid #D9D9D9;
  transition: all .3s ease-in-out;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 16px;
  gap: 16px;

  ${({ active }) => active ? `border: 2px solid #6C5CE7; color: #6C5CE7;` : ''}

  &:hover {
    border: 2px solid ${({ active }) => active ? '#6C5CE7' : '#E7E5FB'};
    color: #6C5CE7;
  }

  span {
    font-weight: 500;
  }
`


export const LoaderWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
`

export const LoaderBackground = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.35);
`