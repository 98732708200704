import styled from "styled-components";

export const Card = styled("div")`
  background-color: #fff;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
  text-align: left;
  padding: 24px;
`;
export const EmptyState = styled("div")`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const CardHeader = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0px 0px 17px;
`;
export const CardBody = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;
export const Title = styled("h3")`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
`;
export const List = styled("ul")`
  margin: 0px;
  padding: 0px;
  display: block;
  width: 100%;
`;
export const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 6px;
`;
export const Name = styled("span")`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;
export const Email = styled("span")`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(45, 52, 54, 0.75);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
export const Copy = styled("button")`
  border: none;
  background: none;
  cursor: pointer;
`;
