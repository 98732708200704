import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Skeleton from './components/Skeleton'
import { NoMoreItems, CardsGrid } from "./styles";

interface ComponentProps {
  data
  children
  active: boolean;
  action: (page: number) => void
  isLoading: boolean
  loader?: ReactElement | string;
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  EmptyState?: () => JSX.Element
}

const FetchDataComponent = ({ data, children, isLoading, loader = <p>Cargando...</p>, EmptyState = Skeleton, page, setPage, active, action }: ComponentProps) => {
  const observer = useRef<IntersectionObserver | null>(null); // Define observer type

  // useEffect(() => {
  //   action(page);
  // }, []);

  const lastElementRef = useRef<HTMLDivElement>(null); // Define ref type

  useEffect(() => {
    if (!isLoading) {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };

      const handleIntersect: IntersectionObserverCallback = async (entries) => {
        if (entries[0].isIntersecting && active) {
          action(page + 1)
          setPage(page + 1)
        }
      };

      observer.current = new IntersectionObserver(handleIntersect, options);

      if (lastElementRef.current) {
        observer.current.observe(lastElementRef.current);
      }
    }

    return () => {
      if (lastElementRef.current) {
        observer.current?.unobserve(lastElementRef.current); // Use optional chaining for observer unobserve
      }
    };
  }, [data]);


  return (
    <div>
      {children}
      {/* {!!children && isLoading && <div style={{ height: 16 }} />} */}
      {isLoading && <EmptyState />}
      {data.length > 0 && !active && !isLoading && <NoMoreItems>No hay más artículos</NoMoreItems>}
      {active && !isLoading && <div ref={lastElementRef} />}
    </div>
  );
};

export default FetchDataComponent;
