import { NewFileInDto, NewFileOutDto, StorageActions } from "../types/storage.dto";
import actions from "./../helpers/rest-client";

interface SignedUrlResponse {
  url: string
}

interface ErrorResponse {
  error: any;
}

let xhrInstances: XMLHttpRequest[] = [];

const getSignedUrl = async ({ fileName, fileType, action }: { fileName: string, fileType?: string, action: StorageActions }): Promise<{ response?: SignedUrlResponse, error?: ErrorResponse }> => {
  return actions
    .Get({
      url: `/api/user/get-signed-url?fileName=${fileName}&fileType=${fileType}&action=${action}`,
    })
    .then((response) => {
      return { response, error: undefined };
    })
    .catch((error) => {
      return { response: undefined, error };
    });
}

const storePut = async ({ url, file, onUploadProgress, onAbort }) => {
  const uploadFileWithProgress = async ({ url, file, onUploadProgress, onAbort }) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          onUploadProgress(progress);
        }
      });

      xhr.addEventListener('load', () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          // Successful upload
          resolve(xhr.response);
        } else {
          // Upload error
          reject(new Error(`Failed to upload file. Status: ${xhr.status}`));
        }
      });

      xhr.addEventListener('error', () => {
        reject(new Error('Network error during file upload.'));
      });

      xhr.addEventListener('abort', () => {
        reject(onAbort());
      });

      xhr.open('PUT', url, true);
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);

      xhrInstances.push(xhr)
    });
  };

  return uploadFileWithProgress({ url, file, onUploadProgress, onAbort })
    .then((response) => {
      return { response }
    })
    .catch((error) => {
      return { error }
    });
}

export const abortUpload = () => {
  if (xhrInstances.length > 0) {
    for (let index = 0; index < xhrInstances.length; index++) {
      const xhrInstance = xhrInstances[index];
      xhrInstance.abort();
      console.log('XHR request aborted.');
    }
  } else {
    console.warn('No active XHR request to abort.');
  }
};

export const getFilePath = ({ fileName, fileExt, entity }) => {
  if(entity === "post")
    return getPostsFilePath({fileName, fileExt})

  if(entity === "group")
    return getGroupsFilePath({fileName, fileExt})

  if(entity === "welcome-content")
    return getPostsFilePath({fileName, fileExt})

  return ""
}

export const getPostsFilePath = ({ fileName, fileExt }) => {
  return `api/posts/${fileName}/${fileName}.${fileExt}`
}

export const getGroupsFilePath = ({ fileName, fileExt }) => {
  return `api/groups/${fileName}/${fileName}.${fileExt}`
}

export class ErrorStorage extends Error { }

class Storage {
  public async saveFile({ file, fileName, onUploadProgress, onAbort }: NewFileOutDto): Promise<NewFileInDto> {
    const fileType = file.type;

    const { response, error } = await getSignedUrl({
      fileName,
      fileType,
      action: StorageActions.WRITE,
    });

    if (!!response) {
      const { url } = response;
      return storePut({ url, file, onUploadProgress, onAbort })
    } else {
      return { error }
    }
  }

  public async getFile({ fileName }) {
    const { response, error } = await getSignedUrl({
      fileName,
      action: StorageActions.READ,
    });

    if (!!response) {
      const { url } = response;
      return url
    } else {
      return { error }
    }
  }

  // public async removeFile({ fileName, fileType }) {
  //   const result = await this.getSignedUrl({
  //     fileName,
  //     fileType,
  //     action: StorageActions.DELETE,
  //   });

  //   if ('response' in result) {
  //     const { response } = result
  //     const { url } = response
  //     return this.storePut({ file fileType, url })
  //   } else {
  //     // Handle error
  //     const error = result.error;
  //     console.error(error);
  //   }
  // }
}

const storage = new Storage();
export default storage;
