import { CategoryDto } from "../../../types/category";

export const NEW_CATEGORY = "NEW_CATEGORY";
export const NEW_CATEGORY_SUCCESS = "NEW_CATEGORY_SUCCESS";
export const NEW_CATEGORY_ERROR = "NEW_CATEGORY_ERROR";
export const RESET_NEW_CATEGORY = "RESET_NEW_CATEGORY";

export interface NewCategory {
  type: typeof NEW_CATEGORY;
  payload: CategoryDto;
}

export interface NewCategorySuccess {
  type: typeof NEW_CATEGORY_SUCCESS;
  payload: CategoryDto | null;
}

export interface NewCategoryError {
  type: typeof NEW_CATEGORY_ERROR;
  payload: boolean | string;
}

export interface ResetNewCategory {
  type: typeof RESET_NEW_CATEGORY;
  payload: null;
}

export interface State {
  data: CategoryDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | NewCategory
  | NewCategorySuccess
  | NewCategoryError
  | ResetNewCategory;
