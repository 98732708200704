import Tabs from './components/Tabs';
import { Separator } from "../../../StyledComponents";
import { SectionName, Title } from './styles';
import { connect } from 'react-redux';

const groupTypes = {
  community: "comunidad",
  free_experience: "taller",
  experience: "taller",
  membership: "membresía",
};

const Component = ({ groupData }) => {
  return (
    <>
      <Separator height={{ desktop: "36px", mobile: "40px" }} />
      <SectionName>{!!groupData?.creator_name &&
        `${groupTypes[groupData?.group_type]} de ${groupData?.creator_name
        }
                `}</SectionName>
      <Title>{groupData?.group_name}</Title>
      <Separator height={{ desktop: "40px", mobile: "16px" }} />
      <Tabs active="about" hiddenTabs={groupData?.recorded ? ["activity-summary", "library"] : groupData?.group_type === 'free_experience' ? ['modules', "activity-summary"] : ['modules']} />
      <Separator height={{ desktop: "70px", mobile: "40px" }} />
    </>
  )
}

const state = ({ userStore, groupStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: subscriptions } = userStore.subscriptions;
  const { data: groupData } = groupStore.group


  return {
    userInformation,
    subscriptions,
    groupData
  };
};

export default connect(state)(Component);