import { EditPlanOutDto, NewPlanOutDto, PlanDto } from "../../types/plan.dto";
import {
  NEW_PLAN,
  NewPlan,
  ResetNewPlan,
  RESET_NEW_PLAN,
} from "../types/plan/new";
import { GET_PLANS, GetPlans } from "../types/plan/get-all";
import {
  GET_PLAN,
  RESET_GET_PLAN,
  ResetGetPlan,
  GetPlan,
} from "../types/plan/get-one";
import {
  EDIT_PLAN,
  EditPlan,
  ResetEditPlan,
  RESET_EDIT_PLAN,
} from "../types/plan/edit";
import {
  DELETE_PLAN,
  DeletePlan,
  RESET_DELETE_PLAN,
  ResetDeletePlan,
} from "../types/plan/delete";
import { SelectedPlan, SELECTED_PLAN, ResetSelectedPlan, RESET_SELECTED_PLAN } from "../types/plan/selected-plan";

export const createNewPlan = (payload: NewPlanOutDto): NewPlan => {
  return { type: NEW_PLAN, payload };
};

export const resetCreateNewPlan = (): ResetNewPlan => {
  return { type: RESET_NEW_PLAN, payload: null };
};

export const editPlan = (payload: EditPlanOutDto): EditPlan => {
  return { type: EDIT_PLAN, payload };
};

export const resetEditPlan = (): ResetEditPlan => {
  return { type: RESET_EDIT_PLAN, payload: null };
};

export const getPlans = (payload: {
  idGroup: number;
}): GetPlans => {
  return { type: GET_PLANS, payload };
};

export const getPlan = (payload: { idPlan: number }): GetPlan => {
  return { type: GET_PLAN, payload };
};

export const resetGetPlan = (): ResetGetPlan => {
  return { type: RESET_GET_PLAN, payload: null };
};

export const deletePlan = (payload: { idPlan: number }): DeletePlan => {
  return { type: DELETE_PLAN, payload };
};

export const resetDeletePlan = (): ResetDeletePlan => {
  return { type: RESET_DELETE_PLAN, payload: null };
};

export const selectedPlan = (payload: PlanDto | null): SelectedPlan => {
  return { type: SELECTED_PLAN, payload };
};

export const resetSelectedPlan = (): ResetSelectedPlan => {
  return { type: RESET_SELECTED_PLAN, payload: null };
};