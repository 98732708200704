import styled, { createGlobalStyle } from "styled-components";
import { Dot } from "pure-react-carousel";

export const GlobalStyles = createGlobalStyle`
  .slider-skin-one {
  }

  .slider-wrap-skin-one {
    padding: 0px;

    @media (min-width: 768px) {
      font-size: 90px;
    }

    @media (min-width: 1440px) {
      font-size: 150px;
    }
  }

  .slide-skin-one{
    margin: 0px 5px;

    @media (min-width: 768px) {
      margin: 0px 15px;
    }
  }
`;

export const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Dots = styled(Dot)`
  margin: 16px 5px 0px 0px;
  border: 3px solid white;
  background-color: ${(props) => props.theme.colors.PurpleVeryLight};
  height: 18px;
  width: 18px;
  border-radius: 100%;
  display: block;

  &.carousel__dot--selected {
    border: 3px solid ${(props) => props.theme.colors.PurpleVeryLight};
    background-color: white;
  }

  &:disabled,
  &:hover,
  &:focus,
  &:active {
    background-color: white;
    border: 3px solid ${(props) => props.theme.colors.PurpleVeryLight};
  }
`;
