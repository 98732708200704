import React from "react";
import ReactDOM from "react-dom";
import Pages from "./pages";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter
import reportWebVitals from "./reportWebVitals";
import createStore from "./store";
import ScriptInjecter from "./helpers/ScriptInjecter";
import { GoogleAnalyticsProvider } from "./components/TrackingProviders"; // Import the tracking providers

const store = createStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <GoogleAnalyticsProvider trackingId={process.env.REACT_APP_GA4_ID || ""}>
            <ScriptInjecter />
            <Pages />
        </GoogleAnalyticsProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
