import { User } from "../../../types";

export const GET_USER_INFORMATION = "GET_USER_INFORMATION";
export const GET_USER_INFORMATION_SUCCESS = "GET_USER_INFORMATION_SUCCESS";
export const GET_USER_INFORMATION_ERROR = "GET_USER_INFORMATION_ERROR";

export const UPDATE_CURRENT_USER_INFORMATION = "UPDATE_CURRENT_USER_INFORMATION";
export const UPDATE_CURRENT_USER_INFORMATION_SUCCESS = "UPDATE_CURRENT_USER_INFORMATION_SUCCESS";
export const UPDATE_CURRENT_USER_INFORMATION_ERROR = "UPDATE_CURRENT_USER_INFORMATION_ERROR";

export const RESET_USER_INFORMATION = "RESET_USER_INFORMATION";

export interface GetUserInformation {
  type: typeof GET_USER_INFORMATION;
  payload: null;
}

export interface GetUserInformationSuccess {
  type: typeof GET_USER_INFORMATION_SUCCESS;
  payload: User;
}
export interface GetUserInformationError {
  type: typeof GET_USER_INFORMATION_ERROR;
  payload: boolean | string;
}

export interface UpdateCurrentUserInformation {
  type: typeof UPDATE_CURRENT_USER_INFORMATION;
  payload: null;
}

export interface UpdateCurrentUserInformationSuccess {
  type: typeof UPDATE_CURRENT_USER_INFORMATION_SUCCESS;
  payload: User;
}

export interface UpdateCurrentUserInformationError {
  type: typeof UPDATE_CURRENT_USER_INFORMATION_ERROR;
  payload: boolean | string;
}

export interface ResetUserInformation {
  type: typeof RESET_USER_INFORMATION;
  payload: null;
}

export interface State {
  data: User | null,
  states: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    error: false,
    success: false,
  },
};

export type Actions =
  | GetUserInformation
  | GetUserInformationSuccess
  | GetUserInformationError
  | UpdateCurrentUserInformation
  | UpdateCurrentUserInformationSuccess
  | UpdateCurrentUserInformationError
  | ResetUserInformation;
