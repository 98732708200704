import { SurveyInDto } from "../../../types/survey.dto";

export const GET_ALL_SURVEY_ANSWERS = "GET_ALL_SURVEY_ANSWERS";
export const GET_ALL_SURVEY_ANSWERS_SUCCESS = "GET_ALL_SURVEY_ANSWERS_SUCCESS";
export const GET_ALL_SURVEY_ANSWERS_ERROR = "GET_ALL_SURVEY_ANSWERS_ERROR";
export const RESET_GET_ALL_SURVEY_ANSWERS = "RESET_GET_ALL_SURVEY_ANSWERS";

export interface GetAllSurveyAnswers {
  type: typeof GET_ALL_SURVEY_ANSWERS;
  payload: { idSurvey: string | number };
}

export interface GetAllSurveyAnswersSuccess {
  type: typeof GET_ALL_SURVEY_ANSWERS_SUCCESS;
  payload: SurveyInDto;
}

export interface GetAllSurveyAnswersError {
  type: typeof GET_ALL_SURVEY_ANSWERS_ERROR;
  payload: string | boolean;
}

export interface ResetGetAllSurveyAnswers {
  type: typeof RESET_GET_ALL_SURVEY_ANSWERS;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetAllSurveyAnswers
  | GetAllSurveyAnswersSuccess
  | GetAllSurveyAnswersError
  | ResetGetAllSurveyAnswers;
