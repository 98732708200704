import * as Yup from "yup";

export const schema = Yup.object().shape({
  title: Yup.string().required("Debes indicar el nombre de la artículo."),
  description: Yup.string()
    .nullable()
    .required("Agrega una descripción a tu artículo."),
});

interface FormValues {
  title: string;
  description: string;
}

export const initialValues: FormValues = {
  title: "",
  description: "",
};
