import styled from "styled-components";
import UiButton from "../../../../../components/Button";
import UiModal from "../../../../../components/Modal";

export const Modal = styled(UiModal)``;

export const ModalDialog = styled("div")``;

export const Title = styled.div`
  margin: 43px 0px 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

export const Text = styled.div`
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const Button = styled(UiButton)`
  min-width: 175px;

  &: first-child {
    margin: 0px 10px 0px 0px;
  }
`;
