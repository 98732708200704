import styled from "styled-components";

export const GoToSignUp = styled("div")`
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
  }
`;

export const Title = styled("h1")`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin: 0px;
`;

export const P = styled("p")`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 474px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
  }
`;
