import { put, call, all, select, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NEW_WELCOME_CONTENT_POST,
  NEW_WELCOME_CONTENT_POST_SUCCESS,
  NEW_WELCOME_CONTENT_POST_ERROR,
} from "../types/welcome-content/new-welcome-content-post";

import {
  GET_WELCOME_CONTENT_POST,
  GET_WELCOME_CONTENT_POST_SUCCESS,
  GET_WELCOME_CONTENT_POST_ERROR,
} from "../types/welcome-content/get-welcome-content-post";

import {
  GET_ALL_WELCOME_CONTENT_POSTS,
  UPDATE_CURRENT_ALL_WELCOME_CONTENT,
  GET_ALL_WELCOME_CONTENT_POSTS_SUCCESS,
  GET_ALL_WELCOME_CONTENT_POSTS_ERROR,
} from "../types/welcome-content/get-all-welcome-content-posts";

import {
  DELETE_WELCOME_CONTENT_POST_FILE,
  DELETE_WELCOME_CONTENT_POST_FILE_SUCCESS,
  DELETE_WELCOME_CONTENT_POST_FILE_ERROR,
} from "../types/welcome-content/delete-welcome-content-post-file";

import {
  DELETE_WELCOME_CONTENT_POST,
  DELETE_WELCOME_CONTENT_POST_SUCCESS,
  DELETE_WELCOME_CONTENT_POST_ERROR,
} from "../types/welcome-content/delete-welcome-content-post";

import {
  EDIT_WELCOME_CONTENT_POST,
  EDIT_WELCOME_CONTENT_POST_SUCCESS,
  EDIT_WELCOME_CONTENT_POST_ERROR,
} from "../types/welcome-content/edit-welcome-content-post";

import {
  EDIT_WELCOME_CONTENT_SECTION,
  EDIT_WELCOME_CONTENT_SECTION_SUCCESS,
  EDIT_WELCOME_CONTENT_SECTION_ERROR,
} from "../types/welcome-content/edit-welcome-content-section";

import {
  NEW_WELCOME_CONTENT_SECTION,
  NEW_WELCOME_CONTENT_SECTION_SUCCESS,
  NEW_WELCOME_CONTENT_SECTION_ERROR,
} from "../types/welcome-content/new-welcome-content-section";

import {
  GET_WELCOME_CONTENT_SECTION,
  GET_WELCOME_CONTENT_SECTION_SUCCESS,
  GET_WELCOME_CONTENT_SECTION_ERROR,
} from "../types/welcome-content/get-welcome-content-section";

import {
  REORDER_WELCOME_CONTENT_POSTS,
  REORDER_WELCOME_CONTENT_POSTS_ERROR,
  REORDER_WELCOME_CONTENT_POSTS_SUCCESS
} from "../types/welcome-content/reorder-welcome-content-posts";

import {
  FINISH_WELCOME_CONTENT_POST,
  FINISH_WELCOME_CONTENT_POST_ERROR,
  FINISH_WELCOME_CONTENT_POST_SUCCESS,
} from "../types/welcome-content/finish-welcome-content-post";

import {
  FINISH_WELCOME_CONTENT_SECTION,
  FINISH_WELCOME_CONTENT_SECTION_ERROR,
  FINISH_WELCOME_CONTENT_SECTION_SUCCESS
} from "../types/welcome-content/finish-welcome-content-section";


// const getCurrentAllWelcomeContentPost = ({ welcomeContentStore }) => welcomeContentStore.allWelcomeContentsPosts.data;
const getCurrentWelcomeContentPost = ({ welcomeContentStore }) => welcomeContentStore.welcomeContentPost.data;
const getCurrentWelcomeContentSection = ({ welcomeContentStore }) => welcomeContentStore.welcomeContentSection.data;

function* newWelcomeContentSection({ payload }): any {
  try {
    const { response, error } = yield call(services.welcomeContent.newWelcomeContentSection, payload);

    if (error) {
      yield put({ type: NEW_WELCOME_CONTENT_SECTION_ERROR, payload: error });
    } else {
      yield all([
        put({ type: NEW_WELCOME_CONTENT_SECTION_SUCCESS, payload: response }),
        // put({ type: GET_WELCOME_CONTENT_SECTION, payload: {idWelcomeContentSection: response.id, filters: { with_contents: true}} }),
        put({ type: GET_WELCOME_CONTENT_SECTION_SUCCESS, payload: response }),
      ])
    }

  } catch (error) {
    yield put({
      type: NEW_WELCOME_CONTENT_SECTION_ERROR,
      payload: "Error inesperado al crear sección.",
    });
  }
}

function* editWelcomeContentSection({ payload }): any {
  const welcomeContentSection = yield select(getCurrentWelcomeContentSection);
  try {
    const { response, error } = yield call(services.welcomeContent.editWelcomeContentSection, payload);

    if (!!error) {
      yield put({ type: EDIT_WELCOME_CONTENT_SECTION_ERROR, payload: error });
    } else {
      yield all([
        put({ type: EDIT_WELCOME_CONTENT_SECTION_SUCCESS, payload: response }),
        put({
          type: GET_WELCOME_CONTENT_SECTION_SUCCESS,
          payload: { ...welcomeContentSection, ...response, welcome_contents: welcomeContentSection.welcome_contents, group: welcomeContentSection.group },
        }),
      ]);
    }
  } catch (error) {
    yield put({
      type: EDIT_WELCOME_CONTENT_SECTION_ERROR,
      payload: "No se pudo editar la sección",
    });
  }
}

function* getWelcomeContentSection({ payload }): any {
  console.log('getWelcomeContentSection saga')
  try {
    const { response, error } = yield call(services.welcomeContent.getWelcomeContentSection, payload);

    if (error) {
      yield put({ type: GET_WELCOME_CONTENT_SECTION_ERROR, payload: error });
    } else {
      yield all([
        put({ type: GET_WELCOME_CONTENT_SECTION_SUCCESS, payload: response }),
        //put({ type: UPDATE_GET_WELCOME_CONTENT_SECTIONS, payload: welcomeContentsList })
      ])
    }
  } catch (error) {
    yield put({
      type: GET_WELCOME_CONTENT_SECTION_ERROR,
      payload: "Error inesperado.",
    });
  }
}

function* newWelcomeContentPost({ payload }): any {
  try {
    const { response, error } = yield call(services.welcomeContent.newWelcomeContentPost, payload);

    const welcomeContentSection = yield select(getCurrentWelcomeContentSection);

    if (response) {
      welcomeContentSection?.welcome_contents?.push(response)
    }

    if (error) {
      yield put({ type: NEW_WELCOME_CONTENT_POST_ERROR, payload: error });
    } else {
      yield all([put({ type: NEW_WELCOME_CONTENT_POST_SUCCESS, payload: response }),
      put({
        type: GET_WELCOME_CONTENT_SECTION_SUCCESS,
        payload: { ...welcomeContentSection },
      })])
    }
  } catch (error) {
    yield put({
      type: NEW_WELCOME_CONTENT_POST_ERROR,
      payload: "Error inesperado al crear artículo.",
    });
  }
}

function* editWelcomeContentPost({ payload }): any {
  const welcomeContentPosts = yield select(getCurrentWelcomeContentPost);

  try {
    const { response, error } = yield call(services.welcomeContent.editWelcomeContentPost, payload);

    if (!!error) {
      yield put({ type: EDIT_WELCOME_CONTENT_POST_ERROR, payload: error });
    } else {
      yield all([
        put({ type: EDIT_WELCOME_CONTENT_POST_SUCCESS, payload: response }),
        put({
          type: GET_WELCOME_CONTENT_POST_SUCCESS,
          payload: response,
        }),
      ]);
    }
  } catch (error) {
    yield put({
      type: EDIT_WELCOME_CONTENT_POST_ERROR,
      payload: "No se pudo editar el Articulo",
    });
  }
}

function* deleteWelcomeContentPost({ payload }): any {
  // const welcomeContentsPosts = yield select(getCurrentAllWelcomeContentPost);
  // const newListWelcomeContentPosts = !!welcomeContentsPosts
  //   ? welcomeContentsPosts.filter((welcomeContent) => welcomeContent.id !== payload)
  //   : [];

  const welcomeContentSection = yield select(getCurrentWelcomeContentSection);

  try {
    const result = yield call(services.welcomeContent.deleteWelcomeContentPost, payload?.id);
    yield all([
      put({ type: DELETE_WELCOME_CONTENT_POST_SUCCESS, payload: result }),
      put({
        type: GET_WELCOME_CONTENT_SECTION, payload: { idWelcomeContentSection: welcomeContentSection.id, filters: { with_contents: true } }
      }),
      // put({ type: GET_ALL_WELCOME_CONTENT_POSTS_SUCCESS, payload: newListWelcomeContentPosts }),
    ]);
  } catch (error) {
    yield put({ type: DELETE_WELCOME_CONTENT_POST_ERROR, payload: error });
  }
}

function* deleteFile({ payload }): any {
  try {
    const result = yield call(services.welcomeContent.deleteFile, payload);
    yield put({ type: DELETE_WELCOME_CONTENT_POST_FILE_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: DELETE_WELCOME_CONTENT_POST_FILE_ERROR, payload: error });
  }
}

function* getWelcomeContentPost({ payload }): any {
  const { response, error } = yield call(services.welcomeContent.getWelcomeContentPost, payload);

  if (error) {
    yield put({ type: GET_WELCOME_CONTENT_POST_ERROR, payload: error?.response?.status });
  } else {
    yield put({ type: GET_WELCOME_CONTENT_POST_SUCCESS, payload: response });
  }
}

function* getAllWelcomeContentPosts({ payload }): any {
  const { response, error } = yield call(services.welcomeContent.getAllWelcomeContentPosts);

  if (error) {
    yield put({ type: GET_ALL_WELCOME_CONTENT_POSTS_ERROR, payload: error.response.status });
  } else {
    yield put({
      type: GET_ALL_WELCOME_CONTENT_POSTS_SUCCESS,
      payload: response,
    });
  }
}

function* reorderWelcomeContentPost({ payload }: any) {
  const { response, error } = yield call(services.welcomeContent.reorderWelcomeContentPost, payload);

  if (error) {
    yield put({ type: REORDER_WELCOME_CONTENT_POSTS_ERROR, payload: error.response.status });
  } else {

    const welcomeSection = yield select(getCurrentWelcomeContentSection);

    yield all([
      put({
        type: REORDER_WELCOME_CONTENT_POSTS_SUCCESS,
        payload: response,
      }),
      put({
        type: GET_WELCOME_CONTENT_SECTION, payload: { idWelcomeContentSection: welcomeSection.id, filters: { with_contents: true } }
      }),
    ]);
  }
}


function* finishWelcomeContentPost({ payload }): any {
  try {
    const result = yield call(services.welcomeContent.finishWelcomeContentPost, payload);
    yield all([
      put({ type: FINISH_WELCOME_CONTENT_POST_SUCCESS, payload: result }),
    ]);
  } catch (error) {
    yield put({ type: FINISH_WELCOME_CONTENT_POST_ERROR, payload: error });
  }
}


function* finishWelcomeContentSection({ payload }): any {
  try {
    const result = yield call(services.welcomeContent.finishWelcomeContentSection, payload);
    yield all([
      put({ type: FINISH_WELCOME_CONTENT_SECTION_SUCCESS, payload: result }),
    ]);
  } catch (error) {
    yield put({ type: FINISH_WELCOME_CONTENT_SECTION_ERROR, payload: error });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(NEW_WELCOME_CONTENT_POST, newWelcomeContentPost);
  yield takeLatest<any>(EDIT_WELCOME_CONTENT_POST, editWelcomeContentPost);
  yield takeLatest<any>(GET_WELCOME_CONTENT_POST, getWelcomeContentPost);
  yield takeLatest<any>(GET_ALL_WELCOME_CONTENT_POSTS, getAllWelcomeContentPosts);
  yield takeLatest<any>(DELETE_WELCOME_CONTENT_POST, deleteWelcomeContentPost);


  yield takeLatest<any>(NEW_WELCOME_CONTENT_SECTION, newWelcomeContentSection);
  yield takeLatest<any>(EDIT_WELCOME_CONTENT_SECTION, editWelcomeContentSection);
  yield takeLatest<any>(GET_WELCOME_CONTENT_SECTION, getWelcomeContentSection);

  yield takeLatest<any>(REORDER_WELCOME_CONTENT_POSTS, reorderWelcomeContentPost);

  yield takeLatest<any>(FINISH_WELCOME_CONTENT_POST, finishWelcomeContentPost);
  yield takeLatest<any>(FINISH_WELCOME_CONTENT_SECTION, finishWelcomeContentSection);

  yield takeLatest<any>(DELETE_WELCOME_CONTENT_POST_FILE, deleteFile);
}
