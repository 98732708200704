import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Card = styled('div')`
  position: relative;
  overflow: hidden;
  padding: 33px 0px 9px;

  &:before {
    opacity: .12;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(207,171,242);
    background: linear-gradient(90deg, rgba(207,171,242,1) 0%, rgba(108,92,231,1) 31%, rgba(66,178,252,1) 99%);
    z-index: -1;
  }
`
export const Title = styled('h3')`
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  line-height: normal;
  padding: 0px 10px;

  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  display: block;

  @media(min-width: 1024px){
    padding: 0px;
  }
`

export const Completed = styled('div')`
  border-radius: 15px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  background-color: white;
  color: #12B47A;
  font-weight: 500;
  gap: 8px;
  padding: 0px 16px;

  @media(min-width: 1024px){
    height: 44px;
    border-radius: 22px;
    font-size: 16px;
    font-weight: 600;
  }
`

export const CloseSectionButton = styled.button`
  text-decoration: none;
  border: 1px solid rgba(45,52,54,.75);
  background-color: rgba(45,52,54,.75);
  border-radius: 15px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  font-weight: 500;
  gap: 8px;
  padding: 0px 16px;
  cursor: pointer; // Add cursor pointer for button
  width: 100%;

  @media(min-width: 1024px){
    height: 44px;
    width: auto;
    border-radius: 22px;
    font-size: 16px;
    font-weight: 600;
  }
`;


export const NotCompleted = styled(Link)`
  text-decoration: none;
  border: 1px solid rgba(45,52,54,.75);
  background-color: white;
  border-radius: 15px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: rgba(45,52,54,.75);
  font-weight: 500;
  gap: 8px;
  padding: 0px 16px;
  width: 100%;

  @media(min-width: 1024px){
    height: 44px;
    border-radius: 22px;
    font-size: 16px;
    font-weight: 600;
    width: auto;
  }
`

export const Text = styled('div')`
  font-size: 16px;
  font-weight: regular;
  line-height: normal;
  padding: 0px 10px;

  @media(min-width: 1024px){
    padding: 0px;
  }
`

export const HorizontalScrollWrapper = styled('div')`
  margin: 0px;
`

export const HorizontalScroll = styled('div')`
  overflow-x: scroll;
  display: flex;
  scroll-snap-type: x mandatory;
  padding: 24px 34px;
  scroll-padding: 0 34px;
  
  @media(min-width: 1024px){
    padding: 24px 10px;
    scroll-padding: 0 10px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  & > * {
    scroll-snap-align: start;
    max-width: 300px!important;
    
    &:not(:last-child){
      margin-right: 30px;
    }
  }
`

export const CloseButton = styled('button')`
  margin-right: 24px;
  padding: 0px;
  border: 0px;
  background: none;
  cursor: pointer;
  // position: absolute;
  // top: 33px; 
  // right: 15px;

  @media(min-width: 1024px){
    margin: 0px;
  }
`