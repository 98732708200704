import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "./../../services";

import {
    EDIT_SUBSCRIPTION,
    EDIT_SUBSCRIPTION_SUCCESS,
    EDIT_SUBSCRIPTION_ERROR
  } from "../types/subscription-admin/edit-subscription";
  
  import {
    CANCEL_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION_SUCCESS,
    CANCEL_SUBSCRIPTION_ERROR
  } from "../types/subscription-admin/cancel-subscription";

  import {
    GET_SUBSCRIPTION,
    GET_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTION_ERROR
  } from "../types/subscription-admin/get-subscription";

function* editSubscription({ payload }) {
    try {
      const result = yield call(services.subscription.editSubscription, payload);
  
      yield all([
        put({ type: EDIT_SUBSCRIPTION_SUCCESS, payload: result }),
        // put({
        //   type: GET_SUBSCRIPTION_SUCCESS,
        //   payload: { ...payload, ...result, id: payload.id },
        // }),
      ]);
    } catch (error) {
      yield put({ type: EDIT_SUBSCRIPTION_ERROR, payload: error });
    }
  }
  
  function* cancelSubscription({ payload }) {
    try {
      const { response, error } = yield call(services.subscription.cancelSubscription, payload);
  
      if (!!error) {
        yield put({ type: CANCEL_SUBSCRIPTION_ERROR, payload: error });
      } else {
        yield all([
          put({ type: CANCEL_SUBSCRIPTION_SUCCESS, payload: response }),
          put({
            type: GET_SUBSCRIPTION_SUCCESS,
            payload: { ...payload, ...response, id: payload.id },
          }),
        ]);
      }
    } catch (error) {
      yield put({ type: CANCEL_SUBSCRIPTION_ERROR, payload: error });
    }
  }


  function* getSubscription({ payload }) {
    try {
      const result = yield call(services.subscription.getSubscription, payload);
  
      yield put({
        type: GET_SUBSCRIPTION_SUCCESS,
        payload: result,
      });
    } catch (error) {
      yield put({ type: GET_SUBSCRIPTION_ERROR, payload: error });
    }
  }


  /**
 * Watchers
 */
export default function* applicant() {

    yield takeLatest<any>(EDIT_SUBSCRIPTION, editSubscription);
    yield takeLatest<any>(CANCEL_SUBSCRIPTION, cancelSubscription);
    yield takeLatest<any>(GET_SUBSCRIPTION, getSubscription);
  
    
  }