import styled from "styled-components";
import { Card as CardUi } from "../StyledComponents";

export const Text = styled("p")`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`;

export const Card = styled(CardUi)`
  padding: 26px 16px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 40px 64px 48px;
  }
`;
