import { Ready, Text } from "./styles";
import { Link } from "../../components/styles";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import Grid from "../../../../components/Grid";
import Row from "../../../../components/Grid/Row";
import Col from "../../../../components/Grid/Col";
import {LinkItem} from "../StartsToday/styles";
import NewMeet from "../../components/NewMeet";

const Component = () => {
  const { isMobile } = useWindowSize();
  return (
    <Ready>
      <Grid.Container>
        <Row>
          <Col offset={{ md: 1 }} md={10}>
            <Text>
              A través de un mágico recorrido y tus dinámicas interactivas
              lograrás afianzar vínculos reales
            </Text>
            <LinkItem>
              <Link to="/expert/signup">¡Crear mi Klouser gratis!</Link>
              <NewMeet skin="white"/>
            </LinkItem>
          </Col>
        </Row>
      </Grid.Container>
    </Ready>
  );
};

export default Component;
