import { FieldArray } from "formik";
import { DeleteQuestion } from "../StyledComponents";
import Button from "../../../../../../../components/Button";
import Icon from "../../../../../../../components/Icon";
import deleteCircleCross from "../../../../../../../assets/icons/delete-circle-cross.svg";
import Input from "../../../../../../../components/FormikForm/Input";
import ReactSelect from "../../../../../../../components/FormikForm/ReactSelect";

const questionsTypes = [
  { label: "Respuesta abierta", value: "short_text", isDisabled: false },
  {
    label: "Respuesta múltiple (próximamente)",
    value: "dropdown_list",
    isDisabled: true,
  },
  {
    label: "Respuesta numérica (próximamente)",
    value: "number",
    isDisabled: true,
  },
];

interface Option {
  label: string;
  value: string | number;
}

const Component = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  isLoading,
}) => {
  return (
    <>
      <FieldArray
        name="content_data.fields"
        render={({ insert, remove, push }) => (
          <>
            <div>
              {values?.content_data?.fields &&
                values?.content_data?.fields?.length > 0 &&
                values?.content_data?.fields.map((question, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        marginBottom: "24px",
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "8px",
                      }}
                    >
                      <div style={{ width: "calc(100% - 32px)" }}>
                        <ReactSelect
                          name={`content_data.fields.${index}.field_type']`}
                          error={
                            errors[`content_data.fields.${index}.field_type`]
                          }
                          touched={
                            touched[`content_data.fields.${index}.field_type`]
                          }
                          items={questionsTypes}
                          defaultValue={questionsTypes.filter(
                            (type) => type.value === question.field_type
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          options={{
                            label: `Pregunta ${index + 1}`,
                            marginBottom: 12,
                            loading: isLoading,
                          }}
                          isOptionDisabled={(option) => option.isDisabled}
                        />

                        <Input
                          name={`content_data.fields.${index}.caption`}
                          error={errors?.content_data?.fields[index]?.caption}
                          touched={
                            touched?.content_data?.fields[index]?.caption
                          }
                          value={question?.caption}
                          placeholder="Ingresa una pregunta para tu suscriptor"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          options={{
                            marginBottom: 0,
                          }}
                        />
                      </div>

                      {index !== 0 && (
                        <div
                          style={{
                            marginTop: "27px",
                            height: "40px",
                            display: "flex",
                          }}
                        >
                          <DeleteQuestion
                            type="button"
                            onClick={() => remove(index)} // remove a question from the list
                          >
                            <Icon
                              icon={deleteCircleCross}
                              size="20px"
                              color="#96999B"
                            />
                          </DeleteQuestion>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>

            {(!values?.content_data?.fields ||
              (!!values?.content_data?.fields &&
                values?.content_data?.fields?.length < 3)) && (
                <Button
                  type="button"
                  options={{
                    size: "lg",
                    type: "link",
                    skin: "purple",
                  }}
                  onClick={() => {
                    insert(values?.content_data?.fields?.length, {
                      caption: "",
                      field_type: "short_text",
                    });
                  }}
                >
                  Agregar otra pregunta
                </Button>
              )}
          </>
        )}
      />
    </>
  );
};

export default Component;
