import {
  Card,
  Top,
  Actions,
  Link,
  Button,
  Title,
  Author,
  Date,
  Information,
  GoToPreview,
  Background,
  FreeLabel,
  Circle,
  FutureDate,
  Reactions,
  EmojiWithCounter
} from "./styles";
import schedulerIcon from "../../../../../assets/scheduler-icon.svg";

import postEditIcon from "../../../../../assets/icons/post-edit-icon.svg";
import postPinUncheckedIcon from "../../../../../assets/icons/post-pin-unchecked-icon.svg";
import postPinCheckedIcon from "../../../../../assets/icons/post-pin-checked-icon.svg";
import postCopyLinkIcon from "../../../../../assets/icons/post-copy-link-icon.svg";

import { connect, useDispatch } from "react-redux";
import { Post } from "../../../../../types/post";
import { useEffect, useMemo, useState } from "react";
import constants from "../../../../../constants";
import CopyToClipboad from "../../../../../components/CopyToClipboad";
import { useMarkdown } from "../../../../../hooks/useMarkdown";
import KeyIcon from "../../../../../assets/icons/key-2.svg";
import Icon from "../../../../../components/Icon";
import { dateSpliter } from "../../../../../helpers/dates-hanlder";
import { setFeaturedPost } from "../../../../../store/actions/post";

import background1 from "../../../../../assets/backgrounds/membership/background-1.svg";
import background2 from "../../../../../assets/backgrounds/membership/background-4.svg";
import background3 from "../../../../../assets/backgrounds/membership/background-5.svg";
import { State } from "../../../../../components/GroupCard/styles";
import chevronDownIcon from "../../../../../assets/icons/chevron-down.svg";
import { Separator } from "../../../../../components/StyledComponents";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import { useLocation } from "react-router-dom";

const backgrounds = [background1, background2, background3];
const randomBg = Math.floor(Math.random() * backgrounds.length);
interface ComponentProps {
  post: Post;
  isMine?: boolean;
  groupInformation;
  userInformation;
  onDelete?;
  style?;
  showActions?: boolean;
  showPinAction?: boolean;
  onUnpinIt?: React.Dispatch<React.SetStateAction<Post | undefined>>
  coverPicHeight?: number;
}

const showFeaturePostCta = ["experience", "membership"];
const Component = ({
  onDelete,
  post,
  isMine = false,
  groupInformation,
  userInformation,
  style,
  showActions = true,
  showPinAction = true,
  onUnpinIt,
  coverPicHeight = 155,
}: ComponentProps) => {
  const { typeMap, markMap, jsonStr, textStr } = useMarkdown({
    brief: post?.body,
  });
  const dispatch = useDispatch();
  const [ownGroup, setOwnGroup] = useState(false);
  const [hastAttached, setHasAttached] = useState(false);
  const [publishedAt, setPublishedAt] = useState<any>();
  const [schedulePublishAt, setSchedulePublishAt] = useState<any>();
  const { isMobile } = useWindowSize()
  const [image, setImage] = useState<string>();
  const location = useLocation();

  const postReactions = useMemo(() => {
    if (post.post_reactions) {
      const result = post.post_reactions.reduce((acc, item) => {
        const emoji = item.reaction_emoji;

        // Create an array for the emoji if it doesn't exist in the result
        if (!acc[emoji]) {
          acc[emoji] = [];
        }

        // Push the current item to the array for the current emoji
        acc[emoji].push(item);

        // Return the accumulator (acc)
        return acc;
      }, {});

      return result;
    }

    return [];
  }, [post]);

  useEffect(() => {
    if (!!groupInformation && !!userInformation) {
      setOwnGroup(groupInformation?.expert?.id === userInformation?.expert?.id);
    }
  }, [groupInformation, userInformation]);

  useEffect(() => {
    if (!!post) {

      if (post.published_at)
        setPublishedAt(dateSpliter(post?.published_at));
      else
        setPublishedAt(dateSpliter(post?.schedule_publish_at))

      setHasAttached(post?.attached_files?.length > 0 || !!post?.attached_url);

      // if (!!post?.attached_files || !!post?.cover_pic) {
        let attachmentImage = backgrounds[randomBg];

        if (post?.attached_files?.length > 0) {
          attachmentImage =
            (!!post?.attached_files &&
              post?.attached_files?.filter((file) =>
                file?.mime?.includes("image")
              )[0]?.url) || backgrounds[randomBg]
        }

        setImage(post?.cover_pic?.url || attachmentImage)
    }
  }, [post]);

  return (
    <Card style={{ ...style }}>
      <Background className="background" backgroundImage={image} height={coverPicHeight}/>

      <Top>
        {post.is_free && (
          <FreeLabel>
            <Icon icon={KeyIcon} size="14px" color="rgba(45, 52, 54, 1)" />
            {!isMobile ? "Artículo" : ""} liberado
          </FreeLabel>
        )}

        {showActions && (
          <Actions className="article-actions">
            {(ownGroup || isMine) && (
              <>
                {showPinAction && showFeaturePostCta.includes(groupInformation?.group_type) && (
                  <Circle>
                    <Button
                      onClick={() => {
                        dispatch(
                          setFeaturedPost({
                            idPost: post?.id,
                            pinned: !post?.pinned,
                          })
                        );

                        if (!!onUnpinIt) {
                          onUnpinIt({
                            ...post,
                            pinned: !post?.pinned,
                          })
                        }
                      }}
                      options={{
                        size: "sm",
                        type: "filled",
                        skin: "purple",
                      }}
                    >
                      <Icon
                        icon={
                          post?.pinned
                            ? postPinCheckedIcon
                            : postPinUncheckedIcon
                        }
                        size="14px"
                        color={"black"}
                      />
                    </Button>
                  </Circle>
                )}

                <Circle>
                  <CopyToClipboad
                    copy={`${process.env.REACT_APP_SITE_BASE_URL}/group/${groupInformation?.id}/p/${post.id}`}
                  >
                    <Icon icon={postCopyLinkIcon} size="14px" color={"black"} />
                  </CopyToClipboad>
                </Circle>

                <Circle>
                  <Link
                    href={`/dashboard/${groupInformation?.group_type === "free_experience"
                      ? "community/events"
                      : groupInformation?.group_type
                      }/${groupInformation?.id}/posts/${post.id}`}
                    options={{
                      native: true,
                      size: "sm",
                      type: "filled",
                      skin: "purple",
                    }}
                  >
                    <Icon icon={postEditIcon} size="14px" color={"black"} />
                  </Link>
                </Circle>
              </>
            )}
          </Actions>
        )}
      </Top>

      <GoToPreview coverPicHeight={coverPicHeight} to={{ pathname: `/group/${groupInformation?.id}/p/${post.id}`, state: { from: location?.pathname } }}>
        <Title>{post.title}</Title>
        <Information>
          {post.published_at ? (
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {/* <Author>{groupInformation?.creator_name}</Author> */}
              <Reactions reactions={postReactions}>
                {Object.keys(postReactions)?.map((reaction) => (
                  <EmojiWithCounter>
                    <div className='reaction-container'>
                      <div className='emoji'>{reaction} </div>
                      <div className='counter'>{postReactions[reaction].length}</div>
                    </div>
                  </EmojiWithCounter>
                ))}
              </Reactions>
              <Date>{`${publishedAt?.day?.number}/${publishedAt?.month?.number}/${publishedAt?.year}`}</Date>
            </div>
          ) : (
            <FutureDate><Icon icon={schedulerIcon} color="black" size="14px" />{!isMobile ? "Se publicará el" : ""} {publishedAt?.day?.number}/{publishedAt?.month?.number} {" - "} {publishedAt?.hours}:{publishedAt?.minutes}hs</FutureDate>
          )}
        </Information>
      </GoToPreview>
    </Card>
  );
};

const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;

  return {
    userInformation,
  };
};

export default connect(state)(Component);
