import PdfPage from './../PdfPage'
import { Thumbnail, ImageWrapper, PageNumber } from './styles';

const Component = ({ pages, setCurrentPage, currentPage }) => {
  return (
    <>
      {pages.map((page, index) => {
        const pageNumber = index;

        return (
          <Thumbnail
            key={'pdf-page-' + pageNumber}
            onClick={() => {
              setCurrentPage(pageNumber);
            }}
          >
            <ImageWrapper active={currentPage === pageNumber}>
              <PdfPage canvas={page} />
            </ImageWrapper>

            <PageNumber active={currentPage === pageNumber}>Página {pageNumber + 1}</PageNumber>
          </Thumbnail >
        )
      })}
    </>
  );
};

export default Component