export const DELETE_POST = "DELETE_POST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_ERROR = "DELETE_POST_ERROR";
export const RESET_DELETE_POST = "RESET_DELETE_POST";

export interface DeletePost {
  type: typeof DELETE_POST;
  payload: { idPost: number };
}

export interface DeletePostSuccess {
  type: typeof DELETE_POST_SUCCESS;
  payload: null;
}

export interface DeletePostError {
  type: typeof DELETE_POST_ERROR;
  payload: string | boolean;
}

export interface ResetDeletePost {
  type: typeof RESET_DELETE_POST;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | DeletePost
  | DeletePostSuccess
  | DeletePostError
  | ResetDeletePost;
