import { useFormikContext } from "formik";
import { useEffect } from "react";

const Component = ({ callback }) => {
  const formik = useFormikContext();

  useEffect(() => {
    if (JSON.stringify(formik?.values as any) !== JSON.stringify(formik.initialValues as any)) {
      callback(formik.values); // or onSubmit if you want to do validations before submitting
    }
  }, [formik.values]);
  return <></>;
};



export default Component;
