import { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../../providers/Auth";
import Welcome from "./component/welcome";
import { FormTitle, Explanation, FormSubTitle, Image } from "./styles";
import constants from "../../../constants";
import Link from "../../../components/Link";
import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Col from "../../../components/Grid/Col";
import { connect } from "react-redux";
import Whatsapp from "./../../../components/Whatsapp";

const Component = ({ userInformation }) => {
  const [userName, setUserName] = useState("");
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (userInformation?.name || user?.displayName) {
      setUserName(userInformation?.name || user?.displayName);
    }
  }, [user, userInformation]);

  if (!!userInformation?.expert) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Container>
      <Row>
        <Col sm={5} offset={{ sm: 3 }}>
          <Welcome>
            <Image
              src={constants.pageWelcome.image}
              alt="Welcome"
              width="auto"
              height="auto"
            />
            <FormTitle marginBottom={0}>
              ¡Hola,
              <span>{userName !== "" && ` ${userName}`}</span>!
            </FormTitle>
            <FormSubTitle>Te damos la Bienvenida a Klouser</FormSubTitle>
            <Col sm={9} offset={{ sm: 0 }}>
              <Explanation>
                Aquí podrás generar ingresos a traves de{" "}
                <span>
                  dinámicas grupales como Encuentros, Talleres y Suscripciones
                  Mensuales.
                </span>
                <br />
                ¡Anímate a vivir de lo que te apasiona!
              </Explanation>
            </Col>
            <Link
              href={"/profile/survey"}
              options={{
                type: "filled",
                size: "sm",
                skin: "purple",
              }}
            >
              Comencemos
            </Link>
          </Welcome>
          <Whatsapp floating />
        </Col>
      </Row>
    </Container>
  );
};

const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;
  return {
    userInformation,
  };
};

export default connect(state)(Component);
