import { Loader } from "./styles";
import Image from "../Image";
import logo from "../../assets/logo.svg";
import spinner from "../../assets/spinnerloader.svg";


const Component = () => {
  return (
    <Loader>
      <Image src={spinner} alt="Logo" width="150" height="150" />
    </Loader>
  );
};

export default Component;
