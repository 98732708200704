import { combineReducers } from "redux";
import expertTelegramGroup from "./get-expert-telegram-group";
import expertTelegramBotUrlForNotifications from "./get-expert-telegram-bot-url-for-notifications";
import group from "./get-group";
import subscriberTelegramGroup from "./get-subscriber-telegram-group";
import newGroup from "./new-group";
import newTelegramGroup from "./new-telegram-group";
import updateGroup from "./update-group";
import groupSubscribers from "./group-subscribers";
import deleteReview from "./delete-review";
import metrics from "./metrics";
import sessions from "./sessions";
import lastActivity from "./group-last-activity";
import updateGroupMainCurrency from './update-group-main-currency'

const reducer = combineReducers({
  group,
  metrics,
  sessions,
  groupSubscribers,
  newGroup,
  updateGroup,
  updateGroupMainCurrency,
  expertTelegramGroup,
  expertTelegramBotUrlForNotifications,
  subscriberTelegramGroup,
  newTelegramGroup,
  deleteReview,
  lastActivity,
});

export default reducer;
