import { Title, Message, ProfileImage, Column, Link, SmallMessage } from "./styles";
import { connect } from "react-redux";
import userAvatar from "../../../../../assets/avatar.svg";
import { useEffect, useMemo, useState } from 'react';
import { getUrlParamByName } from "../../../../../helpers/get-url-param";
import Image from '../../../../../components/Image'
import mercadoPagoImg from '../../../../../assets/mercado-pago.png'
import shieldImg from '../../../../../assets/shield.png'
import { useTheme } from 'styled-components';
import { Subscription, User } from "../../../../../types";

const groupTypes = {
  community: "comunidad",
  free_experience: "taller",
  experience: "taller",
  membership: "membresía",
};

interface ComponentProps {
  subscription: Subscription | null;
  user: User;
}

const Component = ({ subscription, user }: ComponentProps) => {
  const theme = useTheme() as { logo: { small: string, big: string } };

  const group = useMemo(() => subscription?.plan?.group, [subscription])

  const text = useMemo(() => {
    if(!!group){
      const preffix = ['community', 'membership'].includes(group?.group_type) ? 'a la' : 'al';
      return `Ir ${preffix} ${groupTypes[group?.group_type]}`
    } else {
      return ""
    }
  }, [group])

  const profilePicture = useMemo(() => {
    return group?.expert?.profile_pic?.url || userAvatar
  }, [group])

  // useEffect(() => {
  //   if (countdown > 0) {
  //     const timer = setTimeout(() => {
  //       setCountdown(countdown - 1);
  //     }, 1000);

  //     return () => clearTimeout(timer);
  //   } else {
  //     window.location.href = `/group/${groupSlug}/survey`;
  //   }
  // }, [countdown, groupSlug]);

  return (
    <Column>
      <div style={{ gap: 24, display: 'flex', justifyContent: 'center', width: '100%', maxWidth: 410 }}>
        <Image
          src={theme?.logo?.big}
          style={{ display: 'block' }}
          width="107px"
          height="auto"
          className="logo-desktop"
          alt="Klouser"
          lazy
        />

        <div style={{ height: 30, width: 1, backgroundColor: '#B1C6E4' }} />

        <Image
          src={mercadoPagoImg}
          style={{ display: 'block' }}
          width="101px"
          height="auto"
          className="logo-desktop"
          alt="Mercado pago"
          lazy
        />
      </div>

      <div style={{ height: 52 }} />

      <div style={{ position: 'relative' }}>
        <ProfileImage loading="lazy" src={profilePicture} alt={"Profile Image"} />
        <Image
          src={shieldImg}
          style={{
            display: "block",
            position: "absolute",
            left: "100%",
            top: "50%",
            transform: "translate(-26px, -28px)",
          }}
          width="57px"
          height="auto"
          alt="Shield"
          lazy
        />
      </div>

      <div style={{ height: 42 }} />
      <Title>Actualización de método<br />de pago exitosa</Title>
      <div style={{ height: 42 }} />
      <Message>
        {/* OLD MESSAGE - A partir de ahora realizaremos los cobros al nuevo método de pago proporcionado */}
        <strong style={{ fontWeight: 500 }}>Ya hemos actualizado tu metodo de pago para {group?.group_name}.</strong><br /><br />Tu suscripción se renovará como siempre <br /> en la próxima fecha de cobro
      </Message>
      <div style={{ height: 42 }} />
      <Link options={{ type: "filled", size: "lg", skin: "purple", block: true }} href={`/profile/subscriptions`}>Ir a Mis Suscripciones</Link>
      <div style={{ height: 22 }} />
      <SmallMessage>Toca "Ir a Mis Suscripciones" para ser redirigido a la plataforma </SmallMessage>
    </Column>
  )
}

const state = ({ groupStore, userStore, subscriptionAdminStore }) => {
  const { data: user } = userStore?.information
  const { data: subscription } = subscriptionAdminStore.subscriptionAdmin;

  return { subscription, user };
};

export default connect(state)(Component);