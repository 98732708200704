import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import Link from "../../../../../components/Link";
import { Center } from "../../../../../components/StyledComponents";
import Icon from "../../../../../components/Icon";
import { Title, Text, Card, SubTitle, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import iconLaptop from "../../../../../assets/icons/laptop.svg";
import iconUsers from "../../../../../assets/icons/users-2.svg";
import { Group } from "../../../../../types";
import { useState } from "react";
import constants from "../../../../../constants";

const groupTypes = {
  membership: "la membresía",
  experience: "el taller",
  free_experience: "el evento gratuito",
  community: "la comunidad",
};

interface ComponentProps {
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  subscriptions;
}

const Component = ({ group, groupStates, subscriptions }: ComponentProps) => {
  const [optionSelected, setOptionSelected] = useState<string>("live");
  const dispatch = useDispatch();

  return (
    <Modal name="modal-select-experience-type" width="650" onClose={() => {}}>
      <Title>Elige la modalidad de taller que quieres crear</Title>

      <div style={{ height: 40 }} />

      <Center horizontal="center" vertical="center" gap={20}>
        <Card
          onClick={() => setOptionSelected("live")}
          className={`${optionSelected === "live" ? "active" : ""}`}
        >
          <Center horizontal="center" vertical="center" gap={16}>
            <Icon icon={iconUsers} color="black" size="48px" />
            <span>
              <SubTitle>Taller en vivo</SubTitle>
              <Text>
                <span>
                  Crea un taller interactivo en tiempo real con tu comunidad.
                </span>
              </Text>
            </span>
          </Center>
        </Card>

        <Card
          onClick={() => setOptionSelected("recorded")}
          className={`${optionSelected === "recorded" ? "active" : ""}`}
        >
          <Center horizontal="center" vertical="center" gap={16}>
            <Icon icon={iconLaptop} color="black" size="48px" />
            <span>
              <SubTitle>Taller pregrabado</SubTitle>
              <Text>
                <span>
                  Comparte tus conocimientos a través de talleres grabados.
                </span>
              </Text>
            </span>
          </Center>
        </Card>
      </Center>

      <div style={{ height: 40 }} />

      <Center horizontal="right" vertical="center" gap={16}>
        <Button
          options={{
            type: "outline",
            skin: "purple",
            size: "lg",
          }}
          onClick={() => dispatch(hideModal())}
        >
          Cancelar
        </Button>

        <Link
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
          }}
          onClick={() => dispatch(hideModal())}
          href={
            constants.urls.dashboard.newExperience.url +
            `?r=${optionSelected === "recorded" ? "t" : "f"}`
          }
        >
          Continuar
        </Link>
      </Center>
    </Modal>
  );
};

const state = ({ groupStore, userStore }) => {
  const { data: group, states: groupStates } = groupStore.group;
  const { data: subscriptions } = userStore.subscriptions;

  return {
    group,
    groupStates,
    subscriptions,
  };
};

export default connect(state)(Component);
