import {
  Tabs,
  Tab,
  Link,
  HorizontalScroll,
} from "../../../../../pages/Dashboard/components/styles";
import { connect } from "react-redux";

interface ComponentProps {
  active: string;
  isExpert: boolean;
}

const Component = ({ active, isExpert }: ComponentProps) => {
  return (
    <HorizontalScroll>
      <Tabs>
        <Tab>
          <Link to="/profile/my-account" active={active === "profile"}>
            Perfíl
          </Link>
        </Tab>
        <Tab>
            <Link to={`/profile/manage-subscriptions`} active={active === "manage-subscriptions"}>
              Administrar suscripciones
            </Link>
        </Tab>
        {isExpert && (
          <>
            <Tab>
              <Link
                to={`/profile/notifications`}
                active={active === "notifications"}
              >
                Notificaciones
              </Link>
            </Tab>
            <Tab>
              <Link
                to={`/profile/gateways`}
                active={active === "gateways"}
              >
                Pasarelas de pago
              </Link>
            </Tab>
            <Tab>
              <Link to={`/profile/analytics`} active={active === "analytics"}>
                Analytics
              </Link>
            </Tab>
          </>
        )}
      </Tabs>
    </HorizontalScroll>
  );
};

const state = ({ communityStore }) => {
  const { data: community } = communityStore.community;

  return {
    community,
  };
};

export default connect(state)(Component);
