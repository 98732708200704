import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  .grid-big {
    display: grid;
    gap: 16px;
    grid-auto-rows: 222px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
`;

export const CardsGrid = styled("div")`
padding: 10px 0px;
  display: grid;
  gap: 16px;
  grid-auto-rows: 222px;
  grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));

  @media(min-width: 768px){
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  @media(min-width: 1024px){
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
`;

export const Title = styled("h3")`
  margin: 0px;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SessionCardSkeleton = styled("div")`
  background-color: #e0e4ea;
  border-radius: 14px;
`;