import { createRef, useEffect, useState } from "react";
import {
  Acordeon,
  Item,
  Question,
  Chevron,
  Answer,
  AnswerContent,
} from "./styles";
import chevron from "./chevron.svg";

const Component = ({ items, ...rest }: any) => {
  const [refs, setRefts] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState<number | undefined>(undefined);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (items) {
      setRefts((Refs) => items.map((_, i) => Refs[i] || createRef()));
    }
  }, [items]);

  const toggle = (index: number, ref) => {
    if (isCollapsed !== index) {
      setIsCollapsed(index);
      setHeight(ref.current.scrollHeight);
    } else {
      setIsCollapsed(undefined);
      setHeight(0);
    }
  };

  return (
    <Acordeon {...rest}>
      {items?.map((item: any, index: number) => {
        const elementCollapsed = isCollapsed !== index;
        return (
          <Item
            key={`faq-${index}`}
            isCollapsed={elementCollapsed}
            onClick={() => toggle(index, refs[index])}
          >
            <Question isCollapsed={elementCollapsed}>
              {item.question}

              <Chevron
                src={chevron}
                isCollapsed={elementCollapsed}
                width="30px"
                height="30px"
              />
            </Question>

            <Answer
              ref={refs[index]}
              isCollapsed={elementCollapsed}
              height={height}
            >
              <AnswerContent>{item.answer}</AnswerContent>
            </Answer>
          </Item>
        );
      })}
    </Acordeon>
  );
};

export default Component;
