import { GetPostsOutDto, Post } from "../../../types/post";

export const GET_FEATURED_POSTS = "GET_FEATURED_POSTS";
export const GET_FEATURED_POSTS_SUCCESS = "GET_FEATURED_POSTS_SUCCESS";
export const GET_FEATURED_POSTS_ERROR = "GET_FEATURED_POSTS_ERROR";
export const UPDATE_GET_FEATURED_POSTS = "UPDATE_GET_FEATURED_POSTS";
export const RESET_GET_FEATURED_POSTS = "RESET_GET_FEATURED_POSTS";

export interface GetFeaturedPosts {
  type: typeof GET_FEATURED_POSTS;
  payload: GetPostsOutDto;
}

export interface UpdateGetFeaturedPosts {
  type: typeof UPDATE_GET_FEATURED_POSTS;
  payload: Post[];
}

export interface GetFeaturedPostsError {
  type: typeof GET_FEATURED_POSTS_ERROR;
  payload: boolean | string;
}

export interface GetFeaturedPostsSuccess {
  type: typeof GET_FEATURED_POSTS_SUCCESS;
  payload: Post[];
}

export interface ResetGetFeaturedPosts {
  type: typeof RESET_GET_FEATURED_POSTS;
  payload: null;
}

export interface State {
  data: Post[] | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetFeaturedPosts
  | GetFeaturedPostsError
  | GetFeaturedPostsSuccess
  | UpdateGetFeaturedPosts
  | ResetGetFeaturedPosts;
