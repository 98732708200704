import { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import Button from "../../../../../../components/Button";
import Icon from "../../../../../../components/Icon";
import Input from "../../../../../../components/FormikForm/Input";
import Textarea from "../../../../../../components/FormikForm/TextArea";
import DeleteReviewIcon from "../../../../../../assets/icons/delete-circle-cross.svg";

import { deleteReview } from "../../../../../../store/actions/group";

import {
  Delete,
  Edit,
  Message,
  Name,
  Occupation,
  Card,
  Cards
} from "./styles";


export const schema = Yup.object().shape({
  id: Yup.number().nullable(),
  leading: Yup.string().nullable(),
  group: Yup.number().nullable(),
  author_name: Yup.string().required("Debes indicar el nombre del autor."),
  occupation: Yup.string().nullable(),
  message: Yup.string().required("Debes indicar el mensaje."),
});

export const initialValues = {
  author_name: '',
  occupation: '',
  message: '',
}

interface ComponentProps {
  values;
  errors;
  touched;
  setFieldValue,
  setFieldError,
  setFieldTouched
}

const Component = ({
  values: outerFormValues,
  errors: outerFormErrors,
  touched: outerFormTouched,
  setFieldValue,
  setFieldError,
  setFieldTouched
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState(false)
  const [selectedReview, setSelectedReview] = useState(initialValues);

  const onSubmit = ({ values, actions }) => {
    const reviews = outerFormValues.reviews;

    const newReviewsArray = [...(reviews ? reviews : []), {
      ...selectedReview,
      author_name: values.author_name,
      occupation: values.occupation,
      message: values.message
    }];

    setFieldValue('reviews', newReviewsArray);
    actions.resetForm()
  };

  const removeReview = (removedReviewIndex) => {
    const reviews = outerFormValues.reviews;
    const newReviewsArray = reviews.filter(
      (_review, index) => index !== removedReviewIndex
    );
    setFieldValue('reviews', newReviewsArray);
  };

  return (
    <>
      {!!outerFormValues?.reviews &&
        outerFormValues?.reviews.length > 0 && (
          <Cards>
            {outerFormValues?.reviews.map((review, index) => (
              <Card>
                {!!review?.id && !!review?.group && (
                  <>
                    <input
                      type="hidden"
                      value={review.id}
                      id={`reviews[${index}][id]`}
                    />
                    <input
                      type="hidden"
                      value={review.group}
                      id={`reviews[${index}][group]`}
                    />
                  </>
                )}

                <input
                  type="hidden"
                  value={review.author_name}
                  id={`reviews[${index}][author_name]`}
                />
                <input
                  type="hidden"
                  value={review.message}
                  id={`reviews[${index}][message]`}
                />
                <input
                  type="hidden"
                  value={review.occupation}
                  id={`reviews[${index}][occupation]`}
                />

                <Delete
                  type="button"
                  onClick={() => {
                    !!review?.id && dispatch(deleteReview(review.id));
                    removeReview(index);
                  }}
                >
                  <Icon icon={DeleteReviewIcon} size="12px" color="black" />
                </Delete>

                <Edit
                  type="button"
                  onClick={() => {
                    setSelectedReview(review);
                  }}
                />

                <Message>{review.message}</Message>
                <Name>{review.author_name}</Name>
                <Occupation>{review.occupation}</Occupation>
              </Card>
            ))}
          </Cards>
        )}

      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          if (onSubmit) onSubmit({ values, actions });
        }}
        validateOnChange={false}
        validateOnBlur={formSubmmited}
        validationSchema={schema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <form
              className="theme-form"
              onSubmit={(event) => {
                setFormSubmmited(true);
                handleSubmit(event);
              }}
              id="new-review-form"
            >
              <Input
                name="author_name"
                error={errors['author_name']}
                touched={touched['author_name']}
                value={values.author_name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Nombre del autor de la reseña"
                options={{
                  marginBottom: 10
                }}
              />

              <Input
                name="occupation"
                error={errors['occupation']}
                touched={touched['occupation']}
                value={values.occupation}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Ocupación o profesión del autor"
                options={{
                  marginBottom: 10
                }}
              />

              <Textarea
                name="message"
                error={errors['message']}
                touched={touched['message']}
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Reseña"
                maxLength={250}
                options={{
                  marginBottom: 10,
                  minHeight: 100
                }}
              />

              <Button
                type="submit"
                options={{
                  type: "outline",
                  size: "lg",
                  skin: "purple",
                  block: true,
                }}
                // disabled={!authorName || !message || !occupation}
                onClick={handleSubmit}
              >
                Agregar reseña
              </Button>
            </form>
          )
        }}
      </Formik>
    </>
  );
};

export default Component;
