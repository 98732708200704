import { button } from "./button";
import { form } from "./form";
import { link } from "./links";
import { bullet } from "./bullet";
import { footer } from "./footer";
import { howItsWorks } from "./howItsWorks"

export const components = {
  button,
  form,
  link,
  bullet,
  footer,
  howItsWorks
};
