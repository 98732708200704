import { DiscountDto, NewDiscountOutDto } from "../../../types";

export const NEW_DISCOUNT = "NEW_DISCOUNT";
export const NEW_DISCOUNT_SUCCESS = "NEW_DISCOUNT_SUCCESS";
export const NEW_DISCOUNT_ERROR = "NEW_DISCOUNT_ERROR";
export const RESET_NEW_DISCOUNT = "RESET_NEW_DISCOUNT";

export interface NewDiscount {
  type: typeof NEW_DISCOUNT;
  payload: NewDiscountOutDto;
}

export interface NewDiscountSuccess {
  type: typeof NEW_DISCOUNT_SUCCESS;
  payload: DiscountDto;
}

export interface NewDiscountError {
  type: typeof NEW_DISCOUNT_ERROR;
  payload: string | boolean;
}

export interface ResetNewDiscount {
  type: typeof RESET_NEW_DISCOUNT;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | NewDiscount
  | NewDiscountSuccess
  | NewDiscountError
  | ResetNewDiscount;
