import { Tabs, Tab, Link, HorizontalScroll, NewFeatureLabel} from "../../../components/styles";

import Grid from "../../../../../components/Grid";
import { connect } from "react-redux";

interface ComponentProps {
  groupInformation;
  active: string;
  hiddenTabs?: (
    | "home"
    | "modules"
    | "library"
    | "sessions"
    | "members"
    | "surveys"
    | "price"
    | "configuration"
  )[];
}

const Component = ({
  groupInformation,
  active,
  hiddenTabs = [],
}: ComponentProps) => {
  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <HorizontalScroll>
            <Tabs>
              {!hiddenTabs?.includes("home") && (
                <Tab>
                  <Link
                    to={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}`}
                    active={active === "home"}
                  >
                    Panel general
                  </Link>
                </Tab>
              )}
              {!hiddenTabs?.includes("modules") && (
                <Tab>
                  <Link
                    to={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/modules`}
                    active={active === "modules"}
                  >
                    Modulos
                  </Link>
                </Tab>
              )}
              {!hiddenTabs?.includes("library") && (
                <Tab>
                  <Link
                    to={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/posts/`}
                    active={active === "library"}
                  >
                    Biblioteca
                  </Link>
                </Tab>
              )}
              {!hiddenTabs?.includes("sessions") && (
                <Tab>
                  <Link
                    to={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/sessions/`}
                    active={active === "sessions"}
                  >
                    Encuentros
                  </Link>
                </Tab>
              )}
              {!hiddenTabs?.includes("members") && (
                <Tab>
                  <Link
                    to={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/members`}
                    active={active === "members"}
                  >
                    Miembros
                  </Link>
                </Tab>
              )}
              {!hiddenTabs?.includes("surveys") && (
                <Tab>
                  <Link
                    to={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/surveys`}
                    active={active === "surveys"}
                  >
                    Encuestas
                  </Link>
                </Tab>
              )}
              {!hiddenTabs?.includes("price") && (
                <Tab>
                  <Link
                    to={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/price`}
                    active={active === "price"}
                  >
                    Precio
                  </Link>
                </Tab>
              )}
              {!hiddenTabs?.includes("configuration") && (
                <Tab>
                  <Link
                    to={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/configuration`}
                    active={active === "configuration"}
                  >
                    Configuración
                    <NewFeatureLabel>Nuevo</NewFeatureLabel>
                  </Link>
                </Tab>
              )}
            </Tabs>
          </HorizontalScroll>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

const state = ({ groupStore }) => {
  const { data: groupInformation } = groupStore.group;

  return {
    groupInformation,
  };
};

export default connect(state)(Component);
