import {
  Card,
  Information,
  ExpertName,
  Title,
  Date,
  Day,
  Number,
  Sonar,
  Hour,
  When,
  Cover,
  StyledNextDays
} from "./styles";
import Link from "../../../../../components/Link";
import { Group, SessionInDto, User } from "../../../../../types";
import { useEffect, useState } from "react";
import {
  DateSpliteDto,
  dateSpliter,
  sessionInProgress,
  timeToSession,
  sessionExpired,
  convertFloatHourToMinutes
} from "../../../../../helpers/dates-hanlder";
import { connect } from "react-redux";
import background1 from "../../../../../assets/backgrounds/membership/background-1.svg";
import background2 from "../../../../../assets/backgrounds/membership/background-4.svg";
import background3 from "../../../../../assets/backgrounds/membership/background-5.svg";

const backgrounds = [background1, background2, background3];
const randomBg = Math.floor(Math.random() * backgrounds.length);
interface PropsInterface {
  //bigCard?: boolean;
  groupInformation: Group;
  userInformation: User;
  session: SessionInDto;
  link: string;
}

const Component = ({
  groupInformation,
  userInformation,
  session,
  link,
  // bigCard,
}: PropsInterface) => {
  const [date, setDate] = useState<DateSpliteDto>();
  const [ownGroup, setOwnGroup] = useState(false);
  const [isInProgress, setIsInProgress] = useState<boolean>();
  const [timeLeftToSession, setTimeLeftToSession] = useState<string>('');
  const [useExpertInfo, setUseExpertInfo] = useState<boolean>(false);
  const [isExpired, setIsExpired] = useState<boolean>();
  const [hastRecordedSessionUrl, setHastRecordedSessionUrl] =
    useState<boolean>();

  useEffect(() => {
    setDate(dateSpliter(session.session_datetime));
    const minutes = session?.duration_type === "hour" ? convertFloatHourToMinutes(session?.duration) : session?.duration
    setIsInProgress(sessionInProgress(session.session_datetime, minutes));
    setUseExpertInfo(session?.use_profile_info);
    setIsExpired(sessionExpired(session.session_datetime));
    setHastRecordedSessionUrl(!!session.recorded_session_url);
    setTimeLeftToSession(timeToSession(session?.session_datetime));
  }, [session]);

  useEffect(() => {
    if (!!groupInformation && !!userInformation) {
      setOwnGroup(groupInformation?.expert?.id === userInformation?.expert?.id);
    }
  }, [groupInformation, userInformation]);

  useEffect(() => { }, [date]);

  return (
    <Cover to={link}>
      <>
        <Card isInProgress={isInProgress} isExpired={isExpired}>
          <Date backgroundImage={session?.cover_pic?.url || backgrounds[randomBg]}>
            <Day>{date?.month.name.short}</Day>
            <Number>{date?.day.number}</Number>
          </Date>

          <Information>
            <When>
              {isInProgress ? (
                <>
                  <Sonar />
                  Ahora
                </>
              ) : (
                <Hour>{`${date?.day.name.short} ${date?.day.number} de  ${date?.month.name.short}, ${date?.hours}:${date?.minutes}hs (${date?.timeZone?.country?.substring(0, 3)})`}</Hour>
              )}
            </When>

            <div style={{ height: "7px" }} />

            <Title>{session.title}</Title>

            <div style={{ height: "11px" }} />


            {!isInProgress && !timeLeftToSession.startsWith("-") && (
              <StyledNextDays>Dentro de {timeLeftToSession}</StyledNextDays>
            )}


            {/* Agregar el boton participar */}
          </Information>
        </Card>
        {/* 
        {ownGroup && isExpired && !hastRecordedSessionUrl && (
          <>
            <div style={{ height: "6px" }} />
            <Link
              href={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/sessions/${session?.id}`}
              options={{
                type: "filled",
                skin: "white",
                size: "lg",
                block: true,
              }}
            >
              Subir grabación
            </Link>
          </>
        )} */}
      </>
    </Cover>
  );
};

const state = ({ groupStore, userStore }) => {
  const { data: userInformation } = userStore.information;
  const { group } = groupStore;
  const { data: groupInformation, states: groupStates } = group;

  return {
    groupInformation,
    groupStates,
    userInformation,
  };
};

export default connect(state)(Component);
