import { connect, useDispatch } from "react-redux";
import { initialValues, schema } from "../../../../../../../constants/forms/coupon";
import Radio from "../../../../../../../components/FormikForm/Radio";
import Input from "../../../../../../../components/FormikForm/Input";
import ReactSelect from "../../../../../../../components/FormikForm/ReactSelect";
import { Group, PlanDto, User } from "../../../../../../../types";
import {
  newDiscount,
  resetNewDiscount,
} from "../../../../../../../store/actions/discount";
import {
  inputDate,
  inputDateTimeLocal,
  setToZeroTimeZone,
} from "../../../../../../../helpers/dates-hanlder";
import { useMemo } from "react";
import moment from "moment";

interface ComponentProps {
  userInformation: User;
  userGroups: Group[];
  userGroupsStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  newDiscountStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  isLoading: boolean
}

interface Option {
  label: string;
  value: string | number;
}

const groupTypes = [
  { label: "Experiencia", value: "experience" },
  { label: "Membresía", value: "membership" },
];

interface ComponentProps {
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  isLoading: boolean
  selectedPlan: PlanDto
  selectedPlanStates
}

const Component = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  isLoading,
  selectedPlan,
  selectedPlanStates
}: ComponentProps) => {

  return (
    <>
      {values['valid_till'] !== null && (
        <Input
          name="valid_till"
          error={errors["valid_till"]}
          touched={touched["valid_till"]}
          value={inputDateTimeLocal(values["valid_till"])}
          type="datetime-local"
          placeholder="dd/mm/aa"
          min={moment().format("YYYY-MM-DDThh:mm")}
          disabled={values["valid_till"] == null || isLoading}
          onChange={handleChange}
          onBlur={handleBlur}
          options={{
            label: "Descuento válido hasta",
            explainText: "Indica con fecha y hora hasta cuando este descuento se encontrara vigente.",
            marginBottom: 40,
          }}
        />
      )}

      <ReactSelect
        name="status"
        error={errors["status"]}
        touched={touched["status"]}
        defaultValue={[{ value: "active", label: "Activa" }]}
        items={[{ value: "active", label: "Activa" }, { value: "archived", label: "Inactiva" }]}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isLoading}
        options={{
          label: "Estado",
          marginBottom: 40,
          explainText: "Define del descuento. Recuerda que solo puedes tener un descuento activo a la vez.",
        }}
      />
    </>
  );
};

const state = ({ userStore, discountStore, planStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: userGroups, states: userGroupsStates } = userStore.groups;
  const { states: newDiscountStates } = discountStore.newDiscount;
  const { data: selectedPlan, states: selectedPlanStates } = planStore.selectedPlan;

  return {
    userInformation,
    userGroups,
    userGroupsStates,
    newDiscountStates,
    selectedPlan,
    selectedPlanStates,
  };
};

export default connect(state)(Component);
