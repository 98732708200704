import Button from '../../../../../../../components/Button'
import Icon from '../../../../../../../components/Icon'
import {
  Card,
  Title,
  Text,
} from './styles'
import usersIcon from '../../../../../../../assets/icons/users-2.svg'
import { useDispatch } from 'react-redux'
import { Action, Dispatch } from 'redux'
import { showModal } from '../../../../../../../store/actions/modal'
import { Center } from '../../../../../../../components/StyledComponents'

const Component = () => {
  const dispatch = useDispatch<Dispatch<Action>>()

  const handleClick = () => {
    dispatch(showModal('modal-new-welcome-section'))
  }

  return (
    <Card>
      <Title>¡Todavía no has creado la sección de bienvenida!</Title>
      <div style={{ height: 16 }} />
      <Center horizontal='left' vertical='center' gap={10}>
        <Icon icon={usersIcon} size="34px" color="#C4C4C4" />
        <Text>Crea un Onboarding atractivo para brindarle una experiencia única a los nuevos suscriptores de tu membresía. Aquí podrás agregar contenidos que le mostraremos apenas se suscriba y podrá ir completando </Text>
      </Center>
      <div style={{ height: 32 }} />
      <Button
        onClick={handleClick}
        options={{
          skin: 'purple',
          type: 'filled',
          size: 'lg',
        }}
      >
        Crear sección
      </Button>
    </Card>
  )
}

export default Component