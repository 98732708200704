import {
  GroupCard,
  GroupLink,
  GroupCardHeader,
  GroupCardBody,
  GroupCardFooter,
  ExpertName,
  GroupName,
  GroupDescription,
  Cover,
  Avatar,
  Actions,
  Link,
  Copy,
  State,
  GroupTypeIcon
} from "./styles";
import CopyToClipboad from "../CopyToClipboad";
import Icon from "../Icon";
import { Expert, Group } from "../../types";
import constants from "../../constants";

import iconMembership from "../../assets/dashboard-menu/membership.svg";
import pencilIcon from "../../assets/icons/pencil-bold.svg";
import linkIcon from "../../assets/icons/chain-bold.svg";


import iconLaptop from "../../assets/icons/laptop.svg";
import iconUsers from "../../assets/icons/users-2.svg";

import { useMarkdown } from "../../hooks/useMarkdown";
import { getRandomBg } from "../../helpers/randomGroupCover";

interface PropsInterface {
  group: Group;
  expert: Expert | null;
  editLink: string;
  previewLink: string;
  homeGroupLink: string;
}

const getStatus = (group_status, group_type) => {
  const singular = {
    active: group_type === 'membership' ? "activa" : "activo",
    inactive: group_type === 'membership' ? "inactiva" : "inactivo",
    draft: "sin publicar",
  };

  const groupStatus = constants.groupStatus.filter(
    (status) => group_status === status.value
  )[0];

  return singular[groupStatus?.value];
};

const Component = ({
  group,
  expert,
  editLink,
  previewLink,
  homeGroupLink,
}: PropsInterface) => {
  const { typeMap, markMap, jsonStr, textStr } = useMarkdown({
    brief: group?.brief,
  });

  return (
    //
    <GroupCard>
      <Actions>
        <Copy>
          <CopyToClipboad copy={previewLink}>
            <Icon icon={linkIcon} size="15px" color="#6C5CE7" />
          </CopyToClipboad>
        </Copy>

        <Link
          href={editLink}
          target="_self"
          options={{
            skin: "purple",
            size: "sm",
            type: "link",
          }}
        >
          <Icon icon={pencilIcon} size="17px" color="#6C5CE7" />
        </Link>
      </Actions>

      <GroupLink href={homeGroupLink}>
        <GroupCardHeader status={group?.status}>
          <Cover background={group?.cover_pic?.signed_url || getRandomBg()} />

          <Avatar background={expert?.profile_pic?.url} />

          <GroupTypeIcon>
            <Icon icon={group?.group_type === 'membreship' ? iconMembership : group?.recorded ? iconLaptop : iconUsers} size="15px" color="#FEFEFE" />
          </GroupTypeIcon>
        </GroupCardHeader>

        <GroupCardBody>
          <GroupName>{group?.group_name}</GroupName>
          <ExpertName>{group?.creator_name}</ExpertName>
          <div style={{ height: "8px" }} />
          <GroupDescription>{textStr}</GroupDescription>
          <div style={{ height: "10px" }} />
          <State state={group?.status}>{getStatus(group?.status, group?.group_type)}</State>
        </GroupCardBody>
      </GroupLink>
    </GroupCard>
  );
};

export default Component;
