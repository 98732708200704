import { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Transition } from "react-transition-group";
import { Animation, AnimationFixed } from "../Animation";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../store/actions/modal";

import play from "../../assets/player/play.svg";
import pause from "../../assets/player/pause.svg";

import {
  ModalBackdrop,
  Modal,
  ModalDialog,
  ModalContent,
  ModalHeader,
  ModalBody,
  Close,
  Controls,
  PlayButton,
  PauseButton,
  Video,
  Image,
  CloseImg,
} from "./styles";

import closeIcon from "../../assets/icons/close-white.svg";

export interface ModalProps {
  animation?: string;
  video: any;
  mime?: string;
  name: string;
  controls?;
  onClose?: () => void;
  visibleModal?: string | null;
}

export const Component = ({
  animation = "fadeIn",
  video,
  mime = "video/mp4",
  name,
  onClose,
  visibleModal,
  controls,
}: ModalProps) => {
  const modalDOM = document.getElementById("modal") as HTMLElement;
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoSizes, setVideoSizes] = useState<{
    aspectRatio: number;
    width: number;
    height: number;
  }>();
  const videoRef = useRef<any>();
  const controlsRef = useRef<any>();

  const handleLoad = () => {
    const videoElement = videoRef?.current;
    if (videoElement) {
      handlePlay();

      if (videoElement.videoWidth && videoElement.videoHeight) {
        const aspectRatio =
          videoElement?.videoWidth / videoElement?.videoHeight;
        setVideoSizes({
          aspectRatio: aspectRatio,
          width: videoElement.videoWidth,
          height: videoElement?.videoHeight,
        });
      }
    }
  };

  const handleClose = () => {
    dispatch(hideModal());
    if (onClose) {
      onClose();
    }
  };

  const setBodyStyles = () => {
    if (isVisible) {
      document.body.style.height = "100%";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    }
  };

  const handlePlay = () => {
    if (!isPlaying) {
      setIsPlaying(true);
      videoRef?.current?.play();
    } else {
      setIsPlaying(false);
      videoRef?.current?.pause();
    }
  };

  useEffect(() => {
    setIsVisible(name === visibleModal);
  }, [name, visibleModal]);

  useEffect(() => {
    setBodyStyles();
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <Transition in={isVisible} timeout={350} mountOnEnter>
      {(state) => (
        <>
          <AnimationFixed name={animation} className={`animation-${state}`}>
            <Modal>
              <ModalDialog>
                <ModalContent>
                  <ModalHeader className="header">
                    <Close onClick={() => handleClose()}>
                      <CloseImg
                        src={closeIcon}
                        alt="X"
                        width="10px"
                        height="10px"
                      />
                    </Close>
                  </ModalHeader>

                  <ModalBody>
                    <Video
                      onContextMenu={e => e.preventDefault()}
                      controlsList="nodownload"
                      ref={videoRef}
                      preload="metadata"
                      width="100%"
                      controls={controls}
                      onLoadedMetadata={handleLoad}
                      style={{
                        ...(videoSizes && {
                          maxHeight:
                            videoSizes?.height > 640
                              ? videoSizes?.height / 2
                              : videoSizes?.height,
                          maxWidth:
                            videoSizes?.width > 1280
                              ? videoSizes?.width / 2
                              : videoSizes?.width,
                        }),
                      }}
                    >
                      <source
                        src={video}
                        type={mime}
                        onLoadedMetadata={handleLoad}
                      />
                      Tu navegador no soporta Video.
                    </Video>
                  </ModalBody>

                  {!controls && (
                    <Controls className="controls">
                      <PlayButton onClick={handlePlay}>
                        {!isPlaying ? (
                          <Image
                            src={play}
                            alt="Play"
                            width="25px"
                            height="25px"
                          />
                        ) : (
                          <Image
                            src={pause}
                            alt="Pause"
                            width="25px"
                            height="25px"
                          />
                        )}
                      </PlayButton>
                    </Controls>
                  )}
                </ModalContent>
              </ModalDialog>
            </Modal>
          </AnimationFixed>

          <Animation name="fadeIn" className={`animation-${state}`}>
            <ModalBackdrop onClick={() => handleClose()} />
          </Animation>
        </>
      )}
    </Transition>,
    modalDOM
  );
};

const state = ({ modalStore }) => ({ ...modalStore });

export default connect(state)(Component);
