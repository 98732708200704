import {
  initialState,
  Actions,
  State,
  GET_GROUP_METRICS,
  GET_GROUP_METRICS_SUCCESS,
  GET_GROUP_METRICS_ERROR,
} from "../../types/group/get-metrics";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_GROUP_METRICS:
      return {
        ...state,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case GET_GROUP_METRICS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case GET_GROUP_METRICS_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: true,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default reducers;
