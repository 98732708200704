import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import { Formik } from "formik";
import {
    partialSchema,
} from "../../../../../../constants/forms/invitations";
import Submit from "../../../../../../components/FormikForm/Submit";
import Radio from "../../../../../../components/FormikForm/Radio";
import Input from "../../../../../../components/FormikForm/Input";
import dateHelpers from "../../../../../../helpers/dates-hanlder"
import Button from "../../../../../../components/Button";
import { Title, AlignEnd } from "./styles"
import { editSubscription, getSubscription, resetEditSubscription, resetGetSubscription } from "../../../../../../store/actions/subscription-admin";
import { hideModal } from "../../../../../../store/actions/modal";
import { Subscription } from "../../../../../../types";
import { getGroupSubscribers } from "../../../../../../store/actions/group";
import { Group } from "../../../../../../types/group";
import Snackbar from "../../../../../../components/Snackbar"

const options = [
    { label: "Indefinido", value: 'indefinido' },
    { label: "Fecha especifica", value: 'fecha-especifica' },
]

interface ComponentProps {
    subscriptionId: number | null,
    subscription: Subscription | null;
    editSubscriptionStates: {
        loading: boolean;
        success: boolean;
        error: string | boolean;
    };
    subscriptionStates: {
        loading: boolean;
        success: boolean;
        error: string | boolean;
    };
    group: Group;
}

const Component = ({
    subscriptionId,
    subscription,
    editSubscriptionStates,
    subscriptionStates,
    group
}: ComponentProps) => {
    const dispatch = useDispatch()
    const [formSubmmited, setFormSubmmited] = useState(false);

    useEffect(() => {
        if (subscriptionId)
            dispatch(getSubscription(subscriptionId))
    }, [subscriptionId])


    useEffect(() => {
        if (editSubscriptionStates.success) {
            setTimeout(() => {
                //dispatch(getGroupSubscribers({ page: 1, id: group?.id, filters: [] }));
                //dispatch(resetEditSubscription())
                //dispatch(resetGetSubscription())
                dispatch(hideModal())
            }, 500)
        }
    }, [editSubscriptionStates])


    const onSubmit = (values, actions) => {
        dispatch(editSubscription({
            ...values,
            expire_at: values?.expire_at ? dateHelpers.setToZeroTimeZone(values.expire_at) : null,
            id: subscription?.id
        }));
    };


    return (
        <>
            <Modal name="edit-member-modal" width="700" showClose={true}>
                {subscriptionStates.success ? (
                    <>
                        <Title>Editar Suscriptor {">"} {subscription?.subscriber?.email}</Title>

                        <div style={{ height: 30 }} />

                        <Formik
                            initialValues={{
                                expire_at_type: (!subscription?.expire_at || subscription?.expire_at === "0" || !dateHelpers.isFutureDate(dateHelpers.timestampToDate(Number(subscription?.expire_at)))) ? 'indefinido' : "fecha-especifica",
                                expire_at: subscription?.expire_at && subscription?.expire_at !== "0" && dateHelpers.isFutureDate(dateHelpers.timestampToDate(Number(subscription?.expire_at))) ? dateHelpers.timestampToDate(Number(subscription?.expire_at)) : null
                            }}
                            validationSchema={partialSchema}
                            onSubmit={(values, actions) => {
                                if (onSubmit) onSubmit(values, actions);
                            }}
                            validateOnChange={false}
                            validateOnBlur={formSubmmited}
                            enableReinitialize
                        >
                            {({
                                dirty,
                                touched,
                                errors,
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                            }) => {
                                return (
                                    <form
                                        className="theme-form"
                                        onSubmit={(event) => {
                                            setFormSubmmited(true);
                                            handleSubmit(event);
                                        }}
                                        id="edit-subscription-form"
                                    >
                                        <Radio
                                            name="expire_at_type"
                                            error={errors["expire_at_type"]}
                                            touched={touched["expire_at_type"]}
                                            items={options}
                                            value={values["expire_at_type"]}
                                            onChange={(event) => {
                                                handleChange(event);
                                                if (
                                                    event.currentTarget.value === "indefinido" && !!values.expire_at_type
                                                ) {
                                                    setFieldValue("expire_at", undefined);
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            options={{
                                                label:
                                                    "Permanencia hasta",
                                                helperText: "Edita hasta cuando deseas que el suscriptor pueda acceder a todo el contenido.",
                                                marginBottom: 20,
                                                vertical: true,
                                            }}
                                        />

                                        {values["expire_at_type"] === 'fecha-especifica' && (
                                            <Input
                                                name="expire_at"
                                                error={errors["expire_at"]}
                                                touched={touched["expire_at"]}
                                                type="date"
                                                placeholder="dd/mm/aaaa"
                                                value={values?.expire_at && values?.expire_at !== "0" ? values["expire_at"] : undefined}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                options={{
                                                    label: "Permanencia válida hasta",
                                                    helperText:
                                                        "Selecciona hasta que fecha tendra acceso el suscriptor.",
                                                    marginBottom: 20,
                                                }}
                                            />
                                        )}

                                        <div style={{ height: 20 }} />


                                        <AlignEnd>
                                            <Button
                                                options={{
                                                    type: "outline",
                                                    skin: "purple",
                                                    size: "lg",
                                                }}
                                                style={{ marginRight: "16px" }}
                                                onClick={() => dispatch(hideModal())}
                                            >
                                                Cancelar
                                            </Button>
                                            <Submit
                                                form="edit-subscription-form"
                                                disabled={!dirty}
                                                isSubmmiting={editSubscriptionStates.loading}
                                                options={{
                                                    type: "filled",
                                                    skin: "purple",
                                                    size: "lg",
                                                }}
                                            >
                                                Guardar cambios
                                            </Submit>
                                        </AlignEnd>
                                    </form>
                                )
                            }}
                        </Formik>
                    </>
                ) : (
                    <p>Cargando información del suscriptor...</p>
                )}


            </Modal>

            <Snackbar
                visible={editSubscriptionStates.success || !!editSubscriptionStates.error}
                options={{
                    time: 2000,
                    position: "center",
                    type: editSubscriptionStates.success ? "success" : "error",
                }}
                onHide={() => dispatch(resetEditSubscription())}
            >
                {editSubscriptionStates.success
                    ? "Miembro editado exitosamente"
                    : "Se ha producido un error."}
            </Snackbar>
        </>
    )
}


const state = ({ groupStore, subscriptionAdminStore }) => {
    const { states: editSubscriptionStates } = subscriptionAdminStore.editSubscription;
    const { data: subscription, states: subscriptionStates } = subscriptionAdminStore.subscriptionAdmin;
    const { data: group } = groupStore.group;

    return {
        editSubscriptionStates,
        group,
        subscription,
        subscriptionStates
    };
};

export default connect(state)(Component);