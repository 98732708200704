import { Title, Message, ProfileImage, Column, Link, SmallMessage } from "./styles";
import { connect } from "react-redux";
import userAvatar from "../../../../assets/avatar.svg";
import { useEffect, useMemo, useState } from 'react';
import { getUrlParamByName } from "../../../../helpers/get-url-param";
import Image from '../../../../components/Image'
import mercadoPagoImg from '../../../../assets/mercado-pago.png'
import shieldImg from '../../../../assets/shield.png'
import { useTheme } from 'styled-components';

const groupTypes = {
  community: "comunidad",
  free_experience: "taller",
  experience: "taller",
  membership: "membresía",
};

const Component = ({ group, user }) => {
  const theme = useTheme() as { logo: { small: string, big: string } };
  const [countdown, setCountdown] = useState(5);

  const groupSlug = useMemo(() => getUrlParamByName('groupSlug'), [])

  const text = useMemo(() => {
    const preffix = ['community', 'membership'].includes(group?.group_type) ? 'a la' : 'al';
    return `Ir ${preffix} ${groupTypes[group?.group_type]}`
  }, [group])

  const profilePicture = useMemo(() => {
    return group?.expert?.profile_pic?.url || userAvatar
  }, [group])

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      window.location.href = `/group/${groupSlug}/survey`;
    }
  }, [countdown, groupSlug]);

  return (
    <Column>
      <div style={{ gap: 24, display: 'flex', justifyContent: 'center', width: '100%', maxWidth: 410 }}>
        <Image
          src={theme?.logo?.big}
          style={{ display: 'block' }}
          width="107px"
          height="auto"
          className="logo-desktop"
          alt="Klouser"
          lazy
        />

        <div style={{ height: 30, width: 1, backgroundColor: '#B1C6E4' }} />

        <Image
          src={mercadoPagoImg}
          style={{ display: 'block' }}
          width="101px"
          height="auto"
          className="logo-desktop"
          alt="Mercado pago"
          lazy
        />
      </div>

      <div style={{ height: 52 }} />

      <div style={{ position: 'relative' }}>
        <ProfileImage loading="lazy" src={profilePicture} alt={"Profile Image"} />
        <Image
          src={shieldImg}
          style={{
            display: "block",
            position: "absolute",
            left: "100%",
            top: "50%",
            transform: "translate(-26px, -28px)",
          }}
          width="57px"
          height="auto"
          alt="Shield"
          lazy
        />
      </div>

      <div style={{ height: 42 }} />
      <Title>Estamos procesando tu pago</Title>
      <div style={{ height: 42 }} />
      <Message><strong style={{ fontWeight: 500 }}>La validación puede demorar algunas horas por políticas de Mercado Pago.</strong><br /><br />Mientras tanto, ya tienes acceso a {group?.group_name}. ¡A disfrutar!</Message>
      <div style={{ height: 42 }} />
      <Link options={{ type: 'filled', size: 'lg', skin: 'purple', block: true }} href={`/group/${groupSlug}/survey`}>{text}</Link>
      <div style={{ height: 22 }} />
      <SmallMessage>Toca "{text}" o aguarda ({countdown} seg.)<br />y seras redirigido </SmallMessage>
    </Column>
  )
}

const state = ({ groupStore, userStore }) => {
  const { data: group } = groupStore?.group;
  const { data: user } = userStore?.information

  return { group, user };
};

export default connect(state)(Component);