import styled from "styled-components";

export const NoMoreItems = styled("div")`
  text-align: center;
  margin: 50px 0px;
  font-weight: bold;
`;

export const CardsGrid = styled("div")`
  display: grid;
  gap: 16px;
  grid-auto-rows: 237px;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
`;