import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import Link from "../../../../components/Link";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../store/actions/modal";
import { Center } from "../../../../components/StyledComponents";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import ProgressBar from "../WelcomeSection/components/ProgressBar";
import { GetWelcomeContentSectionInDto } from "../../../../types/welcome-section";
import { savePreference } from '../../../../store/actions/preferences'
import { useMemo } from "react";
import LogoLoader from "../../../../components/LogoLoader";

interface ComponentProps {
  welcomeContentSection: GetWelcomeContentSectionInDto,
  welcomeContentSectionStates
  showCancel?: boolean
}


const Component = ({ welcomeContentSection, welcomeContentSectionStates, showCancel = false }: ComponentProps) => {
  const { isMobile } = useWindowSize()
  const dispatch = useDispatch();

  const total = useMemo(() => {
    return welcomeContentSection?.welcome_contents?.length
  }, [welcomeContentSection])

  const completed = useMemo(() => {
    return welcomeContentSection?.welcome_contents?.filter(item => item.completed).length;
  }, [welcomeContentSection])

  const progress = useMemo(() => {
    return total ?  Math.floor((completed / total) * 100) : 0
  }, [total, completed])

  return (
    <Modal name="modal-minimize-welcome-section" width="650" onClose={() => { }}>
      <Title style={{ color: "rbga(45,52,54,.75)", textAlign: 'center' }}>
        {welcomeContentSection?.user_finished_welcome_section ? "¿Quieres minimizar esta sección?" : "¿Quieres cerrar esta sección antes de terminarla?"}
      </Title>

      <div style={{ height: 36 }} />

      <Text style={{ textAlign: 'center' }}>
        {welcomeContentSection?.user_finished_welcome_section ? <span style={{ color: "#12B47A", fontWeight: 600 }}>¡Completada con éxito! 🎉</span> : `Solo has completado el ${progress}% 😔​`}
      </Text>

      <div style={{ height: 14 }} />

      <ProgressBar showNumber={false} />

      <div style={{ height: 38 }} />

      <Text style={{ color: "rbga(45,52,54,.75)", textAlign: 'center' }}>
        Podrás volver a encontrar la seccion de bienvenida en la pestaña de destacados del Inicio
      </Text>

      <div style={{ height: 36 }} />

      <Center horizontal="right" vertical="center" gap={8}>
        <Button
          options={{
            type: "outline",
            skin: "purple",
            size: "lg",
            block: isMobile
          }}
          onClick={() => dispatch(hideModal())}
        >
          Volver
        </Button>

        <Button
          onClick={() => {
            dispatch(savePreference({
              preferenceSlug: `minimize-welcome-section-${welcomeContentSection?.id}`,
              preferenceValue: true
            }))

            dispatch(hideModal())
          }}
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
            block: isMobile
          }}
        >
          {!!welcomeContentSection?.user_finished_welcome_section ? "Minimizar" : "Cerrar"}
        </Button>
      </Center>
    </Modal>
  );
};

const state = ({ userStore, groupStore, welcomeContentStore }) => {
  const { isLoggedIn } = userStore;
  const { data: user, states: userStates } =
    userStore.information;
  const { data: welcomeContentSection, states: welcomeContentSectionStates } = welcomeContentStore.welcomeContentSection;
  const { data: group, states: groupStates } = groupStore.group;

  return {
    isLoggedIn,
    user,
    userStates,
    welcomeContentSection,
    welcomeContentSectionStates,
    group,
    groupStates,
  };
};

export default connect(state)(Component);