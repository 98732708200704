import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0px 80px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 90px 0px 50px;
  }
`;
