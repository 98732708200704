import styled from 'styled-components'

export const Card = styled('div')`
  padding: 0px 0px 0px 10px;
  border-left: 4px solid #E7E5FB;

  @media(min-width: 768px){
    padding: 0px 0px 0px 30px;
  }
`

export const ButtonEdit = styled('button')`
  flex-shrink: 0;
  border: 1px solid ${({ theme }) => theme.colors.Primary};
  background: white;
  cursor: pointer;
  border-radius: 100%;
  padding: 0px;
  margin: 0px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .2s ease-in-out;

  &:hover {        
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`

export const Title = styled('div')`
  font-size: 18px;
  font-weight: 600;
`
export const Text = styled('div')`
  font-size: 16px;
`


export const HorizontalScrollWrapper = styled('div')`
  margin: 0px;
`

export const HorizontalScroll = styled('div')`
  overflow-x: scroll;
  display: flex;
  scroll-snap-type: x mandatory;
  padding: 10px;
  scroll-padding: 0 10px;

  ::-webkit-scrollbar {
    display: none;
  }

  & > * {
    scroll-snap-align: start;
    max-width: 300px!important;
    
    &:not(:last-child){
      margin-right: 16px;
    }
  }
`
