import {
  initialState,
  Actions,
  State,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_ERROR,
  RESET_UPDATE_NOTIFICATION,
} from "../../types/notification/update";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case UPDATE_NOTIFICATION_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_UPDATE_NOTIFICATION:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
