import { put, call, takeLatest } from "redux-saga/effects";
import services from "../../services";
import {
  GET_LOCAL_PRICE,
  GET_LOCAL_PRICE_SUCCESS,
  GET_LOCAL_PRICE_ERROR,
} from "../types/currency";

function* getLocalPrice({ payload }): any {
  try {
    const result = yield call(services.currency.getLocalPrice, payload);
    yield put({ type: GET_LOCAL_PRICE_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: GET_LOCAL_PRICE_ERROR, payload: error });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(GET_LOCAL_PRICE, getLocalPrice);
}
