import styled from "styled-components";

export const Card = styled("div") <{
  isInProgress?: boolean;
  isExpired?: boolean;
}>`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background-color: ${({ isInProgress }) =>
    isInProgress ? "#e5e2f7" : "white"};
  box-shadow: 0px 0px 10px rgba(108, 92, 231, 0.15);
  border-radius: 14px;
  overflow: hidden;
  width: 100%;
  max-width: 315px;
  max-height: 205px;
`;

export const Title = styled("h2")`
  margin: 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.Black};
`;

export const Text = styled("p")`
  margin: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  min-height: 51px;
  color: ${({ theme }) => theme.colors.Black};
`;

export const Center = styled("div")`
  display: flex;
  align-items: center;
  gap: 15px;
`;
