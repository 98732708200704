import React, { useEffect, useState } from "react";
import { CSSProperties } from "styled-components";
import { useWindowSize } from "../../hooks/useWindowSize";

interface ComponentProps {
  ratio: "5/4" | "4/3" | "3/2" | "8/5" | "5/3" | "16/9" | "17/9";
  maxWidth: number;
  children;
  style?: CSSProperties
}

const Component = ({ children, ratio = "16/9", maxWidth, ...rest }: ComponentProps) => {
  const { isMobile } = useWindowSize()
  const [aspectRatio, setAspectRatio] = useState<number>(1);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  let interval;

  const getSizes = () => {
    const docWidth = document.body.clientWidth;
    const elementWidth = docWidth > maxWidth ? maxWidth : docWidth;
    setWidth(elementWidth);
    setHeight(elementWidth / aspectRatio);
  };

  const onResize = () => {
    clearTimeout(interval);
    interval = setTimeout(getSizes, 100);
  };

  useEffect(() => {
    const values = ratio.split("/");
    const number = Number(values[0]) / Number(values[1]);
    setAspectRatio(number);
  }, [ratio, isMobile]);

  useEffect(() => {
    getSizes();
  }, [aspectRatio, isMobile]);

  useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <>
      {React.Children.map(children, (child) => {
        if (!child) return null;

        return React.createElement(child.type, {
          ...{
            ...child.props,
            width: width,
            height: height,
            key: child.props.name,
            ...rest
          },
        });
      })}
    </>
  );
};

export default Component;
