import { DiscountDto } from "../../../../types/discount";
import PreviewSidebar from "./components/PreviewSidebar";
import DesactivateSidebar from "./components/DesactivateSidebar";
import EditSidebar from "./components/EditSidebar";
import { useState } from "react";

interface ComponentProps {
  coupon?: DiscountDto;
  onClose?: (data: any) => void;
}

const Component = ({ coupon, onClose }: ComponentProps) => {
  const [showDesactivateCouponSidebar, setShowDesactivateCouponSidebar] =
    useState<boolean>(false);
  const [showEditCouponSidebar, setShowEditCouponSidebar] =
    useState<boolean>(false);
  const [showPreviewCouponSidebar, setShowPreviewCouponSidebar] =
    useState<boolean>(false);

  return (
    <>
      <PreviewSidebar
        coupon={coupon}
        onClose={onClose}
        setShowDesactivateCouponSidebar={setShowDesactivateCouponSidebar}
        setShowEditCouponSidebar={setShowEditCouponSidebar}
      />
      <DesactivateSidebar
        coupon={coupon}
        onClose={setShowDesactivateCouponSidebar}
        isVisible={showDesactivateCouponSidebar}
      />
      <EditSidebar
        coupon={coupon}
        onClose={setShowEditCouponSidebar}
        isVisible={showEditCouponSidebar}
      />
    </>
  );
};

export default Component;
