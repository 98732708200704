import { put, call, all, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NEW_NOTIFICATION,
  NEW_NOTIFICATION_SUCCESS,
  NEW_NOTIFICATION_ERROR,
} from "../types/notification/new";

import {
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_ERROR,
} from "../types/notification/update";

import {
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  GET_ALL_NOTIFICATIONS_ERROR,
} from "../types/notification/all";

import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_ERROR,
} from "../types/notification/one";

function* newNotification({ payload }): any {
  try {
    const { response, error } = yield call(services.notification.new, payload);

    if (error) {
      yield put({ type: NEW_NOTIFICATION_ERROR, payload: error });
    } else {
      yield put({ type: NEW_NOTIFICATION_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: NEW_NOTIFICATION_ERROR,
      payload: "Error al crear una nueva notificación",
    });
  }
}

function* updateNotification({ payload }): any {
  try {
    const { response, error } = yield call(
      services.notification.update,
      payload
    );

    if (error) {
      yield put({ type: UPDATE_NOTIFICATION_ERROR, payload: error });
    } else {
      yield all([
        put({ type: UPDATE_NOTIFICATION_SUCCESS, payload: response }),
        put({ type: GET_ALL_NOTIFICATIONS_SUCCESS, payload: response }),
      ]);
    }
  } catch (error) {
    yield put({
      type: UPDATE_NOTIFICATION_ERROR,
      payload: "Error al actualzar notificación",
    });
  }
}

function* getAllNotifications(): any {
  try {
    const { response, error } = yield call(services.notification.all);

    if (error) {
      yield put({ type: GET_ALL_NOTIFICATIONS_ERROR, payload: error });
    } else {
      yield put({ type: GET_ALL_NOTIFICATIONS_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: GET_ALL_NOTIFICATIONS_ERROR,
      payload: "Error al obtener notificaciones",
    });
  }
}

function* getNotification({ payload }): any {
  try {
    const { response, error } = yield call(services.notification.one, payload);

    if (error) {
      yield put({ type: GET_NOTIFICATION_ERROR, payload: error });
    } else {
      yield put({ type: GET_NOTIFICATION_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: GET_NOTIFICATION_ERROR,
      payload: "Error al obtener notificación",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(NEW_NOTIFICATION, newNotification);
  yield takeLatest<any>(UPDATE_NOTIFICATION, updateNotification);
  yield takeLatest<any>(GET_ALL_NOTIFICATIONS, getAllNotifications);
  yield takeLatest<any>(GET_NOTIFICATION, getNotification);
  yield takeLatest<any>(UPDATE_NOTIFICATION, updateNotification);
}
