import styled from "styled-components";

export const Column = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-shrink: 0;
  padding: 30px 20px;
  height: auto;
  
  @media(min-width: 768px){
    width: 50%;
    min-height: 100vh;
    padding: 40px 80px;
  }

  form[class^='svelte-']{
    padding: 0px!important;
    background: none!important;
  }

`

export const Title = styled("h1")`
  margin: 0px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
`;

export const Text = styled('p')`
  font-size: 14;
  font-weight: 500;
  margin: 0;
`


export const Subtitle = styled("h2")`
  margin: 0px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  text-align: left;
  width: 100%;
`;

export const CreditCards = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  img {
    width: 32px;
  }
`

export const FormEmptyState = styled('div')`
  
`