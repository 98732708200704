import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  Redirect,
} from "react-router-dom";

import Layout from "../../components/Layout/MainLayout";

import SignIn from "./SignIn";
import SignUp from "./SignUp";

const Component = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/signin`}
        render={() => (
          <Layout useLocation={useLocation}>
            <SignIn />
          </Layout>
        )}
      />
      <Route
        path={`${path}/signup`}
        render={() => (
          <Layout useLocation={useLocation}>
            <SignUp />
          </Layout>
        )}
      />
      <Route path={`${path}/*`} render={() => <Redirect to={"/404"} />} />
    </Switch>
  );
};

export default Component;
