import {
  ReorderModulesOutDto,
  ReorderModulesInDto,
} from "../../../types/module";

export const REORDER_MODULES = "REORDER_MODULES";
export const REORDER_MODULES_SUCCESS = "REORDER_MODULES_SUCCESS";
export const REORDER_MODULES_ERROR = "REORDER_MODULES_ERROR";
export const RESET_REORDER_MODULES = "RESET_REORDER_MODULES";

export interface ReorderModules {
  type: typeof REORDER_MODULES;
  payload: ReorderModulesOutDto;
}

export interface ReorderModulesSuccess {
  type: typeof REORDER_MODULES_SUCCESS;
  payload: ReorderModulesInDto;
}

export interface ReorderModulesError {
  type: typeof REORDER_MODULES_ERROR;
  payload: string | boolean;
}

export interface ResetReorderModules {
  type: typeof RESET_REORDER_MODULES;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | ReorderModules
  | ReorderModulesSuccess
  | ReorderModulesError
  | ResetReorderModules;
