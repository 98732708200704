import styled from "styled-components";

export const Clock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 250px;
  background-color: transparent;
  border-radius: 11px;
  height: 28px;
`;

export const ClockText = styled.div`
  margin: 0px;
  font-size: 12px;
  line-height: 14px;
`;

export const Segment = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 7px;
  line-height: 10px;
  text-transform: capitalize;
  color: ${(props) => props.theme.colors.Black01};
`;

export const Square = styled.div`
  // background-color: #f0f0f0;
  // border-radius: 16px;
  // font-weight: bold;
  // font-size: 16px;
  // width: 48px;
  // height: 48px;
  // display: flex;
  // align-items: center;
  // justify-content: center;

  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
`;

export const Dots = styled.span`
  width: 16px;
  height: 18px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &:before  {
    content: ":";
    font-size: 18px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.Black};
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    width: 16px;
  }
`;
