import { Meet } from '../../types/meet'

export const SAVE_MEET = "SAVE_MEET";
export const SAVE_MEET_SUCCESS = "SAVE_MEET_SUCCESS";
export const SAVE_MEET_ERROR = "SAVE_MEET_ERROR";

export const GET_MEET = "GET_MEET";
export const GET_MEET_SUCCESS = "GET_MEET_SUCCESS";
export const GET_MEET_ERROR = "GET_MEET_ERROR";

export interface SaveMeetPayload {
  userId: number;
  payload: {
    event: {
      uri: string;
    };
    invitee: {
      uri: string;
    };
  };
}

export interface GetMeetPayload {
  userId: number;
}



export interface SaveMeet {
  type: typeof SAVE_MEET;
  payload: SaveMeetPayload;
}

export interface SaveMeetSuccess {
  type: typeof SAVE_MEET_SUCCESS;
  payload: Meet | null;
}

export interface SaveMeetError {
  type: typeof SAVE_MEET_ERROR;
  payload: null;
}

export interface GetMeet {
  type: typeof GET_MEET;
  payload: GetMeetPayload;
}

export interface GetMeetSuccess {
  type: typeof GET_MEET_SUCCESS;
  payload: Meet | null;
}

export interface GetMeetError {
  type: typeof GET_MEET_ERROR;
  payload: null;
}

export interface State {
  data: Meet | null;
  states: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | SaveMeet
  | SaveMeetSuccess
  | SaveMeetError
  | GetMeet
  | GetMeetSuccess
  | GetMeetError;
