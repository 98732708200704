import styled, { keyframes } from "styled-components";

const AnimationZoomIn = keyframes`
0% {
  //transform: scale(0.9, 0.9);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  outline: 2px solid transparent;
  opacity: .6;
}
100% {
  //transform: scale(1, 1);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  outline: 2px solid #6b5ce7;
  opacity: 1;
}
`;

const AnimationZoomOut = keyframes`
0% {
  //transform: scale(1, 1);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  outline: 2px solid #6b5ce7;
  opacity: 1;
}
100% {
  //transform: scale(0.9, 0.9);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  outline: 2px solid transparent;
  opacity: .6;
}
`;

export const Title = styled("div")`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

export const SubTitle = styled("h4")`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0px 0px 4px 0px;
`;

export const Text = styled("p")`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0px;
  min-height: 45px;
`;

export const AlignEnd = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const Card = styled("div")`
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: ${AnimationZoomOut};
  background: #ffffff;
  border-radius: 6px;
  max-width: 260px;
  padding: 16px;
  cursor: pointer;

  &.active {
    animation-name: ${AnimationZoomIn};
  }
`;
