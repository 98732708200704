import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal, showModal } from "../../../../../store/actions/modal";
import { sharePostInTelegram } from "../../../../../store/actions/post";
import { Post } from "../../../../../types/post";
import { useEffect, useState } from "react";
import { Preference } from "../../../../../types/preferences";
import { savePreference } from "../../../../../store/actions/preferences";
import { showPreference } from "../../../../../helpers/preferenceVisible";

interface ComponentProps {
  postInformation: Post;
  sharePostInTelegramStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  preferences: Preference[];
  preferencesStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  saveSetting?: () => void;
}

const Component = ({
  postInformation,
  sharePostInTelegramStates,
  preferences,
  preferencesStates,
  saveSetting,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const id = "welcome-notification-page-modal";

  const handleCLose = () => {
    dispatch(
      savePreference({
        preferenceSlug: id,
        preferenceValue: false,
      })
    );

    setIsVisible(false);
  };

  useEffect(() => {
    setIsVisible(showPreference({ preferences, preferencesStates, id }));
  }, [preferences]);

  useEffect(() => {
    if (isVisible) {
      dispatch(showModal("welcome-notification-page-modal"));
    }
  }, [isVisible]);

  return (
    <Modal name="welcome-notification-page-modal" width="650" showClose={false}>
      <Title>Ahora puedes configurar tus notificaciones</Title>

      <Text>
        Desde ahora puedes gestionar todas las notificaciones de la app desde un
        sólo lugar. Desde el tab de “Notificaciones” podrás configurar qué
        mensajes quieres que le llegue a tus suscriptores y así comunicarles lo
        que desees por cada acción que hagas.
      </Text>

      <AlignEnd>
        <Button
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
          }}
          onClick={() => {
            dispatch(hideModal());
            handleCLose();
          }}
        >
          Ir a notificaciones
        </Button>
      </AlignEnd>
    </Modal>
  );
};

const state = ({ postStore, preferencesStore }) => {
  const { data: postInformation } = postStore.post;
  const { states: sharePostInTelegramStates } = postStore.sharePost;
  const { data: preferences, states: preferencesStates } =
    preferencesStore.preferences;

  return {
    postInformation,
    sharePostInTelegramStates,
    preferences,
    preferencesStates,
  };
};

export default connect(state)(Component);
