import { Title, Text, Alert, InLine } from "./styles";
import RecordSetting from "../RecordSetting";
import Link from "../Link";
import Button from "../Button";

interface ComponentProps {
  title?: string | any[];
  text?: string | any[];
  visible: boolean;
  canHide?: boolean;
  id: string | number;
  options?: {
    marginBottom?: number;
  };
  cta?: {
    type?: "outline" | "filled";
    label: string;
    action?: () => {};
    url?: string;
  };
}

const initialValue = {
  options: {
    marginBottom: 0,
  },
};

const Component = ({
  title,
  text,
  visible,
  id,
  options,
  canHide = true,
  cta,
}: ComponentProps) => {
  const { marginBottom } = {
    ...initialValue.options,
    ...options,
  };
  return (
    <RecordSetting visible={visible} id={id} canHide={canHide}>
      <Alert marginBottom={marginBottom}>
        {!!title && <Title>{title}</Title>}

        <InLine>
          <Text>{text}</Text>

          {!!cta && !!cta.action && (
            <Button
              onClick={cta.action}
              options={{
                type: cta.type || "filled",
                size: "lg",
                skin: "purple",
              }}
            >
              {cta.label}
            </Button>
          )}

          {!!cta && !!cta.url && (
            <Link
              href={cta.url}
              options={{
                type: cta.type || "filled",
                size: "lg",
                skin: "purple",
              }}
            >
              {cta.label}
            </Link>
          )}
        </InLine>
      </Alert>
      <div style={{ height: 16 }} />
    </RecordSetting>
  );
};

export default Component;
