import whatsappIcon from "./../assets/icons/whatsapp.svg";

const whatsapp = {
  enabled: true,
  number: 541124043745,
  message: "¡Hola! Tengo una consulta",
  group_message: `¡Hola! Tengo una consulta sobre el Klouser de "{name}"`,
  logged_in_group_message: `¡Hola! Mi cuenta es {email} y tengo una consulta sobre el Klouser de "{name}"`,
  expert_message: `¡Hola! Soy {name} y quería hacer una consulta sobre mi Klouser`,
  import_members: `¡Hola! Soy {name} y quería pedirles ayuda para importar miembros a mi comunidad "{community}"`,
  checkout_failure: `¡Hola! Soy {name} y quería pedirles ayuda para completar mi suscripción.`,
  retrying_payment: `¡Hola! Soy {name} y quería pedirles ayuda para completar el pago de mi suscripción.`,
  admin_subscriptions: `¡Hola! Soy {name}, mi correo electrónico es {email} y quería pedirles ayuda para darme de baja en una de mis suscripciones.`,
  reactivate_subscription: `¡Hola! Soy {name}, mi correo electrónico es {email} y quiero reactivar mi suscripción a {group} (Nro. {subscription_id})`,
  url: "https://api.whatsapp.com/send",
  icon: whatsappIcon,
};

export default whatsapp;
