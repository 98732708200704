import Icon from "../../../../components/Icon"
import securePaymentIcon from '../../../../assets/secure-payment.png'
import {
  Card,
  Title,
  Text,
  Row
} from './styles'
import { CSSProperties } from "styled-components"
import LockIcon from '../../../../assets/icons/pandlock.svg'
import ShielBolddIcon from '../../../../assets/icons/shield-bold.svg'

interface ComponentProps {
  style?: CSSProperties
}

const Component = ({ style }: ComponentProps) => {
  return (
    <Card style={style}>
      <Row>
        <Icon icon={LockIcon} size="20px" color="#000000" />
        <div>
          <Title>Tu información esta segura</Title>
          <Text>Tus datos personales y financieros estan protegidos</Text>
        </div>
      </Row>

      <div style={{ height: 16 }} />

      <Row>
        <Icon icon={ShielBolddIcon} size="20px" color="#000000" />
        <div>
          <Title>Checkout seguro</Title>
          <Text>Usamos el protocolo https, esto implica que tu informacion está encriptada y se trasnmite sin riesgos.</Text>
        </div>
      </Row>
    </Card>
  )
}

export default Component