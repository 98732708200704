import { put, all, call, select, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NEW_SESSION,
  NEW_SESSION_SUCCESS,
  NEW_SESSION_ERROR,
} from "../types/session/new";

import {
  UPDATE_ONE_SESSION,
  UPDATE_ONE_SESSION_SUCCESS,
  UPDATE_ONE_SESSION_ERROR,
} from "../types/session/update";

import {
  DELETE_ONE_SESSION,
  DELETE_ONE_SESSION_SUCCESS,
  DELETE_ONE_SESSION_ERROR,
} from "../types/session/delete";

import {
  GET_ALL_SESSIONS,
  GET_ALL_SESSIONS_SUCCESS,
  GET_ALL_SESSIONS_ERROR,
} from "../types/session/all";

import {
  DELETE_FILE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_ERROR,
} from "../types/session/delete-file";

import {
  GET_ONE_SESSION,
  GET_ONE_SESSION_SUCCESS,
  GET_ONE_SESSION_ERROR,
} from "../types/session/one";

import {
  SHARE_SESSION_IN_TELEGRAM,
  SHARE_SESSION_IN_TELEGRAM_ERROR,
  SHARE_SESSION_IN_TELEGRAM_SUCCESS,
} from "../types/session/share";

const getCurrentSessions = ({ sessionStore }) => sessionStore.all.data;

function* getOneSession({ payload }) {
  try {
    const { response, error } = yield call(services.session.getOne, payload);

    if (error) {
      yield put({ type: GET_ONE_SESSION_ERROR, payload: error });
    } else {
      yield put({ type: GET_ONE_SESSION_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: GET_ONE_SESSION_ERROR,
      payload: "Error al buscar la sesion",
    });
  }
}

function* getAllSessions({ payload }) {
  try {
    const { response, error } = yield call(services.session.getAll, payload);

    if (error) {
      yield put({ type: GET_ALL_SESSIONS_ERROR, payload: error });
    } else {
      yield put({ type: GET_ALL_SESSIONS_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: GET_ALL_SESSIONS_ERROR,
      payload: "Error al buscar el listado de encuentros",
    });
  }
}

function* newSession({ payload }): any {
  try {
    const { response, error } = yield call(services.session.new, payload);

    if (error) {
      yield put({ type: NEW_SESSION_ERROR, payload: error });
    } else {
      const currentSessions = yield select(getCurrentSessions);
      yield all([
        put({ type: NEW_SESSION_SUCCESS, payload: response }),
        put({ type: GET_ALL_SESSIONS_SUCCESS, payload: [...(!!currentSessions ? currentSessions : []), response] })
      ]);
    }
  } catch {
    yield put({
      type: NEW_SESSION_ERROR,
      payload: "Error al crear un nuevo encuentro.",
    });
  }
}

function* deleteSession({ payload }): any {
  try {
    const { response, error } = yield call(services.session.delete, payload);
    if (error) {
      yield put({ type: DELETE_ONE_SESSION_ERROR, payload: error });
    } else {
      yield all([
        put({ type: DELETE_ONE_SESSION_SUCCESS, payload: response }),
        put({ type: GET_ALL_SESSIONS, payload: null }),
      ]);
    }
  } catch (error) {
    yield put({
      type: DELETE_ONE_SESSION_ERROR,
      payload: "Error al eliminar un encuentro.",
    });
  }
}

function* updateSession({ payload }): any {
  try {
    const { response, error } = yield call(services.session.update, payload);

    if (error) {
      yield put({ type: UPDATE_ONE_SESSION_ERROR, payload: error });
    } else {
      yield all([put({ type: UPDATE_ONE_SESSION_SUCCESS, payload: response })]);
    }
  } catch {
    yield put({
      type: UPDATE_ONE_SESSION_ERROR,
      payload: "Error al actualizar un encuentro.",
    });
  }
}

function* deleteFile({ payload }): any {
  try {
    const result = yield call(services.session.deleteFile, payload);
    yield put({ type: DELETE_FILE_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: DELETE_FILE_ERROR, payload: error });
  }
}

function* shareSessionInTelegram({ payload }): any {
  try {
    const { response, error } = yield call(
      services.session.shareSessionInTelegram,
      payload
    );

    if (error) {
      yield put({
        type: SHARE_SESSION_IN_TELEGRAM_ERROR,
        payload: error?.response?.status,
      });
    } else {
      yield put({
        type: SHARE_SESSION_IN_TELEGRAM_SUCCESS,
        payload: response.length > 0 ? response : null,
      });
    }
  } catch {
    yield put({
      type: SHARE_SESSION_IN_TELEGRAM_ERROR,
      payload: "Error al compartir encuentro en Telegram",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(NEW_SESSION, newSession);
  yield takeLatest<any>(UPDATE_ONE_SESSION, updateSession);
  yield takeLatest<any>(DELETE_ONE_SESSION, deleteSession);
  yield takeLatest<any>(GET_ALL_SESSIONS, getAllSessions);
  yield takeLatest<any>(GET_ONE_SESSION, getOneSession);
  yield takeLatest<any>(DELETE_FILE, deleteFile);
  yield takeLatest<any>(SHARE_SESSION_IN_TELEGRAM, shareSessionInTelegram);
}
