import styled from "styled-components";

export const Schedule = styled("section")`
  min-height: 100vh;
  padding: 150px 0px 100px;
`;
export const Meetings = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
export const Meeting = styled("div")`
  display: flex;
  alig-items: center;
  justify-content: flex-start;
`;
export const Time = styled("div")<{ last? }>`
  position: relative;
  width: 100px;
  text-align: right;
  padding-right: 30px;
  line-height: 20px;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    right: 0;
  }
  
  &:before {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: white;
    border: 2px solid black;
    right: -6px;
    z-index: 10;
  }

  ${(props) =>
    !props.last
      ? ` &:after { height: 100%; width: 2px; right: -1px; background-color: black; z-index: 5; }`
      : ""}
  }
`;
export const Detail = styled("div")`
  padding-left: 30px;
  margin-bottom: 25px;
  max-width: 500px;
`;
export const Title = styled("h3")`
  margin: 0px;
  font-weight: 600;
  line-height: 20px;
`;
export const By = styled("p")`
  margin: 0px;
  font-weight: 500;
`;
export const Description = styled("p")`
  margin: 0px;
`;
