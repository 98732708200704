import * as Yup from "yup";

export const schema = Yup.object().shape({
  title: Yup.string().required("Debes indicar el nombre de la artículo."),
  description: Yup.string()
    .nullable()
    .required("Agrega una descripción a tu artículo."),
  // cover_pic: Yup.array().nullable(),
  // attached_files: Yup.array().nullable(),
  // attached_url: Yup.string().nullable(),
  // block_files_download: Yup.array().of(Yup.string()).nullable(),
});

interface FormValues {
  title: string;
  description: string;
  // cover_pic?: object[];
  // attached_files?: object[];
  // attached_url?: string;
  // block_files_download: string[]
}

export const initialValues: FormValues = {
  title: "",
  description: "",
  // block_files_download: []
};
