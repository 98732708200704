import { SurveyInDto } from "../../../types/survey.dto";

export const GET_ONE_SURVEY = "GET_ONE_SURVEY";
export const GET_ONE_SURVEY_SUCCESS = "GET_ONE_SURVEY_SUCCESS";
export const GET_ONE_SURVEY_ERROR = "GET_ONE_SURVEY_ERROR";
export const UPDATE_CURRENT_SURVEY = "UPDATE_CURRENT_SURVEY";
export const UPDATE_CURRENT_SURVEY_SUCCESS = "UPDATE_CURRENT_SURVEY_SUCCESS";
export const UPDATE_CURRENT_SURVEY_ERROR = "UPDATE_CURRENT_SURVEY_ERROR";
export const RESET_GET_ONE_SURVEY = "RESET_GET_ONE_SURVEY";

export interface GetOneSurvey {
  type: typeof GET_ONE_SURVEY;
  payload: { idSurvey: string | number };
}

export interface GetOneSurveySuccess {
  type: typeof GET_ONE_SURVEY_SUCCESS;
  payload: SurveyInDto;
}

export interface GetOneSurveyError {
  type: typeof GET_ONE_SURVEY_ERROR;
  payload: string | boolean;
}

export interface UpdateCurrentSurvey {
  type: typeof UPDATE_CURRENT_SURVEY;
  payload: { idSurvey: string | number };
}

export interface UpdateCurrentSurveySuccess {
  type: typeof UPDATE_CURRENT_SURVEY_SUCCESS;
  payload: SurveyInDto;
}

export interface UpdateCurrentSurveyError {
  type: typeof UPDATE_CURRENT_SURVEY_ERROR;
  payload: string | boolean;
}

export interface ResetGetOneSurvey {
  type: typeof RESET_GET_ONE_SURVEY;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetOneSurvey
  | GetOneSurveySuccess
  | GetOneSurveyError
  | UpdateCurrentSurvey
  | UpdateCurrentSurveySuccess
  | UpdateCurrentSurveyError
  | ResetGetOneSurvey;
