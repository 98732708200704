import {
  initialState,
  Actions,
  State,
  SUBSCRIBE_TO_THE_COMMUNITY,
  SUBSCRIBE_TO_THE_COMMUNITY_SUCCESS,
  SUBSCRIBE_TO_THE_COMMUNITY_ERROR,
  RESET_SUBSCRIBE_TO_THE_COMMUNITY,
} from "../../types/community/subscription";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SUBSCRIBE_TO_THE_COMMUNITY:
      return {
        ...state,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case SUBSCRIBE_TO_THE_COMMUNITY_SUCCESS:
      return {
        ...state,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case SUBSCRIBE_TO_THE_COMMUNITY_ERROR:
      return {
        ...state,
        states: {
          loading: false,
          error: true,
          success: false,
        },
      };
    case RESET_SUBSCRIBE_TO_THE_COMMUNITY:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
