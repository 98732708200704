import {
  initialState,
  Actions,
  State,
  RESET_SHARE_SESSION_IN_TELEGRAM,
  SHARE_SESSION_IN_TELEGRAM_SUCCESS,
  SHARE_SESSION_IN_TELEGRAM,
  SHARE_SESSION_IN_TELEGRAM_ERROR,
} from "../../types/session/share";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SHARE_SESSION_IN_TELEGRAM:
      return {
        ...state,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case SHARE_SESSION_IN_TELEGRAM_SUCCESS:
      return {
        ...state,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case SHARE_SESSION_IN_TELEGRAM_ERROR:
      return {
        ...state,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_SHARE_SESSION_IN_TELEGRAM:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
