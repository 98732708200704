import Helmet from "react-helmet";
import { connect, useDispatch } from "react-redux";

import Grid from "../../../components/Grid";
import Tabs from "../components/Tabs";
import {
  Title,
  RainbowSectionName,
  Separator,
  Center,
} from "../../../components/StyledComponents";

import EmptyState from "../components/EmptyState";
import Loader from "../../../components/LogoLoader";
import { Group, SessionInDto, Subscription, User } from "../../../types";
import GoToTelegram from "../components/GoToTelegram";
import ModulesList from './components/ModulesList'
import SelectedModule from './components/SelectedModule'
import { useEffect, useMemo, useState } from "react";
import { useModulesPreview } from "../../../providers/ModulesPreviewProvider";
import { getGroupBySlug } from "../../../store/actions/group";
import { Layout, useLayout } from "../../../providers/LayoutProvider";
import { isSubscribed } from "../../../helpers/isSubscribed";
import { Redirect } from "react-router-dom";
import { isCongreso } from "../../../helpers/isCongreso";
import { isCommunity } from "../../../helpers/isCommunity";
import { isOwnGroup } from "../../../helpers/isOwnGroup";
import NotSubscribedUsers from "./components/NotSubscribedUsers";
import { getUrlParamByName } from "../../../helpers/get-url-param";
import { Page } from "../components/styles";
import { useWindowSize } from "../../../hooks/useWindowSize";

const groupTypes = {
  membership: "Membresía",
  experience: "Taller",
  free_experience: "Evento Gratuito",
  community: "Comunidad",
};

interface ComponentProps {
  subscriptions: Subscription[];
  isLoggedIn: boolean;
  sessions: SessionInDto[];
  sessionsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  match;
  user: User;
  userStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  subscriptions,
  group,
  groupStates,
  user,
  userStates,
  isLoggedIn,
  match,
}: ComponentProps) => {
  const { isMobile } = useWindowSize()
  const { modules, selectedModule } = useModulesPreview();
  const [showEmptyState, setShowEmptyState] = useState<boolean>();
  const dispatch = useDispatch();
  const { setLayout, layout } = useLayout();

  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "plain",
      footer: {
        visible: false,
      },
      header: {
        visible: true,
      },
      toolbar: {
        visible: isOwnGroup({ group, expertId: user?.expert?.id })
      },
      body: {
        visible: true,
        background: { color: "#FAFAFA" },
      },
    }),
    [layout, subscriptions, group, user]
  );

  const redirect = useMemo(() => {
    if (!!group) {
      return groupStates?.error || isCongreso(group?.id) || isCommunity(group?.group_type) || (!isOwnGroup({ group, expertId: user?.expert?.id }) && group?.status === 'draft')
    }

    return false
  }, [group, user])

  useEffect(() => {
    setLayout(layoutData);
    setShowEmptyState(!modules || (!!modules && modules.length <= 0))
  }, [modules, group]);

  useEffect(() => {
    if (
      !!match?.params &&
      (!group?.slug || (!!group?.slug && group?.slug !== match?.params?.group))
    ) {
      match?.params && dispatch(getGroupBySlug(match?.params?.group));
    }
  }, [match]);

  if (groupStates?.loading || userStates?.loading || !modules || redirect === undefined) {
    return <Loader />;
  }

  if (redirect) {
    return <Redirect to="/404" />;
  }

  return (
    <Page>
      <Helmet>
        ‍<title></title>‍
        <meta name="description" content="" />
      </Helmet>

      <Grid.Container>
        <Grid.Row>
          <Grid.Col lg={10} offset={{ lg: 1 }}>
            {/* <Separator height={{ desktop: "63px", mobile: "40px" }} />

            <RainbowSectionName>
              {!!group?.expert?.showable_name && `${groupTypes[group?.group_type]} de ${group?.expert?.showable_name}`}
            </RainbowSectionName>

            <Title>{group?.group_name}</Title>

            <Separator height={{ desktop: "40px", mobile: "16px" }} />

            <Tabs
              active="modules"
              hiddenTabs={group?.recorded ? ["activity-summary", "library"] : group?.group_type === 'free_experience' ? ['modules', "activity-summary"] : ['modules']}
            />

            <Separator height={{ desktop: "70px", mobile: "32px" }} /> */}

            <Center horizontal="space" vertical="top" gap={16}>
              {selectedModule?.can_access_to_full_module || isOwnGroup({ group, expertId: user?.expert?.id }) ?
                <>
                  {showEmptyState ?
                    <EmptyState text={
                      <>
                        <strong>Aún no se agregaron módulos al {`${groupTypes[group?.group_type]}`}.</strong>
                        <br /> Aguarda a que se comience a crearlos.
                      </>
                    } /> :
                    <>

                      {!isMobile && <ModulesList />}
                      <SelectedModule />
                    </>
                  }
                </>
                :
                <>
                  {!isMobile && <ModulesList />}
                  <NotSubscribedUsers match={match} />
                </>}
            </Center>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <Separator height={{ desktop: "32px", mobile: "16px" }} />
    </Page>
  );
};

const state = ({ userStore, sessionStore, groupStore, modulesStore }) => {
  const { isLoggedIn } = userStore;
  const { data: user, states: userStates } = userStore.information;
  const { data: sessions, states: sessionsStates } = sessionStore.all;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: subscriptions } = userStore.subscriptions;

  return {
    user,
    userStates,
    subscriptions,
    isLoggedIn,
    sessions,
    sessionsStates,
    group,
    groupStates,
  };
};

export default connect(state)(Component);
