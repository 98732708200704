import React, { useEffect, useRef, useState } from "react";
import {
  Collapse,
  CollapseToggle,
  CollapseContent,
  ToggleIcon,
  Separator,
  Arrow,
} from "./styles";
import Icon from "../../../../../components/Icon";
import chevronNext from "../../../../../assets/icons/chevron-next.svg";

interface ComponentProps {
  children;
  toggleLabel: string;
  toggleIcon: string;
}

const Component = ({ children, toggleLabel, toggleIcon }: ComponentProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [contentHeight, setContentHeight] = useState<number>();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current) {
      setContentHeight(ref?.current?.clientHeight);
    }
  }, [ref]);

  return (
    <Collapse>
      {toggleLabel && (
        <CollapseToggle
          type="button"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {toggleIcon && (
            <ToggleIcon
              collapsed={isCollapsed}
              icon={toggleIcon}
              size="20"
              color="black"
            />
          )}

          {toggleLabel}

          <Arrow isCollapsed={isCollapsed}>
            <Icon icon={chevronNext} color="black" size="14px" />
          </Arrow>
        </CollapseToggle>
      )}

      <Separator collapsed={isCollapsed} />

      <CollapseContent collapsed={isCollapsed} contentHeight={contentHeight}>
        <div ref={ref}>
          <div style={{ paddingTop: "32px", paddingBottom: "20px" }}>
            {children}
          </div>
        </div>
      </CollapseContent>
    </Collapse>
  );
};

export default Component;
