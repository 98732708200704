import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Helmet from "react-helmet";
import Link from "../../../../../components/Link";
import EmptyCard from "../../../components/EmptyCard";
import NoResult from "./components/NoResults";
import { Center, Page, PageHeader, Title } from "../../../components/styles";
import ModalNewGroup from "./components/ModalNewGroup";
import FilterByState from "./components/FilterByState";
import { InLine, CardsGrid } from "./styles";
import Container from "../../../../../components/Grid/Container";
import Tabs from "../../components/Tabs";
import Row from "../../../../../components/Grid/Row";
import Col from "../../../../../components/Grid/Col";
import GroupCard from "../../../../../components/GroupCard";
import slugify from "slugify";
import { Group } from "../../../../../types";
import constants from "../../../../../constants";
import { useLayout } from "../../../../../providers/LayoutProvider";

interface ComponentProps {
  userInformation;
  userGroups: Group[];
  match;
  userGroupsStates;
  community: Group;
  communityStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const wording = {
  membership: {
    title: "Mis Membresias",
    cta: "Crear una membresía",
  },
  experience: {
    title: "Mis Experiencias",
    cta: "Crear una experiencia",
  },
};

const Component = ({
  match,
  userGroups,
  userInformation,
  userGroupsStates,
  community,
  communityStates,
}: ComponentProps) => {
  const groupType = "free_experience";
  const { layout, setLayout } = useLayout();
  const [groups, setGroups] = useState<Group[]>();
  const [filteredGroups, setFilteredGroups] = useState<Group[]>();

  useEffect(() => {
    if (!!userGroups) {
      setGroups(userGroups?.filter((group) => group?.group_type === groupType));
    }
  }, [userGroups]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: community?.group_name,
        description: "Listado de eventos gratuítos",
        back: "/dashboard/community",
      },
      dashboard: {
        tabs: {
          visible: true,
          activeTab: "events",
          component: [<Tabs active="events" />],
        },
      },
      header: {
        visible: true,
        actions: [
          // <Link
          //   href={constants.urls.dashboard.newCommunityEvent.url}
          //   options={{
          //     size: "lg",
          //     type: "filled",
          //     skin: "purple",
          //   }}
          //   style={{ minWidth: "210px" }}
          // >
          //   Crear evento gratuito
          // </Link>,
        ],
      },
    });
  }, [community]);

  return (
    <>
      <Container>
        {(!groups && !userGroupsStates.loading) ||
          (!!groups && groups?.length <= 0) ||
          userGroupsStates.error ||
          userGroupsStates.loading ||
          !userInformation ? (
          <>
            <div style={{ height: "32px" }}></div>
            {/* <Row>
              <Col>
                <NoResult
                  label="Crear evento gratuito"
                  link={constants.urls.dashboard.newCommunityEvent.url}
                />
              </Col>
            </Row> */}
          </>
        ) : (
          <>
            <Row>
              <Col>
                <strong>Importante:</strong> ⚠️ <br />
                La creación de Eventos Gratuitos ha sido desactivada por tiempo indefinido en la plataforma mientras hacemos mejoras para que puedas tener la mejor experiencia posible utilizando esta función
              </Col>
            </Row>

            <div style={{ height: "32px" }}></div>

            <Row>
              <Col>
                <Center horizontal="space" vertical="center">
                  Ultimos eventos
                  <FilterByState
                    groups={groups}
                    reset={match.url}
                    setFilteredGroups={setFilteredGroups}
                  />
                </Center>
              </Col>
            </Row>

            <div style={{ height: "16px" }}></div>

            <Row>
              <Col>
                <CardsGrid>
                  <>
                    {!!filteredGroups &&
                      filteredGroups.map((group) => {
                        const { expert, ...rest } = userInformation;
                        return (
                          <GroupCard
                            group={group}
                            key={slugify(String(group?.id), { lower: true })}
                            expert={expert}
                            editLink={`/dashboard/community/events/edit/${group?.id}`}
                            homeGroupLink={`/dashboard/community/events/${group?.id}`}
                            previewLink={`${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.slug}`}
                          />
                        );
                      })}
                  </>
                </CardsGrid>
              </Col>
            </Row>
          </>
        )}
      </Container>

      <ModalNewGroup />
    </>
  );
};

const state = ({ communityStore, userStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: userGroups, states: userGroupsStates } = userStore.groups;
  const { data: community, states: communityStates } = communityStore.community;


  return {
    userInformation,
    userGroups,
    userGroupsStates,
    community,
    communityStates,
  };
};

export default connect(state)(Component);
