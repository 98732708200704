import { SurveyInDto } from "../../../types/survey.dto";

export const GET_ALL_SURVEYS = "GET_ALL_SURVEYS";
export const GET_ALL_SURVEYS_SUCCESS = "GET_ALL_SURVEYS_SUCCESS";
export const GET_ALL_SURVEYS_ERROR = "GET_ALL_SURVEYS_ERROR";
export const UPDATE_ALL_CURRENT_SURVEYS = "UPDATE_ALL_CURRENT_SURVEYS";
export const UPDATE_ALL_CURRENT_SURVEYS_SUCCESS =
  "UPDATE_ALL_CURRENT_SURVEYS_SUCCESS";
export const UPDATE_ALL_CURRENT_SURVEYS_ERROR =
  "UPDATE_ALL_CURRENT_SURVEYS_ERROR";
export const RESET_GET_ALL_SURVEYS = "RESET_GET_ALL_SURVEYS";

export interface GetAllSurveys {
  type: typeof GET_ALL_SURVEYS;
  payload: { idGroup: number };
}

export interface GetAllSurveysSuccess {
  type: typeof GET_ALL_SURVEYS_SUCCESS;
  payload: SurveyInDto[];
}

export interface GetAllSurveysError {
  type: typeof GET_ALL_SURVEYS_ERROR;
  payload: string | boolean;
}
export interface UpdateAllCurrentSurveys {
  type: typeof UPDATE_ALL_CURRENT_SURVEYS;
  payload: SurveyInDto;
}

export interface UpdateAllCurrentSurveysSuccess {
  type: typeof UPDATE_ALL_CURRENT_SURVEYS_SUCCESS;
  payload: SurveyInDto[];
}

export interface UpdateAllCurrentSurveysError {
  type: typeof UPDATE_ALL_CURRENT_SURVEYS_ERROR;
  payload: string | boolean;
}

export interface ResetGetAllSurveys {
  type: typeof RESET_GET_ALL_SURVEYS;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetAllSurveys
  | GetAllSurveysSuccess
  | GetAllSurveysError
  | UpdateAllCurrentSurveys
  | UpdateAllCurrentSurveysSuccess
  | UpdateAllCurrentSurveysError
  | ResetGetAllSurveys;
