import action from "../helpers/rest-client";
import getPaginationString from "../helpers/getPaginationString";

export class ErrorWelcomeContentSection extends Error { }

class WelcomeContentSection {
  public async newWelcomeContentSection(payload) {
    return action
      .Post({
        url: `/api/welcome-content/section`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        throw { error };
      });
  }

  public async editWelcomeContentSection({ idWelcomeContentSection, body }) {
    return action
      .Put({
        url: `/api/welcome-content/section/${idWelcomeContentSection}`,
        body,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        throw { error };
      });
  }

  public async getWelcomeContentSection({ idWelcomeContentSection, filters }) {
    const queryString = new URLSearchParams(filters).toString();

    return action
      .Get({
        url: `/api/welcome-content/section/${idWelcomeContentSection}?${queryString ? queryString : ''}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async finishWelcomeContentSection({ idWelcomeContentSection: idWelcomeSection }) {
    return action
      .Post({
        url: `/api/welcome-content/section/finish-section`,
        body: { welcome_section_id: idWelcomeSection }
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async newWelcomeContentPost(body) {
    return action
      .Post({
        url: `/api/welcome-content/post`,
        body,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        throw { error };
      });
  }

  public async editWelcomeContentPost({ idWelcomeContentPost, body }) {
    return action
      .Put({
        url: `/api/welcome-content/post/${idWelcomeContentPost}`,
        body,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        throw { error };
      });
  }

  public async deleteWelcomeContentPost(idWelcomeContentPost) {
    return action
      .Del({
        url: `/api/welcome-content/post/${idWelcomeContentPost}`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return { error }
      });
  }

  public async getWelcomeContentPost({ idWelcomeContentPost }) {
    return action
      .Get({
        url: `/api/welcome-content/post/${idWelcomeContentPost}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getAllWelcomeContentPosts() {
    // const pagination = getPaginationString(payload?.pagination);
    // const queryString = new URLSearchParams(payload?.filters).toString();

    return action
      .Get({
        url: `/api/welcome-content/post`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async reorderWelcomeContentPost(payload) {
    return action
      .Post({
        url: `/api/welcome-content/post/reorder`,
        body: payload
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error }
      });
  }

  public async deleteFile(payload) {
    const { idFile } = payload;

    return action
      .Del({
        url: `/api/welcome-content/file/${idFile}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error }
      });
  }

  public async finishWelcomeContentPost({ idWelcomeContentPost }) {
    return action
      .Post({
        url: `/api/welcome-content/finish-post`,
        body: { welcome_content_id: idWelcomeContentPost }
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const welcomeSection = new WelcomeContentSection();
export default welcomeSection;
