import styled from "styled-components";

export const Card = styled('div')`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
  background: #040404;
  background: linear-gradient(155deg, #2E2E30 0%, #040404 100%);
  padding: 28px 22px;
`

export const Text = styled('p')`
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
  color: white;
  text-align: center;
`

export const Title = styled('h2')`
  font-size: 22px;
  line-height: 28px;
  margin: 0px;
  font-weight: 600;
  background: rgb(255,232,163);
  background: linear-gradient(90deg, rgba(255,232,163,1) 25%, rgba(232,185,49,1) 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
`