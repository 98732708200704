import styled from "styled-components";

export const SidebarContent = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
`;

export const Title = styled("h3")`
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #2d3436;
`;

export const Text = styled("p")`
  margin: 0px;
  font-size: 14px;
  line-height: 19px;
  color: #2D3436;
`;

export const Total = styled("div")`
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  font-weight: 600;
`;

export const Email = styled("div")`
  font-size: 16px;
  line-height: 32px;
  display: flex;
  gap: 12px;
`;

export const LetterGroup = styled("div")`
  margin-bottom: 12px;
`;

export const Letter = styled("div")`
  font-size: 16px;
  line-height: 32px;
`;

export const Label = styled("label")`
  display: block;
  margin-bottom: 8px;
  color: rgb(45, 52, 54);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

export const Delete = styled("button")`
  border: 0px;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  cursor: pointer;
`;

export const VerticalScroll = styled("div")`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  padding: 10px 19px 10px 10px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

export const Box = styled('div')`
  height: 160px;
  max-height: 160px;
  background-color: #F0F0F0;
  border: 1px solid #D5D6D7;
  border-radius: 6px;
  transition: height .2s ease-in-out;
`

export const AddedEmail = styled('div')`
  height: 50px;
  border-radius: 25px;
  padding: 0px 18px;
  background-color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ExpirationDate = styled('div')`
  font-size: 12px;
  display: flex;
  alingn-items: center;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  span{
    font-weight: 600;
  }
`

export const RemoveEmailBtn = styled('button')`
  border: 0px;
  margin: 0px;
  padding: 0px;
  outline: 0px;
  background: none;
  cursor: pointer;
`

export const SlideContent = styled('div')`
  & + .carousel__slide-focus-ring {
    display: none;
  }
`