import styled from 'styled-components'

export const Card = styled('div')`
  padding: 28px;
  background: rgb(255,232,163);
  background: linear-gradient(155deg, rgba(255,232,163,1) 0%, rgba(255,241,194,1) 100%);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media(min-width: 768px){
    max-width: 410px;
  }
`

export const Title = styled('h2')`
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  margin: 0px;
  text-align: center;
`
export const Text = styled('p')`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  text-align: center;
`

export const GroupName = styled('div')`
  font-size: 22px;
  font-weight: 600;
`