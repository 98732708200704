import { put, all, call, select, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NEW_SURVEY,
  NEW_SURVEY_SUCCESS,
  NEW_SURVEY_ERROR,
  RESET_NEW_SURVEY,
} from "../types/survey/new";

import {
  UPDATE_ONE_SURVEY,
  UPDATE_ONE_SURVEY_SUCCESS,
  UPDATE_ONE_SURVEY_ERROR,
  RESET_UPDATE_ONE_SURVEY,
} from "../types/survey/update";

import {
  DELETE_ONE_SURVEY,
  DELETE_ONE_SURVEY_SUCCESS,
  DELETE_ONE_SURVEY_ERROR,
  RESET_DELETE_ONE_SURVEY,
} from "../types/survey/delete";

import {
  GET_ALL_SURVEYS,
  GET_ALL_SURVEYS_SUCCESS,
  GET_ALL_SURVEYS_ERROR,
  UPDATE_ALL_CURRENT_SURVEYS,
  UPDATE_ALL_CURRENT_SURVEYS_SUCCESS,
  UPDATE_ALL_CURRENT_SURVEYS_ERROR,
  RESET_GET_ALL_SURVEYS,
} from "../types/survey/all";

import {
  GET_ALL_SURVEY_ANSWERS,
  GET_ALL_SURVEY_ANSWERS_SUCCESS,
  GET_ALL_SURVEY_ANSWERS_ERROR,
  RESET_GET_ALL_SURVEY_ANSWERS,
} from "../types/survey/all-answers";

import {
  NEW_SURVEY_ANSWER,
  NEW_SURVEY_ANSWER_SUCCESS,
  NEW_SURVEY_ANSWER_ERROR,
  RESET_NEW_SURVEY_ANSWER,
} from "../types/survey/new-answer";

import {
  GET_ONE_SURVEY,
  GET_ONE_SURVEY_SUCCESS,
  GET_ONE_SURVEY_ERROR,
  UPDATE_CURRENT_SURVEY,
  UPDATE_CURRENT_SURVEY_SUCCESS,
  UPDATE_CURRENT_SURVEY_ERROR,
  RESET_GET_ONE_SURVEY,
} from "../types/survey/one";

import {
  EXPORT_SURVEY_ANSWERS,
  EXPORT_SURVEY_ANSWERS_SUCCESS,
  EXPORT_SURVEY_ANSWERS_ERROR,
  RESET_EXPORT_SURVEY_ANSWERS,
} from "../types/survey/export-survey-answers";

function* exportSurveyAnswers({ payload }) {
  try {
    const { response, error } = yield call(
      services.survey.exportSurveyAnswers,
      payload
    );

    if (error) {
      yield put({ type: EXPORT_SURVEY_ANSWERS_ERROR, payload: error });
    } else {
      yield put({ type: EXPORT_SURVEY_ANSWERS_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: EXPORT_SURVEY_ANSWERS_ERROR,
      payload: "Error al exportar las respuestas de la encuesta.",
    });
  }
}

function* getOneSurvey({ payload }) {
  try {
    const { response, error } = yield call(services.survey.getOne, payload);

    if (error) {
      yield put({ type: GET_ONE_SURVEY_ERROR, payload: error });
    } else {
      yield put({ type: GET_ONE_SURVEY_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: GET_ONE_SURVEY_ERROR,
      payload: "Error al buscar la encuesta",
    });
  }
}

function* getAllSurveys({ payload }) {
  try {
    const { response, error } = yield call(services.survey.getAll, payload);

    if (error) {
      yield put({ type: GET_ALL_SURVEYS_ERROR, payload: error });
    } else {
      yield put({ type: GET_ALL_SURVEYS_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: GET_ALL_SURVEYS_ERROR,
      payload: "Error al buscar el listado de encuestas",
    });
  }
}

function* newSurvey({ payload }): any {
  try {
    const { response, error } = yield call(services.survey.new, payload);

    if (error) {
      yield put({ type: NEW_SURVEY_ERROR, payload: error });
    } else {
      yield all([
        put({ type: NEW_SURVEY_SUCCESS, payload: response }),
        put({ type: UPDATE_CURRENT_SURVEY, payload: response }),
      ]);
    }
  } catch {
    yield put({
      type: NEW_SURVEY_ERROR,
      payload: "Error al crear un nueva encuesta.",
    });
  }
}

function* deleteSurvey({ payload }): any {
  try {
    const { response, error } = yield call(services.survey.delete, payload);
    if (error) {
      yield put({ type: DELETE_ONE_SURVEY_ERROR, payload: error });
    } else {
      yield all([
        put({ type: DELETE_ONE_SURVEY_SUCCESS, payload: response }),
        put({ type: RESET_GET_ALL_SURVEYS, payload: null }),
        put({ type: RESET_GET_ONE_SURVEY, payload: null }),
        put({ type: RESET_GET_ALL_SURVEY_ANSWERS, payload: null }),
      ]);
    }
  } catch (error) {
    yield put({
      type: DELETE_ONE_SURVEY_ERROR,
      payload: "Error al eliminar la encuesta.",
    });
  }
}

function* updateSurvey({ payload }): any {
  try {
    const { response, error } = yield call(services.survey.update, payload);

    if (error) {
      yield put({ type: UPDATE_ONE_SURVEY_ERROR, payload: error });
    } else {
      yield all([
        put({ type: UPDATE_ONE_SURVEY_SUCCESS, payload: response }),
        put({ type: UPDATE_CURRENT_SURVEY, payload: response }),
      ]);
    }
  } catch {
    yield put({
      type: UPDATE_ONE_SURVEY_ERROR,
      payload: "Error al actualizar la encuesta.",
    });
  }
}

function* updateAllCurrentSurveys({ payload }): any {
  try {
    yield put({
      type: UPDATE_ALL_CURRENT_SURVEYS_SUCCESS,
      payload: payload,
    });
  } catch {
    yield put({
      type: UPDATE_ALL_CURRENT_SURVEYS_ERROR,
      payload: "Error al actualizar las encuestas.",
    });
  }
}

function* updateCurrentSurvey({ payload }): any {
  try {
    yield put({
      type: UPDATE_CURRENT_SURVEY_SUCCESS,
      payload: payload,
    });
  } catch {
    yield put({
      type: UPDATE_CURRENT_SURVEY_ERROR,
      payload: "Error al actualizar la encuesta.",
    });
  }
}

function* getAllSurveyAnswers({ payload }): any {
  try {
    const { response, error } = yield call(
      services.survey.getAllSurveyAnswers,
      payload
    );

    if (error) {
      yield put({ type: GET_ALL_SURVEY_ANSWERS_ERROR, payload: error });
    } else {
      yield all([
        put({ type: GET_ALL_SURVEY_ANSWERS_SUCCESS, payload: response }),
      ]);
    }
  } catch {
    yield put({
      type: GET_ALL_SURVEY_ANSWERS_ERROR,
      payload: "Error al obtener las respuestas de los suscriptores.",
    });
  }
}

function* newSurveyAnswer({ payload }): any {
  try {
    const { response, error } = yield call(services.survey.newAnswer, payload);

    if (error) {
      yield put({ type: NEW_SURVEY_ANSWER_ERROR, payload: error });
    } else {
      yield all([put({ type: NEW_SURVEY_ANSWER_SUCCESS, payload: response })]);
    }
  } catch {
    yield put({
      type: NEW_SURVEY_ANSWER_ERROR,
      payload: "Error al enviar tus respuestas a la encuesta.",
    });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(NEW_SURVEY, newSurvey);
  yield takeLatest<any>(UPDATE_ONE_SURVEY, updateSurvey);
  yield takeLatest<any>(DELETE_ONE_SURVEY, deleteSurvey);
  yield takeLatest<any>(GET_ALL_SURVEYS, getAllSurveys);
  yield takeLatest<any>(GET_ONE_SURVEY, getOneSurvey);
  yield takeLatest<any>(GET_ALL_SURVEY_ANSWERS, getAllSurveyAnswers);
  yield takeLatest<any>(NEW_SURVEY_ANSWER, newSurveyAnswer);
  yield takeLatest<any>(UPDATE_ALL_CURRENT_SURVEYS, updateAllCurrentSurveys);
  yield takeLatest<any>(UPDATE_CURRENT_SURVEY_ERROR, updateCurrentSurvey);
  yield takeLatest<any>(EXPORT_SURVEY_ANSWERS, exportSurveyAnswers);
}
