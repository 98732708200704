import styled from 'styled-components'

export const Card = styled('a')`
  display: flex;  
  gap: 10px;
  align-items: center;
  width: 100%;
  min-height: 50px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 1px 14px 0px rgba(0,0,0,.25);
`

export const Title = styled('h3')`
  color: ${({ theme }) => theme.colors.Black};
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
`

export const Description = styled('p')`
  color: ${({ theme }) => theme.colors.Black75};
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
`

export const Button = styled('button')`
  background-color: white;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 14px 0px;
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
`

export const Actions = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-self: center;
`