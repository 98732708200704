import styled from "styled-components";

export const CardsGrid = styled("div")`
    display: grid;
  gap: 16px;
  grid-auto-rows: 250px;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));

  @media(min-width: 768px){
    grid-auto-rows: 250px;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  }

  @media(min-width: 1024px){
    grid-auto-rows: 250px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

export const EmptyStateCard = styled("div")`
  background-color: #e0e4ea;
  border-radius: 6px;
  flex-shrink: 0;
  width: 100%;
  max-width: 290px;
  height: 250px;
`;
