import facebook from './../assets/social-networks/icon-facebook.svg'
import linkeding from './../assets/social-networks/icon-linkedin.svg'
import slack from './../assets/social-networks/icon-slack.svg'
import twitter from './../assets/social-networks/icon-twitter.svg'
import instagram from './../assets/social-networks/icon-instagram.svg'

const socialNetworks = [
  {
    enabled: true,
    name: 'Facebook',
    url: 'https://www.facebook.com/klouserapp',
    icon: facebook
  },
  {
    enabled: true,
    name: 'Linkedin',
    url: 'https://www.linkedin.com/company/klousergroup/',
    icon: linkeding
  },
  {
    enabled: false,
    name: 'Slack',
    url: '',
    icon: slack
  },
  {
    enabled: true,
    name: 'Twitter',
    url: 'https://twitter.com/klouser_app',
    icon: twitter
  },
  {
    enabled: true,
    name: 'Instagram',
    url: 'https://instagram.com/klouser.app',
    icon: instagram
  }
]

export default socialNetworks