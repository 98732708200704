import { connect, useDispatch } from 'react-redux'
import {
  Card,
  Title,
  SubTitle
} from './styles'
import { Group } from '../../../../../../../types'
import Button from '../../../../../../../components/Button'

interface ComponentProps {
  setShowNewPlanSidebar,
  group: Group
}

const Component = ({ setShowNewPlanSidebar, group }: ComponentProps) => {
  const dispatch = useDispatch()

  return (
    <Card>
      <Title>Todavia no has creado ningun Plan</Title>
      <div style={{ height: 8 }} />
      <SubTitle>Configura un precio para tu membresía</SubTitle>
      <div style={{ height: 18 }} />
      <Button
        options={{
          type: "filled",
          skin: "purple",
          size: "lg",
        }}
        onClick={() => setShowNewPlanSidebar(true)}
      >
        + Agregar precio
      </Button>
    </Card>
  )
}

const state = ({ groupStore, planStore }) => {
  const { data: group } = groupStore.group;
  const { data: allPlans, states: allPlansStates } = planStore.allPlans

  return {
    group,
    allPlans,
    allPlansStates
  };
};

export default connect(state)(Component);
