import Sidebar from "../../../../../../components/Sidebar";
import Button from "../../../../../../components/Button";
import Submit from "../../../../../../components/FormikForm/Submit";
import { Preview, Title, GrayBox } from "../styles";
import Card from "../../../components/Card";
import { DiscountDto } from "../../../../../../types/discount";
import { connect, useDispatch } from "react-redux";
import {
  updateDiscount,
  deleteDiscount,
} from "../../../../../../store/actions/discount";
import { useEffect } from "react";

interface ComponentProps {
  deleteDiscountStates: {
    success: boolean;
    error: string | boolean;
    loading: boolean;
  };
  coupon?: DiscountDto;
  onClose?: (data: any) => void;
  isVisible: boolean;
}

const Component = ({
  coupon,
  onClose,
  isVisible,
  deleteDiscountStates,
}: ComponentProps) => {
  const dispatch = useDispatch();

  const deleteCoupon = () => {
    if (coupon) {
      dispatch(deleteDiscount({ id: coupon?.id }));
    }
  };

  const archiveCoupon = () => {
    if (coupon) {
      dispatch(
        updateDiscount({
          ...coupon,
          status: "archived",
        })
      );
    }
  };

  useEffect(() => {
    if (deleteDiscountStates.success) {
      !!onClose && onClose(false);
    }
  }, [deleteDiscountStates]);

  return (
    <>
      <Sidebar
        position="right"
        visible={isVisible}
        closeType="back"
        onClose={() => {
          !!onClose && onClose(false);
        }}
        width="463px"
        title={
          !!coupon?.redemptions && coupon?.redemptions > 0
            ? "Pausar cupón"
            : "Eliminar cupón"
        }
      >
        <Preview>
          {!!coupon?.redemptions && coupon?.redemptions > 0 ? (
            <div>
              <p>
                Cuando pausas un cupón,{" "}
                <strong>
                  tu audiencia ya no podrá utilizar el código de descuento.
                </strong>{" "}
                Desde el apartado “Expirados” podrás volver a activarlo cuando
                lo desees.
              </p>
            </div>
          ) : (
            <div>
              <p>
                Cuando eliminas un cupón,{" "}
                <strong>
                  tu audiencia ya no podrá utilizar el código de descuento.
                </strong>{" "}
                Ten en cuenta que no podrás volver a recuperar o activar
                nuevamente este cupón.
              </p>
            </div>
          )}

          <div>
            <GrayBox>
              <Card coupon={coupon} key={coupon?.coupon_id} hidePreviewLink />
            </GrayBox>

            <Submit
              form=""
              options={{
                type: "filled",
                skin: "purple",
                size: "lg",
                block: true,
              }}
              style={{ marginBottom: "8px" }}
              isSubmmiting={deleteDiscountStates.loading}
              onClick={
                !!coupon?.redemptions && coupon?.redemptions > 0
                  ? archiveCoupon
                  : deleteCoupon
              }
            >
              {!!coupon?.redemptions && coupon?.redemptions > 0
                ? "Pausar cupón"
                : "Eliminar cupon"}
            </Submit>

            <Button
              options={{
                type: "outline",
                skin: "purple",
                size: "lg",
                block: true,
              }}
              onClick={() => !!onClose && onClose(false)}
            >
              Cancelar
            </Button>
          </div>
        </Preview>
      </Sidebar>
    </>
  );
};

const state = ({ discountStore }) => {
  const { states: deleteDiscountStates } = discountStore.deleteDiscount;

  return {
    deleteDiscountStates,
  };
};

export default connect(state)(Component);
