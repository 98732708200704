import * as Yup from "yup";

export const schema = Yup.object().shape({
  name: Yup.string().required("Debes indicar el nombre de la categoría."),
  description: Yup.string().nullable(),
});

interface FormValues {
  name: string;
  description: string;
}

export const initialValues: FormValues = {
  name: "",
  description: ""
};
