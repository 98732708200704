export const SHARE_SESSION_IN_TELEGRAM = "SHARE_SESSION_IN_TELEGRAM_SESSION";
export const SHARE_SESSION_IN_TELEGRAM_SUCCESS =
  "SHARE_SESSION_IN_TELEGRAM_SUCCESS";
export const SHARE_SESSION_IN_TELEGRAM_ERROR =
  "SHARE_SESSION_IN_TELEGRAM_ERROR";
export const RESET_SHARE_SESSION_IN_TELEGRAM =
  "RESET_SHARE_SESSION_IN_TELEGRAM";

export interface ShareSessionInTelegram {
  type: typeof SHARE_SESSION_IN_TELEGRAM;
  payload: { id: number };
}

export interface ShareSessionInTelegramError {
  type: typeof SHARE_SESSION_IN_TELEGRAM_ERROR;
  payload: false;
}

export interface ShareSessionInTelegramSuccess {
  type: typeof SHARE_SESSION_IN_TELEGRAM_SUCCESS;
  payload: null;
}

export interface ResetShareSessionInTelegram {
  type: typeof RESET_SHARE_SESSION_IN_TELEGRAM;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | ShareSessionInTelegram
  | ShareSessionInTelegramError
  | ShareSessionInTelegramSuccess
  | ResetShareSessionInTelegram;
