import { useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import GatewayCard from './components/GatewayCard'
import { EmptyPlanCard } from './styles'
import Button from '../../../../../components/Button'
import Grid from '../../../../../components/Grid'
import flagArgentina from '../../../../../constants/countries/flags/flag-AR.png'
import flagUnitedStates from '../../../../../constants/countries/flags/flag-US.png'
import flagEuropeanUnion from '../../../../../constants/countries/flags/flag-UE.png'
import { linkGateway } from '../../../../../store/actions/expert';
import { Gateway } from '../../../../../types/gateway';
import paymentIcon from '../../../../../assets/icons/payment.svg';
import Icon from '../../../../../components/Icon';

const currencies = [
  {
    flag: flagArgentina,
    iso: "ARS",
    label: "AR$",
    description: "Pesos Argentinos (ARS)",
  },
  {
    flag: flagEuropeanUnion,
    iso: "EUR",
    label: "€",
    description: "Euros (EUR)",
  },
  {
    flag: flagUnitedStates,
    iso: "USD",
    label: "US$",
    description: "Dolares Americanos (USD)",
  },
];

interface ComponentProps {
  userData;
  userStates;
  setShowNewGatewaySidebar
}

const Component = ({ userData, userStates, setShowNewGatewaySidebar }: ComponentProps) => {
  const dispatch = useDispatch()
  const [formSubmmited, setFormSubmmited] = useState(false);

  const isExpert = useMemo((): boolean => { return !!userData?.expert }, [userData]);
  const primaryGateway = useMemo((): Gateway => userData?.expert?.linked_gateways?.find(lg => lg.priority === "primary" && lg.currency === "ARS"), [userData])


  const onSubmit = (values) => {
    dispatch(linkGateway({ ...values }))
    window.location.href = "/profile/gateways"
  }

  if (!isExpert && !userStates.loading)
    window.location.href = "/profile/my-account"

  return (
    <>
      {!primaryGateway && (
        <Grid.Row>
          <Grid.Col>
            <Button
              style={{ gap: 8 }}
              options={{
                type: "filled",
                skin: "purple",
                size: "lg",
              }}
              onClick={() => setShowNewGatewaySidebar(true)}
            >
              <Icon icon={paymentIcon} size="24px" />
              Agregar nuevo gateway
            </Button>
          </Grid.Col>
        </Grid.Row>
      )}

      <div style={{ height: 23 }} />

      {!primaryGateway ? (
        <>
          <EmptyPlanCard style={{ maxWidth: 371 }}>Aun no has conectado ninguna pasarela de pago</EmptyPlanCard>
          <div style={{ height: 27 }} />
        </>
      ) : (
        <GatewayCard gateway={primaryGateway} />
      )}

    </>
  )
}

const state = ({ expertStore, userStore }) => {
  const { data: expert } = expertStore.linkGateway


  const { data: userData, states: userStates } = userStore.information;

  return {
    expert,
    userData,
    userStates
  };
};

export default connect(state)(Component);
