import { Formik } from "formik";
import { useEffect, useState, createRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";

import { Page, PageHeader, Title } from "../../components/styles";
import GoBack from "../../../../components/GoBack";
import Link from "../../../../components/Link";
import Submit from "../../../../components/FormikForm/Submit";
import { Group, User } from "../../../../types";
import Grid from "../../../../components/Grid";
import {
  initialValues,
  schema,
} from "../../../../constants/forms/sessions/edit";
import {
  getOneSession,
  newSession,
  resetNewSession,
  resetUpdateOneSession,
  updateSession,
} from "../../../../store/actions/session";
import {
  dateSpliter,
  inputDate,
  inputDateTimeLocal,
  setToZeroTimeZone,
} from "../../../../helpers/dates-hanlder";
import SessionForm from "../components/SessionForm";
import moment from "moment";
import { getGroupById } from "../../../../store/actions/group";
import { SessionInDto } from "../../../../types/session.dto";
import Snackbar from "../../../../components/Snackbar";
import Loader from "../../../../components/LogoLoader";
import { useLayout } from "../../../../providers/LayoutProvider";

interface ComponentProps {
  groupInformation: Group;
  groupStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  sessionInformation: SessionInDto;
  sessionStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  sessionEditStates: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
  match;
}

const Component = ({
  groupInformation,
  groupStates,
  sessionInformation,
  sessionStates,
  sessionEditStates,
  match,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const dispatch = useDispatch();
  const history = useHistory();
  const [videoUploaded, setVideoUploaded] = useState(false);
  const [groupId, setGroupId] = useState<number>();
  const [formSubmmited, setFormSubmmited] = useState(false);


  const onSubmit = ({ values, actions }) => {
    const { speaker_name, speaker_profile_pic, session_datetime,
      will_be_recorded, notificate_by_chat, notificate_by_email,
      recording_expiration_datetime, ...rest } = values;

    const useProfileInfo = values.use_profile_info.length > 0;

    setVideoUploaded(
      !sessionInformation.recorded_session_url && !!values.recorded_session_url
    );

    const remindersSettings = values['session_reminders_settings'].map(remSett => {
      return { ...remSett, enabled: remSett?.enabled?.includes("true"), exec_datetime: remSett?.exec_datetime ? setToZeroTimeZone(remSett.exec_datetime) : remSett.exec_datetime }
    })

    const recordingExpirationDate = (!!recording_expiration_datetime && recording_expiration_datetime !== "")
    ? setToZeroTimeZone(recording_expiration_datetime)
    : null

    dispatch(
      updateSession({
        ...rest,
        ...(!useProfileInfo && { speaker_name, speaker_profile_pic }),
        recording_expiration_datetime:recordingExpirationDate,
        use_profile_info: useProfileInfo,
        session_dt_in_local_timezone: session_datetime,
        session_datetime: setToZeroTimeZone(session_datetime),
        will_be_recorded: will_be_recorded?.includes("true") || false,
        notificate_by_chat: notificate_by_chat?.includes("true") || false,
        notificate_by_email: notificate_by_email?.includes("true") || false,
        session_reminders_settings: remindersSettings

      })
    );
  };

  useEffect(() => {
    if (!!match?.params) {
      setGroupId(Number(match?.params?.idGroup));
    }
  }, [match]);

  useEffect(() => {
    if (!!groupId) {
      groupInformation?.id !== groupId &&
        dispatch(getGroupById({ group_id: groupId }));
      sessionInformation?.id !== Number(match?.params?.session) &&
        dispatch(getOneSession({ idSession: Number(match?.params?.session) }));
    }
  }, [groupId, groupInformation]);

  useEffect(() => {
    if (!!sessionInformation && sessionEditStates.success) {
      setTimeout(() => {
        const groupId = groupInformation?.id;
        const sessionId = sessionInformation?.id;
        dispatch(resetUpdateOneSession());
        history.push(
          `/group/${groupId}/s/${sessionId}${videoUploaded ? "?video-uploaded=t" : ""
          }`
        );
      }, 3500);
    }
  }, [sessionInformation, sessionEditStates]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: "Editar encuentro",
        description: "Editar encuentro",
        back: `/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/sessions`,
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: true,
        actions: [
          <Link
            style={{ marginRight: "8px" }}
            options={{
              type: "outline",
              size: "lg",
              skin: "purple",
              native: true,
            }}
            disabled={groupStates.loading}
            loading={groupStates.loading}
            href={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/sessions`}
          >
            Cancelar
          </Link>,

          <Submit
            isSubmmiting={sessionEditStates.loading}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            form="edit-session-form"
          >
            Guardar cambios
          </Submit>,
        ],
      },
    });
  }, []);

  if (!sessionInformation) {
    return <Loader />;
  }

  return (
    <>
      <Grid.Container>
        <div style={{ height: "15px" }}></div>

        <Grid.Row>
          <Grid.Col lg={6}>
            <Formik
              initialValues={{
                ...initialValues,
                ...sessionInformation,
                ...(sessionInformation["cover_pic"] && {
                  cover_pic: [sessionInformation["cover_pic"]],
                }),
                ...(sessionInformation["speaker_profile_pic"] && {
                  speaker_profile_pic: [
                    sessionInformation["speaker_profile_pic"],
                  ],
                }),
                content_categories: sessionInformation.content_categories.map(
                  (category) => category.id
                ),
                use_profile_info: sessionInformation.use_profile_info
                  ? ["true"]
                  : [],
                notificate_by_chat: sessionInformation.notificate_by_chat
                  ? ["true"]
                  : [],
                notificate_by_email: sessionInformation.notificate_by_email
                  ? ["true"]
                  : [],
                session_datetime: inputDateTimeLocal(
                  sessionInformation?.session_datetime
                ),
                group: groupId,
                will_be_recorded: sessionInformation.will_be_recorded
                  ? ["true"]
                  : [],
                recording_expiration_datetime:
                  sessionInformation?.recording_expiration_datetime
                    ? inputDateTimeLocal(
                      sessionInformation?.recording_expiration_datetime
                    )
                    : "",
                  session_reminders_settings: sessionInformation?.session_reminders_settings.map(sett => ({...sett, exec_datetime: sett?.exec_datetime ? inputDateTimeLocal(sett?.exec_datetime) : null, enabled: sett?.enabled ? ['true'] : []}))
              }}
              onSubmit={(values, actions) => {
                if (onSubmit) onSubmit({ values, actions });
              }}
              validateOnChange={false}
              validateOnBlur={formSubmmited}
              validationSchema={schema}
              enableReinitialize
            >
              {({ handleSubmit, ...rest }) => {
                return (
                  <form
                    className="theme-form"
                    onSubmit={(event) => {
                      setFormSubmmited(true);
                      handleSubmit(event);
                    }}
                    id="edit-session-form"
                  >
                    <SessionForm {...rest} isEdit={true} />
                  </form>
                );
              }}
            </Formik>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col>
            <Link
              style={{ marginRight: "8px" }}
              options={{
                type: "outline",
                size: "lg",
                skin: "purple",
                native: true,
              }}
              href={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/sessions`}
            >
              Cancelar
            </Link>

            <Submit
              isSubmmiting={sessionEditStates.loading}
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
              }}
              form="edit-session-form"
            >
              Guardar cambios
            </Submit>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <Snackbar
        visible={sessionEditStates.success || !!sessionEditStates.error}
        options={{
          time: 2000,
          type: sessionEditStates.success ? "success" : "error",
        }}
      >
        {sessionEditStates.success
          ? "Encuentro editado."
          : "Error al editar el encuentro."}
      </Snackbar>
    </>
  );
};

const state = ({ groupStore, sessionStore }) => {
  const { data: sessionInformation, states: sessionStates } = sessionStore.one;
  const { states: sessionEditStates } = sessionStore.edit;
  const { data: groupInformation, states: groupStates } = groupStore.group;

  return {
    groupInformation,
    groupStates,
    sessionInformation,
    sessionStates,
    sessionEditStates,
  };
};

export default connect(state)(Component);
