import styled from "styled-components";
import { Dot } from "pure-react-carousel";

export const Testimonial = styled.div`
  margin-bottom: 80px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    margin-bottom: 150px;
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 32px;
  line-height: 46px;
  font-weight: 700;
  margin: 0px 0px 50px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 48px;
    line-height: 60px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    font-size: 50px;
    line-height: 72px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    flex-direction: row;
  }
`;
