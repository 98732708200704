import styled from "styled-components";
import Image from "../../../Image";
import { Link } from "react-router-dom";
import Icon from "../../../Icon";

const sidebarMenu = {
  opened: {
    width: 343,
    backgroundColor: "rgba(108, 92, 231, 0.16)",
  },
  closed: {
    width: 108,
    backgroundColor: "transparent",
  },
};

export const SidebarContent = styled("div")`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
`;

export const Navbar = styled("ul")`
  height: 66px;
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-align: center;
  justify-content: center;
  padding: 0px 16px;
  margin: 0px;
  width: 100%;
`;

export const NavbarItem = styled("li")`
  margin: 0px:
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const NavbarItemWrapper = styled("button") <{
  isActive;
  width;
}>`
  border: 0px;
  padding: 0px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 65px;
  height: 100%;

  cursor: pointer;
  transition: all 0.2s ease-in-out;
  // outline: 1px solid
  //   ${(props) =>
    props.isActive ? props.theme.colors.Primary : "transparent"};
  color: ${(props) => (props.isActive ? props.theme.colors.Primary : "inherit")};
  background-color: transparent;
  font-size: 12px;

  ${(props) =>
    !props.isActive
      ? `&:hover {
          background-color: rgba(249, 249, 249, 1);

          & > span {
            z-index: 10;
            visibility: visible;
            opacity: 1;
            background-color: #6b5ce7;
            color: white;
            margin-left: 16px;

          }
        }`
      : ""}

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
`;

// export const NavbarItemWrapper = styled("button")<{
//   isOpened;
//   isActive;
//   width;
// }>`
//   background-color: ${(props) =>
//     props.isActive ? props.theme.colors.Primary : "transparent"};
//   display: flex;
//   align-items: center;
//   justify-content: ${(props) =>
//     !props.isOpened && props.isActive ? "center" : "flex-start"};
//   border: 0px;
//   width: ${(props) => (props.width ? "36px" : "100%")};
//   height: 48px;
//   border-radius: 6px;
//   padding: 10px 25px;
//   cursor: pointer;
//   transition: all 0.2s ease-in-out;
//   outline: 1px solid
//     ${(props) => (props.isActive ? props.theme.colors.Primary : "transparent")};

//   &:hover {
//     outline: 1px solid ${(props) => props.theme.colors.Primary};

//     & > span {
//       z-index: 10;
//       visibility: visible;
//       opacity: 1;

//       ${(props) => {
//         return !props.isOpened
//           ? `
//           background-color: #6b5ce7;
//           color: white;
//           margin-left: 16px;
//         `
//           : "";
//       }}
//     }
//   }
// `;

export const NavbarItemLabel = styled("span") <{ isActive }>`
  display: block;
  width: 100%;
  margin-left: 8px;
  white-space: nowrap;
  font-weight: normal;
  font-size: 18px;
  text-align: left;
  color: ${({ isActive, theme }) =>
    isActive ? "white" : theme.colors.Black75};
  padding: "4px 25px;
  line-height: "27px;
  border-radius: "5px;
`;

export const Triangle = styled(Icon)`
  display: block;
  position: absolute;
  right: calc(100% + 7px);
  top: 50%;
  transform: translate(8px, -50%);
`;

export const Profile = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 50px;
`;

export const AvatarImage = styled("img")`
  width: 72px;
  height: 72px;
  border-radius: 100%;
  margin-bottom: 0px;
  outline: 2px solid white;
`;

export const Name = styled("p")`
  margin: 0px;
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
`;

export const Username = styled("p")`
  margin: -3px 0px 0px;
  font-size: 16px;
  line-height: 20px;
`;

export const Mark = styled("div") <{ isActive }>`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${({ isActive }) => (isActive ? "white" : "#6c5ce7")};
  flex-shrink: 0;
  position: absolute;
  top: 9px;
`;
