import styled from 'styled-components'

export const GrayBox = styled("div")`
  margin-bottom: 20px;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 6px;
  padding: 19px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);

  p {
    &:first-child {
      margin-top: 0px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const Title = styled("div")`
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  color: black;
`;
