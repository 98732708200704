import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import { sharePostInTelegram } from "../../../../../store/actions/post";
import { Post } from "../../../../../types/post";


interface ComponentProps {
  postInformation: Post;
  sharePostInTelegramStates: {
        success: boolean;
        loading: boolean;
        error: boolean | string;
  };
}

const Component = ({ postInformation, sharePostInTelegramStates }: ComponentProps) => {
  const dispatch = useDispatch();
    const platformName = {
        telegram: "Telegram",
        whatsapp: "Whatsapp",
    };

  return (
    <Modal name="modal-share-post-in-telegram" width="650" onClose={() => {}}>
      <Title>¡Genial! Estás por compartir tu contenido en {
          platformName[
              postInformation?.group?.telegram_groups[0]?.platform
              ] || "Grupo de chat"
      }✨</Title>

      <Text>
        Ten en cuenta que este contenido aparecerá dentro del canal del taller y
        sólo podrán acceder las personas que se inscribieron.
      </Text>

      <AlignEnd>
        <Button
          options={{
            type: "outline",
            skin: "purple",
            size: "lg",
          }}
          onClick={() => dispatch(hideModal())}
          style={{ marginRight: "16px" }}
        >
          Cancelar
        </Button>

        <Button
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
          }}
          loading={sharePostInTelegramStates.loading}
          onClick={() =>
            dispatch(sharePostInTelegram({ id: postInformation?.id }))
          }
        >
            Compartir en {
            platformName[
                postInformation?.group?.telegram_groups[0]?.platform
                ] || "Grupo de chat"
            }
        </Button>
      </AlignEnd>
    </Modal>


  );
};

const state = ({ postStore}) => {
  const { data: postInformation } = postStore.post;
  const { states: sharePostInTelegramStates } = postStore.sharePost;


    return { postInformation, sharePostInTelegramStates };
};



export default connect(state)(Component);
