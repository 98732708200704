import {
  initialState,
  Actions,
  State,
  GET_USER_SUBSCRIPTIONS,
  GET_USER_SUBSCRIPTIONS_SUCCESS,
  GET_USER_SUBSCRIPTIONS_ERROR,
  GET_USER_FREE_SUBSCRIPTIONS,
  GET_USER_FREE_SUBSCRIPTIONS_SUCCESS,
  GET_USER_FREE_SUBSCRIPTIONS_ERROR,
} from "../../types/user/get-user-subscriptions";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_USER_SUBSCRIPTIONS:
      return {
        ...state,
        data: state.data,
        states: {
          success: false,
          loading: true,
          error: false,
        },
      };
    case GET_USER_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          success: true,
          loading: false,
          error: false,
        },
      };
    case GET_USER_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        data: null,
        states: {
          success: false,
          loading: false,
          error: action.payload,
        },
      };
    case GET_USER_FREE_SUBSCRIPTIONS:
      return {
        ...state,
        data: state.data,
        states: {
          success: false,
          loading: true,
          error: false,
        },
      };
    case GET_USER_FREE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          success: true,
          loading: false,
          error: false,
        },
      };
    case GET_USER_FREE_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        data: null,
        states: {
          success: false,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducers;
