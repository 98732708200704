import {
  GroupCard,
  GroupCardHeader,
  GroupCardBody,
  GroupCardFooter,
  ExpertName,
  GroupName,
  GroupTitle,
  GroupCardAvatar,
} from "./styles";
import Link from "./../Link";
import { Expert, Group } from "../../types";
interface PropsInterface {
  group: Group;
  expert: Expert;
  link: string;
}

const Component = ({ group, expert, link }: PropsInterface) => {
  return (
    <GroupCard>
      <GroupCardHeader>
        <GroupCardAvatar
          src={expert?.profile_pic?.url}
          alt="Profile Logo"
          width="50px"
          height="50px"
        />
        <div>
          <ExpertName>{group?.creator_name}</ExpertName>
          <GroupName>{group?.group_name}</GroupName>
        </div>
      </GroupCardHeader>

      <GroupCardBody>
        <GroupTitle>{group?.group_name}</GroupTitle>
      </GroupCardBody>

      <GroupCardFooter>
        <Link
          href={link}
          options={{
            skin: "purple",
            size: "md",
            type: "filled",
          }}
        >
          Acceder
        </Link>
      </GroupCardFooter>
    </GroupCard>
  );
};

export default Component;
