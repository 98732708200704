import {
  ExportSurveyOutDto,
  ExportSurveyInDto,
} from "../../../types/survey.dto";

export const EXPORT_SURVEY_ANSWERS = "EXPORT_SURVEY_ANSWERS";
export const EXPORT_SURVEY_ANSWERS_SUCCESS = "EXPORT_SURVEY_ANSWERS_SUCCESS";
export const EXPORT_SURVEY_ANSWERS_ERROR = "EXPORT_SURVEY_ANSWERS_ERROR";
export const RESET_EXPORT_SURVEY_ANSWERS = "RESET_EXPORT_SURVEY_ANSWERS";

export interface ExportSurveyAnswers {
  type: typeof EXPORT_SURVEY_ANSWERS;
  payload: ExportSurveyOutDto;
}

export interface ExportSurveyAnswersSuccess {
  type: typeof EXPORT_SURVEY_ANSWERS_SUCCESS;
  payload: ExportSurveyInDto;
}

export interface ExportSurveyAnswersError {
  type: typeof EXPORT_SURVEY_ANSWERS_ERROR;
  payload: string | boolean;
}

export interface ResetExportSurveyAnswers {
  type: typeof RESET_EXPORT_SURVEY_ANSWERS;
  payload: null;
}

export interface State {
  data: ExportSurveyInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | ExportSurveyAnswers
  | ExportSurveyAnswersSuccess
  | ExportSurveyAnswersError
  | ResetExportSurveyAnswers;
