import { CSSProperties } from "remirror";
import { Loader, Circle } from "./styles";

interface ComponentProps {
  color?: string;
  width?: number;
  style?: CSSProperties
}

const Component = ({ color, width, ...rest }: ComponentProps) => {
  return (
    <Loader width={width} {...rest}>
      <Circle color={color} width={width}></Circle>
      <Circle color={color} width={width}></Circle>
      <Circle color={color} width={width}></Circle>
      <Circle color={color} width={width}></Circle>
    </Loader>
  );
};

export default Component;
