import { EditWelcomeContentSectionOutDto, EditWelcomeContentSectionInDto } from "../../../types/welcome-section";
export const EDIT_WELCOME_CONTENT_SECTION = "EDIT_WELCOME_CONTENT_SECTION";
export const EDIT_WELCOME_CONTENT_SECTION_SUCCESS = "EDIT_WELCOME_CONTENT_SECTION_SUCCESS";
export const EDIT_WELCOME_CONTENT_SECTION_ERROR = "EDIT_WELCOME_CONTENT_SECTION_ERROR";
export const RESET_EDIT_WELCOME_CONTENT_SECTION = "RESET_EDIT_WELCOME_CONTENT_SECTION";

export interface EditWelcomeContentSection {
  type: typeof EDIT_WELCOME_CONTENT_SECTION;
  payload: EditWelcomeContentSectionOutDto;
}

export interface EditWelcomeContentSectionSuccess {
  type: typeof EDIT_WELCOME_CONTENT_SECTION_SUCCESS;
  payload: EditWelcomeContentSectionInDto;
}

export interface EditWelcomeContentSectionError {
  type: typeof EDIT_WELCOME_CONTENT_SECTION_ERROR;
  payload: string | boolean;
}

export interface ResetEditWelcomeContentSection {
  type: typeof RESET_EDIT_WELCOME_CONTENT_SECTION;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | EditWelcomeContentSection
  | EditWelcomeContentSectionSuccess
  | EditWelcomeContentSectionError
  | ResetEditWelcomeContentSection;
