import styled from "styled-components";

export const Monetize = styled.div`
  background: ${(props) => props.theme.colors.PurpleStrong};
  margin: 0px 0px 80px;
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  border-radius: 18px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin: 0px 0px 80px;
    border-radius: 0px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    padding: 90px 0px;
    margin-bottom: 150px;
    flex-direction: row;
  }
`;

export const Guide = styled.div`
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.PurpleVeryLight};
  text-align: start;
  margin: 0px 0px 10px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.lgMin}) {
    margin: 0px 0px 20px 0px;
  }
`;

export const Title = styled.h2`
  font-size: 36px;
  line-height: 46px;
  margin: 0px 0px 60px;
  font-weight: 700;
  text-align: start;
  color: white;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    margin: 0px 0px 60px;
    font-size: 50px;
    line-height: 72px;
  }
`;
