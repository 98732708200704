import { Expert } from "../../../types";

export const UPDATE_EXPERT_INFORMATION = "UPDATE_EXPERT_INFORMATION";
export const UPDATE_EXPERT_INFORMATION_SUCCESS = "UPDATE_EXPERT_INFORMATION_SUCCESS";
export const UPDATE_EXPERT_INFORMATION_ERROR = "UPDATE_EXPERT_INFORMATION_ERROR";
export const RESET_UPDATE_EXPERT_INFORMATION = "RESET_UPDATE_EXPERT_INFORMATION";

export interface UpdateExpertInformation {
  type: typeof UPDATE_EXPERT_INFORMATION;
  payload: Expert;
}

export interface UpdateExpertInformationSuccess {
  type: typeof UPDATE_EXPERT_INFORMATION_SUCCESS;
  payload: Expert;
}

export interface UpdateExpertInformationError {
  type: typeof UPDATE_EXPERT_INFORMATION_ERROR;
  payload: boolean | string;
}

export interface ResetUpdateExpertInformation {
  type: typeof RESET_UPDATE_EXPERT_INFORMATION;
  payload: null
}

export interface State {
  data: Expert | null,
  states: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    error: false,
    success: false,
  },
};

export type Actions =
  | UpdateExpertInformation
  | UpdateExpertInformationSuccess
  | UpdateExpertInformationError
  | ResetUpdateExpertInformation;
