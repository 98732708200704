import styled from "styled-components";

const pages = {
  GROUP_DETAILS: "35px",
  NEW_MEMBERSHIP: "110px",
  NEW_EXPERIENCE: "110px",
};

export const WhatsApp = styled("a") <{ floating; currentPage; marginBottom?}>`
  background: rgba(111, 207, 151, 1);
  box-shadow: 0px 8px 24px rgba(38, 50, 56, 0.1),
    0px 16px 32px rgba(38, 50, 56, 0.08);
  text-shadow: 0px 2px 4px rgba(38, 50, 56, 0.16),
    0px 4px 8px rgba(38, 50, 56, 0.08);
  letter-spacing: 0.25px;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  color: white;
  border-radius: 16px;
  min-width: 36px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin-bottom: ${({ marginBottom }) => marginBottom || 24}px;

  ${(props) =>
    props.floating
      ? `
    display: scroll;
    position: fixed;
    z-index: 120;
    right: 16px;
    bottom: ${pages[props.currentPage] || `35px`};
`
      : ``}

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    min-width: 110px;
    ${(props) =>
    props.floating
      ? `bottom: ${pages[props.currentPage] || "0px"};`
      : `bottom: 0px;`}
  }
`;

export const Icon = styled.img`
  margin-right: 0px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    margin-right: 8px;
  }
`;
