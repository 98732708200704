import { combineReducers } from "redux";
import newOne from "./new-one";
import update from "./edit";
import deleteOne from "./delete-one";
import selected from "./get-one";
import all from "./get-all";
import allAnswers from "./all-answers";
import newAnswer from "./new-answer";
import exportSurveyAnswers from "./export-survey-answers";

const reducer = combineReducers({
  selected,
  all,
  newOne,
  update,
  deleteOne,
  allAnswers,
  newAnswer,
  exportSurveyAnswers,
});

export default reducer;
