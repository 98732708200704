import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';


const SpinnerButton = ({ isLoading, children }) => {
  return (
    isLoading ? <Spinner /> : children || <React.Fragment />
  );
};

export default SpinnerButton;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: white;
  animation: ${spin} 1s linear infinite;
`;
