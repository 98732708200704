import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import Link from "../../../../../components/Link";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import { Center } from "../../../../../components/StyledComponents";
import { useWindowSize } from "../../../../../hooks/useWindowSize";

interface ComponentProps {
  title: string
  text: string
  buttons: {
    label: string
    link: string
    target?: '_self' | '_blank'
  }[]
  showCancel?: boolean
}


const Component = ({ title, text, buttons, showCancel = false }: ComponentProps) => {
  const { isMobile } = useWindowSize()
  const dispatch = useDispatch();

  return (
    <Modal name="modal-go-to-preview" width="650" onClose={() => { }}>
      <Title>{title}</Title>

      <Text>
        {text}
      </Text>

      <Center horizontal="right" vertical="center" gap={8} column={isMobile}>
        {showCancel && (

          <Button
            options={{
              type: "outline",
              skin: "purple",
              size: "lg",
            }}
            onClick={() => dispatch(hideModal())}
          >
            Cacenlar
          </Button>
        )}

        {buttons.map(button => {
          const { link, label, target = '_self' } = button
          return (
            <Link
              options={{
                type: "filled",
                skin: "purple",
                size: "lg",
                block: isMobile
              }}
              target={target}
              href={link}
            >
              {label}
            </Link>
          )
        })}
      </Center>
    </Modal>
  );
};

const state = ({ discountStore }) => {
  const { data: allDiscounts, states: allDiscountsStates } =
    discountStore.allDiscounts;
  const { data: newDiscount, states: newDiscountStates } =
    discountStore.newDiscount;

  return { allDiscounts, allDiscountsStates, newDiscount, newDiscountStates };
};

export default connect(state)(Component);
