import styled from 'styled-components';

export const Thumbnail = styled('button')`
  background: none;
  border: 0px;
  padding: 0px;
  cursor: pointer;
`

export const ImageWrapper = styled('div') <{ active }>`
  outline: 2px solid ${({ active, theme }) => active ? theme.colors.Primary : 'transparent'};
`

export const Image = styled('canvas') <{ active }>`
  display: block;
  border: 1px solid ${({ active, theme }) => active ? theme.colors.Primary : 'black'}; 
  width: 100%;
  display: block;
`

export const PageNumber = styled('div') <{ active }>`
 fontSize: 12px;
 textAlign: center;
 margin: 15px 0px;
 color: ${({ active, theme }) => active ? theme.colors.Primary : 'black'};
`