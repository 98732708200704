import { connect, useDispatch } from 'react-redux'
import { Group } from '../../../../../types'
import { CategoryDto } from '../../../../../types/category'
import Icon from '../../../../../components/Icon'

import {
  Card,
  Title,
  Description,
  Button,
  Actions
} from './styles'


import DragAndDropIcon from '../../../../../assets/icons/sort-icon-2.svg'
import BookIcon from '../../../../../assets/icons/book.svg'
import DeleteIcon from '../../../../../assets/icons/trash.svg'
import EditIcon from '../../../../../assets/icons/edit-light.svg'
import { showModal } from '../../../../../store/actions/modal'

interface ComponentProps {
  group: Group
  category?: CategoryDto
  setSelectedCategoryToBeDeleted
  setSelectedCategorToEdit,
  showActions?: boolean;
}

const Component = ({
  group,
  category,
  setSelectedCategoryToBeDeleted,
  setSelectedCategorToEdit,
  showActions = true,
}: ComponentProps) => {
  const dispatch = useDispatch()

  return (
    <div style={{ display: 'flex', gap: 10 }}>
      <Card>
        <Icon icon={DragAndDropIcon} size="40px" />
        <div>
          <Title>{category?.name}</Title>
          <div style={{ height: 3 }} />
          <Description>
            <Icon icon={BookIcon} size="20px" color='#69779B' />
            Contiene {category?.count_posts} artículos
          </Description>
        </div>
      </Card >

      {showActions && (
        <Actions>
        <Button
          onClick={() => setSelectedCategorToEdit(category)}
        >
          <Icon
            icon={EditIcon}
            size="16px"
            color="black"
          />
        </Button>

        <Button
          onClick={() => {
            setSelectedCategoryToBeDeleted(category)
          }}
        >
          <Icon icon={DeleteIcon} size="16px" color="black" />
        </Button>
      </Actions>
      )}
    </div>
  )
}

const state = ({ groupStore, categoryStore }) => {
  const { data: group, states: groupStates } =
    groupStore.group;

  return {
    group,
  };
};

export default connect(state)(Component);