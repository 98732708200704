import styled from 'styled-components';
import { Field as UiField } from "../Field";

export const Slider = styled('span') <{ checked }>`
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ checked, theme }) => (checked ? theme.colors.Primary : '#ccc')};
  transition: 0.4s;
  border-radius: 34px;
  height: 24px;
  width: 50px;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: ${({ checked }) => (checked ? '30px' : '4px')};
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }
`;

export const Input = styled('input')`
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;

  &:checked + ${Slider} {
    background-color: ${({ theme }) => theme.colors.Primary};
  }

  &:checked + ${Slider}:before {
    transform: translateX(0px);
  }
`;

export const HelperText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  color: rgba(36, 40, 44, 0.76);
`;

export const HelperLine = styled("div") <{ marginBottom }>`
  line-height: 16px;
  // position: absolute;
  // top: 100%;
  // left: 0px;
  // width: 100%;
  padding: 0px;
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  min-height: 15px;
`;

export const Field = styled(UiField)`
  border: none;
  background: transparent;
  padding: 0;
  flex-direction: column;
  align-items: start;
`;

export const SwitchLabel = styled("label") <{
  disabled;
  size;
  withLabel;
  theme;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) =>
    props.disabled ? "rgba(0, 0, 24, 0.16)" : "rgba(0, 0, 24, 0.84)"};
  min-height: ${(props) => (props.size === "small" ? "16px" : "20px")};
  z-index: 2;

  font-size: ${(props) => props.theme.form.size[props.size].mobileFontSize};
  line-height: ${(props) => props.theme.form.size[props.size].mobileLineHeight};

  @media (min-width: 768px) {
    font-size: ${(props) => props.theme.form.size[props.size].fontSize};
    line-height: ${(props) => props.theme.form.size[props.size].lineHeight};
  }
`;

export const Center = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`