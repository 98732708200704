export const makeGlobalStyles = (props: any) => {
  return `
  
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700&family=Inconsolata:wght@200&family=Playfair+Display:wght@400;500;700&display=swap');

  ${props.theme.fonts.fontFace}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${props.theme.fonts.family};
    font-size: ${props.theme.fonts.sizes.base};
    font-weight: 400;
    line-height: 19px;
    color: ${props.theme.colors.Black};
    text-align: left;
    background-color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  a,
  p,
  button,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  sup,
  sub,
  span,
  div,
   {
    font-family: ${props.theme.fonts.family};
  }`;
};
