import { Menssage, Image, Title, Text } from "./styles";
import Comillas from "../../../../assets/home-page/quotation-marks.svg";
import Grid from "../../../../components/Grid";
import Row from "../../../../components/Grid/Row";
import Col from "../../../../components/Grid/Col";

const Component = () => {
  return (
    <Grid.Container>
      <Row>
        <Col offset={{ md: 1 }} md={10}>
          <Menssage>
            <Image src={Comillas} alt="" width="68px" height="48px" lazy={true}/>
            <Title>"Nadie es como tú y ese es tu poder"</Title>
            <Text>
              <span>
                En Klouser entendemos todo tu esfuerzo y dedicación para ayudar
                a las personas, por eso decidimos crear un espacio seguro en
                donde tu negocio pueda seguir creciendo de manera saludable y
                sostenible.
              </span>
              <br />
              <br />
              Con nuestra plataforma podrás fidelizar tu audiencia y
              transformarla en una comunidad que vibre en tu misma frecuencia. A
              través de un mágico recorrido y tus dinámicas interactivas
              lograrás afianzar vínculos reales.
            </Text>
          </Menssage>
        </Col>
      </Row>
    </Grid.Container>
  );
};

export default Component;
