import {
  initialState,
  Actions,
  State,
  LINK_GATEWAY,
  LINK_GATEWAY_ERROR,
  LINK_GATEWAY_SUCCESS
} from "../../types/expert/link-gateway";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case LINK_GATEWAY:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case LINK_GATEWAY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case LINK_GATEWAY_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default reducers;
