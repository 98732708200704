import { Post } from "../../../types/post";
export const EDIT_POST = "EDIT_POST";
export const EDIT_POST_SUCCESS = "EDIT_POST_SUCCESS";
export const EDIT_POST_ERROR = "EDIT_POST_ERROR";
export const RESET_EDIT_POST = "RESET_EDIT_POST";

export interface EditPost {
  type: typeof EDIT_POST;
  payload: Post;
}

export interface EditPostSuccess {
  type: typeof EDIT_POST_SUCCESS;
  payload: null;
}

export interface EditPostError {
  type: typeof EDIT_POST_ERROR;
  payload: string | boolean;
}

export interface ResetEditPost {
  type: typeof RESET_EDIT_POST;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | EditPost
  | EditPostSuccess
  | EditPostError
  | ResetEditPost;
