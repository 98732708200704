import { useModules } from "../../../../../../providers/ModulesProvider";
import orderIcon from "../../../../../../assets/icons/order.svg";
import {
  DragDropContext,
  Droppable,
  DroppableProps,
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import Button from "../../../../../../components/Button";
import { Card } from "../../styles";
import ModuleCta from "../ModuleCta";
import { List, ListItem } from "./styles";
import { useEffect, useState } from "react";
import { useWindowSize } from "../../../../../../hooks/useFormHook";

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

interface ComponentProps { }

const Component = ({ }: ComponentProps) => {
  const { isMobile } = useWindowSize()
  const id = "module-list";
  const { modules, actions, reorderOn, setReorderOn } = useModules();

  return (
    <Card
      style={{ width: "100%", maxWidth: isMobile ? '100%' : "317px", position: isMobile ? "static" : "sticky", top: 0 }}
    >
      {!reorderOn && (
        <>
          <Button
            options={{
              type: "outline",
              skin: "lightBlue",
              size: "lg",
              block: true,
            }}
            onClick={() => actions.create.exec()}
          >
            Nuevo módulo
          </Button>

          <div style={{ height: 7 }} />
        </>
      )}

      <Button
        options={{
          type: reorderOn ? "filled" : "outline",
          skin: "lightBlue",
          size: "lg",
          block: true,
        }}
        onClick={() => setReorderOn(!reorderOn)}
      >
        {reorderOn
          ? `Finalizar orden de módulos (${modules?.length})`
          : `Reordenar módulos (${modules?.length})`}
      </Button>

      <div style={{ height: 24 }} />

      <hr />

      <div style={{ height: 24 }} />

      <DragDropContext onDragEnd={actions.reorder.exec}>
        <StrictModeDroppable droppableId={id}>
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <List key={id} {...provided.droppableProps} ref={provided.innerRef}>
              {modules?.map((module, index) => (
                <Draggable
                  key={"module-" + module.id}
                  draggableId={"module-" + module.id}
                  index={index}
                  isDragDisabled={!reorderOn}
                >
                  {(
                    providedDraggable: DraggableProvided,
                    snapshotDraggable: DraggableStateSnapshot
                  ) => (
                    <ListItem
                      {...providedDraggable.draggableProps}
                      {...providedDraggable.dragHandleProps}
                      ref={providedDraggable.innerRef}
                    >
                      <ModuleCta
                        module={module}
                        {...(reorderOn && { icon: orderIcon })}
                      />
                    </ListItem>
                  )}
                </Draggable>
              ))}

              {provided.placeholder}
            </List>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </Card>
  );
};

export default Component;
