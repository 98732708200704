import styled from "styled-components";
import UiButton from "../../../components/Button";

export const Section = styled.div`
  margin-bottom: 32px;
`;

export const FormTitle = styled.h1`
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 37px;
  color: ${(props) => props.theme.colors.Black};
  font-weight: 600;
  text-align: center;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 36px;
    line-height: 44px;
    text-align: left;
    margin-bottom: 49px;
  }
`;

export const Title = styled.h1`
  font-size: 16px;
  line-height: 19px;
  margin: 0px 0px 23px 0px;
  color: ${(props) => props.theme.colors.Black};
  font-weight: 600;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 20px;
    line-height: 24px;
  }
`;
export const Data = styled.h1`
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 6px;
  color: ${(props) => props.theme.colors.Black};
  font-weight: 400;
`;

export const UserEmail = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const UserPassword = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const Username = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const Button = styled(UiButton)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;


export const SelectedColorShape = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.color};
  // border-radius: 100%;
  border: 1px solid #000;
  color: white;
`;


export const ImageCard = styled('img')`
  height: 20px;
`
