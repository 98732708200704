import { useMemo } from 'react';
import { getFreeSubscriptions } from '../../../../helpers/getFreeSubscriptions';
import { isSubscribed } from '../../../../helpers/isSubscribed';
import { Group } from '../../../../types';
import Link from '../../../../components/Link'
import {
  Card,
  Title,
  Text
} from './styles'
import { connect } from 'react-redux';
import MPRetryingSubscriptionButton from '../MPRetryingSubscriptionButton';
import { getPriorityGroupSubscription } from '../../../../helpers/mpStatusPriority';

interface ComponentProps {
  group: Group;
  userSubscriptions
  isLoggedIn: Boolean;
}

const Component = ({
  group,
  userSubscriptions,
  isLoggedIn,
}: ComponentProps) => {

  const isUserAuthenticated = useMemo(() => {
    return isLoggedIn || group?.group_type === 'free_experience'
  }, [isLoggedIn, group])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(userSubscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [userSubscriptions, group, isLoggedIn])

  const subscription = useMemo(() => {
    return getPriorityGroupSubscription(userSubscriptions, group?.id)
  }, [userSubscriptions, group])

  const isDisapprovedPayment = useMemo(() => {
    return subscription?.status === 'not_paid'
  }, [subscription])

  const isPaidSubscription = useMemo(() => {
    const freeSubscriptionTypes = ["guest", "free"]
    return !freeSubscriptionTypes?.includes(subscription?.type)
  }, [subscription])

  if (!isUserAuthenticated || !isUserSubscribed || !isDisapprovedPayment || !isPaidSubscription) {
    return <></>
  }

  return (
    <Card>
      <Title>Tu pago ha fallado</Title>
      <div style={{ height: 18 }} />
      <Text>No pudimos procesar tu pago.<br />Haremos reintentos en los proximos dias o bien puedes probar con otro método de pago.<br /><br />Hemos pausado tu acceso al contenido hasta<br />regularizar el cobro</Text>
      <div style={{ height: 18 }} />
      <MPRetryingSubscriptionButton />
    </Card>
  )
}


const state = ({ userStore, groupStore }) => {
  const { data: group } = groupStore.group;
  const { data: userSubscriptions } = userStore.subscriptions;
  const { isLoggedIn } = userStore;

  return {
    group,
    userSubscriptions,
    isLoggedIn,
  };
};

export default connect(state)(Component);
