import Select from "../../../Form/Select";
import Modal from "../../../Modal";
import Form from "../../../Form";
import Error from "../../../Form/Error";
import Button from "../../../Button";
import Image from "../../../Image";
import constants from "../../../../constants";
import { Title, Information } from "./styles";
import { connect, useDispatch } from "react-redux";
import { updateExpertInformation } from "../../../../store/actions/user";
import { useEffect } from "react";
import { showModal, hideModal } from "../../../../store/actions/modal";
import { getUserInformation } from "../../../../store/actions/user";

const Label = ({ country }) => {
  return (
    <>
      <Image src={country.flag} width="15px" height="15px" alt={""} />{" "}
      {country.es}
    </>
  );
};

const Component = ({ userInformation, updateExpertInformationState }) => {
  const { loading, success, error } = updateExpertInformationState;
  const dispatch = useDispatch();

  const onSubmit = ({ data, errors, setError }) => {
    const currency = constants.countries.filter(
      (country) => country.code === data.country.value
    )[0];

    dispatch(
      updateExpertInformation({
        billing_info: { country: data.country.value },
        currency: currency?.currency?.iso,
      })
    );

    dispatch(showModal("modal-phone-number"));
  };

  useEffect(() => {
    if (
      !!userInformation &&
      !!userInformation?.expert &&
      !userInformation?.expert?.billing_info?.country
    ) {
      dispatch(showModal("select-your-country"));
    }
  }, [userInformation]);

  return (
    <Modal
      name="select-your-country"
      width="580"
      showClose={false}
      onClose={() => { }}
    >
      <Title>
        ¿En qué parte del <br />
        mundo te encuentras?
      </Title>
      <Information>
        Esta información es muy importante para que luego <br /> podamos
        enviarte tus ingresos.
      </Information>

      <Form onSubmit={onSubmit} id="expert-country-form">
        <Select
          name="country"
          options={{
            data: constants.countries
              .filter((country) => country.enabled)
              .map((country) => {
                return {
                  value: country.code,
                  label: <Label country={country} />,
                };
              }),
            textField: "es",
            valueField: "code",
          }}
          placeholder="País donde vives"
          reactSelectOptions={{ isClearable: true, isSearchable: true }}
          rules={{
            required: true,
          }}
        />

        {error && <Error>Error al actualizar el perfil.</Error>}

        <Button
          type="submit"
          form="expert-country-form"
          disabled={loading}
          loading={loading}
          options={{
            skin: "purple",
            size: "lg",
            type: "filled",
          }}
        >
          Confirmar
        </Button>
      </Form>
    </Modal>
  );
};

const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;
  const { states: updateExpertInformationState } =
    userStore.updateExpertInformation;
  return {
    updateExpertInformationState,
    userInformation,
  };
};

export default connect(state)(Component);
