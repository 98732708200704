import CopyToClipboad from '../../../../../../../../components/CopyToClipboad'
import { Center } from "../../../../../../../../components/StyledComponents";
import Icon from '../../../../../../../../components/Icon'
import copyIcon from '../../../../../../../../assets/icons/copy.svg'
import { Title, Text, Box } from '../../styles'
import { Group } from '../../../../../../../../types';
import { connect } from "react-redux";


interface ComponentProps {
  group: Group,
}

const Component = ({ group } : ComponentProps) => {
  return (
    <>
      <Title>¡Ya hemos enviado los accesos vía mail 🙌!</Title>
      <div style={{ height: 26 }} />
      <Text>Aquí te dejamos un mensaje opcional que puedes compartir con tus invitados a través de WhatsApp:</Text>
      <div style={{ height: 20 }} />
      <Box style={{ padding: 10, height: "auto", maxHeight: "none" }}>
        <p style={{ marginTop: 0 }}>¡Hola! Te he enviado el Acceso a mi {group?.group_type === "Membership" ? "membresia" : "taller"} a tu Correo Electrónico 🎉. Si no lo encuentras, recuerda revisar la carpeta de Spam para asegurarte de haberlo recibido</p>
        <p style={{ marginBottom: 0 }}>También puedes acceder con el siguiente enlace <a href="https://klouser.app/subscriber/signup">https://klouser.app/subscriber/signup</a>, regístrate (o si ya tienes una cuenta inicia sesión) y listo!</p>
      </Box>
      <div style={{ height: 20 }} />
      <Center vertical="center" horizontal="right" gap={5}>
        <CopyToClipboad
          copy={`¡Hola! Te he enviado el Acceso a mi ${group?.group_type === "Membership" ? "Membresia" : "Taller"} a tu Correo Electrónico 🎉. Si no lo encuentras, recuerda revisar la carpeta de Spam para asegurarte de haberlo recibido.\n\nTambién puedes acceder con el siguiente enlace https://klouser.app/subscriber/signup, regístrate (o si ya tienes una cuenta inicia sesión) y listo!`}
          iconColor="white"
          style={{
            borderRadius: "6px",
            border: "1px solid #6C5CE7",
            background: "#6C5CE7",
            color: "white",
            padding: "12px 20px",
            fontSize: "14px",
            fontWeight: 600,
            minWidth: 225,
            textAlign: "center"
          }}
        >
          <Center vertical="center" horizontal="center" gap={5}>
            <Icon icon={copyIcon} color="#FFFFFF" size="20px" />
            Copiar texto de invitacion
          </Center>
        </CopyToClipboad>
      </Center>
    </>
  )
}

const state = ({ groupStore, invitationStore }) => {
  const { data: group } = groupStore.group;

  return {
    group,
  };
};


export default connect(state)(Component);
