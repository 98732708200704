import Sidebar from "../../../../../../components/Sidebar";
import Button from "../../../../../../components/Button";
import {
  Preview,
  Text,
  Total,
  LetterGroup,
  Letter,
  Email,
  Delete,
  VerticalScroll,
} from "./styles";
import { connect, useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { getCommunitySubscribersEmails } from "../../../../../../store/actions/community";
import Icon from "../../../../../../components/Icon";
import DeleteCircleCross from "../../../../../../assets/icons/delete-circle-cross.svg";
import Snackbar from "../../../../../../components/Snackbar";

interface ComponentProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onClose?: (data: any) => void;
  appliedFilters;
  communitySubscribersEmails;
  communitySubscribersEmailsStates;
}

const Component = ({
  visible,
  setVisible,
  onClose,
  appliedFilters,
  communitySubscribersEmails,
  communitySubscribersEmailsStates,
}: ComponentProps) => {
  const [snackbarVisible, setSnackbarVisible] = useState<boolean>(false);
  const [deletedEmails, setDeletedEmails] = useState<string[]>([]);
  const [grouppedEmails, setGrouppedEmails] = useState<
    {
      letter: string;
      values: string[];
    }[]
  >();
  const dispatch = useDispatch();

  const emails = useMemo(() => {
    return communitySubscribersEmails?.data
      ?.filter(
        (communitySubscriberEmail) =>
          !deletedEmails?.includes(communitySubscriberEmail?.email)
      )
      ?.map((communitySubscriberEmail) => communitySubscriberEmail?.email);
  }, [communitySubscribersEmails, deletedEmails]);

  const groupIt = (arr) => {
    const groups = arr?.reduce((store, word) => {
      const letter = word?.charAt(0).toUpperCase();
      const keyStore =
        store[letter] || // Does it exist in the object?
        (store[letter] = []); // If not, create it as an empty array
      keyStore.push(word);

      return store;
    }, {});

    return groups;
  };

  const resetItems = () => {
    emails && setDeletedEmails([]);
  };

  useEffect(() => {
    emails && setGrouppedEmails(groupIt(emails));
  }, [emails]);

  useEffect(() => {
    setGrouppedEmails(
      groupIt(emails?.filter((email) => !deletedEmails?.includes(email)))
    );
  }, [deletedEmails]);

  useEffect(() => {
    if (visible) {
      dispatch(getCommunitySubscribersEmails(appliedFilters));
    }
  }, [visible, appliedFilters]);

  return (
    <>
      <Sidebar
        position="right"
        visible={visible}
        onClose={() => {
          setVisible(false);
          onClose && onClose(undefined);
        }}
        width="577px"
        title="Listado de e-mails"
      >
        <Preview>
          <div>
            <Text style={{ marginBottom: "24px" }}>
              A través de este listado podrás copiar todos los e-mails de los
              miembros de tu comunidad para comunicarte con ellos de forma
              masiva mediante correo electrónico.
            </Text>

            <Total>Listado ({emails?.length})</Total>

            <VerticalScroll>
              {!!grouppedEmails &&
                Object.keys(grouppedEmails)
                  .sort()
                  .map((letter) => {
                    return (
                      <LetterGroup>
                        <Letter>{letter}</Letter>
                        {grouppedEmails[letter].map((email, index) => (
                          <Email>
                            <span>{email}</span>
                            <Delete
                              onClick={() =>
                                setDeletedEmails([...deletedEmails, email])
                              }
                            >
                              <Icon
                                icon={DeleteCircleCross}
                                size="20px"
                                color="#96999B"
                              />
                            </Delete>
                          </Email>
                        ))}
                      </LetterGroup>
                    );
                  })}
            </VerticalScroll>
          </div>

          <div>
            <Button
              options={{
                type: "filled",
                skin: "purple",
                size: "lg",
                block: true,
              }}
              onClick={() => {
                setSnackbarVisible(true);
                navigator.clipboard.writeText(emails.toString());
              }}
              style={{ marginBottom: "8px" }}
            >
              Copiar e-mails
            </Button>

            <Button
              options={{
                type: "outline",
                skin: "purple",
                size: "lg",
                block: true,
              }}
              onClick={() => {
                setVisible(false);
                resetItems();
              }}
              style={{ marginBottom: "8px" }}
            >
              Cancelar
            </Button>

            <Button
              options={{
                type: "link",
                skin: "purple",
                size: "lg",
                block: true,
              }}
              onClick={() => resetItems()}
            >
              Restaurar listado
            </Button>
          </div>
        </Preview>
      </Sidebar>

      <Snackbar
        visible={snackbarVisible}
        onHide={() => setSnackbarVisible(false)}
        options={{
          time: 2000,
          type: "success",
          position: "left",
        }}
      >
        Se copiaron {emails?.length} correos electrónicos <br /> al porta
        papeles
      </Snackbar>
    </>
  );
};

const state = ({ communityStore }) => {
  const {
    data: communitySubscribersEmails,
    states: communitySubscribersEmailsStates,
  } = communityStore.subscribersEmails;

  return {
    communitySubscribersEmails,
    communitySubscribersEmailsStates,
  };
};

export default connect(state)(Component);
