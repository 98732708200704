import { useState } from "react";
import { Tooltip } from './styles';

interface TooltipProps {
  enableTooltip: boolean;
  children: React.ReactNode;
  content: string;
  position:
  | 'bottom-centered'
  | 'bottom-left'
  | 'bottom-right'
  | 'top-centered'
  | 'top-left'
  | 'top-right'
  | 'left-centered'
  | 'left-top'
  | 'left-bottom'
  | 'right-centered'
  | 'right-top'
  | 'right-bottom';
}

const Component: React.FC<TooltipProps> = ({ enableTooltip, children, content, position }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      style={{ position: "relative", display: "inline-block" }}
    >
      {children}

      {enableTooltip && (
        <Tooltip className={`tooltip ${position} ${visible ? 'visible' : ''}`}>
          {content}
        </Tooltip>
      )}
    </div>
  );
};

export default Component;
