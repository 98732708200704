import { useTheme } from "styled-components";
import firebase from "firebase/app";
import LogoutMenu from "./LogoutMenu";
import LoginMenu from "./LoginMenu";
import { Header, Img, Navbar } from "./styles";

import { connect } from "react-redux";
import { useWindowSize } from "../../../../../hooks/useWindowSize";

const Component = (props: any) => {
  const theme = useTheme() as { logo: { small: string, big: string } };
  const { forwardedRef, currentPage } = props;
  var user = firebase.auth().currentUser;
  const { isMobile } = useWindowSize();

  return (
    <Header ref={forwardedRef}>
      <a href="/">
        {!isMobile && (
          <Img
            src={theme?.logo?.big}
            width="128px"
            height="auto"
            className="logo-desktop"
            alt="Klouser"
            lazy
          />
        )}
        {isMobile && (
          <Img
            src={theme?.logo?.small}
            width="40px"
            height="auto"
            className="logo-mobile"
            alt="Klouser"
            lazy
          />
        )}
      </a>

      <Navbar>
        {!!user ? <LoginMenu /> : <LogoutMenu currentPage={currentPage} />}
      </Navbar>
    </Header>
  );
};

const state = ({ pageStore }) => ({
  currentPage: pageStore.name,
});

export default connect(state)(Component);
