import styled from 'styled-components'

export const Card = styled('div')`
  width: 100%;
  flex-shrink: 0;
  text-align: center;

  @media(min-width: 768px){
    max-width: 410px;
  }
`

export const Text = styled('p')`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  text-align: center;
`