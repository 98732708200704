import {
  ReactElement,
} from "react";
import { useFormikFieldValidation } from "../../../hooks/useFormikFieldValidation";
import { Props, ClearIndicatorProps, components } from "react-select";
import { Select } from "./styles";
import { useField } from "formik";

import {
  FieldContainer,
  Field,
  Label,
  FieldBefore,
  HelperLine,
  ExplainText,
  HelperText,
  ErrorMessage,
  Backdrop,
} from "../Field";
import Image from "../../Image";
import Loader from "../../Loader";
import chevron from "../../../assets/chevron-down.svg";
import cross from "../../../assets/cross.svg";

const initialValue = {
  options: {
    skin: "base",
    size: "md",
    loading: false,
  },
};

interface Option {
  label: string | ReactElement;
  value: string | number;
  disabled?: boolean
}

export interface GroupedOptions {
  label: string;
  options: Option[];
}

const DropdownIndicator = (props) => {
  const { hasValue, selectProps } = props;
  return (
    (!selectProps.isClearable || (selectProps.isClearable && !hasValue)) && (
      <components.DropdownIndicator {...props}>
        <Image src={chevron} alt=">" width="16px" height="16px" />
      </components.DropdownIndicator>
    )
  );
};

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <img src={cross} width={16} height={16} alt={"X"} />
    </components.ClearIndicator>
  );
};

interface ComponentProps {
  name: string;
  placeholder?: string;
  items?: Option | (Option | GroupedOptions)[];

  forwardRef?;
  menuIsOpen?: boolean;
  style?: any;

  error?: {};
  touched?: {};

  defaultValue?: Option | (Option | GroupedOptions)[];

  // closeMenuOnSelect?: boolean;
  // components?: any;

  disabled?: boolean;

  onChange?: (data: any) => void;
  onBlur?: (data: any) => void;
  onFocus?: (data: any) => void;

  onOptionSelected?: (option, actionMeta: any) => void;
  isOptionDisabled?: (option: any, optionSelected: any) => boolean;

  isMulti?: boolean;
  isClearable?: boolean;

  options?: {
    classes?: any;
    label?: string;
    skin?: "base" | "gray";
    size?: "md" | "lg";
    helperText?: ReactElement | string;
    explainText?: ReactElement | string;
    before?: any;
    after?: any;
    marginBottom?: number;
    hidden?: boolean;
    noOptionsText?: string;
    loading?: boolean;
    css?: string;
  };
}

const Component = ({
  options,
  name,
  items,
  error,
  touched,
  forwardRef,
  onChange,
  onBlur,
  onFocus,
  onOptionSelected,
  isOptionDisabled,
  style,
  disabled,
  isMulti,
  placeholder
}: ComponentProps) => {
  const [field, meta, fieldHelpers] = useField({ name });
  const { classes, setActive } = useFormikFieldValidation(
    error,
    name,
    disabled
  );

  const hasError = !!error && !!touched;

  const {
    loading,
    skin,
    size = "md",
    marginBottom,
    noOptionsText,
  } = {
    ...initialValue.options,
    ...options,
  };

  const getValue = () => {
    if (items) {
      const itemsFlat = (items as any[])
        .map((fieldOption) => {
          if (fieldOption.options) {
            return fieldOption.options;
          }

          return fieldOption;
        })
        .flat();

      return isMulti
        ? itemsFlat?.filter((option) => field?.value?.indexOf(option?.value) >= 0)
        : itemsFlat?.find((option) => option?.value === field?.value);
    } else {
      return isMulti ? [] : ("" as any);
    }
  };

  const handleOnChange = (option, actionMeta) => {
    if (isMulti) {
      const values = option.map((item) => item.value);
      const e = {
        target: {
          name: name,
          value: values,
        },
      };

      !!onChange && onChange(e);
    } else {
      const e = {
        target: {
          name: name,
          value: option.value,
        },
      };

      !!onChange && onChange(e);
    }

    if (!!onOptionSelected) {
      onOptionSelected(isMulti ? (option as Option[]) : (option as Option), {
        ...actionMeta,
        ...fieldHelpers,
      });
    }
  };

  return (
    <>
      <FieldContainer marginBottom={marginBottom}>
        {options && options.label && (
          <Label size={options?.size} htmlFor={`input-${name}`} style={!!options?.explainText ? { marginBottom: "0px" } : {}}>
            {options.label}
            {/* {rules && rules.required && <CustomSup>*</CustomSup>} */}
          </Label>
        )}

        {!!options?.explainText && (
          <ExplainText>{options?.explainText}</ExplainText>
        )}

        <Field
          skin={skin}
          size={size}
          className={`${classes} ${options?.classes}`}
          style={style}
        >
          {loading && (
            <Backdrop>
              <Loader />
            </Backdrop>
          )}

          {options && options.before && (
            <FieldBefore size={options?.size} className="field-before">
              {options.before}
            </FieldBefore>
          )}

          <Select
            {...field}
            options={items}
            variant="base"
            skin={skin}
            size={size}
            components={{ DropdownIndicator, ClearIndicator }}
            classNamePrefix="select"
            placeholder={placeholder}
            menuPlacement="auto"
            onChange={handleOnChange}
            onBlur={onBlur}
            onFocus={onFocus}
            isOptionDisabled={isOptionDisabled}
            isMulti={isMulti}
            value={getValue()}
            noOptionsMessage={({ inputValue }) =>
              inputValue ? noOptionsText : "No hay resultados"
            }
            {...(forwardRef && { ref: forwardRef })}
            isDisabled={disabled}
          />
        </Field>

        {((options && options.helperText) || error) && (
          <HelperLine marginBottom={marginBottom}>
            {!!error && !!touched && <ErrorMessage>{error}</ErrorMessage>}
            {options && options.helperText && !error && (
              <HelperText>{options.helperText}</HelperText>
            )}
          </HelperLine>
        )}
      </FieldContainer>
    </>
  );
};

export default Component;
