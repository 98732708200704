import styled from "styled-components";
import Title from "../../../../components/Title";
import Image from "../../../../components/Image";

export const NewExperience = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  margin-bottom: 16px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.lgMin}) {
    margin-bottom: 20px;
  }
`;

export const ToCreate = styled.div`
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.lgMin}) {
    margin-right: 27px;
  }
`;

export const Experience = styled.div`
  flex-direction: column-reverse;
  display: flex;
  align-items: center;
  padding: 0px 8px 20px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    padding: 20px 38px;
  }
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    flex-direction: row;
  }
`;

export const Launch = styled.div`
  margin-right: 5px;
`;

export const FormTitle = styled(Title)`
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    margin-bottom: 35px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.lgMin}) {
    margin-bottom: 51px;
    text-align: center;
  }
`;

export const Explanation = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`;

export const Img = styled(Image)`
  max-width: 164px;
  max-height: 130px;
  margin-bottom: 16px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    max-width: 237px;
    max-height: 188px;
  }
`;

export const HorizontalSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.Gray5};
`;

export const Boton = styled.div`
  justify-content: center;
  display: flex;
  padding: 24px 0px 35px;
`;
