import { Col } from "./styles";
interface ComponentProps {
  offset?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
  };
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  children: any;
  style?: any;
  order?: number;
  gutter?: number;
}

const Component = ({ children, gutter, ...rest }: ComponentProps) => {
  return (
    <Col gutter={gutter} {...rest}>
      {children}
    </Col>
  );
};

export default Component;
