import { CSSProperties } from "@remirror/theme";
import { useEffect, useState } from "react";
import { useFormikFieldValidation } from "../../../hooks/useFormikFieldValidation";
import { TextArea } from "./styles";
import {
  FieldContainer,
  Field,
  Label,
  HelperLine,
  HelperText,
  CharacterCounter,
  ErrorMessage,
} from "../Field";
import calculateNodeHeight from "./calculateNodeHeight";

interface ComponentProps {
  name?: string;
  value?: string | number;
  disabled?: boolean;
  error?: {};
  touched?: {};
  step?: string;
  placeholder?: string;
  style?: CSSProperties;
  maxLength?: number;
  onKeyDown?: (data: any) => void;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  autocomplete?: "off" | "on" | "new-password";
  forwardRef?;
  options: {
    label?: string;
    skin?: "base";
    size?: "md";
    helperText?: string;
    explainText?: string;
    before?: any;
    after?: any;
    marginBottom?: number;
    hidden?: boolean;
    minHeight?: number;
  };
}

const initialValue = {
  options: {
    skin: "base",
    size: "md",
    marginBottom: 24,
    minHeight: 230
  },
};

const Component = ({
  forwardRef,
  options = {},
  disabled,
  name,
  error,
  touched,
  maxLength,
  onKeyDown,
  onFocus,
  onBlur,
  onChange,
  style,
  ...rest
}: ComponentProps) => {
  const [height, setHeight] = useState(0);
  const { classes, setActive } = useFormikFieldValidation(
    error,
    name,
    disabled
  );
  const { skin, size, marginBottom, hidden, minHeight } = {
    ...initialValue.options,
    ...options,
  };
  const [numCharacters, setNumCharacters] = useState(0);

  const handleOnChange = (event, callback) => {
    const { value } = event.target;
    setNumCharacters(value.length);
    handleResize(event)
    callback && callback(event);
  };

  const handleOnBlur = (event, callback) => {
    setActive(false);
    callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    setActive(true);
    callback && callback(event);
  };

  const handleResize = (event) => {
    const height = calculateNodeHeight(event.target, event.target.value);
    setHeight(height);
  };

  return (
    <FieldContainer marginBottom={marginBottom}>
      {options && options.label && (
        <Label size={size} htmlFor={`textarea-${name}`}>
          {options.label}
        </Label>
      )}

      <Field
        size={size}
        skin={skin}
        className={classes}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "15px",
        }}
      >
        <TextArea
          style={{ height: `${height}px`, minHeight }}
          name={name}
          id={`textarea-${name}`}
          disabled={disabled}
          maxLength={maxLength}
          autoCorrect="off"
          autoCapitalize="off"
          autoComplete="off"
          size={size}
          onKeyDown={(e) => {
            if(onKeyDown)
              onKeyDown(e)
          }}
          onInput={(event) => handleResize(event)}
          onPaste={(event) => handleResize(event)}
          onCut={(event) => handleResize(event)}
          onChange={(event) => {
            handleOnChange(event, onChange);
          }}
          onBlur={(event) => {
            handleOnBlur(event, onBlur);
          }}
          onFocus={(event) => {
            handleOnFocus(event, onFocus);
          }}
          ref={forwardRef}
          {...rest}
        />

        {maxLength && (
          <CharacterCounter
            errors={!!error && !!touched}
            style={{ bottom: 12, right: 16 }}
          >
            {numCharacters} / {maxLength}
          </CharacterCounter>
        )}
      </Field>

      {((options && options.helperText) || maxLength || error) && (
        <HelperLine marginBottom={marginBottom}>
          {!!error && !!touched && <ErrorMessage>{error}</ErrorMessage>}
          {options && options.helperText && !error && (
            <HelperText>{options.helperText}</HelperText>
          )}
        </HelperLine>
      )}
    </FieldContainer>
  );
};

export default Component;
