import { Preference } from "../types/preferences";

export const showPreference = ({ preferences, preferencesStates, id }) => {
  let preference: Preference | null = null;

  if (!!preferences) {
    preference = preferences.filter(
      (preference) => preference.preferenceSlug === id
    )[0];
  }

  return (
    (!preferences && preferencesStates.success) ||
    (!!preferences && !preference) ||
    (!!preference && !!preference && preference?.preferenceValue === "1")
  );
};
