export const SUBSCRIBE_TO_THE_COMMUNITY = "SUBSCRIBE_TO_THE_COMMUNITY";
export const SUBSCRIBE_TO_THE_COMMUNITY_SUCCESS =
  "SUBSCRIBE_TO_THE_COMMUNITY_SUCCESS";
export const SUBSCRIBE_TO_THE_COMMUNITY_ERROR =
  "SUBSCRIBE_TO_THE_COMMUNITY_ERROR";
export const RESET_SUBSCRIBE_TO_THE_COMMUNITY =
  "RESET_SUBSCRIBE_TO_THE_COMMUNITY";

export interface SubscribeToTheCommunity {
  type: typeof SUBSCRIBE_TO_THE_COMMUNITY;
  payload: { email: string; idExpert: number };
}

export interface SubscribeToTheCommunitySuccess {
  type: typeof SUBSCRIBE_TO_THE_COMMUNITY_SUCCESS;
  payload: null;
}

export interface SubscribeToTheCommunityError {
  type: typeof SUBSCRIBE_TO_THE_COMMUNITY_ERROR;
  payload: boolean | string;
}

export interface ResetSubscribeToTheCommunity {
  type: typeof RESET_SUBSCRIBE_TO_THE_COMMUNITY;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | SubscribeToTheCommunity
  | SubscribeToTheCommunityError
  | SubscribeToTheCommunitySuccess
  | ResetSubscribeToTheCommunity;
