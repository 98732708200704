import { DeleteReactionInDto, DeleteReactionOutDto } from "../../../types/reaction.dto";
export const DELETE_REACTION = "DELETE_REACTION";
export const DELETE_REACTION_SUCCESS = "DELETE_REACTION_SUCCESS";
export const DELETE_REACTION_ERROR = "DELETE_REACTION_ERROR";
export const RESET_DELETE_REACTION = "RESET_DELETE_REACTION";

export interface DeleteReaction {
  type: typeof DELETE_REACTION;
  payload: DeleteReactionOutDto;
}

export interface DeleteReactionSuccess {
  type: typeof DELETE_REACTION_SUCCESS;
  payload: DeleteReactionInDto;
}

export interface DeleteReactionError {
  type: typeof DELETE_REACTION_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteReaction {
  type: typeof RESET_DELETE_REACTION;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | DeleteReaction
  | DeleteReactionSuccess
  | DeleteReactionError
  | ResetDeleteReaction;
