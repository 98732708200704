import Icon from "../../../../../components/Icon"
import securePaymentIcon from '../../../../../assets/secure-payment.png'
import {
  Card,
  Title,
  Text
} from './styles'
import { CSSProperties } from "styled-components"
import { connect } from "react-redux"
import { Group, Subscription } from "../../../../../types"
import date from "../../../../../helpers/dates-hanlder"

interface ComponentProps {
  style?: CSSProperties
  group?: Group
  subscription?: Subscription
}

const Component = ({ style, group, subscription }: ComponentProps) => {
  return (
    <Card style={style}>
      <Icon icon={securePaymentIcon} size="67px" color="#000000" height="49px" />
      <Title>Este proceso es<br />completamente seguro.</Title>
      <div style={{ height: 10 }} />
      <Text>Esta acción no generará ningún cobro<br />adicional y se respetará el ciclo de<br />renovación de tu suscripción.</Text>
      <div style={{ height: 20 }} />
      <Text><strong>Próxima renovación: {date.dateSpliter(subscription?.next_billing_at).date} </strong></Text>
    </Card>
  )
}

export default Component