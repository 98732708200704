

const tour = {
  title: "Descubre qué puedes hacer dentro de Klouser",
  thumbnail: '',
  video:
    "https://storage.googleapis.com/storage-klouser-web-app-prod/static-assets/onboardin-demo-compressed.m4v",
  text: "Este es un rápido tour sobre las posibilidades que te ofrece nuestra plataforma. Además, te mostramos algunos ejemplos de comunidades exitosas que ya forman parte.",
};

export default tour;
