import styled from "styled-components";

export const CarouselContent = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-top: 79px;
  margin: 0px -27px;

  .carousel__inner-slide {
    display: flex;
  }

  .carousel__dot {
    border: inherit;
    font-size: inherit;
    font-weight: inherit;
    padding: inherit;
    color: inherit;
    background: inherit;
    display: none;

    &.carousel__dot--selected {
      display: block;
    }
  }
`;

export const Step = styled("div")`
  display: flex;
  align-items: baseline;
  -webkit-box-pack: end;
  justify-content: center;
  width: 120px;
  flex-shrink: 0;
  position: absolute;
  z-index: 10;
  top: -72px;
  // padding-top: 48px;

  &:after {
    content: "";
    display: block;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    background-color: #6c5ce7;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
`;

export const Big = styled("div")`
  color: white;
  font-weight: 500;
  font-size: 52px;
  line-height: 63px;
  position: relative;
  z-index: 1;
`;

export const Small = styled("div")`
  color: white;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  bottom: 3px;
  position: relative;
  position: relative;
  z-index: 1;
`;
