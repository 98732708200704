export const DELETE_WELCOME_CONTENT_POST_FILE = "DELETE_WELCOME_CONTENT_POST_FILE";
export const DELETE_WELCOME_CONTENT_POST_FILE_SUCCESS = "DELETE_WELCOME_CONTENT_POST_FILE_SUCCESS";
export const DELETE_WELCOME_CONTENT_POST_FILE_ERROR = "DELETE_WELCOME_CONTENT_POST_FILE_ERROR";
export const RESET_DELETE_WELCOME_CONTENT_POST_FILE = "RESET_DELETE_WELCOME_CONTENT_POST_FILE";

export interface DeleteWelcomeContentPostFile {
  type: typeof DELETE_WELCOME_CONTENT_POST_FILE;
  payload: { idFile: number };
}

export interface DeleteWelcomeContentPostFileSuccess {
  type: typeof DELETE_WELCOME_CONTENT_POST_FILE_SUCCESS;
  payload: null;
}

export interface DeleteWelcomeContentPostFileError {
  type: typeof DELETE_WELCOME_CONTENT_POST_FILE_ERROR;
  payload: string | boolean;
}

export interface ResetDeleteWelcomeContentPostFile {
  type: typeof RESET_DELETE_WELCOME_CONTENT_POST_FILE;
  payload: null;
}

export interface State {
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | DeleteWelcomeContentPostFile
  | DeleteWelcomeContentPostFileSuccess
  | DeleteWelcomeContentPostFileError
  | ResetDeleteWelcomeContentPostFile;
