import Modal from "../../../Modal";
import Button from "../../../Button";
import Link from "../../../Link";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../store/actions/modal";
import constants from "../../../../constants";
import { useEffect, useState } from "react";
import { Group, User } from "../../../../types";

interface ComponentProps {
  userInformation: User;
  community: Group;
}

const Component = ({ userInformation, community }: ComponentProps) => {
  const dispatch = useDispatch();
  const { whatsapp } = constants;
  const [message, setMessage] = useState(whatsapp.message);
  const [name, setName] = useState("");
  const [communityName, setCommunityName] = useState("");

  useEffect(() => {
    if (!!userInformation?.expert && !!community) {
      setName(userInformation?.expert?.username);
      setMessage(whatsapp.import_members);
      setCommunityName(community.group_name);
    }
  }, [userInformation, community]);

  return (
    <Modal name="modal-importar-miembros" width="659" showClose={false}>
      <Title>¿Deseas importar miembros?</Title>

      <Text>
        Estamos trabajando en esta funcionalidad. Mientras tanto podemos{" "}
        <b>importar tu listado de miembros</b> desde el área de soporte.
        ¡Contáctanos!
      </Text>

      <AlignEnd>
        <Button
          options={{
            type: "outline",
            skin: "purple",
            size: "lg",
          }}
          onClick={() => dispatch(hideModal())}
          style={{ marginRight: "16px" }}
        >
          Cancelar
        </Button>

        <Link
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
            native: false,
          }}
          href={`${constants.whatsapp.url}?phone=${
            whatsapp.number
          }&text=${message
            .replace("{name}", name)
            .replace("{community}", communityName)}`}
          target="_blank"
        >
          Contactar soporte
        </Link>
      </AlignEnd>
    </Modal>
  );
};

const state = ({ communityStore, userStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: community } = communityStore.community;

  return {
    userInformation,
    community,
  };
};

export default connect(state)(Component);
