export const UPDATE_WHITE_LABEL = "UPDATE_WHITE_LABEL";
export const UPDATE_WHITE_LABEL_SUCCESS = "UPDATE_WHITE_LABEL_SUCCESS";
export const UPDATE_WHITE_LABEL_ERROR = "UPDATE_WHITE_LABEL_ERROR";
export const RESET_UPDATE_WHITE_LABEL = "RESET_UPDATE_WHITE_LABEL";


export interface UpdateWhiteLabelPayload {
  preferenceSlug: string | number;
  preferenceValue: any;
}

export interface UpdateWhiteLabel {
  type: typeof UPDATE_WHITE_LABEL;
  payload: UpdateWhiteLabelPayload;
}

export interface UpdateWhiteLabelSuccess {
  type: typeof UPDATE_WHITE_LABEL_SUCCESS;
  payload: null;
}

export interface UpdateWhiteLabelError {
  type: typeof UPDATE_WHITE_LABEL_ERROR;
  payload: string | boolean;
}

export interface ResetUpdateWhiteLabel {
  type: typeof RESET_UPDATE_WHITE_LABEL;
  payload: null
}

export interface State {
  states: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
}

export const initialState: State = {
  states: {
    loading: false,
    error: false,
    success: false,
  },
};

export type Actions =
  | UpdateWhiteLabel
  | UpdateWhiteLabelSuccess
  | UpdateWhiteLabelError
  | ResetUpdateWhiteLabel;
