import {
  SurveyAnswerOutDto,
  SurveyAnswerInDto,
} from "../../../types/survey.dto";

export const NEW_SURVEY_ANSWER = "NEW_SURVEY_ANSWER";
export const NEW_SURVEY_ANSWER_SUCCESS = "NEW_SURVEY_ANSWER_SUCCESS";
export const NEW_SURVEY_ANSWER_ERROR = "NEW_SURVEY_ANSWER_ERROR";
export const RESET_NEW_SURVEY_ANSWER = "RESET_NEW_SURVEY_ANSWER";

export interface NewSurveyAnswer {
  type: typeof NEW_SURVEY_ANSWER;
  payload: SurveyAnswerOutDto;
}

export interface NewSurveyAnswerSuccess {
  type: typeof NEW_SURVEY_ANSWER_SUCCESS;
  payload: SurveyAnswerInDto;
}

export interface NewSurveyAnswerError {
  type: typeof NEW_SURVEY_ANSWER_ERROR;
  payload: string | boolean;
}

export interface ResetNewSurveyAnswer {
  type: typeof RESET_NEW_SURVEY_ANSWER;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | NewSurveyAnswer
  | NewSurveyAnswerSuccess
  | NewSurveyAnswerError
  | ResetNewSurveyAnswer;
