import ReactSelect from "../../../../../components/FormikForm/ReactSelect";
import Input from "../../../../../components/FormikForm/Input";
import {inputDateTimeLocal} from "../../../../../helpers/dates-hanlder";
import Checkbox from "../../../../../components/FormikForm/Checkbox";
import {Post} from "../../../../../types/post";
import {connect} from "react-redux";
import {useEffect, useState} from "react";

interface ComponentProps {

}

const publishAtActions = [
    { label: "Publicar ahora", value: "now_publication" },
    { label: "Publicar en el futuro", value: "scheduled_publication" },
];


const Component = ({   values,
                       errors,
                       touched,
                       handleChange,
                       handleBlur,
                   }) => {

    const [actionPublishAt, setActionPublishAt] = useState("now_publication");
    const [timeZone, setTimeZone] = useState<string | null>(null);

    useEffect(() => {
        const dateTime = new Date();

        const timeZoneString = dateTime.toLocaleTimeString(undefined, {
            timeZoneName: 'short',
        }).split(' ')[2]

        const timeZoneNameString = dateTime.toLocaleTimeString(undefined, {
            timeZoneName: 'long',
        }).split(' ')[2]

        setTimeZone(`Estandar de ${timeZoneNameString} (${timeZoneString})`);
    }, []);

    useEffect(() => {
        if(values["action_publish_at"]){
            setActionPublishAt(values["action_publish_at"])
        }
    }, [values]);


    return (
        <>
            <ReactSelect
                name="action_publish_at"
                error={errors["action_publish_at"]}
                touched={touched["action_publish_at"]}
                placeholder={`Elige cuando quieres que se publique`}
                items={publishAtActions}
                onChange={(event) => {
                    handleChange(event);
                    setActionPublishAt(event.target.value);
                }}
                onBlur={handleBlur}
                options={{
                    label: 'Cuando quieres que se publique el articulo?',
                    marginBottom: actionPublishAt !== "scheduled_publication" ? 60 : 40,
                }}
            />

            {actionPublishAt === "scheduled_publication" && (
                <>
                    <Input
                        name="schedule_publish_at"
                        error={errors["schedule_publish_at"]}
                        touched={touched["schedule_publish_at"]}
                        type="datetime-local"
                        placeholder="dd/mm/aa hh:mm"
                        value={inputDateTimeLocal(values['schedule_publish_at'])}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        options={{
                            label: "Fecha y hora de publicación",
                            explainText:
                                `Selecciona la fecha y hora en que quieres que se publique automaticamente este articulo. Se utilizará la Zona Horaria: ${timeZone}`,
                            marginBottom: 20,
                        }}
                    />

                    <Checkbox
                        name="show_scheduled_post"
                        error={errors["show_scheduled_post"]}
                        touched={touched["show_scheduled_post"]}
                        value={values["show_scheduled_post"]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        items={[
                            {
                                label: `Mostrar artículo visible en la biblioteca de contenidos como contenido programado`,
                                value: "true",
                            },
                        ]}
                        options={{
                            marginBottom: 40,
                            helperText: "Al activar esta opción tus suscriptores podran ver el articulo en la biblioteca de contenidos pero al acceder no podrán ver el contenido completo hasta que no este publicado",
                        }}
                    />
                </>
            )}
        </>
    );
};


export default Component;
