import styled, { createGlobalStyle } from "styled-components";
import background1 from "../../../assets/backgrounds/profiles/background-3.svg";

export const GlobalStyles = createGlobalStyle`
  #footer-sticky {
    position: sticky;
    bottom: 0;
    z-index: 100;
  }

  #root {
    position: relative;
  }

  #header-sticky {
    position: sticky;
    top: 0;
    z-index: 105;
  }

  #sidebars {
    position: relative;
    z-index: 150;
  }

  #modal {
    position: relative;
    z-index: 160;
  }

  #snackbar {
    position: relative;
    z-index: 170;
  }
`;

export const Page = styled.div<{
  minHeight: number;
  currentPage;
  showMenu: boolean;
}>`
  position: relative;
  z-index: 10;
  min-height: 100vh;
  background-color: #e5e5e5;
  padding: ${(props) => (!props.showMenu ? "22px 0px 80px" : "56px 0px 0px")};
`;

export const PageContent = styled.div``;
