
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import RainbowLayout from "../../components/Layout/RainbowLayout";
import PrivateRoute from "../../components/PrivateRoute";
import Success from "./Success";
import Error from "./Error";
import FreeSubscription from "./FreeSubscription";
import PaidSubscription from "./PaidSubscription";
import RetryPaidSubscription from "./RetryPaidSubscription";
import ChangePagymentMethod from "./ChangePagymentMethod";

const Componet = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute
        path={`${path}/free-subscription/:idGroup`}
        component={(props) => <FreeSubscription {...props} />}
      />
      <PrivateRoute path={`${path}/success`} component={(props) => <Success  {...props} />} />
      <PrivateRoute path={`${path}/faliure`} component={() => <RainbowLayout useLocation={useLocation}><Error /></RainbowLayout>} />
      <PrivateRoute path={`${path}/payment`} component={() => <PaidSubscription />} />
      <PrivateRoute path={`${path}/retry-payment`} component={() => <RetryPaidSubscription />} />
      <Route path={`${path}/change-payment-method`} component={() => <ChangePagymentMethod />} />
    </Switch>
  );
};

export default Componet;
