import Grid from "../../../../../components/Grid";
import { IntroVideo, Title, SubTitle, Center } from "./styles";
import constants from "../../../../../constants";

const Component = () => {
  return (
    <IntroVideo id="mas-info">
      <Grid.Container>
        <Grid.Row>
          <Grid.Col lg={10} offset={{ lg: 1 }}>
            <Title>
              Suscribete al primer
              <br /> congreso del Bienestar
            </Title>

            <SubTitle>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus
              orci a risus mollis imperdiet. Ut sit amet velit elementum,
              hendrerit leo a, auctor metus. Proin finibus dolor eget suscipit
              eleifend.
            </SubTitle>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col lg={12}>
            <Center>

              <video controls style={{ height: "400px" }} controlsList="nodownload" onContextMenu={e => e.preventDefault()}>
                <source src={constants.tour.video} />
              </video>
                
            </Center>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </IntroVideo>
  );
};

export default Component;
