import { Expert, GetSubscriptionsOutDto, Group } from "../../../types";

export const GET_USER_GROUPS = "GET_USER_GROUPS";
export const GET_USER_GROUPS_SUCCESS = "GET_USER_GROUPS_SUCCESS";
export const GET_USER_GROUPS_ERROR = "GET_USER_GROUPS_ERROR";

export const UPDATE_CURRENT_USER_GROUPS = "UPDATE_CURRENT_USER_GROUPS";
export const UPDATE_CURRENT_USER_GROUPS_SUCCESS = "UPDATE_CURRENT_USER_GROUPS_SUCCESS";
export const UPDATE_CURRENT_USER_GROUPS_ERROR = "UPDATE_CURRENT_USER_GROUPS_ERROR";


export interface GetUserGroups {
  type: typeof GET_USER_GROUPS;
  payload?: GetSubscriptionsOutDto;
}

export interface GetUserGroupsSuccess {
  type: typeof GET_USER_GROUPS_SUCCESS;
  payload: Group[];
}

export interface GetUserGroupsError {
  type: typeof GET_USER_GROUPS_ERROR;
  payload: boolean | string;
}

export interface UpdateCurrentUserGroups {
  type: typeof UPDATE_CURRENT_USER_GROUPS;
  payload: Group[];
}

export interface UpdateCurrentUserGroupsSuccess {
  type: typeof UPDATE_CURRENT_USER_GROUPS_SUCCESS;
  payload: Group[];
}

export interface UpdateCurrentUserGroupsError {
  type: typeof UPDATE_CURRENT_USER_GROUPS_ERROR;
  payload: boolean | string;
}

export interface State {
  data: Group[] | null,
  states: {
    loading: boolean;
    error: boolean | string;
    success: boolean;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    error: false,
    success: false,
  },
};

export type Actions =
  | GetUserGroups
  | GetUserGroupsSuccess
  | GetUserGroupsError
  | UpdateCurrentUserGroups
  | UpdateCurrentUserGroupsSuccess
  | UpdateCurrentUserGroupsError;
