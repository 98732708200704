import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Formik } from "formik";
import { Text } from "./styles";
import PostForm from "../components/Form";
import { initialValues, schema } from "../../../../constants/forms/welcome-content/edit";
import Link from "../../../../components/Link";
import { ConnectedFocusError } from "../../../../components/ConnectedFocusError";
import Submit from "../../../../components/FormikForm/Submit";
import Grid from "../../../../components/Grid";
import Snackbar from "../../../../components/Snackbar";
import { getGroupById } from "../../../../store/actions/group";
import { editWelcomeContentPost, getWelcomeContentPost, resetEditWelcomeContentPost } from "../../../../store/actions/welcome-content";
import { useLayout } from "../../../../providers/LayoutProvider";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { hideModal, showModal } from "../../../../store/actions/modal";
import { User } from "../../../../types";
import ModalWaitAMinuteBis from '../components/ModalWaitAMinute'
import Loader from "../../../../components/LogoLoader";
import { useFileUploadProgress } from "../../../../providers/FileUploader";
import services from "../../../../services";
import { getPostsFilePath } from '../../../../services/storage'
import { NewFileInDto } from "../../../../types/storage.dto";
import { GetWelcomeContentPostInDto } from "../../../../types/welcome-section";

interface ComponentProps {
  match;
  userInformation: User;
  editedWelcomeContentPost;
  editedWelcomeContentPostStates;
  welcomeContentPost: GetWelcomeContentPostInDto
  welcomeContentPostStates: {
    loading: boolean
  }
  group;
  communityInformation;
}

const Component = ({
  match,
  userInformation,
  editedWelcomeContentPost,
  editedWelcomeContentPostStates,
  welcomeContentPost,
  welcomeContentPostStates,
  group,
  communityInformation,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: { [key: string]: string } = useParams();
  const { uploadFiles, resetFileUploadProgress } = useFileUploadProgress();
  const { isMobile } = useWindowSize();
  const { layout, setLayout } = useLayout();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const timestamp = new Date().getTime();

  const onSubmit = async ({ values, actions }) => {
    const thereAreFilesToUpload = values && (values.cover_pic?.some(file => file instanceof File) || values.attached_files?.some(file => file instanceof File));

    if (thereAreFilesToUpload) {
      dispatch(showModal("modal-wait-a-minute-bis"));
    }

    try {
      if (thereAreFilesToUpload) {
        const uploadedFiles = await uploadFiles(
          {
            cover_pic: values.cover_pic,
            attached_files: values.attached_files,
          },
          userInformation.id,
          "welcome-content"
        );

        const updatedValues = {
          title: values.title,
          body: values.body,
          attached_url: values.attached_url,
          content_order: values.content_order,
          block_files_download: values?.block_files_download?.includes("true"),
          cover_pic: uploadedFiles?.cover_pic && uploadedFiles?.cover_pic.length >= 0 ? uploadedFiles?.cover_pic[0] : null,
          attached_files: uploadedFiles?.attached_files ? uploadedFiles?.attached_files : []
        };

        dispatch(editWelcomeContentPost({ idWelcomeContentPost: welcomeContentPost?.id, body: updatedValues }));
      } else {
        // If there are no files to upload, dispatch newWelcomeContentPost directly
        const updatedValues = {
          title: values.title,
          body: values.body,
          attached_url: values.attached_url,
          content_order: values.content_order,
          block_files_download: values?.block_files_download?.includes("true"),
          cover_pic: values?.cover_pic[0] ? values?.cover_pic[0] : null,
          attached_files: values?.attached_files ? values?.attached_files : []
        };

        dispatch(editWelcomeContentPost({ idWelcomeContentPost: welcomeContentPost?.id, body: updatedValues }));
      }
    } catch (error) {
      console.error('Error during file uploads:', error);
    } finally {
      dispatch(hideModal());
    }
  };

  useEffect(() => {
    if (
      !!match.params &&
      (!group ||
        (!!group &&
          group?.id !== Number(match?.params?.idGroup)))
    ) {
      dispatch(getGroupById({ group_id: match.params.idGroup }));
    }
  }, [params.idGroup]);

  useEffect(() => {
    if (
      !!match.params &&
      (!welcomeContentPost ||
        (!!welcomeContentPost &&
          welcomeContentPost?.id !== Number(match?.params?.idPost)))
    ) {
      dispatch(getWelcomeContentPost({ idWelcomeContentPost: match.params.idPost }));
    }
  }, [params.idPost]);

  useEffect(() => {
    if (editedWelcomeContentPostStates.success) {
      setTimeout(() => {
        const idGroup = group?.id;
        const idWelcomeContentPost = welcomeContentPost?.id;
        dispatch(resetEditWelcomeContentPost());
        resetFileUploadProgress();
        dispatch(hideModal());
        history.push(`/group/${idGroup}/wc/${idWelcomeContentPost}`);
      }, 3500);
    }
  }, [editedWelcomeContentPostStates]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: "Nuevo contenido de bienvenida",
        description: "Nuevo contenido de bienvenida",
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: true,
      },
    });
  }, [group]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: "Nuevo contenido de bienvenida",
        description: "Nuevo contenido de bienvenida",
        back: `/dashboard/${welcomeContentPost?.welcome_section?.group?.group_type}/${group?.id}/configuration`,
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: true,
        actions: [
          <Link
            options={{
              type: "outline",
              size: "lg",
              skin: "purple",
              native: true,
            }}
            href={`/dashboard/${welcomeContentPost?.welcome_section?.group?.group_type}/${group?.id}/configuration`}
          >
            Cancelar
          </Link>,
          <Submit
            isSubmmiting={editedWelcomeContentPostStates.loading}
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
            }}
            form="new-welcome-content-form"
          >
            Guardar contenido
          </Submit>,
        ],
      },
    });
  }, [group, editedWelcomeContentPostStates]);

  if (!welcomeContentPost || welcomeContentPostStates.loading) {
    return <Loader />;
  }

  return (
    <>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <Text>
              📝 Redacta tus contenidos de bienvenida y sube documentos en pdf, imágenes, videos y ¡hasta tus Reels de Instagram!
            </Text>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col md={7}>
            <Formik
              initialValues={{
                ...initialValues,
                ...welcomeContentPost,
                cover_pic: welcomeContentPost?.cover_pic ? [welcomeContentPost?.cover_pic] : [],
                block_files_download: welcomeContentPost?.block_files_download ? ["true"] : [],
              }}
              onSubmit={(values, actions) => {
                if (onSubmit) onSubmit({ values, actions });
              }}
              validateOnChange={false}
              validateOnBlur={formSubmmited}
              validationSchema={schema}
              enableReinitialize
            >
              {({ handleSubmit, ...rest }) => {
                return (
                  <form
                    className="theme-form"
                    onSubmit={(event) => {
                      setFormSubmmited(true);
                      handleSubmit(event);
                    }}
                    id="new-welcome-content-form"
                  >
                    <ConnectedFocusError />
                    <PostForm {...rest} />
                  </form>
                );
              }}
            </Formik>
          </Grid.Col>
        </Grid.Row>

        {!isMobile && (
          <Grid.Row>
            <Grid.Col>
              <Link
                style={{ marginRight: "8px" }}
                options={{
                  type: "outline",
                  size: "lg",
                  skin: "purple",
                  native: true,
                }}
                href={`/dashboard/${welcomeContentPost?.welcome_section?.group?.group_type}/${group?.id}/configuration`}

              >
                Cancelar
              </Link>

              <Submit
                isSubmmiting={editedWelcomeContentPostStates.loading}
                options={{
                  type: "filled",
                  size: "lg",
                  skin: "purple",
                }}
                form="new-welcome-content-form"
              >
                Guardar contenido de bienvenida
              </Submit>
            </Grid.Col>
          </Grid.Row>
        )}
      </Grid.Container>

      <ModalWaitAMinuteBis />

      <Snackbar
        visible={editedWelcomeContentPostStates.success || editedWelcomeContentPostStates.error}
        options={{
          time: 2000,
          type: editedWelcomeContentPostStates.success ? "success" : "error",
        }}
      >
        {editedWelcomeContentPostStates.success
          ? "Contenido de bienvenida creado."
          : "Error al crear el contenido de bienvenida."}
      </Snackbar>
    </>
  );
};

const state = ({ groupStore, welcomeContentStore, userStore, communityStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: editedWelcomeContentPost, states: editedWelcomeContentPostStates } = welcomeContentStore.editWelcomeContentPost;
  const { data: welcomeContentPost, states: welcomeContentPostStates } = welcomeContentStore.welcomeContentPost;
  const { data: group } = groupStore.group;
  const { data: communityInformation } = communityStore.community;

  return {
    editedWelcomeContentPost,
    editedWelcomeContentPostStates,
    welcomeContentPost,
    welcomeContentPostStates,
    group,
    communityInformation,
    userInformation,
  };
};

export default connect(state)(Component);
