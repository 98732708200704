import { useDispatch, connect } from "react-redux";
import remove from "../../../../../assets/remove.svg";
import Image from "../../../../../components/Image";
import { hideModal } from "../../../../../store/actions/modal";
import { deletePost, resetDeletePost } from "../../../../../store/actions/post";
import { Modal, ModalDialog, Title, Text, Actions, Button } from "./styles";

const Component = ({ idArticle, deletePostStates }) => {
  const dispatch = useDispatch();

  return (
    <Modal
      name="modal-remove"
      width="660"
      onClose={() => dispatch(resetDeletePost())}
    >
      <ModalDialog>
        {deletePostStates.success ? (
          <>
            <div style={{ textAlign: "center" }}>
              <Image src={remove} width="102px" height="117px" alt={remove} />
            </div>
            <Text>
              <strong>Contenido eliminado satisfactoriamente</strong>
            </Text>
          </>
        ) : (
          <>
            <Title>¿Deseas eliminar este contenido?</Title>
            <Text>
              Una vez eliminado no podrás volver a acceder al mismo. En caso de
              haberlo compartido, tu comunidad ya no podrá visualizarlo.
            </Text>

            <Actions>
              <Button
                onClick={() => dispatch(hideModal())}
                type="button"
                options={{
                  skin: "purple",
                  size: "md",
                  type: "outline",
                }}
              >
                Cancelar
              </Button>

              <Button
                onClick={() => dispatch(deletePost(idArticle))}
                type="button"
                options={{
                  skin: "purple",
                  size: "md",
                  type: "filled",
                }}
              >
                Eliminar
              </Button>
            </Actions>
          </>
        )}
      </ModalDialog>
    </Modal>
  );
};

const state = ({ postStore }) => {
  const { states: deletePostStates } = postStore.deletePost;

  return {
    deletePostStates,
  };
};

export default connect(state)(Component);
