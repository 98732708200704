import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "./styles";

interface ComponentProps {
  onSubmit: (data: any) => void;
  onError?: (data: any) => void;
  options?: {
    mode?;
    reValidateMode?;
    resolver?;
    context?;
    defaultValues?;
    shouldFocusError?;
    delayError?;
    shouldUseNativeValidation?;
    shouldUnregister?;
    criteriaMode?;
  };
  children: any;
  defaultValues?: any;
  id: string;
  enctype?;
}

const Component = ({
  onSubmit,
  onError,
  options,
  children,
  id,
  enctype,
  ...rest
}: ComponentProps) => {
  const { control, handleSubmit, register, getValues, formState, setError } =
    useForm({
      mode: "onSubmit",
      reValidateMode: "onChange",
      ...options,
    });

  const { isValid, errors } = formState;

  const onFormSubmit = (data) => {
    if (onSubmit) onSubmit({ data, isValid, errors, setError });
  };

  const onFormError = (data) => {
    if (onError) onError({ data, isValid, errors, setError });
  };

  return (
    <Form onSubmit={handleSubmit(onFormSubmit, onFormError)} id={id} {...rest}>
      {React.Children.map(children, (child) => {
        if (!child) return null;
        let rules;

        if (child.props.name) {
          rules = {
            ...(child.props.rules && { ...child.props.rules }),
            ...(child.props.rules?.sameAs && {
              validate: (value) =>
                value.toLowerCase() ===
                  getValues()[child.props.rules.sameAs.field].toLowerCase() ||
                child.props.rules.sameAs.message,
            }),
          };
        }

        return child.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                rules,
                errors: { ...errors },
                formState,
                register,
                key: child.props.name,
                control,
              },
            })
          : child;
      })}
    </Form>
  );
};

export default Component;
