import Button from "../../../../components/Button";
import Link from "../../../../components/Link";
import Reactions from "../../../../components/Reactions";
import { Page } from "../../components/styles";
import {
  getYouTubeUrl,
  isYoutubeUrl,
  isInstagramUrl,
  isVimeoUrl,
  getVimeoVideoIdFromUrl
} from "../../../../helpers/you-tube";
import MPBlockedSection from '../../../Group/components/MPBlockedSection'
import AspectRatio from "../../../../components/AspectRatio";
import {
  Text,
  Title,
  Image,
  Audio,
  BlockContentMessage,
  BlockContentMessageTitle,
  PostContent,
  InLine,
  GlobalStyles
} from "./styles";
import linkIcon from "../../../../assets/icons/open-link.svg";
import Icon from "../../../../components/Icon";
import Helmet from "react-helmet";
import Grid from "../../../../components/Grid";
import { connect, useDispatch } from "react-redux";
import { createRef, useEffect, useMemo, useState } from "react";
import {
  getPost,
  resetGetPost,
  resetSharePostInTelegram,
} from "../../../../store/actions/post";
import slugify from "slugify";
import userAvatar from "../../../../assets/avatar.svg";
import { AttachedFile } from "../../../../types/attached-file";
import { Post } from "../../../../types/post";
import InstagramReel from "../components/InstagramReel";
import VimeoVideo from "../components/VimeoVideo";
import Loader from "../../../../components/LogoLoader";
import { useMarkdown } from "../../../../hooks/useMarkdown";
import { RemirrorRenderer } from "@remirror/react-renderer";
import { hideModal, showModal } from "../../../../store/actions/modal";
import { getUrlParamByName } from "../../../../helpers/get-url-param";
import Header from "./components/Header";
import { useWindowSize } from "../../../../hooks/useFormHook";
import { DateSpliteDto, dateSpliter } from "../../../../helpers/dates-hanlder";
import { Redirect, useHistory, useLocation } from "react-router";
import { useLayout } from "../../../../providers/LayoutProvider";
import schedulerIcon from "../../../../assets/scheduler-icon.svg";
import { resetSubscription, subscribeToTheCommunity } from "../../../../store/actions/community";
import { getFreeSubscriptions } from "../../../../helpers/getFreeSubscriptions";
import { isSubscribed } from "../../../../helpers/isSubscribed";
import { Group } from "../../../../types";
import PdfViewer from '../../../../components/PdfViewer';
import ChangeToFreePostModal from "../components/ChangeToFreePostModal";
import SharePostInTelegramModal from "../components/SharePostInTelegramModal";
import DeletePostModal from "../components/DeletePostModal";
import ConfirmPostCreation from "../components/ConfirmPostCreation";
import SharePostLinkModal from "../components/SharePostLinkModal";
import ChangePublishedDateModal from "../components/ChangePublishedDateModal";
import Snackbar from "../../../../components/Snackbar";
import ChangeToNotFreePostModal from "../components/ChangeToNotFreePostModal";
import PayWall from "../../components/PayWall";
import AskForUserInformation from "../../../components/AskForUserInformation";
import NoSubscribed from "../components/NoSubscribed";
import { getUserInformation } from "../../../../store/actions/user";
import MPVerifyYourPaymentInformationSection from "../../../Group/components/MPVerifyYourPaymentInformationSection";


interface ComponentProps {
  userInformation;
  userSubscriptions;
  postInformation: Post;
  postStates;
  match;
  avatar?: any;
  isLoggedIn: boolean;
  sharePostInTelegramStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  subscriptionStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

interface LocationState {
  from: string;
}

const Component = ({
  userInformation,
  userSubscriptions,
  postInformation,
  postStates,
  match,
  sharePostInTelegramStates,
  subscriptionStates,
  isLoggedIn
}: ComponentProps) => {
  const location = useLocation()
  const previousPage = (location?.state as LocationState)?.from;

  const { typeMap, markMap, jsonStr, textStr } = useMarkdown({
    brief: postInformation?.body,
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const { layout, setLayout } = useLayout();
  const { isMobile } = useWindowSize();
  const [createdAt, setCreatedAt] = useState<DateSpliteDto>();
  const [audioFiles, setAudioFiles] = useState<AttachedFile[]>([]);
  const [imageFiles, setImageFiles] = useState<AttachedFile[]>([]);
  const [pdfFiles, setPdfFiles] = useState<AttachedFile[]>([]);
  const [videoFiles, setVideoFiles] = useState<AttachedFile[]>([]);
  const [showSubscriptionModal, setShopSubscriptionModal] = useState(false);
  const [isCommunityPost, setIsCommunityPost] = useState(false);
  const [isFreePost, setIsFreePost] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [timeZone, setTimeZone] = useState<string | null>(null);
  const [viewAsSubscriber, setViewAsSubscriber] = useState(false)


  const [videoSizes, setVideoSizes] = useState<{
    aspectRatio: number;
    width: number;
    height: number;
  }>();

  const videoRef = createRef<HTMLVideoElement>();

  const handleLoad = () => {
    const videoElement = videoRef?.current;

    if (videoElement) {
      if (videoElement.videoWidth && videoElement.videoHeight) {
        const aspectRatio =
          videoElement?.videoWidth / videoElement?.videoHeight;
        setVideoSizes({
          aspectRatio: aspectRatio,
          width: videoElement.videoWidth,
          height: videoElement?.videoHeight,
        });
      }
    }
  };

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(userSubscriptions, postInformation?.group?.id)
    } else if (!isLoggedIn || postInformation?.group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), postInformation?.group?.id)
    } else {
      return false
    }
  }, [userSubscriptions, postInformation, isLoggedIn])


  const backUrl = useMemo(() => {
    if (!!postInformation && isOwner && !!previousPage) {
      return previousPage;
    } else {
      if (postInformation?.group?.group_type === "community")
        return `/dashboard/community/${postInformation?.group?.id}/posts/`

      return `/group/${postInformation?.group?.slug}/library/`;
    }
  }, [isOwner, postInformation, previousPage])

  const headerTitle = useMemo(() => {
    if (!!postInformation) {
      if (postInformation?.group?.group_type === "community") {
        return "Artículo Gratuito"
      } else {
        return postInformation?.is_free ? "Artículo Liberado" : "Artículo Exclusivo"
      }
    }

    return "Artículo"
  }, [postInformation])


  const isMpInactiveSubscription = useMemo(() => {
    const groupSubscription = userSubscriptions?.find(subscription => subscription?.group?.id === postInformation?.group?.id)
    const mpInactiveSubscriptionStates = ['retrying', 'not_paid']
    return mpInactiveSubscriptionStates.includes(groupSubscription?.status)
  }, [userSubscriptions, postInformation])

  useEffect(() => {
    const dateTime = new Date();

    const timeZoneNameString = dateTime.toLocaleTimeString(undefined, {
      timeZoneName: 'long',
    }).split(' ')[2]

    setTimeZone(`${timeZoneNameString}`);
  }, []);

  useEffect(() => {
    //TODO consultar con jona que onda esto, le agregue el !postInformation para evitar requests de mas
    if (!!match.params && !postInformation && !postStates.loading) {
      dispatch(getPost({ idPost: match.params.id }));
    }
  }, [match]);

  useEffect(() => {
    setCreatedAt(dateSpliter(postInformation?.published_at || postInformation?.schedule_publish_at));

    if (!!postInformation && !!postInformation?.attached_files) {
      setAudioFiles(
        postInformation.attached_files.filter((attached_file) =>
          attached_file?.mime?.includes("audio")
        )
      );

      setImageFiles(
        postInformation.attached_files.filter((attached_file) =>
          attached_file?.mime?.includes("image")
        )
      );

      setPdfFiles(
        postInformation.attached_files.filter((attached_file) =>
          attached_file?.mime?.includes("pdf")
        )
      );

      setVideoFiles(
        postInformation.attached_files.filter((attached_file) =>
          attached_file?.mime?.includes("video")
        )
      );
    }
  }, [postInformation]);

  useEffect(() => {
    const isLoggedIn = !!userInformation;
    const isSubscribed =
      userSubscriptions?.filter(
        (subscription) => subscription.id === postInformation?.group?.id
      ).length > 0;
    const isPostOwner =
      !!postInformation &&
      !!userInformation &&
      postInformation?.group?.expert?.id === userInformation?.expert?.id;
    const isCommunityPost = postInformation?.group?.group_type === "community";
    const isFreePost = postInformation?.is_free;

    setIsFreePost(isFreePost);
    setIsCommunityPost(isCommunityPost);
    setIsOwner(isPostOwner);

    setShopSubscriptionModal(
      isCommunityPost &&
      (!isLoggedIn || (isLoggedIn && !isSubscribed && !isPostOwner))
    );
  }, [postInformation, userInformation]);

  useEffect(() => {
    if (sharePostInTelegramStates.success) {
      dispatch(hideModal());
      resetSharePostInTelegram();
    }
  }, [sharePostInTelegramStates]);

  useEffect(() => {
    if (showSubscriptionModal) {
      dispatch(showModal("ask-for-user-information"))
    }
  }, [showSubscriptionModal])

  useEffect(() => {
    if (isOwner) {
      if (getUrlParamByName("new") === "t") {
        dispatch(showModal("modal-new-post-created"));
        window.history.pushState(null, document.title, history.location.pathname);
      }

      if (getUrlParamByName("viewAsSubscriber") === "true") {
        setViewAsSubscriber(true)
      }
    }
  }, [isOwner]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        //title: `${postInformation?.group?.group_name} - Articulo Exclusivo`,
        title: headerTitle,
        back: backUrl,
        showBack: postInformation?.group?.group_type !== "community"
      },
      header: {
        visible: true,
        beforeHeader: <MPVerifyYourPaymentInformationSection />
      }
    });
  }, [postInformation, backUrl, headerTitle, isOwner]);

  useEffect(() => {
    if (subscriptionStates?.success) {
      dispatch(resetSubscription());
    }
  }, [subscriptionStates]);

  useEffect(() => {
    if (!!userSubscriptions) {
      const regex = /\/group\/([^\/]+)/;
      const result = location.pathname.match(regex);
      const id = result && result[1]
      const pendingInvite = userSubscriptions?.find((subscrition) => subscrition?.group?.id === Number(id) && subscrition.status === 'pending_invite')

      if (!!pendingInvite) {
        dispatch(showModal("pending-invitation"))
      }
    }
  }, [userSubscriptions, location]);

  useEffect(() => {
    if (!userInformation) {
      dispatch(getUserInformation())
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch(resetGetPost())
    }
  }, [])

  if (postStates.error) {
    return <Redirect to="/404" />;
  }

  if (postStates.loading) {
    return <Loader />;
  }

  return (
    <Page
      style={{
        ...(isFreePost &&
          !isCommunityPost && { paddingBottom: isMobile ? "168px" : "252px" }),
      }}
    >
      <GlobalStyles />

      <Helmet>
        <title>
          {`${postInformation?.group?.group_name} - ${postInformation?.title}`}
        </title>
        <meta name="description" content={`${postInformation?.group?.group_name} - ${postInformation?.title}`} />
      </Helmet>



      <Grid.Container style={{ position: 'relative', zIndex: 1 }}>
        <Grid.Row>
          <Grid.Col lg={8} offset={{ lg: 2 }}>
            <div style={{ height: 16 }} />

            <Header viewAsSubscriber={viewAsSubscriber} />

            <div style={{ height: 36 }} />

            <Title>{postInformation?.title}</Title>

            <div style={{ height: 50 }} />

            {!postInformation?.published_at &&
              postInformation?.can_access_to_full_post &&
              (!isOwner || viewAsSubscriber) &&
              postInformation?.show_scheduled_post
              ? (
                <BlockContentMessage>
                  <Icon icon={schedulerIcon} color="#000" size="24px" />
                  <BlockContentMessageTitle style={{ display: "block" }}>
                    {"El contenido se desbloqueará el"} {" "} <br />
                    {`${createdAt?.date} a las ${createdAt?.hours}:${createdAt?.minutes} hs (Zona Horaria ${timeZone}).`}
                  </BlockContentMessageTitle>
                  <Button
                    onClick={() => {
                      window.location.href = `/group/${postInformation?.group?.slug}/library`;
                    }}
                    options={{
                      type: "outline",
                      size: "lg",
                      skin: "purple",
                    }}
                  >
                    Volver
                  </Button>
                </BlockContentMessage>
              ) : (
                <>
                  <Text>
                    {!jsonStr && !!textStr && textStr}

                    {!!jsonStr && (
                      <RemirrorRenderer
                        json={jsonStr}
                        typeMap={typeMap}
                        markMap={markMap}
                      />
                    )}

                    {!jsonStr && !textStr && ""}
                  </Text>

                  <div style={{ height: 16 }} />


                  {videoFiles?.length > 0 &&
                    videoFiles?.map((attached_file) => (
                      <>
                        <video
                          onContextMenu={e => e.preventDefault()}
                          controlsList="nodownload"
                          ref={videoRef}
                          controls
                          width="100%"
                          key={`attached-file-${slugify(attached_file?.original_name)}`}
                          style={{
                            marginBottom: "10px",
                            display: "block",
                            ...(videoSizes && {
                              maxHeight:
                                videoSizes?.height > 640
                                  ? videoSizes?.height / 2
                                  : videoSizes?.height,
                              maxWidth:
                                videoSizes?.width > 1280
                                  ? videoSizes?.width / 2
                                  : videoSizes?.width,
                            }),
                          }}
                          onLoad={handleLoad}
                          onLoadedMetadata={handleLoad}
                        >
                          <source src={attached_file.signed_url} type={attached_file?.mime} />
                          Tu navegador no soporta Video.
                        </video >

                        <div style={{ height: 16 }} />
                      </>
                    )
                    )}

                  {!!postInformation?.attached_url && (
                    <>
                      {isYoutubeUrl(postInformation?.attached_url) && (
                        <AspectRatio
                          ratio="16/9"
                          maxWidth={940}
                          key={`attached-file-${slugify(
                            postInformation?.attached_url
                          )}`}
                        >
                          <iframe
                            style={{ maxWidth: "100%" }}
                            src={getYouTubeUrl(postInformation?.attached_url)}
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                        </AspectRatio>
                      )}

                      {isVimeoUrl(postInformation?.attached_url) && (
                        <VimeoVideo videoId={getVimeoVideoIdFromUrl(postInformation?.attached_url)} isPreview={true} />
                      )}

                      {isInstagramUrl(postInformation?.attached_url) && (
                        <InstagramReel
                          src={postInformation?.attached_url}
                          key={`attached-file-${slugify(
                            postInformation?.attached_url
                          )}`}
                        />
                      )}

                      {(!isVimeoUrl(postInformation?.attached_url)) && !isYoutubeUrl(postInformation?.attached_url) &&
                        !isInstagramUrl(postInformation?.attached_url) && (
                          <Link
                            target="_blank"
                            href={postInformation?.attached_url}
                            options={{
                              type: "filled",
                              skin: "purple",
                              size: "lg",
                              native: false,
                            }}
                          >
                            <InLine style={{ gap: "10px" }}>
                              Click aquí para acceder al contenido
                              <Icon icon={linkIcon} color="white" />
                            </InLine>
                          </Link>
                        )}
                      <div style={{ height: 16 }} />
                    </>
                  )}

                  {audioFiles.length > 0 &&
                    audioFiles.map((attached_file) => (
                      <>
                        <Audio key={`attached-file-${slugify(attached_file.original_name)}`}>
                          <span>
                            Escucha el audio:{" "}
                            {attached_file.original_name.replace(".mp3", "").replace(".ogg", "")}
                          </span>
                          <audio
                            controls
                            onContextMenu={e => postInformation.block_files_download ? e.preventDefault() : "undefined"}
                            controlsList={postInformation.block_files_download ? "nodownload" : undefined}
                            style={{
                              margin: "10px 0px 0px 0px",
                              height: "40px",
                              width: "100%",
                            }}
                          >
                            <source
                              src={attached_file.signed_url}
                              type={attached_file.mime}
                            />
                          </audio>
                        </Audio>
                        <div style={{ height: 16 }} />
                      </>
                    )
                    )}

                  {imageFiles.length > 0 &&
                    imageFiles.map((attached_file) => (
                      <>
                        <Image
                          style={{ display: 'block' }}
                          onContextMenu={e => postInformation.block_files_download ? e.preventDefault() : "undefined"}
                          src={attached_file.signed_url}
                          key={`attached-file-${slugify(attached_file.original_name)}`}
                        />
                        <div style={{ height: 16 }} />
                      </>
                    )
                    )}

                  {pdfFiles.length > 0 &&
                    pdfFiles.map((pdfFile) => (
                      <>
                        <PdfViewer
                          fileUrl={pdfFile.signed_url}
                          fileName={pdfFile.original_name}
                          blockDownloadFiles={postInformation.block_files_download || false}
                          key={`attached-file-${slugify(pdfFile.original_name)}`}
                        />
                        <div style={{ height: 16 }} />
                      </>
                    )
                    )}
                </>
              )}

            {isMpInactiveSubscription ? (
              <MPBlockedSection />
            ) : (
              <NoSubscribed />
            )}

            <div style={{ height: 16 }} />

            <Reactions isSubscribed={isUserSubscribed || isOwner} style={{ position: 'relative', zIndex: 1 }} />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <AskForUserInformation
        avatar={
          postInformation?.group?.expert?.profile_pic?.url || userAvatar
        }
        ctaText={"Ver el contenido"}
        title={postInformation?.title}
        group={postInformation?.group?.group_name}
        username={postInformation?.group?.expert?.username}
        idGroup={postInformation?.group?.expert?.id}
        handleSubmit={(data) => dispatch(subscribeToTheCommunity({ email: data?.email, idExpert: data?.idGroup }))}
      />


      {isFreePost &&
        !isCommunityPost &&
        (userSubscriptions?.filter(
          (subscription) =>
            (subscription?.status === "active" ||
              subscription?.status === "paid" ||
              subscription?.status === "non_renewing") &&
            subscription.group?.id === postInformation?.group?.id
        ).length <= 0 ||
          !userSubscriptions) && (
          <PayWall postSlug={postInformation?.group?.slug} />
        )
      }

      <SharePostInTelegramModal />
      <SharePostLinkModal />
      {postInformation?.published_at && <ConfirmPostCreation />}
      <ChangeToFreePostModal />
      <ChangeToNotFreePostModal />
      <DeletePostModal />
      <ChangePublishedDateModal />

      <Snackbar
        visible={
          sharePostInTelegramStates?.success || !!sharePostInTelegramStates?.error
        }
        options={{
          time: 2000,
          type: sharePostInTelegramStates?.success ? "success" : "error",
        }}
      >
        {sharePostInTelegramStates?.success
          ? `Contenido compartido en el grupo de chat`
          : `Error al compartir contenido en el grupo de chat`}
      </Snackbar>

      <div style={{ height: "50px" }} />
    </Page >
  );
};

const state = ({ userStore, postStore, groupStore, communityStore }) => {
  const { isLoggedIn } = userStore;
  const { data: userInformation } = userStore.information;
  const { data: userSubscriptions } = userStore.subscriptions;
  const { data: postInformation, states: postStates } = postStore.post;
  const { states: sharePostInTelegramStates } = postStore.sharePost;
  const { states: subscriptionStates } = communityStore.subscription;

  return {
    isLoggedIn,
    userInformation,
    userSubscriptions,
    postInformation,
    postStates,
    sharePostInTelegramStates,
    subscriptionStates
  };
};

export default connect(state)(Component);
