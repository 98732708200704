import {
  initialState,
  Actions,
  State,
  SET_SETTING,
  SET_CAROUSEL,
  SET_NEXT_ARROW,
  SET_PREV_ARROW,
  CURRENT_SLIDE,
} from "../types/carousel";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_SETTING:
      return {
        ...state,
        settings: { ...state.settings, ...action.payload },
      };
    case SET_CAROUSEL:
      return {
        ...state,
        carouselRef: action.payload,
      };
    case SET_NEXT_ARROW:
      return {
        ...state,
        nextArrowRef: action.payload,
      };
    case SET_PREV_ARROW:
      return {
        ...state,
        prevArrowRef: action.payload,
      };
    case CURRENT_SLIDE:
      return {
        ...state,
        currentSlide: action.payload,
      };
    default:
      return state;
  }
};

export default reducers;
