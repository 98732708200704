import * as Yup from "yup";

export const schema = Yup.object().shape({
  month: Yup.number(),
  year: Yup.number(),
});

interface FormValues {
  month: number;
  year: number;
  currency: string;
}

export const initialValues: FormValues = {
  month: new Date().getMonth()+1,
  year: new Date().getFullYear(),
  currency: "all"
};
