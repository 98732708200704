import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Helmet from "react-helmet";
import Alert from "../../../../components/Alert";
import Link from "../../../../components/Link";
import { Page, PageHeader, Title } from "../../components/styles";
import ModalNewGroup from "./components/ModalNewGroup";
import FilterByState from "./components/FilterByState";
import { ActionsBar, CardsGrid } from "./styles";
import Container from "../../../../components/Grid/Container";
import Row from "../../../../components/Grid/Row";
import Col from "../../../../components/Grid/Col";
import GroupCard from "../../../../components/GroupCard";
import slugify from "slugify";
import { Group } from "../../../../types";
import constants from "../../../../constants";
import { Redirect } from "react-router";
import { useLayout } from "../../../../providers/LayoutProvider";

interface ComponentProps {
  userInformation;
  userGroups: Group[];
  userGroupsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  match;
}

const wording = {
  membership: {
    title: "Mis Membresías",
    cta: "Crear una membresía",
  },
  experience: {
    title: "Mis Talleres",
    cta: "Crear un taller",
  },
};

const Component = ({
  match,
  userGroups,
  userGroupsStates,
  userInformation,
}: ComponentProps) => {
  const groupType = "membership";
  const { layout, setLayout } = useLayout();
  const [memberships, setMemberships] = useState<Group[]>();
  const [filteredGroups, setFilteredGroups] = useState<Group[]>();

  useEffect(() => {
    if (!!userGroups && !!groupType) {
      setMemberships(
        userGroups?.filter((group) => group?.group_type === groupType)
      );
    }
  }, [userGroups, groupType]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: !!groupType && wording[groupType]?.title,
        description: !!groupType && wording[groupType]?.title,
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: true,
        actions: [
          <Link
            href={constants.urls.dashboard.newMembership.url}
            options={{
              size: "lg",
              type: "filled",
              skin: "purple",
            }}
            style={{ minWidth: "210px" }}
          >
            Crear membresía
          </Link>
        ],
      },
    });
  }, [filteredGroups]);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <Row>
              <Col>
                <Alert
                  visible={true}
                  title="Empecemos a motivar a más personas 😎"
                  text="Desde esta sección podrás crear tu propia membresía"
                  id="empecemos-a-motivar-a-mas-personas"
                />
                <div style={{ height: "32px" }}></div>
              </Col>
            </Row>

            <Row>
              <Col>
                <ActionsBar>
                  <FilterByState
                    groups={memberships}
                    reset={match.url}
                    setFilteredGroups={setFilteredGroups}
                  />
                </ActionsBar>
              </Col>
            </Row>

            <Row>
              <Col>
                {!filteredGroups && <Col>Cargando</Col>}

                <CardsGrid>
                  {!!filteredGroups &&
                    !!userInformation &&
                    filteredGroups.map((group) => {
                      const { expert, ...rest } = userInformation;
                      return (
                        <GroupCard
                          key={slugify(String(group?.id), { lower: true })}
                          group={group}
                          expert={expert}
                          editLink={`/dashboard/${groupType}/edit/${group?.id}`}
                          homeGroupLink={`/dashboard/${groupType}/${group?.id}`}
                          previewLink={`${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.slug}`}
                        />
                      );
                    })}
                </CardsGrid>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <ModalNewGroup />
    </>
  );
};

const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: userGroups, states: userGroupsStates } = userStore.groups;

  return {
    userInformation,
    userGroups,
    userGroupsStates,
  };
};

export default connect(state)(Component);
