import { connect } from "react-redux";
import { Group } from "../../../../../types";
import { Section, SectionName, Title } from "../StyledComponents";
import { Text, Card } from "./styles";
import { getGroupType } from "../../../../../helpers/getGroupType";
import { useMarkdown } from "../../../../../hooks/useMarkdown";
import Markdown from "markdown-to-jsx";
import { RemirrorRenderer } from "@remirror/react";

interface ComponentProps {
  group: Group;
  groupStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ group }: ComponentProps) => {
  const { typeMap, markMap, jsonStr, textStr } = useMarkdown({
    brief: group?.this_is_for_me,
  });

  return (
    <Card>
      <Section>
        <SectionName>
          sobre {getGroupType({ group, options: { showArticle: true } })}
        </SectionName>
        <Title>¿Esto es para mí?</Title>
        <div style={{ height: "40px" }} />

        <Text style={{ textAlign: "center", maxWidth: "880px" }}>
          {!jsonStr && !!textStr && <Markdown>{textStr}</Markdown>}

          {!!jsonStr && (
            <RemirrorRenderer
              json={jsonStr}
              typeMap={typeMap}
              markMap={markMap}
            />
          )}
        </Text>
      </Section>
    </Card>
  );
};

const state = ({ groupStore }) => {
  const { data: group, states: groupStates } = groupStore.group;

  return {
    group,
    groupStates,
  };
};

export default connect(state)(Component);
