import styled from "styled-components";

export const Title = styled("h3")`
  margin: 0px;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
`;

export const HorizontalScrollWrapper = styled('div')`
  margin: 0px 0px 0px -10px;

  @media(min-width: 768px){
    margin: 0px calc(-1 * ((100vw * 8.33333%) + 10px));
  }
`

export const HorizontalScroll = styled('div')`
  overflow-x: scroll;
  display: flex;
  padding: 0px 10px 10px 10px;
  scroll-snap-type: x mandatory;
  scroll-padding: 0px 10px;

  ::-webkit-scrollbar {
    display: none;
  }

  & > * {
    scroll-snap-align: start;

    &:not(:last-child){
      margin-right: 16px;
    }
  }
`