import { useMemo } from 'react'
import { Row, Card, Plan, Price, Separator, Frecuency, Discount, Description, Status, Payments, Button, ValidTill } from './styles'
import Image from '../../../../../../../components/Image'
import Dropdown from '../../../../../../../components/DropdownBis'
import flagArgentina from '../../../../../../../constants/countries/flags/flag-AR.png'
import flagUnitedStates from '../../../../../../../constants/countries/flags/flag-US.png'
import flagEuropeanUnion from '../../../../../../../constants/countries/flags/flag-UE.png'
import moreVerticalIcon from '../../../../../../../assets/icons/more-vertical.svg'
import { PlanDto } from '../../../../../../../types/plan.dto'
import { DiscountDto } from '../../../../../../../types'
import dateHelpers from "../../../../../../../helpers/dates-hanlder";
import {formatPrice} from "../../../../../../../helpers/price-formatter";
import {selectedPlan} from "../../../../../../../store/actions/plan";

const status = {
  archived: 'Inactivo',
  active: 'Activo',
  expired: 'Vencido'
}

const currencies = [
  {
    flag: flagArgentina,
    iso: "ARS",
    label: "AR$",
    description: "Pesos Argentinos (ARS)",
  },
  {
    flag: flagEuropeanUnion,
    iso: "EUR",
    label: "€",
    description: "Euros (EUR)",
  },
  {
    flag: flagUnitedStates,
    iso: "USD",
    label: "US$",
    description: "Dolares Americanos (USD)",
  },
];

interface ComponentProps {
  plan: PlanDto
  discount: DiscountDto
  onSelect: (discount: DiscountDto) => void
  onActive: (discount: DiscountDto) => void
  onPause: (discount: DiscountDto) => void
}

const Component = ({
  plan,
  discount,
  onSelect,
  onActive,
  onPause,
}: ComponentProps) => {
  const paymentText = useMemo(() => {
    if (plan?.type === "subscription" && plan?.period) {
      const numeroCobros = discount?.duration_type !== "forever" && discount?.period / plan?.period;
      const cobroText = numeroCobros > 1 ? `En los primeros ${numeroCobros} cobros` : `En el primer cobro`
      const durationType = discount?.duration_type === "forever" ? "Duracion indefinida" : cobroText
      return `${durationType}`
    } else {
      return ""
    }
  }, [plan, discount])

  const currency = useMemo(() => {
    return currencies.find(item => item.iso === plan?.currency)
  }, [plan, discount])

  return (
    <Card featured={!!plan?.featured}>
      <Plan featured={!!plan?.featured}>
        <Row style={{ alignItems: "flex-start" }}>
          <Price>
            {`$${formatPrice(discount?.amount)} OFF`}
          </Price>

          <Description>
            <div>{paymentText}</div>
            <ValidTill>
              Descuento activo {discount?.valid_till ? (<span>hasta <br />el <strong> {dateHelpers.getDateInLocalTimeZone(discount?.valid_till)}</strong></span>) : (<span><strong>sin fecha de expiración</strong></span>)}
            </ValidTill>
          </Description>

          <Status status={discount?.status}>{status[discount?.status]}</Status>

          <Dropdown staticLabel={false} label="" icon={moreVerticalIcon} styles={{
            wrapper: { border: 0, padding: 0 },
            list: { minWidth: 100, padding: "8px 0px" },
            item: { padding: "0px 8px"}
          }}>
            <Button
              onClick={() => onSelect(discount)}
              key={`edit-discount-${discount?.id}`}
            >
              Editar
            </Button>


            {plan?.price > discount?.amount ? (
              <Button
                onClick={() => {
                  if (discount.status === 'archived') {
                    onActive(discount)
                  } else {
                    onPause(discount)
                  }
                }}
                key={`pause-discount-${discount.id}`}
                type="button"
              >
                {discount.status === 'archived' ? 'Activar' : 'Desactivar'}
              </Button>
            ) : <></>}
          </Dropdown>
        </Row>
      </Plan>
    </Card>
  )
}

export default Component
