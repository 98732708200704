import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Center } from "../../../../../../../../components/StyledComponents";
import ReactSelect from "../../../../../../../../components/FormikForm/ReactSelect";
import Submit from "../../../../../../../../components/FormikForm/Submit";
import {
  initialValues,
  schema,
} from "../../../../../../../../constants/forms/members-list/filters";
import { Filter, GroupedOptions, Option } from "../../../../";
import { Group } from "../../../../../../../../types";
import { useWindowSize } from "../../../../../../../../hooks/useFormHook";

interface ComponentProps {
  appliedFilters?: Filter[];
  setAppliedFilters?: React.Dispatch<
    React.SetStateAction<Filter[] | undefined>
  >;
  conditions: Option[];
  options: Option[];
}

const Component = ({
  setAppliedFilters,
  appliedFilters = [],
  options = [],
  conditions = [],
}: ComponentProps) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const [filteredOptions, setFilteredOptions] =
    useState<(Option | GroupedOptions)[]>();

  const groupOptions = (data: Option[]): (Option | GroupedOptions)[] => {
    const others = data.filter((group) => !group?.group_type);
    const experiences = data.filter(
      (group) => group?.group_type === "experience"
    );

    const events = data.filter(
      (group) => group?.group_type === "free_experience"
    );

    const memberships = data.filter(
      (group) => group?.group_type === "membership"
    );

    return [
      ...others,
      {
        label: "Membresía",
        options: memberships,
      },
      {
        label: "Talleres",
        options: experiences,
      },
      {
        label: "Eventos gratuitos",
        options: events,
      },
    ];
  };

  const onSubmit = ({ values, actions }) => {
    setAppliedFilters && setAppliedFilters([...appliedFilters, values]);
    actions.resetForm();
  };

  useEffect(() => {
    const values = appliedFilters.map((appliedFilter) => appliedFilter.value);
    setFilteredOptions(
      groupOptions(options.filter((option) => !values.includes(option.value)))
    );
  }, [appliedFilters]);

  useEffect(() => {
    setFilteredOptions(groupOptions(options));
  }, []);

  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      onSubmit={(values, actions) => {
        if (onSubmit) onSubmit({ values, actions });
      }}
      validateOnChange={false}
      validateOnBlur={formSubmmited}
      validationSchema={schema}
      enableReinitialize
    >
      {({ handleSubmit, values, errors, handleChange, handleBlur }) => {
        return (
          <form
            style={{ width: "100%", maxWidth: "690px" }}
            className="theme-form"
            onSubmit={(event) => {
              setFormSubmmited(true);
              handleSubmit(event);
            }}
            id="add-filter"
          >
            <Center
              horizontal="left"
              vertical="center"
              column={isMobile}
              gap={12}
            >
              <ReactSelect
                name="condition"
                placeholder="Condición"
                items={conditions}
                isClearable
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  marginBottom: 0,
                }}
              />

              <ReactSelect
                name="value"
                placeholder="Selecciona una opción"
                items={filteredOptions}
                isClearable
                onChange={handleChange}
                onBlur={handleBlur}
                options={{
                  marginBottom: 0,
                  loading: !filteredOptions,
                  ...(!isMobile && {
                    css: ".select__menu { min-width: calc(100% + 185px + 12px ); }",
                  }),
                }}
                style={{
                  minWidth: "285px",
                }}
              />

              <Submit
                isSubmmiting={false}
                options={{
                  type: "outline",
                  size: "lg",
                  skin: "purple",
                  block: isMobile,
                }}
                form="add-filter"
                style={{ minWidth: "185px" }}
              >
                Agregar filtro
              </Submit>
            </Center>
          </form>
        );
      }}
    </Formik>
  );
};

export default Component;
