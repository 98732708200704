import { CardPayment } from '@mercadopago/sdk-react';
import React, { useEffect, useMemo, useState } from 'react';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { Title, Text, Subtitle, CreditCards, FormEmptyState } from './styles'
import { User } from '../../../../types';
import { connect } from 'react-redux';
import CcAmericanExpressIcon from '../../../../assets/credit-cards/american-express.svg'
import CcCabalIcon from '../../../../assets/credit-cards/cabal.svg'
import CcMastercardIcon from '../../../../assets/credit-cards/mastercard.svg'
import CcNaranjaIcon from '../../../../assets/credit-cards/naranja.svg'
import CcVisaIcon from '../../../../assets/credit-cards/visa.svg'

interface ComponentProps {
  onSuccess,
  onError,
  onSubmit,
  user: User,
  price: number,
  publicKey: string,
  title?: JSX.Element | string,
  subtitle?: string
  submitText: string
  changeEmail?: boolean
  beforeForm?: JSX.Element | string,
  afterForm?: JSX.Element | string,
}

const Component = React.memo(({ user, onSubmit, submitText, publicKey, price, title, subtitle, beforeForm, afterForm, changeEmail = false }: ComponentProps) => {
  const [sdkInititialized, setSdkInitialized] = useState<boolean>(false)

  const initialization = useMemo(() => {
    if (!!price && price > 0) {
      return {
        amount: price,
        payer: {
          ...(process.env.REACT_APP_ENVIRONMENT !== "production" ? {email: "test_user_1799191595@testuser.com"} : (!changeEmail ? {email: user?.email} : {}))
          // ...(!changeEmail && { email: process.env.REACT_APP_ENVIRONMENT !== "production" ? "test_user_1799191595@testuser.com" : user?.email })
        },
      }
    } else {
      return null
    }
  }, [price, changeEmail, user?.email]);


  useEffect(() => {
    if (!sdkInititialized && !!publicKey) {
      try {
        // initMercadoPago('APP_USR-8ac153fe-60bc-41e4-8bb5-cd6fd1c7cdc7');
        // initMercadoPago('APP_USR-71450594-f11e-404a-8897-21628283a695'); //Mp Vendedor Externo
        initMercadoPago(publicKey); //Mp Vendedor Externo
        setSdkInitialized(true)
        console.log("MercadoPago SDK initialized successfully");
      } catch (error) {
        setSdkInitialized(false)
        console.error("Failed to initialize MercadoPago SDK", error);
      }
    }
  }, [sdkInititialized, publicKey])

  if (!sdkInititialized || !initialization || (!user && !changeEmail)) {
    return <FormEmptyState />
  }

  return (
    <div style={{ width: '100%', maxWidth: 350 }}>
      <Title>{title}</Title>

      {subtitle && (
        <>
          <div style={{ height: 10 }} />
          <Text>{subtitle}</Text>
        </>
      )}

      {beforeForm && <> {beforeForm} </>}

      <div style={{ height: 22 }} />

      <Subtitle>Tarjeta de crédito o débito</Subtitle>

      <div style={{ height: 12 }} />

      <CreditCards>
        <img src={CcVisaIcon} alt="Visa" />
        <img src={CcMastercardIcon} alt="Mastercard" />
        <img src={CcAmericanExpressIcon} alt="American Express" />
        <img src={CcNaranjaIcon} alt="Naranja" />
        <img src={CcCabalIcon} alt="Cabal" />
      </CreditCards>

      <div style={{ height: 39 }} />

      <CardPayment
        customization={{
          visual: {
            hideFormTitle: true,
            texts: {
              formSubmit: submitText,
              emailSectionTitle: `Agrega un email secundario`,
              email: {
                label: "Email alternativo",
                placeholder: `Utiliza un email distinto al de tu cuenta`
              }
            },
            style: {
              theme: 'flat',
              customVariables: {},
            },
          },
          paymentMethods: {
            maxInstallments: 1,
          },
        }}
        initialization={initialization}
        onSubmit={onSubmit}
        onReady={() => { }}
        onError={(error) => console.log('Error al iniciar el Brick', error)}
      />

      <div style={{ height: 10 }} />

      {afterForm && <> {afterForm} </>}
    </div>
  )
})

export default Component