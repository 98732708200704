import {
  initialState,
  Actions,
  State,
  GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS,
  GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_SUCCESS,
  GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_ERROR,
  RESET_GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_GROUP,
} from "../../types/group/get-expert-telegram-bot-url-for-notifications";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_GET_EXPERT_TELEGRAM_BOT_URL_FOR_NOTIFICATIONS_GROUP:
      return {
        ...state,
        states: {
          loading: false,
          error: false,
          success: false,
        },
      };
    default:
      return state;
  }
};

export default reducers;
