import { FinishWelcomeContentPostOutDto, FinishWelcomeContentPostInDto } from "../../../types/welcome-section";
export const FINISH_WELCOME_CONTENT_POST = "FINISH_WELCOME_CONTENT_POST";
export const FINISH_WELCOME_CONTENT_POST_SUCCESS = "FINISH_WELCOME_CONTENT_POST_SUCCESS";
export const FINISH_WELCOME_CONTENT_POST_ERROR = "FINISH_WELCOME_CONTENT_POST_ERROR";
export const RESET_FINISH_WELCOME_CONTENT_POST = "RESET_FINISH_WELCOME_CONTENT_POST";

export interface FinishWelcomeContentPost {
  type: typeof FINISH_WELCOME_CONTENT_POST;
  payload: FinishWelcomeContentPostOutDto;
}

export interface FinishWelcomeContentPostSuccess {
  type: typeof FINISH_WELCOME_CONTENT_POST_SUCCESS;
  payload: FinishWelcomeContentPostInDto;
}

export interface FinishWelcomeContentPostError {
  type: typeof FINISH_WELCOME_CONTENT_POST_ERROR;
  payload: string | boolean;
}

export interface ResetfinishWelcomeContentPost {
  type: typeof RESET_FINISH_WELCOME_CONTENT_POST;
  payload: null;
}

export interface State {
  data: FinishWelcomeContentPostInDto | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | FinishWelcomeContentPost
  | FinishWelcomeContentPostSuccess
  | FinishWelcomeContentPostError
  | ResetfinishWelcomeContentPost
