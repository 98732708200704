import styled from "styled-components";

export const IntroVideo = styled("section")`
  min-height: 100vh;
  padding: 150px 0px 100px;
  background-color: #452f61;
`;

export const Title = styled("h1")`
  margin: 0px 0px 30px;
  font-size: 48px;
  line-height: 1;
  text-align: center;
  color: white;
`;

export const SubTitle = styled("p")`
  margin: 0px auto 30px;
  font-size: 16px;
  max-width: 500px;
  text-align: center;
  color: white;
`;

export const Center = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;
