import styled, { css, keyframes } from "styled-components";
import UiButton from "../../../../../components/Button";
import LinkUi from "../../../../../components/Link";
import ButtonUi from "../../../../../components/Button";
import { Link as LinkRrd } from "react-router-dom";
import Icon from "../../../../../components/Icon";
import Markdown from "markdown-to-jsx";

export const Card = styled("div") <{ bigCard }>`
  padding: 16px;
  color: inherit;
  overflow: hidden;
  border-radius: 16px;
  min-height: ${({ bigCard }) => bigCard ? '350px' : '222px'};
  max-width: 383px;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  alig-items: flex-start;
  justify-content: space-between;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,.1);
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 3px 5px 3px rgba(0,0,0,.1);
  }
`;

export const Top = styled("div")`
  position: relative;
  z-index: 10;
  display: flex;
  align-elements: center;
  justify-content: flex-start;
  width: 100%;
`;

export const Actions = styled("div")`
  display: flex;
  width: 100%;
  align-elements: center;
  justify-content: flex-end;
  gap: 4px;
`;

export const Circle = styled("div")`
  position: relative;
  width: 21px;
  height: 21px;
  background: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const cleanCta = css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  pointer-events: auto;
  width: 21px;
  height: 21px;
  border-radius: 100%;
  min-width: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    box-shadow: none;
  }
`;

export const Link = styled(LinkUi)`
  ${cleanCta}
`;

export const Button = styled(ButtonUi)`
  ${cleanCta}
`;

export const Title = styled("p")`
  margin: 0px;
  font-weight: 500;
  font-size: 16px;
  text-overflow: ellipsis;
  color: black;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const Author = styled("span")`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: black;
`;

export const FutureDate = styled("div")`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  color:#fff;
  padding-left: 5px;
`;

export const Information = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const GoToPreview = styled(LinkRrd) <{ bigCard }>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding:  ${({ bigCard }) => bigCard ? '250px 15px 15px' : '150px 15px 15px'};
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Background = styled("div") <{ backgroundImage?, bigCard }>`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
  height: ${({ bigCard }) => bigCard ? '235px' : '135px'};
  transition: all 0.3s ease-in-out 0s;

  ${({ backgroundImage }) =>
    !!backgroundImage
      ? "background-image:url('" +
      backgroundImage +
      "'); background-size: cover; background-repeat: no-repeat; background-position: center;"
      : ""}

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 44.79%,
      rgba(0, 0, 0, 0.7) 65.62%
    );
  }
`;

export const FreeLabel = styled("div")`
  width: 245px;
  background-color: white;
  border-radius: 6px;
  padding: 2.5px 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const expand = keyframes`
  0% {
    padding: 0px;
    width: 0%;
  }
  100% {
    padding: 0px 5px;
    width: 100%;
  }
`

export const Reactions = styled('div') <{ reactions }>`
  display: flex;
`

export const EmojiWithCounter = styled('div')`
  height: 30px;
  padding: 0px 5px;
  border-radius: 17px;
  background-color: rgb(241, 241, 241);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.4) -1px 0px 1px -1px;
  position: relative;
  cursor: pointer;

  &:not(:first-child){
    margin-left: -10px;
  }

  div {
    &.reaction-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    
    &.emoji {
      
    }

    &.counter {
      width: 0px;
      overflow: hidden;
      font-size: 14px;
    }
  }

  &:hover {
    z-index: 10;

    div {
      &.counter {
        animation: ${expand} 0.2s ease-in-out forwards;
      }
    }
  }
  
`

export const Date = styled("div")`
  position: relative;
  background-color: white;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
`;

export const Day = styled("div")`
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.Black};
  line-height: normal;
`;

export const Number = styled("div")`
  font-weight: 700;
  font-size: 34px;
  color: ${({ theme }) => theme.colors.Black};
  line-height: normal;
`;


export const Hour = styled("div")`
  font-weight: 500;
  font-size: 12px;
  line-height: normal;
  color: ${({ theme }) => theme.colors.Black};
  text-transform: capitalize;
  width: 100%;
  text-align: right;
`;