import {
  ExpertName,
  LinkCard,
  ButtonCard,
  Avatar,
  Information,
  Tag,
  Status,
  Type,
  Title,
  InLine,
} from "./styles";
import Image from "../Image";
import { Expert, Group, Subscription } from "../../types";
import { getGroupType } from "../../helpers/getGroupType";
import { isSubscribed } from "../../helpers/isSubscribed";

interface PropsInterface {
  group: Group;
  expert: Expert;
  link: string;
  action?: () => void;
  subscription?: Subscription
}

const Component = ({ subscription, action, group, expert, link }: PropsInterface) => {
  const isMembreship = () => {
    return group?.group_type === "membership";
  };

  const Content = () => (
    <>
      <Avatar>
        <Image
          src={expert?.profile_pic?.url}
          width="auto"
          height="113px"
          alt=""
          lazy
        />
      </Avatar>

      <Information>
        <Type>{getGroupType({ group })}</Type>
        <Title>{group?.group_name}</Title>

        <div style={{ height: "4px" }} />

        <ExpertName>
          {!!group?.creator_name && `Por ${group?.creator_name}`}
        </ExpertName>

        <div style={{ height: "9px" }} />

        <InLine>
          {/* <Tag inverted={false}>{group?.group_name}</Tag> */}

          <Tag style={{ marginLeft: "5px" }}>
            {!!subscription && isSubscribed([subscription], group?.id) ? 'Suscripción activa' : 'Suscripción inactiva'}
          </Tag>


          {subscription?.status === "pending_invite" && (
            <Tag invited style={{ marginLeft: "5px" }}>
              Invitado
            </Tag>
          )}

        </InLine>
      </Information>
    </>
  )

  return subscription?.status === "pending_invite" && !!action ? (
    <ButtonCard onClick={action}>
      <Content />
    </ButtonCard>
  ) : (
    <LinkCard to={link}>
      <Content />
    </LinkCard>
  )

};

export default Component;
