import { CSSProperties } from "@remirror/theme";
import { Container } from "./styles";

interface propsInterface {
  margin?: number;
  children: any;
  style?: CSSProperties;
  className?: string;
}

const Component = ({ children, margin, ...rest }: propsInterface) => {
  return (
    <Container margin={margin} {...rest}>
      {children}
    </Container>
  );
};

export default Component;
