import styled from "styled-components";

export const CardsGrid = styled("div")`
  display: grid;
  gap: 10px;
  grid-auto-rows: 250px;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  
  @media(min-width: 768px) {
    gap: 20px;
    grid-auto-rows: 250px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }

  @media(min-width: 1200px) {
    grid-auto-rows: 250px;
    // height: 215px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }

  & > * {
    height: 215px;
  }

  & > * {
    @media (max-width: 767px){
      width: 100%;
      max-width: 100%;
    }
  }

`;