import earnedIncome from "../assets/statistics/earned-income.svg";
import totalMembers from "../assets/statistics/total-members.svg";
import totalIncomeInAMonth from "../assets/statistics/total-incom-in-a-month.svg";

const page = {
  statistics: [
    {
      type: "free_experience_first_kpi",
      image: earnedIncome,
      title: "Total de ingresos generados a través de tu taller",
      emptyState: {
        text: "Revisa en tu página de venta si hay algo que podrías mejorar.",
        cta: {
          label: "Ir a página de venta",
        },
      },
    },
    {
      type: "free_experience_second_kpi",
      image: totalMembers,
      title: "Total de personas que se suscribieron al taller",
      emptyState: {
        text: "Lanza un descuento promocional y suma más suscriptores.",
        cta: {
          label: "Lanzar descuento",
        },
      },
    },
    {
      type: "free_experience_third_kpi",
      image: totalIncomeInAMonth,
      title: "Ingresos recibidos este mes",
      emptyState: {
        text: "Revisa en tu página de venta si hay algo que podrías mejorar",
        cta: {
          label: "Ir a página de venta",
        },
      },
    },
  ],
};

export default page;
