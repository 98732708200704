import ContentLoader from "react-content-loader";

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={194}
    viewBox="0 0 320 194"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="16" ry="16" width="320" height="194" />
    <rect x="1" y="1" rx="16" ry="16" width="319" height="193" />
    <path d="M 0 18 C 0 8.059 8.059 0 18 0 h 284 c 9.941 0 18 8.059 18 18 v 82 H 0 V 18 z" />
    <path d="M 0 99.5 h 320" />
  </ContentLoader>
);

export default MyLoader;
