import styled from "styled-components";
import Image from "../../../Image";
import { Link } from "react-router-dom";
import { rainbowBackgroundGradient } from "../../../../components/StyledComponents";

export const AvatarImage = styled("img")`
  width: 40px;
  min-height: 40px;
  border-radius: 100%;
  margin-bottom: 0px;
  outline: 2px solid white;
  transition: all 0.2s ease-in-out;
  background-color: #f5f5f5;
  display: block;
`;

export const Header = styled("header")`
  min-height: 40px;
  width: 100%;
  max-width: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Toggle = styled("div")<{ skin }>`
  ${({ skin }) =>
    skin === "rainbow" ? rainbowBackgroundGradient : "background: #6C5CE7;"}
  width: 41px;
  height: 41px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled("h1")`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin: 0px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
`;

export const ToggleMenu = styled("button")`
  margin: 0px;
  padding: 0px;
  border: 0px;
  background: transparent;
`;
