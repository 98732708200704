import firebase from "firebase/app";
import LogoutMenu from "./LogoutMenu";
import LoginMenu from "./LoginMenu";
import { Header, Img, Section, Navbar } from "./styles";

import logo from "./../../assets/logo.svg";
import icon from "./../../assets/logo-icon.svg";
import Grid from "../Grid";
import { connect } from "react-redux";
import { useWindowSize } from "../../hooks/useWindowSize";

const Component = ({ forwardedRef, currentPage }: any) => {
  var user = firebase.auth().currentUser;
  const { isMobile } = useWindowSize();

  return (
    <Section>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <Header ref={forwardedRef}>
              <a href="/">
                {!isMobile && (
                  <Img
                    src={logo}
                    width="128px"
                    height="25px"
                    className="logo-desktop"
                    alt="Klouser"
                    lazy
                  />
                )}
                {isMobile && (
                  <Img
                    src={icon}
                    width="40px"
                    height="36px"
                    className="logo-mobile"
                    alt="Klouser"
                    lazy
                  />
                )}
              </a>
              <Navbar>
                {!!user ? (
                  <LoginMenu />
                ) : (
                  <LogoutMenu currentPage={currentPage} />
                )}
              </Navbar>
            </Header>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Section>
  );
};

const state = ({ pageStore }) => ({
  currentPage: pageStore.name,
});

export default connect(state)(Component);
