import { Card, Title, Text } from './styles'
import sadIcon from '../../../../../assets/icons/sad.svg'
import Icon from '../../../../../components/Icon'
import { CSSProperties, useMemo } from 'react'
import { Group, Subscription } from '../../../../../types'
import { getUrlParamByName } from '../../../../../helpers/get-url-param'
import { connect } from 'react-redux'

interface ComponentProps {
  style?: CSSProperties
  userSubscriptions: Subscription[]
  group: Group
}


const reasonsCopies = (reason, group_name) => {
  const texts = {
    insufficient_amount: {
      title: "Fondos insuficientes",
      description: <>Intentamos procesar tu pago, pero no hay fondos suficientes en tu tarjeta.<br /><br />Por favor, comprueba tu saldo disponible e intentalo nuevamente o  prueba con otro metodo de pago.</>
    },
    card_limit: {
      title: "Límite de tarjeta alcanzado",
      description: <>El pago no se pudo procesar porque tu tarjeta ha alcanzado su límite de gasto.<br /><br />Te sugerimos verificar tu límite o utilizar otro método de pago.</>
    },
    high_risk: {
      title: "Tu pago ha fallado",
      description: <>Tuvimos un problema al procesar el pago de tu suscripción a {group_name}.<br /><br />La transacción con tu tarjeta no pudo completarse. Te sugerimos probar con otro metodo de pago</>
    },
    call_for_authorize: {
      title: "Tu pago ha fallado",
      description: <>Tuvimos un problema al procesar el pago de tu suscripción a {group_name}.<br /><br />La transacción con tu tarjeta no pudo completarse. Te sugerimos probar con otro metodo de pago</>
    },
    other: {
      title: "Tu pago ha fallado",
      description: <>Tuvimos un problema al procesar el pago de tu suscripción a {group_name}.<br /><br />La transacción con tu tarjeta no pudo completarse. Te sugerimos probar con otro metodo de pago</>
    }
  }

  return reason ? (texts[reason] ? texts[reason] : texts['other']) : texts['other']
}

const Component = ({ style, userSubscriptions, group }: ComponentProps) => {
  const subscriptionId = useMemo(() => getUrlParamByName('subscriptionId'), []);

  const subscription = useMemo(() =>
    userSubscriptions?.find((suscription) => suscription?.id === Number(subscriptionId)),
    [userSubscriptions, subscriptionId]
  )

  return (
    <Card {...(!!style ? { style } : {})}>
      <Icon icon={sadIcon} size="24px" color="#D82E33" />
      <div style={{ height: 12 }} />
      <Title>{reasonsCopies(subscription?.current_rejected_reason || 'other', group?.group_name)?.title}</Title>
      <div style={{ height: 12 }} />
      <Text>{reasonsCopies(subscription?.current_rejected_reason || 'other', group?.group_name)?.description}</Text>
    </Card>
  )
}


const state = ({ userStore, groupStore }) => {
  const { data: userSubscriptions } = userStore.subscriptions;
  const { data: group } = groupStore?.group;

  return {
    group,
    userSubscriptions
  };
};

export default connect(state)(Component);