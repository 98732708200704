import { connect, useDispatch } from "react-redux";
import {
  changeUserAvatar,
  resetChangeUserAvatar,
} from "./../../../../../store/actions/user";
import Snackbar from "../../../../../components/Snackbar";
import { Data, Button, Section } from "../../styles";
import { Form } from "./styles";
import AvatarEditor from "../../../../../components/Form/AvatarEditor";
import { useForm } from "react-hook-form";
import { useEffect, useState, useRef, useMemo } from "react";
import { hideModal } from "../../../../../store/actions/modal";

const Component = ({ changeUserAvatarStates, userInformation }) => {
  const dispatch = useDispatch();
  const [profilePic, setProfilePic] = useState();
  const ref = useRef<HTMLFormElement>();

  const onFormSubmit = (data) => {
    dispatch(changeUserAvatar({ ...data, userId: userInformation?.id }));
  };

  const onFormError = (data) => {};

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    if (userInformation?.expert && userInformation?.expert?.profile_pic) {
      setProfilePic(userInformation?.expert.profile_pic.url);
    }
  }, [userInformation]);

  useEffect(() => {
    dispatch(hideModal());
    setTimeout(() => {
      dispatch(resetChangeUserAvatar());
    }, 5000);
  }, [changeUserAvatarStates]);

  return (
    <>
      <Section>
        <Data>Foto de perfil</Data>

        <Form
          onSubmit={handleSubmit(onFormSubmit, onFormError)}
          id="change-avatar"
          ref={ref}
        >
          <AvatarEditor
            onSave={() => {
              ref.current?.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
              );
            }}
            name="profile_pic"
            setValue={setValue}
            picture={profilePic}
            register={register}
            errors={{ ...errors }}
          />
        </Form>
      </Section>

      <Snackbar
        visible={
          changeUserAvatarStates.success || !!changeUserAvatarStates.error
        }
        options={{
          time: 2000,
          type: changeUserAvatarStates.success ? "success" : "error",
        }}
      >
        {changeUserAvatarStates.success
          ? "Avatar actualizado."
          : "Error al actualizar el avatar."}
      </Snackbar>
    </>
  );
};

const state = ({ userStore }) => {
  const { data: userInformation } = userStore.information;
  const { states: changeUserAvatarStates } = userStore.changeUserAvatar;
  return {
    userInformation,
    changeUserAvatarStates,
  };
};

export default connect(state)(Component);
