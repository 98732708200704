import styled from "styled-components";

export const InLine = styled("div")`
  display: flex;
  flex-shrink: 0;
`;

export const CardsGrid = styled("div") <{ size }>` 
  padding: 10px 0px;
  display: grid;
  gap: 16px;
  grid-template-rows:${({ size }) => (size === "large" ? "250px" : "110px")};
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
`;

export const CardsGridLarge = styled("div")`
  display: grid;
  gap: 16px;
  // grid-auto-rows: 237px;
  // grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
`;

export const SectionTitle = styled("h2")`
  margin: 0 0 16px 0;
  font-size: 18px;
  font-weight: normal;
`;

export const SessionCardSkeleton = styled("div")`
  background-color: #e0e4ea;
  border-radius: 14px;
`;

export const Center = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const TextImg = styled("p")`
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  & > span {
    font-style: oblique;
    font-weight: 100;
  }
`;
