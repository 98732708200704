import {
  NewModuleOutDto,
  UpdateOneModuleOutDto,
  GetOneModuleOutDto,
  GetAllModulesOutDto,
  DeleteOneModuleOutDto,
  ReorderModulesOutDto,
  PublishModulesOutDto,
  RestoreModulesOutDto
} from "../../types/module";

import {
  NEW_MODULE,
  NewModule,
  ResetNewModule,
  RESET_NEW_MODULE,
} from "../types/module/new-module";

import {
  UPDATE_MODULE,
  UpdateModule,
  ResetUpdateModule,
  RESET_UPDATE_MODULE,
} from "../types/module/update-module";

import {
  DELETE_MODULE,
  DeleteModule,
  RESET_DELETE_MODULE,
  ResetDeleteModule,
} from "../types/module/delete-module";

import {
  REORDER_MODULES,
  ReorderModules,
  RESET_REORDER_MODULES,
  ResetReorderModules,
} from "../types/module/reorder-modules";

import {
  SELECTED_MODULE,
  SelectedModule,
} from "../types/module/selected-module";

import {
  PUBLISH_MODULES,
  PublishModules,
  RESET_PUBLISH_MODULES,
  ResetPublishModules,
} from "../types/module/publish-modules";

import {
  RESTORE_MODULES,
  RestoreModules,
  RESET_RESTORE_MODULES,
  ResetRestoreModules,
} from "../types/module/restore-modules";

import { DeleteFile, DELETE_FILE } from "../types/module/delete-file";

import { GetAllModules, GET_ALL_MODULES } from "../types/module/all-modules";

export const newModule = (payload: NewModuleOutDto): NewModule => {
  return { type: NEW_MODULE, payload };
};

export const resetNewModule = (): ResetNewModule => {
  return { type: RESET_NEW_MODULE, payload: null };
};

export const updateModule = (payload: UpdateOneModuleOutDto): UpdateModule => {
  return { type: UPDATE_MODULE, payload };
};

export const resetUpdateModule = (): ResetUpdateModule => {
  return { type: RESET_UPDATE_MODULE, payload: null };
};

export const reorderModules = (
  payload: ReorderModulesOutDto
): ReorderModules => {
  return { type: REORDER_MODULES, payload };
};

export const resetReorderModules = (): ResetReorderModules => {
  return { type: RESET_REORDER_MODULES, payload: null };
};

export const deleteModule = (payload: DeleteOneModuleOutDto): DeleteModule => {
  return { type: DELETE_MODULE, payload };
};

export const resetDeleteModule = (): ResetDeleteModule => {
  return { type: RESET_DELETE_MODULE, payload: null };
};

export const getAllModules = (payload: GetAllModulesOutDto): GetAllModules => {
  return { type: GET_ALL_MODULES, payload };
};

export const selectedModule = (payload: GetOneModuleOutDto): SelectedModule => {
  return { type: SELECTED_MODULE, payload };
};

export const deleteFile = (payload: { idFile: number }): DeleteFile => {
  return { type: DELETE_FILE, payload };
};

export const publishModules = (payload: PublishModulesOutDto): PublishModules => {
  return { type: PUBLISH_MODULES, payload };
};

export const resetPublishModules = (): ResetPublishModules => {
  return { type: RESET_PUBLISH_MODULES, payload: null };
};

export const restoreModules = (payload: RestoreModulesOutDto): RestoreModules => {
  return { type: RESTORE_MODULES, payload };
};

export const resetRestoreModules = (): ResetRestoreModules => {
  return { type: RESET_RESTORE_MODULES, payload: null };
};