import styled from "styled-components";

export const Card = styled("div")`
  background-color: white;
  padding: 16px 24px;
  border-radius: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;
