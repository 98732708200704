import { Label, StepNumber, Text } from "../styles";
import { AddBenefit } from "./styles";
import Benefit from "./components/Benefit";
import TipCta from "../TipCta";
import { useEffect, useState } from "react";

const defaultBenefits = [
  { name: "group_perks[0]", value: "" },
  { name: "group_perks[1]", value: "" },
  { name: "group_perks[2]", value: "" },
  { name: "group_perks[3]", value: "" },
  { name: "group_perks[4]", value: "" },
];

interface Benefit {
  name: string;
  value: string;
}

interface ComponentProps {
  onFocus;
  onBlur?;
  onChange?;
  setValue?
  fieldNumber: number;
  register;
  unregister;
  errors;
  labelForwardRef;
  benefits?;
  fildName: any;
}

const Component = ({
  onFocus,
  onBlur,
  onChange,
  setValue,
  fieldNumber,
  register,
  unregister,
  errors,
  labelForwardRef,
  benefits = defaultBenefits,
  fildName,
  ...rest
}: ComponentProps) => {
  const [counter, setCounter] = useState(defaultBenefits.length);
  const [benefitsList, setBenefitsList] = useState<Benefit[]>([
    ...defaultBenefits,
  ]);

  const handleChange = (index, value, name) => {
    const benefits = [...benefitsList];
    benefits[index].value = value;
    setBenefitsList([...benefits]);
  };

  const handleFocus = (name = "group_perks[0]") => {
    onFocus(fieldNumber, name);
  };

  const addBenefit = () => {
    const name = `group_perks[${counter}]`;
    const value = "";
    setBenefitsList([...benefitsList, { name, value }]);
    setCounter(counter + 1);
  };

  const removeBenefit = (removedBenefit) => {
    const benefits = [...benefitsList];
    const newArray = benefits.filter(
      (benefit) => benefit.name !== removedBenefit.name
    );

    unregister(removedBenefit.name);

    for (const newBenefit of newArray) {
      setValue(newBenefit.name, newBenefit.value)
      register(newBenefit.name);
    }

    setBenefitsList(newArray);
  };

  useEffect(() => {
    onBlur();
  }, [benefitsList]);

  useEffect(() => {
    if (!!benefits) {
      setBenefitsList(
        benefits.map((benefit, index) => {
          return { name: `group_perks[${index}]`, value: benefit };
        })
      );
      setCounter(benefits.length);
    }
  }, [benefits]);

  return (
    <>
      <Label>
        <Text>
          <StepNumber>{fieldNumber - 1}.</StepNumber>
          <span>{fildName}</span>
        </Text>

        {/*<TipCta
          htmlFor="group_perks[0]"
          forwardRef={labelForwardRef}
          onClick={handleFocus}
        />*/}
      </Label>

      {benefitsList.map((benefit, index) => {
        const { name, value } = benefit;

        return (
          <Benefit
            name={name}
            id={name}
            key={name}
            placeholder={`Beneficio ${index + 1}`}
            canDelete={index > 0}
            onChange={(event) => {
              const { value } = event.target as HTMLInputElement;
              handleChange(index, value, name);
            }}
            onDelete={() => {
              removeBenefit(benefit);
            }}
            maxLength={300}
            options={{ marginBottom: 42 }}
            onFocus={() => index === 0 && handleFocus()}
            onBlur={onBlur}
            rules={
              index === 0 && {
                required: true,
              }
            }
            errors={errors}
            register={register}
            {...rest}
          />
        );
      })}

      <AddBenefit
        options={{
          type: "outline",
          size: "sm",
          skin: "purple",
        }}
        onClick={addBenefit}
      >
        Agregar otro beneficio
      </AddBenefit>
    </>
  );
};

export default Component;
