import styled from "styled-components";

export const MenuButton = styled('button')`
  font-weight: normal!important; 
  line-height: 26px!important; 
  color: #2d3436!important;
  width: 100%;
  text-align: right;
  display: flex!important;
  align-items: flex-end!important;
  justify-content: flex-end!important;
  padding: 0px 4px!important;

  &:hover {
    background-color: #6C5CE7;
    color: white!important;
    border-radius: 4px;
  }

  &:disabled {
    background-color: #fff!important; /* Change the background color for disabled state */
    color: #a9a9a9!important; /* Change the text color for disabled state */
    cursor: not-allowed; /* Change the cursor to indicate that the button is not clickable */
  }

  
`

export const Empty = styled("div")`
  width: calc(100% / 2);
  height: 22px;
  margin: 4px 0px;
  border-radius: 4px;
  background-color: #f5f5f5;
`;

export const Table = styled("div")`
  // min-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  
  overflow-x: scroll;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const TH = styled("div")`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  min-width: 100px;
  min-height: 50px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.xsMin}) {
    min-width: 200px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    min-width: 170px;
  }

  &.sticky {
    position: sticky;
    right: 0;
    z-index: 10;
    box-shadow: -10px 15px 22px 0px rgba(0,0,0,.26);
  }

  &.sticky-left {
    position: sticky;
    z-index: 1;
    left: 0;
    box-shadow: 2px 4px 6px 0px rgba(0,0,0,.15)
  }

  &.sticky-right {
    @media(min-width: 768px){
      position: sticky;
      right: 0;
      box-shadow: -2px 4px 6px 0px rgba(0,0,0,.15);
    }
  }
`;

export const TD = styled("div")`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 5px 0px;
  min-width: 100px;
  min-height: 49px;
  
  @media (min-width: ${(props) => props.theme.mediaBreakpoints.xsMin}) {
    min-width: 200px;
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    min-width: 170px;
  }

  &.sticky-left {
    position: sticky;
    z-index: 10;
    left: 0;
    box-shadow: 2px 4px 6px 0px rgba(0,0,0,.15);
  }

  &.sticky-right {
    @media(min-width: 768px){
      position: sticky;
      z-index: 10;
      right: 0;
      box-shadow: -2px 4px 6px 0px rgba(0,0,0,.15);
    }
  }
`;

export const TR = styled("div")`
  display: grid;
  grid-template-columns: 5fr 5fr 3fr 3fr 3fr 3fr 1fr 2fr 2fr 1fr;
  background-color: #fafafa;

  ${TH}:not(:last-child),
  ${TD}:not(:last-child) {
    margin-right: 25px;
  }
`;


export const TableHead = styled("div")`
  ${TR} {
    border-bottom: 1px solid #e0e4ea;
  }
`;

export const TableBody = styled("div")`
  ${TR}:not(:last-child) {
    border-bottom: 1px solid #e0e4ea;
  }
`;

export const EmptyState = styled("div")`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TotalMembers = styled("div")`
  padding: 5px 15px;
  text-align: center;
  font-weight: bold;
  //background: #6b5ce7;
  border: 1px solid #6b5ce7;
  color: #6b5ce7;
  font-size: 14px;
  border-radius: 21px;
  display: inline-block;
  line-height: 22px;
  margin-bottom: 12px;
`;
