import styled from "styled-components";

export const Pill = styled("span") <{ styles }>`
cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-shrink: 0;
  font-weight: 600;
  background: ${({ styles }) => styles?.background};
  color: ${({ styles }) => styles?.text};
  line-height: 20px;
  border-radius: 5px;
  padding: 5px 10px;
`;
