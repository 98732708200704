import styled, { createGlobalStyle } from "styled-components";
import {
  Dot,
  ButtonBack,
  ButtonNext,
  Slide as SlidePrc,
} from "pure-react-carousel";

export const GlobalStyles = createGlobalStyle`
  .carousel{
    &.review-carousel {
      width: 100%;
  
      .carousel__slider {
        width: 100%;
        padding: 15px calc(15%);
  
        @media (min-width: 768px) {
          padding: 15px calc(30% - 10px);
        }
  
        @media (min-width: 1024px) {
          padding: 15px calc(100% / 3 + 2px);
        }

        .carousel__slider-tray {
          
        }
      }
    }
  }
`;

export const ButtonsNext = styled(ButtonNext)`
  z-index: 2;
  background: none;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const ButtonsBack = styled(ButtonBack)`
  z-index: 2;
  background: none;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const CarouselContent = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const Slide = styled(SlidePrc)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: transform 0.3s ease-in-out;
  transform: scale(0.8);

  &.carousel__slide--hidden {
    max-width: 315px;
  }

  &.carousel__slide--visible {
    transform: scale(1);
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
  }
`;

export const ReviewCard = styled("div")`
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  padding: 16px;
  position: relative;
  transition: all 0.3s ease-in-out;
`;

export const Message = styled("p")`
  margin: 0px 0px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  min-height: 51px;
  transition: all 0.3s ease-in-out;

  // overflow: hidden;
  // display: -webkit-box;
  // -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;
`;

export const Name = styled("p")`
  margin: 0px;
  font-family: "Playfair Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
`;

export const Occupation = styled("p")`
  margin: 0px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #6c5ce7;
  transition: all 0.3s ease-in-out;
`;
