import { components } from './components'
import { globals } from './globals'
import logoBig from "../../../../assets/logo.svg";
import logoSmall from "../../../../assets/logo-icon.svg";

const theme = {
  ...globals,
  ...components,
  logo: {
    big: logoBig,
    small: logoSmall
  },
}

export default theme
