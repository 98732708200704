import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Group } from "../../../../types";
import Grid from "../../../../components/Grid";
import { Preference } from "../../../../types/preferences";
import { useWindowSize } from "../../../../hooks/useFormHook";
import Desktop from "./components/Desktop";
import Mobile from "./components/Mobile";
import { showModal } from "../../../../store/actions/modal";

interface ComponentProps {
  slides?: any;
  groupInformation: Group;
  preferences: Preference[];
  preferencesStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

export const Component = ({
  slides,
  groupInformation,
  preferences,
  preferencesStates,
}: ComponentProps) => {
  const { isDesktop, isTabletLandscape } = useWindowSize();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState<string | null>();

  useEffect(() => {
    if (groupInformation) {
      setId(`initial-steps-group-${groupInformation.id}`);
    }
  }, [groupInformation]);

  useEffect(() => {
    let showSection: Preference | null = null;

    showSection = preferences?.filter(
      (preference) => preference.preferenceSlug === id
    )[0];

    if (
      (preferencesStates.success || preferencesStates.error) &&
      !showSection &&
      id !== ""
    ) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [preferences, id]);

  useEffect(() => {
    if (!isDesktop && !isTabletLandscape && visible) {
      dispatch(showModal("modal-initial-steps"));
    }
  }, [isDesktop, isTabletLandscape]);

  if (!id || !visible) {
    return null;
  }

  return isDesktop || isTabletLandscape ? (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <Desktop id={id} slides={slides} />
          <div style={{ height: "32px" }}></div>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  ) : (
    <Mobile id={id} slides={slides} />
  );
};

const state = ({ groupStore, userStore, preferencesStore }) => {
  const { data: userInformation } = userStore.information;
  const { group } = groupStore;
  const { data: groupInformation } = group;
  const { data: preferences, states: preferencesStates } =
    preferencesStore.preferences;

  return {
    groupInformation,
    userInformation,
    preferences,
    preferencesStates,
  };
};

export default connect(state)(Component);
