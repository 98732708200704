import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Transition } from "react-transition-group";
import { connect, useDispatch } from "react-redux";
import { Animation } from "../Animation";
import { hideModal, showModal } from "../../store/actions/modal";
import { useSwipeable } from "react-swipeable";

import { Backdrop, SwipeAnimation, Swipe, SwipeContent, TransparentBackdrop } from "./styles";

import closeIcon from "../../assets/icons/close.svg";

export interface ModalProps {
  animation?: string;
  children: any;
  showClose?: boolean;
  title?: string;
  name: string;
  onClose?: () => void;
  visibleModal?: string | null;
  styles?: any;
  width?: string;
}

export const Component = ({
  animation = "fadeInSlideUp",
  showClose = true,
  children,
  title,
  name,
  onClose,
  visibleModal,
  styles,
  width,
}: ModalProps) => {
  const swipeDOM = document.getElementById("swipe") as HTMLElement;
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);

  const handlers = useSwipeable({
    onSwiped: (eventData) => console.log("User Swiped!", eventData),
    ...{
      trackMouse: false,
      preventScrollOnSwipe: true,
      onSwipedUp: () => {
        dispatch(showModal(name));
      },
      onSwipedDown: () => {
        dispatch(hideModal());
      },
    },
  });

  const handleClose = () => {
    dispatch(hideModal());
    setIsVisible(false);

    if (onClose) {
      onClose();
    }
  };

  const setBodyStyles = () => {
    if (isVisible) {
      document.body.style.height = "100%";
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    setIsVisible(name === visibleModal);

    if (name !== visibleModal) {
      if (onClose) {
        onClose();
      }
    }
  }, [name, visibleModal]);

  useEffect(() => {
    setBodyStyles();
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Transition in={isVisible} timeout={350} mountOnEnter>
        {(state) => (
          <>
            <SwipeAnimation
              name={animation}
              className={`animation-${state}`}
              width={width}
              {...(!!styles && styles)}
            >
              <Swipe>
                <SwipeContent>{children}</SwipeContent>
              </Swipe>

              <TransparentBackdrop onClick={() => handleClose()} />
            </SwipeAnimation>


            <Animation name="fadeIn" className={`animation-${state}`}>
              <Backdrop onClick={() => handleClose()} />
            </Animation>
          </>
        )}
      </Transition>
    </>,
    swipeDOM
  );
};

const state = ({ modalStore }) => ({ ...modalStore });

export default connect(state)(Component);
