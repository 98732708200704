import {
  UpdateOneModuleInDto,
  UpdateOneModuleOutDto,
} from "../../../types/module";

export const UPDATE_MODULE = "UPDATE_MODULE";
export const UPDATE_MODULE_SUCCESS = "UPDATE_MODULE_SUCCESS";
export const UPDATE_MODULE_ERROR = "UPDATE_MODULE_ERROR";
export const RESET_UPDATE_MODULE = "RESET_UPDATE_MODULE";

export interface UpdateModule {
  type: typeof UPDATE_MODULE;
  payload: UpdateOneModuleOutDto;
}

export interface UpdateModuleSuccess {
  type: typeof UPDATE_MODULE_SUCCESS;
  payload: UpdateOneModuleInDto;
}

export interface UpdateModuleError {
  type: typeof UPDATE_MODULE_ERROR;
  payload: string | boolean;
}

export interface ResetUpdateModule {
  type: typeof RESET_UPDATE_MODULE;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | UpdateModule
  | UpdateModuleSuccess
  | UpdateModuleError
  | ResetUpdateModule;
