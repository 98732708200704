import { put, call, takeLatest, select } from "redux-saga/effects";
import {
  CURRENT_SLIDE,
  NEXT_SLIDE,
  PREV_SLIDE,
  PAUSE_SLIDE,
  PLAY_SLIDE,
  GO_TO_SLIDE,
} from "../types/carousel";

const getCurrentCarousel = ({ carouselStore }) => carouselStore.carouselRef;

function* nextSlide() {
  const carousel = yield select(getCurrentCarousel);
  if (!!carousel && !!carousel.current) {
    carousel.current.slickNext();
  }
  //yield put({ type: CURRENT_SLIDE, payload: null });
}

function* prevSlide() {
  const carousel = yield select(getCurrentCarousel);
  if (!!carousel && !!carousel.current) {
    carousel.current.slickPrev();
  }
  //yield put({ type: CURRENT_SLIDE, payload: null });
}

function* pauseSlide() {
  const carousel = yield select(getCurrentCarousel);
  if (!!carousel && !!carousel.current) {
    carousel.current.slickPause();
  }
  //yield put({ type: CURRENT_SLIDE, payload: null });
}

function* playSlide() {
  const carousel = yield select(getCurrentCarousel);
  if (!!carousel && !!carousel.current) {
    carousel.current.slickPlay();
  }
  //yield put({ type: CURRENT_SLIDE, payload: null });
}

function* goToSlide({ payload }) {
  const carousel = yield select(getCurrentCarousel);
  if (!!carousel && !!carousel.current) {
    carousel.current.slickGoTo(payload);
    yield put({ type: CURRENT_SLIDE, payload });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(NEXT_SLIDE, nextSlide);
  yield takeLatest<any>(PREV_SLIDE, prevSlide);
  yield takeLatest<any>(PAUSE_SLIDE, pauseSlide);
  yield takeLatest<any>(PLAY_SLIDE, playSlide);
  yield takeLatest<any>(GO_TO_SLIDE, goToSlide);
}
