import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import { resetNewSurvey } from "../../../../../store/actions/survey";
import { useEffect, useState } from "react";
import { SurveyInDto } from "../../../../../types/survey.dto";
import { Group } from "../../../../../types";

const groupTypes = {
  membership: "membresía",
  experience: "taller",
};

interface ComponentProps {
  group: Group;
}

const Component = ({ group }: ComponentProps) => {
  const dispatch = useDispatch();

  return (
    <Modal name="new-survey-created-modal" width="650">
      <Title>¡Encuesta creada con éxito!</Title>

      <Text>
        Creaste una encuesta para el post venta de {group?.group_name}. Ahora
        puedes ver las respuestas de quienes compren tu{" "}
        {groupTypes[group?.group_type]} en la sección de encuestas.
      </Text>

      <AlignEnd>
        <Button
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
          }}
          onClick={() => dispatch(hideModal())}
        >
          Aceptar
        </Button>
      </AlignEnd>
    </Modal>
  );
};

const state = ({ groupStore }) => {
  const { data: group } = groupStore.group;

  return {
    group,
  };
};

export default connect(state)(Component);
