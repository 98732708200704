/**
 *  Colors
 */

export const colors = {
  Primary: "rgba(108, 92, 231, 1)",
  Primary5: "rgba(108, 92, 231, .5)",
  Primary10: "rgba(108, 92, 231, .10)",
  Primary15: "rgba(108, 92, 231, .15)",
  Primary20: "rgba(108, 92, 231, .20)",
  Primary25: "rgba(108, 92, 231, .25)",
  Primary30: "rgba(108, 92, 231, .30)",
  Primary35: "rgba(108, 92, 231, .35)",
  Primary40: "rgba(108, 92, 231, .40)",
  Primary45: "rgba(108, 92, 231, .45)",
  Primary50: "rgba(108, 92, 231, .50)",
  Primary55: "rgba(108, 92, 231, .55)",
  Primary60: "rgba(108, 92, 231, .60)",
  Primary65: "rgba(108, 92, 231, .65)",
  Primary70: "rgba(108, 92, 231, .70)",
  Primary75: "rgba(108, 92, 231, .75)",
  Primary80: "rgba(108, 92, 231, .80)",
  Primary85: "rgba(108, 92, 231, .85)",
  Primary90: "rgba(108, 92, 231, .90)",
  Primary95: "rgba(108, 92, 231, .95)",

  PurpleLight: "#CF6AFF",
  PurpleVeryLight: "#BB85FF",
  PurpleExtraLight: "#FAF3FF",
  PurpleStrong: "#452F61",
  Yellow: "#FDCB6E",
  YellowLight: "#FFEAA7",
  Pink: "#FFF7E9",
  Lila: "rgba(108, 92, 231, 0.08)",

  Blue1: "#DBE9FC",
  Blue2: "#6DA5F3",
  Blue3: "#5698F5",
  Blue4: "#2869C5",

  Black: "#2D3436",
  Black24: "#C2C2C2",
  Black32: "rgba(0,0,0,.32)",
  Black40: "rgba(45, 52, 54, 0.4)",
  Black75: "rgba(45, 52, 54, 0.75)",
  Black50: "rgba(45, 52, 54, 0.50)",
  Black45: "rgba(45, 52, 54, 0.45)",
  Black01: "rgba(0, 0, 0, 1)",

  Transparent25: "rgba(255, 255, 255, 0.25)",
  Transparent0: "rgba(255, 255, 255, 0)",

  White: "#FFFFFF",

  Green: "#12B47A",
  LightGreen: "#128C7E",

  Red: "#EB5757",
  RedLight: "#FCE6E6",

  Gray: "#FAF3FF",
  LightGray: "#FAFAFA",

  Gray0: "#e0e4ea",
  Gray1: "#ecf0f1",
  Gray2: "#d9dde6",
  Gray5: "#E4E4E5",
  Gray8: "#B8B7BC",
  Gray9: "#69779B",
  Gray10: "#E5E5E5",
  Gray16: "#F2F2F2",
  Gray68: "#ADADAD",

  BlueLight: "#69779B",
  SoftBlue: "rgba(105, 119, 155, 0.15)",
  LightBlue2: "#42B2FC",

  Success: "#51CF66",
  Info: "#259DD5",
  Warning: "#FAB005",
  Danger: "#FF0000",

  WaterGreen: "#12B1B4",
  SoftOrange: "#FDCB6E",



  RainbowText: `
  background: rgb(197, 171, 242);
  background: linear-gradient(
    180deg,
    rgba(197, 171, 242, 1) 0%,
    rgba(108, 92, 231, 1) 30%,
    rgba(66, 178, 252, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(197, 171, 242, 1) 0%,
    rgba(108, 92, 231, 1) 30%,
    rgba(66, 178, 252, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`,

  RainbowBackground: `
  background: rgb(197, 171, 242);
  background: linear-gradient(
    180deg,
    rgba(197, 171, 242, 1) 0%,
    rgba(108, 92, 231, 1) 30%,
    rgba(66, 178, 252, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(197, 171, 242, 1) 0%,
    rgba(108, 92, 231, 1) 30%,
    rgba(66, 178, 252, 1) 100%
  );
`,
};
