import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import Submit from "../../../../../components/FormikForm/Submit";
import { Title, Text, AlignEnd } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import { editPost, resetEditPost } from "../../../../../store/actions/post";
import { useEffect } from "react";
import { Post } from "../../../../../types/post";

interface ComponentProps {
  postInformation: Post;
  updatePostStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({ postInformation, updatePostStates }: ComponentProps) => {
  const dispatch = useDispatch();

  const handleSetNotFree = () => {
    dispatch(editPost({ id: postInformation?.id, is_free: false }));
  };

  useEffect(() => {
    if (updatePostStates.success) {
      dispatch(hideModal());
      dispatch(resetEditPost());
    }
  }, [updatePostStates]);

  return (
    <Modal name="modal-not-free-post" width="650" onClose={() => {}}>
      <Title>¿Deseas Limitar este contenido?</Title>

      <Text>
        Cuando un contenido es <strong>Limitado</strong> significa que sólo
        estará disponible para las personas que compraron tu taller y podrás
        compartirlo en Telegram 🙌
      </Text>

      <AlignEnd>
        <Button
          options={{
            type: "outline",
            skin: "purple",
            size: "lg",
          }}
          style={{ marginRight: "16px" }}
          onClick={() => dispatch(hideModal())}
        >
          Cancelar
        </Button>

        <Submit
          form=""
          isSubmmiting={updatePostStates.loading}
          options={{
            type: "filled",
            skin: "purple",
            size: "lg",
          }}
          onClick={() => handleSetNotFree()}
        >
          Limitar contenido
        </Submit>
      </AlignEnd>
    </Modal>
  );
};

const state = ({ postStore }) => {
  const { data: postInformation } = postStore.post;
  const { states: updatePostStates } = postStore.editPost;
  return {
    postInformation,
    updatePostStates,
  };
};

export default connect(state)(Component);
