import { Image, Partners } from "./styles";
import partner1 from "../../../../assets/home-page/partner-1.jpg";
import partner2 from "../../../../assets/home-page/partner-2.jpg";
import partner3 from "../../../../assets/home-page/partner-3.jpg";
import Grid from "../../../../components/Grid";

const Component = () => {
  return (
    <Partners>
      <Image src={partner1} alt="partner-1" width="227px" height="auto" lazy={true}/>
      <Image src={partner2} alt="partner-2" width="260px" height="auto" lazy={true}/>
      <Image src={partner3} alt="partner-3" width="163px" height="auto" lazy={true}/>
    </Partners>
  );
};

export default Component;
