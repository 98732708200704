import { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import remove from "../../../../../../assets/remove.svg";
import Image from "../../../../../../components/Image";
import { Center } from "../../../../../../components/StyledComponents";
import { useModules } from "../../../../../../providers/ModulesProvider";
import { hideModal } from "../../../../../../store/actions/modal";
import { resetDeleteModule } from "../../../../../../store/actions/module";
import { Modal, ModalDialog, Title, Text, Actions, Button } from "./styles";

const Component = ({ deleteModuleStates }) => {
  const { moduleToBeDeleted, actions } = useModules();

  const dispatch = useDispatch();

  useEffect(() => {
    if (deleteModuleStates.success) {
      setTimeout(() => {
        dispatch(hideModal());

        setTimeout(() => {
          dispatch(resetDeleteModule());
        }, 1000);
      }, 1000);
    }
  }, [deleteModuleStates]);

  return (
    <Modal
      name="modal-remove-module"
      width="400"
      onClose={() => dispatch(resetDeleteModule())}
    >
      <ModalDialog>
        {deleteModuleStates.success ? (
          <>
            <div style={{ textAlign: "center" }}>
              <Image src={remove} width="102px" height="117px" alt={remove} />
            </div>
            <Text>
              <strong>Módulo eliminado satisfactoriamente</strong>
            </Text>
          </>
        ) : (
          <>
            <Title>¿Deseas eliminar el módulo seleccionado?</Title>

            <Text>
              Una vez eliminado no podrás volver a recueperarlo. Toda la
              informacion del módulo se perdera definitivamente.
            </Text>

            <Center horizontal="center" vertical="center">
              <Button
                onClick={() => dispatch(hideModal())}
                type="button"
                options={{
                  skin: "purple",
                  size: "md",
                  type: "outline",
                }}
              >
                Cancelar
              </Button>

              <Button
                onClick={() => {
                  moduleToBeDeleted &&
                    actions.deleteOne.exec({
                      id: moduleToBeDeleted,
                    });
                }}
                type="button"
                options={{
                  skin: "purple",
                  size: "md",
                  type: "filled",
                }}
              >
                Eliminar
              </Button>
            </Center>
          </>
        )}
      </ModalDialog>
    </Modal>
  );
};

const state = ({ modulesStore }) => {
  const { states: deleteModuleStates } = modulesStore.deleteModule;

  return {
    deleteModuleStates,
  };
};

export default connect(state)(Component);
