import * as Yup from "yup";

export const schema = Yup.object().shape({
  content_data: Yup.object({
    description: Yup.string().required(),
    attached_files: Yup.array().test({
      name: 'attached_files',
      test: function (value) {
        const attached_url_field: string = this.resolve(Yup.ref('attached_url'));
        if (!attached_url_field || attached_url_field.trim().length === 0) {
          return value && value.length > 0 || this.createError({ message: 'Selecciona un archivo de tu computadora o carga la URL de tu contenido.' });
        }
        return true;
      }
    }).nullable(),
    attached_url: Yup.string().test({
      name: 'attached_url',
      test: function (value) {
        const attached_files_field: [] = this.resolve(Yup.ref('attached_files'));
        if (!attached_files_field || attached_files_field.length === 0) {
          return value && value.trim().length > 0 || this.createError({ message: 'Selecciona un archivo de tu computadora o carga la URL de tu contenido.' });
        }
        return true;
      }
    }).nullable(),
  }),
});
interface FormValues {
  content_data: {
    description?: string;
    attached_files?: object[];
    attached_url?: string;
  };
}

export const initialValues: FormValues = {
  content_data: {},
};
