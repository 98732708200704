import styled from "styled-components";
import { Link as UiLink } from "react-router-dom";

export const Tabs = styled("div")`
  max-width: 100%;
  display: inline-flex;
  overflow-x: auto;
  gap: 6px;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  @media(min-width: 768px){
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const Tab = styled("div")`
  flex-shrink: 0;
`;

export const Link = styled(UiLink) <{ active?: boolean }>`
  display: block;
  text-decoration: none;
  background-color: ${(props) =>
    props.active ? props.theme.colors.Primary15 : "transparent"};
  color: ${(props) =>
    props.active ? props.theme.colors.Primary : props.theme.colors.Black75};
  padding: 6px 10px;
  font-weight: ${(props) => (props.active ? "600" : "500")};
  font-size: 18px;
  line-height: 22px;
  border-radius: 5px;

  &:hover {
    background-color: ${(props) =>
    props.active ? props.theme.colors.Primary15 : "transparent"};
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const Center = styled("div")`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
