import styled from "styled-components";

export const List = styled.ul`
  background-color: white;
  padding: 16px 0px;
  margin: 0px;
  position: absolute;
  top: calc(100% + 8px);
  right: 0%;
  min-width: 230px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 16px;

  li {
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;
