import { connect, useDispatch } from "react-redux";
import { Slide } from "pure-react-carousel";
import Button from "../../../../../../components/Button";
import Link from "../../../../../../components/Link";

import { Text, Actions, SlideContent } from "./styles";
import { Group } from "../../../../../../types";
import { showModal } from "../../../../../../store/actions/modal";
import { savePreference } from "../../../../../../store/actions/preferences";
import { getExpertTelegramBotUrlForNotifications, getExpertTelegramGroup } from "../../../../../../store/actions/group";
import { useEffect } from "react";

interface ComponentProps {
  id: string;
  groupInformation: Group;
  groupStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  expertTelegramBotUrlNotificationsUrl: string;
  expertTelegramBotUrlNotificationsUrlStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  id,
  groupInformation,
  expertTelegramBotUrlNotificationsUrl,
  expertTelegramBotUrlNotificationsUrlStates
}: ComponentProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExpertTelegramBotUrlForNotifications())
  }, [])

  return groupInformation?.recorded ? (
    <>
      <Slide index={0}>
        <SlideContent>
          <Text>
            ¡Hey! 👋 Comienza por aquí. <b>Crea los módulos de tu taller</b>.
            Esto estructurará todo el contenido del mismo.
          </Text>

          <Actions>
            <Link
              href={`/dashboard/experience/${groupInformation?.id}/modules`}
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
              }}
              style={{ flexShrink: "0" }}
            >
              Crear módulos
            </Link>
          </Actions>
        </SlideContent>
      </Slide>
      <Slide index={1}>
        <SlideContent>
          <Text>
            ✨ <b>Activa las notificaciones</b> para enterarte cuando se
            <br /> unan nuevos suscriptores
          </Text>

          <Actions>
            <Link
              href={expertTelegramBotUrlNotificationsUrl}
              loading={expertTelegramBotUrlNotificationsUrlStates.loading}
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
              }}
              style={{ flexShrink: "0" }}
            >
              Activar notificaciones
            </Link>
          </Actions>
        </SlideContent>
      </Slide>
      <Slide index={2}>
        <SlideContent>
          <Text>
            Lanza un <b>descuento promocional</b> para sumar más
            <br /> personas al taller
          </Text>

          <Actions>
            <Link
              href={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/price`}
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
              }}
              style={{ flexShrink: "0" }}
            >
              Lanzar descuento
            </Link>
          </Actions>
        </SlideContent>
      </Slide>
      <Slide index={3}>
        <SlideContent>
          <Text>
            Configura tu grupo de chat. Es un canal privado de <b>Whatsapp</b> o{" "}
            <b>Telegram</b> para interactuar con tus suscriptores
          </Text>

          <Actions>
            {!!groupInformation?.telegram_groups &&
              !!groupInformation?.telegram_groups[0] ? (
              <Link
                href={
                  groupInformation?.telegram_groups[0]?.main_invitation_link
                }
                options={{
                  type: "filled",
                  size: "lg",
                  skin: "purple",
                  native: false,
                }}
                style={{ flexShrink: "0" }}
                target="_blank"
              >
                Ir a{" "}
                {groupInformation.platform === "whatsapp"
                  ? "Whatsapp"
                  : "Telegram"}
              </Link>
            ) : (
              <Button
                onClick={() => dispatch(showModal("modal-telegram"))}
                options={{
                  type: "filled",
                  size: "lg",
                  skin: "purple",
                }}
                style={{ flexShrink: "0" }}
              >
                Crear grupo de chat
              </Button>
            )}
          </Actions>
        </SlideContent>
      </Slide>
      <Slide index={4}>
        <SlideContent>
          <Text>
            ¡Perfecto! 🤗 Ahora <b>Comparte</b> tu página de venta.
          </Text>

          <Actions>
            <Button
              onClick={() => {
                dispatch(
                  savePreference({
                    preferenceSlug: id,
                    preferenceValue: false,
                  })
                );
              }}
              options={{
                type: "outline",
                size: "lg",
                skin: "purple",
              }}
              style={{ flexShrink: "0" }}
            >
              Finalizar
            </Button>

            <Button
              onClick={() => {
                const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${groupInformation?.slug}`;
                navigator.clipboard.writeText(link);
                dispatch(showModal("modal-share-group-link"));
              }}
              disabled={
                !groupInformation?.telegram_groups ||
                !groupInformation?.telegram_groups[0]
              }
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
              }}
            >
              Compartir
            </Button>
          </Actions>
        </SlideContent>
      </Slide>
    </>
  ) : (
    <>
      <Slide index={0}>
        <SlideContent>
          <Text>
            Configura tu grupo de chat. Es un canal privado de <b>Whatsapp</b> o{" "}
            <b>Telegram</b> para interactuar con tus suscriptores
          </Text>

          <Actions>
            {!!groupInformation?.telegram_groups &&
              !!groupInformation?.telegram_groups[0] ? (
              <Link
                href={
                  groupInformation?.telegram_groups[0]?.main_invitation_link
                }
                options={{
                  type: "filled",
                  size: "lg",
                  skin: "purple",
                  native: false,
                }}
                style={{ flexShrink: "0" }}
                target="_blank"
              >
                Ir a{" "}
                {groupInformation.platform === "whatsapp"
                  ? "Whatsapp"
                  : "Telegram"}
              </Link>
            ) : (
              <Button
                onClick={() => dispatch(showModal("modal-telegram"))}
                options={{
                  type: "filled",
                  size: "lg",
                  skin: "purple",
                }}
                style={{ flexShrink: "0" }}
              >
                Crear grupo de chat
              </Button>
            )}
          </Actions>
        </SlideContent>
        {/*         
        <SlideContent>
          <Text>
            ¡Hey! 👋 Comienza por aquí. <b>Configura tu canal</b>.Es un canal
            <br /> privado de Telegram para interactuar con tus suscriptores
          </Text>

          {(!groupInformation?.telegram_groups ||
            !groupInformation?.telegram_groups[0]) && (
            <Actions>
              <Button
                onClick={() => dispatch(showModal("modal-telegram"))}
                options={{
                  type: "filled",
                  size: "lg",
                  skin: "purple",
                }}
                style={{ flexShrink: "0" }}
              >
                Configurar mi canal
              </Button>
            </Actions>
          )}
        </SlideContent> */}
      </Slide>
      <Slide index={1}>
        <SlideContent>
          <Text>
            ✨ <b>Activa las notificaciones</b> para enterarte cuando se
            <br /> unan nuevos suscriptores
          </Text>

          <Actions>
            <Link
              href={expertTelegramBotUrlNotificationsUrl}
              loading={expertTelegramBotUrlNotificationsUrlStates.loading}
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
              }}
              style={{ flexShrink: "0" }}
            >
              Activar notificaciones
            </Link>
          </Actions>
        </SlideContent>
      </Slide>
      <Slide index={2}>
        <SlideContent>
          <Text>
            Lanza un <b>descuento promocional</b> para sumar más
            <br /> personas al taller
          </Text>

          <Actions>
            <Link
              href={`/dashboard/${groupInformation?.group_type}/${groupInformation?.id}/price`}
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
              }}
              style={{ flexShrink: "0" }}
            >
              Lanzar descuento
            </Link>
          </Actions>
        </SlideContent>
      </Slide>
      <Slide index={3}>
        <SlideContent>
          <Text>
            Sube un <b>contenido liberado.</b> Funciona como muestra gratuita
            para sumar más personas
          </Text>

          <Actions>
            <Link
              href={`/dashboard/community/${groupInformation?.id}/posts/new?t=f`}
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
              }}
              style={{ flexShrink: "0" }}
            >
              Subir contenido
            </Link>
          </Actions>
        </SlideContent>
      </Slide>
      <Slide index={4}>
        <SlideContent>
          <Text>
            ¡Perfecto! 🤗 Ahora <b>Comparte</b> tu página de venta.
          </Text>

          <Actions>
            <Button
              onClick={() => {
                dispatch(
                  savePreference({
                    preferenceSlug: id,
                    preferenceValue: false,
                  })
                );
              }}
              options={{
                type: "outline",
                size: "lg",
                skin: "purple",
              }}
              style={{ flexShrink: "0" }}
            >
              Finalizar
            </Button>

            <Button
              onClick={() => {
                const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${groupInformation?.slug}`;
                navigator.clipboard.writeText(link);
                dispatch(showModal("modal-share-group-link"));
              }}
              disabled={
                !groupInformation?.telegram_groups ||
                !groupInformation?.telegram_groups[0]
              }
              options={{
                type: "filled",
                size: "lg",
                skin: "purple",
              }}
            >
              Compartir
            </Button>
          </Actions>
        </SlideContent>
      </Slide>
    </>
  );
};

const state = ({ groupStore, userStore, preferencesStore }) => {
  const { data: userInformation } = userStore.information;
  const { group, expertTelegramBotUrlForNotifications } = groupStore;
  const { data: groupInformation, states: groupStates } = group;
  const { data: expertTelegramBotUrlNotificationsUrl, states: expertTelegramBotUrlNotificationsUrlStates } = expertTelegramBotUrlForNotifications;
  const { data: preferences, states: preferencesStates } = preferencesStore.preferences;

  return {
    groupInformation,
    groupStates,
    userInformation,
    preferences,
    preferencesStates,
    expertTelegramBotUrlNotificationsUrl,
    expertTelegramBotUrlNotificationsUrlStates
  };
};

export default connect(state)(Component);
