import { Card } from "./styles";
import Filters from "./components/Form";
import SelectedFilters from "./components/SelectedFilters";
import { useMemo, useState } from "react";

const Component = ({ data, conditions, appliedFilters, setAppliedFilters }) => {
  return (
    <>
      <Card>
        Filtros{" "}
        <Filters
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          conditions={conditions}
          options={data}
        />
      </Card>
      <div style={{ height: "21px" }} />
      <SelectedFilters
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        conditions={conditions}
        options={data}
      />
      <div style={{ height: "34px" }} />
    </>
  );
};

export default Component;
