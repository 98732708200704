import { CSSProperties } from "@remirror/theme";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Link from "../../../../components/Link";
import { isSubscribed } from "../../../../helpers/isSubscribed";
import {
  getExpertTelegramGroup,
  getSubscriberTelegramGroup,
} from "../../../../store/actions/group";
import { Group, Subscription, User } from "../../../../types";

interface ComponentProps {
  user: User;
  userStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  subscriptions: Subscription[];
  subscriberTelegramUrl: string;
  subscriberTelegramUrlStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  expertTelegramUrl: string;
  expertTelegramUrlStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  style?: CSSProperties;
  label?: string;
}

const Component = ({
  user,
  userStates,
  group,
  groupStates,
  subscriptions,
  subscriberTelegramUrl,
  subscriberTelegramUrlStates,
  expertTelegramUrl,
  expertTelegramUrlStates,
  label = "",
  ...rest
}: ComponentProps) => {
  const dispatch = useDispatch();

  const isOwnGroup = ({ group, user }) => {
    return group?.expert?.id === user?.expert?.id;
  };


  useEffect(() => {
    if (!!group && !groupStates.loading && !userStates.loading && (!subscriberTelegramUrlStates.loading && !expertTelegramUrlStates.loading)) {
      if (user && isOwnGroup({ group, user })) {
        dispatch(getExpertTelegramGroup(group?.id));
      } else if (group?.group_type !== "free_experience") {
        dispatch(getSubscriberTelegramGroup(group?.id));
      }
    }
  }, [group, groupStates]);

  return (
    <Link
      target="_blank"
      href={
        group?.group_type === "free_experience" ? group?.telegram_groups[0]?.main_invitation_link : (
          isOwnGroup({ group, user }) ? expertTelegramUrl : subscriberTelegramUrl)
      }
      disabled={group?.group_type !== "free_experience" && (
        (isOwnGroup({ group, user })
          ? expertTelegramUrlStates.loading ||
          (!expertTelegramUrlStates.loading && !expertTelegramUrl)
          : subscriberTelegramUrlStates.loading ||
          (!subscriberTelegramUrlStates.loading && !subscriberTelegramUrl)) ||
        groupStates.loading)
      }
      loading={
        (isOwnGroup({ group, user })
          ? expertTelegramUrlStates.loading
          : subscriberTelegramUrlStates.loading) || groupStates.loading
      }
      options={{
        skin: "purple",
        size: "lg",
        type: "filled",
        native: false,
      }}
      {...rest}
    >
      Ir a{" "}
      {group?.telegram_groups[0]?.platform === "telegram"
        ? "Telegram"
        : group?.telegram_groups[0]?.platform === "whatsapp" ? "Whatsapp" : "Grupo de Chat"}
    </Link>
  );
};

const state = ({ groupStore, userStore }) => {
  const { data: subscriberTelegramUrl, states: subscriberTelegramUrlStates } =
    groupStore?.subscriberTelegramGroup;
  const { data: expertTelegramUrl, states: expertTelegramUrlStates } =
    groupStore?.expertTelegramGroup;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;
  const { data: user, states: userStates } = userStore.information;

  return {
    user,
    userStates,
    group,
    groupStates,
    subscriptions,
    subscriptionsStates,
    subscriberTelegramUrl,
    subscriberTelegramUrlStates,
    expertTelegramUrl,
    expertTelegramUrlStates,
  };
};

export default connect(state)(Component);
