import styled from "styled-components";
import UiLink from "../../../../components/Link"
import UiButton from "../../../../components/Button"
import Image from "../../../../components/Image";

export const Column = styled('div')`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 45px 20px;
  max-width: 375px;
  margin: 0 auto;
`

export const LinkBlack = styled(UiLink)`
  margin: 0px;
  border: 0px;
  border-radius: ${({ theme }) => theme.button.size['lg'].borderRadius};
  font-size: ${({ theme }) => theme.button.size['lg'].fontSize};
  height: ${({ theme }) => theme.button.size['lg'].height};
  width: 100%;
  max-width: 346px;
  font-weight: 500;
  letter-spacing: 0.2px;
  background-color: #0C0C0C;
  border-color: #0C0C0C;
  color: white;
  transition: all .1s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:hover{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
`

export const LinkTransparent = styled(UiButton)`
  margin: 0px;
  border: 0px;
  border-radius: ${({ theme }) => theme.button.size['lg'].borderRadius};
  font-size: ${({ theme }) => theme.button.size['lg'].fontSize};
  height: ${({ theme }) => theme.button.size['lg'].height};
  width: 100%;
  max-width: 346px;
  font-weight: 500;
  letter-spacing: 0.2px;
  background-color: transparent;
  color: #000000;
  transition: all .1s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:hover{
    box-shadow: none;
    background-color: transparent;
  }
`

export const Title = styled("h3")`
  margin: 0px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  line-height: 24px;
`;

export const Message = styled('p')`
  text-align: center;
  margin: 0px;
  font-size: 16px;
`

export const ProfileImage = styled(Image)`
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 2px solid white;
  display: block;
  border: 3px solid #FFD964;
`