import {
  initialState,
  Actions,
  State,
  GET_ALL_SURVEYS,
  GET_ALL_SURVEYS_SUCCESS,
  GET_ALL_SURVEYS_ERROR,
  UPDATE_ALL_CURRENT_SURVEYS,
  UPDATE_ALL_CURRENT_SURVEYS_SUCCESS,
  UPDATE_ALL_CURRENT_SURVEYS_ERROR,
  RESET_GET_ALL_SURVEYS,
} from "../../types/survey/all";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_ALL_SURVEYS:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case GET_ALL_SURVEYS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case GET_ALL_SURVEYS_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case UPDATE_ALL_CURRENT_SURVEYS:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case UPDATE_ALL_CURRENT_SURVEYS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case UPDATE_ALL_CURRENT_SURVEYS_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_GET_ALL_SURVEYS:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
