import earnedIncome from "../assets/statistics/earned-income.svg";
import totalMembers from "../assets/statistics/total-members.svg";
import newSubscribers from "../assets/statistics/new-subscribers.svg";

const page = {
  statistics: [
    {
      type: "experience_first_kpi",
      image: earnedIncome,
      title: "Total de ingresos generados a través de tu taller",
      emptyState: {
        text: "Revisa en tu página de venta si hay algo que podrías mejorar.",
        cta: {
          label: "Ir a página de venta",
        },
      },
    },
    {
      type: "experience_second_kpi",
      image: totalMembers,
      title: "Total de personas que se suscribieron al taller",
      emptyState: {
        text: "Lanza un descuento promocional y suma más suscriptores.",
        cta: {
          label: "Lanzar descuento",
        },
      },
    },
    {
      type: "experience_third_kpi",
      image: newSubscribers,
      title: "Ingresos generados el mes actual",
      emptyState: {
        text: "Descubre más información de los miembros de tu comunidad",
        cta: {
          label: "Ir a miembros",
        },
      },
    },
  ],
};

export default page;
