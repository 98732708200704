import {
  GetCommunitySubscribers,
  GET_COMMUNITY_SUBSCRIBERS,
} from "../types/community/subscribers";

import {
  GetCommunitySubscribersEmails,
  GET_COMMUNITY_SUBSCRIBERS_EMAILS,
} from "../types/community/subscribers-emails";

import {
  ExportCommunitySubscribers,
  EXPORT_COMMUNITY_SUBSCRIBERS,
} from "../types/community/export-subscribers";

import {
  GetCommunityMetrics,
  GET_COMMUNITY_METRICS,
} from "../types/community/metrics";

import {
  SubscribeToTheCommunity,
  SUBSCRIBE_TO_THE_COMMUNITY,
  ResetSubscribeToTheCommunity,
  RESET_SUBSCRIBE_TO_THE_COMMUNITY,
} from "../types/community/subscription";

import { GetCommunity, GET_COMMUNITY } from "../types/community/community";

export const getCommunity = (payload): GetCommunity => {
  return { type: GET_COMMUNITY, payload };
};

export const subscribeToTheCommunity = (payload: {
  email: string;
  idExpert: number;
}): SubscribeToTheCommunity => {
  return { type: SUBSCRIBE_TO_THE_COMMUNITY, payload };
};

export const resetSubscription = (): ResetSubscribeToTheCommunity => {
  return { type: RESET_SUBSCRIBE_TO_THE_COMMUNITY, payload: null };
};

export const getCommunitySubscribers = (payload): GetCommunitySubscribers => {
  return { type: GET_COMMUNITY_SUBSCRIBERS, payload };
};

export const getCommunitySubscribersEmails = (
  payload
): GetCommunitySubscribersEmails => {
  return {
    type: GET_COMMUNITY_SUBSCRIBERS_EMAILS,
    payload,
  };
};

export const exportCommunitySubscribers = (
  payload
): ExportCommunitySubscribers => {
  return { type: EXPORT_COMMUNITY_SUBSCRIBERS, payload };
};

export const getCommunityMetrics = (): GetCommunityMetrics => {
  return { type: GET_COMMUNITY_METRICS, payload: null };
};
