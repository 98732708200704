import * as Yup from "yup";

const today = new Date();


export const schema = Yup.object().shape({
  title: Yup.string().required("Debes indicar el nombre del encuentro."),
  description: Yup.string()
    .nullable()
    .required("Agrega una descripción a tu encuentro."),
  join_url: Yup.string().matches(
    /^(http|https):\/\/[^\s/$.?#].[^\s]*$/,
    'Debe ser una URL válida con http:// o https:// por delante. Ejemplo https://zoom.us'
  ),
  speaker_name: Yup.array().of(Yup.string()).nullable(),
  speaker_profile_pic: Yup.array().nullable(),
  use_profile_info: Yup.array().of(Yup.string()).nullable(),
  cover_pic: Yup.array().nullable(),
  duration: Yup.number().min(0.1, "Debes indicar un numero mayor a 0").required("Debes indicar la duracion del Encuentro"),
  duration_type: Yup.string().required("Debes indicar la unidad de la duración del Encuentro"),
  session_datetime: Yup.date()
    // .min(today, "Debes indicar una fecha futura")
    .required("Indica la fecha del encuentro."),
  content_categories: Yup.array().of(Yup.number()).nullable(),
  recorded_session_url: Yup.string(),
  group: Yup.number().required(),
  session_reminders_settings: Yup.array().of(
    Yup.object().shape({
      exec_datetime: Yup.date().when(
        ['type', 'execution_type'], // Array of field names
        {
          is: (type: string, executionType: string) =>
            type === 'first_announcement' && executionType === 'in_future',
          then: (schema: any) =>
            schema.min(new Date(), 'Debes indicar una fecha futura').required("Indica una fecha"),
          otherwise: (schema: any) => schema // If executionType is not 'in_future', no validation is applied
        }
      )
    })
  )
});

interface FormNotificationRemindersValues {
  exec_time_before: number | null;
  exec_time_before_unit: string | null;
  enabled: string[];
  custom_message: string;
  execution_type: string;
  type: string;
  exec_datetime?: string;
  schedule_type?: string;
}

interface FormValues {
  title: string;
  description: string;
  join_url: string;
  speaker_name: string;
  speaker_profile_pic: object[];
  use_profile_info: string[];
  cover_pic: object[];
  session_datetime: string;
  content_categories: number[];
  recorded_session_url: string;
  group: number | null;
  will_be_recorded: string[];
  access_code: string;
  duration: number;
  duration_type: string;
  recorded_access_code: string;
  notificate_by_chat: string[];
  notificate_by_email: string[];
  session_reminders_settings: FormNotificationRemindersValues[];
}

export const initialValues: FormValues = {
  title: "",
  description: "",
  join_url: "",
  speaker_name: "",
  speaker_profile_pic: [],
  use_profile_info: ["true"],
  cover_pic: [],
  session_datetime: "",
  content_categories: [],
  recorded_session_url: "",
  group: null,
  will_be_recorded: ['true'],
  access_code: "",
  duration: 0,
  duration_type: "hour",
  recorded_access_code: "",
  notificate_by_chat: ["true"],
  notificate_by_email: ["true"],
  session_reminders_settings: [
    {
      exec_time_before: null,
      exec_time_before_unit: null,
      enabled: ['true'],
      custom_message: "",
      execution_type: "now",
      type: "first_announcement",
      schedule_type: "exact_datetime"
    },
    {
      exec_time_before: 24,
      exec_time_before_unit: "hour",
      enabled: ['false'],
      custom_message: "",
      execution_type: "in_future",
      type: "reminder",
      schedule_type: "time_before"
    },
    {
      exec_time_before: 1,
      exec_time_before_unit: "hour",
      enabled: ['false'],
      custom_message: "",
      execution_type: "in_future",
      type: "reminder",
      schedule_type: "time_before"
    },
    {
      exec_time_before: 30,
      exec_time_before_unit: "minute",
      enabled: ['false'],
      custom_message: "",
      execution_type: "in_future",
      type: "reminder",
      schedule_type: "time_before"
    },
    {
      exec_time_before: null,
      exec_time_before_unit: null,
      enabled: ['true'],
      custom_message: "",
      execution_type: "in_future",
      type: "open_room",
      exec_datetime: "",
      schedule_type: "exact_datetime"
    }
  ]
};