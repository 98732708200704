import { connect } from "react-redux";

import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import { Group, SessionInDto, User } from "../../../../../../types";

import Mobile from './components/Mobile'
import Desktop from './components/Desktop'


interface ComponentProps {
  session: SessionInDto
  user: User
  group: Group
}

const Component = ({ session, user, group }: ComponentProps) => {
  const { isTabletPortrait, isMobile } = useWindowSize()

  return isTabletPortrait || isMobile ? <Mobile /> : <Desktop />
}

const state = ({ userStore, sessionStore, groupStore }) => {

  const { data: user, states: userStates } =
    userStore.information;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: userSubscriptions, states: userSubscriptionsStates } =
    userStore.subscriptions;
  const { data: session, states: sessionStates } = sessionStore.one;

  return {
    user,
    userStates,
    userSubscriptions,
    userSubscriptionsStates,
    session,
    sessionStates,
    group,
    groupStates,

  };
};

export default connect(state)(Component);