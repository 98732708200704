import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import { hideModal, showModal } from "../../../../../store/actions/modal";
import { Group } from "../../../../../types";
import Desktop from "./components/Desktop";
import Mobile from "./components/Mobile";

interface ComponentProps {
  groupInformation: Group;
}

export const Component = ({ groupInformation }: ComponentProps) => {
  const { isMobile, isDesktop, isTabletPortrait, isTabletLandscape } =
    useWindowSize();
  const [id, setId] = useState<string | null>();

  useEffect(() => {
    if (groupInformation) {
      setId(`telegram-subscription-banner-${groupInformation.id}`);
    }
  }, [groupInformation]);


  if (!id || groupInformation?.telegram_groups?.length <= 0) {
    return <></>;
  }

  return isDesktop || isTabletLandscape || groupInformation?.recorded ? (
    <>
      <Desktop id={id} />
      <div style={{ height: 36 }} />
    </>
  ) : (
    <>
      <div style={{ height: 26 }} />
      {/* {!groupInformation?.recorded && <Mobile id={id} />} */}
    </>
  )
}

const state = ({ groupStore }) => {
  const { data: groupInformation } = groupStore.group;

  return {
    groupInformation,
  };
};

export default connect(state)(Component);
