import { useCallback, useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getFreeSubscriptions } from '../../../../helpers/getFreeSubscriptions';
import { isSubscribed } from '../../../../helpers/isSubscribed';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import closeIcon from "../../../../assets/icons/close_circled.svg";
import Icon from "../../../../components/Icon";
import { Group } from '../../../../types';
import {
  Card,
  Title,
  Text,
  Close,
  CardHeader
} from './styles'
import { savePreference } from '../../../../store/actions/preferences';
import { getPriorityGroupSubscription } from '../../../../helpers/mpStatusPriority';

interface ComponentProps {
  group: Group;
  userSubscriptions
  isLoggedIn: Boolean;
  preferencesStates,
  preferences,
}

const Component = ({
  group,
  userSubscriptions,
  isLoggedIn,
  preferencesStates,
  preferences,
}: ComponentProps) => {
  const dispatch = useDispatch()
  const { isMobile } = useWindowSize()

  const subscription = useMemo(() => {
    return getPriorityGroupSubscription(userSubscriptions, group?.id)
  }, [userSubscriptions, group])

  const confirmedPaymentPreferenceId = useMemo(() => {
    if (subscription?.status === 'active') {
      return `confirmed-payment-${group?.id}-${subscription?.id}`
    }
    return null
  }, [subscription])

  const hideSection = useMemo(() => {
    const preferenceFound = preferences?.filter((preference) => preference.preferenceSlug === confirmedPaymentPreferenceId)[0];
    return (preferencesStates.success || preferencesStates.error) && preferenceFound
  }, [confirmedPaymentPreferenceId, preferencesStates])

  const isUserAuthenticated = useMemo(() => {
    return isLoggedIn || group?.group_type === 'free_experience'
  }, [isLoggedIn, group])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(userSubscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [userSubscriptions, group, isLoggedIn])

  const isSubscriptionActive = useMemo(() => {
    return subscription?.status === 'active'
  }, [subscription])

  const isPaidMPSubscription = useMemo(() => {
    const freeSubscriptionTypes = ["guest", "free"]
    return !freeSubscriptionTypes?.includes(subscription?.type) && subscription?.payment_processor === "mercadopago"
  }, [subscription])


  const isMigratedSubscription = useMemo(() => {
    return !!subscription?.migration_old_subscription_id
  }, [subscription])

  const handleClose = useCallback(() => {
    if (confirmedPaymentPreferenceId) {
      dispatch(
        savePreference({
          preferenceSlug: confirmedPaymentPreferenceId,
          preferenceValue: false,
        })
      );
    }
  }, [confirmedPaymentPreferenceId])


  if (!isUserAuthenticated || !isUserSubscribed || !isSubscriptionActive || !isPaidMPSubscription || hideSection || isMigratedSubscription) {
    return <></>
  }

  return (
    <>
      {isMobile && <div style={{ height: 26 }} />}

      <Card>
        <CardHeader>
          <Title>¡Pago confirmado! 🎉</Title>
          <Close onClick={handleClose}>
            <Icon icon={closeIcon} size={"22px"} color={"white"} />
          </Close>
        </CardHeader>

        <div style={{ height: 8 }} />
        <Text>Acceso total desbloqueado. ¡Hora de disfrutar! 🔓</Text>
      </Card>

      {!isMobile && <div style={{ height: 26 }} />}
    </>
  )
}

const state = ({ userStore, groupStore, preferencesStore }) => {
  const { data: group } = groupStore.group;
  const { data: userSubscriptions } = userStore.subscriptions;
  const { isLoggedIn } = userStore;
  const { data: preferences, states: preferencesStates } =
    preferencesStore.preferences;

  return {
    group,
    userSubscriptions,
    isLoggedIn,
    preferences,
    preferencesStates
  };
};

export default connect(state)(Component);