import { useEffect, useState } from 'react'
import {
  Box,
  Reactions,
  Reaction,
  Label,
  LabelReaction,
  Checkbox,
  Toolbox,
  Emoji,
  EmojiWithCounter,
  Views,
  // Overlay,
  // TextDesc
} from './styles'
import { useTheme } from 'styled-components';
import Icon from '../../components/Icon'
import Loader from '../../components/Loader'
import ThumbsUpIcon from '../../assets/icons/thumbs-up-icon.svg'
import EyeOneIcon from '../../assets/icons/eye-on.svg'
import { connect, useDispatch } from 'react-redux';
import { deleteReaction, newReaction, resetDeleteReaction } from '../../store/actions/reaction';
import { Post } from '../../types/post';
import { User } from '../../types';
import { ReactionDto } from '../../types/reaction.dto';
import { CSSProperties } from 'remirror';
import { Center } from '../StyledComponents';

interface AvailableReaction {
  emoji: string,
  name: string,
  label: string
}
const availableReactions: AvailableReaction[] = [
  { emoji: "✨", name: "sparkles", label: "¡Brillante!" },
  { emoji: '\u2764\uFE0F', name: "red_heart", label: "¡Me encanta!" },
  { emoji: "👏", name: "clapping_hands", label: "¡Aplausos!" },
  { emoji: "🙏", name: "folded_hands", label: "¡Gracias!" },
  { emoji: "🔮", name: "crystal_ball", label: "¡Mágico!" },
  { emoji: "🤯", name: "exploding_head", label: "¡Sorprendente!" }
]

interface ComponentProps {
  user: User;
  post: Post;
  deleteReactionStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  }
  newReactionStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  }
  isLoggedIn: boolean;
  style: CSSProperties;
  isSubscribed: boolean;
}

const Component = ({ post, user, deleteReactionStates, newReactionStates, isLoggedIn, isSubscribed, ...rest }: ComponentProps) => {
  const dispatch = useDispatch();
  const theme: any = useTheme();
  const [selectedReaction, setSelectedReaction] = useState<string>('')
  const [postReactions, setPostReactions] = useState<{ [key: string]: ReactionDto[] }>({})
  const [userSelectedReactions, setUserSelectedReactions] = useState<ReactionDto[]>([])

  const handleNewReaction = (reaction: AvailableReaction) => {
    if (post?.id && user?.subscriber?.id && reaction?.emoji) {
      setSelectedReaction(reaction?.emoji)
      dispatch(newReaction({
        postId: post?.id,
        subscriberId: user?.subscriber?.id,
        reaction_emoji: reaction?.emoji
      }))
    }
  }

  const handleRemoveReaction = (reaction: AvailableReaction) => {
    if (post?.id && user?.subscriber?.id && reaction?.emoji) {
      setSelectedReaction(reaction?.emoji)
      const reactionToBeRemove = userSelectedReactions.filter(userSelectedReaction => userSelectedReaction.reaction_emoji === reaction.emoji)[0]
      dispatch(deleteReaction({ idReaction: reactionToBeRemove.id }))
    }
  }

  const getUserReactions = (post_reactions, subscriberId) => {
    const userReactions = post_reactions.filter(post_reaction => {
      return post_reaction.subscriber.id === subscriberId;
    })

    setUserSelectedReactions(userReactions)
  };

  // Group the array by reaction_emoji
  const groupReactionsByEmoji = (post_reactions) => {
    const result = post_reactions.reduce((acc, item) => {
      const emoji = item.reaction_emoji;

      // Create an array for the emoji if it doesn't exist in the result
      if (!acc[emoji]) {
        acc[emoji] = [];
      }

      // Push the current item to the array for the current emoji
      acc[emoji].push(item);

      // Return the accumulator (acc)
      return acc;
    }, {});

    // Assuming you want to set the result to some state (e.g., using setPostReactions)
    setPostReactions(result);

    // Return the result if needed
    return result;
  };


  const isSelectedReaction = (reaction) => {
    return userSelectedReactions.map(userSelectedReaction => userSelectedReaction.reaction_emoji).includes(reaction.emoji)
  }

  useEffect(() => {
    if (deleteReactionStates.success) {
      setSelectedReaction('')
      setTimeout(() => {
        dispatch(resetDeleteReaction());
      }, 1000);
    }
  }, [deleteReactionStates]);

  useEffect(() => {
    if (newReactionStates.success) {
      setSelectedReaction('')
      setTimeout(() => {
        dispatch(resetDeleteReaction());
      }, 1000);
    }
  }, [newReactionStates]);

  useEffect(() => {
    if (post?.post_reactions && post?.post_reactions.length > 0) {
      groupReactionsByEmoji(post?.post_reactions)
    }
  }, [post, newReactionStates, deleteReactionStates])

  useEffect(() => {
    if (!!post?.post_reactions && post?.post_reactions.length > 0 && !!user) {
      getUserReactions(post?.post_reactions, user?.subscriber?.id)
    }
  }, [post, user, newReactionStates, deleteReactionStates])


  return (
    <>
      <Center vertical='center' horizontal="space">

        <div style={{ padding: "0px 7px", display: "flex", gap: 10 }}>
          <Reactions reactions={postReactions}>
            {Object.keys(postReactions)?.map((reaction) => (
              <EmojiWithCounter>
                <div className='reaction-container'>
                  <div className='emoji'>{reaction} </div>
                  <div className='counter'>{postReactions[reaction].length}</div>
                </div>
              </EmojiWithCounter>
            ))}
          </Reactions>

          <div style={{ fontSize: "14px", lineHeight: "34px" }}>{post?.post_reactions?.length || ''}</div>
        </div>

        <Views><Icon icon={EyeOneIcon} size="20px" color={"#2D3436"} />{post?.count_unique_views || 0} Vistas</Views>
      </Center>



      {isLoggedIn && isSubscribed && (
        <>
          <div style={{ height: 16 }} />
          <div style={{ height: 2, backgroundColor: 'rgba(214, 214, 214, 0.5)' }} />
          <div style={{ height: 16 }} />

          <Box reactions={availableReactions} {...rest}>
            <Reactions reactions={availableReactions}>
              {!!post && !!user && availableReactions.map((reaction, index) => (
                <Reaction htmlFor={reaction.name} index={index} className={isSelectedReaction(reaction) ? 'active' : ''}>
                  <Checkbox type="checkbox" name="reaction" value={reaction.emoji} id={reaction.name} checked={isSelectedReaction(reaction)} onChange={() => {
                    if (isSelectedReaction(reaction)) {
                      handleRemoveReaction(reaction)
                    } else {
                      handleNewReaction(reaction)
                    }
                  }} />
                  <Label>{reaction.label}</Label>
                  <Emoji>{(newReactionStates.loading || deleteReactionStates.loading) && selectedReaction === reaction.emoji ? <Loader color={theme.colors.Primary} width={40} /> : <>{reaction.emoji}</>}</Emoji>
                </Reaction>
              ))}
            </Reactions>

            <Toolbox reactions={availableReactions} />

            <LabelReaction isActive={userSelectedReactions.length > 0}><Icon icon={ThumbsUpIcon} size="24px" color={userSelectedReactions.length > 0 ? 'white' : theme.colors.Primary} /> Reacionar</LabelReaction>
          </Box>
        </>
      )}
    </>
  )
}

const state = ({ userStore, postStore, reactionStore }) => {
  const { data: user } = userStore.information;
  const { data: post } = postStore.post;
  const { states: newReactionStates } = reactionStore.newReaction
  const { states: deleteReactionStates } = reactionStore.deleteReaction

  return { user, post, newReactionStates, deleteReactionStates, isLoggedIn: userStore.isLoggedIn };
};

export default connect(state)(Component);
