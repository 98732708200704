import action from "../helpers/rest-client";

export class ErrorNotification extends Error {}

class Notification {
  public async new(fields) {
    return action
      .Post({
        url: `/api/notification/`,
        body: fields,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async update(payload) {
    return action
      .Put({
        url: `/api/notification/`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async all() {
    return action
      .Get({
        url: `/api/notification/`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async one(id) {
    return action
      .Get({
        url: `/api/notification/${id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const notification = new Notification();
export default notification;
