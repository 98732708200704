import { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  DragDropContext,
  Droppable,
  DroppableProps,
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult
} from "react-beautiful-dnd";

import PostOrderCard from '../../../components/PostOrderCard'

import Sidebar from "../../../../../components/Sidebar";
import Button from "../../../../../components/Button";
import Input from "../../../../../components/FormikForm/Input";
import TextArea from '../../../../../components/FormikForm/TextArea';
import Submit from "../../../../../components/FormikForm/Submit";
import Snackbar from "../../../../../components/Snackbar";

import { initialValues, schema } from '../../../../../constants/forms/category/edit'

import { getCategory, reorderCategoryPosts, resetUpdateCategory, updateCategory } from '../../../../../store/actions/category'
import { getPosts, resetGetPosts } from '../../../../../store/actions/post'
import { CategoryDto } from '../../../../../types/category';
import { Group } from '../../../../../types';
import { GetAllPostInDto, GetPostsOutDto, Post } from '../../../../../types/post';

import {
  Description,
  List,
  ListItem,
  Preview
} from './styles'
import LogoLoader from '../../../../../components/LogoLoader';


export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

interface ComponentProps {
  group: Group
  groupStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  allCategories: CategoryDto[]
  allCategoriesStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  category?: CategoryDto
  categoryStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  posts: Post[];
  postStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  onClose: any
  isVisible: boolean
  idCategory: number
  idGroup: number
  reorderedPosts
  reorderPostsStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

const Component = ({
  group,
  groupStates,
  allCategories,
  allCategoriesStates,
  idCategory,
  idGroup,
  category,
  categoryStates,
  posts,
  postStates,
  onClose,
  isVisible,
  reorderedPosts,
  reorderPostsStates
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [sortPosts, setSortPosts] = useState<Post[]>([])

  const reorder = () => {
    dispatch(reorderCategoryPosts({ idCategory, posts: sortPosts }));
  };

  const onDragEnd = (payload: DropResult) => {
    const { source, destination } = payload;

    if (!destination) {
      return;
    }

    const result: Post[] = Array.from(sortPosts);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);

    // Update the order and sort in descending order
    const newOrder = result.map((item: Post, index) => {
      return {
        ...item,
        post_order: result.length - index - 1, // Reverse the order index
      };
    });

    // Sort by post_order in descending order
    const orderedPosts = newOrder.sort((a, b) => b.post_order - a.post_order);

    setSortPosts(orderedPosts);
  };

  useEffect(() => {
    if(!!isVisible){
      dispatch(getPosts({
        pagination: {
          _sort_by: "post_order",
          _sort: "desc"
        },
        filters: {
          group_id: idGroup,
          content_categories: idCategory,
          get_basic_data: true
        }
      }))
    }
  }, [isVisible, idCategory, idGroup]);

  useEffect(() => {
    if (!!postStates.success) {
      setSortPosts(posts || [])
    } else {
      setSortPosts([])
    }
  }, [postStates]);


  // useEffect(() => {
  //   if (idGroup && idCategory) {
  //     const currentCategory = allCategories.find(item => item.id === idCategory);
  //     const allPosts = currentCategory?.last_posts;

  //     if (allPosts && allPosts?.length > 0) {
  //       const orderedPosts = allPosts
  //         ?.sort((a, b) => b.post_order - a.post_order);

  //       setSortPosts(orderedPosts || []);
  //     }
  //   }
  // }, [idGroup, idCategory]);

  useEffect(() => {
    if (reorderPostsStates.success) {
      !!onClose && onClose();
      setTimeout(() => {
        dispatch(resetUpdateCategory());
      }, 3500);
    }
  }, [reorderPostsStates]);

  return <>
    <Sidebar
      position="right"
      visible={isVisible}
      closeType="back"
      onClose={() => {
        !!onClose && onClose();
      }}
      width="463px"
      title="Ordenar Artículos"
    >

      <Preview>
        <Description>Mueve y arrastra los bloques para ordenar los artículos de la categoría, luego toca en "Guardar Orden"</Description>

        <div style={{ height: 30 }} />

        <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
          <Button
            disabled={reorderPostsStates.loading}
            loading={reorderPostsStates.loading}
            options={{
              type: "filled",
              skin: "purple",
              size: "lg",
              block: true,
            }}
            onClick={() => reorder()}
          >
            Guardar Orden
          </Button>
        </div>

        <div style={{ height: 30 }} />


        {(!sortPosts || sortPosts.length <= 0) ? (
          <LogoLoader />
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId={'category-drag-and-drop'}>
              {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                <List key={'category-drag-and-drop'} {...provided.droppableProps} ref={provided.innerRef}>

                  {sortPosts?.map((post, index) => (
                    <>
                      <Draggable
                        key={"post-" + post.id}
                        draggableId={"post-" + post.id}
                        index={index}
                        isDragDisabled={String(post?.id) === "without_post"}
                      >
                        {(
                          providedDraggable: DraggableProvided,
                          snapshotDraggable: DraggableStateSnapshot
                        ) => (
                          <ListItem
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                            ref={providedDraggable.innerRef}
                            style={{
                              ...providedDraggable?.draggableProps?.style,
                              left: 50
                              // Add any other styles as needed
                            }}
                          >
                            <PostOrderCard post={post} showActions={false} />
                          </ListItem>

                        )}
                      </Draggable>
                    </>
                  ))}

                  {provided.placeholder}
                </List>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        )}

        <div style={{ height: 30 }} />
      </Preview>
    </Sidebar>

    <Snackbar
      visible={(reorderPostsStates.success || !!reorderPostsStates.error || reorderPostsStates.loading)}
      options={{
        time: 1000,
        type: reorderPostsStates.success ? "success" : (reorderPostsStates.loading ? "info" : "error"),
      }}
    // onHide={() => dispatch(resetReorder())}
    >
      {reorderPostsStates.success && "Artículos Reordenados exitosamente!"}
      {reorderPostsStates.error && "Error al Reordenar artículos"}
    </Snackbar>
  </>
}

const state = ({ groupStore, categoryStore, postStore }) => {
  const { data: category, states: categoryStates } =
    categoryStore?.singleCategory;
  const { data: group, states: groupStates } =
    groupStore.group;
  const { data: allCategories, states: allCategoriesStates } = categoryStore?.allCategories;
  const { data: posts, states: postStates } = postStore.listPosts;
  const { data: reorderedPosts, states: reorderPostsStates } = categoryStore.reorderCategoryPosts;

  return {
    allCategories,
    allCategoriesStates,
    group,
    groupStates,
    category,
    categoryStates,
    posts,
    postStates,
    reorderedPosts,
    reorderPostsStates
  };
};

export default connect(state)(Component);