import { DeleteWelcomeContentPostOutDto, EditWelcomeContentPostOutDto, EditWelcomeContentSectionOutDto, FinishWelcomeContentPostOutDto, FinishWelcomeContentSectionOutDto, GetAllWelcomeContentPostsOutDto, GetWelcomeContentPostOutDto, GetWelcomeContentSectionOutDto, NewWelcomeContentPostOutDto, ReorderWelcomeContentPostsOutDto, WelcomeContentPostDto, WelcomeContentSectionDto } from "../../types/welcome-section";

import {
  NEW_WELCOME_CONTENT_POST,
  NewWelcomeContentPost,
  ResetNewWelcomeContentPost,
  RESET_NEW_WELCOME_CONTENT_POST,
} from "../types/welcome-content/new-welcome-content-post";

import {
  GET_ALL_WELCOME_CONTENT_POSTS,
  GetAllWelcomeContentPosts,
  RESET_GET_ALL_WELCOME_CONTENT_POSTS,
  ResetGetAllWelcomeContentPosts
} from "../types/welcome-content/get-all-welcome-content-posts";

import {
  GET_WELCOME_CONTENT_POST,
  RESET_GET_WELCOME_CONTENT_POST,
  ResetGetWelcomeContentPost,
  GetWelcomeContentPost,
} from "../types/welcome-content/get-welcome-content-post";

import {
  EDIT_WELCOME_CONTENT_POST,
  EditWelcomeContentPost,
  ResetEditWelcomeContentPost,
  RESET_EDIT_WELCOME_CONTENT_POST,
} from "../types/welcome-content/edit-welcome-content-post";

import {
  DELETE_WELCOME_CONTENT_POST,
  DeleteWelcomeContentPost,
  RESET_DELETE_WELCOME_CONTENT_POST,
  ResetDeleteWelcomeContentPost,
} from "../types/welcome-content/delete-welcome-content-post";



import { DeleteWelcomeContentPostFile, DELETE_WELCOME_CONTENT_POST_FILE } from "../types/welcome-content/delete-welcome-content-post-file";

import {
  NEW_WELCOME_CONTENT_SECTION,
  RESET_NEW_WELCOME_CONTENT_SECTION,
  NewWelcomeContentSection,
  ResetNewWelcomeContentSection,
} from "../types/welcome-content/new-welcome-content-section";

import { GET_WELCOME_CONTENT_SECTION, RESET_GET_WELCOME_CONTENT_SECTION, GetWelcomeContentSection, ResetGetWelcomeContentSection } from "../types/welcome-content/get-welcome-content-section";
import { EDIT_WELCOME_CONTENT_SECTION, RESET_EDIT_WELCOME_CONTENT_SECTION, EditWelcomeContentSection, ResetEditWelcomeContentSection } from "../types/welcome-content/edit-welcome-content-section";
import { REORDER_WELCOME_CONTENT_POSTS, ReorderWelcomeContentPosts } from "../types/welcome-content/reorder-welcome-content-posts";
import { FinishWelcomeContentPost, FINISH_WELCOME_CONTENT_POST, ResetfinishWelcomeContentPost, RESET_FINISH_WELCOME_CONTENT_POST } from "../types/welcome-content/finish-welcome-content-post";
import { FinishWelcomeContentSection, FINISH_WELCOME_CONTENT_SECTION, ResetfinishWelcomeContentSection, RESET_FINISH_WELCOME_CONTENT_SECTION } from "../types/welcome-content/finish-welcome-content-section";

export const newWelcomeContentPost = (payload: NewWelcomeContentPostOutDto): NewWelcomeContentPost => {
  return { type: NEW_WELCOME_CONTENT_POST, payload };
};

export const resetNewWelcomeContentPost = (): ResetNewWelcomeContentPost => {
  return { type: RESET_NEW_WELCOME_CONTENT_POST, payload: null };
};

export const getAllWelcomeContentPosts = (payload: GetAllWelcomeContentPostsOutDto): GetAllWelcomeContentPosts => {
  return { type: GET_ALL_WELCOME_CONTENT_POSTS, payload };
};

export const resetGetAllWelcomeContentPost = (): ResetGetAllWelcomeContentPosts => {
  return { type: RESET_GET_ALL_WELCOME_CONTENT_POSTS, payload: null };
};

export const getWelcomeContentPost = (payload: GetWelcomeContentPostOutDto): GetWelcomeContentPost => {
  return { type: GET_WELCOME_CONTENT_POST, payload };
};

export const resetGetWelcomeContentPost = (): ResetGetWelcomeContentPost => {
  return { type: RESET_GET_WELCOME_CONTENT_POST, payload: null };
};

export const editWelcomeContentPost = (payload: EditWelcomeContentPostOutDto): EditWelcomeContentPost => {
  return { type: EDIT_WELCOME_CONTENT_POST, payload };
};

export const resetEditWelcomeContentPost = (): ResetEditWelcomeContentPost => {
  return { type: RESET_EDIT_WELCOME_CONTENT_POST, payload: null };
};

export const deleteWelcomeContentPost = (payload: DeleteWelcomeContentPostOutDto): DeleteWelcomeContentPost => {
  return { type: DELETE_WELCOME_CONTENT_POST, payload };
};

export const resetDeleteWelcomeContentPost = (): ResetDeleteWelcomeContentPost => {
  return { type: RESET_DELETE_WELCOME_CONTENT_POST, payload: null };
};

export const reorderWelcomeContentPost = (payload: ReorderWelcomeContentPostsOutDto): ReorderWelcomeContentPosts => {
  return { type: REORDER_WELCOME_CONTENT_POSTS, payload };
};


export const deleteFile = (payload: { idFile: number }): DeleteWelcomeContentPostFile => {
  return { type: DELETE_WELCOME_CONTENT_POST_FILE, payload };
};


export const newWelcomeContentSection = (payload: WelcomeContentSectionDto): NewWelcomeContentSection => {
  console.log('action newWelcomeContentSection', payload)
  return { type: NEW_WELCOME_CONTENT_SECTION, payload };
};

export const resetNewWelcomeContentSection = (): ResetNewWelcomeContentSection => {
  return { type: RESET_NEW_WELCOME_CONTENT_SECTION, payload: null };
};
export const getWelcomeContentSection = (payload: GetWelcomeContentSectionOutDto): GetWelcomeContentSection => {
  return { type: GET_WELCOME_CONTENT_SECTION, payload };
};

export const resetGetWelcomeContentSection = (): ResetGetWelcomeContentSection => {
  return { type: RESET_GET_WELCOME_CONTENT_SECTION, payload: null };
};
export const editWelcomeContentSection = (payload: EditWelcomeContentSectionOutDto): EditWelcomeContentSection => {
  return { type: EDIT_WELCOME_CONTENT_SECTION, payload };
};

export const resetEditWelcomeContentSection = (): ResetEditWelcomeContentSection => {
  return { type: RESET_EDIT_WELCOME_CONTENT_SECTION, payload: null };
};

export const finishWelcomeContentPost = (payload: FinishWelcomeContentPostOutDto): FinishWelcomeContentPost => {
  return { type: FINISH_WELCOME_CONTENT_POST, payload };
};

export const finishWelcomeContentSection = (payload: FinishWelcomeContentSectionOutDto): FinishWelcomeContentSection => {
  return { type: FINISH_WELCOME_CONTENT_SECTION, payload };
};

export const resetFinishWelcomeContentPost = (): ResetfinishWelcomeContentPost => {
  return { type: RESET_FINISH_WELCOME_CONTENT_POST, payload: null };
};

export const resetFinishWelcomeContentSection = (): ResetfinishWelcomeContentSection => {
  return { type: RESET_FINISH_WELCOME_CONTENT_SECTION, payload: null };
};