import Link from '../../../../components/Link'
import { useMemo } from 'react';
import { getFreeSubscriptions } from '../../../../helpers/getFreeSubscriptions';
import { isSubscribed } from '../../../../helpers/isSubscribed';
import { Group, SessionInDto, User } from '../../../../types';
import {
  Title,
  Text,
  Card
} from './styles'
import { connect } from 'react-redux';
import { Post } from '../../../../types/post';
import constants from '../../../../constants';
import whatsapp from '../../../../constants/whatsapp';
import MPRetryingSubscriptionButton from '../MPRetryingSubscriptionButton';
import { CSSProperties } from 'styled-components';
import { WelcomeContentPostDto } from '../../../../types/welcome-section';
import { getPriorityGroupSubscription } from '../../../../helpers/mpStatusPriority';

interface ComponentProps {
  post: Post;
  group: Group;
  welcomeContentPost: WelcomeContentPostDto;
  session: SessionInDto;
  user: User;
  userSubscriptions;
  style?: CSSProperties
  isLoggedIn: Boolean;
  currentPage: string;
  visibleModal: string
}

const Component = ({
  post,
  group,
  welcomeContentPost,
  session,
  user,
  userSubscriptions,
  isLoggedIn,
  currentPage,
  visibleModal,
  style = {}
}: ComponentProps) => {
  const groupId = useMemo(() => {
    return post?.group?.id || session?.group?.id || group?.id || welcomeContentPost?.welcome_section?.group
  }, [group, post, session])

  const subscription = useMemo(() => {
    return getPriorityGroupSubscription(userSubscriptions, groupId)
  }, [userSubscriptions, group])

  const isUserAuthenticated = useMemo(() => {
    return isLoggedIn
  }, [isLoggedIn, post])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(userSubscriptions, groupId)
    } else {
      return false
    }
  }, [userSubscriptions, groupId, isLoggedIn, groupId])

  const isSubscriptionWaitingForGateway = useMemo(() => {
    const mpInactiveSubscriptionStates = ['retrying', 'not_paid']
    return mpInactiveSubscriptionStates.includes(subscription?.status)
  }, [subscription])

  const isPaidSubscription = useMemo(() => {
    const freeSubscriptionTypes = ["guest", "free"]
    return !freeSubscriptionTypes?.includes(subscription?.type)
  }, [subscription])

  if (!isUserAuthenticated || !isUserSubscribed || !isSubscriptionWaitingForGateway || !isPaidSubscription) {
    return <></>
  }

  return (
    <Card style={style}>
      {subscription?.status === 'not_paid' && (
        <>
          <Title skin="red">Tu pago ha fallado</Title>
          <div style={{ height: 22 }} />
          <Text>Hola {user?.name}, lamentablemente, el<br />pago no se pudo completar 😔. No te<br />preocupes, puedes solucionarlo<br />fácilmente.</Text>
          <div style={{ height: 22 }} />
          <MPRetryingSubscriptionButton />
          <div style={{ height: 22 }} />
          <Text>El acceso estará bloqueado hasta <br />que se procese el pago. <br /> ¡Gracias por tu paciencia!</Text>
        </>
      )}

      {subscription?.status === 'retrying' && (
        <>
          <Title skin="purple">En breve estarás de vuelta</Title>
          <div style={{ height: 22 }} />
          <Text>Estamos procesando tu pago y en<br />breve estará todo resuelto.<br />Por ahora, el acceso al contenido<br />está temporalmente pausado hasta<br />que tu banco nos confirme <br />que el pago ha sido aprobado.<br /><br />Gracias por tu paciencia.<br />¡Todo estará listo muy pronto!</Text>
          <div style={{ height: 22 }} />
          <Text style={{ display: 'flex', gap: 4, justifyContent: 'center', alignItems: 'center', fontSize: 14 }}>
            ¿Necesitas ayuda?
            <Link
              options={{ skin: 'purple', size: 'sm', type: 'link', native: false }}
              style={{ fontWeight: 500, fontSize: 14, color: '#81CEE3' }}
              href={`${constants.whatsapp.url}?phone=${whatsapp.number
                }&text=${constants.whatsapp.retrying_payment.replace("{name}", user?.name).replace("{email}", user?.email)}`}
              target="_blank"
            >
              Contactar a soporte
            </Link>
          </Text>
        </>
      )}

    </Card>
  )
}

const state = ({ userStore, postStore, pageStore, groupStore, sessionStore, modalStore, welcomeContentStore }) => {
  const { data: user } = userStore.information;
  const { data: group } = groupStore.group;
  const { data: userSubscriptions } = userStore.subscriptions;
  const { data: post } = postStore.post;
  const { data: session } = sessionStore.one;
  const { isLoggedIn } = userStore;
  const { visibleModal } = modalStore
  const { data: welcomeContentPost } = welcomeContentStore.welcomeContentPost;

  return {
    post,
    group,
    session,
    user,
    welcomeContentPost,
    userSubscriptions,
    isLoggedIn: isLoggedIn.isLoggedIn,
    visibleModal,
    currentPage: pageStore?.name,
  };
};

export default connect(state)(Component);