import ReactDOM from "react-dom";
import { connect, useDispatch } from "react-redux";
import Icon from "../../../Icon";
import constants from "../../../../constants";
import { User } from "../../../../types";
import { Preference } from "../../../../types/preferences";
import { Actions } from "./styles";
import { useEffect, useMemo, useState } from "react";
import slugify from "slugify";
import { ghostLink } from "../../../../helpers/ghost-link";
import { useHistory } from "react-router-dom";
import { useLayout } from "../../../../providers/LayoutProvider";

interface ComponentProps { }

const footerSticky = document.getElementById("footer-sticky") as HTMLElement;

interface ComponentProps {
  userInformation: User;
  userInformationStates;
  useLocation;
  preferences: Preference[];
  preferencesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  subscriptions;
  subscriptionsStates;
  currentPage;
}

const Component = ({
  userInformation,
  userInformationStates,
  useLocation,
  preferences,
  preferencesStates,
  subscriptions,
  subscriptionsStates,
  currentPage,
}: ComponentProps) => {
  const { layout } = useLayout();
  const dispatch = useDispatch();
  const history = useHistory();
  const [availableMarks, setAvailableMarks] = useState<string[]>([]);

  const onClick = (page) => {
    const redirect = page.native;

    if (page.url) {
      if (redirect) {
        history.push(page.url);
      } else {
        ghostLink(page.url);
      }
    }

    if (page.action) {
      dispatch(page.action());
    }
  };

  const isExpert = useMemo((): boolean => {
    return !!userInformation?.expert && userInformationStates.success;
  }, [userInformation, userInformationStates]);

  if (!layout?.header?.actions || layout?.header?.actions?.length <= 0) {
    return <></>;
  }

  return ReactDOM.createPortal(
    <Actions>{layout?.header?.actions}</Actions>,
    footerSticky
  );
};

const state = ({ userStore, preferencesStore, pageStore }) => {
  const { data: userInformation, states: userInformationStates } =
    userStore.information;
  const { data: preferences, states: preferencesStates } =
    preferencesStore.preferences;
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;

  return {
    userInformation,
    userInformationStates,
    preferences,
    preferencesStates,
    subscriptions,
    subscriptionsStates,
    currentPage: pageStore?.name,
  };
};

export default connect(state)(Component);
