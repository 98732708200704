import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { showModal } from "../../../../../../store/actions/modal";
import { useTheme } from 'styled-components'

import Grid from "../../../../../../components/Grid";
import Image from "../../../../../../components/Image";
import Swipe from "../../../../../../components/Swipe";
import { Post } from "../../../../../../types/post";
import GoBack from "../../../../../../components/GoBack";
import Link from "../../../../../../components/Link";
import Icon from "../../../../../../components/Icon";
import Button from "../../../../../../components/Button";
import { isOwnArticle } from "../../../../../../helpers/isOwnArticle";
import {
  PostType,
  MobileHeader,
  ShareCta,
  SwipeContent,
  Actions,
  MenuCta,
  Name,
  Title,
  PublicationStateData,
  PublicationState,
  InLine,
  Avatar,
  AvatarImage,
  CreatedAt,
  Dropdown,
  ButtonItem,
  LinkItem,
} from "./styles";
import { Center } from '../../../../../../components/StyledComponents'

import dotsVerticalIcon from "../../../../../../assets/icons/more-vertical.svg";
import shareIcon from "../../../../../../assets/icons/share.svg";
import pencilIcon from "../../../../../../assets/icons/post-edit-icon.svg";
import { getUrlParamByName } from "../../../../../../helpers/get-url-param";
import { useWindowSize } from "../../../../../../hooks/useWindowSize";
import { dateSpliter } from "../../../../../../helpers/dates-hanlder";
import userAvatar from "../../../../../../assets/avatar.svg";
import schedulerIcon from "../../../../../../assets/scheduler-icon.svg";
import checkIcon from "../../../../../../assets/icons/check-light.svg";

interface ComponentProps {
  userInformation;
  postInformation: Post;
  viewAsSubscriber: boolean
}

const Component = ({ userInformation, postInformation, viewAsSubscriber }: ComponentProps) => {
  const theme = useTheme() as { colors: { Primary: string } }
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();

  const createdAt = useMemo(() => dateSpliter(postInformation?.published_at || postInformation?.schedule_publish_at), [postInformation])
  const timeZone = useMemo(() => {
    const dateTime = new Date();

    const timeZoneNameString = dateTime.toLocaleTimeString(undefined, {
      timeZoneName: 'long',
    }).split(' ')[2]

    return `${timeZoneNameString}`;
  }, [])

  return (
    <>
      <Center vertical="center" horizontal="space">
        <AvatarImage>
          <Avatar
            src={
              postInformation?.group?.profile_pic?.url ||
              postInformation?.group?.expert?.profile_pic?.url ||
              userAvatar
            }
            alt={postInformation?.group?.expert?.username}
            width="55px"
            height="55px"
          />

          <Center horizontal="center" vertical="top" column gap={2}>
            <Name>
              {postInformation?.group?.creator_name ||
                postInformation?.group?.expert?.username}
            </Name>
            <CreatedAt>{!postInformation?.published_at ? (
              <>
                <Icon icon={schedulerIcon} color="#2D3436" size="14px" />
                Se publicará el {createdAt?.date} {isMobile && <br />} a las {createdAt?.hours}:{createdAt?.minutes} hs ({timeZone})
              </>
            ) : (
              <>
                {createdAt?.date}
                <span>|</span>
                <span style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  <Icon icon={checkIcon} color="#2D3436" size="14px" />
                  Publicado
                </span>
              </>
            )}
            </CreatedAt>
          </Center>
        </AvatarImage>

        <Actions>
          {/* 
          <Link
            target={"_blank"}
            options={{
              type: "link",
              size: "lg",
              skin: "white",
            }}
            href={`/group/${postInformation?.group?.id}/p/${postInformation?.id}?viewAsSubscriber=true`}
          >
            Ver como suscriptor
          </Link>
          */}

          {isOwnArticle(postInformation, userInformation?.expert?.id) && !viewAsSubscriber && (
            <>
              {!isMobile &&
                <Link
                  options={{
                    type: "link",
                    size: "lg",
                    skin: "purple",
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 6,
                    textDecoration: 'none'
                  }}
                  href={`/dashboard/${postInformation?.group?.group_type === "free_experience" ? "community/events" : postInformation?.group?.group_type}/${postInformation?.group?.id}/posts/${postInformation?.id}`}
                >
                  <Icon icon={pencilIcon} size="24px" color={theme.colors.Primary} />
                  Editar
                </Link>
              }

              <ShareCta
                type="button"
                onClick={() => {
                  const isFree = postInformation?.is_free;
                  const modal = isFree
                    ? "modal-share-post-link"
                    : "modal-share-post-in-telegram";

                  if (isFree) {
                    const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${postInformation?.group?.id}/p/${postInformation?.id}`;
                    navigator.clipboard.writeText(link);
                  }

                  dispatch(showModal(modal));
                }}
              >
                <Icon icon={shareIcon} size="24px" color={theme.colors.Primary} />
                {!isMobile && "Compartir"}
              </ShareCta>

              {isMobile ? (
                <MenuCta
                  type="button"
                  style={{ display: "flex" }}
                  onClick={() =>
                    dispatch(showModal("swipe-article-preview-owner-menu"))
                  }
                >
                  <Icon icon={dotsVerticalIcon} size="24px" color={theme.colors.Primary} />
                </MenuCta>
              ) : (
                <Dropdown
                  listMinWidth="250px"
                  label={
                    <Icon icon={dotsVerticalIcon} size="24px" color={theme.colors.Primary} />
                  }>

                  {postInformation?.group?.group_type !== "community" && postInformation?.group?.group_type !== "free_experience" && (
                    <ButtonItem
                      marginBottom="8px"
                      alignSelf="flex-end"
                      options={{
                        type: "filled",
                        size: "lg",
                        skin: "purple",
                      }}
                      onClick={() => {
                        dispatch(
                          showModal(
                            postInformation?.is_free
                              ? "modal-not-free-post"
                              : "modal-free-post"
                          )
                        );
                      }}
                    >
                      {postInformation?.is_free
                        ? "Limitar Contenido"
                        : "Liberar Contenido"}
                    </ButtonItem>
                  )}

                  <LinkItem
                    marginBottom="8px"
                    alignSelf="flex-end"
                    options={{
                      type: "outline",
                      size: "lg",
                      skin: "purple",
                    }}
                    href={`/dashboard/${postInformation?.group?.group_type === "free_experience"
                      ? "community/events"
                      : postInformation?.group?.group_type
                      }/${postInformation?.group?.id}/posts/${postInformation?.id}`}
                  >
                    Editar artículo
                  </LinkItem>

                  {postInformation?.group?.group_type !== "community" && postInformation?.group?.group_type !== "free_experience" && (
                    <ButtonItem
                      marginBottom="8px"
                      alignSelf="flex-end"
                      options={{
                        type: "link",
                        size: "lg",
                        skin: "purple",
                      }}
                      onClick={() => {
                        if (postInformation?.published_at)
                          dispatch(showModal("modal-change-post-published-date"));
                        else
                          dispatch(showModal("modal-change-post-published-date"));
                      }}
                    >
                      {postInformation?.published_at ? "Programar publicación" : "Reprogramar"}
                    </ButtonItem>
                  )}

                  <ButtonItem
                    marginBottom="0px"
                    alignSelf="flex-end"
                    options={{
                      type: "link",
                      size: "lg",
                      skin: "purple",
                    }}
                    onClick={() => {
                      dispatch(showModal("modal-delete-post"));
                    }}
                  >
                    Eliminar artículo
                  </ButtonItem>
                </Dropdown>
              )}
            </>
          )}
        </Actions>
      </Center>

      {isOwnArticle(postInformation, userInformation?.expert?.id) && !viewAsSubscriber && (
        <Swipe name="swipe-article-preview-owner-menu">
          <SwipeContent>
            {postInformation?.group?.group_type !== "community" && postInformation?.group?.group_type !== "free_experience" && (
              <Button
                options={{
                  type: "filled",
                  size: "lg",
                  skin: "purple",
                  block: true,
                }}
                onClick={() => {
                  dispatch(
                    showModal(
                      postInformation?.is_free
                        ? "modal-not-free-post"
                        : "modal-free-post"
                    )
                  );
                }}
                style={{ marginBottom: "8px" }}
              >
                {postInformation?.is_free
                  ? "Limitar Contenido"
                  : "Liberar Contenido"}
              </Button>
            )}

            <Link
              options={{
                type: "outline",
                size: "lg",
                skin: "purple",
                block: true,
              }}
              style={{ marginBottom: "8px", height: "40px" }}
              href={`/dashboard/${postInformation?.group?.group_type === "free_experience"
                ? "community/events"
                : postInformation?.group?.group_type
                }/${postInformation?.group?.id}/posts/${postInformation?.id}`}
            >
              Editar artículo
            </Link>

            {postInformation?.group?.group_type !== "community" && postInformation?.group?.group_type !== "free_experience" && (
              <Button
                options={{
                  type: "link",
                  size: "lg",
                  skin: "purple",
                  block: true,
                }}
                style={{ height: "40px" }}

                onClick={() => {
                  if (postInformation?.published_at)
                    dispatch(showModal("modal-change-post-published-date"));
                  else
                    dispatch(showModal("modal-change-post-published-date"));
                }}
              >
                {postInformation?.published_at ? "Programar publicación" : "Reprogramar"}
              </Button>
            )}

            <Button
              options={{
                type: "link",
                size: "lg",
                skin: "purple",
                block: true,
              }}
              style={{ height: "40px" }}
              onClick={() => {
                dispatch(showModal("modal-delete-post"));
              }}
            >
              Eliminar artículo
            </Button>
          </SwipeContent>
        </Swipe>
      )}
    </>
  );
};

const state = ({ userStore, postStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: postInformation } = postStore.post;

  return {
    userInformation,
    postInformation,
  };
};

export default connect(state)(Component);
