import { connect, useDispatch } from "react-redux";
import { showModal } from "./../../../../../store/actions/modal";
import { Data, Button, Section, Username } from "../../styles";
import ModalChangeUsername from "./ModalChangeUsername";

const Component = ({ changeUsernameStates, userInformation }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Section>
        <Data>Nombre de usuario</Data>
        <Username>{userInformation?.expert?.username}</Username>
        <Button
          type="button"
          onClick={() => dispatch(showModal("change-username-modal"))}
          loading={false}
          options={{
            skin: "purple",
            size: "lg",
            type: "link",
          }}
        >
          Modificar
        </Button>

        <ModalChangeUsername />
      </Section>
    </>
  );
};

const state = ({ userStore }) => {
  const { information, changeUsername } = userStore;
  const { data: userInformation } = information;
  return {
    userInformation,
    changeUsernameStates: changeUsername.states,
  };
};

export default connect(state)(Component);
