/**
 *  Colors
 */

function calculateColors(startingColor) {
  const rgbValues = startingColor.split(',').map(Number); // Split the string and convert to numbers

  // Calculate the other two colors
  const color2 = `${rgbValues[0] - 15}, ${rgbValues[1] - 17}, ${rgbValues[2] - 16}`;
  const color3 = `${rgbValues[0] - 25}, ${rgbValues[1] - 28}, ${rgbValues[2] - 7}`;

  return [color2, color3];
}


export const colors = ({ primary, secondary }) => ({
  Primary: `rgba(${primary}, 1)`,
  Primary05: `rgba(${primary}, .05)`,
  Primary10: `rgba(${primary}, .10)`,
  Primary15: `rgba(${primary}, .15)`,
  Primary20: `rgba(${primary}, .20)`,
  Primary25: `rgba(${primary}, .25)`,
  Primary30: `rgba(${primary}, .30)`,
  Primary35: `rgba(${primary}, .35)`,
  Primary40: `rgba(${primary}, .40)`,
  Primary45: `rgba(${primary}, .45)`,
  Primary50: `rgba(${primary}, .50)`,
  Primary55: `rgba(${primary}, .55)`,
  Primary60: `rgba(${primary}, .60)`,
  Primary65: `rgba(${primary}, .65)`,
  Primary70: `rgba(${primary}, .70)`,
  Primary75: `rgba(${primary}, .75)`,
  Primary80: `rgba(${primary}, .80)`,
  Primary85: `rgba(${primary}, .85)`,
  Primary90: `rgba(${primary}, .90)`,
  Primary95: `rgba(${primary}, .95)`,

  Secondary: `rgba(${secondary}, 1)`,
  Secondary05: `rgba(${secondary}, .05)`,
  Secondary10: `rgba(${secondary}, .10)`,
  Secondary15: `rgba(${secondary}, .15)`,
  Secondary20: `rgba(${secondary}, .20)`,
  Secondary25: `rgba(${secondary}, .25)`,
  Secondary30: `rgba(${secondary}, .30)`,
  Secondary35: `rgba(${secondary}, .35)`,
  Secondary40: `rgba(${secondary}, .40)`,
  Secondary45: `rgba(${secondary}, .45)`,
  Secondary50: `rgba(${secondary}, .50)`,
  Secondary55: `rgba(${secondary}, .55)`,
  Secondary60: `rgba(${secondary}, .60)`,
  Secondary65: `rgba(${secondary}, .65)`,
  Secondary70: `rgba(${secondary}, .70)`,
  Secondary75: `rgba(${secondary}, .75)`,
  Secondary80: `rgba(${secondary}, .80)`,
  Secondary85: `rgba(${secondary}, .85)`,
  Secondary90: `rgba(${secondary}, .90)`,
  Secondary95: `rgba(${secondary}, .95)`,

  PurpleLight: "#CF6AFF",
  PurpleVeryLight: "#BB85FF",
  PurpleExtraLight: "#FAF3FF",
  PurpleStrong: "#452F61",

  Yellow: "#FDCB6E",
  YellowLight: "#FFEAA7",
  Pink: "#FFF7E9",
  Lila: "rgba(108, 92, 231, 0.08)",

  Blue1: "#DBE9FC",
  Blue2: "#6DA5F3",
  Blue3: "#5698F5",
  Blue4: "#2869C5",

  Black: "#2D3436",
  Black24: "#C2C2C2",
  Black32: "rgba(0,0,0,.32)",
  Black40: "rgba(45, 52, 54, 0.4)",
  Black75: "rgba(45, 52, 54, 0.75)",
  Black50: "rgba(45, 52, 54, 0.50)",
  Black45: "rgba(45, 52, 54, 0.45)",
  Black01: "rgba(0, 0, 0, 1)",

  Transparent25: "rgba(255, 255, 255, 0.25)",
  Transparent0: "rgba(255, 255, 255, 0)",

  White: "#FFFFFF",

  Green: "#12B47A",
  LightGreen: "#128C7E",

  Red: "#EB5757",
  RedLight: "#FCE6E6",

  Gray: "#FAF3FF",
  LightGray: "#FAFAFA",

  Gray0: "#e0e4ea",
  Gray1: "#ecf0f1",
  Gray2: "#d9dde6",
  Gray5: "#E4E4E5",
  Gray8: "#B8B7BC",
  Gray9: "#69779B",
  Gray10: "#E5E5E5",
  Gray16: "#F2F2F2",
  Gray68: "#ADADAD",

  BlueLight: "#69779B",
  SoftBlue: "rgba(105, 119, 155, 0.15)",
  LightBlue2: "#42B2FC",

  Success: "#51CF66",
  Info: "#259DD5",
  Warning: "#FAB005",
  Danger: "#FF0000",

  WaterGreen: "#12B1B4",
  SoftOrange: "#FDCB6E",

  RainbowText: `
  background: rgba(${primary}, 1);
  background: linear-gradient(
    180deg,
    rgba(${primary}, 1) 0%,
    rgba(${calculateColors(primary)[0]}, 1) 30%,
    rgba(${calculateColors(primary)[1]}, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(${primary}, 1) 0%,
    rgba(${calculateColors(primary)[0]}, 1) 30%,
    rgba(${calculateColors(primary)[1]}, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`,

  RainbowBackground: `
  background: rgba(${primary}, 1);
  background: linear-gradient(
    180deg,
    rgba(${primary}, 1) 0%,
    rgba(${calculateColors(primary)[0]}, 1) 30%,
    rgba(${calculateColors(primary)[1]}, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(${primary}, 1) 0%,
    rgba(${calculateColors(primary)[0]}, 1) 30%,
    rgba(${calculateColors(primary)[1]}, 1) 100%
  );
`,
});
