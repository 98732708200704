import { combineReducers } from "redux";
import acceptInvitation from "./accept";
import createInvitation from "./create";
import editInvitation from "./edit";

const reducer = combineReducers({
  acceptInvitation,
  createInvitation,
  editInvitation
});

export default reducer;
