import {
  subscriptionSteps,
  subscriptionStates,
  paymentStatus,
} from "../../store/types/user/subscription";

interface ChargebeeCustomerInformation {
  email: string;
  id?: string;
  name?: string;
  lastName?: string;
  address?: string;
  state?: string;
  city?: string;
  postalCode?: string;
  cellphone?: string;
  phoneAreaCode?: string;
}

export interface SubscriptionDto {
  subscriptionId: number | null;
  currentRejectedReason: string | null;
  groupUrl: string;
  groupId: string | null;
  groupName: string | null;
  groupSlug: string | null;
  groupType?: "membership" | "experience";
  planId: string | null;
  planID: number | null;
  planPrice: number | null;
  organizationId?: string | null;
  organizationAlias?: string | null;
  platform?: "chargebee" | "rebill" | "mercadopago" | "paypal";
  planType?: "subscription" | "charge";
  couponId?: number;
  isLoggedIn: boolean | null;
  userEmail: string | null;
  subscriberName: string | null;
  subscriberLastName: string | null;
  subscriberCellphone: string | null;
  subscriberPhoneAreaCode: string | null;
  subscriberAddress: string | null;
  subscriberPostalCode: string | null;
  subscriberState: string | null;
  subscriberCity: string | null;
  chargebeeCustomerId: string | null;
  isAlreadySubscribed: boolean | null;
  subscriptionState:
  | typeof subscriptionStates.CANCELLED
  | typeof subscriptionStates.DONE
  | typeof subscriptionStates.INPROGRESS
  | null;
  subscriptionStep:
  | typeof subscriptionSteps.AUTHENTICATION
  | typeof subscriptionSteps.PAYMENT
  | typeof subscriptionSteps.CHECKOUT
  | typeof subscriptionSteps.AUTHORIZED
  | null;
  paymentStatus:
  | typeof paymentStatus.PAID
  | typeof paymentStatus.CANCELLED
  | typeof paymentStatus.WAITING
  | null;
}

const initialValue: SubscriptionDto = {
  subscriptionId: null,
  currentRejectedReason: null,
  groupUrl: "",
  groupId: null,
  groupSlug: null,
  groupName: null,
  planId: null,
  planPrice: null,
  planID: null,
  organizationId: null,
  organizationAlias: null,
  platform: undefined,
  isLoggedIn: null,
  userEmail: null,
  chargebeeCustomerId: null,
  subscriberName: null,
  subscriberLastName: null,
  subscriberPhoneAreaCode: null,
  subscriberCellphone: null,
  subscriberAddress: null,
  subscriberPostalCode: null,
  subscriberState: null,
  subscriberCity: null,
  isAlreadySubscribed: null,
  subscriptionState: null,
  subscriptionStep: null,
  paymentStatus: null,
  groupType: undefined,
};

export const clean = () => {
  sessionStorage.removeItem("subscription");
};

export const init = () => {
  const subscription = sessionStorage.getItem("subscription");
  !subscription &&
    sessionStorage.setItem("subscription", JSON.stringify(initialValue));

  return initialValue;
};

export const save = (data): SubscriptionDto => {
  const subscription = sessionStorage.getItem("subscription") || "{}";
  const object: SubscriptionDto = { ...JSON.parse(subscription), ...data };
  sessionStorage.setItem("subscription", JSON.stringify(object));
  return object;
};

export const getByKey = (item: string): any => {
  const subscription = sessionStorage.getItem("subscription") || "{}";
  const object: SubscriptionDto = JSON.parse(subscription);
  const value = object[item];
  return value;
};

export const getAll = () => {
  const subscription = sessionStorage.getItem("subscription") || "{}";
  const object: SubscriptionDto = JSON.parse(subscription);
  return object;
};

export const getChargebeeData = () => {
  const subscription = sessionStorage.getItem("subscription") || "{}";
  const object: SubscriptionDto = JSON.parse(subscription);

  const { planId, couponId, groupType, planType, platform, ...rest } = object;

  const customer: ChargebeeCustomerInformation = {
    id: rest.chargebeeCustomerId || "",
    email: rest.userEmail || "",
    name: rest.subscriberName || "",
    lastName: rest.subscriberLastName || "",
    address: rest.subscriberAddress || "",
    state: rest.subscriberState || "",
    postalCode: rest.subscriberPostalCode || "",
    city: rest.subscriberCity || "",
    phoneAreaCode: rest.subscriberPhoneAreaCode || "",
    cellphone: rest.subscriberCellphone || "",
  };

  return {
    planId,
    platform,
    couponId,
    planType,
    customer,
    groupType,
  };
};

export const getMercadoPagoData = () => {
  const subscription = sessionStorage.getItem("subscription") || "{}";
  const object: SubscriptionDto = JSON.parse(subscription);

  const { planID, couponId, groupType, platform, ...rest } = object;

  return {
    planID,
    couponId,
    platform,
    groupType,
  };
};

export const getRebillData = () => {
  const subscription = sessionStorage.getItem("subscription") || "{}";
  const object: SubscriptionDto = JSON.parse(subscription);

  const { planId, couponId, groupType, platform, organizationId, organizationAlias, ...rest } = object;

  const customer: ChargebeeCustomerInformation = {
    id: rest.chargebeeCustomerId || "",
    email: rest.userEmail || "",
    name: rest.subscriberName || "",
    lastName: rest.subscriberLastName || "",
    address: rest.subscriberAddress || "",
    state: rest.subscriberState || "",
    postalCode: rest.subscriberPostalCode || "",
    city: rest.subscriberCity || "",
    phoneAreaCode: rest.subscriberPhoneAreaCode || "",
    cellphone: rest.subscriberCellphone || "",
  };

  return {
    planId,
    organizationId,
    organizationAlias,
    platform,
    couponId,
    customer,
    groupType,
  };
};
