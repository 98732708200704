import action from "../helpers/rest-client";

export class ErrorSession extends Error { }

class Session {
  public async new(payload) {
    const formData = new FormData();
    const data = {};

    for (let key in payload) {
      const currentElement = payload[key];

      if (
        (key === "cover_pic" || key === "speaker_profile_pic") &&
        currentElement
      ) {
        currentElement.forEach(async (element) => {
          formData.append(key, element, element.name);
        });
      } else {
        data[key] = currentElement;
      }
    }

    formData.append("data", JSON.stringify(data));

    return action
      .Post({
        url: `/api/session`,
        body: formData,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async update(payload) {
    const { id: idSession, ...rest } = payload;
    const formData = new FormData();
    const data = {};

    for (let key in rest) {
      const currentElement = payload[key];

      if (
        (key === "cover_pic" || key === "speaker_profile_pic") &&
        currentElement
      ) {
        currentElement.forEach(async (element) => {
          if (element && element?.url) {
            const file = await fetch(element?.url)
              .then((res) => res.blob())
              .then((blob) => {
                const extension = element?.url?.split(".").pop();
                return new File([blob], `${key}.${extension}"`, {
                  type: `image/${extension}`,
                });
              });

            formData.append(key, file, file.name);
          } else {
            formData.append(key, element, element.name);
          }
        });
      } else {
        data[key] = currentElement;
      }
    }

    formData.append("data", JSON.stringify(data));

    return action
      .Put({
        url: `/api/session/${idSession}`,
        body: formData,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async delete({ idSession, action_notification_message, notify_action }) {
    return action
      .Post({
        url: `/api/session/delete/${idSession}`,
        body: {
          idSession,
          action_notification_message,
          notify_action
        }
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async getOne(payload) {
    const { idSession } = payload;
    return action
      .Get({
        url: `/api/session/${idSession}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  }

  public async getAll(payload) {
    const { idGroup } = payload;
    return action
      .Get({
        url: `/api/session?group_id=${idGroup}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async deleteFile(payload) {
    const { idFile } = payload;

    return action
      .Del({
        url: `/api/post/file/${idFile}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async shareSessionInTelegram({ id }) {
    return action
      .Post({
        url: `/api/session/share-in-telegram`,
        body: { session_id: id },
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }
}

const session = new Session();
export default session;
