import socialNetworks from "./social-networks";
import pageGroupDetails from "./page-group-details";
import whatsapp from "./whatsapp";
import pageSignIn from "./page-signin";
import pageSignUp from "./page-signup";
import pageTelegram from "./page-telegram";
import currencies from "./currencies";
import pages from "./pages";
import validations from "./validations";
import urls from "./urls";
import countries from "./countries";
import userAvatar from "./user-avatar";
import reviews from "./reviews";
import pageWelcome from "./page-welcome";
import bestPractices from "./best-practices";
import dashboardMenu from "./menu/expert-menu";
import suscriberMenu from "./menu/suscriber-menu";
import groupMenu from "./menu/group-menu";
import calendly from "./calendly";
import tips from "./tips";
import tour from "./tour";
import advice from "./advice";
import fileUploader from "./file-uploader";
import homePage from "./home-page";
import durations from "./durations";
import groupStatus from "./group-status";
import congreso from "./congreso";
import pageCommunityHomeGroup from "./page-community-home-group";
import pageMembershipHomeGroup from "./page-membership-home-group";
import pageExperienceHomeGroup from "./page-experience-home-group";
import pageRecordedExperienceHomeGroup from "./page-recorded-experience-home-group";

const constants = {
  validations,
  currencies,
  socialNetworks,
  whatsapp,
  pages,
  pageGroupDetails,
  pageSignIn,
  pageSignUp,
  pageTelegram,
  urls,
  countries,
  userAvatar,
  reviews,
  pageWelcome,
  bestPractices,
  dashboardMenu,
  suscriberMenu,
  groupMenu,
  calendly,
  tips,
  tour,
  advice,
  fileUploader,
  homePage,
  durations,
  groupStatus,
  congreso,
  pageCommunityHomeGroup,
  pageMembershipHomeGroup,
  pageExperienceHomeGroup,
  pageRecordedExperienceHomeGroup
};

export default constants;
