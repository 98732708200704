import { useEffect, useContext, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import SignUp from "../../components/SignUpForm";
import { signUp } from "../../../store/actions/auth";
import { SubscriptionContext } from "../../../providers/Subscription";
import { subscriptionStates } from "../../../store/types/user/subscription";
import { setCookie } from "../../../helpers/cookies-handler";
import { getUrlParamByName } from "../../../helpers/get-url-param";

const Component = ({ signUpStates, userInformation }) => {
  const dispatch = useDispatch();
  const [leadId, setLeadId] = useState<number | null>(null);
  const [referralId, setReferralId] = useState<number | null>(null);
  const [formSubmmited, setFormSubmmited] = useState<boolean>(false);
  const { subscriptionState, groupUrl } = useContext(SubscriptionContext);

  const onFormSubmit = ({ values, actions }) => {
    dispatch(signUp({ ...values, lead_id: leadId, referral_id: referralId }));
  };

  useEffect(() => {
    const id = getUrlParamByName("lead_id");
    if (!!id) {
      setLeadId(Number(id));
      setCookie("lead_id", Number(id), 999);
    }

    const referralIdParam = getUrlParamByName("referral_id");
    if (!!referralIdParam) {
      setReferralId(Number(referralIdParam));
      setCookie("referral_id", Number(referralIdParam), 999);
    }

  }, []);

  useEffect(() => {
    if (!!userInformation && signUpStates) {
      ReactPixel.track("CompleteRegistration", {
        content_name: "Expert Sign Up",
        status: "complete",
        value: userInformation.id,
      });
    }
  }, [signUpStates, userInformation]);

  if (!!userInformation) {
    if (subscriptionState === subscriptionStates.INPROGRESS) {
      return <Redirect to={groupUrl} />;
    }

    return <Redirect to="/profile/welcome" />;
  }

  return (
    <SignUp
      usertType="expert"
      onFormSubmit={onFormSubmit}
      formSubmmited={formSubmmited}
      setFormSubmmited={setFormSubmmited}
    />
  );
};

const state = ({ authStore, userStore }) => {
  const { states: signUpStates } = authStore.signup;
  const { data: userInformation } = userStore.information;
  return {
    signUpStates,
    userInformation,
  };
};

export default connect(state)(Component);
