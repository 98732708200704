import { Center } from "../../../../../components/StyledComponents";
import { ModuleInDto } from "../../../../../types/module";
import Image from "../../../../../components/Image";
import Icon from "../../../../../components/Icon";
import { Button, ModuleIcon, ModuleName, ModuleActions, ValidationFlag } from "./styles";
import deleteIcon from "../../../../../assets/icons/trash.svg";
import eyeOnIcon from "../../../../../assets/icons/show.svg";
import eyeOffIcon from "../../../../../assets/icons/hide.svg";

import { useMemo } from "react";
import { useModulesPreview } from "../../../../../providers/ModulesPreviewProvider";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../../store/actions/modal";

interface ComponentProps {
  module: ModuleInDto;
  icon?: string;
}

const Component = ({ module, icon }: ComponentProps) => {
  const {
    selectedModule,
    setSelectedModule,
  } = useModulesPreview();

  const isActiveModule = useMemo(() => {
    return module.id === selectedModule?.id;
  }, [module, selectedModule]);

  return (
    <Center horizontal="center" vertical="center" gap={6}>
      <ModuleIcon isActiveModule={isActiveModule} isReadedModule={module?.readed}>
        {!!module?.icon && (
          <Icon icon={icon || module?.icon} size="20px" color="white" />
        )}
      </ModuleIcon>

      <ModuleName
        isActiveModule={isActiveModule}
        isReadedModule={module?.readed}
        onClick={() =>
          selectedModule?.id !== module.id &&
          setSelectedModule({ ...module })
        }
      >
        {!module.is_valid && <ValidationFlag isValid={module.is_valid} />}
        <span>{module.title || "Módulo sin título"}</span>
      </ModuleName>
    </Center>
  );
};

export default Component;
