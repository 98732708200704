import styled from "styled-components";

export const Container = styled("div")<{ margin?: number }>`
  ${(props) => {
    const { gutter, margin } = props.theme.grid;

    let styles = `
      width: 100%;
      padding-right: ${gutter / 2}px;
      padding-left: ${gutter / 2}px;
      margin-right: auto;
      margin-left: auto;`;

    return styles;
  }}
`;
