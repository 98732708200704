import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import group from "./group";
import user from "./user";
import expert from "./expert";
import auth from "./auth";
import subscription from "./subscription";
import subscriptionAdmin from "./subscription-admin"
import page from "./page";
import modal from "./modal";
import meet from "./meet";
import currency from "./currency";
import carousel from "./carousel";
import preferences from "./preferences";
import discount from "./discount";
import post from "./post";
import category from "./category";
import community from "./community";
import session from "./session";
import survey from "./survey";
import notification from "./notification";
import modules from "./modules";
import plan from './plan'
import reaction from './reaction'
import invitation from './invitation'
import welcomeContent from './welcome-content'

const createRootReducer = (history: any) =>
  combineReducers({
    routerStore: connectRouter(history),
    groupStore: group,
    userStore: user,
    expertStore: expert,
    authStore: auth,
    subscriptionStore: subscription,
    pageStore: page,
    modalStore: modal,
    meetStore: meet,
    carouselStore: carousel,
    currencyStore: currency,
    preferencesStore: preferences,
    discountStore: discount,
    postStore: post,
    categoryStore: category,
    communityStore: community,
    sessionStore: session,
    surveyStore: survey,
    notificationStore: notification,
    modulesStore: modules,
    planStore: plan,
    reactionStore: reaction,
    invitationStore: invitation,
    subscriptionAdminStore: subscriptionAdmin,
    welcomeContentStore: welcomeContent,
  });

export default createRootReducer;
