import * as Yup from "yup";

export const schema = Yup.object().shape({
  message: Yup.string().nullable(),
});

interface FormValues {
  message: string;
}

export const initialValues: FormValues = {
  message: "",
};
