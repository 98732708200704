import { connect, useDispatch } from "react-redux";
import { useTheme } from "styled-components";

import Grid from "../../../../../../../../components/Grid";
import Link from "../../../../../../../../components/Link"
import Button from "../../../../../../../../components/Button"
import Icon from "../../../../../../../../components/Icon";
import Swipe from "../../../../../../../../components/Swipe";

import { useWindowSize } from "../../../../../../../../hooks/useWindowSize";
import { showModal } from "../../../../../../../../store/actions/modal";
import { isOwnSession } from "../../../../../../../../helpers/isOwnSession";
import { Group, SessionInDto, Subscription, User } from "../../../../../../../../types";

import shareIcon from "../../../../../../../../assets/icons/share-semibold.svg";
import notificationIcon from "../../../../../../../../assets/sessions/notification-icon.svg";
import pencilIcon from "../../../../../../../../assets/icons/post-edit-icon.svg";
import userAvatar from "../../../../../../../../assets/avatar.svg";
import dotsVerticalIcon from "../../../../../../../../assets/icons/more-vertical.svg";

import laptopIcon from "../../../../../../../../assets/icons/laptop-semibold.svg";
import trashIcon from "../../../../../../../../assets/icons/trash.svg";
import calendarIcon from "../../../../../../../../assets/icons/calendar-semibold.svg";
import clockIcon from "../../../../../../../../assets/icons/clock-semibold.svg";
import videoRecorderIcon from "../../../../../../../../assets/icons/video-recorder.svg";
import noVideoRecorderIcon from "../../../../../../../../assets/sessions/no-recorded-session.svg";
import unlockIcon from "../../../../../../../../assets/icons/unlock-semibold.svg";
import locationIcon from "../../../../../../../../assets/icons/location-semibold.svg";
import arroFowardUpRight from '../../../../../../../../assets/icons/arrow_forward_up_right-semibold.svg'
import warningIcon from '../../../../../../../../assets/icons/warning-semibold.svg'
import CopyToClipboad from "../../../../../../../../components/CopyToClipboad";

import { Center } from "../../../../styles";
import {
  Cover, OwnerActions, Actions, EditCta, Reminder, Avatar, BigText, SmallText, Description, Title, SessionDetail, CleanLink
  , MenuCta, SwipeContent, Line, Sonar
} from './styles'
import { useMemo } from "react";
import { useMarkdown } from "../../../../../../../../hooks/useMarkdown";
import { RemirrorRenderer } from "@remirror/react";
import { dateSpliter, sessionExpired, sessionInProgress, isToday, isTomorrow, convertFloatHourToMinutes } from "../../../../../../../../helpers/dates-hanlder";
import { isSubscribed } from "../../../../../../../../helpers/isSubscribed";
import { getFreeSubscriptions } from "../../../../../../../../helpers/getFreeSubscriptions";
import NoSubscribed from "../../../NoSubscribed";
import { getRandomBg } from "../../../../../../../../helpers/randomBackground";
import MPBlockedSection from "../../../../../../../Group/components/MPBlockedSection";

interface ComponentProps {
  session: SessionInDto
  user: User
  group: Group
  userSubscriptions: Subscription[]
  isLoggedIn
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function sumarMinutosAFecha(fecha, minutos) {
  // Clonar la fecha para evitar modificar la original
  let nuevaFecha = new Date(fecha);

  // Sumar los minutos a la fecha
  nuevaFecha.setMinutes(nuevaFecha.getMinutes() + minutos);

  return nuevaFecha;
}

const Component = ({ session, user, group, userSubscriptions, isLoggedIn }: ComponentProps) => {
  const theme = useTheme() as { colors: { [key: string]: string } }
  const { isMobile } = useWindowSize()
  const dispatch = useDispatch()
  const { typeMap, markMap, jsonStr, textStr } = useMarkdown({
    brief: session?.description,
  });

  const expert = useMemo(() => {
    const useExpertInformation = !!session?.use_profile_info

    if (useExpertInformation) {
      return {
        name: group?.creator_name,
        avatar: session?.group?.expert?.profile_pic?.url
      }
    } else {
      return {
        name: session?.speaker_name,
        avatar: session?.speaker_profile_pic?.url || userAvatar
      }
    }
  }, [session, user, group])

  const date = useMemo(() => {
    return dateSpliter(session?.session_datetime)
  }, [session])

  const strDate = useMemo(() => {
    if (isToday(session?.session_datetime))
      return "Hoy"

    if (isTomorrow(session?.session_datetime))
      return "Mañana"

    return ""
  }, [session])


  const durationInMinutes = useMemo(() => {
    return session?.duration_type === "hour" ? convertFloatHourToMinutes(session?.duration) : Math.round(session?.duration)
  }, [session])

  const startHour = useMemo(() => {
    const date = dateSpliter(session?.session_datetime);
    return `${date.hours}:${date.minutes}hs`
  }, [session])

  const endHour = useMemo(() => {
    const date = dateSpliter(sumarMinutosAFecha(session?.session_datetime, durationInMinutes))
    return `${date.hours}:${date.minutes}hs`
  }, [session, durationInMinutes])

  const isExpiredSession = useMemo(() => {
    return sessionExpired(session?.session_datetime, durationInMinutes)
  }, [session, durationInMinutes])

  const isExpiredSessionWithMargin = useMemo(() => {
    const marginMinutes = 30;
    return sessionExpired(session?.session_datetime, durationInMinutes + marginMinutes)
  }, [session, durationInMinutes])

  const timeElapsedSinceExpiration = useMemo(() => {
    // Fecha inicial
    const fechaInicial = new Date(session?.session_datetime);
    fechaInicial.setMinutes(fechaInicial.getMinutes() + durationInMinutes);


    // Fecha actual
    const fechaActual: Date = new Date();

    // Convertir ambas fechas a milisegundos
    const milisegundosInicial: number = fechaInicial.getTime();
    const milisegundosActual: number = fechaActual.getTime();

    // Calcular la diferencia en milisegundos
    const diferenciaMilisegundos: number = milisegundosActual - milisegundosInicial;

    // Calcular minutos
    const minutos: number = Math.floor(diferenciaMilisegundos / (1000 * 60));
    // Calcular horas
    const horas: number = Math.floor(minutos / 60);
    // Calcular días
    const dias: number = Math.floor(horas / 24);

    if (dias === 0) {
      if (horas >= 1) {
        return `${horas} ${horas > 1 || horas === 0 ? "horas" : "hora"}`
      } else {
        return `${minutos} ${minutos > 1 || minutos === 0 ? "minutos" : "minuto"}`
      }
    } else {
      return `${dias} ${dias > 1 || dias === 0 ? "días" : "día"}`
    }

    // Convertir la diferencia de milisegundos a días
    // const diasTranscurridos: number = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));


    // console.log(`Han pasado/faltan ${diasTranscurridos} días.`);
    // return `${diasTranscurridos} ${diasTranscurridos > 1 ? "días" : "día"}`
  }, [session, durationInMinutes])

  const isInProgressSession = useMemo(() => {
    const minutes = session?.duration_type === "hour" ? convertFloatHourToMinutes(session?.duration) : session?.duration
    return sessionInProgress(session?.session_datetime, minutes)
  }, [session])

  const sessionWillBeRecorded = useMemo(() => {
    return session?.will_be_recorded
  }, [session])

  const sessionRecordingUrl = useMemo(() => {
    return session?.recorded_session_url
  }, [])

  const notifySession = useMemo(() => {
    return session?.notificate_by_email || session?.notificate_by_chat
  }, [session])


  const chatGroup = useMemo(() => {
    return session?.group?.telegram_groups.length > 0 ? session?.group?.telegram_groups[0] : null
  }, [session])

  const notificationText = useMemo(() => {
    return (session?.notificate_by_email || session?.notificate_by_chat) && `Recordatorios automáticos activados`
  }, [session, chatGroup])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(userSubscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [userSubscriptions, group, isLoggedIn])

  const isMpInactiveSubscription = useMemo(() => {
    const groupSubscription = userSubscriptions?.find(subscription => subscription?.group?.id === session?.group?.id)
    const mpInactiveSubscriptionStates = ['retrying', 'not_paid']
    return groupSubscription?.status && mpInactiveSubscriptionStates.includes(groupSubscription?.status)
  }, [userSubscriptions, session])

  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col offset={{ md: 1 }} md={10}>

          <div style={{ height: 19 }} />

          {/* {session?.cover_pic?.url && ( */}
          <Cover onContextMenu={e => e.preventDefault()} src={session?.cover_pic?.url || getRandomBg()} isExpiredSession={isExpiredSession}>
            {isExpiredSession && (<BigText>Este encuentro finalizó hace aprox. {timeElapsedSinceExpiration}</BigText>)}
            {isExpiredSession && sessionWillBeRecorded && (<SmallText>Proximamente la grabación estará disponbile</SmallText>)}
          </Cover>
          {/* )} */}

          <div style={{ height: 22 }} />

          {isOwnSession(session, user?.expert?.id) &&
            <>
              <OwnerActions isExpiredSession={isExpiredSession} sessionWillBeRecorded={sessionWillBeRecorded} >
                <Center horizontal="left" vertical="center" gap={8}>
                  {isExpiredSession && sessionWillBeRecorded && !sessionRecordingUrl ? <><Icon icon={warningIcon} size="24px" color="#C0382C" />Subir grabación pediente</> : "Eres anfitrion del encuentro"}
                </Center>

                <Actions>
                  <EditCta
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 6,
                      textDecoration: 'none'
                    }}
                    href={`/dashboard/${session?.group?.group_type === "free_experience"
                      ? "community/events"
                      : session?.group?.group_type || "experience"
                      }/${session?.group?.id}/sessions/${session?.id}${isExpiredSession && !sessionRecordingUrl && sessionWillBeRecorded ? "?scrollTo=recording_section" : ""}`}
                  >
                    {isExpiredSession && !sessionRecordingUrl && sessionWillBeRecorded ? "Subir" : "Editar"}
                    <Icon icon={arroFowardUpRight} size="12px" color="#FFFFFF" />
                  </EditCta>

                  <MenuCta
                    type="button"
                    style={{ display: "flex" }}
                    onClick={() =>
                      dispatch(showModal("swipe-session-preview-owner-menu"))
                    }
                  >
                    <Icon icon={dotsVerticalIcon} size="24px" color={theme.colors.Primary} />
                  </MenuCta>
                </Actions>
              </OwnerActions>

              {notifySession && !isExpiredSession && (
                <>
                  <div style={{ height: 10 }} />

                  <Reminder>
                    <Icon icon={notificationIcon} size="18px" color={theme.colors.Black} />
                    {notificationText}
                  </Reminder>
                </>
              )}

              <div style={{ height: 32 }} />
            </>
          }

          <Title>{session?.title}</Title>

          <div style={{ height: 24 }} />

          <SessionDetail horizontal="left" vertical="center" gap={33}>
            <Icon icon={calendarIcon} size="24px" color={theme.colors.Primary} />
            <Center horizontal="left" vertical="top" column>
              <SmallText>Día del encuentro</SmallText>
              <BigText>{strDate !== "" ? strDate : capitalizeFirstLetter(date?.day?.name?.large)}, {date?.day?.number} de {date?.month?.name?.large}</BigText>
            </Center>
          </SessionDetail>

          <div style={{ height: 16 }} />

          <SessionDetail horizontal="left" vertical="center" gap={33}>
            <Icon icon={clockIcon} size="24px" color={theme.colors.Primary} />
            <Center horizontal="left" vertical="top" column>
              <SmallText>Horario</SmallText>
              <BigText>{startHour} - {endHour}</BigText>
            </Center>
          </SessionDetail>

          <div style={{ height: 30 }} />

          {(isOwnSession(session, user?.expert?.id) || isUserSubscribed) && !isMpInactiveSubscription ? (
            <>
              {!isExpiredSessionWithMargin ? (
                <>
                  <SessionDetail horizontal="left" vertical="top" gap={19} column highlighted>
                    {/* <Center horizontal="left" vertical="center" gap={10}>
                      <Icon icon={calendarIcon} size="24px" color={theme.colors.Primary} />
                      <BigText>Ingresa a la sala aqui</BigText>
                    </Center> */}
                    {isInProgressSession && (
                      <Center horizontal="left" vertical="center" gap={10}>
                        <Sonar />
                        <BigText>¡En curso!</BigText>
                      </Center>
                    )}


                    <Link
                      style={{ minWidth: 175 }}
                      target="_blank"
                      disabled={isExpiredSessionWithMargin}
                      options={{
                        type: "filled",
                        skin: "purple",
                        size: "lg",
                        block: true,
                        native: false
                      }}
                      href={session?.join_url}>
                      Ingresar
                    </Link>

                    {!!session?.access_code && session?.access_code !== "" && (
                      <Center horizontal="left" vertical="center" gap={10}>
                        <Icon icon={unlockIcon} size="24px" color={theme.colors.Primary} />
                        <SmallText>Código de acceso</SmallText>
                        <BigText>
                          <CopyToClipboad
                            copy={session?.access_code}
                            showChildren
                            showCopyIcon={true}
                            iconColor={"#007bff"}
                            successStyle={{
                              position: "absolute",
                              left: "100%",
                              top: "50%",
                              color: "green",
                              transform: "translate(0%, -50%)",
                            }}
                          >
                            <BigText style={{ color: isExpiredSessionWithMargin ? "#C2C4C5" : "inherit" }}>{session?.access_code}</BigText>
                          </CopyToClipboad></BigText>
                      </Center>
                    )}
                  </SessionDetail>
                </>
              ) : (
                <Line />
              )}

              <div style={{ height: 30 }} />

              <Center horizontal="center" vertical="center">
                <Avatar
                  src={expert.avatar ? expert.avatar : userAvatar}
                  alt={expert.name}
                  width="50px"
                  height="50px"
                />

                <Center horizontal="center" vertical="top" column gap={2}>
                  <span style={{ fontSize: 14 }}>Organiza el encuentro</span>
                  <BigText>{expert.name}</BigText>
                </Center>
              </Center>

              <div style={{ height: 26 }} />

              {!isExpiredSession && (
                <>
                  <SessionDetail horizontal="left" vertical="center" gap={15} style={{ padding: 0 }}>
                    <Icon icon={locationIcon} size="24px" color={theme.colors.Primary} />
                    <Center horizontal="left" vertical="top" column>
                      <SmallText>Link de acceso</SmallText>
                      {!!session?.join_url ? (
                        <CleanLink href={session?.join_url} target="_blank"><BigText style={{ color: isExpiredSession ? "#C2C4C5" : "inherit" }}>{session?.join_url}</BigText><Icon icon={arroFowardUpRight} size="18px" color="#B1C6E4" /></CleanLink>
                      ) : (
                        <BigText style={{ color: isExpiredSession ? "#C2C4C5" : "inherit" }}>Aún no se ha subido el link para unirse al encuentro.</BigText>
                      )}
                    </Center>
                  </SessionDetail>

                  <div style={{ height: 14 }} />
                </>
              )}


              <SessionDetail horizontal="left" vertical="center" gap={15} style={{ padding: 0 }}>
                <Icon icon={sessionWillBeRecorded ? videoRecorderIcon : noVideoRecorderIcon} size="24px" color={theme.colors.Primary} />
                <Center horizontal="left" vertical="top" column>
                  <SmallText>Grabación</SmallText>
                  <BigText>{sessionWillBeRecorded ? "Se subirá la grabación" : "No quedará grabado"}</BigText>
                </Center>
              </SessionDetail>

              <div style={{ height: 28 }} />

              <Center horizontal="left" vertical="top" column>
                <Center horizontal="left" vertical="center" gap={15}>
                  <Icon icon={laptopIcon} size="24px" color={theme.colors.Primary} />
                  <BigText>Acerca del encuentro</BigText>
                </Center>

                <div style={{ height: 14 }} />

                <Description>
                  {!jsonStr && !!textStr && textStr}

                  {!!jsonStr && (
                    <RemirrorRenderer
                      json={jsonStr}
                      typeMap={typeMap}
                      markMap={markMap}
                    />
                  )}

                  {!jsonStr && !textStr && ""}
                </Description>
              </Center>

              <div style={{ height: 35 }} />
            </>
          ) : (
            <>
              {isMpInactiveSubscription ? (
                <MPBlockedSection />
              ) : (
                <NoSubscribed />
              )}
            </>
          )}

          {isOwnSession(session, user?.expert?.id) && (
            <Swipe name="swipe-session-preview-owner-menu">
              <SwipeContent>
                <Button
                  options={{
                    type: "link",
                    size: "lg",
                    skin: "purple",
                    block: true,
                  }}
                  style={{ height: "40px", gap: 10, textDecoration: "none" }}
                  onClick={() => {
                    const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.id}/s/${session?.id}`;
                    navigator.clipboard.writeText(link);
                    dispatch(showModal('modal-share-session-link'));
                  }}
                >
                  <Icon icon={shareIcon} size="18px" color={theme.colors.Primary} />
                  Compartir
                </Button>

                {isExpiredSession && !sessionRecordingUrl && sessionWillBeRecorded && (
                  <Link
                    options={{
                      type: "link",
                      size: "lg",
                      skin: "purple",
                      block: true
                    }}
                    style={{ height: "40px", gap: 10, textDecoration: "none" }}
                    href={`/dashboard/${session?.group?.group_type === "free_experience"
                      ? "community/events"
                      : session?.group?.group_type || "experience"
                      }/${session?.group?.id}/sessions/${session?.id}`}
                  >
                    <Icon icon={pencilIcon} size="18px" color={theme.colors.Primary} />
                    Editar
                  </Link>
                )}

                <Button
                  options={{
                    type: "link",
                    size: "lg",
                    skin: "purple",
                    block: true,
                  }}
                  style={{ height: "40px", gap: 10, textDecoration: "none" }}
                  onClick={() => {
                    dispatch(showModal("modal-delete-session"));
                  }}
                >
                  <Icon icon={trashIcon} size="18px" color={theme.colors.Primary} />
                  Eliminar
                </Button>
              </SwipeContent>
            </Swipe>
          )}
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  )
}

const state = ({ userStore, sessionStore, groupStore }) => {
  const { isLoggedIn } = userStore;
  const { data: user, states: userStates } =
    userStore.information;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: userSubscriptions, states: userSubscriptionsStates } =
    userStore.subscriptions;
  const { data: session, states: sessionStates } = sessionStore.one;


  return {
    user,
    userStates,
    userSubscriptions,
    userSubscriptionsStates,
    session,
    sessionStates,
    group,
    groupStates,
    isLoggedIn,
  };
};

export default connect(state)(Component);