import { GetPostsOutDto, Post } from "../../types/post";
import {
  NEW_POST,
  NewPost,
  ResetNewPost,
  RESET_NEW_POST,
} from "../types/post/new-post";
import { GET_POSTS, GetPosts, RESET_GET_POSTS, ResetGetPosts } from "../types/post/get-posts";
import {
  GET_POST,
  RESET_GET_POST,
  ResetGetPost,
  GetPost,
} from "../types/post/get-post";

import {
  EDIT_POST,
  EditPost,
  ResetEditPost,
  RESET_EDIT_POST,
} from "../types/post/edit-post";
import {
  DELETE_POST,
  DeletePost,
  RESET_DELETE_POST,
  ResetDeletePost,
} from "../types/post/delete-post";
import {
  SharePostInTelegram,
  SHARE_POST_IN_TELEGRAM,
  RESET_SHARE_POST_IN_TELEGRAM,
  ResetSharePostInTelegram,
} from "../types/post/share-post";

import {
  SetFeaturedPost,
  SET_FEATURED_POST,
  ResetSetFeaturedPost,
  RESET_SET_FEATURED_POST
} from "../types/post/set-featured-post";

import {
  GetFeaturedPosts,
  GET_FEATURED_POSTS,
  ResetGetFeaturedPosts,
  RESET_GET_FEATURED_POSTS,
} from "../types/post/get-featured-posts";

import { DeleteFile, DELETE_FILE } from "../types/post/delete-file";
import { ResetGetScheduledPosts, RESET_GET_SCHEDULED_POSTS, GetScheduledPosts, GET_SCHEDULED_POSTS } from "../types/post/get-scheduled-posts";
import {
  GET_LAST_POSTS,
  GetLastPosts,
  ResetGetLastPosts,
  RESET_GET_LAST_POSTS
} from "../types/post/get-last-posts";

import { REORDER_FEATURED_POSTS } from "../types/post/reorder-featured-posts";

export const newPost = (payload: Post): NewPost => {
  return { type: NEW_POST, payload };
};

export const resetNewPost = (): ResetNewPost => {
  return { type: RESET_NEW_POST, payload: null };
};

export const getPosts = (payload: GetPostsOutDto): GetPosts => {
  return { type: GET_POSTS, payload };
};

export const resetGetPosts = (): ResetGetPosts => {
  return { type: RESET_GET_POSTS, payload: null };
};

export const getPost = (payload: { idPost: number }): GetPost => {
  return { type: GET_POST, payload };
};

export const resetGetPost = (): ResetGetPost => {
  return { type: RESET_GET_POST, payload: null };
};

export const getLastPosts = (payload: GetPostsOutDto): GetLastPosts => {
  return { type: GET_LAST_POSTS, payload };
};

export const resetGetLastPosts = (): ResetGetLastPosts => {
  return { type: RESET_GET_LAST_POSTS, payload: null };
};


export const editPost = (payload: any): EditPost => {
  return { type: EDIT_POST, payload };
};

export const resetEditPost = (): ResetEditPost => {
  return { type: RESET_EDIT_POST, payload: null };
};

export const deletePost = (payload): DeletePost => {
  return { type: DELETE_POST, payload };
};

export const resetDeletePost = (): ResetDeletePost => {
  return { type: RESET_DELETE_POST, payload: null };
};

export const deleteFile = (payload: { idFile: number }): DeleteFile => {
  return { type: DELETE_FILE, payload };
};

export const sharePostInTelegram = (payload: {
  id: number;
}): SharePostInTelegram => {
  return { type: SHARE_POST_IN_TELEGRAM, payload };
};

export const resetSharePostInTelegram = (): ResetSharePostInTelegram => {
  return { type: RESET_SHARE_POST_IN_TELEGRAM, payload: null };
};

export const setFeaturedPost = (payload): SetFeaturedPost => {
  return { type: SET_FEATURED_POST, payload };
};


export const resetSetFeaturedPost = (): ResetSetFeaturedPost => {
  return { type: RESET_SET_FEATURED_POST, payload: null };
};

export const getFeaturedPosts = (payload: GetPostsOutDto): GetFeaturedPosts => {
  return { type: GET_FEATURED_POSTS, payload };
};

export const resetGetFeaturedPosts = (): ResetGetFeaturedPosts => {
  return { type: RESET_GET_FEATURED_POSTS, payload: null };
};


export const getScheduledPosts = (payload: GetPostsOutDto): GetScheduledPosts => {
  return { type: GET_SCHEDULED_POSTS, payload };
};

export const resetGetScheduledPosts = (): ResetGetScheduledPosts => {
  return { type: RESET_GET_SCHEDULED_POSTS, payload: null };
};



export const reorderFeaturedPosts = (payload) => {
  return { type: REORDER_FEATURED_POSTS, payload };
}