import { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import Spinner from "../../../../components/Loader";
import Link from "../../../../components/Link";
import Icon from "../../../../components/Icon";
import {
  Modal,
  ModalDialog,
  Title,
  SubTitle,
  Text,
  RedirectMsg,
  Button,
  CurrentStep,
  InLineIcon,
  Body,
  Card,
} from "./styles";
import { Center } from "../../../../components/StyledComponents";
import { newTelegramGroup } from "../../../../store/actions/group";
import { hideModal } from "../../../../store/actions/modal";
import telegramIcon from "../../../../assets/icons/telegram.svg";
import whatsappIcon from "../../../../assets/icons/whatsapp.svg";

interface ComponentProps {
  groupInformation;
  newTelegramGroupInformation;
  newTelegramGroupStates;
}

const availablePlatforms: { [type: string]: ("whatsapp" | "telegram")[] } = {
  broadcast: ["whatsapp", "telegram"],
  broadcast_with_comments: ["telegram"],
  group_chat: ["whatsapp", "telegram"],
};

const typesLabels = {
  broadcast: "grupo de difusión",
  broadcast_with_comments: "grupo de difusión con comentarios",
  group_chat: "grupo de chat",
};

const Component = ({
  groupInformation,
  newTelegramGroupInformation,
  newTelegramGroupStates,
}: ComponentProps) => {
  const [type, setType] = useState<
    "broadcast" | "broadcast_with_comments" | "group_chat"
  >("broadcast");
  const [platform, setPlatform] = useState<"whatsapp" | "telegram">();
  const { isMobile } = useWindowSize();
  const [index, setIndex] = useState(0);
  const totalSlides = 3;
  const dispatch = useDispatch();

  const handleNextButtonPressed = () => {
    if (index === totalSlides - 1) {
      return;
    } else {
      setIndex(index + 1);
    }
  };

  const handlePrevButtonPressed = () => {
    setIndex(index - 1);
  };

  const createTelegramGroup = () => {
    if (platform && type) {
      dispatch(
        newTelegramGroup({ group_id: groupInformation.id, type, platform })
      );
    }
  };

  useEffect(() => {
    if (newTelegramGroupStates.success) {
      handleNextButtonPressed();
    }
  }, [newTelegramGroupStates]);

  useEffect(() => {
    setPlatform(availablePlatforms[type][0]);
  }, [type]);


  useEffect(() => {
    if (groupInformation) {
      setType(groupInformation?.recorded ? 'group_chat' : 'broadcast')
    }
  }, [groupInformation])

  return (
    <Modal name="modal-telegram" width="750">
      <ModalDialog>
        <CarouselProvider
          currentSlide={index}
          naturalSlideWidth={305}
          naturalSlideHeight={146}
          totalSlides={totalSlides}
          isIntrinsicHeight={true}
          dragEnabled={false}
          touchEnabled={false}
          infinite={false}
        >
          <Body>
            <Slider>
              <Slide index={0}>
                <Title>
                  <CurrentStep>
                    👉 Definí la dinámica que mejor se adapte a tu taller
                  </CurrentStep>
                </Title>

                <div style={{ height: 20 }} />

                <Text>
                  Tus suscriptores utilizarán este medio para enterarse de todas
                  las novedades, interactuar contigo, acceder a los streamings,
                  y mucho más. Este grupo estará totalmente sincronizado con tu
                  taller en Klouser.
                </Text>

                <div style={{ height: 40 }} />

                <Center
                  horizontal="center"
                  vertical="center"
                  gap={20}
                  column={isMobile}
                >
                  {!groupInformation?.recorded && (
                    <>
                      <Card
                        onClick={() => setType("broadcast")}
                        className={`${type === "broadcast" ? "active" : ""}`}
                      >
                        <SubTitle>Grupo de difusión</SubTitle>
                      </Card>

                      <Card
                        onClick={() => setType("broadcast_with_comments")}
                        className={`${type === "broadcast_with_comments" ? "active" : ""
                          }`}
                      >
                        <SubTitle>Grupo de difusión con comentarios</SubTitle>
                      </Card>
                    </>
                  )}

                  <Card
                    onClick={() => setType("group_chat")}
                    className={`${type === "group_chat" ? "active" : ""}`}
                  >
                    <SubTitle>Grupo de chat</SubTitle>
                  </Card>
                </Center>

                <div style={{ height: 40 }} />

                <Text>
                  {type === "broadcast" && (
                    <>
                      En esta modalidad solo tú podrás escribir. Los inscriptos
                      solo podrán leer lo que tu publiques. Esta opción esta
                      disponible para{" "}
                      <InLineIcon>
                        <Icon icon={whatsappIcon} color="#25D366" size="20px" />
                      </InLineIcon>{" "}
                      y{" "}
                      <InLineIcon>
                        <Icon icon={telegramIcon} color="#229ED9" size="20px" />
                      </InLineIcon>
                    </>
                  )}
                  {type === "broadcast_with_comments" && (
                    <>
                      En esta modalidad solo tú podrás escribir. Los inscriptos
                      podrán hacer comentarios en tus publicaciones. Esta opción
                      esta disponible solo en{" "}
                      <InLineIcon>
                        <Icon icon={telegramIcon} color="#229ED9" size="20px" />
                      </InLineIcon>
                    </>
                  )}
                  {type === "group_chat" && (
                    <>
                      En esta modalidad tú y todos los inscriptos podrán
                      escribir libremente. Esta opción esta disponible para{" "}
                      <InLineIcon>
                        <Icon icon={whatsappIcon} color="#25D366" size="20px" />
                      </InLineIcon>{" "}
                      y{" "}
                      <InLineIcon>
                        <Icon icon={telegramIcon} color="#229ED9" size="20px" />
                      </InLineIcon>
                    </>
                  )}
                </Text>

                <div style={{ height: 40 }} />

                <Center
                  horizontal={isMobile ? "space" : "center"}
                  vertical="center"
                  gap={isMobile ? 0 : 20}
                >
                  <Button
                    options={{
                      type: "outline",
                      skin: "purple",
                      size: "lg",
                    }}
                    onClick={() => dispatch(hideModal())}
                  >
                    Cancelar
                  </Button>

                  <Button
                    options={{
                      type: "filled",
                      skin: "purple",
                      size: "lg",
                    }}
                    onClick={handleNextButtonPressed}
                  >
                    Continuar
                  </Button>
                </Center>
              </Slide>

              <Slide index={1}>
                <Title>
                  <CurrentStep>
                    👉 Selecciona tu plataforma de preferencia.
                  </CurrentStep>
                </Title>

                <div style={{ height: 20 }} />

                <Text>
                  ¡Bien hecho! <br /> Ahora selecciona en que plataforma
                  prefieres crear tu {typesLabels[type]}
                </Text>

                <div style={{ height: 40 }} />

                <Center
                  horizontal="center"
                  vertical="center"
                  gap={20}
                  column={isMobile}
                >
                  {availablePlatforms[type].includes("whatsapp") && (
                    <Card
                      onClick={() => setPlatform("whatsapp")}
                      className={`${platform === "whatsapp" ? "active" : ""}`}
                    >
                      <Center
                        horizontal="center"
                        vertical="center"
                        column
                        gap={8}
                      >
                        <Icon icon={whatsappIcon} color="#25D366" size="38px" />
                        <SubTitle>Whatsapp</SubTitle>
                      </Center>
                    </Card>
                  )}

                  {availablePlatforms[type].includes("telegram") && (
                    <Card
                      onClick={() => setPlatform("telegram")}
                      className={`${platform === "telegram" ? "active" : ""}`}
                    >
                      <Center
                        horizontal="center"
                        vertical="center"
                        column
                        gap={8}
                      >
                        <Icon icon={telegramIcon} color="#229ED9" size="38px" />
                        <SubTitle>Telegram</SubTitle>
                      </Center>
                    </Card>
                  )}
                </Center>

                <div style={{ height: 40 }} />

                <Center
                  horizontal={isMobile ? "space" : "center"}
                  vertical="center"
                  gap={isMobile ? 0 : 20}
                >
                  {newTelegramGroupStates.loading ||
                    newTelegramGroupStates.success ? (
                    <Center
                      horizontal="center"
                      vertical="center"
                      column
                      gap={16}
                    >
                      <Text>
                        Estamos creando tu canal de{" "}
                        {platform === "whatsapp" ? "Whatsapp" : "Telegram"}. No
                        deberia tomar más de 1 minuto
                      </Text>
                      <Spinner color={"rgba(3,155,229,1)"} />
                    </Center>
                  ) : (
                    <Center
                      horizontal={isMobile ? "space" : "center"}
                      vertical="center"
                      gap={isMobile ? 0 : 20}
                    >
                      <Button
                        options={{
                          type: "outline",
                          skin: "purple",
                          size: "lg",
                        }}
                        onClick={handlePrevButtonPressed}
                      >
                        Volver
                      </Button>
                      <Button
                        onClick={createTelegramGroup}
                        disabled={newTelegramGroupStates.loading}
                        options={{
                          type: "filled",
                          skin: "purple",
                          size: "lg",
                        }}
                      >
                        Crear {!isMobile && "y configurar mi canal de chat"}
                      </Button>
                    </Center>
                  )}
                </Center>
              </Slide>

              <Slide index={2}>
                <Title>
                  <CurrentStep>
                    👉 Ingresar a tu {typesLabels[type]} en{" "}
                    {platform === "whatsapp" ? "Whatsapp" : "Telegram"}
                  </CurrentStep>
                </Title>

                <div style={{ height: 20 }} />

                <Text>
                  Tu {typesLabels[type]} ya se encuentra disponible, ingresa a{" "}
                  {platform === "whatsapp" ? "Whatsapp" : "Telegram"}
                  <br />
                  <br />
                  Si aún no tienes la aplicacion de{" "}
                  {platform === "whatsapp"
                    ? "Whatsapp"
                    : "Telegram"} instalada,{" "}
                  <Link
                    href={
                      platform === "whatsapp"
                        ? "https://www.whatsapp.com/download"
                        : "https://onelink.to/84gpfk"
                    }
                    target="_blank"
                    options={{
                      type: "link",
                      size: "sm",
                      skin: "purple",
                      native: false,
                    }}
                  >
                    descargala
                  </Link>{" "}
                  y crea tu cuenta antes de continuar.
                </Text>

                <div style={{ height: 40 }} />

                <Center horizontal="center" vertical="center" column gap={20}>
                  <Link
                    href={
                      !!groupInformation?.telegram_groups
                        ? groupInformation?.telegram_groups[0]
                          ?.main_invitation_link
                        : newTelegramGroupInformation?.tg_bot_url
                    }
                    options={{
                      skin: "lightBlue",
                      size: "lg",
                      type: "filled",
                      native: false,
                    }}
                    target="_blank"
                  >
                    Ingresar al {typesLabels[type]}
                  </Link>

                  <RedirectMsg>
                    Solo serás re-dirigido si tienes <br /> la app{" "}
                    {platform === "whatsapp" ? "Whatsapp" : "Telegram"}{" "}
                    instalada
                  </RedirectMsg>
                </Center>
              </Slide>
            </Slider>
          </Body>
        </CarouselProvider>
      </ModalDialog>
    </Modal>
  );
};

const state = ({ groupStore }) => {
  const { group, newTelegramGroup } = groupStore;
  const { data: groupInformation } = group;
  const { data: newTelegramGroupInformation, states: newTelegramGroupStates } =
    newTelegramGroup;

  return {
    groupInformation,
    newTelegramGroupInformation,
    newTelegramGroupStates,
  };
};

export default connect(state)(Component);
