import { SurveyOutDto } from "../../../types/survey.dto";

export const UPDATE_ONE_SURVEY = "UPDATE_ONE_SURVEY";
export const UPDATE_ONE_SURVEY_SUCCESS = "UPDATE_ONE_SURVEY_SUCCESS";
export const UPDATE_ONE_SURVEY_ERROR = "UPDATE_ONE_SURVEY_ERROR";
export const RESET_UPDATE_ONE_SURVEY = "RESET_UPDATE_ONE_SURVEY";

export interface UpdateOneSurvey {
  type: typeof UPDATE_ONE_SURVEY;
  payload: SurveyOutDto;
}

export interface UpdateOneSurveySuccess {
  type: typeof UPDATE_ONE_SURVEY_SUCCESS;
  payload: any;
}

export interface UpdateOneSurveyError {
  type: typeof UPDATE_ONE_SURVEY_ERROR;
  payload: string | boolean;
}

export interface ResetUpdateOneSurvey {
  type: typeof RESET_UPDATE_ONE_SURVEY;
  payload: null;
}

export interface State {
  data: any | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | UpdateOneSurvey
  | UpdateOneSurveySuccess
  | UpdateOneSurveyError
  | ResetUpdateOneSurvey;
