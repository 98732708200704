import RcTable from "rc-table";
import { ColumnsType, TableComponents } from "rc-table/lib/interface";
import constants from "../../../../../../constants";

import { ReactNode } from "react";
import { Table, Head, Body, Footer, Row, Td, Th } from "./styles";
import {
  dateSpliter,
  getUtcDate,
} from "../../../../../../helpers/dates-hanlder";
import moment from "moment";

const components = {
  table: Table,
  header: {
    wrapper: Head,
    row: Row,
    cell: Th,
  },
  body: {
    wrapper: Body,
    row: Row,
    cell: Td,
  },
};

interface ComponentProps {
  columns;
  data;
  pagination;
  states: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  columns,
  data,
  pagination,
  states,
}: ComponentProps): JSX.Element => {
  return data && data.length > 0 ? (
    <RcTable
      columns={columns}
      data={data}
      components={components}
      emptyText="Aun no cuentas con miembros en tu comunidad"
    />
  ) : (
    <div>Aun no cuentas con miembros en tu comunidad</div>
  );
};

export default Component;
