import styled from "styled-components";

export const Duration = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const DurationNumber = styled.div`
  width: 30%;
  margin-right: 16px;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    width: 20%;
  }
`;

export const DurationUnit = styled.div`
  width: 70%;

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) {
    width: 28%;
  }
`;
