import { Group } from "../../../types";

export const GET_EXPERT_GROUPS = "GET_EXPERT_GROUPS";
export const GET_EXPERT_GROUPS_SUCCESS = "GET_EXPERT_GROUPS_SUCCESS";
export const GET_EXPERT_GROUPS_ERROR = "GET_EXPERT_GROUPS_ERROR";

export interface GetExpertGroups {
  type: typeof GET_EXPERT_GROUPS;
  payload: string;
}

export interface GetExpertGroupsSuccess {
  type: typeof GET_EXPERT_GROUPS_SUCCESS;
  payload: Group[];
}

export interface GetExpertGroupsError {
  type: typeof GET_EXPERT_GROUPS_ERROR;
  payload: boolean | string;
}

export interface State {
  data: Group[] | null;
  states: {
    loading: boolean;
    success: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    loading: false,
    success: false,
    error: false,
  },
};

export type Actions =
  | GetExpertGroups
  | GetExpertGroupsSuccess
  | GetExpertGroupsError;
