import styled from "styled-components";
import Dropdown from "../../../../Dropdown";
import Image from "../../../../Image";
import { rainbowBackgroundGradient } from "../../../../../components/StyledComponents";

export const Header = styled.header`
  position: relative;
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 7px 7px;
  border-bottom: 1px solid #CDCDCD;
  background-color: white;
`;

export const VerticalSeparator = styled("span") <{ margin?; visible?}>`
  width: 1px;
  height: 34px;
  margin: 0px ${(props) => (props.margin ? props.margin / 2 : `0`)}px;
  background-color: ${(props) => (props.visible ? `#ADADAD` : `transparent`)};
`;

export const HorizontalSeparator = styled("span") <{
  margin?;
  visible?;
  isSeparator?;
}>`
  height: 1px;
  width: 100%;
  background-color: ${(props) => (props.visible ? `#ADADAD` : `transparent`)};
`;

export const Img = styled(Image)`
  &.logo-desktop {
    display: none;
    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      display: block;
    }
  }

  &.logo-mobile {
    display: block;
    @media (min-width: ${(props) => props.theme.mediaBreakpoints.smMin}) {
      display: none;
    }
  }
`;
export const CustomDropdown = styled(Dropdown)``;

export const Toggle = styled("div") <{ skin }>`
  height: 41px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Back = styled("a")`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: #2D3436;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
`;

export const Type = styled('h3')`
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
`