import styled from "styled-components";
import errorIcon from "../../assets/icons/alert-circle.svg";
import successIcon from "../../assets/icons/checkmark-circle.svg";

export const Snackbar = styled("div") <{ type; position }>`
  ${(props) => {
    const { type, position, theme } = props;

    const color = {
      error: "#ff5555",
      success: theme.colors.Primary,
    };

    return `
    color: #ffff;
    background-color: ${color[type]};
    border-radius: 8px;
    position: fixed;
    bottom: 30px;
    ${position !== "center" ? `${position}: 16px;` : ""}
    ${position === "center" ? "left: 50%; transform: translate(-50%, 0%);" : ""}

    @media (min-width: ${theme.mediaBreakpoints.smMin}) {
      bottom: 44px;
      ${position !== "center" ? `${position}: 44px;` : ""}
      ${position === "center"
        ? "left: 50%; transform: translate(-50%, 0%);"
        : ""
      }
    }

    align-items: center;
    justify-content: center;
    display: flex;
    padding: 16px;
    line-height: normal;
    min-height: 48px;
    min-width: 290px;`;
  }}
`;

export const ErrorIcon = styled("div")`
  margin-right: 20px;
  mask-image: url(${errorIcon});
  width: 24px;
  height: 24px;
  mask-size: contain;
  background-color: white;
  flex-shrink: 0;
`;

export const SuccessIcon = styled("div")`
  margin-right: 20px;
  mask-image: url(${successIcon});
  width: 24px;
  height: 24px;
  mask-size: contain;
  background-color: white;
  flex-shrink: 0;
`;
