import { Text, Title } from "../styles";
import SurveyForm from "./SurveyForm";

interface ComponentProps {
  setFieldValue;
  values;
  touched;
  handleBlur;
  handleChange;
  errors;
}

const Component = ({
  setFieldValue,
  values,
  touched,
  handleBlur,
  handleChange,
  errors,
}: ComponentProps) => {
  return (
    <>
      <Title>Encuesta</Title>

      <div style={{ height: 16 }} />

      <Text>
        Desde este panel podrás crear, editar o eliminar la encuesta. Recuerda
        que puedes elegir preguntas con respuestas abiertas, respuestas
        numéricas o de respuesta múltiple.
      </Text>

      <div style={{ height: 24 }} />

      <SurveyForm
        {...{
          values,
          touched,
          errors,
          setFieldValue,
          handleBlur,
          handleChange,
        }}
        isLoading={false}
      />
    </>
  );
};

export default Component;
