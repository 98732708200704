import {
  NewMembership,
  Membership,
  Launch,
  FormTitle,
  Explanation,
  Img,
  HorizontalSeparator,
  Boton,
} from "./styles";
import logo from "./../../../../assets/dashboard-home/small-team-discussing.svg";
import Link from "../../../../components/Link";
import constants from "../../../../constants";

const Component = () => {
  return (
    <NewMembership>
      <Membership>
        <Launch>
          <FormTitle>Lanza una Membresía</FormTitle>
          <Explanation>
            Tus seguidores abonarán una suscripción mensual para ser parte de un
            Súper Canal de Telegram y podrás guiarlos mes a mes con tu contenido
            (audios, videos, encuentros en vivo y material descargable)
          </Explanation>
        </Launch>
        <Img src={logo} alt="newMembership" width="237px" height="188px" />
      </Membership>

      <HorizontalSeparator />

      <Boton>
        <Link
          href={constants.urls.dashboard.newMembership.url}
          options={{
            size: "sm",
            type: "filled",
            skin: "purple",
          }}
        >
          Quiero lanzar una membresía
        </Link>
      </Boton>
    </NewMembership>
  );
};

export default Component;
