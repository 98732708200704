import { WhatsApp, Icon } from "./styles";
import constants from "../../constants";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";

interface ComponentProps {
  floating?: boolean;
  currentPage;
  group;
  options?: {
    marginBottom?: number;
  };
  userInformation;
}

const initialValue = {
  options: {
    marginBottom: 20,
  },
};

const Component = ({
  floating,
  currentPage,
  group,
  options,
  userInformation,
}: ComponentProps) => {
  const { isMobile } = useWindowSize();
  const { whatsapp } = constants;
  const [message, setMessage] = useState(whatsapp.message);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const { marginBottom } = {
    ...initialValue.options,
    ...options,
  };

  useEffect(() => {
    if (!!userInformation?.expert?.username) {
      setName(userInformation?.expert?.username);
      setMessage(whatsapp.expert_message);
    }
    if (currentPage.name === "GROUP_DETAILS") {

      if(!!userInformation?.email){
        setName(group?.group_name);
        setEmail(userInformation?.email);
        setMessage(whatsapp.logged_in_group_message);
      } else {
        setName(group?.group_name);
        setMessage(whatsapp.group_message);
      }

    }
  }, [currentPage, userInformation, group]);

  return whatsapp.enabled ? (
    <WhatsApp
      marginBottom={marginBottom}
      currentPage={currentPage.name}
      floating={floating}
      href={`${constants.whatsapp.url}?phone=${
        whatsapp.number
      }&text=${message.replace("{name}", name).replace("{email}", email)}`}
      target="_blank"
    >
      <Icon src={whatsapp.icon} alt="Whatsapp" width="16px" height="16px" />
      {!isMobile && "Ayuda"}
    </WhatsApp>
  ) : null;
};

const state = ({ pageStore, groupStore, userStore }) => {
  const { data: userInformation } = userStore.information;
  return {
    userInformation,
    group: groupStore.group.data,
    currentPage: pageStore,
  };
};

export default connect(state)(Component);
