import { combineReducers } from "redux";
import newCategory from "./new-category";
import updateCategory from "./update-category";
import deleteCategory from "./delete-category";
import allCategories from "./get-all-categories";
import singleCategory from "./get-category";
import reorderCategories from "./reorder-categories";
import reorderCategoryPosts from "./reorder-category-posts";

const reducer = combineReducers({
  newCategory,
  updateCategory,
  deleteCategory,
  allCategories,
  singleCategory,
  reorderCategories,
  reorderCategoryPosts
});

export default reducer;
