import { Metric } from "../../../types/community-metric";

export const GET_COMMUNITY_METRICS = "GET_COMMUNITY_METRICS";
export const GET_COMMUNITY_METRICS_SUCCESS = "GET_COMMUNITY_METRICS_SUCCESS";
export const GET_COMMUNITY_METRICS_ERROR = "GET_COMMUNITY_METRICS_ERROR";

export interface GetCommunityMetrics {
  type: typeof GET_COMMUNITY_METRICS;
  payload: null;
}

export interface GetCommunityMetricsSuccess {
  type: typeof GET_COMMUNITY_METRICS_SUCCESS;
  payload: Metric[];
}

export interface GetCommunityMetricsError {
  type: typeof GET_COMMUNITY_METRICS_ERROR;
  payload: boolean | string;
}

export interface State {
  data: Metric[] | null;
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | GetCommunityMetrics
  | GetCommunityMetricsError
  | GetCommunityMetricsSuccess;
