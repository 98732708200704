import constants from "../../../../../constants";
import { User } from "../../../../../types";
import forward from "../../../../../helpers/fowardUrl";
import { connect } from "react-redux";
import {
  NoSubscribedMessage,
  NoSubscribedMessageTitle,
  GoToAccount,
} from './styles'
import Button from '../../../../../components/Button'
import { useModulesPreview } from "../../../../../providers/ModulesPreviewProvider";

interface ComponentProps {
  userInformation: User
  match
  group
}

const Component = ({ userInformation, match, group }: ComponentProps) => {
  const { selectedModule } = useModulesPreview();

  return <NoSubscribedMessage>
    <NoSubscribedMessageTitle>
      Contenido exclusivo para usuarios inscriptos
    </NoSubscribedMessageTitle>
    <div style={{ height: 25 }} />
    El contenido de esta sección se encuentra reservado exclusivamente para los usuarios que se inscribieron al taller.
    <div style={{ height: 25 }} />
    <Button
      onClick={() => {
        if (
          !!selectedModule &&
          !selectedModule?.can_access_to_full_module
        ) {
          forward.setUrl(match.url);
        }
        window.location.href = `/group/${group?.slug}?`;
      }}
      style={{ width: 230 }}
      options={{
        type: "filled",
        size: "lg",
        skin: "purple",
      }}
    >
      Inscribirme
    </Button>
    <div style={{ height: 25 }} />
    {!userInformation && (
      <>
        Si ya te encuentras inscripto al taller
        <div style={{ height: 25 }} />
        <Button
          onClick={() => {
            if (
              !!selectedModule &&
              !selectedModule?.can_access_to_full_module
            ) {
              forward.setUrl(match.url);
            }
            window.location.href =
              constants.urls.subscriber.signin.url;
          }}
          style={{ width: 230 }}
          options={{
            type: "outline",
            size: "lg",
            skin: "purple",
          }}
        >
          Iniciar sesión con mi cuenta
        </Button>
      </>

    )}
  </NoSubscribedMessage>
}

const state = ({ userStore, postStore, groupStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: userSubscriptions } = userStore.subscriptions;
  const { data: selectedModule, states: postStates } = postStore.post;
  const { states: sharePostInTelegramStates } = postStore.sharePost;
  const { data: group } = groupStore.group;

  return {
    group,
    userInformation,
    userSubscriptions,
    selectedModule,
    postStates,
    sharePostInTelegramStates,
  };
};

export default connect(state)(Component);