import slugify from "slugify";
import {
  FieldContainer,
  HelperLine,
  HelperText,
  Label,
  ErrorMessage,
} from "../Field";

import { Field, Radio, OptionLabel, Checked } from "./styles";
import { useFormikFieldValidation } from "../../../hooks/useFormikFieldValidation";
import { useEffect, useState } from "react";
import { CSSProperties } from "styled-components";
import { Value } from "../../../pages/Dashboard/components/Statistics/styles";

const initialValue = {
  options: {
    skin: "base",
    size: "md",
  },
};

interface ComponentProps {
  name: string;
  id: string;
  label: string;
  value: string | number;
  disabled?: boolean;
  error?: {};
  touched?: {};
  selectedValue?: string;
  onFocus?: (data: any) => void;
  onBlur?: (data: any) => void;
  onChange?: (data: any) => void;
  styles?: {
    wrapper?: CSSProperties
  }
  options: {
    label?: string;
    skin?: "base";
    size?: "md";
    helperText?: string;
    before?: any;
    after?: any;
    options?: number;
    hidden?: boolean;
    marginBottom?: number;
    vertical?: boolean
  };
}

const Component = ({
  name,
  id,
  label,
  value,
  selectedValue,
  options,
  error,
  touched,
  disabled,
  onFocus,
  onBlur,
  onChange,
  styles
}: ComponentProps) => {
  const { classes } = useFormikFieldValidation(error, name, disabled);
  const { skin, size, marginBottom } = {
    ...initialValue.options,
    ...options,
  };

  const handleOnChange = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnBlur = (event, callback) => {
    !!callback && callback(event);
  };

  const handleOnFocus = (event, callback) => {
    !!callback && callback(event);
  };

  return (
    <FieldContainer marginBottom={marginBottom} style={{ ...(styles?.wrapper ? styles?.wrapper : {}) }}>
      {options?.label && (
        <Label size={size} htmlFor={`input-${name}`} style={{ marginBottom: 8 }}>
          {options.label}
          {/* {rules && rules.required && <Required>*</Required>} */}
        </Label>
      )}

      {(options && options.helperText) && (
        <HelperLine marginBottom={0} style={{ marginBottom: 12, marginTop: 0 }}>
          <HelperText>{options.helperText}</HelperText>
        </HelperLine>
      )}

      <Field
        skin={skin}
        size={size}
        className={classes}
        style={{
          backgroundColor: "transparent", ...(options.vertical ? {
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "5px",
          } : {})
        }}
      >
        <Radio size={size} key={id}>
          <OptionLabel
            size={size}
            disabled={disabled}
            withLabel={label}
            className={selectedValue === value ? "checked" : ""}
          >
            <input
              id={id}
              value={value}
              name={name}
              type="radio"
              onChange={(event) => handleOnChange(event, onChange)}
              onBlur={(event) => handleOnBlur(event, onBlur)}
              checked={selectedValue === value}
              disabled={disabled}
            />
            <Checked checked={selectedValue === value} />
            {label}
          </OptionLabel>
        </Radio>
      </Field>

      {error && (
        <HelperLine marginBottom={marginBottom || 40}>
          {!!error && !!touched && <ErrorMessage>{error}</ErrorMessage>}
        </HelperLine>
      )}
    </FieldContainer>
  );
};

export default Component;
