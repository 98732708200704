import { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useWindowSize } from "./../../../../hooks/useWindowSize";
import { AuthContext } from "../../../../providers/Auth";
import { saveMeet, getMeet } from "../../../../store/actions/meet";
import { Button } from "./styles";
import constants from "../../../../constants";
import dateHanlder from "../../../../helpers/dates-hanlder";
import { Meet } from "../../../../types/meet";
import { newAppDemo } from "../../../../helpers/calendly";

declare global {
  interface Window {
    Calendly: any;
  }
}

const isCalendlyEvent = (event) => {
  return event.data.event && event?.data?.event?.indexOf("calendly") === 0;
};

const getMeetDate = (date) => {
  const { day, month, hours, minutes } = dateHanlder.dateSpliter(date);
  return `${day?.name?.large} ${day?.number} de ${month?.name?.large}, ${hours}:${minutes}hs`;
};

interface ComponentsProps {
  userInformation: any;
  meetInformation: Meet;
  skin?: "purple" | "ligthPurple" | "transparent" | "white";
}

const Component = ({
  userInformation,
  meetInformation,
  skin = "purple",
}: ComponentsProps) => {
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { isMobile, isTabletLandscape, isDesktop } = useWindowSize();
  const [userName, setUserName] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [userId, setUserId] = useState<number>();
  const [meetDate, setMeetDate] = useState("");

  const calendlyEvent = (event) => {
    // calendly.profile_page_viewed	  Profile page was viewed
    // calendly.event_type_viewed	  Event type page was viewed
    // calendly.date_and_time_selected	  Invitee selected date and time
    // calendly.event_scheduled	  Invitee successfully booked a meeting

    if (isCalendlyEvent(event)) {
      if (event.data.event === "calendly.event_scheduled" && userId) {
        dispatch(saveMeet({ payload: event.data.payload, userId }));
      }
    }
  };

  useEffect(() => {
    if (userInformation) {
      if (userInformation?.name || user?.displayName) {
        setUserName(userInformation?.name || user?.displayName);
      }

      if (userInformation?.email || user?.email) {
        setUserEmail(userInformation?.email || user?.email);
      }

      setUserId(userInformation.id);
    }
  }, [user, userInformation]);

  useEffect(() => {
    if (userId) {
      dispatch(getMeet({ userId }));
      window.addEventListener("message", calendlyEvent);
    }
    return () => {
      window.removeEventListener("message", calendlyEvent);
    };
  }, [userId]);

  useEffect(() => {
    if (!!meetInformation) {
      setMeetDate(getMeetDate(meetInformation.start_time));
    }
  }, [meetInformation]);
  return (
    <Button
      type="button"
      onClick={() => newAppDemo(userName, userEmail)}
      loading={false}
      options={{
        skin,
        size: "xl",
        type: "outline",
      }}
    >
      Quiero una demostración
    </Button>
  );
};

const state = ({ userStore, meetStore }) => {
  const { data: userInformation } = userStore.information;
  const { data: meetInformation, states: meetStates } = meetStore;
  return {
    userInformation,
    meetInformation,
    meetStates,
  };
};

export default connect(state)(Component);
