import Sidebar from "../../../../components/Sidebar";
import Button from "../../../../components/Button";
import { Preview, Text, Option } from "../Sidebars/components/styles";
import { SurveyInDto } from "../../../../types/survey.dto";
import { useEffect, useState } from "react";
import { Formik } from "formik";
import { connect, useDispatch } from "react-redux";
import DeleteSurvey from "../components/DeleteSurveyModal";
import { initialValues, schema } from "../../../../constants/forms/survey/edit";
import Submit from "../../../../components/FormikForm/Submit";
import {
  resetDeleteSurvey,
  resetUpdateSurvey,
  updateSurvey,
} from "../../../../store/actions/survey";
import SurveyForm from "../components/SurveyForm";
import { showModal } from "../../../../store/actions/modal";

interface ComponentProps {
  onClose?: (data: any) => void;
  isVisible: boolean;
  survey: SurveyInDto;
  deleteSurveyStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  updateSurveyStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  onClose,
  isVisible,
  survey,
  deleteSurveyStates,
  updateSurveyStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState(false);

  const onSubmit = ({ values, actions }) => {
    dispatch(updateSurvey(values));
  };

  useEffect(() => {
    if (updateSurveyStates.success) {
      !!onClose && onClose(false);
    }
  }, [updateSurveyStates]);

  return (
    <>
      <Sidebar
        position="right"
        visible={isVisible}
        closeType="back"
        onClose={() => {
          !!onClose && onClose(false);
        }}
        width="576px"
        title="Editar encuesta"
      >
        <Preview>
          <div>
            <Text>
              Desde este panel podrás crear, editar o eliminar la encuesta.
              Recuerda que puedes elegir preguntas con respuestas abiertas,
              respuestas numéricas o de respuesta múltiple.
            </Text>

            <Option>
              <Formik
                initialValues={{
                  ...initialValues,
                  ...survey,
                  group: survey?.group?.id,
                }}
                onSubmit={(values, actions) => {
                  if (onSubmit) onSubmit({ values, actions });
                }}
                validateOnChange={false}
                validateOnBlur={formSubmmited}
                validationSchema={schema}
                enableReinitialize
              >
                {({ handleSubmit, ...rest }) => {
                  return (
                    <form
                      className="theme-form"
                      onSubmit={(event) => {
                        setFormSubmmited(true);
                        handleSubmit(event);
                      }}
                      id="edit-survey-form"
                    >
                      <SurveyForm
                        {...rest}
                        isLoading={updateSurveyStates.loading}
                      />
                    </form>
                  );
                }}
              </Formik>
            </Option>
          </div>

          <div>
            <Submit
              disabled={survey?.has_answers}
              isSubmmiting={updateSurveyStates.loading}
              form="edit-survey-form"
              options={{
                type: "filled",
                skin: "purple",
                size: "lg",
                block: true,
              }}
              style={{ marginBottom: "8px" }}
            >
              Guardar Cambios
            </Submit>

            <Button
              options={{
                type: "outline",
                skin: "purple",
                size: "lg",
                block: true,
              }}
              onClick={() => !!onClose && onClose(false)}
            >
              Cancelar
            </Button>

            <div style={{ height: "12px" }} />

            <Button
              options={{
                type: "link",
                skin: "purple",
                size: "lg",
                block: true,
              }}
              onClick={() => {
                onClose && onClose(false);
                dispatch(showModal("modal-delete-survey"));
              }}
            >
              Eliminar encuesta
            </Button>
          </div>
        </Preview>
      </Sidebar>
    </>
  );
};

const state = ({ surveyStore }) => {
  const { states: updateSurveyStates } = surveyStore.update;
  const { states: deleteSurveyStates } = surveyStore.deleteOne;

  return {
    updateSurveyStates,
    deleteSurveyStates,
  };
};

export default connect(state)(Component);
