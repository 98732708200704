import { Card, Title, Text, Center } from "./styles";
import Icon from "../../../../components/Icon";
import Link from "../../../../components/Link";

interface ComponentProps {
  title: string;
  text: string;
  link?: {
    label: string;
    url: string;
  };
  icon?: string;
}

const Component = ({ title, text, link, icon }: ComponentProps) => {
  return (
    <Card>
      <Center>
        {icon && <Icon icon={icon} size="48px" color="#C4C4C4" />}

        <div>
          <Title>{title}</Title>
          <div style={{ height: "4px" }} />
          <Text>{text}</Text>
        </div>
      </Center>
      <div style={{ height: "19px" }} />
      {link && (
        <Link
          href={link.url}
          options={{
            type: "outline",
            skin: "purple",
            size: "lg",
            block: true,
          }}
        >
          {link.label}
        </Link>
      )}
    </Card>
  );
};

export default Component;
