import styled from "styled-components";

export const Title = styled("div")`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;

export const Text = styled("div")`
  font-size: 16px;
  line-height: 19px;
`;
