import { Group } from "../../../../types";

export const GET_GROUP_BY_ID = "GET_GROUP_BY_ID";
export const GET_GROUP_BY_ID_SUCCESS = "GET_GROUP_BY_ID_SUCCESS";
export const GET_GROUP_BY_ID_ERROR = "GET_GROUP_BY_ID_ERROR";
export const RESET_GET_GROUP = "RESET_GET_GROUP";

export interface GetGroupById {
  type: typeof GET_GROUP_BY_ID;
  payload: number;
}

export interface GetGroupByIdSuccess {
  type: typeof GET_GROUP_BY_ID_SUCCESS;
  payload: Group;
}

export interface GetGroupByIdError {
  type: typeof GET_GROUP_BY_ID_ERROR;
  payload: boolean | string;
}

export interface ResetGetGroup {
  type: typeof RESET_GET_GROUP;
  payload: any;
}