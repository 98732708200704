import styled from "styled-components";

export const CarouselContent = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 82px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 1px 14px rgb(0 0 0 / 7%);

  .carousel__inner-slide {
    display: flex;
  }
`;

export const Step = styled("div")`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  width: 120px;
  flex-shrink: 0;
  position: relative;
  padding-right: 14px;

  &:after {
    content: "";
    display: block;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    background-color: #6c5ce7;
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(0%, -50%);
    z-index: 0;
  }
`;

export const Big = styled("div")`
  color: white;
  font-weight: 500;
  font-size: 52px;
  line-height: 63px;
  position: relative;
  z-index: 1;
`;

export const Small = styled("div")`
  color: white;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  bottom: 3px;
  position: relative;
  position: relative;
  z-index: 1;
`;
