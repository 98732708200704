import action from "./../helpers/rest-client";

export class ErrorCurrency extends Error {}

class Currency {
  public async getLocalPrice(payload) {
    const result = await action
      .Post({
        url: `/api/third-party/exchange`,
        body: payload,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorCurrency("Error al obtener la taza de cambio.");
      });

    return result;
  }
}

const currency = new Currency();
export default currency;
