import { useState } from "react";
import { Stepper } from "./styles";
import Step from "../Step";
import slugify from "slugify";

const states = {
  CURRENT: "CURRENT",
  NEXT: "NEXT",
  PREV: "PREV",
};

const Component = ({ steps, activeStep, onClick }) => {
  const [currentStep, setCurrentStep] = useState(activeStep);

  const getState = (index) => {
    if (index === currentStep) return states.CURRENT;
    if (index < currentStep) return states.PREV;
    if (index > currentStep) return states.NEXT;
  };

  const handleClick = (index) => {
    onClick && onClick(index);
    setCurrentStep(index);
  };

  return (
    <Stepper>
      {steps.map((step, index) => {
        const { label } = step;

        return (
          <Step
            key={slugify(label, { lower: true })}
            label={label}
            state={getState(index)}
            step={index + 1}
            onClick={() => handleClick(index)}
          />
        );
      })}
    </Stepper>
  );
};

export default Component;
