import * as Yup from "yup";
import services from "../../../services";

let typingTimeout;

export const schema = ({ validationRunning, initialProfile }) => Yup.object().shape({
  name: Yup.string().required("Ingresa tu nombre"),
  last_name: Yup.string().required("Ingresa tu apellido"),
  email: Yup.string()
    .required("Ingresa tu corréo electrónico")
    .trim("El corréo electrónico no puede contener espacio")
    .email("Ingresa un corréo electrónico válido")
    .test(
      "checkUserEmailExist",
      `Ya has creado una cuenta con este email en algún momento. Puedes <a target='_blank' href='/${initialProfile}/signin'>Iniciar Sesión</a>`,
      function async(email) {
        //clear timeout if user is typing and timeout is set
        if (typingTimeout) clearTimeout(typingTimeout);

        return new Promise((resolve, reject) => {
          //delay until user stops typing

          typingTimeout = setTimeout(async () => {
            //check if email is valid
            if (!email) return resolve(true);

            validationRunning(true)

            const { response } = await services.auth.validateEmail(email);

            validationRunning(false)

            if (response?.status > 500) {
              return resolve(
                this.createError({
                  message: "Error inesperado al validar el e-mail",
                })
              );
            }

            if (response?.status > 400) {
              return resolve(true);
            }

            if (!response || response?.status < 400) {
              return resolve(false);
            }
          }, 1000);
        });
      }
    ),
  password: Yup.string().required("Ingresa tu contraseña").min(6, 'La contraseña debe tener al menos 6 caracteres'),
  "confirmar-password": Yup.string()
    .required("Vuelve a ingresar tu contraseña")
    .oneOf(
      [Yup.ref("password"), null],
      "Las contraseñas ingresadas nos no coinciden."
    ),
  initial_profile: Yup.string(),
});

interface FormValues {
  name: string;
  last_name: string;
  email: string;
  password: string;
  "confirmar-password": string;
  initial_profile: string;
}

export const initialValues: FormValues = {
  name: "",
  last_name: "",
  email: "",
  password: "",
  "confirmar-password": "",
  initial_profile: "",
};
